import { View, ViewProps } from 'react-native';
import React, { FunctionComponent } from 'react';
import { Modal, Button, Text, HSpacer } from '@design';
import { useTranslation } from 'react-i18next';

export interface ExportModalProps extends ViewProps {
  exportText: string,
  exportButtonText: string,
  onClose: () => void,
  onExport: () => void,
}

export const ExportModal: FunctionComponent<ExportModalProps> = ({
  exportButtonText,
  exportText,
  onExport,
  onClose,
}) => {
  const onExportConfirm = () => {
    onExport();
    onClose();
  };
  const [translate] = useTranslation(['common']);

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            appearance="outline"
            onPress={onClose}
            testID="cancel-button"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            onPress={onExportConfirm}
            testID="export-button"
          >
            {exportButtonText}
          </Button>
        </>
      )}
      onClose={onClose}
      testID="export-modal"
      title={translate('EXPORT')}
      visible
    >
      <View>
        <View style={{ justifyContent: 'flex-start' }}>
          <Text>{exportText}</Text>
        </View>
      </View>
    </Modal>
  );
};
