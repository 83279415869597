import { ApiDiscount } from '@shared/interfaces/api';
import { CalculatedProductDiscount } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Text, IconButton, HSpacer, VSpacer } from '@design';
import { StringUtility, testId } from '../../../../../utilities';

export interface ProductDiscountItemProps {
  calculatedDiscount: CalculatedProductDiscount,
  discount: ApiDiscount,
  index: number,
  isDraft: boolean,
  isEditable: boolean,
  onDelete: (discount: ApiDiscount) => void,
  onEdit: (discount: ApiDiscount) => void,
  prefix?: string,
  readOnly?: boolean,
  viewMode?: boolean,
}

export const ProductDiscountItem: FC<ProductDiscountItemProps> = ({
  calculatedDiscount,
  discount,
  index,
  isDraft,
  isEditable,
  onDelete,
  onEdit,
  prefix,
  readOnly,
  viewMode,
}) => {
  const [translate] = useTranslation(['productOrders', 'common']);

  const themedStyles = useStyleSheet({
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flex: 1,
      flexDirection: 'column',
    },
  });

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.column}>
        <Text {...testId('product-discount-item-name', index)} category="s1">
          {prefix}{prefix ? ' - ' : ''}{discount.name}
        </Text>
        <VSpacer size="3" />
        <View style={themedStyles.row}>
          <Text appearance="hint" category="p2">{`${translate<string>('TYPE')}: `}</Text>
          <Text category="p2" {...testId('product-discount-item-cost-type', index)}>
            {translate<string>(discount.costType)}
          </Text>
          {
            !!calculatedDiscount.percent && (
              <>
                <HSpacer size="7" />
                <Text {...testId('product-discount-item-percent', index)} category="p2" status="success">
                  {`${calculatedDiscount.percent}% ${translate<string>('OFF')}`}
                </Text>
              </>
            )
           }
          {
            !!calculatedDiscount.dollarsTotal && (
              <>
                <HSpacer size="7" />
                <Text {...testId('product-discount-item-dollars', index)} category="p2" status="success">
                  {StringUtility.formatDiscount(calculatedDiscount.dollarsTotal)}
                </Text>
              </>
            )
           }
        </View>
      </View>
      <View style={themedStyles.row}>
        {
          !viewMode && (
            <>
              {isEditable && (
                <IconButton
                  appearance="ghost"
                  disabled={readOnly}
                  onPress={() => onEdit(discount)}
                  status={isDraft ? 'basic' : 'primary'}
                  testID={`product-discount-item-edit-${index}`}
                >
                  Edit
                </IconButton>
              )}
              <IconButton
                appearance="ghost"
                disabled={readOnly}
                onPress={() => onDelete(discount)}
                status={isDraft ? 'basic' : 'primary'}
                testID={`product-discount-item-delete-${index}`}
              >
                Trash
              </IconButton>
            </>
          )
        }
      </View>
    </View>
  );
};
