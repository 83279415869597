/* eslint-disable max-len */
import React, { FunctionComponent, useState, useRef } from 'react';
import { View } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import {
  Accordion,
  AccordionItem,
  Select,
  SelectItem,
  Button,
  IconButton,
  IconName,
  Input,
  Text,
  VSpacer,
  SidePanel,
} from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const NONE = 'None';
type IconSelection = IconName | typeof NONE;

export const SidePanelDemo: FunctionComponent = () => {
  const [emptySidePanelVisible, setEmptySidePanelVisible] = useState(false);
  const [textSidePanelVisible, setTextSidePanelVisible] = useState(false);
  const accordionSidePanel = useRef<SidePanel>(null);
  const [iconRight, setIconRight] = useState<IconSelection>(NONE);
  const [iconLeft, setIconLeft] = useState<IconSelection>(NONE);
  const [accessoryRightOptions] = useState<IconSelection[]>([NONE, 'MoreHorizontal', 'MoreVertical']);
  const [accessoryLeftOptions] = useState<IconSelection[]>([NONE, 'ArrowBack']);
  const [header, setHeader] = useState<string>('Heading');

  return (
    <View>
      <DemoContainer>
        <DemoBlock>
          <Select
            label="Header accessory left"
            onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(accessoryLeftOptions[(ip as IndexPath).row])}
            selectedIndex={new IndexPath(accessoryLeftOptions.findIndex((n) => n === iconRight))}
            testID="side-panel-demo-accesory-left-selector"
            value={iconLeft}
          >
            {accessoryLeftOptions.map((icon, index) => <SelectItem key={`left-${icon}`} testID={`side-panel-demo-accessory-left-dropdown-value-${index}`} title={icon} />)}
          </Select>
        </DemoBlock>
        <DemoBlock>
          <Input label="Header text" onChangeText={setHeader} testID="demo-input" value={header} />
        </DemoBlock>
        <DemoBlock>
          <Select
            label="Header accessory right"
            onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(accessoryRightOptions[(ip as IndexPath).row])}
            selectedIndex={new IndexPath(accessoryRightOptions.findIndex((n) => n === iconRight))}
            testID="side-panel-demo-accesory-right-selector"
            value={iconRight}
          >
            {accessoryRightOptions.map((icon, index) => <SelectItem key={`right-${icon}`} testID={`side-panel-demo-accessory-right-dropdown-value-${index}`} title={icon} />)}
          </Select>
        </DemoBlock>
      </DemoContainer>
      <VSpacer size="7" />
      <DemoContainer label="Open Side Panel">
        <DemoBlock>
          <Button onPress={() => { setEmptySidePanelVisible(true); }} size="small" testID="test-button">
            Empty
          </Button>
          <SidePanel
            header={header}
            headerAccessoryLeft={iconLeft !== NONE && ((props) => <IconButton {...props} testID="test-button">{iconLeft}</IconButton>)}
            headerAccessoryRight={iconRight !== NONE && ((props) => <IconButton {...props} testID="test-button">{iconRight}</IconButton>)}
            onClose={() => { setEmptySidePanelVisible(false); }}
            testID="sidepanel"
            visible={emptySidePanelVisible}
          />
        </DemoBlock>
        <DemoBlock>
          <Button onPress={() => { setTextSidePanelVisible(true); }} size="small" testID="test-button">
            Text
          </Button>
          <SidePanel
            header={header}
            headerAccessoryLeft={iconLeft !== NONE && ((props) => <IconButton {...props} testID="test-button">{iconLeft}</IconButton>)}
            headerAccessoryRight={iconRight !== NONE && ((props) => <IconButton {...props} testID="test-button">{iconRight}</IconButton>)}
            onClose={() => { setTextSidePanelVisible(false); }}
            style={{ paddingHorizontal: 20 }}
            testID="sidepanel"
            visible={textSidePanelVisible}
          >
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis placerat vestibulum. Nunc ac ex hendrerit, sollicitudin eros nec, congue nulla. Vestibulum efficitur neque ac odio aliquam maximus. Quisque quis viverra nulla. Vivamus malesuada, tellus sed cursus egestas, enim leo facilisis ipsum, eu fringilla leo libero quis velit. Morbi molestie augue purus, in volutpat diam elementum et. Maecenas sollicitudin faucibus dui sed vestibulum. Sed aliquet nisi ac est pharetra varius. Praesent eleifend eros eu nunc tincidunt viverra. Etiam pulvinar id sem non sodales.
            </Text>
            <Button onPress={() => setTextSidePanelVisible(false)} testID="test-button">Close by Prop</Button>
            <Button onPress={() => setEmptySidePanelVisible(true)} testID="test-button">Open Empty</Button>
          </SidePanel>
        </DemoBlock>
        <DemoBlock>
          <Button onPress={() => { accordionSidePanel.current.open(); }} size="small" testID="test-button">
            Accordion
          </Button>
          <SidePanel
            header={header}
            headerAccessoryLeft={iconLeft !== NONE && ((props) => <IconButton {...props} testID="test-button">{iconLeft}</IconButton>)}
            headerAccessoryRight={iconRight !== NONE && ((props) => <IconButton {...props} testID="test-button">{iconRight}</IconButton>)}
            onClose={() => { /* do a thing */ }}
            ref={accordionSidePanel}
            testID="sidepanel"
          >
            <Accordion testID="side-panel-demo-list">
              <AccordionItem testID="side-panel-demo-item-1" title="1. Lorem ipsum dolor sit amet">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis placerat vestibulum. Nunc ac ex hendrerit, sollicitudin eros nec, congue nulla. Vestibulum efficitur neque ac odio aliquam maximus. Quisque quis viverra nulla. Vivamus malesuada, tellus sed cursus egestas, enim leo facilisis ipsum, eu fringilla leo libero quis velit. Morbi molestie augue purus, in volutpat diam elementum et. Maecenas sollicitudin faucibus dui sed vestibulum. Sed aliquet nisi ac est pharetra varius. Praesent eleifend eros eu nunc tincidunt viverra. Etiam pulvinar id sem non sodales.
              </AccordionItem>
              <AccordionItem testID="side-panel-demo-item-2" title="2. Nulla eget maximus tortor">
                Nulla eget maximus tortor. Integer fringilla mi sagittis semper varius. Nam quis augue magna. Nunc et mauris ut dui accumsan lobortis. Suspendisse congue lorem id lobortis sagittis. Phasellus et ligula eget magna semper euismod ac vel mi. Maecenas vel blandit tortor, ac auctor justo.
              </AccordionItem>
              <AccordionItem testID="side-panel-demo-item-3" title="3. Aliquam nec tortor">
                Aliquam nec tortor eu quam semper porta nec eu tortor. Ut quis blandit nunc. Suspendisse pretium sed metus quis consequat. Duis sagittis, nisi ac egestas consectetur, velit nulla euismod lacus, vitae hendrerit sem felis at tortor. Morbi accumsan, risus ut condimentum commodo, nibh augue rutrum lorem, ut bibendum augue ante quis tellus. Etiam suscipit at elit sit amet scelerisque. Suspendisse et scelerisque nisl.
              </AccordionItem>
              <AccordionItem testID="side-panel-demo-item-4" title="4. Maecenas vitae posuere tortor">
                Maecenas vitae posuere tortor. Vivamus volutpat sem sit amet rhoncus interdum. Phasellus vitae vestibulum felis, nec viverra nunc. Phasellus eget arcu sit amet eros faucibus accumsan quis at libero. Maecenas justo libero, dignissim vitae cursus nec, luctus vitae enim. Phasellus vestibulum vitae sem eu molestie. Nam id eros volutpat, vehicula ex sit amet, dapibus erat. Nullam vel mauris elit. Mauris rhoncus in magna vel accumsan. Vestibulum tempor congue aliquet. Fusce egestas, velit eu ullamcorper vestibulum, enim magna vulputate lectus, eget dapibus felis tortor et quam. Donec vitae ornare libero. Etiam lacus nulla, volutpat id pellentesque sed, aliquet at mauris. Donec pharetra, turpis ac venenatis rutrum, est magna vehicula turpis, sit amet malesuada ex eros non est. Integer feugiat vel diam vel dictum. Aliquam nisl ante, ultrices nec auctor ac, mollis sed metus.
              </AccordionItem>
              <AccordionItem testID="side-panel-demo-item-5" title="5. Phasellus ut diam">
                Phasellus ut diam at ex tincidunt bibendum. Cras mi orci, porttitor nec gravida ut, tempor ut ipsum. Proin interdum, dolor ut dapibus feugiat, mauris enim accumsan sapien, et ornare arcu dolor ultricies tortor. Vivamus a convallis risus. Nulla in dui in felis fringilla eleifend a a leo. Nam quis quam sapien. Praesent consequat mi quis consequat lacinia. Vestibulum bibendum nibh nec gravida ultricies. Curabitur in ligula vel mi sodales consequat at eu ex. Pellentesque turpis metus, congue id nunc vitae, lacinia gravida mauris. Vivamus at ipsum quis velit vestibulum imperdiet. Donec posuere fringilla dolor.
              </AccordionItem>
            </Accordion>
          </SidePanel>
        </DemoBlock>
      </DemoContainer>
    </View>
  );
};
