import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBar } from '../../../../components/shared/ButtonBar';
import {
  ConfirmationModal,
} from '../../../../components/shared/ConfirmationModal/ConfirmationModal';

export interface EditModeFooterProps {
  disabled?: boolean,
  editMode?: boolean,
  confirmOnCancel?: boolean,
  onSave?(): void,
  onCancel(): void,
}
export function EditModeFooter ({
  disabled,
  editMode,
  confirmOnCancel,
  onSave,
  onCancel,
}: EditModeFooterProps) {
  const [translate] = useTranslation(['prepare', 'tankMix', 'common', 'errors']);
  const [cancelEditVisible, setCancelEditVisible] = useState(false);

  return (
    <>
      <ConfirmationModal
        cancelText={translate('CONTINUE_EDITING')}
        confirmText={translate('QUIT_EDITING')}
        messageText={translate(
          editMode ? 'CANCEL_EDIT_DIALOG_DESCRIPTION_EDIT'
            : 'CANCEL_EDIT_DIALOG_DESCRIPTION_NEW',
        )}
        onCancel={() => setCancelEditVisible(false)}
        onConfirm={onCancel}
        title={translate('CANCEL_EDIT_DIALOG_TITLE')}
        visible={cancelEditVisible}
      />
      <ButtonBar
        buttonBarType={!editMode ? 'grouped' : 'spaced'}
        disableRightAction={disabled}
        leftAction={confirmOnCancel ? () => setCancelEditVisible(true) : onCancel}
        leftButtonText={translate('CANCEL')}
        rightAction={onSave}
        rightButtonText={translate(editMode ? 'SAVE_CHANGES' : 'CREATE_PRODUCT_MIX')}
        testID="button-bar"
      />
    </>
  );
}
