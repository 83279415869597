import { SharedConfig } from '@shared/constants';
import {
  ApiGrower,
  ApiGrowerAssignedUser,
  ApiPlanningParameter,
  ApiPlanningParameterSave,
  ApiUserAccount,
  GrowerContactEndpoint,
  GrowerEndpoint,
  ImportEndpoint,
  PaginatedData,
  PlanningParametersEndpoint,
} from '@shared/interfaces/api';
import qs from 'qs';
import { Client } from './Client';

export class GrowerApi {
  static createGrower (
    growerData: GrowerEndpoint.Create.Request,
  ): Promise<GrowerEndpoint.Create.Response> {
    return Client('growers', { body: growerData });
  }

  static getGrowers (
    filters: GrowerEndpoint.List.Query,
  ): Promise<GrowerEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`growers?${query}`);
  }

  static getGrower (growerId: string): Promise<ApiGrower> {
    return Client(`growers/${growerId}`);
  }

  static getGrowerContacts (
    growerId: string,
    filters: GrowerContactEndpoint.List.Query = {},
  ): Promise<GrowerContactEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`growerContacts/listByGrower/${growerId}?${query}`);
  }

  static updateGrowerContact (growerContactId, updates: GrowerContactEndpoint.Update.Request) {
    return Client(`growerContacts/${growerContactId}`, {
      method: 'PATCH',
      body: updates,
    });
  }

  static updateGrower (
    growerId: string,
    updates: GrowerEndpoint.Update.Request,
  ): Promise<ApiGrower> {
    return Client(`growers/${growerId}`, {
      method: 'PATCH',
      body: updates,
    });
  }

  static exportGrowers (filters: any, businessId?: string) {
    const query = qs.stringify({ businessId, ...filters }, { arrayFormat: 'comma' });
    return Client(`export/growers?${query}`);
  }

  static setGrowerContactAsPrimary (contactId : string, growerId : string) {
    return Client(`growerContacts/${contactId}/primary?growerId=${growerId}`, { method: 'POST' });
  }

  static getAssignedUsers (
    growerId: string,
  ): Promise<ApiGrowerAssignedUser[]> {
    return Client(`growers/${growerId}/linkedUsers`);
  }

  static getPlanningParameters (
    growerId: string,
  ): Promise<PaginatedData<ApiPlanningParameter>> {
    const filters: PlanningParametersEndpoint.List.Query = {
      limit: SharedConfig.MAX_PAGE_LIMIT,
    };
    const query = qs.stringify(filters, { arrayFormat: 'comma' });
    return Client(`growers/${growerId}/planning-parameters?${query}`);
  }

  static syncPlanningParameters (
    growerId: string,
    growerPlanningParameters: ApiPlanningParameterSave[],
  ) {
    return Client(`growers/${growerId}/planning-parameters`, {
      method: 'PUT',
      body: {
        growerPlanningParameters,
      } as PlanningParametersEndpoint.Sync.Request,
    }) as Promise<PlanningParametersEndpoint.Sync.Response>;
  }

  static getEligibleFarmPlanOwners (
    growerId: string,
  ): Promise<ApiUserAccount[]> {
    return Client(`growers/${growerId}/eligibleFarmPlanOwners`);
  }

  static async getAllGrowersForBusiness (businessId: string): Promise<PaginatedData<ApiGrower>> {
    const filters: GrowerEndpoint.List.Query = {
      businessId,
      limit: SharedConfig.MAX_PAGE_LIMIT,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    };
    return this.getGrowers(filters);
  }

  static unlinkBusinessUsers (growerId: string, userAccountIds: string[]) {
    return Client(`growers/${growerId}/unlinkBusinessUsers`, {
      method: 'POST',
      body: { userAccountIds },
    });
  }

  static linkBusinessUsers (growerId: string, userAccountIds: string[]) {
    return Client(`growers/${growerId}/linkBusinessUsers`, {
      method: 'POST',
      body: { userAccountIds },
    });
  }

  static async getCsvTemplate () {
    return Client(
      'export/template/customersWithContacts',
    );
  }

  static importGrowers (fileInfo: any, businessId: string): Promise<ImportEndpoint.ImportResult> {
    const body = { csv: fileInfo.uri };
    const query = qs.stringify({ businessId });
    return Client(`import/growersWithContacts?${query}`, {
      body,
      method: 'POST',
    });
  }

  static modifyGrowerContacts (growerData: GrowerContactEndpoint.BulkSave.Request) {
    return Client('growerContacts/bulkModify', { body: growerData, method: 'PUT' });
  }
}
