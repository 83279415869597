import { View } from 'react-native';
import { Analytics } from '@mui/icons-material';
import { Button, Icon, Text, useToast, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import { PropertyGroupModal } from './PropertiesTab/PropertyGroupModal';
import { ApiForm, FormEndpoint } from '@shared/interfaces/api';
import { PropertyGroupApi } from '../../../utilities/api/PropertyGroupApi';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { PropertyGroupList } from './PropertiesTab/PropertyGroupList';
import { userIsInternal } from '@shared/utils';

export const PropertiesTab = () => {
  const [translate] = useTranslation(['propertyGroups', 'common']);
  const { createToast } = useToast();
  const { currentBusinessId, user } = useAuthentication();
  const [isPropertyGroupModalVisible, setIsPropertyGroupModalVisible] = useState(false);
  const [propertyGroups, setPropertyGroups] = useState<ApiForm[]>([]);
  const queryClient = useQueryClient();
  const [isEdit, setIsEdit] = useState(false);
  const [propertyGroup, setPropertyGroup] = useState(null);
  const isInternalUser = userIsInternal(user);

  const styles = useStyleSheet({
    iconStyles: {
      color: 'color-primary-500', 
      width: 60, 
      height: 60,
      borderRadius: 0,
      borderWidth: 1,
    },
    centeredGroup: {
      alignItems: 'center',
    },
  });

  const invalidatePropertyGroups = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.PROPERTY_GROUP_LIST, currentBusinessId])
  ), [currentBusinessId, queryClient]);

  const getPropertyGroupsList = () => PropertyGroupApi.getPropertyGroups(currentBusinessId);

  useQuery<FormEndpoint.List.Response, Error>(
    [QueryKeys.PROPERTY_GROUP_LIST, currentBusinessId],
    getPropertyGroupsList,
    {
      onError: () => {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR'),
          status: 'warning',
          testID: 'property-group-list-error-toast',
        });
      },
      onSuccess: (res) => setPropertyGroups(res),
    },
  );

  const EmptyResultSet = (       
    <View>
      <Text appearance="hint" category="p2">
        {translate<string>('PROPERTIES_TAB_DESCRIPTION')}
      </Text>
      <VSpacer size="12" />
      <View style={styles.centeredGroup}>
        <Analytics
          sx={styles.iconStyles}
        />
        <VSpacer size="5" />
        <Text category="h6">
          {translate<string>('PROPERTIES_TAB_CALL_TO_ACTION')}
        </Text>
        <VSpacer size="7" />
        <Button
          accessoryLeft={
            (iconProps) => {
              return <Icon 
                name="Plus" 
                testID="create-first-property-button-icon" 
                {...iconProps} 
              />;
            } 
          }
          appearance="outline"
          disabled={isInternalUser}
          onPress={() => {
            setIsPropertyGroupModalVisible(true);
          }}
          size="large"
          testID="create-first-property-button"
        >
          {translate<string>('PROPERTY_CREATE_BUTTON_TITLE')}
        </Button>
      </View>
    </View>
  );

  const PropertyGroupListHeader = (
    <>
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text appearance="hint" category="p2">
          {translate<string>('PROPERTIES_TAB_DESCRIPTION')}
        </Text>
        <VSpacer size="12" />
        <Button
          accessoryLeft={
            (iconProps) => {
              return <Icon 
                name="Plus" 
                testID="create-first-property-button-icon" 
                {...iconProps}
              />;
            }
          }
          appearance="filled"
          disabled={isInternalUser}
          onPress={() => {
            setIsPropertyGroupModalVisible(true);
          }}
          size="large"
          testID="create-additional-property-button"
        >
          {translate<string>('PROPERTY_CREATE_BUTTON_TITLE')}
        </Button>
      </View>
      <VSpacer size="7" />
      <Text category="p2">
        {`${propertyGroups?.length} ${translate<string>('PROPERTY_GROUPS')}`}
      </Text>
    </>
  );

  const updatePropertyGroup = async (propertyGroupRequest: FormEndpoint.Update.Request) => {
    // not allowed to use null id for newly added fields on update, so we remove them.
    propertyGroupRequest.fields = propertyGroupRequest.fields.map((field) => {
      const fieldOptions = field.options.map((op) => {
        if (op.id === null) {
          return {
            value: op.value,
            other: op.other,
          };
        }
        return op;
      });
      field.options = fieldOptions;
      if (field.id === '') {
        return { 
          name: field.name, 
          options: field.options,
          type: field.type,
        };
      }
      return field;
    });
    await PropertyGroupApi.updatePropertyGroup(propertyGroup.id, propertyGroupRequest).then(() => {
      setIsPropertyGroupModalVisible(false);
      createToast({
        children: translate<string>('PROPERTY_GROUP_UPDATED'),
        status: 'success',
        testID: 'property-group-updated-success-toast',
      });
      invalidatePropertyGroups();
      setPropertyGroup(null);
      setIsEdit(false);
    });
  };

  const createPropertyGroup = async (newPropertyGroup: FormEndpoint.Create.Request) => {
    await PropertyGroupApi.createPropertyGroup(newPropertyGroup).then(() => {
      setIsPropertyGroupModalVisible(false);
      createToast({
        children: translate<string>('PROPERTY_GROUP_CREATED'),
        status: 'success',
        testID: 'property-group-created-success-toast',
      });
      invalidatePropertyGroups();
      setPropertyGroup(null);
      setIsEdit(false);
    });
  };

  return (
    <>
      <VSpacer size="8" />
      {!!!propertyGroups?.length && EmptyResultSet}
      {!!propertyGroups?.length && PropertyGroupListHeader}
      {!!propertyGroups?.length && 
        <>
          <VSpacer size="6" />
          <PropertyGroupList
            onEdit={(form: ApiForm) => {
              setIsEdit(true);
              setPropertyGroup(form);
              setIsPropertyGroupModalVisible(true);
            }}
            propertyGroups={propertyGroups}
            setPropertyGroups={setPropertyGroups}
          />
        </>
      }
      {isPropertyGroupModalVisible && 
        <PropertyGroupModal
          data={propertyGroup}
          isEdit={isEdit}
          onCancel={() => {
            setIsPropertyGroupModalVisible(false);
            setPropertyGroup(null);
            setIsEdit(false);
          }}
          onDone={async (propertyGroupRequest: FormEndpoint.Create.Request | FormEndpoint.Update.Request) => {
            const apiFunction = isEdit ? updatePropertyGroup : createPropertyGroup;
            apiFunction(propertyGroupRequest);
          }}
        />
      }
    </>
  );
};
