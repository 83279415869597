import React from 'react';
import { ListItem } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';
import { StyledText } from './Text';
import { ThemeColors } from '../../../constants';

const styles = StyleSheet.create({ list: { backgroundColor: ThemeColors.DARK_GRAY } });

export const SearchableSelectItem = (props) => (
  <ListItem
    style={styles.list}
    {...props}
    title={() => <StyledText>{props.title}</StyledText>}
  />
);
