import * as _ from 'lodash';
import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import { testId } from '../../utilities/testId';
import { Sizing, ThemeColors } from '../../constants';
import { Icon, Dropdown } from '../../ui-components';

const styles = StyleSheet.create({
  tableRow: {
    borderBottomColor: ThemeColors.MID_GRAY,
    borderBottomWidth: 1,
    justifyContent: 'center',
  },
  tableColumns: {
    flexDirection: 'row',
    paddingHorizontal: Sizing.DOUBLE_SPACING,
    paddingVertical: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.DARKEST_GRAY,
  },
  tableColumnsInline: {
    paddingHorizontal: Sizing.HALF_SPACING,
    paddingVertical: Sizing.HALF_SPACING,
  },
  tableColumnsWithActions: { paddingVertical: Sizing.BASE_SPACING },
  tableBodyActions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableBodyChevron: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tableDrawer: {
    padding: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.DRAWER,
  },
  tableIcon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
});

export interface ISortableTableRowLiteProps {
  index?: number,
  key: string,
  columns: JSX.Element[],
  inlineInput?: boolean,
  actions?: {
    label: string,
    action: Function,
  }[];
  drawer?: JSX.Element,
  drawerProps?: ViewProps,
  drawerPadding?: number;
  dynamicDrawer?: boolean, // whether drawer contents can be dynamically updated
  expanded?: boolean,
  toggleExpanded?: (index: number) => void,
  widths?: number[],
  nativeID?: string,
  testID?: string,
  disableToggleOnRowPress?: boolean,
  includeDrawerToggleSpacing?: boolean,
}

export const SortableTableRowLite = (props: ISortableTableRowLiteProps) => {
  /**
   * Toggles expansion of the drawer
   */
  const toggleExpanded = () => {
    if (props.toggleExpanded) {
      props.toggleExpanded(props.index);
    }
  };

  // actions column denoted by presense of an actions array, even if it is empty
  // this would be where some rows have actions and others don't due to permission
  const hasActionColumn = !_.isNil(props.actions);
  // if this row doesn't have any actions, but other rows might, we need to reserve
  // space for the menu so that other columns line up.
  const hasActions = !_.isEmpty(props.actions);

  const hasDrawer = !!props.drawer;
  return (
    <TouchableOpacity
      onPress={() => props.drawer
        && !props.disableToggleOnRowPress
        && toggleExpanded()}
      style={styles.tableRow}
    >
      <View
        nativeID={props.nativeID}
        style={[
          styles.tableColumns,
          props.inlineInput && styles.tableColumnsInline,
          (hasActionColumn || hasDrawer)
            && !props.inlineInput && styles.tableColumnsWithActions,
        ]}
        testID={props.testID}
      >
        {hasDrawer && (
          <View
            style={[styles.tableBodyChevron, { flex: props.inlineInput ? 0.25 : 0.5 }]}
          >
            <TouchableOpacity onPress={toggleExpanded}>
              <Icon
                fill={ThemeColors.PRIMARY}
                name={props.expanded ? 'chevron-down-outline' : 'chevron-right-outline'}
                style={[styles.tableIcon, props.inlineInput && { marginTop: Sizing.HALF_SPACING }]}
                {...testId('row-chevron')}
              />
            </TouchableOpacity>
          </View>
        )}
        {props.columns.map((column, i) => (
          <View
            {...testId(`column-${i}`)}
            key={i} // eslint-disable-line react/no-array-index-key
            style={{ flex: props.widths[i] }}
          >
            {column}
          </View>
        ))}
        {hasActionColumn && (
          <View nativeID="options" style={[styles.tableBodyActions, { flex: 0.5 }]}>
            {hasActions && (
              <Dropdown actions={props.actions} icon="more-vertical-outline" nativeID="options" />
            )}
          </View>
        )}
      </View>
      {props.drawer && props.expanded
        && (
          <View
            {...props.drawerProps}
          >
            <View
              {...props.drawerProps}
              style={[
                styles.tableDrawer,
                !_.isNil(props.drawerPadding) ? { padding: props.drawerPadding } : {},
                props.drawerProps?.style,
              ]}
            >
              {props.drawer}
            </View>
          </View>
        )}
    </TouchableOpacity>
  );
};
