import { ApiCrop, CropEndpoint } from '@shared/interfaces/api';
import { Client } from './Client';

export class BusinessCropApi {
  static saveBusinessCrops (
    businessId: string,
    crops: ApiCrop[],
  ): Promise<CropEndpoint.Update.Response> {
    return Client(`businesses/${businessId}/crops`, {
      method: 'PUT',
      body: { crops },
    });
  }

  static getBusinessCrops (businessId: string): Promise<CropEndpoint.Get.Response> {
    return Client(`businesses/${businessId}/crops`);
  }
}
