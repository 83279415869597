import { ThemeStyleType } from '@eva-design/dss';
import * as eva from '@eva-design/eva';
import { GrowersIconsPack, GrowersThemes, GrowersThemeValue, mapping } from '@theme/GrowersThemes';
import { EvaExtensionIconsPack } from '@theme/icons/EvaExtensionIconPack';
import { IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { ApplicationProvider } from './ApplicationProvider';
import { BannerManagerPanel } from './Banner/BannerManagerPanel';
import { BannerPanel } from './Banner/BannerPanel';
import { ToastManagerPanel } from './Toast/ToastManagerPanel';
import { ToastPanel } from './Toast/ToastPanel';

interface IThemeContext {
  theme: GrowersThemeValue;
  toggleTheme: () => void;
  styles: ThemeStyleType;
}

const ThemeContext = React.createContext<IThemeContext>({
  theme: 'light',
  toggleTheme: () => {},
  styles: null,
});

export interface ThemeProviderProps {
  children: ReactNode,
  styles?: ThemeStyleType,
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  children,
  styles,
}) => {
  const [theme, setTheme] = React.useState<GrowersThemeValue>('dark');

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      const nextTheme = prevTheme === 'light' ? 'dark' : 'light';
      return nextTheme;
    });
  }, []);

  return (
    <>
      <IconRegistry icons={[EvaIconsPack, GrowersIconsPack, EvaExtensionIconsPack]} />
      <ThemeContext.Provider value={{ theme, toggleTheme, styles }}>
        <ApplicationProvider
          {...eva}
          customMapping={mapping}
          notificationLayer={(
            <>
              <ToastPanel />
              <ToastManagerPanel />
              <BannerPanel />
              <BannerManagerPanel />
            </>
          )}
          styles={styles}
          theme={GrowersThemes[theme]}
        >
          {children}
        </ApplicationProvider>
      </ThemeContext.Provider>
    </>
  );
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeToggle must be used within a ThemeProvider');
  }

  return context;
};
