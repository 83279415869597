import {
  Badge,
  Button,
  FilterCategory,
  FilterTable,
  Icon,
  MenuItem,
  OverflowMenu,
  SortDirection,
  Text,
  TextLink,
  useBanner,
} from '@design';
import { Selections } from '@design/Filter/Filter';
import {
  ApiTankMixListQuery,
  ApiTankMixListQuerySort,
  GrowerEndpoint,
  PaginatedData,
} from '@shared/interfaces/api';
import { ApiTankMix } from '@shared/interfaces/api/TankMixEndpoint';
import { Permissions, RoleUtility } from '@shared/utils';
import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  getTankMixDuplicateRoute,
  QueryKeys,
  Routes,
  Sizing,
} from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { GrowerApi, TankMixApi } from '../../../../utilities/api';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { ChangeTankMixStatusDialog } from './ChangeTankMixStatusDialog';
import { TankMixListTabRowDetails } from './TankMixListTabRowDetails';
import { maxListSize } from '@design/FilterMenu/FilterMenu';
import { useCustomerTotals } from '../../../../hooks/useCustomerTotals';

const defaultFilters = ['isActive', 'assignedGrowers', 'cropType', 'ownerBusinessUserId'];

const defaultPageOptions: ApiTankMixListQuery = {
  page: 0,
  sort: ApiTankMixListQuerySort.modified,
  sortDesc: true,
  isActive: ['true'],
};

const defaultAppliedFilters: Map<string, Selections> = new Map([
  ['isActive', { true: true }],
]);

const filterOptions: FilterCategory[] = [
  {
    columnLabel: 'Status',
    columnKey: 'isActive',
    columns: [{ label: 'Active', id: 'true' }, { label: 'Inactive', id: 'false' }],
    preserve: true,
  },
  {
    columnLabel: 'Customer',
    columnKey: 'assignedGrowers',
    columns: [],
    total: 0,
  },
  {
    columnLabel: 'Crop',
    columnKey: 'cropType',
    columns: [],
  },
  {
    columnLabel: 'User',
    columnKey: 'ownerBusinessUserId',
    columns: [],
  },
];

const sortColDictionary = {
  name: 'name',
};

const styles = StyleSheet.create({
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
  badgeAndKebabContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inUseBadge: {
    top: 11, 
    height: 20,
  },
});

export const TankMixListTab: FC = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const scrollRef = useRef<ScrollView>();
  const { user, currentBusinessId } = useAuthentication();
  const { createBanner } = useBanner();
  const [translate] = useTranslation(['tankMix', 'prepare', 'common']);
  const [tankMixes, setTankMixes] = useState<(ApiTankMix & RowMeta)[]>([]);
  const [pageOptions, setPageOptions] = useState<ApiTankMixListQuery>(defaultPageOptions);
  const [columnCategories, setColumnCategories] = useState<FilterCategory[]>([]);
  const [resultCount, setResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [error, setError] = useState(null);
  const [tankMixesLoaded, setTankMixesLoaded] = useState(false);
  const [changeTankMixStatus, setChangeTankMixStatus] = useState<ApiTankMix>(null);
  const isSuper = RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES);
  const [customerSearch, setCustomerSearch] = useState('');

  const getTankMixes = () => {
    const appliedFilters: ApiTankMixListQuery = { ...pageOptions };
    // when no isActive filters, default to display both active and inactive
    if (!appliedFilters.isActive) {
      appliedFilters.isActive = ['true', 'false'];
    }

    // Separate croptype and crop subtype
    if (appliedFilters.cropType) {
      const newCropSubTypeFilters = [];
      const newCropTypeFilters = [];
      appliedFilters.cropType.forEach((cropTypeString) => {
        const [cropType, subType] = cropTypeString.split(',');
        newCropTypeFilters.push(cropType);
        newCropSubTypeFilters.push(subType);
      });
      appliedFilters.cropSubType = newCropSubTypeFilters;
      appliedFilters.cropType = newCropTypeFilters;
    }

    appliedFilters.sort = pageOptions.sort;
    appliedFilters.sortDesc = pageOptions.sortDesc;

    return TankMixApi.listTankMix(
      isSuper
        ? { businessId: currentBusinessId, ...appliedFilters }
        : appliedFilters,
    );
  };

  const { isFetching } = useQuery<PaginatedData<ApiTankMix>, Error>(
    [QueryKeys.TANK_MIX_LIST, currentBusinessId, pageOptions], getTankMixes, {
      onError: (err) => {
        setPageOptions({ ...pageOptions, page: 0 });
        setError(translate('UNEXPECTED_ERROR', { error: err }));
      },
      enabled: !!currentBusinessId,
      onSuccess: (mixes) => {
        setError('');
        setTankMixes(mixes.data.map((tankMix, index) => ({
          ...tankMix,
          rowId: `row:${index}|${tankMix.id}|`,
          hasDetails: true,
        })));
        setPageCount(mixes.lastPage + 1);
        setResultCount(mixes.total);
      },
      onSettled: () => {
        setTankMixesLoaded(true);
      },
    },
  );

  const { customerTotals, isSuccess } = useCustomerTotals({
    isEnabled: !isFetching,
    onError: (err) => {
      setPageOptions({ ...pageOptions, page: 0 });
      setError(translate('UNEXPECTED_ERROR', { error: err }));
    },
  });

  const { data: customers, isFetching: areCustomersFetching } = useQuery(
    [QueryKeys.GROWER_LIST, currentBusinessId, customerSearch],
    async () => GrowerApi.getGrowers({
      businessId: currentBusinessId,
      limit: maxListSize,
      search: customerSearch,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }),
    {
      enabled: !isFetching && isSuccess,
      onError: (err) => {
        setPageOptions({ ...pageOptions, page: 0 });
        setError(translate('UNEXPECTED_ERROR', { error: err }));
      },
    },
  );

  /**   get crop type */
  const getAllTankMixes = async (): Promise<PaginatedData<ApiTankMix>> => (
    TankMixApi.listAllTankMix(currentBusinessId, [true, false])
  );

  useQuery<PaginatedData<ApiTankMix>, Error>(
    [QueryKeys.TANK_MIX_BUSINESS_CROPS, tankMixesLoaded, currentBusinessId],
    getAllTankMixes, {
      enabled: tankMixesLoaded && !areCustomersFetching,
      onError: (err) => {
        setColumnCategories(filterOptions);
        setError({ err, text: translate<string>('GETTING_PRODUCT_MIXES') });
      },
      onSuccess: (allTankMixes) => {
        if (!tankMixesLoaded || allTankMixes.data.length === 0) return;
        const newColumnCategories = _.cloneDeep(filterOptions);
        const userList = [];
        const newCropList = [];
        const uniqueCropTypes = new Set<string>();

        allTankMixes.data.forEach((tankMix) => {
          tankMix.crops.forEach((crop) => {
            const cropName = `${translate(crop.cropType)} | ${translate(crop.cropSubType)}`;

            if (!uniqueCropTypes.has(cropName)) {
              uniqueCropTypes.add(cropName);
              newCropList.push({
                label: cropName,
                id: `${crop.cropType},${crop.cropSubType}`,
              });
            }
          });
        });
        newCropList.sort((a, b) => a.label.localeCompare(b.label));
        const newTankMixUsers = allTankMixes.data.filter((tankMix) => {
          const uniqueUser = !userList.includes(tankMix.ownerBusinessUser?.fullName);
          if (uniqueUser) {
            userList.push(tankMix.ownerBusinessUser?.fullName);
          }
          return uniqueUser;
        }).map((tankMix) => ({
          label: tankMix.ownerBusinessUser?.fullName ?? '',
          id: tankMix.ownerBusinessUser?.id ?? '',
        }));

        newColumnCategories.find((category) => category.columnKey === 'cropType').columns = newCropList;
        newColumnCategories.find((category) => category.columnKey === 'ownerBusinessUserId').columns = newTankMixUsers;

        const customerFilter = newColumnCategories.find(({ columnKey }) => columnKey === 'assignedGrowers');
        const newCustomers = customers?.data.map((customer) => ({
          id: customer.id,
          label: customer.legalName,
        }));
        customerFilter.columns = newCustomers;
        customerFilter.total = customerTotals;
        customerFilter.onSearch = setCustomerSearch;

        setColumnCategories(newColumnCategories);
      },
    },
  );

  useEffect(() => {
    setColumnCategories(filterOptions);
    setPageOptions(defaultPageOptions);
    setTankMixesLoaded(false);
    queryClient.invalidateQueries([QueryKeys.GROWER_LIST]);
  }, [currentBusinessId, queryClient]);

  useEffect(() => {
    if (error) {
      createBanner({
        status: 'warning',
        children: (
          <View>
            <Text>{error}</Text>
          </View>
        ),
        testID: 'error-banner',
        actionAccessory: ({ dismissProps }) => (
          <View style={styles.banner}>
            <Button
              {...dismissProps}
              appearance="ghost"
              size="small"
              status="basic"
              testID="dismiss-button"
            >
              {`${translate('DISMISS')}`}
            </Button>
          </View>
        ),

      });
    }
  }, [createBanner, error, translate]);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, []);

  const handleSort = (column: string, direction: SortDirection) => {
    setPageOptions({
      ...pageOptions,
      page: 0,
      sort: sortColDictionary[column] ?? column,
      sortDesc: direction === 'DESC',
    });
  };

  const onChangeTankMixStatus = (updatedTankMix: ApiTankMix) => {
    setChangeTankMixStatus(null);
    setTankMixes((prev) => prev.map((tankMix) => {
      if (tankMix.id !== updatedTankMix.id) {
        return tankMix;
      }
      return {
        ...tankMix,
        ...updatedTankMix,
      };
    }));
  };

  const columns: IColumn<(ApiTankMix & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (tankMix) => (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => history.push(Routes.TANK_MIX_VIEW.replace(':id', tankMix.id))}
          wrap
        >
          {tankMix.name}
        </TextLink>
      ),
      flex: 2,
    },
    {
      columnId: 'cropType',
      header: {
        render: translate('CROP_TYPE'),
      },
      render: (tankMix) => (
        <Text
          category="p2"
          nativeID="tank-mix-crop-type"
        >
          {tankMix.crops?.map((c) => `${translate(c.cropSubType)}`).join(', ') ?? ''}
        </Text>
      ),
      flex: 2,
    },
    {
      columnId: 'ownerName',
      header: {
        render: translate('USER'),
      },
      render: (tankMix) => (
        <Text
          category="p2"
          nativeID="tank-mix-owner-business-user"
          style={{ marginRight: Sizing.BASE_SPACING }}
        >
          {tankMix.ownerBusinessUser?.fullName}
        </Text>
      ),
      flex: 1.9,
    },
    {
      columnId: 'menu',
      header: {
        render: null,
      },
      render: (tankMix) => {
        const isOwner = tankMix.ownerBusinessUserId === user.id;
        const canEditTankMix = (tankMix.isEditable && (
          RoleUtility.roleHasPermission(user.userRole, Permissions.MODIFY_OTHER_OWNER)
          || isOwner) && tankMix.isActive
        );

        const canDuplicateTankMix = tankMix.isActive && !isSuper;

        const hasEditPermission = (
          RoleUtility.roleHasPermission(user.userRole, Permissions.MODIFY_OTHER_OWNER)
          || isOwner
        );
        const canEditStatus = tankMix.isActive
          ? hasEditPermission
          : (hasEditPermission && tankMix.components.some((x) => x.product.isActive));

        if (!canEditTankMix && !canDuplicateTankMix && !canEditStatus) {
          return undefined;
        }

        return (
          <View style={styles.badgeAndKebabContainer}>
            {
              !tankMix.isEditable && 
              <Badge
                status="info"
                style={styles.inUseBadge} 
                testID={`in-use-badge-${tankMix.id}`}
              >
                {translate('IN_USE')}
              </Badge>
            }
            <OverflowMenu testID="tankmix-list-tab-overflow-menu">
              {canDuplicateTankMix ? (
                <MenuItem
                  onPress={() => history.push(getTankMixDuplicateRoute(tankMix.id))}
                  testID="duplicate-tank-mix"
                  title={`${translate('DUPLICATE')}`}
                />
              ) : null}
              {canEditStatus ? (
                <MenuItem
                  onPress={() => setChangeTankMixStatus(tankMix)}
                  testID="edit-tank-mix-status"
                  // TODO: Active/Deactivate Tank Mix Modal
                  title={`${translate(tankMix.isActive ? 'DEACTIVATE' : 'ACTIVATE')}`}
                />
              ) : null}
            </OverflowMenu>
          </View>
        );
      },
      flex: 0.3,
    },
  ];

  return (
    <ScrollView key={currentBusinessId} ref={scrollRef}>
      <FilterTable
        accessoryRight={
          (
            <Button
              accessoryLeft={(iconProps) => (
                <Icon name="Plus" testID="create-tank-mix-button-icon" {...iconProps} />
              )}
              appearance="filled"
              onPress={() => history.push(Routes.TANK_MIX_CREATE)}
              size="medium"
              status="primary"
              testID="create-tank-mix-button"
            >
              {translate('CREATE_PRODUCT_MIX') as string}
            </Button>
          )
        }
        columns={columns}
        currentPage={pageOptions.page + 1}
        data={tankMixes}
        defaultColumnFilters={defaultFilters}
        defaultFilters={defaultAppliedFilters}
        filterOptions={columnCategories}
        isLoading={isFetching}
        noFilters={false}
        onPageChange={(page: number) => {
          setPageOptions({ ...pageOptions, page: page - 1 });
        }}
        onSort={handleSort}
        onUpdateFilter={(filter) => {
          setPageOptions({ ...filter, page: 0 });
        }}
        rowDetail={(rowDetail) => <TankMixListTabRowDetails {...rowDetail} />}
        tableId="tankMix"
        tableRowId="tankMixListTable"
        testID="tankMix"
        totalPages={pageCount}
        totalResults={resultCount}
      />
      <ChangeTankMixStatusDialog
        onCancel={() => setChangeTankMixStatus(null)}
        onTankMixUpdated={onChangeTankMixStatus}
        tankMix={changeTankMixStatus}
      />
    </ScrollView>
  );
};
