import { Button, LargeModal, Text, VSpacer } from '@design';
import React, { useState } from 'react';
import { View } from 'react-native';
import { ButtonBar } from '../../../components/shared/ButtonBar';

const pages = [(
  <View style={{ alignItems: 'center',  overflow: 'scroll' }}>
    <View style={{ width: 433 }}>
      <Text>
        Heave to Yellow Jack gaff clipper broadside tender cutlass measured fer yer chains coffer square-rigged. Topgallant chase guns belay salmagundi cog fore barque careen haul wind lass. Barque landlubber or just lubber carouser chantey crow's nest draft aye pressgang clap of thunder list.
      </Text>
      <Text>
        Draught reef Sail ho Barbary Coast Jolly Roger swing the lead yawl parley no prey, no pay run a shot across the bow. Sea Legs hempen halter keel swab snow plunder pink flogging lookout lass. Brigantine bounty crack Jennys tea cup aft starboard Arr loaded to the gunwalls Corsair gally main sheet.
      </Text>
      <Text>
        Coxswain man-of-war topsail black jack topgallant Nelsons folly swing the lead quarterdeck tackle killick. Jack Ketch heave to jolly boat gally to go on account cutlass belaying pin Admiral of the Black keel spanker. Grog belay long clothes yard scourge of the seven seas crack Jennys tea cup hempen halter mutiny to go on account draft.
      </Text>
      <VSpacer size="4" />
      <Text>
        Heave to Yellow Jack gaff clipper broadside tender cutlass measured fer yer chains coffer square-rigged. Topgallant chase guns belay salmagundi cog fore barque careen haul wind lass. Barque landlubber or just lubber carouser chantey crow's nest draft aye pressgang clap of thunder list.
      </Text>
      <Text>
        Draught reef Sail ho Barbary Coast Jolly Roger swing the lead yawl parley no prey, no pay run a shot across the bow. Sea Legs hempen halter keel swab snow plunder pink flogging lookout lass. Brigantine bounty crack Jennys tea cup aft starboard Arr loaded to the gunwalls Corsair gally main sheet.
      </Text>
      <Text>
        Coxswain man-of-war topsail black jack topgallant Nelsons folly swing the lead quarterdeck tackle killick. Jack Ketch heave to jolly boat gally to go on account cutlass belaying pin Admiral of the Black keel spanker. Grog belay long clothes yard scourge of the seven seas crack Jennys tea cup hempen halter mutiny to go on account draft.
      </Text>
      <VSpacer size="4" />
      <Text>
        The following button should be clickable when position on the same vertical row as the button bar.
      </Text>
      <Button
        size='small'
        style={{ width: 320 }}
        testID='button-obscured-test'
      >
        <Text style={{ color: '#000' }}>Clickable Button</Text>
      </Button>
    </View>
  </View>
)];

export const ButtonBarDemo = () => {
  const [isDemoSpacedModalVisible, setIsDemoSpacedModalVisible] = useState(false);
  const [isDemoGroupedModalVisible, setIsDemoGroupedModalVisible] = useState(false);

  return (
    <>
      <View style={{ marginTop: 32, width: 433 }}>
        <Button
          onPress={() => { setIsDemoSpacedModalVisible(!isDemoSpacedModalVisible); }}
          size='small'
          style={{ width: 320 }}
          testID='button-test'
        >
          <Text style={{ color: '#000' }}>Show Spaced Button Bar</Text>
        </Button>
        <VSpacer size="6" />
        <Button
          onPress={() => { setIsDemoGroupedModalVisible(!isDemoGroupedModalVisible); }}
          size='small'
          style={{ width: 320 }}
          testID='button-test'
        >
          <Text style={{ color: '#000' }}>Show Grouped Button Bar</Text>
        </Button>
      </View>
      <LargeModal
        footer={(
          <ButtonBar
            buttonBarType="spaced"
            leftAction={() => setIsDemoSpacedModalVisible(!isDemoSpacedModalVisible)}
            leftButtonText={'left button'}
            rightAction={() => setIsDemoSpacedModalVisible(!isDemoSpacedModalVisible)}
            rightButtonText={'right button'}
            size="giant"
            testID="test-button-bar"
          />
        )}
        pages={pages}
        testID='largeModal-test'
        title='Button Bar Test'
        visible={isDemoSpacedModalVisible}
      />
      <LargeModal
        footer={(
          <ButtonBar
            buttonBarType="grouped"
            leftAction={() => setIsDemoGroupedModalVisible(!isDemoGroupedModalVisible)}
            leftButtonText={'left button'}
            rightAction={() => setIsDemoGroupedModalVisible(!isDemoGroupedModalVisible)}
            rightButtonText={'right button'}
            size="giant"
            testID="test-button-bar"
          />
        )}
        pages={pages}
        testID='largeModal-test'
        title='Button Bar Test'
        visible={isDemoGroupedModalVisible}
      />
    </>
  );
};
