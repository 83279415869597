import { Button } from '@design';
import { DefaultTags, DeliverableType } from '@shared/enums';
import { ApiDeliverable, ApiGrowerContact, GrowerContactEndpoint } from '@shared/interfaces/api';
import React, { useMemo, useState } from 'react';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import {
  ShareDeliverableModal,
} from '../../../components/shared/ShareDeliverableModal/ShareDeliverableModal';
import { DemoContainer } from '../components/DemoBlock';

const getRandomTags = (): DefaultTags[] => {
  const tagsArray = Object.values(DefaultTags);
  const randomLength = Math.floor(Math.random() * 6);
  const randomTags = new Set<DefaultTags>();

  while (randomTags.size < randomLength) {
    const index = Math.floor(Math.random() * tagsArray.length) % tagsArray.length;
    randomTags.add(tagsArray[index]);
  }

  return Array.from(randomTags);
};

const generateContact = (idx: number): ApiGrowerContact => ({
  address1: '123 Sesame St',
  address2: '',
  businessId: '',
  city: 'City',
  country: 'US',
  createdAt: new Date(),
  email: `test${idx}@email.com`,
  firstName: 'Farmer',
  growerId: `growerId-${idx}`,
  id: `fake-${idx}`,
  isActive: true,
  isPrimary: idx === 1,
  lastName: `Johnson ${idx}`,
  postalCode: '90210',
  state: 'North Carolina',
  tags: getRandomTags(),
  telephone: '9105551212',
  updatedAt: new Date(),
});

export const ShareDeliverableModalDemo = () => {
  const [showModal, setShowModal] = useState(false);

  const { currentBusinessId } = useAuthentication();

  const demoDeliverable = useMemo<ApiDeliverable>(() => {
    return {
      createdAt: new Date(),
      isActive: true,
      updatedAt: new Date(),
      businessId: currentBusinessId,
      createdByName: 'test',
      deliverableData: {},
      deliverableType: DeliverableType.PRODUCT_ORDER,
      deliverableVersion: 0,
      generationDate: new Date(),
      growerId: null,
      id: '1',
      name: 'Test Deliverable',
      productOrderId: '1',
      publicId: '1',
      selections: {},
      status: '',
      userAccountId: 'user1',
    };
  }, [currentBusinessId]);

  const fakeContacts: GrowerContactEndpoint.List.Response = {
    data: Array.from({ length: 5 }, (_, idx) => generateContact(idx + 1)),
    lastPage: 0,
    page: 0,
    total: 5,
  };

  return (
    <DemoContainer>
      <Button onPress={() => setShowModal(true)} size="large" testID="test-button">
        Show
      </Button>
      {showModal && (
        <ShareDeliverableModal
          customerId={'fake'}
          deliverable={demoDeliverable}
          onClose={async () => setShowModal(false)}
          uxSandboxContacts={fakeContacts}
        />
      )}
    </DemoContainer>
  );
};
