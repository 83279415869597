import React, { FC, ReactElement, ReactNode, ReactText, useEffect, useState } from 'react';
import { ImageSourcePropType, ScrollView, StyleSheet, TextProps, View } from 'react-native';
import {
  EvaProp,
  ModalProps as UIKModalProps,
  StyleType,
} from '@ui-kitten/components';
import { FalsyFC, FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { styled } from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Logo } from '@design/Logo/Logo';
import { PoweredByGrowersLogo } from '../../../../svg/PoweredByGrowersLogo';
import { ButtonProps } from '../Button/Button';
import { BannerAwareModal } from '../BannerAwareModal/BannerAwareModal';
import { ResponsiveView } from '../SimpleLayout/ResponsiveView';
import HeadedStepIndicator from '../../shared/HeadedStepIndicator/HeadedStepIndicator';
import { VSpacer } from '@design/Spacer/VSpacer';
import ClientDimensionUtility from '../../../../utilities/ClientDimension';

interface LogoProps {
  image: ImageSourcePropType,
  error: Error,
  isLoading: boolean
}

interface LargeModalProps extends UIKModalProps {
  blurIntensity?: number,
  blurTint?: string,
  containerStyle?: StyleType,
  currentPage?: number,
  eva?: EvaProp,
  footer?: RenderProp<Partial<ReactElement>>,
  headerButton?: RenderProp<Partial<ButtonProps>>,
  logo?: LogoProps,
  pages: ReactNode[],
  sectionHeading?: string,
  sectionSubHeading?: string,
  showSteps?: boolean,
  subTitle?: string,
  testID: string,
  title?: ReactText | RenderProp<TextProps>,
  titleOverline?: string,
  visible: boolean,
}

const getComponentStyle = (source: StyleType) => {
  const titleStyles = PropsServiceHelper.allWithPrefixMapped(source, 'title');
  const sectionSubHeadingStyles = PropsServiceHelper.allWithPrefixMapped(source, 'sectionSubHeading');
  const sectionHeadingStyles = PropsServiceHelper.allWithPrefixMapped(source, 'sectionHeading');
  const scrollViewStyles = PropsServiceHelper.allWithPrefixMapped(source, 'scrollView');
  const buttonStyles = PropsServiceHelper.allWithPrefixMapped(source, 'button');
  const headerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'header');
  const surfaceStyles = PropsServiceHelper.allWithPrefixMapped(source, 'surface');
  const pagingContainerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'pagingContainer');
  const headerLogoStyles = PropsServiceHelper.allWithPrefixMapped(source, 'headerLogo');
  const logoDividerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'logoDivider');

  const { elevation } = source;

  return {
    title: titleStyles,
    sectionSubHeading: sectionSubHeadingStyles,
    sectionHeading: sectionHeadingStyles,
    button: buttonStyles,
    header: headerStyles,
    scrollView: scrollViewStyles,
    pagingContainer: pagingContainerStyles,
    headerLogo: headerLogoStyles,
    logoDivider: logoDividerStyles,
    surface: {
      ...surfaceStyles,
      ...elevation,
    },
  };
};

const dimensions = ClientDimensionUtility.get();

const LargeModalRaw: FC<LargeModalProps> = ({
  blurIntensity: propBlurIntensity,
  blurTint: propBlurTint,
  children,
  containerStyle,
  currentPage = 0,
  eva,
  footer,
  headerButton,
  logo,
  pages,
  sectionHeading,
  sectionSubHeading,
  showSteps = true,
  style,
  subTitle,
  testID,
  title,
  titleOverline,
  visible,
  ...viewProps
}: LargeModalProps) => {
  const evaStyle = getComponentStyle(eva.style);
  const styles = StyleSheet.create({
    headerButtons: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    responsiveContainer: {
      marginLeft: 0,
      height: '96%',
      width: '96%', 
    }, 
    contentContainer: { 
      overflow: 'scroll', 
      height: dimensions.initial.y - 180,
      width: '100%',
    },
  });
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    setShowContent(false);
    setTimeout(() => {
      // HACK: This forces the contents of the modal to be re-rendered so that the dimensions
      // are correctly set after switching pages.
      setShowContent(true);
    }, 50);
  }, [currentPage]);

  return (
    <BannerAwareModal
      testID={testID}
      visible={visible}
      {...viewProps}
    >
        <ResponsiveView
          small={8}
          style={[evaStyle.surface, styles.responsiveContainer]}
          xLarge={12}
          xSmall={4}
        >
          <ScrollView
            showsHorizontalScrollIndicator={false}
            style={styles.contentContainer}
            testID="large-modal-scroll"
          >
            {
              logo !== undefined && !logo?.error && !logo?.isLoading && (
              <View style={[evaStyle.header, evaStyle.headerLogo]}>
                {
                  !!logo.image && (
                    <>
                      <Logo
                        padding={0}
                        shape="square"
                        size="large-rectangle"
                        source={logo.image}
                        testID="large-modal-logo"
                      />
                      <View style={evaStyle.logoDivider} />
                    </>
                  )
                }
                <PoweredByGrowersLogo />
              </View>
              )
            }
            {!!(title || headerButton) && (
              <>
                <View style={evaStyle.header}>
                  <View style={{ flexDirection: 'column' }}>
                    <FalsyText
                      category="overline"
                      component={titleOverline}
                      testID="large-modal-overline"
                    />
                    <FalsyText
                      category="h5"
                      component={title}
                      style={[evaStyle.title, { flex: 1, paddingVertical: 8 }]}
                      testID="large-modal-title"
                    />
                    <FalsyText
                      appearance="hint"
                      category="p2"
                      component={subTitle}
                      testID="large-modal-subTitle"
                    />
                  </View>
                  <View style={styles.headerButtons}>
                    <FalsyFC
                      component={headerButton}
                      style={evaStyle.button}
                      testID="large-modal-header-button"
                    />
                  </View>
                </View>
              </>
            )}
            {showSteps && (
              <View style={[evaStyle.pagingContainer]}>
                {pages.length > 1 && (
                  <HeadedStepIndicator
                    currentPage={currentPage}
                    data={pages}
                    direction="horizontal"
                    heading={sectionHeading}
                    size="small"
                    subTitle={sectionSubHeading}
                    testID="large-modal-step-indicator"
                  />
                )}
              </View>
            )}
            {showContent && (
              <View testID="large-modal-body">
                {pages[currentPage]}
              </View>
            )}
          </ScrollView>
          <FalsyFC component={footer} />
          <VSpacer size="10" />
        </ResponsiveView>
    </BannerAwareModal>
  );
};

export const LargeModal: FC<LargeModalProps> = styled('LargeModal')(LargeModalRaw);
