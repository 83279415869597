import {
  Button,
  Filter,
  Icon,
  MenuItem,
  OverflowMenu,
  Pagination,
  SortDirection,
  TextLink,
  useToast,
  VSpacer,
} from '@design';
import { PlanStatus } from '@shared/enums';
import { ApiFarmPlan, ApiProductSummary, FarmPlanEndpoint } from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys, Routes } from '../../../../constants';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useHistory, useParams } from '../../../../router';
import { StringUtility } from '../../../../utilities';
import { GrowerFarmPlanApi, UserApi } from '../../../../utilities/api';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';
import { FarmPlanDetails } from './FarmPlanDetails';

const defaultPageOptions: FarmPlanEndpoint.List.Query = {
  page: 0,
  sort: FarmPlanEndpoint.List.FarmPlanSort.updatedAt,
  sortDesc: true,
};

const sortColDictionary = {
  name: 'planName',
};

export const FarmPlanTab = () => {
  const [translate] = useTranslation(['common', 'farmPlans', 'prepare']);
  const { grower: customerId } = useParams<{ grower: string }>();
  const history = useHistory();
  const scrollRef = useRef<ScrollView>();
  const { user } = useAuthentication();
  const { createToast } = useToast();
  const { setModalProps } = useAppContext();
  const [farmPlans, setFarmPlans] = useState<(ApiFarmPlan & RowMeta)[]>([]);
  const [pageOptions, setPageOptions] = useState<FarmPlanEndpoint.List.Query>(defaultPageOptions);

  const userGreaterThanBizStandard = RoleUtility.roleHasPermission(
    user.userRole, Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const isSuperUser = RoleUtility.roleHasPermission(
    user.userRole, Permissions.ACCESS_ALL_BUSINESSES,
  );

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, [scrollRef]);

  const onError = (error: Error) => {
    createToast({
      status: 'warning',
      children: translate<string>('UNEXPECTED_ERROR', { error }),
      testID: 'toast-content-element',
    });
    setPageOptions({ ...pageOptions, page: 0 });
  };

  const generateDeliverable = (farmPlan: ApiFarmPlan) => {
    setModalProps({
      farmPlanId: farmPlan.id,
      showNoteCheckbox: !!farmPlan.note,
      type: 'createDeliverableFarmPlan',
    });
  };

  const {
    data: growerAssignments,
    isFetching: isGrowerAssignmentsFetching,
    isError: isGrowerAssignmentsError,
  } = useQuery(
    [QueryKeys.GROWER_ASSIGNMENTS, customerId, pageOptions],
    () => UserApi.getGrowerAssignments(),
    { onError },
  );

  const {
    data: farmPlanListResponse,
    isFetching: isFetchingFarmPlans,
  } = useQuery(
    [QueryKeys.FARM_PLAN_LIST, pageOptions],
    () => GrowerFarmPlanApi.listFarmPlans({
      ...pageOptions,
      growerId: [customerId],
    }),
    {
      keepPreviousData: true,
      enabled: isGrowerAssignmentsFetching && !isGrowerAssignmentsError,
      onError,
      onSuccess: (farmPlansData) => setFarmPlans(
        farmPlansData.data.map((farmPlan, idx) => ({
          ...farmPlan,
          hasDetails: true,
          rowId: `row:${idx}|${farmPlan.id}|`,
        })),
      ),
    },
  );

  const handleSort = (column: string, direction: SortDirection) => {
    setPageOptions({
      ...pageOptions,
      sort: sortColDictionary[column] ?? column,
      sortDesc: direction === 'DESC',
      page: 0,
    });
  };

  const handlePageChange = (page: number) => {
    setPageOptions({ ...pageOptions, page: page - 1 });
  };

  // does this set of summaries have any accepted orders in it?
  const hasAcceptedOrders = (summaries: ApiProductSummary[] = []) => (
    summaries?.some((summary) => summary.planStatus === PlanStatus.ACCEPTED)
  );

  const columns: IColumn<(ApiFarmPlan & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (farmPlan) => (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => {
            if (!hasAcceptedOrders(farmPlan.growerFarmPlanProductSummaries)) {
              history.push(
                Routes.FARM_PLAN_EDIT_FROM_GROWER
                  .replace(/:grower/, farmPlan.growerId)
                  .replace(/:id/, farmPlan.id),
              );
            } else {
              history.push(
                Routes.FARM_PLAN_OVERVIEW
                  .replace(/:grower/, farmPlan.growerId)
                  .replace(/:id/, farmPlan.id),
              );
            }
          }}
          wrap
        >
          {farmPlan.planName}
        </TextLink>
      ),
      flex: 3,
    },
    {
      columnId: 'grower',
      header: {
        render: translate('CUSTOMER'),
      },
      render: (farmPlan) => farmPlan.grower.legalName,
      flex: 3,
    },
    {
      columnId: 'acresPlanned',
      header: {
        render: translate('ACRES_PLANNED'),
      },
      render: (farmPlan) => StringUtility.localizeNumber(farmPlan.acresPlanned, 3),
      flex: 3,
    },
    userGreaterThanBizStandard && {
      columnId: 'userAccountId',
      header: {
        render: translate('SALESPERSON'),
      },
      render: (farmPlan) => farmPlan.userAccount?.fullName ?? '',
      flex: 3,
    },
    {
      columnId: 'menu',
      header: { render: null },
      render: (farmPlan) => {
        const canModifyOtherOwner = RoleUtility.roleHasPermission(
          user.userRole,
          Permissions.MODIFY_OTHER_OWNER,
        );
        const userCanEditFarmPlans = canModifyOtherOwner
          || growerAssignments?.includes(farmPlan.growerId);
        return userCanEditFarmPlans ? (
          <OverflowMenu testID="fp-tab-overflow-menu">
            {!hasAcceptedOrders(farmPlan.growerFarmPlanProductSummaries) && (
              <MenuItem
                onPress={() => {
                  history.push(
                    Routes.FARM_PLAN_EDIT_FROM_GROWER
                      .replace(/:grower/, farmPlan.growerId)
                      .replace(/:id/, farmPlan.id),
                  );
                }}
                testID="edit-option"
                title={translate<string>('EDIT')}
              />
            )}
            <MenuItem
              onPress={() => {
                history.push(
                  Routes.FARM_PLAN_OVERVIEW
                    .replace(/:grower/, farmPlan.growerId)
                    .replace(/:id/, farmPlan.id),
                );
              }}
              testID="order-details"
              title={translate<string>('ORDER_DETAILS')}
            />
            {!isSuperUser && (
              <MenuItem
                onPress={() => {
                  generateDeliverable(farmPlan);
                }}
                testID="create-deliverable"
                title={translate<string>('CREATE_DELIVERABLE_SENTENCE')}
              />
            )}
          </OverflowMenu>
        ) : null;
      },
      width: 72,
    },
  ];

  const totalPages = (farmPlanListResponse?.lastPage ?? 0) + 1;

  return (
    <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false}>
      <Filter
        accessoryRight={() => (
          <Button
            accessoryLeft={(iconProps) => (
              <Icon name="Plus" testID="create-farm-plan-icon" {...iconProps} />
            )}
            appearance="filled"
            onPress={() => history.push(
              Routes.FARM_PLAN_CREATE_FROM_GROWER.replace(/:grower/, customerId),
            )}
            size="medium"
            status="primary"
            testID="create-farm-plan"
          >
            {translate<string>('CREATE_FARM_PLAN')}
          </Button>
        )}
        defaultColumnFilters={[]}
        filterOptions={[]}
        noFilters
        onUpdateFilter={(filter) => setPageOptions({
          ...filter,
          ...defaultPageOptions,
        })}
        testID="farm-plan-tab-filter"
        totalResults={isFetchingFarmPlans ? undefined : (farmPlanListResponse?.total ?? 0)}
        totalResultsText={translate(
          'FARM_PLANS_COUNT',
          { count: farmPlanListResponse?.total ?? 0 },
        )}
      />
      <VSpacer size="5" />
      <View>
        <SortableTable
          columns={columns}
          data={farmPlans}
          isLoading={isFetchingFarmPlans}
          onSort={handleSort}
          rowDetail={(rowDetail) => <FarmPlanDetails farmPlan={rowDetail} />}
          testID="farm-plan-table"
        />
        {totalPages > 1 && (
          <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
            <VSpacer size="5" />
            <Pagination
              currentPage={pageOptions.page + 1}
              displayPages={9}
              onChangePage={handlePageChange}
              totalPages={totalPages}
            />
          </View>
        )}
      </View>
    </ScrollView>
  );
};
