import { ChildrenWithProps } from '@ui-kitten/components/devsupport';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { DescriptionListItemProps } from './DescriptionListItem';

export interface DescriptionListProps {
  direction?: DescriptionListDirection,
  children: ChildrenWithProps<DescriptionListItemProps>,
}

export enum DescriptionListDirection {
  INLINE = 'INLINE',
  STACKED = 'STACKED',
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  inline: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  stacked: {
    flexDirection: 'column',
  },
  item: {
    flexDirection: 'row',
    flex: 1,
  },
  inlineKeyValue: {
    flexWrap: 'wrap',
    paddingRight: 24,
  },
  stackedKeyValue: {
    paddingBottom: 8,
  },
});

const DescriptionList: FC<DescriptionListProps> = ({
  children,
  direction = DescriptionListDirection.INLINE,
}) => {
  const containerDirectionStyle = direction === DescriptionListDirection.INLINE
    ? styles.inline : styles.stacked;

  const keyValueDirectionStyle = direction === DescriptionListDirection.INLINE
    ? styles.inlineKeyValue : styles.stackedKeyValue;

  return (
    <View style={[styles.container, containerDirectionStyle]}>
      {React.Children.toArray(children).map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={index} style={[styles.item, keyValueDirectionStyle]}>
          {item}
        </View>
      ))}
    </View>
  );
};

export default DescriptionList;
