import { ApiDeliverable, ApiDeliverablePublic } from '@shared/interfaces/api';
import { DeliverableRenderer } from '../DeliverableRenderer';
import { FarmPlanDeliverableV0PublicRender } from './v0/FarmPlanDeliverableV0PublicRender';
import { FarmPlanDeliverableV0Render } from './v0/FarmPlanDeliverableV0Render';
import { FarmPlanDeliverableV1PublicRender } from './v1/FarmPlanDeliverableV1PublicRender';
import { FarmPlanDeliverableV1Render } from './v1/FarmPlanDeliverableV1Render';
import { FarmPlanDeliverableV2PublicRender } from './v2/FarmPlanDeliverableV2PublicRender';
import { FarmPlanDeliverableV2Render } from './v2/FarmPlanDeliverableV2Render';

export class FarmPlanDeliverableRenderFactory {
  static getRenderer = (deliverable: ApiDeliverable): DeliverableRenderer => {
    switch (deliverable.deliverableVersion) {
      case 0:
        return new FarmPlanDeliverableV0Render(deliverable);
      case 1:
        return new FarmPlanDeliverableV1Render(deliverable);
      case 2:
        return new FarmPlanDeliverableV2Render(deliverable);
      default:
        throw new Error(`Renderer not found for ${deliverable.deliverableType} version ${deliverable.deliverableVersion}`);
    }
  };

  static getPublicRenderer = (deliverable: ApiDeliverablePublic) : DeliverableRenderer => {
    switch (deliverable.deliverableVersion) {
      case 0:
        return new FarmPlanDeliverableV0PublicRender(deliverable);
      case 1:
        return new FarmPlanDeliverableV1PublicRender(deliverable);
      case 2:
        return new FarmPlanDeliverableV2PublicRender(deliverable);
      default:
        throw new Error(`Renderer not found for ${deliverable.deliverableType} version ${deliverable.deliverableVersion}`);
    }
  };
}
