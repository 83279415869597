import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ViewProps } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { Button, HSpacer, Icon, Modal, useToast, Text, VSpacer, CenteredSpinner } from '@design';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { ErrorCode } from '@shared/enums/ErrorCodes';
import { DetailedApiError } from '../../../../utilities/api/DetailedApiError';

const styles = StyleService.create({
  flexCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
  },
});

interface UploadResult {
  inserted?: number;
  updated?: number;
  errors?: any;
}

interface UploadModalProps extends ViewProps {
  children?: JSX.Element,
  hideCloseButton?: boolean,
  mimeType?: string,
  onCancel: () => void,
  onDone: () => void,
  onFileUpload: (fileInfo: DocumentPicker.DocumentResult) => Promise<UploadResult>,
  showTitle?: boolean,
}

export const UploadModal = ({
  children,
  hideCloseButton = false,
  mimeType = 'text/csv',
  onCancel,
  onDone,
  onFileUpload,
  showTitle = true,
}: UploadModalProps) => {
  const [translate] = useTranslation(['errors', 'common']);
  const { createToast } = useToast();
  const evaStyles = useStyleSheet(styles);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [errors, setErrors] = useState(null);
  const [inserted, setInserted] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [hasResults, setHasResults] = useState(false);

  const handleUpload = async () => {
    setApiError(null);
    setErrors(null);
    setInserted(null);
    setUpdated(null);
    setLoading(true);
    try {
      const fileInfo: DocumentPicker.DocumentResult = await DocumentPicker
        .getDocumentAsync({ type: mimeType });
      const result: UploadResult = await onFileUpload(fileInfo);
      setInserted(result.inserted);
      setUpdated(result.updated);
      onDone();
    } catch (err) {
      if (err instanceof DetailedApiError) {
        if (err.code) {
          setApiError(err.message);
        }
        if (err.details.errors) {
          setErrors(err.details.errors);
        }
      } else {
        setApiError(translate('UNEXPECTED_ERROR'));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (updated !== null && inserted !== null) {
      setHasResults(true);
    }
    if (apiError) {
      createToast({
        status: 'warning',
        children: apiError,
        testID: 'toast-content-element',
      });
    }
  }, [apiError, createToast, inserted, updated]);

  useEffect(() => {
    if (!errors && hasResults) {
      createToast({
        status: 'success',
        children: (
          <View>
            <Text>{`${translate('CSV_SUCCESS')}`}</Text>
            <Text>
              {`${translate(inserted === 1 ? 'ROW_INSERTED'
                : 'ROWS_INSERTED', { count: inserted })} | `
                + `${translate(updated === 1 ? 'ROW_UPDATED'
                  : 'ROWS_UPDATED', { count: updated })}`}
            </Text>
          </View>
        ),
        testID: 'toast-content-element',
      });
      onCancel();
    }
  }, [createToast, errors, hasResults, inserted, onCancel, translate, updated]);

  const getTitle = () => {
    if (!showTitle) return null;
    if (errors) {
      return translate('ERROR');
    }
    return translate('UPLOAD');
  };

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            appearance="outline"
            onPress={onCancel}
            testID="cancel-button"
          >
            {`${translate('CANCEL')}`}
          </Button>
          <HSpacer {...spacerProp} />
          {hasResults ? (
            <Button
              {...secondaryButtonProp}
              onPress={onDone}
              testID="done-button"
            >
              {`${translate('DONE')}`}
            </Button>
          ) : (
            <Button
              {...secondaryButtonProp}
              appearance={errors ? 'outline' : 'filled'}
              disabled={loading}
              onPress={handleUpload}
              status={errors ? 'danger' : 'primary'}
              testID="upload-button"
            >
              {`${translate(errors ? 'TRY_AGAIN' : 'UPLOAD')}`}
            </Button>
          )}
        </>
      )}
      hideCloseButton={hideCloseButton}
      onClose={onCancel}
      scrollableContent
      showScrollIndicator={false}
      testID="upload-modal"
      title={getTitle()}
      visible
    >
      {loading && <CenteredSpinner isFixed />}
      {errors ? (
        <Text>{`${translate('CSV_UPLOAD_FAILED')}`}</Text>
      ) : children}
      {errors && (
        <View>
          <VSpacer size="5" />
          <View style={evaStyles.flexLeft}>
            <Icon name="AlertCircle" status="danger" testID="error-icon" />
            <HSpacer size="2" />
            <Text category="s1">
              {`${translate(errors === 1 ? 'ROW_FAILED'
                : 'ROWS_FAILED', { count: errors.length })}`}
            </Text>
          </View>
          <VSpacer size="5" />
          <View>
            {errors.map((rowErrors) => rowErrors.errors.map((error) => {
              let errorText = translate('UNKNOWN_ERROR', { row: rowErrors.row + 1 });
              const errorCode = Object
                .keys(ErrorCode)
                .find((key) => ErrorCode[key] === error.code);
              if (errorCode) {
                errorText = translate(errorCode, {
                  row: rowErrors.row + 1,
                  field: error.field,
                  value: error.value,
                });
              }
              return (
                <View key={errorText} style={evaStyles.flexLeft}>
                  <Text>{errorText}</Text>
                </View>
              );
            }))}
          </View>
        </View>
      )}
    </Modal>
  );
};
