import React from 'react';
import {
  Pie,
  PieChart,
  Cell,
  Tooltip,
} from 'recharts';
import { View } from 'react-native';
import { EmptyState, Text } from '@design';
import { useTranslation } from 'react-i18next';
import { ApiCompanyRevenueByProductCategory } from '@shared/interfaces/api/StatisticsEndpoint';
import { StringUtility, testId } from '../../../../utilities';
import { CHART_COLORS, CHART_STYLE, ChartContainer, LegendLabel } from './helpers';

export const RevenueByProductCategoryDonut = ({
  seed,
  fertilizer,
  chemical,
  other,
  total,
  totalAfterDiscounts,
  orderDiscounts,
}: ApiCompanyRevenueByProductCategory) => {
  const [translate] = useTranslation(['dashboard', 'products', 'common']);
  const chartData = [
    { name: translate('SEED'), amount: seed, color: CHART_COLORS[0] },
    { name: translate('FERTILIZER'), amount: fertilizer, color: CHART_COLORS[1] },
    { name: translate('CHEMICAL'), amount: chemical, color: CHART_COLORS[2] },
    { name: translate('OTHER_PRODUCTS'), amount: other, color: CHART_COLORS[3] },
  ];

  const totalLabel = !total ? '' : translate('TOTAL_COMPANY_REVENUE', {
    total: StringUtility.formatFlatCurrencyShortBasic(total),
  });

  // at the moment we will not show the discount amount to the user
  // const discountLabel = translate(
  //   'TOTAL_AFTER_DISCOUNTS',
  //   {
  //     total: StringUtility.formatFlatCurrencyShortBasic(totalAfterDiscounts),
  //     discounts: StringUtility.formatFlatCurrencyShortBasic(orderDiscounts),
  //   },
  // );

  const discountLabel = (!orderDiscounts && !totalAfterDiscounts)
    ? '' : translate('ORDER_DISCOUNT_MESSAGE');

  const textColor = '#212121';

  const CustomTooltip = (tooltipProps) => {
    if (tooltipProps.payload.length === 0 || !tooltipProps.payload[0].name) {
      return <View />;
    }

    const { name } = tooltipProps.payload[0];
    const data = chartData.find((d) => d.name === name);
    const totalAmount = chartData.reduce((acc, cur) => acc + cur.amount, 0);
    const percent = (100 * data.amount) / totalAmount;

    return (
      <View
        {...testId('rbpc-donut-tooltip', name.toLowerCase())}
        style={{
          backgroundColor: 'white',
          height: 60,
          minWidth: 120,
          justifyContent: 'space-between',
          padding: 5,
          borderColor: textColor,
          borderWidth: 1,
        }}
      >
        <Text
          {...testId(`rbpc-donut-tooltip-${name.toLowerCase()}-label`)}
          style={{ color: textColor }}
        >
          {`${data.name}:`}
        </Text>
        <Text
          {...testId(`rbpc-donut-tooltip-${name.toLowerCase()}-amount`)}
          style={{ color: textColor }}
        >
          {`${StringUtility.formatCurrency(data?.amount)} `
            + `(${StringUtility.formatDecimal(percent, 1)}%)`}
        </Text>
      </View>
    );
  };

  return (
    <ChartContainer
      legend={total && chartData.map((item) => (
        <LegendLabel color={item.color} key={item.name} text={item.name} />
      ))}
      legendPosition="right"
      subTitle={`${totalLabel} ${discountLabel}`}
      testID="revenue-by-product-category-card"
      title={translate<string>('ACCEPTED_REVENUE_BY_PRODUCT_CATEGORY')}
    >
      {!total ? (
        <EmptyState testID="no-data" title={translate('THERE_IS_NO_DATA_TO_DISPLAY')} />
      ) : (
        <PieChart height={500} style={CHART_STYLE} width={422}>
          <Pie data={chartData} dataKey="amount" innerRadius={150} outerRadius={200} stroke="none">
            {chartData.map(({ color, amount }) => (
              <Cell fill={color} key={amount} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      )}
    </ChartContainer>
  );
};
