import { TankMixDeliverableV0 as V0 } from '@shared/interfaces';
import { ApiDeliverable } from '@shared/interfaces/api';
import React from 'react';
import { DeliverableRenderer } from '../../DeliverableRenderer';
import { TankMixDeliverableDesktopView } from './TankMixDeliverableDesktopView';

export class TankMixDeliverableV0Render implements DeliverableRenderer {
  private deliverable: ApiDeliverable<V0.Snapshot, V0.Selections>;

  constructor (deliverable: ApiDeliverable) {
    this.deliverable = deliverable;
  }
  getPlanName (): string {
    return this.deliverable.deliverableData.HEADER?.TANK_MIX_NAME?.data;
  }
  getGrowerName (): string {
    return this.deliverable.deliverableData.HEADER?.GROWER_NAME?.data;
  }
  getBusinessName (): string {
    return this.deliverable.deliverableData.HEADER?.BUSINESS_NAME?.data;
  }
  getBusinessLocation (): string {
    return this.deliverable.deliverableData.HEADER?.LOCATION_NAME?.data;
  }

  getDesktopView () {
    return (
      <TankMixDeliverableDesktopView
        deliverableId={this.deliverable.publicId}
        name={this.deliverable.name}
        note={this.deliverable.note}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
      />
    );
  }

  getMobileView () {
    // authorized users currently don't ever see a mobile view
    return null;
  }
}
