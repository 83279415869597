import { Header, HSpacer, NumericInput, Text } from '@design';
import {
  ApiCrop,
  ApiGrowerCropZone,
  ApiGrowerFarm,
  ApiGrowerField,
  ApiPlanningParameter,
} from '@shared/interfaces/api';
import { useStyleSheet } from '@ui-kitten/components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { IColumn, RowMeta, SortableTable } from '../../../../components/SortableTable';
import { SetPlanningParameters } from '../PlanningParametersTab';
import { GetPlanningParameters } from './CropCard';

interface CropZoneTableProps {
  crop: ApiCrop,
  cropZones: ApiGrowerCropZone[],
  farm: ApiGrowerFarm,
  fields: ApiGrowerField[],
  getCustomerPlanningParameters: GetPlanningParameters,
  testID?: string,
  updatePlanningParameters: SetPlanningParameters,
}

type CropDataType = IColumn<(
  RowMeta
  & ApiGrowerCropZone
  & Pick<ApiPlanningParameter, 'yieldGoal' | 'yieldGoalUom'>
)>[];

const CropZoneTable = ({
  cropZones,
  crop,
  farm,
  fields,
  getCustomerPlanningParameters,
  testID,
  updatePlanningParameters,
}: CropZoneTableProps) => {
  const [translate] = useTranslation(['common', 'growers']);

  const styles = useStyleSheet({
    container: { flex: 1, justifyContent: 'flex-start' },
    line: {
      borderBottomColor: 'color-basic-transparent-100',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
  });

  const cropZoneRows = useCallback((
    cropInput: ApiCrop,
    farmInput: ApiGrowerFarm,
    fieldsInput: ApiGrowerField[],
  ) => cropZones.map((cropZone, idx) => {
    const field = fieldsInput?.find((f) => f.id === cropZone.growerFieldId);
    const cropZonePlanningParams = getCustomerPlanningParameters({
      crop: cropInput,
      farm: farmInput,
      field,
      cropZone,
    });
    return {
      ...cropZone,
      hasDetails: false,
      rowId: `row:${idx}|${cropZone?.id}`,
      yieldGoal: cropZonePlanningParams.yieldGoal,
      yieldGoalUom: cropZonePlanningParams.yieldGoalUom,
    };
  }), [cropZones, getCustomerPlanningParameters]);

  const columns: CropDataType = [
    {
      columnId: 'cropZoneName',
      header: null,
      render: (cz) => cz.zoneName,
      flex: 1,
    },
    {
      columnId: 'cropZoneYieldGoal',
      header: null,
      render: (cz) => (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <NumericInput
            onChangeValue={(value) => {
              const field = fields?.find((f) => f.id === cz.growerFieldId);
              updatePlanningParameters({
                crop,
                farm,
                field,
                cropZone: cz,
                property: 'yieldGoal',
                value,
              });
            }}
            precision={2}
            style={{ minWidth: 88 }}
            testID={`${crop.cropType}-${crop.subType}-${farm.id}`
            + `-${cz.growerFieldId}-${cz.id}-yield-goal-input`}
            value={cz.yieldGoal}
          />
          <HSpacer size="3" />
          <Text>{translate<string>(cz.yieldGoalUom)}</Text>
        </View>
      ),
      flex: 1,
    },
    {
      columnId: 'cropZoneAcreage',
      header: null,
      render: (cz) => cz.areaValue,
      flex: 1,
      align: 'right',
    },
  ];

  return (
    <View style={styles.container}>
      <Header level="4" testID="crop-zones-table-header" title={translate<string>('CROP_ZONES')} titleHint />
      <View style={styles.line} />
      <SortableTable
        columns={columns}
        data={cropZoneRows(crop, farm, fields)}
        showHeaders="no"
        testID={testID}
      />
    </View>
  );
};

export default React.memo(CropZoneTable);
