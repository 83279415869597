import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import {
  TextLink,
  Text,
  Icon,
  Card,
  Input,
  Select,
  SelectItem,
  CheckBox,
  IconName,
  IconNames,
  useToast,
} from '@design';
import { Categories, Category } from '@theme/variant-interfaces/Category';
import { TextLinkAppearance, TextLinkAppearances } from '@theme/variant-interfaces/Appearance';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const TextDemoKnobs: FC = () => {
  const [pre, setPre] = useState('Click ');
  const [text, setText] = useState('here');
  const [post, setPost] = useState(' to proceed.');

  const [category, setCategory] = useState<Category>('p1');
  const [disable, setDisable] = useState(false);
  const [appearance, setAppearance] = useState<TextLinkAppearance>('default');
  const [iconLeft, setIconLeft] = useState<IconName>(null);
  const [iconRight, setIconRight] = useState<IconName>(null);

  const { createToast } = useToast();
  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
        <Input label="Pre Text" onChangeText={setPre} testID="demo-input" value={pre} />
        <Input label="Link Text" onChangeText={setText} testID="demo-input" value={text} />
        <Input label="Post Text" onChangeText={setPost} testID="demo-input" value={post} />
        <Select
          label="appearance"
          onSelect={(ip: IndexPath | IndexPath[]) => setAppearance(
            TextLinkAppearances[(ip as IndexPath).row],
          )}
          selectedIndex={new IndexPath(TextLinkAppearances.findIndex((a) => a === appearance))}
          testID="text-link-demo-appearance-selector"
          value={appearance}
        >
          {TextLinkAppearances.map((a, i) => (
            <SelectItem key={a} testID={`text-link-demo-appearances-dropdown-value-${i}`} title={a} />
          ))}
        </Select>
        <Select
          label="category"
          onSelect={(ip: IndexPath | IndexPath[]) => setCategory(Categories[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(Categories.findIndex((c) => c === category))}
          testID="text-link-demo-category-selector"
          value={category}
        >
          {Categories.map((c, i) => (
            <SelectItem key={c} testID={`text-link-demo-category-dropdown-value-${i}`} title={c} />
          ))}
        </Select>
        <Select
          label="accessoryLeft"
          onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
            IconNames[(ip as IndexPath).row - 1],
          )}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)}
          testID="text-link-demo-accessory-left-selector"
          value={iconLeft || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="text-link-demo-left-icon-dropdown-value" title="None" />,
            ...IconNames.map((name, index) => (
              <SelectItem key={name} testID={`text-link-demo-left-icon-name-dropdown-value-${index}`} title={name} />
            )),
          ]}
        </Select>
        <Select
          label="accessoryRight"
          onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
            IconNames[(ip as IndexPath).row - 1],
          )}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
          testID="text-link-demo-accessory-right-selector"
          value={iconRight || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="text-link-demo-right-icon-name-dropdown-value" title="None" />,
            ...IconNames.map((name, index) => (
              <SelectItem key={name} testID={`text-link-demo-right-icon-name-dropdown-value-${index}`} title={name} />
            )),
          ]}
        </Select>
        <CheckBox
          checked={disable}
          onChange={setDisable}
          testID="test-checkbox"
        >
          disabled
        </CheckBox>
      </View>
      <DemoContainer>
        <DemoBlock>
          <View style={{ flexDirection: 'row', alignItems: 'baseline', flexWrap: 'wrap' }}>
            <Text category={category}>{pre}</Text>
            <TextLink
              accessoryLeft={(props) => (
                (iconLeft || null) && <Icon name={iconLeft} testID="demo-icon-left" {...props} />
              )}
              accessoryRight={(props) => (
                (iconRight || null) && <Icon name={iconRight} testID="demo-icon-right" {...props} />
              )}
              appearance={appearance}
              category={category}
              disabled={disable}
              onPress={() => createToast('Pressed')}
            >
              {text}
            </TextLink>
            <Text category={category}>{post}</Text>
          </View>
        </DemoBlock>
      </DemoContainer>
    </>
  );
};

export const TextLinkDemo: FC = () => {
  const { createToast } = useToast();
  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <TextDemoKnobs />
      </Card>
      <Card testID="test-card">
        <DemoContainer label="appearance">
          {TextLinkAppearances.map((appearance) => (
            <React.Fragment key={appearance}>
              <DemoBlock label={appearance}>
                <Text>
                  <Text>Click </Text>
                  <TextLink appearance={appearance} onPress={() => createToast('Pressed')}>here</TextLink>
                  <Text> to proceed.</Text>
                </Text>
              </DemoBlock>
              <DemoBlock label={`${appearance} disabled`}>
                <Text>
                  <Text>Click </Text>
                  <TextLink appearance={appearance} disabled onPress={() => createToast('Pressed')}>here</TextLink>
                  <Text> to proceed.</Text>
                </Text>
              </DemoBlock>
            </React.Fragment>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer label="with left icon">
          {Categories.map((category) => (
            <DemoBlock key={category} label={category}>
              <Text>
                <Text category={category}>Click </Text>
                <TextLink
                  accessoryLeft={(props) => <Icon name="AlertCircle" testID="demo-icon" {...props} />}
                  category={category}
                  onPress={() => createToast('Pressed')}
                >
                  here
                </TextLink>
                <Text category={category}> to proceed.</Text>
              </Text>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer label="with right icon">
          {Categories.map((category) => (
            <DemoBlock key={category} label={category}>
              <Text>
                <Text category={category}>Click </Text>
                <TextLink
                  accessoryRight={(props) => <Icon name="AlertCircle" testID="demo-icon" {...props} />}
                  category={category}
                  onPress={() => createToast('Pressed')}
                >
                  here
                </TextLink>
                <Text category={category}> to proceed.</Text>
              </Text>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer label="with both icon">
          {Categories.map((category) => (
            <DemoBlock key={category} label={category}>
              <Text>
                <Text category={category}>Click </Text>
                <TextLink
                  accessoryLeft={(props) => <Icon name="AlertCircle" testID="demo-icon-left" {...props} />}
                  accessoryRight={(props) => <Icon name="AlertCircle" testID="demo-icon-right" {...props} />}
                  category={category}
                  onPress={() => createToast('Pressed')}
                >
                  here
                </TextLink>
                <Text category={category}> to proceed.</Text>
              </Text>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
    </View>
  );
};
