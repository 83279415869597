import { HSpacer, IconButton, Input, NumericInput, VSpacer } from '@design';
import { ApiGrowerCropZone } from '@shared/interfaces/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  nameInput: {
    flex: 1,
  },
  areaInput: {
    width: 120,
  },
});

interface FarmCropZoneProps {
  cropZone: ApiGrowerCropZone,
  index?: number,
  onUpdateCropZone(cropZone: ApiGrowerCropZone): void,
  onRemoveCropZone?(): void;
}

export function FarmCropZone ({
  cropZone,
  index,
  onUpdateCropZone,
  onRemoveCropZone,
}: FarmCropZoneProps) {
  const [translate] = useTranslation(['growers', 'common']);

  const [cropZoneError, setCropZoneError] = useState(false);
  const [areaValueError, setAreaValueError] = useState(false);

  return (
    <>
      <View style={styles.row} testID={`crop-zone-pair-${index}`}>
        <Input
          isRequired
          label={translate<string>('CROP_ZONE_NAME')}
          onBlur={() => setCropZoneError(!cropZone.zoneName.trim())}
          onChangeText={(zoneName) => {
            onUpdateCropZone({ ...cropZone, zoneName });
          }}
          status={cropZoneError ? 'danger' : 'basic'}
          style={styles.nameInput}
          testID={`crop-zone-field-name-${index}`}
          value={cropZone.zoneName}
        />
        <HSpacer size="7" />
        <NumericInput
          isValidated={false}
          label={translate<string>('ACRES')}
          onBlur={() => setAreaValueError(!cropZone.areaValue)}
          onChangeValue={(areaValue) => {
            onUpdateCropZone({ ...cropZone, areaValue });
          }}
          precision={3}
          status={areaValueError ? 'danger' : 'basic'}
          style={styles.areaInput}
          testID={`crop-zone-field-areaValue-${index}`}
          value={cropZone.areaValue}
        />
        <HSpacer size="5" />
        <View style={styles.column}>
          <VSpacer size="7" />
          <VSpacer size="2" />
          <IconButton
            appearance="ghost"
            onPress={onRemoveCropZone}
            size="medium"
            status="basic"
            testID={`crop-zone-delete-button-${index}`}
          >
            Trash
          </IconButton>
        </View>
      </View>
      <VSpacer size="3" />
    </>
  );
}
