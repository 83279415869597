import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  ViewProps,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { Icon, Popover } from '@ui-kitten/components';
import { Sizing, ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: ThemeColors.DARK_GRAY,
    borderTopLeftRadius: Sizing.QUARTER_SPACING,
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    marginBottom: Sizing.DOUBLE_SPACING,
    borderBottomColor: ThemeColors.DARK_GRAY,
    borderBottomWidth: 3,
    height: 3.5 * Sizing.EM,
  },
  touchable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  input: {
    color: ThemeColors.TEXT,
    fontSize: 1.1 * Sizing.BASE_SPACING,
    paddingHorizontal: 0.75 * Sizing.BASE_SPACING,
    paddingTop: 1.75 * Sizing.BASE_SPACING,
    paddingBottom: Sizing.QUARTER_SPACING,
  },
  focused: { borderBottomColor: ThemeColors.PRIMARY },
  danger: { borderBottomColor: ThemeColors.DANGER },
  label: {
    color: ThemeColors.LIGHT_GRAY,
    fontSize: 0.8 * Sizing.EM,
    position: 'absolute',
    top: Sizing.HALF_SPACING,
    left: Sizing.BASE_SPACING,
  },
  caption: {
    position: 'absolute',
    top: 3.5 * Sizing.BASE_SPACING,
    fontSize: 0.8 * Sizing.EM,
    marginTop: 0.5 * Sizing.QUARTER_SPACING,
    color: ThemeColors.LIGHT_GRAY,
    paddingLeft: Sizing.BASE_SPACING,
  },
  focusedText: { color: ThemeColors.PRIMARY },
  expandIcon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
    position: 'absolute',
    right: Sizing.HALF_SPACING,
    top: Sizing.BASE_SPACING,
  },
  dropdown: {
    borderWidth: 0,
    borderRadius: Sizing.QUARTER_SPACING,
    backgroundColor: ThemeColors.DARK_GRAY,
    maxHeight: 10.3 * Sizing.EM,
  },
  colorContainer: {
    padding: Sizing.BASE_SPACING,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  dropdownItemDesktop: {
    width: 2 * Sizing.EM,
    height: 2 * Sizing.EM,
    borderRadius: Sizing.EM,
    marginRight: Sizing.BASE_SPACING,
    marginBottom: Sizing.BASE_SPACING,
  },
  dropDownItemIpad: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
    borderRadius: Sizing.EM / 1.333,
    marginRight: Sizing.HALF_SPACING,
    marginBottom: Sizing.HALF_SPACING,
  },
  inline: {
    marginBottom: 0,
    height: 2.5 * Sizing.EM,
  },
  expandIconInline: { top: Sizing.HALF_SPACING },
});

export const COLORS: ThemeColors[] = [
  ThemeColors.TAG_GREEN_1,
  ThemeColors.TAG_GREEN_2,
  ThemeColors.TAG_GREEN_3,
  ThemeColors.TAG_GREEN_4,
  ThemeColors.TAG_GREY_1,
  ThemeColors.TAG_YELLOW_1,
  ThemeColors.TAG_YELLOW_2,
  ThemeColors.TAG_YELLOW_3,
  ThemeColors.TAG_PURPLE_1,
  ThemeColors.TAG_PURPLE_2,
  ThemeColors.TAG_BLUE_1,
  ThemeColors.TAG_BLUE_2,
  ThemeColors.TAG_BLUE_3,
  ThemeColors.TAG_BLUE_4,
  ThemeColors.TAG_BLUE_5,
  ThemeColors.TAG_RED_1,
  ThemeColors.TAG_RED_2,
  ThemeColors.TAG_PINK_1,
  ThemeColors.TAG_PINK_2,
  ThemeColors.TAG_ORANGE_1,
];

export interface ColorSelectProps extends ViewProps {
  label?: string;
  value?: string,
  caption?: string;
  status?: string;
  disabled?: boolean,
  inline?: boolean,
  customPopoverWidth?: number,
  onSelect?: (color: ThemeColors) => void,
}

export const StyledColorSelect = (props: ColorSelectProps) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(props.value);
  const popoverRef = useRef();
  const [listVisible, setListVisible] = useState(false);

  useEffect(() => {
    if (focused) {
      setListVisible(focused);
    }
    setValue(props.value);
  }, [focused, props.value]);

  const onBackdropPress = useCallback(() => {
    setListVisible(false);
  }, []);

  const selectOption = (clr: ThemeColors) => {
    if (props.onSelect) {
      props.onSelect(clr);
    }
    setListVisible(false);
  };

  const renderInputElement = () => (
    <View
      style={[
        styles.inputContainer,
        focused && styles.focused,
        props.status === 'danger' && styles.danger,
        props.disabled && { opacity: 0.5 },
        props.inline && styles.inline,
        value && { backgroundColor: value },
      ]}
    >
      <Icon
        fill={value ? ThemeColors.WHITE : ThemeColors.LIGHT_GRAY}
        name="chevron-down-outline"
        style={[
          styles.expandIcon,
          props.inline && styles.expandIconInline,
        ]}
      />
      {!props.inline && props.label && (
      <Text
        style={[
          styles.label,
          focused && styles.focusedText,
        ]}
      >
        {props.label}
      </Text>
      )}
      <TouchableOpacity
        {...props}
        onBlur={() => {
          setFocused(false);
        }}
        onPress={() => {
          setFocused(!focused);
        }}
        style={[
          styles.touchable,
          props.style,
        ]}
      />
      {!props.inline && props.caption !== '' && (
      <Text
        style={[
          styles.caption,
          focused && styles.focusedText,
        ]}
      >
        {props.caption}
      </Text>
      )}
    </View>
  );

  const { width } = useWindowDimensions();

  const renderOption = (color) => (
    <TouchableOpacity
      key={color}
      onPress={() => selectOption(color)}
      style={[
        width < 1300 ? styles.dropDownItemIpad : styles.dropdownItemDesktop,
        { backgroundColor: color },
      ]}
    />
  );

  return (
    <>
      <Popover
        anchor={() => renderInputElement()}
        fullWidth={!props.customPopoverWidth}
        onBackdropPress={onBackdropPress}
        placement="bottom start"
        ref={popoverRef}
        style={[styles.dropdown, { width: props.customPopoverWidth ?? '100%' }]}
        visible={listVisible}
      >
        <ScrollView style={styles.dropdown}>
          <View nativeID="crop-selector-view" style={styles.colorContainer}>
            {COLORS.map((clr) => (
              renderOption(clr)
            ))}
          </View>
        </ScrollView>
      </Popover>
    </>
  );
};
