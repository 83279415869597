import { Button } from '@design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { ButtonBar } from '../../../../src/components/shared/ButtonBar';
export interface FooterButtonsProps {
  isEdit: boolean,
  isValid: boolean,
  onCancel: () => void,
  onDone: () => void,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
}

const styles = StyleSheet.create({
  buttonLeft: {
    position: 'absolute',
    marginHorizontal: 24,
    height: 56,
    bottom: 32,
  },
});

export const FooterButtons = ({
  isEdit,
  isValid,
  onCancel,
  onDone,
  page = 0,
  setPage,
}: FooterButtonsProps) => {
  const [translate] = useTranslation(['common']);

  const footerButtonsResolver = [
    <ButtonBar
      buttonBarType="spaced"
      disableRightAction={!isValid}
      leftAction={onCancel}
      leftButtonText={translate<string>('CANCEL')}
      rightAction={() => setPage(1)}
      rightButtonText={translate<string>('NEXT')}
      size="giant"
      testID="property-group-modal-details-footer-buttons"
    />,
    <>
      <Button
        appearance="outline"
        capitalize
        design="floating"
        onPress={onCancel}
        size="large"
        status="basic"
        style={styles.buttonLeft}
        testID="property-group-modal-properties-cancel-button"
      >
        {translate<string>('CANCEL')}
      </Button>
      <ButtonBar
        buttonBarType="grouped"
        disableRightAction={!isValid}
        leftAction={() => setPage(0)}
        leftButtonText={translate<string>('BACK')}
        rightAction={() => onDone()}
        rightButtonText={isEdit ? translate<string>('APPLY') : translate<string>('DONE')}
        size="giant"
        testID="property-group-modal-properties-footer-button-bar"
      />
    </>,
  ];

  return footerButtonsResolver[page];
};
