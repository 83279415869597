import { CropSubType, CropType } from '@shared/enums';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HSpacer, Modal, Text, TextLink } from '@design';

const abridgedCropsLimit = 5;

const styles = StyleSheet.create({
  linkContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
});

interface CropTypeAndCropSubType {
  cropSubType: CropSubType,
  cropType: CropType,
}

interface CropsProps {
  crops: CropTypeAndCropSubType[],
}

const Crops = ({ crops }: CropsProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  return (
    <>
      {crops.map((crop) => (
        <View key={`${crop.cropType}-${crop.cropSubType}`} testID="crop-type-value">
          <Text category="p2">
            {`${translate(crop.cropType)} | ${translate(crop.cropSubType)}`}
          </Text>
        </View>
      ))}
    </>
  );
};

export interface CropSummaryProps {
  crops: CropTypeAndCropSubType[],
}

export const CropSummary = ({ crops }: CropSummaryProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showModal, setShowModal] = useState(false);

  if (!crops.length) {
    return (
      <Text category="p2">
        {translate<string>('NOT_APPLICABLE')}
      </Text>
    );
  }

  const abridgedCrops = crops.slice(0, abridgedCropsLimit);
  const isAbridged = crops.length > abridgedCrops.length;

  return (
    <>
      <Crops crops={abridgedCrops} />
      {isAbridged && (
        <>
          <Modal
            onClose={() => setShowModal(false)}
            testID="crop-more-modal"
            title={translate('CROP_TYPE')}
            visible={showModal}
          >
            <Crops crops={crops} />
          </Modal>
          <View style={styles.linkContainer}>
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowModal(true)}
            >
              {translate<string>('VIEW_ALL_CROPS')}
            </TextLink>
            <HSpacer size="8" />
          </View>
        </>
      )}
    </>
  );
};
