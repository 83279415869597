/* eslint-disable @typescript-eslint/comma-dangle */
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  Popper,
  PopperPlacementType,
  TextField,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { Close, ExpandMore } from '@mui/icons-material';
import { StyleSheet, View } from 'react-native';
import { Text } from '../Text/Text';
import { useTranslation } from 'react-i18next';

export interface ComboBoxProps<T> extends Omit<MuiAutocompleteProps<T, false, false, false>, 'renderInput'> {
  disabled?: boolean,
  getOptionLabel: (option: T) => string,
  id: string, // without an ID, chrome does crazy things.
  isRequired?: boolean,
  label?: string,
  onChangeValue: (value: T) => void,
  options: T[],
  optionWindowMaxHeight?: number,
  optionWindowPlacement?: PopperPlacementType,
  readOnly?: boolean,
  renderOptionAccessory?: (option: T) => ReactNode,
  size?: 'small' | 'medium',
  testID: string,
  topLabel?: string,
}

const styles = StyleSheet.create({
  helpTextContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 12,
    marginBottom: 8,
  },
});

export const ComboBox = <T,>({
  disabled,
  getOptionLabel,
  id,
  isRequired,
  label,
  onChangeValue,
  options,
  optionWindowMaxHeight,
  optionWindowPlacement,
  readOnly,
  renderOptionAccessory,
  size = 'small',
  testID,
  topLabel,
  ...autocompleteProps
}: ComboBoxProps<T>) => {
  const [translate] = useTranslation('common');
  const [zIndex, setZIndex] = useState(10000);

  return (
    <View style={{ zIndex }}>
      <View style={styles.helpTextContainer}>
        {topLabel && (
          <Text appearance='hint' category='p2' style={{ flex: 1 }}>
            {topLabel}
            {readOnly && ` (${translate('READ_ONLY')})`}
          </Text>
        )}
        {isRequired && (
          <Text appearance='hint' category='c1'>
            {translate('REQUIRED')}
          </Text>
        )}
      </View>
      <View testID={`${testID}-container`}>
        <MuiAutocomplete
          ListboxProps={{
            sx: { maxHeight: optionWindowMaxHeight },
          }}
          PopperComponent={(props) => (
            <Popper {...props} placement={optionWindowPlacement} />
          )}
          autoHighlight
          clearIcon={<Close fontSize={size} />}
          disablePortal
          disabled={disabled || readOnly}
          getOptionLabel={getOptionLabel}
          id={id}
          onChange={(event, value) => onChangeValue(value)}
          onClose={() => setZIndex(10000)}
          onOpen={() => setZIndex(10001)}
          options={options}
          popupIcon={<ExpandMore fontSize={size} />}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, 'data-testid': testID }}
              label={label}
            />
          )}
          renderOption={(props, option, state) => (
            <li {...props} data-testid={`${testID}-option-${state.index}`}>
              {getOptionLabel(option)}
              {renderOptionAccessory?.(option) ?? <></>}
            </li>
          )}
          size={size}
          {...autocompleteProps}
        />
      </View>
    </View>
  );
};
