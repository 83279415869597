import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import { Button, Dialog, HSpacer, Input, Select, SelectItem, Text } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const tints = ['dark', 'light', 'default'];

export const DialogDemo: FunctionComponent = () => {
  const [basicWithTitle, setBasicWithTitle] = useState(false);
  const [basicNoTitle, setBasicNoTitle] = useState(false);
  const [dangerWithTitle, setDangerWithTitle] = useState(false);
  const [dangerNoTitle, setDangerNoTitle] = useState(false);
  const [warningWithTitle, setWarningWithTitle] = useState(false);
  const [warningNoTitle, setWarningNoTitle] = useState(false);
  const [blurView, setBlurView] = useState(false);

  const [blurTint, setBlurTint] = useState('dark');
  const [blurIntensity, setBlurIntensity] = useState(50);

  const commonProps = {
    footerAccessory: ({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
      <>
        <Button {...primaryButtonProp}>Cancel</Button>
        <HSpacer {...spacerProp} />
        <Button {...secondaryButtonProp}>Action</Button>
      </>
    ),
  };

  const knobs = (
    <DemoContainer>
      <DemoBlock>
        <Select
          label="blur tint"
          onSelect={(ip: IndexPath | IndexPath[]) => setBlurTint(tints[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(tints.findIndex(((t) => t === blurTint)))}
          testID="dialog-demo-selector"
          value={blurTint}
        >
          {tints.map((t, i) => (
            <SelectItem key={t} testID={`dialog-demo-tint-dropdown-value-${i}`} title={t} />
          ))}
        </Select>
      </DemoBlock>
      <DemoBlock>
        <Input
          label="blur intensity"
          onChangeText={(text) => setBlurIntensity(parseInt(text, 10) || 0)}
          testID="test-input"
          value={blurIntensity.toString()}
        />
      </DemoBlock>
    </DemoContainer>
  );

  return (
    <View>
      <DemoContainer>
        <DemoBlock>
          <Button onPress={() => setBasicWithTitle(!basicWithTitle)} size="small" testID="test-button">
            Dialog Basic
          </Button>
          <Dialog
            onClose={() => setBasicWithTitle(false)}
            testID="basic-status-dialog"
            title="Basic status Dialog"
            visible={basicWithTitle}
            {...commonProps}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Dialog>
        </DemoBlock>
        <DemoBlock>
          <Button
            onPress={() => setBasicNoTitle(!basicNoTitle)}
            size="small"
            testID="test-button"
          >
            Danger Basic - No title
          </Button>
          <Dialog
            onClose={() => setBasicNoTitle(false)}
            testID="basic-status-no-title-dialog"
            visible={basicNoTitle}
            {...commonProps}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Dialog>
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock>
          <Button onPress={() => setDangerWithTitle(!dangerWithTitle)} size="small" testID="test-button">
            Dialog Danger
          </Button>
          <Dialog
            onClose={() => setDangerWithTitle(false)}
            status="danger"
            testID="danger-status-dialog"
            title="Danger status Dialog"
            visible={dangerWithTitle}
            {...commonProps}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Dialog>
        </DemoBlock>
        <DemoBlock>
          <Button
            onPress={() => setDangerNoTitle(!dangerNoTitle)}
            size="small"
            testID="test-button"
          >
            Dialog Danger - No Title
          </Button>
          <Dialog
            onClose={() => setDangerNoTitle(false)}
            status="danger"
            testID="danger-status-no-title-dialog"
            visible={dangerNoTitle}
            {...commonProps}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Dialog>
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock>
          <Button onPress={() => setWarningWithTitle(!warningWithTitle)} size="small" testID="test-button">
            Warning Danger
          </Button>
          <Dialog
            onClose={() => setWarningWithTitle(false)}
            status="warning"
            testID="waring-status-dialog"
            title="Warning Status Dialog"
            visible={warningWithTitle}
            {...commonProps}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Dialog>
        </DemoBlock>
        <DemoBlock>
          <Button
            onPress={() => setWarningNoTitle(!warningNoTitle)}
            size="small"
            testID="test-button"
          >
            Warning Danger - No Title
          </Button>
          <Dialog
            onClose={() => setWarningNoTitle(false)}
            status="warning"
            testID="warning-status-no-title-dialog"
            visible={warningNoTitle}
            {...commonProps}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Dialog>
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock>
          <Button onPress={() => setBlurView(true)} size="small" testID="test-button">
            Configure BlurView
          </Button>
          <Dialog
            // only provided for this demo.  not normally required in regular usage
            blurIntensity={blurIntensity}
            // only provided for this demo.  not normally required in regular usage
            blurTint={blurTint}
            onClose={() => setBlurView(false)}
            testID="configure-blur-view-dialog"
            title="Configure BlurView"
            visible={blurView}
            {...commonProps}
          >
            {knobs}
          </Dialog>
        </DemoBlock>
      </DemoContainer>
    </View>
  );
};
