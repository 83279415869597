import {
  Button,
  CenteredSpinner,
  DataPoint,
  FilterCategory,
  FilterTable,
  Icon,
  MenuItem,
  OverflowMenu,
  useBanner,
  ViewRow,
  VSpacer,
} from '@design';
import { Selections } from '@design/Filter/Filter';
import { ApiBusiness, ApiBusinessLocation, BusinessLocationEndpoint } from '@shared/interfaces/api';
import { Permissions, RoleUtility, toShortDate } from '@shared/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { BusinessLocationApi } from '../../../../utilities/api';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { LocationModal } from './LocationModal';

const styles = StyleSheet.create({
  row: {
    width: '100%',
  },
  item: {
    width: '25%',
  },
});

const defaultPageOptions: BusinessLocationEndpoint.List.Query = {
  page: 0,
  search: '',
  isActive: ['true'],
};

const defaultFiltersKeys = ['isActive'];

const defaultFilters: Map<string, Selections> = new Map([
  ['isActive', {
    true: true,
  }],
]);

export interface LocationTabProps {
  business: ApiBusiness,
  onPageChange: () => void,
}
export function LocationTab ({ business, onPageChange }: LocationTabProps) {
  const [translate] = useTranslation(['businessLocations', 'businesses', 'common', 'errors']);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState<(ApiBusinessLocation & RowMeta)[]>([]);
  const [isActivationModalVisible, setActivationModalVisible] = useState(false);
  const [pageOptions, setPageOptions] = useState(defaultPageOptions);
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const filterOptions: FilterCategory[] = [
    {
      columnLabel: translate('STATUS'),
      columnKey: 'isActive',
      columns: [{ label: 'Active', id: 'true' }, { label: 'Inactive', id: 'false' }],
    },
  ];
  const [columnCategories] = useState(filterOptions);
  const { showBanner } = useBanner();
  const { user } = useAuthentication();

  const getLocations = () => {
    const filters: BusinessLocationEndpoint.List.Query = {
      sort: BusinessLocationEndpoint.List.Sort.locationName,
      businessId: business.id,
      ...pageOptions,
    };

    if (!filters.isActive) {
      filters.isActive = ['true', 'false'];
    }

    if (!filters.search) { delete filters.search; }

    return BusinessLocationApi.getLocations(filters);
  };

  const { isLoading, isFetching, data: locationResponse } = useQuery(
    [
      QueryKeys.BUSINESS_LOCATION_LIST,
      business.id,
      pageOptions,
      defaultFiltersKeys,
    ],
    getLocations,
    {
      initialData: null,
      enabled: !!business.id,
      onError: () => {
        showBanner(
          translate(
            'ERROR_OCURRED',
          ),
        );
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (result) => {
        setLocations(
          result.data.map((location, index) => ({
            ...location,
            hasDetails: true,
            rowId: `row:${index}|${location.locationName}`,
          })),
        );
      },
    },
  );

  const isInternal = RoleUtility.roleHasPermission(
    user.userRole,
    Permissions.ACCESS_ALL_BUSINESSES,
  );
  const isAdmin = RoleUtility.roleHasPermission(
    user?.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const updateBusinessLocation = async () => {
    const { id, isActive } = selectedLocation;
    try {
      await BusinessLocationApi.updateBusinessLocation(
        business.id,
        id,
        {
          isActive: !isActive,
        },
      );

      queryClient.invalidateQueries([QueryKeys.BUSINESS_LOCATION_LIST, business.id]);

      setSelectedLocation(null);
      setActivationModalVisible(false);
    } catch (err) {
      showBanner(
        translate(
          isActive ? 'ERROR_DEACTIVATING_BUSINESS_LOCATION' : 'ERROR_ACTIVATING_BUSINESS_LOCATION',
        ),
      );
    }
  };

  const columns: IColumn<(ApiBusinessLocation & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: false,
      },
      render: (location) => location.locationName || '',

      flex: 1,
    },
    {
      columnId: 'address',
      header: {
        render: translate('ADDRESS1'),
        sortable: false,
      },
      render: (location) => location.address1 || '',

      flex: 1,
    },
    {
      columnId: 'state',
      header: {
        render: translate('STATE'),
        sortable: false,
      },
      render: (location) => location.state || '',

      flex: 1,
    },
    {
      columnId: 'county',
      header: {
        render: translate('COUNTY'),
        sortable: false,
      },
      render: (location) => location.county || '',

      flex: 1,
    },
    {
      columnId: 'postalCode',
      header: {
        render: translate('POSTAL_CODE'),
        sortable: false,
      },
      render: (location) => location.postalCode || '',

      flex: 1,
    },
    {
      columnId: 'menu',
      header: {
        render: null,
      },
      render: (location) => (
        <>
          {
            (isInternal || isAdmin) && (
              <OverflowMenu
                placement="bottom end"
                testID={`${location.rowId}`}
              >
                {location.isActive && (
                  <MenuItem
                    onPress={() => {
                      setSelectedLocation(location);
                      setShowModal(true);
                    }}
                    testID="location-edit"
                    title={`${translate('EDIT')}`}
                  />
                )}
                <MenuItem
                  onPress={() => {
                    setSelectedLocation(location);
                    setActivationModalVisible(true);
                  }}
                  testID="location-activation"
                  title={`${translate(location.isActive ? 'DEACTIVATE' : 'ACTIVATE')}`}
                />
              </OverflowMenu>
            )
          }
        </>
      ),
      width: 72,
    },
  ];

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <>
      <FilterTable
        accessoryRight={() => isAdmin && (
          <View style={{ flexDirection: 'row' }}>
            <Button
              accessoryLeft={<Icon name="Plus" testID="add-business-location-button-icon" />}
              onPress={() => {
                setSelectedLocation(null);
                setShowModal(true);
              }}
              testID="add-business-location-button"
            >
              {translate('CREATE_BUSINESS_LOCATION') as string}
            </Button>
          </View>
        )}
        columns={columns}
        currentPage={pageOptions.page + 1}
        data={locations}
        defaultColumnFilters={defaultFiltersKeys}
        defaultFilters={defaultFilters}
        filterOptions={columnCategories}
        isLoading={isFetching}
        noDataMessage={translate('NO_LOCATIONS_FOUND')}
        noFilters={false}
        onPageChange={(page: number) => {
          setPageOptions((options) => ({ ...options, page: page - 1 }));
          onPageChange();
        }}
        onUpdateFilter={(filter: any) => {
          setPageOptions({
            ...filter,
            page: 0,
          });
        }}
        rowDetail={({
          locationEmail,
          locationTelephone,
          country,
          city,
          createdAt,
          updatedAt,
          isActive,
        }) => (
          <>
            <ViewRow style={styles.row}>
              <DataPoint
                label={translate<string>('EMAIL_ADDRESS')}
                style={styles.item}
                testID="location-email-address"
              >
                {locationEmail || ''}
              </DataPoint>
              <DataPoint
                label={translate<string>('PHONE_NUMBER')}
                style={styles.item}
                testID="location-email-address"
              >
                {locationTelephone || ''}
              </DataPoint>
              <DataPoint
                label={translate<string>('COUNTRY')}
                style={styles.item}
                testID="location-country"
              >
                {country}
              </DataPoint>
              <DataPoint
                label={translate<string>('CITY')}
                style={styles.item}
                testID="location-city"
              >
                {city}
              </DataPoint>
            </ViewRow>
            <VSpacer size="8" />
            <ViewRow style={styles.row}>
              <DataPoint
                label={translate<string>('DATE_CREATED')}
                style={styles.item}
                testID="date-created"
              >
                {toShortDate(createdAt)}
              </DataPoint>
              <DataPoint
                label={translate<string>('DATE_UPDATED')}
                style={styles.item}
                testID="date-updated"
              >
                {toShortDate(updatedAt)}
              </DataPoint>
              <DataPoint
                label={translate<string>('STATUS')}
                style={styles.item}
                testID="status"
              >
                {isActive ? translate<string>('ACTIVE') : translate<string>('INACTIVE')}
              </DataPoint>
            </ViewRow>
          </>
        )}
        tableId="locations-list-table"
        tableRowId="locations-list"
        testID="locations-list-page"
        totalPages={(locationResponse?.lastPage ?? 0) + 1}
        totalResults={locationResponse?.total}
      />
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate(selectedLocation?.isActive
          ? 'DEACTIVATE'
          : 'ACTIVATE')}
        messageText={translate(selectedLocation?.isActive
          ? 'BUSINESS_LOCATION_DEACTIVATE_MODAL_WARNING'
          : 'BUSINESS_LOCATION_ACTIVATE_MODAL_WARNING')}
        onCancel={() => {
          setSelectedLocation(null);
          setActivationModalVisible(false);
        }}
        onConfirm={updateBusinessLocation}
        status={selectedLocation?.isActive ? 'warning' : null}
        title={translate(selectedLocation?.isActive
          ? 'DEACTIVATE_LOCATION'
          : 'ACTIVATE_LOCATION')}
        visible={isActivationModalVisible}
      />
      {
        showModal && (
          <LocationModal
            editMode={!!selectedLocation}
            location={selectedLocation}
            toggle={() => setShowModal(false)}
            visible
          />
        )
      }
    </>
  );
}
