import React, { useEffect, useState } from 'react';
import { Filter } from '@design';
import { FilterCategory, Selections } from '@design/Filter/Filter';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useCropYears } from '../../../../hooks/useCropYears';

interface CropYearFilterProps {
  onChange: (value: number[]) => void;
}

export const CropYearFilter = (props: CropYearFilterProps) => {
  const [translate] = useTranslation(['common', 'errors']);
  const { currentBusinessId } = useAuthentication();

  const { cropYears } = useCropYears(currentBusinessId);

  const [filterOptions, setFilterOptions] = useState<FilterCategory[]>([
    {
      columnLabel: translate('CROP_YEAR'),
      columnKey: 'cropYear',
      columns: [],
    },
  ]);

  useEffect(() => {
    filterOptions[0].columns = cropYears.map((y) => (
      { id: y.toString(), label: y.toString() }
    ));
    setFilterOptions([...filterOptions]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropYears]);

  const mapYears = (selectedYears?: string[]) => {
    const obj = {};
    cropYears.forEach((y) => {
      obj[y] = (selectedYears?.includes(y) ?? false);
    });
    return obj;
  };

  const [defaultFilters, setDefaultFilters] = useState<Map<string, Selections>>(new Map([
    ['cropYear', mapYears()],
  ]));

  return (
    <Filter
      autoClearSelections
      defaultColumnFilters={['cropYear']}
      defaultFilters={defaultFilters}
      filterOptions={filterOptions}
      noFilters={false}
      noSearchBox
      onUpdateFilter={(filter) => {
        const selectedCropYears = filter?.cropYear ?? [];
        props.onChange(selectedCropYears.map((y) => parseInt(y, 10)));
        setDefaultFilters(new Map([
          ['cropYear', mapYears(selectedCropYears)],
        ]));
      }}
      showMoreFiltersButton={false}
      testID="crop-year-filter"
    />
  );
};
