import React, { PureComponent, ReactElement, isValidElement, cloneElement, createElement } from 'react';
import { RenderProp } from '@ui-kitten/components/devsupport';
import { Icon, IconName, IconNames, IconProps } from '../Icon/Icon';

export interface FalsyIconProps extends Omit<IconProps, 'name'> {
  component?: RenderProp<Partial<IconProps>> | IconName | string;
}

/**
 * Helper component for optional icon properties.
 *
 * Accepts same props as Icon component,
 * and `component` which may be a IconName string, a function, null, or undefined.
 *
 * If it is null or undefined, will render nothing.
 * If it is a function, will call it with props passed to this component.
 * Otherwise, will render an Icon with props passed to this component.
 */

export class FalsyIcon extends PureComponent<FalsyIconProps> {
  public render (): ReactElement {
    const { component, testID, ...iconProps } = this.props;

    const isIconName = (x: any): x is IconName => IconNames.includes(x);

    if (isValidElement(component)) {
      return cloneElement(component, iconProps as IconProps);
    }

    if (typeof component === 'function') {
      return createElement(component, iconProps as IconProps);
    }

    if (typeof component === 'string' && isIconName(component)) {
      return (
        <Icon
          name={component}
          testID={testID}
          {...iconProps}
        />
      );
    }

    return null;
  }
}
