import React from 'react';
import { StyleSheet, Text, TextProps } from 'react-native';
import { testId } from '../../../utilities/testId';
import { ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  text: { color: ThemeColors.TEXT },
  link: { color: ThemeColors.SECONDARY, textDecorationLine: 'underline' },
});

export interface StyledTextProps extends TextProps {
  children: any;
}

export const StyledText = (props: StyledTextProps) => (
  <Text
    {...props}
    {...testId(props.nativeID)}
    style={[
      styles.text,
      props.onPress && styles.link,
      props.style,
    ]}
  >
    {props.children}
  </Text>
);
