import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../../hooks/useBusinessLocationList';
import { Button, ComboBox, Input, LargeModal, Text } from '@design';

export const ComboBoxDemo: FunctionComponent = () => {
  const { currentBusinessId } = useAuthentication();
  const { businessLocations } = useBusinessLocationList(currentBusinessId);
  const [businessLocation, setBusinessLocation] = useState(null);

  const [isDemoModalVisible, setIsDemoModalVisible] = useState(false);

  return (
    <View style={{ alignItems: 'center', padding: 32 }}>
      <ComboBox
        getOptionLabel={(location) => location?.locationName ?? 'Corporate'}
        id='comboBox-1'
        label='Business Location'
        onChangeValue={(value) => setBusinessLocation(value)}
        options={businessLocations}
        sx={{ width: 433 }}
        testID='comboBox-test'
      />

      <View style={{ marginTop: 32, width: 433 }}>
        <Text>This component is here to ensure the autocomplete popover completely obscures controls rendered on the same layer.</Text>
        <Text>{`Here is the value of the autocomplete: ${businessLocation}`}</Text>
        <Input
          testID='sample-input'
        />
      </View>

      <View style={{ marginTop: 32, width: 433 }}>
        <Button
          onPress={() => { setIsDemoModalVisible(!isDemoModalVisible); }}
          style={{ width: 320 }}
          testID='button-test'
        >
          <Text style={{ color: '#000' }}>Show Large Modal</Text>
        </Button>
      </View>

      <LargeModal
        footer={() => (
          <View style={{ paddingRight: 32, justifyContent: 'flex-end', flexDirection: 'row' }}>
            <Button
              appearance="outline"
              onPress={() => setIsDemoModalVisible(!isDemoModalVisible)}
              size="giant"
              status="basic"
              testID="test-button"
            >
              Cancel
            </Button>
          </View>
        )}
        pages={[
          (
            <>
              <View style={{ paddingLeft: 32, zIndex: 10000 }}>
                <ComboBox
                  getOptionLabel={(location) => location?.locationName ?? 'Corporate'}
                  id='comboBox-1'
                  label='Business Location'
                  onChangeValue={(value) => setBusinessLocation(value)}
                  options={businessLocations}
                  sx={{ width: 433 }}
                  testID='comboBox-test'
                />
              </View>
              <View style={{ padding: 32, marginTop: 32, width: 433 }}>
                <Text>This component is here to ensure the autocomplete popover completely obscures controls rendered on the same layer.</Text>
                <Input testID='sample-input' />
              </View>
            </>
          ),
        ]}
        testID='largeModal-test'
        title='Large Modal Test'
        visible={isDemoModalVisible}
      />
    </View>
  );    
};