import { CheckBox, Text } from '@design';
import React from 'react';
import { View } from 'react-native';
import { useStyleSheet } from '@ui-kitten/components';

export interface SelectionModalListItemProps {
  checked: boolean,
  index: number,
  label: string,
  onChange: (checked: boolean, indeterminate: boolean) => void,
}

export const SelectionModalListItem = (props: SelectionModalListItemProps) => {
  const styles = useStyleSheet({
    listItem: {
      flex: 1,
      padding: 12,
      borderBottomColor: 'color-basic-700',
      borderBottomWidth: 1,
    },
  });

  return (
    <View style={styles.listItem}>
      <CheckBox
        checked={props.checked}
        onChange={props.onChange}
        testID={`selection-modal-options-checkbox-${props.index}`}
      >
        <Text>{props.label}</Text>
      </CheckBox>
    </View>
  );
};