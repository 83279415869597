import { ApiPriceType } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';

interface ChangePriceTypeConfirmationModalProps {
  newPriceType: ApiPriceType,
  oldPriceType: ApiPriceType,
  onCancel: () => void,
  onConfirm: () => void,
  visible: boolean,
}

export const ChangePriceTypeConfirmationModal = (props: ChangePriceTypeConfirmationModalProps) => {
  const [translate] = useTranslation(['common']);
  const { newPriceType, oldPriceType, onCancel, onConfirm, visible } = props;

  return (
    <ConfirmationModal
      cancelText={translate('CANCEL')}
      confirmText={translate('YES_CHANGE')}
      messageText={translate('CHANGE_PRICE_TYPE_MESSAGE', {
        newPriceType: newPriceType?.name,
        oldPriceType: oldPriceType?.name,
      })}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={translate('CHANGE_PRICE_TYPE_TITLE')}
      visible={visible}
    />
  );
};
