import React, { FC, useLayoutEffect, useState } from 'react';
import { useWindowDimensions, View, ViewProps } from 'react-native';
import { StyleType, styled, StyledComponentProps } from '@ui-kitten/components';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { screenBreakpoints, ScreenSizeType } from './constants';

export interface ResponsiveContainerProps extends ViewProps, StyledComponentProps {
  children: React.ReactNode,
}

export const ResponsiveContainerRaw: FC<ResponsiveContainerProps> = ({
  eva,
  children,
  style,
  ...viewProps
}: ResponsiveContainerProps) => {
  const { width: windowWidth } = useWindowDimensions();
  const [screenSize, setScreenSize] = useState<ScreenSizeType>('small');

  useLayoutEffect(() => {
    for (const [key, value] of Object.entries(screenBreakpoints)) {
      if (windowWidth > value.min && windowWidth < value.max) {
        setScreenSize(key as ScreenSizeType);
      }
    }
  }, [windowWidth]);

  const getComponentStyles = (source: StyleType) => {
    const xSmallStyles = PropsServiceHelper.allWithPrefixMapped(source, 'xSmall');
    const smallStyles = PropsServiceHelper.allWithPrefixMapped(source, 'small');
    const mediumStyles = PropsServiceHelper.allWithPrefixMapped(source, 'medium');
    const largeStyles = PropsServiceHelper.allWithPrefixMapped(source, 'large');
    const xLargeStyles = PropsServiceHelper.allWithPrefixMapped(source, 'xLarge');

    return {
      xSmall: {
        ...xSmallStyles,
      },
      small: {
        ...smallStyles,
      },
      medium: {
        ...mediumStyles,
      },
      large: {
        ...largeStyles,
      },
      xLarge: {
        ...xLargeStyles,
      },
    };
  };

  const componentStyle = getComponentStyles(eva.style);

  return (
    <View {...viewProps} style={[componentStyle[screenSize], { flex: 1, flexDirection: 'row' }, style]}>
      {children}
    </View>
  );
};

export const ResponsiveContainer: FC<ResponsiveContainerProps> = styled('ResponsiveContainer')(ResponsiveContainerRaw);
