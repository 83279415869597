import {
  Button,
  HSpacer,
  Icon,
  Input,
  Modal,
  Select,
  SelectItem,
  Text,
  VSpacer,
} from '@design';
import { CostType, DiscountType } from '@shared/enums';
import { ApiDiscount, DiscountEndpoint } from '@shared/interfaces/api';
import { IndexPath, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Alert } from '../../../../../ui-components';
import { UseBusinessDiscountsListProps } from '../../../../../hooks/useBusinessDiscounts';
import { StyleSheet } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { DiscountInputSelect } from '../../../../components/shared/Input/DiscountInputSelect';

export interface AddEditSkuDiscountDialogProps {
  businessDiscountsList: UseBusinessDiscountsListProps,
  skuDiscount?: ApiDiscount;
  skuDiscounts: ApiDiscount[];
  onApply: (updates: DiscountEndpoint.Save.Request) => void;
  onClose: () => void;
}

const defaultProductDiscount: Partial<ApiDiscount> = {
  name: '',
};

export const AddEditSkuDiscountModal: FC<AddEditSkuDiscountDialogProps> = ({
  businessDiscountsList,
  skuDiscount,
  skuDiscounts,
  onApply,
  onClose,
}) => {
  const styles = useStyleSheet({
    apply: {
      height: 73,
      alignItems: 'center',
      borderBottomColor: 'color-basic-900',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    selectSubTitle: {
      color: 'text-hint-color',
    },
  });

  const {
    areBusinessDiscountsFetched,
    businessDiscountsListData: currentBusinessDiscounts,
    isBusinessDiscountsDataLoading,
  } = businessDiscountsList;
  const [translate] = useTranslation(['common', 'productOrders']);
  const [applyNewDiscountSelected, setApplyNewDiscountSelected] = useState(false);
  const costTypeOptions = Object.keys(CostType).map((key) => ({
    title: translate(CostType[key]),
    key: CostType[key] as CostType,
  }));
  const [discount, setDiscount] = useState(defaultProductDiscount);

  const availableBusinessDiscounts = useMemo(() => (
    currentBusinessDiscounts.data.filter((bizDiscount) => !skuDiscounts.some((skuDisc) => (
      bizDiscount.name === skuDisc.name)))
  ), [currentBusinessDiscounts.data, skuDiscounts]);

  useEffect(() => {
    if (!isBusinessDiscountsDataLoading && !currentBusinessDiscounts.data.length) {
      setApplyNewDiscountSelected(true);
    }
  }, [currentBusinessDiscounts.data.length, isBusinessDiscountsDataLoading]);
  

  useEffect(() => {
    let isEditable = false;
    if (areBusinessDiscountsFetched && skuDiscount?.name) {
      isEditable = !currentBusinessDiscounts.data.some((currentBizDiscount) => {
        return currentBizDiscount.name === skuDiscount.name;
      });
    }
    if (isEditable) {
      setApplyNewDiscountSelected(true);
      setDiscount(skuDiscount);
    }
  }, [areBusinessDiscountsFetched, currentBusinessDiscounts.data, skuDiscount]);

  const isIncludedInBusinessDiscounts = useMemo(() => {
    if (applyNewDiscountSelected && areBusinessDiscountsFetched) {
      return currentBusinessDiscounts.data.some((bizDiscount) => (
        bizDiscount.name === discount.name
      ));
    }
  }, [applyNewDiscountSelected, currentBusinessDiscounts.data, discount.name, areBusinessDiscountsFetched]);
  
  const isDuplicateName = useMemo(
    () => skuDiscounts.some(
      (disc) => disc.name.trim() === discount.name?.trim(),
    ) && (discount.name?.trim() !== skuDiscount?.name?.trim()),
    [skuDiscounts, skuDiscount?.name, discount.name],
  );

  const isFormValid = useMemo(
    () => !(!discount.name
      || !discount.costType
      || !discount.discountType
      || (!discount.percent && !discount.dollars)
      || isDuplicateName),
    [
      discount.name,
      discount.costType,
      discount.discountType,
      discount.percent,
      discount.dollars,
      isDuplicateName,
    ],
  );

  const selectedBusinessDiscount = currentBusinessDiscounts?.data?.find((disc) => (
    disc.name === discount.name
  ))?.name || '';

  const isBusinessDiscountSelected = () => {
    if (applyNewDiscountSelected && areBusinessDiscountsFetched && skuDiscount?.name) {
      return currentBusinessDiscounts.data.some((bizDiscount) => (
        bizDiscount.name === discount.name
      ));
    }
    return false;
  };

  const onSelectBusinessDiscount = ({ row }: IndexPath) => {
    const isApplyNewDiscountSelected = row === 0;
    if (isApplyNewDiscountSelected) {
      const shouldKeepValue = !isBusinessDiscountSelected;
      setApplyNewDiscountSelected(true);
      setDiscount({
        ...discount,
        name: shouldKeepValue ? discount.name : '',
        dollars: shouldKeepValue ? discount.dollars : undefined,
        percent: shouldKeepValue ? discount.percent : undefined,
        discountType: shouldKeepValue ? discount.discountType : DiscountType.DOLLARS,
      });
    } else {
      setApplyNewDiscountSelected(false);
      const selectedDiscount = availableBusinessDiscounts[row - 1];
      setDiscount({
        ...discount,
        ...selectedDiscount,
      });
    }
  };

  const onSelectCostType = ({ row }: IndexPath) => {
    const selectedDiscountType = costTypeOptions[row].key;
    setDiscount({ ...discount, costType: selectedDiscountType });
  };

  const selectOptions = (
    <>
      <SelectItem
        accessoryLeft={(iconProps) => (
          <Icon name="Plus" status="basic" testID="apply-new-discount-icon" {...iconProps} />
        )}
        key="apply-new-discount"
        style={styles.apply}
        testID="business-discount-apply-new-discount"
        title={(
          <View>
            <Text>{translate('APPLY_NEW_DISCOUNT')}</Text>
          </View>
        )}
      />
      {!isBusinessDiscountsDataLoading
        ? availableBusinessDiscounts.map((disc, idx) => (
          <SelectItem
            key={disc.id}
            testID={`business-discount-${idx}`}
            title={(
              <View>
                <Text>{disc.name}</Text>
                <Text category="s2" style={styles.selectSubTitle}>
                  {StringUtility.formatDiscountAmount(disc)}
                </Text>
              </View>
            )}
          />
        )) : <></>}
    </>
  );

  const applyDiscount = () => {
    const baseDiscount: Pick<DiscountEndpoint.Save.Request, 'name' | 'costType'> = {
      name: discount.name,
      costType: discount.costType,
    };
    let discountToApply: DiscountEndpoint.Save.PercentDiscount
    | DiscountEndpoint.Save.DollarDiscount;
    if (discount.discountType === DiscountType.DOLLARS) {
      discountToApply = {
        ...baseDiscount,
        dollars: discount.dollars,
        discountType: DiscountType.DOLLARS,
      };
    } else {
      discountToApply = {
        ...baseDiscount,
        percent: discount.percent,
        discountType: DiscountType.PERCENT,
      };
    }
    onApply(discountToApply);
  };

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            onPress={onClose}
            testID="add-edit-sku-discount-modal-cancel-button"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            appearance={!isFormValid ? 'outline' : 'filled'}
            disabled={!isFormValid}
            onPress={() => applyDiscount()}
            testID="add-edit-sku-discount-modal-save-button"
          >
            {translate<string>('APPLY')}
          </Button>
        </>
      )}
      onClose={onClose}
      testID="add-edit-sku-discount-modal"
      title={translate(skuDiscount !== undefined ? 'EDIT_SKU_DISCOUNT' : 'ADD_SKU_DISCOUNT')}
      visible
    >
      <VSpacer size="3" />
      {(isDuplicateName || isIncludedInBusinessDiscounts) && (
        <View testID="add-edit-sku-discount-modal-duplicate-name-error">
          <Alert level="danger" text={translate('DISCOUNT_DUPLICATE')}
          />
          <VSpacer size="7" />
        </View>
      )}
      {!!currentBusinessDiscounts.data.length && (
        <>
          <Select
            isRequired
            label={translate('DISCOUNT')}
            onSelect={(idx: IndexPath | IndexPath[]) => onSelectBusinessDiscount(idx as IndexPath)}
            testID="preset-discount-select"
            value={selectedBusinessDiscount}
          >
            {selectOptions}
          </Select>
          <VSpacer size="8" />
        </>
      )}
      {applyNewDiscountSelected && (
        <>
          <Input
            defaultValue={discount.name}
            isRequired
            label={translate<string>('DISCOUNT_CUSTOM_NAME')}
            onChangeText={(name) => setDiscount({ ...discount, name })}
            testID="add-edit-sku-discount-modal-discount-name"
          />
          <VSpacer size="8" />
        </>
      )}
      <Select
        isRequired
        label={translate<string>('DISCOUNT_TYPE')}
        onSelect={(idx: IndexPath | IndexPath[]) => onSelectCostType(idx as IndexPath)}
        placeholder={translate<string>('SELECT_A_DISCOUNT_TYPE')}
        selectedIndex={discount.costType
          ? new IndexPath(costTypeOptions.findIndex((option) => (
            option.key === discount.costType
          )))
          : undefined}
        testID="add-edit-sku-discount-modal-discount-type"
        value={costTypeOptions.find((option) => option.key === discount.costType)?.title}
      >
        {
        costTypeOptions.map((option, index) => (
          <SelectItem key={option.key} testID={`add-edit-sku-type-dropdown-value-${index}`} title={option.title} />
        ))
      }
      </Select>
      {applyNewDiscountSelected && (
        <>
          <VSpacer size="8" />
          <DiscountInputSelect
            discount={discount as ApiDiscount}
            setDiscount={(updates) => setDiscount(updates)}
            testID="product-order-product"
          />
        </>
      )}
    </Modal>
  );
};
