import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Card, IndexPath } from '@ui-kitten/components';
import { Input, Select, SelectItem, NumericInput, ListModal, Text } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const ListModalDemo: FunctionComponent = () => {
  const [title, setTitle] = useState('List title');
  const [itemText, setItemText] = useState('Item');
  const [horizontal, setHorizontal] = useState(0);
  const [maxItems, setMaxItems] = useState(5);
  const [items, setItems] = useState(10);
  const [showMoreText, setShowMoreText] = useState('');

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
          <Input label="Title" onChangeText={setTitle} style={{ width: '25%', margin: 5 }} testID="demo-input" value={title} />
          <Select
            label="Horizontal"
            onSelect={(ip: IndexPath | IndexPath[]) => setHorizontal((ip as IndexPath).row)}
            style={{ width: '25%', margin: 5 }}
            testID="list-modal-demo-horizonal-selector"
            value={horizontal === 0 ? 'No' : 'Yes'}
          >
            <SelectItem key={0} testID={`list-modal-demo-no-dropdown-value-${0}`} title="No" />
            <SelectItem key={1} testID={`list-modal-demo-no-dropdown-value-${1}`} title="Yes" />
          </Select>
          <NumericInput
            label="maxItems"
            onChangeValue={(value) => setMaxItems(value)}
            precision={0}
            style={{ width: '25%', margin: 5 }}
            testID="demo-input"
            value={maxItems}
          />
          <Input label="showMoreText" onChangeText={setShowMoreText} style={{ width: '25%', margin: 5 }} testID="demo-input" value={showMoreText} />
          <NumericInput
            label="items"
            maxValue={100}
            onChangeValue={(value) => setItems(value)}
            precision={0}
            style={{ width: '25%', margin: 5 }}
            testID="demo-input"
            value={items}
          />
          <Input label="Item text" onChangeText={setItemText} style={{ width: '25%', margin: 5 }} testID="demo-input" value={itemText} />

        </View>
        <DemoContainer>
          <DemoBlock>
            <ListModal
              buttonShowMoreText={showMoreText.length ? showMoreText : null}
              horizontal={!!horizontal}
              items={Array.from({ length: items }, (_, index) => (
                <Text style={{ margin: 10 }}>{`${itemText} #${index + 1}`}</Text>
              ))}
              maxItems={maxItems}
              testID="list-modal-demo-dropdown-value"
              title={title}
            />
          </DemoBlock>
        </DemoContainer>
      </Card>
    </View>
  );
};
