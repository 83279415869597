import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { View } from 'react-native';
import { EmptyState, Text } from '@design';
import { useTranslation } from 'react-i18next';
import { ApiCompanyRevenueByStatus } from '@shared/interfaces/api/StatisticsEndpoint';
import { ContentType } from 'recharts/types/component/Label';
import { StringUtility, testId } from '../../../../utilities';
import {
  AXIS_COLOR,
  BAR_SIZE,
  CHART_COLORS,
  CHART_STYLE,
  ChartContainer,
  LABEL_BACKGROUND_COLOR,
  LabelText,
  RULES_COLOR,
} from './helpers';

export const SalesFunnelChart = ({
  total,
  orderDiscounts,
  accepted,
  unlabeled,
  shared,
  totalAfterDiscounts,
}: ApiCompanyRevenueByStatus) => {
  const [translate] = useTranslation(['dashboard', 'common']);
  const revenueData = [
    { name: translate('UNLABELED'), amount: unlabeled },
    { name: translate('SHARED'), amount: shared },
    { name: translate('ACCEPTED'), amount: accepted },
  ];

  const largest = Math.max(...revenueData.map((d) => d.amount));
  const chartData = revenueData.map((d) => ({
    ...d,
    spacer: (largest - d.amount) / 2,
    displayAmount: StringUtility.formatFlatCurrencyShortBasic(d.amount),
  }));

  const textColor = '#212121';

  const CustomBarLabel = (labelProps: {
    x: number,
    y: number,
    value: string,
    width: number,
    height: number,
    fill: string,
  }) => {
    return (
      <LabelText
        align="center"
        verticalAlign="center"
        x={labelProps.x + labelProps.width / 2}
        y={labelProps.y + (labelProps.height / 2)}
      >
        {labelProps.value}
      </LabelText>
    );
  };

  const CustomTooltip = (tooltipProps) => {
    const data = chartData.find((d) => d.name === tooltipProps.label);
    return (
      <View
        style={{
          backgroundColor: 'white',
          height: 60,
          justifyContent: 'space-between',
          padding: 5,
          borderColor: textColor,
          borderWidth: 1,
        }}
      >
        <Text
          {...testId('sales-funnel-tooltip-label')}
          style={{ color: textColor }}
        >
          {`${tooltipProps.label}:`}
        </Text>
        <Text
          {...testId('sales-funnel-tooltip-amount')}
          style={{ color: textColor }}
        >
          {StringUtility.formatFlatCurrency(data?.amount)}
        </Text>
      </View>
    );
  };

  const totalLabel = !total ? '' : translate('TOTAL_REVENUE_IN_FUNNEL', {
    total: StringUtility.formatFlatCurrencyShortBasic(total),
  });

  // at the moment we will not show the discount amount to the user
  // const discountLabel = translate(
  //   'TOTAL_AFTER_DISCOUNTS',
  //   {
  //     total: StringUtility.formatFlatCurrencyShortBasic(totalAfterDiscounts),
  //     discounts: StringUtility.formatFlatCurrencyShortBasic(orderDiscounts),
  //   },
  // );

  const discountLabel = (!orderDiscounts || !totalAfterDiscounts)
    ? '' : translate('ORDER_DISCOUNT_MESSAGE');

  return (
    <ChartContainer
      subTitle={`${totalLabel} ${discountLabel}`}
      testID="company-revenue-in-funnel-card"
      title={translate('COMPANY_REVENUE_IN_FUNNEL')}
    >
      {!total ? (
        <EmptyState testID="no-data" title={translate('THERE_IS_NO_DATA_TO_DISPLAY')} />
      ) : (
        <BarChart
          data={chartData}
          height={500}
          layout="vertical"
          margin={{ left: 10, bottom: 40 }}
          style={CHART_STYLE}
          width={600}
        >
          <CartesianGrid horizontal={false} stroke={RULES_COLOR} />
          <XAxis
            hide
            type="number"
            xAxisId="0"
          />
          <YAxis
            dataKey="name"
            fontSize="0.8em"
            orientation="left"
            stroke={AXIS_COLOR}
            type="category"
            yAxisId="0"
          />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: LABEL_BACKGROUND_COLOR }}
          />
          <Bar barSize={BAR_SIZE} dataKey="spacer" fill="transparent" stackId="a" />
          <Bar
            barSize={BAR_SIZE}
            dataKey="amount"
            fill={textColor}
            isAnimationActive={false}
            stackId="a"
          >
            <LabelList
              content={CustomBarLabel as ContentType}
              dataKey="displayAmount"
              fill={textColor}
              id="sales-funnel-bar-label"
            />
            {chartData.map((entry, index) => (
              <Cell fill={CHART_COLORS[index]} key={entry.name} />
            ))}
          </Bar>
        </BarChart>
      )}
    </ChartContainer>
  );
};
