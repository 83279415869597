import { BannerProps, Button, Icon, IconButton, LargeModal, useBanner, useToast } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { Permissions, RoleUtility } from '@shared/utils';
import { useLogo } from '../../../hooks/useLogo';
import { QueryKeys } from '../../../constants';
import { Environment, LinkingUtility, testId } from '../../../utilities';
import { DeliverableProductOrder } from './components/ViewDeliverable/DeliverableProductOrder';
import { Footer } from './components/ViewDeliverable/Footer';
import { GrowerDeliverableApi } from '../../../utilities/api';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { DeleteDeliverableDialog } from '../PrepareReview/GrowerDeliverableTab/components/DeleteDeliverable/DeleteDeliverableDialog';

type Data = ProductOrderDeliverableV0.Snapshot;
type Selections = ProductOrderDeliverableV0.Selections;

export interface ViewDeliverableModalProps {
  deliverablePublicId?: string,
  deliverableId: string,
  onClose: (event?) => Promise<void>,
  shared: boolean,
}

const ViewDeliverableModal: FC<ViewDeliverableModalProps> = ({
  deliverablePublicId,
  deliverableId,
  onClose,
  shared,
}) => {
  const [translate] = useTranslation(['common', 'productOrders', 'errors', 'deliverable']);
  const { createBanner } = useBanner();
  const { createToast } = useToast();
  const queryClient = useQueryClient();
  const { user: currentUser } = useAuthentication();
  const [
    deleteDeliverableModalVisible,
    setDeleteDeliverableModalVisible,
  ] = useState(false);

  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'success-error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) });

  const { data: deliverable } = useQuery(
    [QueryKeys.DELIVERABLE, deliverablePublicId],
    () => GrowerDeliverableApi.getPublicGrowerDeliverable<Data, Selections>(deliverablePublicId),
    {
      enabled: !!deliverablePublicId,
      onError: () => createBanner(bannerProps(translate('ERROR_FETCH_GROWERS'))),
    },
  );
  const { logo, error, isLoading } = useLogo(deliverable?.user?.businessId ?? '');
  const isSuperUser = RoleUtility.roleHasPermission(
    currentUser.userRole,
    Permissions.ACCESS_ALL_BUSINESSES,
  );
  const isOwner = deliverable?.user.id === currentUser.id;
  const canDeleteDeliverable = (!isSuperUser && isOwner && !shared);

  const handleDeleteDeliverable = async () => {
    try {
      await GrowerDeliverableApi.deleteDeliverable(deliverableId);
      await queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST);
      setDeleteDeliverableModalVisible(false);
      await onClose();
      createToast({
        children: `${translate('DELIVERABLE_DELETED')}`,
        status: 'success',
        testID: 'toast-content-element',
      });
    } catch (err) {
      createBanner(bannerProps((err as Error).message));
    }
  };

  return (
    <>
      <LargeModal
        footer={(
          <Footer
            onClose={onClose}
            onDownloadPdf={() => LinkingUtility.openUrl(
              `${Environment.get('API_URL')}/growerDeliverables/public/${deliverablePublicId}/pdf`,
              { newTab: true },
            )}
          />
        )}
        headerButton={() => canDeleteDeliverable && (
          <View style={{ justifyContent: 'flex-end' }}>
            <IconButton
              appearance="ghost"
              onPress={() => setDeleteDeliverableModalVisible(!deleteDeliverableModalVisible)}
              status="basic"
              testID="delete-button"
            >
              <Icon name="Trash" testID="delete-button-icon" />
            </IconButton>
          </View>
        )}
        logo={{
          error,
          isLoading,
          image: logo,
        }}
        pages={[
          <DeliverableProductOrder deliverable={deliverable} />,
        ]}
        title={deliverable?.name}
        titleOverline={translate('DELIVERABLE')}
        visible
        {...testId('deliverable-modal')}
      />
      <DeleteDeliverableDialog
        name={deliverable?.name}
        onClose={() => {
          setDeleteDeliverableModalVisible(false);
        }}
        onDelete={handleDeleteDeliverable}
        visible={deleteDeliverableModalVisible}
      />
    </>
  );
};

export default ViewDeliverableModal;
