import { Badge, HSpacer, Icon } from '@design';
import { OrderStatus } from '@shared/interfaces';
import { BadgeStatus } from '@theme/variant-interfaces/Status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { testId } from '../../../../utilities/testId';

const orderStatuses: { [k: string]: BadgeStatus } = {
  SHARED: 'basic',
  ACCEPTED: 'info',
  REJECTED: 'danger',
};

const styles = StyleSheet.create({
  badge: {
    alignSelf: 'baseline',
    textTransform: 'uppercase',
  },
  badgeContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    alignSelf: 'center',
  },
});

export interface ProductOrderStatusProps {
  iconCenter?: boolean,
  isCancelled?: boolean,
  status?: OrderStatus,
}
export function ProductOrderStatus ({ status, iconCenter, isCancelled }: ProductOrderStatusProps) {
  const [translate] = useTranslation(['productOrders']);

  return status === OrderStatus.UNLABELED ? (
    <View style={iconCenter && styles.icon}>
      <Icon
        {...testId('product-order-status-empty')}
        name="Minus"
      />
    </View>
  ) : (
    <View style={styles.badgeContainer}>
      <Badge
        {...testId('product-order-status')}
        status={orderStatuses[status]}
        style={styles.badge}
      >
        {translate<string>(status).toUpperCase()}
      </Badge>
      {isCancelled && (
        <>
          <HSpacer size="3" />
          <Icon name="Slash" status="danger" testID="product-order-status-cancelled" />
        </>
      )}
    </View>
  );
}
