import { TankMixDeliverableV0 as V0 } from '@shared/interfaces';
import { ApiDeliverablePublic } from '@shared/interfaces/api';
import React from 'react';
import { DeliverableRenderer } from '../../DeliverableRenderer';
import { TankMixDeliverableDesktopView } from './TankMixDeliverableDesktopView';
import { TankMixDeliverableMobileView } from './TankMixDeliverableMobileView';

export class TankMixDeliverableV0PublicRender implements DeliverableRenderer {
  private deliverable: ApiDeliverablePublic<V0.Snapshot, V0.Selections>;

  constructor (deliverable: ApiDeliverablePublic) {
    this.deliverable = deliverable;
  }
  getPlanName (): string {
    return this.deliverable.deliverableData.HEADER?.TANK_MIX_NAME?.data;
  }
  getGrowerName (): string {
    return this.deliverable.deliverableData.HEADER?.GROWER_NAME?.data;
  }
  getBusinessName (): string {
    return this.deliverable.deliverableData.HEADER?.BUSINESS_NAME?.data;
  }
  getBusinessLocation (): string {
    return this.deliverable.deliverableData.HEADER?.LOCATION_NAME?.data;
  }

  getDesktopView () {
    return (
      <TankMixDeliverableDesktopView
        deliverableId={this.deliverable.publicId}
        note={this.deliverable.note}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
      />
    );
  }

  getMobileView () {
    return (
      <TankMixDeliverableMobileView
        deliverableId={this.deliverable.publicId}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
      />
    );
  }
}
