import { SharedConfig } from '@shared/constants';
import { GrowerFarmEndpoint, ImportEndpoint } from '@shared/interfaces/api';
import qs from 'qs';
import { Client } from './Client';

export class GrowerFarmApi {
  static updateFarms (growerId: string, farmParams: GrowerFarmEndpoint.BulkCreate.Request)
    : Promise<GrowerFarmEndpoint.BulkCreate.Response> {
    return Client(`growers/${growerId}/bulkFarms`, {
      method: 'POST',
      body: farmParams,
    });
  }

  static async getFarms (growerId: string): Promise<GrowerFarmEndpoint.List.Response> {
    const filters: GrowerFarmEndpoint.List.Query = {
      limit: SharedConfig.MAX_PAGE_LIMIT,
    };
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`growers/${growerId}/farms?${query}`);
  }

  static async getCsvTemplate () {
    return Client('export/template/farms');
  }

  static async importFarms (fileInfo: any, growerId): Promise<ImportEndpoint.ImportResult> {
    const body = {
      csv: fileInfo.uri,
      growerId,
    };
    return Client('import/farms', {
      body,
      method: 'POST',
    });
  }
}
