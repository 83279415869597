import { Button, Header, Modal, Text, VSpacer } from '@design';
import { BusinessLocationCountryList } from '@shared/constants';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import DescriptionList, {
  DescriptionListDirection,
} from '../DescriptionList/DescriptionList';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';
import { ContactTagBadges } from '../CustomTags/ContactTagBadges';
import { DefaultTags } from '@shared/enums';

const formatContactInfo = (contact: ProductOrderDeliverableV0.Contact) => {
  const result = [];
  const {
    address1,
    address2,
    city,
    country,
    postalCode,
    state,
  } = contact;

  if (address1) {
    result.push(address1);
  }

  if (address2) {
    result.push(address2);
  }

  const cityStateInfo = [city, state].filter(Boolean).join(', ');
  const abbreviation = !country ? '' : BusinessLocationCountryList.find((countryItem) => countryItem.name === country)?.abbreviation ?? '';
  const countryInfo = !abbreviation ? '' : ` ${abbreviation}`;
  const postalCodeInfo = !postalCode ? '' : ` ${postalCode}`;

  result.push(`${cityStateInfo}${postalCodeInfo}${countryInfo}`);

  return result;
};

interface BillingShippingContactDetailsProps {
  contact?: ProductOrderDeliverableV0.Contact,
  title: string,
}

const BillingShippingContactDetails = ({
  contact,
  title,
}: BillingShippingContactDetailsProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const formattedTestId: string = title === 'Shipping contact' ? 'shipping-contact' : 'billing-contact';

  const styles = useStyleSheet({
    divider: {
      backgroundColor: 'text-hint-color',
      marginTop: 3,
    },
  });

  const billingShippingTags: string[] | undefined = contact?.tags?.filter((tag) => (
    tag === DefaultTags.BillingContact || tag === DefaultTags.ShippingContact
  ));

  return (
    <>
      <Header level="4" testID={`qq-header-${formattedTestId}`} title={title} />
      <Divider style={styles.divider} />
      <VSpacer size="5" />
      <DescriptionList direction={DescriptionListDirection.STACKED}>
        <DescriptionListItem
          label={translate<string>('NAME')}
          testID={`qq-${formattedTestId}-modal-name`}
          text={`${contact?.firstName || ''} ${contact?.lastName || ''}`}
          textAccessory={(
            <ContactTagBadges
              tags={billingShippingTags ?? []}
              testID="billing-shipping-contacts-modal"
            />
          )}
          textCategory="p2"
        />
        <DescriptionListItem
          label={translate<string>('EMAIL')}
          testID={`qq-${formattedTestId}-modal-email`}
          text={contact?.email}
          textCategory="p2"
        />
        <DescriptionListItem
          label={translate<string>('PHONE')}
          testID={`qq-${formattedTestId}-modal-phone`}
          text={contact?.telephone}
          textCategory="p2"
        />
        <View>
          <DescriptionListItem
            label={translate<string>('ADDRESS')}
            testID={`qq-${formattedTestId}-modal-address`}
            textCategory="p2"
          >
            {contact && (
              <View>
                {formatContactInfo(contact).map((line) => (
                  <Text category="p2" key={line}>
                    {line}
                  </Text>
                ))}
              </View>
            )}
          </DescriptionListItem>
        </View>
      </DescriptionList>
    </>
  );
};

interface BillingShippingContactsModalProps {
  billingContact?: ProductOrderDeliverableV0.Contact,
  onClose: () => void,
  shippingContact?: ProductOrderDeliverableV0.Contact,
  showModal: boolean,
}

export const BillingShippingContactsModal = ({
  billingContact,
  onClose,
  shippingContact,
  showModal,
}: BillingShippingContactsModalProps) => {
  const [translate] = useTranslation(['common']);

  return (
    <Modal
      footerAccessory={({
        secondaryButtonProp,
      }) => (
        <Button
          {...secondaryButtonProp}
          onPress={onClose}
          testID="qq-billing-shipping-contact-modal-close-button"
        >
          {translate<string>('CLOSE')}
        </Button>
      )}
      hideCloseButton
      onClose={onClose}
      testID="qq-billing-shipping-contact-modal"
      title={translate('CONTACTS')}
      visible={showModal}
    >
      <BillingShippingContactDetails
        contact={billingContact}
        title={translate<string>('BILLING_CONTACT')}
      />
      <VSpacer size="6" />
      <BillingShippingContactDetails
        contact={shippingContact}
        title={translate<string>('SHIPPING_CONTACT')}
      />
    </Modal>
  );
};
