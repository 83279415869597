import { DataPoint, ViewRow, VSpacer } from '@design';
import { ApiGrowerListItem } from '@shared/interfaces/api';
import { toShortDate } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fixedWidth: {
    flex: 1,
    maxWidth: '25%',
  },
  flexing: {
    flex: 1,
  },
});

export const CustomerRowDetails = (props: ApiGrowerListItem) => {
  const {
    cellPhoneNumber,
    createdAt,
    externalDisplayId,
    externalId,
    isActive,
    updatedAt,
  } = props;
  const [translate] = useTranslation(['growers', 'common']);

  return (
    <>
      <ViewRow style={styles.flexing}>
        <DataPoint
          flex
          label={translate<string>('CELL_PHONE_NUMBER')}
          style={styles.flexing}
          testID="cell-phone-number"
        >
          {cellPhoneNumber || ''}
        </DataPoint>
        <DataPoint
          flex
          label={translate<string>('LAST_DELIVERABLE_SENT_NAME')}
          style={styles.flexing}
          testID="last-deliverable-sent-name"
        >
          {' '}
        </DataPoint>
        <DataPoint
          flex
          label={translate<string>('DATE_OF_LAST_DELIVERABLE_SENT')}
          style={styles.flexing}
          testID="date-of-last-deliverable-sent"
        >
          {' '}
        </DataPoint>
        <DataPoint
          flex
          label={translate<string>('DATE_CREATED')}
          style={styles.flexing}
          testID="date-created"
        >
          {toShortDate(createdAt)}
        </DataPoint>
      </ViewRow>
      <VSpacer size="8" />
      <ViewRow style={styles.flexing}>
        <DataPoint
          flex
          label={translate<string>('DATE_UPDATED')}
          style={styles.fixedWidth}
          testID="date-updated"
        >
          {toShortDate(updatedAt)}
        </DataPoint>
        <DataPoint
          flex
          label={translate<string>('STATUS')}
          style={styles.fixedWidth}
          testID="status"
        >
          {isActive ? translate<string>('ACTIVE') : translate<string>('INACTIVE')}
        </DataPoint>
        <DataPoint
          flex
          label={translate<string>('CUSTOMER_ID')}
          style={styles.fixedWidth}
          testID="customer-id"
        >
          {`${externalId ?? ''}${externalDisplayId ? ` (${externalDisplayId})` : ''}`}
        </DataPoint>
      </ViewRow>
    </>
  );
};
