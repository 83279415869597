import { View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Header, HSpacer, Icon, IconButton, Text, TextLink, VSpacer } from '@design';
import { ApiGrowerFarm, ApiGrowerField } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
import { FarmUtility } from '@shared/utils';
import { StringUtility } from '../../../../utilities';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';
import CropZoneRowDetails from './CropZoneRowDetails';

interface FarmCardProps {
  farm: ApiGrowerFarm,
  index?: number,
  onDelete: () => void,
  onEdit: () => void,
  showDetails?: boolean,
}

type FieldDataType = (RowMeta & ApiGrowerField);

export const FarmCard = ({
  farm,
  index,
  onDelete,
  onEdit,
  showDetails = false,
}: FarmCardProps) => {
  const [translate] = useTranslation(['common', 'growers']);
  const [showFieldDetails, setShowFieldDetails] = useState(showDetails);
  const [fieldData, setFieldData] = useState<FieldDataType[]>(null);
  const localize = StringUtility.localizeNumber;
  const farmArea = useMemo(() => FarmUtility.getFarmArea(farm),
    [farm]);

  useEffect(() => {
    setShowFieldDetails(showDetails);
  }, [showDetails]);

  useEffect(() => {
    setFieldData(farm.growerFields.map((field, idx) => ({
      ...field,
      hasDetails: !!field.cropZones.length,
      rowId: `row:${idx}|${field.id}`,
      rowDetailId: `row-drawer:${idx}|${field.id}`,
    })));
  }, [farm]);

  const containsCropZones = useMemo(() => {
    return fieldData && fieldData.some((field) => field.cropZones.length > 0);
  }, [fieldData]);

  const fieldColumns: IColumn<(RowMeta & ApiGrowerField)>[] = [
    !containsCropZones && {
      columnId: 'spacer',
      header: {
        render: <HSpacer size="11" />,
      },
      render: () => null,
      flex: 0.11,
      align: 'left',
    },
    {
      columnId: 'fieldName',
      header: {
        render: translate('FIELD_NAME'),
      },
      render: (field) => field.fieldName,
      flex: 1,
    },
    {
      columnId: 'totalAcres',
      header: {
        render: translate('TOTAL_ACRES'),
        align: 'right',
      },
      render: (field) => localize(field.areaValue, 3),
      align: 'right',
      flex: 1,
    },
  ];

  return (
    <Card style={{ flex: 1 }} testID={`farm-card-${index}`}>
      <Header
        childrenAlign="flex-start"
        level="2"
        subTitle={farm.growerFields.length > 1
          ? translate<string>('FIELDS_COUNT', { count: farm.growerFields.length })
          : translate<string>('FIELD_COUNT')}
        subTitleHint
        testID={`farm-name-header-${index}`}
        title={farm.farmName}
        titleMaxWidth="100%"
      >
        <Text
          category="h6"
          numberOfLines={1}
          testID={`total-acres-${index}`}
        >
          {translate<string>(
            'TOTAL_ACRES_COUNT',
            { acres: localize(Number(farmArea), 3) },
          )}
        </Text>
      </Header>
      <VSpacer size="5" />
      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TextLink
          accessoryRight={(chevronProps) => (
            <Icon
              {...chevronProps}
              name={showFieldDetails ? 'ChevronUp' : 'ChevronDown'}
              testID={`show-hide-details-icon-${index}`}
            />
          )}
          appearance="secondary"
          category="p2"
          onPress={() => setShowFieldDetails(!showFieldDetails)}
          testID={`show-hide-details-${index}`}
        >
          {translate<string>((showFieldDetails) ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
        </TextLink>
        <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
          <IconButton
            appearance="ghost"
            onPress={onEdit}
            size="large"
            status="basic"
            testID={`edit-icon-${index}`}
          >
            Edit
          </IconButton>
          <HSpacer size="2" />
          <IconButton
            appearance="ghost"
            onPress={onDelete}
            size="large"
            status="basic"
            testID={`delete-icon-${index}`}
          >
            Trash
          </IconButton>
        </View>
      </View>
      {showFieldDetails && (
        <SortableTable<ApiGrowerField & RowMeta>
          columns={fieldColumns}
          data={fieldData}
          rowDetail={!!containsCropZones && ((props) => {
            if (props.cropZones) {
              return <CropZoneRowDetails {...props.cropZones} />;
            }
            return null;
          })}
          tableId="field-table"
          testID="field-parent-table"
        />
      )}
    </Card>
  );
};
