import { Button, CenteredSpinner, Logo, Text, VSpacer } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, useWindowDimensions, View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useLogo } from '../../../../hooks/useLogo';
import DescriptionList, {
  DescriptionListDirection,
} from '../../../../src/components/shared/DescriptionList/DescriptionList';
import DescriptionListItem
  from '../../../../src/components/shared/DescriptionList/DescriptionListItem';
import { PoweredByGrowersLogoMobile } from '../../../../svg/PoweredByGrowersLogoMobile';
import { PoweredByGrowersLogoMobileCentered } from '../../../../svg/PoweredByGrowersLogoMobileCentered';
import { DataPoint } from '../../../../ui-components/components/DataPoint/DataPoint';
import { MobileBox } from '../../../../ui-components/components/MobileBox/MobileBox';
import { QuadRow } from '../../../../ui-components/components/QuadRow/QuadRow';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { GrowerDeliverableApi } from '../../../../utilities/api';

interface Props {
  snapshot: ProductOrderDeliverableV0.Snapshot,
  selections: ProductOrderDeliverableV0.Selections,
  deliverableId: string,
  deliverableName: string,
}

export const ProductOrderDeliverableMobileView: FunctionComponent<Props> = ({
  snapshot,
  selections,
  deliverableId,
  deliverableName,
}) => {
  const [translate] = useTranslation(['deliverable', 'productOrders', 'common']);
  const windowDimensions = useWindowDimensions();
  const isLandscape = windowDimensions.width > windowDimensions.height;

  const getUser = async () => GrowerDeliverableApi.getPublicGrowerDeliverable(deliverableId);
  const [user, setUser] = useState(null);

  useQuery([QueryKeys.DELIVERABLE, deliverableId], getUser, {
    onSuccess: (res) => setUser(res.user),
  });

  const { logo, error, isLoading } = useLogo(user?.businessId);

  const styles = useStyleSheet({
    mainContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      flexGrow: 1,
      flexShrink: 0,
    },
    line: {
      marginTop: 24,
      marginBottom: 16,
      borderTopWidth: 1,
      borderTopColor: 'text-hint-color',
    },
    center: {
      alignItems: 'center',
    },
    centeredContent: {
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    row: {
      flexDirection: 'row',
    },
    logoDivider: {
      marginHorizontal: 24,
      height: 48,
      width: 1,
      backgroundColor: 'text-hint-color',
    },
    disclaimerMessage: {
      maxWidth: 226,
      textAlign: 'center',
    },
  });

  const { header, overview, products } = snapshot;
  const {
    contactPhone,
    location,
    owner,
    acreage,
  } = selections.basicInfo;
  const { retailPrice, totalCost } = selections.costSummary;

  const showOwner = owner && !!(header.businessUserName);
  const showPhone = contactPhone && !!(header.businessUserPhone);
  const showAcreage = acreage && !!(overview.acreage);
  const showPriceAc = retailPrice && acreage && !!(overview.costPerAcre);

  const dataValues = [
    showAcreage && (
      <DataPoint
        label={translate<string>('ACREAGE')}
        value={StringUtility.localizeNumber(overview.acreage, 3)}
      />
    ),
    <DataPoint
      label={translate<string>('PRODUCT_COUNT')}
      value={StringUtility.localizeNumber(products?.length ?? 0)}
    />,
    showPriceAc && (
      <DataPoint
        label={translate<string>('PRICE_AC')}
        value={StringUtility.formatCurrency(overview.orderTotalPerAcre)}
      />
    ),
    totalCost && (
      <DataPoint
        label={translate<string>('TOTAL_PRICE')}
        value={StringUtility.formatCurrency(overview.orderTotal)}
      />
    ),
  ].filter((dataValue) => !!dataValue);

  return (
    <ScrollView contentContainerStyle={styles.mainContainer} nativeID="vscroll">
      <VSpacer size="8" />
      <Text appearance="hint" category="overline">{translate<string>('DELIVERABLE').toUpperCase()}</Text>
      <VSpacer size="4" />
      <Text category="h3">{deliverableName}</Text>

      <View style={styles.line} />

      <DescriptionList direction={DescriptionListDirection.STACKED}>
        <DescriptionListItem
          accessoryLabel="GrowersPlanning"
          label={translate<string>('CUSTOMER')}
          text={header.growerName}
          textCategory="p2"
        />
        {showOwner && (
          <DescriptionListItem
            accessoryLabel="Person"
            label={translate<string>('SALESPERSON')}
            text={header.businessUserName}
            textCategory="p2"
          />
        )}
        <DescriptionListItem
          accessoryLabel="GrowersBusiness"
          label={translate<string>('BUSINESS')}
          text={header.businessName}
          textCategory="p2"
        />
        {location && (
          <DescriptionListItem
            accessoryLabel="PaperPlane"
            label={translate<string>('LOCATION')}
            text={header.locationName || translate('CORPORATE_LOCATION')}
            textCategory="p2"
          />
        )}
        {showPhone && (
          <DescriptionListItem
            accessoryLabel="Phone"
            label={translate<string>('PHONE')}
            text={header.businessUserPhone}
            textCategory="p2"
          />
        )}
      </DescriptionList>

      {!isLandscape && (
        <>
          <VSpacer size="10" />
          <VSpacer size="4" />
        </>
      )}
      {isLandscape && <VSpacer size="12" />}

      {!isLandscape && <MobileBox dataValues={dataValues} paddingBottom={0} paddingTop={0} />}
      {isLandscape && (
        <View style={styles.centeredContent}>
          <QuadRow cells={dataValues} />
        </View>
      )}

      <VSpacer size="8" />

      <View style={styles.center}>
        <Text category="p2" style={styles.disclaimerMessage} testID="include-all-products-disclaimer">
          {translate<string>('PAC_INCLUDE_ALL_PRODUCTS_DISCLAIMER_SHORT')}
        </Text>
        <VSpacer size="8" />
        <Button
          onPress={() => LinkingUtility.openUrl(
            `${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`,
          )}
          size="large"
          testID="view-pdf-button"
        >
          {translate<string>('VIEW_PDF')}
        </Button>
      </View>

      <VSpacer size="8" />

      <View style={styles.center}>
        {isLoading && <CenteredSpinner />}
        {
          !!logo && (
            <View style={styles.row}>
              <Logo
                padding={0}
                shape="square"
                size="large-rectangle"
                source={logo}
                testID="large-modal-logo"
              />
              <View style={styles.logoDivider} />
              <PoweredByGrowersLogoMobile />
            </View>
          )
        }
        {
          ((!logo || error) && !isLoading) && (<PoweredByGrowersLogoMobileCentered />)
        }
      </View>
    </ScrollView>
  );
};
