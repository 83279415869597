/* eslint-disable @typescript-eslint/no-use-before-define */
import { Card, HSpacer, Text, VSpacer } from '@design';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export const CHART_COLORS = [
  '#206FDB',
  '#05DAF5',
  '#A848CC',
  '#D0D004',
  '#FB7268',
  '#0097A7',
  '#ADD5F7',
  '#BF360C',
  '#8C9EFF',
  '#FAC37F',
];
export const BAR_SIZE = 48;
export const TICK_WIDTH = 5;
export const RULES_COLOR = GrowersDarkTheme['color-basic-transparent-100'];
export const TICKS_COLOR = GrowersDarkTheme['color-basic-200'];
export const AXIS_COLOR = GrowersDarkTheme['color-basic-200'];
export const LABEL_COLOR = GrowersDarkTheme['color-basic-900'];
export const LABEL_BACKGROUND_COLOR = 'rgba(33, 33, 33, 0.5)';
export const CHART_STYLE = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  Card: {
    marginRight: 32,
    marginBottom: 32,
  },
  Column: {
    flexDirection: 'column',
  },
  Row: {
    flexDirection: 'row',
  },
  LegendLabel: {
    flexDirection: 'row',
  },
  LegendLabelIcon: {
    width: 20,
    height: 20,
  },
  LabelText: {
    backgroundColor: LABEL_BACKGROUND_COLOR,
    position: 'relative',
    width: 'max-content',
    padding: 4,
  },
});

export interface ChartContainerProps {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  legendPosition?: 'top' | 'bottom' | 'right';
  legend?: React.ReactNode;
  testID?: string;
}
export function ChartContainer ({
  children,
  title,
  subTitle,
  legendPosition = 'bottom',
  legend,
  testID,
}: ChartContainerProps) {
  const vertical = legendPosition === 'right';
  const showLegend = !!legend;

  return (
    <Card
      style={styles.Card}
      subTitle={subTitle}
      testID={testID}
      title={title}
    >
      <View style={vertical ? styles.Row : styles.Column}>
        {(showLegend && legendPosition === 'top') && (
          <>
            <Legend vertical={vertical}>
              {legend}
            </Legend>
            <LegendSpacer />
          </>
        )}
        <View>{children}</View>
        {(showLegend && legendPosition !== 'top') && (
          <>
            <LegendSpacer vertical={!vertical} />
            <Legend vertical={vertical}>
              {legend}
            </Legend>
          </>
        )}
      </View>
    </Card>
  );
}

interface LegendContextProps {
  vertical?: boolean;
}
export const LegendContext = React.createContext<LegendContextProps | null>(null);

export interface LegendProps extends LegendContextProps {
  children: React.ReactNode;
}
export function Legend ({ vertical, children }: LegendProps) {
  return (
    <LegendContext.Provider value={{ vertical }}>
      <View style={vertical ? styles.Column : styles.Row}>
        {children}
      </View>
    </LegendContext.Provider>
  );
}

export interface LegendLabelProps {
  text: string;
  color: string;
}
export function LegendLabel ({ text, color }: LegendLabelProps) {
  const context = React.useContext(LegendContext);
  if (context === undefined) {
    throw new Error('LegendLabel must be used within Legend');
  }

  return (
    <>
      <View style={styles.LegendLabel}>
        <View style={[styles.LegendLabelIcon, { backgroundColor: color }]} />
        <HSpacer size="4" />
        <Text appearance="hint">{text}</Text>
      </View>
      <LegendSpacer vertical={context.vertical} />
    </>
  );
}

export interface LegendSpacerProps {
  vertical?: boolean;
}
export function LegendSpacer ({ vertical }: LegendSpacerProps) {
  return vertical ? <VSpacer size="8" /> : <HSpacer size="8" />;
}

export interface LabelTextProps {
  x: number;
  y: number;
  children: string;
  align?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'center' | 'bottom';
}
export function LabelText ({ x, y, children, align, verticalAlign }: LabelTextProps) {
  const transform = [];
  if (align) {
    let translateX: string | number = 0; // left
    if (align === 'center') {
      translateX = '-50%';
    } else if (align === 'right') {
      translateX = '-100%';
    }

    transform.push({ translateX });
  }
  if (verticalAlign) {
    let translateY: string | number = 0; // bottom
    if (verticalAlign === 'center') {
      translateY = '-50%';
    } else if (verticalAlign === 'top') {
      translateY = '-100%';
    }

    transform.push({ translateY });
  }

  return (
    <foreignObject style={{ overflow: 'visible', width: 1, height: 1 }} x={x} y={y}>
      <View style={[styles.LabelText, { transform }]}>
        <Text>{children}</Text>
      </View>
    </foreignObject>
  );
}
