import { Linking } from 'react-native';

export class LinkingUtility {
  static openUrl (url: string, options: { newTab?: boolean, sameTab?: boolean } = {}) {
    if (options.newTab) {
      window.open(url, '_blank');
    } else if (options.sameTab) {
      window.location.href = url;
    } else {
      Linking.openURL(url);
    }
  }

  /**
   * Prompts a telephone call
   * @param phoneNumber The number to calls
   */
  static callTelephone (phoneNumber: string) {
    Linking.openURL(`tel:${phoneNumber}`);
  }

  /**
   * Opens the email client to send an email
   * @param emailAddress The emaill address to send to
   */
  static openEmail (emailAddress: string) {
    Linking.openURL(`mailto:${emailAddress}`);
  }

  static openMaps (address: string) {
    const mapsLink = `https://maps.google.com?q=${address}`;
    Linking.openURL(mapsLink);
  }
}
