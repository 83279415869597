import React, { FunctionComponent, useEffect } from 'react';

const truckImg = require('./ag_truck.png');

export const TruckGame: FunctionComponent = () => {
  const run = () => {
    const canvas = document.getElementById('game-canvas') as any;
    const ctx = canvas.getContext('2d');

    canvas.width = document.body.clientWidth;
    canvas.height = document.body.clientHeight;

    const groundY = canvas.height / 2;
    const jumpHeldAcceleration = -0.7;
    const maxGravityAcceleration = 20;
    const gravity = 0.09;
    const maxJumps = 1;
    const initialJumpAcceleration = -22;

    let startTime = Date.now();
    let score = 0;
    let isGameOver = false;
    let canReset = false;
    let delay = 0;

    const player = {
      x: 0,
      y: 0,
      w: 279,
      h: 190,
      jumps: 0,
      isJumping: false,
      wantsToJump: false,
      isFalling: false,
      isJumpHeld: false,
      gravityAcceleration: 0,
    };

    const obstacle = {
      x: 0,
      y: 0,
      w: 50,
      h: 120,
      speed: 0,
      jumpedOver: false,
    };

    const image = new Image(player.w, player.h);
    image.src = truckImg;

    const reset = () => {
      player.x = (canvas.width / 2) - (player.w / 2);
      player.isJumping = false;
      player.wantsToJump = false;
      player.isFalling = false;
      player.gravityAcceleration = 0;
      player.isJumpHeld = false;
      obstacle.x = canvas.width;
      obstacle.y = (canvas.height / 2) - obstacle.h;
      obstacle.speed = 0.7;
      obstacle.jumpedOver = false;
      isGameOver = false;
      canReset = false;
      score = 0;
      delay = 500;
    };

    reset();

    const randomIntFromInterval = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const jump = () => {
      player.jumps += 1;
      player.gravityAcceleration = initialJumpAcceleration;
      player.isJumping = true;
      player.wantsToJump = false;
    };

    const stopJumping = () => {
      player.isJumping = false;
      player.gravityAcceleration = 0;
      player.jumps = 0;
    };

    const handlePlayerObstacleCollision = () => {
      if (player.x + player.w > obstacle.x && player.x < obstacle.x + obstacle.w) {
        if (player.y + player.h > obstacle.y && player.y < obstacle.y + obstacle.h) {
          isGameOver = true;
          canReset = !player.isJumpHeld;
        }
      }
    };

    const onPressDown = () => {
      if (!isGameOver) {
        if (!player.isJumpHeld) {
          player.wantsToJump = true;
        }
        player.isJumpHeld = true;
      }
    };

    const onPressUp = () => {
      if (isGameOver) {
        if (canReset) {
          reset();
        }
      } else {
        player.isJumpHeld = false;
        player.wantsToJump = false;
      }
      canReset = true;
    };

    const onKeyDown = (e) => {
      if (e.keyCode === 32) {
        onPressDown();
      }
      e.preventDefault();
      return false;
    };

    const onKeyUp = (e) => {
      if (e.keyCode === 32) {
        onPressUp();
      }
      e.preventDefault();
      return false;
    };

    const update = (diff) => {
      if (isGameOver) {
        return;
      }

      if (delay === 0) {
        obstacle.x -= diff * obstacle.speed;
      } else {
        delay -= diff;
        if (delay < 0) {
          delay = 0;
        }
      }

      if (obstacle.x < canvas.width / 2 && !obstacle.jumpedOver) {
        score += 1;
        obstacle.jumpedOver = true;

        if (score % 7 === 0) {
          obstacle.speed += 0.1;
        }
      }

      if (obstacle.x < -200) {
        obstacle.jumpedOver = false;
        obstacle.x = canvas.width;
        obstacle.h = randomIntFromInterval(50, 150);
        obstacle.w = randomIntFromInterval(30, 60);
        obstacle.y = (canvas.height / 2) - obstacle.h;
      }

      if (player.isJumping && player.isJumpHeld) {
        player.gravityAcceleration += jumpHeldAcceleration;
      }

      const diffY = diff * gravity;
      player.gravityAcceleration += diffY;
      player.gravityAcceleration = Math.min(player.gravityAcceleration, maxGravityAcceleration);

      player.y += player.gravityAcceleration;

      if (player.y + player.h >= groundY) {
        stopJumping();
        player.y = groundY - player.h;
      }

      handlePlayerObstacleCollision();

      if (maxJumps > 1) {
        if (player.wantsToJump && player.jumps < maxJumps) {
          jump();
        }
      } else {
        if (player.wantsToJump && !player.isJumping && !player.isFalling) {
          jump();
        }
      }

      player.isFalling = player.gravityAcceleration > 0;

      if (player.x < 0) {
        player.x = 0;
      }
    };

    const draw = () => {
      ctx.fillStyle = '#C1CCDC';
      ctx.fillRect(0, 0, canvas.width, canvas.height / 2);

      ctx.fillStyle = '#5C472C';
      ctx.fillRect(0, canvas.height / 2, canvas.width, canvas.height / 2);

      ctx.fillStyle = '#755D34';
      ctx.fillRect(0, canvas.height / 2, canvas.width, 15);

      ctx.font = '32px Roboto';
      ctx.fillText(`SCORE: ${score}`, 50, 50);

      if (isGameOver) {
        ctx.font = '32px Roboto';
        ctx.fillText('GAME OVER', 50, 100);
      }

      ctx.drawImage(image, player.x, player.y);

      ctx.fillRect(obstacle.x, obstacle.y, obstacle.w, obstacle.h);
    };

    const gameLoop = (diff) => {
      // eslint-disable-next-line no-restricted-globals
      if (diff < 0 || isNaN(diff)) {
        return;
      }

      update(diff);
      draw();
    };

    document.addEventListener('keydown', onKeyDown, false);
    document.addEventListener('keyup', onKeyUp, false);
    document.addEventListener('touchstart', onPressDown, false);
    document.addEventListener('touchend', onPressUp, false);

    const animate = (timestamp: number = null) => {
      const drawStart = (timestamp || Date.now()) as number;
      const diff = drawStart - startTime;
      startTime = drawStart;
      requestAnimationFrame(animate);
      gameLoop(diff);
    };

    animate();
  };

  useEffect(() => { run(); }, []);

  return <canvas id="game-canvas" />;
};
