import { PropsService } from '@ui-kitten/components/devsupport';

export class PropsServiceHelper {
  static allWithPrefixMapped (source: Record<string, any>, prefix: string): Record<string, any> {
    const withPrefix = PropsService.allWithPrefix(source, prefix);
    const regex = new RegExp(`^${prefix}`);
    Object.keys(withPrefix).forEach((key) => {
      let newKey = key.replace(regex, '');
      newKey = newKey[0].toLowerCase() + newKey.substr(1);
      withPrefix[newKey] = withPrefix[key];
      delete withPrefix[key];
    });
    return withPrefix;
  }
}
