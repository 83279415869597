/*
 * SortableTableLite is an attempt at a performant copy of the sortable table.
 * The main difference being this table does not render drawer content unless
 * the drawer is expanded. It also does not animate drawer opening or closing.
 */

import * as _ from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { testId } from '../../utilities/testId';
import { Sizing, ThemeColors } from '../../constants';
import { Button, Icon, Spinner, Text } from '../../ui-components';
import { ISortableTableRowLiteProps, SortableTableRowLite } from './SortableTableRowLite';

const styles = StyleSheet.create({
  tableHead: {
    backgroundColor: ThemeColors.MID_GRAY,
    flexDirection: 'row',
    borderTopLeftRadius: Sizing.HALF_SPACING,
    borderTopRightRadius: Sizing.HALF_SPACING,
    paddingHorizontal: Sizing.DOUBLE_SPACING,
  },
  tableHeadInline: { paddingHorizontal: Sizing.HALF_SPACING },
  tableHeadInDrawer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  tableHeaderCell: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: Sizing.BASE_SPACING,
  },
  tableHeaderCellInline: { paddingVertical: Sizing.HALF_SPACING },
  tableHeaderText: {
    fontWeight: 'bold',
    alignItems: 'center',
    display: 'flex',
    color: ThemeColors.TEXT,
    textDecorationLine: 'none',
  },
  tableHeaderIcon: {
    width: 1.5 * Sizing.BASE_SPACING,
    height: 1.5 * Sizing.BASE_SPACING,
  },
  tableColumns: { flexDirection: 'row' },
  tableBodyText: {
    padding: Sizing.BASE_SPACING,
    justifyContent: 'center',
  },
  tableBodyActions: {
    paddingVertical: Sizing.BASE_SPACING,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableBodyChevron: {
    paddingVertical: Sizing.BASE_SPACING,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tableBodyChevronInline: { paddingVertical: Sizing.QUARTER_SPACING },
  tableIcon: {
    width: Sizing.EM,
    height: Sizing.EM,
  },
  loadMoreContainer: {
    marginTop: Sizing.BASE_SPACING,
    alignItems: 'center',
  },
});

export interface ISortableTableHeaderLiteProps {
  label: string;
  field: string;
  sortable?: boolean;
  sorting?: boolean;
  sortDirection?: string;
  nativeID?: string;
  contentStyle?: StyleProp<ViewStyle>;
  dateContent?: boolean;
}

export interface ISortableTableLiteProps {
  headers?: ISortableTableHeaderLiteProps[];
  rows: ISortableTableRowLiteProps[];
  widths?: number[];
  inlineInput?: boolean;
  inDrawer?: boolean;
  page?: number;
  lastPage?: number;
  onSort?: (field: string, direction: string) => void;
  loadNextPage?: (event?) => void;
  loading?: boolean;
  nativeID?: string;
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  numberOfRows?: number;
  includeDrawerToggleSpacing?: boolean;
  setExpandRowRef?: (callback: (index: number) => void) => void;
  setCollapseRowRef?: (callback: (index: number) => void) => void;
  setToggleRowRef?: (callback: (index: number) => void) => void;
}

export const SortableTableLite: FC<ISortableTableLiteProps> = ({
  rows: propRows,
  widths: propWidths,
  onSort,
  headers,
  page,
  lastPage,
  loading,
  loadNextPage,
  numberOfRows,
  inlineInput,
  includeDrawerToggleSpacing,
  containerStyle,
  inDrawer,
  contentStyle,
  nativeID,
  setCollapseRowRef,
  setExpandRowRef,
  setToggleRowRef,
}) => {
  const [allExpanded, setAllExpanded] = useState(false);
  const [rows, setRows] = useState(propRows);
  const widths = propWidths || _.map(propRows, () => 1);

  useEffect(() => {
    setRows((currentRows) => propRows.map((row) => {
      const existingRow = currentRows.find((r) => r.key === row.key);
      if (!existingRow) {
        return row;
      }
      return {
        ...existingRow,
        ...row,
      };
    }));
  }, [propRows]);

  /**
   * Toggles the expansion of all rows
   */
  const toggleAllExpanded = () => {
    setAllExpanded(!allExpanded);
    setRows(_.map(rows, (row) => _.set(row, 'expanded', !allExpanded)));
  };

  const expandRow = useCallback((index: number) => {
    setAllExpanded(false);
    setRows((prev) => (
      prev.map((row, i) => (
        i === index
          ? {
            ...row,
            expanded: true,
          }
          : row
      ))
    ));
  }, []);

  useEffect(() => {
    if (setExpandRowRef) {
      setExpandRowRef(expandRow);
    }
  }, [expandRow, setExpandRowRef]);

  const collapseRow = useCallback((index: number) => {
    setAllExpanded(false);
    setRows((prev) => (
      prev.map((row, i) => (
        i === index
          ? {
            ...row,
            expanded: false,
          }
          : row
      ))
    ));
  }, []);

  useEffect(() => {
    if (setCollapseRowRef) {
      setCollapseRowRef(collapseRow);
    }
  }, [collapseRow, setCollapseRowRef]);

  /**
   * Toggles the expansion of a single row
   * @param index The index of the row
   */
  const toggleRow = useCallback((index: number) => {
    setAllExpanded(false);
    setRows((prev) => (
      prev.map((row, i) => (
        i === index
          ? {
            ...row,
            expanded: !row.expanded,
          }
          : row
      ))
    ));
  }, []);

  useEffect(() => {
    if (setToggleRowRef) {
      setToggleRowRef(toggleRow);
    }
  }, [setToggleRowRef, toggleRow]);

  /**
   * Dispatches onSort when a header is clicked
   * @param header The header to sort by
   */
  const sortByHeader = (header: ISortableTableHeaderLiteProps) => {
    if (header.sortable && onSort) {
      const selectedHeader = _.find(headers, { field: header.field });
      let sortDirection = selectedHeader.dateContent ? 'desc' : 'asc';
      if (selectedHeader.sortDirection) {
        sortDirection = selectedHeader.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      onSort(selectedHeader.field, sortDirection);
    }
  };

  const LoadMoreButton = () => (
    <>
      {!_.isUndefined(page)
        && page < lastPage
        && !loading && (
          <View style={styles.loadMoreContainer}>
            <Button {...testId('load-more')} onPress={loadNextPage} status="secondary">
              Load More
            </Button>
          </View>
      )}
      {loading && <Spinner size="giant" />}
    </>
  );

  // major optimization if we memoize the rows
  const tableRows = useMemo(
    () => rows.slice(0, numberOfRows).map((row, i) => (
      <SortableTableRowLite
        index={i}
        {...testId(`row:${i}|${row.key}|`)}
        actions={row.actions}
        columns={row.columns}
        disableToggleOnRowPress={row.disableToggleOnRowPress}
        drawer={row.drawer}
        drawerPadding={row.drawerPadding}
        drawerProps={{
          ...testId(`row-drawer:${i}|${row.key}`),
        }}
        dynamicDrawer={row.dynamicDrawer}
        expanded={allExpanded || row.expanded}
        includeDrawerToggleSpacing={includeDrawerToggleSpacing
          && (rows.some((r) => r.drawer))}
        inlineInput={inlineInput}
        key={row.key}
        toggleExpanded={toggleRow}
        widths={propWidths}
      />
    )),
    [
      rows,
      numberOfRows,
      inlineInput,
      propWidths,
      includeDrawerToggleSpacing,
      toggleRow,
      allExpanded,
    ],
  );

  return (
    <ScrollView
      stickyHeaderIndices={[0]}
      style={[{ maxWidth: '100%' }, containerStyle]}
    >
      {headers && (
        <View>
          <View
            style={[
              styles.tableHead,
              inlineInput && styles.tableHeadInline,
              inDrawer && styles.tableHeadInDrawer,
            ]}
          >
            {_.some(rows, (row) => row.drawer) && (
              <View
                style={[
                  styles.tableBodyChevron,
                  inlineInput && styles.tableBodyChevronInline,
                  { flex: inlineInput ? 0.25 : 0.5 },
                ]}
              >
                <TouchableOpacity onPress={toggleAllExpanded}>
                  <Icon
                    fill={ThemeColors.PRIMARY}
                    name={
                      allExpanded
                        ? 'chevron-down-outline'
                        : 'chevron-right-outline'
                    }
                    style={styles.tableHeaderIcon}
                    {...testId('header-chevron')}
                  />
                </TouchableOpacity>
              </View>
            )}
            {headers.map((header, i) => (
              <View
                key={i} // eslint-disable-line react/no-array-index-key
                style={[
                  styles.tableHeaderCell,
                  inlineInput && styles.tableHeaderCellInline,
                  { flex: widths[i] },
                  header.contentStyle,
                ]}
              >
                {!header.sortable && (
                  <Text
                    style={[
                      styles.tableHeaderText,
                      { color: ThemeColors.TEXT },
                    ]}
                  >
                    {header.label}
                  </Text>
                )}
                {header.sortable && (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                      nativeID={header.label}
                      onPress={() => sortByHeader(header)}
                      style={styles.tableHeaderText}
                    >
                      {header.label}
                    </Text>
                    {header.sorting && header.sortDirection === 'asc' && (
                      <Icon
                        fill={ThemeColors.TEXT}
                        name="chevron-up-outline"
                        style={styles.tableHeaderIcon}
                      />
                    )}
                    {header.sorting && header.sortDirection === 'desc' && (
                      <Icon
                        fill={ThemeColors.TEXT}
                        name="chevron-down-outline"
                        style={styles.tableHeaderIcon}
                      />
                    )}
                  </View>
                )}
              </View>
            ))}
            {_.some(rows, (row) => !_.isEmpty(row.actions)) && (
              <View style={[styles.tableBodyActions, { flex: 0.5 }]} />
            )}
          </View>
        </View>
      )}
      <View style={contentStyle}>
        <View nativeID={nativeID}>
          {tableRows}
          <LoadMoreButton />
        </View>
      </View>
    </ScrollView>
  );
};
