import { Card, IndexPath } from '@ui-kitten/components';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Radio, RadioGroup, Select, SelectItem, HSpacer, VSpacer } from '@design';
import { SpacerSize, SpacerSizes } from '@theme/variant-interfaces/Size';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const Square = () => (
  <View style={{ backgroundColor: '#0095FF', width: 100, height: 100 }} />
);

export const SpacerDemo: FunctionComponent = () => {
  const [size, setSize] = useState<SpacerSize>('1');
  const [axis, setAxis] = useState(0); // 0 = horizontal, 1 = vertical
  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <DemoContainer>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <RadioGroup
              onChange={setAxis}
              selectedIndex={axis}
              style={{ justifyContent: 'space-around' }}
              testID="test"
            >
              <Radio testID="spacer-demo-horizonal-radio">Horizontal space</Radio>
              <Radio testID="spacer-demo-vertical-radio">Vertical space</Radio>
            </RadioGroup>
            <Select
              label="Size"
              onSelect={(ip: IndexPath | IndexPath[]) => setSize(
                SpacerSizes[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(SpacerSizes.findIndex((n) => n === size) + 1)}
              testID="spacer-demo-size"
              value={size || 'None'}
            >
              {[
                <SelectItem key="noicon" testID="spacer-demo-size" title="None" />,
                ...SpacerSizes.map((n, i) => (
                  <SelectItem key={n} testID={`spacer-demo-dropdown-value-${i}`} title={n} />
                )),
              ]}
            </Select>
          </View>
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer>
          <DemoBlock style={{ alignContent: 'center', justifyContent: 'space-around', width: '100%' }}>
            <View>
              <View style={{ flexDirection: 'row' }}>
                <Square />
                {(!axis && size) && <HSpacer size={size} />}
                <Square />
              </View>
              {(!!axis && size) && <VSpacer size={size} />}
              <View style={{ flexDirection: 'row' }}>
                <Square />
                {(!axis && size) && <HSpacer size={size} />}
                <Square />
              </View>
            </View>

          </DemoBlock>
        </DemoContainer>
      </Card>
    </View>
  );
};
