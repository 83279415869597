import React from 'react';
import { Button, Chip, Text, VSpacer } from '@design';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ApiGrowerListItem } from '@shared/interfaces/api';
export interface CustomersChipSetProps {
  assignedCustomers: ApiGrowerListItem[],
  disabled?: boolean,
  handleOnAddCustomers: () => void,
  isStandardUser: boolean,
  onPress: (name: string) => void,
}

export const CustomersChipSet = ({
  assignedCustomers,
  disabled,
  handleOnAddCustomers,
  isStandardUser,
  onPress,
}: CustomersChipSetProps) => {
  const [translate] = useTranslation(['prepare', 'tankMix', 'common']);

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View>
          <Text category="label">
            {translate<string>('CUSTOMERS')}
          </Text>
          <VSpacer size="3" />
          <Text appearance="hint" category="p2">
            {translate<string>('CUSTOMERS_PRODUCTMIX_ASSOCIATION')}
          </Text>
        </View>
        <Button
          appearance="ghost"
          disabled={disabled}
          onPress={handleOnAddCustomers}
          size='small'
          testID="add-customers-button"
        >
          + {translate<string>('ADD_CUSTOMERS_TITLE')}
        </Button>
      </View>
      <VSpacer size="5" />
      <View style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
        {
          assignedCustomers?.map((customer) => (
            <Chip
              accessoryRight={(isStandardUser && !customer.assigned) ? <></> : undefined}
              disabled={(isStandardUser && !customer.assigned)}
              key={customer.id}
              onPress={() => onPress(customer.id)}
              style={{ marginTop: 4, marginBottom: 4, marginLeft: 0 }}
              testID={`customer-name-chip-${customer.legalName}`}
            >
              {customer.legalName}
            </Chip>
          ))
        }
      </View>
    </>
  );
};