import { View } from 'react-native';
import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Card,
  CenteredSpinner,
  Chip,
  Header,
  HSpacer,
  Icon,
  Input,
  Select,
  SelectItem,
  Text,
  ToastProps,
  useToast,
  VSpacer,
} from '@design';
import { useTranslation } from 'react-i18next';
import { ApiPriceType, ApiPriceTypeCreate, ApiProduct, PaginatedData } from '@shared/interfaces/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IndexPath } from '@ui-kitten/components';
import { Status } from '@theme/variant-interfaces/Status';
import { SharedConfig } from '@shared/constants';
import { RoleUtility } from '@shared/utils';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { PriceTypeApi } from '../../../../utilities/api/PriceTypeApi';
import { ProductApi } from '../../../../utilities/api';
import {
  ColorCircleIndicator,
} from '../../../components/shared/ColorCircleIndicator/ColorCircleIndicator';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { ProductSettingsPriceTypeModal } from './ProductSettingsPriceTypeModal';

interface PriceTypeUpdates {
  id: string,
  name: ApiPriceTypeCreate['name'],
  businessId: ApiPriceTypeCreate['businessId'],
  isDefault?: ApiPriceTypeCreate['isDefault'],
}

const initialPriceType: ApiPriceTypeCreate = {
  businessId: '',
  name: '',
};

const PriceTypeCardRaw = () => {
  const [translate] = useTranslation(['businesses', 'common', 'errors']);
  const queryClient = useQueryClient();
  const { currentBusinessId: businessId, user } = useAuthentication();
  const [inputStatus, setInputStatus] = useState<Status>('basic');
  const [noProductsAffected, setNoProductsAffected] = useState(0);
  const [priceTypeId, setPriceTypeId] = useState('');
  const [priceTypes, setPriceTypes] = useState<ApiPriceType[]>([]);
  const [priceType, setPriceType] = useState<ApiPriceTypeCreate>(initialPriceType);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [unableToDeleteConfirmationModal, setUnableToDeleteConfirmationModal] = useState(false);

  const userHasPermission = RoleUtility.canEditBusiness(user, businessId);

  const { createToast } = useToast();
  const toastProps = useCallback((bannerText: string, status: Status = 'warning'): ToastProps => ({
    children: (
      <View>
        <Text>
          {bannerText}
        </Text>
      </View>
    ),
    status,
    testID: 'toast-content-element',
  }), []);

  const getPriceTypes = async () => PriceTypeApi.list(businessId);
  const { isLoading } = useQuery([
    QueryKeys.PRICE_TYPE_LIST, businessId], getPriceTypes, {
    onError: (err) => {
      createToast(toastProps(translate('UNEXPECTED_ERROR', { error: err })));
    },
    onSuccess: (data) => {
      data.sort((a, b) => {
        if (a.isDefault !== b.isDefault) {
          return a.isDefault ? -1 : 1;
        }

        return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
      });
      setPriceTypes(data);
    },
  });

  const fetchProductsAffectedCount = async () => (
    ProductApi.list({ businessId, limit: 1, isActive: ['true', 'false'] }));
  useQuery<PaginatedData<ApiProduct>>(
    [QueryKeys.PRODUCT_FILTERS], fetchProductsAffectedCount, {
      enabled: !!priceTypeId,
      onError: (err) => (
        createToast(toastProps(translate('ERROR_FETCH_AFFECTED_PRODUCT_COUNT', { error: (err as Error).message })))
      ),
      onSuccess: ({ total }) => setNoProductsAffected(total),
    },
  );

  const addPriceType = useMutation(
    (creationParams: ApiPriceTypeCreate) => PriceTypeApi.create(creationParams), {
      onError: (err: Error) => {
        createToast(toastProps(translate('UNEXPECTED_ERROR', { error: err.message })));
      },
      onSuccess: () => {
        setPriceType(initialPriceType);
        queryClient.invalidateQueries([QueryKeys.PRICE_TYPE_LIST]);
        createToast(toastProps(translate('PRICE_TYPE_CREATED_SUCCESS'), 'success'));
      },
    },
  );

  const makeDefaultPriceType = useMutation((priceTypeUpdates: PriceTypeUpdates) => (
    PriceTypeApi.update(
      priceTypeUpdates.id, {
        name: priceTypeUpdates.name,
        isDefault: !priceTypeUpdates.isDefault,
      },
    )), {
    onError: (err: Error) => {
      createToast(toastProps(translate('UNEXPECTED_ERROR', { error: err.message })));
    },
    onSuccess: () => queryClient.invalidateQueries([QueryKeys.PRICE_TYPE_LIST]),
  });

  const onSelectDefaultPriceType = async (idx: IndexPath) => {
    const { id, name, businessId: priceTypeBizId, isDefault } = priceTypes[idx.row];
    await makeDefaultPriceType.mutateAsync({ id, name, businessId: priceTypeBizId, isDefault });
  };

  const deletePriceType = useMutation((id: string) => PriceTypeApi.delete(id), {
    onError: (err: Error) => {
      createToast(toastProps(translate('UNEXPECTED_ERROR', { error: err.message })));
      setShowDeleteConfirmationModal(false);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.PRICE_TYPE_LIST]);
      createToast(toastProps(translate('PRICE_TYPE_DELETED_SUCCESS'), 'success'));
      setShowEditModal(false);
      setShowDeleteConfirmationModal(false);
    },
  });

  const hasDuplicateName = useCallback(() => (
    priceTypes.some(
      (priceTypeToValidate) => (
        priceTypeToValidate.name.toUpperCase() === priceType.name.trim().toUpperCase()),
    )), [priceType.name, priceTypes]);

  useEffect(() => {
    if (hasDuplicateName()) {
      setInputStatus('danger');
    } else {
      setInputStatus('basic');
    }
  }, [hasDuplicateName]);

  const isInputValid = useCallback(() => {
    if (priceTypes?.length === SharedConfig.MAX_PRICE_TYPES) {
      return false;
    }
    if (priceType.name.trim()) {
      if (!hasDuplicateName()) {
        return true;
      }
    }
    return false;
  }, [hasDuplicateName, priceType.name, priceTypes]);

  return (
    <View>
      <Header
        level="3"
        testID="price-type-header"
        title={translate<string>('PRICE_TYPE')}
      >
        {userHasPermission && (
        <Button
          accessoryLeft={(pencilProps) => <Icon name="Edit" testID="edit-button-icon" {...pencilProps} />}
          appearance="ghost"
          onPress={() => setShowEditModal(true)}
          size="small"
          testID="edit-button"
        >
          {translate<string>('EDIT')}
        </Button>
        )}
      </Header>
      <VSpacer size="5" />
      <Card testID="price-type-card">
        {isLoading && <CenteredSpinner />}
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <Input
            caption={hasDuplicateName() && translate<string>('NAME_TAKEN')}
            disabled={!userHasPermission}
            label={translate<string>('PRICE_TYPE_NAME')}
            onChangeText={(name) => setPriceType({ businessId, name })}
            onSubmitEditing={async () => isInputValid() && addPriceType.mutateAsync(priceType)}
            status={inputStatus}
            style={{ flex: 1 }}
            testID="price-type-input"
            value={priceType.name}
          />
          <HSpacer size="7" />
          <View>
            <Button
              accessoryLeft={(addButtonProps) => <Icon name="Plus" testID="price-type-add-button-icon" {...addButtonProps} />}
              disabled={!isInputValid() || !userHasPermission}
              onPress={async () => addPriceType.mutateAsync(priceType)}
              size="medium"
              testID="price-type-add-button"
            >
              {translate<string>('ADD')}
            </Button>
            {hasDuplicateName() && <VSpacer size="7" />}
          </View>
        </View>
        <VSpacer size="5" />
        <View style={{ flexDirection: 'row' }}>
          {userHasPermission && (
          <Select
            accessoryLeft={() => (
              <ColorCircleIndicator
                style={{ marginRight: 12 }}
                testID="price-type-card-indicator"
              />
            )}
            onSelect={(idx: IndexPath | IndexPath[]) => onSelectDefaultPriceType(idx as IndexPath)}
            placeholder={translate<string>('SET_DEFAULT_LABEL')}
            style={{ flex: 1 }}
            testID="price-type-card-label-selector"
          >
            {priceTypes?.map((priceTypeForSelectItem, index) => (
              <SelectItem
                accessoryLeft={() => priceTypeForSelectItem.isDefault && (
                  <ColorCircleIndicator
                    style={{ marginRight: 12 }}
                    testID={`price-types-${index}`}
                  />
                )}
                disabled={priceTypeForSelectItem.isDefault}
                key={priceTypeForSelectItem.id}
                testID={`price-card-type-dropdown-value-${index}`}
                title={priceTypeForSelectItem.name}
              />
            ))}
          </Select>
          )}
          <View style={{ flex: 2.5 }} />
        </View>
        <VSpacer size="7" />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {priceTypes?.map((priceTypeForChip, index) => (
            <Chip
              accessoryLeft={() => priceTypeForChip.isDefault && (
                <ColorCircleIndicator style={{ marginLeft: 19.5 }} testID="default-price-type" />
              )}
              disabled={!userHasPermission}
              key={priceTypeForChip.id}
              onPress={() => {
                if (userHasPermission) {
                  if (priceTypeForChip.isDefault) {
                    setUnableToDeleteConfirmationModal(true);
                  } else {
                    setPriceTypeId(priceTypeForChip.id);
                    setShowDeleteConfirmationModal(true);
                  }
                }
              }}
              style={index === 0 ? { marginLeft: 0 } : null}
              testID={`price-type-chip-${index}`}
            >
              {priceTypeForChip.name}
            </Chip>
          ))}
        </View>
      </Card>
      {showEditModal && (
        <ProductSettingsPriceTypeModal
          isLoading={isLoading}
          onClose={() => setShowEditModal(false)}
          onDelete={(id) => {
            setPriceTypeId(id);
            setShowDeleteConfirmationModal(true);
          }}
          priceTypes={priceTypes}
        />
      )}
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('YES_DELETE')}
        messageText={translate('DELETE_PRICE_TYPE_MESSAGE', { count: noProductsAffected })}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        onConfirm={async () => deletePriceType.mutateAsync(priceTypeId)}
        status="warning"
        title={translate('DELETE_PRICE_TYPE_TITLE')}
        visible={showDeleteConfirmationModal}
      />
      <ConfirmationModal
        cancelText={translate('CLOSE')}
        confirmText=""
        messageText={translate('UNABLE_TO_DELETE_MESSAGE')}
        onCancel={() => setUnableToDeleteConfirmationModal(false)}
        onConfirm={() => {}}
        status="warning"
        title={translate('UNABLE_TO_DELETE_TITLE')}
        visible={unableToDeleteConfirmationModal}
      />
    </View>
  );
};

export const PriceTypeCard = React.memo(PriceTypeCardRaw);
