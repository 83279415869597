import React, { useState } from 'react';
import {
  Button,
  Card,
  DataPoint,
  Header,
  Icon,
  IconButton,
  Text,
  VSpacer,
  useToast,
} from '@design';
import { ApiForm, FormEndpoint } from '@shared/interfaces/api';
import { toShortDateTime } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { PropertyGroupApi } from '../../../utilities/api/PropertyGroupApi';
import { useQuery } from 'react-query';

interface SelectedPropertyGroupProps {
  customerId: string,
  selectedPropertyGroup: ApiForm,
}

const styles = StyleSheet.create({
  dataPoint: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',
    width: '33.33%',
  },
});

export const SelectedPropertyGroup = ({
  customerId,
  selectedPropertyGroup,
}: SelectedPropertyGroupProps) => {
  const [translate] = useTranslation(['common']);
  const { createToast } = useToast();
  const [form, setForm] = useState<ApiForm>();

  useQuery<FormEndpoint.Get.Response, Error>(
    [selectedPropertyGroup],
    () => PropertyGroupApi.getPropertyGroupInstance(selectedPropertyGroup.id, customerId),
    {
      onError: () => {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR'),
          status: 'warning',
          testID: 'property-group-edit-error-toast',
        });
      },
      onSuccess: (apiForm) => {
        setForm(apiForm);
      },
    },
  );

  const subTitle = (
    form?.formInstance
    && `Last updated ${toShortDateTime(form.formInstance.updatedAt)} by ${form.formInstance.updatedByName}`
  );

  return (
    <>
      <View>
        <View style={{ flex: 1 }}>
          <Header
            childrenStyle={{ paddingTop: 0 }}
            level="3"
            subTitle={subTitle}
            subTitleHint
            testID="details-header"
            title={selectedPropertyGroup.name}
          >
            <Button
              accessoryLeft={(props) => (
                <Icon name="Edit" testID="bulk-edit-property-group-button-icon" {...props} />
              )}
              appearance="ghost"
              onPress={() => alert('bulk edit property group')}
              size="small"
              status="primary"
              testID="edit-property-group-button"
            >
              <Text category="p2" status="primary">
                {translate<string>('EDIT')}
              </Text>
            </Button>
          </Header>
          <VSpacer size="3" />
          <Card testID="properties-card">
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {(form?.fields ?? []).map((field, index) => (
                <View
                  key={field.name}
                  style={styles.dataPoint}
                >
                  <Text appearance='hint' category='c1' testID={`property-datapoint-label-${index}`}>
                    {field.name}
                  </Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <DataPoint
                      key={field.name}
                      testID={`property-datapoint-${index}`}
                    >
                      {/* form instance value here? */}
                      Input answer
                    </DataPoint>
                    <IconButton
                      appearance="ghost"
                      onPress={() => alert('edit pencil')}
                      size="small"
                      status="basic"
                      testID={`edit-property-icon-${index}`}
                    >
                      Edit
                    </IconButton>
                  </View>
                </View>
              ))}
            </View>
          </Card>
        </View>
      </View>
    </>
  );
};
