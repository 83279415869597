import { PlanStatus } from '@shared/enums';
import { CropLogicResult, PlannedFarm } from '@shared/interfaces';
import {
  ApiCrop,
  ApiCropLogic, ApiDiscount,
  ApiFarmPlan,
  ApiFarmPlanToCropLogicLink,
  ApiGrowerFarm,
  ApiPlanningParameter,
  ApiProductSummary,
  FarmPlanEndpoint,
} from '@shared/interfaces/api';
import {
  AreaPlannedResult,
  asyncMapSequential,
  CalculationUtility,
  FarmPlanProfitCalculation,
  FarmUtility,
  Permissions,
  userHasPermission,
} from '@shared/utils';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { CropLogicApi, GrowerFarmPlanApi } from '../utilities/api';
import { useBusinessCrops } from './useBusinessCrops';
import { useBusinessLocation } from './useBusinessLocation';
import { useCustomer } from './useCustomer';
import { useFarms } from './useFarms';
import { usePriceTypeList } from './usePriceTypeList';
import { useUser } from './useUser';

const defaultProfit: FarmPlanProfitCalculation = {
  acresPlanned: 0,
  costAfterDiscounts: 0,
  discounts: [],
  income: 0,
  orderDiscountTotal: 0,
  productCosts: 0,
  productDiscountTotal: 0,
  products: [],
  subtotal: 0,
  totalDiscount: 0,
};

const defaultAcresPlanned: AreaPlannedResult = {
  areaUnitType: 'ac',
  crops: [],
  planned: 0,
  total: 0,
};

interface ProfitProductAcresSummaries {
  acresPlanned: AreaPlannedResult,
  boundaryLinks: ApiFarmPlanToCropLogicLink[],
  products: ApiProductSummary[],
  profit: FarmPlanProfitCalculation,
  programs: CropLogicResult[],
}

function getProfitProductAcresSummaries (
  farmsWithPrograms: PlannedFarm[],
  farmPlan: ApiFarmPlan,
  planningParameters: ApiPlanningParameter[],
  priceTypeId: string,
  useSkuPackagesRequired?: boolean,
  useUserInputsForSummaries?: boolean,
): ProfitProductAcresSummaries {
  const { growerFarmPlanProductSummaries: products } = FarmUtility.createFarmPlanSummary(
    farmsWithPrograms,
    farmPlan.id,
    priceTypeId,
    farmPlan.businessLocationId,
    farmPlan.growerFarmPlanProductSummaries ?? [],
  );

  const profit = CalculationUtility.getFarmPlanProfitCalculation(
    farmsWithPrograms,
    products,
    planningParameters,
    farmPlan.discounts ?? [],
    useSkuPackagesRequired,
  );

  const acresPlanned = CalculationUtility.getFarmPlanAcresPlanned(
    farmsWithPrograms,
    useUserInputsForSummaries ? farmPlan : undefined,
  );

  const boundaryLinks: ApiFarmPlanToCropLogicLink[] = [];
  const programs: CropLogicResult[] = [];

  farmsWithPrograms.forEach(({ growerFields }) => {
    growerFields.forEach((field) => {
      if (field.cropLogic) {
        boundaryLinks.push({
          cropLogicId: field.cropLogic.id,
          growerCropZoneId: null,
          growerFieldId: field.id,
        });
        programs.push(field.cropLogic);
      } else if (field.cropZones.length) {
        field.cropZones.forEach((zone) => {
          if (zone.cropLogic) {
            boundaryLinks.push({
              cropLogicId: zone.cropLogic.id,
              growerFieldId: field.id,
              growerCropZoneId: zone.id,
            });
            programs.push(zone.cropLogic);
          }
        });
      }
    });
  });

  return {
    acresPlanned,
    boundaryLinks,
    products,
    profit,
    programs,
  };
}

interface UseFarmPlan {
  crops: ApiCrop[],
  farmPlan: ApiFarmPlan,
  farms: ApiGrowerFarm[],
  farmsWithPrograms: PlannedFarm[],
  hasAcceptedOrders: boolean,
  initialFarmPlan: ApiFarmPlan,
  initialHasAcceptedOrders: boolean,
  isFarmPlanSaved: boolean,
  isFarmPlanValid: boolean,
  passes: number,
  programs: ApiCropLogic[],
  summaries: ProfitProductAcresSummaries,
}

function generateFarmPlanOptions (
  farmPlan: ApiFarmPlan,
  initialFarmPlan: ApiFarmPlan,
  farms: ApiGrowerFarm[],
  businessCrops: ApiCrop[],
  planningParameters: ApiPlanningParameter[] = [],
  useSkuPackagesRequired = true,
  useUserInputsForSummaries = true,
  farmsWithPrograms?: PlannedFarm[],
): UseFarmPlan {
  const programs = farmPlan.cropLogics ?? [];

  if (!farmsWithPrograms) {
    farmsWithPrograms = FarmUtility.assignCropLogicsToFarmAreas(
      farmPlan.growerId,
      farmPlan.cropLogics?.length ? farmPlan.growerFarmPlanLogicBoundaryLinks ?? [] : [],
      farms,
      businessCrops,
      programs,
      farmPlan.priceTypeId,
      farmPlan.businessLocationId,
      [],
    );
  } else {
    farmsWithPrograms.forEach(({ growerFields }) => {
      growerFields.forEach((field) => {
        if (field.cropLogic) {
          field.cropLogic = FarmUtility.addCostPerAcreToLogic(
            FarmUtility.addCropsToLogic(field.cropLogic, businessCrops),
            farmPlan.priceTypeId,
            farmPlan.businessLocationId,
          );
        } else if (field.cropZones.length) {
          field.cropZones.forEach((zone) => {
            if (zone.cropLogic) {
              zone.cropLogic = FarmUtility.addCostPerAcreToLogic(
                FarmUtility.addCropsToLogic(zone.cropLogic, businessCrops),
                farmPlan.priceTypeId,
                farmPlan.businessLocationId,
              );
            }
          });
        }
      });
    });
  }

  const hasAcceptedOrders = farmPlan.growerFarmPlanProductSummaries?.some(
    ({ planStatus }) => planStatus === PlanStatus.ACCEPTED,
  );

  const initialHasAcceptedOrders = initialFarmPlan.growerFarmPlanProductSummaries?.some(
    ({ planStatus }) => planStatus === PlanStatus.ACCEPTED,
  );

  const summaries = getProfitProductAcresSummaries(
    farmsWithPrograms,
    farmPlan,
    planningParameters,
    farmPlan.priceTypeId,
    useSkuPackagesRequired,
    useUserInputsForSummaries,
  );

  const isFarmPlanValid = [
    farmPlan?.businessId,
    farmPlan?.cropYear,
    farmPlan?.growerId,
    farmPlan?.planName,
    farmPlan?.userAccountId,
  ].every(Boolean) && (farmPlan.growerFarmPlanLogicBoundaryLinks?.length ?? 0) > 0;

  return {
    crops: businessCrops,
    farmPlan,
    farms,
    farmsWithPrograms,
    hasAcceptedOrders,
    initialFarmPlan,
    initialHasAcceptedOrders,
    isFarmPlanSaved: _.isEqual(farmPlan, initialFarmPlan),
    isFarmPlanValid,
    passes: programs.reduce((acc, { passes }) => (
      acc + passes.length
    ), 0),
    programs,
    summaries,
  };
}

interface UseFarmPlanProps {
  defaultCustomerId?: string,
  farmPlanId: string | null,
  onCreate?(farmPlan: ApiFarmPlan): void,
  onError?(error: Error): void,
  onUpdate?(farmPlan: ApiFarmPlan): void,
  useSkuPackagesRequired?: boolean,
  useUserInputsForSummaries?: boolean,
}

export function useFarmPlan ({
  defaultCustomerId,
  farmPlanId,
  onCreate,
  onError,
  onUpdate,
  useSkuPackagesRequired = true,
  useUserInputsForSummaries = true,
}: UseFarmPlanProps) {
  const { currentBusinessId, user: currentUser } = useAuthentication();
  const {
    defaultPriceType,
    defaultPriceTypeId,
    isLoading: isPriceTypeLoading,
    priceTypes,
  } = usePriceTypeList();

  const [isSaving, setIsSaving] = useState(false);
  const updateInitialFarmPlanRef = useRef(true);
  const farmsWithProgramsRef = useRef<PlannedFarm[] | undefined>();

  const canEditAccount = userHasPermission(currentUser, Permissions.MODIFY_OTHER_OWNER);
  const defaultUserId = canEditAccount ? '' : currentUser.id ?? '';

  const defaultFarmPlan = useMemo<ApiFarmPlan>(() => ({
    acresPlanned: 0,
    billingContactId: null,
    businessId: currentBusinessId,
    businessLocationId: null,
    createdAt: new Date(),
    cropYear: null,
    id: null,
    isActive: true,
    note: null,
    planName: '',
    growerId: defaultCustomerId ?? '',
    growerFarmPlanLogicBoundaryLinks: [],
    growerFarmPlanProductSummaries: [],
    priceTypeId: defaultPriceTypeId,
    shippingContactId: null,
    updatedAt: new Date(),
    userAccountId: defaultUserId,
  }), [currentBusinessId, defaultUserId, defaultCustomerId, defaultPriceTypeId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultSummaries: ProfitProductAcresSummaries = {
    acresPlanned: defaultAcresPlanned,
    boundaryLinks: [],
    products: [],
    profit: defaultProfit,
    programs: [],
  };

  const initialData = useMemo<UseFarmPlan>(() => ({
    crops: [],
    farmPlan: defaultFarmPlan,
    farms: [],
    farmsWithPrograms: [],
    hasAcceptedOrders: false,
    initialFarmPlan: defaultFarmPlan,
    initialHasAcceptedOrders: false,
    isFarmPlanSaved: true,
    isFarmPlanValid: false,
    passes: 0,
    programs: [],
    summaries: defaultSummaries,
  }), [defaultFarmPlan, defaultSummaries]);

  const queryKey = [QueryKeys.FARM_PLAN, farmPlanId];
  const queryClient = useQueryClient();

  const { data, isFetching: isFarmPlanLoading } = useQuery(
    queryKey,
    async () => {
      if (!farmPlanId) {
        return initialData;
      }

      const farmPlan = await GrowerFarmPlanApi.getFarmPlan(farmPlanId);
      if (!farmPlan.priceTypeId) {
        farmPlan.priceTypeId = defaultPriceTypeId;
      }

      return generateFarmPlanOptions(
        farmPlan,
        farmPlan,
        [],
        [],
        [],
        useSkuPackagesRequired,
        useUserInputsForSummaries,
      );
    },
    {
      enabled: !isPriceTypeLoading,
      onError: (error: Error) => onError?.(error),
      placeholderData: initialData,
    },
  );

  const isOwner = data.farmPlan.userAccountId === currentUser.id;

  const priceType = priceTypes.find(({ id }) => (
    id === data.farmPlan?.priceTypeId)) ?? defaultPriceType;

  const { isFarmsLoading, farms } = useFarms({
    customerId: data.farmPlan.growerId,
    onError,
  });

  const { isCustomerLoading, customer } = useCustomer({
    customerId: data.farmPlan.growerId,
    onError,
  });

  const { isBusinessCropsLoading, businessCrops } = useBusinessCrops({
    businessId: data.farmPlan.businessId,
    onError,
  });

  const { isUserLoading, user } = useUser({
    userAccountId: data.farmPlan?.userAccountId,
    onError,
  });

  const { isBusinessLocationLoading, businessLocation } = useBusinessLocation({
    businessLocationId: data.farmPlan?.businessLocationId,
    onError,
  });

  const programWithFarmsMutation = useMutation(async (newFarmPlanId: string) => {
    const userAccountId = !userHasPermission(user, Permissions.ACCESS_ALL_BUSINESSES)
      ? user.id
      : data.farmPlan.userAccountId;

    const createdPrograms: ApiCropLogic[] = [];

    const ensureProgram = async (program: CropLogicResult) => {
      program.farmPlanId = newFarmPlanId ?? data.farmPlan.id;
      program.userAccountId = userAccountId;

      const foundProgram = createdPrograms.find(
        (logic) => logic.logicName === program.logicName,
      );

      if (foundProgram) {
        return foundProgram;
      }

      const newProgram = await CropLogicApi.createCropLogic(FarmUtility.stripCropLogic(program));

      createdPrograms.push(newProgram);
      return newProgram;
    };

    return asyncMapSequential(data.farmsWithPrograms, async (farmWithProgram) => {
      farmWithProgram.growerFields = await asyncMapSequential(
        farmWithProgram.growerFields,
        async (field) => {
          if (field.cropLogic) {
            field.cropLogic = await ensureProgram(field.cropLogic);
          }

          if (field.cropZones?.length) {
            field.cropZones = await asyncMapSequential(field.cropZones, async (cropZone) => {
              if (cropZone.cropLogic) {
                cropZone.cropLogic = await ensureProgram(cropZone.cropLogic);
              }

              return cropZone;
            });
          }

          return field;
        },
      );

      return farmWithProgram;
    });
  }, {
    onError,
  });

  const isLoading = isBusinessCropsLoading
    || isBusinessLocationLoading
    || isCustomerLoading
    || isFarmPlanLoading
    || isFarmsLoading
    || isPriceTypeLoading
    || isUserLoading
    || isSaving;

  const planningParameters = customer?.planningParameters ?? [];

  const mutationFarmPlan = useMutation(async ({
    newFarmPlan = !farmPlanId ? defaultFarmPlan : data.farmPlan,
    newFarmsWithPrograms,
    updateInitialFarmPlan,
  }: {
    newFarmPlan?: ApiFarmPlan,
    newFarmsWithPrograms?: PlannedFarm[] | null,
    updateInitialFarmPlan: boolean,
  }) => {
    newFarmPlan.userAccountId = (!newFarmPlan.userAccountId && !canEditAccount)
      ? currentUser.id : newFarmPlan.userAccountId;

    if (newFarmsWithPrograms !== undefined) {
      if (newFarmsWithPrograms === null) {
        farmsWithProgramsRef.current = undefined;
      } else {
        const summaries = getProfitProductAcresSummaries(
          newFarmsWithPrograms,
          data.farmPlan,
          planningParameters,
          data.farmPlan.priceTypeId,
          useSkuPackagesRequired,
          useUserInputsForSummaries,
        );

        newFarmPlan = {
          ...data.farmPlan,
          growerFarmPlanProductSummaries: summaries.products,
          growerFarmPlanLogicBoundaryLinks: summaries.boundaryLinks,
          cropLogics: summaries.programs,
        };

        farmsWithProgramsRef.current = newFarmsWithPrograms;
      }
    }

    if (data.isFarmPlanValid && updateInitialFarmPlan) {
      setIsSaving(true);

      const createMode = !newFarmPlan.id;

      if (!createMode && (data.initialHasAcceptedOrders || !farmsWithProgramsRef.current)) {
        newFarmPlan = await GrowerFarmPlanApi.updateFarmPlan(
          newFarmPlan.id,
          newFarmPlan as FarmPlanEndpoint.Update.Request,
        );
      } else {
        if (createMode) {
          const { id } = await GrowerFarmPlanApi.createFarmPlan({
            ...newFarmPlan,
            growerFarmPlanLogicBoundaryLinks: [],
            growerFarmPlanProductSummaries: [],
          } as FarmPlanEndpoint.Create.Request);

          newFarmPlan.id = id;
        } else {
          // remove all programs to avoid duplicated name error
          await GrowerFarmPlanApi.updateFarmPlan(newFarmPlan.id, {
            ...newFarmPlan,
            growerFarmPlanLogicBoundaryLinks: [],
            growerFarmPlanProductSummaries: [],
          } as FarmPlanEndpoint.Update.Request);
        }

        newFarmsWithPrograms = await programWithFarmsMutation.mutateAsync(newFarmPlan.id);

        const summaries = getProfitProductAcresSummaries(
          newFarmsWithPrograms,
          newFarmPlan,
          planningParameters,
          newFarmPlan.priceTypeId,
          useSkuPackagesRequired,
          useUserInputsForSummaries,
        );

        newFarmPlan.growerFarmPlanProductSummaries = summaries.products.map((product) => ({
          ...product,
          discounts: product.discounts?.map(({ id, ...discount }) => discount) as ApiDiscount[],
        }));
        newFarmPlan.growerFarmPlanLogicBoundaryLinks = summaries.boundaryLinks;
        newFarmPlan.cropLogics = summaries.programs;
        newFarmPlan.acresPlanned = summaries.acresPlanned.planned;

        farmsWithProgramsRef.current = undefined;

        newFarmPlan = await GrowerFarmPlanApi.updateFarmPlan(
          newFarmPlan.id,
          newFarmPlan as FarmPlanEndpoint.Update.Request,
        );
      }

      if (createMode) {
        onCreate?.(newFarmPlan);
      } else {
        onUpdate?.(newFarmPlan);
      }

      setIsSaving(false);
    }

    const initialFarmPlan = (updateInitialFarmPlan || updateInitialFarmPlanRef.current)
      ? newFarmPlan
      : data.initialFarmPlan;

    queryClient.setQueryData(queryKey, generateFarmPlanOptions(
      newFarmPlan,
      initialFarmPlan,
      farms ?? [],
      businessCrops ?? [],
      planningParameters,
      useSkuPackagesRequired,
      useUserInputsForSummaries,
      farmsWithProgramsRef.current,
    ));
  }, {
    onError: (error: Error) => {
      setIsSaving(false);
      onError?.(error);
    },
  });

  useEffect(() => {
    if (updateInitialFarmPlanRef.current && !isLoading) {
      mutationFarmPlan.mutate({ updateInitialFarmPlan: false });

      updateInitialFarmPlanRef.current = false;
    }
  }, [isLoading, mutationFarmPlan]);

  const setFarmPlan = useCallback((
    newFarmPlan: ApiFarmPlan,
    updateInitialFarmPlan = false,
    newFarmsWithPrograms?: PlannedFarm[] | null,
  ) => {
    mutationFarmPlan.mutate({ newFarmPlan, updateInitialFarmPlan, newFarmsWithPrograms });
  }, [mutationFarmPlan]);

  return {
    ...data,
    businessLocation,
    canEditAccount,
    customer,
    isLoading,
    isLocked: updateInitialFarmPlanRef.current,
    isOwner,
    priceType,
    setFarmPlan,
    user,
  };
}
