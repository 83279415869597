import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Text } from '../../ui-components';
import { Sizing } from '../../constants';
import { ModalUtility } from './Modal';
import { testId } from '../../utilities/testId';

export const styles = StyleSheet.create({
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: { paddingBottom: 0 },
  header: {
    fontSize: 2 * Sizing.EM,
    fontWeight: 'bold',
    margin: Sizing.BASE_SPACING,
    marginLeft: Sizing.DOUBLE_SPACING,
  },
  footer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: { marginVertical: Sizing.BASE_SPACING },
  lastButton: {
    marginLeft: Sizing.HALF_SPACING,
    marginRight: Sizing.BASE_SPACING,
  },
});

export const ConfirmModal = (props) => {
  const [translate] = useTranslation('common');
  const {
    onConfirm,
    onCancel,
    message,
    confirmButtonText = translate('CONFIRM'),
  } = props;

  const ModalFooter = () => (
    <View style={styles.footer}>
      <Button
        nativeID="cancel-button"
        onPress={() => {
          if (onCancel) {
            onCancel();
          }
          ModalUtility.hide();
        }}
        status="secondary"
        style={styles.button}
      >
        {translate('CANCEL')}
      </Button>
      <Button
        nativeID="confirm-button"
        onPress={() => { onConfirm(); ModalUtility.hide(); }}
        style={[styles.button, styles.lastButton]}
      >
        { confirmButtonText }
      </Button>
    </View>
  );

  return (
    <View {...testId('confirmation-modal')}>
      <Card disabled footer={<ModalFooter />} style={styles.card} testID="confirmation-modal-card">
        <Text>
          { message }
        </Text>
      </Card>
    </View>
  );
};
