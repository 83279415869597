import { Button, Dialog, VSpacer } from '@design';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalDisplayIdText } from './ExternalDisplayIdText';

interface ExternalIdsModalProps {
  externalIds: string[],
  onClose: () => void,
  visible: boolean,
}

export const ExternalIdsModal = ({ externalIds, onClose, visible }: ExternalIdsModalProps) => {
  const [translate] = useTranslation(['common', 'integration']);
  const uniqueIds = Array.from(new Set(externalIds)).sort();
  return (
    <Dialog
      footerAccessory={({ primaryButtonProp }) => (
        <Button
          {...primaryButtonProp}
          onPress={onClose}
          testID="external-ids-modal-close-button"
        >
          {translate('CLOSE')}
        </Button>
      )}
      onClose={onClose}
      testID="external-ids-modal"
      title={translate('EXTERNAL_IDS_MODAL_TITLE')}
      visible={visible}
    >
      {uniqueIds.map((id, index) => (
        <Fragment key={id}>
          <ExternalDisplayIdText externalDisplayId={id} />
          {index !== externalIds.length - 1 &&
            <VSpacer size="1" />
          }
        </Fragment>
      ))}
    </Dialog>
  );
};
