import {
  Button,
  Card,
  DataPoint,
  FilterTable,
  Header,
  HSpacer,
  Icon,
  IconButton,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { ApiBusiness, ApiBusinessContact, BusinessEndpoint } from '@shared/interfaces/api';
import { RoleUtility, toShortDate } from '@shared/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useAppContext } from '../../../contexts/AppContext';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { BusinessContactModal } from './BusinessContactModal';
import BusinessContactCreate = BusinessEndpoint.BusinessContactCreate;
import { useStyleSheet } from '@ui-kitten/components';
import { LinkingUtility } from '../../../utilities';
import { IColumn, RowMeta } from 'src/components/SortableTable';

const styles = StyleSheet.create({
  action: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  item: {
    flex: 1,
  },
  list: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  tableContainerStyle: { paddingVertical: 0, paddingHorizontal: 0 },
  contactHeader: { flexDirection: 'row', justifyContent: 'space-between' },
});

const itemsPerPage = 10;

export interface InfoTabProps {
  business: ApiBusiness,
  scrollToTop: () => void,
  updateContacts: (contacts: BusinessContactCreate.Request[]) => void
}

export const DetailsTab = ({ business, scrollToTop, updateContacts }: InfoTabProps) => {
  const [translate] = useTranslation(['businesses', 'common', 'errors']);
  const { user } = useAuthentication();
  const { setModalProps } = useAppContext();
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ApiBusinessContact>();
  const [showDeleteContactDialog, setShowDeleteContactDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Pagination is 1 indexed

  const canModifyBusiness = business.isActive && RoleUtility.canEditBusiness(user, business.id);
  const canDeleteContacts = business.contacts.length > 1;

  const deleteContact = (contactId: string) => {
    const newContacts = business.contacts.filter((contact) => contact.id !== contactId);
    updateContacts(newContacts);
  };

  const saveContact = (newContact: BusinessContactCreate.Request) => {
    const isUpdatingContact = business.contacts.some((contact) => contact.id === newContact.id);
    let newContacts: BusinessContactCreate.Request[];
    if (isUpdatingContact) {
      newContacts = business.contacts.map((contact) => (
        contact.id === newContact.id ? newContact : contact
      ));
    } else {
      newContacts = (business.contacts as BusinessContactCreate.Request[]).concat(newContact);
    }
    updateContacts(newContacts);
  };

  const growersStyles = useStyleSheet({
    textLink: {
      color: 'color-primary-500',
      flex: 1,
      width: '100%',
      overflow: 'hidden',
    },
  });

  const contacts = business.contacts.map((contact, index) => ({
    ...contact,
    rowId: `row:${index}|${contact.id}|`,
  }));

  const displayedContacts = useMemo(() => (
    contacts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
  ), [contacts, currentPage]);

  const changePage = useCallback((page: number) => {
    setCurrentPage(page);
    scrollToTop();
  }, [scrollToTop]);

  useEffect(() => {
    const totalPages = Math.ceil(contacts.length / itemsPerPage);
    if (totalPages < currentPage) {
      changePage(totalPages);
    }
  }, [changePage, contacts.length, currentPage]);

  const columns: IColumn<(ApiBusinessContact & RowMeta)>[] = [
    {
      columnId: 'contacts-name',
      header: {
        render: translate('NAME'),
      },
      render: (contact) => contact.contactName,
      flex: 1,
    },
    {
      columnId: 'contacts-email',
      header: {
        render: translate('EMAIL'),
      },
      render: (contact) => (
        <TextLink
          category="p2"
          onPress={() => LinkingUtility.openEmail(contact.contactEmail)}
          style={growersStyles.textLink}
        >
          {contact.contactEmail}
        </TextLink>
      ),
      flex: 1,
    },
    {
      columnId: 'contacts-phone',
      header: {
        render: translate('PHONE'),
      },
      render: (contact) => contact.contactTelephone ? (
        <TextLink
          category="p2"
          onPress={() => LinkingUtility.callTelephone(contact.contactTelephone)}
          style={growersStyles.textLink}
        >
          {contact.contactTelephone}
        </TextLink>
      ) : '—',
      flex: 1,
    },
    {
      columnId: 'contacts-receive-delieverables',
      header: {
        render: translate('RECEIVE_DELIVERABLES'),
      },
      render: (contact) => translate(contact.isDeliverableDefault ? 'YES' : 'NO'),
      flex: 1,
    },
  ];

  if (canModifyBusiness) {
    columns.push(
      {
        columnId: 'contacts-table-header-name',
        header: {
          render: '',
        },
        render: (contact) => (
          <>
            <IconButton
              appearance="ghost"
              onPress={() => {
                setSelectedContact(contact);
                setShowContactModal(true);
              }}
              size="medium"
              status="basic"
              testID="edit-contact-button"
            >
              Edit
            </IconButton>
            {canDeleteContacts && (
              <>
                <HSpacer size="3" />
                <IconButton
                  appearance="ghost"
                  onPress={() => {
                    setSelectedContact(contact);
                    setShowDeleteContactDialog(true);
                  }}
                  size="medium"
                  status="basic"
                  testID="remove-contact-button"
                >
                  Trash
                </IconButton>
              </>
            )}
          </>
        ),
        align: 'right',
        flex: 1,
      },
    );
  }

  const DetailsCard = (
    <Card testID="details-card">
      <View style={styles.list}>
        <DataPoint
          label={translate<string>('HEADQUARTERS')}
          style={styles.item}
          testID="headquarters"
        >
          <View>
            <Text category="p2">{business.address1}</Text>
            <Text category="p2">{`${business.city}, ${business.state} ${business.postalCode ?? ''}`}</Text>
            <Text category="p2">{business.telephone}</Text>
          </View>
        </DataPoint>
        <DataPoint
          label={translate<string>('BUSINESS_ID')}
          style={styles.item}
          testID="business-id"
        >
          {business.id}
        </DataPoint>
        <DataPoint
          label={translate<string>('CREATED_ON')}
          style={styles.item}
          testID="created"
        >
          {toShortDate(business.createdAt)}
        </DataPoint>
        <DataPoint
          label={translate<string>('LAST_UPDATED_ON')}
          style={styles.item}
          testID="last-updated"
        >
          {toShortDate(business.updatedAt)}
        </DataPoint>
        <View style={styles.item} />
      </View>
    </Card>
  );

  const ContactsTable = (
    <FilterTable
      columns={columns}
      currentPage={currentPage}
      data={displayedContacts}
      defaultColumnFilters={[]}
      filterOptions={[]}
      noDataMessage={translate('NO_CONTACTS')}
      noFilters
      onPageChange={changePage}
      onUpdateFilter={() => { }}
      tableContainerStyle={styles.tableContainerStyle}
      testID="contacts-table"
      totalPages={Math.ceil(contacts.length / itemsPerPage)}
    />
  );

  const ContactsHeader = (
    <View style={styles.contactHeader}>
      <Header level="3" testID="contacts-header" title={translate('CONTACTS')} />
      {canModifyBusiness && (
        <Button
          accessoryLeft={<Icon name="Plus" testID="add-new-contact-button-icon" />}
          appearance="outline"
          onPress={() => setShowContactModal(true)}
          size="small"
          testID="add-new-contact-button"
        >
          {translate('ADD_NEW_CONTACT')}
        </Button>
      )}
    </View>
  );

  const AddEditModal = (
    <BusinessContactModal
      contact={selectedContact}
      hideDeleteButton={business.contacts.length === 1}
      onClose={() => {
        setSelectedContact(undefined);
        setShowContactModal(false);
      }}
      onDelete={() => {
        setShowContactModal(false);
        setShowDeleteContactDialog(true);
      }}
      onSave={(contact) => {
        saveContact(contact);
        setShowContactModal(false);
      }}
    />
  );

  const DeleteModal = (
    <ConfirmationModal
      cancelText={translate('CANCEL')}
      confirmText={translate('DELETE_CONTACT_CONFIRMATION_BUTTON')}
      onCancel={() => setShowDeleteContactDialog(false)}
      onConfirm={() => {
        deleteContact(selectedContact.id);
        setSelectedContact(undefined);
        setShowDeleteContactDialog(false);
      }}
      status="warning"
      title={translate('DELETE_CONTACT_CONFIRMATION_TITLE')}
      visible
    />
  );

  return (
    <>
      {canModifyBusiness && (
        <View style={{ alignItems: 'flex-end' }}>
          <Button
            appearance="outline"
            onPress={() => setModalProps({
              type: 'businessModal',
              editMode: true,
              businessId: business.id,
            })}
            size="small"
            testID="edit-button"
          >
            Edit
          </Button>
        </View>
      )}
      <VSpacer size="5" />
      {DetailsCard}
      <VSpacer size="8" />
      {ContactsHeader}
      <VSpacer size="5" />
      {ContactsTable}
      {showContactModal && AddEditModal}
      {showDeleteContactDialog && DeleteModal}
    </>
  );
};
