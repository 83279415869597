import { LargeModal, useToast } from '@design';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { ApiForm, FormEndpoint } from '@shared/interfaces/api';
import { PropertyGroupDetailsPage } from './PropertyGroupDetailsPage';
import { FooterButtons } from './FooterButtons';
import { PropertyListPage } from './PropertyListPage';
import PropertyGroupValidation from './PropertyGroupValidation';
import { PropertyGroupApi } from '../../../../utilities/api/PropertyGroupApi';
import { QueryKeys } from '../../../../constants';
import { useQuery } from 'react-query';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
export interface PropertyGroupModalProps {
  data?: ApiForm,
  isEdit: boolean,
  onCancel: () => void,
  onDone: (propertyGroup: FormEndpoint.Create.Request) => void,
}

export const PropertyGroupModal = ({
  data,
  isEdit,
  onCancel,
  onDone,
}: PropertyGroupModalProps) => {
  const [translate] = useTranslation(['common', 'propertyGroups']);
  const [page, setPage] = useState<number>(0);
  const [propertyGroup, setPropertyGroup] = useState<ApiForm>(data);
  const [isValid, setIsValid] = useState(false);
  const { createToast } = useToast();
  const { currentBusinessId } = useAuthentication();
  const [originalPropertyGroup, setOriginalPropertyGroup] = useState(null);

  useEffect(() => {
    const validate = PropertyGroupValidation.Validate;
    if (isEdit) setIsValid(validate(propertyGroup, page, originalPropertyGroup));
    else setIsValid(validate(propertyGroup, page));
  }, [propertyGroup, page, originalPropertyGroup, isEdit]);

  const getPropertyGroup = async () => {
    if (!isEdit) return null;
    return PropertyGroupApi.getPropertyGroup(data.id);
  };

  useQuery<FormEndpoint.Get.Response, Error>(
    [QueryKeys.PROPERTY_GROUP, currentBusinessId],
    getPropertyGroup,
    {
      onError: () => {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR'),
          status: 'warning',
          testID: 'property-group-edit-error-toast',
        });
      },
      onSuccess: (res) => {
        setPropertyGroup(res);
        setOriginalPropertyGroup(res);
      },
    },
  );

  const pages = [
    <PropertyGroupDetailsPage data={propertyGroup} setPropertyGroup={setPropertyGroup} />,
    <PropertyListPage data={propertyGroup} setPropertyGroup={setPropertyGroup} />,
  ];
  
  const modalTitle:string = isEdit 
    ? translate<string>('PROPERTY_EDIT_TITLE')
    : translate<string>('PROPERTY_CREATE_BUTTON_TITLE');

  return (
    <LargeModal
      currentPage={page}
      footer={
        <FooterButtons
          isEdit={isEdit}
          isValid={isValid}
          onCancel={onCancel}
          onDone={() => onDone(propertyGroup)}
          page={page}
          setPage={setPage}
        />
      }
      pages={pages}
      showSteps={false}
      testID="property-group-modal"
      title={modalTitle}
      visible 
    />
  );
};
