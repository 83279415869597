import {
  Button,
  CenteredSpinner,
  FilterDefault,
  FilterTable,
  HSpacer,
  Icon,
  MenuItem,
  OverflowMenu,
  SortDirection,
  Text,
  TextLink,
  useToast,
} from '@design';
import { FilterCategory, Selections } from '@design/Filter/Filter';
import { FormulationType, ProductCategory } from '@shared/enums';
import { IBusinessLocation } from '@shared/interfaces';
import { ApiCrop, ApiProductSubCategory, ProductEndpoint } from '@shared/interfaces/api';
import {
  ApiProduct,
  ApiProductListQuery,
  ApiProductListQuerySort,
  ApiProductPrice,
} from '@shared/interfaces/api/ProductEndpoint';
import { omit, Permissions, RoleUtility, timeStamp } from '@shared/utils';
import { DocumentResult } from 'expo-document-picker';
import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants/QueryKeys';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { StringUtility } from '../../../utilities';
import { BusinessApi, ProductApi } from '../../../utilities/api';
import { ExportToCSV } from '../../../utilities/helpers/exportCSV';
import { UploadModal } from '../../components/shared/UploadModal/UploadModal';
import { IColumn, RowMeta } from '../../components/SortableTable';
import { ChangeProductStatusDialog } from './ChangeProductStatusDialog';
import { ProductListTabRowDetail } from './ProductListTabRowDetail';
import { ViewPricingModal } from './ViewPricingModal';

const styles = StyleSheet.create({
  flexStart: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  noPadding: {
    paddingVertical: 0,
    paddingHorizontal: 0,
    paddingBottom: 0,
  },
});

interface ProductListTabProps {
  businessId: string,
  locations: IBusinessLocation[],
  modalToggle?: (id: string) => void,
  productCategory: ProductCategory,
}

const filenamePrefix = {
  [ProductCategory.SEED]: 'Seed Products',
  [ProductCategory.FERTILIZER]: 'Fertilizer Products',
  [ProductCategory.CHEMICAL]: 'Chemical Products',
  [ProductCategory.OTHER]: 'Other Products',
};

const defaultPageOptions: ApiProductListQuery = {
  page: 0,
  sort: ApiProductListQuerySort.updatedAt,
  sortDesc: true,
  isActive: ['true'],
};

const sortColDictionary = ProductEndpoint.List.QuerySort;

const DEFAULT_FILTER_VALUES_SEED = ['cropSubType', 'isActive', 'businessCategoryId'];
const DEFAULT_FILTER_VALUES_FERT = ['formulation', 'isActive', 'businessCategoryId'];
const DEFAULT_FILTER_VALUES_OTHER = ['isActive', 'businessCategoryId'];

const emptyAppliedFilters: Map<string, Selections> = new Map();
const defaultIsActiveAppliedFilters: Map<string, Selections> = new Map([
  ['isActive', { true: true }],
]);

const exportProductData = async (
  businessId: string,
  productCategory: ProductCategory,
  filters: ApiProductListQuery,
  translate: any,
  setError: any,
) => {
  try {
    const queryFilters = {
      businessId,
      category: productCategory,
      ...omit(filters, ['sort']),
    };

    const data = await ProductApi.exportProducts(queryFilters);
    const fileName = filenamePrefix[productCategory];

    ExportToCSV(data.csv, `${fileName} ${timeStamp()}`);
  } catch (err) {
    setError('UNEXPECTED_ERROR');
  }
};

const getImportTemplate = async (translate: any, setError: any,
  businessId: string, category: ProductCategory) => {
  try {
    const data = await ProductApi.getCsvTemplate(businessId, category);
    ExportToCSV(data.csv, translate('PRODUCTS'));
  } catch (err) {
    setError('UNEXPECTED_ERROR');
  }
};

export const ProductListTab: FC<ProductListTabProps> = (
  {
    businessId,
    locations,
    productCategory,
    modalToggle,
  }: ProductListTabProps,
) => {
  const scrollRef = useRef<ScrollView>();
  const { user, currentBusinessId } = useAuthentication();

  const { createToast } = useToast();
  const [translate] = useTranslation(['products', 'common']);
  const queryClient = useQueryClient();
  const [initError, setInitError] = useState<Error>(null);
  const [pageOptions, setPageOptions] = useState<ApiProductListQuery>(defaultPageOptions);
  const [defaultFilters, setDefaultFilters] = useState([]);
  const [defaultAppliedFilters, setDefaultAppliedFilters] = useState(emptyAppliedFilters);
  const [filterOptions, setFilterOptions] = useState<FilterCategory[]>([]);
  const [businessCrops, setBusinessCrops] = useState<ApiCrop[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [
    businessProductSubCategories, setBusinessProductSubCategories,
  ] = useState<ApiProductSubCategory[]>([]);
  const [columnCategories, setColumnCategories] = useState<FilterCategory[]>([]);
  const [prices, setPrices] = useState<ApiProductPrice[]>([]);
  const [products, setProducts] = useState([]);
  const [productsForPricing, setProductsForPricing] = useState([]);
  const [productToUpdate, setProductToUpdate] = useState<ApiProduct>(null);
  const [showChangeProductStatusDialog, setShowChangeProductStatusDialog] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [isAllFetched, setIsAllFetched] = useState(false);
  const { defaultPriceTypeId } = usePriceTypeList();

  useEffect(() => {
    if (initError) {
      createToast({
        status: 'warning',
        children: `${translate('UNEXPECTED_ERROR')}`,
        testID: 'toast-content-element',
      });
    }
  }, [createToast, initError, translate]);

  const canEditProduct = RoleUtility.roleHasPermission(
    user?.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const handleSort = (column: string, direction: SortDirection) => {
    setPageOptions({
      ...pageOptions,
      sort: sortColDictionary[column] ?? column,
      sortDesc: direction === 'DESC',
      page: 0,
    });
  };

  const getProducts = async () => {
    const appliedFilters: ApiProductListQuery = { ...pageOptions };
    // when no isActive filters, default to display both active and inactive
    if (!appliedFilters.isActive) {
      appliedFilters.isActive = ['true', 'false'];
    }

    appliedFilters.sort = pageOptions.sort || defaultPageOptions.sort;
    appliedFilters.sortDesc = pageOptions.sortDesc === undefined
      ? defaultPageOptions.sortDesc
      : pageOptions.sortDesc;

    return ProductApi.list({
      ...appliedFilters,
      businessId,
      category: productCategory,
    });
  };
  const { isLoading, isFetching, isFetched: isProductsFetched } = useQuery(
    [QueryKeys[`${productCategory}_LIST`],
      productCategory, businessId, pageOptions, products], getProducts, {
      onError: (err: Error) => {
        setInitError(err);
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (res) => {
        const productsResult = res.data.map((product, idx) => ({
          ...product,
          rowId: `${productCategory.toLowerCase()}-row:${idx}|${product.id}|`,
          hasDetails: true,
        }));
        setProducts(productsResult);
        setProductsForPricing(res.data);
        setTotalResults(res.total);
        setTotalPages(res.lastPage + 1);
        if (scrollRef?.current) {
          scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
          });
        }
      },
    },
  );

  const getBusinessCrops = () => BusinessApi.getBusinessCrops(businessId);
  useQuery([QueryKeys.BUSINESS_CROPS, productCategory], getBusinessCrops, {
    enabled: isProductsFetched && productCategory === ProductCategory.SEED,
    onError: (err: Error) => {
      setInitError(err);
      setPageOptions({ ...pageOptions, page: 0 });
    },
    onSuccess: (data) => {
      setBusinessCrops(data);
    },
  });

  const getBusinessProductCategories = async () => BusinessApi
    .getBusinessProductCategories(businessId);
  const { isFetched: isBusinessCategoriesFetched } = useQuery(
    [QueryKeys.BUSINESS_CATEGORIES, currentBusinessId], getBusinessProductCategories, {
      enabled: isProductsFetched,
      onError: (err: Error) => {
        setInitError(err);
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (res) => {
        const productSubCategories = res
          .find((product) => product.category === productCategory)?.subCategories || [];
        setBusinessProductSubCategories(productSubCategories);
        /**
       * Using a setTimeout here to disable the filter drop downs for a split second
       * because there is a race condition / UI bug where two filter drop downs
       * lose position in the UI and freeze the app when the drop downs are clicked
       * multiple times very quickly before business product categories are
       * fully loaded.
       */
        setTimeout(() => {
          setIsAllFetched(true);
        });
      },
    },
  );

  useEffect(() => {
    const options = [
      productCategory === ProductCategory.SEED
        ? {
          columnLabel: translate('CROP'),
          columnKey: 'cropSubType',
          columns: [],
        } : null,
      productCategory === ProductCategory.CHEMICAL || productCategory === ProductCategory.FERTILIZER
        ? {
          columnLabel: translate('FORMULATION'),
          columnKey: 'formulation',
          columns: [{ label: 'Dry', id: FormulationType.DRY }, { label: 'Liquid', id: FormulationType.LIQUID }],
        } : null,
      {
        columnLabel: translate('STATUS'),
        columnKey: 'isActive',
        columns: [
          { label: translate('ACTIVE'), id: 'true' },
          { label: translate('INACTIVE'), id: 'false' },
        ],
      },
      {
        columnLabel: translate('BUSINESS_PRODUCT_SUB_CATEGORY'),
        columnKey: 'businessCategoryId',
        columns: [],
      },
    ].filter((x) => !!x);

    setFilterOptions(options);
  }, [productCategory, translate]);

  /**
   * Filter logic depending on product category
   */
  useEffect(() => {
    let newColumnCategories: FilterCategory[];
    let newColumnDefaults;
    let newProductSubCategories;

    if (filterOptions.length) {
      newColumnCategories = _.cloneDeep(filterOptions);

      if (productCategory === ProductCategory.SEED) {
        newColumnDefaults = [...DEFAULT_FILTER_VALUES_SEED];
      } else if (productCategory === ProductCategory.CHEMICAL
        || productCategory === ProductCategory.FERTILIZER) {
        newColumnDefaults = [...DEFAULT_FILTER_VALUES_FERT];
      } else {
        newColumnDefaults = [...DEFAULT_FILTER_VALUES_OTHER];
      }

      if (!businessProductSubCategories.length) {
        const removeIdxCategories = newColumnCategories
          .findIndex((category) => category.columnKey === 'businessCategoryId');
        const removeIdxDefaults = newColumnDefaults
          .findIndex((category) => category === 'businessCategoryId');
        newColumnCategories.splice(removeIdxCategories, 1);
        newColumnDefaults.splice(removeIdxDefaults, 1);
      }
      if (businessProductSubCategories.length) {
        if (!newColumnCategories.find(
          (val) => val.columnKey === 'businessCategoryId',
        )) {
          newColumnCategories.concat({
            columnLabel: translate('BUSINESS_PRODUCT_SUB_CATEGORY'),
            columnKey: 'businessCategoryId',
            columns: [],
          });
        }
        newProductSubCategories = businessProductSubCategories.map((subCategory) => ({
          label: subCategory.name,
          id: subCategory.id,
        })).sort((a, b) => a.label.localeCompare(b.label));

        const hasCategory = !!newColumnCategories.find((c) => c.columnKey === 'businessCategoryId');
        if (hasCategory && newProductSubCategories.length) {
          newColumnCategories
            .find((category) => category.columnKey === 'businessCategoryId')
            .columns = newProductSubCategories;
        }
      }

      if (productCategory === ProductCategory.SEED) {
        // Logic for populating crop type
        const newProductCropTypes = businessCrops.map(({ cropType, subType }) => ({
          label: `${translate(cropType)} | ${translate(subType)}`,
          id: subType,
        }));
        newColumnCategories
          .find((category) => category.columnKey === 'cropSubType')
          .columns = newProductCropTypes;
      }

      if (isBusinessCategoriesFetched) {
        setColumnCategories(newColumnCategories);
        setDefaultFilters(newColumnDefaults);
        setDefaultAppliedFilters(defaultIsActiveAppliedFilters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessCrops,
    businessProductSubCategories,
    defaultAppliedFilters,
    filterOptions,
    productCategory,
    translate,
  ]);

  useEffect(() => {
    setColumnCategories([]);
    setPageOptions(defaultPageOptions);
  }, [currentBusinessId]);

  const canImportProducts = () => (
    RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES)
  );

  const canEditBusiness = () => (
    RoleUtility.canEditBusiness(user, currentBusinessId)
  );

  const calculatePrices = (product: Pick<ApiProduct, 'prices' | 'packageUnitQuantity'>) => {
    const defaultUnitPrice = product.prices
      .find((price) => !price.businessLocationId
        && price.priceTypeId === defaultPriceTypeId)?.price;
    const packagePrice = defaultUnitPrice * product.packageUnitQuantity;
    return { defaultUnitPrice, packagePrice };
  };

  const assignPricesForPricingModal = (pricesToSet: ApiProductPrice[]) => {
    setPrices(pricesToSet);
    setShowPricingModal(true);
  };

  const columns: IColumn<(ApiProduct & RowMeta)>[] = [
    {
      columnId: ProductEndpoint.List.QuerySort.name,
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (product) => <Text wrap>{product.name}</Text>,
      flex: 1,
    },
    {
      columnId: ProductEndpoint.List.QuerySort.skuName,
      header: {
        render: translate('SKU_NAME'),
        sortable: true,
      },
      render: (product) => <Text wrap>{product.skuName}</Text>,
      flex: 0.75,
    },
    {
      columnId: 'packageUnitQuantity',
      header: {
        render: translate('UNITS_PER_SKU'),
      },
      render: (product) => `${product.packageUnitQuantity} ${translate(product.unitUoM)}`,
      flex: 1,
    },
    productCategory !== ProductCategory.OTHER && {
      columnId: ProductEndpoint.List.QuerySort.unitPrice,
      header: {
        render: translate('UNIT_PRICE'),
        sortable: true,
      },
      render: (product) => StringUtility.formatCurrencyAccounting(
        calculatePrices(product).defaultUnitPrice,
      ),
      flex: 1.25,
    },
    {
      columnId: ProductEndpoint.List.QuerySort.packagePrice,
      header: {
        render: translate('SKU_PRICE'),
        sortable: true,
      },
      render: (product) => StringUtility.formatCurrencyAccounting(
        calculatePrices(product).packagePrice,
      ),
      flex: 0.75,
    },
    {
      columnId: 'view-pricing',
      header: {
        render: '',
      },
      render: (product) => (
        <Button
          appearance="outline"
          onPress={() => assignPricesForPricingModal(product.prices)}
          size="tiny"
          testID="view-pricing-button"
        >
          {translate<string>('VIEW_PRICING')}
        </Button>
      ),
      flex: 1,
    },
    canEditProduct
    && ({
      columnId: 'menu',
      header: { render: null },
      render: (product) => (
        <OverflowMenu testID="product-list-tab-overflow-menu">
          <MenuItem
            onPress={() => modalToggle(product.id)}
            testID="edit-product-option"
            title={`${translate('EDIT')}`}
          />
          <MenuItem
            onPress={() => {
              setProductToUpdate(product);
              setShowChangeProductStatusDialog(true);
            }}
            testID="change-product-status-option"
            title={`${product.isActive ? translate('DEACTIVATE') : translate('ACTIVATE')}`}
          />
        </OverflowMenu>
      ),
      flex: 0.25,
    }),
  ];

  function createButton () {
    const category = productCategory;
    const tId = `create-${category.toLowerCase()}-button`;
    if (category === ProductCategory.SEED) {
      return (
        <Button
          accessoryLeft={<Icon name="Plus" testID={`${tId}-icon`} />}
          onPress={() => modalToggle('')}
          testID={tId}
        >
          {translate(`${productCategory}_PRODUCT_BUTTON`) as string}
        </Button>
      );
    }

    return (
      <Button
        accessoryLeft={<Icon name="Plus" testID={`${tId}-icon`} />}
        onPress={() => modalToggle('')}
        testID={tId}
      >
        {translate(`${productCategory}_PRODUCT_BUTTON`) as string}
      </Button>
    );
  }

  return (
    <>
      { (isLoading && !isFetching) && <CenteredSpinner isFixed /> }
      <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false}>
        <FilterTable<FilterDefault, ApiProduct & RowMeta>
          accessoryRight={() => (
            <View style={{ flexDirection: 'row' }}>
              {canImportProducts() && (
                <>
                  <Button
                    accessoryLeft={(iconProps) => (
                      <Icon name="Upload" testID="upload-products-icon" {...iconProps} />
                    )}
                    appearance="ghost"
                    onPress={() => setShowUploadModal(true)}
                    status="basic"
                    testID="upload-products"
                  >
                    {translate('IMPORT') as string}
                  </Button>
                  <HSpacer size="5" />
                </>
              )}
              <Button
                accessoryLeft={(iconProps) => (
                  <Icon name="FileText" testID="export-products-button-icon" {...iconProps} />
                )}
                appearance="ghost"
                onPress={() => exportProductData(businessId,
                  productCategory, pageOptions, translate, setInitError)}
                status="basic"
                testID="export-products-button"
              >
                {translate('EXPORT') as string}
              </Button>
              {canEditBusiness() && (
                <>
                  <HSpacer size="5" />
                  {createButton()}
                </>
              )}
            </View>
          )}
          columns={columns}
          currentPage={pageOptions.page + 1}
          data={products}
          defaultColumnFilters={defaultFilters}
          defaultFilters={defaultAppliedFilters}
          filterOptions={columnCategories}
          isFilterDisabled={!isAllFetched}
          isLoading={isFetching}
          noFilters={false}
          onPageChange={(pg: number) => {
            setPageOptions({ ...pageOptions, page: pg - 1 });
          }}
          onSort={handleSort}
          onUpdateFilter={(filter) => {
            setPageOptions({ ...filter, page: 0 });
          }}
          rowDetail={(product) => (
            <ProductListTabRowDetail locations={locations} product={product} />
          )}
          searchBoxTestID={`${productCategory.toLowerCase()}-search-bar`}
          tableContainerStyle={styles.noPadding}
          tableId={`${productCategory.toLowerCase()}-table`}
          tableRowId={`${productCategory.toLowerCase()}ListView`}
          testID={`${productCategory.toLowerCase()}-table-testID`}
          totalPages={totalPages}
          totalResults={totalResults}
        />
        <ChangeProductStatusDialog
          businessId={businessId}
          isVisible={showChangeProductStatusDialog}
          onCancel={() => setShowChangeProductStatusDialog(false)}
          product={productToUpdate}
          productCategory={productCategory}
        />
        {showUploadModal && (
          <UploadModal
            onCancel={async () => {
              setShowUploadModal(false);
            }}
            onDone={async () => {
              setShowUploadModal(false);
              await queryClient.invalidateQueries(QueryKeys[`${productCategory}_LIST`]);
            }}
            onFileUpload={(fileInfo: DocumentResult) => ProductApi.importProducts(
              fileInfo,
              productCategory,
              businessId,
            )}
          >
            <View style={styles.flexStart}>
              <Text>{`${translate('UPLOAD_PRODUCTS_CSV')}`}</Text>
              <TextLink
                appearance="secondary"
                onPress={() => getImportTemplate(
                  translate, setInitError, currentBusinessId, productCategory,
                )}
              >
                {translate('THIS_TEMPLATE') as string}
              </TextLink>
            </View>
          </UploadModal>
        )}
      </ScrollView>
      {showPricingModal && (
        <ViewPricingModal
          onClose={() => setShowPricingModal(false)}
          prices={prices}
          products={productsForPricing}
          showPricingModal={showPricingModal}
        />
      )}
    </>
  );
};
