import { Button, Icon } from '@design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testId } from '../../../../../utilities';

export interface NoteButtonProps {
  note?: string;
  onPress?(): void;
}
export function NoteButton ({ note, onPress }: NoteButtonProps) {
  const [translate] = useTranslation(['productOrders', 'common']);

  const noteEmpty = !note || note.trim() === '';
  const NoteIcon = (props) => (
    <Icon
      {...testId('note-button-icon')}
      {...props}
      name={noteEmpty ? 'Plus' : 'Edit'}
    />
  );

  return (
    <Button
      {...testId('note-button')}
      accessoryLeft={NoteIcon}
      appearance="ghost"
      onPress={onPress}
      size="medium"
      status="basic"
    >
      {translate<string>(noteEmpty ? 'ADD_NOTE' : 'EDIT_NOTE')}
    </Button>
  );
}
