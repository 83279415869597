import { Dimensions } from 'react-native';

type Coordinates = { x: number, y: number };
class ClientDimensionUtility {
  private delta: Coordinates; // change since last get.
  private current: Coordinates; // current dimensions.
  private initial: Coordinates; // initial dimensions.

  constructor () {
    this.delta = {
      x: 0,
      y: 0,
    };
    this.current = {
      x: Dimensions.get('window').width,
      y: Dimensions.get('window').height,
    };
    this.initial = {
      x: Dimensions.get('window').width,
      y: Dimensions.get('window').height,
    };
  }

  public get = () => {
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;

    this.delta = {
      x: this.current.x - width,
      y: this.current.y - height,
    };
    this.current = {
      x: width,
      y: height,
    };

    return {
      delta: this.delta,
      current: this.current,
      initial: this.initial,
    };
  };
}

export default new ClientDimensionUtility();