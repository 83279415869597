import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Text, HSpacer, VSpacer } from '@design';
import { StringUtility, testId } from '../../../../../utilities';

export interface ProductDiscountItemProps {
  discount: ProductOrderDeliverableV0.ProductDiscount,
  index: number,
  prefix?: string,
}

export const ProductDiscountItem: FC<ProductDiscountItemProps> = ({
  discount,
  index,
  prefix,
}) => {
  const [translate] = useTranslation(['productOrders', 'common']);

  const themedStyles = useStyleSheet({
    container: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    row: {
      flexDirection: 'row',
    },
    column: {
      flex: 1,
      flexDirection: 'column',
    },
  });

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.column}>
        <Text {...testId('product-discount-item-name', index)} category="s1">
          {prefix ? `${prefix} - ` : ''}{discount.name}
        </Text>
        <VSpacer size="3" />
        <View style={themedStyles.row}>
          <Text appearance="hint" category="p2">{`${translate<string>('TYPE')}: `}</Text>
          <Text category="p2" {...testId('product-discount-item-cost-type', index)}>
            {translate<string>(discount.costType)}
          </Text>
          {!!discount.percent && (
            <>
              <HSpacer size="7" />
              <Text {...testId('product-discount-item-percent', index)} category="p2" status="success">
                {`${discount.percent}% ${translate<string>('OFF')}`}
              </Text>
            </>
          )}
          {!!discount.dollarsTotal && (
            <>
              <HSpacer size="7" />
              <Text {...testId('product-discount-item-dollars', index)} category="p2" status="success">
                {StringUtility.formatDiscount(discount.dollarsTotal)}
              </Text>
            </>
          )}
        </View>
      </View>
    </View>
  );
};
