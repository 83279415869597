import React, { FC, useEffect, useState } from 'react';
import { ApiFeatureFlag } from '@shared/interfaces/api/FeatureFlagEndpoint';
import { Card, Modal } from '@ui-kitten/components';
import { View } from 'react-native';
import { Button, Input, Text, Toggle, useFeatureFlags } from '@design';
import { FeatureFlagsApi } from '../../../../utilities/api/FeatureFlagsApi';
import { UnexpectedError } from '../../../../ui-components/utils/UnexpectedError';
import { ToasterUtility } from '../../../../elements/content';
import {
  detailsHasErrors,
  errorIsDetailedApiError,
} from '../../../../utilities/api/DetailedApiError';

interface Props {
  featureFlag?: ApiFeatureFlag;
  onFinish: () => void;
}

export const FeatureFlagEdit: FC<Props> = ({
  featureFlag,
  onFinish,
}) => {
  const { updateFlag } = useFeatureFlags();

  const [includeIds, setIncludeIds] = useState('');
  const [excludeIds, setExcludeIds] = useState('');
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!featureFlag) {
      setIncludeIds('');
      setExcludeIds('');
      setEnabled(false);
    } else {
      setIncludeIds(featureFlag.includedBusinessIds.join(','));
      setExcludeIds(featureFlag.excludedBusinessIds.join(','));
      setEnabled(featureFlag.enabled);
    }
  }, [featureFlag]);

  if (!featureFlag) {
    return null;
  }

  return (
    <Modal style={{ width: '90%' }} visible>
      <Card
        footer={({ style, ...props }) => (
          <View {...props} style={[style, { flexDirection: 'row' }]}>
            <Button
              onPress={async () => {
                try {
                  const updatedFlag = await FeatureFlagsApi.updateFeatureFlag({
                    ...featureFlag,
                    includedBusinessIds: (
                      includeIds.split(',').map((s) => s.trim()).filter((id) => !!id)
                    ),
                    excludedBusinessIds: (
                      excludeIds.split(',').map((s) => s.trim()).filter((id) => !!id)
                    ),
                    enabled,
                  });
                  updateFlag(updatedFlag);
                  onFinish();
                } catch (e) {
                  const err = e as Error & { code: string, details: string };
                  if (!errorIsDetailedApiError(err as Error)) {
                    UnexpectedError(err as Error, 'Error while saving');
                    return;
                  }

                  switch (err.code) {
                    case 'invalid-business':
                      // eslint-disable-next-line no-case-declarations
                      const ids = (
                        detailsHasErrors(err.details)
                          ? err.details.errors
                          : ['ids not returned']
                      );
                      ToasterUtility.error({
                        children: (
                          <Text>
                            The following businessIds are invalid:
                            {` ${ids.join(', ')}`}
                          </Text>
                        ),
                        testID: 'toast-content-element',
                      });
                      break;
                    default:
                      UnexpectedError(err, 'Error while saving');
                      break;
                  }
                }
              }}
              size="medium"
              style={{ marginRight: 5 }}
              testID="save-button"
            >
              Save
            </Button>
            <Button onPress={onFinish} size="medium" testID="cancel-button">Cancel</Button>
          </View>
        )}
        header={({ style, ...props }) => (
          <View {...props} style={[style, { flexDirection: 'row' }]}>
            <Text category="h4">Edit Flag:</Text>
            <Text category="h5">{` ${featureFlag.name}`}</Text>
          </View>
        )}
        testID="feature-flag-card"
      >
        <Toggle checked={enabled} onChange={setEnabled}>Enabled</Toggle>
        <Input
          caption="comma separated"
          label="Include Business Ids"
          multiline
          onChangeText={setIncludeIds}
          testID="include-businessId-input"
          textStyle={{ height: '5em' }}
          value={includeIds}
        />
        <Input
          caption="comma separated"
          label="Exclude Business Ids"
          multiline
          onChangeText={setExcludeIds}
          testID="exclude-businessId-input"
          textStyle={{ height: '5em' }}
          value={excludeIds}
        />
      </Card>
    </Modal>
  );
};
