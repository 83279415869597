import { Button, Dialog, HSpacer, Text, ToastProps, useToast } from '@design';
import { ApiProductOrder } from '@shared/interfaces/api';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useAppContext } from '../../../../../contexts/AppContext';
import { testId } from '../../../../../utilities/testId';
import { ToastManager } from '../../../../components/DesignSystem/Application/Toast/ToastManager';

const styles = StyleService.create({
  footer: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    flexDirection: 'row',
    display: 'flex',
  },
});

interface ExitButtonProps {
  onClose: () => void;
  onSave: () => Promise<boolean>;
  productOrder: ApiProductOrder;
}

export const ExitButton: FC<ExitButtonProps> = ({ onClose, onSave, productOrder }) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const { createToast, destroyToast } = useToast();
  const { setModalProps } = useAppContext();
  const [show, setShow] = useState(false);
  const themedStyles = useStyleSheet(styles);

  const showDialog = () => {
    if (productOrder.name || productOrder.growerId || productOrder.locationId) {
      setShow(true);
    } else {
      onClose();
    }
  };

  const getText = (type: string) => {
    if (type === 'description' && productOrder.id && !productOrder.growerId) return '';

    if (productOrder.id && !productOrder.growerId) {
      return translate('EXIT_WITHOUT_SAVING');
    }

    if (productOrder.id && productOrder.growerId) {
      return translate(`EXIT_SAVING${type === 'description' ? '_DESCRIPTION' : ''}`);
    }

    return translate(`YES_EXIT_WITHOUT_SAVING${type === 'description' ? '_DESCRIPTION' : ''}`);
  };

  const saveCloseModal = async () => {
    if (productOrder.id && productOrder.growerId) {
      await onSave();
      const toastId = ToastManager.generateUniqueComponentKey();
      const toastProps: ToastProps = {
        children: `${translate('QUICK_QUOTE_SAVED')}`,
        status: 'success',
        accessoryRight: (props) => (
          <Button
            {...testId('view-quick-quote-link')}
            {...props}
            onPress={() => {
              destroyToast(toastId);
              setModalProps({
                type: 'viewProductOrder',
                productOrderId: productOrder.id,
              });
            }}
            status="basic"
          >
            {translate<string>('VIEW_QUICK_QUOTE')}
          </Button>
        ),
        testID: 'toast-content-element',
      };
      createToast(toastProps, toastId);
    }
    onClose();
  };
  const title = useMemo(() => {
    if (productOrder.id && !productOrder.growerId) {
      return translate('CANNOT_SAVE_WITHOUT_CUSTOMER');
    }
    return translate('EXIT_TITLE');
  }, [productOrder.id, productOrder.growerId, translate]);

  return (
    <>
      <Dialog
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <View style={themedStyles.footer}>
              <Button
                {...testId('product-order-exit-modal-continue-editing')}
                {...primaryButtonProp}
                appearance="outline"
                status="basic"
              >
                {`${translate('CONTINUE_EDITING')}`}
              </Button>
              <HSpacer {...spacerProp} />
              <Button
                {...testId('product-order-exit-modal-save')}
                {...secondaryButtonProp}
                onPress={saveCloseModal}
              >
                {`${getText('button')}`}
              </Button>
            </View>
          </>
        )}
        onClose={() => setShow(false)}
        testID="product-order-exit-modal"
        title={title}
        visible={show}
      >
        <Text category="p2" {...testId('product-order-exit-modal-description')}>
          {`${getText('description')}`}
        </Text>
      </Dialog>
      <Button
        appearance="outline"
        design="floating"
        onPress={showDialog}
        size="large"
        status="basic"
        {...testId('product-order-button-exit')}
      >
        {translate('EXIT') as string}
      </Button>
    </>
  );
};
