import {
  ApiAuthTokenUser,
  ApiResetPassword,
  IAuthTokenData,
  UserPasswordEndpoint,
} from '@shared/interfaces/api';
import base64url from 'base64url';
import { Buffer } from 'buffer';
import { Environment } from '../Environment';
import { ApiError } from './ApiError';

// Buffer may not be available on native platform, which is used by
// base64url. As a workaround we use the npm package Buffer in this case
global.Buffer = global.Buffer || Buffer;

export interface IAuthResponse {
  user: ApiAuthTokenUser;
  token: string;
}

export class AuthApi {
  /**
   * Attempts a login with the API
   * @param email The user email
   * @param password The user password
   * @returns The logged in user and bearer token
   */
  static async login (email: string, password: string): Promise<IAuthResponse> {
    const response = await fetch(`${Environment.get('API_URL')}/login`, {
      body: JSON.stringify({
        email,
        password,
      }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [Environment.get('API_KEY_NAME')]: Environment.get('API_KEY'),
      },
      method: 'POST',
    });
    if (response.status >= 300) {
      throw new ApiError(response.status);
    }

    const content = await response.json();
    return {
      token: content.token,
      user: AuthApi.parseJwt(content.token).user,
    };
  }

  /**
   * Attempts a logout with the API
   * @returns The response object
   */
  static async logout () {
    await fetch(`${Environment.get('API_URL')}/logout`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [Environment.get('API_KEY_NAME')]: Environment.get('API_KEY'),
      },
      method: 'POST',
    });
  }

  static parseJwt (token: string): IAuthTokenData {
    let decodedToken: string = base64url.decode(token.split('.')[1]);
    decodedToken = decodedToken.substring(0, decodedToken.lastIndexOf('}') + 1);
    return JSON.parse(decodedToken) as IAuthTokenData;
  }

  /**
   * Called when a user clicks 'forgot password'
   */
  static async requestPasswordReset (email: string) {
    const requestBody: UserPasswordEndpoint.RequestResetPassword.Request = {
      email,
    };
    const response = await fetch(`${Environment.get('API_URL')}/requestResetPassword`, {
      body: JSON.stringify(requestBody),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [Environment.get('API_KEY_NAME')]: Environment.get('API_KEY'),
      },
      method: 'POST',
    });
    if (response.status >= 300) {
      throw new ApiError(response.status);
    }

    const content = await response.json();
    return content;
  }

  /**
   * Reset a user's password given a reset token and their new password.
   */
  static async resetPassword (token: string, password: string): Promise<void> {
    const requestBody: ApiResetPassword = {
      newPassword: password,
      token,
    };
    const response = await fetch(`${Environment.get('API_URL')}/resetPassword`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        [Environment.get('API_KEY_NAME')]: Environment.get('API_KEY'),
      },
      body: JSON.stringify(requestBody),
    });
    if (response.status >= 300) {
      const errResponse = await response.json();
      throw new ApiError(errResponse.code, errResponse.message);
    }
  }
}
