import { Button, HSpacer, Modal, NumericInput, Select, SelectItem, Text, VSpacer } from '@design';
import { AgriculturalUnit, ApplicationUom, ProductCategory } from '@shared/enums';
import { ApiProductOrderComponent } from '@shared/interfaces/api';
import { CalculationUtility, ProductUtilities } from '@shared/utils';
import { IndexPath, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Sizing } from '../../../../../constants';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import { StringUtility } from '../../../../../utilities';
import { CalculatedQuantity } from './CalculateQuantityDetails';

interface CalculateQuantityModalProps {
  acres: number
  calculatedQuantity?: CalculatedQuantity
  component: ApiProductOrderComponent
  isVisible: boolean
  locationId: string | null
  onCancel: () => void
  onApply: (calculatedQuantity: CalculatedQuantity) => void
}

export const CalculateQuantityModal: FC<CalculateQuantityModalProps> = ({
  acres: defaultAcres,
  calculatedQuantity: initialCalculatedQuantity,
  component,
  isVisible,
  locationId,
  onCancel,
  onApply,
}) => {
  const [translate] = useTranslation(['productOrders', 'growers', 'common']);

  const getUomOptions = useCallback(() => (
    ProductUtilities.getProductApplicationUoMs(component.product)),
  [component.product]);

  const [applicationRate, setApplicationRate] = useState<number>(null);
  const [acres, setAcres] = useState<number>(defaultAcres);
  const [selectedUom, setSelectedUom] = useState<ApplicationUom>(getUomOptions()[0]);
  const [calculatedQuantity, setCalculatedQuantity] = (
    useState<CalculatedQuantity>(initialCalculatedQuantity)
  );
  const { defaultPriceTypeId } = usePriceTypeList();

  const styles = useStyleSheet({
    modal: {
      paddingHorizontal: Sizing.DOUBLE_SPACING,
    },
    inputRow: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
    input: {
      width: 168,
    },
    select: {
      width: 144,
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      color: 'text-hint-color',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  });

  useEffect(() => {
    if (initialCalculatedQuantity) {
      setApplicationRate(initialCalculatedQuantity.applicationRate);
      setSelectedUom(initialCalculatedQuantity.applicationUom);
      setCalculatedQuantity(initialCalculatedQuantity);
    } else {
      setApplicationRate(null);
      setCalculatedQuantity(null);
      if (component.product?.category === ProductCategory.SEED) {
        setSelectedUom(AgriculturalUnit.KSDS);
      } else {
        setSelectedUom(getUomOptions()[0]);
      }
    }
  }, [component, getUomOptions, initialCalculatedQuantity]);

  const isValidApplicationRate = !!applicationRate
    && !!acres
    && !Number.isNaN(acres + applicationRate);
  const isFormValid = isValidApplicationRate && !!selectedUom;

  useEffect(() => {
    if (isFormValid && isVisible) {
      const requiredPackages = (ProductUtilities.getRequiredProductUnitsPerAcre(
        component.product,
        applicationRate,
        selectedUom,
      ) / component.product.packageUnitQuantity) * acres;
      const roundedPackages = CalculationUtility.roundToPlaces(requiredPackages, 4);
      const calculation = {
        acreage: acres,
        quantity: roundedPackages,
        applicationRate,
        applicationUom: selectedUom,
        price: CalculationUtility.getApplicableProductPrice(
          component.product,
          defaultPriceTypeId,
          locationId,
        ),
      } as CalculatedQuantity;
      setCalculatedQuantity(calculation);
    } else {
      setCalculatedQuantity(null);
    }
  }, [
    acres,
    applicationRate,
    component.product,
    defaultPriceTypeId,
    locationId,
    isFormValid,
    isVisible,
    selectedUom,
  ]);

  const footerAccessory = ({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
    <>
      <Button
        {...primaryButtonProp}
        onPress={onCancel}
        testID="calculate-qty-modal-cancel"
      >
        {translate('CANCEL') as string}
      </Button>
      <HSpacer {...spacerProp} />
      <Button
        {...secondaryButtonProp}
        disabled={!isFormValid}
        onPress={() => onApply(calculatedQuantity)}
        testID="calculate-qty-modal-apply"
      >
        {translate('APPLY') as string}
      </Button>
    </>
  );

  return (
    <Modal
      footerAccessory={footerAccessory}
      hideCloseButton
      style={styles.modal}
      testID="calculate-quantity-modal"
      title={translate('CALCULATE_QUANTITY')}
      visible={isVisible}
    >
      <VSpacer size="3" />
      <NumericInput
        label={`${translate('ACRES')}`}
        minValue={0}
        onChangeValue={(value) => {
          setAcres(value);
        }}
        precision={3}
        style={styles.input}
        testID="acres-input"
        value={acres}
      />
      <VSpacer size="8" />
      <View style={styles.inputRow}>
        <NumericInput
          label={`${translate('APPLICATION_RATE')}${translate('PER_AC')}`}
          minValue={0}
          onChangeValue={(rate) => {
            setApplicationRate(rate);
          }}
          precision={3}
          style={styles.input}
          testID="application-rate-input"
          value={applicationRate}
        />
        <HSpacer size="7" />
        <Select
          label={translate('UOM') as string}
          onSelect={(index: IndexPath | IndexPath[]) => setSelectedUom(
            getUomOptions()[(index as IndexPath).row],
          )}
          style={styles.select}
          testID="uom-selector"
          value={translate(selectedUom) as string}
        >
          {getUomOptions().map((uom, i) => (
            <SelectItem
              key={uom}
              testID={`uom-selector-${i}`}
              title={translate(uom) as string}
            />
          ))}
        </Select>
      </View>
      <VSpacer size="8" />
      {isFormValid && (
        <>
          <VSpacer size="3" />
          <View style={styles.labelContainer}>
            <Text style={styles.label}>{`${translate('SKU_QUANTITY_NEEDED')}:`}</Text>
            <HSpacer size="3" />
            <Text testID="quantity-label">
              {StringUtility.localizeNumber(calculatedQuantity?.quantity, 4)}
            </Text>
          </View>
        </>
      )}
    </Modal>
  );
};
