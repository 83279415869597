import {
  Button,
  Card,
  CenteredSpinner,
  Chip,
  HSpacer,
  Icon,
  Input,
  LargeModal,
  Select,
  SelectItem,
  Text,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import { SharedConfig } from '@shared/constants';
import { BusinessLocationCountryList } from '@shared/constants/LocationCountries';
import { GrowerType, UserRole, UserType } from '@shared/enums';
import {
  ApiBusiness,
  ApiGrower,
  GrowerEndpoint,
} from '@shared/interfaces/api';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useQuery } from 'react-query';
import { getGrowerRoute, QueryKeys, Routes } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../hooks/useIntegration';
import { useHistory } from '../../../router';
import { BusinessApi, BusinessLocationApi, GrowerApi } from '../../../utilities/api';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { CustomerAssignUsersModal } from './CustomerAssignUsersModal';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 489,
  },
  footer: {
    position: 'absolute',
    flexDirection: 'row',
    right: 132,
    bottom: 32,
    width: 100,
  },
  card: {
    flex: 1,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
  },
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
  assignedUser: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chip: {
    marginRight: 12,
    marginBottom: 12,
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export interface IAssignedUser {
  fullName: string,
  id: string,
}

interface CustomerFormPageProps {
  growerId?: string,
  modalToggle: () => void,
  /**
   * When grower list page is flipped, invalidate keys
   * using queryClient.invalidate(key) onSubmit.
   */
  refreshPage?: () => void,
}

interface FooterProps {
  formClear: () => void,
  growerId?: string,
  hasChanged: () => boolean,
  saving: boolean,
  submit: (callBack: () => void) => void,
  toggle: () => void,
  validForm: () => boolean,
}

const Footer = (props: FooterProps) => {
  const { formClear, growerId, hasChanged, saving, submit, toggle, validForm } = props;
  const [translate] = useTranslation(['growers']);
  return (
    <View style={styles.footer}>
      <Button
        appearance="outline"
        design="floating"
        onPress={() => {
          if (!growerId) {
            formClear();
          }
          toggle();
        }}
        status="basic"
        testID="grower-form-modal-cancel-button"
      >
        {translate<string>('CANCEL')}
      </Button>
      <HSpacer size="6" />
      <Button
        design="floating"
        disabled={!validForm() || !hasChanged() || saving}
        onPress={() => submit(toggle)}
        testID="grower-form-modal-submit-button"
      >
        {translate<string>('SAVE')}
      </Button>
    </View>
  );
};

export const CustomerModal = (props: CustomerFormPageProps) => {
  const { growerId, modalToggle, refreshPage } = props;
  const history = useHistory();
  const [translate] = useTranslation(['growers', 'common']);
  const { currentBusiness, currentBusinessId, user } = useAuthentication();
  const { createToast } = useToast();
  const { createBanner } = useBanner();
  const [allCountryList, setAllCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState<IAssignedUser[]>([]);
  const [assignUsersModalVisible, setAssignUsersModalVisible] = useState(false);
  const [assignedBusiness, setAssignedBusiness] = useState<ApiBusiness>(currentBusiness);
  const [externalId, setExternalId] = useState('');
  const [initialUserAssignments, setInitialUserAssignments] = useState<IAssignedUser[]>([]);
  const [initialGrowerData, setInitialGrowerData] = useState<ApiGrower>();
  const [county, setCounty] = useState('');
  const [error, setError] = useState<Error | null>(null);
  const [growerType, setGrowerType] = useState<GrowerType>(null);
  const [legalName, setLegalName] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [saving, setSaving] = useState(false);

  const { integration } = useIntegration({ businessId: currentBusinessId });

  const redirectUser = useCallback((id: string = growerId) => {
    let url = getGrowerRoute(id, 'info');
    if (user.userType === UserType.INTERNAL || _.isEmpty(id)) {
      refreshPage();
      url = Routes.CUSTOMER_PLANNING.replace(/:tab/, 'customers');
    }
    history.push(url);
  }, [growerId, history, refreshPage, user.userType]);

  useEffect(() => {
    setSaving(false);
  }, [legalName, county]);

  useEffect(() => {
    if (error) {
      createToast({
        status: 'warning',
        children: `${translate(error.message)}`,
        duration: 3000,
        testID: 'toast-content-element',
      });
    }
  }, [createToast, error, translate]);

  useEffect(function updateStateList () {
    if (country) {
      const countryObj = allCountryList.find(({ name }) => name === country);
      setStateList(countryObj?.states || []);
      const isStateInCountry = !!countryObj.states.find(({ name }) => name === state);
      if (!isStateInCountry) {
        setState('');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCountryList, country]);

  const clearForm = () => {
    setAssignedBusiness(null);
    setAssignedUsers([]);
    setCounty('');
    setError(null);
    setGrowerType(null);
    setExternalId('');
    setLegalName('');
    setState('');
  };

  useQuery(
    [QueryKeys.COUNTRIES],
    BusinessLocationApi.getCountries,
    {
      onError: setError,
      onSuccess: (countries) => {
        setAllCountryList(countries);
      },
    },
  );

  const getGrowerData = async () => {
    const growerData = await GrowerApi.getGrower(growerId);
    const contactList = await GrowerApi.getGrowerContacts(
      growerId,
      {
        limit: SharedConfig.MAX_PAGE_LIMIT,
        isActive: ['true', 'false'],
      },
    );

    return { growerData, contactList };
  };

  const { data, isLoading, isFetched } = useQuery(
    [QueryKeys.GROWER, growerId],
    getGrowerData,
    {
      enabled: !!growerId,
      onError: setError,
      onSuccess: ({ growerData }) => {
        setInitialGrowerData(growerData);
        setLegalName(growerData.legalName);
        setGrowerType(growerData.growerType);
        setExternalId(growerData.externalId ?? '');
        setCountry(BusinessLocationCountryList.find(({ abbreviation }) => (
          abbreviation === growerData.country
        ))?.name || growerData.country);
        setState(growerData.state);
        setCounty(growerData.county);
      },
    },
  );

  useQuery(
    [QueryKeys.USER, growerId],
    () => GrowerApi.getAssignedUsers(growerId),
    {
      enabled: !!growerId,
      onError: setError,
      onSuccess: (assignedUserData) => {
        const newAssignedUsers = assignedUserData
          .map((u) => ({ id: u.id, fullName: u.fullName }));
        setAssignedUsers(newAssignedUsers);
        setInitialUserAssignments(newAssignedUsers);
      },
    },
  );

  const getBusinessDetails = async () => BusinessApi
    .getBusiness(growerId && isFetched ? data.growerData.businessId : currentBusinessId);
  useQuery<ApiBusiness, Error>([QueryKeys.BUSINESS_DETAILS],
    getBusinessDetails, {
      enabled: growerId
        ? !!growerId && !!data?.growerData?.businessId
        : !!currentBusinessId,
      onError: setError,
      onSuccess: (businessData) => {
        setAssignedBusiness(businessData);
      },
    });

  const formValid = (): boolean => {
    const requiredFields = [
      legalName,
      country,
      state,
      growerType,
      county,
    ];
    const hasRequiredFields = requiredFields.every(Boolean);
    return hasRequiredFields && !_.isNil(assignedBusiness);
  };

  const hasChanged = (): boolean => {
    const initialCountryName = BusinessLocationCountryList.find(({ abbreviation }) => (
      abbreviation === initialGrowerData?.country
    ))?.name;
    const hasGrowerChanged = (
      legalName !== initialGrowerData?.legalName
      || country !== initialCountryName
      || state !== initialGrowerData?.state
      || county !== initialGrowerData?.county
      || growerType !== initialGrowerData?.growerType
      || externalId !== (initialGrowerData?.externalId ?? '')
    );
    return hasGrowerChanged;
  };

  const getTitle = () => {
    return translate(!growerId ? 'CREATE_CUSTOMER' : 'EDIT_CUSTOMER');
  };

  const onSelectGrowerType = (selection: IndexPath) => {
    const growerTypes = Object.keys(GrowerType) as GrowerType[];
    const selectedGrowerType = growerTypes[selection.row];
    setGrowerType(GrowerType[selectedGrowerType]);
  };

  const getBusinessIdForGrower = () => (
    (growerId || user.userType === UserType.INTERNAL)
      ? assignedBusiness.id
      : currentBusinessId
  );

  const makeWarningBanner = useCallback((message: string) => createBanner({
    status: 'warning',
    children: <Text>{message}</Text>,
    testID: 'warning-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={styles.banner}>
        <Button
          {...dismissProps}
          appearance="ghost"
          size="small"
          status="basic"
          testID="dismiss-button"
        >
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ),
  }), [createBanner, translate]);

  const assignUserShouldBeDisplayed = user.userType === UserType.INTERNAL
    || user.userRole === UserRole.SUPER
    || user.userRole === UserRole.BUSINESS_ADMIN;

  const onSubmit = async () => {
    const formData: GrowerEndpoint.Create.Request = {
      legalName,
      country,
      county,
      state,
      growerType,
      externalId: externalId.trim() || null,
      businessId: getBusinessIdForGrower(),
      assignedUserIds: assignedUsers.map((assignedUser) => assignedUser.id),
    };
    try {
      setSaving(true);
      let grower: ApiGrower;
      if (growerId) {
        grower = await GrowerApi.updateGrower(growerId, formData);
      } else {
        grower = await GrowerApi.createGrower(formData);
      }
      if (!_.isEqual(initialUserAssignments, assignedUsers)) {
        const addUserLinks = [];
        const removeUserLinks = [];
        assignedUsers.forEach((userLink) => {
          const addUser = !initialUserAssignments
            .some((oldUser) => oldUser.id === userLink.id);
          if (addUser) {
            addUserLinks.push(userLink.id);
          }
        });

        initialUserAssignments.forEach((userLink) => {
          const addUser = !assignedUsers
            .some((newUser) => newUser.id === userLink.id);
          if (addUser) {
            removeUserLinks.push(userLink.id);
          }
        });

        if (addUserLinks.length > 0) {
          await GrowerApi.linkBusinessUsers(grower.id, addUserLinks);
        }

        if (removeUserLinks.length > 0) {
          await GrowerApi.unlinkBusinessUsers(grower.id, removeUserLinks);
        }
      }
      modalToggle();
      createToast({
        children: translate<string>(growerId ? 'CUSTOMER_UPDATED' : 'CUSTOMER_CREATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
      if (refreshPage) {
        redirectUser(grower.id);
      }
      setSaving(false);
    } catch (err) {
      if (err instanceof DetailedApiError) {
        if (err.code === 'grower-exists') {
          makeWarningBanner(translate('DUPLICATED_CUSTOMER_NAME'));
        }
        if (err.code === 'email-exists') {
          makeWarningBanner(translate('CONTACT_EMAIL_EXISTS'));
        }
      } else {
        setError(err as SetStateAction<Error>);
      }
    }
  };

  const applySelectedUsers = (users) => {
    setAssignedUsers(users);
    setAssignUsersModalVisible(false);
  };

  const removeAssignedUser = (id: string) => {
    const userIdIndex = assignedUsers.findIndex((u) => id === u.id);
    const newAssignedUsers = [...assignedUsers];
    newAssignedUsers.splice(userIdIndex, 1);
    setAssignedUsers(newAssignedUsers);
  };

  const CustomerFormPage = [
    <View style={{ alignItems: 'center', width: '100%' }}>
      {isLoading && <CenteredSpinner />}
      <View style={styles.container}>
        <KeyboardAwareScrollView>
          <Card style={styles.card} testID="customer-card">
            <Text category="label">
              {translate<string>('CUSTOMER_DETAILS')}
            </Text>
            <VSpacer size="9" />
            <Input
              isRequired
              label={translate<string>('LEGAL_NAME')}
              onChangeText={setLegalName}
              testID="grower-legalname-field"
              value={legalName}
            />
            <VSpacer size="8" />
            <Select
              isRequired
              label={translate<string>('COUNTRY')}
              onSelect={(idx: IndexPath | IndexPath[]) => {
                setCountry(allCountryList[(idx as IndexPath).row].name);
              }}
              testID="grower-country-selector"
              value={country}
            >
              {allCountryList.map(({ name }, i) => (
                <SelectItem
                  key={name}
                  testID={`grower-country-dropdown-value-${i}`}
                  title={name}
                />
              ))}
            </Select>
            <VSpacer size="8" />
            <Select
              disabled={!country}
              isRequired
              label={translate<string>('STATE')}
              onSelect={(idx: IndexPath | IndexPath[]) => {
                setState(stateList[(idx as IndexPath).row].name);
              }}
              testID="grower-state-selector"
              value={state}
            >
              {stateList.map(({ name }, i) => (
                <SelectItem
                  key={name}
                  testID={`grower-state-selector-value-${i}`}
                  title={name}
                />
              ))}
            </Select>
            <VSpacer size="8" />
            <Input
              isRequired
              label={translate<string>('COUNTY')}
              onChangeText={setCounty}
              testID="grower-county-field"
              value={county}
            />
            <VSpacer size="8" />
            <Select
              isRequired
              label={translate<string>('CUSTOMER_TYPE')}
              onSelect={(index: IndexPath | IndexPath[]) => onSelectGrowerType(index as IndexPath)}
              testID="grower-type-selector"
              value={translate<string>(growerType)}
            >
              {Object.keys(GrowerType).map((type, i) => (
                <SelectItem
                  key={type}
                  testID={`grower-type-option-${type}-${i}`}
                  title={translate<string>(GrowerType[type])}
                />
              ))}
            </Select>
            <VSpacer size="8" />
            <Input
              disabled={!!integration}
              label={translate<string>('CUSTOMER_ID')}
              maxLength={50}
              onChangeText={setExternalId}
              testID="grower-customer-id-field"
              value={externalId}
            />
            {user.userType === UserType.INTERNAL && !growerId && (
              <View>
                <VSpacer size="12" />
                <Text>
                  {translate<string>('ASSIGNED_TO', { name: assignedBusiness?.businessName })}
                </Text>
              </View>
            )}
            {assignUserShouldBeDisplayed && (
              <>
                <VSpacer size="9" />
                <View
                  style={styles.assignedUser}
                >
                  <Text
                    category="label"
                  >
                    {translate<string>('ASSIGN_USERS_TO_CUSTOMER')}
                  </Text>
                  <Button
                    accessoryLeft={() => <Icon name="PersonAdd" status="primary" testID="assign-users-button-icon" />}
                    appearance="outline"
                    onPress={() => { setAssignUsersModalVisible(true); }}
                    size="small"
                    testID="assign-users-button"
                  >
                    {translate<string>('ASSIGN_USERS')}
                  </Button>
                </View>
              </>
            )}
            {assignUserShouldBeDisplayed && (
              <View>
                {assignedUsers.length > 0 ? (
                  <View>
                    <VSpacer size="9" />
                    <Text>
                      {translate<string>('ASSIGNED_USERS')}
                    </Text>
                    <VSpacer size="5" />
                    <View style={styles.chipContainer}>
                      {assignedUsers.map((userRow) => (
                        <Chip
                          accessoryRight={(
                            <TouchableOpacity
                              onPress={() => removeAssignedUser(userRow.id)}
                              testID={`assigned-user-chip-close-${userRow.id}`}
                            >
                              <Icon name="Close" testID={`assigned-user-chip-close-icon-${userRow.id}`} />
                            </TouchableOpacity>
                          )}
                          key={`assignedUsersChip${userRow.id}`}
                          style={styles.chip}
                          testID={`assigned-user-chip-${userRow.id}`}
                        >
                          {userRow.fullName}
                        </Chip>
                      ))}
                    </View>
                  </View>
                ) : (
                  <View style={{ alignItems: 'center' }}>
                    <VSpacer size="9" />
                    <Text appearance="hint" category="p2">
                      {translate<string>('ASSIGNED_USER_NONE')}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </Card>
        </KeyboardAwareScrollView>
        {assignUsersModalVisible && (
          <CustomerAssignUsersModal
            assignedUsers={assignedUsers}
            businessId={currentBusinessId}
            isVisible
            onApply={(users) => applySelectedUsers(users)}
            onClose={() => setAssignUsersModalVisible(false)}
          />
        )}
      </View>
    </View>,
  ];

  return (
    <LargeModal
      footer={() => (
        <Footer
          formClear={clearForm}
          hasChanged={hasChanged}
          saving={saving}
          submit={onSubmit}
          toggle={modalToggle}
          validForm={formValid}
        />
      )}
      pages={CustomerFormPage}
      testID="customer-form-modal"
      title={getTitle()}
      visible
    />
  );
};
