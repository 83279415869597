import React, { ReactText } from 'react';
import {
  ViewProps,
  Dimensions,
  Animated,
  ViewStyle,
  View,
  EmitterSubscription,
  StyleProp,
} from 'react-native';
import {
  FalsyFC,
  FalsyText,
  Frame,
  MeasureElement,
  MeasuringElement,
  RenderProp,
} from '@ui-kitten/components/devsupport';
import { styled, StyledComponentProps, StyleType } from '@ui-kitten/components';
import { BannerStatus } from '@theme/variant-interfaces/Status';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import {
  BannerPresentingConfig,
  BannerService,
} from '../Application/Banner/BannerService';
import { TextProps } from '../Text/Text';
import { ButtonProps } from '../Button/Button';
import { VSpacer } from '../Spacer/VSpacer';
import { FalsyIcon } from '../FalsyIcon/FalsyIcon';
import { HSpacer } from '../Spacer/HSpacer';

export interface BannerProps extends BannerPresentingConfig, StyledComponentProps {
  actionAccessory?: RenderProp<{
    dismissProps: Partial<ButtonProps>,
    actionProps: Partial<ButtonProps>
  }>,
  actionBehavior?: () => void,
  children: RenderProp<TextProps> | React.ReactText,
  closeBehavior?: () => void,
  duration?: number,
  onClose?: () => void,
  status?: BannerStatus,
  style?: StyleProp<ViewStyle>,
  testID: string,
}

export type BannerElement = React.ReactElement<BannerProps>;

interface State {
  contentFrame: Frame;
  forceMeasure: boolean;
  // this is a state machine
  // 1. measure -
  //    render the element the page and measure it for width
  //    set the right margin to negative width
  //    animate right margin to final display
  // 2. slideIn - state while the slide in animation is executing
  //    start the duration timer
  // 3. hold = state after the slide in animation is complete.
  renderState: 'measure' | 'slideIn' | 'hold' | 'slideOut';
  rightMargin: Animated.Value;
  bottomMargin: Animated.Value;
}

const iconDictionary = {
  info: 'Info',
  success: 'CheckmarkCircle2',
  warning: 'AlertTriangle',
  danger: 'AlertCircle',
  default: '',
};

/**
 * A wrapper that presents content above an enclosing view.
 *
 * @extends React.Component
 *
 * @method {() => void} show - Sets modal visible.
 *
 * @method {() => void} hide - Sets modal invisible.
 *
 * @property {ReactNode} children - Component to render within the banner.
 *
 * @property {Renderprop<Partial<ButtonProps>>} accessoryRight - For rendering an action
 *
 * @property {BannerStatus} status - Highlight banner with a status
 *
 * @property {() => void} onClose - Method called when the banner finishes sliding out.
 *
 * @property {ViewProps} ...ViewProps - Any props applied to View component.
 *
 * @overview-example BannerSimpleUsage
 * Bannerss accept content views as child elements and are displayed in the bottom screen center.
 *
 */
@styled('Banner')
export class Banner extends React.PureComponent<BannerProps, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    contentFrame: Frame.zero(),
    forceMeasure: false,
    renderState: 'measure',

    rightMargin: new Animated.Value(-999),
    bottomMargin: new Animated.Value(0),
  };

  private bannerId: string;
  private timer: NodeJS.Timeout;
  private listener: EmitterSubscription;

  public componentDidMount (): void {
    this.listener = Dimensions.addEventListener('change', this.onDimensionChange);
    if (!this.bannerId) {
      this.show();
    }
  }

  public componentDidUpdate (): void {
    if (!this.bannerId && !this.state.forceMeasure) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ forceMeasure: true });
      return;
    }

    if (!this.bannerId) {
      this.show();
      return;
    }

    BannerService.update(this.bannerId, this.renderContentElement());
  }

  public componentWillUnmount (): void {
    this.listener.remove();
    // make sure we clear any timeouts to avoid a state update on an unmounted component
    clearTimeout(this.timer);
    this.hide();
  }

  private closeBanner = (): void => {
    if (this.props.closeBehavior) this.props.closeBehavior();
    this.listener.remove();
    // make sure we clear any timeouts to avoid a state update on an unmounted component
    clearTimeout(this.timer);
    const shouldClose = true;
    this.hide(shouldClose);
  };

  private actionBanner = (): void => {
    if (this.props.actionBehavior) this.props.actionBehavior();
    this.listener.remove();
    // make sure we clear any timeouts to avoid a state update on an unmounted component
    clearTimeout(this.timer);
    const shouldClose = true;
    this.hide(shouldClose);
  };

  public show = (): void => {
    this.bannerId = BannerService.show(this.renderMeasuringContentElement(), {});
  };

  public hide = (shouldClose?: boolean): void => {
    Animated.timing(this.state.rightMargin, {
      toValue: (this.state.contentFrame.size.width * -1),
      useNativeDriver: false,
    }).start(() => {
      this.afterSlideOut(shouldClose);
    });
    this.state.renderState = 'slideOut';
  };

  private afterSlideOut = (shouldClose?: boolean) => {
    if (this.state.renderState === 'slideOut') {
      this.state.renderState = 'hold';
      this.bannerId = BannerService.hide(this.bannerId);
      if (shouldClose) this.props.onClose();
    }
  };

  private onDimensionChange = (): void => {
    BannerService.update(this.bannerId, this.renderMeasuringContentElement());
  };

  private onContentMeasure = (contentFrame: Frame): void => {
    this.state.contentFrame = contentFrame;

    if (this.state.renderState === 'measure') {
      // after we measure for the first time, set the initial position
      // of the banner to begin sliding
      this.state.rightMargin.setValue(contentFrame.size.width * -1);
      // then animate it to our display horizontal margin
      Animated.timing(this.state.rightMargin, {
        toValue: this.props.eva.style.marginHorizontal,
        useNativeDriver: false,
      }).start(this.afterSlideIn);
      // and set our state to slideIn
      this.state.renderState = 'slideIn';
    }
  };

  private afterSlideIn = () => {
    if (this.state.renderState === 'slideIn') {
      this.state.renderState = 'hold';
    }
  };

  private getComponentStyle = (source: StyleType): {
    container: Animated.WithAnimatedObject<ViewStyle>,
    action: ButtonProps['style']
  } => {
    return {
      container: {
        alignItems: 'center',
        maxWidth: Frame.window().size.width - source.marginHorizontal * 3,
        ...source.elevation,
        ...source,
        marginBottom: this.state.bottomMargin,
        marginRight: this.state.rightMargin,
      },
      action: PropsServiceHelper.allWithPrefixMapped(source, 'action'),
    };
  };

  private renderContentElement = (): React.ReactElement<ViewProps> => {
    const {
      eva,
      style,
      children,
      status = null,
      actionAccessory,
      ...viewProps
    } = this.props;

    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <Animated.View
        {...viewProps}
        style={[
          evaStyle.container,
          style,
        ]}
        testID="banner-content-element"
      >
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <View style={{ flexDirection: 'row', flex: 1 }}>

            <FalsyIcon
              component={iconDictionary[status]}
              status={status}
              style={{ paddingLeft: 0 }}
            />
            <HSpacer size="5" />
            <FalsyText
              category="p2"
              component={children as ReactText}
              testID="banner-content-text"
            />
          </View>
          <VSpacer size="4" />
          <FalsyFC
            // @ts-ignore this works with just style,
            // but the typings are freaking out with the extra props
            actionProps={{ onPress: () => this.actionBanner() }}
            component={actionAccessory}
            dismissProps={{ onPress: () => this.closeBanner() }}
          />
        </View>
      </Animated.View>
    );
  };

  private renderMeasuringContentElement = (): MeasuringElement => {
    return (
      <MeasureElement
        onMeasure={this.onContentMeasure}
        shouldUseTopInsets={BannerService.getShouldUseTopInsets}
      >
        {this.renderContentElement()}
      </MeasureElement>
    );
  };

  public render (): React.ReactNode {
    return null;
  }
}
