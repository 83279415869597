import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { CalculationUtility } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Card, VSpacer, Text } from '@design';
import ActionsHeader from '../../../../components/shared/ActionsHeader/ActionsHeader';
import { OrderSummaryItem } from '../ProductOrderReviewCreate/OrderSummaryItem';

export interface OrderSummaryProps {
  deliverable: ProductOrderDeliverableV0.DeliverablePublic,
}
export const OrderSummary: FC<OrderSummaryProps> = ({ deliverable }) => {
  const themedStyles = useStyleSheet({
    centered: {
      alignItems: 'center',
    },
    addDiscountButton: {
      maxWidth: 150,
    },
    line: {
      borderWidth: 1,
      marginHorizontal: -8,
      borderColor: 'color-basic-transparent-100',
    },
    fullWidthLine: {
      marginHorizontal: -28,
    },
    dialogFooter: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });

  const [translate] = useTranslation(['productOrders', 'common']);

  const { overview, products, productMixes } = deliverable.deliverableData;
  const { acreage } = deliverable.selections.basicInfo;
  const { discounts, retailPrice, totalCost } = deliverable.selections.costSummary;
  const { discounts: productDiscounts } = deliverable.selections.products;

  const skuDiscountsCount = products.reduce(
    (partialSum, product) => partialSum + product.discounts?.length ?? 0,
    0,
  ) + productMixes?.reduce(
    (partialSum, productMix) => partialSum + (
      productMix.products.reduce((productPartialSum, productMixProduct) =>
        productPartialSum + (productMixProduct.discounts?.length ?? 0)
      , 0) ?? 0
    ),
    0,
  );

  const showDuplicatedTotal = totalCost && (
    retailPrice || (
      discounts && (skuDiscountsCount > 0 || overview.discounts?.length > 0)
    )
  );

  return (
    <View>
      <ActionsHeader header={translate<string>('ORDER_SUMMARY')} />
      <VSpacer size="5" />
      <Card testID="order-summary-card">
        {totalCost && (
          <>
            <OrderSummaryItem
              perAcre={acreage && overview.orderTotalPerAcre}
              testIdPostfix="total-top"
              title={translate<string>('TOTAL')}
              titleCategory="h4"
              titleValue={overview.orderTotal}
            />
            {showDuplicatedTotal && (
              <>
                <VSpacer size="7" />
                <View style={[themedStyles.line, themedStyles.fullWidthLine]} />
                <VSpacer size="9" />
              </>
            )}
          </>
        )}
        {retailPrice && (
          <OrderSummaryItem
            isLineThrough={!!(overview.productDiscountTotal)}
            perAcre={acreage && overview.costPerAcre}
            testIdPostfix="retail-price"
            title={translate<string>('RETAIL_PRICE')}
            titleValue={overview.cost}
          />
        )}
        {!!(overview.productDiscountTotal) && (
          <>
            {(productDiscounts && !!overview.discountTotal) && (
              <>
                <VSpacer size="8" />
                <OrderSummaryItem
                  isDiscount
                  perAcre={acreage && overview.productDiscountPerAcre}
                  testIdPostfix="sku-discounts"
                  title={translate<string>('SKU_DISCOUNTS_WITH_COUNT', { count: skuDiscountsCount })}
                  titleValue={overview.productDiscountTotal}
                />
              </>
            )}
            {!!overview.subTotal && (
              <>
                <VSpacer size="8" />
                <OrderSummaryItem
                  isLineThrough={!!(overview.orderDiscountTotal)}
                  perAcre={acreage && overview.subTotalPerAcre}
                  testIdPostfix="order-subtotal"
                  title={translate<string>('ORDER_SUBTOTAL')}
                  titleValue={overview.subTotal}
                />
              </>
            )}
          </>
        )}
        {discounts && (
          overview.discounts?.map((discount, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <View key={index}>
              <VSpacer size="8" />
              <OrderSummaryItem
                isDiscount
                perAcre={acreage && CalculationUtility.calculatePerAcre(
                  discount.dollarsTotal,
                  overview.acreage,
                )}
                testIdPostfix={`discount-${index}`}
                title={discount.name}
                titleValue={discount.dollarsTotal}
              />
            </View>
          ))
        )}
        {showDuplicatedTotal && (
          <>
            <VSpacer size="7" />
            <View style={themedStyles.line} />
            <VSpacer size="7" />
            <OrderSummaryItem
              perAcre={acreage && overview.orderTotalPerAcre}
              testIdPostfix="total-bottom"
              title={translate<string>('TOTAL')}
              titleCategory="s1"
              titleValue={overview.orderTotal}
            />
          </>
        )}
        <>
          <VSpacer size="7" />
          <Text appearance="hint" category="p2" testID="include-all-products-disclaimer">
            {translate<string>('PAC_INCLUDE_ALL_PRODUCTS_DISCLAIMER')}
          </Text>
        </>
      </Card>
      <VSpacer size="12" />
    </View>
  );
};
