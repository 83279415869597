import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import { GlobalBanner, GlobalBannerProps } from '../GlobalBanner/GlobalBanner';

interface GlobalBannerContext {
  globalBannerVisible: boolean,
  bannerHeight: number,
  clearGlobalBanner?: () => void,
  setGlobalBannerProps?: (props: GlobalBannerProps) => void
}

export const GlobalBannerContext = React.createContext<GlobalBannerContext>({
  globalBannerVisible: false,
  bannerHeight: 0,
  clearGlobalBanner: () => {},
  setGlobalBannerProps: () => {},
});

const defaultBannerProps: GlobalBannerProps = {
  testID: '',
};

interface GlobalBannerProviderRawProps extends StyledComponentProps {
  children?: ReactNode,
}

export const GlobalBannerProviderRaw = ({
  eva,
  children,
}: GlobalBannerProviderRawProps) => {
  const [bannerProps, setBannerProps] = useState<GlobalBannerProps>(defaultBannerProps);
  const [visible, setVisible] = useState<boolean>(false);
  const [bannerHeight, setBannerHeight] = useState<number>(0);

  useEffect(() => {
    setBannerHeight(visible ? eva.style.bodyHeight : 0);
  }, [eva.style.bodyHeight, visible]);

  const clearGlobalBanner = useCallback(() => {
    setBannerProps(defaultBannerProps);
    setVisible(false);
  }, []);

  const setGlobalBannerProps = useCallback((props) => {
    setBannerProps(props);
    setVisible(true);
  }, []);

  return (
    <>
      <GlobalBannerContext.Provider
        value={{
          bannerHeight,
          globalBannerVisible: visible,
          clearGlobalBanner,
          setGlobalBannerProps,
        }}
      >
        {visible && <GlobalBanner {...bannerProps} />}
        {children}
      </GlobalBannerContext.Provider>
    </>
  );
};

export const useGlobalBanner = () => {
  const context = React.useContext(GlobalBannerContext);
  if (context === undefined) {
    throw new Error('useGlobalBanner can only be implemented in a child of GlobalBannerProvider');
  }

  return context;
};

export const GlobalBannerProvider = styled('GlobalBanner')(GlobalBannerProviderRaw);
