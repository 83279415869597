import { CropLogicResult } from '@shared/interfaces';
import React, { useCallback, useMemo, useState } from 'react';
import { Card, LargeModal, ModalSpinner, Text, VSpacer } from '@design';
import { StyleSheet, View } from 'react-native';
import { ApiCrop, ApiCropLogic, ApiCropLogicPass, CropLogicEndpoint } from '@shared/interfaces/api';
import { CalculationUtility, CropLogicUtilities } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAuthentication } from '../../../../../../contexts/dataSync/AuthenticationContext';
import { ProgramNameInput } from '../../../../PrepareReview/ProgramTab/ProgramNameInput';
import { ButtonBar } from '../../../../../components/shared/ButtonBar';
import { CropSelect } from '../../../../../components/shared/Input/CropSelect';
import { ProgramDetailsPasses } from '../../../../PrepareReview/ProgramTab/ProgramDetailsPasses';
import { ConfirmationModal } from '../../../../../components/shared/ConfirmationModal/ConfirmationModal';
import { useBusinessCrops } from '../../../../../../hooks/useBusinessCrops';

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
    paddingHorizontal: 38,
    paddingVertical: 0,
  },
  cardDetails: {
    flexDirection: 'row',
    display: 'flex',
    gap: 24,
  },
});

interface ProgramFormProps {
  assignedPrograms: ApiCropLogic[],
  businessLocationId: string,
  canUseInitialName: boolean,
  customerId?: string,
  onCancel: () => void,
  onSave: (program: CropLogicResult, clone?: boolean) => void,
  priceTypeId: string,
  program?: ApiCropLogic,
}

export const ProgramForm = ({
  assignedPrograms,
  businessLocationId,
  canUseInitialName,
  customerId,
  onCancel,
  onSave,
  priceTypeId,
  program: initialProgram,
}: ProgramFormProps) => {
  const [translate] = useTranslation(['common', 'programs']);

  const { user, currentBusinessId } = useAuthentication();

  const { businessCrops, isBusinessCropsLoading } = useBusinessCrops({
    businessId: currentBusinessId,
  });

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [applyToAll] = useState(false); // TODO: GR-4720 - setApplyToAll
  const [nameValidated, setNameValidated] = useState(false);
  const defaultProgramProp = {
    businessId: currentBusinessId,
    cropSubType: null,
    cropType: null,
    logicName: '',
    passes: [],
    priceTypeId,
    scenarioParameters: null,
    userAccountId: user.id,
  };
  const [program, setProgram] = (
    useState<ApiCropLogic | CropLogicEndpoint.Create.Request>(initialProgram ?? defaultProgramProp)
  );

  const currentCrop = useMemo(() => businessCrops.find((crop) => (
    crop.cropType === program.cropType && crop.subType === program.cropSubType
  )), [businessCrops, program.cropSubType, program.cropType]);

  const handleCropTypeSelect = (
    crop: ApiCrop,
    passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[],
  ) => {
    const { cropType, subType: cropSubType } = crop;

    setProgram({
      ...program,
      passes,
      cropSubType,
      cropType,
    });
  };

  const formValid = () => {
    const { isFieldsValid } = CropLogicUtilities.validateCropLogic(
      program,
      false,
    );
    const programHasChanged = initialProgram ? !_.isEqual(program, initialProgram) : true;

    return isFieldsValid && program.passes.length && programHasChanged && nameValidated;
  };

  const handleUpdatePasses = useCallback(
    (passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[]) => {
      setProgram({
        ...program,
        passes,
      });
    },
    [program],
  );

  const FormPage = [
    <View testID="program-form-modal">
      <Card style={styles.card} testID="program-form-modal-card">
        <Text>
          {translate<string>('DETAILS')}
        </Text>
        <VSpacer size="4" />
        <Card testID="program-form-details">
          <View style={styles.cardDetails}>
            <View style={{ flex: 1 }}>
              <ProgramNameInput
                assignedPrograms={assignedPrograms}
                canUseInitialName={canUseInitialName || applyToAll}
                name={program.logicName}
                onUpdateName={(logicName) => {
                  setProgram({
                    ...program,
                    logicName,
                  });
                }}
                onUpdateValidate={setNameValidated}
                programId={'id' in program && program.id}
                valid={nameValidated}
                verifyDatabase={false}
              />
            </View>
            <View style={{ flex: 1 }}>
              <CropSelect
                crop={currentCrop}
                onUpdateCrop={handleCropTypeSelect}
                passes={program.passes}
                testID="program-form-modal-crop-type"
              />
            </View>
          </View>
        </Card>
        <VSpacer size="10" />
        <ProgramDetailsPasses
          businessLocationId={businessLocationId}
          canEdit
          cropSubType={program.cropSubType}
          cropType={program.cropType}
          customerId={customerId}
          noPassesMessage={translate('ADD_A_PASS_TO_YOUR_PROGRAM')}
          onUpdatePasses={handleUpdatePasses}
          passes={program.passes}
          priceTypeId={priceTypeId}
          user={user}
        />
      </Card>
    </View>,
  ];

  return (
    <>
      {isBusinessCropsLoading && <ModalSpinner visible />}
      <LargeModal
        footer={() => (
          <ButtonBar
            buttonBarType="grouped"
            disableRightAction={!formValid()}
            leftAction={() => setShowCancelModal(true)}
            leftButtonText={translate('CANCEL')}
            rightAction={() => onSave({
              ...program as ApiCropLogic,
              crop: currentCrop,
              costPerArea: CalculationUtility.calculateCropLogicCostPerAcre(
                program,
                priceTypeId,
                businessLocationId,
              ),
              scenarioParameters: null,
            }, applyToAll)}
            rightButtonText={translate<string>(initialProgram ? 'APPLY' : 'CREATE_AND_APPLY_PROGRAM')}
            size="giant"
            testID="program-form-modal-button"
          />
        )}
        // TODO: GR-4720
        // headerButton={initialProgram && (
        //   <>
        //     <Radio
        //       checked={!applyToAll}
        //       onChange={() => setApplyToAll(false)}
        //       status="primary"
        //       testID="apply-to-selected-Radio"
        //     >
        //       {translate<string>('APPLY_TO_SELECTED')}
        //     </Radio>
        //     <HSpacer size="5" />
        //     <Radio
        //       checked={applyToAll}
        //       onChange={() => setApplyToAll(true)}
        //       status="primary"
        //       testID="apply-to-selected-Radio"
        //     >
        //       {translate<string>('APPLY_TO_ALL')}
        //     </Radio>
        //   </>
        // )}
        pages={FormPage}
        testID="program-form-large-modal"
        title={translate<string>(initialProgram ? 'EDIT_PROGRAM_ON_FARM_PLAN' : 'CREATE_PROGRAM_ON_FARM_PLAN')}
        visible
      />
      {showCancelModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('DISCARD')}
          messageText={translate('THIS_PROGRAM_HAS_NOT_YET_BEEN_CREATED')}
          onCancel={() => {
            setShowCancelModal(false);
          }}
          onConfirm={onCancel}
          status="warning"
          title={translate('DISCARD_THIS_PROGRAM')}
          visible
        />
      )}
    </>
  );
};
