import {
  Badge,
  Card,
  HSpacer,
  Icon,
  Select,
  SelectItem,
  TextLink,
  VSpacer,
} from '@design';
import { SharedConfig } from '@shared/constants';
import { OrderStatus, ProductOrderDeliverableV0 } from '@shared/interfaces';
import { ApiProductOrder, GrowerContactEndpoint } from '@shared/interfaces/api';
import { toFullDate } from '@shared/utils';
import { IndexPath, useStyleSheet } from '@ui-kitten/components';
import { TFunction } from 'i18next';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../../constants/QueryKeys';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocation } from '../../../../../hooks/useBusinessLocation';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import { getCustomerLabel, StringUtility } from '../../../../../utilities';
import { GrowerApi, UserApi } from '../../../../../utilities/api';
import ActionsHeader from '../../../../components/shared/ActionsHeader/ActionsHeader';
import {
  BillingShippingContactsModal,
} from '../../../../components/shared/BillingShippingContactsModal/BillingShippingContactsModal';
import DescriptionList, {
  DescriptionListDirection,
} from '../../../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import {
  ExternalDisplayIdText,
} from '../../../../components/shared/IntegrationOrder/ExternalDisplayIdText';

export interface BasicInformationHeaderProps {
  onEditAcreage?(): void,
  onEditBusinessLocation?(): void,
  onEditContacts?(): void,
  onEditCropYear?(): void,
  onEditNote?(): void,
  onEditPriceType?(): void,
  onStatusSelected?(status: OrderStatus): void,
  onViewNote?(): void,
  productOrder: ApiProductOrder,
  viewMode?: boolean,
}

interface SalesStatusOption {
  title: TFunction,
  key: OrderStatus,
}

export const BasicInformationHeader: FC<BasicInformationHeaderProps> = ({
  onEditAcreage,
  onEditBusinessLocation,
  onEditContacts,
  onEditCropYear,
  onEditNote,
  onEditPriceType,
  onStatusSelected,
  onViewNote,
  productOrder,
  viewMode,
}) => {
  const [translate] = useTranslation(['productOrders', 'common']);
  const { priceTypes } = usePriceTypeList();
  const [salesStatusOptions, setSalesStatusOptions] = useState<SalesStatusOption[]>([]);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const { currentBusiness, user } = useAuthentication();

  const { data: customerContacts } = useQuery(
    [QueryKeys.GROWER_CONTACTS, productOrder?.growerId],
    () => GrowerApi.getGrowerContacts(
      productOrder.growerId,
      {
        sort: GrowerContactEndpoint.List.Sort.isPrimary,
        limit: SharedConfig.MAX_PAGE_LIMIT,
        sortDesc: true,
      },
    ),
    { enabled: !!productOrder.growerId },
  );

  const getContact = (billingShippingId: string): ProductOrderDeliverableV0.Contact => (
    customerContacts.data.find(({ id }) => id === billingShippingId)
  );

  const priceTypeName = priceTypes?.find((priceType) => (
    priceType.id === productOrder.priceTypeId
  ))?.name;

  const { data: productOrderGrower } = useQuery(
    [QueryKeys.GROWER, productOrder.growerId],
    () => GrowerApi.getGrower(productOrder.growerId),
    { enabled: !!productOrder.growerId },
  );

  const { data: userData } = useQuery(
    [QueryKeys.USER, productOrder.ownerId, user.id],
    () => UserApi.getUserById(productOrder.ownerId ?? user.id),
    { enabled: !!user.id },
  );

  const { businessLocation } = useBusinessLocation({
    businessLocationId: productOrder.locationId,
  });

  const [selectedStatusIndex, setSelectedStatusIndex] = useState<IndexPath | undefined>();

  useEffect(() => {
    setSalesStatusOptions([
      {
        title: translate('ACCEPTED'),
        key: OrderStatus.ACCEPTED,
      },
      {
        title: translate('REJECTED'),
        key: OrderStatus.REJECTED,
      },
      {
        title: translate('SHARED'),
        key: OrderStatus.SHARED,
      },
    ]);
  }, [translate]);

  useEffect(() => {
    const hasValue = productOrder.status
      && salesStatusOptions.some(({ key }) => key === productOrder.status);

    setSelectedStatusIndex(
      hasValue
        ? new IndexPath(
          salesStatusOptions.findIndex(({ key }) => key === productOrder.status),
        )
        : undefined,
    );
  }, [productOrder.status, salesStatusOptions]);

  const themedStyles = useStyleSheet({
    cardBody: {
      display: 'flex',
      flexDirection: 'row',
    },
    cancelledBadge: {
      alignSelf: 'flex-start',
    },
    cardChild: {
      flex: 1,
    },
  });

  const handleStatusSelected = !onStatusSelected ? undefined : (selectedIndex: IndexPath) => {
    setSelectedStatusIndex(selectedIndex);
    onStatusSelected(salesStatusOptions[selectedIndex.row].key);
  };

  const noteEmpty = !productOrder.note?.trim();
  const noteText = useMemo(() => {
    if (!onViewNote) {
      return translate(noteEmpty ? 'ADD_NOTE' : 'VIEW_EDIT_NOTE');
    }

    return translate('VIEW_NOTE');
  }, [onViewNote, translate, noteEmpty]);

  return (
    <View>
      <ActionsHeader header={translate('BASIC_INFORMATION') as string} />
      <VSpacer size="5" />
      <Card testID="basic-info-card">
        <View style={themedStyles.cardBody}>
          <View style={themedStyles.cardChild}>
            <DescriptionList direction={DescriptionListDirection.STACKED}>
              {!!productOrder.externalDisplayId && (
                <DescriptionListItem
                  label={translate<string>('EXTERNAL_ID')}
                  testID="basic-info-external-id"
                  text={
                    <ExternalDisplayIdText externalDisplayId={productOrder.externalDisplayId} />
                  }
                  textCategory="p2"
                />
              )}
              <DescriptionListItem
                label={translate<string>('OWNER')}
                testID="basic-info-owner"
                text={userData?.fullName}
                textCategory="p2"
              />
              {!!(userData?.telephone) && (
                <DescriptionListItem
                  label={translate<string>('PHONE')}
                  testID="basic-info-phone"
                  text={userData?.telephone}
                  textCategory="p2"
                />
              )}
              {!!(currentBusiness?.businessName) && (
                <DescriptionListItem
                  label={translate<string>('BUSINESS')}
                  testID="basic-info-business"
                  text={currentBusiness?.businessName}
                  textCategory="p2"
                />
              )}
              <DescriptionListItem
                accessoryRight={!!onEditBusinessLocation && (
                  <>
                    <HSpacer size="2" />
                    <TouchableOpacity onPress={onEditBusinessLocation}>
                      <Icon
                        name="Edit"
                        status="primary"
                        testID="basic-info-edit-location-button"
                      />
                    </TouchableOpacity>
                  </>
                )}
                label={translate<string>('BUSINESS_LOCATION')}
                testID="basic-info-business-location"
                text={businessLocation?.locationName || translate('CORPORATE_LOCATION')}
                textCategory="p2"
              />
              <DescriptionListItem
                accessoryRight={!!onEditPriceType && (
                  <>
                    <HSpacer size="2" />
                    <TouchableOpacity onPress={onEditPriceType}>
                      <Icon
                        name="Edit"
                        status="primary"
                        testID="basic-info-edit-price-type-button"
                      />
                    </TouchableOpacity>
                  </>
                )}
                label={translate('PRICE_TYPE')}
                testID="price-type-name"
                text={priceTypeName}
                textCategory="p2"
              />
            </DescriptionList>
          </View>
          <HSpacer size="7" />
          <View style={themedStyles.cardChild}>
            <DescriptionList
              direction={DescriptionListDirection.STACKED}
            >
              {!viewMode && (
                <DescriptionListItem
                  label={translate<string>('ORDER_NAME')}
                  testID="basic-info-order-name"
                  text={productOrder.name}
                  textCategory="p2"
                />
              )}
              {!!(productOrderGrower?.legalName) && (
                <DescriptionListItem
                  label={translate<string>('CUSTOMER')}
                  testID="basic-info-grower"
                  text={getCustomerLabel(productOrderGrower)}
                  textCategory="p2"
                />
              )}
              {viewMode && (
                <DescriptionListItem
                  label={translate<string>('CREATED_ON')}
                  testID="basic-info-create-date"
                  text={toFullDate(productOrder.createdAt)}
                  textCategory="p2"
                />
              )}
              <DescriptionListItem
                accessoryRight={!!onEditCropYear && (
                <>
                  <HSpacer size="2" />
                  <TouchableOpacity onPress={onEditCropYear}>
                    <Icon
                      name="Edit"
                      status="primary"
                      testID="basic-information-header-edit-crop-year"
                    />
                  </TouchableOpacity>
                </>
                )}
                label={translate<string>('CROP_YEAR')}
                testID="basic-info-crop-year"
                text={productOrder.cropYear?.toString()}
                textCategory="p2"
              />
              {(customerContacts?.total > 0) && (
                <DescriptionListItem
                  accessoryRight={() => (
                    <>
                      <HSpacer size="2" />
                      <TextLink
                        appearance="secondary"
                        category="p2"
                        onPress={() => setShowContactsModal(true)}
                        testID="basic-info-billing-shipping-view-link"
                      >
                        {translate<string>('VIEW')}
                      </TextLink>
                      {onEditContacts && (
                      <>
                        <HSpacer size="2" />
                        <TouchableOpacity
                          onPress={onEditContacts}
                          testID="billing-shipping-contacts-edit-button"
                        >
                          <Icon
                            name="Edit"
                            status="primary"
                            testID="billing-shipping-contacts-edit-button-icon"
                          />
                        </TouchableOpacity>
                      </>
                      )}
                    </>
                  )}
                  label={translate<string>('BILLING_SHIPPING_CONTACTS')}
                  testID="basic-info-billing-shipping"
                  textCategory="p2"
                />
              )}
              {!(!onEditNote && noteEmpty) && (
                <DescriptionListItem
                  accessoryRight={(!!onViewNote && !!onEditNote) && (
                    <>
                      <HSpacer size="2" />
                      <TouchableOpacity onPress={onEditNote}>
                        <Icon
                          name="Edit"
                          status="primary"
                          testID="product-card-edit-note-button"
                        />
                      </TouchableOpacity>
                    </>
                  )}
                  label={translate<string>('NOTE')}
                  testID="basic-info-note"
                  textCategory="p2"
                >
                  {(!noteEmpty || !onViewNote) && (
                    <TextLink
                      appearance="secondary"
                      category="p2"
                      onPress={onViewNote ?? onEditNote}
                      testID="view-note-link"
                    >
                      {noteText}
                    </TextLink>
                  )}
                </DescriptionListItem>
              )}
              <DescriptionListItem
                accessoryRight={(
                  <>
                    {
                      onEditNote && (
                        <>
                          <HSpacer size="2" />
                          <TouchableOpacity onPress={onEditAcreage}>
                            <Icon
                              name="Edit"
                              status="primary"
                              testID="product-card-edit-order-acreage-button"
                            />
                          </TouchableOpacity>
                        </>
                      )
                    }
                  </>
                )}
                label={translate('ORDER_ACREAGE')}
                testID="basic-info-order-acreage"
                text={productOrder.acreage && (
                  `${StringUtility.localizeNumber(productOrder.acreage, 3)} ${translate(
                    productOrder.acreage === 1 ? 'ACRE' : 'ACRES',
                  )}`
                )}
                textCategory="p2"
              />
            </DescriptionList>
          </View>
          <HSpacer size="7" />
          <View style={themedStyles.cardChild}>
            <Select
              label={translate<string>('SALES_STATUS')}
              onSelect={(i: IndexPath | IndexPath[]) => handleStatusSelected(i as IndexPath)}
              placeholder={translate<string>(!onStatusSelected ? 'UNLABELED' : 'SELECT_A_SALES_STATUS')}
              readonly={!onStatusSelected}
              selectedIndex={selectedStatusIndex}
              testID="basic-info-sales-status"
              value={selectedStatusIndex ? salesStatusOptions[selectedStatusIndex.row].title : ''}
            >
              {salesStatusOptions.map((option, index) => (
                <SelectItem
                  key={option.key}
                  testID={`sales-status-option-${index}`}
                  title={option.title}
                />
              ))}
            </Select>
            {productOrder.status === OrderStatus.ACCEPTED && productOrder.cancelled && (
              <>
                <VSpacer size="5" />
                <Badge
                  status="danger"
                  style={themedStyles.cancelledBadge}
                  testID="cancelled-badge"
                >
                  {translate('CANCELLED').toUpperCase()}
                </Badge>
              </>
            )}
          </View>
        </View>
      </Card>
      { showContactsModal && (
        <BillingShippingContactsModal
          billingContact={getContact(productOrder.billingContactId)}
          onClose={() => setShowContactsModal(false)}
          shippingContact={getContact(productOrder.shippingContactId)}
          showModal={showContactsModal}
        />
      )}
    </View>
  );
};
