import {
  isFarmPlanDeliverable,
  isFarmPlanDeliverablePublic,
  isProductOrderDeliverable,
  isTankMixDeliverable,
  isTankMixDeliverablePublic,
} from '@shared/interfaces';
import { ApiDeliverable, ApiDeliverablePublic } from '@shared/interfaces/api';
import { DeliverableRenderer } from './DeliverableRenderer';
import {
  FarmPlanDeliverableRenderFactory,
} from './FarmPlanDeliverable/FarmPlanDeliverableRenderFactory';
import {
  ProductOrderDeliverableRenderFactory,
} from './ProductOrderDeliverable/ProductOrderDeliverableRenderFactory';
import {
  TankMixDeliverableRenderFactory,
} from './TankMixDeliverable/TankMixDeliverableRenderFactory';

export class DeliverableRenderFactory {
  static getRenderer = (deliverable: ApiDeliverable): DeliverableRenderer => {
    if (isFarmPlanDeliverable(deliverable)) {
      return FarmPlanDeliverableRenderFactory.getRenderer(deliverable);
    }
    if (isTankMixDeliverable(deliverable)) {
      return TankMixDeliverableRenderFactory.getRenderer(deliverable);
    }
    if (isProductOrderDeliverable(deliverable)) {
      return ProductOrderDeliverableRenderFactory.getRenderer(deliverable);
    }
    throw new Error('no renderer defined for deliverable');
  };

  static getPublicRenderer = (deliverable: ApiDeliverablePublic) : DeliverableRenderer => {
    if (isFarmPlanDeliverablePublic(deliverable)) {
      return FarmPlanDeliverableRenderFactory.getPublicRenderer(deliverable);
    }
    if (isTankMixDeliverablePublic(deliverable)) {
      return TankMixDeliverableRenderFactory.getPublicRenderer(deliverable);
    }
    if (isProductOrderDeliverable(deliverable)) {
      return ProductOrderDeliverableRenderFactory.getRenderer(deliverable);
    }
    throw new Error('no public renderer defined for deliverable');
  };
}
