import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Planning = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path clipRule="evenodd" d="M15.2727 4.63636H5.45455C5.00268 4.63636 4.63636 5.00268 4.63636 5.45455C4.63636 5.90641 5.00268 6.27273 5.45455 6.27273H15.2727C15.7246 6.27273 16.0909 5.90641 16.0909 5.45455C16.0909 5.00268 15.7246 4.63636 15.2727 4.63636ZM5.45455 3C4.09894 3 3 4.09894 3 5.45455C3 6.81015 4.09894 7.90909 5.45455 7.90909H15.2727C16.6283 7.90909 17.7273 6.81015 17.7273 5.45455C17.7273 4.09894 16.6283 3 15.2727 3H5.45455Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M18.5454 11.1818H8.72725C8.27538 11.1818 7.90907 11.5481 7.90907 12C7.90907 12.4518 8.27538 12.8181 8.72725 12.8181H18.5454C18.9973 12.8181 19.3636 12.4518 19.3636 12C19.3636 11.5481 18.9973 11.1818 18.5454 11.1818ZM8.72725 9.54541C7.37164 9.54541 6.27271 10.6443 6.27271 12C6.27271 13.3556 7.37164 14.4545 8.72725 14.4545H18.5454C19.901 14.4545 21 13.3556 21 12C21 10.6443 19.901 9.54541 18.5454 9.54541H8.72725Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M15.2727 17.7272H5.45455C5.00268 17.7272 4.63636 18.0935 4.63636 18.5454C4.63636 18.9972 5.00268 19.3635 5.45455 19.3635H15.2727C15.7246 19.3635 16.0909 18.9972 16.0909 18.5454C16.0909 18.0935 15.7246 17.7272 15.2727 17.7272ZM5.45455 16.0908C4.09894 16.0908 3 17.1898 3 18.5454C3 19.901 4.09894 20.9999 5.45455 20.9999H15.2727C16.6283 20.9999 17.7273 19.901 17.7273 18.5454C17.7273 17.1898 16.6283 16.0908 15.2727 16.0908H5.45455Z" fillRule="evenodd" />
  </Svg>
);
