import React, { FC, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Layout } from '@ui-kitten/components';
import { Button, Icon, TabView, Tab, Text, HSpacer, VSpacer } from '@design';

const styles = StyleSheet.create({
  tabContainer: {
    flex: 1,
    marginTop: 16,
    paddingVertical: 128,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

export const TabsDemo: FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabBarWidth, setTabBarWidth] = useState(100);

  return (
    <>
      <VSpacer size="10" />

      <View style={{ flexDirection: 'row' }}>
        <Button onPress={() => setTabBarWidth(100)} size="small" testID="test-button">Render at 100% width</Button>
        <HSpacer size="5" />
        <Button onPress={() => setTabBarWidth(75)} size="small" testID="test-button">Render at 75% width</Button>
        <HSpacer size="5" />
        <Button onPress={() => setTabBarWidth(66)} size="small" testID="test-button">Render at 66% width</Button>
      </View>
      <VSpacer size="10" />

      <TabView
        indicatorStyle={{ width: `${tabBarWidth}%` }}
        onSelect={(index) => setSelectedIndex(index)}
        onSwipe={(event) => {
          const swipeDirection = -1 * Math.sign(event.nativeEvent.translationX as number);
          setSelectedIndex(selectedIndex + swipeDirection);
        }}
        selectedIndex={selectedIndex}
        tabBarStyle={{ width: `${tabBarWidth}%` }}
      >
        <Tab title="Info">
          <Layout style={styles.tabContainer}>
            <Text>Lorem Ipsum, information in the Info Tab.</Text>
          </Layout>
        </Tab>
        <Tab title="Farms">
          <Layout style={styles.tabContainer}>
            <Text>Lorem Ipsum, information in the Farms Tab.</Text>
          </Layout>
        </Tab>
        <Tab rightIcon={() => <Icon name="AlertTriangle" status="warning" testID="demo-icon" />} title="Farm Plan">
          <Layout style={styles.tabContainer}>
            <Text>Lorem Ipsum, information in the Farm Plan Tab.</Text>
          </Layout>
        </Tab>
        <Tab title="Planning Parameters">
          <Layout style={styles.tabContainer}>
            <Text>Lorem Ipsum, information in the Planning Parameters Tab.</Text>
          </Layout>
        </Tab>
      </TabView>
    </>
  );
};
