import {
  Badge,
  Button,
  Chip, Header,
  HSpacer,
  Icon,
  List,
  ListItem,
  Modal,
  Pagination,
  Tab,
  TabView,
  Text,
  TextLink,
  useToast,
  VSpacer,
} from '@design';
import { DeliverableType } from '@shared/enums';
import {
  ApiDeliverable,
  GrowerContactEndpoint,
} from '@shared/interfaces/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys, Routes } from '../../../../constants';
import { useAppContext } from '../../../../contexts/AppContext';
import { useHistory } from '../../../../router';
import { GrowerApi, GrowerDeliverableApi } from '../../../../utilities/api';
import { useBusiness } from '../../../pages/BusinessDetails/helpers';

interface SendOption {
  email: boolean,
  text: boolean,
}

interface Contacts {
  businessContacts: {
    [contactId: string]: SendOption,
  },
  growerContacts: {
    [contactId: string]: SendOption,
  },
}

const styles = StyleSheet.create({
  chip: {
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttons: {
    flexDirection: 'row',
  },
  contact: { marginRight: 12 },
  row: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tag: { marginRight: 4 },
});

export interface ShareDeliverableModalProps {
  customerId: string,
  deliverable: ApiDeliverable,
  isNew?: boolean,
  onClose: () => Promise<void>,
  resending?: boolean,
  uxSandboxContacts?: GrowerContactEndpoint.List.Response,
}

const singleLineSize = 25;

export const ShareDeliverableModal = ({
  customerId,
  deliverable,
  isNew = false,
  onClose,
  resending = false,
  uxSandboxContacts,
}: ShareDeliverableModalProps) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'common']);
  const { createToast } = useToast();
  const { setModalProps } = useAppContext();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isWrapping, setIsWrapping] = useState(false);
  const [contacts, setContacts] = useState<Contacts>({
    businessContacts: {},
    growerContacts: {},
  });

  const { data: contactsResponse, isFetching } = useQuery(
    [QueryKeys.GROWER_CONTACTS, customerId, page],
    () => uxSandboxContacts ? Promise.resolve(uxSandboxContacts) : GrowerApi.getGrowerContacts(
      customerId,
      {
        page: page - 1,
        sort: GrowerContactEndpoint.List.Sort.firstName,
      },
    ),
    {
      enabled: !!customerId,
      onSuccess: (data) => {
        setTotalPages(data.lastPage + 1);
      },
    },
  );

  const {
    isLoading: isBusinessLoading,
    business,
  } = useBusiness({
    businessId: deliverable.businessId,
    onError: () => createToast({
      children: translate('UNEXPECTED_ERROR'),
      status: 'warning',
      testID: 'toast-content-element',
    }),
  });

  useEffect(() => {
    const newContacts: Contacts = {
      businessContacts: {},
      growerContacts: {},
    };

    business.contacts.forEach(({ id, isDeliverableDefault }) => {
      newContacts.businessContacts[id] = { email: isDeliverableDefault, text: false };
    });

    setContacts(newContacts);
  }, [business.contacts]);

  const viewDeliverable = useCallback((sharing = false) => {
    const route = Routes.GROWER_DELIVERABLE
      .replace(/:grower/, customerId)
      .replace(/:id/, deliverable.id);

    if (deliverable?.deliverableType === DeliverableType.PRODUCT_ORDER) {
      setModalProps({
        type: 'viewDeliverableModal',
        deliverablePublicId: deliverable.publicId,
        deliverableId: deliverable.id,
        shared: sharing,
      });
    } else {
      history.push(route);
    }
  }, [
    deliverable,
    customerId,
    history,
    setModalProps,
  ]);

  const sendLinkToContacts = useMutation(
    async () => {
      await GrowerDeliverableApi.sendLinkToContacts(
        deliverable.id,
        contacts,
      );
    },
    {
      onSettled: async () => {
        if (deliverable?.deliverableType === DeliverableType.PRODUCT_ORDER) {
          await queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST);
        }
      },
      onSuccess: async () => {
        createToast({
          children: resending
            ? translate('DELIVERABLE_SENT')
            : `${translate('DELIVERABLE_SUCCESS', { name: deliverable.name })}\n${translate('DELIVERABLE_SENT')}`,
          status: 'success',
          accessoryRight: resending ? undefined : (props) => (
            <Button
              {...props}
              onPress={() => viewDeliverable(true)}
              status="basic"
              testID="view-button"
            >
              {translate('VIEW')}
            </Button>
          ),
          testID: 'toast-content-element',
        });

        await onClose();
      },
      onError: () => {
        createToast({
          children: translate('DELIVERABLE_RECIPIENT_FAILURE'),
          status: 'warning',
          testID: 'toast-content-element',
        });
      },
    },
  );

  const isLoading = isFetching || isBusinessLoading || sendLinkToContacts.isLoading;

  const toggleContactSelected = (
    type: keyof Contacts,
    contactId: string,
    contactType: keyof SendOption,
  ) => {
    const newContacts: Contacts = {
      businessContacts: {
        ...contacts.businessContacts,
      },
      growerContacts: {
        ...contacts.growerContacts,
      },
    };

    newContacts[type][contactId] = newContacts[type][contactId] ?? { email: false, text: false };
    newContacts[type][contactId][contactType] = !newContacts[type][contactId][contactType];

    setContacts(newContacts);
  };

  const contactsSelected = useMemo(() => {
    const getCount = (type: keyof Contacts) => Object.values(contacts[type]).reduce((count, contact) => (
      count + ((!contact.email && !contact.text) ? 0 : 1)
    ), 0);

    const businessContacts = getCount('businessContacts');
    const customerContacts = getCount('growerContacts');

    return {
      businessContacts,
      customerContacts,
      total: businessContacts + customerContacts,
    };
  }, [contacts]);

  const onCancel = async () => {
    await onClose();

    if (!resending) {
      createToast({
        children: `${translate('DELIVERABLE_SUCCESS', { name: deliverable.name })}`,
        status: 'success',
        accessoryRight: (props) => (
          <Button
            {...props}
            onPress={() => viewDeliverable(false)}
            status="basic"
            testID="view-button"
          >
            {`${translate('VIEW')}`}
          </Button>
        ),
        testID: 'toast-content-element',
      });
    }
  };

  const ModalFooter = ({ primaryButtonProp, secondaryButtonProp, spacerProp }) => isFetching ? (
    <Button
      testID="deliverable-close-button"
      {...secondaryButtonProp}
      onPress={onCancel}
    >
      {`${translate('CLOSE')}`}
    </Button>
  ) : (
    <>
      <Text>
        {translate(
          contactsSelected.total > 1 ? 'CONTACTS_SELECTED' : 'CONTACT_SELECTED',
          { count: contactsSelected.total },
        )}
      </Text>
      <View style={styles.buttons}>
        <Button
          testID="deliverable-cancel-button"
          {...primaryButtonProp}
          disabled={isLoading}
          onPress={onCancel}
        >
          {`${translate('CLOSE')}`}
        </Button>
        <HSpacer {...spacerProp} />
        <Button
          {...secondaryButtonProp}
          accessoryLeft={(iconProps) => (
            <Icon {...iconProps} name="PaperPlane" testID="deliverable-send-icon" />
          )}
          appearance={(!contactsSelected.total || isLoading) ? 'outline' : 'filled'}
          disabled={!contactsSelected.total || isLoading}
          onPress={() => !uxSandboxContacts && sendLinkToContacts.mutate()}
          testID="deliverable-send-button"
        >
          {`${translate('SHARE_DELIVERABLE')}`}
        </Button>
      </View>
    </>
  );

  return (
    <Modal
      footerAccessory={ModalFooter}
      footerStyle={styles.footer}
      hideCloseButton
      maxHeight
      subTitle={isNew ? (props) => (
        <Text {...props}>
          <TextLink
            appearance="primary"
            category="p2"
            onPress={async () => {
              viewDeliverable();
              await onClose();
            }}
            testID="share-deliverable-link"
          >
            {translate<string>('VIEW_DELIVERABLE')}
          </TextLink>
          <Text category="p2">
            {`${translate<string>('VIEW_DELIVERABLE_POST')} `}
          </Text>
        </Text>
      ) : null}
      testID="deliverable-recipients-modal"
      title={translate(
        isNew ? 'SHARE_DELIVERABLE_CREATED' : 'SHARE_DELIVERABLE_FOR',
        { name: deliverable.name },
      )}
      visible
      width={560}
    >
      <Header
        level="3"
        testID="deliverable-header"
        title={translate('SEND_THIS_DELIVERABLE_TO_YOUR_CONTACTS')}
      />
      <VSpacer size="7" />
      <TabView
        onSelect={setSelectedTab}
        selectedIndex={selectedTab}
      >
        <Tab
          rightIcon={!contactsSelected.customerContacts ? undefined : () => (
            <Badge status="info" testID="selected-customer-badge">
              {contactsSelected.customerContacts}
            </Badge>
          )}
          title={translate('CUSTOMER_CONTACTS')}
        >
          <>
            <VSpacer size="3" />
            {!contactsResponse?.data.length ? (
              <>
                {!isFetching && (
                  <Text category="p1" testID="no-contacts-message">
                    {`${translate('NO_CONTACTS_WARNING')}`}
                  </Text>
                )}
              </>
            ) : (
              <>
                <List separator="bottom" testID="contacts">
                  {contactsResponse?.data.map((contact) => (
                    <ListItem
                      iconButtons={() => (
                        <>
                          {!!contact.telephone && (
                            <Chip
                              accessoryLeft={contacts.growerContacts[contact.id]?.text ? (iconProps) => (
                                <Icon
                                  {...iconProps}
                                  name="Checkmark"
                                  testID={`contact-${contact.id}-text-icon`}
                                />
                              ) : undefined}
                              accessoryRight={() => <></>}
                              appearance="outline"
                              onPress={() => toggleContactSelected(
                                'growerContacts',
                                contact.id,
                                'text',
                              )}
                              status={contacts.growerContacts[contact.id]?.text ? 'primary' : 'default'}
                              style={styles.chip}
                              testID={`contact-${contact.id}-text-chip`}
                            >
                              {translate('TEXT')}
                            </Chip>
                          )}
                          {!!contact.email && (
                            <Chip
                              accessoryLeft={contacts.growerContacts[contact.id]?.email ? (iconProps) => (
                                <Icon
                                  {...iconProps}
                                  name="Checkmark"
                                  testID={`contact-${contact.id}-email-icon`}
                                />
                              ) : undefined}
                              accessoryRight={() => <></>}
                              appearance="outline"
                              onPress={() => toggleContactSelected(
                                'growerContacts',
                                contact.id,
                                'email',
                              )}
                              status={contacts.growerContacts[contact.id]?.email ? 'primary' : 'default'}
                              style={styles.chip}
                              testID={`contact-${contact.id}-email-chip`}
                            >
                              {translate('EMAIL')}
                            </Chip>
                          )}
                        </>
                      )}
                      key={contact.id}
                      testID={`contact-${contact.id}`}
                      title={(
                        <View
                          onLayout={(e) => setIsWrapping(e.nativeEvent.layout.height > singleLineSize)}
                          style={styles.row}
                        >
                          <Text style={styles.contact}>
                            {`${contact.firstName} ${contact.lastName}`}
                          </Text>
                          {contact.tags.map((name, idx) => (
                            <Badge
                              appearance="tag"
                              key={name}
                              style={[styles.tag, { marginTop: isWrapping ? 4 : undefined }]}
                              testID={`${name}-${idx}`}
                            >
                              {name}
                            </Badge>
                          ))}
                        </View>
                      )}
                    />
                  ))}
                </List>
                <VSpacer size="8" />
                <View style={{ alignItems: 'center' }}>
                  {totalPages > 1 && (
                    <Pagination
                      currentPage={page}
                      displayPages={9}
                      onChangePage={(val) => setPage(val)}
                      totalPages={totalPages}
                    />
                  )}
                </View>
              </>
            )}
          </>
        </Tab>
        <Tab
          disabled={!business.contacts}
          rightIcon={!contactsSelected.businessContacts ? undefined : () => (
            <Badge status="info" testID="selected-internal-contacts-badge">
              {contactsSelected.businessContacts}
            </Badge>
          )}
          title={translate('INTERNAL_CONTACTS')}
        >
          <>
            <VSpacer size="3" />
            <List separator="bottom" testID="contacts">
              {business.contacts.map((contact) => !contact.contactEmail ? null : (
                <ListItem
                  iconButtons={() => (
                    <Chip
                      accessoryLeft={contacts.businessContacts[contact.id]?.email ? (iconProps) => (
                        <Icon
                          {...iconProps}
                          name="Checkmark"
                          testID={`contact-${contact.id}-email-icon`}
                        />
                      ) : undefined}
                      accessoryRight={() => <></>}
                      appearance="outline"
                      onPress={() => toggleContactSelected(
                        'businessContacts',
                        contact.id,
                        'email',
                      )}
                      status={contacts.businessContacts[contact.id]?.email ? 'primary' : 'default'}
                      style={styles.chip}
                      testID={`internal-contact-${contact.id}-email-chip`}
                    >
                      {translate('EMAIL')}
                    </Chip>
                  )}
                  key={contact.id}
                  testID={`contact-${contact.id}`}
                  title={contact.contactName}
                />
              ))}
            </List>
          </>
        </Tab>
      </TabView>
    </Modal>
  );
};
