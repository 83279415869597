import { Button, HSpacer, Search, SidePanel, Text, useToast, VSpacer } from '@design';
import { ApiGrower, ApiGrowerAssignedUser } from '@shared/interfaces/api';
import { useStyleSheet } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { GrowerApi, UserApi } from '../../../../utilities/api';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';
import { ComponentSelectPanelItem } from '../../ComponentSelectPanelItem';

type AssignUserType = ApiGrowerAssignedUser & RowMeta;
type AssignUserTableType = IColumn<(AssignUserType)>;

export interface AssignUsersSidePanelProps {
  customer: ApiGrower,
  onClose(): void,
  selectedUsers: ApiGrowerAssignedUser[],
  visible: boolean,
}

export const AssignUsersSidePanel = ({
  customer,
  onClose,
  visible,
  ...props
}: AssignUsersSidePanelProps) => {
  const [translate] = useTranslation(['common', 'users', 'growers']);
  const { createToast } = useToast();
  const queryClient = useQueryClient();
  const [selectedUsers, setSelectedUsers] = useState<ApiGrowerAssignedUser[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<(AssignUserType)[]>([]);
  const [search, setSearch] = useState('');
  const styles = useStyleSheet({
    cropBorder: {
      flex: 1,
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: 'color-basic-control-transparent-100',
      maxHeight: 1,
    },
  });

  useEffect(() => {
    setSelectedUsers(props.selectedUsers);
  }, [props.selectedUsers, visible]);

  const { isLoading, data: assignUsersResponse } = useQuery(
    [QueryKeys.USER_LIST, customer, search, visible],
    () => UserApi.getUsers({
      page: 0,
      search,
      businessId: customer.businessId,
    }),
    {
      onSuccess: (usersData) => {
        const rows: AssignUserType[] = [];
        usersData?.data.forEach((aUser, idx) => {
          rows.push({
            ...aUser,
            assignedAt: null,
            hasDetails: false,
            rowId: `row:${idx}|${aUser.id}`,
          });
        });

        setAssignedUsers(rows);
      },
      onError: () => {
        createToast({
          status: 'warning',
          children: translate<string>('ERROR_FETCH_ASSIGN_USERS'),
          testID: 'toast-content-element',
        });
      },
    },
  );

  const onUserSelected = (user: ApiGrowerAssignedUser) => {
    const selected = selectedUsers.some((aUser) => aUser.id === user.id);

    if (selected) {
      const filteredUsers = selectedUsers.filter((selectedUser) => (
        selectedUser.id !== user.id
      ));
      setSelectedUsers(filteredUsers);
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const columns: AssignUserTableType[] = [
    {
      columnId: 'fullname',
      header: null,
      render: (user) => (
        <ComponentSelectPanelItem
          onSelect={() => onUserSelected(user)}
          selected={!!selectedUsers.find((u) => u.id === user.id)}
        >
          <Text testID="user-fullname">
            {user.fullName}
          </Text>
        </ComponentSelectPanelItem>
      ),
      flex: 1,
    },
  ];

  const handleApply = async () => {
    const toRemove = props.selectedUsers.filter(
      (aUser) => !selectedUsers.some(({ id }) => aUser.id === id),
    ).map((user) => user.id);

    const toAdd = selectedUsers.filter(
      (aUser) => !props.selectedUsers.some(({ id }) => aUser.id === id),
    ).map((user) => user.id);

    try {
      if (toRemove.length > 0) {
        await GrowerApi.unlinkBusinessUsers(customer.id, toRemove);
      }

      if (toAdd.length > 0) {
        await GrowerApi.linkBusinessUsers(customer.id, toAdd);
      }

      await queryClient.invalidateQueries(QueryKeys.ASSIGNED_USERS);

      createToast({
        status: 'success',
        children: translate<string>('USERS_ASSIGNED_TO_CUSTOMER'),
        testID: 'toast-content-element',
      });

      onClose();
    } catch (err) {
      createToast({
        status: 'warning',
        children: translate<string>('ERROR_SAVING_ASSIGN_USERS'),
        testID: 'toast-content-element',
      });
    }
  };

  return (
    <SidePanel
      header={
        translate<string>('ASSIGN_USERS_COUNT', { count: selectedUsers.length })
      }
      onClose={onClose}
      testID="assign-users-side-panel"
      visible={visible}
    >
      <ScrollView>
        <View style={{ marginHorizontal: 20 }}>
          <Search
            onChangeText={(value) => setSearch(value)}
            onClear={() => setSearch('')}
            size="medium"
            testID="assign-users-search"
            value={search}
          />
          <VSpacer size="9" />
          <Text appearance="hint" category="p2" testID="assign-users-count">
            {translate<string>('USERS_COUNT', { count: assignUsersResponse?.total ?? 0 })}
          </Text>
          <VSpacer size="5" />
        </View>
        <View style={styles.cropBorder} />
        <SortableTable
          columns={columns}
          data={assignedUsers}
          footerRow={!assignedUsers.length && (
            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <VSpacer size="7" />
              <Text>{translate<string>('NO_AVAILABLE_USERS')}</Text>
              <VSpacer size="7" />
            </View>
          )}
          showHeaders="no"
          tableBackground="transparent"
          testID="assign-users-tables"
        />
        <VSpacer size="11" />
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            appearance="outline"
            onPress={onClose}
            status="basic"
            testID="cancel-button"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer size="7" />
          <Button
            disabled={isLoading || !assignedUsers.length}
            onPress={handleApply}
            testID="apply-button"
          >
            {translate<string>('APPLY')}
          </Button>
          <HSpacer size="6" />
        </View>
        <VSpacer size="8" />
      </ScrollView>
    </SidePanel>
  );
};
