import { 
  Button,
  HSpacer,
  Modal,
  Pagination,
  Search,
  Text,
  VSpacer,
} from '@design';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SelectionModalListItem } from './SelectionModalListItem';

interface SelectionModalProps<T> {
  currentPage: number,
  getOptionKey: (option: T) => string,
  getOptionLabel: (option: T) => string,
  onCancel: () => void,
  onDone: ([]) => void,
  optionCountLabel?: string,
  options: T[],
  pageSize?: number,
  search: string,
  selectedOptions: string[],
  setPage: (page: number) => void,
  setSearch: (search: string) => void,
  subTitle?: string,
  testID?: string,
  title?: string,
  totalOptions: number,
  totalPages: number,
  visible: boolean,
}

const styles = StyleSheet.create({
  container: {
    height: 500,
    overflow: 'scroll',
  },
  modal: {
    maxHeight: 750,
    minWidth: 560,
  },
});

let scrollingListRef = null;

export const SelectionModal = <T extends any>({
  currentPage,
  getOptionKey,
  getOptionLabel,
  onCancel,
  onDone,
  optionCountLabel = 'items',
  options,
  pageSize = 20,
  search,
  selectedOptions = [],
  setPage,
  setSearch,
  subTitle,
  testID = 'selection-modal',
  title,
  totalOptions,
  totalPages,
  visible,
}: SelectionModalProps<T>) => {
  const [translate] = useTranslation(['common']);
  const [selections, setSelections] = useState([...selectedOptions]);
    
  const toggleSelection = (isChecked: boolean, result: T) => {
    if (!isChecked) {
      const newSelections = [...selections];
      newSelections.splice(selections.indexOf(getOptionKey(result)), 1);
      setSelections(newSelections);
      return;
    }
    if (selections.includes(getOptionKey(result))) {
      return;
    }
    const newSelections = [...selections];
    newSelections.push(getOptionKey(result));
    setSelections(newSelections);
  };

  const clearSearchResults = () => {
    setSearch('');
    setPage(0);
  };

  return (
    <>
      <Modal
        footerAccessory={() => (
          <>
            <Button
              appearance="outline"
              onPress={onCancel}
              size="small"
              status="basic"
              testID="selection-modal-cancel-button"
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer size="4" />
            <Button
              onPress={() => onDone(selections)}
              size="small"  
              testID="selection-modal-done-button"
            >
              {translate<string>('DONE')}
            </Button>
          </>
        )}
        onClose={onCancel}
        style={styles.modal}
        testID={testID}
        title={title}
        visible={visible}
      >
        <Text category="p2">{subTitle}</Text>
        <VSpacer size="7" />
        <Search
          onChangeText={(value) => {
            setSearch(value);
            setPage(0);
            if (value === '') {
              clearSearchResults();
              return;
            }
          }}
          onClear={() => {clearSearchResults();}}
          placeholder={`${translate('SEARCH')}...`}
          size="medium"
          style={{ flex: 1, color: '#fff' }}
          testID="search-bar"
          value={search}
        />
        <VSpacer size="7" />
        <Text category="p2">
          {totalOptions} {optionCountLabel}
        </Text>
        <ScrollView
          ref={(ref) => scrollingListRef = ref}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator
          style={styles.container}
        >
          {options.map((result, index) => {
            return (
              <SelectionModalListItem
                checked={!!selections.includes(getOptionKey(result))}
                index={index}
                key={index}
                label={getOptionLabel(result)}
                onChange={(isChecked) => toggleSelection(isChecked, result)}
              />
            );
          })}
        </ScrollView>
        {!!pageSize && 
          (
            <View style={{ alignItems: 'center' }}>
              <VSpacer size="4" />
              <Pagination 
                currentPage={currentPage + 1}
                displayPages={5}
                onChangePage={
                  (newPage) => {
                    setPage(newPage - 1);
                    scrollingListRef?.scrollTo({
                      y: 0,
                      animated: true,
                    });
                  }
                }
                totalPages={totalPages + 1}
              />
            </View>
          )
        }
      </Modal>
    </>
  );
};
