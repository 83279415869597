import React from 'react';
import { Avatar as UIKAvatar, AvatarProps, useStyleSheet, useTheme } from '@ui-kitten/components';
import { RectangleSize, Size } from '@theme/variant-interfaces/Size';
import { Overwrite } from '@ui-kitten/components/devsupport';
import { View, StyleSheet } from 'react-native';
import { Text } from '@design';
import { Shape } from '@theme/variant-interfaces/Shape';

const fontSize = {
  tiny: 12,
  small: 16,
  medium: 20,
  large: 28,
  giant: 36,
};

type ShapeSizeProps = Overwrite<AvatarProps, {
  shape?: Shape,
  size?: Size | RectangleSize,
}>;

interface MediaPlaceholderProps extends ShapeSizeProps {
  padding?: number,
  text?: string,
  testID: string,
}

export const Logo = (props: MediaPlaceholderProps): JSX.Element => {
  const theme = useTheme();

  /**
   * @param size Size property returned from eva DS when rendering an
   * Avatar
   * @returns a number corresponding to the GrowersMapping Avatar size
   * property
   */
  const getFontSize = (size: string): number => {
    const newSize = size.includes('-')
      ? size.slice(0, size.indexOf('-'))
      : size;
    return Object.keys(fontSize).includes(newSize) && fontSize[newSize];
  };

  const styles = useStyleSheet({
    inner: {
      borderColor: theme['color-basic-700'],
      borderWidth: StyleSheet.hairlineWidth,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme['color-basic-700'],
    },
    text: {
      fontSize: getFontSize(props.size),
      fontWeight: '700',
      /**
       * 1.5 is a magic number to center the text correctly within the Avatar component
       * 1.5 * fontSize allows component to correctly justify and align the text
       * `lineHeight` is required for the overlay on a React Native ImageBackground component
       */
      lineHeight: getFontSize(props.size) * 1.5,
    },
    outer: {
      padding: props.padding ?? 12,
    },
  });

  if (!props.source) {
    return (
      <View style={styles.outer} testID={props.testID}>
        <UIKAvatar
          {...props}
          ImageComponent={(restProps: MediaPlaceholderProps) => (
            <View
              {...restProps}
              style={[restProps.style, styles.inner]}
            >
              <Text style={styles.text}>
                {props.text}
              </Text>
            </View>
          )}
        />
      </View>
    );
  }

  return (
    <View style={styles.outer} testID={props.testID}>
      <UIKAvatar {...props} resizeMode="contain" />
    </View>
  );
};
