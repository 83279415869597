import React from 'react';
import { StyleProp, View, ViewProps, ViewStyle } from 'react-native';
import {
  EvaProp,
  styled,
  StyleType,
} from '@ui-kitten/components';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Text } from '../Text/Text';
import { AccordionItem } from '../Accordion/AccordionItem';
import { FilterMenu, FilterMenuOptions } from '../FilterMenu/FilterMenu';

export interface SPFilterProps extends ViewProps {
  category: string,
  eva?: EvaProp,
  filterOptions: FilterMenuOptions[],
  filterSelections: { [key: string]: boolean },
  onSearch?: (query: string) => void,
  style?: StyleProp<ViewStyle>,
  testID: string,
  total?: number,
  updateSelections?: (selectedId: string, value: boolean) => void,
}

@styled('SidePanelFilterItem')
export class SidePanelFilterItem extends React.Component<SPFilterProps> {
  private renderCounter = (
    counterContainerStyles: Record<string, any>,
    counterValueStyles: Record<string, any>,
  ) => (
    <View
      style={[counterContainerStyles]}
    >
      <Text category="c1" style={counterValueStyles}>
        {this.filterCount()}
      </Text>
    </View>
  );

  private filterCount = () => Object.keys(this.props.filterSelections)
    .filter((key) => this.props.filterSelections[key]).length;

  private hasFilters = () => !!Object.keys(this.props.filterSelections)
    .find((key) => this.props.filterSelections[key]);

  private getComponentStyle = (source: StyleType) => {
    const counterContainerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'counterContainer');
    const counterValueStyles = PropsServiceHelper.allWithPrefixMapped(source, 'counterValue');
    const accordionItemStyle = PropsServiceHelper.allWithPrefixMapped(source, 'accordionItem');

    return {
      counterContainer: counterContainerStyles,
      counterValue: counterValueStyles,
      accordionItem: accordionItemStyle,
    };
  };

  public render () {
    const {
      eva,
      testID,
      ...viewProps
    } = this.props;

    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <AccordionItem
        {...viewProps}
        accessoryRight={this.hasFilters()
          && (() => this.renderCounter(evaStyle.counterContainer, evaStyle.counterValue))}
        style={[evaStyle.accordionItem, this.props.style]}
        testID={`${testID}-accordion`}
        title={this.props.category}
      >
        <FilterMenu
          multiSelect
          onSearch={this.props.onSearch}
          onSelect={(option, value) => this.props.updateSelections(option, value)}
          options={this.props.filterOptions}
          searchSize="large"
          selectedOptions={this.props.filterSelections}
          showScrollBar={false}
          sidePanel
          testID={`${testID}-menu`}
          total={this.props.total}
        />
      </AccordionItem>
    );
  }
}
