import React, { FC } from 'react';
import { BlurView, BlurViewProps } from 'expo-blur';
import { useWindowDimensions } from 'react-native';

export const WindowBlur: FC<BlurViewProps> = ({
  style,
  ...blurProps
} : BlurViewProps) => {
  const { width, height } = useWindowDimensions();

  return (
    <BlurView
      {...blurProps}
      style={[
        {
          width,
          height,
        },
        style,
      ]}
    />
  );
};
