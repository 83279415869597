import React, { ReactText } from 'react';
import {
  ViewProps,
  Dimensions,
  Animated,
  ViewStyle,
  EmitterSubscription,
  StyleProp,
} from 'react-native';
import {
  FalsyFC,
  FalsyText,
  Frame,
  MeasureElement,
  MeasuringElement,
  RenderProp,
} from '@ui-kitten/components/devsupport';
import { styled, StyledComponentProps, StyleType } from '@ui-kitten/components';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Status } from '@theme/variant-interfaces/Status';
import {
  ToastPresentingConfig,
  ToastService,
} from '../Application/Toast/ToastService';
import { TextProps } from '../Text/Text';
import { ButtonProps } from '../Button/Button';

export interface ToastProps extends ToastPresentingConfig, StyledComponentProps {
  accessoryRight?: RenderProp<Partial<ButtonProps>>,
  children: RenderProp<TextProps> | React.ReactText,
  duration?: number,
  onClose?: () => void,
  status?: Status,
  style?: StyleProp<ViewStyle>,
  testID: string,
}

export type ToastElement = React.ReactElement<ToastProps>;

interface State {
  contentFrame: Frame;
  forceMeasure: boolean;
  // this is a state machine
  // 1. measure -
  //    render the element the page and measure it for width
  //    set the right margin to negative width
  //    animate right margin to final display
  // 2. slideIn - state while the slide in animation is executing
  //    start the duration timer
  // 4. slideOut - state while the slide out animation is executing
  //    this state also includes a delay for the view duration.
  //    after slideOut finishes, the toast is closed.
  renderState: 'measure' | 'slideIn' | 'slideOut';
  rightMargin: Animated.Value;
  bottomMargin: Animated.Value;
}

/**
 * A wrapper that presents content above an enclosing view.
 *
 * @extends React.Component
 *
 * @method {() => void} show - Sets modal visible.
 *
 * @method {() => void} hide - Sets modal invisible.
 *
 * @property {ReactNode} children - Component to render within the toast.
 *
 * @property {Renderprop<Partial<ButtonProps>>} accessoryRight - For rendering an action
 *
 * @property {Status} status - Highlight toast with a status
 *
 * @property {number} duration - How long the toast is visible in miliseconds
 *
 * @property {() => void} onClose - Method called when the toast finishes sliding out.
 *
 * @property {ViewProps} ...ViewProps - Any props applied to View component.
 *
 * @overview-example ToastSimpleUsage
 * Toasts accept content views as child elements and are displayed in the bottom screen center.
 *
 */
@styled('Toast')
export class Toast extends React.PureComponent<ToastProps, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    contentFrame: Frame.zero(),
    forceMeasure: false,
    renderState: 'measure',

    rightMargin: new Animated.Value(-999),
    bottomMargin: new Animated.Value(0),
  };

  private toastId: string;
  private timer: NodeJS.Timeout;
  private listener: EmitterSubscription;

  public componentDidMount (): void {
    this.listener = Dimensions.addEventListener('change', this.onDimensionChange);
    if (!this.toastId) {
      this.show();
    }
  }

  public componentDidUpdate (): void {
    if (!this.toastId && !this.state.forceMeasure) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ forceMeasure: true });
      return;
    }

    if (!this.toastId) {
      this.show();
      return;
    }

    ToastService.update(this.toastId, this.renderContentElement());
  }

  public componentWillUnmount (): void {
    this.listener.remove();
    // make sure we clear any timeouts to avoid a state update on an unmounted component
    clearTimeout(this.timer);
    this.hide();
  }

  public show = (): void => {
    this.toastId = ToastService.show(this.renderMeasuringContentElement(), {});
  };

  public hide = (): void => {
    this.toastId = ToastService.hide(this.toastId);
  };

  private onDimensionChange = (): void => {
    ToastService.update(this.toastId, this.renderMeasuringContentElement());
  };

  private onContentMeasure = (contentFrame: Frame): void => {
    this.state.contentFrame = contentFrame;

    if (this.state.renderState === 'measure') {
      // after we measure for the first time, set the initial position
      // of the toast to begin sliding
      this.state.rightMargin.setValue(contentFrame.size.width * -1);
      // then animate it to our display horizontal margin
      Animated.timing(this.state.rightMargin, {
        toValue: this.props.eva.style.marginHorizontal,
        duration: this.props.eva.style.animationDuration,
        useNativeDriver: false,
      }).start(this.afterSlideIn);
      // and set our state to slideIn
      this.state.renderState = 'slideIn';
    }
  };

  private afterSlideOut = () => {
    const {
      onClose = () => {},
    } = this.props;

    if (this.state.renderState === 'slideOut') {
      // when the slide out animation finishes, call the onClose callback
      onClose();
    }
  };

  private afterSlideIn = () => {
    const {
      duration = this.props.eva.style.visibleDuration as number,
    } = this.props;

    if (this.state.renderState === 'slideIn') {
      // begin animating the slide down after delaying for the requested view duration.
      this.state.renderState = 'slideOut';
      Animated.timing(this.state.bottomMargin, {
        toValue: this.state.contentFrame.size.height * -1,
        duration: this.props.eva.style.animationDuration,
        useNativeDriver: false,
        delay: duration,
      }).start(this.afterSlideOut);
    }
  };

  private getComponentStyle = (source: StyleType): {
    container: Animated.WithAnimatedObject<ViewStyle>,
    action: ButtonProps['style']
  } => {
    if (this.state.renderState !== 'slideOut') {
      // when render, set the bottom margin, but don't interrupt an
      // animation when we are in the slideOut state.
      this.state.bottomMargin.setValue(source.marginBottom);
    }

    return {
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: Frame.window().size.width - source.marginHorizontal * 3,
        ...source.elevation,
        ...source,
        marginBottom: this.state.bottomMargin,
        marginRight: this.state.rightMargin,
      },
      action: PropsServiceHelper.allWithPrefixMapped(source, 'action'),
    };
  };

  private renderContentElement = (): React.ReactElement<ViewProps> => {
    const {
      accessoryRight,
      children,
      eva,
      style,
      testID,
      ...viewProps
    } = this.props;

    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <Animated.View
        {...viewProps}
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        style={[
          evaStyle.container,
          style,
        ]}
        testID={testID}
      >

        <FalsyText
          category="p2"
          component={children as ReactText}
          style={{ flex: 1, width: '100%' }}
          testID={`${testID}-text`}
        />
        <FalsyFC
          appearance="ghost"
          // @ts-ignore this works with just style,
          // but the typings are freaking out with the extra props
          component={accessoryRight}
          size="small"
          style={evaStyle.action}
          testID={`${testID}-button`}
        />
      </Animated.View>
    );
  };

  private renderMeasuringContentElement = (): MeasuringElement => {
    return (
      <MeasureElement
        onMeasure={this.onContentMeasure}
        shouldUseTopInsets={ToastService.getShouldUseTopInsets}
      >
        {this.renderContentElement()}
      </MeasureElement>
    );
  };

  public render (): React.ReactNode {
    return null;
  }
}
