import { Button, Icon, SidePanel, VSpacer } from '@design';
import { ApiCropLogic, ApiFarmPlanToCropLogicLink } from '@shared/interfaces/api';
import { useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { FarmPlanProgramSelector } from './FarmPlanProgramSelector';

export interface FarmPlanProgramSidePanelProps {
  allAssignedPrograms: ApiCropLogic[],
  boundaryLinks: ApiFarmPlanToCropLogicLink[],
  onClose(): void,
  onCreateProgram: () => void,
  onProgramSelected: (program: ApiCropLogic, businessLevel: boolean) => void,
  visible: boolean,
}

export const FarmPlanProgramSidePanel = ({
  allAssignedPrograms = [],
  boundaryLinks,
  onClose,
  onCreateProgram,
  onProgramSelected,
  visible,
}: FarmPlanProgramSidePanelProps) => {
  const [translate] = useTranslation(['common', 'farmPlans', 'prepare']);
  const styles = useStyleSheet({
    flexRight: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });
  const currentAssignedPrograms = allAssignedPrograms.filter(
    (program) => boundaryLinks.find(
      (link) => link.cropLogicId === program.id,
    ),
  );

  return (
    <SidePanel
      header={translate<string>('APPLY_PROGRAM')}
      onClose={onClose}
      testID="apply-program-side-panel"
      visible={visible}
    >
        <ScrollView 
          showsHorizontalScrollIndicator={false}
          style={{ paddingHorizontal: 20 }}
        >
          <View style={[{ minHeight: 32 }, styles.flexRight]}>
            <Button
              accessoryLeft={(props) => (
                <Icon name="Plus" testID="create-program-button-icon" {...props} />
              )}
              appearance="ghost"
              onPress={onCreateProgram}
              size="medium"
              testID="create-program-button"
            >
              {translate<string>('CREATE_PROGRAM')}
            </Button>
          </View>
          <VSpacer size="7" />
          <FarmPlanProgramSelector
            assignedPrograms={currentAssignedPrograms}
            onProgramSelected={onProgramSelected}
          />
        </ScrollView>
    </SidePanel>
  );
};
