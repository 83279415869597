/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import _ from 'lodash';
import { Text } from '../../ui-components';
import { Sizing } from '../../constants';
import { testId } from '../../utilities/testId';

const styles = StyleSheet.create({
  subsection: {
    margin: Sizing.BASE_SPACING,
    minWidth: '30%',
  },
  infoWrapper: { flex: 1, flexDirection: 'row' },
  wrapper: { flexDirection: 'row' },
  infoHeader: { fontWeight: 'bold' },
});

interface ITableQuickViewItem {
  header: string,
  value: ReactNode,
  testId?: string,
}

interface ITableQuickViewProps {
  data: ITableQuickViewItem[];
  mode?: 'default' | '3col';
  testId?: string;
}

export const TableQuickView = (props: ITableQuickViewProps) => {
  const { data, mode = 'default', testId: propsTestId } = props;
  const NotWrappedLayout = () => {
    let chunkSize: number;
    switch (mode) {
      case '3col':
        chunkSize = 3;
        break;
      default:
        chunkSize = 1;
        break;
    }
    const chunks = _.chunk(data, chunkSize);
    return (
      <View style={{ flex: 1 }}>
        {chunks.map((chunk, index) => (
          <View key={index} style={styles.infoWrapper}>
            {chunk.map((info) => (
              <View key={info.header} style={{ flex: (1 / chunkSize) }}>
                <View style={{ margin: Sizing.BASE_SPACING }} {...testId(info.testId)}>
                  <Text {...testId('label')} style={styles.infoHeader}>
                    {info.header}
                  </Text>
                  {typeof info.value !== 'object' ? (
                    <Text {...testId('value')}>
                      {info.value}
                    </Text>
                  ) : info.value}
                </View>
              </View>
            ))}
          </View>
        ))}
      </View>
    );
  };

  const WrappedLayout = () => data.map((info) => (
    <View key={info.header} style={styles.subsection} {...testId(info.testId)}>
      <Text {...testId('label')} style={styles.infoHeader}>{info.header}</Text>
      {typeof info.value !== 'object' ? (
        <Text {...testId('value')}>
          {info.value}
        </Text>
      ) : info.value}
    </View>
  ));

  return (
    <View
      style={[styles.wrapper, { flexWrap: (mode === 'default') ? 'wrap' : 'nowrap' }]}
      {...testId(propsTestId)}
    >
      { (mode === 'default') ? WrappedLayout() : NotWrappedLayout() }
    </View>
  );
};
