import { ProductUtilities } from '@shared/utils';
import { useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { TFunction, useTranslation } from 'react-i18next';
import { Badge, FilterTable, HSpacer, Icon, IconButton, Modal, Text } from '@design';
import { FarmPlanDeliverableV2 } from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { ApplicationUom, PlanStatus } from '@shared/enums';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { IColumn, RowMeta } from '../../SortableTable';
import { Sizing, ThemeColors } from '../../../../constants';
import { StringUtility } from '../../../../utilities';
import { CustomRow } from '../CustomRow/CustomRow';
import OrderDetailsModal, { OrderDetailsModalProps } from './OrderDetailsModal';
import { OrderDetailsTableRowDetails } from './OrderDetailsTableRowDetails';

const styles = StyleSheet.create({
  disabled: {
    opacity: 0.4,
  },
  flexOne: {
    flex: 1,
  },
  lockIcon: {
    marginTop: Sizing.QUARTER_SPACING,
    alignSelf: 'flex-start',
  },
  lineThrough: {
    textDecorationLine: 'line-through',
  },
});

interface CalculatedProductCost {
  total: number,
  perAcre: number,
  perUnit: number,
  perPackage: number,
}

interface OrderDiscount {
  name: string,
  percent: number,
  dollarsTotal: number,
  dollarsPerAcre: number,
}

interface ProductDiscount extends OrderDiscount {
  dollarsPerUnit: number,
  dollarsPerPackage: number,
}

interface OrderDetailsTableProps {
  products: {
    acresApplied?: number,
    averageRateApplied?: number,
    averageRateAppliedUom?: ApplicationUom,
    cancelled?: boolean,
    discounts: ProductDiscount[],
    externalId?: string,
    packagesContracted: number,
    packagesRequired?: number,
    planStatus: PlanStatus,
    priceLocked?: boolean,
    productSkuName: string,
    retailPrice?: CalculatedProductCost,
    discountedPrice?: CalculatedProductCost,
    unitsRequired?: number,
  }[],
  overview: FarmPlanDeliverableV2.Snapshot['overview'];
  selections: FarmPlanDeliverableV2.Selections,
  showDetails: boolean,
  footerRow: React.ReactElement,
  expanderChevronStatus?: EvaStatus,
}

const getOrderDetailsModalData = (
  product: FarmPlanDeliverableV2.ProductSummary & RowMeta,
  translate: TFunction<('deliverable' | 'farmPlans' | 'common' | 'prepare')[], undefined>,
  selections: FarmPlanDeliverableV2.Selections,
): OrderDetailsModalProps['data'] => ([
  (selections.orderDetails.averageRateApplied && {
    label: translate('AVG_RATE_APPLIED'),
    value: `${StringUtility
      .formatDecimal(product.averageRateApplied)} ${translate(
      product.averageRateAppliedUom,
    )}${translate('PER_AC')}`,
    testID: 'avg-rate-applied',
  }),
  (selections.orderDetails.acresApplied && {
    label: translate('ACRES_APPLIED_SENTENCE'),
    value: StringUtility.formatDecimal(product.acresApplied, 3),
    testID: 'acres-applied',
  }),
  (selections.orderDetails.productUnitsRequired && {
    label: translate('UNITS_REQUIRED_SENTENCE'),
    value: StringUtility.formatDecimal(product.unitsRequired, 2),
    testID: 'units-required',
  }),
  (selections.orderDetails.productSkuPackagesRequired && {
    label: translate('SKU_PACKAGES_REQUIRED'),
    value: StringUtility.formatDecimal(product.packagesRequired, 2),
    testID: 'sku-packages-required',
  }),
  (selections.pricingDetails.productSkuPrice && {
    label: translate('PRODUCT_SKU_PRICE_SENTENCE'),
    value: StringUtility.formatCurrencyAccounting(product.retailPrice.perPackage),
    testID: 'product-sku-price',
  }),
  (selections.orderDetails.totalUnits && {
    label: translate('TOTAL_UNITS'),
    value: `${StringUtility.formatDecimal(product.totalUnits, 4)} ${translate(product.totalUnitsUom)}`,
    testID: 'total-units',
  }),
]).filter(Boolean);

let productExpandedState = {};

export const OrderDetailsTable = (props: OrderDetailsTableProps) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'products', 'common', 'prepare']);
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
  const [showDiscountInfoModal, setShowDiscountInfoModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalData, setModalData] = useState<OrderDetailsModalProps['data']>([]);
  const theme = useTheme();

  const statusBadge = (status: string) => {
    const badges = {
      SHARED: () => (
        <Badge status="info" testID="info-badge">
          {translate<string>('SHARED').toLocaleUpperCase()}
        </Badge>
      ),
      ACCEPTED: () => (
        <Badge status="success" testID="success-badge">
          {translate<string>('ACCEPTED').toLocaleUpperCase()}
        </Badge>
      ),
      REJECTED: () => (
        <Badge status="danger" testID="danger-badge">
          {translate<string>('REJECTED').toLocaleUpperCase()}
        </Badge>
      ),
      CANCELLED: () => (
        <Badge status="basic" testID="basic-badge">
          {translate<string>('CANCELLED').toLocaleUpperCase()}
        </Badge>
      ),
    };
    return badges[status];
  };

  const handleOpenModal = (
    data: OrderDetailsModalProps['data'],
    title: React.SetStateAction<string>,
  ) => {
    setShowOrderDetailsModal(true);
    setModalData(data);
    setModalTitle(title);
  };

  useEffect(() => {
    productExpandedState = {};
  }, []);

  const doesProductIdExist = props.products.some((product) => product.externalId);

  const productIdColumn = {
    columnId: 'productId',
    header: {
      render: translate('PRODUCT_ID'),
    },
    render: (product) => (
      <View style={styles.flexOne}>
        <Text
          category="p2"
          testID={`${product.productSkuName}-product-id`}
        >
          {product.externalId ?? '—'}
        </Text>
      </View>
    ),
    flex: 2,
  };

  const columns: IColumn<(FarmPlanDeliverableV2.ProductSummary & RowMeta)>[] = [
    ...(doesProductIdExist ? [productIdColumn] : []),
    {
      columnId: 'productSku',
      header: {
        render: translate('PRODUCT'),
      },
      render: (product) => (
        <Text
          category="p2"
          testID={`${product.productSkuName}-product-sku`}
        >
          {ProductUtilities.buildProductName(
            product,
            props.selections.options?.name,
            props.selections.options?.skuName,
          )}
        </Text>
      ),
      flex: 2,
    },
    {
      columnId: 'packagesContracted',
      header: {
        render: translate('PACKAGES_CONTRACTED'),
        align: 'right',
      },
      render: (product) => (
        <Text
          category="p2"
          testID={`${product.productSkuName}-sku-packages-contracted`}
        >
          {StringUtility.formatDecimal(product.packagesContracted, 2)}
        </Text>
      ),
      flex: 2,
      align: 'right',
    },
    (props.selections.pricingDetails.productUnitPrice) ? {
      columnId: 'unitPrice',
      header: {
        render: <Text>{translate<string>('UNIT_PRICE')}</Text>,
        align: 'right',
      },
      render: (product) => (
        <Text
          category="p2"
          style={[
            (!!product.discounts?.length) && styles.lineThrough,
          ]}
          testID={`${product.productSkuName}-unit-price`}
        >
          {StringUtility.formatCurrency(product.retailPrice?.perUnit)}
        </Text>
      ),
      flex: 2,
      align: 'right',
    } : {
      columnId: 'unitPrice',
      header: {
        render: '',
      },
      render: () => {},
      flex: 2,
    },
    (props.selections.orderDetails.priceLocked
    && props.selections.pricingDetails.productUnitPrice) && {
      columnId: 'priceLocked',
      header: {
        render: '',
      },
      render: (product) => (
        <>
          {(product.priceLocked)
            && (
              <Icon
                appearance="primary"
                name="Lock"
                size="giant"
                status="basic"
                style={styles.lockIcon}
                testID={`${product.productSkuName}-lock-icon`}
              />
            )}
          {(!product.priceLocked)
            && (
              <Icon
                appearance="primary"
                name="GrowersUnlock"
                size="giant"
                status="basic"
                style={styles.lockIcon}
                testID={`${product.productSkuName}-unlock-icon`}
              />
            )}
        </>
      ),
      flex: 1,
    },
    (props.selections.pricingDetails.productCostPerAcre) ? {
      columnId: 'costPerAcre',
      header: {
        render: translate('COST_PER_AC'),
        align: 'right',
      },
      render: (product) => (
        <Text
          category="p2"
          style={[
            (!!product.discounts?.length) && styles.lineThrough,
          ]}
          testID={`${product.productSkuName}-cost-per-acre`}
        >
          {StringUtility.formatCurrencyAccounting(product.retailPrice?.perAcre)}
        </Text>
      ),
      flex: 2,
      align: 'right',
    } : {
      columnId: 'costPerAcre',
      header: {
        render: '',
      },
      render: () => {},
      flex: 2,
    },
    (props.selections.pricingDetails.productTotalCost) ? {
      columnId: 'totalCost',
      header: {
        render: translate('TOTAL_COST'),
        align: 'right',
      },
      render: (product) => (
        <Text
          category="p2"
          style={[
            (!!product.discounts?.length) && styles.lineThrough,
          ]}
          testID={`${product.productSkuName}-cost`}
        >
          {StringUtility.formatCurrencyAccounting(product.retailPrice?.total)}
        </Text>
      ),
      flex: 2,
      align: 'right',
    } : {
      columnId: 'totalCost',
      header: {
        render: '',
      },
      render: () => {},
      flex: 2,
    },
    {
      columnId: 'planStatus',
      header: {
        render: translate('PLAN_STATUS'),
      },
      render: (product) => (
        product.cancelled
          ? statusBadge('CANCELLED')
          : statusBadge(product.planStatus?.toString())),
      flex: 3,
    },
    {
      columnId: 'detailsIcon',
      header: {
        render: null,
      },
      render: (product) => {
        const data: OrderDetailsModalProps['data'] = getOrderDetailsModalData(
          product,
          translate,
          props.selections,
        );
        return (
          <IconButton
            appearance="ghost"
            onPress={() => handleOpenModal(data, ProductUtilities.buildProductName(
              product,
              props.selections.options?.name,
              props.selections.options?.skuName,
            ))}
            status="basic"
            testID="view-details-button"
            {...props}
          >
            <Icon name="GrowersViewDetails" testID="view-details-button-icon" />
          </IconButton>
        );
      },
      flex: 1,
    },
  ];

  const filterTableData = props.products?.map((product, index) => ({
    ...product,
    hasDetails: true,
    initDetail: productExpandedState[product.productSkuName],
    rowId: `row:${index}|${product.productSkuName}|`,
    rowDetailId: `row-drawer:${index}|${product.productSkuName}|`,
  }));

  const subtotal = props.overview.cost - props.overview.productDiscountTotal;
  const subtotalPerAcre = subtotal / props.overview.acresPlanned;

  return (
    <>
      <FilterTable
        columns={columns}
        currentPage={0}
        data={filterTableData}
        defaultColumnFilters={[]}
        expanderChevronStatus={props.expanderChevronStatus}
        filterOptions={[]}
        footerRow={(
          props.selections.pricingDetails.productTotalCost
          || props.selections.pricingDetails.productCostPerAcre) ? (
            <View style={{ flex: 1 }}>
              {props.footerRow}
              {(!!props.overview.discounts.length) && (
              <>
                <CustomRow
                  columns={columns}
                  values={{
                    productSku: {
                      children: <Text>{translate<string>('SUBTOTAL')}</Text>,
                    },
                    packagesContracted: null,
                    costPerAcre: {
                      children: (
                        <Text>
                          {StringUtility.formatCurrencyAccounting(subtotalPerAcre)}
                        </Text>
                      ),
                      align: 'right',
                    },
                    unitPrice: null,
                    priceLocked: null,
                    totalCost: {
                      children: (
                        <Text>
                          {StringUtility.formatCurrencyAccounting(subtotal)}
                        </Text>
                      ),
                      align: 'right',
                    },
                  }}
                />
                <CustomRow
                  columns={columns}
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: theme['color-basic-control-transparent-100'],
                  }}
                  values={{
                    productSku: {
                      children: (
                        <View style={{ flexDirection: 'row' }}>
                          <Text category="s1">
                            {translate<string>('ORDER_DISCOUNTS')}
                          </Text>
                          <TouchableOpacity onPress={() => setShowDiscountInfoModal(true)}>
                            <Icon
                              fill={ThemeColors.SECONDARY}
                              name="Info"
                              size="small"
                              style={[{ marginLeft: Sizing.EM / 1.71 }]}
                              testID="info-icon"
                            />
                          </TouchableOpacity>
                        </View>
                      ),
                      flex: 4.5,
                    },
                    packagesContracted: { flex: 0 },
                    costPerAcre: null,
                    unitPrice: null,
                    priceLocked: null,
                    totalCost: null,
                  }}
                />
                {props.overview.discounts.map((discount, index) => (
                  <CustomRow
                    columns={columns}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: (index + 1 === props.overview.discounts.length)
                        ? theme['color-basic-control-transparent-600']
                        : theme['color-basic-control-transparent-100'],
                    }}
                    values={{
                      productSku: {
                        children: (
                          <Text category="p2">
                            {discount.name}
                            {(!!discount.percent) && (
                              <>
                                <HSpacer size="4" />
                                |
                                <HSpacer size="4" />
                                <Text category="p2" status="success">
                                  {translate<string>('PERCENT_OFF', { percent: discount.percent })}
                                </Text>
                              </>
                            )}
                          </Text>
                        ),
                        flex: 4.5,
                      },
                      packagesContracted: { flex: 0 },
                      costPerAcre: {
                        children: (
                          <Text category="p2" status="success">
                            {StringUtility.formatDiscount(discount.dollarsPerAcre)}
                          </Text>
                        ),
                        align: 'right',
                      },
                      unitPrice: null,
                      priceLocked: null,
                      totalCost: {
                        children: (
                          <Text category="p2" status="success">
                            {StringUtility.formatDiscount(discount.dollarsTotal)}
                          </Text>
                        ),
                        align: 'right',
                      },
                    }}
                  />
                ))}
              </>
              )}
              <CustomRow
                columns={columns}
                values={{
                  productSku: {
                    children: <Text>{translate<string>('TOTAL')}</Text>,
                  },
                  packagesContracted: null,
                  costPerAcre: {
                    children: (
                      <Text>
                        {props.selections.pricingDetails.productCostPerAcre
                          ? StringUtility.formatCurrencyAccounting(props.overview.orderTotalPerAcre)
                          : null}
                      </Text>
                    ),
                    align: 'right',
                  },
                  unitPrice: null,
                  priceLocked: null,
                  totalCost: { children: (
                    <Text>
                      {props.selections.pricingDetails.productTotalCost
                        ? StringUtility.formatCurrencyAccounting(props.overview.orderTotal)
                        : null}
                    </Text>
                  ),
                  align: 'right',
                  },
                }}
              />
            </View>
          ) : null}
        noFilters
        onPageChange={() => { }}
        onRowExpand={(rowData, expanded) => {
          productExpandedState[rowData.productSkuName] = expanded;
        }}
        onUpdateFilter={() => { }}
        rowDetail={({ discounts, discountedPrice }) => ((!discounts?.length) ? null : (
          <OrderDetailsTableRowDetails
            columns={columns}
            discountedPrice={discountedPrice}
            discounts={discounts}
          />
        ))}
        tableId="OrderDetailsTable"
        testID="order-details-table"
      />
      {showOrderDetailsModal && (
        <OrderDetailsModal
          data={modalData}
          onClose={() => setShowOrderDetailsModal(false)}
          testID="order-details-modal"
          title={modalTitle}
        />
      )}
      {showDiscountInfoModal && (
        <Modal
          onClose={() => setShowDiscountInfoModal(false)}
          testID="discount-info-modal"
          visible
        >
          <Text>
            {translate<string>('DISCOUNT_INFO_MESSAGE_1')}
          </Text>
        </Modal>
      )}
    </>
  );
};
