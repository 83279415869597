import {
  Button,
  Card,
  CenteredSpinner,
  HSpacer,
  Icon,
  Input,
  Text,
  VSpacer,
} from '@design';
import { SharedConfig } from '@shared/constants';
import { ApiPriceType } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  ColorCircleIndicator,
} from '../../../components/shared/ColorCircleIndicator/ColorCircleIndicator';

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    width: 604,
  },
  colorIndicator: {
    alignItems: 'flex-end',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 2.5,
  },
  inputContainer: {
    flex: 1,
  },
  inputDefaultHint: {
    paddingTop: 8,
    paddingLeft: 12,
  },
  inputRow: {
    alignItems: 'flex-start',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export type EditFormPriceType = Partial<ApiPriceType> & {
  key?: string,
  name: string,
};

interface ProductSettingsEditPriceTypeFormProps {
  addPriceType: () => void,
  errorIds: string[],
  isLoading: boolean,
  onChangeText: (text: string, index: number) => void,
  onDelete: (id: string, index: number) => void,
  priceTypes: EditFormPriceType[],
}

export const ProductSettingsEditPriceTypeForm = ({
  addPriceType,
  errorIds,
  isLoading,
  onChangeText,
  onDelete,
  priceTypes,
}: ProductSettingsEditPriceTypeFormProps) => {
  const [translate] = useTranslation(['businesses']);
  const errorMessage = translate<string>('PRICE_TYPE_NAME_MUST_BE_UNIQUE');
  const priceTypeCount = priceTypes.length;

  return (
    <View style={{ alignItems: 'center', width: '100%' }}>
      <KeyboardAwareScrollView>
        { isLoading && <CenteredSpinner /> }
        <Card style={styles.card} testID="edit-price-type-form-card">
          <View style={{ paddingTop: 8 }}>
            <Text category="label">
              {translate<string>('PRICE_TYPES_HEADER', { count: priceTypeCount })}
            </Text>
          </View>
          <VSpacer size="7" />
          { priceTypes.map(({ id, key, name, isDefault }, index) => (
            <View key={`${id || key}`}>
              <View style={styles.inputRow}>
                <View style={styles.inputContainer}>
                  <Input
                    {...(isDefault && (
                      { accessoryLeft: () => (
                        <ColorCircleIndicator
                          style={styles.colorIndicator}
                          testID="edit-modal-default-price-type"
                        />
                      ) }
                    ))}
                    onChangeText={(text) => onChangeText(text, index)}
                    {...(errorIds.includes(id || key) && { status: 'danger', caption: errorMessage })}
                    testID={`${`new-price-type-${index}`}-input`}
                    value={name}
                  />
                  { isDefault && (
                    <Text appearance="hint" category="c1" style={styles.inputDefaultHint}>
                      { translate<string>('PRICE_TYPE_DEFAULT_INPUT_CAPTION', { currentPriceType: name }) }
                    </Text>
                  )}
                </View>
                <HSpacer size="3" />
                <View style={{ height: 40, width: 40 }}>
                  <Button
                    accessoryLeft={(prop) => <Icon name="Trash" testID={`${name || `new-price-type${index}`}-delete-button-icon`} {...prop} />}
                    appearance="ghost"
                    disabled={isDefault || (!id && !!name)}
                    onPress={() => onDelete(id, index)}
                    size="medium"
                    status="basic"
                    testID={`${`new-price-type-${index}`}-delete-button`}
                  />
                </View>
              </View>
              <VSpacer size="7" />
            </View>
          ))}
          <View style={{ flexDirection: 'row' }}>
            <Button
              accessoryLeft={(prop) => <Icon name="Plus" testID="add-price-type-button-icon" {...prop} />}
              appearance="filled"
              disabled={priceTypeCount >= SharedConfig.MAX_PRICE_TYPES}
              onPress={addPriceType}
              size="tiny"
              style={{ flex: 0 }}
              testID="add-price-type-button"
            >
              {translate<string>('ADD_PRICE_TYPE_BUTTON')}
            </Button>
          </View>
        </Card>
      </KeyboardAwareScrollView>
    </View>
  );
};
