import { TableCell } from '@design';
import { Alignment } from '@theme/variant-interfaces/Alignment';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { IColumn } from '../../SortableTable';
import { columnWidthStyle } from '../../SortableTable/functions';

export interface CustomRowProps<T> {
  columns: IColumn<T>[],
  values: {
    [columnId: string]: { children?: React.ReactChild, flex?: number, align?: Alignment } | null,
  },
  style?: StyleProp<ViewStyle>,
  rowDetail?: boolean;
}
export function CustomRow<T> ({ columns, values, style, rowDetail }: CustomRowProps<T>) {
  const row = columns.map((col) => {
    if (!col) return null;
    const value = values[col.columnId];
    return (
      <TableCell
        align={value?.align}
        key={col.columnId}
        style={[
          columnWidthStyle(value?.flex === undefined ? col : { flex: value.flex }),
          (value !== undefined) && style,
        ]}
        testID={col.columnId}
      >
        {value?.children}
      </TableCell>
    );
  });

  return (
    <View
      style={[
        { flexDirection: 'row' },
        rowDetail ? { marginLeft: -16, marginRight: -16 } : { marginLeft: 72 },
      ]}
    >
      {row}
    </View>
  );
}
