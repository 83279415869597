import { Card, Header, IconButton, Modal, Text, TextLink, VSpacer } from '@design';
import { ApplicationUom } from '@shared/enums';
import { ApiProductSummary } from '@shared/interfaces/api';
import { CalculationUtility, FarmPlanProfitCalculation } from '@shared/utils';
import { Status } from '@theme/variant-interfaces/Status';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../../constants';
import { StringUtility } from '../../../../../utilities';
import { LineSpacer } from '../../../../components/shared';

const styles = StyleSheet.create({
  card: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

interface RowProps {
  isTotal?: boolean,
  perAcre: number,
  title: string,
  titleValue: number,
}

const Row = ({ isTotal, perAcre, title, titleValue }: RowProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  const category = isTotal ? 's1' : undefined;
  let status: Status;

  if (isTotal) {
    if (titleValue < 0) {
      status = 'danger';
    } else {
      status = 'success';
    }
  }

  return (
    <>
      <View style={styles.row}>
        <Text category={category} testID={`${title}-title`}>
          {title}
        </Text>
        <Text category={category} status={status} testID={`${title}-value`}>
          {StringUtility.formatCurrencyAccounting(titleValue)}
        </Text>
      </View>
      <VSpacer size="2" />
      <View style={styles.row}>
        <Text appearance="hint" category="p2" testID={`${title}-per-acre-title`}>
          {translate<string>('PER_ACRE')}
        </Text>
        <Text appearance="hint" category="p2" status={status} testID={`${title}-per-acre-value`}>
          {StringUtility.formatCurrencyAccounting(perAcre)}
        </Text>
      </View>
    </>
  );
};

export interface FarmPlanProfitProps {
  customerId: string,
  profit: FarmPlanProfitCalculation,
  products: ApiProductSummary[],
  priceTypeId: string,
  locationId: string | null,
}

export const FarmPlanProfit = ({
  customerId,
  profit,
  products,
  priceTypeId,
  locationId,
}: FarmPlanProfitProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const history = useHistory();
  const planningParameters = 'annual-planning';

  const getProductCost = (productItem) => {
    const costPerAcre = CalculationUtility.calculateProductCostPerAcre(
      productItem.product,
      productItem.avgRateApplied,
      productItem.avgRateAppliedUom as ApplicationUom,
      CalculationUtility.getApplicableProductPrice(
        productItem.product,
        priceTypeId,
        locationId,
      ),
    );
    return costPerAcre * productItem.acresApplied;
  };

  const productCosts = products.map(getProductCost).reduce((a, b) => a + b, 0);
  const costPerAcre = productCosts / profit.acresPlanned;
  const incomePerAcre = profit.income / profit.acresPlanned;
  const profitTotal = profit.income - productCosts;
  const profitPerAcre = profitTotal / profit.acresPlanned;

  return (
    <View style={styles.card}>
      <Header level="3" testID="profit-summary-title" title={translate('PROFIT_SUMMARY')}>
        <IconButton
          appearance="ghost"
          onPress={() => setShowInfoModal(true)}
          size="small"
          status="info"
          testID="info-button"
        >
          Info
        </IconButton>
      </Header>
      <VSpacer size="5" />
      <Card style={styles.card} testID="profit-card">
        <Row
          perAcre={incomePerAcre}
          title={translate('REVENUE')}
          titleValue={profit.income}
        />
        <VSpacer size="8" />
        <Row
          perAcre={costPerAcre}
          title={translate('COST')}
          titleValue={productCosts}
        />
        <LineSpacer size="7" />
        <Row
          isTotal
          perAcre={profitPerAcre}
          title={translate<string>('PROFIT')}
          titleValue={profitTotal}
        />
      </Card>
      {showInfoModal && (
      <Modal
        onClose={() => setShowInfoModal(false)}
        style={{ alignSelf: 'center' }}
        testID="farm-plan-info-modal"
        visible={showInfoModal}
      >
        <Text>
          {`${translate('INFO_MESSAGE_1')} `}
          {`${translate<string>('INFO_MESSAGE_2')} `}
          <TextLink
            appearance="secondary"
            onPress={() => (
              history.push(
                Routes.GROWER_DETAILS
                  .replace(/:grower/, customerId)
                  .replace(/:tab/, planningParameters),
              ))}
          >
            {translate<string>('CLICK_HERE')}
          </TextLink>
        </Text>
      </Modal>
      )}
    </View>
  );
};
