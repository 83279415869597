import { ApiPrice, ApiPriceType } from '@shared/interfaces/api';
import _ from 'lodash';

export const arePricesValid = (prices: ApiPrice[]) => {
  if (_.isEmpty(prices)) {
    return true;
  }

  for (let i = 0; i < prices.length; i += 1) {
    const priceEntry = prices[i];
    const isLast = i === prices.length - 1;
    if ((_.isEmpty(priceEntry.locationId) || _.isNil(priceEntry.unitPrice)) && !isLast) {
      return false;
    }
    if (isLast) {
      return !_.isEmpty(priceEntry.locationId) && !_.isNil(priceEntry.unitPrice);
    }
  }
  return true;
};

export const getDefaultPrices = (priceTypes: ApiPriceType[], defaultPrices: ApiPrice[] = []) => {
  const defaultPrice = defaultPrices.find(
    (dp) => !dp.locationId && dp.priceTypeId === priceTypes.find((pt) => pt.isDefault)?.id,
  )?.unitPrice;

  return [
    ...priceTypes.map((pt) => {
      const existingPrice = defaultPrices.find(
        (dp) => !dp.locationId && dp.priceTypeId === pt.id,
      );

      return {
        id: existingPrice?.id,
        locationId: null,
        priceTypeId: pt.id,
        unitPrice: existingPrice?.unitPrice ?? defaultPrice,
      };
    }),
    ...defaultPrices.filter((dp) => !!dp.locationId),
  ];
};
