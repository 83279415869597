import _ from 'lodash';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  Text,
  Button,
  HSpacer,
  Card,
  VSpacer,
  useToast,
  Modal,
  LargeModal,
  Icon,
} from '@design';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTranslation } from 'react-i18next';
import { ApiPriceType, ApiTankMix, GrowerEndpoint } from '@shared/interfaces/api';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import {
  ConfirmationModal,
} from '../../../../components/shared/ConfirmationModal/ConfirmationModal';
import { BusinessCropSelect } from '../../../../components/shared/Input/BusinessCropSelect';
import {
  BusinessLocationSelect,
} from '../../../../components/shared/Input/BusinessLocationSelect';
import { BusinessUserSelect } from '../../../../components/shared/Input/BusinessUserSelect';
import { TankMixNameInput } from './TankMixNameInput';
import { PriceTypeSelect } from '../../../../components/shared/Input/PriceTypeSelect';
import { SelectionModal } from '../../../../components/shared/SelectionModal/SelectionModal';
import { CustomersChipSet } from './CustomersChipSet';
import { GrowerApi } from '../../../../../utilities/api';
import { useQuery } from 'react-query';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { QueryKeys } from '../../../../../constants';
import { UserRole } from '@shared/enums';
import { SharedConfig } from '@shared/constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 489,
  },
  footer: {
    position: 'absolute',
    flexDirection: 'row',
    right: 132,
    bottom: 32,
    width: 100,
    marginRight: 32,
  },
  card: {
    flex: 1,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
  },
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
});

const billingLocationSelectWindowHeight = 300;
const modalWidth = 560;

interface FormWrapperProps {
  children: ReactNode;
}

const FormWrapper = ({ children }: FormWrapperProps) => (
  <View style={{ alignItems: 'center', width: '100%' }}>
    <View style={styles.container}>
      <KeyboardAwareScrollView>
        {children}
      </KeyboardAwareScrollView>
    </View>
  </View>
);

interface TankMixModalProps {
  editMode?: boolean,
  isReadOnlyExceptCustomers?: boolean,
  onCancel(tankMix: ApiTankMix): void,
  onSave(): void,
  onTankMixChange: (tankMix: ApiTankMix) => void,
  tankMix: ApiTankMix,
}

export const TankMixModal: FC<TankMixModalProps> = ({
  editMode,
  isReadOnlyExceptCustomers,
  onCancel,
  onSave,
  onTankMixChange,
  tankMix,
}: TankMixModalProps) => {
  const [translate] = useTranslation(['prepare', 'tankMix', 'common', 'errors']);
  const initialTankMix = useRef(tankMix).current;
  const [selectedPriceType, setSelectedPriceType] = useState<ApiPriceType>(null);
  const { priceTypes, defaultPriceType } = usePriceTypeList();
  const [nameValidated, setNameValidated] = useState(true);
  const [cancelEditVisible, setCancelEditVisible] = useState(false);
  const [isCustomerSelectionModalVisible, setIsCustomerSelectionModalVisible] = useState(false);
  const { currentBusinessId, user } = useAuthentication();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const isStandardUser = user.userRole === UserRole.BUSINESS_STANDARD;

  const { createToast } = useToast();

  const { data: customerList, isLoading: loadingCustomers } = useQuery(
    [QueryKeys.GROWER_LIST, tankMix.id, page, search],
    async () => GrowerApi.getGrowers({
      assigned: isStandardUser,
      businessId: currentBusinessId,
      page,
      search,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }),
    {
      onError: () => {
        createToast({
          children: translate<string>('ERROR_FETCH_GROWERS'),
          status: 'danger',
          testID: 'tankmix-modal-error-toast',
        });
      },
    },
  );

  const { data: assignedCustomerList } = useQuery(
    [QueryKeys.GROWER_LIST, tankMix.assignedGrowers],
    async () => GrowerApi.getGrowers({
      assigned: false,
      businessId: currentBusinessId,
      ids: tankMix.assignedGrowers,
      limit: SharedConfig.MAX_PAGE_LIMIT,
    }),
    {
      enabled: !!tankMix.assignedGrowers.length,
      onError: () => {
        createToast({
          children: translate<string>('ERROR_FETCH_GROWERS'),
          status: 'danger',
          testID: 'tankmix-modal-error-toast',
        });
      },
    },
  );

  useEffect(() => {
    const currentPriceType = priceTypes.find((priceType) => (
      priceType.id === tankMix.priceTypeId
    ));
    if (!currentPriceType) {
      setSelectedPriceType(defaultPriceType);
      if (tankMix.priceTypeId !== defaultPriceType?.id) {
        onTankMixChange({ ...tankMix, priceTypeId: defaultPriceType?.id });
      }
    } else {
      setSelectedPriceType(currentPriceType);
    }
  }, [defaultPriceType, onTankMixChange, priceTypes, tankMix]);

  const formValid = (): boolean => {
    const requiredFields = [
      tankMix.name,
      tankMix.ownerBusinessUserId,
      tankMix.crops.length,
      nameValidated,
      tankMix.priceTypeId,
    ];

    return requiredFields.every((field) => (
      (Array.isArray(field) && field.length) || (!Array.isArray(field) && field)
    ));
  };

  const formContent = (
    <>
      <TankMixNameInput
        name={tankMix.name}
        onUpdateName={(name) => {
          onTankMixChange({ ...tankMix, name });
        }}
        onUpdateValidate={setNameValidated}
        readonly={isReadOnlyExceptCustomers}
        tankMixId={tankMix.id}
      />
      <VSpacer size="8" />
      <BusinessUserSelect
        disabled={editMode}
        onUpdateOwner={(ownerBusinessUserId) => {
          onTankMixChange({ ...tankMix, ownerBusinessUserId });
        }}
        ownerId={tankMix.ownerBusinessUserId}
        readonly={isReadOnlyExceptCustomers}
      />
      <VSpacer size="8" />
      <BusinessCropSelect
        crops={tankMix.crops}
        onUpdateCrops={(crops) => {
          onTankMixChange({ ...tankMix, crops });
        }}
        readonly={isReadOnlyExceptCustomers}
      />
      <VSpacer size="8" />
      <BusinessLocationSelect
        locationId={tankMix.businessLocationId || null}
        onUpdateLocation={(businessLocationId) => {
          onTankMixChange({ ...tankMix, businessLocationId });
        }}
        optionWindowMaxHeight={billingLocationSelectWindowHeight}
        readonly={isReadOnlyExceptCustomers}
      />
      <PriceTypeSelect
        isRequired
        onUpdatePriceType={(priceType) => {
          onTankMixChange({
            ...tankMix,
            priceTypeId: priceType.id,
          });
        }}
        readonly={isReadOnlyExceptCustomers}
        selectedPriceType={selectedPriceType}
      />
      <VSpacer size="8" />
      <CustomersChipSet
        assignedCustomers={assignedCustomerList?.data.filter((customer) => tankMix.assignedGrowers.includes(customer.id))}
        disabled={loadingCustomers}
        handleOnAddCustomers={() => setIsCustomerSelectionModalVisible(true)}
        isStandardUser={isStandardUser}
        onPress={(selection) => {
          const newSelections = tankMix.assignedGrowers.filter((growerId) => growerId !== selection);
          onTankMixChange({
            ...tankMix,
            assignedGrowers: newSelections,
          });
        }}
      />
    </>
  );

  const createModeFormPage = [
    <FormWrapper>
      <Card style={styles.card} testID="tank-mix-modal-card">
        <Text category="label">
          {translate<string>('DETAILS')}
        </Text>
        <VSpacer size="9" />
        {formContent}
      </Card>
    </FormWrapper>,
  ];

  return (
    <>
      <ConfirmationModal
        cancelText={translate('CONTINUE_EDITING')}
        confirmText={translate('CONFIRM')}
        messageText={translate(
          editMode ? 'CANCEL_EDIT_DIALOG_DESCRIPTION_EDIT'
            : 'CANCEL_EDIT_DIALOG_DESCRIPTION_NEW',
        )}
        onCancel={() => setCancelEditVisible(false)}
        onConfirm={() => onCancel(initialTankMix)}
        title={(!editMode) && translate('CANCEL_EDIT_DIALOG_TITLE')}
        visible={cancelEditVisible}
      />
      {editMode ? (
        <Modal
          footerAccessory={() => (
            <>
              <Button
                appearance="outline"
                onPress={() => {
                  if (_.isEqual(initialTankMix, tankMix)) {
                    onCancel(initialTankMix);
                  } else {
                    setCancelEditVisible(true);
                  }
                }}
                size="small"
                status="basic"
                testID="tankmix-modal-cancel-button"
              >
                {translate<string>('CANCEL')}
              </Button>
              <HSpacer size="4" />
              <Button
                disabled={!formValid()}
                onPress={onSave}
                size="small"
                testID={'tankmix-modal-save-button'}
              >
                {translate<string>('SAVE_CHANGES')}
              </Button>
            </>
          )}
          hideCloseButton
          maxHeight
          testID="tankmix-large-modal"
          title={translate<string>('EDIT_DETAILS')}
          visible
          width={modalWidth}
        >
          <FormWrapper>
            {formContent}
          </FormWrapper>
        </Modal>
      ) : (
        <LargeModal
          footer={() => (
            <View style={styles.footer}>
              <Button
                appearance="outline"
                design="floating"
                onPress={() => {
                  if (_.isEqual(initialTankMix, tankMix)) {
                    onCancel(initialTankMix);
                  } else {
                    setCancelEditVisible(true);
                  }
                }}
                status="basic"
                testID="tankmix-modal-cancel-button"
              >
                {translate<string>('CANCEL')}
              </Button>
              <HSpacer size="6" />
              <Button
                accessoryRight={(iconsProps) => (
                  <Icon
                    name="ArrowForward"
                    testID={'tankmix-modal-next-button-icon'}
                    {...iconsProps}
                  />
                )}
                design="floating"
                disabled={!formValid()}
                onPress={onSave}
                testID={'tankmix-modal-next-button'}
              >
                {translate<string>('NEXT')}
              </Button>
            </View>
          )}
          pages={createModeFormPage}
          testID="tankmix-large-modal"
          title={translate<string>('CREATE_PRODUCT_MIX')}
          visible
        />
      )}
      {isCustomerSelectionModalVisible && 
        <SelectionModal
          currentPage={page}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.legalName}
          onCancel={() => setIsCustomerSelectionModalVisible(false)}
          onDone={(selections) => {
            setIsCustomerSelectionModalVisible(false);
            onTankMixChange({
              ...tankMix,
              assignedGrowers: [...selections],
            });
            setPage(0);
            setSearch('');
          }}
          optionCountLabel={translate('CUSTOMERS')}
          options={customerList?.data ?? []}
          search={search}
          selectedOptions={tankMix.assignedGrowers}
          setPage={setPage}
          setSearch={setSearch}
          subTitle={translate('SELECT_CUSTOMERS_DESCRIPTION')}
          title={translate('ADD_CUSTOMERS_TITLE')}
          totalOptions={customerList?.total ?? 0}
          totalPages={customerList?.lastPage ?? 0}
          visible={isCustomerSelectionModalVisible}
        />
      }
    </>
  );
};
