import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectItem } from '@design';
import { IndexPath } from '@ui-kitten/components';
import { getCurrentYearAsNumber } from '@shared/utils';

interface CropYearPickerSelectProps {
  cropYear?: number;
  onChange: (value) => void;
}

export const CropYearPickerSelect = ({ cropYear, onChange }: CropYearPickerSelectProps) => {
  const [translate] = useTranslation(['common']);
  const currentYear = getCurrentYearAsNumber();
  const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2];
  useEffect(() => {
    if (!cropYear) {
      onChange(currentYear);
    }
  }, [cropYear, onChange, currentYear]);

  const selectedIndex = years.findIndex((year) => year === cropYear) ?? -1;

  return (
    <Select
      isRequired
      label={translate<string>('CROP_YEAR')}
      onSelect={(idx: IndexPath | IndexPath[]) => {
        onChange(years[(idx as IndexPath).row]);
      }}
      selectedIndex={selectedIndex === -1 ? undefined : new IndexPath(selectedIndex)}
      testID="crop-year-selector"
      value={cropYear}
    >
      {years.map((year, index) => (
        <SelectItem key={year} testID={`crop-year-picker-dropdown-value-${index}`} title={year} />
      ))}
    </Select>
  );
};
