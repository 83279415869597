import { StyleSheet, View } from 'react-native';
import React, { FunctionComponent, useState } from 'react';
import { Text, TextLink, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Routes } from '../../../../constants/Routes';
import { ErrorTractor } from '../../../../svg/ErrorTractor';
import { TruckGame } from './TruckGame';

export const styles = StyleSheet.create({
  background: {
    position: 'relative',
    textAlign: 'center',
    backgroundColor: '#1C1C1C',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  footer: {
    bottom: 0,
    position: 'absolute',
    width: '100%',
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logo: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export const ErrorPage: FunctionComponent = () => {
  const [translate] = useTranslation(['errors', 'login']);
  const history = useHistory();

  const [showEasterEgg, setShowEasterEgg] = useState(false);

  const contactRallySupport = () => history.push(Routes.HELP);

  return (
    <View style={styles.background}>
      {showEasterEgg
        ? <TruckGame />
        : (
          <>
            <View style={styles.content}>
              <View style={styles.logoContainer}>
                <View style={styles.logo}>
                  <TouchableOpacity onPress={() => setShowEasterEgg(true)}>
                    <ErrorTractor />
                  </TouchableOpacity>
                </View>
              </View>
              <VSpacer size="8" />
              <Text category="h6">{translate<string>('FATAL_ERROR_MESSAGE')}</Text>
              <VSpacer size="8" />
              <Text category="h5">{translate<string>('FATAL_ERROR_RELOAD_MESSAGE')}</Text>
              <VSpacer size="14" />
            </View>
            <View style={styles.footer}>
              <View style={styles.content}>
                <Text appearance="hint" category="c1">
                  {translate<string>('NEED_HELP')}
                </Text>
                <VSpacer size="2" />
                <TextLink
                  appearance="secondary"
                  category="p2"
                  onPress={() => contactRallySupport()}
                >
                  {translate<string>('EMAIL_GROWERS')}
                </TextLink>
              </View>
            </View>
          </>
        )}
    </View>
  );
};
