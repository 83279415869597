import { Button, HSpacer, Icon, Text } from '@design';
import { ApiProductOrderComponent, ApiProductOrderProductMixComponent } from '@shared/interfaces/api';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { calculateTankMixTotalCostPerAcre } from '../../helpers';

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderTopColor: GrowersDarkTheme['color-basic-transparent-100'],
    borderTopWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 16,
    paddingLeft: 28,
    paddingRight: 28,
    paddingTop: 16,
  },
  text: {
    flex: 1,
    flexShrink: 1,
    width: 1,
  },
  buttonPrice: {
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 30,
  },
});

export interface SelectedProductItemProps {
  index: number,
  locationId: string,
  onRemovePress: (
    product?: ApiProductOrderComponent,
    productMix?: ApiProductOrderProductMixComponent,
  ) => void,
  priceTypeId: string,
  product?: ApiProductOrderComponent,
  productMixes?: ApiProductOrderProductMixComponent[],
  selected?: boolean,
}

export const SelectedProductItem: FC<SelectedProductItemProps> = ({
  index,
  locationId,
  onRemovePress,
  priceTypeId,
  product,
  productMixes,
  selected,
}) => {
  const [translate] = useTranslation(['common', 'businesses']);

  const formattedPrice = useMemo(() => {

    if (!product) {
      return '';
    }

    let price = 0;
    if (product.product.prices != null) {
      const defaultPrice = product.product.prices.find(
        (x) => !x.businessLocationId && x.priceTypeId === priceTypeId,
      );
      const locationPrice = product.product.prices.find(
        (x) => x.businessLocationId === locationId && x.priceTypeId === priceTypeId,
      );

      price = (locationPrice || defaultPrice)?.price ?? 0;
    }

    return StringUtility.formatCurrency(price, '$');
  }, [product, locationId, priceTypeId]);

  const formattedTotalCostPerAcre = useMemo(() => {
    if (!productMixes) {
      return '';
    }

    const totalCostPerAcre = calculateTankMixTotalCostPerAcre(
      productMixes,
      priceTypeId,
      locationId,
    );

    return StringUtility.formatCurrency(totalCostPerAcre, '$');
  }, [locationId, priceTypeId, productMixes]);

  const productTestPrefix = `${onRemovePress ? 'selected-' : ''}product-`;
  const productMix = productMixes ? productMixes[0] : undefined;

  return (
    <View style={[styles.container]} testID={`${productTestPrefix}item-${index}`}>
      <Text
        category="p1"
        disabled={selected}
        style={styles.text}
        testID={`${productTestPrefix}name-${index}`}
      >
        {productMix?.tankMix?.name || product?.product?.skuName}
      </Text>
      <HSpacer size="7" />
      <View style={styles.buttonPrice}>
        {!!product && !productMix && (
          <>
            <Text
              category="p1"
              disabled={selected}
              testID={`${productTestPrefix}price-${index}`}
            >
              {`${formattedPrice}/${translate(product?.product?.unitUoM ?? 'UNIT')}`}
            </Text>
            <HSpacer size="5" />
          </>
        )}
        {!!productMix && (
          <>
            <Text
              category="p1"
              disabled={selected}
              testID={`${productTestPrefix}cost-per-acre-${index}`}
            >
              {`${formattedTotalCostPerAcre}/${translate('ACRE')}`}
            </Text>
            <HSpacer size="5" />
          </>
        )}
        {selected ? (
          <Icon
            name="Checkmark"
            status="success"
            testID={`${productTestPrefix}selected-icon-${index}`}
          />
        ) : (
          <>
            {!!onRemovePress && (
              <Button
                accessoryLeft={<Icon name="Trash" testID={`${productTestPrefix}remove-button-icon-${index}`} />}
                appearance="outline"
                onPress={() => onRemovePress(product, productMix)}
                size="tiny"
                testID={`${productTestPrefix}remove-button-${index}`}
              >
                {translate('REMOVE') as string}
              </Button>
            )}
          </>
        )}
      </View>
    </View>
  );
};
