import React, { FunctionComponent } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Text, TextProps, VSpacer } from '@design';

const styles = StyleSheet.create({
  demoContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    minHeight: 'auto',
  },
  demo: {
    margin: 5,
    minWidth: '15%',
    maxWidth: '100%',
    paddingHorizontal: 5,
    paddingVertical: 10,
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'auto',
  },
});

interface ContainerProps extends ViewProps {
  column?: boolean,
  label?: string,
}

export const DemoContainer: FunctionComponent<ContainerProps> = ({
  children,
  column,
  label,
  style,
  ...viewProps
}) => (
  <>
    {label && <Text category="h4">{label}</Text>}
    <View style={[!column && styles.demoContainer, style]} {...viewProps}>
      {children}
    </View>
    <VSpacer size="8" />
  </>
);

interface BlockProps extends ViewProps {
  label?: string
  labelProps?: TextProps
}

export const DemoBlock: FunctionComponent<BlockProps> = ({
  label,
  labelProps = {},
  children,
  style,
}) => (
  <View
    style={[styles.demo, style]}
  >
    {children}
    {label && <Text category="c1" {...labelProps}>{label}</Text>}
  </View>
);
