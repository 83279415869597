import { DataPoint, ListModal, Text, ViewRow, VSpacer } from '@design';
import { ProductCategory } from '@shared/enums';
import { ApiBusinessLocation, ApiProduct } from '@shared/interfaces/api';
import { toShortDate } from '@shared/utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { StringUtility } from '../../../utilities';

const styles = StyleSheet.create({
  itemDetail: {
    width: '25%',
    maxWidth: '25%',
  },
  viewRow: {
    alignSelf: 'stretch',
    flex: 1,
  },
  dataPoint: {
    flex: 1,
  },
});

interface ProductListTabRowDetailProps {
  product: ApiProduct,
  locations: ApiBusinessLocation[]
}

export const ProductListTabRowDetail: FC<ProductListTabRowDetailProps> = ({
  product,
  locations,
}: ProductListTabRowDetailProps) => {
  const [translate] = useTranslation(['products', 'common']);

  return (
    <>
      <ViewRow style={styles.viewRow}>
        { product.category === ProductCategory.SEED && (
          <>
            <DataPoint
              label={`${translate('SEEDS_PER_UNIT')}`}
              style={styles.itemDetail}
              testID="seeds-per-unit"
            >
              {product.seedProduct.seedsPerUnit}
            </DataPoint>
            <DataPoint
              label={`${translate('CROP')}`}
              style={styles.itemDetail}
              testID="crop"
            >
              {`${translate(product.seedProduct.cropType)} | ${translate(product.seedProduct.cropSubType)}`}
            </DataPoint>
            <DataPoint
              label={`${translate('BUSINESS_PRODUCT_SUB_CATEGORY')}`}
              style={styles.itemDetail}
              testID="seed-product-subcategory"
            >
              {product.businessCategory?.name}
            </DataPoint>
            <DataPoint
              label={`${translate('PACKAGE_NAME')}`}
              style={styles.itemDetail}
              testID="seed-package-name"
            >
              {product.packageName}
            </DataPoint>
          </>
        )}
        { (product.category === ProductCategory.FERTILIZER
          || product.category === ProductCategory.CHEMICAL) && (
          <>
            <DataPoint
              label={`${translate('FORMULATION')}`}
              style={styles.itemDetail}
              testID="chemical-fertilizer-formulation"
            >
              {translate<string>(product.chemicalFertilizerProduct.formulation)}
            </DataPoint>
            <DataPoint
              label={`${translate('BUSINESS_PRODUCT_SUB_CATEGORY')}`}
              style={styles.itemDetail}
              testID="chemical-fertilizer-product-subcategory"
            >
              {product.businessCategory?.name ?? ''}
            </DataPoint>
            <DataPoint
              label={`${translate('PACKAGE_NAME')}`}
              style={styles.itemDetail}
              testID="chemical-fertilizer-package-name"
            >
              {product.packageName}
            </DataPoint>
            <DataPoint
              label={`${translate('STATUS')}`}
              style={styles.dataPoint}
              testID="chemical-fertilizer--status"
            >
              {translate(product.isActive ? 'ACTIVE' : 'INACTIVE') as string}
            </DataPoint>
          </>
        )}
        {(product.category === ProductCategory.OTHER) && (
          <>
            <DataPoint
              label={`${translate('BUSINESS_PRODUCT_SUB_CATEGORY')}`}
              style={styles.dataPoint}
              testID="other-product-subcategory"
            >
              {product.businessCategory?.name ?? ''}
            </DataPoint>
            <DataPoint
              label={`${translate('PACKAGE_NAME')}`}
              style={styles.dataPoint}
              testID="other-package-name"
            >
              {product.packageName}
            </DataPoint>
            <DataPoint
              label={`${translate('STATUS')}`}
              style={styles.dataPoint}
              testID="other-status"
            >
              {translate(product.isActive ? 'ACTIVE' : 'INACTIVE') as string}
            </DataPoint>
            <View style={styles.dataPoint} />
          </>
        )}
      </ViewRow>
      <VSpacer size="9" />
      <ViewRow>
        <DataPoint
          label={`${translate('DATE_CREATED')}`}
          style={styles.itemDetail}
          testID="date-created"
        >
          {toShortDate(product.createdAt)}
        </DataPoint>
        <DataPoint
          label={`${translate('LAST_UPDATED')}`}
          style={styles.itemDetail}
          testID="last-updated"
        >
          {toShortDate(product.updatedAt)}
        </DataPoint>
        <ListModal
          items={product.prices.map((price) => {
            const locationName = locations
              .find((location) => location.id === price.businessLocationId)?.locationName;
            return (price.businessLocationId && !!locationName) && (
              <View key={price.id} style={{ flexDirection: 'row', alignItems: 'stretch' }}>
                <Text category="p2">
                  {`${locationName}: `}
                  {StringUtility.formatCurrencyAccounting(price.price)}
                  <Text appearance="hint" category="p2">
                    {` ${toShortDate(price.updatedAt)}`}
                  </Text>
                </Text>
              </View>
            );
          })}
          style={styles.itemDetail}
          testID="location-pricing"
          title={`${translate('LOCATION_PRICING')}`}
        />
        {product.category === ProductCategory.SEED && (
          <DataPoint
            label={`${translate('STATUS')}`}
            style={styles.itemDetail}
            testID="status"
          >
            {translate<string>(product.isActive ? 'ACTIVE' : 'INACTIVE')}
          </DataPoint>
        )}
        {product.category !== ProductCategory.SEED && (
          <DataPoint
            label={`${translate('MANUFACTURER')}`}
            style={styles.itemDetail}
            testID="fertilizer-chemical-other-manufacturer"
          >
            {product.manufacturer}
          </DataPoint>
        )}
      </ViewRow>
      <VSpacer size="9" />
      <ViewRow>
        {product.category === ProductCategory.SEED && (
          <DataPoint
            label={`${translate('MANUFACTURER')}`}
            style={styles.itemDetail}
            testID="seed-manufacturer"
          >
            {product.manufacturer}
          </DataPoint>
        )}
        {product.category === ProductCategory.FERTILIZER 
          && !!product?.chemicalFertilizerProduct.lbsPerGal && (
          <DataPoint
            label={`${translate('LIQ_DENSITY')}`}
            style={styles.itemDetail}
            testID="chemical-fertilizer-lbsPerGal"
          >
            {product.chemicalFertilizerProduct.lbsPerGal}
          </DataPoint>
        )}
        <DataPoint
          label={`${translate('PRODUCT_ID')}`}
          style={styles.itemDetail}
          testID="external-product-id"
        >
          {product.externalId}
        </DataPoint>
      </ViewRow>
    </>
  );
};
