import { ApiProductOrderListResponse } from '@shared/interfaces/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BannerProps, Button, Dialog, HSpacer, Text, useBanner, useToast, VSpacer } from '@design';
import { StyleSheet, View } from 'react-native';
import { testId } from '../../../../utilities';
import { ProductOrderApi } from '../../../../utilities/api/ProductOrderApi';

const styles = StyleSheet.create({
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
  title: {
    width: 348,
  },
});

export interface DeleteProductOrderDialogProps {
  productOrder: ApiProductOrderListResponse | null,
  onCancelPress(): void,
  onDeletePress(): void,
}
export function DeleteProductOrderDialog ({
  productOrder,
  onCancelPress,
  onDeletePress,
}: DeleteProductOrderDialogProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const { createToast } = useToast();

  const { createBanner } = useBanner();

  const bannerProps = useCallback((bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={styles.banner}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) }), [translate]);

  const handleDeletePress = useCallback(async () => {
    try {
      await ProductOrderApi.deleteProductOrder(productOrder.id);
      createToast({
        status: 'success',
        children: translate<string>('PRODUCT_ORDER_DELETED'),
        testID: 'toast-content-element',
      });

      onDeletePress();
    } catch (e) {
      createBanner(bannerProps(translate('ERROR_DELETE_PRODUCT_ORDER')));
    }
  }, [bannerProps, createBanner, createToast, onDeletePress, productOrder, translate]);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...testId('delete-product-order-modal-cancel')}
            {...primaryButtonProp}
            appearance="outline"
            status="basic"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...testId('delete-product-order-modal-delete')}
            {...secondaryButtonProp}
            onPress={handleDeletePress}
          >
            {translate<string>('CONFIRM_DELETE_PRODUCT_ORDER')}
          </Button>
        </>
      )}
      onClose={onCancelPress}
      testID="delete-product-order-modal"
      visible={!!productOrder}
    >
      <Text
        category="h5"
        status="basic"
        style={styles.title}
      >
        {translate<string>(
          'CONFIRM_DELETE_PRODUCT_ORDER_DIALOG_TITLE',
          { title: productOrder?.name ?? '' },
        )}
      </Text>
      <VSpacer size="7" />
      <Text status="basic">
        {translate<string>('CONFIRM_DELETE_PRODUCT_ORDER_DIALOG_WARNING')}
      </Text>
    </Dialog>
  );
}
