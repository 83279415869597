import { Card } from '@ui-kitten/components';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Text } from '@design';
import { Statuses } from '@theme/variant-interfaces/Status';
import { Categories } from '@theme/variant-interfaces/Category';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const TextDemo: FunctionComponent = () => (
  <View style={{ alignSelf: 'stretch' }}>
    <Card testID="test-card">
      <DemoContainer label="category">
        <DemoBlock label="Default">
          <Text>Grumpy wizards make a toxic brew for the jovial queen.</Text>
        </DemoBlock>
        {Categories.map((c) => (
          <DemoBlock key={c} label={c}>
            <Text category={c}>Grumpy wizards make a toxic brew for the jovial queen.</Text>
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <Card testID="test-card">
      <DemoContainer label="status">
        <DemoBlock label="Default">
          <Text>Grumpy wizards make a toxic brew for the jovial queen.</Text>
        </DemoBlock>
        {Statuses.map((s) => (
          <DemoBlock key={s} label={s}>
            <Text status={s}>Grumpy wizards make a toxic brew for the jovial queen.</Text>
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
  </View>
);
