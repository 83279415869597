import { ComboBox } from '@design';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../../hooks/useBusinessLocationList';
import { PopperPlacementType } from '@mui/material';

export interface BusinessLocationSelectProps {
  locationId: string | null,
  onUpdateLocation(locationId: string | null): void,
  optionWindowMaxHeight?: number,
  optionWindowPlacement?: PopperPlacementType,
  readonly?: boolean,
  testID?: string,
}

export const BusinessLocationSelect = ({
  locationId = null,
  onUpdateLocation,
  optionWindowMaxHeight,
  optionWindowPlacement,
  readonly,
  testID = 'business-locations-selector',
}: BusinessLocationSelectProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const { currentBusinessId } = useAuthentication();
  const { businessLocations, isLoading } = useBusinessLocationList(currentBusinessId);

  const locations = useMemo(() => [
    {
      id: null,
      locationName: translate('CORPORATE_LOCATION'),
    },
    ...businessLocations,
  ], [businessLocations, translate]);

  const businessLocation = locations.find((location) => location.id === locationId);

  return (
    <ComboBox
      disabled={isLoading}
      getOptionLabel={(location) => location?.locationName}
      id='business-location-ComboBox'
      isRequired
      onChangeValue={(location) => onUpdateLocation(location?.id ?? null)}
      optionWindowMaxHeight={optionWindowMaxHeight}
      optionWindowPlacement={optionWindowPlacement}
      options={locations}
      readOnly={readonly}
      testID={testID}
      topLabel={translate<string>('BUSINESS_LOCATION')}
      value={businessLocation}
    />
  );
};
