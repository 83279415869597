/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Button,
  DataPoint,
  FilterCategory,
  FilterTable,
  Icon,
  MenuItem,
  ModalSpinner,
  StickyHeaderPage,
  OverflowMenu,
  Text,
  ViewRow,
  VSpacer,
} from '@design';
import { SharedConfig } from '@shared/constants';
import {
  ApiUserAccount,
  ApiUserAccountQuerySort,
  UserAccountEndpoint,
} from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { UnexpectedError } from '../../../../ui-components/utils/UnexpectedError';
import { StringUtility } from '../../../../utilities';
import { UserApi } from '../../../../utilities/api';
import { testId } from '../../../../utilities/testId';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { ChangeUserStatusDialog } from '../../User/ChangeUserStatusDialog';
import { DeleteUserDialog } from '../../User/DeleteUserDialog';

const columnCategories: FilterCategory[] = [{
  columnLabel: 'Name',
  columnKey: 'firstName',
  columns: [],
},
{
  columnLabel: 'Email',
  columnKey: 'email',
  columns: [],
},
{
  columnLabel: 'User type',
  columnKey: 'userType',
  columns: [],
},
{
  columnLabel: 'User role',
  columnKey: 'userRole',
  columns: [],
},
{
  columnLabel: 'Phone',
  columnKey: 'telephone',
  columns: [],
},
];

const sortColDictionary = {
  'Email': ApiUserAccountQuerySort.email,
  'Name': ApiUserAccountQuerySort.firstName,
  'User Role': ApiUserAccountQuerySort.userRole,
  'User Type': ApiUserAccountQuerySort.userType,
};

export const FilterTableDemo: FC = () => {
  const { t } = useTranslation(['users', 'common']);
  const { user: authUser } = useAuthentication();
  const [scrollRef, setScrollRef] = useState<React.MutableRefObject<ScrollView>>();
  const { setModalProps } = useAppContext();

  const canEditUser = RoleUtility.roleHasPermission(
    authUser?.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<(ApiUserAccount & RowMeta)[]>([]);
  const [expandAll] = useState<boolean>(false);
  const [changeStatusUser, setChangeStatusUser] = useState<ApiUserAccount | null>();
  const [deleteUser, setDeleteUser] = useState<ApiUserAccount | null>();
  const [results, setResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // we combine all page options in one state to make sure not be called many times
  // when update (filter and page) or (sort column and direction) for example
  const [pageOptions, setPageOptions] = useState<UserAccountEndpoint.List.Request>({
    page: 0,
    sort: ApiUserAccountQuerySort.updatedAt,
    sortDesc: true,
  });

  // TODO: we should find better way for the filter,
  //  we should avoid update columnCategories like this 'category.columns = ...'
  useEffect(() => {
    UserApi.getUsers({ page: 0, limit: SharedConfig.PAGE_LIMIT })
      .then(({ data }) => {
        if (data === null) return;
        columnCategories.forEach((category) => {
          const key = category.columnKey;
          const uniqueKeys = {};

          if (category.columns.length === 0) {
            category.columns = data.filter(
              (result) => {
                const isUnique = !uniqueKeys[result[key]];
                uniqueKeys[result[key]] = true;
                return isUnique;
              },
            ).map((result) => ({
              label: t(result[key]),
              id: result[key],
            }));
          }
        });
      });
  }, [t]);

  const columns: IColumn<(ApiUserAccount & RowMeta)>[] = [
    {
      columnId: 'Name',
      header: {
        render: t('NAME'),
        sortable: true,
      },
      render: (data) => data.fullName,
      flex: 3,
    },
    {
      columnId: 'phone',
      header: {
        render: t('PHONE'),
      },
      render: (data) => data.telephone,
      flex: 2,
    },
    {
      columnId: 'Email',
      header: {
        render: t('EMAIL'),
        sortable: true,
      },
      render: (data) => data.email,
      flex: 3,
    },
    {
      columnId: 'User Type',
      header: {
        render: t('USER_TYPE'),
        sortable: true,
      },
      render: (data) => StringUtility.toProperCase(data.userType),
      flex: 2,
    },
    {
      columnId: 'User Role',
      header: {
        render: t('USER_ROLE'),
        sortable: true,
      },
      render: (data) => StringUtility.toProperCase(data.userRole),
      flex: 2,
    },
    {
      columnId: 'menu',
      header: {
        render: '',
      },
      render: (data) => (
        <OverflowMenu
          placement="bottom end"
          testID="options"
        >
          {data.isActive && canEditUser && (
            <MenuItem
              onPress={() => setModalProps({
                type: 'userFormModal',
                userId: data.id,
                actionType: 'EDIT_USER',
              })}
              testID="option-edit"
              title={t('EDIT') as string}
            />
          )}
          {canEditUser && authUser.id !== data.id && (
            <MenuItem
              onPress={() => setChangeStatusUser(data)}
              testID={data.isActive ? 'option-deactivate' : 'option-activate'}
              title={(data.isActive ? t('DEACTIVATE') : t('ACTIVATE')) as string}
            />
          )}
          {(
            (
              RoleUtility.roleHasPermission(
                authUser.userRole,
                Permissions.DELETE_USERS,
              ) && authUser.id !== data.id
            ) && (
              <MenuItem
                onPress={() => setDeleteUser(data)}
                testID="option-delete"
                title={t('DELETE') as string}
              />
            )
          )}
        </OverflowMenu>
      ),
      width: 72, // 40 for icon, 32 for padding of the cell
    },
  ];

  const getUsers = useCallback(() => {
    // TODO: we should use cancel token to stop the previous request in case the call still working
    setLoading(true);

    UserApi.getUsers(pageOptions).then((result) => {
      setTotalPages(result.lastPage + 1);
      setResults(result.total);
      setUsers(result.data.map((u, index) => ({
        ...u,
        hasDetails: true,
        // row index here just to match ids on old page.
        rowId: `row:${index}|${u.id}|`,
        // row detail id here is to match old page
        rowDetailId: `row-drawer:${index}|${u.id}|`,
      })));
      if (scrollRef && scrollRef.current) {
        // TODO: scrollTo not working in current react-native-web version
        scrollRef.current.scrollTo({});
      }
    }).catch((err) => {
      UnexpectedError(err, 'Unexpected error loading user list.');
    }).finally(() => {
      setLoading(false);
    });
  }, [pageOptions, scrollRef]);

  useEffect(() => {
    getUsers();
  }, [getUsers, pageOptions]);

  return (
    <StickyHeaderPage
      scrollViewProps={{ stickyHeaderIndices: [0] }}
      setScrollRef={setScrollRef}
    >
      <FilterTable
        accessoryRight={canEditUser && (
        <Button
          accessoryLeft={(iconProps) => (
            <Icon name="Plus" testID="create-user-button-icon" {...iconProps} />
          )}
          onPress={() => setModalProps({
            type: 'userFormModal',
            actionType: 'CREATE_USER',
          })}
          size="large"
          {...testId('create-user-button')}
        >
          {t('CREATE_USER') as string}
        </Button>
        )}
        columns={columns}
        currentPage={pageOptions.page + 1}
        data={users}
        defaultColumnFilters={['firstName', 'email', 'userRole']}
        expandAll={expandAll}
        filterOptions={columnCategories}
        noFilters={false}
        onPageChange={(page: number) => {
          setPageOptions({ ...pageOptions, page: page - 1 });
        }}
        onSort={(column, direction) => {
          // this is to maintain the testId from the old page
          // idealy, your columnId, sortColumnName, and testId should all match.
          const name = sortColDictionary[column];
          setPageOptions({ ...pageOptions, sort: name, sortDesc: direction === 'DESC' });
        }}
        onUpdateFilter={(filter) => {
          setPageOptions({ page: 0, ...filter });
        }}
        rowDetail={(rowData) => (
          <>
            <ViewRow style={{ alignSelf: 'stretch' }}>
              <DataPoint
                label={t('LOCATION_ASSIGNMENT') as string}
                style={styles.dataPoint}
                testID="location-assignment"
              >
                {(textProps) => {
                  if (!rowData.businessLocations?.length) {
                    return <Text {...textProps}>{t('N/A') as string}</Text>;
                  }

                  return (
                    <>
                      {rowData.businessLocations?.map((location) => (
                        <Text key={location.id} {...textProps}>{location.locationName}</Text>
                      ))}
                    </>
                  );
                }}
              </DataPoint>
              <DataPoint
                label={t('BUSINESS_ASSIGNMENT') as string}
                style={styles.dataPoint}
                testID="business-assignment"
              >
                {rowData.business?.businessName}
              </DataPoint>
              <DataPoint
                label={t('EMAIL') as string}
                style={styles.dataPoint}
                testID="email"
              >
                {rowData.email}
              </DataPoint>
            </ViewRow>
            <VSpacer size="8" />
            <ViewRow style={{ alignSelf: 'stretch' }}>
              <DataPoint
                label={t('PHONE') as string}
                style={styles.dataPoint}
                testID="phone"
              >
                {rowData.telephone || t('N/A') as string}
              </DataPoint>
              <DataPoint
                label={t('USER_CREATED') as string}
                style={styles.dataPoint}
                testID="user-created"
              >
                {new Date(rowData.createdAt).toLocaleString()}
              </DataPoint>
              <DataPoint
                label={t('STATUS') as string}
                style={styles.dataPoint}
                testID="status"
              >
                {(rowData.isActive ? t('ACTIVE') : t('INACTIVE')) as string}
              </DataPoint>
            </ViewRow>
          </>
        )}
        tableId="userListView"
        testID="user-list-table"
        totalPages={totalPages}
        totalResults={results}
      />
      <ChangeUserStatusDialog
        onCancel={() => setChangeStatusUser(null)}
        onUserUpdated={(updatedUser) => {
          setChangeStatusUser(null);
          setUsers((prevUsers) => prevUsers.map((prevUser) => {
            if (prevUser.id !== updatedUser.id) {
              return prevUser;
            }
            return {
              ...prevUser,
              ...updatedUser,
            };
          }));
        }}
        user={changeStatusUser}
      />
      <DeleteUserDialog
        onCancel={() => setDeleteUser(null)}
        onUserDeleted={() => {
          setDeleteUser(null);
          getUsers();
        }}
        user={deleteUser}
      />
      <ModalSpinner visible={loading} />
    </StickyHeaderPage>
  );
};

const styles = StyleSheet.create({
  dataPoint: {
    width: '33%',
  },
  card: {
    flex: 1,
    marginHorizontal: 36,
    marginBottom: 19,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});
