import {
  CropSubType,
  CropType,
  FormulationType,
  ProductCategory,
} from '@shared/enums';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useQuery } from 'react-query';
import { Button, useBanner, BannerProps, Filter, FilterCategory } from '@design';
import { QueryKeys } from '../../../../../constants';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { BusinessApi, GrowerApi, TankMixApi } from '../../../../../utilities/api';
import { GrowerEndpoint } from '@shared/interfaces/api';
import { useCustomerTotals } from '../../../../../hooks/useCustomerTotals';
import { maxListSize } from '@design/FilterMenu/FilterMenu';

const styles = StyleSheet.create({
  marginTopOverride: {
    // This is for getting 48 pixels between the subtitle and the search box
    marginTop: -28,
  },
});

export type ProductFilters = {
  category: ProductCategory;
  cropSubtypes: CropSubType[];
  crops: CropType[];
  customers?: string[];
  formulations: FormulationType[];
  manufacturers: string[];
  search: string;
  subcategories: string[];
};

type QueryFilters = {
  category: ProductCategory[];
  crop: string[];
  customer: string[];
  formulation: FormulationType[];
  manufacturer: string[];
  search: string;
  subcategory: string[];
};

interface ProductFiltererProps {
  customerId?: string;
  onFiltersChanged: (filters: ProductFilters) => void;
}

export const ProductFilterer: FC<ProductFiltererProps> = ({
  customerId,
  onFiltersChanged,
}) => {
  const [translate] = useTranslation(['common', 'business', 'products', 'errors']);
  const { currentBusinessId: businessId } = useAuthentication();
  const [defaultColumnFilters, setDefaultColumnFilters] = useState(['category']);
  const [filterOptions, setFilterOptions] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allCrops, setAllCrops] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [allManufacturers, setAllManufacturers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [shouldPresetCustomer, setShouldPresetCustomer] = useState(false);
  const [customerSearch, setCustomerSearch] = useState('');

  const bannerProps = useCallback((bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate('DISMISS') as string}
        </Button>
      </View>
    ) }), [translate]);

  const category = {
    columnLabel: translate('CATEGORY'),
    columnKey: 'category',
    singleSelection: true,
    preserve: true,
    columns: [
      { label: translate(ProductCategory.CHEMICAL as string), id: ProductCategory.CHEMICAL },
      { label: translate(ProductCategory.FERTILIZER as string), id: ProductCategory.FERTILIZER },
      { label: translate(ProductCategory.SEED as string), id: ProductCategory.SEED },
      { label: translate(ProductCategory.PRODUCT_MIX), id: ProductCategory.PRODUCT_MIX },
      { label: translate('OTHER_PRODUCTS'), id: ProductCategory.OTHER },
    ].filter((c) => !!c),
  };

  const crop = {
    columnLabel: translate('CROP'),
    columnKey: 'crop',
    columns: [],
  };

  const formulation = {
    columnLabel: translate('FORMULATION'),
    columnKey: 'formulation',
    columns: [
      { label: translate(FormulationType.DRY as string), id: FormulationType.DRY },
      { label: translate(FormulationType.LIQUID as string), id: FormulationType.LIQUID },
    ],
  };

  const subcategory = {
    columnLabel: translate('SUBCATEGORY'),
    columnKey: 'subcategory',
    columns: [],
  };

  const manufacturer = {
    columnLabel: translate('MANUFACTURER'),
    columnKey: 'manufacturer',
    columns: [],
  };

  const customer = {
    columnLabel: translate('CUSTOMER'),
    columnKey: 'customer',
    columns: [],
  };

  const { createBanner } = useBanner();
  const noCategoryFilters = [category.columnKey];
  const seedFilters = [
    category.columnKey,
    subcategory.columnKey,
    crop.columnKey,
    manufacturer.columnKey,
  ];
  const chemicalFilters = [
    category.columnKey,
    subcategory.columnKey,
    formulation.columnKey,
    manufacturer.columnKey,
  ];
  const fertilizerFilters = [
    category.columnKey,
    subcategory.columnKey,
    formulation.columnKey,
    manufacturer.columnKey,
  ];
  const otherFilters = [
    category.columnKey,
    subcategory.columnKey,
    manufacturer.columnKey,
  ];
  const productMixFilters = [
    category.columnKey,
    subcategory.columnKey,
    ...[customer.columnKey],
    crop.columnKey,
  ];

  const defaultFilters = [
    category,
    crop,
    ...[customer],
    manufacturer,
    subcategory,
  ];

  const { customerTotals, isSuccess } = useCustomerTotals({
    onError: () => {
      createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_FILTERS')));
      setFilterOptions(new Map());
    },
  });

  const updateFilters = async () => {
    const crops = await BusinessApi.getBusinessCrops(businessId);
    const categories = await BusinessApi.getBusinessProductCategories(businessId);
    const manufacturers = await BusinessApi.getBusinessManufacturers();
    const customers = (await GrowerApi.getGrowers({
      businessId,
      limit: maxListSize,
      search: customerSearch,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }));
    return { crops, categories, manufacturers, customers };
  };

  useQuery([
    QueryKeys.PRODUCT_FILTERS,
    crop,
    category,
    manufacturer,
    subcategory,
    formulation,
  ], updateFilters, {
    enabled: isSuccess,
    onError: () => {
      createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_FILTERS')));
      setFilterOptions(new Map());
    },
    onSuccess: ({ crops, categories, manufacturers, customers }) => {
      setAllCategories(categories);
      setAllCrops(crops);
      setAllManufacturers(manufacturers);
      setAllCustomers(customers.data);
      const newFilterOptions = new Map();
      defaultFilters.forEach((val) => {
        newFilterOptions.set(val.columnKey, val);
      });
      setFilterOptions(newFilterOptions);
    },
  });

  const onQueryChanged = async (filter: QueryFilters) => {
    const categoryValue = filter.category && filter.category.length > 0
      ? filter.category[0] : null;

    const updateFilterOptions: FilterCategory[] = [
      category,
    ];
    const defaultFiltersMap: Record<string, any> = {
      [ProductCategory.SEED]: seedFilters,
      [ProductCategory.FERTILIZER]: fertilizerFilters,
      [ProductCategory.CHEMICAL]: chemicalFilters,
      [ProductCategory.OTHER]: otherFilters,
      [ProductCategory.PRODUCT_MIX]: productMixFilters,
    };
    if (categoryValue) {
      setDefaultColumnFilters(defaultFiltersMap[categoryValue]);
    } else {
      setDefaultColumnFilters(noCategoryFilters);
    }

    updateFilterOptions.push({ ...subcategory,
      columns: (allCategories
        .find((x) => x.category === categoryValue)?.subCategories || [])
        .map((x) => ({ label: x.name, id: x.id })) });

    switch (categoryValue) {
      case ProductCategory.SEED:
        updateFilterOptions.push({ ...crop,
          columns: allCrops.map((x) => ({
            label: `${translate(x.cropType)} | ${translate(x.subType)}`,
            id: `${x.cropType},${x.subType}`,
          })),
        });
        break;
      case ProductCategory.FERTILIZER:
      case ProductCategory.CHEMICAL:
        updateFilterOptions.push(formulation);
        break;
      case ProductCategory.PRODUCT_MIX:
        updateFilterOptions.push({ ...crop,
          columns: allCrops.map((x) => ({
            label: `${translate(x.cropType)} | ${translate(x.subType)}`,
            id: `${x.cropType},${x.subType}`,
          })),
        });
        updateFilterOptions.push({
          ...customer,
          columns: allCustomers.map((x) => ({ label: x.legalName, id: x.id })),
          total: customerTotals,
          onSearch: setCustomerSearch,
        });
        break;
    }

    updateFilterOptions.push({
      ...manufacturer,
      columns: allManufacturers.map((x) => ({ label: x.manufacturer, id: x.manufacturer })),
    });
    let filters: ProductFilters;

    if (filter.category?.[0] !== selectedCategory) {
      setSelectedCategory(categoryValue);
      const newFilterOptions = new Map();
      updateFilterOptions.forEach((val) => {
        newFilterOptions.set(val.columnKey, val);
      });
      setFilterOptions(newFilterOptions);

      let doesCustomerHaveProductMixes = false;
      if (
        filter.category?.[0] === ProductCategory.PRODUCT_MIX
        && customerId
      ) {
        const customerMixes = await TankMixApi.listTankMix({
          assignedGrowers: [customerId],
          businessId,
          limit: 1,
        });
        doesCustomerHaveProductMixes = !!customerMixes.data.length;
      }
      setShouldPresetCustomer(doesCustomerHaveProductMixes);
      const customerFilter = doesCustomerHaveProductMixes ? [customerId] : [];
      filters = {
        search: filter.search || '',
        category: filter.category?.[0],
        crops: [],
        cropSubtypes: [],
        ...{ customers: customerFilter },
        formulations: [],
        subcategories: [],
        manufacturers: [],
      };
    } else {
      filters = {
        search: filter.search || '',
        category: categoryValue,
        crops: (filter.crop || []).map((filterCrop) => filterCrop.split(',').shift()) as CropType[],
        cropSubtypes: (filter.crop || []).map((filterCrop) => filterCrop.split(',').pop()) as CropSubType[],
        ...{ customers: filter.customer },
        customers: filter.customer || [],
        formulations: filter.formulation || [],
        subcategories: filter.subcategory || [],
        manufacturers: filter.manufacturer || [],
      };
    }

    onFiltersChanged(filters);
  };

  const isProductMix = selectedCategory === ProductCategory.PRODUCT_MIX;
  const defaultFilterValues = useMemo(() => {
    return (isProductMix && shouldPresetCustomer)
      ? new Map([['customer', { [customerId]: true }]])
      : undefined;
  }, [isProductMix, shouldPresetCustomer, customerId]);


  return (
    <>
      {filterOptions && (
      <View style={styles.marginTopOverride}>
        <Filter
          autoClearSelections
          defaultColumnFilters={defaultColumnFilters}
          defaultFilters={defaultFilterValues}
          filterOptions={filterOptions}
          key={1}
          minFiltersToShowMore={2}
          noFilters={false}
          onUpdateFilter={onQueryChanged}
          showMoreFiltersButton={!!selectedCategory}
          testID="select-products-filter"
        />
      </View>
      )}
    </>
  );
};
