import React, { useState } from 'react';
import { Dimensions, View } from 'react-native';
import {
  Button,
  CalendarViewModes, Divider,
  Icon,
  Layout,
  NativeDateService,
  Popover,
  Text,
  withStyles,
} from '@ui-kitten/components';
import { CalendarPicker,
  CalendarPickerElement } from '@ui-kitten/components/ui/calendar/components/picker/calendarPicker.component';
import { DeviceType } from 'expo-device';
import { CalendarDateInfo } from '@ui-kitten/components/ui/calendar/type';
import { CalendarDataService, DateBatch } from '@ui-kitten/components/ui/calendar/service/calendarData.service';
import { CalendarPickerCellProps } from '@ui-kitten/components/ui/calendar/components/picker/calendarPickerCell.component';
import { CalendarDateContent,
  CalendarDateContentElement } from '@ui-kitten/components/ui/calendar/components/calendarDateContent.component';
import { CalendarMonthHeader } from '@ui-kitten/components/ui/calendar/components/calendarMonthHeader.component';
import { StyleType } from '@ui-kitten/components/theme';
import { CalendarHeaderElement } from '@ui-kitten/components/ui/calendar/components/calendarHeader.component';
import { FalsyText, TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import { styles } from './DatePickerStyle';

const PICKER_ROWS: number = 4;
const PICKER_COLUMNS: number = 3;
const VIEWS_IN_PICKER: number = PICKER_ROWS * PICKER_COLUMNS;

const FORMAT_DAY: string = 'D';
const FORMAT_MONTH: string = 'MMM';
const FORMAT_YEAR: string = 'YYYY';

const min: Date = new Date('1970-01-01');
const max: Date = new Date('2970-01-01');

type D = Date;

interface CustomDateInputProps {
  deviceType: number,
  value: any,
  onChange: (val) => void,
  eva: any,
}

const CustomDateInput = ({ deviceType, eva, value, onChange }: CustomDateInputProps) => {
  const dateService = new NativeDateService();
  const dataService: CalendarDataService<D> = new CalendarDataService(dateService);

  const [isDatePanelActive, setIsDatePanelActive] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState(CalendarViewModes.DATE);
  const [visibleDate, setVisibleDate] = useState(dateService.getMonthStart(value));
  const [selectedPicker, setSelectedPicker] = useState<'date' | 'year' | 'month'>('date');

  const customOnFocus = () => {
    setIsDatePanelActive(true);
  };

  const customOnBlur = () => {
    setIsDatePanelActive(false);
  };

  const customOnChange = (text) => {
    onChange(text);
  };

  const onDateSelect = (date: D): void => {
    customOnChange(date);
    customOnBlur();
  };

  const createDates = (date: D): DateBatch<D> => dataService.createDayPickerData(date);

  const onDaySelect = ({ date }: CalendarDateInfo<D>): void => {
    onDateSelect(date);
  };

  const onMonthSelect = ({ date }: CalendarDateInfo<D>): void => {
    const nextVisibleDate: D = dateService.createDate(
      dateService.getYear(visibleDate),
      dateService.getMonth(date),
      dateService.getDate(visibleDate),
    );

    setViewMode(viewMode.pickNext());
    setVisibleDate(nextVisibleDate);
    customOnChange(nextVisibleDate);
    setSelectedPicker('date');
  };

  const onYearSelect = ({ date }: CalendarDateInfo<D>): void => {
    const nextVisibleDate: D = dateService.createDate(
      dateService.getYear(date),
      dateService.getMonth(visibleDate),
      dateService.getDate(visibleDate),
    );

    setViewMode(viewMode.pickNext());
    setVisibleDate(nextVisibleDate);
    customOnChange(nextVisibleDate);
    setSelectedPicker('date');
  };

  const isDateSelected = (date: D): boolean => dateService.isSameDaySafe(date, value);

  const isDaySelected = ({ date }: CalendarDateInfo<D>): boolean => isDateSelected(date);

  const isDayDisabled = ({ date }: CalendarDateInfo<D>): boolean => {
    const minDayStart: D = dateService.createDate(
      dateService.getYear(min),
      dateService.getMonth(min),
      dateService.getDate(min),
    );

    const maxDayStart: D = dateService.createDate(
      dateService.getYear(max),
      dateService.getMonth(max),
      dateService.getDate(max),
    );

    const fitsFilter: boolean = false;

    return !dateService.isBetweenIncludingSafe(date, minDayStart, maxDayStart) || fitsFilter;
  };

  const isDayToday = ({ date }: CalendarDateInfo<D>):
  boolean => dateService.isSameDaySafe(date, dateService.today());

  const shouldUpdateDate = (props:
  CalendarPickerCellProps<D>, nextProps: CalendarPickerCellProps<D>): boolean => {
    const dateChanged:
    boolean = dateService.compareDatesSafe(props.date.date, nextProps.date.date) !== 0;

    if (dateChanged) {
      return true;
    }

    const selectionChanged: boolean = props.selected !== nextProps.selected;
    const disablingChanged: boolean = props.disabled !== nextProps.disabled;

    const isSelectedChange: boolean = selectionChanged || disablingChanged;

    if (isSelectedChange) {
      return true;
    }

    return props.eva.theme !== nextProps.eva.theme;
  };

  const renderDayElement = ({ date }: CalendarDateInfo<D>, evaStyle):
  CalendarDateContentElement => (
    <CalendarDateContent
      style={evaStyle.container}
      textStyle={[
        evaStyle.text,
      ]}
    >
      {dateService.format(date, FORMAT_DAY)}
    </CalendarDateContent>
  );

  const renderDayIfNeeded = (item: CalendarDateInfo<D>, style: StyleType):
  CalendarDateContentElement => {
    const shouldRender: boolean = !item.bounding || false;

    if (shouldRender) {
      return renderDayElement(item, style);
    }

    return null;
  };

  const getWeekdayStyle = (source: StyleType): StyleType => ({
    fontSize: source.weekdayTextFontSize,
    fontWeight: source.weekdayTextFontWeight,
    color: source.weekdayTextColor,
    fontFamily: source.weekdayTextFontFamily,
  });

  const renderWeekdayElement = (weekday: string, index: number): CalendarDateContentElement => (
    <CalendarDateContent
      key={index}
      textStyle={[
        getWeekdayStyle(eva.style),
        eva.style.weekDayHeaderText,
        deviceType === DeviceType.DESKTOP && { fontWeight: 600 },
      ]}
    >
      {weekday}
    </CalendarDateContent>
  );

  const createViewModeVisibleDate = (page: number, calendatViewMode: any): D => {
    switch (calendatViewMode.id) {
      case CalendarViewModes.DATE.id: {
        return dateService.addMonth(visibleDate, page);
      }
      case CalendarViewModes.MONTH.id: {
        return dateService.addYear(visibleDate, page);
      }
      case CalendarViewModes.YEAR.id: {
        return dateService.addYear(visibleDate, VIEWS_IN_PICKER * page);
      }
      default: {
        break;
      }
    }
    return visibleDate;
  };

  const onHeaderMonthNavigationLeftPress = (): void => {
    setVisibleDate(createViewModeVisibleDate(-1, CalendarViewModes.DATE));
  };

  const onHeaderMonthNavigationRightPress = (): void => {
    setVisibleDate(createViewModeVisibleDate(1, CalendarViewModes.DATE));
  };

  const onHeaderYearNavigationLeftPress = (): void => {
    setVisibleDate(createViewModeVisibleDate(-1, CalendarViewModes.MONTH));
  };

  const onHeaderYearNavigationRightPress = (): void => {
    setVisibleDate(createViewModeVisibleDate(1, CalendarViewModes.MONTH));
  };

  const onYearPickerNavigationLeftPress = (): void => {
    setVisibleDate(createViewModeVisibleDate(-1, CalendarViewModes.YEAR));
  };

  const onYearPickerNavigationRightPress = (): void => {
    setVisibleDate(createViewModeVisibleDate(1, CalendarViewModes.YEAR));
  };

  const cancelSelection = () => {
    setSelectedPicker('date');
  };

  const isYearSelected = ({ date }: CalendarDateInfo<D>):
  boolean => dateService.isSameYearSafe(date, value);

  const isYearDisabled = ({ date }: CalendarDateInfo<D>): boolean => {
    const minYearStart: D = dateService.getYearStart(min);
    const maxYearStart: D = dateService.getYearEnd(max);

    return !dateService.isBetweenIncludingSafe(date, minYearStart, maxYearStart);
  };

  const isYearToday = ({ date }: CalendarDateInfo<D>):
  boolean => dateService.isSameYearSafe(date, dateService.today());

  const renderYearElement = ({ date }: CalendarDateInfo<D>, evaStyle):
  CalendarDateContentElement => (
    <CalendarDateContent
      style={[evaStyle.container]}
      textStyle={evaStyle.text}
    >
      {dateService.format(date, FORMAT_YEAR)}
    </CalendarDateContent>
  );

  const renderYearPickerElement = (date: D): CalendarPickerElement<D> => (
    <View style={styles.yearPickerElementOuterContainer}>
      <View style={styles.yearPickerElementInnerContainer}>
        <Button
          accessoryLeft={() => (
            <Icon
              fill="#8F9BB3"
              name="chevron-left-outline"
              style={[styles.yearPickerElementNavigationButton]}
            />
          )}
          appearance="ghost"
          onPress={() => onYearPickerNavigationLeftPress()}
          style={{}}
        />
        <CalendarPicker
          data={dataService.createYearPickerData(date, PICKER_ROWS, PICKER_COLUMNS)}
          isItemDisabled={isYearDisabled}
          isItemSelected={isYearSelected}
          isItemToday={isYearToday}
          onSelect={onYearSelect}
          rowStyle={[
            eva.style.row,
            styles.yearPickerElementRow,
          ]}
          style={styles.yearPickerElement}
        >
          {renderYearElement}
        </CalendarPicker>
        <Button
          accessoryLeft={() => (
            <Icon
              fill="#8F9BB3"
              name="chevron-right-outline"
              style={[styles.yearPickerElementNavigationButton]}
            />
          )}
          appearance="ghost"
          onPress={() => onYearPickerNavigationRightPress()}
          style={{}}
        />
      </View>
      <Divider />
      <Button appearance="ghost" onPress={cancelSelection}>
        CANCEL
      </Button>
    </View>
  );

  const isMonthSelected = ({ date }: CalendarDateInfo<D>):
  boolean => dateService.isSameMonthSafe(date, value);

  const isMonthDisabled = ({ date }: CalendarDateInfo<D>): boolean => {
    const minMonthStart: D = dateService.getMonthStart(min);
    const maxMonthStart: D = dateService.getMonthStart(max);

    return !dateService.isBetweenIncludingSafe(date, minMonthStart, maxMonthStart);
  };

  const isMonthToday = (date: CalendarDateInfo<D>):
  boolean => dateService.isSameMonthSafe(date.date, dateService.today());

  const renderMonthElement = ({ date }: CalendarDateInfo<D>, evaStyle):
  CalendarDateContentElement => (
    <CalendarDateContent
      style={[evaStyle.container]}
      textStyle={[evaStyle.text, evaStyle.monthElement]}
    >
      {dateService.format(date, FORMAT_MONTH)}
    </CalendarDateContent>
  );

  const renderMonthPickerElement = (date: D): CalendarPickerElement<D> => (
    <View
      style={[styles.monthPickerElementContainer]}
    >
      <CalendarPicker
        data={dataService.createMonthPickerData(date, PICKER_ROWS, PICKER_COLUMNS)}
        isItemDisabled={isMonthDisabled}
        isItemSelected={isMonthSelected}
        isItemToday={isMonthToday}
        onSelect={onMonthSelect}
        rowStyle={[eva.style.row, styles.monthPickerElementRow]}
        style={styles.monthPickerElement}
      >
        {renderMonthElement}
      </CalendarPicker>
      <Divider />
      <Button appearance="ghost" onPress={cancelSelection}>
        CANCEL
      </Button>
    </View>
  );

  const renderDatePickerInputElement = () => (
    <TouchableWithoutFeedback
      onPress={customOnFocus}
      style={[eva.style.control, styles.inputContainer]}
    >
      <FalsyText
        component={() => <Text>{value.toString()}</Text>}
        ellipsizeMode="tail"
        numberOfLines={1}
        style={eva.style.text}
      />
    </TouchableWithoutFeedback>
  );

  const renderHeaderElement = () => {
    const title = dateService.format(value, 'ddd, MMM D, YYYY');
    return (
      <View style={[
        styles.row,
        styles.headerElement,
        eva.style.headerElement,
      ]}
      >
        <Text style={[eva.style.headerElementTitle]}>{title}</Text>
      </View>
    );
  };

  const renderMonthAndYearSelectorElement = (): CalendarHeaderElement => {
    const monthTitle = dateService.format(visibleDate, 'MMMM');
    const yearTitle = dateService.format(visibleDate, 'YYYY');
    return (
      <View style={[
        styles.row,
        styles.monthAndYearSelectorElementContainer,
      ]}
      >
        <View style={[styles.monthSelectorElementContainer]}>
          <Button
            accessoryLeft={() => (
              <Icon
                fill="#8F9BB3"
                name="chevron-left-outline"
                style={[styles.monthSelectorElementNavigationButton]}
              />
            )}
            appearance="ghost"
            onPress={onHeaderMonthNavigationLeftPress}
            style={{}}
          />
          <Button appearance="ghost" onPress={() => setSelectedPicker('month')} status="basic">{monthTitle}</Button>
          <Button
            accessoryLeft={() => (
              <Icon
                fill="#8F9BB3"
                name="chevron-right-outline"
                style={[styles.monthSelectorElementNavigationButton]}
              />
            )}
            appearance="ghost"
            onPress={onHeaderMonthNavigationRightPress}
            style={{}}
          />
        </View>
        <View style={[styles.yearSelectorElementContainer]}>
          <Button
            accessoryLeft={() => (
              <Icon
                fill="#8F9BB3"
                name="chevron-left-outline"
                style={[styles.yearSelectorElementNavigationButton]}
              />
            )}
            appearance="ghost"
            onPress={onHeaderYearNavigationLeftPress}
            style={{}}
          />
          <Button appearance="ghost" onPress={() => setSelectedPicker('year')} status="basic">{yearTitle}</Button>
          <Button
            accessoryLeft={() => (
              <Icon
                fill="#8F9BB3"
                name="chevron-right-outline"
                style={[styles.yearSelectorElementNavigationButton]}
              />
            )}
            appearance="ghost"
            onPress={onHeaderYearNavigationRightPress}
            style={{}}
          />
        </View>
      </View>
    );
  };

  const renderDatePickerElement = () => (
    <View style={[styles.datePickerElementContainer]}>
      <CalendarMonthHeader
        data={dateService.getDayOfWeekNames()}
        style={[
          eva.style.daysHeaderContainer,
        ]}
      >
        {renderWeekdayElement}
      </CalendarMonthHeader>
      <CalendarPicker
        data={createDates(visibleDate)}
        isItemDisabled={isDayDisabled}
        isItemSelected={isDaySelected}
        isItemToday={isDayToday}
        onSelect={onDaySelect}
        rowStyle={styles.datePickerElementRow as any}
        shouldItemUpdate={shouldUpdateDate}
        style={styles.datePickerElement}
      >
        {renderDayIfNeeded}
      </CalendarPicker>
    </View>
  );

  return (
    <>
      <Popover
        anchor={renderDatePickerInputElement}
        onBackdropPress={() => customOnBlur()}
        style={eva.style.popover}
        visible={isDatePanelActive}
      >
        <Layout style={[
          styles.datePickerPanelContainer,
          deviceType === DeviceType.PHONE && { width: Dimensions.get('window').width - 10 },
        ]}
        >
          <View style={styles.datePickerPanelHeaderContainer}>
            {renderHeaderElement()}
          </View>
          <View style={styles.datePickerPanelContentContainer}>
            { selectedPicker === 'date' && [renderMonthAndYearSelectorElement(), renderDatePickerElement()] }
            { selectedPicker === 'month' && [renderMonthPickerElement(value)] }
            { selectedPicker === 'year' && [renderYearPickerElement(visibleDate)] }
          </View>
        </Layout>
      </Popover>

    </>
  );
};

const UnThemedDatePicker = ({ deviceType, eva = null }) => {
  const [dateInputValue, setDateInputValue] = useState(new Date());

  const onChange = (val) => {
    setDateInputValue(val);
  };

  return (
    <CustomDateInput deviceType={deviceType} eva={eva} onChange={onChange} value={dateInputValue} />
  );
};

let EvaType: any;

UnThemedDatePicker.propTypes = {
  deviceType: DeviceType,
  eva: EvaType,
};

UnThemedDatePicker.defaultProps = {
  deviceType: DeviceType.TABLET,
  eva: null,
};

export const DatePicker = withStyles(UnThemedDatePicker as any, (theme) => ({
  input: {
    borderWidth: 1,
    borderColor: theme['color-border-default'],
  },
  headerElement: { backgroundColor: theme['color-primary-default'] },
  headerElementTitle: {
    color: theme['text-control-color'],
    fontSize: 24,
  },
  weekDayHeaderText: {
    color: theme['text-hint-color'],
    fontSize: 15,
  },
}));

export default DatePicker;
