import { Button, Dialog, Text, VSpacer } from '@design';
import { ApiUserAccount } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SalesPersonMissingModalProps {
  onClose: () => void,
  salesperson: ApiUserAccount,
  visible: boolean,
}

export const SalesPersonMissingModal = ({
  onClose,
  salesperson,
  visible,
}: SalesPersonMissingModalProps) => {
  const [translate] = useTranslation(['common', 'errors']);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp }) => (
        <Button
          {...primaryButtonProp}
          onPress={onClose}
          testID="salesperson-missing-close-button"
        >
          {translate('CLOSE')}
        </Button>
      )}
      onClose={onClose}
      testID="salesperson-missing-modal"
      title={translate('SALESPERSON_NOT_FOUND_MODAL_TITLE_DYNAMICS')}
      visible={visible}
    >
      <Text>{translate('ERROR_SAVING_ORDER_PRODUCTS_MISSING_SALESPERSON_DYNAMICS')}</Text>
      <VSpacer size="4" />
      <Text>{salesperson.fullName}</Text>
    </Dialog>
  );
};
