import { useStyleSheet } from '@ui-kitten/components';
import React, { useState } from 'react';
import { Icon, Text, TextLink, VSpacer, HSpacer } from '@design';
import { View } from 'react-native';
import { FarmPlanDeliverableV2 } from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { useTranslation } from 'react-i18next';
import { OrderDetailsTable } from './OrderDetailsTable';
import { ThemeColors } from '../../../../constants';

interface OrderDetailsProps {
  selections: FarmPlanDeliverableV2.Selections;
  products: FarmPlanDeliverableV2.ProductSummary[];
  overview: FarmPlanDeliverableV2.Snapshot['overview'];
}

const INITIAL_NUMBER_OF_ROWS = 3;

const OrderDetails = (props: OrderDetailsProps) => {
  const [showMore, setShowMore] = useState(false);
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'common', 'prepare']);

  const styles = useStyleSheet({
    footer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: 'color-basic-control-transparent-100',
    },
  });

  const orderDetailsKeys = Object.keys(props.selections.orderDetails).filter(
    (key) => key !== 'priceLocked',
  );
  const hasOrderDetailsSelections = orderDetailsKeys.some(
    (key) => props.selections.orderDetails[key],
  );
  if (!hasOrderDetailsSelections) {
    return null;
  }
  return (
    <>
      <VSpacer size="10" />
      <VSpacer size="3" />
      <VSpacer size="1" />
      <Text category="h6">{translate<string>('ORDER_DETAILS')}</Text>
      <VSpacer size="4" />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text category="p2">
          {`${props.products.length} ${translate<string>('PRODUCTS')}`}
        </Text>
        {props.products.length > INITIAL_NUMBER_OF_ROWS && (
          <View style={{ flexDirection: 'row' }}>
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowMore(!showMore)}
              testID="show-details-link"
            >
              {translate<string>(showMore ? 'COLLAPSE_ALL' : 'SHOW_ALL')}
            </TextLink>
            <HSpacer size="2" />
            <Icon
              fill={ThemeColors.SECONDARY}
              name={!showMore ? 'ChevronDown' : 'ChevronUp'}
              size="medium"
              testID="expand-icon"
            />
          </View>
        )}
      </View>
      <VSpacer size="3" />
      <OrderDetailsTable
        expanderChevronStatus="primary"
        footerRow={(props.products.length > INITIAL_NUMBER_OF_ROWS) && (
          <View style={styles.footer}>
            <VSpacer size="7" />
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowMore(!showMore)}
              testID="show-details-link"
            >
              {showMore ? translate<string>('COLLAPSE_ALL')
                : translate<string>(props.products.length - INITIAL_NUMBER_OF_ROWS === 1
                  ? 'MORE_ROW' : 'MORE_ROWS', {
                  count: props.products.length - INITIAL_NUMBER_OF_ROWS,
                })}
            </TextLink>
            <VSpacer size="7" />
          </View>
        )}
        overview={props.overview}
        products={showMore ? props.products : props.products.slice(0, INITIAL_NUMBER_OF_ROWS)}
        selections={props.selections}
        showDetails={showMore}
      />
    </>
  );
};

export default OrderDetails;
