import { Button, Card, Text, VSpacer } from '@design';
import { ApiProductOrderComponent, ApiProductOrderProductMixComponent } from '@shared/interfaces/api';
import { sortBy } from '@shared/utils/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { countGroupedTankMixes, groupByTankMixId } from '../../helpers';
import { SelectedProductItem } from './SelectedProductItem';

interface SelectedProductsProps {
  locationId: string,
  onProductRemoved: (
    product?: ApiProductOrderComponent,
    productMix?: ApiProductOrderProductMixComponent,
  ) => void,
  onClearAllPress?: () => void,
  priceTypeId: string,
  selectedProducts: ApiProductOrderComponent[],
  selectedProductMixes: ApiProductOrderProductMixComponent[],
}

export const SelectedProducts: FC<SelectedProductsProps> = ({
  locationId,
  selectedProducts,
  selectedProductMixes,
  onProductRemoved,
  onClearAllPress,
  priceTypeId,
}) => {
  const styles = useStyleSheet({
    card: {
      flex: 1,
      alignSelf: 'stretch',
      paddingTop: 28,
      paddingBottom: 32,
      paddingHorizontal: 0,
    },
    header: {
      minHeight: 32,
      flexDirection: 'row',
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    heading: {
      textTransform: 'uppercase',
    },
    line: {
      borderTopWidth: 1,
      borderTopColor: 'color-basic-transparent-100',
    },
  });

  const [translate] = useTranslation(['common', 'products']);

  const totalSelectedProducts = (selectedProducts?.length ?? 0)
    + countGroupedTankMixes(selectedProductMixes);
  const combinedProducts: { 
    productComponent?: ApiProductOrderComponent,
    productMixComponent?: ApiProductOrderProductMixComponent, 
  }[] = [
    ...(selectedProducts || []).map(p => (
      { productComponent: p }
    )), 
    ...groupByTankMixId(selectedProductMixes).map(p => (
      { productMixComponent: p }
    )),
  ];

  return (
    <>
      <View style={styles.header} testID="selected-products-header">
        {totalSelectedProducts === 0 ? (
          <Text
            category="s2"
            style={styles.heading}
            testID="selected-products-title"
          >
            {translate('SELECTED_PRODUCTS') as string}
          </Text>
        ) : (
          <>
            <Text
              category="s2"
              style={styles.heading}
              testID="selected-products-title"
            >
              {`${translate('SELECTED_PRODUCTS')} (${totalSelectedProducts})`}
            </Text>
            <Button
              appearance="ghost"
              onPress={onClearAllPress}
              size="small"
              testID="selected-products-clear-all"
            >
              {translate('CLEAR_ALL') as string}
            </Button>
          </>
        )}
      </View>
      <VSpacer size="5" />
      <Card
        style={styles.card}
        testID="selected-products-card"
      >
        {totalSelectedProducts > 0 && (
          <>
            {sortBy(combinedProducts, (productOrTankMix) => (
              productOrTankMix?.productComponent?.product?.name ||
              productOrTankMix?.productMixComponent?.tankMix?.name || ''
            ).toLowerCase()).map((productOrTankMix, index) => (
              <SelectedProductItem
                index={index}
                key={
                  productOrTankMix?.productComponent?.productId ||
                  productOrTankMix?.productMixComponent?.tankMixId
                }
                locationId={locationId}
                onRemovePress={onProductRemoved}
                priceTypeId={priceTypeId}
                product={productOrTankMix.productComponent}
                productMixes={productOrTankMix.productMixComponent
                  ? selectedProductMixes?.filter(
                    x => x.tankMixId === productOrTankMix.productMixComponent.tankMixId,
                  )
                  : []
                }
              />
            ))}
            <View style={styles.line} />
          </>
        )}
      </Card>
    </>
  );
};
