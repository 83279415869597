import React, { FC, useCallback, useState } from 'react';
import {
  OverflowMenu as UIKOverflowMenu,
  OverflowMenuProps as UIKOverflowMenuProps,
} from '@ui-kitten/components';
import { Overwrite, RenderFCProp } from '@ui-kitten/components/devsupport';
import { IconButton } from '../IconButton/IconButton';

type OverrideProps = Overwrite<UIKOverflowMenuProps, {
  anchor?: RenderFCProp<{ onPress: () => void }>,
  placement?: (
    'left'
    | 'top'
    | 'right'
    | 'bottom'
    | 'left start'
    | 'left end'
    | 'top start'
    | 'top end'
    | 'right start'
    | 'right end'
    | 'bottom start'
    | 'bottom end'
  )
}>;

interface OverflowMenuProps extends OverrideProps {
  testID: string,
}

export const OverflowMenu: FC<OverflowMenuProps> = ({
  anchor,
  children,
  testID,
  nativeID,
  ...overflowMenuProps
}) => {
  const [open, setOpen] = useState(false);
  const onPress = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <UIKOverflowMenu
      anchor={(() => anchor?.({ onPress }) || (
        <IconButton
          appearance="ghost"
          onPress={onPress}
          status="basic"
          testID={testID}
        >
          MoreVertical
        </IconButton>
      ))}
      appearance="noDivider"
      onBackdropPress={onClose}
      onSelect={onClose}
      testID="option-list"
      visible={open}
      {...overflowMenuProps}
    >
      {children}
    </UIKOverflowMenu>
  );
};
