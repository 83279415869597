import {
  Button,
  Card,
  CenteredSpinner,
  HSpacer,
  Input,
  LargeModal,
  NumericInput,
  Select,
  SelectItem,
  Text,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import { OtherProductUnits, ProductCategory } from '@shared/enums';
import {
  ApiPrice,
  ApiProduct,
  ApiProductCategory,
  ApiProductSubCategory,
  ProductEndpoint,
} from '@shared/interfaces/api';
import { Status } from '@theme/variant-interfaces/Status';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useQuery } from 'react-query';
import { getBusinessProductListPage } from '../../../constants';
import { QueryKeys } from '../../../constants/QueryKeys';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { useHistory } from '../../../router';
import { BusinessApi, ProductApi } from '../../../utilities/api';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { MultiplePricingTable } from './MultiplePricingTable';
import { getDefaultPrices } from './utils';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 545,
  },
  footer: {
    position: 'absolute',
    flexDirection: 'row',
    right: 132,
    bottom: 32,
    width: 100,
  },
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
});

const flattenProduct = (
  product: ApiProduct,
): ProductEndpoint.Create.Other & { id: string, manufacturer: string } => {
  const {
    businessCategoryId,
    externalId,
    id,
    isActive,
    manufacturer,
    name,
    packageName,
    packageUnitQuantity,
    prices,
    skuName,
  } = product;
  const unitUoM = product.unitUoM as OtherProductUnits;
  const priceList = prices.map((price) => ({
    id: price.id,
    locationId: price.businessLocationId,
    priceTypeId: price.priceTypeId,
    unitPrice: price.price,
  }));

  return {
    businessCategoryId,
    category: ProductCategory.OTHER,
    externalId,
    id,
    isActive,
    prices: !_.isEmpty(priceList) ? priceList : [],
    manufacturer,
    name,
    packageName,
    packageUnitQuantity,
    skuName,
    unitUoM,
  };
};

const categoryUoMs = [...OtherProductUnits].sort();

export interface OtherProductModalProps {
  productId?: string,
  category: ProductCategory,
  onClose: (event?) => Promise<void>,
}

export const OtherProductModal: FC<OtherProductModalProps> = ({
  productId,
  category,
  onClose,
}) => {
  const history = useHistory();
  const { currentBusinessId, currentBusiness } = useAuthentication();
  const { createToast } = useToast();
  const { createBanner } = useBanner();
  const [translate] = useTranslation(['prepare', 'products', 'common']);
  const [formLoaded, setFormLoaded] = useState(false);
  const [businessCategorySelection, setBusinessCategorySelection] = useState(null);
  const [error, setError] = useState('');
  const [initError, setInitError] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status>('basic');
  const [name, setName] = useState('');
  const [skuName, setSkuName] = useState('');
  const [externalProductId, setExternalProductId] = useState('');
  const [packageName, setPackageName] = useState('');
  const [packageUnitQuantity, setPackageUnitQuantity] = useState<number | null>(null);
  const [productManufacturer, setProductManufacturer] = useState('');
  const [prices, setPrices] = useState<ApiPrice[]>([]);
  const {
    businessLocations,
    error: businessLocationError,
  } = useBusinessLocationList(currentBusinessId);
  const [businessCategories, setBusinessCategories] = useState<ApiProductCategory[]>([]);
  const [subCategories, setSubCategories] = useState<ApiProductSubCategory[]>([]);
  const [businessProductData, setBusinessProductData] = useState<ApiProduct>(null);
  const { priceTypes, invalidatePriceTypeList } = usePriceTypeList();
  const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState<boolean>(false);
  const [unitUoM, setUnitUoM] = useState<OtherProductUnits>(null);

  const clearForm = useCallback(() => {
    setName('');
    setSkuName('');
    setPackageName('');
    setExternalProductId('');
    setPackageUnitQuantity(null);
    setPrices(getDefaultPrices(priceTypes));
    setBusinessCategories([]);
    setSubCategories([]);
    setBusinessCategorySelection(null);
    setStatus('basic');
    setError('');
    setInitError('');
    setLoading(false);
    setBusinessProductData(null);
  }, [priceTypes]);

  useEffect(() => {
    clearForm();
  }, [clearForm]);

  const getBusinessCategories = async () => {
    return BusinessApi.getBusinessProductCategories(currentBusinessId);
  };

  const {
    data: businessCategoryData,
    isLoading: isBusinessCategoriesLoading,
  } = useQuery(
    [QueryKeys.BUSINESS_CATEGORIES, currentBusinessId, productId],
    getBusinessCategories, {
      onError: () => {
        setInitError('ERROR_BUSINESS_CATEGORIES');
      },
      enabled: !!currentBusinessId,
    },
  );

  useEffect(() => {
    setLoading(isBusinessCategoriesLoading);
    if (businessCategoryData) {
      setBusinessCategories(businessCategoryData);
    } else if (!productId) {
      clearForm();
    }
  }, [businessCategoryData, clearForm, isBusinessCategoriesLoading, productId]);

  useEffect(() => {
    const categoryEntry = businessCategories.find((cat) => cat.category === category);
    setSubCategories(
      [
        {
          id: '',
          name: translate('NONE'),
          category,
        },
        ..._.defaultTo(categoryEntry?.subCategories, [])].sort(),
    );
  }, [businessCategories, category, translate]);

  useEffect(() => {
    async function closeOnLocationError () {
      if (initError && businessLocationError) {
        await onClose();
        history.replace(getBusinessProductListPage(category));
      }
    }
    closeOnLocationError();
  }, [
    businessLocationError,
    category,
    history,
    initError,
    onClose,
  ]);

  const getProduct = async () => ProductApi.get(currentBusinessId, productId);

  const {
    isError: isBusinessProductError,
    isLoading: isBusinessProductLoading,
  } = useQuery<ApiProduct, Error>([
    QueryKeys.BUSINESS_PRODUCT_DATA,
    currentBusinessId,
    category,
    productId,
  ],
  getProduct, {
    enabled: !!productId && !!currentBusinessId,
    onSuccess: (data: ApiProduct) => {
      setBusinessProductData(data);
    },
    onError: () => {
      setInitError(translate('ERROR_LOADING_PRODUCT'));
      history.replace(getBusinessProductListPage(category));
    },
  });

  useEffect(() => {
    setLoading(isBusinessProductLoading);
    if (businessProductData && productId && !formLoaded) {
      const flatProduct = flattenProduct(businessProductData);
      setName(flatProduct.name);
      setSkuName(flatProduct.skuName);
      setExternalProductId(flatProduct.externalId ?? '');
      setPackageName(flatProduct.packageName);
      setPackageUnitQuantity(flatProduct.packageUnitQuantity);
      setProductManufacturer(flatProduct.manufacturer ?? '');
      setPrices(getDefaultPrices(priceTypes, flatProduct.prices));
      setUnitUoM(flatProduct.unitUoM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessProductData,
    isBusinessProductError,
    error,
    initError,
    translate,
    isBusinessProductLoading,
    productId,
    subCategories,
  ]);

  useEffect(() => {
    if (businessProductData && subCategories.length > 0 && productId && !formLoaded) {
      const flatProduct = flattenProduct(businessProductData);
      setBusinessCategorySelection(
        subCategories.sort().findIndex((sub) => sub.id === flatProduct.businessCategoryId),
      );
      setFormLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subCategories,
    businessProductData,
    productId,
  ]);

  // Clear form on modal close
  useEffect(() => {
    if (error || initError) {
      createBanner({
        status: 'warning',
        children: error
          ? `${translate(error)}`
          : `${translate(initError)}`,
        testID: 'error-banner',
        actionAccessory: ({ dismissProps }) => (
          <View style={styles.banner}>
            <Button
              {...dismissProps}
              appearance="ghost"
              size="small"
              status="basic"
              testID="dismiss-button"
            >
              {`${translate('DISMISS')}`}
            </Button>
          </View>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, initError, translate]);

  const formValid = (): boolean => {
    const requiredFields = [
      currentBusinessId,
      name,
      packageName,
      skuName,
      unitUoM,
    ];
    const requiredNumbers = [packageUnitQuantity];
    const countOfPrices = [];
    businessLocations.forEach((location) => {
      countOfPrices.push(
        prices.filter(
          (price) => price.locationId === location.id,
        ).length,
      );
    });

    const hasRequiredFields = !_.some(requiredFields, (val) => _.isEmpty(val));
    const hasRequiredNumbers = _.every(requiredNumbers, (num) => !_.isNaN(num) && num !== null);
    const validPrices = prices.every(
      (p) => p.priceTypeId && (p.unitPrice !== null && p.unitPrice !== undefined),
    );

    return hasRequiredFields
      && hasRequiredNumbers
      && validPrices;
  };

  const onSubmit = async (toggleModal: () => void) => {
    try {
      setError('');
      const businessCategoryId = subCategories.sort()[businessCategorySelection]?.id || null;
      const params: ProductEndpoint.Create.Other = {
        businessCategoryId,
        category: ProductCategory.OTHER,
        externalId: externalProductId.trim() || null,
        prices,
        name,
        packageName,
        packageUnitQuantity,
        skuName,
        unitUoM,
      };
      if (!productId) {
        await ProductApi.create(currentBusinessId, params);
        createToast({
          status: 'success',
          children: `${translate('PRODUCT_SUCCESS', { name })}`,
          testID: 'toast-content-element',
        });
        clearForm();
      } else {
        await ProductApi.update(currentBusinessId, productId, params);
        createToast({
          status: 'success',
          children: `${translate('PRODUCT_EDIT_SUCCESS', { name })}`,
          testID: 'toast-content-element',
        });
      }
      toggleModal();
    } catch (err) {
      if (err instanceof DetailedApiError) {
        if (err.code === 'already-existing-product-sku') {
          setError('ERROR_PRODUCT_SKU_ALREADY_USED');
          setStatus('warning');
        } else if (err.message === 'Default price is required for price type'
          || err.message === 'Price type not found') {
          setError('ERROR_PRICE_TYPES_CHANGED');
          invalidatePriceTypeList();
          clearForm();
        }
      } else setError('UNEXPECTED_ERROR');
    }
  };

  const onSelectSubCategory = (selection: IndexPath) => {
    setBusinessCategorySelection(selection.row);
  };

  const onSetSkuName = useCallback((value) => {
    setSkuName(value);
    setStatus('basic');
  }, []);

  const getModalTitle = (): string => {
    if (!productId) {
      return `${translate(`CREATE_${category}_PRODUCT`)}`;
    }
    return `${translate(`EDIT_${category}_PRODUCT`)}`;
  };

  const onPricesChanged = useCallback((newPrices: ApiPrice[]) => {
    setPrices(newPrices);
  }, []);

  const Footer = (
    submitModal: (cb: () => void) => void,
    toggleModal: () => void,
  ) => (
    <View style={styles.footer}>
      <Button
        appearance="outline"
        design="floating"
        onPress={() => {
          if (!productId) {
            clearForm();
          }
          toggleModal();
        }}
        status="basic"
        testID={`${category.toLowerCase()}-product-modal-cancel-button`}
      >
        {translate('CANCEL') as string}
      </Button>
      <HSpacer size="6" />
      <Button
        design="floating"
        disabled={!formValid()}
        onPress={() => (!productId
          ? submitModal(toggleModal)
          : setShowUpdateConfirmationModal(true))}
        testID={`${category.toLowerCase()}-product-modal-submit-button`}
      >
        {translate('SAVE') as string}
      </Button>
    </View>
  );

  const ProductFormPage = [
    <View style={{ alignItems: 'center', width: '100%' }}>
      {loading && <CenteredSpinner />}
      {!loading && (
        <View style={styles.container}>
          <KeyboardAwareScrollView>
            <Card
              style={{
                flex: 1,
                backgroundColor: 'transparent',
                shadowColor: 'transparent',
              }}
              testID="product-modal-details-card"
            >
              <Text category="label">
                {`${translate('PRODUCT_DETAILS')}`}
              </Text>
              <VSpacer size="9" />
              <Input
                isRequired
                label={`${translate('PRODUCT_NAME')}`}
                onChangeText={setName}
                testID="product-name-field"
                value={name}
              />
              <VSpacer size="7" />
              <Input
                isRequired
                label={`${translate('SKU_NAME')}`}
                onChangeText={onSetSkuName}
                status={status}
                testID="sku-name-field"
                value={skuName}
              />
              <VSpacer size="7" />
              <Input
                label={`${translate('PRODUCT_ID')}`}
                maxLength={50}
                onChangeText={setExternalProductId}
                status={status}
                testID="external-product-id-field"
                value={externalProductId}
              />
              {subCategories.length > 0 && (
                <>
                  <VSpacer size="7" />
                  <Select
                    label={`${translate('SUB_CATEGORY')}`}
                    onSelect={(i) => onSelectSubCategory(i as IndexPath)}
                    testID="subcategory-selector"
                    value={subCategories.sort()[businessCategorySelection]?.name ?? translate<string>('NONE')}
                  >
                    {subCategories.map((item, index) => (
                      <SelectItem key={item.id} testID={`other-product-subcategory-dropdown-value-${index}`} title={item.name} />
                    ))}
                  </Select>
                </>
              )}

              {!!productId && (
                <>
                  <VSpacer size="7" />
                  <Input
                    label={`${translate('MANUFACTURER')}`}
                    readonly
                    testID="product-manufacturer"
                    value={productManufacturer}
                  />
                  <Text
                    appearance="hint"
                    category="c1"
                    style={{ paddingLeft: 10, paddingTop: 8 }}
                  >
                    {translate<string>('MANUFACTURER_MESSAGE')}
                  </Text>
                </>
              )}

              <VSpacer size="12" />
              <Text category="label">{`${translate('PACKAGE')}`}</Text>
              <VSpacer size="9" />
              <Input
                isRequired
                label={`${translate('PACKAGE_NAME')}`}
                onChangeText={setPackageName}
                testID="package-name-field"
                value={packageName}
              />
              <VSpacer size="8" />
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <NumericInput
                    isRequired
                    label={`${translate('UNITS_PER_SKU')}`}
                    onChangeValue={setPackageUnitQuantity}
                    precision={3}
                    testID="package-unit-quantity-field"
                    value={packageUnitQuantity}
                  />
                </View>
                <HSpacer size="2" />
                <HSpacer size="6" />
                <View style={{ flex: 1, alignSelf: 'flex-end' }}>
                  <Select
                    disabled={!!businessProductData}
                    isRequired
                    label={`${translate('UOM')}`}
                    onSelect={(sel: IndexPath | IndexPath[]) => setUnitUoM(
                      categoryUoMs[(sel as IndexPath).row],
                    )}
                    testID="uom-field"
                    value={`${translate(unitUoM)}`}
                  >
                    {categoryUoMs.map((uom, index) => (
                      <SelectItem
                        key={uom}
                        testID={`product-category-uom-dropdown-value-${index}-${translate(uom)}`}
                        title={`${translate(uom)}`}
                      />
                    ))}
                  </Select>
                </View>
              </View>
              <VSpacer size="12" />
              <MultiplePricingTable
                businessLocations={businessLocations}
                onPricesChanged={onPricesChanged}
                priceTypes={priceTypes}
                prices={prices}
              />
            </Card>
          </KeyboardAwareScrollView>
        </View>
      )}
    </View>,
  ];

  return (
    <>
      {showUpdateConfirmationModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('YES_UPDATE')}
          onCancel={() => {
            setShowUpdateConfirmationModal(false);
          }}
          onConfirm={() => {
            onSubmit(onClose);
            setShowUpdateConfirmationModal(false);
          }}
          status="warning"
          title={translate('UPDATE_PRODUCT')}
          visible={showUpdateConfirmationModal}
        />
      )}
      <LargeModal
        footer={() => Footer(onSubmit, onClose)}
        pages={ProductFormPage}
        subTitle={currentBusiness?.businessName}
        testID="other-product-large-modal"
        title={getModalTitle()}
        visible
      />
    </>
  );
};
