import { Icon } from '@design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBar } from '../../../../components/shared/ButtonBar';

export interface ViewModeFooterProps {
  disabled?: boolean,
  onBack(): void,
  onViewOrderDetails?(): void,
}

export const ViewModeFooter = ({
  disabled,
  onBack,
  onViewOrderDetails,
}: ViewModeFooterProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  return (
    <ButtonBar
      accessoryLeft={(backBtnProps) => <Icon name="ArrowBack" testID="view-order-details-back-button-icon" {...backBtnProps} />}
      accessoryRight={(orderDetailsBtnProps) => <Icon name="ArrowForward" testID="view-order-details-forward-button-icon" {...orderDetailsBtnProps} />}
      disableRightAction={disabled}
      leftAction={onBack}
      leftButtonText={translate('BACK')}
      rightAction={onViewOrderDetails}
      rightButtonText={translate('VIEW_ORDER_DETAILS')}
      testID="view-order-details-button"
    />
  );
};
