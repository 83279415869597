import {
  BannerProps,
  Button,
  Card,
  CenteredSpinner,
  Pagination,
  Text,
  useBanner,
  VSpacer,
} from '@design';
import { ProductCategory } from '@shared/enums';
import {
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  ApiTankMix,
  ApiTankMixListQuerySort,
} from '@shared/interfaces/api';
import {
  ApiProduct,
  ApiProductListQuery,
  ApiProductListQuerySort,
} from '@shared/interfaces/api/ProductEndpoint';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { ProductApi, TankMixApi } from '../../../../../utilities/api';

import { ProductFilters } from './ProductFilterer';
import { ProductListItem } from './ProductListItem';

const styles = StyleSheet.create({
  card: {
    flex: 1,
    alignSelf: 'stretch',
    paddingTop: 28,
    paddingBottom: 32,
    paddingHorizontal: 0,
  },
  header: {
    minHeight: 32,
    justifyContent: 'center',
  },
  availableProductsHeading: {
    textTransform: 'uppercase',
  },
  line: {
    borderTopWidth: 1,
    borderTopColor: GrowersDarkTheme['color-basic-transparent-100'],
  },
  pagination: {
    alignSelf: 'stretch',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: GrowersDarkTheme['color-basic-transparent-100'],
    paddingTop: 20,
    paddingVertical: 28,
  },
});

interface ProductOrProductMix {
  product?: ApiProduct,
  productMix?: ApiTankMix,
}

interface ProductListProps {
  filters: ProductFilters,
  locationId: string,
  onProductSelected: (product: ApiProduct, productMix: ApiTankMix) => void,
  priceTypeId: string,
  selectedProducts?: ApiProductOrderComponent[],
  selectedProductMixes?: ApiProductOrderProductMixComponent[],
}

export const ProductList: FC<ProductListProps> = ({
  filters,
  locationId,
  onProductSelected,
  priceTypeId,
  selectedProducts,
  selectedProductMixes,
}) => {
  const [translate] = useTranslation(['common', 'products']);
  const { createBanner } = useBanner();
  const { currentBusinessId: businessId } = useAuthentication();
  const [products, setProducts] = useState<ProductOrProductMix[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [error, setError] = useState(null);

  const bannerProps = useCallback((bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate('DISMISS') as string}
        </Button>
      </View>
    ) }), [translate]);

  const loadProducts = useCallback(async () => {

    const pageSize = 20;
    setLoadingProducts(true);

    const queryFilters: ApiProductListQuery = {
      ...(filters?.category ? { category: filters.category } : undefined),
      ...(filters?.formulations?.length ? { formulation: filters.formulations } : undefined),
      ...(filters?.crops?.length ? { cropType: filters.crops } : undefined),
      ...(filters?.cropSubtypes?.length ? { cropSubType: filters.cropSubtypes } : undefined),
      ...(filters?.customers?.length ? { assignedGrowers: filters.customers } : undefined),
      ...(filters?.manufacturers?.length ? { manufacturer: filters.manufacturers } : undefined),
      ...(filters?.subcategories?.length
        ? { businessCategoryId: filters.subcategories } : ''),
      businessId,
    };

    const listTankMix = (tankMixListPage: number) => {
      return TankMixApi.listTankMix({
        ...queryFilters,
        limit: pageSize,
        page: tankMixListPage,
        search: filters.search,
        sort: ApiTankMixListQuerySort.name,
      });
    };

    try {
      if (filters.category && filters.category !== ProductCategory.PRODUCT_MIX) {
        const result = await ProductApi.list({
          ...queryFilters,
          limit: pageSize,
          page,
          search: filters.search,
          sort: ApiProductListQuerySort.name,
        });

        const res = result.data.map(product => ({ product }));
        setTotalProducts(result.total);
        setLastPage(result.lastPage);
        setProducts(res);
        if (res.length === 0 && page !== 0) {
          setPage(0);
        }
        return;
      }

      const tankMixResult = await listTankMix(page);

      if (filters.category === ProductCategory.PRODUCT_MIX) {
        const tankMixes = tankMixResult.data.map(productMix => ({ productMix }));
        setTotalProducts(tankMixResult.total);
        setLastPage(tankMixResult.lastPage);
        setProducts(tankMixes);
        if (tankMixes.length === 0 && page !== 0) {
          setPage(0);
        }
        return;
      }

      const result = await ProductApi.list({
        ...queryFilters,
        limit: pageSize,
        page,
        search: filters.search,
        sort: ApiProductListQuerySort.name,
      });

      const totalPages = Math.ceil((result.total + tankMixResult.total) / pageSize);
      let productsCount = 0;

      if (page >= result.lastPage) {
        const pageToFetchForTankMixes = result.total === 0
          ? page
          : Math.max(0, page - result.lastPage - 1);

        const rowsToSkip = (result.total % pageSize !== 0) && page > result.lastPage
          ? pageSize - (result.total % pageSize)
          : 0;
          
        let combinedResults = page === pageToFetchForTankMixes
          ? [
            ...result.data.map(product => ({ product })),
            ...tankMixResult.data.slice(rowsToSkip).map(
              productMix => ({ productMix }),
            ),
          ] : [
            ...result.data.map(product => ({ product })),
            ...(await listTankMix(pageToFetchForTankMixes)).data.slice(rowsToSkip).map(
              productMix => ({ productMix }),
            ),
          ];

        combinedResults = combinedResults.slice(0, pageSize);

        if (combinedResults.length < pageSize) {
          combinedResults = [
            ...combinedResults,
            ...(await listTankMix(
              pageToFetchForTankMixes + 1,
            )).data.map(
              productMix => ({ productMix }),
            ),
          ];

          combinedResults = combinedResults.slice(0, pageSize);
        }
        productsCount = combinedResults.length;
        setProducts(combinedResults);
      } else {
        const resultProducts = result.data.map(product => ({ product }));
        productsCount = resultProducts.length;
        setProducts(resultProducts);
      }

      setTotalProducts(result.total + tankMixResult.total);
      setLastPage(totalPages - 1);

      if (productsCount === 0 && page !== 0) {
        setPage(0);
      }

    } catch (err) {
      setError(err);
      setProducts([]);
      setTotalProducts(0);
      setLastPage(0);
    } finally {
      setLoadingProducts(false);
    }
  }, [businessId, page, filters]);

  useEffect(() => {
    (async () => {
      await loadProducts();
    })();
  }, [page, businessId, loadProducts, filters]);

  useEffect(() => {
    if (error) {
      createBanner(bannerProps(translate('ERROR_FETCH_PRODUCTS')));
    }
  }, [bannerProps, createBanner, error, translate]);

  const isProductSelected = (productOrProductMix: ProductOrProductMix) => {
    return selectedProducts?.some(
      (selectedProduct) => {
        if (productOrProductMix.product) {
          return selectedProduct?.productId === productOrProductMix.product.id;
        }
        return false;
      },
    ) || selectedProductMixes?.some(
      (selectedProductMix) => {
        if (productOrProductMix.productMix) {
          return selectedProductMix?.tankMixId === productOrProductMix.productMix.id;
        }
        return false;
      },
    );
  };

  const isProductMix = filters.category === ProductCategory.PRODUCT_MIX;

  return (
    <>
      <View style={styles.header} testID="available-products-title">
        <Text category="s2" style={styles.availableProductsHeading}>
          {`${translate(isProductMix ? 'AVAILABLE_PRODUCT_MIXES' : 'AVAILABLE_PRODUCTS')} (${totalProducts})`}
        </Text>
      </View>
      <VSpacer size="5" />
      { products.length === 0 && !loadingProducts && <Text>{translate('NO_PRODUCTS') as string}</Text> }
      <Card style={styles.card} testID="product-list-card">
        {loadingProducts ? (
          <CenteredSpinner testID="product-list-spinner" />
        ) : (
          <>
            {products.map((productOrTankMix, index) => (
              <ProductListItem
                index={index}
                key={productOrTankMix.product?.id || productOrTankMix.productMix?.id}
                locationId={locationId}
                onAddPress={onProductSelected}
                priceTypeId={priceTypeId}
                product={productOrTankMix.product}
                productMix={productOrTankMix.productMix}
                selected={isProductSelected(productOrTankMix)}
              />
            ))}
            {lastPage === 0 ? (
              <View style={styles.line} />
            ) : (
              <View style={styles.pagination}>
                <Pagination
                  currentPage={page + 1}
                  displayPages={7}
                  onChangePage={(selectedPage) => setPage(selectedPage - 1)}
                  totalPages={lastPage + 1}
                />
              </View>
            )}
          </>
        )}
      </Card>
    </>
  );
};
