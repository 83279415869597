import React from 'react';
import { Select, SelectItem, VSpacer } from '@design';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { IndexPath } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { ApiPriceType } from '@shared/interfaces/api';
import { usePriceTypeList } from '../../../../hooks/usePriceTypeList';

interface PriceTypeSelectProps {
  isRequired?: boolean,
  onUpdatePriceType: (priceType: ApiPriceType) => void,
  readonly?: boolean,
  selectedPriceType: ApiPriceType,
  testID?: string
  vSpacerSize?: SpacerSize,
}

export const PriceTypeSelect = ({
  isRequired,
  onUpdatePriceType,
  readonly,
  selectedPriceType,
  testID = 'price-type',
  vSpacerSize = '8',
}: PriceTypeSelectProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const { isLoading, priceTypes } = usePriceTypeList();
  const translatePriceTypeValue = (priceType: ApiPriceType) => {
    return priceType?.isDefault
      ? `${priceType?.name} (${translate('DEFAULT_LOWERCASE')})`
      : priceType?.name;
  };

  return (
    <>
      <VSpacer size={vSpacerSize} />
      <Select
        disabled={isLoading}
        isRequired={isRequired}
        label={translate<string>('PRICE_TYPE')}
        onSelect={(index: IndexPath | IndexPath[]) => onUpdatePriceType(
          priceTypes[(index as IndexPath).row],
        )}
        readonly={readonly}
        testID={`${testID}-selector`}
        value={translatePriceTypeValue(selectedPriceType)}
      >
        {priceTypes.map((priceType, index) => (
          <SelectItem
            key={priceType.id}
            selected={priceType.id === selectedPriceType?.id}
            testID={`${testID}-selector-${index}`}
            title={translatePriceTypeValue(priceType)}
          />
        ))}
      </Select>
    </>
  );
};
