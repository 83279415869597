import { ApiGrower } from '@shared/interfaces/api';

export const getCustomerLabel = (customer: ApiGrower) => {
  if (!customer) {
    return '';
  }
  if (!customer.externalDisplayId) {
    return customer.legalName;
  }
  return `${customer.legalName} (${customer.externalDisplayId})`;
};
