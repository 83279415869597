import {
  Button,
  HSpacer,
  Icon,
  Input,
  Modal,
  Select,
  SelectItem,
  Text,
  VSpacer,
} from '@design';
import { CostType, DiscountType } from '@shared/enums';
import { ApiDiscount, DiscountEndpoint } from '@shared/interfaces/api';
import { IndexPath, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Sizing } from '../../../../../constants';
import { Alert, CenteredSpinner } from '../../../../../ui-components';
import { StringUtility } from '../../../../../utilities';
import {
  DiscountInputSelect,
} from '../../../../components/shared/Input/DiscountInputSelect';
import { UseBusinessDiscountsListProps } from '../../../../../hooks/useBusinessDiscounts';

export interface AddEditOrderDiscountModalProps {
  businessDiscountsList: UseBusinessDiscountsListProps,
  orderDiscount?: ApiDiscount;
  orderDiscounts: ApiDiscount[];
  onApply: (updates: DiscountEndpoint.Save.Request) => void;
  onClose: () => void;
}

const defaultDiscount: Partial<ApiDiscount> = {
  discountType: DiscountType.DOLLARS,
  dollars: undefined,
  name: '',
  percent: undefined,
} as const;

export const AddEditOrderDiscountModal: FC<AddEditOrderDiscountModalProps> = ({
  businessDiscountsList,
  orderDiscount,
  orderDiscounts,
  onApply,
  onClose,
}) => {
  const styles = useStyleSheet({
    apply: {
      height: 73,
      alignItems: 'center',
      borderBottomColor: 'color-basic-900',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    error: {
      marginTop: Sizing.BASE_SPACING,
      marginBottom: null,
    },
    selectSubTitle: {
      color: 'text-hint-color',
    },
  });
  const {
    businessDiscountsListData: currentBusinessDiscounts,
    areBusinessDiscountsFetched,
    isBusinessDiscountsDataLoading,
  } = businessDiscountsList;
  const [translate] = useTranslation(['common', 'productOrders']);
  const [applyNewDiscountSelected, setApplyNewDiscountSelected] = useState(false);
  const [discount, setDiscount] = useState<Partial<ApiDiscount>>(defaultDiscount);

  const availableBusinessDiscounts = useMemo(() => (
    currentBusinessDiscounts.data.filter((bizDiscount) => !orderDiscounts.some((skuDisc) => (
      bizDiscount.name === skuDisc.name)))
  ), [currentBusinessDiscounts.data, orderDiscounts]);

  useEffect(() => {
    if (!isBusinessDiscountsDataLoading && !currentBusinessDiscounts.data.length) {
      setApplyNewDiscountSelected(true);
    }
  }, [currentBusinessDiscounts.data.length, isBusinessDiscountsDataLoading]);

  useEffect(() => {
    let isEditable = false;
    if (areBusinessDiscountsFetched && orderDiscount?.name) {
      isEditable = !currentBusinessDiscounts?.data?.some((currentBizDiscount) => {
        return currentBizDiscount?.name === orderDiscount.name;
      });
    }
    if (isEditable) {
      setApplyNewDiscountSelected(true);
      setDiscount(orderDiscount);
    }
  }, [currentBusinessDiscounts?.data, areBusinessDiscountsFetched, orderDiscount]);
  
  const isIncludedInBusinessDiscounts = useMemo(() => {
    if (applyNewDiscountSelected && areBusinessDiscountsFetched) {
      return currentBusinessDiscounts.data.some((bizDiscount) => (
        bizDiscount.name === discount.name
      ));
    }
  }, [applyNewDiscountSelected, currentBusinessDiscounts.data, discount.name, areBusinessDiscountsFetched]);

  const isBusinessDiscountSelected = () => {
    if (applyNewDiscountSelected && areBusinessDiscountsFetched && orderDiscount.name) {
      return currentBusinessDiscounts.data.some((bizDiscount) => (
        bizDiscount.name === discount.name
      ));
    }
    return false;
  };

  const isDuplicateName = useMemo(
    () => orderDiscounts.some((od) => od.name.trim() === discount.name?.trim())
      && (discount.name?.trim() !== orderDiscount?.name?.trim()),
    [orderDiscounts, orderDiscount?.name, discount.name],
  );

  const isFormValid = useMemo(
    () => !(!discount.name
      || (!discount.percent && !discount.dollars)
      || isDuplicateName),
    [discount.name, discount.percent, discount.dollars, isDuplicateName],
  );

  const onSelectBusinessDiscount = ({ row }: IndexPath) => {
    const isApplyNewDiscountSelected = row === 0;
    if (isApplyNewDiscountSelected) {
      setApplyNewDiscountSelected(true);
      const shouldKeepValue = !isBusinessDiscountSelected;
      setDiscount({
        ...discount,
        name: shouldKeepValue ? discount.name : '',
        dollars: shouldKeepValue ? discount.dollars : undefined,
        percent: shouldKeepValue ? discount.percent : undefined,
        discountType: shouldKeepValue ? discount.discountType : DiscountType.DOLLARS,
      });
    } else {
      setApplyNewDiscountSelected(false);
      const selectedDiscount = availableBusinessDiscounts[row - 1];
      setDiscount({
        ...discount,
        name: selectedDiscount.name,
        dollars: selectedDiscount.dollars,
        percent: selectedDiscount.percent,
        discountType: selectedDiscount.discountType,
        costType: CostType.TOTAL_COST,
      });
    }
  };

  const selectedBusinessDiscount = currentBusinessDiscounts?.data?.find((disc) => (
    disc.name === discount.name
  ))?.name || '';

  const selectOptions = (
    <>
      <SelectItem
        accessoryLeft={(iconProps) => (
          <Icon name="Plus" testID="apply-new-discount-icon" {...iconProps} />
        )}
        key="apply-new-discount"
        style={styles.apply}
        testID="business-discount-apply-new-discount"
        title={(
          <View>
            <Text>{translate('APPLY_NEW_DISCOUNT')}</Text>
          </View>
        )}
      />
      {!isBusinessDiscountsDataLoading && !!currentBusinessDiscounts.data.length
        ? availableBusinessDiscounts.map((disc, idx) => (
        <SelectItem
          key={disc.id}
          testID={`business-discount-${idx}`}
          title={(
            <View>
              <Text>{disc.name}</Text>
              <Text category="s2" style={styles.selectSubTitle}>
                {StringUtility.formatDiscountAmount(disc)}
              </Text>
            </View>
          )}
        />
        )) : <CenteredSpinner />}
    </>
  );

  const applyDiscount = () => {
    const baseDiscount: Pick<DiscountEndpoint.Save.Request, 'name' | 'costType'> = {
      costType: discount?.costType || CostType.TOTAL_COST,
      name: discount.name?.trim(),
    };
    if (discount.discountType === DiscountType.DOLLARS) {
      const dollarDiscount: DiscountEndpoint.Save.DollarDiscount = {
        ...baseDiscount,
        dollars: discount.dollars,
        discountType: DiscountType.DOLLARS,
      };
      onApply(dollarDiscount);
    } else {
      const percentDiscount: DiscountEndpoint.Save.PercentDiscount = {
        ...baseDiscount,
        percent: discount.percent,
        discountType: DiscountType.PERCENT,
      };
      onApply(percentDiscount);
    }
  };

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            onPress={onClose}
            testID="add-edit-order-discount-modal-cancel-button"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            appearance={!isFormValid ? 'outline' : 'filled'}
            disabled={!isFormValid}
            onPress={() => applyDiscount()}
            testID="add-edit-order-discount-modal-save-button"
          >
            {translate<string>('APPLY')}
          </Button>
        </>
      )}
      onClose={onClose}
      testID="add-edit-order-discount-modal"
      title={translate(!!orderDiscount?.id ? 'EDIT_ORDER_DISCOUNT' : 'ADD_ORDER_DISCOUNT')}
      visible
    >
      {(isDuplicateName || isIncludedInBusinessDiscounts) && (
        <View testID="add-edit-order-discount-modal-duplicate-name-error">
          <Alert
            level="danger"
            style={styles.error}
            text={translate('DISCOUNT_DUPLICATE')}
          />
        </View>
      )}
      <VSpacer size="5" />
      {!isBusinessDiscountsDataLoading && !!currentBusinessDiscounts.data.length && (
        <>
          <Select
            isRequired
            label={translate('DISCOUNT')}
            onSelect={(idx: IndexPath | IndexPath[]) => onSelectBusinessDiscount(idx as IndexPath)}
            testID="preset-discount-select"
            value={selectedBusinessDiscount}
          >
            {selectOptions}
          </Select>
          <VSpacer size="5" />
        </>
      )}
      {applyNewDiscountSelected && (
        <>
          <Input
            defaultValue={discount.name}
            isRequired
            label={translate<string>('DISCOUNT_CUSTOM_NAME')}
            onChangeText={(name) => setDiscount({ ...discount, name })}
            testID="add-edit-order-discount-modal-discount-name"
          />
          <VSpacer size="8" />
          <DiscountInputSelect
            discount={discount as ApiDiscount}
            setDiscount={(updates) => setDiscount(updates)}
            testID="product-order"
          />
        </>
      )}
    </Modal>
  );
};
