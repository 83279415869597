import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from 'react-native-svg';

export const RallyIcon = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <G clipPath="url(#clip0)">
      <Path d="M21.3414 0C22.0466 0 22.723 0.280975 23.2216 0.781113C23.7203 1.28125 24.0004 1.95958 24.0004 2.66689V24H18.6824V2.66689C18.6824 1.95958 18.9625 1.28125 19.4612 0.781113C19.9599 0.280975 20.6362 0 21.3414 0V0Z" fill="#F4FF24" />
      <Path d="M8.93357 6.22205C9.63879 6.22205 10.3151 6.50302 10.8138 7.00316C11.3125 7.5033 11.5926 8.18163 11.5926 8.88893V23.9998H6.27454V8.88893C6.27454 8.18163 6.55468 7.5033 7.05335 7.00316C7.55201 6.50302 8.22835 6.22205 8.93357 6.22205V6.22205Z" fill="#F4FF24" />
      <Path d="M2.72983 12.4443C3.43505 12.4443 4.11139 12.7253 4.61005 13.2254C5.10872 13.7256 5.38886 14.4039 5.38886 15.1112V23.9999H0.0708008V15.1112C0.0708008 14.4039 0.350948 13.7256 0.849613 13.2254C1.34828 12.7253 2.02461 12.4443 2.72983 12.4443Z" fill="#F4FF24" />
      <Path d="M15.1377 12.4443C15.8429 12.4443 16.5192 12.7253 17.0179 13.2254C17.5165 13.7256 17.7967 14.4039 17.7967 15.1112V23.9999H12.4786V15.1112C12.4786 14.4039 12.7588 13.7256 13.2574 13.2254C13.7561 12.7253 14.4324 12.4443 15.1377 12.4443Z" fill="#F4FF24" />
      <Path d="M17.7963 0H12.3586V11.4033H17.7963V0Z" fill="#969A3D" />
      <Path d="M5.43769 0H0V11.4033H5.43769V0Z" fill="#969A3D" />
      <Path d="M11.5915 0H6.15381V5.45376H11.5915V0Z" fill="#969A3D" />
    </G>
    <Defs>
      <ClipPath id="clip0">
        <Rect fill="white" height="24" width="24" />
      </ClipPath>
    </Defs>
  </Svg>
);
