export default {
  ACRES: 'Acres',
  ACRES_COUNT: '{{acres}} acres',
  ACTION_CANNOT_BE_UNDONE: 'Yes, exit without saving',
  ACTIVATE_CUSTOMER: 'Activate Customer',
  ADDRESS1: 'Address line 1',
  ADDRESS2: 'Address line 2',
  ADD_CONTACT: 'Add contact',
  ADD_CONTACT_ACTION: 'Add Contact',
  ADD_CROP: 'Add crop',
  ADD_CROPS: 'Add crops',
  ADD_CROP_ZONE: 'Add Crop Zone',
  ADD_CUSTOMER: 'Add customer',
  ADD_FARM: 'Add Farm',
  ADD_FIELD: 'Add field',
  ADD_NOTE: 'Add note',
  ADD_TAGS: 'Add tags',
  ASSIGN: 'Assign',
  ASSIGNED_TO: 'Assigned to {{ name }}',
  ASSIGNED_USER: 'Assigned user',
  ASSIGNED_USERS: 'Assigned users',
  ASSIGNED_USER_NONE: 'No users are assigned to this customer',
  ASSIGN_USERS: 'Assign Users',
  ASSIGN_USERS_COUNT: 'Assign Users ({{ count }})',
  ASSIGN_USERS_TO_CUSTOMER: 'Assign users to customer',
  CALL: 'Call',
  CANCEL: 'Cancel',
  CANCEL_CUSTOMER_PLANNING_PARAMETERS_CHANGE: 'Are you sure you want to cancel the annual planning parameters changes for this customer?',
  CANCEL_EDIT_DIALOG_DESCRIPTION_EDIT: 'Changes to your farm will not be saved.',
  CANCEL_EDIT_DIALOG_DESCRIPTION_NEW: 'Your farm will not be saved.',
  CANCEL_EDIT_DIALOG_TITLE: 'Exit Farm?',
  CANCEL_FARM_CHANGE: 'Are you sure you want to cancel the farm changes for this customer?',
  CANCEL_FARM_CONFIRMATION_MESSAGE: 'This action cannot be undone.',
  CELL_PHONE_NUMBER: 'Cell Phone Number',
  CHANGE_PRIMARY_TITLE: 'Change Primary',
  CONFIRM_DELETE_NOTE_DIALOG_TITLE: 'Delete note?',
  CONFIRM_REMOVING_CROP_PLANNING_PARAMS: 'Are you sure you want to remove every planning parameter for crop(s) {{crops}}',
  CONFIRM_REMOVING_CROP_PLANNING_PARAM_MESSAGE: 'This action will delete {{crops}}.\n\nRevenue calculations will be reset to zero on Farm Plans that use these planning parameters.',
  CONFIRM_REMOVING_CROP_PLANNING_PARAM_TITLE: 'Delete planning parameters?',
  CONTACT_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this customer contact?',
  CONTACT_CREATED: 'Contact created successfully',
  CONTACT_CREATE_ERROR: 'Contact create error',
  CONTACT_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this customer contact?',
  CONTACT_EMAIL_EXISTS: 'Customer contact email ({{email}}) already exists',
  CONTACT_PRIMARY_MODAL_WARNING: 'Are you sure you want to make this the primary contact?',
  CONTACT_UPDATED: 'Contact updated successfully',
  CONTACT_UPDATE_ERROR: 'Contact update error',
  CONTINUE_EDITING: 'Continue editing',
  CREATE_CUSTOMER: 'Create Customer',
  CREATE_FARM_PLAN: 'Create Farm Plan',
  CROP: 'Crop',
  CROPS: 'Crops',
  CROPS_SELECTED_COUNT: '{{count}} crop(s) selected',
  CROPS_UPDATED: 'Crops successfully updated',
  CROP_COUNT: '{{count}} Crops',
  CROP_TYPE: 'Crop Type',
  CROP_ZONE: 'Crop Zone',
  CROP_ZONES: 'Crop Zones',
  CROP_ZONE_NAME: 'Crop Zone Name',
  CROP_ZONE_SENTENCE: 'Crop zone',
  CUSTOMER: 'Customer',
  CUSTOMER_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this customer?',
  CUSTOMER_CREATED: 'Customer created',
  CUSTOMER_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this customer?',
  CUSTOMER_DETAILS: 'Customer Details',
  CUSTOMER_ID: 'Customer ID',
  CUSTOMER_NAME: 'Customer Name',
  CUSTOMER_PLANNING: 'Customer Planning',
  CUSTOMER_TYPE: 'Customer Type',
  CUSTOMER_TYPE_SENTENCE: 'Customer type',
  CUSTOMER_UPDATED: 'Customer updated',
  DATE_CREATED: 'Date Created',
  DATE_OF_LAST_DELIVERABLE_SENT: 'Date of Last Deliverable Sent',
  DATE_UPDATED: 'Date Updated',
  DEACTIVATE_CUSTOMER: 'Deactivate Customer',
  DELETE_FARM: 'Delete farm',
  DELETE_NOTE: 'Delete note',
  DELETE_THIS_FARM: 'Delete this farm?',
  DUPLICATED_CUSTOMER_NAME: 'Customer Name must be unique',
  EDIT: 'Edit',
  EDIT_CUSTOMER: 'Edit Customer',
  EDIT_FARM: 'Edit Farm',
  EDIT_NOTE: 'Edit note',
  EDIT_TAGS: 'Edit tags',
  EMAIL: 'Email',
  ERROR_FETCH_ASSIGN_USERS: 'There was an error loading assign users',
  ERROR_SAVING_ASSIGN_USERS: 'There was an error saving the assign users',
  EXPORT: 'Export',
  FARM: 'Farm',
  FARMS: 'Farms',
  FARMS_COUNT: '{{count}} Farm(s)',
  FARM_CREATED: 'Farm was created successfully',
  FARM_DELETE_MODAL_WARNING: 'This action cannot be undone.',
  FARM_NAME: 'Farm Name',
  FARM_NAME_DUPLICATE: 'Farm name {{ name }} used for multiple farms!',
  FARM_UPDATED: 'Farm was updated successfully',
  FIELD: 'Field',
  FIELDS: 'Fields',
  FIELDS_COUNT: '{{count}} Fields',
  FIELD_COUNT: '1 Field',
  FIELD_NAME: 'Field Name',
  FIELD_NAME_DUPLICATE: 'Field name {{ name }} used for multiple fields!',
  FILTERS: 'Filters',
  GROWERS: 'Growers',
  HAS_ACRES: 'Has acres',
  HAS_ACRES_FALSE: 'Has no acres',
  HIDE_DETAILS: 'Hide details',
  HIDE_FIELDS_CROP_ZONES: 'Hide fields & crop zones',
  LAST_DELIVERABLE_SENT_NAME: 'Last Deliverable Sent (Name)',
  LAST_UPDATED_ON: 'Last updated on {{ date }}',
  LEGAL_NAME: 'Legal Name',
  LEGAL_NAME_SENTENCE: 'Legal name',
  MAKE_PRIMARY: 'Make primary',
  MISSING_REQUIRED_FIELD: 'Missing required field {{field}}',
  NAME: 'Name',
  NOTES: 'Notes',
  NOTE_DELETED: 'Note deleted',
  NOTE_SUCCESSFULLY_UPDATED: 'Note successfully updated',
  NO_ASSIGNED_USERS: 'No Assigned Users',
  NO_AVAILABLE_USERS: 'No users currently available!',
  NO_CROPS_TO_ADD: 'No Crops To Add',
  NO_CUSTOMERS: 'No Customers',
  NO_CUSTOMER_CONTACTS: 'No Customer Contacts',
  NO_FARMS_MSG: 'No Farms! Click Add Farm to create one',
  NO_KEEP_EDITING: 'No, keep editing',
  NO_PLANNING_PARAMS_MSG: 'No planning parameters, click Add crop to create one',
  NO_USERS: 'No users',
  PLANNING_PARAMS_UPDATED: 'Planning parameters successfully updated.',
  PRIMARY: 'Primary',
  PRODUCT_APPLICATION_RATE: 'Product Application Rate',
  PRODUCT_SKU: 'Product SKU',
  PRODUCT_SKU_NAME: 'Product SKU Name',
  PROGRAM_NAME: 'Program Name',
  POSTAL_CODE: 'Postal code',
  REMOVE_CONTACT: 'Remove contact',
  REMOVE_FARM_DIALOG_DESCRIPTION: 'This action cannot be undone.',
  REMOVE_FARM_DIALOG_TITLE: 'Delete this farm?',
  ROLE: 'Role',
  SAVE: 'Save',
  SAVED_FARM: 'The farm information for this customer has successfully been saved.',
  SEARCH: 'Search',
  SEARCH_FIELDS_CROP_ZONES: 'Search fields and crop zones',
  SET_AS_PRIMARY: 'Set as primary contact',
  SHOW_DETAILS: 'Show details',
  SHOW_FIELDS_CROP_ZONES: 'Show fields & crop zones',
  START_TYPING: 'Start typing...',
  STATUS: 'Status',
  TAGS: 'Tags',
  TOTAL_ACRES: 'Total Acres',
  TOTAL_ACRES_COUNT: 'Total Acres: {{acres}}',
  TOTAL_NUM_PASSES: 'Total Number of Passes',
  UNASSIGN: 'Unassign',
  UNASSIGN_BUSINESS_USER_WARNING: 'Are you sure you want to unassign {{name}}?',
  UNASSIGN_DIALOG_TITLE: 'Unassign user {{ user }}?',
  UNEXPECTED_ERROR: 'An unexpected error occurred.',
  UPLOAD_CUSTOMER_CSV: 'Upload CSV file with Customers and contacts using ',
  UPLOAD_FARMS_CSV: 'Upload CSV file with Farms using ',
  USERS_ASSIGNED_TO_CUSTOMER: 'Users successfully assigned to customer',
  USERS_COUNT: '{{count}} User(s)',
  USER_ROLE: 'User Role',
  USE_CROP_ZONES: 'Use Crop Zones',
  VIEW_FULL_TEXT: 'View full text',
  YES_DELETE_FARM: 'Yes, delete farm',
  ZONE_NAME_DUPLICATE: 'Zone name {{ name }} used for multiple zones!',
};
