import { Header, HSpacer, Table, TableCell, TableRow, Text, ViewRow, VSpacer } from '@design';
import { PlannedCropZone, PlannedFarm, PlannedField } from '@shared/interfaces';
import { ApiCropLogic, ApiPlanningParameter } from '@shared/interfaces/api';
import { CalculationUtility, use } from '@shared/utils';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import { useStyleSheet } from '@ui-kitten/components';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { CheckBox } from '../../../ui-components';
import { StringUtility } from '../../../utilities';
import {
  AreaSelectionProps,
} from '../CustomerPlanning/FarmPlanTab/FarmPlanForm/FarmPlanProgramAssignment';
import { ProgramCard } from './ProgramCard';

export interface FarmPlanRowDetailsProps {
  farm: PlannedFarm,
  farmIndex: number,
  onChangeSelections: (selections: AreaSelectionProps) => void,
  onEditProgram: (program: ApiCropLogic, areaId: string) => void,
  onRemoveProgram: (areaId: string) => void,
  planningParams: ApiPlanningParameter[],
  selections: { [id: string]: boolean },
}

export const FarmPlanRowDetails = ({
  farm,
  farmIndex,
  onChangeSelections,
  onEditProgram,
  onRemoveProgram,
  planningParams,
  selections,
}: FarmPlanRowDetailsProps) => {
  const styles = useStyleSheet({
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: 'color-basic-transparent-100',
    },
    noBorderBottom: {
      borderBottomWidth: 0,
    },
    borderTop: {
      borderTopWidth: 1,
      borderTopColor: 'color-basic-transparent-100',
    },
    cropContainer: {
      paddingLeft: 16,
      borderBottomWidth: 1,
      borderBottomColor: 'color-basic-transparent-100',
    },
    rowDetails: {
      paddingBottom: 32,
      paddingHorizontal: 0,
      paddingTop: 0,
      marginLeft: 50,
      borderTopWidth: 0,
    },
    tableCell: {
      padding: 0,
    },
    tableRow: {
      justifyContent: 'space-between',
    },
  });

  const [translate] = useTranslation(['farmPlans', 'growers', 'common']);
  const [currentSelections, setCurrentSelections] = useState(selections);
  const onSelectionChange = (id: string, selected: boolean) => {
    setCurrentSelections({ ...currentSelections, [id]: selected });
    onChangeSelections({ ...currentSelections, [id]: selected });
  };

  const pricedFarm = CalculationUtility.calculateFarmCosts(farm);

  const toggleField = (field: PlannedField, selected: boolean) => {
    const allCropZonesSelected = field.cropZones.reduce((acc, cropZone) => {
      return { ...acc, [cropZone.id]: selected };
    }, {});
    setCurrentSelections({ ...currentSelections, ...allCropZonesSelected, [field.id]: selected });
    onChangeSelections({ ...currentSelections, ...allCropZonesSelected, [field.id]: selected });
  };

  useEffect(() => {
    farm.growerFields?.forEach((field) => {
      if (field.cropZones.length === 0) {
        return;
      }
      if (field.cropZones.every((cz) => currentSelections[cz.id])) {
        if (!currentSelections[field.id]) {
          setCurrentSelections({ ...currentSelections, [field.id]: true });
          onChangeSelections({ ...currentSelections, [field.id]: true });
        }
      } else if (currentSelections[field.id]) {
        setCurrentSelections({ ...currentSelections, [field.id]: false });
        onChangeSelections({ ...currentSelections, [field.id]: false });
      }
    });
  }, [currentSelections, farm.growerFields, onChangeSelections]);

  const toggleCropZone = (cropZone: PlannedCropZone, selected: boolean) => {
    onSelectionChange(cropZone.id, selected);
  };

  useEffect(() => {
    if (!_.isEqual(currentSelections, selections)) {
      setCurrentSelections(selections);
    }
  // this only need to trigger if the selections change from the props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections]);

  function cropZoneDetails (field: PlannedField, fieldIndex: number) {
    if (!field.cropZones?.length) { return null; }
    return (
      <View testID="crop-zone-table">
        <Header level="4" testID="crop-zones-header" title={translate<string>('CROP_ZONES')}/>
        {field.cropZones.map((cropZone, zoneIndex) => {
          const costAndArea = use(pricedFarm.fields[field.id]?.cropZones[cropZone.id], (cost) => {
            let costText: JSX.Element = null;
            if (cost) {
              costText = <>
                { StringUtility.formatCurrency(cost) }
                <HSpacer size="3"/>
                <Text style={{ color: GrowersDarkTheme['color-basic-600'] }}>|</Text>
                <HSpacer size="3"/>
              </>;
            }
            const areaText = cropZone.areaValue + ' '
                + translate<string>(cropZone.areaUnitType.toUpperCase()).toLowerCase();
            return <Text
              category="p2"
              testID={`crop-zone-area-value-${zoneIndex}`}
            >
              {costText}
              {areaText}
            </Text>;
          });

          return (
            <React.Fragment key={cropZone.id}>
              <TableRow
                key={cropZone.id}
                noBottomBorder={zoneIndex === field.cropZones.length - 1}
                style={[
                  styles.tableRow,
                  zoneIndex > 0 && styles.borderTop,
                ]}
                testID={`crop-zone-name-and-toggle-row-${zoneIndex}`}
              >
                <TableCell style={styles.tableCell} testID="crop-zone-name-and-toggle">
                  <ViewRow>
                    <CheckBox
                      checked={!!currentSelections[cropZone.id]}
                      onChange={(on: boolean) => toggleCropZone(cropZone, on)}
                      testID={`${cropZone.zoneName}-assign-logic-checkbox`}
                    />
                    <HSpacer size="5"/>
                    <Text category="p2" testID="crop-zone-name">{cropZone.zoneName}</Text>
                  </ViewRow>
                </TableCell>
                <TableCell testID="field-area">
                  {costAndArea}
                </TableCell>
              </TableRow>
              {cropZone.cropLogic && (
                <>
                  <ProgramCard
                    area={cropZone}
                    indexes={[farmIndex, fieldIndex, zoneIndex]}
                    onEditProgram={() => {
                      onEditProgram(cropZone.cropLogic, cropZone.id);
                    }}
                    onRemoveProgram={onRemoveProgram}
                    planningParameters={planningParams}
                    testID={`program-card-row-${zoneIndex}`}
                  />
                  <VSpacer size="8"/>
                </>
              )}
            </React.Fragment>
          );
        })}
      </View>
    );
  }

  const Fields = farm?.growerFields && farm.growerFields.map((field, fieldIndex) => {
    const fieldCost = pricedFarm.fields[field.id]?.cost;

    let costAndArea = <>
      {`${field.areaValue} ${translate<string>(field.areaUnitType.toUpperCase()).toLowerCase()}`}
    </>;
    if (fieldCost) {
      let costText = <>
        { StringUtility.formatCurrency(fieldCost) }
        <HSpacer size="3"/>
        <Text style={{ color: GrowersDarkTheme['color-basic-600'] }}>|</Text>
        <HSpacer size="3"/>
      </>;
      costAndArea = <>
        {costText}
        {costAndArea}
      </>;
    }

    return (
      <Table key={field.id} testID={`farm-plan-fields-crop-zones-table-${fieldIndex}`}>
        <TableRow
          noBottomBorder={!!field.cropLogic}
          rowDetailStyle={styles.rowDetails}
          rowDetails={cropZoneDetails(field, fieldIndex)}
          style={[
            styles.tableRow,
            field.cropZones.length === 0 && styles.borderBottom,
            !!field.cropLogic && styles.noBorderBottom,
          ]}
          testID={`farm-plan-fields-crops-zone-row-${fieldIndex}`}
        >
          <TableCell testID="field-name-and-toggle">
            <ViewRow>
              <CheckBox
                checked={!!currentSelections[field.id]}
                onChange={(on: boolean) => toggleField(field, on)}
                testID={`${field.fieldName}-assign-logic-checkbox`}
              />
              <HSpacer size="5"/>
              <Text category="p2" testID="field-name">{field.fieldName}</Text>
            </ViewRow>
          </TableCell>
          <TableCell testID={`field-area-${fieldIndex}`}>
            <Text
              category="p2"
              testID="field-area-value"
            >
              {costAndArea}
            </Text>
          </TableCell>
        </TableRow>
        {field.cropLogic && (
          <View style={styles.cropContainer}>
            <ProgramCard
              area={field}
              areaType="field"
              indexes={[farmIndex, fieldIndex]}
              onEditProgram={() => {
                onEditProgram(field.cropLogic, field.id);
              }}
              onRemoveProgram={onRemoveProgram}
              planningParameters={planningParams}
              testID="program-card"
            />
            <VSpacer size="8"/>
          </View>
        )}
      </Table>
    );
  });

  return <>{Fields}</>;
};
