import React, { useState } from 'react';
import { Icon, Button, Card, StepIndicator } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const DATA = [1, 2, 3, 4, 5];
export const StepIndicatorDemo = () => {
  const [pageNumber, setPageNumber] = useState(0);

  const nextPage = () => {
    setPageNumber(
      pageNumber < (DATA.length - 1)
        ? pageNumber + 1
        : pageNumber,
    );
  };

  const prevPage = () => {
    setPageNumber(
      pageNumber > 0
        ? pageNumber - 1
        : pageNumber,
    );
  };
  return (
    <Card testID="test-card">
      <DemoContainer label="Step Indicator Demo">
        <DemoBlock style={{ justifyContent: 'center' }}>
          <StepIndicator currentPage={pageNumber} data={DATA} />
        </DemoBlock>
        <DemoBlock style={{ justifyContent: 'space-around', marginBottom: 24 }}>
          <Button
            accessoryRight={(props) => <Icon {...props} name="ArrowUp" testID="test-button-icon" />}
            onPress={prevPage}
            size="medium"
            testID="test-button"
          >
            Prev
          </Button>
          <Button
            accessoryLeft={(props) => <Icon {...props} name="ArrowDown" testID="test-button-icon" />}
            onPress={nextPage}
            size="medium"
            testID="test-button"
          >
            Next
          </Button>
        </DemoBlock>
      </DemoContainer>
    </Card>
  );
};
