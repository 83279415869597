import { Select, SelectItem, useToast } from '@design';
import { CropSubType, CropType } from '@shared/enums';
import {
  ApiCrop,
  ApiCropLogicPass,
  ApiCropLogicPassComponent,
  CropLogicEndpoint,
} from '@shared/interfaces/api';
import { CropLogicUtilities } from '@shared/utils';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessCrops } from '../../../../hooks/useBusinessCrops';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

interface CropSelectProps {
  crop: ApiCrop,
  onUpdateCrop: (
    crop: ApiCrop,
    passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[],
  ) => void,
  passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[],
  testID: string,
}

export const CropSelect = ({
  crop,
  onUpdateCrop,
  passes,
  testID,
}: CropSelectProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const { createToast } = useToast();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [newCrop, setNewCrop] = useState<ApiCrop>(undefined);
  const [conflictComponents, setConflictComponents] = useState([]);
  const { currentBusinessId } = useAuthentication();
  const { businessCrops, isBusinessCropsLoading } = useBusinessCrops({
    businessId: currentBusinessId,
    onError: () => createToast(translate<string>('ERROR_LOADING_CROP_TYPES')),
  });

  const getCropName = ({ cropType, subType }: ApiCrop) => (
    `${translate(cropType)} | ${translate(subType)}`
  );

  const checkForNonMatchingComponents = useCallback((
    newType: CropType,
    newSubType: CropSubType,
  ) => passes.flatMap(({ components }) => (
    components.filter((c) => {
      const component = c as ApiCropLogicPassComponent;
      if (component.product?.seedProduct?.cropType) {
        const typeMismatch = component.product.seedProduct.cropType !== newType;
        const subTypeMismatch = component.product.seedProduct.cropSubType !== newSubType;
        return typeMismatch || subTypeMismatch;
      }

      if (component.tankMix) {
        return component.tankMix.crops.every(({ cropType, cropSubType }) => (
          cropType !== newType || cropSubType !== newSubType
        ));
      }

      return false;
    })
  )), [passes]);

  const handleSelect = useCallback((indexPath: IndexPath | IndexPath[]) => {
    const currentCrop = businessCrops[(indexPath as IndexPath).row];
    if (!crop || (crop.cropType === currentCrop.cropType && crop.subType === currentCrop.subType)) {
      onUpdateCrop(currentCrop, passes);
    } else {
      const nonMatchingComponents = checkForNonMatchingComponents(
        currentCrop.cropType,
        currentCrop.subType,
      );

      if (nonMatchingComponents.length > 0) {
        setNewCrop(currentCrop);
        setConflictComponents(nonMatchingComponents);
        setConfirmModalVisible(true);
      } else {
        onUpdateCrop(currentCrop, passes);
      }
    }
  }, [businessCrops, checkForNonMatchingComponents, crop, onUpdateCrop, passes]);

  return (
    <>
      <Select
        isRequired
        label={translate<string>('CROP_TYPE_INPUT')}
        onSelect={isBusinessCropsLoading ? undefined : handleSelect}
        testID={`${testID}-selector`}
        value={(crop?.cropType) ? getCropName(crop) : ''}
      >
        {isBusinessCropsLoading ? (
          <SelectItem
            testID={`${testID}-selector-dropdown-value-loading`}
            title={translate<string>('LOADING')}
          />
        ) : businessCrops?.map((businessCrop, index) => (
          <SelectItem
            key={`${businessCrop.cropType}${businessCrop.subType}`}
            testID={`${testID}-selector-dropdown-value-${index}`}
            title={getCropName(businessCrop)}
          />
        ))}
      </Select>
      {confirmModalVisible && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('CONFIRM')}
          messageText={translate('CHANGE_CROP_TYPE_CONFIRM', {
            cropName: `${translate(crop.cropType)} - ${translate(crop.subType)}`,
            newCropName: `${translate(newCrop.cropType)} - ${translate(newCrop.subType)}`,
          })}
          onCancel={() => setConfirmModalVisible(false)}
          onConfirm={() => {
            setConfirmModalVisible(false);

            const newPasses = CropLogicUtilities.removeComponentsByCriteria(
              passes,
              (component) => _.includes(conflictComponents, component),
            ).filter(({ components }) => components.length > 0);

            onUpdateCrop(newCrop, newPasses);
          }}
          status="warning"
          visible
        />
      )}
    </>
  );
};
