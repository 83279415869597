import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { QueryKeys } from '../constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DiscountEndpoint } from '@shared/interfaces/api';
import { BusinessApi } from '../utilities/api/BusinessApi';
import { useTranslation } from 'react-i18next';
import { useToast } from '@design';
import { DetailedApiError } from 'utilities/api/DetailedApiError';
import { SharedConfig } from '@shared/constants';

export type UseBusinessDiscountsListProps = {
  areBusinessDiscountsFetched: boolean,
  businessDiscountsListData: DiscountEndpoint.List.Response,
  isBusinessDiscountsDataLoading: boolean,
};

export const conflictErrorCode = '409';

const defaultFilters: DiscountEndpoint.List.Query = {
  limit: SharedConfig.MAX_PAGE_LIMIT,
};

export const useBusinessDiscounts = (filters?: DiscountEndpoint.List.Query) => {
  const { currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const [translate] = useTranslation(['businesses']);
  const { createToast } = useToast();
  const {
    data: businessDiscountsListData,
    isFetched: areBusinessDiscountsFetched,
    isLoading: isBusinessDiscountsDataLoading,
    isError: hasDiscountsError,
  } = useQuery(
    [QueryKeys.BUSINESS_DISCOUNTS, currentBusinessId, filters],
    () => BusinessApi.listBusinessDiscounts(currentBusinessId, filters ?? defaultFilters), {
      enabled: !!currentBusinessId,
      onError: () => {
        createToast({
          children: translate('DISCOUNTS_GET_ERROR'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      },
    },
  );

  const businessDiscountsList = {
    areBusinessDiscountsFetched,
    businessDiscountsListData,
    hasDiscountsError,
    isBusinessDiscountsDataLoading,
  };

  const createBusinessDiscount = useMutation(
    (discount: DiscountEndpoint.Save.Request) =>
      (BusinessApi.createBusinessDiscount(currentBusinessId, discount)), {
      onError: (err: DetailedApiError) => {
        if (err.code !== conflictErrorCode) {
          createToast({
            children: err.message,
            status: 'warning',
            testID: 'toast-content-element',
          });
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.BUSINESS_DISCOUNTS]);
        createToast({
          children: translate('DISCOUNT_CREATED'),
          status: 'success',
          testID: 'toast-content-element',
        });
      },
    });

  const deleteBusinessDiscount = useMutation((discountId: string) => {
    return BusinessApi.deleteBusinessDiscount(currentBusinessId, discountId);
  }, {
    onError: () => {
      createToast({
        children: translate('DISCOUNT_DELETE_ERROR'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.BUSINESS_DISCOUNTS]);
      createToast({
        children: translate('DISCOUNT_DELETE_SUCCESS'),
        status: 'success',
        testID: 'toast-content-element',
      });
    },
  });

  const loading = businessDiscountsList.isBusinessDiscountsDataLoading || createBusinessDiscount.isLoading || deleteBusinessDiscount.isLoading;

  return {
    businessDiscountsList,
    createBusinessDiscount,
    deleteBusinessDiscount,
    isBusinessDiscountsLoading: loading,
  };
};