import _ from 'lodash';
import { Reports } from '@shared/enums';
import qs from 'qs';
import { Client } from './Client';

const reportsUrl = (reportName: Reports) => `report/${reportName}`;

export class ReportingApi {
  static getAcceptedReport (businessId: string) {
    const query = qs.stringify({ businessId }, { arrayFormat: 'comma' });
    return Client(`${reportsUrl(Reports.AcceptedReport)}?${query}`);
  }

  static getBusinessPricingReport (businessId: string) {
    const query = qs.stringify({ businessId }, { arrayFormat: 'comma' });
    return Client(`${reportsUrl(Reports.BusinessPricingReport)}?${query}`);
  }

  static getSalesInsightReport (businessId: string) {
    const query = qs.stringify({ businessId }, { arrayFormat: 'comma' });
    return Client(`${reportsUrl(Reports.SalesInsightReport)}?${query}`);
  }
}
