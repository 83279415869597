import { Button, HSpacer, Icon } from '@design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { testId } from '../../../../../utilities';

const styles = StyleSheet.create({
  actionButtons: {
    position: 'absolute',
    right: 40,
    bottom: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  footer: { flexDirection: 'row' },
});

export interface FooterProps {
  onDownloadPdf(): void,
  onClose?(): void,
}
export function Footer ({
  onDownloadPdf,
  onClose,
}: FooterProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);

  return (
    <>
      <View {...testId('footer')} style={styles.actionButtons}>
        {!!onClose && (
          <Button
            appearance="outline"
            design="floating"
            onPress={onClose}
            size="large"
            status="basic"
            {...testId('footer-close-button')}
          >
            {translate<string>('CLOSE')}
          </Button>
        )}
        <HSpacer size="8" />
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Download" testID="footer-download-pdf-button-icon" />}
          design="floating"
          onPress={onDownloadPdf}
          size="large"
          {...testId('footer-download-pdf-button')}
        >
          {translate<string>('DOWNLOAD_PDF')}
        </Button>
      </View>
    </>
  );
}
