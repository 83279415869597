import { Modify } from '@shared/interfaces/api';
import React, { FC, forwardRef, ReactNode, Ref } from 'react';
import {
  Button as UIKButton,
  ButtonProps as UIKButtonProps,
  StyleType,
} from '@ui-kitten/components';
import { Text } from 'react-native';
import { Size } from '@theme/variant-interfaces/Size';
import { styled } from '@ui-kitten/components/theme';

export type Appearance = 'filled' | 'outline' | 'ghost';
export const Appearances: Appearance[] = [
  'filled', 'outline', 'ghost',
];

export type Design = 'standard' | 'floating';
export const Designs : Design[] = [
  'standard', 'floating',
];

const textMargins = {
  'tiny': 4,
  'small': 4,
  'medium': 6,
  'large': 8,
  'giant': 12,
} as const;

export interface ButtonProps extends Modify<UIKButtonProps, { children?: ReactNode }> {
  appearance?: Appearance,
  capitalize?: boolean,
  design?: Design,
  ref?: Ref<UIKButton>,
  size?: Size,
  testID: string,
}

const getComponentStyle = (source: StyleType) => {
  const { elevation } = source;

  return {
    elevation: { ...elevation },
  };
};

const ButtonRaw = forwardRef(({
  capitalize = false,
  children,
  eva,
  ...otherProps
}: ButtonProps, ref: Ref<UIKButton>) => {
  const evaStyle = getComponentStyle(eva.style);

  return (
    <UIKButton
      ref={ref}
      {...otherProps}
      style={[evaStyle.elevation, otherProps.disabled && { pointerEvents: 'none' }, otherProps?.style]}
    >
      {({ style, ...otherEvaProps }) => {
        if (children === undefined) {
          return null;
        }

        return (
          <Text
            style={[
              style,
              capitalize && { textTransform: 'capitalize' },
              otherProps.accessoryLeft && { marginLeft: textMargins[otherProps.size] },
              otherProps.accessoryRight && { marginRight: textMargins[otherProps.size] },
            ]}
            {...otherEvaProps}
          >
            {children}
          </Text>
        );
      }}
    </UIKButton>
  );
});

export const Button: FC<ButtonProps> = styled('Button')(ButtonRaw);
