import {
  Button,
  Card,
  CenteredSpinner,
  DataPoint,
  Header,
  HSpacer,
  Icon,
  Text,
  TextLink,
  ToastProps,
  useFeatureFlags,
  useToast,
  VSpacer,
} from '@design';
import {
  ApiForm,
  ApiGrower,
  ApiGrowerContact,
  FormEndpoint,
  GrowerContactEndpoint,
} from '@shared/interfaces/api';
import { DateTime } from 'luxon';
import React, { Dispatch, FC, Fragment, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useParams } from '../../../router';
import { GrowerApi } from '../../../utilities/api';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { CustomerNoteModal } from '../CustomerDetails/CustomerNoteModal';
import { SubNavigation } from '@design/SubNavigation/SubNavigation';
import { ContactCard } from '../../components/shared/ContactCard/ContactCard';
import { ContactModal } from './ContactModal';
import { CustomerModal } from '../CustomerDetails';
import { FeatureFlags } from '@shared/enums';
import { PropertyGroupApi } from '../../../utilities/api/PropertyGroupApi';
import { SelectedPropertyGroup } from './SelectedPropertyGroup';

interface CustomerInfoTabProps {
  customer: ApiGrower,
}

interface TogglePrimaryProps {
  contact: ApiGrowerContact,
  id: ApiGrower['id']
}

interface ToggleActiveStatusProps {
  contactId: string,
  isActive: boolean,
}

const defaultSubNavigationLabels = ['Details', 'Notes'];

const styles = StyleSheet.create({
  emptyState: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '64px',
  },
  propertiesCard: {
    paddingBottom: 24,
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
});

export const CustomerOverviewTab: FC<CustomerInfoTabProps> = ({ customer }) => {
  const [translate] = useTranslation(['growers', 'common']);
  const { grower: customerId } = useParams<{ grower: string }>();
  const [contacts, setContacts] = useState<ApiGrowerContact[]>(null);
  const [customerNoteViewMode, setCustomerNoteViewMode] = useState<boolean>(false);
  const [isEditContactMode, setIsEditContactMode] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<ApiGrowerContact>(null);
  const [showActiveModal, setShowActiveModal] = useState<boolean>(false);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [showCustomerNoteModal, setShowCustomerNoteModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showMakePrimaryModal, setShowMakePrimaryModal] = useState<boolean>(false);
  const [showViewFullText, setShowViewFullText] = useState<boolean>(false);
  const [subNavigationLabels, setSubNavigationLabels] = useState(defaultSubNavigationLabels);
  const [selectedSubNavigationLabel, setSelectedSubNavigationLabel] = useState(defaultSubNavigationLabels[0]);
  const [propertyGroups, setPropertyGroups] = useState<ApiForm[]>([]);
  const scrollRef = useRef<ScrollView>();
  const queryClient = useQueryClient();
  const { createToast } = useToast();
  const { isFlagOn } = useFeatureFlags();
  const isPropertyGroupsFeatureFlagOn = isFlagOn(customer.businessId, FeatureFlags.PROPERTY_GROUPS);
  const selectedPropertyGroup = propertyGroups.find((group) => group.name === selectedSubNavigationLabel);

  useQuery<FormEndpoint.List.Response, Error>(
    [QueryKeys.PROPERTY_GROUP_LIST, customer.businessId],
    () => PropertyGroupApi.getPropertyGroups(customer.businessId),
    {
      enabled: isPropertyGroupsFeatureFlagOn && !!customer.businessId,
      onError: () => {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR'),
          status: 'warning',
          testID: 'property-group-edit-error-toast',
        });
      },
      onSuccess: (forms) => {
        setPropertyGroups(forms);
        const labelsWithPropertyGroups = [...defaultSubNavigationLabels];
        forms.forEach((form) => labelsWithPropertyGroups.push(form.name));
        setSubNavigationLabels(labelsWithPropertyGroups);
      },
    },
  );

  const successBannerProps = (bannerText: string): ToastProps => ({
    children: (
      <View>
        <Text>
          {bannerText}
        </Text>
      </View>
    ),
    status: 'success',
    testID: 'toast-content-element',
  });

  const bannerProps = (bannerText: string): ToastProps => ({
    children: (
      <View>
        <Text>
          {translate<string>('UNEXPECTED_ERROR', { error: bannerText })}
        </Text>
      </View>
    ),
    status: 'danger',
    testID: 'toast-content-element',
  });

  const refetch = async () => {
    await queryClient.invalidateQueries([QueryKeys.GROWER_CONTACTS]);
    await queryClient.invalidateQueries([QueryKeys.GROWER]);
  };

  const toggleModal = async (setShowModal: Dispatch<SetStateAction<boolean>>): Promise<void> => {
    try {
      await refetch();
    } catch (error) {
      createToast(bannerProps((error as Error).message));
    } finally {
      setShowModal(false);
    }
  };

  const toggleActiveStatus = useMutation(
    ({ contactId, isActive }: ToggleActiveStatusProps): Promise<ApiGrowerContact> => (
      GrowerApi.updateGrowerContact(contactId, { isActive: !isActive })
    ), {
      onError: (err: Error) => {
        createToast(bannerProps(err.message));
      },
      onSuccess: () => toggleModal(setShowActiveModal),
    },
  );

  const togglePrimary = useMutation(
    ({ contact, id }: TogglePrimaryProps) => (
      GrowerApi.setGrowerContactAsPrimary(contact.id, id)
    ), {
      onError: (err: Error) => {
        createToast(bannerProps(err.message));
      },
      onSuccess: () => toggleModal(setShowMakePrimaryModal),
    },
  );

  const formatContact = (contact) => {
    for (const property in contact) {
      if (!contact[property]) {
        contact[property] = '';
      }
    }
    return contact;
  };
  const getCustomerContacts = async () => (
    GrowerApi.getGrowerContacts(
      customerId,
      {
        isActive: ['true', 'false'],
        limit: 100,
        sort: GrowerContactEndpoint.List.Sort.isPrimary,
        sortDesc: true,
      },
    ));
  const { isFetching } = useQuery(
    [QueryKeys.GROWER_CONTACTS, customer, customerId],
    getCustomerContacts, {
      enabled: !!customerId && !!customer,
      onError: (error: Error) => createToast(bannerProps(error.message)),
      onSuccess: (results) => {
        setContacts(results.data.map((contact, idx) => ({
          ...formatContact(contact),
          rowId: `row${idx}|${contact.id}`,
        })));
        scrollRef.current?.scrollTo({
          y: 0,
          animated: true,
        });
      },
    },
  );

  const createContact = async (newContact: GrowerContactEndpoint.Create.Request) => {
    const growerContacts = contacts.map((contact) => {
      if (newContact.isPrimary) {
        contact.isPrimary = false;
      } else {
        contact.isPrimary = contact.isPrimary === true ? true : false;
      }
      contact.address1 = contact.address1 || null;
      contact.address2 = contact.address2 || null;
      contact.city = contact.city || null;
      contact.country = contact.country || 'US';
      contact.email = contact.email || null;
      contact.postalCode = contact.postalCode || null;
      contact.state = contact.state || null;
      contact.telephone = contact.telephone || null;
      return contact;
    });

    try {
      await GrowerApi.modifyGrowerContacts({
        growerContacts: [...growerContacts, newContact],
        growerId: customer.id,
      }).then(() => {
        setShowContactModal(false);
        createToast({
          children: translate<string>('CONTACT_CREATED'),
          status: 'success',
          testID: 'contact-created-success-toast',
        });
        queryClient.invalidateQueries([QueryKeys.GROWER_CONTACTS]);
      });
    } catch (exception) {
      createToast({
        children: translate<string>('CONTACT_CREATE_ERROR'),
        status: 'warning',
        testID: 'contact-updated-error-toast',
      });
    }
  };

  const updateContact = async (contactUpdate: GrowerContactEndpoint.Update.Request) => {
    try {
      await GrowerApi.updateGrowerContact(selectedContact.id, contactUpdate).then(() => {
        if (contactUpdate.isPrimary) GrowerApi.setGrowerContactAsPrimary(selectedContact.id, customer.id);
        setShowContactModal(false);
        createToast({
          children: translate<string>('CONTACT_UPDATED'),
          status: 'success',
          testID: 'contact-updated-success-toast',
        });
        queryClient.invalidateQueries([QueryKeys.GROWER_CONTACTS]);
      });
    } catch (exception) {
      createToast({
        children: translate<string>('CONTACT_UPDATE_ERROR'),
        status: 'warning',
        testID: 'contact-updated-error-toast',
      });
    }
  };

  const formatDate = (isoDateString) => {
    return isoDateString
      ? DateTime.fromISO(isoDateString).toFormat('MMM dd, t')
      : '---';
  };

  const DetailsTab = () => (
    <>
      <View>
        {/* Details */}
        <View style={{ flex: 1 }}>
          <Header
            childrenStyle={{ paddingTop: 0 }}
            level="3"
            testID="details-header"
            title={translate('DETAILS')}
          >
            <Button
              accessoryLeft={(props) => (
                <Icon name="Edit" testID="edit-customer-button-icon" {...props} />
              )}
              appearance="ghost"
              onPress={() => setShowCustomerModal(true)}
              size="small"
              status="primary"
              testID="edit-customer-button"
            >
              <Text appearance="hint" category="p2" status="primary">
                {translate<string>('EDIT')}
              </Text>
            </Button>
          </Header>
          <VSpacer size="3" />
          <Card testID="customer-info-card">
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1 }}>
                <DataPoint
                  label={translate<string>('LEGAL_NAME_SENTENCE')}
                  testID="customer"
                >
                  {customer?.legalName}
                </DataPoint>
                <VSpacer size="7" />
                <DataPoint
                  label={translate<string>('CUSTOMER_TYPE_SENTENCE')}
                  testID="customer-type"
                >
                  {translate(customer?.growerType)}
                </DataPoint>
              </View>
              <View style={{ flex: 1 }}>
                <DataPoint
                  label={translate<string>('STATE')}
                  testID="customer-state"
                >
                  {customer?.state}
                </DataPoint>
                <VSpacer size="7" />
                <DataPoint
                  label={translate<string>('CUSTOMER_ID')}
                  testID="customer-id"
                >
                  {`${customer?.externalId || ''} ${customer?.externalDisplayId ? `(${customer.externalDisplayId})` : ''}`}
                </DataPoint>
              </View>
              <View style={{ flex: 1 }}>
                <DataPoint
                  label={translate<string>('COUNTY')}
                  testID="customer-county"
                >
                  {customer?.county}
                </DataPoint>
              </View>
            </View>
          </Card>
        </View>
      </View>
      <VSpacer size="9" />
      {/* Contacts */}
      <View>
        <Header
          childrenStyle={{ paddingTop: 0 }}
          level="3"
          testID="contacts-header"
          title={translate('CONTACTS')}
        >
          <Button
            accessoryLeft={(props) => (
              <Icon name="Plus" testID="add-contact-modal-edit-contacts-button-icon" {...props} />
            )}
            appearance="ghost"
            onPress={() => {
              setSelectedContact(null);
              setIsEditContactMode(false);
              setShowContactModal(true);
            }}
            size="small"
            status="primary"
            testID="add-contact-modal-edit-contacts-button"
          >
            <Text appearance="hint" category="p2" status="primary">
              {translate<string>('ADD_CONTACT_ACTION')}
            </Text>
          </Button>
        </Header>
        <VSpacer size="3" />
        {contacts?.length > 0 ? contacts.map((contact) => (
          <Fragment key={contact.id}>
            <ContactCard
              canEdit
              contact={contact}
              customer={customer}
              onEdit={() => {
                setSelectedContact(contact);
                setIsEditContactMode(true);
                setShowContactModal(true);
              }}
            />
            <VSpacer size="4" />
          </Fragment>
        )) : (
          <View style={styles.emptyState}>
            {isFetching ? (
              <CenteredSpinner testID="loading-spinner" />
            ) : (
              <Text category="p2" testID="no-data-message">
                {translate('NO_CUSTOMER_CONTACTS')}
              </Text>
            )}
          </View>
        )}
      </View>
    </>
  );

  const NotesTab = () => (
    <>
      <View style={{ flex: 3 }}>
        <Header level="3" testID="notes-header" title={translate('NOTES')}>
          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
            {customer?.note && (
              <Button
                accessoryRight={(props) => (
                  <Icon name="Edit" testID="show-customer-note-edit-button-icon" {...props} />
                )}
                appearance="ghost"
                onPress={() => {
                  setCustomerNoteViewMode(false);
                  setShowCustomerNoteModal(true);
                }}
                size="small"
                status="basic"
                testID="show-customer-note-edit-button"
              >
                <Text appearance="hint" category="p2">
                  {translate<string>('EDIT')}
                </Text>
              </Button>
            )}
          </View>
        </Header>
        <Card testID="customer-info-note-card">
          <View style={{ maxHeight: 350 }}>
            {customer?.note ? (
              <>
                <VSpacer size="3" />
                <Text appearance="hint" category="c1" testID="customer-note-last-updated-on">
                  {translate<string>('LAST_UPDATED_ON', { date: formatDate(customer?.noteUpdatedAt) })}
                </Text>
                <VSpacer size="7" />
                <Text
                  category="p2"
                  numberOfLines={10}
                  onLayout={(event) => {
                    setShowViewFullText(event.nativeEvent.layout.height > 200);
                  }}
                  testID="customer-note"
                >
                  {customer?.note}
                </Text>
                <VSpacer size="5" />
                {showViewFullText && (
                  <TextLink
                    appearance="secondary"
                    category="c1"
                    onPress={() => {
                      setCustomerNoteViewMode(true);
                      setShowCustomerNoteModal(true);
                    }}
                    testID="customer-note-view-full-text"
                  >
                    {translate<string>('VIEW_FULL_TEXT')}
                  </TextLink>
                )}
              </>
            ) : (
              <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <Button
                  accessoryLeft={(props) => <Icon name="Plus" testID="add-note-button" {...props} />}
                  appearance="outline"
                  onPress={() => {
                    setCustomerNoteViewMode(false);
                    setShowCustomerNoteModal(true);
                  }}
                  size="small"
                  testID="add-note-button"
                >
                  {translate<string>('ADD_NOTE')}
                </Button>
              </View>
            )}
          </View>
        </Card>
      </View>
    </>
  );

  return (
    <ScrollView ref={scrollRef}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {/* SubNavigation */}
        <View style={{ width: 200 }}>
          <SubNavigation
            labels={subNavigationLabels}
            onChange={setSelectedSubNavigationLabel}
            selectedLabel={selectedSubNavigationLabel}
            testID="customer-overview-sub-navigation"
          />
        </View>
        <HSpacer size="7" />
        {/* SubNavigation Content */}
        <View style={{ flex: 1 }}>
          {selectedSubNavigationLabel === subNavigationLabels[0] && <DetailsTab />}
          {selectedSubNavigationLabel === subNavigationLabels[1] && <NotesTab />}
          {selectedPropertyGroup && (
            <SelectedPropertyGroup
              customerId={customerId}
              selectedPropertyGroup={selectedPropertyGroup}
            />
          )}
        </View>
      </View>
      {!isFetching && showContactModal &&
        <ContactModal
          data={selectedContact}
          growerId={customer.id}
          isEdit={isEditContactMode}
          isOnlyContact={
            contacts.length === 0 || (isEditContactMode && contacts.length === 1)
          }
          onCancel={() => setShowContactModal(false)}
          onCreate={createContact}
          onUpdate={updateContact}
        />
      }
      {showCustomerNoteModal && (
        <CustomerNoteModal
          note={customer?.note}
          onClose={() => setShowCustomerNoteModal(false)}
          onUpdateNote={async (note) => {
            const updatedNote = await GrowerApi.updateGrower(
              customer.id,
              { note },
            );
            customer.note = updatedNote.note;
            customer.noteUpdatedAt = updatedNote.noteUpdatedAt;
            createToast(
              successBannerProps(translate<string>(
                customer.note === null
                  ? 'NOTE_DELETED'
                  : 'NOTE_SUCCESSFULLY_UPDATED',
              )),
            );
            setShowCustomerNoteModal(false);
          }}
          viewMode={customerNoteViewMode}
          visible={showCustomerNoteModal}
        />
      )}
      {showMakePrimaryModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('MAKE_PRIMARY')}
          messageText={translate('CONTACT_PRIMARY_MODAL_WARNING')}
          onCancel={() => setShowMakePrimaryModal(false)}
          onConfirm={() => togglePrimary.mutate({ contact: selectedContact, id: customerId })}
          title={translate('CHANGE_PRIMARY_TITLE')}
          visible={showMakePrimaryModal}
        />
      )}
      {showActiveModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate(selectedContact?.isActive ? 'DEACTIVATE' : 'ACTIVATE')}
          messageText={translate(selectedContact?.isActive
            ? 'CONTACT_DEACTIVATE_MODAL_WARNING' : 'CONTACT_ACTIVATE_MODAL_WARNING')}
          onCancel={() => setShowActiveModal(false)}
          onConfirm={() => {
            toggleActiveStatus.mutate({
              contactId: selectedContact?.id,
              isActive: selectedContact?.isActive,
            });
          }}
          title={translate(selectedContact?.isActive ? 'DEACTIVATE_CUSTOMER' : 'ACTIVATE_CUSTOMER')}
          visible={showActiveModal}
        />
      )}
      {showCustomerModal && (
        <CustomerModal
          growerId={customer.id}
          modalToggle={() => toggleModal(setShowCustomerModal)}
        />
      )}
    </ScrollView>
  );
};
