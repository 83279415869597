import {
  BannerProps,
  Button,
  Card,
  Header,
  ModalSpinner,
  Text,
  ToastProps,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import { ProductCategory } from '@shared/enums';
import {
  ApiProduct,
  ApiProductCategory,
  PaginatedData,
  ProductEndpoint,
} from '@shared/interfaces/api';
import { BannerStatus, Status } from '@theme/variant-interfaces/Status';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useMutation, useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { ScreenSizes, useScreenSizing } from '../../../../hooks/useScreenSizing';
import { BusinessApi, ProductApi } from '../../../../utilities/api';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { PriceTypeCard } from './PriceTypeCard';
import { ProductCategoryListItem } from './ProductCategoryListItem';
import { DiscountsTable } from './DiscountsTable';

const styles = StyleSheet.create({
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
  },
});

export interface ProductCategoryTabProps {
  businessId: string,
  canModifyBusiness: boolean,
  onPageChange: () => void,
}

type SubCategoryItemsProps = {
  productCategory?: ProductCategory,
  name: string
};

const CATEGORIES_ORDER: ProductCategory[] = [
  ProductCategory.SEED,
  ProductCategory.CHEMICAL,
  ProductCategory.FERTILIZER,
  ProductCategory.OTHER,
];

const isIPadPortrait = () => {
  if (window.matchMedia('(orientation: portrait)').matches) {
    return true;
  }
  return false;
};

export const ProductSettingsTab = ({ businessId, canModifyBusiness, onPageChange }: ProductCategoryTabProps) => {
  const [translate] = useTranslation(['businesses', 'common', 'errors']);
  const { currentBusinessId } = useAuthentication();
  const [categories, setCategories] = useState<ApiProductCategory[] | null>(null);
  const screenSize = useScreenSizing();
  const isDesktop = screenSize === ScreenSizes.Wide;
  const [duplicateError, setDuplicateError] = (
    useState<ApiProductCategory | ProductEndpoint.Category.Save.ProductCategory>(null)
  );
  const [subcategoryItems, setSubcategoryItems] = useState<SubCategoryItemsProps>(null);
  const [confirmRemovalModal, setConfirmRemovalModal] = useState(false);
  const [businessCategoryId, setBusinessCategoryId] = useState<string[]>([]);
  const [noProductsAffected, setNoProductsAffected] = useState(0);

  const orderCategories = (toSort: ApiProductCategory[]) => {
    return toSort
      .filter(({ category }) => category !== 'PRODUCT_MIX')
      .sort((a, b) => {
        const aIndex = CATEGORIES_ORDER.indexOf(a.category);
        const bIndex = CATEGORIES_ORDER.indexOf(b.category);
        return aIndex > bIndex ? 1 : -1;
      });
  };
  const { createBanner } = useBanner();
  const bannerProps = useCallback(
    (
      bannerText: string,
      status: BannerStatus = 'warning',
    ): BannerProps => ({
      children: bannerText,
      status,
      testID: 'toast-banner',
      actionAccessory: ({ dismissProps }) => (
        <View style={styles.banner}>
          <Button
            {...dismissProps}
            appearance="ghost"
            size="small"
            status="basic"
            testID="message-dismiss-button"
          >
            {translate<string>('DISMISS')}
          </Button>
        </View>
      ) }), [translate],
  );

  const { createToast } = useToast();
  const toastProps = useCallback((bannerText: string, status: Status = 'warning'): ToastProps => ({
    children: (
      <View>
        <Text>
          {bannerText}
        </Text>
      </View>
    ),
    status,
    testID: 'toast-content-element',
  }), []);

  useEffect(() => {
    if (duplicateError) {
      createBanner(bannerProps(translate('BUSINESS_PRODUCT_SUBCATEGORIES_DUPLICATE_ERROR')));
    }
    setDuplicateError(null);
  }, [bannerProps, createBanner, duplicateError, translate]);

  const fetchBusinessProductCategories = async () => (
    BusinessApi.getBusinessProductCategories(businessId)
  );
  const { isLoading } = useQuery(
    [
      QueryKeys.BUSINESS_CATEGORIES,
      businessId,
      currentBusinessId,
    ],
    fetchBusinessProductCategories, {
      onError: (err: Error) => createToast(toastProps(
        translate('UNEXPECTED_ERROR', { error: err.message }),
      )),
      onSuccess: (businessCategories) => {
        const sortedBusinessCategories = orderCategories(businessCategories);
        setCategories(sortedBusinessCategories);
      },
    },
  );

  const fetchProductsAffectedCount = async () => (
    ProductApi.list({ businessId, businessCategoryId, limit: 1 }));
  useQuery<PaginatedData<ApiProduct>>(
    [QueryKeys.PRODUCT_FILTERS], fetchProductsAffectedCount, {
      enabled: !!businessCategoryId.length,
      onError: (err) => (
        createToast(toastProps(translate('UNEXPECTED_ERROR', { error: (err as Error).message })))
      ),
      onSuccess: ({ total }) => setNoProductsAffected(total),
    },
  );

  const updateSubCategories = useMutation(
    [QueryKeys.BUSINESS_CATEGORIES],
    (updates: ProductEndpoint.Category.Save.Request) => (
      BusinessApi.saveBusinessProductCategories(businessId, updates)),
    {
      onError: (err) => {
        createToast(
          toastProps(translate<string>('UNEXPECTED_ERROR', { error: (err as Error).message })),
        );
      },
      onSuccess: (data) => {
        setCategories(orderCategories(data));
        setSubcategoryItems(null);
      },
    },
  );

  const onChange = async (updates: SubCategoryItemsProps, added?: boolean) => {
    const newCategories = [...categories] as ProductEndpoint.Category.Save.Request;
    if (added) {
      setDuplicateError(null);
      const duplicate = newCategories.find((category) => (
        category?.subCategories.find((subcategory) => subcategory.name === updates.name)));
      if (duplicate) {
        setDuplicateError(duplicate);
        return;
      }
      // add subcategory
      const selectedCategory = newCategories.find((c) => c.category === updates.productCategory);
      selectedCategory.subCategories = (
        selectedCategory.subCategories.concat([{
          name: updates.name,
        }])
      );
    } else {
      // remove subcategory
      const indexToUpdate = newCategories.findIndex((item) => (
        item.category === updates.productCategory));
      const itemsToKeep = newCategories[indexToUpdate].subCategories.filter((item) => (
        item.name !== updates.name));
      newCategories[indexToUpdate].subCategories = itemsToKeep;
    }
    await updateSubCategories.mutateAsync(newCategories);
    if (added) {
      createToast(
        toastProps(translate('CATEGORY_ADDED', { name: updates.name }), 'success'),
      );
    } else {
      setConfirmRemovalModal(false);
      setBusinessCategoryId([]);
      createToast(
        toastProps(translate('CATEGORY_REMOVED', { name: updates.name }), 'success'),
      );
    }
  };

  const onCancel = () => {
    setConfirmRemovalModal(false);
    setBusinessCategoryId([]);
  };

  return (
    <>
      <ScrollView contentContainerStyle={{ flexDirection: 'row' }}>
        <View style={{ flex: 1.25 }}>
        <Header level="3" testID="discounts-header" title={translate('DISCOUNTS')} />
          <VSpacer size="5" />
          <Card testID="discounts-card">
            <DiscountsTable
              businessId={businessId}
              canAddDiscount={canModifyBusiness}
              onPageChange={onPageChange}
            />
          </Card>
          <VSpacer size="9" />
          <Header level="3" testID="product-categories-header" title={translate('PRODUCT_CATEGORIES')} />
          <VSpacer size="5" />
          {isLoading && <ModalSpinner />}
          <Card testID="product-categories-card">
            {categories?.map((category) => (
              <ProductCategoryListItem
                canModify={canModifyBusiness}
                category={category}
                key={category.category}
                onSubCategoryAdded={async (subCategoryName) => (
                  onChange({ productCategory: category.category, name: subCategoryName }, true))}
                onSubCategoryRemoved={(productCategory, name) => {
                  setSubcategoryItems({ productCategory, name });
                  const { id } = category.subCategories.find((sc) => sc.name === name);
                  setBusinessCategoryId([id]);
                  setConfirmRemovalModal(true);
                }}
              />
            ))}
          </Card>
          <VSpacer size="9" />
          <PriceTypeCard />
          {confirmRemovalModal && (
            <ConfirmationModal
              cancelText={translate('CANCEL')}
              confirmText={translate('YES_DELETE')}
              messageText={translate('REMOVE_PRODUCT_CATEGORIES_MODAL_WARNING', {
                count: noProductsAffected,
              })}
              onCancel={onCancel}
              onConfirm={async () => onChange(subcategoryItems)}
              status="warning"
              title={translate<string>('DELETE_PRODUCT_CATEGORY')}
              visible={confirmRemovalModal}
            />
          )}
        </View>
        <View style={{ flex: isDesktop && !isIPadPortrait() && 1 }} />
      </ScrollView>
      <VSpacer size="12" />
    </>
  );
};
