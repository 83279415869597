import React from 'react';
import { Button, Chip, VSpacer } from '@design';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

export interface ContactTagsChipsetProps {
  assignedTags: string[],
  handleOnAddTags: () => void,
  handleOnTagRemove: (index: number) => void,
}

export const ContactTagsChipSet = ({
  assignedTags,
  handleOnAddTags,
  handleOnTagRemove,
}: ContactTagsChipsetProps) => {
  const [translate] = useTranslation(['prepare', 'common', 'growers']);

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          appearance="ghost"
          onPress={handleOnAddTags}
          size="small"
          status="basic"
          testID="add-tags-button"
        >
        {!assignedTags.length && '+'} {translate<string>(!assignedTags.length ? 'ADD_TAGS' : 'EDIT_TAGS')}
        </Button>
      </View>
      <VSpacer size="5" />
      <View style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
        {
          assignedTags?.map((tag) => (
            <Chip
              key={tag.replace(' ', '-')}
              onPress={() => handleOnTagRemove(assignedTags.indexOf(tag))}
              style={{ marginTop: 4, marginBottom: 4, marginLeft: 0 }}
              testID={`tag-name-chip-${tag.replace(' ', '-')}`}
            >
              {`${tag}`}
            </Chip>
          ))
        }
      </View>
    </>
  );
};
