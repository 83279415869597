import { useTranslation } from 'react-i18next';
import React from 'react';
import { Text } from '../ui-components';

/**
 * Page that can be used to indicate that the user has landed on a page where a
 * permission is required that the user does not have
 */
export const AccessDeniedSuperPage = () => {
  const [translate] = useTranslation('common');

  return (<Text>{translate('ACCESS_DENIED_SUPER') as string}</Text>);
};
