import { VSpacer } from '@design';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import React from 'react';

export interface LineSpacerProps {
  opacity?: '100' | '200' | '300' | '400' | '500' | '600',
  size?: SpacerSize,
}
export const LineSpacer = ({ size, opacity = '100' }: LineSpacerProps) => {
  const styles = useStyleSheet({
    divider: {
      marginHorizontal: -8,
      backgroundColor: `color-basic-transparent-${opacity}`,
    },
  });

  return (
    <>
      <VSpacer size={size} />
      <Divider style={styles.divider} />
      <VSpacer size={size} />
    </>
  );
};
