import {
  Button,
  Header,
  Icon,
  IconButton,
  useToast,
  VSpacer,
} from '@design';
import { ApiGrower, ApiGrowerAssignedUser } from '@shared/interfaces/api';
import { Permissions, RoleUtility, toShortDate } from '@shared/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { GrowerApi } from '../../../../utilities/api';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';
import { AssignUsersSidePanel } from './AssignUsersSidePanel';

export interface AssignmentTabProps {
  customer: ApiGrower;
}

export const AssignmentTab = ({ customer }: AssignmentTabProps) => {
  const [translate] = useTranslation(['common', 'users', 'growers']);
  const { createToast } = useToast();
  const [assignUsersVisible, setAssignUsersVisible] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState<(ApiGrowerAssignedUser & RowMeta)[]>([]);
  const [modalOptions, setModalOptions] = useState<{
    visible: boolean,
    assignedUser?: ApiGrowerAssignedUser,
  }>({
    visible: false,
  });

  const { user } = useAuthentication();
  const canAssignUser = RoleUtility.roleHasPermission(
    user?.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const { isFetching, refetch } = useQuery(
    [QueryKeys.ASSIGNED_USERS, customer],
    () => GrowerApi.getAssignedUsers(customer.id),
    {
      onSuccess: (assignedUsersData) => setAssignedUsers(
        assignedUsersData.map((assignedUser, idx) => ({
          ...assignedUser,
          hasDetails: true,
          rowId: `row:${idx}|${assignedUser.id}|`,
        })),
      ),
      onError: () => {
        createToast({
          status: 'warning',
          children: translate<string>('UNEXPECTED_ERROR'),
          testID: 'toast-content-element',
        });
      },
    },
  );

  const columns: IColumn<(ApiGrowerAssignedUser & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (assignedUser) => assignedUser.fullName,
      flex: 1,
    },
    {
      columnId: 'email',
      header: {
        render: translate('EMAIL'),
        sortable: true,
      },
      render: (assignedUser) => assignedUser.email,
      flex: 1,
    },
    {
      columnId: 'userRole',
      header: {
        render: translate('ROLE'),
        sortable: true,
      },
      render: (assignedUser) => translate<string>(assignedUser.userRole),
      flex: 1,
    },
    {
      columnId: 'assignedAt',
      header: {
        render: translate('ASSIGNED_ON'),
        sortable: true,
      },
      render: (assignedUser) => toShortDate(assignedUser.assignedAt),
      flex: 1,
    },
    {
      columnId: 'delete-icon',
      header: { render: null },
      render: (assignedUser) => {
        return canAssignUser ? (
          <IconButton
            appearance="ghost"
            onPress={() => setModalOptions({ visible: true, assignedUser })}
            size="medium"
            status="basic"
            testID="delete-button"
          >
            Trash
          </IconButton>
        ) : null;
      },
      width: 72,
    },
  ];

  return (
    <>
      {canAssignUser && (
        <>
          <VSpacer size="8" />
          <Header level="2" testID="assignment-header">
            <Button
              accessoryLeft={(iconProps) => <Icon name="Plus" testID="assign-users-icon" {...iconProps} />}
              appearance="filled"
              onPress={() => setAssignUsersVisible(true)}
              size="medium"
              status="primary"
              testID="assign-users"
            >
              {translate<string>('ASSIGN_USERS')}
            </Button>
          </Header>
        </>
      )}
      <VSpacer size="8" />
      {!isFetching && (
        <Header
          level="3"
          testID="users-count-header"
          title={translate('USERS_COUNT', { count: assignedUsers?.length ?? 0 })}
        />
      )}
      <VSpacer size="5" />
      <SortableTable
        columns={columns}
        data={assignedUsers}
        isLoading={isFetching}
        noDataMessage={translate<string>('NO_ASSIGNED_USERS')}
        testID="assign-users-tables"
      />
      <AssignUsersSidePanel
        customer={customer}
        onClose={() => setAssignUsersVisible(false)}
        selectedUsers={assignedUsers ?? []}
        visible={assignUsersVisible}
      />
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('UNASSIGN')}
        onCancel={() => setModalOptions({ visible: false })}
        onConfirm={!modalOptions.assignedUser ? undefined : async () => {
          await GrowerApi.unlinkBusinessUsers(customer.id, [modalOptions.assignedUser.id]);
          await refetch();
          setModalOptions({ visible: false });
        }}
        status="warning"
        title={translate('UNASSIGN_DIALOG_TITLE', { user: modalOptions.assignedUser?.fullName })}
        visible={modalOptions.visible}
      />
    </>
  );
};
