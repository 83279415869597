import React, { useRef } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { ModalContext, useAppContext } from '../contexts/AppContext';
import { BusinessModal } from '../src/pages/BusinessDetails/BusinessModal';
import {
  ShareDeliverableModal,
} from '../src/components/shared/ShareDeliverableModal/ShareDeliverableModal';
import { OtherProductModal } from '../src/pages/ProductList/OtherProductModal';
import {
  CreateDeliverableModal,
} from '../src/pages/ProductOrder/components/CreateDeliverableModal/CreateDeliverableModal';
import ProductOrderModal from '../src/pages/ProductOrder/ProductOrderModal';
import ViewDeliverableModal from '../src/pages/ProductOrder/ViewDeliverableModal';
import ViewProductOrderModal from '../src/pages/ProductOrder/ViewProductOrderModal';
import { UserFormModal } from '../src/pages/User/UserFormModal';
import { CreateDeliverableFarmPlanModal } from '../src/pages/CustomerPlanning/FarmPlanTab/FarmPlanForm/CreateDeliverableFarmPlanModal/CreateDeliverableFarmPlanModal';

export const SharedModals = () => {
  const queryClient = useQueryClient();
  const { modalProps, setModalProps } = useAppContext();

  const typeRef = useRef<ModalContext['type'] | null>();
  typeRef.current = modalProps?.type ?? null;

  const closeModal = () => {
    if (typeRef.current === modalProps.type) {
      setModalProps(null);
    }
  };

  return !modalProps ? null : (
    <>
      {(modalProps.type === 'businessModal') && (
        <BusinessModal
          {...modalProps}
          onClose={async () => {
            // Reload the business in the background
            await queryClient.invalidateQueries(QueryKeys.BUSINESS_DETAILS);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'createDeliverable') && (
        <CreateDeliverableModal
          {...modalProps}
          onClose={async () => {
            // Reload the (product order and deliverable) list in the background
            await Promise.all([
              queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST),
              queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST),
            ]);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'createDeliverableFarmPlan') && (
        <CreateDeliverableFarmPlanModal
          {...modalProps}
          onClose={async () => {
            await Promise.all([
              queryClient.invalidateQueries(QueryKeys.FARM_PLAN_LIST),
              queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST),
            ]);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'otherProductModal') && (
        <OtherProductModal
          {...modalProps}
          onClose={async () => {
            // Reload the other product list in the background
            await queryClient.invalidateQueries(QueryKeys.OTHER_LIST);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'productOrder') && (
        <ProductOrderModal
          {...modalProps}
          onClose={async () => {
            // Reload the product order list in the background
            await queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST);
            closeModal();
          }}
          showContactDesignation
        />
      )}
      {(modalProps.type === 'shareDeliverable') && (
        <ShareDeliverableModal
          {...modalProps}
          onClose={async () => {
            // Reload the deliverable list in the background
            await queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST);
            await queryClient.invalidateQueries(QueryKeys.DELIVERABLE);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'userFormModal') && (
        <UserFormModal
          {...modalProps}
          onClose={async () => {
            // Reload the other product list in the background
            await queryClient.invalidateQueries(QueryKeys.USER);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'viewDeliverableModal') && (
        <ViewDeliverableModal
          {...modalProps}
          onClose={async () => {
            // Reload the product order list in the background
            await queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST);
            closeModal();
          }}
        />
      )}
      {(modalProps.type === 'viewProductOrder') && (
        <ViewProductOrderModal
          {...modalProps}
          onClose={async () => {
            // Reload the product order list in the background
            await queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST);
            closeModal();
          }}
        />
      )}
    </>
  );
};
