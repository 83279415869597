/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/state-in-constructor */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  StyleType,
  styled,
  Interaction,
  StyledComponentProps,
  Popover,
  List,
  ListItem,
  PopoverElement,
  ListItemProps,
} from '@ui-kitten/components';
import {
  TextInput,
  TouchableWithoutFeedback,
  View,
  TextInputProps,
  StyleSheet,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TouchableOpacity,
  TextInputSubmitEditingEventData,
  TargetedEvent,
} from 'react-native';
import {
  FalsyFC,
  FlexViewCrossStyleProps,
  LiteralUnion,
  PropsService,
  Overwrite,
  ChildrenWithProps,
} from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { SearchSize } from '@theme/variant-interfaces/Size';
import { Text } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { testId } from '../../../../utilities/testId';

type SearchStyledProps = Overwrite<
StyledComponentProps,
{
  appearance?: LiteralUnion<'default'>;
}
>;
export interface SearchProps extends TextInputProps, SearchStyledProps {
  children?: ChildrenWithProps<ListItemProps>,
  onClear?: () => void,
  onSelect?: (index: number) => void,
  options?: { name: string }[],
  size: SearchSize,
  testID: string,
}

interface SearchState {
  listVisible: boolean;
}
type SearchElement = React.ReactElement<SearchProps>;

@styled('Search')
export class Search extends React.Component<SearchProps, SearchState> {
  static defaultProps = {
    placeholder: 'Search',
  };

  public state = {
    listVisible: false,
  };

  private popoverRef = React.createRef<Popover>();
  private searchRef = React.createRef<TextInput>();

  public componentDidUpdate (prevProps: SearchProps): void {
    const isChildCountChanged: boolean = this.data.length
    !== React.Children.count(prevProps.children);
    const shouldBecomeVisible: boolean = !this.state.listVisible
    && this.isFocused() && isChildCountChanged;

    shouldBecomeVisible && this.setState({ listVisible: shouldBecomeVisible });
  }

  private get data (): any[] {
    return React.Children.toArray(this.props.children || []);
  }

  public focus = (): void => {
    this.searchRef.current?.focus();
  };

  public blur = (): void => {
    this.searchRef.current?.blur();
  };

  public isFocused = (): boolean => this.searchRef.current?.isFocused();

  public clear = (): void => {
    this.searchRef.current?.clear();
  };

  public onFocus = (
    event: NativeSyntheticEvent<TargetedEvent>,
  ): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.setOptionsListVisible();
    this.props.onFocus && this.props.onFocus(
      event as NativeSyntheticEvent<TextInputFocusEventData>,
    );
  };

  public onBlur = (
    event: NativeSyntheticEvent<TargetedEvent>,
  ): void => {
    this.props.eva.dispatch([]);
    this.props.onBlur && this.props.onBlur(event as NativeSyntheticEvent<TextInputFocusEventData>);
  };

  private onItemPress = (item: number): void => {
    if (this.props.onSelect) {
      this.setOptionsListInvisible();
      this.props.onSelect(item);
    }
  };

  private setOptionsListInvisible = (): void => {
    this.setState({ listVisible: false });
  };

  private setOptionsListVisible = (): void => {
    const hasData: boolean = this.props.options?.length > 0;
    hasData && this.setState({ listVisible: true });
  };

  private onInputSubmitEditing = (
    e: NativeSyntheticEvent<TextInputSubmitEditingEventData>,
  ): void => {
    this.setOptionsListInvisible();
    this.props.onSubmitEditing && this.props.onSubmitEditing(e);
  };

  private onBackdropPress = (): void => {
    this.blur();
    this.setOptionsListInvisible();
  };

  private getComponentStyle = (source: StyleType) => {
    const flatStyles: ViewStyle = StyleSheet.flatten(this.props.style);
    const {
      rest: inputContainerStyle,
      ...containerStyle
    } = PropsService.allWithRest(flatStyles, FlexViewCrossStyleProps);
    const {
      placeholderColor,
      paddingHorizontal,
      borderRadius,
      ...containerParameters
    } = source;

    const iconRightStyles = PropsServiceHelper.allWithPrefixMapped(
      source,
      'iconRight',
    );
    const iconLeftStyles = PropsServiceHelper.allWithPrefixMapped(
      source,
      'iconLeft',
    );
    const iconStyles = PropsServiceHelper.allWithPrefixMapped(source, 'icon');
    const textStyles = PropsServiceHelper.allWithPrefixMapped(source, 'text');
    const popoverStyles = PropsServiceHelper.allWithPrefixMapped(
      source,
      'popover',
    );
    const listStyles = PropsServiceHelper.allWithPrefixMapped(source, 'list');
    const listItemStyles = PropsServiceHelper.allWithPrefixMapped(source, 'listItem');

    const listItemTextProps = PropsServiceHelper.allWithPrefixMapped(source, 'listItemTextProps');

    return {
      container: {
        ...containerStyle,
      },
      inputContainer: {
        paddingHorizontal,
        ...inputContainerStyle,
        ...containerParameters,
      },
      text: textStyles,
      placeholder: {
        color: placeholderColor,
      },
      iconLeft: iconLeftStyles,
      iconRight: iconRightStyles,
      icon: iconStyles,
      popover: popoverStyles,
      list: listStyles,
      listItem: listItemStyles,
      listItemTextProps,
    };
  };

  // private renderSearchChildren = (props: SearchProps, evaStyle: StyleType) => {

  // Going to put the renderSearchElement element children in here since TouchableWithoutFeedback
  // only supports one child. https://reactnative.dev/docs/touchablewithoutfeedback

  // };

  private renderSearchElement = (
    props: SearchProps,
    evaStyle: any,
  ): SearchElement => {
    const { placeholder } = props;
    return (
      <TouchableWithoutFeedback
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        style={[evaStyle.container]}
      >
        <View style={[styles.inputContainer, evaStyle.inputContainer]}>
          <FalsyFC
            component={(prop) => (
              <Icon {...prop} name="Search" size={props.size} testID={`${this.props.testID}-search-icon`} />
            )}
            style={[evaStyle.iconLeft, evaStyle.icon]}
          />
          <TextInput
            {...testId(this.props.testID)}
            onSubmitEditing={this.onInputSubmitEditing}
            placeholder={placeholder}
            placeholderTextColor={evaStyle.placeholder.color}
            ref={this.searchRef}
            style={[evaStyle.text, { flex: 1 }]}
            {...props}
          />
          {props.value?.length > 0 && (
            <TouchableOpacity
              hitSlop={{ top: 50, bottom: 50, right: 50, left: 50 }}
              onPressIn={this.props.onClear}
            >
              <FalsyFC
                component={(prop) => (
                  <Icon {...prop} name="Close" size={props.size} testID={`${this.props.testID}-clear-icon`} />
                )}
                style={[evaStyle.iconRight, evaStyle.icon]}
              />
            </TouchableOpacity>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  };

  public render (): PopoverElement {
    const { eva, children, options, ...inputProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);
    const optionData = this.props.options;

    return (
      <Popover
        anchor={() => this.renderSearchElement(inputProps, evaStyle)}
        fullWidth
        onBackdropPress={this.onBackdropPress}
        ref={this.popoverRef}
        style={[styles.popover, evaStyle.popover, styles.popoverShadow]}
        testID={this.props.testID}
        visible={this.props.value.length > 0 && this.state.listVisible}
      >
        <List
          bounces={false}
          data={optionData}
          overScrollMode="auto"
          renderItem={(item) => {
            // regular expression to find needle in haystack and surround needle with |.
            // hayneedlestack => hay|needle|stack
            // haneedlestneedleack => ha|needle|st|needle|ack
            const surrounded = item.item.name.replace(new RegExp(`(${this.props.value})`, 'ig'), '|$1|');
            // split tokens.  every other token will be a needle
            // [hay, needle, stack]
            // [ha, needle, st, needle, ack]
            const outputTokens = surrounded.split('|');

            return (
              <ListItem
                key={item.index}
                onPress={() => this.onItemPress(item.index)}
                title={() => (
                  <View style={[styles.listItem, evaStyle.listItem]}>
                    {
                      // output tokens.  every odd token matches the search value so make them bold.
                      outputTokens.map((token: string, i: number) => (
                        <Text
                          key={`${token}-${i}`}
                          {...evaStyle.listItemTextProps}
                          style={[
                            evaStyle.list,
                            i % 2 && styles.bold,
                          ]}
                        >
                          {token}
                        </Text>
                      ))
                    }
                  </View>
                )}
              />
            );
          }}
          style={styles.list}
        />
      </Popover>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 360,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  popover: {
    overflow: 'hidden',
  },
  popoverShadow: {
    shadowColor: '#000',
    shadowOpacity: 0.2,
    shadowRadius: 15,
    shadowOffset: { width: 9, height: 9 },
  },
  list: {
    flexGrow: 0,
    overflow: 'hidden',
  },
  listItem: { flexDirection: 'row' },
  bold: { fontWeight: '900' },
  textInput: {
    borderColor: 'transparent',
    borderWidth: 0,
    borderRadius: 0,
  },

});
