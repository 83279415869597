import { Button, Dialog, HSpacer, Text } from '@design';
import { IntegrationType } from '@shared/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../../hooks/useIntegration';
import { testId } from '../../../../../utilities';

interface ConfirmSaveDialogProps {
  visible: boolean,
  onCancel: () => void;
  onSave: () => void;
}

export const ConfirmSaveDialog: FC<ConfirmSaveDialogProps> = ({
  visible,
  onCancel,
  onSave,
}) => {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const styles = StyleSheet.create({
    footer: { flexDirection: 'row' },
  });
  const { currentBusinessId } = useAuthentication();
  const { integration } = useIntegration({ businessId: currentBusinessId });
  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );
  return (
    <Dialog
      footerAccessory={({
        primaryButtonProp,
        secondaryButtonProp,
        spacerProp,
      }) => (
        <>
          <Button
            {...testId('save-qq-confirmation-dialog-cancel')}
            {...primaryButtonProp}
            appearance="outline"
            status="basic"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...testId('save-qq-confirmation-dialog-save')}
            {...secondaryButtonProp}
            onPress={onSave}
          >
            {translate<string>('SAVE')}
          </Button>
        </>
      )}
      footerStyle={styles.footer}
      onClose={onCancel}
      status="warning"
      testID="save-qq-confirmation-dialog"
      title={translate('CONFIRM_SAVE_QQ_DIALOG_TITLE')}
      visible={visible}
    >
      <Text {...testId('save-qq-confirmation-dialog-warning')} category="p1">
        {translate<string>(hasBusinessCentralIntegration
          ? 'CONFIRM_SAVE_QQ_DIALOG_WARNING_DYNAMICS'
          : 'CONFIRM_SAVE_QQ_DIALOG_WARNING')}
      </Text>
    </Dialog>
  );
};
