import { HealthEndpoint } from '@shared/interfaces/api';
import { Client } from './Client';

export class LogErrorApi {
  /**
   * Sends a client side error to an error logging endpoint on the backend
   */
  static logError (error: Error, context: object = {}) {
    try {
      const body: HealthEndpoint.LogError.Request = {
        error: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
        context,
      };
      return Client<void>('logError', {
        method: 'POST',
        body,
      });
    } catch (e) {
      // Empty catch block, if we can't send the error to remote API, fail silently
      return Promise.resolve();
    }
  }
}
