import {
  Button,
  CenteredSpinner,
  FilterCategory,
  Icon,
  IconButton,
  Text,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import { ApiBusiness, ApiCrop } from '@shared/interfaces/api';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { RoleUtility } from '@shared/utils';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { BusinessCropApi } from '../../../../utilities/api';
import { CropCard } from './CropCard';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { CropModal, Mode } from './CropModal';

export interface CropTabProps {
  business: ApiBusiness,
  businessId: string,
}

export function CropTab ({ business, businessId }: CropTabProps) {
  const [translate] = useTranslation(['businesses', 'common', 'errors']);
  const [crops, setCrops] = useState<ApiCrop[]>([]);
  const [selectedCrop, setSelectedCrop] = useState<ApiCrop>();
  const [deleteCropModalVisible, setDeleteCropModalVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState(null);
  const [filterOptions, setFilterOptions] = useState<FilterCategory[]>([
    {
      columnLabel: translate('STATUS'),
      columnKey: 'isActive',
      columns: [
        { label: translate('ACTIVE'), id: 'true' },
        { label: translate('INACTIVE'), id: 'false' },
      ],
    },
    {
      columnLabel: translate('CROP'),
      columnKey: 'crop',
      columns: [],
    },
  ]);

  const { user } = useAuthentication();
  const queryClient = useQueryClient();

  const { showBanner } = useBanner();
  const { createToast } = useToast();

  const canModifyBusiness = business?.isActive && RoleUtility.canEditBusiness(user, businessId);

  const totalResults = useMemo(() => {
    return crops.length;
  }, [crops]);

  const getBusinessCrops = () => BusinessCropApi.getBusinessCrops(businessId);
  const { isFetching } = useQuery([QueryKeys.BUSINESS_CROPS, businessId], getBusinessCrops, {
    onError: () => {
      showBanner(translate('ERROR_BUSINESS_CROPS'));
    },
    onSuccess: (data) => {
      setCrops(data);
      filterOptions[1].columns = data.filter((value, index, self) => {
        return self.findIndex((v) => v.cropType === value.cropType) === index;
      }).map((c) => {
        return { id: c.cropType, label: translate(c.cropType) };
      });
      setFilterOptions([...filterOptions]);
    },
  });

  return (
    <View>
      {
        canModifyBusiness && (
          <View style={{ alignItems: 'flex-end' }}>
            <Button
              accessoryLeft={<Icon name="Plus" testID="add-crop-icon" />}
              onPress={() => {
                setSelectedCrop(null);
                setMode(Mode.ADD);
                setShowModal(true);
              }}
              size="medium"
              testID="add-crop"
            >
              {`${translate('ADD_CROP_BUTTON')}`}
            </Button>
          </View>
        )
      }
      {!isFetching && (
        <Text category="p2" testID="crop-count">{`${totalResults} ${translate<string>('CROPS')}`}</Text>
      )}
      <VSpacer size="5" />
      {isFetching && (
        <>
          <VSpacer size="9" />
          <CenteredSpinner testID="loading-spinner" />
        </>
      )}
      {
        crops.map((crop, index) => (
          <Fragment key={`${crop.cropType}|${crop.subType}`}>
            <CropCard
              buttonLeft={canModifyBusiness && (
                <IconButton
                  appearance="ghost"
                  onPress={() => {
                    setSelectedCrop(crop);
                    setMode(Mode.EDIT);
                    setShowModal(true);
                  }}
                  status="basic"
                  testID={`crop-card-edit-${index}`}
                >
                  Edit
                </IconButton>
              )}
              buttonRight={(!crop?.inUse && canModifyBusiness) && (
                <IconButton
                  appearance="ghost"
                  onPress={() => {
                    setSelectedCrop(crop);
                    setDeleteCropModalVisible(true);
                  }}
                  status="basic"
                  testID={`crop-card-delete-${index}`}
                >
                  Trash
                </IconButton>
              )}
              crop={crop}
            />
            <VSpacer size="5" />
          </Fragment>
        ))
      }
      {deleteCropModalVisible && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('DELETE_CROP_MODAL_CONFIRMATION')}
          messageText={translate('DELETE_CROP_MODAL_MESSAGE')}
          onCancel={() => setDeleteCropModalVisible(false)}
          onConfirm={async () => {
            try {
              const cropsToUpdate = crops.filter(
                (c) => !(
                  c.cropType === selectedCrop.cropType && c.subType === selectedCrop.subType
                ),
              );
              await BusinessCropApi.saveBusinessCrops(business.id, cropsToUpdate);
              await queryClient.invalidateQueries(QueryKeys.BUSINESS_CROPS);
              createToast({
                children: translate<string>('CROP_SUCCESSFULLY_DELETED'),
                status: 'success',
                testID: 'toast-content-element',
              });
            } catch (error) {
              createToast({
                children: error.toString(),
                status: 'danger',
                testID: 'toast-content-element',
              });
            }
            setDeleteCropModalVisible(false);
          }}
          status="warning"
          title={translate('DELETE_CROP_MODAL_TITLE')}
          visible={deleteCropModalVisible}
        />
      )}
      {showModal && (
        <CropModal
          crop={selectedCrop}
          mode={mode}
          toggle={() => setShowModal(false)}
          visible={false}
        />
      )}
    </View>
  );
}
