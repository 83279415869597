import { Button, Card, Header, HSpacer, IconButton, NumericInput, Text, VSpacer } from '@design';
import {
  ApiCrop,
  ApiGrower,
  ApiGrowerCropZone,
  ApiGrowerFarm,
  ApiGrowerField,
  ApiPlanningParameter,
} from '@shared/interfaces/api';
import { FarmUtility, getPredicateFn } from '@shared/utils';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { SetPlanningParameters } from '../PlanningParametersTab';
import FieldTable from './FieldTable';

export interface PlanningParameterOptions {
  crop: ApiCrop,
  farm?: ApiGrowerFarm,
  field?: ApiGrowerField,
  cropZone?: ApiGrowerCropZone,
}

export type GetPlanningParameters = (
  options: PlanningParameterOptions
) => ApiPlanningParameter;

interface CropCardProps {
  crop: ApiCrop,
  customer: ApiGrower,
  farms: ApiGrowerFarm[],
  index?: number,
  onDelete: () => void,
  planningParameters: ApiPlanningParameter[],
  updatePlanningParameters: SetPlanningParameters,
}

const styles = StyleSheet.create({
  card: {
    paddingHorizontal: 20,
    paddingRight: 28,
    paddingTop: 20,
  },
  colorIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 12,
  },
});

export const CropCardRaw = (props: CropCardProps) => {
  const {
    crop,
    customer,
    farms,
    index,
    onDelete,
    updatePlanningParameters,
    planningParameters,
  } = props;
  const [translate] = useTranslation(['growers', 'common']);
  const [showTable, setShowTable] = useState(false);

  const getCustomerPlanningParameters = useCallback((
    options: PlanningParameterOptions,
  ): ApiPlanningParameter => {
    const { crop: cropInput, farm, field, cropZone } = options;
    const predicateFn = getPredicateFn(customer?.id, cropInput, farm, field, cropZone);
    return planningParameters.find(predicateFn);
  }, [customer, planningParameters]);

  const cropOutput: ApiCrop = useMemo(() => {
    const planningParameterForCrop = getCustomerPlanningParameters({ crop });
    let { price, priceUom, yieldGoal, yieldGoalUom } = crop;
    if (planningParameterForCrop) {
      price = planningParameterForCrop.price;
      priceUom = planningParameterForCrop.priceUom;
      yieldGoal = planningParameterForCrop.yieldGoal;
      yieldGoalUom = planningParameterForCrop.yieldGoalUom;
    }

    return {
      ...crop,
      price,
      priceUom,
      yieldGoal,
      yieldGoalUom,
    };
  }, [crop, getCustomerPlanningParameters]);

  const {
    color,
    cropType,
    price,
    priceUom,
    subType,
    yieldGoal,
    yieldGoalUom,
  } = cropOutput;

  return (
    <Card style={styles.card} testID={`crop-card-${index}`}>
      <View>
        <Header
          leftAccessory={() => (
            <View style={{ flexDirection: 'row', paddingTop: 4 }}>
              <View style={[{ backgroundColor: color }, styles.colorIndicator]} />
              <HSpacer size="5" />
            </View>
          )}
          level="2"
          testID={`crop-card-header-${index}`}
          title={`${translate(cropType)} | ${translate(subType)}`}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text>
              {translate<string>('YIELD_GOAL_PER_ACRE_LABEL')}
              :
            </Text>
            <HSpacer size="3" />
            <Text testID="yield-goal">
              {StringUtility.localizeNumber(yieldGoal, 2)}
              {' '}
              {translate<string>(yieldGoalUom)}
            </Text>
          </View>
        </Header>
        <VSpacer size="3" />
        <View style={{ flexDirection: 'row' }}>
          <HSpacer size="10" />
          <View style={{ alignItems: 'flex-end', flexDirection: 'row', flex: 1 }}>
            <NumericInput
              label={translate<string>('CROP_MARKET_PRICE')}
              onChangeValue={(value) => updatePlanningParameters({
                crop: cropOutput,
                property: 'price',
                value,
              })}
              prefix="$"
              size="medium"
              style={{ minWidth: 148 }}
              testID={`crop-market-price-input-${index}`}
              value={price}
            />
            <HSpacer size="3" />
            <Text style={{ marginBottom: 10, flex: 1 }}>{translate<string>(priceUom)}</Text>
          </View>
        </View>
        <View>
          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
            <Button
              appearance="outline"
              onPress={() => setShowTable(!showTable)}
              size="medium"
              status="basic"
              testID={`show-farm-table-button-${index}`}
            >
              {showTable ? translate<string>('HIDE_FIELDS_CROP_ZONES')
                : translate<string>('SHOW_FIELDS_CROP_ZONES')}
            </Button>
            <HSpacer size="5" />
            <IconButton
              appearance="ghost"
              onPress={() => onDelete()}
              status="basic"
              testID={`delete-crop-button-${index}`}
            >
              Trash
            </IconButton>
          </View>
        </View>
        <View style={{ flex: 1 }}>
          {showTable && farms.map((farm, idx) => {
            const farmArea = FarmUtility.getFarmArea(farm);
            return (
              <Fragment key={farm.id}>
                <VSpacer size="7" />
                <Card
                  style={{ paddingVertical: 32 }}
                  testID={`farm-card-${idx}`}
                >
                  <Header level="2" testID="farm-header" title={farm.farmName}>
                    <Text category="h6">
                      {StringUtility.localizeNumber(farmArea, 3)}
                      {' '}
                      {translate<string>('ACRES')}
                    </Text>
                  </Header>
                  <VSpacer size="7" />
                  <FieldTable
                    crop={cropOutput}
                    farm={farm}
                    getCustomerPlanningParameter={getCustomerPlanningParameters}
                    testID="field-table"
                    updatePlanningParameters={updatePlanningParameters}
                  />
                </Card>
                {farms.length > 1 && <VSpacer size="5" />}
              </Fragment>
            );
          })}
        </View>
      </View>
    </Card>
  );
};

export const CropCard = React.memo(CropCardRaw);
