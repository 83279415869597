import * as _ from 'lodash';
import React, { useState } from 'react';
import {
  StyleSheet,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
} from 'react-native';
import { Icon } from '@ui-kitten/components';
import { Sizing, ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  inputContainer: {
    flex: 1,
    backgroundColor: ThemeColors.DARK_GRAY,
    borderRadius: Sizing.HALF_SPACING,
    borderBottomColor: ThemeColors.DARK_GRAY,
    borderBottomWidth: 3,
    height: 2.5 * Sizing.BASE_SPACING,
  },
  input: {
    color: ThemeColors.TEXT,
    fontSize: 1.1 * Sizing.BASE_SPACING,
    paddingLeft: Sizing.BASE_SPACING,
    paddingRight: 2.5 * Sizing.BASE_SPACING,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  focused: { borderBottomColor: ThemeColors.PRIMARY },
  clearIcon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
    position: 'absolute',
    right: Sizing.HALF_SPACING,
    top: Sizing.HALF_SPACING,
  },
});

export interface FilterInputProps extends TextInputProps {
  onClear: () => void,
}

export const StyledFilterInput = (props: FilterInputProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <View style={[
      styles.inputContainer,
      focused && styles.focused,
    ]}
    >
      <TextInput
        {..._.omit(props, 'onClear')}
        keyboardType="web-search"
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        style={[
          styles.input,
          props.style,
        ]}
      />
      {props.value !== '' && (
        <TouchableOpacity onPress={props.onClear}>
          <Icon fill={ThemeColors.TEXT} name="close-outline" style={styles.clearIcon} />
        </TouchableOpacity>
      )}
    </View>
  );
};
