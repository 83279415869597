import { Badge, Card, HSpacer, NotesModal, TextLink, VSpacer } from '@design';
import { Mode } from '@design/NotesModal/NotesModal';
import { OrderStatus, ProductOrderDeliverableV0 } from '@shared/interfaces';
import { toFullDate } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { BillingShippingContactsModal } from '../../../../components/shared/BillingShippingContactsModal/BillingShippingContactsModal';
import { StringUtility, testId } from '../../../../../utilities';
import ActionsHeader from '../../../../components/shared/ActionsHeader/ActionsHeader';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';

export interface BasicInformationHeaderProps {
  deliverable: ProductOrderDeliverableV0.DeliverablePublic;
}

export const BasicInformationHeader: FC<BasicInformationHeaderProps> = ({
  deliverable,
}) => {
  const [translate] = useTranslation(['productOrders', 'growers', 'common']);
  const [noteVisible, setNoteVisible] = useState(false);

  const themedStyles = useStyleSheet({
    cardBody: {
      display: 'flex',
      flexDirection: 'row',
    },
    cardChild: {
      flex: 1,
    },
  });

  const { header, overview } = deliverable.deliverableData;
  const {
    contactPhone,
    location,
    note,
    owner,
    planStatus,
    priceType,
  } = deliverable.selections.basicInfo;

  const showOwner = owner && !!header.businessUserName;
  const showPhone = contactPhone && !!header.businessUserPhone;
  const showNote = note && !!overview.note?.trim();
  const showStatus = planStatus && overview.status !== OrderStatus.UNLABELED;
  const showContactDetails = overview.billingContact || overview.shippingContact;
  const [showContactsModal, setShowContactsModal] = useState(false);

  return (
    <>
      <View>
        <ActionsHeader header={translate('BASIC_INFORMATION')} />
        <VSpacer size="5" />
        <Card testID="basic-info-card">
          <View style={themedStyles.cardBody}>
            <View style={themedStyles.cardChild}>
              <View>
                {showOwner && (
                  <>
                    <DescriptionListItem
                      label={translate('SALESPERSON')}
                      testID="basic-info-owner"
                      text={header.businessUserName}
                      textCategory="p2"
                    />
                    <VSpacer size="3" />
                  </>
                )}
                <DescriptionListItem
                  label={translate('BUSINESS')}
                  testID="basic-info-business"
                  text={header.businessName}
                  textCategory="p2"
                />
                {location && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate('LOCATION')}
                      testID="basic-info-business-location"
                      text={
                        header.locationName || translate('CORPORATE_LOCATION')
                      }
                      textCategory="p2"
                    />
                  </>
                )}
                {showContactDetails && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate<string>('BILLING_SHIPPING_CONTACTS')}
                      testID="basic-info-billing-shipping"
                      textCategory="p2"
                    >
                      <HSpacer size="2" />
                      <TextLink
                        appearance="secondary"
                        category="p2"
                        onPress={() => setShowContactsModal(true)}
                        testID="basic-info-billing-shipping-view-more"
                      >
                        {translate<string>('VIEW')}
                      </TextLink>
                    </DescriptionListItem>
                  </>
                )}
              </View>
            </View>
            <HSpacer size="7" />
            <View style={themedStyles.cardChild}>
              <View>
                <DescriptionListItem
                  label={translate('CUSTOMER')}
                  testID="basic-info-grower"
                  text={header.growerName}
                  textCategory="p2"
                />
                {!!header.externalId && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate<string>('CUSTOMER_ID')}
                      testID="basic-info-customer-id"
                      text={header.externalId}
                      textCategory="p2"
                    />
                  </>
                )}
                {showPhone && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate<string>('PHONE')}
                      testID="basic-info-phone"
                      text={header.businessUserPhone}
                      textCategory="p2"
                    />
                  </>
                )}
                {!!overview.cropYear && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate('CROP_YEAR')}
                      testID="basic-info-crop-year"
                      text={overview.cropYear.toString()}
                      textCategory="p2"
                    />
                  </>
                )}
                {priceType && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate('PRICE_TYPE')}
                      testID="basic-info-price-type"
                      text={header.priceTypeName}
                      textCategory="p2"
                    />
                  </>
                )}
              </View>
            </View>
            <HSpacer size="7" />
            <View style={themedStyles.cardChild}>
              <View>
                <DescriptionListItem
                  label={translate<string>('CREATED_ON')}
                  testID="basic-info-create-date"
                  text={toFullDate(deliverable.createdAt)}
                  textCategory="p2"
                />
                {showStatus && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate('SALES_STATUS')}
                      testID="basic-info-sales-status"
                      text={translate(overview.status)}
                      textCategory="p2"
                    />
                  </>
                )}
                {showNote && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate('NOTE')}
                      testID="basic-info-note"
                      textCategory="p2"
                    >
                      <TextLink
                        {...testId('view-note-link')}
                        appearance="secondary"
                        category="p2"
                        onPress={() => setNoteVisible(true)}
                      >
                        {translate<string>('VIEW_NOTE')}
                      </TextLink>
                    </DescriptionListItem>
                  </>
                )}
                {!!overview.acreage && (
                  <>
                    <VSpacer size="3" />
                    <DescriptionListItem
                      label={translate('ORDER_ACREAGE')}
                      testID="basic-info-order-acreage"
                      text={translate(overview.acreage > 1 ? 'ACRES_COUNT' : 'ACRE_COUNT', {
                        acres: StringUtility.localizeNumber(overview.acreage, 3),
                      })}
                      textCategory="p2"
                    />
                  </>
                )}
                {overview.status === OrderStatus.ACCEPTED
                  && overview.cancelled && (
                    <>
                      <VSpacer size="7" />
                      <Badge status="danger" testID="cancelled-badge">
                        {translate('CANCELLED').toUpperCase()}
                      </Badge>
                    </>
                )}
              </View>
            </View>
          </View>
        </Card>
      </View>
      { showContactsModal && (
        <BillingShippingContactsModal
          billingContact={overview.billingContact}
          onClose={() => setShowContactsModal(false)}
          shippingContact={overview.shippingContact}
          showModal={showContactsModal}
        />
      )}
      {noteVisible && (
        <NotesModal
          mode={Mode.VIEW}
          note={overview.note}
          onClose={() => setNoteVisible(false)}
        />
      )}
    </>
  );
};
