import { EmptyState } from '@design';
import React from 'react';
import { View } from 'react-native';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const TITLE = 'Title message text here';
const SUB_TITLE = 'SubTitle text here SubTitle text here SubTitle text here SubTitle text here';
const ICON = 'DoneAll';

export function EmptyStateDemo () {
  return (
    <View>
      <DemoContainer label="Default">
        <DemoBlock>
          <EmptyState testID="test-data" title={TITLE} />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="SubTitle">
        <DemoBlock>
          <EmptyState subTitle={SUB_TITLE} testID="test-data" title={TITLE} />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Image">
        <DemoBlock>
          <EmptyState icon={ICON} testID="test-data" title={TITLE} />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="SubTitle, Image">
        <DemoBlock>
          <EmptyState icon={ICON} subTitle={SUB_TITLE} testID="test-data" title={TITLE} />
        </DemoBlock>
      </DemoContainer>
    </View>
  );
}
