import { Button, CheckBox, HSpacer, Icon, Input, Modal, VSpacer } from '@design';
import { ApiBusinessContact, BusinessEndpoint } from '@shared/interfaces/api';
import { validateEmail, validatePhoneNumber } from '@shared/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import BusinessContactCreate = BusinessEndpoint.BusinessContactCreate;

const styles = StyleSheet.create({
  footer: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerButtons: {
    flexDirection: 'row',
  },
});

interface BusinessContactModalProps {
  contact?: ApiBusinessContact,
  hideDeleteButton?: boolean,
  onClose: () => void,
  onDelete?: () => void,
  onSave: (contact: BusinessContactCreate.Request) => void,
}

export const BusinessContactModal = ({
  contact,
  hideDeleteButton = false,
  onClose,
  onDelete,
  onSave,
}: BusinessContactModalProps) => {
  const [translate] = useTranslation(['common', 'businesses']);

  const [name, setName] = useState(contact?.contactName ?? '');
  const [email, setEmail] = useState(contact?.contactEmail ?? '');
  const [telephone, setTelephone] = useState(contact?.contactTelephone ?? '');
  const [isDeliverableDefault, setIsDeliverableDefault] = useState(!!contact?.isDeliverableDefault);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);

  const isChanged = (
    name !== contact?.contactName
    || email !== contact?.contactEmail
    || telephone !== contact?.contactTelephone
    || isDeliverableDefault !== contact?.isDeliverableDefault
  );
  const isFormValid = (
    name.trim()
    && (email && validateEmail(email))
    && (!telephone || validatePhoneNumber(telephone))
    && (!contact || isChanged)
  );

  const handleSave = () => {
    onSave({
      contactName: name.trim(),
      contactEmail: email,
      contactTelephone: telephone || null,
      id: contact?.id,
      isDeliverableDefault,
    });
    onClose();
  };

  const footer = ({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
    <>
      {(contact && !hideDeleteButton) ? (
        <Button
          {...primaryButtonProp}
          accessoryLeft={(iconProps) => (
            <Icon name="Trash" testID="acreage-modal-delete-textLink" {...iconProps} />
          )}
          appearance="ghost"
          onPress={onDelete}
          testID="contact-modal-delete-button"
        >
          {translate<string>('DELETE_CONTACT')}
        </Button>
      ) : (
        <View />
      )}
      <View style={styles.footerButtons}>
        <Button
          onPress={onClose}
          testID="contact-modal-cancel-button"
          {...primaryButtonProp}
        >
          {translate('CANCEL')}
        </Button>
        <HSpacer {...spacerProp} />
        <Button
          disabled={!isFormValid}
          onPress={handleSave}
          testID="contact-modal-save-button"
          {...secondaryButtonProp}
        >
          {translate(!!contact ? 'SAVE_CHANGES' : 'SAVE')}
        </Button>
      </View>
    </>
  );

  return (
    <Modal
      footerAccessory={footer}
      footerStyle={styles.footer}
      hideCloseButton
      onClose={onClose}
      testID="contact-modal"
      title={translate(!!contact ? 'EDIT_CONTACT' : 'ADD_CONTACT')}
      visible
    >
      <Input
        caption={nameError ? translate('NAME_REQUIRED') : undefined}
        isRequired
        label={translate('CONTACT_NAME_SENTENCE')}
        onBlur={() => setNameError(!name)}
        onChangeText={(newName) => {
          setName(newName);
          if (nameError) {
            setNameError(!name);
          }
        }}
        status={nameError ? 'danger' : 'basic'}
        testID="contact-modal-name-input"
        value={name}
      />
      <VSpacer size="8" />
      <Input
        caption={emailError ? translate('VALID_EMAIL_REQUIRED') : undefined}
        isRequired
        label={translate('CONTACT_EMAIL_SENTENCE')}
        onBlur={() => setEmailError(!email || !validateEmail(email))}
        onChangeText={(newEmail) => {
          setEmail(newEmail);
          if (emailError) {
            setEmailError(!newEmail || !validateEmail(newEmail));
          }
        }}
        status={emailError ? 'danger' : 'basic'}
        testID="contact-modal-email-input"
        value={email}
      />
      <VSpacer size="8" />
      <CheckBox
        checked={isDeliverableDefault}
        onChange={setIsDeliverableDefault}
        testID="contact-modal-send-deliverables-checkbox"
      >
        {translate('SEND_DELIVERABLES_TO_CONTACT')}
      </CheckBox>
      <VSpacer size="8" />
      <Input
        caption={telephoneError ? translate('PHONE_IS_INVALID') : undefined}
        keyboardType="phone-pad"
        label={translate('CONTACT_PHONE_NUMBER_SENTENCE')}
        onBlur={() => setTelephoneError(!!telephone && !validatePhoneNumber(telephone))}
        onChangeText={(newTelephone) => {
          setTelephone(newTelephone);
          if (telephoneError) {
            setTelephoneError(!!telephone && !validatePhoneNumber(telephone));
          }
        }}
        status={telephoneError ? 'danger' : 'basic'}
        testID="contact-modal-telephone-input"
        value={telephone}
      />
    </Modal>
  );
};
