import { ApiForm, FormEndpoint } from '@shared/interfaces/api';
import { Client } from './Client';

export class PropertyGroupApi {
  static createPropertyGroup (
    propertyGroup: FormEndpoint.Create.Request,
  ): Promise<ApiForm> {
    return Client('forms', {
      method: 'POST',
      body: propertyGroup,
    });
  }

  static deletePropertyGroup (id: string): Promise<void> {
    return Client(`forms/${id}`, { method: 'DELETE' });
  }

  static getPropertyGroups (businessId: string): Promise<ApiForm[]> {
    return Client(`forms/?businessId=${businessId}`);
  }

  static getPropertyGroup (id: string): Promise<ApiForm> {
    return Client(`forms/${id}`);
  }

  static getPropertyGroupInstance (formId: string, customerId: string): Promise<ApiForm> {
    return Client(`forms/${formId}/customer/${customerId}`);
  }

  static updatePropertyGroup (
    id: string,
    propertyGroup: FormEndpoint.Update.Request,
  ): Promise<ApiForm> {
    return Client(`forms/${id}`, {
      method: 'PUT',
      body: propertyGroup,
    });
  }

  static reorderPropertyGroups (
    ordererdIds: string[],
  ): Promise<void> {
    return Client('forms/set-order', {
      method: 'PUT',
      body: { orderedIds: ordererdIds },
    });
  }
}
