import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { FarmPlanDeliverableV0 as V0 } from '@shared/interfaces';
import { Divider } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@design';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { RallyLogoHeader } from '../../../../svg/RallyLogoHeader';
import { Button, Text } from '../../../../ui-components';
import { Sizing, ThemeColors } from '../../../../constants';
import { DataPoint } from '../../../../ui-components/components/DataPoint/DataPoint';
import { QuadRow } from '../../../../ui-components/components/QuadRow/QuadRow';
import { MobileBox } from '../../../../ui-components/components/MobileBox/MobileBox';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    padding: Sizing.BASE_SPACING,
    justifyContent: 'space-around',
  },
  pageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titles: {
    flex: 1,
  },
  headerLogo: {
    marginTop: -45,
    marginBottom: -45,
  },
  headerLandscape: {
    flexDirection: 'row',
  },
  headerPortrait: {
    flexDirection: 'column',
  },
  headerGroup: {
    flex: 0.5,
  },
  divider: {
    backgroundColor: 'white',
    marginVertical: Sizing.HALF_SPACING,
  },
  headerRow: {
    marginVertical: Sizing.HALF_SPACING,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerData: {
    fontSize: Sizing.BASE_SPACING,
  },
  headerIcon: {
    color: ThemeColors.PRIMARY,
    marginRight: Sizing.HALF_SPACING,
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
  },
  farmPlanName: {
    fontSize: 1.25 * Sizing.BASE_SPACING,
    fontWeight: 'bold',
  },
  dataPointsPortrait: {
    margin: 1.75 * Sizing.BASE_SPACING,
  },
  dataPointsLandscape: {
    margin: 1.5 * Sizing.BASE_SPACING,
  },
  centeredContent: {
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

interface Props {
  selections: V0.Selections;
  snapshot: V0.Snapshot;
  deliverableId: string;
}

export const FarmPlanDeliverableMobileView: React.FunctionComponent<Props> = ({
  selections,
  snapshot,
  deliverableId,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  const [showCostPerAcre, setShowCostPerAcre] = useState(false);
  const [showTotalCost, setShowTotalCost] = useState(false);
  const [showProductCount, setShowProductCount] = useState(false);
  const [showAcresPlanned, setShowAcresPlanned] = useState(false);

  const [costPerAcre, setCostPerAcre] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [acresPlanned, setAcresPlanned] = useState(0);
  const windowDimensions = useWindowDimensions();
  const isLandscape = windowDimensions.width > windowDimensions.height;

  useEffect(() => {
    setShowAcresPlanned(selections.OVERVIEW.ACRES_PLANNED?.enabled);
    setShowProductCount(true);
    setShowTotalCost((
      // if order total is available as a selection
      selections.OVERVIEW.ORDER_TOTAL
        // use that to base our decision
        ? selections.OVERVIEW.ORDER_TOTAL.enabled
        // before order total was introduced, we had product cost
        : selections.OVERVIEW.PRODUCT_COST?.enabled
    ));
    setShowCostPerAcre((
      selections.OVERVIEW.ORDER_TOTAL_PER_ACRE
        ? selections.OVERVIEW.ORDER_TOTAL_PER_ACRE.enabled
        : selections.OVERVIEW.PRODUCT_COST?.enabled
    ));

    const totalCostLocal = (
      selections.OVERVIEW.ORDER_TOTAL
        ? snapshot.OVERVIEW?.ORDER_TOTAL?.data
        // we are using PRODUCT_COST here because that is our only cost
        // and EXPECTED_COST was turned off because it is redundant
        : snapshot.OVERVIEW?.PRODUCT_COST?.data
    ) || 0;
    setTotalCost(totalCostLocal);

    const acresPlannedLocal = snapshot.OVERVIEW?.ACRES_PLANNED?.data || 0;
    setAcresPlanned(acresPlannedLocal);

    // if this field is an available selection, then use the value returned by the db
    if (selections.OVERVIEW.ORDER_TOTAL_PER_ACRE) {
      setCostPerAcre(snapshot.OVERVIEW?.ORDER_TOTAL_PER_ACRE?.data);
    } else if (acresPlannedLocal > 0) {
      setCostPerAcre(totalCostLocal / acresPlannedLocal);
    }

    const productSummary = snapshot.PRODUCT_SUMMARY;
    const uniqueProducts = _.uniqBy(productSummary, (p) => p.PRODUCT_SKU_NAME);
    setProductCount(uniqueProducts.length);
  }, [
    selections.OVERVIEW.ACRES_PLANNED,
    selections.OVERVIEW.ORDER_TOTAL,
    selections.OVERVIEW.ORDER_TOTAL_PER_ACRE,
    selections.OVERVIEW.PRODUCT_COST,
    snapshot.OVERVIEW,
    snapshot.PRODUCT_SUMMARY,
  ]);

  const dataValues = _.compact([
    showAcresPlanned && (
      <DataPoint
        label={translate('ACRES_PLANNED')}
        value={StringUtility.localizeNumber(acresPlanned)}
      />
    ),
    showProductCount && (
      <DataPoint
        label={translate('PRODUCT_COUNT_LABEL')}
        value={StringUtility.localizeNumber(productCount)}
      />
    ),
    showTotalCost && (
      <DataPoint
        label={translate('TOTAL_COST')}
        value={StringUtility.formatFlatCurrencyShort(totalCost)}
      />
    ),
    showCostPerAcre && (
      <DataPoint
        label={translate('AVERAGE_COST_PER_ACRE')}
        value={StringUtility.formatFlatCurrencyShort(costPerAcre)}
      />
    ),
  ]);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, flexShrink: 0 }} nativeID="vscroll">
      <ScrollView contentContainerStyle={{ flex: 1 }} horizontal nativeID="hscroll">
        {selections && snapshot && (
          <View nativeID="wrapper" style={styles.wrapper}>
            <View style={styles.pageHeader}>
              <View style={styles.titles}>
                <View style={styles.headerRow}>
                  <Text>{translate('FARM_PLAN_DELIVERABLE_FOR')}</Text>
                </View>
                <View style={styles.headerRow}>
                  <Text style={styles.farmPlanName}>
                    {snapshot.HEADER?.FARM_PLAN_NAME?.data || ''}
                  </Text>
                </View>
              </View>
              {isLandscape && (
                <View style={[styles.headerLogo]}>
                  <View style={[styles.headerRow]}>
                    <RallyLogoHeader />
                  </View>
                </View>
              )}
            </View>
            <Divider style={styles.divider} />
            <View
              style={isLandscape ? styles.headerLandscape : styles.headerPortrait}
            >
              <View style={styles.headerGroup}>
                <View style={styles.headerRow}>
                  <Icon
                    fill={ThemeColors.PRIMARY}
                    name="GrowersPlanning"
                    style={[styles.headerIcon]}
                    testID="grower-icon"
                  />
                  <Text style={styles.headerData}>
                    {snapshot.HEADER?.GROWER_NAME?.data || ''}
                  </Text>
                </View>
                <View style={styles.headerRow}>
                  <Icon
                    fill={ThemeColors.PRIMARY}
                    name="GrowersBusiness"
                    style={[styles.headerIcon]}
                    testID="business-icon"
                  />
                  <Text style={styles.headerData}>
                    {snapshot.HEADER?.BUSINESS_NAME?.data || ''}
                  </Text>
                </View>
              </View>
              <View style={styles.headerGroup}>
                <View style={styles.headerRow}>
                  <Icon
                    fill={ThemeColors.PRIMARY}
                    name="Person"
                    style={[styles.headerIcon]}
                    testID="user-icon"
                  />
                  <Text style={styles.headerData}>
                    {snapshot.HEADER?.BUSINESS_USER_NAME?.data || ''}
                  </Text>
                </View>
                {snapshot.HEADER?.LOCATION_NAME?.data && (
                  <View style={styles.headerRow}>
                    <Icon
                      fill={ThemeColors.PRIMARY}
                      name="Navigation2"
                      style={[styles.headerIcon]}
                      testID="location-icon"
                    />
                    <Text style={styles.headerData}>
                      {snapshot.HEADER?.LOCATION_NAME?.data}
                    </Text>
                  </View>
                )}
              </View>
            </View>

            {!isLandscape && <MobileBox dataValues={dataValues} />}
            {isLandscape && (
              <View style={styles.centeredContent}>
                <QuadRow cells={dataValues} style={styles.dataPointsLandscape} />
              </View>
            )}
            <View style={styles.centeredContent}>
              <Button
                onPress={() => LinkingUtility.openUrl(`${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`)}
                status="primary"
              >
                {translate('VIEW_PDF')}
              </Button>
            </View>
            {!isLandscape && (
              <View style={styles.centeredContent}>
                <RallyLogoHeader />
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </ScrollView>
  );
};
