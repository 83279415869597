const EM: number = 16;

export const Sizing = {
  EM,
  BASE_SPACING: EM,
  HALF_SPACING: 0.5 * EM,
  QUARTER_SPACING: 0.25 * EM,
  DOUBLE_SPACING: 2 * EM,
  HEADER_HEIGHT: 4 * EM,
  IOS_TOP: 1.5 * EM,
  SIDEBAR_EXPANDED: 20 * EM,
  SIDEBAR_COLLAPSED: 4 * EM,
  SIDE_PANEL_WIDTH: 25 * EM,
  TOASTER_WIDTH: 20 * EM,
};
