import { AreaUnitType } from '@shared/enums';
import { GrowerFarmEndpoint } from '@shared/interfaces/api';

export const defaultField: GrowerFarmEndpoint.Create.Field = {
  fieldName: '',
  areaValue: 0,
  areaUnitType: AreaUnitType.Acre,
  cropZones: [],
};

export const defaultCropZone: GrowerFarmEndpoint.Create.CropZone = {
  zoneName: '',
  areaValue: 0,
  areaUnitType: AreaUnitType.Acre,
};
