import { 
  Button,
  CheckBox,
  Header,
  HSpacer,
  Icon,
  Modal,
  NumericInput,
  Select,
  SelectItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  VSpacer,
} from '@design';
import {
  ApiProductOrderProductMixComponent,
} from '@shared/interfaces/api';
import { IndexPath } from '@ui-kitten/components';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { StringUtility } from '../../../utilities';
import { CalculationUtility, ProductUtilities } from '@shared/utils';
import { AgriculturalUnit, AreaUnitType, FormulationUom, ProductMixIntendedUse } from '@shared/enums';
import { useState } from 'react';
import { SaveAsNewProductMixDialog } from './SaveAsNewProductMixDialog';
import { calculateTankMixTotalCostPerAcre } from './helpers';
import _ from 'lodash';
import { TankMixApi } from '../../../utilities/api';
import { View } from 'react-native';
import { LockPriceConfirmationDialog } from './LockPriceConfirmationDialog';

interface EditProductMixModalProps {
  components: ApiProductOrderProductMixComponent[],
  onApply: (
    components: ApiProductOrderProductMixComponent[],
    previousTankMixId?: string,
  ) => void,
  onCancel: () => void,
  priceTypeId: string,
  locationId: string,
  viewMode?: boolean,
  visible: boolean,
}

interface ProductMixComponentsTableRow {
  index: number,
  initPrice: number,
  locationId: string,
  priceTypeId: string,
  styles,
  component: ApiProductOrderProductMixComponent,
  updateComponent: (index: number, updates) => void,
  viewMode: boolean,
}

const ProductMixComponentsTableRow: FC<ProductMixComponentsTableRow> = ({
  index,
  initPrice,
  locationId,
  priceTypeId,
  styles,
  component,
  updateComponent,
  viewMode,
}) => {
  const [showPriceLockDialog, setShowPriceLockDialog] = useState<boolean>(false);
  const [translate] = useTranslation(['productOrders', 'products', 'common']);
  const [priceLocked, setPriceLocked] = useState<boolean>(!_.isNil(component.packagePrice));
  const componentTypeOptions = [' ', ProductMixIntendedUse.Adjuvant, ProductMixIntendedUse.Carrier];
  const getUomOptions = useCallback((productMixComponent: ApiProductOrderProductMixComponent) => (
    ProductUtilities.getProductApplicationUoMs(productMixComponent.product)
  ), []);

  const costPerAcre = useMemo(() => (
    calculateTankMixTotalCostPerAcre(
      [component],
      priceTypeId,
      locationId,
    )
  ), [component, locationId, priceTypeId]);

  useEffect(() => {
    setPriceLocked(
      !_.isNil(component.packagePrice) || component.packagePrice === initPrice,
    );
  }, [initPrice, component.packagePrice]);

  const isAcre = component.applicationRateUom === AreaUnitType.Acre;
  const isUnit = component.applicationRateUom === AgriculturalUnit.Unit;
  const isAcreOrUnit = isAcre || isUnit;

  return (
    <>
      {showPriceLockDialog && (
        <LockPriceConfirmationDialog
          initPrice={initPrice}
          onCancel={() => setShowPriceLockDialog(false)}
          onConfirm={() => {
            updateComponent(index, { packagePrice: null });
            setShowPriceLockDialog(false);
          }}
          packagePrice={component.packagePrice}
          unitUoM={component.product.unitUoM}
          visible
        />
      )}
      <TableRow
        key={component.id}
        testID={`edit-product-mix-modal-table-row-${index}`}
      >
        {/* Component */}
        <TableCell
          style={styles.tableCell}
          testID={`edit-product-mix-modal-table-row-component-${index}`}
        >
          <Text category="p2" wrap>{component.product?.skuName}</Text>
        </TableCell>
        {/* Unit Price */}
        <TableCell
          style={[styles.tableCell]}
          testID={`edit-product-mix-modal-table-row-unitprice-${index}`}
        >
          <View style={[styles.rightAligned]}>
            <NumericInput
              disabled={viewMode || !component.product.isActive}
              label=""
              onChangeValue={(packagePrice) => updateComponent(index, { packagePrice })}
              precision={2}
              prefix="$"
              readOnlyLabel={false}
              style={styles.input}
              testID={`edit-product-mix-modal-table-row-unitprice-input-${index}`}
              value={component.packagePrice ?? initPrice}
            />
            <VSpacer size="4" />
            {!viewMode ? (
              <>
                <CheckBox
                  checked={priceLocked}
                  disabled={!component.product.isActive}
                  onChange={(checked) => {
                    if (!checked) {
                      if (component.packagePrice === initPrice) {
                        setPriceLocked(false);
                        updateComponent(index, { packagePrice: null });
                      } else {
                        setShowPriceLockDialog(true);
                      }
                    } else {
                      updateComponent(index, { packagePrice: initPrice });
                      setPriceLocked(checked);
                    }
                  }}
                  testID={`edit-product-mix-modal-price-locked-${index}`}
                >
                  {translate<string>('LOCK_PRICE')}
                </CheckBox>
              </>
            ) : (
              <View style={styles.row}>
                <Icon
                  name={priceLocked ? 'Lock' : 'GrowersUnlock'}
                  status="basic"
                  testID={`edit-product-mix-modal-price-locked-${index}`}
                />
                <HSpacer size="3" />
                <Text testID={`edit-product-mix-modal-price-locked-${index}`}>
                  {translate<string>(priceLocked ? 'PRICE_LOCKED' : 'PRICE_UNLOCKED')}
                </Text>
              </View>
            )}
          </View>
        </TableCell>
        {/* Rate/ac */}
        <TableCell
          style={[styles.tableCell, styles.rightAligned]}
          testID={`edit-product-mix-modal-table-row-rateac-${index}`}
        >
          <NumericInput
            disabled={viewMode || !component.product.isActive}
            label=""
            onChangeValue={(applicationRate) => updateComponent(index, { applicationRate })}
            precision={2}
            readOnly={isAcre}
            readOnlyLabel={false}
            style={styles.input}
            testID={`edit-product-mix-modal-table-row-rateac-input-${index}`}
            value={component.applicationRate}
          />
        </TableCell>
        {/* Rate UoM */}
        <TableCell
          style={styles.tableCell}
          testID={`edit-product-mix-modal-table-row-rateuom-${index}`}
        >
          <Select
            disabled={viewMode || !component.product.isActive}
            onSelect={(ip: IndexPath | IndexPath[]) => {
              updateComponent(
                index,
                { applicationRateUom: getUomOptions(component)[(ip as IndexPath).row] },
              );
            }}
            placeholder={<Text />}
            readOnlyLabel={false}
            readonly={isAcreOrUnit}
            selectedIndex={component.applicationRateUom ? new IndexPath(
              getUomOptions(component).findIndex((c) => c === component.applicationRateUom),
            ) : undefined}
            style={{ width: '100%' }}
            testID={`edit-product-mix-modal-table-row-rateac-select-${index}`}
            value={translate(component.applicationRateUom)}
          >
            {getUomOptions(component).map((uom, itemIndex) => (
              <SelectItem
                key={uom}
                testID={`edit-product-mix-modal-table-row-rateac-select-item-${itemIndex}`}
                title={translate(uom)}
              />
            ))}
          </Select>
        </TableCell>
        {/* Intended use */}
        <TableCell
          style={styles.tableCell}
          testID={`edit-product-mix-modal-table-row-intendeduse-${index}`}
        >
          <Select
            disabled={viewMode || !component.product.isActive}
            onSelect={(ip: IndexPath | IndexPath[]) => {
              updateComponent(
                index,
                { intendedUse: componentTypeOptions[(ip as IndexPath).row] },
              );
            }}
            placeholder={<Text />}
            selectedIndex={component.intendedUse ? new IndexPath(
              componentTypeOptions.findIndex((c) => c === component.intendedUse),
            ) : undefined}
            style={{ width: '100%' }}
            testID={`edit-product-mix-modal-table-row-intendeduse-select-${index}`}
            value={translate<string>(component.intendedUse?.toUpperCase()) ?? ''}
          >
            {componentTypeOptions.map((componentType, itemIndex) => (
              <SelectItem
                key={componentType}
                testID={`edit-product-mix-modal-table-row-intendeduse-select-item-${itemIndex}`}
                title={translate<string>(componentType?.toUpperCase())}
              />
            ))}
          </Select>
        </TableCell>
        {/* Cost/ac */}
        <TableCell
          style={[styles.tableCell, styles.rightAligned]}
          testID={`edit-product-mix-modal-table-row-costac-${index}`}
        >
          {StringUtility.formatCurrency(costPerAcre)}
        </TableCell>
      </TableRow>
    </>
  );
};

export const EditProductMixModal: FC<EditProductMixModalProps> = ({
  components: initialComponents,
  onApply,
  onCancel,
  priceTypeId,
  locationId,
  viewMode = false,
  visible,
}) => {
  const [translate] = useTranslation(['productOrders', 'products', 'common']);

  const styles = StyleSheet.create({
    container: {
      height: 500,
      overflow: 'scroll',
    },
    modal: {
      height: '80%',
      minWidth: 834,
    },
    footer: {
      flexDirection: 'row',
    },
    headerTableRow: {
      justifyContent: 'space-between',
    },
    input: {
      width: 88,
    },
    rightAligned: {
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    row: {
      flexDirection: 'row',
    },
    tableCell: {
      flex: 1,
      alignItems: 'flex-start',
    },
    headerCell: {
      backgroundColor: 'transparent',
      flex: 1,
    },
  });
  
  const [components, setComponents] = useState(_.cloneDeep(initialComponents));
  const [showSaveAsNewProductDialog, setShowSaveAsNewProductDialog] = useState(false);

  const getApplicableProductPrice = (component: ApiProductOrderProductMixComponent) => {
    return CalculationUtility.getApplicableProductPrice(
      component.product,
      priceTypeId,
      locationId,
    );
  };

  const updateComponent = (index: number, updates) => {
    components[index] = {
      ...components[index],
      ...updates,
    };
    setComponents([...components]);
  };

  const total = useMemo(() => {
    return calculateTankMixTotalCostPerAcre(
      components,
      priceTypeId,
      locationId,
    );
  }, [components, priceTypeId, locationId]);

  const isModified = useCallback(() => {
    
    const isAnyPackagePriceModified = components.some(component => {
      const tankMixComponent = initialComponents.find(x => x.productId === component.productId);
      const tankMixComponentPrice = tankMixComponent.packagePrice ?? CalculationUtility.getApplicableProductPrice(
        tankMixComponent.product,
        priceTypeId,
        locationId,
      );

      const componentPrice = component.packagePrice ?? CalculationUtility.getApplicableProductPrice(
        component.product,
        priceTypeId,
        locationId,
      );

      if (tankMixComponent.packagePrice !== component.packagePrice) {
        return true;
      }

      return tankMixComponentPrice !== componentPrice;
    });
    
    const isAnyIntendedUseModified = components.some(component => {
      const tankMixComponent = initialComponents.find(x => x.productId === component.productId);
      return component.intendedUse !== tankMixComponent.intendedUse;
    });
    const isAnyRateModified = components.some(component => {
      const tankMixComponent = initialComponents.find(x => x.productId === component.productId);
      return component.applicationRate !== tankMixComponent.applicationRate;
    });
    const isAnyRateUomModified = components.some(component => {
      const tankMixComponent = initialComponents.find(x => x.productId === component.productId);
      return component.applicationRateUom !== tankMixComponent.applicationRateUom;
    });

    return isAnyPackagePriceModified || isAnyIntendedUseModified
      || isAnyRateModified || isAnyRateUomModified;
  }, [components, initialComponents, locationId, priceTypeId]);

  const handleSaveAsNewProductMix = async (newName: string) => {
    
    const previousTankMixId = components[0].tankMixId;
    const tankMixToDuplicate = await TankMixApi.getTankMix(previousTankMixId);

    const newTankMix = await TankMixApi.createTankMix({
      ...tankMixToDuplicate,
      name: newName,
      components: components.map(c => ({
        productId: c.productId,
        rate: c.applicationRate,
        rateUom: c.applicationRateUom as FormulationUom,
        packagePrice: c.packagePrice,
        adjuvant: c.intendedUse === ProductMixIntendedUse.Adjuvant,
        carrier: c.intendedUse === ProductMixIntendedUse.Carrier,
        componentCategory: tankMixToDuplicate.components.find(
          tc => tc.productId === c.productId,
        )?.componentCategory,
        formulation: tankMixToDuplicate.components.find(
          tc => tc.productId === c.productId,
        )?.formulation,
      })),
    });

    onApply([...components.map(component => ({
      ...component,
      tankMix: newTankMix,
      tankMixId: newTankMix?.id,
    }))], previousTankMixId);

    setShowSaveAsNewProductDialog(false);
  };

  return (
    <>
      <Modal
        footerAccessory={({ spacerProp }) => (
          <>
            <Button
              appearance="outline"
              onPress={onCancel}
              size="small"
              status="basic"
              testID="edit-product-mix-modal-cancel"
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              appearance="outline"
              disabled={!isModified()}
              onPress={() => setShowSaveAsNewProductDialog(true)}
              size="small"
              status="basic"
              testID="edit-product-mix-modal-save-as-new"
            >
              {translate<string>('SAVE_AS_NEW_PRODUCT_MIX')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              disabled={!isModified()}
              onPress={() => onApply(components)}
              size="small"  
              testID="edit-product-mix-modal-apply"
            >
              {translate<string>('APPLY')}
            </Button>
          </>
        )}
        onClose={onCancel}
        style={styles.modal}
        testID="edit-product-mix-modal"
        title={`${translate('EDIT')} ${components[0].tankMix?.name}`}
        visible={visible}
      >
        <Text category="p2">
          {translate<string>('EDIT_TANK_MIX_MODAL_MESSAGE')} 
        </Text>
        <VSpacer size="9" />
        <Header
          level="3"
          testID="edit-product-mix-modal-header"
          title={translate<string>('PRODUCT_MIX_COMPONENTS')}
        />
        <ScrollView
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator
          style={styles.container}
        >
          <Table
          tableBackground="transparent"
          testID="edit-product-mix-modal-table"
        >
          <TableRow
            appearance="header"
            style={[styles.headerCell, styles.headerTableRow]}
            testID="edit-product-mix-modal-table-header"
          >
            <TableHeader
              style={[styles.headerCell]}
              testID="edit-product-mix-modal-table-component"
            >
              <Text category="s2">{`${translate('COMPONENT')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="edit-product-mix-modal-table-unit-price"
            >
              <Text category="s2">{`${translate('UNIT_PRICE')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell, styles.rightAligned]}
              testID="edit-product-mix-modal-table-rateac"
            >
              <Text category="s2">{`${translate('RATE_AC')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="edit-product-mix-modal-table-rateuom"
            >
              <Text category="s2">{`${translate('RATE_UOM')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="edit-product-mix-modal-table-intendeduse"
            >
              <Text category="s2">{`${translate('INTENDED_USE')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell, styles.rightAligned]}
              testID="edit-product-mix-modal-table-costac"
            >
              <Text category="s2">{`${translate('COST_AC')}`}</Text>
            </TableHeader>
          </TableRow>
          {components.map((tankMixComponent, index) => (
            <ProductMixComponentsTableRow
              component={tankMixComponent}
              index={index}
              initPrice={getApplicableProductPrice(tankMixComponent)}
              key={tankMixComponent.productId}
              locationId={locationId}
              priceTypeId={priceTypeId}
              styles={styles}
              updateComponent={updateComponent}
              viewMode={viewMode}
            />
          ))}
          <TableRow testID="edit-product-mix-modal-table-row-total">
            <TableCell
              style={styles.tableCell}
              testID="edit-product-mix-modal-table-row-total-label"
            >
              <Text category="s1">{translate<string>('TOTAL')}</Text>
            </TableCell>
            <TableCell
              style={[styles.tableCell, styles.rightAligned]}
              testID="edit-product-mix-modal-table-row-total-value"
            >
              <Text category="s1">{StringUtility.formatCurrency(total)}</Text>
            </TableCell>
          </TableRow>
        </Table>
        </ScrollView>
      </Modal>
      {showSaveAsNewProductDialog && (
        <SaveAsNewProductMixDialog
          name={components[0].tankMix?.name}
          onCancel={() => setShowSaveAsNewProductDialog(false)}
          onSave={handleSaveAsNewProductMix}
          visible
        />
      )}
    </>
  );
};
