import React, { ReactElement, useRef, useState } from 'react';
import { List, Popover } from '@ui-kitten/components';
import { ListRenderItemInfo, StyleSheet, View } from 'react-native';
import { StyledInput as Input, InputProps } from './Input';
import { SearchableSelectItem } from './SearchableSelectItem';
import { Sizing, ThemeColors } from '../../../constants';

export interface SearchableSelectProps extends InputProps {
  onSelect?(item: any): void;
  placement?: string;
  data: {
    label: string,
    value: any,
  }[];
  listComponent?: ReactElement;
}
const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: ThemeColors.DARK_GRAY,
    borderTopLeftRadius: Sizing.QUARTER_SPACING,
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    marginBottom: Sizing.DOUBLE_SPACING,
    borderBottomColor: ThemeColors.DARK_GRAY,
    borderBottomWidth: 3,
    height: 3.5 * Sizing.EM,
  },
  list: {
    flexGrow: 0,
    overflow: 'hidden',
  },
  popover: {
    maxHeight: 192,
    overflow: 'hidden',
    backgroundColor: ThemeColors.DARK_GRAY,
    borderColor: ThemeColors.DARKEST_GRAY,
  },
  input: { height: 3.5 * Sizing.EM },
});

export const SearchableSelect = ({
  listComponent,
  data,
  onSelect,
  placement,
  ...inputProps
}: SearchableSelectProps) => {
  const [listVisible, setListVisible] = useState(false);
  const popoverRef = useRef();

  const onInputFocus = () => {
    setListVisible(true);
  };

  const onBackDropPress = () => {
    setListVisible(false);
  };

  const onItemPress = (item: any) => {
    if (onSelect) {
      onSelect(item);
      setListVisible(false);
    }
  };

  const renderTextInputElement = (props: InputProps) => (
    <View style={styles.inputContainer}>
      <Input style={styles.input} {...props} onFocus={onInputFocus} />
    </View>
  );

  const renderListItem = (info: ListRenderItemInfo<any>) => (
    <SearchableSelectItem
      key={info.item.label}
      nativeID={info.item.label}
      onPress={() => onItemPress(info.item.value)}
      title={info.item.label}
    />
  );

  return (
    <Popover
      anchor={() => renderTextInputElement(inputProps)}
      fullWidth
      onBackdropPress={onBackDropPress}
      placement={placement}
      ref={popoverRef}
      style={styles.popover}
      visible={listVisible}
    >
      <List
        bounces={false}
        data={data}
        renderItem={renderListItem}
        style={styles.list}
      />
    </Popover>
  );
};
