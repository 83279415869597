import { EnumTranslations } from '@shared/enums';

export default {
  ACCEPTED: 'Accepted',
  ACCESS_DENIED: 'You do not have permission to view this page',
  ACCESS_DENIED_SUPER: 'Business selection is required to view this page',
  ACCOUNT: 'Account',
  ACRE: 'acre',
  ACREAGE: 'Acreage',
  ACRES: 'acres',
  ACTIVATE: 'Activate',
  ACTIVE: 'Active',
  ADDRESS: 'Address',
  ADD_COMPONENT: 'Add component',
  ADD_CONTACT_TITLE: 'Add Contact',
  ADD_NOTE: 'Add note',
  ADD_ORDER_DISCOUNT: 'Add order discount',
  ADD_PASS: 'Add pass',
  ADD_PRODUCTS: 'Add products',
  ADD_SKU_DISCOUNT: 'Add SKU discount',
  ALL: 'All',
  AMOUNT: 'Amount',
  APPLICATION: 'Application',
  APPLIED: 'Applied',
  APPLY: 'Apply',
  APPLY_NOTE_TEMPLATE: 'Apply Note Template',
  ASSIGN: 'Assign',
  ASSIGNED: 'Assigned',
  ASSIGNED_ON: 'Assigned on',
  BACK: 'Back',
  BILLING_CONTACT: 'Billing contact',
  BILLING_SHIPPING_CONTACTS: 'Billing/Shipping contacts',
  BUSINESS: 'Business',
  BUSINESSES: 'Businesses',
  BUSINESS_LOCATION: 'Business location',
  CANCEL: 'Cancel',
  CANCELLED: 'Cancelled',
  CATEGORY: 'Category',
  CHANGE_PRICE_TYPE_MESSAGE: 'Change from {{oldPriceType}} to {{newPriceType}}? Unlocked prices in this order will be updated to reflect the new price type.',
  CHANGE_PRICE_TYPE_TITLE: 'Change price type?',
  CHARACTERS: 'characters',
  CHARACTERS_REMAINING: '{{ remaining }} of {{ maxCharacters }} characters remaining',
  CHARACTER_LIMIT: 'character limit',
  CHEMICAL: 'Chemical',
  CITY: 'City',
  CLEAR: 'Clear',
  CLEAR_ALL: 'Clear all',
  CLOSE: 'Close',
  COLLAPSE_ALL: 'Collapse all',
  COLOR: 'Color',
  COMPONENTS: 'Components',
  CONFIRM: 'Confirm',
  CONTACTS: 'Contacts',
  CONTACT_ADDRESS1: 'Address1',
  CONTACT_ADDRESS2: 'Address2',
  CONTACT_CITY: 'Contact City',
  CONTACT_COUNTRY: 'Country',
  CONTACT_EMAIL: 'Contact Email',
  CONTACT_FIRST_NAME: 'Contact First Name',
  CONTACT_INFO: 'Contact info',
  CONTACT_LAST_NAME: 'Contact Last Name',
  CONTACT_PHONE_NUMBER: 'Contact Phone Number',
  CONTACT_POSTAL: 'Contact Postal',
  CONTACT_STATE: 'Contact State',
  CORPORATE: 'Corporate',
  COST_PER_AC: 'Cost/ac',
  COST_PER_AC_CALC: '{{ cost }}/ac',
  COST_SUMMARY: 'Cost Summary',
  COST_UOM_PER_ACRE: '{{cost}} {{uom}}/ac',
  COUNTY: 'County',
  COUNTRY: 'Country',
  CREATE_AND_SHARE_DELIVERABLE: 'Create & share deliverable',
  CREATE_NOTE: 'Create note',
  CREATED_BY: 'Created by',
  CROP: 'Crop',
  CROPS: 'Crops',
  CROPS_PERCENT_AREA_VALUE: '{{percent}}%, {{areaValue}} acres',
  CROPS_PERCENT_PER_AREA: '{{percent}}%, {{areaValue}} {{area}}',
  CROP_CHANGE_BLOCKED_IN_USE: 'This crop is currently being used in a Product Mix or Program (or both) and cannot be replaced.',
  CROP_MARKET_PRICE: 'Crop Market Price',
  CROP_MARKET_PRICE_LABEL: 'Crop market price',
  CROP_SUB_TYPE: 'Crop Subtype',
  CROP_TYPE: 'Crop Type',
  CROP_TYPE_INPUT: 'Crop type',
  CROP_YEAR: 'Crop year',
  CSV_SUCCESS: 'CSV file was successfully uploaded.',
  CUSTOM: 'Custom',
  CUSTOMER: 'Customer',
  CUSTOMERS: 'Customers',
  DATE_CREATED: 'Created',
  DATE_UPDATED: 'Last Updated',
  DEACTIVATE: 'Deactivate',
  DEFAULT_LOWERCASE: 'default',
  DELETE: 'Delete',
  DELETE_NOTE: 'Delete Note',
  DETAILS: 'Details',
  DISCARD: 'Discard',
  DISMISS: 'Dismiss',
  DOLLAR_DISCOUNT: 'Dollar discount',
  DONE: 'Done',
  DOWNLOAD: 'Download',
  DOWNLOADING_NEW_VERSION: 'A new version of Growers Rally is being downloaded. The update will be automatically applied.',
  DUPLICATE: 'Duplicate',
  EDIT: 'Edit',
  EDIT_CONTACT_TITLE: 'Edit Contact',
  EDIT_CONTACTS: 'Edit contacts',
  EDIT_DETAILS: 'Edit details',
  EDIT_NOTE: 'Edit Note',
  EDIT_SKU_DISCOUNT: 'Edit SKU discount',
  EMAIL: 'Email',
  EMAIL_ADDRESS: 'Email address',
  ERROR_COUNTRIES: 'There was an error loading countries',
  ERROR_CUSTOMER_CONTACTS: 'There was an error loading customer {{ type }} contacts',
  ERROR_DETAILS: 'Error Details',
  ERROR_DOWNLOADING_APP: 'There was an unexpected error while checking for app updates.',
  ERROR_LOADING_CROP_TYPES: 'There was an error loading crop types',
  EXIT: 'Exit',
  EXPAND_ALL: 'Expand all',
  EXPORT: 'Export',
  EXPORT_CUSTOMER_SUBTITLE: 'Download a CSV file with existing Customer information',
  FARM_NAME_ALREADY_EXISTS: 'Farm name already exists.',
  FARM_PLANS: 'Farm Plans',
  FERTILIZER: 'Fertilizer',
  FILTERS: 'Filters',
  FILTER_BY: 'Filter by:',
  FILTER_COUNT: 'Filter ({{ count }})',
  FILTER_SELECT_ALL: 'Select all',
  FORMULATION: 'Formulation',
  GROWERS: 'Growers',
  GROWERS_MOVED_MESSAGE: 'Please tap below to launch GROWERS Rally in your web browser',
  GROWERS_MOVED_TITLE: 'GROWERS Rally has moved!',
  HELP: 'Help',
  HIDE_DETAILS: 'Hide details',
  IMPORT: 'Import',
  INACTIVE: 'Inactive',
  ITEMS_COUNT: '{{ count }} Items',
  ITEM_SINGLE_COUNT: '1 Item',
  KNOWLEDGE_BASE: 'Knowledge Base',
  LAST_UPDATED: 'Last Updated',
  LIQUID: 'Liquid',
  LOADING: 'Loading...',
  LOAD_MORE: 'Load more',
  LOCATION: 'Location',
  LOG_OUT: 'Log Out',
  MANUFACTURER: 'Manufacturer',
  MORE: '+ {{count}} more',
  MORE_FILTERS: 'More filters',
  MORE_ROW: '+ {{count}} more row',
  MORE_ROWS: '+ {{count}} more rows',
  NAME: 'Name',
  NAME_REQUIRED: 'A name is required',
  NEXT: 'Next',
  NO: 'No',
  NONE: 'None',
  NOTE: 'Note',
  NOT_APPLICABLE: 'N/A',
  NOT_APPLIED: 'Not Applied',
  NO_DATA: 'No Data',
  NUMBER_OF_PASSES: 'Number of Passes',
  OPEN_IN_BROWSER: 'Open in browser',
  OR: 'or',
  OTHER: 'Other',
  OTHER_PRODUCTS: 'Other Products',
  OTHER_PRODUCTS_OPTION: 'Other products',
  OWNER: 'Owner',
  PASSES_COUNT: '{{count}} Passes',
  PASS_NAME: 'Pass name',
  PERCENTAGE_DISCOUNT: 'Percentage discount',
  PER_AC: '/ac',
  PER_ACRE: 'Per acre',
  PER_ACRE_COST: '{{ cost }} per acre',
  PER_ACRE_NOTE: '(per acre)',
  PHONE: 'Phone',
  PHONE_IS_INVALID: 'Phone number is invalid',
  PHONE_NUMBER: 'Phone number',
  PRICE: 'Price',
  PRICE_TYPE: 'Price type',
  PRIMARY_CONTACT: 'Primary Contact',
  PRIMARY_CONTACT_SENTENCE: 'Primary contact',
  PRODUCTS: 'Products',
  PRODUCT_MIX: 'Product Mix',
  PROGRAM: 'Program',
  PROPERTIES: 'Properties',
  PROPERTY: 'Property',
  RALLY_SUPPORT: 'Rally support',
  RATE_PER_AC: 'Rate/ac',
  RATE_UOM: 'Rate UoM',
  READ_ONLY: 'Read only',
  REJECTED: 'Rejected',
  REMOVE: 'Remove',
  RENAME: 'Rename',
  REQUEST: 'Request',
  REQUIRED: 'Required',
  RESEND: 'Resend',
  RESULT_COUNT: '{{ count }} results',
  ROWS_INSERTED: '{{count}} Rows Inserted',
  ROWS_UPDATED: '{{count}} Rows Updated',
  ROW_INSERTED: '1 Row Inserted',
  ROW_UPDATED: '1 Row Updated',
  SAVE: 'Save',
  SAVE_CHANGES: 'Save changes',
  SAVE_NEW: 'Save as new',
  SEARCH: 'Search',
  SECONDARY_CONTACT: 'Additional Contact #{{number}}',
  SEED: 'Seed',
  SELECT_ALL: 'Select All',
  SHARED: 'Shared',
  SHIPPING_CONTACT: 'Shipping contact',
  SHIPPING_SAME_AS_BILLING: 'Use the same contact information from billing contact',
  SHOWING_FILTER: 'Showing {{listLength}} of {{totalResults}}',
  SHOW_ALL_CROPS: 'Show all crops',
  SHOW_DETAILS: 'Show details',
  SHOW_LESS: 'Show less',
  SHOW_MORE_NUMBER: 'Show {{number}} more',
  SHOW_RESULTS: 'Show results',
  SIGN_IN: 'Sign in',
  SKU_DISCOUNTS: 'SKU discounts',
  START_TYPING_HERE: 'Start typing here...',
  STATE: 'State',
  STATUS: 'Status',
  SUBCATEGORY: 'Subcategory',
  SUBMIT: 'Submit',
  SUBMIT_REQUEST: 'Submit request',
  SUBTYPE: 'Subtype',
  SUB_CATEGORY: 'Sub Category',
  SUPPORT_MESSAGE_SUCCESS: 'Thank you for reaching out to our Support team.\n Someone will be in touch soon.',
  TAB_TITLE_ASSIGNMENT: 'Assignment',
  TAB_TITLE_FARMS: 'Farms',
  TAB_TITLE_FARM_PLAN: 'Farm Plan',
  TAB_TITLE_FARM_PLANS: 'Farm Plans',
  TAB_TITLE_DETAILS: 'Details',
  TAB_TITLE_INFO: 'Info',
  TAB_TITLE_INTEGRATIONS: 'Integration',
  TAB_TITLE_LOCATIONS: 'Locations',
  TAB_TITLE_OVERVIEW: 'Overview',
  TAB_TITLE_PLANNING_PARAMETERS: 'Planning Parameters',
  TAB_TITLE_PRODUCT_CATEGORIES: 'Product Categories',
  TAB_TITLE_PRODUCT_SETTINGS: 'Product Settings',
  THIS_TEMPLATE: 'this template',
  TOTAL: 'Total',
  TOTAL_COST_AC: 'Total cost/ac',
  TOTAL_UNITS: 'Total Units',
  TYPE: 'Type',
  UNEXPECTED_ERROR: 'An unexpected error occurred.\n{{error}}',
  UNIT_LABEL: 'Unit',
  UOM: 'UoM',
  UOM_PER_ACRE_PARSED: '{{ UoM }}/ac',
  UPDATE: 'Update',
  UPLOAD: 'Upload',
  USER: 'User',
  USER_NAME: 'User name',
  VALID_EMAIL_REQUIRED: 'A valid email address is required',
  VALID_PHONE_REQUIRED: 'A valid phone number is required',
  VALID_POSTAL_CODE_REQUIRED: 'A valid postal code is required',
  VERSION_INFO: 'Version Info',
  VIEW: 'View',
  VIEW_ALL: 'View all',
  VIEW_ALL_PRICING_INFO: 'View all pricing info',
  VIEW_NOTE: 'View note',
  YES: 'Yes',
  YES_CHANGE: 'Yes, change',
  YES_DELETE: 'Yes, delete',
  YIELD_GOAL: 'Yield Goal',
  YIELD_GOAL_PER_ACRE: 'Yield Goal/ac',
  YIELD_GOAL_PER_ACRE_LABEL: 'Yield goal/ac',
  //  ASSIGNED_TO: 'Assigned to {{ name }}',

  ...EnumTranslations,
};
