export const IconMap = {
  GrowersDragHandle: { pack: 'growers', name: 'drag-handle' },
  GrowersAreaCropZone: { pack: 'growers', name: 'area-crop-zone' },
  GrowersAreaFarm: { pack: 'growers', name: 'area-farm' },
  GrowersAreaField: { pack: 'growers', name: 'area-field' },
  GrowersArrowExpand: { pack: 'growers', name: 'arrow-expand' },
  GrowersBusiness: { pack: 'growers', name: 'business' },
  GrowersCalculate: { pack: 'growers', name: 'calculate' },
  GrowersPlanning: { pack: 'growers', name: 'planning' },
  GrowersPrepareReview: { pack: 'growers', name: 'prepare-review' },
  GrowersProductChemical: { pack: 'growers', name: 'product-chemical' },
  GrowersProductFertilizer: { pack: 'growers', name: 'product-fertilizer' },
  GrowersProductSeed: { pack: 'growers', name: 'product-seed' },
  GrowersProduct: { pack: 'growers', name: 'product' },
  GrowersUnlock: { pack: 'growers', name: 'unlock' },
  GrowersViewDetails: { pack: 'growers', name: 'view-details-icon' },
  GrowersIcon: { pack: 'growers', name: 'nav-logo-growers-icon' },
  GrowersFooter: { pack: 'growers', name: 'nav-logo-growers-footer' },
  RallyIcon: { pack: 'growers', name: 'nav-logo-rally-icon' },
  RallyLogo: { pack: 'growers', name: 'nav-logo-rally' },
  Activity: { pack: 'eva', name: 'activity-outline' },
  AlertCircle: { pack: 'eva', name: 'alert-circle-outline' },
  AlertTriangle: { pack: 'eva', name: 'alert-triangle-outline' },
  Archive: { pack: 'eva', name: 'archive-outline' },
  ArrowBack: { pack: 'eva', name: 'arrow-back-outline' },
  ArrowCircleDown: { pack: 'eva', name: 'arrow-circle-down-outline' },
  ArrowCircleLeft: { pack: 'eva', name: 'arrow-circle-left-outline' },
  ArrowCircleRight: { pack: 'eva', name: 'arrow-circle-right-outline' },
  ArrowCircleUp: { pack: 'eva', name: 'arrow-circle-up-outline' },
  ArrowDown: { pack: 'eva', name: 'arrow-down-outline' },
  ArrowDownward: { pack: 'eva', name: 'arrow-downward-outline' },
  ArrowForward: { pack: 'eva', name: 'arrow-forward-outline' },
  ArrowIosBack: { pack: 'eva', name: 'arrow-ios-back-outline' },
  ArrowIosDownward: { pack: 'eva', name: 'arrow-ios-downward-outline' },
  ArrowIosForward: { pack: 'eva', name: 'arrow-ios-forward-outline' },
  ArrowIosUpward: { pack: 'eva', name: 'arrow-ios-upward-outline' },
  ArrowLeft: { pack: 'eva', name: 'arrow-left-outline' },
  ArrowRight: { pack: 'eva', name: 'arrow-right-outline' },
  ArrowUp: { pack: 'eva', name: 'arrow-up-outline' },
  ArrowUpward: { pack: 'eva', name: 'arrow-upward-outline' },
  ArrowheadDown: { pack: 'eva', name: 'arrowhead-down-outline' },
  ArrowheadLeft: { pack: 'eva', name: 'arrowhead-left-outline' },
  ArrowheadRight: { pack: 'eva', name: 'arrowhead-right-outline' },
  ArrowheadUp: { pack: 'eva', name: 'arrowhead-up-outline' },
  At: { pack: 'eva', name: 'at-outline' },
  Attach2: { pack: 'eva', name: 'attach-2-outline' },
  Attach: { pack: 'eva', name: 'attach-outline' },
  Award: { pack: 'eva', name: 'award-outline' },
  Backspace: { pack: 'eva', name: 'backspace-outline' },
  BarChart2: { pack: 'eva', name: 'bar-chart-2-outline' },
  BarChart: { pack: 'eva', name: 'bar-chart-outline' },
  Battery: { pack: 'eva', name: 'battery-outline' },
  Behance: { pack: 'eva', name: 'behance-outline' },
  BellOff: { pack: 'eva', name: 'bell-off-outline' },
  Bell: { pack: 'eva', name: 'bell-outline' },
  Bluetooth: { pack: 'eva', name: 'bluetooth-outline' },
  BookOpen: { pack: 'eva', name: 'book-open-outline' },
  Book: { pack: 'eva', name: 'book-outline' },
  Bookmark: { pack: 'eva', name: 'bookmark-outline' },
  Briefcase: { pack: 'eva', name: 'briefcase-outline' },
  Browser: { pack: 'eva', name: 'browser-outline' },
  Brush: { pack: 'eva', name: 'brush-outline' },
  Bulb: { pack: 'eva', name: 'bulb-outline' },
  Calendar: { pack: 'eva', name: 'calendar-outline' },
  Camera: { pack: 'eva', name: 'camera-outline' },
  Car: { pack: 'eva', name: 'car-outline' },
  Cast: { pack: 'eva', name: 'cast-outline' },
  Charging: { pack: 'eva', name: 'charging-outline' },
  CheckmarkCircle2: { pack: 'eva', name: 'checkmark-circle-2-outline' },
  CheckmarkCircle: { pack: 'eva', name: 'checkmark-circle-outline' },
  Checkmark: { pack: 'eva', name: 'checkmark-outline' },
  CheckmarkSquare2: { pack: 'eva', name: 'checkmark-square-2-outline' },
  CheckmarkSquare: { pack: 'eva', name: 'checkmark-square-outline' },
  ChevronDown: { pack: 'eva', name: 'chevron-down-outline' },
  ChevronLeft: { pack: 'eva', name: 'chevron-left-outline' },
  ChevronRight: { pack: 'eva', name: 'chevron-right-outline' },
  ChevronUp: { pack: 'eva', name: 'chevron-up-outline' },
  Clipboard: { pack: 'eva', name: 'clipboard-outline' },
  Clock: { pack: 'eva', name: 'clock-outline' },
  CloseCircle: { pack: 'eva', name: 'close-circle-outline' },
  Close: { pack: 'eva', name: 'close-outline' },
  CloseSquare: { pack: 'eva', name: 'close-square-outline' },
  CloudDownload: { pack: 'eva', name: 'cloud-download-outline' },
  CloudUpload: { pack: 'eva', name: 'cloud-upload-outline' },
  CodeDownload: { pack: 'eva', name: 'code-download-outline' },
  Code: { pack: 'eva', name: 'code-outline' },
  Collapse: { pack: 'eva', name: 'collapse-outline' },
  CollapseCircle: { pack: 'eva-extension', name: 'collapse-circle-outline' },
  ColorPalette: { pack: 'eva', name: 'color-palette-outline' },
  ColorPicker: { pack: 'eva', name: 'color-picker-outline' },
  Compass: { pack: 'eva', name: 'compass-outline' },
  Copy: { pack: 'eva', name: 'copy-outline' },
  CornerDownLeft: { pack: 'eva', name: 'corner-down-left-outline' },
  CornerDownRight: { pack: 'eva', name: 'corner-down-right-outline' },
  CornerLeftDown: { pack: 'eva', name: 'corner-left-down-outline' },
  CornerLeftUp: { pack: 'eva', name: 'corner-left-up-outline' },
  CornerRightDown: { pack: 'eva', name: 'corner-right-down-outline' },
  CornerRightUp: { pack: 'eva', name: 'corner-right-up-outline' },
  CornerUpLeft: { pack: 'eva', name: 'corner-up-left-outline' },
  CornerUpRight: { pack: 'eva', name: 'corner-up-right-outline' },
  CreditCard: { pack: 'eva', name: 'credit-card-outline' },
  Crop: { pack: 'eva', name: 'crop-outline' },
  Cube: { pack: 'eva', name: 'cube-outline' },
  DiagonalArrowLeftDown: { pack: 'eva', name: 'diagonal-arrow-left-down-outline' },
  DiagonalArrowLeftUp: { pack: 'eva', name: 'diagonal-arrow-left-up-outline' },
  DiagonalArrowRightDown: { pack: 'eva', name: 'diagonal-arrow-right-down-outline' },
  DiagonalArrowRightUp: { pack: 'eva', name: 'diagonal-arrow-right-up-outline' },
  DoneAll: { pack: 'eva', name: 'done-all-outline' },
  Download: { pack: 'eva', name: 'download-outline' },
  DropletOff: { pack: 'eva', name: 'droplet-off-outline' },
  Droplet: { pack: 'eva', name: 'droplet-outline' },
  Edit2: { pack: 'eva', name: 'edit-2-outline' },
  Edit: { pack: 'eva', name: 'edit-outline' },
  Email: { pack: 'eva', name: 'email-outline' },
  Expand: { pack: 'eva', name: 'expand-outline' },
  ExpandCircle: { pack: 'eva-extension', name: 'expand-circle-outline' },
  ExternalLink: { pack: 'eva', name: 'external-link-outline' },
  EyeOff2: { pack: 'eva', name: 'eye-off-2-outline' },
  EyeOff: { pack: 'eva', name: 'eye-off-outline' },
  Eye: { pack: 'eva', name: 'eye-outline' },
  Facebook: { pack: 'eva', name: 'facebook-outline' },
  FileAdd: { pack: 'eva', name: 'file-add-outline' },
  File: { pack: 'eva', name: 'file-outline' },
  FileRemove: { pack: 'eva', name: 'file-remove-outline' },
  FileText: { pack: 'eva', name: 'file-text-outline' },
  Film: { pack: 'eva', name: 'film-outline' },
  Fire: { pack: 'eva-extension', name: 'fire-outline' },
  Flag: { pack: 'eva', name: 'flag-outline' },
  FlashOff: { pack: 'eva', name: 'flash-off-outline' },
  Flash: { pack: 'eva', name: 'flash-outline' },
  Flip2: { pack: 'eva', name: 'flip-2-outline' },
  Flip: { pack: 'eva', name: 'flip-outline' },
  FolderAdd: { pack: 'eva', name: 'folder-add-outline' },
  Folder: { pack: 'eva', name: 'folder-outline' },
  FolderRemove: { pack: 'eva', name: 'folder-remove-outline' },
  Funnel: { pack: 'eva', name: 'funnel-outline' },
  Gift: { pack: 'eva', name: 'gift-outline' },
  Github: { pack: 'eva', name: 'github-outline' },
  Globe2: { pack: 'eva', name: 'globe-2-outline' },
  Globe: { pack: 'eva', name: 'globe-outline' },
  Google: { pack: 'eva', name: 'google-outline' },
  Grid: { pack: 'eva', name: 'grid-outline' },
  HardDrive: { pack: 'eva', name: 'hard-drive-outline' },
  Hash: { pack: 'eva', name: 'hash-outline' },
  Headphones: { pack: 'eva', name: 'headphones-outline' },
  Heart: { pack: 'eva', name: 'heart-outline' },
  Height: { pack: 'eva-extension', name: 'height-outline' },
  Home: { pack: 'eva', name: 'home-outline' },
  Image: { pack: 'eva', name: 'image-outline' },
  Inbox: { pack: 'eva', name: 'inbox-outline' },
  Info: { pack: 'eva', name: 'info-outline' },
  Keypad: { pack: 'eva', name: 'keypad-outline' },
  Layers: { pack: 'eva', name: 'layers-outline' },
  Layout: { pack: 'eva', name: 'layout-outline' },
  Link2: { pack: 'eva', name: 'link-2-outline' },
  Link: { pack: 'eva', name: 'link-outline' },
  Linkedin: { pack: 'eva', name: 'linkedin-outline' },
  List: { pack: 'eva', name: 'list-outline' },
  Loader: { pack: 'eva-extension', name: 'loader-outline' },
  Lock: { pack: 'eva', name: 'lock-outline' },
  // we have growers unlock customized
  // Unlock: { pack: 'eva', name: 'unlock-outline' },
  LogIn: { pack: 'eva', name: 'log-in-outline' },
  LogOut: { pack: 'eva', name: 'log-out-outline' },
  Map: { pack: 'eva', name: 'map-outline' },
  Maximize: { pack: 'eva', name: 'maximize-outline' },
  Menu2: { pack: 'eva', name: 'menu-2-outline' },
  MenuArrow: { pack: 'eva', name: 'menu-arrow-outline' },
  Menu: { pack: 'eva', name: 'menu-outline' },
  MessageCircle: { pack: 'eva', name: 'message-circle-outline' },
  MessageSquare: { pack: 'eva', name: 'message-square-outline' },
  MicOff: { pack: 'eva', name: 'mic-off-outline' },
  Mic: { pack: 'eva', name: 'mic-outline' },
  Minimize: { pack: 'eva', name: 'minimize-outline' },
  MinusCircle: { pack: 'eva', name: 'minus-circle-outline' },
  Minus: { pack: 'eva', name: 'minus-outline' },
  MinusSquare: { pack: 'eva', name: 'minus-square-outline' },
  Monitor: { pack: 'eva', name: 'monitor-outline' },
  Moon: { pack: 'eva', name: 'moon-outline' },
  MoreHorizontal: { pack: 'eva', name: 'more-horizontal-outline' },
  MoreVertical: { pack: 'eva', name: 'more-vertical-outline' },
  Move: { pack: 'eva', name: 'move-outline' },
  Music: { pack: 'eva', name: 'music-outline' },
  Navigation2: { pack: 'eva', name: 'navigation-2-outline' },
  Navigation: { pack: 'eva', name: 'navigation-outline' },
  Npm: { pack: 'eva', name: 'npm-outline' },
  Options2: { pack: 'eva', name: 'options-2-outline' },
  Options: { pack: 'eva', name: 'options-outline' },
  Pantone: { pack: 'eva', name: 'pantone-outline' },
  PaperPlane: { pack: 'eva', name: 'paper-plane-outline' },
  PauseCircle: { pack: 'eva', name: 'pause-circle-outline' },
  People: { pack: 'eva', name: 'people-outline' },
  Percent: { pack: 'eva', name: 'percent-outline' },
  PersonAdd: { pack: 'eva', name: 'person-add-outline' },
  PersonDelete: { pack: 'eva', name: 'person-delete-outline' },
  PersonDone: { pack: 'eva', name: 'person-done-outline' },
  Person: { pack: 'eva', name: 'person-outline' },
  PersonRemove: { pack: 'eva', name: 'person-remove-outline' },
  PhoneCall: { pack: 'eva', name: 'phone-call-outline' },
  PhoneMissed: { pack: 'eva', name: 'phone-missed-outline' },
  PhoneOff: { pack: 'eva', name: 'phone-off-outline' },
  Phone: { pack: 'eva', name: 'phone-outline' },
  PieChart: { pack: 'eva', name: 'pie-chart-outline' },
  Pin: { pack: 'eva', name: 'pin-outline' },
  PlayCircle: { pack: 'eva', name: 'play-circle-outline' },
  PlusCircle: { pack: 'eva', name: 'plus-circle-outline' },
  Plus: { pack: 'eva', name: 'plus-outline' },
  PlusSquare: { pack: 'eva', name: 'plus-square-outline' },
  Power: { pack: 'eva', name: 'power-outline' },
  Pricetags: { pack: 'eva', name: 'pricetags-outline' },
  Printer: { pack: 'eva', name: 'printer-outline' },
  QuestionMarkCircle: { pack: 'eva', name: 'question-mark-circle-outline' },
  QuestionMark: { pack: 'eva', name: 'question-mark-outline' },
  RadioButtonOff: { pack: 'eva', name: 'radio-button-off-outline' },
  RadioButtonOn: { pack: 'eva', name: 'radio-button-on-outline' },
  Radio: { pack: 'eva', name: 'radio-outline' },
  Recording: { pack: 'eva', name: 'recording-outline' },
  Refresh: { pack: 'eva', name: 'refresh-outline' },
  Repeat: { pack: 'eva', name: 'repeat-outline' },
  RewindLeft: { pack: 'eva', name: 'rewind-left-outline' },
  RewindRight: { pack: 'eva', name: 'rewind-right-outline' },
  Save: { pack: 'eva', name: 'save-outline' },
  Scissors: { pack: 'eva', name: 'scissors-outline' },
  Search: { pack: 'eva', name: 'search-outline' },
  Settings2: { pack: 'eva', name: 'settings-2-outline' },
  Settings: { pack: 'eva', name: 'settings-outline' },
  Shake: { pack: 'eva', name: 'shake-outline' },
  Share: { pack: 'eva', name: 'share-outline' },
  ShieldOff: { pack: 'eva', name: 'shield-off-outline' },
  Shield: { pack: 'eva', name: 'shield-outline' },
  ShoppingBag: { pack: 'eva', name: 'shopping-bag-outline' },
  ShoppingCart: { pack: 'eva', name: 'shopping-cart-outline' },
  Shuffle2: { pack: 'eva', name: 'shuffle-2-outline' },
  Shuffle: { pack: 'eva', name: 'shuffle-outline' },
  SkipBack: { pack: 'eva', name: 'skip-back-outline' },
  SkipForward: { pack: 'eva', name: 'skip-forward-outline' },
  Slash: { pack: 'eva', name: 'slash-outline' },
  Smartphone: { pack: 'eva', name: 'smartphone-outline' },
  Speaker: { pack: 'eva', name: 'speaker-outline' },
  Square: { pack: 'eva', name: 'square-outline' },
  Star: { pack: 'eva', name: 'star-outline' },
  StarFilled: { pack: 'eva', name: 'star' },
  StopCircle: { pack: 'eva', name: 'stop-circle-outline' },
  Sun: { pack: 'eva', name: 'sun-outline' },
  Swap: { pack: 'eva', name: 'swap-outline' },
  SwapUpDown: { pack: 'eva-extension', name: 'swap-up-down-outline' },
  Sync: { pack: 'eva', name: 'sync-outline' },
  Text: { pack: 'eva', name: 'text-outline' },
  ThermometerMinus: { pack: 'eva', name: 'thermometer-minus-outline' },
  Thermometer: { pack: 'eva', name: 'thermometer-outline' },
  ThermometerPlus: { pack: 'eva', name: 'thermometer-plus-outline' },
  Timer: { pack: 'eva-extension', name: 'timer-outline' },
  ToggleLeft: { pack: 'eva', name: 'toggle-left-outline' },
  ToggleRight: { pack: 'eva', name: 'toggle-right-outline' },
  Trash2: { pack: 'eva', name: 'trash-2-outline' },
  Trash: { pack: 'eva', name: 'trash-outline' },
  TrendingDown: { pack: 'eva', name: 'trending-down-outline' },
  TrendingUp: { pack: 'eva', name: 'trending-up-outline' },
  Tv: { pack: 'eva', name: 'tv-outline' },
  Twitter: { pack: 'eva', name: 'twitter-outline' },
  Umbrella: { pack: 'eva', name: 'umbrella-outline' },
  Undo: { pack: 'eva', name: 'undo-outline' },
  Upload: { pack: 'eva', name: 'upload-outline' },
  VideoOff: { pack: 'eva', name: 'video-off-outline' },
  Video: { pack: 'eva', name: 'video-outline' },
  VolumeDown: { pack: 'eva', name: 'volume-down-outline' },
  VolumeMute: { pack: 'eva', name: 'volume-mute-outline' },
  VolumeOff: { pack: 'eva', name: 'volume-off-outline' },
  VolumeUp: { pack: 'eva', name: 'volume-up-outline' },
  Weight: { pack: 'eva-extension', name: 'weight-outline' },
  WifiOff: { pack: 'eva', name: 'wifi-off-outline' },
  Wifi: { pack: 'eva', name: 'wifi-outline' },
};
