import React from 'react';
import {
  ViewProps,
} from 'react-native';
import { BannerPresentingConfig } from './BannerService';

type ChildElement = React.ReactElement;
type ChildrenProp = ChildElement | ChildElement[];

export interface BannerResolverProps extends ViewProps, BannerPresentingConfig {
  children: ChildrenProp;
}

export class BannerResolver extends React.Component<BannerResolverProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<BannerResolverProps> = {
  };

  private renderChildElement = (source: ChildElement): ChildElement => {
    return React.cloneElement(source, {
      style: [source.props.style, this.props.style],
    });
  };

  private renderComponentChildren = (source: ChildrenProp): ChildElement[] => {
    return React.Children.map(source, this.renderChildElement);
  };

  private renderComponent = (): React.ReactElement<ViewProps> => {
    const componentChildren = this.renderComponentChildren(this.props.children);

    return (
      <>
        {componentChildren}
      </>
    );
  };

  public render (): React.ReactElement<ViewProps> | undefined {
    return this.renderComponent();
  }
}
