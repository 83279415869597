import React, { ReactElement } from 'react';
import {
  View,
  ViewProps,
  ImageProps,
  TextProps,
  StyleSheet,
} from 'react-native';
import {
  styled,
  StyleType,
  StyledComponentProps,
} from '@ui-kitten/components';
import { FalsyFC, FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { BannerStatus } from '@theme/variant-interfaces/Status';
import { HSpacer } from '../Spacer/HSpacer';
import { IconButtonProps } from '../IconButton/IconButton';

const styles = StyleSheet.create({
  body: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  mainContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export interface GlobalBannerProps extends ViewProps, StyledComponentProps {
  accessoryRight?: RenderProp<{
    iconButtonProps: Partial<IconButtonProps>,
  }>,
  action?: RenderProp<Partial<ImageProps>>,
  testID: string,
  title?: React.ReactText | RenderProp<TextProps>,
  status?: BannerStatus,
}

@styled('GlobalBanner')
export class GlobalBanner extends React.Component<GlobalBannerProps> {
  private getComponentStyle = (source: StyleType) => {
    const title = PropsServiceHelper.allWithPrefixMapped(source, 'title');
    const body = PropsServiceHelper.allWithPrefixMapped(source, 'body');
    const action = PropsServiceHelper.allWithPrefixMapped(source, 'action');
    const mainContent = PropsServiceHelper.allWithPrefixMapped(source, 'mainContent');

    return {
      title,
      body,
      action,
      mainContent,
    };
  };

  public render () {
    const {
      eva,
      title,
      action,
      accessoryRight,
      testID,
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View style={[evaStyle.body, styles.body]} testID={testID}>
        <View />
        <View style={[styles.mainContent, evaStyle.mainContent]}>
          <FalsyText
            component={title}
            style={evaStyle.title}
            testID={`${testID}-title`}
          />
          <HSpacer size="4" />
          <FalsyFC
            component={action}
            style={[evaStyle.action]}
            testID={`${testID}-action`}
          />
        </View>
        <FalsyFC
          component={accessoryRight as RenderProp<Partial<ReactElement>>}
          /**
           * Fallback to an equally sized Spacer
           * so we can rely on flex space-between to center content
           */
          fallback={<HSpacer size="8" />}
          iconButtonProps={{ size: 'small', appearance: 'ghost', status: 'basic' }}
          testID={`${testID}-accessory-right`}
        />
      </View>
    );
  }
}
