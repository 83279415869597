import {
  Button,
  FilterCategory,
  FilterTable,
  Icon,
  MenuItem,
  OverflowMenu,
  SortDirection,
  TextLink,
  useToast,
} from '@design';
import { PlanStatus } from '@shared/enums';
import {
  ApiFarmPlan,
  ApiProductSummary,
  FarmPlanEndpoint,
  GrowerEndpoint,
  PaginatedData,
  StatisticsCropYearType,
} from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys, Routes } from '../../../../constants';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../../hooks/useBusinessLocationList';
import { useCropYears } from '../../../../hooks/useCropYears';
import { useHistory } from '../../../../router/index';
import { StringUtility } from '../../../../utilities';
import { GrowerApi, GrowerFarmPlanApi, UserApi } from '../../../../utilities/api';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { FarmPlanListRowDetails } from './FarmPlanListRowDetails';
import { maxListSize } from '@design/FilterMenu/FilterMenu';
import { useCustomerTotals } from '../../../../hooks/useCustomerTotals';

interface FarmPlanTabProps {
  growerId?: string,
  nativeId?: string,
}

enum FarmPlanFilterKeys {
  BUSINESS_LOCATIONS = 'locationId',
  GROWER = 'growerId',
  CROP_YEAR = 'cropYear',
}

const defaultPageOptions: FarmPlanEndpoint.List.Query = {
  page: 0,
  sort: FarmPlanEndpoint.List.FarmPlanSort.updatedAt,
  sortDesc: true,
};

const sortColDictionary = {
  name: 'planName',
};

export const FarmPlanTab = (props: FarmPlanTabProps) => {
  const history = useHistory();
  const { setModalProps } = useAppContext();
  const scrollRef = useRef<ScrollView>();
  const prevBusinessIdRef = useRef<string>();
  const { user, currentBusinessId } = useAuthentication();
  const { businessLocations } = useBusinessLocationList(currentBusinessId);
  const { createToast } = useToast();
  const [translate] = useTranslation(['common', 'prepare', 'farmPlans', 'businesses']);
  const [farmPlans, setFarmPlans] = useState<(ApiFarmPlan & RowMeta)[]>([]);
  const [growerAssignments, setGrowerAssignments] = useState<string[]>([]);
  const [initError, setInitError] = useState(null);
  const [farmPlansLoaded, setFarmPlansLoaded] = useState(false);
  const [pageOptions, setPageOptions] = useState<FarmPlanEndpoint.List.Query>(defaultPageOptions);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { cropYears, invalidateCropYears } = useCropYears(
    currentBusinessId, StatisticsCropYearType.FARM_PLANS,
  );
  const [customerSearch, setCustomerSearch] = useState('');

  const defaultFilters = [
    FarmPlanFilterKeys.GROWER,
    FarmPlanFilterKeys.BUSINESS_LOCATIONS,
    FarmPlanFilterKeys.CROP_YEAR,
  ];

  const userGreaterThanBizStandard = RoleUtility.roleHasPermission(
    user.userRole, Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const isSuperUser = RoleUtility.roleHasPermission(
    user.userRole, Permissions.ACCESS_ALL_BUSINESSES,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterOptions: FilterCategory[] = [
    {
      columnLabel: translate('CUSTOMER'),
      columnKey: FarmPlanFilterKeys.GROWER,
      columns: [],
    },
    {
      columnLabel: translate('BUSINESS_LOCATION'),
      columnKey: FarmPlanFilterKeys.BUSINESS_LOCATIONS,
      columns: [],
    },
    {
      columnLabel: translate('CROP_YEAR'),
      columnKey: FarmPlanFilterKeys.CROP_YEAR,
      columns: [],
    },
  ];
  const [columnCategories, setColumnCategories] = useState<FilterCategory[]>(
    _.cloneDeep(filterOptions),
  );

  // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
  useEffect(() => {
    prevBusinessIdRef.current = currentBusinessId;
  });
  const prevBusinessId = prevBusinessIdRef.current;

  useEffect(() => {
    const newColumnCategories = [...columnCategories];
    const filters = newColumnCategories.find((category) => (
      category.columnKey === FarmPlanFilterKeys.CROP_YEAR));

    const yearsInColumns = filters.columns.reduce((acc, prev) => {
      return [...acc, prev.id];
    }, []);

    if (!_.isEqual(cropYears, yearsInColumns)) {
      filters.columns = cropYears.map((cropYear) => ({
        label: cropYear,
        id: cropYear,
      }));
      setColumnCategories(newColumnCategories);
    }
  }, [columnCategories, cropYears]);

  // Reset the filter categories on business change
  // Uses the previous businessId to compare to the currentBusinessId
  useEffect(() => {
    if (prevBusinessId !== currentBusinessId) {
      setColumnCategories(filterOptions);
      setPageOptions(defaultPageOptions);
      setFarmPlansLoaded(false);
    }
  }, [currentBusinessId, filterOptions, prevBusinessId]);

  const { customerTotals, isSuccess } = useCustomerTotals({
    onError: (err) => { setInitError(err); },
  });

  useQuery(
    [QueryKeys.GROWER_LIST, currentBusinessId, customerSearch],
    async () => GrowerApi.getGrowers({
      assigned: !userGreaterThanBizStandard,
      businessId: currentBusinessId,
      limit: maxListSize,
      search: customerSearch,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }),
    {
      enabled: farmPlansLoaded && isSuccess,
      onError: (err) => {
        setPageOptions({ ...pageOptions, page: 0 });
        setInitError(translate('UNEXPECTED_ERROR', { error: err }));
      },
      onSuccess: (customers) => {
        if (farmPlans.length === 0) {
          return;
        }
        const newColumnCategories = [...columnCategories];
        const newGrowerOptions = customers.data.map((grower) => ({
          label: grower.legalName,
          id: grower.id,
        }));
        const growerFilter = newColumnCategories.find((category) => (
          category.columnKey === FarmPlanFilterKeys.GROWER));
        growerFilter.columns = newGrowerOptions;
        growerFilter.total = customerTotals;
        growerFilter.onSearch = setCustomerSearch;
        setColumnCategories(newColumnCategories);
      }, 
    },
  );

  useEffect(() => {
    const newBusinessLocations = businessLocations.map((location) => ({
      label: location.locationName,
      id: location.id,
    }));
    const businessLocationFilter = columnCategories.find((category) => (
      category.columnKey === FarmPlanFilterKeys.BUSINESS_LOCATIONS));
    businessLocationFilter.columns = newBusinessLocations;
  }, [businessLocations, columnCategories]);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, [scrollRef]);

  // Error handling
  useEffect(() => {
    if (initError) {
      createToast({
        status: 'warning',
        children: `${translate('UNEXPECTED_ERROR', { error: initError })}`,
        testID: 'toast-content-element',
      });
    }
  }, [createToast, initError, translate]);

  const getGrowerAssignments = async () => UserApi.getGrowerAssignments();

  const {
    isFetching: isGrowerAssignmentsFetching,
    isError: isGrowerAssignmentsError,
  } = useQuery<string[], Error>(
    [QueryKeys.GROWER_ASSIGNMENTS, currentBusinessId, props.growerId, pageOptions],
    getGrowerAssignments, {
      onError: (err) => {
        setInitError(err);
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (growers) => {
        setGrowerAssignments(growers);
      },
    },
  );

  const generateDeliverable = (farmPlan: ApiFarmPlan) => {
    setModalProps({
      farmPlanId: farmPlan.id,
      showNoteCheckbox: !!farmPlan.note,
      type: 'createDeliverableFarmPlan',
    });
  };

  const getFarmPlans = async () => {
    const appliedFilters: FarmPlanEndpoint.List.Query = { ...pageOptions };
    const listFilters: FarmPlanEndpoint.List.Query = {
      ...appliedFilters,
      ...(props.growerId ? { growerId: [props.growerId] } : {}),
      businessId: currentBusinessId,
    };
    return GrowerFarmPlanApi.listFarmPlans(listFilters);
  };

  const {
    isFetching: isFetchingFarmPlans,
    isRefetching: isRefetchingFarmPlans,
  } = useQuery<PaginatedData<ApiFarmPlan>, Error>(
    [QueryKeys.FARM_PLAN_LIST, pageOptions],
    getFarmPlans, {
      keepPreviousData: true,
      enabled: !!currentBusinessId && isGrowerAssignmentsFetching && !isGrowerAssignmentsError,
      onError: (err) => {
        setInitError(err);
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (farmPlansData) => {
        setInitError(null);
        setFarmPlans(farmPlansData.data.map((farmPlan, idx) => ({
          ...farmPlan,
          hasDetails: true,
          rowId: `row:${idx}|${farmPlan.id}|`,
        })));

        setTotalPages(farmPlansData.lastPage + 1);
        setTotalResults(farmPlansData.total);

        setFarmPlansLoaded(true);
      },
    },
  );

  useEffect(() => {
    if (isRefetchingFarmPlans) {
      invalidateCropYears();
    }
  }, [isRefetchingFarmPlans, invalidateCropYears]);

  const handleSort = (column: string, direction: SortDirection) => {
    setPageOptions({
      ...pageOptions,
      sort: sortColDictionary[column] ?? column,
      sortDesc: direction === 'DESC',
      page: 0,
    });
  };

  const onPageChange = (page: number) => {
    setPageOptions({ ...pageOptions, page: page - 1 });
  };

  // does this set of summaries have any accepted orders in it?
  const hasAcceptedOrders = (summaries: ApiProductSummary[]) => (
    summaries?.some((summary) => summary.planStatus === PlanStatus.ACCEPTED)
  );

  // eslint-disable-next-line consistent-return
  const showEditOption = (farmPlan) => {
    if (!hasAcceptedOrders(farmPlan.growerFarmPlanProductSummaries ?? [])) {
      return (
        <MenuItem
          onPress={() => {
            history.push(Routes.FARM_PLAN_EDIT.replace(/:id/, farmPlan.id));
          }}
          testID="Edit-option"
          title={`${translate('EDIT')}`}
        />
      );
    }
  };

  const columns: IColumn<(ApiFarmPlan & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (farmPlan) => (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => {
            if (!hasAcceptedOrders(farmPlan.growerFarmPlanProductSummaries ?? [])) {
              history.push(Routes.FARM_PLAN_EDIT.replace(/:id/, farmPlan.id));
            } else {
              history.push(
                Routes.FARM_PLAN_OVERVIEW
                  .replace(/:grower/, farmPlan.growerId)
                  .replace(/:id/, farmPlan.id),
              );
            }
          }}
          wrap
        >
          {farmPlan.planName}
        </TextLink>
      ),
      flex: 3,
    },
    {
      columnId: 'grower',
      header: {
        render: translate('CUSTOMER'),
      },
      render: (farmPlan) => farmPlan.grower.legalName,
      flex: 3,
    },
    {
      columnId: 'acresPlanned',
      header: {
        render: translate('ACRES_PLANNED'),
      },
      render: (farmPlan) => StringUtility.formatDecimal(farmPlan.acresPlanned, 3),
      flex: 3,
    },
    userGreaterThanBizStandard && {
      columnId: 'userAccountId',
      header: {
        render: translate('OWNER'),
      },
      render: (farmPlan) => farmPlan.userAccount?.fullName ?? '',
      flex: 3,
    },
    {
      columnId: 'menu',
      header: { render: null },
      render: (farmPlan) => {
        const canModifyOtherOwner = RoleUtility.roleHasPermission(
          user.userRole, Permissions.MODIFY_OTHER_OWNER,
        );
        const userCanEditFarmPlans = canModifyOtherOwner
        || growerAssignments.includes(farmPlan.growerId);
        return userCanEditFarmPlans ? (
          <OverflowMenu testID="prepare-fp-tab-overflow-menu">
            {showEditOption(farmPlan)}
            <MenuItem
              onPress={() => {
                history.push(
                  Routes.FARM_PLAN_OVERVIEW
                    .replace(/:grower/, farmPlan.growerId)
                    .replace(/:id/, farmPlan.id),
                );
              }}
              testID="Order details-option"
              title={`${translate('ORDER_DETAIL_SENTENCE')}`}
            />
            { !isSuperUser && (
              <MenuItem
                onPress={() => generateDeliverable(farmPlan)}
                testID="Create deliverable-option"
                title={`${translate('CREATE_DELIVERABLE_SENTENCE')}`}
              />
            )}
          </OverflowMenu>
        ) : null;
      },
      flex: 0.3,
    },
  ];

  return (
    <ScrollView ref={scrollRef}>
      <FilterTable
        accessoryRight={
          (
            <Button
              accessoryLeft={(iconProps) => (
                <Icon name="Plus" testID="create-farm-plan-button-icon" {...iconProps} />
              )}
              appearance="filled"
              onPress={() => history.push(Routes.FARM_PLAN_CREATE)}
              size="medium"
              status="primary"
              testID="create-farm-plan-button"
            >
              {translate('CREATE_FARM_PLAN') as string}
            </Button>
          )
        }
        columns={columns}
        currentPage={pageOptions.page + 1}
        data={farmPlans}
        defaultColumnFilters={defaultFilters}
        filterOptions={columnCategories}
        isLoading={isFetchingFarmPlans}
        noDataMessage={translate('NO_FARM_PLANS')}
        noFilters={false}
        onPageChange={onPageChange}
        onSort={handleSort}
        onUpdateFilter={(filter) => {
          setPageOptions({
            ...filter,
            sort: FarmPlanEndpoint.List.FarmPlanSort.updatedAt,
            sortDesc: true,
            page: 0,
          });
        }}
        rowDetail={(rowDetail) => <FarmPlanListRowDetails farmPlan={rowDetail} />}
        tableId={`${props.nativeId}`}
        tableRowId={`${props.nativeId}ListView`}
        testID="farm-plan-table"
        totalPages={totalPages}
        totalResults={totalResults}
      />
    </ScrollView>
  );
};
