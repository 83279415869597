import { Header } from '@design';
import { ApiDiscount, DiscountEndpoint } from '@shared/interfaces/api';
import { CalculatedProductDiscount } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ProductDiscountItem } from './ProductDiscountItem';

export interface ProductDiscountsProps {
  calculatedDiscounts: CalculatedProductDiscount[],
  discounts: (ApiDiscount | DiscountEndpoint.Save.Request)[],
  onDeleteDiscount?(discountIdx?: number, productId?: string): void,
  onEditDiscount?(
    discount: DiscountEndpoint.Save.Request,
    discountIdx?: number,
    productId?: string,
  ): void,
  productId?: string,
}

export const ProductDiscounts = ({
  calculatedDiscounts,
  discounts,
  onDeleteDiscount,
  onEditDiscount,
  productId,
}: ProductDiscountsProps) => {
  const [translate] = useTranslation(['common', 'productCard']);

  const themedStyles = useStyleSheet(
    {
      container: {
        backgroundColor: 'color-basic-transparent-0',
        paddingHorizontal: 16,
        paddingTop: 4,
        paddingBottom: 40,
      },
    },
  );

  return (
    <View style={themedStyles.container}>
      <Header level="4" testID="discounts-header" title={translate('DISCOUNTS')} />
      {discounts.map((discount, discountIdx) => (
        <ProductDiscountItem
          calculatedDiscount={calculatedDiscounts[discountIdx]}
          discount={discount}
          discountIdx={discountIdx}
          discounts={discounts}
          key={discount.id ?? discountIdx}
          onDeleteDiscount={onDeleteDiscount}
          onEditDiscount={onEditDiscount}
          productId={productId}
        />
      ))}
    </View>
  );
};
