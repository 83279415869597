import React, { ReactText } from 'react';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import { StyleType } from 'ui-components';
import { View, StyleSheet, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { TextProps } from '../Text/Text';

const styles = StyleSheet.create({
  container: {
    maxWidth: 352,
  },
  flex: {
    flex: 1,
  },
});
export interface DataPointProps extends StyledComponentProps {
  children: RenderProp<TextProps> | React.ReactText,
  flex?: boolean,
  label?: RenderProp<TextProps> | React.ReactText,
  labelStyle?: StyleProp<TextStyle>,
  style?: StyleProp<ViewStyle>,
  testID: string,
  valueStyle?: StyleProp<TextStyle>,
}

type DataPointElement = React.ReactElement<DataPointProps>;

@styled('DataPoint')
export class DataPoint extends React.Component<DataPointProps> {
  private getComponentStyle (style: StyleType) {
    const textStyles = PropsServiceHelper.allWithPrefixMapped(style, 'text');
    const labelStyles = PropsServiceHelper.allWithPrefixMapped(style, 'label');
    return {
      text: textStyles,
      label: labelStyles,
    };
  }

  public render (): DataPointElement {
    const { eva, flex, style, children, label, ...restProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View style={[flex ? styles.flex : styles.container, style]} {...restProps}>
        <FalsyText
          component={label as ReactText}
          style={[evaStyle.label, this.props.labelStyle]}
          testID={`${label}-label`}
        />
        <FalsyText
          component={children as ReactText}
          style={[evaStyle.text, this.props.valueStyle]}
          testID={`${label}-value`}
        />
      </View>
    );
  }
}
