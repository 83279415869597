import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { styled } from '@ui-kitten/components/theme';
import { Redirect, Route, Switch } from '../router';
import { Routes } from '../constants';
import { VersionPage } from '../pages/public';
import { WithHeader } from '../elements/navigation/WithHeader';
import { DeliverablePublicPage } from '../pages/Deliverable/DeliverablePublicPage';
import { Modal, ModalUtility } from '../elements/content';
import { PublicHelp } from '../src/pages/Help/PublicHelp';

const PublicViewRaw = ({ eva }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  // Tie singleton utilities to this element
  useEffect(() => {
    ModalUtility.setShow((content: JSX.Element) => {
      setModalContent(content);
      setShowModal(true);
    });
    ModalUtility.setHide(() => {
      setShowModal(false);
    });
  }, []);

  return (
    <View style={[eva.style, { flex: 1 }]}>
      <Switch>
        <Route
          path={Routes.GROWER_DELIVERABLE_PUBLIC}
        >
          <DeliverablePublicPage />
        </Route>
        <Route
          path={Routes.VERSION}
        >
          <WithHeader style={eva.style}>
            <VersionPage />
          </WithHeader>
        </Route>
        <Route path={Routes.PUBLIC_HELP} render={() => <PublicHelp />} />
        <Route render={() => <Redirect to={Routes.LOGIN} />} />
      </Switch>
      <Modal content={modalContent} visible={showModal} />
    </View>
  );
};

export const PublicView = styled('App')(PublicViewRaw);
