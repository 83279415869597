export enum ThemeColors {
  PRIMARY = '#EDF354',
  BACKGROUND = '#232325',
  TEXT = '#F2F2F2',
  INPUT = '#CDCDCD',
  WHITE = '#FFFFFF',
  SECONDARY = '#64B2BD',
  DARK_GREEN = 'F5C742',
  DARKEST_GRAY = '#2F2F2F',
  DARK_GRAY = '#3E3E3F',
  MID_GRAY = '#484848',
  LIGHT_GRAY = '#6A6A6A',
  LIGHTER_GRAY = '#ACACAC',
  LIGHT_BLACK = '#252525',
  DANGER = '#CF6679',
  WARNING = '#E5A236',
  SUCCESS = '#8BC467',
  INFO = '#37CAD3',
  HEADER = '#131313',
  SIDEBAR = '#1A1A1B',
  DRAWER = '#29292A',
  CLOSE = '#292929',
  DISCOUNT = '#00D68F',

  TAG_GREEN_1 = '#20DEB7',
  TAG_GREEN_2 = '#96ED89',
  TAG_GREEN_3 = '#BEDB39',
  TAG_GREEN_4 = '#97C0A0',
  TAG_GREY_1 = '#9BB4BF',
  TAG_YELLOW_1 = '#EDDA99',
  TAG_YELLOW_2 = '#FFE11A',
  TAG_YELLOW_3 = '#FFF176',
  TAG_PURPLE_1 = '#BD9CF6',
  TAG_PURPLE_2 = '#FF74FF',
  TAG_BLUE_1 = '#7ABAF2',
  TAG_BLUE_2 = '#04BFBF',
  TAG_BLUE_3 = '#ADD5F7',
  TAG_BLUE_4 = '#0EEAFF',
  TAG_BLUE_5 = '#76FFFF',
  TAG_RED_1 = '#F2977A',
  TAG_RED_2 = '#DCA682',
  TAG_PINK_1 = '#FF8A8A',
  TAG_PINK_2 = '#E89CCE',
  TAG_ORANGE_1 = '#FA9600',
}
