import { ApiPriceType, ApiPriceTypeCreate, ApiPriceTypeUpdate } from '@shared/interfaces/api';
import { Client } from './Client';

export class PriceTypeApi {
  static create (
    priceType: ApiPriceTypeCreate,
  ): Promise<ApiPriceType> {
    return Client('price-types', {
      method: 'POST', body: priceType,
    });
  }

  static delete (id: string): Promise<void> {
    return Client(`price-types/${id}`, {
      method: 'DELETE',
    });
  }

  static get (id: string): Promise<ApiPriceType> {
    return Client(`price-types/${id}`, {
      method: 'GET',
    });
  }

  static list (businessId?: string): Promise<ApiPriceType[]> {
    let url = 'price-types';
    if (businessId) {
      url += `?businessId=${businessId}`;
    }
    return Client(url, {
      method: 'GET',
    });
  }

  static update (
    id: string,
    priceType: ApiPriceTypeUpdate,
  ): Promise<ApiPriceType> {
    return Client(`price-types/${id}`, {
      method: 'PATCH', body: priceType,
    });
  }
}
