import * as eva from '@eva-design/eva';

export const GrowersLightTheme = {
  ...eva.light,

  // Crop colors
  'crop-color-placeholder': '$text-hint-color',
  'color-crop-01': '#97C0A0',
  'color-crop-02': '#BEDB39',
  'color-crop-03': '#96ED89',
  'color-crop-04': '#20DEB7',
  'color-crop-05': '#0EEAFF',
  'color-crop-06': '#04BFBF',
  'color-crop-07': '#7ABAF2',
  'color-crop-08': '#76FFFF',
  'color-crop-09': '#FF74FF',
  'color-crop-10': '#BD9CF6',
  'color-crop-11': '#9BB4BF',
  'color-crop-12': '#ADD5F7',
  'color-crop-13': '#FF8A8A',
  'color-crop-14': '#F2977A',
  'color-crop-15': '#DCA682',
  'color-crop-16': '#E89CCE',
  'color-crop-17': '#FA9600',
  'color-crop-18': '#EDDA99',
  'color-crop-19': '#FFE11A',
  'color-crop-20': '#FFF176',

  // TextLink colors
  'color-secondary-100': '#FDFDE0',
  'color-secondary-200': '#D2F7F3',
  'color-secondary-300': '#B6E9E7',
  'color-secondary-400': '#9AD2D6',
  'color-secondary-500': '#64B2BD',
  'color-secondary-600': '#5A8EA0',
  'color-secondary-700': '#416E85',
  'color-secondary-800': '#2C506A',
  'color-secondary-900': '#1D3B57',
  'color-secondary-default': '$color-secondary-500',
  'color-secondary-hover': '$color-secondary-400',
  'color-secondary-focus': '$color-secondary-200',
  'color-secondary-active': '$color-secondary-300',
  'color-secondary-disabled': '$color-basic-transparent-300',
  'color-secondary-transparent': 'rgba(237, 243, 84, 0.04)',
  'color-secondary-transparent-100': 'rgba(237, 243, 84, 0.08)',
  'color-secondary-transparent-200': 'rgba(237, 243, 84, 0.16)',
  'color-secondary-transparent-300': 'rgba(237, 243, 84, 0.24)',
  'color-secondary-transparent-400': 'rgba(237, 243, 84, 0.32)',
  'color-secondary-transparent-500': 'rgba(237, 243, 84, 0.4)',
  'color-secondary-transparent-600': 'rgba(237, 243, 84, 0.48)',
};
