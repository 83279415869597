import { Button, Dialog, HSpacer, Text } from '@design';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

const styles = StyleService.create({
  footer: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    flexDirection: 'row',
    display: 'flex',
  },
});

interface DeleteDeliverableDialogProps {
  visible: boolean;
  onClose: () => void;
  onDelete: () => void;
  name: string;
}

export const DeleteDeliverableDialog: FC<DeleteDeliverableDialogProps> = (
  { onClose, visible, onDelete, name },
) => {
  const [translate] = useTranslation(['prepare', 'common', 'deliverable', 'errors']);
  const themedStyles = useStyleSheet(styles);

  return (
    <Dialog
      footerAccessory={({
        primaryButtonProp,
        secondaryButtonProp,
        spacerProp,
      }) => (
        <View style={themedStyles.footer}>
          <Button
            testID="delete-deliverable-modal-cancel-button"
            {...primaryButtonProp}
          >
            {`${translate('CANCEL')}`}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            testID="delete-deliverable-modal-save-button"
            {...secondaryButtonProp}
            onPress={onDelete}
          >
            {`${translate('DELETE_DELIVERABLE_CONFIRM')}`}
          </Button>
        </View>
      )}
      onClose={onClose}
      testID="delete-deliverable-modal"
      title={
        name
          ? translate('DELETE_DELIVERABLE_TITLE', { name })
          : ''
      }
      visible={visible}
    >
      <Text category="p2">
        {`${translate('DELETE_DELIVERABLE_TEXT')}`}
      </Text>
    </Dialog>
  );
};
