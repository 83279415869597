import { Popover } from '@ui-kitten/components';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { testId } from '../../../../utilities/testId';
import { FilterMenu } from '../FilterMenu/FilterMenu';
import { Select } from '../Select';
import { DropdownValue } from './DropdownValue';
import { FilterCategory, Selections } from './Filter';

export interface MainFilterSelectsProps {
  dropdownContainerStyle: Record<string, any>,
  filterIndicatorCircleStyle: Record<string, any>,
  isFilterDisabled?: boolean,
  onUpdateSelections: (key: string, category: string, value: boolean) => void,
  options: FilterCategory,
  selections: Selections,
  selectStyle: Record<string, any>,
}
export function MainFilterSelects ({
  dropdownContainerStyle,
  filterIndicatorCircleStyle,
  isFilterDisabled,
  onUpdateSelections,
  options,
  selections,
  selectStyle,
}: MainFilterSelectsProps) {
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  return (
    <View>
      <Popover
        anchor={() => (
          <TouchableOpacity>
            <Select
              disabled={isFilterDisabled}
              {...testId(`filter-select-${options.columnKey}`)}
              onPress={() => { setVisibleDropdown(true); }}
              style={selectStyle}
              value={(
                <DropdownValue
                  dropdownContainerStyle={dropdownContainerStyle}
                  filterIndicatorCircleStyle={filterIndicatorCircleStyle}
                  options={options}
                  selections={selections}
                />
              )}
            />
          </TouchableOpacity>
        )}
        onBackdropPress={() => { setVisibleDropdown(false); }}
        placement="bottom start"
        style={{ paddingTop: 16 }}
        visible={visibleDropdown}
      >
        <FilterMenu
          initialSearchValue={searchQuery}
          multiSelect={!options.singleSelection}
          onSearch={(query) => {
            setSearchQuery(query);
            options.onSearch(query);
          }}
          onSelect={(option, value) => {
            if (options.singleSelection) setVisibleDropdown(false);
            onUpdateSelections(option, options.columnKey, value);
          }}
          options={options.columns}
          selectedOptions={selections}
          style={{
            paddingHorizontal: 0,
            maxHeight: 300,
          }}
          testID={`filter-menu-${options.columnKey}`}
          total={options.total}
        />
      </Popover>
    </View>
  );
}
