import { ApiGrowerFarm } from '@shared/interfaces/api';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { GrowerFarmApi } from '../utilities/api';

const initialData: ApiGrowerFarm[] = [];

interface UseFarmsProps {
  customerId: string | null,
  onError?(error: Error): void,
}

export function useFarms ({
  customerId,
  onError,
}: UseFarmsProps) {
  const queryClient = useQueryClient();
  const invalidateFarms = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.CUSTOMER_FARM_LIST, customerId])
  ), [customerId, queryClient]);

  const { data, isFetching: isFarmsLoading } = useQuery(
    [QueryKeys.CUSTOMER_FARM_LIST, customerId],
    async () => {
      if (!customerId) {
        return {
          farms: initialData,
        };
      }

      const farms = await GrowerFarmApi.getFarms(customerId);

      return {
        farms: farms.data,
      };
    },
    {
      onError: (error: Error) => onError?.(error),
      placeholderData: {
        farms: initialData,
      },
    },
  );

  return {
    ...data,
    invalidateFarms,
    isFarmsLoading,
  };
}
