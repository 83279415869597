import BreadCrumb from './breadcrumb';
import Business from './businesses';
import BusinessLocation from './businessLocations';
import Common from './common';
import Dashboard from './dashboard';
import Deliverable from './deliverable';
import Errors from './errors';
import FarmPlans from './farmPlans';
import Growers from './growers';
import Integration from './integration';
import Login from './login';
import Prepare from './prepare';
import ProductCard from './ProductCard';
import ProductOrders from './productOrders';
import Products from './products';
import Programs from './programs';
import PropertyGroups from './propertyGroups';
import Reports from './reports';
import Sidebar from './sidebar';
import TankMix from './tankMix';
import Users from './users';

export default {
  breadcrumb: BreadCrumb,
  businessLocations: BusinessLocation,
  businesses: Business,
  common: Common,
  dashboard: Dashboard,
  deliverable: Deliverable,
  errors: Errors,
  farmPlans: FarmPlans,
  growers: Growers,
  integration: Integration,
  login: Login,
  prepare: Prepare,
  productCard: ProductCard,
  productOrders: ProductOrders,
  products: Products,
  programs: Programs,
  propertyGroups: PropertyGroups,
  reports: Reports,
  sidebar: Sidebar,
  tankMix: TankMix,
  users: Users,
};
