export default {
  ACTIVATE_LOCATION: 'Activate location',
  ADD_BUSINESS_LOCATION_TITLE: 'Create Business Location',
  ASSIGNED_TO: 'Assigned to',
  ASSIGN_TO_BUSINESS: 'Assign to Business',
  BUSINESS_LOCATION_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this location?',
  BUSINESS_LOCATION_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this location?',
  BUSINESS_LOCATION_DETAILS: 'Location Details',
  BUSINESS_LOCATION_NAME: 'Location Name',
  BUSINESS_LOCATION_SUCCESSFULLY_ADDED: 'Location successfully added',
  BUSINESS_LOCATION_SUCCESSFULLY_EDITED: 'Location successfully edited',
  CHANGE_BUSINESS_ASSIGNMENT: 'Change Business Assignment',
  COUNTY: 'County',
  CREATE_BUSINESS_LOCATION: 'Create Business Location',
  DEACTIVATE_LOCATION: 'Deactivate location',
  DUPLICATE_BUSINESS_LOCATION: 'Location name already exists',
  EDIT_BUSINESS_LOCATION_TITLE: 'Edit Business Location',
  ERROR_BUSINESS_LOCATION_NAME_ALREADY_USED: 'Location named {{name}} already exists',
  ERROR_CREATING_BUSINESS_LOCATION: 'Error creating location',
  ERROR_FETCH_BUSINESS_LOCATION: 'Error loading location',
  NO_LOCATIONS_FOUND: 'No locations found',
  POSTAL_CODE: 'Postal Code',
  STATE: 'State',
  STATUS: 'Status',
};
