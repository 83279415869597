import { UserType } from '@shared/enums';
import { ApiBusiness, BusinessEndpoint } from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { getBusinessEditRoute, getBusinessRoute, Routes, Sizing } from '../constants';
import { useAppContext } from '../contexts/AppContext';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import {
  ConfirmModal,
  Filters,
  FilterType,
  IFilterSettings,
  ModalUtility,
  SidePanelUtility,
} from '../elements/content';
import { TableQuickView } from '../elements/quickviews/TableQuickView';
import {
  ISortableTableHeaderProps,
  ISortableTableRowProps,
  SortableTable,
} from '../elements/table';
import { useHistory } from '../router/index';
import { ActionLabel, Alert, Button, Search, Spinner, Text } from '../ui-components';
import { BusinessApi, BusinessLocationApi } from '../utilities/api';
import { CSVResponse, ExportToCSV } from '../utilities/helpers/exportCSV';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionBar: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Sizing.BASE_SPACING,
  },
  flexLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rowButton: { marginLeft: Sizing.HALF_SPACING },
  filterLabels: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: Sizing.BASE_SPACING,
    marginBottom: Sizing.HALF_SPACING,
  },
  filterLabel: {
    marginRight: Sizing.HALF_SPACING,
    marginBottom: Sizing.HALF_SPACING,
  },
  tableColumnExtraSpacing: {
    marginRight: 1.5 * Sizing.EM,
  },
});

const tableWidths = [3, 3, 3, 3, 3, 0];

const getQuickViewData = (business, translate, user) => ([
  {
    header: translate('DATE_CREATED'),
    value: DateTime.fromISO(business.createdAt).toLocaleString(DateTime.DATE_SHORT),
    testId: 'date-created',
  },
  {
    header: translate('DATE_UPDATED'),
    value: DateTime.fromISO(business.updatedAt).toLocaleString(DateTime.DATE_SHORT),
    testId: 'date-updated',
  },
  (user.userType === UserType.INTERNAL) && {
    header: translate('BUSINESS_ID'),
    value: business.id,
    testId: 'business-id',
  },
  {
    header: translate('STATUS'),
    value: business.isActive ? translate('ACTIVE') : translate('INACTIVE'),
    testId: 'status',
  },
]);

const exportBusinessData = async (
  filters: any,
  translate: any,
  setError: any,
) => {
  try {
    const data: CSVResponse = await BusinessApi.exportBusinesses(filters);
    ExportToCSV(data.csv, translate('BUSINESSES'));
  } catch (err) {
    setError('UNEXPECTED_ERROR');
  }
};

export const BusinessListPage = () => {
  const history = useHistory();
  const { setModalProps } = useAppContext();
  const { user, updateBusinessList } = useAuthentication();
  const [translate] = useTranslation(['businesses', 'common']);
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<BusinessEndpoint.List.BusinessSort>(
    BusinessEndpoint.List.BusinessSort.businessName,
  );
  const [sortDesc, setSortDesc] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [lastPage, setLastPage] = useState<number>(0);
  const [businesses, setBusinesses] = useState<ApiBusiness[]>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [allCountryList, setAllCountryList] = useState([]);
  const [forceGet, setForceGet] = useState(false);
  const [tableRows, setTableRows] = useState<ISortableTableRowProps[]>([]);

  useEffect(() => {
    // Runs once on mount
    (async () => {
      try {
        setAllCountryList(await BusinessLocationApi.getCountries());
      } catch (err) {
        setError('UNEXPECTED_ERROR');
      }
    })();
  });

  useEffect(() => {
  /**
   * Loads a list of paged businesses
   */
    const getBusinesses = async () => {
      try {
        setError(null);
        setIsLoading(true);
        const result = await BusinessApi.getBusinesses({
          ...filters,
          page,
          search,
          sort,
          sortDesc,
        });
        setBusinesses((businesses || []).concat(result.data));
        setLastPage(result.lastPage);
      } catch (err) {
        setPage(Math.max(page - 1, 0));
        setError('UNEXPECTED_ERROR');
      }
      setIsLoading(false);
    };
    if (forceGet || (!isLoading && _.isNil(businesses) && !error)) {
      setForceGet(false);
      getBusinesses();
    }
  }, [
    businesses,
    error,
    filters,
    forceGet,
    history,
    isLoading,
    page,
    search,
    sort,
    sortDesc,
  ]);

  /**
   * Loads the next page of businesses
   */
  const loadNextPage = () => {
    setError(null);
    setPage(page + 1);
    setForceGet(true);
  };

  /**
   * Gets the display string for a filter label
   * @param key The filter key
   * @param value The filter value
   * @returns The display string
   */
  const getFilterLabel = (key: string, value: string) => {
    switch (key) {
      case 'isActive':
        return `${translate('STATUS')}: ${translate(value ? 'ACTIVE' : 'INACTIVE')}`;
      case 'country':
        return `${translate('COUNTRY')}: ${value}`;
      default:
        return '';
    }
  };

  const tableHeaders: ISortableTableHeaderProps[] = useMemo(() => {
    const headers = [
      {
        label: translate('BUSINESS_NAME'),
        field: 'businessName',
        sortable: true,
      },
      {
        label: translate('CONTACT_EMAIL'),
        field: 'contactEmail',
        sortable: true,
      },
      {
        label: translate('CONTACT_PHONE_NUMBER'),
        field: 'telephone',
      },
      {
        label: translate('ADDRESS'),
        field: 'address1',
      },
    ];

    headers.forEach((header: ISortableTableHeaderProps) => {
      header.sorting = header.field === sort;

      if (header.sorting) {
        header.sortDirection = sortDesc ? 'desc' : 'asc';
      }
    });

    return headers;
  }, [translate, sort, sortDesc]);

  useEffect(() => {
    const toggleStatus = async ({ id, isActive }) => {
      let updatedBusiness;
      try {
        setIsLoading(true);
        updatedBusiness = await BusinessApi.updateBusiness(id, { isActive: !isActive });
        setBusinesses(_.map(businesses, (business) => (
          business.id !== id ? business : updatedBusiness
        )));
        updateBusinessList();
      } catch (err) {
        setError('UNEXPECTED_ERROR');
      }
      setIsLoading(false);
    };

    const newRows = _.map(businesses, (business): ISortableTableRowProps => {
      const actions: ISortableTableRowProps['actions'] = [];
      if (
        RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES)
        && business.isActive
      ) {
        actions.push({
          label: 'Edit Business',
          action: () => history.push(getBusinessEditRoute(business.id, Routes.BUSINESS_LIST)),
          nativeID: 'edit-business',
        });
      }

      actions.push({
        label: translate('BUSINESS_DETAILS_ACTION'),
        action: () => history.push(getBusinessRoute(business.id)),
        nativeID: 'business-details',
      });

      if (RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES)) {
        actions.push({
          label: translate(!business.isActive ? 'ACTIVATE' : 'DEACTIVATE'),
          action: () => ModalUtility.show(
            <ConfirmModal
              confirmButtonText={translate(business.isActive ? 'DEACTIVATE' : 'ACTIVATE')}
              message={translate(business.isActive ? 'BUSINESS_DEACTIVATE_MODAL_WARNING' : 'BUSINESS_ACTIVATE_MODAL_WARNING')}
              onConfirm={() => toggleStatus(business as any)}
            />,
          ),
        });
      }

      return ({
        key: business.businessName,
        columns: [
          (
            <View style={styles.tableColumnExtraSpacing}>
              <Text
                numberOfLines={1}
                onPress={() => history.push(getBusinessRoute(business.id))}
              >
                {business.businessName}
              </Text>
            </View>
          ),
          (
            <View style={styles.tableColumnExtraSpacing}>
              <Text numberOfLines={1}>{business.contactEmail}</Text>
            </View>
          ),
          (<Text numberOfLines={1}>{business.telephone}</Text>),
          (<Text numberOfLines={1}>{`${business.address1} ${business.city} ${business.state} ${business.postalCode}`}</Text>),
        ],
        actions,
        drawer: <TableQuickView data={getQuickViewData(business, translate, user)} />,
      });
    });
    setTableRows(newRows);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businesses, filters, history, tableHeaders, translate, user.userRole]);

  /**
   * Sorts users
   * @param field The field to sort by
   * @param direction The direction to sort by (asc, desc)
   */
  const onSort = (field: string, direction: string) => {
    setSort(field as BusinessEndpoint.List.BusinessSort);
    setSortDesc(direction === 'desc');
    setError(null);
    setPage(0);
    setBusinesses(null);
    setForceGet(true);
  };

  /**
   * Searches users
   */
  const onSearch = async () => {
    setError(null);
    setPage(0);
    setForceGet(true);
    setBusinesses(null);
  };

  /**
   * Removes a single filter
   * @param key The filter key
   * @param value The filter value
   */
  const removeFilter = (key: string, value: string) => {
    const newFilters = _.set(_.cloneDeep(filters), key, _.without(filters[key], value));
    if (_.isEmpty(newFilters[key])) {
      delete newFilters[key];
    }
    setFilters(newFilters);
    setSearch(search || null);
    setError(null);
    setPage(0);
    setBusinesses(null);
  };

  /**
   * Clears search and triggers businesses to reload
   */
  const resetSearch = () => {
    setSearch('');
    setError(null);
    setPage(0);
    setBusinesses(null);
    setForceGet(true);
  };

  /**
   * Filter definitions for side panel
   */
  const businessFilters = () => {
    const filterSettings: IFilterSettings[] = [{
      type: FilterType.CHECKBOX,
      label: translate('STATUS'),
      field: 'isActive',
      options: [{
        label: translate('ACTIVE'),
        value: true,
      }, {
        label: translate('INACTIVE'),
        value: false,
      }],
    }, {
      type: FilterType.CHECKBOX,
      label: translate('COUNTRY'),
      field: 'country',
      options: allCountryList.map((country) => ({
        label: country.name,
        value: country.name,
      })),
    }];

    const onSubmitFilters = (selections: any) => {
      setFilters(selections);
      setSearch(search || null);
      setError(null);
      setPage(0);
      setForceGet(true);
      setBusinesses(null);
      SidePanelUtility.hide();
    };

    return (
      <Filters
        filters={filterSettings}
        onSubmit={onSubmitFilters}
        selections={filters}
      />
    );
  };

  return (
    <>
      {RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES) && (
        <View style={styles.actionBar}>
          <View style={styles.flexRight}>
            <Button
              iconLeft="plus-outline"
              onPress={() => setModalProps({ type: 'businessModal' })}
              testID="create-business-button"
            >
              {translate('CREATE_BUSINESS')}
            </Button>
          </View>
        </View>
      )}
      {error && <Alert level="danger" text={translate(error)} />}
      <View style={styles.actionBar}>
        <View style={styles.flexLeft}>
          {(isLoading || search !== null || !_.isEmpty(businesses)) && (
            <Search
              onChangeText={setSearch}
              onClear={resetSearch}
              onSubmitEditing={onSearch}
              placeholder={`${translate('SEARCH')}...`}
              style={{ flex: 1 }}
              testID="search-bar"
              value={search}
            />
          )}
        </View>
        <View style={styles.flexRight}>
          <Button
            iconLeft="file-text-outline"
            onPress={() => exportBusinessData(filters, translate, setError)}
            status="ghost"
            style={styles.rowButton}
            testID="export-business"
          >
            {translate('EXPORT')}
          </Button>
          <Button
            iconLeft="funnel-outline"
            onPress={() => SidePanelUtility.show(businessFilters())}
            status="ghost"
            style={styles.rowButton}
          >
            {translate('FILTERS') as string}
          </Button>
        </View>
      </View>
      {filters && (
        <View style={styles.filterLabels}>
          {Object.keys(filters).map((key) => filters[key].map((value) => (
            <ActionLabel
              key={`label-${key}:${value}`}
              onDismiss={() => removeFilter(key, value)}
              style={styles.filterLabel}
            >
              {getFilterLabel(key, value)}
            </ActionLabel>
          )))}
        </View>
      )}
      { (_.isEmpty(businesses) && !isLoading)
        ? (
          <ScrollView testID="businessListView">
            <View style={[styles.container, styles.spinnerContainer]}>
              <Text testID="no-businesses-error-message">{translate('NO_BUSINESSES') as string}</Text>
            </View>
          </ScrollView>
        )
        : (
          <SortableTable
            headers={tableHeaders}
            lastPage={lastPage}
            loadNextPage={loadNextPage}
            onSort={onSort}
            page={page}
            rows={tableRows}
            widths={tableWidths}
          />
        )}
      {isLoading && (
      <View style={[styles.container, styles.spinnerContainer]}>
        <Spinner size="giant" />
      </View>
      )}
    </>
  );
};
