import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Chip, Modal, VSpacer } from '@design';
import { ApiProductCategory } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
import { ProductCategory } from '@shared/enums';
import { View, StyleSheet, ScrollView } from 'react-native';
import { ProductCategoryInputRow } from './ProductCategoryInputRow';

const styles = StyleSheet.create({
  chip: {
    marginRight: 12,
  },
  chipParent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

interface ModalProps {
  canModifyBusiness: boolean,
  caption: string,
  category: ApiProductCategory,
  onDone: () => void,
  onSubCategoryAdded: (subCategoryName: string) => void;
  onSubCategoryRemoved: (productCategory: ProductCategory, name: string) => void;
  setSubCategoryText: Dispatch<SetStateAction<string>>,
  subCategoryText: string,
}

export const ProductCategoryModal = ({
  canModifyBusiness,
  category,
  onDone,
  onSubCategoryAdded,
  onSubCategoryRemoved,
  setSubCategoryText,
  subCategoryText,
}: ModalProps) => {
  const [translate] = useTranslation(['common', 'businesses']);
  const [chipHeight, setChipHeight] = useState(0);
  const [hasHeightGrown, setHasHeightGrown] = useState(false);

  const addSubCategory = () => {
    if (subCategoryText) {
      onSubCategoryAdded(subCategoryText);
      setSubCategoryText('');
    }
  };

  const removeSubCategory = (
    productCategory: ProductCategory,
    name: string,
  ) => onSubCategoryRemoved(productCategory, name);

  const getTitle = () => `${translate(
    category.category === ProductCategory.OTHER
      ? 'OTHER_PRODUCTS_OPTION'
      : category.category,
  )} (${category.subCategories.length})`;

  return (
    <Modal
      footerAccessory={({ secondaryButtonProp }) => (
        <Button
          {...secondaryButtonProp}
          onPress={onDone}
          testID="done-button"
        >
          {translate<string>('DONE')}
        </Button>
      )}
      height={489}
      hideCloseButton
      onBackdropPress={onDone}
      testID={`${category.category}-modal`}
      title={getTitle()}
      visible
      width={623}
    >
      <ScrollView
        showsHorizontalScrollIndicator={false}
      >
        <ProductCategoryInputRow
          addSubCategory={() => addSubCategory()}
          canModify={canModifyBusiness}
          category={category}
          flexInput
          setSubCategoryText={setSubCategoryText}
          style={styles.inputRow}
          subCategoryText={subCategoryText}
        />
        <VSpacer size="6" />
        <View
          onLayout={(e) => {
            const { height } = e.nativeEvent.layout;
            if (height > chipHeight) {
              setHasHeightGrown(true);
            }
          }}
          style={styles.chipParent}
          testID={`${category.category}-categories`}
        >
          {category.subCategories.map((subcategory) => {
            return (
              <View key={subcategory.name}>
                <Chip
                  disabled={!canModifyBusiness}
                  onLayout={(e) => (
                    setChipHeight(e.nativeEvent.layout.height))}
                  onPress={() => removeSubCategory(category.category, subcategory.name)}
                  style={styles.chip}
                  testID={`${category.category}-subCategory`}
                >
                  {subcategory.name}
                </Chip>
                {hasHeightGrown && <VSpacer size="5" />}
              </View>
            );
          })}
        </View>
      </ScrollView>
    </Modal>
  );
};
