import React, { ReactNode } from 'react';
import {
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';
import {
  BannerResolver,
  BannerResolverProps,
} from './BannerResolver';
import {
  BannerPresenting,
  BannerPresentingConfig,
  BannerService,
} from './BannerService';

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    position: 'absolute',
    top: 72, // NOTE value is value of global header height
    right: 0,
    alignItems: 'flex-end',
    zIndex: 10000,
  },
});

interface BannerPanelChild extends BannerPresentingConfig {
  element: React.ReactElement;
}

export interface BannerPanelProps {
  children?: ReactNode,
}

interface BannerPanelState {
  components: Map<string, BannerPanelChild>;
}

export class BannerPanel extends React.Component<
BannerPanelProps, BannerPanelState>
  implements BannerPresenting {
  // eslint-disable-next-line react/state-in-constructor
  public state: BannerPanelState = {
    components: new Map(),
  };

  public componentDidMount (): void {
    BannerService.mount(this);
  }

  public componentWillUnmount (): void {
    BannerService.unmount();
  }

  private areThereAnyComponents = (): boolean => {
    return this.state.components && this.state.components.size !== 0;
  };

  private generateUniqueComponentKey = (): string => {
    return Math.random().toString(36).substring(2);
  };

  public hide = (identifier: string): string => {
    const { components } = this.state;
    components.delete(identifier);
    this.setState({ components });
    return '';
  };

  public show (element: React.ReactElement, config: BannerPresentingConfig): string {
    const key: string = this.generateUniqueComponentKey();

    this.setState((prevState) => ({
      components: prevState.components.set(key, { ...config, element }),
    }));

    return key;
  }

  public update (identifier: string, children: React.ReactNode): void {
    const panelChild: BannerPanelChild = this.state.components.get(identifier);

    if (!panelChild) {
      return;
    }

    const childElement: React.ReactElement = panelChild.element;
    panelChild.element = React.cloneElement(childElement, childElement.props, children);

    const { components } = this.state;
    components.set(identifier, panelChild);
    this.forceUpdate();
  }

  private renderBanner = (id: string): React.ReactElement<BannerResolverProps> => {
    return (
      <BannerResolver
        key={id}
      >
        {this.state.components.get(id).element}
      </BannerResolver>
    );
  };

  private renderBanners = (): React.ReactElement<BannerResolverProps>[] => {
    return Array.from(this.state.components.keys()).reverse().map(this.renderBanner);
  };

  public render (): React.ReactElement<ViewProps> {
    return (
      <View nativeID="Banner-panel" style={styles.container}>
        {this.props.children}
        {this.areThereAnyComponents() && this.renderBanners()}
      </View>
    );
  }
}
