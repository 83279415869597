import {
  Badge,
  Card,
  HSpacer,
  Icon,
  NumericInput,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { CostType } from '@shared/enums';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { ProductUtilities } from '@shared/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import CalculateQuantityDetails from './CalculateQuantityDetails';
import { ProductDiscounts } from './ProductDiscounts';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  rightAligned: {
    alignItems: 'flex-end',
  },
  centerAligned: {
    alignItems: 'center',
  },
  spaceBetweenRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  qtyWrapper: {
    width: 109,
    height: '100%',
  },
  qtyTextStyles: {
    width: 30,
    textAlign: 'center',
  },
  unitPriceInput: {
    width: 109,
  },
  flexOne: {
    flex: 1,
  },
  dialogFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  isDiscountsAppliedButton: {
    position: 'relative',
    minWidth: 150,
  },
  text: {
    flexShrink: 1,
  },
});

interface ProductCardProps {
  product: ProductOrderDeliverableV0.ProductSummary,
  selections: ProductOrderDeliverableV0.Selections['products'],
  index: number,
}

const ProductCard = ({
  product,
  selections,
  index,
}: ProductCardProps) => {
  const [translate] = useTranslation(['productOrders', 'products', 'common', 'prepare']);
  const {
    applicationRate,
    cost,
    costPerAcre,
    discounts,
    name,
    quantity,
    skuName,
    totalCost,
    totalUnits,
    unitPrice,
  } = selections;

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const showQuantityDetails = (costPerAcre || applicationRate)
    && (product.applicationRate || product.pricePerAcre || product.quantityRequired);
  const showDiscounts = discounts && product.discounts?.length > 0;
  const hasDetails = showQuantityDetails || showDiscounts;

  const productName = ProductUtilities.buildProductName(product, name, skuName);

  const unitQuantityText = (
    `${StringUtility.formatCurrency(product.unitPrice)}/${translate<string>(product.unitUom)}`
  );

  const isDiscountCostTypeApplied = (discountType: CostType) => {
    if (!showDiscounts) return false;
    return product.discounts.some((discount) => discount.costType === discountType);
  };

  const renderSkuTotalCost = () => {
    if (product.discounts?.length > 0) {

      if (isDiscountCostTypeApplied(CostType.TOTAL_COST)) {
        return <>
          <Text
            category="h3"
            style={{ textDecorationLine: 'line-through' }}
            testID={`product-card-total-${index}`}
          >
            {StringUtility.formatCurrency(product.retailPrice)}
          </Text>
          <Text
            category="h3"
            testID={`product-card-total-${index}`}
          >
            {StringUtility.formatCurrency(product.discountedPrice)}
          </Text>
        </>;
      }
    }
    return <Text
      category="h3"
      testID={`product-card-total-${index}`}
      >
      {StringUtility.formatCurrency(product.discountedPrice)}
    </Text>;
  };

  const renderUnitPrice = () => {
    if (isDiscountCostTypeApplied(CostType.UNIT_PRICE)) {
      return <>
        <Text
          category="p2"
          style={{ textDecorationLine: 'line-through' }}
          testID={`product-card-package-unit-quantity-${index}`}
        >
          {unitQuantityText}
        </Text>
        <HSpacer size="2" />
        <Text
          category="p2"
          testID={`product-card-package-unit-quantity-${index}`}
        >
          {`${StringUtility.formatCurrency(product.discountedPrice / product.totalUnits)}/${translate<string>(product.unitUom)}`}
        </Text>
      </>;
    }
    return <Text
        category="p2"
        testID={`product-card-package-unit-quantity-${index}`}
      >
        {unitQuantityText}
    </Text>;
  };

  return (
    <Card testID={`product-card-${index}`}>
      {/* Older deliverables don't contain this property, so check for undefined as well */}
      {product.isActive === false && (
        <>
          <View style={styles.row}>
            <Icon
              name="AlertTriangle"
              status="warning"
              testID={`product-card-deactivated-warning-icon-${index}`}
            />
            <HSpacer size="2" />
            <Text
              category="p1"
              testID={`product-card-deactivated-warning-message-${index}`}
            >
              {translate<string>('PRODUCT_DEACTIVATED_WARNING')}
            </Text>
          </View>
          <VSpacer size="5" />
        </>
      )}

      {/* SKU/Price */}
      <View style={styles.spaceBetweenRow}>
        <Text
          category="h6"
          style={styles.text}
          testID={`product-card-name-${index}`}
        >
          {productName}
        </Text>
        <HSpacer size="7" />
        <View style={[styles.column, styles.rightAligned]}>
          {totalCost && renderSkuTotalCost()}
          {(discounts && product.discounts?.length > 0) && (
            <Badge
              status="success"
              style={styles.isDiscountsAppliedButton}
              testID={`product-card-is-discount-applied-${index}`}
            >
              {translate<string>('DISCOUNT_APPLIED').toUpperCase()}
            </Badge>
          )}
        </View>
      </View>

      <VSpacer size="5" />

      {/* QTY/Lock */}
      <View style={styles.row}>
        {quantity && (
          <>
            <View style={styles.qtyWrapper}>
              <NumericInput
                disabled
                label={translate<string>('QTY')}
                precision={4}
                testID={`product-card-quantity-${index}`}
                value={product.packages}
              />
            </View>
            <HSpacer size="6" />
          </>
        )}
        <View style={styles.flexOne}>
          {quantity && (
            <VSpacer size="8" />
          )}
          <View style={styles.row}>
            <Icon
              name={product.isPriceLocked ? 'Lock' : 'GrowersUnlock'}
              status="basic"
              testID={`product-card-text-price-locked-icon-${index}`}
            />
            <HSpacer size="3" />
            <Text testID={`product-card-text-price-locked-${index}`}>
              {translate<string>(product.isPriceLocked ? 'PRICE_LOCKED' : 'PRICE_UNLOCKED')}
            </Text>
          </View>
        </View>
      </View>

      {/* Unit Price / SKU Price / Units Per SKU / Total Units */}
      <View style={styles.row}>
        <View style={styles.flexOne}>
          <VSpacer size="5" />
          <View style={styles.row}>
            {unitPrice && (
              <>
                <Text
                  appearance="hint"
                  category="p2"
                  testID={`product-card-unit-price-${index}`}
                >
                  {`${translate<string>('UNIT_PRICE')}:`}
                </Text>
                <HSpacer size="3" />
                {renderUnitPrice()}
                <HSpacer size="5" />
              </>
            )}
            {cost && (
              <>
                <Text
                  appearance="hint"
                  category="p2"
                  testID={`product-card-sku-price-label-${index}`}
                >
                  {`${translate<string>('SKU_PRICE')}:`}
                </Text>
                <HSpacer size="3" />
                <Text
                  category="p2"
                  testID={`product-card-sku-price-${index}`}
                >
                  {StringUtility.formatCurrency(product.skuPrice)}
                </Text>
                <HSpacer size="5" />
              </>
            )}
            <>
              <Text
                appearance="hint"
                category="p2"
                testID={`product-card-units-per-sku-${index}`}
              >
                {`${translate<string>('UNITS_PER_SKU')}:`}
              </Text>
              <HSpacer size="3" />
              <Text
                category="p2"
                testID={`product-card-package-unit-quantity-${index}`}
              >
                {`${product.unitsPerPackage} ${translate<string>(product.unitUom)}`}
              </Text>
              <HSpacer size="5" />
            </>
            {totalUnits && (
              <>
                <Text
                  appearance="hint"
                  category="p2"
                  testID={`product-card-total-units-label-${index}`}
                >
                  {`${translate<string>('TOTAL_UNITS')}:`}
                </Text>
                <HSpacer size="3" />
                <Text
                  category="p2"
                  testID={`product-card-total-units-${index}`}
                >
                  {`${StringUtility.formatDecimal(product.totalUnits, 4)} ${translate(product.unitUom)}`}
                </Text>
              </>
            )}
          </View>
          {!!product.externalId && (
            <>
              <VSpacer size="3" />
              <View style={styles.row}>
                <>
                  <Text
                    appearance="hint"
                    category="p2"
                    testID={`product-card-product-id-label-${index}`}
                  >
                    {`${translate<string>('PRODUCT_ID')}:`}
                  </Text>
                  <HSpacer size="3" />
                  <Text
                    category="p2"
                    testID={`product-card-product-id-${index}`}
                  >
                    {product.externalId}
                  </Text>
                </>
              </View>
            </>
          )}

          <VSpacer size="5" />

          {/* Show Details link */}
          {hasDetails && (
            <TextLink
              accessoryRight={(iconProps) => (
                <Icon
                  name={showDetails ? 'ChevronUp' : 'ChevronDown'}
                  testID={`product-card-details-text-link-icon=${index}`}
                  {...iconProps}
                />
              )}
              appearance="secondary"
              category="p2"
              onPress={() => setShowDetails((prev) => !prev)}
              testID={`product-card-details-text-link=${index}`}
            >
              {translate<string>(showDetails ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
            </TextLink>
          )}

          {/* Details */}
          {showDetails && hasDetails && (
            <>
              {showDiscounts && (
                <>
                  <VSpacer size="7" />
                  <ProductDiscounts discounts={product.discounts} />
                </>
              )}
              {showQuantityDetails && (
                <>
                  <VSpacer size="7" />
                  <CalculateQuantityDetails
                    index={index}
                    product={product}
                    selections={selections}
                  />
                </>
              )}
            </>
          )}
        </View>
      </View>
    </Card>
  );
};

export default ProductCard;
