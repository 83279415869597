import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Icon, IconButton, Text, Table, TableRow, TableHeader, TableCell } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';
import { SortableTableDemo } from './TableDemo-SortableTable';

const rowDetails = (
  <>
    <Text>
      Most recent deliverable is Fall crops 2021 sent on 9/24/21
    </Text>
    <View style={{ flexDirection: 'row', paddingTop: 20 }}>
      <TableCell style={{ flex: 1, borderBottomWidth: 0 }} testID="test-cell">
        <View style={{ flexDirection: 'column' }}>
          <Text category="c1" status="basic">
            Status
          </Text>
          <Text category="p1">
            Active
          </Text>
        </View>
      </TableCell>
      <TableCell style={{ flex: 1, borderBottomWidth: 0 }} testID="test-cell">
        <View style={{ flexDirection: 'column' }}>
          <Text category="c1" status="basic">
            Cell Phone
          </Text>
          <Text category="p1" wrap>
            Active
          </Text>
        </View>
      </TableCell>
      <TableCell style={{ flex: 1, borderBottomWidth: 0 }} testID="test-cell">
        <View style={{ flexDirection: 'column' }}>
          <Text category="c1" status="basic">
            Date Updated
          </Text>
          <Text category="p1">
            Active
          </Text>
        </View>
      </TableCell>
      <TableCell style={{ flex: 1, borderBottomWidth: 0 }} testID="test-cell">
        <View style={{ flexDirection: 'column' }}>
          <Text category="c1" status="basic">
            Date Created
          </Text>
          <Text category="p1">
            Active
          </Text>
        </View>
      </TableCell>
    </View>
  </>
);

export const TableDemo: FunctionComponent = () => (
  <View style={{ alignSelf: 'stretch' }}>
    <DemoContainer>
      <DemoBlock style={{ width: '100%' }}>
        <Table style={{ width: '100%' }} testID="table">
          <TableRow appearance="header" testID="table-demo-block-row">
            <TableHeader
              style={{ width: 52 }}
              testID="table-header-chevron-down"
            >
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                ChevronDown
              </IconButton>
            </TableHeader>
            <TableHeader
              accessoryLeft={(props) => <Icon name="MoreHorizontal" testID="table-icon-left" {...props} />}
              accessoryRight={(props) => <Icon name="MoreVertical" testID="table-icon-right" {...props} />}
              sortDirection="ASC"
              style={{ flex: 1 }}
              testID="table-header-column-1"
            >
              Column 1
            </TableHeader>
            <TableHeader
              accessoryRight={(props) => <Icon name="MoreVertical" testID="table-icon" {...props} />}
              sortDirection="DESC"
              style={{ flex: 1 }}
              testID="table-header-column-2"
            >
              Column 2
            </TableHeader>
            <TableHeader
              accessoryLeft={(props) => <Icon name="MoreHorizontal" testID="table-icon" {...props} />}
              sortDirection="ASC"
              style={{ flex: 1 }}
              testID="table-header-column-3"
            >
              Column 3
            </TableHeader>
            <TableHeader
              accessoryLeft={(props) => <Icon name="MoreHorizontal" testID="table-icon-left" {...props} />}
              accessoryRight={(props) => <Icon name="MoreVertical" testID="table-icon-right" {...props} />}
              style={{ flex: 1 }}
              testID="table-header-column-4"
            >
              Column 4
            </TableHeader>
            <TableHeader
              sortDirection="DESC"
              testID="table-header-column-5"
            >
              Column 5
            </TableHeader>
            <TableHeader
              accessoryLeft={(props) => <Icon name="MoreHorizontal" testID="table-icon" {...props} />}
              style={{ flex: 1 }}
              testID="table-header-column-6"
            >
              Column 6
            </TableHeader>
            <TableHeader
              accessoryRight={(props) => <Icon name="MoreVertical" testID="table-icon" {...props} />}
              style={{ flex: 1 }}
              testID="table-header-column-7"
            >
              Column 7
            </TableHeader>
            <TableHeader
              style={{ width: 52 }}
              testID="table-header-empty-column"
            >
              {' '}
            </TableHeader>
          </TableRow>
          <TableRow testID="table-demo-row-test-cell">
            <TableCell style={{ width: 52 }} testID="test-cell">
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                ChevronDown
              </IconButton>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 1
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 2
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 3
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 4
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 5
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 6
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              Cell 7
            </TableCell>
            <TableCell style={{ width: 52 }} testID="test-cell">
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                MoreVertical
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow
            rowDetails={rowDetails}
            testID={`table-demo-${rowDetails.key}`}
          >
            <TableCell style={{ width: 52 }} testID="test-cell">
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                ChevronDown
              </IconButton>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 1
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 2
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 3
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 4
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 5
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 6
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              Cell 7
            </TableCell>
            <TableCell style={{ width: 52 }} testID="test-cell">
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                MoreVertical
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ width: 52 }} testID="test-cell">
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                ChevronDown
              </IconButton>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 1
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 2
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 3
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 4
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 5
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              <Text>
                Cell 6
              </Text>
            </TableCell>
            <TableCell style={{ flex: 1 }} testID="test-cell">
              Cell 7
            </TableCell>
            <TableCell style={{ width: 52 }} testID="test-cell">
              <IconButton
                appearance="ghost"
                size="medium"
                status="basic"
                testID="test-button"
              >
                MoreVertical
              </IconButton>
            </TableCell>
          </TableRow>
        </Table>
      </DemoBlock>
    </DemoContainer>
    <DemoContainer label="Header and Cell alignments">
      <DemoBlock style={{ width: '100%' }}>
        <Table style={{ width: '100%' }} testID="table">
          <TableRow appearance="header" testID="table-demo-header-left">
            <TableHeader
              align="left"
              style={{ flex: 1 }}
              testID="table-header-left-1"
            >
              Left
            </TableHeader>
            <TableHeader
              align="left"
              style={{ flex: 1 }}
              testID="table-header-left-2"
            >
              Left
            </TableHeader>
            <TableHeader
              align="left"
              style={{ flex: 1 }}
              testID="table-header-left-3"
            >
              Left
            </TableHeader>
            <TableHeader
              align="right"
              style={{ flex: 1 }}
              testID="table-header-right-1"
            >
              Right
            </TableHeader>
            <TableHeader
              align="right"
              style={{ flex: 1 }}
              testID="table-header-right-2"
            >
              Right
            </TableHeader>
            <TableHeader
              align="right"
              style={{ flex: 1 }}
              testID="table-header-right-3"
            >
              Right
            </TableHeader>
            <TableHeader
              align="center"
              style={{ flex: 1 }}
              testID="table-header-center-1"
            >
              Center
            </TableHeader>
            <TableHeader
              align="center"
              style={{ flex: 1 }}
              testID="table-header-center-2"
            >
              Center
            </TableHeader>
            <TableHeader
              align="center"
              style={{ flex: 1 }}
              testID="table-header-center-3"
            >
              Center
            </TableHeader>
          </TableRow>
          <TableRow>
            <TableCell align="left" style={{ flex: 1 }} testID="test-cell">
              Left
            </TableCell>
            <TableCell align="right" style={{ flex: 1 }} testID="test-cell">
              Right
            </TableCell>
            <TableCell align="center" style={{ flex: 1 }} testID="test-cell">
              Center
            </TableCell>
            <TableCell align="left" style={{ flex: 1 }} testID="test-cell">
              Left
            </TableCell>
            <TableCell align="right" style={{ flex: 1 }} testID="test-cell">
              Right
            </TableCell>
            <TableCell align="center" style={{ flex: 1 }} testID="test-cell">
              Center
            </TableCell>
            <TableCell align="left" style={{ flex: 1 }} testID="test-cell">
              Left
            </TableCell>
            <TableCell align="right" style={{ flex: 1 }} testID="test-cell">
              Right
            </TableCell>
            <TableCell align="center" style={{ flex: 1 }} testID="test-cell">
              Center
            </TableCell>
          </TableRow>
        </Table>
      </DemoBlock>
    </DemoContainer>
    <SortableTableDemo />
  </View>
);
