import React, { FC, useState } from 'react';
import { RenderProp } from '@ui-kitten/components/devsupport';
import { IndexPath, MenuItem, MenuItemProps, OverflowMenu } from '@ui-kitten/components';
import { StyleType } from '@ui-kitten/components/theme';
import { isEmpty as _isEmpty } from 'lodash';
import { Size } from '@theme/variant-interfaces/Size';
import { IconButton } from '../IconButton/IconButton';
import { IconName, IconProps } from '../Icon/Icon';

export interface CardMenuItemProps extends MenuItemProps {
  action?: () => void;
  key?: number;
}

export interface CardMenuProps {
  menuItems: CardMenuItemProps[];
  size?: Size;
  iconStyle?: StyleType;
  style?: StyleType;
  icon: RenderProp<Partial<IconProps>> | IconName;
}

export const CardMenu: FC<CardMenuProps> = ({
  menuItems,
  size = 'medium',
  iconStyle = {},
  style = {},
  icon,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<IndexPath>(null);
  const [isVisible, setIsVisible] = useState(false);

  const onActionSelected = (action: CardMenuItemProps) => {
    setIsVisible(false);
    setTimeout(() => action.action());
  };

  const onItemSelect = (index: IndexPath) => {
    setSelectedIndex(index);
    onActionSelected(menuItems[index.row]);
  };

  const renderOverflowMenuIcon = () => (
    <IconButton
      appearance="ghost"
      onPress={() => setIsVisible(true)}
      size={size}
      status="basic"
      style={iconStyle}
      testID="overflow-menu-icon-button"
    >
      {icon}
    </IconButton>
  );
  if (!_isEmpty(menuItems)) {
    return (
      <OverflowMenu
        anchor={renderOverflowMenuIcon}
        onBackdropPress={() => setIsVisible(false)}
        onSelect={onItemSelect}
        selectedIndex={new IndexPath(menuItems.findIndex((m) => m === selectedIndex))}
        style={style}
        visible={isVisible}
      >
        {menuItems.filter(Boolean).map((item) => (
          <MenuItem
            key={item.key}
            title={item.title}
            {...item}
          />
        ))}
      </OverflowMenu>
    );
  }
  return null;
};
