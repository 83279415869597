import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { BusinessApi } from '../utilities/api';

interface UseBusinessCropsProps {
  businessId?: string,
  onError?(error: Error): void,
}

export const useBusinessCrops = ({ businessId, onError }: UseBusinessCropsProps) => {
  const queryClient = useQueryClient();
  const invalidateBusinessCrops = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.BUSINESS_CROPS, businessId])
  ), [businessId, queryClient]);

  const { data: businessCrops, isFetching: isBusinessCropsLoading } = useQuery(
    [QueryKeys.BUSINESS_CROPS, businessId],
    () => BusinessApi.getBusinessCrops(businessId),
    {
      enabled: !!businessId,
      onError: (error: Error) => onError?.(error),
    },
  );

  return {
    businessCrops,
    invalidateBusinessCrops,
    isBusinessCropsLoading,
  };
};
