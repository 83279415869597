export default {
  ACTIVATE: 'Activate',
  ADD: 'Add',
  ADDRESS1: 'Address 1',
  ADDRESS2: 'Address 2',
  ADDRESS: 'Address',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  ADD_ANOTHER_CONTACT: 'Add another contact',
  ADD_BUSINESS_LOCATION: 'Add Business Location',
  ADD_CONTACT: 'Add Contact',
  ADD_CONTACT_TAGS: 'Add contact tags',
  ADD_CROP: 'Add crop',
  ADD_CROP_BUTTON: 'Add Crop',
  ADD_CROP_TITLE: 'Add Crop',
  ADD_NEW_CONTACT: 'Add new contact',
  ADD_PRICE_TYPE_BUTTON: 'Add price type',
  AMOUNT: 'Amount',
  BUSINESS_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this business?',
  BUSINESS_ACTIVATE_TITLE: 'Activate Business',
  BUSINESS_CREATED: 'Business created',
  BUSINESS_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this business?',
  BUSINESS_DEACTIVATE_TITLE: 'Deactivate Business',
  BUSINESS_DETAILS: 'Business Details',
  BUSINESS_DETAILS_ACTION: 'Show details',
  BUSINESS_EMAIL: 'Business email',
  BUSINESS_ID: 'Business ID',
  BUSINESS_NAME: 'Business name',
  BUSINESS_NAME_ALREADY_USED: 'Business name is already used',
  BUSINESS_PRODUCT_SUBCATEGORIES_DUPLICATE_ERROR: 'Subcategory name must be unique across all categories!',
  BUSINESS_TELEPHONE: 'Business phone number',
  BUSINESS_UPDATED: 'Business updated',
  CANCEL_EDITING_PRODUCT_CATEGORIES_MODAL_WARNING: 'Are you sure you want to discard all changes?',
  CATEGORY_ADDED: 'Category added: {{name}}',
  CATEGORY_REMOVED: 'Category removed: {{name}}',
  CITY: 'City',
  CONFIRM: 'Confirm',
  CONTACT: 'Contact',
  CONTACTS: 'Contacts',
  CONTACT_ADDED_TOAST: 'Contact successfully added',
  CONTACT_DELETED_TOAST: 'Contact deleted',
  CONTACT_EMAIL: 'Contact Email',
  CONTACT_EMAIL_SENTENCE: 'Contact email',
  CONTACT_NAME_SENTENCE: 'Contact name',
  CONTACT_PHONE_NUMBER: 'Contact Phone Number',
  CONTACT_PHONE_NUMBER_SENTENCE: 'Contact phone number',
  CONTACT_UPDATED_TOAST: 'Contact updated',
  CONTACT_NAME: 'Contact name',
  CONTACT_SEND_DELIVERABLES_TEXT: 'Automatically send deliverable copies to this contact',
  COUNTY: 'County',
  CREATED_ON: 'Created on',
  CREATE_BUSINESS: 'Create business',
  CREATE_BUSINESS_LOCATION: 'Create Location',
  CREATE_CUSTOM_TAG: 'Create custom tag',
  CREATE_DISCOUNT: 'Create Discount',
  CROP_LIST_SAVED: 'Crop list saved!',
  CROP_SUCCESSFULLY_ADDED: 'Crop successfully added',
  CROP_SUCCESSFULLY_DELETED: 'Crop successfully deleted',
  CROP_SUCCESSFULLY_EDITED: 'Crop successfully edited',
  CUSTOM_TAG_DELETED: 'Custom tag deleted',
  CUSTOM_TAG_ALREADY_EXISTS: 'This tag already exists',
  CUSTOM_TAG_UPDATED: 'Custom tag updated',
  CUSTOM_TAGS: 'Custom tags',
  DEACTIVATE: 'Deactivate',
  DEFAULT_TAGS: 'Default tags',
  DELETE_CONTACT: 'Delete contact',
  DELETE_CONTACT_CONFIRMATION_TITLE: 'Delete this contact?',
  DELETE_CONTACT_CONFIRMATION_BUTTON: 'Yes, delete',
  DELETE_CROP_MODAL_CONFIRMATION: 'Yes, delete',
  DELETE_CROP_MODAL_MESSAGE: 'Are you sure you want to delete this crop?',
  DELETE_CROP_MODAL_TITLE: 'Delete crop?',
  DELETE_CUSTOM_TAG: 'Delete custom tag?',
  DELETE_CUSTOM_TAG_MESSAGE: 'This tag will be removed from all instances throughout the application.',
  DELETE_DISCOUNT_MESSAGE: 'You will need to recreate this discount if you wish to use it again.',
  DELETE_DISCOUNT_TITLE: 'Delete discount?',
  DELETE_PRICE_TYPE_MESSAGE: 'This price type will be removed from the {{ count }} product(s) currently using it. Any orders will be updated to reflect business default pricing. Deliverables will not be affected.',
  DELETE_PRICE_TYPE_TITLE: 'Delete price type?',
  DELETE_PRODUCT_CATEGORY: 'Delete product category?',
  DISCOUNT_CREATED: 'Discount created',
  DISCOUNT_DELETE_ERROR: 'There was an error deleting discount',
  DISCOUNT_DELETE_SUCCESS: 'Discount deleted successfully',
  DISCOUNT_EDITED: 'Discount edited',
  DISCOUNT_MISSING_VALUE: 'A value is required',
  DISCOUNT_NAME: 'Discount name',
  DISCOUNT_NAME_EXISTS_ERROR: 'A discount with that name already exists',
  DISCOUNT_TYPE: 'Discount type',
  DISCOUNTS_GET_ERROR: 'There was an error getting discounts',
  DISCOUNTS: 'Discounts',
  DOLLAR_FILTER: '$ Dollar',
  DOLLAR_SIGN: '$',
  EDIT: 'Edit',
  EDIT_BUSINESS: 'Edit Business',
  EDIT_BUSINESS_LOCATION: 'Edit Location',
  EDIT_CONTACT: 'Edit Contact',
  EDIT_CROP_TITLE: 'Edit Crop',
  EDIT_DISCOUNT: 'Edit Discount',
  EDIT_PRICE_TYPE: 'Edit Price Type',
  ENTER_CUSTOM_TAG_NAME: 'Enter custom tag name',
  ERROR_ACTIVATING_BUSINESS_LOCATION: 'There was an error activating this business location',
  ERROR_BUSINESS_COUNTRIES: 'There was an error loading business countries',
  ERROR_DEACTIVATING_BUSINESS_LOCATION: 'There was an error deactivating this business location',
  ERROR_SAVING_BUSINESS: 'There was an error saving this business',
  FILTERS: 'Filters',
  HEADQUARTERS: 'Headquarters',
  LAST_UPDATED_ON: 'Last updated on',
  NAME: 'Name',
  NEW_TAG_CREATED: 'New tag created',
  NO_BUSINESSES: 'There are no available businesses',
  NO_BUSINESS_LOCATIONS: 'There are no available business locations',
  NO_BUSINESS_PRODUCT_CATEGORIES: 'There are no available business product categories',
  NO_CONTACTS: 'No contacts',
  NO_DISCOUNTS: 'There are no discounts.',
  NO_DISCOUNTS_CAN_CREATE: '\nClick "Create Discount" to create one',
  PERCENT_FILTER: '% Percent',
  PERCENT_SIGN: '%',
  PRICE_TYPE: 'Price Type',
  PRICE_TYPES_HEADER: 'Price Types ({{ count }})',
  PRICE_TYPES_SUCCESSFULLY_SAVED: 'Price types were successfully saved.',
  PRICE_TYPE_CREATED_SUCCESS: 'Price type successfully created.',
  PRICE_TYPE_DEFAULT_INPUT_CAPTION: 'To delete {{currentPriceType}}, first set a new default price type.',
  PRICE_TYPE_DELETED_SUCCESS: 'Price type successfully deleted.',
  PRICE_TYPE_NAME: 'Price type name',
  PRICE_TYPE_NAME_MUST_BE_UNIQUE: 'Price type name already in use, the label must be unique.',
  PRODUCT_CATEGORIES: 'Product Categories',
  RECEIVE_DELIVERABLES: 'Receive deliverables',
  REMOVE_PRODUCT_CATEGORIES_MODAL_WARNING: 'This action will affect {{count}} product(s).',
  REMOVE_PRODUCT_CATEGORY: 'Remove Product Category',
  SAVE_CROPS_MODAL_WARNING: 'Are you sure you want to save changes to crops?',
  SEARCH: 'Search',
  SEND_DELIVERABLES_TO_CONTACT: 'Automatically send deliverable copies to this contact',
  SET_DEFAULT_LABEL: 'Set default',
  SET_PRODUCT_CATEGORIES: 'Set Product Categories',
  STATE: 'State',
  STATUS: 'Status',
  SUBCATEGORY_NAME: 'Subcategory name',
  UNABLE_TO_DELETE_MESSAGE: 'This price type is set as “Default”, set a new “Default Price Type”, after that you will be able to delete it.',
  UNABLE_TO_DELETE_TITLE: 'Unable to delete',
  UNEXPECTED_ERROR: 'An unexpected error occurred.',
  USERS: 'Users',
  VIEW_MORE: 'View {{length}} more',
  ZIPCODE: 'Zip code',
};
