/* eslint-disable @typescript-eslint/no-use-before-define */
import { Button, Header, Modal } from '@design';
import { PlanStatus } from '@shared/enums';
import { ApiDiscount, ApiProductSummary, DiscountEndpoint } from '@shared/interfaces/api';
import {
  CalculatedDiscount,
  CalculatedProductDiscount,
  CalculatedProductWithDiscounts,
  CalculationUtility,
  FarmPlanProfitCalculation,
} from '@shared/utils';
import { useBusinessDiscounts, UseBusinessDiscountsListProps } from '../../../../hooks/useBusinessDiscounts';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiscountModalTitle } from '../../../../constants';
import { LineSpacer } from '../../../components/shared';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import {
  OrderDetailsDiscountModal,
} from '../../../components/shared/FarmPlan/OrderDetailsDiscountModal';
import {
  OrderSummaryItem,
} from '../../ProductOrder/components/ProductOrderReviewCreate/OrderSummaryItem';

interface DeleteEditProps {
  onDelete(discountIdx: number, productId?: string): void,
  onEdit(discount: DiscountEndpoint.Save.Request, discountIdx: number, productId?: string): void,
}

export interface DiscountsModalProps extends DeleteEditProps {
  businessDiscountsList: UseBusinessDiscountsListProps,
  discounts: ApiDiscount[],
  onClose(): void,
  productSummaries: ApiProductSummary[],
  profitSummary: FarmPlanProfitCalculation,
}

export const DiscountsModal = ({
  businessDiscountsList,
  discounts,
  onClose,
  onDelete,
  onEdit,
  productSummaries,
  profitSummary,
}: DiscountsModalProps) => {
  const {
    businessDiscountsListData: currentBusinessDiscounts,
    areBusinessDiscountsFetched,
  } = businessDiscountsList;

  const isDiscountEditable = useCallback((discount: CalculatedDiscount | CalculatedProductDiscount) => {
    if (areBusinessDiscountsFetched) {
      return !currentBusinessDiscounts?.data?.some((currentBusinessDiscount) => {
        return currentBusinessDiscount?.name === discount.name;
      });
    }
    return true;
  }, [areBusinessDiscountsFetched, currentBusinessDiscounts]);

  const [translate] = useTranslation(['farmPlans', 'growers', 'common']);

  return (
    <Modal
      footerAccessory={({ secondaryButtonProp }) => (
        <Button
          testID="close-button"
          {...secondaryButtonProp}
          onPress={onClose}
        >
          {translate<string>('CLOSE')}
        </Button>
      )}
      hideCloseButton
      maxHeight
      onClose={onClose}
      testID="discounts-modal"
      title={translate('DISCOUNTS')}
      visible
      width={640}
    >
      <Header level="4" testID="order-discounts-header" title={translate('ORDER_DISCOUNTS')} />
      <LineSpacer size="5" />
      {profitSummary.discounts.map((discount, discountIdx) => (
        <DiscountItem
          acreage={profitSummary.acresPlanned}
          discount={discount.dollarsTotal}
          discountIdx={discountIdx}
          discounts={discounts}
          isEditable={isDiscountEditable(discount)}
          key={`discount-${discount.name}`}
          name={discount.name}
          onDelete={onDelete}
          onEdit={onEdit}
          testIdPostfix={`order-discount-${discountIdx}`}
        />
      ))}
      <OrderSummaryItem
        isDiscount
        isDraft
        perAcre={CalculationUtility.calculatePerAcre(
          profitSummary.orderDiscountTotal,
          profitSummary.acresPlanned,
        )}
        testIdPostfix="total-order-discounts"
        title={translate<string>('TOTAL_ORDER_DISCOUNTS')}
        titleCategory="s1"
        titleValue={profitSummary.orderDiscountTotal}
      />
      <LineSpacer opacity="600" size="8" />
      <Header level="4" testID="sku-discounts-header" title={translate('SKU_DISCOUNTS')} />
      <LineSpacer size="5" />
      {profitSummary.products.map((product, index) => (
        <ProductDiscount
          acreage={productSummaries[index].acresApplied}
          discounts={productSummaries[index].discounts}
          isDiscountEditable={isDiscountEditable}
          key={product.productId}
          onDelete={onDelete}
          onEdit={onEdit}
          planStatus={productSummaries[index].planStatus}
          product={product}
          productId={product.productId}
        />
      ))}
      <OrderSummaryItem
        isDiscount
        isDraft
        perAcre={CalculationUtility.calculatePerAcre(
          profitSummary.productDiscountTotal,
          profitSummary.acresPlanned,
        )}
        testIdPostfix="total-sku-discounts"
        title={translate<string>('TOTAL_SKU_DISCOUNTS')}
        titleCategory="s1"
        titleValue={profitSummary.productDiscountTotal}
      />
      <LineSpacer opacity="600" size="8" />
      <OrderSummaryItem
        isDiscount
        isDraft
        perAcre={CalculationUtility.calculatePerAcre(
          profitSummary.totalDiscount,
          profitSummary.acresPlanned,
        )}
        testIdPostfix="total-savings"
        title={translate<string>('TOTAL_SAVINGS')}
        titleCategory="h4"
        titleValue={profitSummary.totalDiscount}
      />
    </Modal>
  );
};

interface ProductDiscountProps extends DeleteEditProps {
  acreage: number,
  discounts: ApiDiscount[],
  isDiscountEditable: (discount: CalculatedProductDiscount) => boolean,
  planStatus?: PlanStatus,
  product: CalculatedProductWithDiscounts,
  productId: string,
}
const ProductDiscount = ({
  acreage,
  discounts,
  isDiscountEditable,
  planStatus,
  product,
  productId,
  onDelete,
  onEdit,
}: ProductDiscountProps) => {
  return (
    <>
      {product.discounts.map((discount, discountIdx) => (
        <DiscountItem
          acreage={acreage}
          discount={discount.dollarsTotal}
          discountIdx={discountIdx}
          discounts={discounts}
          isEditable={isDiscountEditable(discount)}
          key={`${product.productId}-${discount.name}`}
          name={discount.name}
          onDelete={onDelete}
          onEdit={onEdit}
          planStatus={planStatus}
          productId={productId}
          testIdPostfix={`discount-${product.productId}-${discount.name}`}
        />
      ))}
    </>
  );
};

interface DiscountItemProps extends DeleteEditProps {
  acreage: number,
  discount: number,
  discounts: ApiDiscount[],
  discountIdx: number,
  isEditable: boolean,
  name: string,
  planStatus?: PlanStatus,
  productId?: string,
  testIdPostfix: string,
}
const DiscountItem = ({
  acreage,
  discount,
  discounts,
  discountIdx,
  isEditable,
  name,
  onDelete,
  onEdit,
  planStatus,
  productId,
  testIdPostfix,
}: DiscountItemProps) => {
  const [translate] = useTranslation(['farmPlans', 'growers', 'common']);
  const { businessDiscountsList } = useBusinessDiscounts();

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <>
      { planStatus && planStatus === PlanStatus.ACCEPTED ? (
        <OrderSummaryItem
          isDisabled
          isDiscount
          isDraft
          isEditable={isEditable}
          perAcre={CalculationUtility.calculatePerAcre(
            discount,
            acreage,
          )}
          testIdPostfix={testIdPostfix}
          title={name}
          titleValue={discount}
        />
      ) : (
        <OrderSummaryItem
          isDisabled
          isDiscount
          isDraft
          isEditable={isEditable}
          onDelete={() => setDeleteModalVisible(true)}
          onEdit={() => setEditModalVisible(true)}
          perAcre={CalculationUtility.calculatePerAcre(
            discount,
            acreage,
          )}
          testIdPostfix={testIdPostfix}
          title={name}
          titleValue={discount}
        />
      )}
      <LineSpacer size="5" />
      {editModalVisible && (
        <OrderDetailsDiscountModal
          businessDiscountsList={businessDiscountsList}
          discountIdx={discountIdx}
          discountSkuEditActionTitle={DiscountModalTitle.EDIT}
          discounts={discounts}
          onApply={(newDiscount) => {
            onEdit(newDiscount, discountIdx, productId);
            setEditModalVisible(false);
          }}
          onClose={() => setEditModalVisible(false)}
          productId={productId}
        />
      )}
      {deleteModalVisible && (
        <ConfirmationModal
          cancelText={translate('BACK')}
          confirmText={translate('YES_DELETE')}
          onCancel={() => setDeleteModalVisible(false)}
          onConfirm={() => {
            onDelete(discountIdx, productId);
            setDeleteModalVisible(false);
          }}
          status="warning"
          title={translate('DELETE_DISCOUNT')}
          visible
        />
      )}
    </>
  );
};
