import {
  Accordion,
  AccordionItem,
  BannerProps,
  Button,
  CheckBox,
  HSpacer,
  Modal,
  Text,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import { CostType } from '@shared/enums';
import { OrderStatus } from '@shared/interfaces';
import { ApiProductOrder } from '@shared/interfaces/api';
import {
  ProductOrderDeliverableV0 as V0,
} from '@shared/interfaces/GrowerDeliverable/ProductOrderDeliverable/v0/ProductOrderDeliverableV0';
import { Spinner, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../../constants/QueryKeys';
import { useAppContext } from '../../../../../contexts/AppContext';
import { ProductOrderApi } from '../../../../../utilities/api/ProductOrderApi';

type CheckboxGroup = {
  initExpanded: boolean;
  translationKey: string,
  group: SelectionGroup,
  options: CheckboxControlAny[],
};

type SelectionGroup = keyof V0.Selections;
type SelectionKey<T extends SelectionGroup> = keyof V0.Selections[T];

type CheckboxControl<T extends SelectionGroup> = {
  column: number,
  group: T,
  key: SelectionKey<T>,
  translationKey: string,
  disabled: boolean,
};
type CheckboxControlAny = CheckboxControl<any> & {
  key: string,
};

type GroupState = {
  [key in SelectionGroup]?: 'all' | 'some' | 'none'
};

function createCheckbox<T extends SelectionGroup> (
  column: number,
  group: T,
  translationKey: string,
  key?: SelectionKey<T>,
): CheckboxControl<T> {
  return {
    column,
    group,
    key,
    translationKey,
    disabled: key === undefined,
  };
}

export interface CreateDeliverableModalProps {
  onBeforeCreateDeliverable?: () => Promise<boolean>;
  onClose: () => Promise<void>;
  productOrderId: string;
}

export const CreateDeliverableModal: FC<CreateDeliverableModalProps> = ({
  onBeforeCreateDeliverable,
  onClose,
  productOrderId,
}) => {
  const [translate] = useTranslation(['common', 'productOrders', 'deliverable', 'errors']);
  const [productOrder, setProductOrder] = useState<ApiProductOrder>();
  const [groupState, setGroupState] = useState<GroupState>({});
  const [selectionOptions, setSelectionOptions] = useState<CheckboxGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [selections, setSelections] = useState<V0.Selections>({
    basicInfo: {
      acreage: true,
      contactPhone: true,
      location: true,
      note: true,
      owner: true,
      planStatus: true,
      priceType: true,
    },
    costSummary: {
      discounts: true,
      retailPrice: true,
      totalCost: true,
    },
    products: {
      applicationRate: true,
      cost: true,
      costPerAcre: true,
      discounts: true,
      name: true,
      quantity: true,
      skuName: true,
      totalCost: true,
      totalUnits: true,
      unitPrice: true,
    },
  });
  const { setModalProps } = useAppContext();
  const { createToast } = useToast();
  const { createBanner } = useBanner();

  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate('DISMISS') as string}
        </Button>
      </View>
    ) });

  const toggleSelection = useCallback((group: SelectionGroup, key: string) => (
    setSelections((oldSelections) => ({
      ...oldSelections,
      [group]: {
        ...oldSelections[group],
        [key]: !oldSelections[group][key],
      },
    }))
  ), []);

  const isSelectionChecked = useCallback((group: SelectionGroup, key: string) => {
    const selection = selections[group][key];
    return selection || selection === undefined;
  }, [selections]);

  const initializeSelectionOptions = (order: ApiProductOrder) => {
    const newSelections = { ...selections };
    const hasNote = !!order.note;
    const hasTelephone = !!order.owner.telephone;
    const hasStatus = order.status !== OrderStatus.UNLABELED;
    if (!hasTelephone) {
      newSelections.basicInfo.contactPhone = false;
    }

    if (!hasNote) {
      newSelections.basicInfo.note = false;
    }

    if (!hasStatus) {
      newSelections.basicInfo.planStatus = false;
    }

    setSelections(newSelections);

    setSelectionOptions(
      [
        {
          initExpanded: false,
          translationKey: 'BASIC_INFORMATION',
          group: 'basicInfo',
          options: [
            createCheckbox(0, 'basicInfo', 'ORDER_NAME_LABEL'),
            createCheckbox(0, 'basicInfo', 'BUSINESS'),
            createCheckbox(0, 'basicInfo', 'SALESPERSON', 'owner'),
            createCheckbox(hasTelephone ? 0 : -1, 'basicInfo', 'PHONE', 'contactPhone'),
            createCheckbox(0, 'basicInfo', 'LOCATION', 'location'),

            createCheckbox(1, 'basicInfo', 'CUSTOMER'),
            createCheckbox(hasNote ? 1 : -1, 'basicInfo', 'NOTE', 'note'),
            createCheckbox(hasStatus ? 1 : -1, 'basicInfo', 'SALES_STATUS', 'planStatus'),
            createCheckbox(order.acreage ? 1 : -1, 'basicInfo', 'ACREAGE', 'acreage'),
            createCheckbox(1, 'basicInfo', 'PRICE_TYPE', 'priceType'),
          ],
        },
        {
          initExpanded: true,
          translationKey: 'PRODUCTS',
          group: 'products',
          options: [
            createCheckbox(0, 'products', 'PRODUCT_NAME', 'name'),
            createCheckbox(1, 'products', 'SKU_NAME', 'skuName'),
            createCheckbox(0, 'products', 'QUANTITY', 'quantity'),
            createCheckbox(0, 'products', CostType.UNIT_PRICE, 'unitPrice'),
            createCheckbox(1, 'products', 'SKU_PRICE_LABEL', 'cost'),

            createCheckbox(1, 'products', 'SKU_TOTAL_PRICE', 'totalCost'),
            createCheckbox(order.components?.some((c) => c.discounts?.length > 0) ? 1 : -1,
              'products', 'SKU_DISCOUNTS', 'discounts'),
            createCheckbox(order.components?.some((c) => c.applicationRate > 0) ? 1 : -1,
              'products', 'SKU_APPLICATION_RATE', 'applicationRate'),
            createCheckbox(order.acreage ? 1 : -1, 'products', 'SKU_PRICE_PER_ACRE', 'costPerAcre'),
            createCheckbox(0, 'products', 'TOTAL_UNITS', 'totalUnits'),
          ],
        },
        {
          initExpanded: false,
          translationKey: 'ORDER_SUMMARY',
          group: 'costSummary',
          options: [
            createCheckbox(0, 'costSummary', 'RETAIL_PRICE', 'retailPrice'),
            createCheckbox(order.discounts?.length > 0 ? 0 : -1, 'costSummary', 'ORDER_DISCOUNTS', 'discounts'),
            createCheckbox(0, 'costSummary', 'ORDER_TOTAL_PRICE', 'totalCost'),
          ],
        },
      ],
    );
  };

  useQuery(
    [QueryKeys.PRODUCT_ORDER, productOrderId],
    () => ProductOrderApi.getProductOrder(productOrderId),
    {
      enabled: !!productOrderId,
      onSuccess: (data) => {
        setLoading(false);
        setProductOrder(data);
        initializeSelectionOptions(data);
      },
      onError: () => {
        createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_ORDER')));
      },
    },
  );

  const styles = useStyleSheet({
    row: {
      flexDirection: 'row',
      flex: 1,
    },
    checkbox: {
      paddingBottom: 27,
      flexDirection: 'row',
      alignItems: 'center',
    },
    leftContainer: {
      width: 263,
      paddingLeft: 48,
    },
    rightContainer: {
      position: 'absolute',
      left: 311,
      width: 263,
    },
    spinnerContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 560,
    },
    divider: {
      borderTopWidth: 1,
      borderTopColor: 'color-basic-transparent-100',
      backgroundColor: 'color-basic-transparent-100',
    },
    accordionItem: {
      paddingBottom: 18,
      paddingTop: 23,
    },
    modalBody: {
      maxHeight: 600,
    },
  });

  const toggleGroup = useCallback((group: SelectionGroup) => {
    const groupInfo = selectionOptions.find((s) => s.group === group);
    const hasDefaultValues = groupInfo?.options.some((o) => o.disabled);

    let newGroupState = (
      groupState[group] === 'all' || groupState[group] === 'some'
        ? 'none'
        : 'all'
    );

    if (hasDefaultValues) {
      newGroupState = groupState[group] === 'all' ? 'some' : 'all';
    }

    setGroupState((prev) => ({
      ...prev,
      [group]: newGroupState,
    }));

    setSelections((prev) => {
      const newGroupSelection = { ...prev[group] };
      Object.keys(newGroupSelection).forEach((key) => {
        const option = groupInfo.options.find((o) => o.key === key);
        if (!option.disabled) {
          newGroupSelection[key] = option.column === -1 ? false : newGroupState === 'all';
        }
      });
      return {
        ...prev,
        [group]: newGroupSelection,
      };
    });
  }, [groupState, selectionOptions]);

  const onSubmit = async () => {
    try {
      setSaving(true);
      let upserted;
      if (onBeforeCreateDeliverable) {
        upserted = await onBeforeCreateDeliverable();
      }

      if (upserted === false) {
        setSaving(false);
        return;
      }

      const { createdDeliverable } = await ProductOrderApi.createDeliverable(
        `${productOrder.name} ${DateTime.local().toLocaleString(DateTime.DATETIME_SHORT)}`,
        productOrderId,
        selections,
      );

      await onClose();

      if (createdDeliverable) {
        setModalProps({
          type: 'shareDeliverable',
          customerId: productOrder.growerId,
          deliverable: createdDeliverable,
          isNew: true,
        });
      }
    } catch (err) {
      createToast({
        children: translate<string>('DELIVERABLE_FAILURE'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
    setSaving(false);
  };

  useEffect(() => {
    if (selectionOptions.length === 0) {
      return;
    }

    const newGroupState = { ...groupState };

    Object.keys(selections).forEach((group) => {
      const options = selectionOptions.find((s) => s.group === group)
        ?.options.filter((o) => o.column !== -1) || [];
      const selectedOptions = [];

      for (const option of options) {
        if (selections[group][option.key] || selections[group][option.key] === undefined) {
          selectedOptions.push(option);
        }
      }

      if (selectedOptions.length === options.length) {
        newGroupState[group] = 'all';
      } else if (selectedOptions.length > 0) {
        newGroupState[group] = 'some';
      } else {
        newGroupState[group] = 'none';
      }
    });
    setGroupState(newGroupState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections, selectionOptions]);

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            testID="create-deliverable-modal-cancel-button"
            {...primaryButtonProp}
            disabled={saving}
            onPress={onClose}
          >
            {translate('CANCEL') as string}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            testID="create-deliverable-modal-save-button"
            {...secondaryButtonProp}
            disabled={saving || (!selections.products.name && !selections.products.skuName)}
            onPress={onSubmit}
          >
            {translate('CREATE_DELIVERABLE') as string}
          </Button>
        </>
      )}
      onClose={onClose}
      testID="create-deliverable-modal"
      title={translate<string>('CREATE_DELIVERABLE')}
      visible
      width={637}
    >
      <View style={styles.modalBody}>
        {loading && (
          <View style={styles.spinnerContainer}>
            <Spinner size="giant" />
          </View>
        )}
        {!loading && (
          <>
            <VSpacer size="4" />
            <View style={styles.divider} />
            <Accordion dividerStyle={styles.divider} testID="create-deliverable-modal-list">
              {
                selectionOptions.map((option, index) => (
                  <AccordionItem
                    initExpanded={option.initExpanded}
                    key={option.group}
                    style={styles.accordionItem}
                    testID={`create-deliverable-modal-group-${index}`}
                    title={(
                      <View>
                        <CheckBox
                          checked={groupState[option.group] === 'all'}
                          indeterminate={groupState[option.group] === 'some'}
                          onChange={() => toggleGroup(option.group)}
                          testID={`create-deliverable-modal-header-checkbox-${index}`}
                        />
                        <HSpacer size="5" />
                        <Text category="h6">{translate<string>(option.translationKey)}</Text>
                      </View>
                    )}
                  >
                    <>
                      <View style={styles.leftContainer}>
                        {option.options.filter((o) => o.column === 0)
                          .map((subOption, subOptionIndex) => (
                            <View key={subOption.translationKey} style={styles.checkbox}>
                              <CheckBox
                                checked={isSelectionChecked(option.group, subOption.key)}
                                disabled={subOption.disabled}
                                onChange={() => toggleSelection(option.group, subOption.key)}
                                testID={`create-deliverable-modal-checkbox-left-${subOptionIndex}`}
                              />
                              <HSpacer size="4" />
                              <Text category="p2">{translate<string>(subOption.translationKey)}</Text>
                            </View>
                          ))}
                      </View>
                      <View style={styles.rightContainer}>
                        {option.options.filter((o) => o.column === 1)
                          .map((subOption, subOptionIndex) => (
                            <View key={subOption.translationKey} style={styles.checkbox}>
                              <CheckBox
                                checked={isSelectionChecked(option.group, subOption.key)}
                                disabled={subOption.disabled}
                                onChange={() => toggleSelection(option.group, subOption.key)}
                                testID={`create-deliverable-modal-checkbox-right-${subOptionIndex}`}
                              />
                              <HSpacer size="4" />
                              <Text category="p2">{translate<string>(subOption.translationKey)}</Text>
                            </View>
                          ))}
                      </View>
                    </>

                  </AccordionItem>
                ))
              }
            </Accordion>
          </>
        )}
      </View>
    </Modal>
  );
};
