import { Button, Card, Header, HSpacer, Icon, TextLink, VSpacer } from '@design';
import {
  ApiBusinessLocation,
  ApiFarmPlan,
  ApiGrower,
  ApiPriceType,
  ApiUserAccount,
} from '@shared/interfaces/api';
import { Location } from 'history';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { getCustomerLabel } from '../../../../../utilities';
import {
  BillingShippingContactsModal,
} from '../../../../components/shared/BillingShippingContactsModal/BillingShippingContactsModal';
import DescriptionList from '../../../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import { useCustomerContacts } from '../../../../../hooks/useCustomerContacts';

interface FarmPlanDetailsProps {
  customer: ApiGrower,
  farmPlan: ApiFarmPlan,
  farmPlanLocation: ApiBusinessLocation,
  location: Location,
  onPress: () => void,
  priceType: ApiPriceType,
  userData: ApiUserAccount,
}

export const FarmPlanFormDetails = ({
  customer,
  farmPlan,
  farmPlanLocation,
  location,
  onPress,
  priceType,
  userData,
}: FarmPlanDetailsProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showContactsModal, setShowContactsModal] = useState(false);
  
  const { data: contacts } = useCustomerContacts(customer?.id);

  return (
    <>
      <Header level="3" testID="details-header" title={translate('DETAILS')}>
        <Button
          accessoryLeft={(editProps) => (
            <Icon
              name="Edit"
              testID={/create/.test(location.pathname)
                ? 'create-details-button-icon' : 'edit-details-button-icon'}
              {...editProps}
            />
          )}
          appearance="ghost"
          onPress={onPress}
          size="small"
          status="basic"
          testID={/create/.test(location.pathname)
            ? 'create-details-button' : 'edit-details-button'}
        >
          {translate<string>('EDIT')}
        </Button>
      </Header>
      <VSpacer size="5" />
      <Card testID="farm-plan-card">
        <DescriptionList>
          <DescriptionListItem
            label={translate('NAME')}
            testID="farm-plan-name"
            text={farmPlan.planName}
          />
          <DescriptionListItem
            label={translate('SALESPERSON')}
            testID="salesperson-name"
            text={userData?.fullName}
          />
          <DescriptionListItem
            label={translate('BUSINESS_LOCATION')}
            testID="location"
            text={farmPlanLocation?.locationName ?? translate('CORPORATE_LOCATION')}
          />
        </DescriptionList>
        <VSpacer size="3" />
        <DescriptionList>
          <DescriptionListItem
            label={translate('CUSTOMER')}
            testID="customer-name"
            text={getCustomerLabel(customer)}
          />
          <DescriptionListItem
            label={translate('PHONE')}
            testID="phone"
            text={userData?.telephone ?? translate('NOT_APPLICABLE')}
          />
          <DescriptionListItem
            label={translate('PRICE_TYPE')}
            testID="price-type"
            text={priceType?.name}
          />
        </DescriptionList>
        <VSpacer size="3" />
        <DescriptionList>
          <DescriptionListItem
            label={translate('CROP_YEAR')}
            testID="crop-year"
            text={`${farmPlan.cropYear}`}
          />
          <DescriptionListItem
            accessoryRight={() => (
              <>
                <HSpacer size="2" />
                <TextLink
                  appearance="secondary"
                  onPress={() => setShowContactsModal(true)}
                  testID="basic-info-billing-shipping-view-more"
                >
                  {translate<string>('VIEW')}
                </TextLink>
              </>
            )}
            label={translate<string>('BILLING_SHIPPING_CONTACTS')}
            testID="basic-info-billing-shipping"
          />
          <View style={{ flex: 1 }} />
        </DescriptionList>
        {showContactsModal && (
          <BillingShippingContactsModal
            billingContact={contacts?.find((c) => c.id === farmPlan.billingContactId)}
            onClose={() => setShowContactsModal(false)}
            shippingContact={contacts?.find((c) => c.id === farmPlan.shippingContactId)}
            showModal={showContactsModal}
          />
        )}
      </Card>
    </>
  );
};
