import { HSpacer, IconButton, Text, VSpacer } from '@design';
import { ApiDiscount, DiscountEndpoint } from '@shared/interfaces/api';
import { CalculatedProductDiscount } from '@shared/utils';
import { useBusinessDiscounts } from '../../../../hooks/useBusinessDiscounts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { DiscountModalTitle } from '../../../../constants';
import { StringUtility } from '../../../../utilities';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { OrderDetailsDiscountModal } from '../FarmPlan/OrderDetailsDiscountModal';
import { LineSpacer } from '../LineSpacer';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
});

export interface ProductDiscountItemProps {
  calculatedDiscount: CalculatedProductDiscount,
  discount: ApiDiscount | DiscountEndpoint.Save.Request,
  discounts: (ApiDiscount | DiscountEndpoint.Save.Request)[],
  discountIdx: number;
  onDeleteDiscount?(discountIdx?: number, productId?: string): void,
  onEditDiscount?(
    discount: DiscountEndpoint.Save.Request,
    discountIdx?: number,
    productId?: string,
  ): void,
  productId?: string,
}

export const ProductDiscountItem = ({
  calculatedDiscount,
  discount,
  discounts,
  discountIdx,
  onDeleteDiscount,
  onEditDiscount,
  productId,
}: ProductDiscountItemProps) => {

  const { businessDiscountsList } = useBusinessDiscounts();
  const [translate] = useTranslation(['common', 'productCard']);

  const [discountModalVisible, setDiscountModalVisible] = useState(false);
  const [deleteDiscountModalVisible, setDeleteDiscountModalVisible] = useState(false);

  return (
    <>
      <LineSpacer size="5" />
      <View style={styles.container}>
        <View>
          <Text category="s1" testID={`product-discount-item-name-${discountIdx}`}>{discount.name}</Text>
          <VSpacer size="3" />
          <View style={styles.row}>
            <Text appearance="hint" category="p2">{`${translate<string>('TYPE')}: `}</Text>
            <Text category="p2" testID={`product-discount-item-cost-type-${discountIdx}`}>
              {translate<string>(discount.costType)}
            </Text>
            <HSpacer size="7" />
            <Text category="p2" status="success" testID={`product-discount-item-percent-${discountIdx}`}>
              {translate<string>('PERCENT_OFF', { percent: calculatedDiscount.percent })}
            </Text>
            <HSpacer size="7" />
            <Text category="p2" status="success" testID={`product-discount-item-dollars-${discountIdx}`}>
              {StringUtility.formatDiscount(calculatedDiscount.dollarsTotal)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          {!!onEditDiscount && (
            <IconButton
              appearance="ghost"
              onPress={() => setDiscountModalVisible(true)}
              status="basic"
              testID={`product-discount-item-edit-${discountIdx}`}
            >
              Edit
            </IconButton>
          )}
          {!!onDeleteDiscount && (
            <IconButton
              appearance="ghost"
              onPress={() => setDeleteDiscountModalVisible(true)}
              status="basic"
              testID={`product-discount-item-delete-${discountIdx}`}
            >
              Trash
            </IconButton>
          )}
        </View>
      </View>
      {discountModalVisible && (
        <OrderDetailsDiscountModal
          businessDiscountsList={businessDiscountsList}
          discountIdx={discountIdx}
          discountSkuEditActionTitle={DiscountModalTitle.EDIT}
          discounts={discounts}
          onApply={(newDiscount) => {
            onEditDiscount(newDiscount, discountIdx, productId);
            setDiscountModalVisible(false);
          }}
          onClose={() => setDiscountModalVisible(false)}
          productId={productId}
        />
      )}
      {deleteDiscountModalVisible && (
        <ConfirmationModal
          cancelText={translate('BACK')}
          confirmText={translate('YES_DELETE')}
          onCancel={() => setDeleteDiscountModalVisible(false)}
          onConfirm={() => {
            onDeleteDiscount(discountIdx, productId);
            setDeleteDiscountModalVisible(false);
          }}
          status="warning"
          title={translate('DELETE_DISCOUNT')}
          visible
        />
      )}
    </>
  );
};
