import { Card } from '@ui-kitten/components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { CheckBox } from '@design';
import { Statuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

interface CheckedState {
  [key: string]: boolean
}

export const CheckBoxDemo: FunctionComponent = () => {
  const [checked, setChecked] = useState<CheckedState>({});
  const [indeterminateChecked, setIndeterminateChecked] = useState<CheckedState>({});
  const [indeterminate, setIndeterminate] = useState<CheckedState>({
    basic: true,
    control: true,
    primary: true,
    info: true,
    warning: true,
    danger: true,
  });

  const onIndeterminateChange = useCallback((key) => (isChecked, isIndeterminate) => {
    setIndeterminateChecked((prev) => ({ ...prev, [key]: isChecked }));
    setIndeterminate((prev) => ({ ...prev, [key]: isIndeterminate }));
  }, []);

  const toggleChecked = useCallback((key) => (
    setChecked((prev) => (
      {
        ...prev,
        [key]: !prev[key],
      }
    ))
  ), []);

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="Status">
          {Statuses.map((status) => (
            <DemoBlock key={status}>
              <CheckBox
                checked={checked[status]}
                onChange={() => toggleChecked(status)}
                status={status}
                testID="test-checkbox"
              >
                {status}
              </CheckBox>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="Disabled">
          <DemoBlock>
            <CheckBox checked disabled testID="test-checkbox">Checked</CheckBox>
          </DemoBlock>
          <DemoBlock>
            <CheckBox disabled testID="test-checkbox">Not checked</CheckBox>
          </DemoBlock>
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="Indeterminate">
          {Statuses.map((status) => (
            <DemoBlock>
              <CheckBox
                checked={indeterminateChecked[status]}
                indeterminate={indeterminate[status]}
                onChange={onIndeterminateChange(status)}
                status={status}
                testID="test-checkbox"
              >
                {status}
              </CheckBox>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
    </View>
  );
};
