import { SharedConfig } from '@shared/constants/SharedConfig';
import { IBusinessLocation } from '@shared/interfaces';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { BusinessLocationApi } from '../utilities/api';

export interface UseLocationList {
  businessLocations: IBusinessLocation[];
  invalidateBusinessLocationList: () => void,
  isLoading: boolean;
  error: unknown;
}

const placeholderData = { data: [], total: 0, lastPage: 0, page: 0 };

export const useBusinessLocationList = (
  businessId: string,
): UseLocationList => {
  const queryClient = useQueryClient();

  const invalidateBusinessLocationList = () => {
    queryClient.invalidateQueries([QueryKeys.BUSINESS_LOCATION_LIST, businessId]);
  };

  const { data: businessLocations, isLoading, error } = useQuery(
    [QueryKeys.BUSINESS_LOCATION_LIST, businessId],
    () => {
      const filters = {
        businessId,
        limit: SharedConfig.MAX_PAGE_LIMIT,
      };
      return BusinessLocationApi.getLocations(filters);
    },
    {
      enabled: !!businessId,
      placeholderData,
      staleTime: 5 * 60 * 1000,
    },
  );

  return {
    // In UI tests, `businessLocations` is returned as undefined
    businessLocations: businessLocations?.data ?? [],
    invalidateBusinessLocationList,
    isLoading,
    error,
  };
};
