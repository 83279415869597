import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { BusinessLocationApi } from '../utilities/api';
import { QueryKeys } from '../constants';

interface UseBusinessLocationProps {
  businessLocationId?: string,
  onError?(error: Error): void,
}

export const useBusinessLocation = ({ businessLocationId, onError }: UseBusinessLocationProps) => {
  const queryClient = useQueryClient();
  const invalidateBusinessLocation = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.BUSINESS_LOCATION, businessLocationId])
  ), [businessLocationId, queryClient]);

  const { data: businessLocation, isFetching: isBusinessLocationLoading } = useQuery(
    [QueryKeys.BUSINESS_LOCATION, businessLocationId],
    () => BusinessLocationApi.getBusinessLocation(businessLocationId),
    {
      enabled: !!businessLocationId,
      onError: (error: Error) => onError?.(error),
    },
  );

  return {
    businessLocation,
    invalidateBusinessLocation,
    isBusinessLocationLoading,
  };
};
