import { View, StyleSheet, FlatList } from 'react-native';
import React, { useEffect } from 'react';
import { Button, CenteredSpinner, LargeModal, TableCell, TableHeader, TableRow, useToast } from '@design';
import { useTranslation } from 'react-i18next';
import { toShortDate } from '@shared/utils';
import { ApiProductPrice } from '@shared/interfaces/api';
import { useStyleSheet } from '@ui-kitten/components';
import { IProduct } from '@shared/interfaces';
import { ColorCircleIndicator } from '../../components/shared/ColorCircleIndicator/ColorCircleIndicator';
import { StringUtility } from '../../../utilities';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';

interface ViewPricingModalProps {
  onClose: () => void,
  prices: ApiProductPrice[],
  products: IProduct[],
  showPricingModal: boolean,
}

export const ViewPricingModal = ({
  onClose,
  prices,
  products,
  showPricingModal,
}: ViewPricingModalProps) => {
  const [translate] = useTranslation(['common', 'products']);
  const { currentBusinessId } = useAuthentication();
  const { createToast } = useToast();

  const styles = useStyleSheet({
    line: {
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: 'color-basic-transparent-100',
    },
    row: {
      backgroundColor: 'transparent',
      flex: 1,
    },
  });

  const {
    businessLocations,
    isLoading: areLocationsLoading,
    error: locationsError,
  } = useBusinessLocationList(currentBusinessId);

  const {
    error: priceTypeError,
    isLoading: arePriceTypesLoading,
    priceTypes,
  } = usePriceTypeList();

  useEffect(() => {
    const hasErrors = [locationsError, priceTypeError].some(Boolean);
    if (hasErrors) {
      createToast({
        children: translate<string>('UNEXPECTED_ERROR'),
        status: 'warning',
        testID: 'toast-content-element',
      });
      onClose();
    }
  }, [createToast, locationsError, onClose, priceTypeError, translate]);

  const assignedLocationAndCorporatePrices = prices.filter((price) => {
    const isCorporatePrice = !price.businessLocationId;
    const assignedLocation = businessLocations.find((location) => (
      location.id === price.businessLocationId
    ));
    return (isCorporatePrice || !!assignedLocation);
  });

  const sortPrices = (priceList) => {
    priceList.sort((a, b) => {
      return a.priceType.name.localeCompare(b.priceType.name);
    });

    return priceList.sort((a, b) => {
      if (!a.location?.locationName && !b.location?.locationName) return 0;
      if (!a.location?.locationName) return -1;
      if (!b.location?.locationName) return 1;
      return (a.location.locationName as string).localeCompare(b.location.locationName);
    });
  };

  const priceData = sortPrices(
    assignedLocationAndCorporatePrices.map((price) => {
      const { unitUoM, packageUnitQuantity } = products.find((product) => (
        product.id === price.productId));
      return {
        dateLastModified: price.updatedAt,
        id: price?.id,
        location: businessLocations.find((location) => location.id === price.businessLocationId)
          ?? null,
        packageUnitQuantity,
        price: price?.price,
        priceType: priceTypes.find((priceType) => priceType.id === price.priceTypeId),
        unitUoM,
      };
    }),
  );

  const PricingTableHeader = () => (
    <>
      <View style={styles.line} />
      <TableRow appearance="header" testID="pricing-table-header">
        <TableHeader
          style={{ backgroundColor: 'transparent', flex: 0.3 }}
          testID="pricing-table-header-default-indicator"
        />
        <TableHeader
          style={styles.row}
          testID="pricing-table-header-location"
        >
          {translate<string>('LOCATION')}
        </TableHeader>
        <TableHeader
          style={styles.row}
          testID="pricing-table-header-price-type"
        >
          {translate<string>('PRICE_TYPE')}
        </TableHeader>
        <TableHeader
          style={styles.row}
          testID="pricing-table-header-price-unit"
        >
          {translate<string>('PRICE_UNIT')}
        </TableHeader>
        <TableHeader
          style={styles.row}
          testID="pricing-table-header-date-last-modified"
        >
          {translate<string>('DATE_LAST_MODIFIED')}
        </TableHeader>
      </TableRow>
    </>
  );

  const PricingTable = () => {
    return (
      <FlatList
        ListFooterComponent={() => areLocationsLoading && arePriceTypesLoading && (
          <CenteredSpinner isFixed />
        )}
        ListHeaderComponent={PricingTableHeader}
        data={priceData}
        keyExtractor={(item) => item.id}
        renderItem={({ item, index }) => {
          return (
            <TableRow testID={`view-pricing-row-${item.id}`}>
              <TableCell align="center" style={{ flex: 0.3 }} testID={`is-default-${index}`}>
                {item.priceType?.isDefault && <ColorCircleIndicator testID="view-pricing-indicator" />}
              </TableCell>
              <TableCell style={styles.row} testID={`locationName-${index}`}>
                {item?.location?.locationName || translate<string>('CORPORATE_LOCATION')}
              </TableCell>
              <TableCell style={styles.row} testID={`name-${index}`}>{item.priceType?.name}</TableCell>
              <TableCell
                style={styles.row}
                testID={`price-${index}`}
              >
                {`${StringUtility.formatCurrencyAccounting(item.price)}/${item.unitUoM ? translate(item.unitUoM) : 'unit'}`}
              </TableCell>
              <TableCell style={styles.row} testID={`dateLastModified-${index}`}>
                {toShortDate(item.dateLastModified)}
              </TableCell>
            </TableRow>
          );
        }}
        style={{ marginHorizontal: 36 }}
      />
    );
  };

  return (
    <LargeModal
      footer={() => (
        <View style={{ position: 'absolute', bottom: 32, right: 40 }}>
          <Button
            appearance="outline"
            design="floating"
            onPress={onClose}
            size="giant"
            status="basic"
            testID="close-button"
          >
            {translate<string>('CLOSE')}
          </Button>
        </View>
      )}
      pages={[<PricingTable />]}
      testID="view-pricing-large-modal"
      title={translate<string>('PRICING')}
      visible={showPricingModal}
    />
  );
};
