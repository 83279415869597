import { UserType } from '@shared/enums';
import { ApiBusiness, ApiUserAccount } from '@shared/interfaces/api';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { LocalStorageUtility } from '../../utilities';
import { AuthApi, BusinessApi, UserApi } from '../../utilities/api';

export const AuthenticationContext = React.createContext<IAuthenticationContext>(null);

const KEY_SELECTED_BUSINESS_ID = 'selected business id';

export enum SessionState {
  UNKNOWN,
  LOGGED_OUT,
  LOGGED_IN,
}

/**
 * Authentication Context interface that handles management of the user session
 */
export interface IAuthenticationContext {
  /**
   * State for the current session. Until the pre-authentication
   * finishes, it will be UNKNOWN
   */
  sessionState: SessionState;
  /**
   * Optional user object for the logged in user
   */
  user?: ApiUserAccount;
  setUser: Dispatch<SetStateAction<ApiUserAccount | null>>,
  /**
   * Authentication function which tries to authenticate with the
   * given credentials
   * @param email
   * @param password
   * @returns A promise that resolves if authentication is successful, or rejects if fails
   */
  authenticate: (email: string, password: string) => Promise<void>;
  /**
   * Logout callback function that will clear the user session
   * @returns A promise that resolves after the cleanup is performed
   */
  logout: () => Promise<void>;
  updateBusinessList: () => void;
  accessDenied: () => void;
  // Undefined means the ID is not yet loaded, null means no business is selected.
  currentBusinessId: string | null | undefined;
  // Undefined means the business is not yet loaded, null means no business is selected.
  currentBusiness: ApiBusiness | null | undefined;
  availableBusinesses: ApiBusiness[];
  selectBusiness: (business: ApiBusiness | null) => void;
}

const { Provider } = AuthenticationContext;
/**
 * React Authentication provider that manages auth state in it's internal
 * state and pushes these down to the underlying components.
 *
 * To consume the auth state, use the useAuthentication hook:
 * const { user } = useAuthentication();
 */
export const AuthenticationProvider = ({ children }) => {
  const [availableBusinesses, setAvailableBusinesses] = useState<ApiBusiness[]>([]);
  const [currentBusiness, setCurrentBusiness] = useState<ApiBusiness | null | undefined>(undefined);
  const [currentBusinessId, setCurrentBusinessId] = useState<string | null | undefined>(undefined);
  const history = useHistory();
  const [sessionState, setSessionState] = useState(SessionState.UNKNOWN);
  const queryClient = useQueryClient();
  const [updateBizList, setUpdateBizList] = useState(false);
  const [user, setUser] = useState<ApiUserAccount | null>(null);

  useEffect(() => {
    if (user?.userType === UserType.INTERNAL) {
      (async () => {
        const businesses = await BusinessApi.getAllBusinesses();
        setAvailableBusinesses(businesses.data);
        setUpdateBizList(false);
      })();
    }
  }, [updateBizList, user]);

  useEffect(() => {
    const validateUserSession = async () => {
      try {
        const userData = await UserApi.getUserById('current');
        setUser(userData);
        setSessionState(SessionState.LOGGED_IN);
      } catch (e) {
        setSessionState(SessionState.LOGGED_OUT);
        setUser(null);
      }
    };

    if (sessionState === SessionState.UNKNOWN) {
      validateUserSession();
    }
  }, [sessionState]);

  useEffect(() => {
    (async () => {
      if (user) {
        if (user.businessId) {
          setCurrentBusinessId(user.businessId);
          const business = await BusinessApi.getBusiness(user.businessId);
          setCurrentBusiness(business);
          setAvailableBusinesses([business]);
        } else {
          const storedBusinessId = await LocalStorageUtility.getValue(KEY_SELECTED_BUSINESS_ID);
          setCurrentBusinessId(storedBusinessId);
          const businesses = await BusinessApi.getAllBusinesses();
          setAvailableBusinesses(businesses.data);
          const business = businesses.data.find((b) => b.id === storedBusinessId);
          setCurrentBusiness(business);
        }
      } else {
        setCurrentBusiness(undefined);
        setAvailableBusinesses([]);
      }
    })();
  }, [user]);

  const selectBusiness = useCallback((business: ApiBusiness | null | undefined) => {
    setCurrentBusiness(business);
    setCurrentBusinessId(business?.id ?? (business as null | undefined));
    LocalStorageUtility.setValue(KEY_SELECTED_BUSINESS_ID, business?.id ?? null);
    if (business === null) { history.push(Routes.USER_LIST); }
  }, [history, setCurrentBusiness]);

  const authenticate = async (email: string, password: string) => {
    const result = await AuthApi.login(email, password);

    const userData = await UserApi.getUserById(result.user.id);
    setUser(userData);
    setSessionState(SessionState.LOGGED_IN);
  };

  const logout = async () => {
    await AuthApi.logout();
    selectBusiness(undefined);
    setAvailableBusinesses([]);
    queryClient.clear();
    setSessionState(SessionState.LOGGED_OUT);
  };

  const updateBusinessList = async () => {
    setUpdateBizList(true);
  };

  const accessDenied = () => {
    history.replace(Routes.ACCESS_DENIED);
  };

  return (
    <Provider value={{
      accessDenied,
      authenticate,
      availableBusinesses,
      currentBusiness,
      currentBusinessId,
      logout,
      selectBusiness,
      sessionState,
      setUser,
      updateBusinessList,
      user,
    }}
    >
      {children}
    </Provider>
  );
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }
  return context as IAuthenticationContext;
};
