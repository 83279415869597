import React, { useState } from 'react';
import { Button, HSpacer, Icon, Modal, NumericInput, Text, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  description: {
    maxWidth: 356,
  },
  input: {
    maxWidth: 218,
  },
  footer: {
    paddingTop: 40,
    paddingLeft: 0,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttons: {
    flexDirection: 'row',
  },
});

export interface AcreageModalProps {
  initialAcreage: number;
  onAcreageChange: (acreage: number) => void;
  onCancel(): void,
}
export function AcreageModal ({
  initialAcreage,
  onAcreageChange,
  onCancel,
}: AcreageModalProps) {
  const [translate] = useTranslation(['common', 'productOrders']);
  const [acreage, setAcreage] = useState(initialAcreage);

  const renderFooterAccessory = ({
    primaryButtonProp,
    secondaryButtonProp,
    spacerProp,
  }) => (
    <>
      {acreage === 0 ? (
        <View />
      ) : (
        <Button
          {...primaryButtonProp}
          accessoryLeft={(iconProps) => (
            <Icon name="Trash" testID="acreage-modal-delete-textLink" {...iconProps} />
          )}
          appearance="ghost"
          onPress={() => onAcreageChange(0)}
          testID="acreage-modal-delete-button"
        >
          {translate<string>('REMOVE_ACREAGE')}
        </Button>
      )}
      <View style={styles.buttons}>
        <Button
          {...primaryButtonProp}
          onPress={onCancel}
          testID="acreage-modal-cancel-button"
        >
          {translate<string>('CANCEL')}
        </Button>
        <HSpacer {...spacerProp} />
        <Button
          {...secondaryButtonProp}
          disabled={initialAcreage === acreage}
          onPress={() => onAcreageChange(acreage)}
          testID="acreage-modal-update-button"
        >
          {translate<string>('UPDATE')}
        </Button>
      </View>
    </>
  );

  return (
    <Modal
      footerAccessory={renderFooterAccessory}
      footerStyle={styles.footer}
      hideCloseButton
      onClose={onCancel}
      testID="acreage-modal"
      title={translate('EDIT_ACREAGE')}
      visible
    >
      <Text category="p2" status="basic" style={styles.description}>
        {translate<string>('ACREAGE_ADD_DESCRIPTION')}
      </Text>
      <VSpacer size="7" />
      <NumericInput
        label={translate<string>('ACRES_LABEL')}
        minValue={0}
        onChangeValue={setAcreage}
        precision={3}
        style={styles.input}
        testID="input-add-acreage"
        value={acreage}
      />
    </Modal>
  );
}
