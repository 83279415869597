import React, { useMemo, useState } from 'react';
import { Button, HSpacer, Modal, Text, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { testId } from '../../../../../utilities';
import { TankMixNameInput } from './TankMixNameInput';

export interface DuplicateModalProps {
  saveNew?: boolean,
  name: string,
  onUpdateName(name: string): void,
  onClose(): void,
}
export function DuplicateModal ({
  saveNew,
  onUpdateName,
  onClose,
  ...props
}: DuplicateModalProps) {
  const [translate] = useTranslation(['prepare', 'tankMix', 'common', 'errors']);
  const [name, setName] = useState(props.name ?? '');
  const [nameValidated, setNameValidated] = useState(true);

  const title = useMemo(() => {
    return translate(saveNew ? 'SAVE_NEW' : 'DUPLICATE');
  }, [saveNew, translate]);

  const renderFooterAccessory = ({
    primaryButtonProp,
    secondaryButtonProp,
    spacerProp,
  }) => (
    <>
      <Button
        {...testId('duplicate-modal-cancel-button')}
        {...primaryButtonProp}
        onPress={onClose}
      >
        {translate<string>('CANCEL')}
      </Button>
      <HSpacer {...spacerProp} />
      <Button
        {...testId('duplicate-modal-save-button')}
        {...secondaryButtonProp}
        disabled={!name || name === props.name || !nameValidated}
        onPress={() => onUpdateName(name)}
      >
        {translate<string>('SAVE')}
      </Button>
    </>
  );

  return (
    <Modal
      {...testId('duplicate-modal')}
      footerAccessory={renderFooterAccessory}
      onClose={onClose}
      scrollableContent
      showScrollIndicator
      title={title}
      visible
    >
      <Text category="p2" status="basic">
        {translate<string>('DUPLICATE_DESCRIPTION')}
      </Text>
      <VSpacer size="8" />
      <TankMixNameInput
        name={name}
        onUpdateName={setName}
        onUpdateValidate={setNameValidated}
      />
    </Modal>
  );
}
