import { View, Text, StyleSheet } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, CenteredSpinner, ColorCircle, ColorSelect, HSpacer, LargeModal, NumericInput, Select, SelectItem, ToastProps, useToast, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { CropColor, CropColors, ECropColor } from '@theme/variant-interfaces/Colors';
import { IndexPath } from '@ui-kitten/components';
import { ApiCrop, CropEndpoint } from '@shared/interfaces/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Status } from '@theme/variant-interfaces/Status';
import { CropSubType, CropType, CropTypeMap, CropUnitOfMeasure } from '@shared/enums';
import { ButtonBar } from '../../../components/shared/ButtonBar';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { BusinessCropApi } from '../../../../utilities/api';

const styles = StyleSheet.create({
  inputRow: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

export enum Mode { ADD, EDIT }

interface CropModalProps {
  crop: ApiCrop,
  mode: Mode,
  toggle: () => void,
  visible: boolean,
}

export const CropModal = ({ crop, mode, toggle, visible }: CropModalProps) => {
  const [translate] = useTranslation(['businesses', 'common']);
  const { currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const { createToast } = useToast();
  const [saving, setSaving] = useState(false);
  const [cropList, setCropList] = useState<CropEndpoint.Get.Response>(null);
  const [selectedColor, setSelectedColor] = useState<CropColor>(null);
  const [cropType, setCropType] = useState<CropType>(null);
  const [cropSubType, setCropSubType] = useState<CropSubType>(null);
  const [price, setPrice] = useState<number>(null);
  const [priceUom, setPriceUOM] = useState<CropUnitOfMeasure>(null);
  const [yieldGoal, setYieldGoal] = useState<number>(null);
  const [yieldGoalUOM, setYieldGoalUOM] = useState<CropUnitOfMeasure>(null);

  const toastProps = (toastText: string, status: Status): ToastProps => ({
    children: toastText,
    status,
    testID: 'toast-content-element',
  });

  const clearForm = () => {
    setCropSubType(null);
    setCropType(null);
    setPrice(null);
    setPriceUOM(null);
    setSelectedColor(null);
    setYieldGoal(null);
    setYieldGoalUOM(null);
  };

  const setRandomCropColor = () => {
    const { length } = CropColors;
    const randomCropColor = CropColors[Math.floor(Math.random() * length)];
    setSelectedColor(randomCropColor);
  };

  useEffect(() => {
    if (crop) {
      setCropSubType(crop.subType);
      setCropType(crop.cropType);
      setPrice(crop.price);
      setPriceUOM(crop.priceUom);
      setSelectedColor(Object.keys(ECropColor)
        .find((key) => crop?.color === ECropColor[key]) as CropColor);
      setYieldGoal(crop.yieldGoal);
      setYieldGoalUOM(crop.yieldGoalUom);
    } else if (!crop || !visible) {
      clearForm();
      setRandomCropColor();
    }
  }, [crop, visible]);

  const getBusinessCrops = async () => BusinessCropApi.getBusinessCrops(currentBusinessId);
  const { isLoading } = useQuery([
    QueryKeys.BUSINESS_CROPS,
    crop,
    currentBusinessId,
  ], getBusinessCrops, {
    onError: () => createToast(toastProps(translate('UNEXPECTED_ERROR'), 'warning')),
    onSuccess: (data) => setCropList(data),
  });

  const getCropListOptions = () => (
    CropTypeMap.getCropSubTypes().filter((c) => (
      !cropList?.find((cropToFind) => (
        c.type === cropToFind.cropType
        && c.subType === cropToFind.subType
      ))
    )));

  const onSelectCrop = (selection: IndexPath) => {
    const { type, subType } = getCropListOptions()[selection.row];
    setCropType(type);
    setCropSubType(subType);
  };

  const onSelectUnitUoM = (selection: IndexPath) => {
    let selectedUom;
    if (!cropType) {
      selectedUom = CropUnitOfMeasure[selection.row];
    } else {
      selectedUom = CropTypeMap.crops
        .find((c) => c.type === cropType).marketUoms[selection.row];
    }
    setPriceUOM(selectedUom);
  };

  const onSelectYieldUoM = (selection: IndexPath) => {
    let selectedUom;
    if (!cropType) {
      selectedUom = CropUnitOfMeasure[selection.row];
    } else {
      selectedUom = CropTypeMap.crops
        .find((c) => c.type === cropType).yieldUoms[selection.row];
    }
    setYieldGoalUOM(selectedUom);
  };

  const getUomOptions = (goal: 'yieldUoms' | 'marketUoms') => {
    if (!cropType) {
      return (
        Object.keys(CropUnitOfMeasure).map((uom, index) => (
          <SelectItem
            key={uom}
            testID={`crop-modal-uom-dropdown-value-${index}`}
            title={translate<string>(CropUnitOfMeasure[uom])}
          />
        ))
      );
    }
    return (
      CropTypeMap.crops
        .find((c) => c.type === cropType)[goal].map((uom, index) => (
          <SelectItem
            key={uom}
            testID={`crop-modal-uom-dropdown-value-${index}`}
            title={translate<string>(uom)}
          />
        ))
    );
  };

  const getTitle = () => (
    mode === Mode.EDIT
      ? translate('EDIT_CROP_TITLE')
      : translate('ADD_CROP_TITLE')
  );

  const formValid = useMemo(() => {
    const hasRequiredFields = [
      cropSubType,
      cropType,
      priceUom,
      selectedColor,
      yieldGoalUOM,
    ].every(Boolean);
    const hasCorrectValues = [price, yieldGoal].every((value) => {
      return !!value && value >= 0;
    });
    return hasRequiredFields && hasCorrectValues;
  }, [cropSubType, cropType, price, priceUom, selectedColor, yieldGoal, yieldGoalUOM]);

  const onSubmitMutate = useMutation(
    [QueryKeys.BUSINESS_CROPS],
    (updates: ApiCrop[]) => (
      BusinessCropApi.saveBusinessCrops(currentBusinessId, updates)
    ), {
      onError: (error: Error) => {
        createToast(toastProps(
          translate<string>('UNEXPECTED_ERROR', { error }),
          'warning',
        ));
      },
      onSuccess: () => {
        setSaving(false);
        queryClient.invalidateQueries(QueryKeys.BUSINESS_CROPS);
        createToast(toastProps(translate<string>(mode === Mode.ADD
          ? 'CROP_SUCCESSFULLY_ADDED'
          : 'CROP_SUCCESSFULLY_EDITED'),
        'success'));
        toggle();
      },
    },
  );

  const onSubmit = async () => {
    const color = ECropColor[CropColors.find((c) => c === selectedColor)];
    setSaving(true);
    const newCropList = [...cropList];
    const updates: ApiCrop = {
      color,
      cropType,
      inUse: mode === Mode.ADD ? false : crop?.inUse,
      price,
      priceUom,
      subType: cropSubType,
      yieldGoal,
      yieldGoalUom: yieldGoalUOM,
    };
    const indexToUpdate = newCropList.findIndex((item) => (
      item.cropType === crop?.cropType
      && item.subType === crop?.subType
    ));
    if (indexToUpdate > -1) {
      newCropList[indexToUpdate] = updates;
    } else {
      newCropList.push(updates);
    }
    return onSubmitMutate.mutateAsync(newCropList);
  };

  const CropFormPage = [
    <View style={{ alignItems: 'center', width: '100%' }}>
      <KeyboardAwareScrollView>
        {isLoading && <CenteredSpinner />}
        <Card
          style={{ justifyContent: 'center', alignItems: 'center', width: 489, backgroundColor: 'transparent' }}
          testID="crop-card"
        >
          <View style={styles.inputRow}>
            <Select
              disabled={crop?.inUse}
              isRequired
              label={translate<string>('CROP')}
              onSelect={(i) => onSelectCrop(i as IndexPath)}
              style={{ flex: 1 }}
              testID="crop-type-options"
              value={(!cropType) ? '' : `${translate(cropType)} | ${translate(cropSubType)}`}
            >
              {getCropListOptions()?.map((item, i) => (
                <SelectItem
                  key={`${item.type}-${item.subType}`}
                  testID={`crop-select-${i}`}
                  title={`${translate(item.type)} | ${translate(item.subType)}`}
                />
              ))}
            </Select>
            <HSpacer size="7" />
            <ColorSelect
              label={translate('COLOR')}
              onSelect={(i: IndexPath | IndexPath[]) => setSelectedColor(
                CropColors[(i as IndexPath).row],
              )}
              selectedIndex={new IndexPath(CropColors.findIndex((c) => c === selectedColor))}
              testID="crop-color"
              value={selectedColor}
            >
              {CropColors.map((color, index) => (
                <ColorCircle color={color} key={color} testID={`crop-color-circle-${index}`} />
              ))}
            </ColorSelect>
          </View>
          <VSpacer size="8" />
          <View style={styles.inputRow}>
            <NumericInput
              isRequired
              label={translate<string>('CROP_MARKET_PRICE')}
              onChangeValue={setPrice}
              prefix="$ "
              style={{ flex: 1 }}
              testID="crop-price-input"
              value={price}
            />
            <HSpacer size="7" />
            <Select
              isRequired
              label={translate<string>('UNIT_LABEL')}
              onSelect={(i: IndexPath | IndexPath[]) => onSelectUnitUoM(i as IndexPath)}
              style={{ flex: 1 }}
              testID="crop-price-uom-selector"
              value={translate<string>(priceUom) || ''}
            >
              {getUomOptions('marketUoms')}
            </Select>
          </View>
          <VSpacer size="8" />
          <View style={styles.inputRow}>
            <NumericInput
              isRequired
              label={translate<string>('YIELD_GOAL_PER_ACRE')}
              onChangeValue={setYieldGoal}
              style={{ flex: 1 }}
              testID="yield-goal-input"
              value={yieldGoal}
            />
            <HSpacer size="7" />
            <Select
              isRequired
              label={translate<string>('UNIT_LABEL')}
              onSelect={(i: IndexPath | IndexPath[]) => onSelectYieldUoM(i as IndexPath)}
              style={{ flex: 1 }}
              testID="yield-goal-uom-selector"
              value={translate<string>(yieldGoalUOM)}
            >
              {getUomOptions('yieldUoms')}
            </Select>
          </View>
        </Card>
      </KeyboardAwareScrollView>
    </View>,
  ];

  const Footer = () => (
    <ButtonBar
      disableRightAction={!formValid || saving}
      leftAction={() => toggle()}
      leftButtonText={translate('CANCEL')}
      rightAction={() => onSubmit()}
      rightButtonText={mode === Mode.EDIT
        ? translate('SAVE_CHANGES')
        : translate('SAVE')}
      testID="modal-footer"
    />
  );

  return (
    <LargeModal
      footer={() => <Footer />}
      pages={CropFormPage}
      testID="crop-form-modal"
      title={getTitle()}
      visible
    >
      <Text>CropModal</Text>
    </LargeModal>
  );
};
