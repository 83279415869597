export default {
  ACRES_APPLIED: 'Acres applied',
  ADD_DISCOUNT: 'Add discount',
  APPLICATION_RATE: 'Application rate',
  APPLICATION_RATE_UOM_PER_AC: '{{ applicationRate }} {{ applicationRateUom }}/ac',
  AVG_RATE_APPLIED: 'Avg. rate applied',
  CALCULATE_QUANTITY: 'Calculate Quantity',
  CALCULATE_QTY: 'Calculate Qty',
  CANCEL_CONFIRMATION: 'Farm Plan successfully updated',
  CANCEL_PRODUCT: 'This product has been cancelled.',
  CANCEL_SKU_WARNING: 'Canceling this product SKU will remove the cost information from this order and any deliverables created after this point. Reports will also be updated to reflect this change.\n\nThis action cannot be undone.',
  CANCEL_SKU_ORDER_WARNING_DYNAMICS: 'Cancelling this product SKU will remove the cost information from this order and any deliverables created after this point. Reports will also be updated to reflect this change.\n\nThe corresponding order in Business Central will not be deleted.\n\nRally will notify {{ email }} of the cancellations.',
  CANCEL_SKU_QUOTE_WARNING_DYNAMICS: 'Cancelling this product SKU will remove the cost information from this order and any deliverables created after this point. Reports will also be updated to reflect this change.\n\nThe corresponding quote(s) in Business Central will be marked as Cancelled if all products within the quote have been cancelled. They will not be deleted.\n\nProducts within partially cancelled quotes will be deleted from the quote. Rally will notify {{ email }} of the cancellations.',
  DELETE_DISCOUNT: 'Delete discount?',
  DISCOUNTS: 'Discounts',
  DISCOUNT_APPLIED: 'Discount applied',
  EXTERNAL_ID: 'External ID',
  LOCK_PRICE: 'Lock price',
  NO_KEEP_EDITING: 'No, keep editing',
  PERCENT_OFF: '{{percent}}% OFF',
  PER_UOM: 'per {{ UoM }}',
  PRICE_LOCKED: 'Price locked',
  PRICE_PER_AC: '{{ price }}/ac',
  PRICE_UNLOCKED: 'Price unlocked',
  PRODUCT_SKU_PRICE: 'Product SKU price',
  RETAIL_UNIT_PRICE: 'Retail unit price',
  SET_PLAN_STATUS: 'Set plan status',
  SKU_PACKAGES_REQUIRED: 'SKU packages required',
  SKU_PRICE: 'SKU price',
  SKU_QTY: 'SKU Qty',
  SKU_QUANTITY_NEEDED: 'SKU quantity needed',
  THIS_PRODUCT_HAS_BEEN_CANCELLED: 'This product has been cancelled.',
  THIS_PRODUCT_WILL_BE_CANCELLED: 'This product will be cancelled.',
  TOTAL_UNITS: 'Total units',
  UNITS_PER_SKU: 'Units per SKU',
  UNITS_REQUIRED: 'Units required',
  UNIT_PRICE: 'Unit price',
  UNLOCK: 'Unlock',
  UNLOCK_PRICE: 'Unlock price?',
  UNLOCK_PRICE_WARNING: 'The current unit price for this product is {{ current }} per {{ unit }} and unlocking will change the price to {{ default }} per {{ unit }}.',
  YES_DELETE: 'Yes, delete',
  YES_CANCEL_SKU: 'Yes, cancel SKU',
};
