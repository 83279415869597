import {
  Button,
  Chip,
  HSpacer,
  Input,
  LargeModal,
  ModalSpinner,
  ResponsiveView,
  Select,
  SelectItem,
  Text,
  useToast,
  VSpacer,
} from '@design';
import { UserRole, UserType } from '@shared/enums';
import { IBusinessLocation } from '@shared/interfaces';
import { ApiBusiness, ApiUserAccount } from '@shared/interfaces/api';
import { Permissions, RoleUtility, userIsInternal } from '@shared/utils';
import { validateEmail, validatePhoneNumber } from '@shared/utils/validators';
import { IndexPath } from '@ui-kitten/components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { StringUtility } from '../../../utilities';
import { BusinessApi, UserApi } from '../../../utilities/api';
import { errorIsDetailedApiError } from '../../../utilities/api/DetailedApiError';
import { ChangeUserPasswordForm } from '../Auth/ChangeUserPasswordForm';

const userTypes = [UserType.INTERNAL, UserType.BUSINESS];
const userRoleMap = {
  [UserType.INTERNAL]: [UserRole.SUPER],
  [UserType.BUSINESS]: [UserRole.BUSINESS_ADMIN, UserRole.BUSINESS_STANDARD],
};

export interface UserFormModalProps {
  onClose: () => Promise<void>;
  userId?: string;
  actionType: 'EDIT_USER' | 'CREATE_USER';
}
export const UserFormModal: FC<UserFormModalProps> = ({ onClose, userId, actionType }) => {
  const [translate] = useTranslation(['users', 'common', 'login']);
  const queryClient = useQueryClient();
  const { user, setUser, currentBusiness, currentBusinessId } = useAuthentication();
  const { businessLocations } = useBusinessLocationList(currentBusinessId);
  const accessAllBusiness = userIsInternal(user);
  const modifyBusinessObjects = (
    RoleUtility.roleHasPermission(user.userRole, Permissions.MODIFY_BUSINESS_OBJECTS)
  );

  const [loading, setLoading] = useState(false);
  const [availableBusinesses, setAvailableBusinesses] = useState<ApiBusiness[]>([]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [telephone, setTelephone] = useState('');
  const [telephoneError, setTelephoneError] = useState(false);

  const [userType, setUserType] = useState<UserType>();
  const [userRole, setUserRole] = useState<UserRole>();
  const [userTypeReadonly, setUserTypeReadonly] = useState<boolean>(false);
  const [selectedBusiness, setSelectedBusiness] = useState<ApiBusiness>();
  const [availableLocations, setAvailableLocations] = useState<IBusinessLocation[]>([]);
  const [selectedBusinessLocations, setSelectedBusinessLocations] = (
    useState<IBusinessLocation[]>([])
  );
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const [editUser, setEditUser] = useState<ApiUserAccount>();
  const { createToast } = useToast();

  const selectBusiness = useCallback((business: ApiBusiness) => {
    if (!business) {
      setAvailableLocations([]);
      setSelectedBusinessLocations([]);
      return;
    }
    setSelectedBusiness(business);
    // TODO consider moving this logic to the api so that locations is only active and pre-sorted
    if (!business?.locations) {
      setAvailableLocations([]);
      setSelectedBusinessLocations([]);
      return;
    }
    businessLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));
    setAvailableLocations(businessLocations);
  }, [businessLocations]);

  useEffect(() => {
    if (currentBusiness) {
      selectBusiness(currentBusiness);
      setUserType(UserType.BUSINESS);
    } else {
      setUserType(UserType.INTERNAL);
      setUserTypeReadonly(true);
      setSelectedBusiness(undefined);
    }
  }, [currentBusiness, selectBusiness]);

  useEffect(() => {
    if (!userId) {
      if (selectedBusiness) {
        setUserRole(UserRole.BUSINESS_ADMIN);
      } else if (accessAllBusiness) {
        setUserRole(UserRole.SUPER);
      }
    }
  }, [userId, selectedBusiness, accessAllBusiness]);

  useEffect(() => {
    if (availableBusinesses.length) {
      return;
    }

    if (!accessAllBusiness) {
      setUserType(UserType.BUSINESS);
      BusinessApi.getBusiness(user.businessId).then((business) => {
        setAvailableBusinesses([business]);
        selectBusiness(business);
      });
    }
    if (accessAllBusiness) {
      BusinessApi.getAllBusinesses().then((businesses) => {
        setAvailableBusinesses(businesses.data);
      });
    }
  }, [accessAllBusiness, availableBusinesses, user.businessId, selectBusiness]);

  const { isFetching } = useQuery(
    [QueryKeys.USER, userId],
    () => UserApi.getUserById(userId),
    {
      onSuccess: (data) => {
        setEditUser(data);
        setUserType(data.userType);
        setUserRole(data.userRole);
        setFirstName(data.firstName || '');
        setLastName(data.lastName || '');
        setEmail(data.email || '');
        setOriginalEmail(data.email || '');
        setTelephone(data.telephone || '');

        const activeLocations = data.businessLocations.filter((l) => l.isActive);
        setSelectedBusinessLocations(activeLocations);

        if (availableBusinesses.length) {
          selectBusiness(availableBusinesses.find((b) => b.id === data.business?.id));
        }
      },
      enabled: !!userId,
    },
  );

  useEffect(() => {
    if (!editUser) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setTelephone('');
      setOriginalEmail('');
    }
  }, [editUser]);

  useEffect(() => {
    if (emailError) {
      setEmailError(!!email && !validateEmail(email));
    }
    if (telephoneError) {
      setTelephoneError(!!telephone && !validatePhoneNumber(telephone));
    }
  }, [email, emailError, telephone, telephoneError]);

  const onSelectUserType = useCallback((indexPath: IndexPath | IndexPath[]) => {
    const selectedUserType = userTypes[(indexPath as IndexPath).row];
    setUserType(selectedUserType);
    setUserRole(null);
    if (userRoleMap[selectedUserType].length === 1) {
      setUserRole(userRoleMap[selectedUserType][0]);
    }
  }, []);

  const formValid = (): boolean => {
    const requiredFields = [
      firstName.trim(),
      lastName.trim(),
      email,
      userType,
      userRole,
    ];
    const formValidations = [
      requiredFields.every(Boolean),
      (email && validateEmail(email))
      && (!telephone || validatePhoneNumber(telephone)),
      !(accessAllBusiness && userType === UserType.BUSINESS
    && !selectedBusiness),
    ];

    return formValidations.every(Boolean);
  };

  const createUser = useMutation(async () => UserApi.createUser({
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    email,
    telephone: telephone || null,
    userType,
    userRole,
    businessId: selectedBusiness?.id,
    locationAssignments: selectedBusinessLocations.map((location) => location.id),
  }), {
    onError: (err: Error) => {
      if (errorIsDetailedApiError(err) && err.code === 'email-exists') {
        createToast({
          children: translate<string>('EMAIL_ALREADY_EXISTS'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      } else {
        createToast({
          children: translate<string>('ERROR_CREATING_USER'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      }
    },
    onSuccess: async () => {
      createToast({
        children: translate<string>('USER_CREATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
      await queryClient.invalidateQueries(QueryKeys.USER);
      await onClose();
    },
  });

  const updateUser = useMutation(async () => UserApi.updateUser(userId, {
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    email,
    telephone: telephone || null,
    userRole,
    locationAssignments: selectedBusinessLocations.map((location) => location.id),
  }), {
    onError: (err: Error) => {
      if (errorIsDetailedApiError(err) && err.message === 'email-taken') {
        createToast({
          children: translate<string>('EMAIL_ALREADY_EXISTS'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      } else {
        createToast({
          children: translate<string>('ERROR_UPDATING_USER'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      }
    },
    onSuccess: (userData) => {
      if (email !== originalEmail) {
        createToast({
          children: translate<string>('EMAIL_VERIFICATION_SENT'),
          status: 'info',
          testID: 'toast-content-element',
        });
      }

      createToast({
        children: translate<string>('USER_UPDATED'),
        status: 'success',
        testID: 'toast-content-element',
      });

      if (userId === user.id) {
        setUser(userData);
      }
      queryClient.invalidateQueries(QueryKeys.USER);
      onClose();
    },
  });

  useEffect(() => {
    setLoading(isFetching || createUser.isLoading || updateUser.isLoading);
  }, [createUser.isLoading, isFetching, updateUser.isLoading]);

  // only super can set user type only when in internal view
  const userTypeVisible = accessAllBusiness && !currentBusinessId;

  const userRoleReadonly = (
    // can not change when only single value
    userRoleMap[userType]?.length === 1
    // can not change if you can not modify business objects
    || !modifyBusinessObjects
    // can not change if editing yourself
    || userId === user.id
  );
  // can't set role until type is selected.
  const userRoleDisabled = !userType;

  // show locations if there are locations and if business standard
  const showLocations = !!availableLocations?.length
    && userRole === UserRole.BUSINESS_STANDARD;
  // can only modify locations if you can modify the business
  const locationsReadonly = !modifyBusinessObjects;

  const getSubTitle = () => {
    if (user.userRole === UserRole.SUPER) {
      return currentBusiness ? currentBusiness.businessName
        : StringUtility.toProperCase(UserType.INTERNAL);
    }
    return null;
  };

  if (
    actionType === 'EDIT_USER'
    && user.id !== userId
    && !modifyBusinessObjects
  ) {
    return null;
  }

  return (
    <>
      <LargeModal
        footer={(
          <View style={{
            flexDirection: 'row',
            position: 'absolute',
            right: 40,
            bottom: 40,
          }}
          >
            <Button
              appearance="outline"
              onPress={onClose}
              status="basic"
              testID="cancel-button"
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer size="6" />
            <Button
              disabled={!formValid()}
              onPress={async () => {
                if (userId) {
                  await updateUser.mutateAsync();
                } else {
                  await createUser.mutateAsync();
                }
              }}
              testID="submit-button"
            >
              {translate<string>('SAVE')}
            </Button>
          </View>
        )}
        pages={[
          <>
            <View
              style={{
                flex: 1,
                alignItems: 'center',
              }}
              testID="user-creation-view"
            >
              <ResponsiveView large={4} medium={8} small={6} xLarge={4} xSmall={4}>
                <Text category="label" style={{ marginBottom: 48 }}>
                  {translate<string>('USER_DETAILS')}
                </Text>
                <Input
                  caption={translate<string>('REQUIRED')}
                  label={translate<string>('FIRST_NAME')}
                  onChangeText={setFirstName}
                  testID="first-name-field"
                  value={firstName}
                />
                <VSpacer size="8" />
                <Input
                  caption={translate<string>('REQUIRED')}
                  label={translate<string>('LAST_NAME')}
                  onChangeText={setLastName}
                  testID="last-name-field"
                  value={lastName}
                />
                <VSpacer size="8" />
                <Input
                  autoCapitalize="none"
                  caption={translate<string>(emailError ? 'VALID_EMAIL_REQUIRED' : 'REQUIRED')}
                  defaultValue={email}
                  keyboardType="email-address"
                  label={translate<string>('EMAIL')}
                  onBlur={() => setEmailError(!!email && !validateEmail(email))}
                  onChangeText={setEmail}
                  status={emailError ? 'danger' : undefined}
                  testID="email-field"
                />
                <VSpacer size="8" />
                <Input
                  caption={telephoneError && translate<string>('VALID_PHONE_REQUIRED')}
                  defaultValue={telephone}
                  keyboardType="phone-pad"
                  label={translate<string>('PHONE')}
                  onBlur={() => (
                    setTelephoneError(!!telephone
                      && !validatePhoneNumber(telephone))
                  )}
                  onChangeText={setTelephone}
                  status={telephoneError ? 'danger' : undefined}
                  testID="phone-field"
                />
                <VSpacer size="8" />
                {(userTypeVisible && (
                  <>
                    <Select
                      caption={translate<string>('REQUIRED')}
                      label={translate<string>('USER_TYPE')}
                      onSelect={onSelectUserType}
                      placeholder={translate<string>('SELECT_USER_TYPE')}
                      readonly={userTypeReadonly}
                      testID="user-type-selector"
                      value={userType && StringUtility.toProperCase(userType)}
                    >
                      {userTypes.map((kind, index) => (
                        <SelectItem key={kind} testID={`user-type-dropdown-value-${index}`} title={StringUtility.toProperCase(kind)} />
                      ))}
                    </Select>
                    <VSpacer size="8" />
                  </>
                ))}
                <Select
                  caption={translate<string>('REQUIRED')}
                  disabled={userRoleDisabled}
                  label={translate<string>('USER_ROLE')}
                  onSelect={(indexPath: IndexPath | IndexPath[]) => {
                    setUserRole(userRoleMap[userType][(indexPath as IndexPath).row]);
                  }}
                  placeholder={translate<string>('SELECT_USER_ROLE')}
                  readonly={userRoleReadonly}
                  testID="user-role-selector"
                  value={userRole && StringUtility.toProperCase(userRole)}
                >
                  {!userRoleMap[userType] ? null : userRoleMap[userType].map((role, index) => (
                    <SelectItem key={role} testID={`user-role-dropdown-value-${index}`} title={StringUtility.toProperCase(role)} />
                  ))}
                </Select>
                {showLocations && (
                  <>
                    <VSpacer size="8" />
                    <Select
                      label={translate<string>('LOCATION')}
                      multiSelect
                      onSelect={(indexPath: IndexPath | IndexPath[]) => {
                        // make sure we store the selected in the same order
                        // as the available
                        const indicies = (indexPath as IndexPath[]).map((ip) => ip.row);
                        indicies.sort();
                        setSelectedBusinessLocations(indicies.map((index) => (
                          availableLocations[index]
                        )));
                      }}
                      placeholder={translate<string>('ASSIGN_LOCATION')}
                      readonly={locationsReadonly}
                      selectedIndex={selectedBusinessLocations.map((location) => (
                        new IndexPath(availableLocations?.findIndex((bl) => (
                          bl.id === location.id
                        )))
                      ))}
                      testID="business-assignment-selector"
                      value={selectedBusinessLocations.length ? (textProps) => (
                        <>
                          <Chip
                            accessoryRight={
                              locationsReadonly && (
                                () => null
                              )
                            }
                            onPress={
                              !locationsReadonly
                                ? () => setSelectedBusinessLocations([])
                                : undefined
                            }
                            testID="business-assignment-selector-chip"
                          >
                            {selectedBusinessLocations.length}
                          </Chip>
                          <HSpacer size="2" />
                          <Text {...textProps} testID="business-assignment-selected">
                            {
                              selectedBusinessLocations.map((
                                (location) => location.locationName
                              )).join(', ')
                            }
                          </Text>
                        </>
                      ) : undefined}
                    >
                      {availableLocations.map((location, index) => (
                        <SelectItem key={location.id} testID={`business-location-dropdown-value-${index}`} title={location.locationName} />
                      ))}
                    </Select>
                  </>
                )}
                {(user.id === userId) && (
                  <>
                    <VSpacer size="8" />
                    <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                      <Button
                        appearance="ghost"
                        onPress={() => setShowPasswordForm(true)}
                        size="medium"
                        testID="password-button"
                      >
                        {translate<string>('CHANGE_YOUR_PASSWORD')}
                      </Button>
                    </View>
                  </>
                )}
              </ResponsiveView>
            </View>
            <ModalSpinner visible={loading} />
          </>,
        ]}
        subTitle={getSubTitle()}
        testID="user-form-large-modal"
        title={translate<string>(actionType === 'EDIT_USER' ? 'EDIT_USER' : 'CREATE_USER')}
        visible

      />
      {showPasswordForm && <ChangeUserPasswordForm onClose={() => setShowPasswordForm(false)} />}
    </>
  );
};
