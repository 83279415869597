import { HSpacer, VSpacer } from '@design';
import { OrderStatus } from '@shared/interfaces';
import { ApiProductOrder } from '@shared/interfaces/api';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  ComponentCalculatedQuantityMap,
} from './components/CalculateQuantity/CalculateQuantityDetails';
import {
  BasicInformationHeader,
} from './components/ProductOrderReviewCreate/BasicInformationHeader';
import { OrderSummary } from './components/ProductOrderReviewCreate/OrderSummary';
import { ProductList } from './components/ProductOrderReviewCreate/ProductList';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 36,
  },
  row: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 2,
  },
  rightContainer: {
    flex: 1,
  },
});

export interface ProductOrderReviewCreateProps {
  calculatedQuantities: ComponentCalculatedQuantityMap,
  disallowDeleteComponent?: boolean,
  disallowEditCalculateQuantity?: boolean,
  disallowNewCalculateQuantity?: boolean,
  onProductOrderChange: (productOrder) => void,
  onStatusSelected?: (status: OrderStatus) => void,
  onEditContacts?: () => void,
  onEditCropYear?: () => void,
  onViewNote?: () => void,
  onEditNote?: () => void,
  onEditPriceType?: () => void,
  onEditBusinessLocation?: () => void,
  onEditAcreage?: () => void,
  productOrder: ApiProductOrder,
  viewMode?: boolean,
}

export const ProductOrderReviewCreate: FC<ProductOrderReviewCreateProps> = ({
  calculatedQuantities,
  disallowDeleteComponent = false,
  disallowEditCalculateQuantity = false,
  disallowNewCalculateQuantity = false,
  onEditAcreage,
  onEditBusinessLocation,
  onEditContacts,
  onEditCropYear,
  onEditNote,
  onEditPriceType,
  onProductOrderChange,
  onStatusSelected,
  onViewNote,
  productOrder,
  viewMode,
}) => (
  <View style={styles.container}>
    <BasicInformationHeader
      onEditAcreage={onEditAcreage}
      onEditBusinessLocation={onEditBusinessLocation}
      onEditContacts={onEditContacts}
      onEditCropYear={onEditCropYear}
      onEditNote={onEditNote}
      onEditPriceType={onEditPriceType}
      onStatusSelected={onStatusSelected}
      onViewNote={onViewNote}
      productOrder={productOrder}
      viewMode={viewMode}
    />
    <VSpacer size="8" />
    <View style={styles.row}>
      <View style={styles.leftContainer}>
        <ProductList
          acreage={productOrder.acreage}
          calculatedQuantities={calculatedQuantities}
          components={productOrder.components}
          disallowDeleteComponent={disallowDeleteComponent}
          disallowEditCalculateQuantity={disallowEditCalculateQuantity}
          disallowNewCalculateQuantity={disallowNewCalculateQuantity}
          isDraft={productOrder.isDraft}
          locationId={productOrder.locationId}
          onProductsChange={(components, productMixComponents) => {
            onProductOrderChange({ ...productOrder, components, productMixComponents });
          }}
          priceTypeId={productOrder.priceTypeId}
          productMixComponents={productOrder.productMixComponents}
          viewMode={viewMode}
        />
      </View>
      <HSpacer size="7" />
      <View style={styles.rightContainer}>
        <OrderSummary
          acreage={productOrder.acreage}
          components={productOrder.components}
          discounts={productOrder.discounts}
          isDraft={productOrder.isDraft}
          locationId={productOrder.locationId}
          onDiscountsChange={(discounts) => {
            onProductOrderChange({ ...productOrder, discounts });
          }}
          priceTypeId={productOrder.priceTypeId}
          productMixComponents={productOrder.productMixComponents || []}
          viewMode={viewMode}
        />
      </View>
    </View>
  </View>
);
