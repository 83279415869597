import * as _ from 'lodash';
import React, { useState, useEffect, ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { TButtonStatus } from 'ui-components/components/StyledComponents';
import { Toast } from '@design';
import { ThemeColors, Sizing } from '../../constants';

const TOAST_DISMISS_TIMEOUT = 3000;

const styles = StyleSheet.create({
  toaster: {
    position: 'absolute',
    right: Sizing.BASE_SPACING,
    bottom: Sizing.BASE_SPACING,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  toast: {
    backgroundColor: ThemeColors.MID_GRAY,
    padding: Sizing.BASE_SPACING,
    marginTop: Sizing.HALF_SPACING,
    borderTopEndRadius: Sizing.HALF_SPACING,
    borderBottomEndRadius: Sizing.HALF_SPACING,
    width: Sizing.TOASTER_WIDTH,
    minHeight: 5 * Sizing.EM,
    borderLeftWidth: Sizing.QUARTER_SPACING,
  },
  info: { borderLeftColor: ThemeColors.INFO },
  success: { borderLeftColor: ThemeColors.SUCCESS },
  warning: { borderLeftColor: ThemeColors.WARNING },
  danger: { borderLeftColor: ThemeColors.DANGER },
  icon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
    marginLeft: -Sizing.QUARTER_SPACING,
    marginTop: -Sizing.QUARTER_SPACING,
    marginRight: Sizing.HALF_SPACING,
  },
  actionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginTop: Sizing.HALF_SPACING,
  },
  actionButton: {
    paddingHorizontal: Sizing.BASE_SPACING,
    marginRight: Sizing.HALF_SPACING,
    marginTop: Sizing.HALF_SPACING,
  },
  dismissButton: {

    backgroundColor: ThemeColors.WHITE,
  },
});

export interface IToastProps {
  actions?: {
    action?: () => Promise<void>,
    label: ReactNode,
    route?: string,
    status?: TButtonStatus,
  }[],
  children: any,
  icon?: string,
  iconPack?: string,
  onPress?: () => void,
  testID: string,
  toastId?: string,
  width?: number,
}

interface IInternalProps extends IToastProps {
  autoDismiss: false,
  status: 'info',
}

class TUtility {
  updateToasts: () => void = () => {};
  toasts: IToastProps[] = [];

  /**
   * Adds a new toast to the stack
   * @param props The toast props
   */
  private add = (props: IInternalProps) => {
    _.assignIn(props, {
      toastId: props.toastId || new Date().getTime().toString() + (Math.random() * 99999),
      status: props.status || 'info',
    });
    if (props.autoDismiss) {
      setTimeout(() => this.remove(props.toastId), TOAST_DISMISS_TIMEOUT);
      if (props.onPress) {
        this.remove(props.toastId);
      }
    }
    this.toasts.push(props);
    this.updateToasts();
  };

  private updateProps = (
    originalProps,
    statusType,
    allowAutoDismiss,
  ) => {
    const newProps: IInternalProps = originalProps;

    newProps.status = statusType;
    newProps.autoDismiss = allowAutoDismiss;

    return newProps;
  };

  success = (props: IToastProps) => {
    const newProps = this.updateProps(props, 'success', true);

    this.add(newProps);
  };

  info = (props: IToastProps) => {
    const newProps = this.updateProps(props, 'info', true);

    this.add(newProps);
  };

  warning = (props: IToastProps) => {
    const newProps = this.updateProps(props, 'warning', false);

    this.add(newProps);
  };

  error = (props: IToastProps) => {
    const newProps = this.updateProps(props, 'danger', false);

    this.add(newProps);
  };

  /**
   * Removes a toast from the stack
   * @param key The key of the toast
   */
  remove = (key: string) => {
    this.toasts = _.reject(this.toasts, { toastId: key });
    this.updateToasts();
  };
}

/**
 * @deprecated This has been deprecated in favor of the DS Toast
 * and ancillary functions/methods.  Please use useToast.
 */
export const ToasterUtility = new TUtility();

export const Toaster = () => {
  const [toasts, setToasts] = useState(ToasterUtility.toasts);

  useEffect(() => {
    ToasterUtility.updateToasts = () => {
      setToasts([...ToasterUtility.toasts]);
    };
  }, []);

  return (
    <View style={styles.toaster}>
      {toasts.map((props: IToastProps) => <Toast key={props.toastId} {...props} />)}
    </View>
  );
};
