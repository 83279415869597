import {
  Button,
  Card,
  DataPoint,
  Header,
  HSpacer,
  Icon,
  SidePanel,
  Text,
  useToast,
  VSpacer,
} from '@design';
import { CropLogicComponentCategory } from '@shared/enums';
import { ApiCropLogicPassComponent, ApiTankMixComponent, GrowerEndpoint } from '@shared/interfaces/api';
import { formatDate, Permissions, RoleUtility } from '@shared/utils';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { useBusinessLocation } from '../../../../../hooks/useBusinessLocation';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import { QueryKeys, Routes } from '../../../../../constants';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useHistory, useLocation, useParams } from '../../../../../router';
import { useQueryParams } from '../../../../../utilities';
import { GrowerApi, TankMixApi, UserApi } from '../../../../../utilities/api';
import { ComponentSelectPanel } from '../../../ComponentSelectPanel';
import { DuplicateModal } from './DuplicateModal';
import { EditModeFooter } from './EditModeFooter';
import { useTankMix } from './helpers';
import { TankMixComponentList } from './TankMixComponentList';
import { TankMixModal } from './TankMixModal';
import { SharedConfig } from '@shared/constants';
import { useStyleSheet } from '@ui-kitten/components';

export enum Mode { VIEW, EDIT, CREATE, DUPLICATE }

export const TankMixFormPage = () => {
  const [translate] = useTranslation(['prepare', 'tankMix', 'common', 'errors', 'growers', 'businesses', 'products']);
  const [scrollEnabled, setScrollEnabled] = useState(true);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [componentsSelectorPanelVisible, setComponentsSelectorPanelVisible] = useState(false);
  const { priceTypes } = usePriceTypeList();

  const queryClient = useQueryClient();
  const history = useHistory();

  const location = useLocation();
  const { id: tankMixId } = useParams<{ id: string }>();
  const { duplicate } = useQueryParams();
  const [isTableEditButtonDisabled, setIsTableEditButtonDisabled] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);

  const [mode, setMode] = useState<Mode>(() => {
    if (tankMixId) {
      if (/edit/.test(location.pathname)) {
        setIsTableEditButtonDisabled(true);
        return !duplicate ? Mode.EDIT : Mode.DUPLICATE;
      }
      return Mode.VIEW;
    }
    return Mode.CREATE;
  });

  const { user, currentBusinessId } = useAuthentication();
  const { createToast } = useToast();

  const styles = useStyleSheet({
    scrollView: {
      paddingBottom: 150 - 32, // 32 = wrapper padding bottom
    },
    list: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    backButton: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      maxWidth: 200,
      marginBottom: 32,
      marginHorizontal: 24,
    },
    dotStyle: {
      borderRadius: 3,
      height: 5,
      width: 5,
      backgroundColor: 'text-hint-color',
      marginLeft: 6,
      marginRight: 6,
      marginTop: 7,
    },
  });

  const {
    isLoading,
    isFetching,
    initialTankMix,
    tankMix,
    setTankMix,
  } = useTankMix({
    tankMixId,
    duplicate: !!duplicate,
    onError: () => createToast({
      children: translate<string>('UNEXPECTED_ERROR'),
      status: 'danger',
      testID: 'toast-content-element',
    }),
  });

  const { data: customers } = useQuery(
    [QueryKeys.GROWER_LIST, currentBusinessId, tankMix.assignedGrowers],
    async () => GrowerApi.getGrowers({
      assigned: false,
      businessId: currentBusinessId,
      ids: tankMix.assignedGrowers,
      limit: SharedConfig.MAX_PAGE_LIMIT,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }),
    {
      enabled: !!tankMix.assignedGrowers.length,
      onError: () => {
        createToast({
          children: translate<string>('ERROR_FETCH_GROWERS'),
          status: 'danger',
          testID: 'tankmix-modal-error-toast',
        });
      },
    },
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!duplicate) {
      const canShowModal = /create/.test(location.pathname);
      if (canShowModal) {
        setShowCreateModal(true);
      }
    }
  }, [isLoading, duplicate, location, setShowCreateModal]);

  useEffect(() => {
    if (tankMix.id && tankMix.name && duplicate) {
      setShowDuplicateModal(true);
    }
  }, [duplicate, tankMix]);

  const { data: userData } = useQuery(
    [QueryKeys.USER, tankMix.ownerBusinessUserId],
    () => UserApi.getUserById(tankMix.ownerBusinessUserId),
    { enabled: !!tankMix.ownerBusinessUserId },
  );

  const { businessLocation } = useBusinessLocation({
    businessLocationId: tankMix.businessLocationId,
  });

  const onDragEnd = useCallback(() => {
    setScrollEnabled(true);
  }, []);

  const updateComponents = useCallback((components: (
    ApiTankMixComponent | ApiCropLogicPassComponent)[]) => {
    setTankMix({ ...tankMix, components: components as ApiTankMixComponent[] });
  }, [tankMix, setTankMix]);

  const formValid = useMemo((): boolean => {
    const requiredFields = [
      tankMix.businessId,
      tankMix.ownerBusinessUserId,
      tankMix.name,
      tankMix.crops,
    ];

    const isFieldsValid = requiredFields.every((f) => !_.isNil(f) && !_.isEmpty(f));

    // all components require rate (numeric) and rateUom (string)
    const componentsIsValid = tankMix.components.every((component) => (
      !_.isNil(component.rate)
      && !_.isEmpty(component.rateUom)
    ));

    return isFieldsValid && (tankMix.components.length > 0) && componentsIsValid;
  }, [
    tankMix.businessId,
    tankMix.components,
    tankMix.crops,
    tankMix.name,
    tankMix.ownerBusinessUserId,
  ]);

  const goBack = useCallback(async () => {
    await queryClient.invalidateQueries(QueryKeys.TANK_MIX_LIST);

    history.push(
      Routes.PREPARE_REVIEW.replace(/:tab\?/, 'tank-mixes'),
    );
  }, [queryClient, history]);

  const viewMode = mode === Mode.VIEW;
  const canEditTankMix = (tankMix.isEditable && (
    RoleUtility.roleHasPermission(user.userRole, Permissions.MODIFY_OTHER_OWNER)
    || tankMix.ownerBusinessUserId === user.id)
  );

  const upsertTankMix = async (): Promise<boolean> => {
    try {
      if (!tankMix.id || mode === Mode.CREATE) {
        const res = await TankMixApi.createTankMix(tankMix);
        setTankMix(res, true);
      } else if (tankMix.isEditable && canEditTankMix) {
        delete tankMix.isActive;
        const res = await TankMixApi.updateTankMix(tankMix.id, tankMix);
        setTankMix(res, true);
      } else {
        const res = await TankMixApi.updateCustomers(tankMix.id, tankMix.assignedGrowers);
        setTankMix(res, true);
      }

      return true;
    } catch (error) {
      createToast({
        children: translate<string>('UNEXPECTED_ERROR'),
        status: 'danger',
        testID: 'toast-content-element',
      });
      return false;
    }
  };

  useEffect(() => {
    if (!!tankMix.businessId && tankMix.businessId !== currentBusinessId) {
      history.push(Routes.PREPARE_REVIEW.replace(/:tab\?/, 'tank-mixes'));
    }
  }, [tankMix.businessId, currentBusinessId, history]);

  const productMixCustomers = tankMix.assignedGrowers.map((grower) => {
    return customers?.data.find(c => c.id === grower)?.legalName;
  }).join(', ');

  const productMixStatus = translate(tankMix.isActive ? 'ACTIVE' : 'INACTIVE') as string;

  const addComponentsButton = (outlined: boolean) => {                   
    return (
      <Button
        accessoryLeft={(props) => <Icon name="Plus" testID="add-component-icon" {...props} />}
        appearance={outlined ? 'outline' : 'filled'}
        onPress={() => setComponentsSelectorPanelVisible(true)}
        size="medium"
        testID="add-component"
      >
        {translate<string>('ADD_COMPONENTS')}
      </Button>
    );
  };

  return (
    <>
      {showDuplicateModal && !isDuplicated && (
        <DuplicateModal
          name={tankMix.name}
          onClose={() => {
            setShowDuplicateModal(false);
            if (mode === Mode.DUPLICATE) {
              history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'tank-mixes'));
            }
          }}
          onUpdateName={(name) => {
            const { id, ...newTankMix } = tankMix;
            const ownerBusinessUserId = user.id;
            setTankMix({
              ...newTankMix,
              id: null,
              name,
              businessLocationId: null,
              ownerBusinessUserId,
              isEditable: true,
            });
            if (mode === Mode.VIEW) {
              setMode(Mode.CREATE);
            }
            setShowDuplicateModal(false);
            setIsDuplicated(true);
          }}
          saveNew={mode === Mode.VIEW}
        />
      )}
      {showCreateModal && (
        <TankMixModal
          editMode={mode === Mode.EDIT || (mode !== Mode.CREATE && !_.isEqual(initialTankMix, tankMix))}
          isReadOnlyExceptCustomers={!canEditTankMix}
          onCancel={(newTankMix) => {
            setShowCreateModal(false);
            if (mode === Mode.CREATE && _.isEqual(initialTankMix, newTankMix)) {
              history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'tank-mixes'));
            } else {
              setTankMix(newTankMix);
            }
          }}
          onSave={() => setShowCreateModal(false)}
          onTankMixChange={setTankMix}
          tankMix={tankMix}
        />
      )}
      <ScrollView
        scrollEnabled={scrollEnabled}
        style={styles.scrollView}
        testID="tank-mix-form-view"
      >
        <Text appearance="hint" category="overline">
          {translate<string>('TANK_MIX')}
        </Text>
        <VSpacer size="2" />
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text category="h3" testID="product-mix-header-name">

            {tankMix.name}
          </Text>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              accessoryLeft={() => <Icon name="Copy" testID="duplicate-copy-icon" /> }
              appearance="ghost"
              onPress={() => {
                setShowDuplicateModal(true);
                setIsDuplicated(false);
              }}
              size="medium"
              status="basic"
              testID="duplicate-button"
            >
              {translate('DUPLICATE')}
            </Button>
          </View>
        </View>
        {!!tankMix.components.length && mode !== Mode.CREATE && (
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <VSpacer size="2" />
            <Text appearance="hint" category="p2">
              {`${translate('LAST_UPDATED')} ${formatDate(tankMix.lastModified, 'MMM d, yyyy')}`}
            </Text>
            <View style={styles.dotStyle} />
            <Text appearance="hint" category="p2">
              {`${translate('CREATED')} ${formatDate(tankMix.createdAt, 'MMM d, yyyy')}`}
            </Text>
          </View>
        )}
        <VSpacer size="8" />
        <Header level="3" testID="details-header" title={translate('DETAILS')}>
        {(
          <Button
            accessoryLeft={(editProps) => (
              <Icon
                name="Edit"
                testID={mode === Mode.CREATE
                  ? 'create-details-button-icon' : 'edit-details-button-icon'}
                {...editProps}
              />
            )}
            appearance="outline"
            onPress={() => {
              setShowCreateModal(true);
              if (mode !== Mode.CREATE) setMode(Mode.EDIT);
            }}
            size="medium"
            status="basic"
            testID={mode === Mode.CREATE
              ? 'create-details-button' : 'edit-details-button'}
          >
            {translate<string>('EDIT')}
          </Button>
        )}
        </Header>
        <VSpacer size="5" />
        <Card testID="tank-mix-details-card">
          <View style={styles.list}>
            {/* Owner && Business Location */}
            <View>
              <DataPoint
                label={translate('OWNER')}
                testID="order-name"
              >
                {userData?.fullName ?? 'n/a'}
              </DataPoint>
              <VSpacer size="8" />
              <DataPoint
                label={translate<string>('BUSINESS_LOCATION')}
                testID="business-location"
              >
                {businessLocation?.locationName || translate('CORPORATE_LOCATION')}
              </DataPoint>
            </View>
            {/* Owner & Price type */}
            <View>
              <DataPoint
                label={translate('CUSTOMERS')}
                testID="customers"
              >
                {productMixCustomers || 'n/a'}
              </DataPoint>
              <VSpacer size="8" />
              {tankMix && priceTypes && (
                <DataPoint
                  label={translate('PRICE_TYPE')}
                  testID="price-type"
                >
                  {priceTypes.find(
                    (p) => p.id === tankMix.priceTypeId,
                  )?.name}
                </DataPoint>
              )}
              <View />
            </View>
            <View>
              <DataPoint
                label={translate('CROPS')}
                testID="crops"
              >
                {tankMix.crops.map(
                  ({
                    cropType,
                    cropSubType,
                  }) => `${translate(cropType)} | ${translate(cropSubType)}`,
                ).join(', ')}
              </DataPoint>
              <VSpacer size="8" />
              <DataPoint
                label={translate('STATUS')}
                testID="status"
              >
                {productMixStatus}
              </DataPoint>
            </View>
          </View>
        </Card>
        <VSpacer size="8" />
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Header level="3" testID="components-header" title={translate<string>('COMPONENTS')} />
          {!!tankMix.components.length && canEditTankMix &&
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                accessoryLeft={(accessoryLeftProps) => (
                  <Icon
                    name="Edit" 
                    testID='edit-components-button'
                    {...accessoryLeftProps}
                  />
                )}
                appearance="outline"
                disabled={(!!!initialTankMix.components.length && !!tankMix.components.length) || isTableEditButtonDisabled}
                onPress={() => {
                  setMode(Mode.EDIT);
                  setIsTableEditButtonDisabled(true);
                }}
                size="medium"
                status="basic"
                testID='edit-components-button'
              >
                {translate<string>('EDIT')}
              </Button>
              <HSpacer size="6" />
              {addComponentsButton(true)}
            </View>
          }
        </View>
        <VSpacer size="5" />
        {tankMix.businessId && (
          <Card style={{ paddingVertical: 0, paddingHorizontal: 0, paddingBottom: 0 }} testID="tank-mix-components-card">
            <TankMixComponentList
              components={tankMix.components}
              emptyListContent={() => 
                <View style={{ alignItems: 'center' }}>
                  {addComponentsButton(false)}
                </View>
              }
              isLoading={isFetching}
              locationId={tankMix.businessLocationId}
              onDragEnd={onDragEnd}
              onDragStart={() => {}}
              onUpdateComponents={!viewMode && canEditTankMix && isTableEditButtonDisabled && updateComponents}
              priceTypeId={tankMix.priceTypeId}
            />
          </Card>
        )}
        <>
          <SidePanel
            header={translate<string>('COMPONENTS')}
            onClose={() => setComponentsSelectorPanelVisible(false)}
            testID="tank-mix-sidepanel"
            visible={componentsSelectorPanelVisible}
          >
            <ComponentSelectPanel
              businessId={tankMix.businessId}
              components={tankMix.components}
              excludeCategory={[
                CropLogicComponentCategory.SEED,
                CropLogicComponentCategory.TANK_MIX,
              ]}
              locationId={tankMix.businessLocationId}
              onClose={() => setComponentsSelectorPanelVisible(false)}
              priceTypeId={tankMix.priceTypeId}
              programSavedComponents={[]}
              setPassComponents={(components) => {
                updateComponents(components);
                if (tankMix.id && mode === Mode.VIEW) {
                  setMode(Mode.EDIT);
                }
                setIsTableEditButtonDisabled(true);
              }}
            />
          </SidePanel>
        </>
      </ScrollView>
      {viewMode ? (
        <View style={styles.backButton}>
          <Button
            accessoryLeft={(backBtnProps) => (
              <Icon name="ArrowBack" testID="back-button-icon" {...backBtnProps} />
            )}
            appearance="outline"
            design="floating"
            onPress={goBack}
            size="large"
            status="basic"
            testID="back-button"
          >
            {translate<string>('BACK')}
          </Button>
        </View>
      ) : (
        <EditModeFooter
          confirmOnCancel={mode !== Mode.EDIT || !_.isEqual(initialTankMix, tankMix)}
          disabled={!formValid || _.isEqual(initialTankMix, tankMix)}
          editMode={mode === Mode.EDIT || (mode !== Mode.CREATE && !_.isEqual(initialTankMix, tankMix))}
          onCancel={() => {
            if (mode === Mode.DUPLICATE || mode === Mode.CREATE) {
              history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'tank-mixes'));
            } else {
              setTankMix(initialTankMix);
              setMode(Mode.VIEW);
              setIsTableEditButtonDisabled(false);
            }
          }}
          onSave={(!(_.isEqual(initialTankMix, tankMix)) && formValid) ? async () => {
            const done = await upsertTankMix();
            if (done) {
              let toastMessage = '';
              if (mode !== Mode.CREATE && mode !== Mode.DUPLICATE && !_.isEqual(initialTankMix, tankMix)) {
                toastMessage = translate<string>('PRODUCT_MIX_UPDATED');
                setMode(Mode.VIEW);
              } else {
                toastMessage = translate<string>(
                  mode === Mode.DUPLICATE ? 'PRODUCT_MIX_DUPLICATED' : 'PRODUCT_MIX_CREATED',
                );
                setMode(Mode.VIEW);
              }
              setIsTableEditButtonDisabled(false);

              createToast({
                status: 'success',
                children: toastMessage,
                testID: 'toast-content-element',
              });
            }
          } : undefined}
        />
      )}
    </>
  );
};
