import { ProductCategory } from '@shared/enums';
import {
  ApiProduct,
  ExportEndpoint,
  ImportEndpoint,
  PaginatedData,
  ProductEndpoint,
} from '@shared/interfaces/api';
import qs from 'qs';
import { CSVResponse } from '../helpers/exportCSV';
import { Client, ClientOptions } from './Client';

export class ProductApi {
  static create (
    businessId: string,
    product: ProductEndpoint.Create.Request,
  ): Promise<ApiProduct> {
    return Client(`businesses/${businessId}/products`, {
      method: 'POST', body: product,
    });
  }

  static get (
    businessId: string,
    productId: string,
  ): Promise<ApiProduct> {
    return Client(`businesses/${businessId}/products/${productId}`);
  }

  static getPricing (
    filters: ProductEndpoint.Pricing.Query,
  ): Promise<ProductEndpoint.Pricing.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma' });
    return Client(`products/pricing?${query}`);
  }

  static list (
    filters: ProductEndpoint.List.Query,
  ): Promise<PaginatedData<ApiProduct>> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`products?${query}`);
  }

  static update (
    businessId: string,
    id: string,
    updates: ProductEndpoint.Update.Request,
  ): Promise<ApiProduct> {
    return Client(`businesses/${businessId}/products/${id}`, {
      method: 'PATCH',
      body: updates,
    });
  }

  /**
   * Exports existing products in API
   * */
  static async exportProducts (
    filters: ExportEndpoint.ProductExport.Query,
  ): Promise<CSVResponse> {
    const query = qs.stringify(filters, { arrayFormat: 'comma' });
    return Client(`export/products?${query}`);
  }

  static async getCsvTemplate (
    businessId: string,
    category: ProductCategory,
  ): Promise<CSVResponse> {
    let endpoint: string;
    switch (category) {
      case ProductCategory.SEED:
        endpoint = 'seedProducts'; break;
      case ProductCategory.FERTILIZER:
        endpoint = 'fertilizerProducts'; break;
      case ProductCategory.CHEMICAL:
        endpoint = 'chemicalProducts'; break;
      case ProductCategory.OTHER:
        endpoint = 'otherProducts'; break;
      default:
        return null;
    }

    return Client(`export/template/${endpoint}?businessId=${businessId}`, { method: 'GET' });
  }

  static async importProducts (
    fileInfo: any,
    category: ProductCategory,
    businessId: string,
  ): Promise<ImportEndpoint.ImportResult> {
    const body = { csv: fileInfo.uri };
    const options: ClientOptions = {
      body,
      method: 'POST',
    };

    switch (category) {
      case ProductCategory.SEED:
        return Client(`import/seedProducts?businessId=${businessId}`, options);
      case ProductCategory.FERTILIZER:
        return Client(`import/fertilizerProducts?businessId=${businessId}`, options);
      case ProductCategory.CHEMICAL:
        return Client(`import/chemicalProducts?businessId=${businessId}`, options);
      case ProductCategory.OTHER:
        return Client(`import/otherProducts?businessId=${businessId}`, options);
      default:
        return null;
    }
  }
}
