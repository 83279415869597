import { Button, HSpacer, Icon, LargeModal, NotesModal, ResponsiveView, useBanner } from '@design';
import { Mode } from '@design/NotesModal/NotesModal';
import { OrderStatus } from '@shared/interfaces';
import {
  ApiProduct,
  ApiProductOrder,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  ApiUserAccount,
  ProductOrderEndpoint,
} from '@shared/interfaces/api';
import _ from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAppContext } from '../../../contexts/AppContext';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { ProductOrderApi } from '../../../utilities/api/ProductOrderApi';
import {
  CustomerMissingRequestFieldsModal,
} from '../../components/shared/IntegrationOrder/CustomerMissingRequireFieldsModal';
import {
  PrimaryContactMissingModal,
} from '../../components/shared/IntegrationOrder/PrimaryContactMissingModal';
import {
  ProductsMissingModal,
} from '../../components/shared/IntegrationOrder/ProductsMissingModal';
import {
  SalesPersonMissingModal,
} from '../../components/shared/IntegrationOrder/SalesPersonMissingModal';
import { EditAcreageDialog } from './components/Acreage/EditAcreageDialog';
import { ConfirmSaveDialog } from './components/ConfirmSaveDialog/ConfirmSaveDialog';
import { CreateDeliverableModal } from './components/CreateDeliverableModal/CreateDeliverableModal';
import { ExitButton } from './components/ExitButton/ExitButton';
import { NoteButton } from './components/Note/NoteButton';
import { calculateQuantities, handleAcceptedStatus, useProductOrder } from './helpers';
import { ProductOrderBasicDetails } from './ProductOrderBasicDetails';
import { ProductOrderReviewCreate } from './ProductOrderReviewCreate';
import { ProductOrderSelectProducts } from './ProductOrderSelectProducts';
import { ProductOrderSetProductDetails } from './ProductOrderSetProductDetails';

const styles = StyleSheet.create({
  actionButtons: { display: 'flex', flexDirection: 'row' },
});

export enum ProductOrderPage {
  BASIC_DETAILS = 0,
  SELECTED_PRODUCTS = 1,
  SET_PRODUCT_DETAILS = 2,
  REVIEW_CREATE_ORDER = 3,
}

export interface ProductOrderModalProps {
  addProductsMode?: boolean,
  duplicate?: boolean,
  initialPage?: ProductOrderPage,
  onClose: (event?) => Promise<void>,
  productOrderId?: string,
  showContactDesignation?: boolean,
  updatedComponents?: ApiProductOrderComponent[],
  updatedProductMixComponents?: ApiProductOrderProductMixComponent[],
}

const ProductOrderModal: FC<ProductOrderModalProps> = ({
  addProductsMode = false,
  duplicate,
  initialPage,
  onClose,
  productOrderId,
  showContactDesignation = false,
  updatedComponents,
  updatedProductMixComponents,
}) => {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState<number>(
    initialPage ?? (!addProductsMode ? ProductOrderPage.BASIC_DETAILS : ProductOrderPage.SELECTED_PRODUCTS),
  );

  const [initialUpdatedComponents] = useState(
    _.cloneDeep(updatedComponents),
  );
  const [initialUpdatedProductMixComponents] = useState(
    _.cloneDeep(updatedProductMixComponents),
  );
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [saveConfirmationDialogVisible, setSaveConfirmationDialogVisible] = useState(false);
  const [createDeliverableModalVisible, setCreateDeliverableModalVisible] = useState(false);
  const [showProductsMissingModal, setShowProductsMissingModal] = useState(false);
  const [productsMissing, setProductsMissing] = useState<ApiProduct[]>([]);
  const [showSalesPersonMissingModal, setShowSalesPersonMissingModal] = useState(false);
  const [salespersonMissing, setSalespersonMissing] = useState<ApiUserAccount>();
  const [showPrimaryContactMissingModal, setShowPrimaryContactMissingModal] = useState(false);
  const [showCustomerFieldsMissingModal, setShowCustomerFieldsMissingModal] = useState(false);
  const { showBanner } = useBanner();
  const { setModalProps } = useAppContext();

  const {
    productOrder,
    setProductOrder,
    calculatedQuantities,
    setCalculatedQuantities,
  } = useProductOrder({
    productOrderId,
    updatedComponents,
    updatedProductMixComponents,
    duplicate,
    onError: () => showBanner(translate('ERROR_FETCH_PRODUCT_ORDER')),
  });

  const [noteVisible, setNoteVisible] = useState(false);
  const [acreageVisible, setAcreageVisible] = useState(false);

  // Form Value Validation
  useEffect(() => {
    // TODO: when we are in page three
    //  we should also run the other validation for lower pages (second and first)
    let isValid: boolean;
    switch (currentPage) {
      case ProductOrderPage.BASIC_DETAILS: {
        isValid = !!(productOrder.growerId && productOrder.name?.trim());
        break;
      }
      case ProductOrderPage.SELECTED_PRODUCTS:
      case ProductOrderPage.SET_PRODUCT_DETAILS: {
        isValid = productOrder.components.length > 0 || productOrder.productMixComponents?.length > 0;
        break;
      }
      case ProductOrderPage.REVIEW_CREATE_ORDER: {
        isValid = productOrder.components.length > 0 || productOrder.productMixComponents?.length > 0;
        break;
      }

      default: isValid = false;
    }

    setIsNextDisabled(!isValid);
  }, [currentPage, productOrder]);

  const updateCalculatedQuantities = (upsertedProductOrder: ApiProductOrder) => {
    if (Object.keys(calculatedQuantities).length === 0) return;
    const updatedCalculatedQuantities = calculateQuantities(
      upsertedProductOrder,
      upsertedProductOrder.priceTypeId,
    );
    setCalculatedQuantities(updatedCalculatedQuantities);
  };

  const upsertProductOrder = async (): Promise<boolean> => {
    try {
      const possibleAcceptedProductOrder = handleAcceptedStatus(productOrder);
      const { id, ...newProductOrder } = possibleAcceptedProductOrder;

      let order: ApiProductOrder;
      const componentsLength = newProductOrder.components.length
        + (newProductOrder.productMixComponents?.length ?? 0);

      if (duplicate && !componentsLength) {
        const apiOrder = await ProductOrderApi.duplicateProductOrder(id, newProductOrder);
        order = {
          ...apiOrder,
          billingContactId: newProductOrder.billingContactId,
          cropYear: newProductOrder.cropYear,
          note: newProductOrder.note,
          priceTypeId: newProductOrder.priceTypeId,
          shippingContactId: newProductOrder.shippingContactId,
        };
        updateCalculatedQuantities(order);
      } else if (!duplicate && !id) {
        order = await ProductOrderApi.createProductOrder(
          newProductOrder as ProductOrderEndpoint.Create.Request,
        );
        updateCalculatedQuantities(order);
      } else {
        order = await ProductOrderApi.updateProductOrder(
          id,
          newProductOrder as ProductOrderEndpoint.Update.Request,
        );
      }
      setProductOrder(order, true);

      return true;
    } catch (error) {
      if (error instanceof DetailedApiError
        && error.details.details.error === 'products-missing-dynamics') {
        setShowProductsMissingModal(true);
        setProductsMissing(error.details.details.products);
      } else if (error instanceof DetailedApiError
        && error.details.details.error === 'salesperson-not-found') {
        setShowSalesPersonMissingModal(true);
        setSalespersonMissing(productOrder.owner);
      } else if (error instanceof DetailedApiError
        && error.details.details.error === 'primary-contact-missing') {
        setShowPrimaryContactMissingModal(true);
      } else if (error instanceof DetailedApiError
        && error.details.details.error === 'missing-required-customer-fields') {
        setShowCustomerFieldsMissingModal(true);
      } else {
        showBanner(translate('ERROR_SAVING_QQ'));
      }
      return false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pages = [
    !addProductsMode && (
      <ResponsiveView large={4} medium={6} small={6} style={{ alignSelf: 'center' }} xLarge={4} xSmall={4}>
        <ProductOrderBasicDetails
          onProductOrderChange={setProductOrder}
          productOrder={productOrder}
          showContactDesignation={showContactDesignation}
        />
      </ResponsiveView>
    ),
    <ProductOrderSelectProducts
      onProductOrderChange={setProductOrder}
      productOrder={productOrder}
    />,
    <ResponsiveView large={10} medium={10} small={8} style={{ alignSelf: 'center' }} xLarge={10} xSmall={4}>
      <ProductOrderSetProductDetails
        calculatedQuantities={calculatedQuantities}
        onCalculatedQuantitiesChange={(quantities) => {
          setCalculatedQuantities(quantities);
        }}
        onProductOrderChange={setProductOrder}
        productOrder={productOrder}
      />
    </ResponsiveView>,
    !addProductsMode && (
      <ProductOrderReviewCreate
        calculatedQuantities={calculatedQuantities}
        disallowEditCalculateQuantity={!productOrder.isDraft}
        onEditAcreage={() => setAcreageVisible(true)}
        onEditNote={() => setNoteVisible(true)}
        onProductOrderChange={setProductOrder}
        onStatusSelected={(status) => setProductOrder({ ...productOrder, status })}
        productOrder={productOrder}
      />
    ),
  ];

  const isFirstPage = !addProductsMode
    ? currentPage === ProductOrderPage.BASIC_DETAILS
    : currentPage === ProductOrderPage.SELECTED_PRODUCTS;
  const isLastPage = useMemo(
    () => currentPage === pages.length - (!addProductsMode ? 1 : 2),
    [addProductsMode, currentPage, pages],
  );

  const title = useMemo(() => {
    if (productOrderId) {
      return translate(duplicate ? 'DUPLICATE_PRODUCT_ORDER' : 'EDIT_PRODUCT_ORDER');
    }

    return translate('CREATE_PRODUCT_ORDER');
  }, [productOrderId, duplicate, translate]);

  const sectionHeading = useMemo(() => {
    if (currentPage === ProductOrderPage.BASIC_DETAILS) {
      return translate('BASIC_DETAILS_HEADING');
    }
    if (currentPage === ProductOrderPage.SELECTED_PRODUCTS) {
      return translate('SELECT_PRODUCTS_HEADING');
    }
    if (currentPage === ProductOrderPage.SET_PRODUCT_DETAILS) {
      return translate('SET_PRODUCT_DETAILS_HEADING');
    }
    if (currentPage === ProductOrderPage.REVIEW_CREATE_ORDER) {
      return translate('REVIEW_CREATE_ORDER_HEADING');
    }
    return '';
  }, [currentPage, translate]);

  const sectionSubHeading = useMemo(() => {
    if (currentPage === ProductOrderPage.BASIC_DETAILS) {
      return translate('BASIC_DETAILS_SUBTITLE');
    }
    if (currentPage === ProductOrderPage.SELECTED_PRODUCTS) {
      return translate('SELECT_PRODUCTS_SUBTITLE');
    }
    if (currentPage === ProductOrderPage.SET_PRODUCT_DETAILS) {
      return translate('SET_PRODUCT_DETAILS_SUBTITLE');
    }
    if (currentPage === ProductOrderPage.REVIEW_CREATE_ORDER) {
      return translate('REVIEW_CREATE_ORDER_SUBTITLE');
    }
    return '';
  }, [currentPage, translate]);

  const goToPreviousPage = async () => {

    if (addProductsMode) {
      setIsPrevDisabled(true);
      setIsNextDisabled(false);
      setCurrentPage(currentPage - 1);
      return;
    }

    const updateCurrentPage = await upsertProductOrder();

    if (isFirstPage) {
      setIsPrevDisabled(true);
    } else {
      setIsNextDisabled(false);
      if (updateCurrentPage) {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const launchCreateAndShareDeliverableFlow = async () => {
    if (productOrder.status === OrderStatus.ACCEPTED) {
      setSaveConfirmationDialogVisible(true);
    } else {
      setCreateDeliverableModalVisible(true);
    }
  };

  const callToActionOnPress = async () => {
    if (addProductsMode) {
      if (isLastPage) {
        setModalProps({
          type: 'viewProductOrder',
          editMode: true,
          productOrderId,
          updatedComponents: productOrder.components,
          updatedProductMixComponents: productOrder.productMixComponents,
        });
      } else {
        setIsPrevDisabled(false);
        setIsNextDisabled(true);
        setCurrentPage(currentPage + 1);
      }
      return;
    }

    const updateCurrentPage = await upsertProductOrder();

    if (isLastPage) {
      await launchCreateAndShareDeliverableFlow();
    } else {
      setIsPrevDisabled(false);
      setIsNextDisabled(true);
      if (updateCurrentPage) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const ActionButton = () => (
    <View style={styles.actionButtons}>
      {!isFirstPage && (
        <>
          <Button
            accessoryLeft={(props) => (
              <Icon
                {...props}
                name="ArrowBack"
                testID="product-order-back-button-icon"
              />
            )}
            appearance="outline"
            design="floating"
            disabled={isPrevDisabled}
            onPress={goToPreviousPage}
            size="large"
            status="basic"
            testID="product-order-back-button"
          >
            {(translate<string>('BACK'))}
          </Button>
          <HSpacer size="8" />
        </>
      )}
      <Button
        accessoryRight={(props) => !isLastPage && (
          <Icon
            {...props}
            name="ArrowForward"
            testID="product-order-action-button-icon"
          />
        )}
        design="floating"
        disabled={isNextDisabled}
        onPress={callToActionOnPress}
        size="large"
        testID="product-order-action-button"
      >
        {!addProductsMode ? (
          translate<string>(isLastPage ? 'CREATE_AND_SHARE_DELIVERABLE' : 'NEXT')
        ) : (
          translate<string>(isLastPage ? 'ADD_PRODUCTS' : 'NEXT')
        )}
      </Button>
    </View>
  );

  return (
    <>
      {noteVisible && (
        <NotesModal
          mode={productOrder.note ? Mode.EDIT : Mode.ADD}
          note={productOrder.note ?? ''}
          onClose={() => setNoteVisible(false)}
          onDelete={() => setProductOrder({ ...productOrder, note: null })}
          setNote={(note) => setProductOrder({ ...productOrder, note })}
        />
      )}
      <EditAcreageDialog
        acreage={productOrder.acreage ?? 0}
        onAcreageChange={(acreage) => {
          setProductOrder({ ...productOrder, acreage });
          setAcreageVisible(false);
        }}
        onCancel={() => setAcreageVisible(false)}
        visible={acreageVisible}
      />
      { createDeliverableModalVisible && (
        <CreateDeliverableModal
          onBeforeCreateDeliverable={async () => {
            productOrder.isDraft = false;
            const upserted = await upsertProductOrder();
            queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST);
            if (!upserted) {
              productOrder.isDraft = true;
            }
            return upserted;
          }}
          onClose={async () => {
            setCreateDeliverableModalVisible(false);
          }}
          productOrderId={productOrder.id}
        />
      )}
      <ConfirmSaveDialog
        onCancel={() => setSaveConfirmationDialogVisible(false)}
        onSave={async () => {
          setSaveConfirmationDialogVisible(false);
          setCreateDeliverableModalVisible(true);
        }}
        visible={saveConfirmationDialogVisible}
      />
      <LargeModal
        currentPage={currentPage}
        footer={(
          <>
            <View style={{
              position: 'absolute',
              left: 40,
              bottom: 40,
            }}
            >
              {!addProductsMode ? (
                <ExitButton
                  onClose={onClose}
                  onSave={upsertProductOrder}
                  productOrder={productOrder}
                />
              ) : (
                <Button
                  appearance="outline"
                  design="floating"
                  onPress={() => {
                    setModalProps({
                      type: 'viewProductOrder',
                      editMode: true,
                      productOrderId,
                      updatedComponents: initialUpdatedComponents,
                      updatedProductMixComponents: initialUpdatedProductMixComponents,
                    });
                  }}
                  size="large"
                  status="basic"
                  testID="product-order-button-cancel"
                >
                  {translate<string>('CANCEL')}
                </Button>
              )}

            </View>
            <View style={{
              position: 'absolute',
              right: 40,
              bottom: 40,
            }}
            >
              <ActionButton />
            </View>
          </>
        )}
        headerButton={!isLastPage && (
          <NoteButton
            note={productOrder.note}
            onPress={() => setNoteVisible(true)}
          />
        )}
        pages={pages}
        sectionHeading={sectionHeading}
        sectionSubHeading={sectionSubHeading}
        showSteps={!addProductsMode}
        testID="product-order-modal"
        title={!addProductsMode
          ? title
          : translate<string>('ADD_MORE_PRODUCTS')
        }
        visible
      />
      {showProductsMissingModal &&
        <ProductsMissingModal
          onClose={() => setShowProductsMissingModal(false)}
          products={productsMissing}
          visible
        />
      }
      {showSalesPersonMissingModal &&
        <SalesPersonMissingModal
          onClose={() => setShowSalesPersonMissingModal(false)}
          salesperson={salespersonMissing}
          visible
        />
      }
      {showPrimaryContactMissingModal &&
        <PrimaryContactMissingModal
          onClose={() => setShowPrimaryContactMissingModal(false)}
          visible
        />
      }
      {showCustomerFieldsMissingModal &&
        <CustomerMissingRequestFieldsModal
          onClose={() => setShowCustomerFieldsMissingModal(false)}
          visible
        />
      }
    </>
  );
};

export default ProductOrderModal;
