import { Input, useToast } from '@design';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../../constants';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { GrowerFarmPlanApi } from '../../../../../utilities/api';
import { useDebouncedValue } from '../../../../../hooks/useDebouncedValue';

export interface FarmPlanNameInputProps {
  farmPlanId?: string,
  name: string,
  onUpdateName(name: string): void,
  onUpdateValidate(valid: boolean): void,
  valid: boolean,
}

export const FarmPlanNameInput = ({
  farmPlanId,
  name,
  onUpdateName,
  onUpdateValidate,
  valid,
}: FarmPlanNameInputProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const { currentBusinessId } = useAuthentication();
  const { createToast } = useToast();
  const [showError, setShowError] = useState(false);
  const [nameInput, setNameInput] = useState(name);
  const debouncedName = useDebouncedValue(nameInput);

  useQuery([QueryKeys.FARM_PLAN_LIST, debouncedName], () => (
    GrowerFarmPlanApi.listFarmPlans({
      businessId: currentBusinessId,
      planName: debouncedName.trim(),
    })
  ), {
    enabled: !!debouncedName.length,
    onSuccess: ({ data }) => {
      setShowError(true);
      if ((!farmPlanId ? data : data.filter(({ id }) => id !== farmPlanId)).length === 0) {
        onUpdateValidate(true);
      } else {
        onUpdateValidate(false);
      }
    },
    onError: () => {
      createToast(translate('UNEXPECTED_ERROR'));
    },
  });

  const handleChangeText = useCallback((text: string) => {
    if (valid) {
      onUpdateValidate(true);
      setShowError(false);
    }

    setNameInput(text);
    onUpdateName(text);
  }, [onUpdateName, onUpdateValidate, valid]);

  return (
    <Input
      caption={(!valid && showError) && translate<string>(
        'FARM_PLAN_ALREADY_EXISTS',
        { name: name.trim() },
      )}
      isRequired
      label={translate<string>('NAME')}
      onChangeText={handleChangeText}
      status={(valid || !showError) ? 'basic' : 'danger'}
      testID="farm-plan-name-field"
      value={nameInput}
    />
  );
};
