import { Input, useToast } from '@design';
import { ApiCropLogic } from '@shared/interfaces/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { CropLogicApi } from '../../../../utilities/api';

export interface ProgramNameInputProps {
  assignedPrograms?: ApiCropLogic[],
  canUseInitialName?: boolean,
  name: string,
  onUpdateName(name: string): void,
  onUpdateValidate(valid: boolean): void,
  programId?: string,
  valid: boolean,
  verifyDatabase?: boolean,
}

export const ProgramNameInput = ({
  assignedPrograms = [],
  canUseInitialName = true,
  name,
  onUpdateName,
  onUpdateValidate,
  programId,
  valid,
  verifyDatabase = true,
}: ProgramNameInputProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const { currentBusinessId } = useAuthentication();
  const { createToast } = useToast();
  const [showError, setShowError] = useState(false);
  const [nameInput, setNameInput] = useState(name);
  const initialNameRef = useRef(name);

  const assignedProgramsRef = useRef<ApiCropLogic[]>();
  useEffect(() => {
    assignedProgramsRef.current = assignedPrograms;
  }, [assignedPrograms]);

  const validateProgramName = useCallback(async (programName: string) => {
    onUpdateValidate(false);

    programName = programName.trim().toLowerCase();

    if (programName === '') {
      setShowError(false);
      return;
    }

    const sameNameValid = (
      canUseInitialName
      || initialNameRef.current.trim().toLowerCase() !== programName
    );

    const assignedNameExists = assignedProgramsRef.current.some(({ id, logicName }) => (
      id !== programId && logicName.toLowerCase() === programName
    ));

    if (verifyDatabase) {
      if (sameNameValid && !assignedNameExists) {
        try {
          setShowError(false);

          const { data: cropLogicList } = await CropLogicApi.listCropLogic({
            businessId: currentBusinessId,
            logicName: [programName],
          });

          const filteredPrograms = !programId
            ? cropLogicList
            : cropLogicList.filter(({ id }) => id !== programId);

          onUpdateValidate(filteredPrograms.length === 0);
        } catch (err) {
          createToast(translate('UNEXPECTED_ERROR'));
        }
      }
    } else {
      onUpdateValidate(sameNameValid && !assignedNameExists);
    }

    setShowError(true);
  }, [
    canUseInitialName,
    createToast,
    currentBusinessId,
    onUpdateValidate,
    programId,
    translate,
    verifyDatabase,
  ]);

  const debouncedValidateProgamName = useMemo(() => {
    return debounce(validateProgramName, 300);
  }, [validateProgramName]);

  const handleChangeText = useCallback(async (text: string) => {
    await debouncedValidateProgamName(text);
    setNameInput(text);
    onUpdateName(text);
  }, [debouncedValidateProgamName, onUpdateName]);

  useEffect(() => {
    validateProgramName(initialNameRef.current);
  }, [validateProgramName]);

  return (
    <Input
      caption={(!valid && showError) && translate<string>(
        'PROGRAM_NAME_ALREADY_EXISTS',
        { name: name.trim() },
      )}
      isRequired
      label={translate<string>('PROGRAM_NAME')}
      onBlur={() => handleChangeText(nameInput)}
      onChangeText={handleChangeText}
      status={(valid || !showError) ? 'basic' : 'danger'}
      testID="program-name-field"
      value={nameInput}
    />
  );
};
