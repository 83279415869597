/* eslint-disable max-len */
import React, { FunctionComponent, useState, useCallback } from 'react';
import { IndexPath } from '@ui-kitten/components';
import {
  Select,
  SelectItem,
  Button,
  IconButton,
  Input,
  Text,
  TextLink,
  Modal,
  HSpacer,
  CheckBox,
  useGlobalBanner,
  SidePanel,
} from '@design';
import { BannerStatus, BannerStatuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const GlobalBannerDemo: FunctionComponent = () => {
  const {
    globalBannerVisible,
    setGlobalBannerProps,
    clearGlobalBanner,
  } = useGlobalBanner();
  const [selectedIndex, setSelectedIndex] = useState<IndexPath | IndexPath[]>(new IndexPath(0));
  const [bannerText, setBannerText] = useState<string>('Global banner messages goes here');
  const [actionText, setActionText] = useState<string>('Action');
  const [completeVisible, setCompleteVisible] = useState(false);
  const [emptySidePanelVisible, setEmptySidePanelVisible] = useState(false);
  const [useCloseButton, setUseCloseButton] = useState<boolean>(false);

  const getValue = (options: string[], selected: IndexPath | IndexPath[]) => {
    if (!selected) {
      return '';
    }
    if (Array.isArray(selected)) {
      return selected.map((s) => options[s.row]).join(', ');
    }
    return options[selected.row];
  };

  const toggleBanner = useCallback(() => {
    if (globalBannerVisible) {
      clearGlobalBanner();
    } else {
      setGlobalBannerProps({
        title: bannerText,
        status: getValue(BannerStatuses, selectedIndex) as BannerStatus,
        action: !!actionText && ((props) => <TextLink {...props}>{actionText}</TextLink>),
        accessoryRight: useCloseButton && (
          ({ iconButtonProps }) => <IconButton {...iconButtonProps} onPress={clearGlobalBanner} testID="test-button">Close</IconButton>
        ),
        testID: 'global-banner-demo',
      });
    }
  }, [globalBannerVisible, actionText, bannerText, selectedIndex, clearGlobalBanner, setGlobalBannerProps, useCloseButton]);

  return (
    <>
      <DemoContainer label="Global Banner" style={{ alignItems: 'center' }}>
        <DemoBlock>
          <Input
            label="Banner text"
            onChangeText={setBannerText}
            testID="test-input"
            value={bannerText}
          />
        </DemoBlock>
        <DemoBlock>
          <Input
            label="Action text"
            onChangeText={setActionText}
            testID="test-input"
            value={actionText}
          />
        </DemoBlock>
        <DemoBlock>
          <Select
            label="Banner Status"
            onSelect={setSelectedIndex}
            placeholder="Default"
            selectedIndex={selectedIndex}
            testID="global-banner-demo-status-selector"
            value={getValue(BannerStatuses, selectedIndex)}
          >
            {
              BannerStatuses.map((v, i) => <SelectItem key={v} testID={`global-banner-demo-status-dropdown-value-${i}`} title={v} />)
            }
          </Select>
        </DemoBlock>
        <DemoBlock>
          <CheckBox
            checked={useCloseButton}
            onChange={() => setUseCloseButton((prev) => !prev)}
            testID="test-checkbox"
          >
            Close button
          </CheckBox>
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock>
          <Button appearance="outline" onPress={toggleBanner} testID="test-button">Toggle banner</Button>
        </DemoBlock>
        <DemoBlock>
          <Button appearance="outline" onPress={() => setCompleteVisible(!completeVisible)} testID="test-button">
            Open Modal
          </Button>
          <Modal
            footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
              <>
                <Button {...primaryButtonProp} testID="test-button">Cancel</Button>
                <HSpacer {...spacerProp} />
                <Button {...secondaryButtonProp} testID="test-button">Cancel</Button>
              </>
            )}
            onClose={() => setCompleteVisible(false)}
            subTitle="Subtitle"
            testID="test-modal"
            title="Title"
            visible={completeVisible}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Modal>
        </DemoBlock>
        <DemoBlock>
          <Button appearance="outline" onPress={() => { setEmptySidePanelVisible(true); }} testID="test-button">
            Open Side Panel
          </Button>
          <SidePanel
            onClose={() => { setEmptySidePanelVisible(false); }}
            testID="sidepanel"
            visible={emptySidePanelVisible}
          />
        </DemoBlock>
      </DemoContainer>
    </>
  );
};
