export type Size = 'tiny' | 'small' | 'medium' | 'large' | 'giant';
export const Sizes: Size[] = [
  'tiny',
  'small',
  'medium',
  'large',
  'giant',
];

export type InputSize = 'small' | 'medium' | 'large';
export const InputSizes: InputSize[] = [
  'small',
  'medium',
  'large',
];

export type StepIndicatorSize = 'small' | 'medium';

export type SearchSize = 'medium' | 'large';
export const SearchSizes: SearchSize[] = ['medium', 'large'];

export type SpacerSize = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14';
export const SpacerSizes: SpacerSize[] = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14',
];

export type RectangleSize = 'tiny-rectangle' | 'small-rectangle' | 'medium-rectangle' | 'large-rectangle' | 'giant-rectangle';

export const RectangleSize: RectangleSize[] = ['tiny-rectangle', 'small-rectangle', 'medium-rectangle', 'large-rectangle', 'giant-rectangle'];
