import { ApiUserAccount } from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import { Reports } from '@shared/enums';

const reportPermissionMap: { [key in Reports]: Permissions } = {
  [Reports.AcceptedReport]: Permissions.MODIFY_BUSINESS_OBJECTS,
  [Reports.BusinessPricingReport]: Permissions.MODIFY_BUSINESS_OBJECTS,
  [Reports.SalesInsightReport]: Permissions.MODIFY_BUSINESS_OBJECTS,
};

export const canSeeReports = (user: ApiUserAccount) => {
  const hasPermissions = RoleUtility.roleHasSomePermissions(
    user.userRole, Object.values(reportPermissionMap),
  );
  return hasPermissions;
};

type TCanSeeReport = (user: ApiUserAccount, reportName: Reports) => boolean;
/**
* @deprecated Use permission checking instead
* */
export const canSeeReport: TCanSeeReport = (user, reportName) => {
  const hasPermission = RoleUtility.roleHasPermission(
    user.userRole,
    reportPermissionMap[reportName],
  );

  return hasPermission;
};
