export enum QueryKeys {
  ASSIGNED_USERS = 'assigned_users',
  BUSINESS_CATEGORIES = 'business_categories',
  BUSINESS_CROPS = 'business_crops',
  BUSINESS_DETAILS = 'business_details',
  BUSINESS_DISCOUNTS = 'business_discounts',
  BUSINESS_LOCATION = 'business_location',
  BUSINESS_LOCATION_LIST = 'business_locations',
  BUSINESS_LOGO = 'business-logo',
  BUSINESS_PRODUCT_DATA = 'business_product',
  BUSINESS_PRODUCT_PRICING = 'business_product_pricing',
  BUSINESS_TAGS = 'business_tags',
  BUSINESS_USERS = 'business_users',
  CHART_SALES_FUNNEL_LOCATION = 'sales_funnel_location_chart',
  CHEMICAL_LIST = 'chemical_products_list',
  COMPANY_REVENUE = 'company_revenue',
  COUNTRIES = 'countries',
  CROP_YEARS = 'crop_years',
  CUSTOMER = 'customer',
  CUSTOMER_FARM_LIST = 'farm_list',
  CUSTOMER_LIST = 'customer_list',
  CUSTOMER_PLANNING_PARAMETERS = 'customer_planning_parameters',
  DELIVERABLE = 'deliverable',
  DELIVERABLE_LIST = 'deliverables',
  FARM_PLAN = 'farm_plan',
  FARM_PLAN_LIST = 'farm_plan_list',
  FERTILIZER_LIST = 'fertilizer_products_list',
  FULFILLMENT_LOCATION_LIST = 'fulfillment_location_list',
  GROWER = 'grower',
  GROWER_ASSIGNMENTS = 'growerAssignments',
  GROWER_CONTACTS = 'growerContacts',
  GROWER_LIST = 'growers',
  INTEGRATION = 'integration',
  OTHER_LIST = 'other_products_list',
  PRICE_TYPE_LIST = 'price_type_list',
  PRODUCT_FILTERS = 'product-filters',
  PRODUCT_ORDER = 'product_order',
  PRODUCT_ORDER_FILTERS = 'product_order_filters',
  PRODUCT_ORDER_LIST = 'product_order_list',
  PRODUCT_PRICING = 'product_pricing',
  PRODUCT_SUMMARIES = 'product_summaries',
  PROGRAM = 'program',
  PROGRAM_LIST = 'program_list',
  PROGRAM_LIST_BUSINESS_CROPS = 'program_list_business_crops',
  PROPERTY_GROUP = 'property_group',
  PROPERTY_GROUP_LIST = 'property_group_list',
  SEED_LIST = 'seed_products_list',
  TANK_MIX = 'tank_mix',
  TANK_MIX_BUSINESS_CROPS = 'tank_mix_business_crops',
  TANK_MIX_LIST = 'tank_mix_list',
  USER = 'user',
  USER_LIST = 'user_list',
}
