import { FalsyFC, FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { styled, StyledComponentProps, StyleType } from '@ui-kitten/components/theme';
import React, { FC, ReactElement, ReactNode } from 'react';
import { View, ViewProps } from 'react-native';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { testId } from '../../../../utilities/testId';
import { IconButtonProps } from '../IconButton/IconButton';
import {
  DescriptionListDirection,
  DescriptionListProps,
} from '../../shared/DescriptionList/DescriptionList';
import { VSpacer } from '../Spacer';
import { Text } from '../Text/Text';

export interface ListItemProps extends ViewProps, StyledComponentProps {
  title: ReactNode;
  sublineText?: string;
  sublineDataPoints?: RenderProp<DescriptionListProps>;
  iconButtons?: RenderProp<Partial<IconButtonProps>>;
}

const getComponentStyle = (style: StyleType) => {
  const itemContainerStyles = PropsServiceHelper.allWithPrefixMapped(style, 'itemContainer');
  const iconButtonsContainerStyles = PropsServiceHelper
    .allWithPrefixMapped(style, 'iconButtonsContainer');
  const iconButtonStyles = PropsServiceHelper.allWithPrefixMapped(style, 'iconButton');
  const sublineTextStyles = PropsServiceHelper.allWithPrefixMapped(style, 'sublineText');

  return {
    itemContainer: itemContainerStyles,
    iconButtonsContainer: iconButtonsContainerStyles,
    iconButton: iconButtonStyles,
    sublineText: sublineTextStyles,
  };
};

const ListItemRaw: FC<ListItemProps> = ({
  title,
  sublineText,
  sublineDataPoints,
  iconButtons,
  eva,
  testID,
}) => {
  const evaStyle = getComponentStyle(eva.style);

  return (
    <View style={evaStyle.itemContainer}>
      <View style={{ flex: 1 }}>
        <VSpacer size="5" />
        {typeof title === 'string' ? (
          <Text category="s1" testID="entity-list-item-title">
            {title}
          </Text>
        ) : title}
        {(!!sublineText || !!sublineDataPoints) && (
          <>
            <VSpacer size="3" />
            {sublineText ? (
              <FalsyText
                {...testId(testID, 'subline-text')}
                category="p2"
                component={sublineText}
                style={evaStyle.sublineText}
              />
            ) : (
              <FalsyFC
                {...testId(testID, 'subline-data-points')}
                component={sublineDataPoints as RenderProp<Partial<ReactElement>>}
                direction={DescriptionListDirection.INLINE}
                size="medium"
              />
            )}
          </>
        )}
        <VSpacer size="6" />
      </View>
      <View style={evaStyle.iconButtonsContainer} {...testId('icon-buttons-container')}>
        <FalsyFC
          appearance="ghost"
          component={iconButtons as RenderProp<Partial<ReactElement>>}
          size="small"
          status="basic"
          style={evaStyle.iconButton}
        />
      </View>
    </View>
  );
};

export const ListItem: FC<ListItemProps> = styled('List')(ListItemRaw);
