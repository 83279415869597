import { Button, Dialog, HSpacer, Text, useToast } from '@design';
import { ApiCropLogic, ApiCropLogicListItem } from '@shared/interfaces/api';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CropLogicApi } from '../../../../utilities/api';

interface ChangeProgramStatusProps {
  program?: ApiCropLogicListItem,
  onCancel: () => void,
  onProgramUpdated: (updatedProgram: ApiCropLogic) => void,
}

export const ChangeProgramStatusDialog: FC<ChangeProgramStatusProps> = (
  { program, onCancel, onProgramUpdated },
) => {
  const [translate] = useTranslation(['common', 'programs']);
  const { createToast } = useToast();

  const updateProgram = useCallback(async () => {
    const { id, isActive } = program;
    try {
      const updatedProgram = await CropLogicApi.updateCropLogic(id, { isActive: !isActive });
      onProgramUpdated(updatedProgram);
      createToast({
        status: 'success',
        children: translate<string>('PROGRAM_UPDATED'),
        testID: 'toast-content-element',
      });
    } catch (err) {
      createToast({
        children: (
          <>
            <Text>{translate<string>('PROGRAM_ERROR')}</Text>
            <Text>{(err as Error).message}</Text>
          </>
        ),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
  }, [createToast, onProgramUpdated, program, translate]);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button testID="cancel" {...primaryButtonProp}>{translate<string>('CANCEL')}</Button>
          <HSpacer {...spacerProp} />
          <Button testID="submit" {...secondaryButtonProp} onPress={updateProgram}>
            {translate<string>(!program?.isActive ? 'ACTIVATE' : 'DEACTIVATE')}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="deactivate-dialog"
      visible={!!program}
    >
      <Text>
        {translate<string>(program?.isActive
          ? 'ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_THIS_PROGRAM'
          : 'ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE_THIS_PROGRAM')}
      </Text>
    </Dialog>
  );
};
