import React, { Component } from 'react';
import { View, ViewProps } from 'react-native';
import {
  styled,
  StyledComponentProps,
} from '@ui-kitten/components/theme';
import { SpacerSize } from '@theme/variant-interfaces/Size';

export interface SpacerProps extends ViewProps, StyledComponentProps {
  size?: SpacerSize;
}

@styled('Spacer')
export class HSpacer extends Component<SpacerProps> {
  public render () {
    const {
      eva,
      style,
      ...viewProps
    } = this.props;

    return (
      <View
        {...viewProps}
        style={[{ width: eva.style.pixels }, style]}
      />
    );
  }
}
