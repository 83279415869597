import { SharedConfig } from '@shared/constants';
import { ApiGrowerContact, GrowerContactEndpoint, PaginatedData } from '@shared/interfaces/api';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { GrowerApi } from '../utilities/api';

const placeholderData: PaginatedData<ApiGrowerContact> = {
  data: [],
  total: 0,
  lastPage: 0,
  page: 0,
};

export const useCustomerContacts = (customerId: string) => {
  const queryClient = useQueryClient();

  const invalidateContacts = () => {
    return queryClient.invalidateQueries([QueryKeys.GROWER_CONTACTS, customerId]);
  };

  const getCustomerContacts = async () => {
    return GrowerApi.getGrowerContacts(
      customerId,
      {
        sort: GrowerContactEndpoint.List.Sort.isPrimary,
        limit: SharedConfig.MAX_PAGE_LIMIT,
        sortDesc: true,
      },
    );
  };

  const { isLoading, data, error } = useQuery([QueryKeys.GROWER_CONTACTS, customerId],
    getCustomerContacts, {
      enabled: !!customerId,
      placeholderData,
      staleTime: 5 * 60 * 1000,
    });

  return {
    ...data,
    data: data?.data || [],
    error,
    invalidateContacts,
    isLoading,
  };
};
