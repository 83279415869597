import { SharedConfig } from '@shared/constants';
import React, { FunctionComponent, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Modal, Button, HSpacer, Text, CheckBox, useBanner, BannerProps, Filter, CenteredSpinner } from '@design';
import { useQuery } from 'react-query';
import { ApiUserAccount, PaginatedData } from '@shared/interfaces/api';
import { UserApi } from '../../../utilities/api';
import { QueryKeys } from '../../../constants/QueryKeys';
import { IAssignedUser } from './CustomerModal';

const styles = StyleSheet.create({
  userRow: { flexDirection: 'row', paddingVertical: 12 },
  userOptions: { paddingHorizontal: 16, top: -40 },
  userRows: { height: 305 },
  checkbox: { marginLeft: 6 },
  loading: { justifyContent: 'center', alignItems: 'center' },
});

export interface CustomerAssignUsersModalProps {
  businessId: string,
  isVisible: boolean,
  assignedUsers?: IAssignedUser[],
  onClose?: () => void,
  onApply?: (selections: IAssignedUser[]) => void,
}
export const CustomerAssignUsersModal: FunctionComponent<CustomerAssignUsersModalProps> = ({
  businessId,
  assignedUsers = [],
  isVisible,
  onClose,
  onApply,
}) => {
  const [translate] = useTranslation(['growers', 'common', 'users']);
  const [selectedUsers, setSelectedUsers] = useState<IAssignedUser[]>(assignedUsers);
  const [newSearch, setNewSearch] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [search, setSearch] = useState<string>('');
  const { createBanner } = useBanner();
  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) });

  /**
   * Returns all the business users
   * @returns users for this business
   */
  const getBusinessUsers = () => UserApi
    .getUsers({
      search,
      page: 0,
      limit: SharedConfig.MAX_PAGE_LIMIT,
      businessId,
    });

  const {
    data: userData,
  } = useQuery<PaginatedData<ApiUserAccount>, Error>(
    [QueryKeys.USER, businessId, newSearch, search],
    getBusinessUsers, {
      onError: () => {
        createBanner(bannerProps(translate('USER_LOAD_ERROR')));
        setNewSearch(false);
      },
      onSuccess: () => {
        setUsersLoaded(true);
        setNewSearch(false);
      },
    },
  );

  const toggleChecked = (id: string, fullName: string) => {
    const userIdIndex = selectedUsers.findIndex((user) => id === user.id);
    const newSelectedUsers = [...selectedUsers];
    if (userIdIndex !== -1) {
      newSelectedUsers.splice(userIdIndex, 1);
    } else {
      newSelectedUsers.push({ id, fullName });
    }
    setSelectedUsers(newSelectedUsers);
  };

  const applyUserAssignments = () => {
    onApply(selectedUsers);
  };

  const onChangeText = (query) => {
    const newQuery = query ?? '';
    setSearch(newQuery);
    setNewSearch(true);
    setUsersLoaded(false);
  };

  const RenderUsers = () => (
    <>
      {!usersLoaded && (
      <View style={styles.loading}>
        <CenteredSpinner />
      </View>
      )}
      { (usersLoaded && !!userData?.data.length)
        ? userData.data.map((user) => (
          <View key={`${user.id}-assignUserRow`} style={styles.userRow}>
            <CheckBox
              checked={selectedUsers.some((u) => u.id === user.id)}
              onChange={() => toggleChecked(user.id, user.fullName)}
              style={styles.checkbox}
              testID="selected-user-checkbox"
            />
            <HSpacer size="4" />
            <Text category="p1">
              {user.fullName}
            </Text>
          </View>
        )) : (
          <View style={styles.loading}>
            <Text>{translate<string>('NO_USERS')}</Text>
          </View>
        )}
    </>
  );

  const ModalFooter = ({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
    <>
      <Button
        testID="assign-user-modal-cancel"
        {...primaryButtonProp}
        onPress={onClose}
      >
        {`${translate('CANCEL')}`}
      </Button>
      <HSpacer {...spacerProp} />
      <Button
        testID="assign-user-modal-apply"
        {...secondaryButtonProp}
        onPress={applyUserAssignments}
      >
        {`${translate('APPLY')}`}
      </Button>
    </>
  );

  return (
    <Modal
      footerAccessory={ModalFooter}
      hideCloseButton
      testID="customer-assign-users-modal"
      title={translate('ASSIGN_USERS')}
      visible={isVisible}
    >
      <View style={styles.userOptions}>
        <Filter
          defaultColumnFilters={[]}
          filterOptions={[]}
          noFilters
          onUpdateFilter={(filter) => onChangeText(filter.search)}
          testID="assign-user-modal-filter"
        />
        <ScrollView style={styles.userRows}>
          <RenderUsers />
        </ScrollView>
      </View>
    </Modal>
  );
};
