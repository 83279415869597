import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { HSpacer, Logo, CenteredSpinner } from '@design';
import { useStyleSheet } from '@ui-kitten/components';
import { useHistory, useParams } from '../../router';
import { QueryKeys, Sizing, ThemeColors } from '../../constants';
import { useLogo } from '../../hooks/useLogo';
import { GrowerDeliverableApi } from '../../utilities/api';
import { PoweredByGrowersLogo } from '../../svg/PoweredByGrowersLogo';

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    height: Sizing.HEADER_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: Sizing.DOUBLE_SPACING,
    position: 'absolute',
    backgroundColor: ThemeColors.HEADER,
    overflow: 'hidden',
  },
  flexLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  logosContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

export const PublicHeader = () => {
  const history = useHistory();
  const { deliverableId } = useParams<{ deliverableId: string }>();
  const [user, setUser] = useState(null);
  const getUser = async () => GrowerDeliverableApi.getPublicGrowerDeliverable(deliverableId);
  useQuery([QueryKeys.DELIVERABLE, deliverableId], getUser, {
    onSuccess: (res) => setUser(res.user),
  });
  const lineStyle = useStyleSheet({
    line: {
      borderWidth: StyleSheet.hairlineWidth,
      borderRightColor: 'text-hint-color',
      height: 48,
    },
  });

  const { logo, error, isLoading } = useLogo(user?.businessId);

  return (
    <View style={styles.header}>
      <View style={styles.flexLeft}>
        {isLoading && <CenteredSpinner />}
        <TouchableOpacity onPress={() => history.push('/')}>
          {logo && (
            <View style={styles.logosContainer}>
              <Logo
                shape="square"
                size="large-rectangle"
                source={logo}
                testID="business-logo"
              />
              <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
                <HSpacer size="4" style={lineStyle.line} />
              </View>
              <HSpacer size="7" />
              <PoweredByGrowersLogo testID="powered-by-growers-logo" />
            </View>
          )}
          {(!logo || error) && <PoweredByGrowersLogo testID="powered-by-growers-logo" />}
        </TouchableOpacity>
      </View>
    </View>
  );
};
