/* eslint-disable react/jsx-no-bind */
import React from 'react';
import {
  GestureResponderEvent,
  NativeSyntheticEvent,
  TargetedEvent,
  ImageProps,
} from 'react-native';
import {
  RenderProp,
  FalsyFC,
  Overwrite,
  LiteralUnion,
} from '@ui-kitten/components/devsupport';
import {
  Interaction,
  styled,
  StyleType,
} from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Text, TextProps } from '../Text/Text';

export interface TextLinkProps extends Overwrite<Omit<TextProps, 'status'>, {
  appearance?: LiteralUnion<'default' | 'primary' | 'secondary'>;
}> {
  disabled?: boolean;
  accessoryLeft?: RenderProp<Partial<ImageProps>>;
  accessoryRight?: RenderProp<Partial<ImageProps>>;

  // react native Text TS definitions doesn't support mouse enter or mouse leave
  // but it seems to work on web if supplied, so we are defining them here
  onMouseEnter?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
  onMouseLeave?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
}

interface TextLinkState {
  hovered: boolean;
}

type TextLinkElement = React.ReactElement<TextLinkProps>;

@styled('TextLink')
export class TextLink extends React.Component<TextLinkProps, TextLinkState> {
  private onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([Interaction.HOVER]);
    const { onMouseEnter = () => {} } = this.props;
    onMouseEnter(event);
  };

  private onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([]);
    const { onMouseLeave = () => {} } = this.props;
    onMouseLeave(event);
  };

  private onPress = (event: GestureResponderEvent): void => {
    if (this.props.disabled) return;
    const { onPress = () => {} } = this.props;
    onPress(event);
  };

  private getComponentStyle (style: StyleType) {
    const textStyles = PropsServiceHelper.allWithPrefixMapped(style, 'text');
    const iconStyles = PropsServiceHelper.allWithPrefixMapped(style, 'icon');
    const webIconStyles = PropsServiceHelper.allWithPrefixMapped(style, 'webIcon');
    const rightIconStyles = PropsServiceHelper.allWithPrefixMapped(style, 'rightIcon');
    const leftIconStyles = PropsServiceHelper.allWithPrefixMapped(style, 'leftIcon');
    return {
      text: textStyles,
      icon: [
        iconStyles,
        webIconStyles,
      ],
      rightIcon: rightIconStyles,
      leftIcon: leftIconStyles,
    };
  }

  public render (): TextLinkElement {
    const {
      eva,
      style,
      accessoryLeft,
      accessoryRight,
      children,
      appearance, // don't pass this to text.  we are overriding it in TextLink
      onPress, // strip out to prevent when disabled
      onMouseEnter, // strip out to prevent when disabled
      onMouseLeave, // strip out to prevent when disabled
      testID,
      nativeID,
      ...textProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <Text
        onPress={this.onPress}
        {...{
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
        }}
        {...textProps}
        style={[evaStyle.text, style]}
      >
        <FalsyFC
          component={accessoryLeft}
          style={[evaStyle.icon, evaStyle.leftIcon]}
        />
        <Text
          {...textProps}
          nativeID={nativeID}
          style={[evaStyle.text, style]}
          testID={testID}
        >
          {children}
        </Text>
        <FalsyFC
          component={accessoryRight}
          style={[evaStyle.icon, evaStyle.rightIcon]}
        />
      </Text>
    );
  }
}
