import { useRef } from 'react';

const wait = 300;

export const useDebounce = (rate?: number) => {
  const busy = useRef(false);

  const debounce = async <T extends () => void>(callback: T) => {
    setTimeout(() => {
      busy.current = false;
    }, rate || wait);

    if (!busy.current) {
      busy.current = true;
      callback();
    }
  };

  return { debounce };
};
