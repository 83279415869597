import React, { FC } from 'react';
import { GestureResponderEvent } from 'react-native';
import {
  ModalProps as UIKModalProps,
} from '@ui-kitten/components';
import { HSpacer, Text, Button, Dialog } from '@design';

interface ConfirmationModalProps extends UIKModalProps {
  cancelText: string,
  confirmText?: string,
  disabled?: boolean,
  messageText?: string,
  onCancel: (event: GestureResponderEvent) => void,
  onConfirm: (event: GestureResponderEvent) => void,
  status?: 'danger' | 'warning',
  title?: string,
  visible: boolean,
  width?: number,
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  cancelText,
  confirmText,
  disabled,
  messageText,
  onCancel,
  onConfirm,
  status,
  title,
  visible,
  width = 480,
}: ConfirmationModalProps) => (
  <Dialog
    footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
      <>
        <Button
          testID="cancel-button"
          {...primaryButtonProp}
          appearance="outline"
          onPress={onCancel}
        >
          {cancelText}
        </Button>
        {confirmText && (
          <>
            <HSpacer {...spacerProp} />
            <Button
              disabled={disabled}
              testID="confirm-button"
              {...secondaryButtonProp}
              onPress={onConfirm}
            >
              {confirmText}
            </Button>
          </>
        )}
      </>
    )}
    status={status}
    testID="confirmation-dialog"
    title={title}
    visible={visible}
    width={width}
  >
    <Text>
      {messageText}
    </Text>
  </Dialog>
);
