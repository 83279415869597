import { ProductUtilities } from '@shared/utils';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { FilterTable, Icon, Search, Text, TextLink, VSpacer, HSpacer } from '@design';
import { FarmPlanDeliverableV2 as V2 } from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { useTranslation } from 'react-i18next';
import { IColumn, RowMeta } from '../../components/SortableTable';
import { StringUtility } from '../../../utilities';
import { ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  cropColor: {
    width: 16,
    height: 20.3,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
});

const INITIAL_NUMBER_OF_ROWS = 3;

export interface DeliverableFieldSummaryProps {
  selections: V2.Selections,
  zoneSummaries: V2.Snapshot['areas'],
}

export const FarmPlanDeliverablePerFieldSummary = ({
  selections,
  zoneSummaries,
}: DeliverableFieldSummaryProps): JSX.Element => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(INITIAL_NUMBER_OF_ROWS);

  const fieldCount = useMemo(() => (
    new Set(zoneSummaries.map(({ farmName, fieldName }) => `${farmName}-${fieldName}`)).size
  ), [zoneSummaries]);

  const getRows = useCallback((searchStr: string = '') => {
    if (!selections || !zoneSummaries) {
      return [];
    }

    const summaries = zoneSummaries.map((zoneSummary) => ({
      ...zoneSummary,
      key: [
        zoneSummary.farmName,
        zoneSummary.fieldName,
        zoneSummary.cropZoneName,
        zoneSummary.passName,
        zoneSummary.productSkuName,
        zoneSummary.passNumber,
        zoneSummary.cropType,
        zoneSummary.cropSubType,
      ].join(' '),
    }));

    let lastFarmName = '';
    let lastFieldName = '';
    let lastZoneName = '';

    const newRows = summaries.map((zoneSummary) => {
      if (lastFarmName !== zoneSummary.farmName) {
        lastFieldName = '';
        lastZoneName = '';
      }

      const columns = {
        ...((searchStr || lastFarmName !== zoneSummary.farmName)
        && {
          farmName: zoneSummary.farmName,
          farmArea: zoneSummary.farmArea,
        }),
        ...((searchStr || lastFieldName !== zoneSummary.fieldName) && ({
          fieldName: zoneSummary.fieldName,
          fieldArea: zoneSummary.fieldArea,
        })),
        ...(!!zoneSummary.cropZoneName
          && (searchStr || lastZoneName !== zoneSummary.cropZoneName)
          && ({
            cropZoneName: zoneSummary.cropZoneName,
            cropZoneArea: zoneSummary.cropZoneArea,
          })),
        ...(selections.fieldDetails.cropType
          && !!zoneSummary.cropType && ({
          cropType: zoneSummary.cropType,
          cropSubType: zoneSummary.cropSubType,
          cropColor: zoneSummary.cropColor,
        })),
        ...(selections.fieldDetails.passName && ({
          passName: zoneSummary.passName,
        })),
        ...({
          productName: zoneSummary.productName,
          productSkuName: zoneSummary.productSkuName,
        }),
        ...(selections.fieldDetails.rateApplied && ({
          rateApplied: zoneSummary.rateApplied,
          rateAppliedUom: zoneSummary.rateAppliedUom,
        })),
        ...(selections.fieldDetails.costPerAcre && ({
          costPerAcre: zoneSummary.costPerAcre,
        })),
        ...(selections.fieldDetails.unitsRequired && ({
          unitsRequired: zoneSummary.unitsRequired,
          unitsRequiredUom: zoneSummary.unitsRequiredUom,
        })),
      };

      const row = {
        key: zoneSummary.key,
        rowId: zoneSummary.key,
        ...columns,
      };

      lastFarmName = zoneSummary.farmName;
      lastFieldName = zoneSummary.fieldName;
      lastZoneName = zoneSummary.cropZoneName || zoneSummary.fieldName;

      return row;
    });

    if (!searchStr) {
      return newRows;
    }

    return newRows.filter((row) => (
      new RegExp(`.*${searchStr}.*`, 'ig').test(row?.key)
    ));
  }, [selections, zoneSummaries]);

  useEffect(() => {
    setRows(getRows());
  }, [getRows]);

  const resetSearch = useCallback(() => {
    setSearch('');
    setRows(getRows());
  }, [getRows]);

  const searchFieldDetails = useCallback((searchStr) => {
    setSearch(searchStr);
    setRows(getRows(searchStr));
  }, [getRows]);

  const onSelect = useCallback((index) => {
    setSearch(rows.map((val) => ({ name: val.key }))[index].name);
  }, [rows]);

  const getSearchOptions = (fieldRows: typeof rows[number]) => (
    fieldRows.map((val) => ({ name: val.key })));

  const showMoreHandler = () => {
    if (!showDetails) {
      setNumberOfRows(rows.length);
    } else {
      setNumberOfRows(INITIAL_NUMBER_OF_ROWS);
    }
    setShowDetails(!showDetails);
  };

  const columns: IColumn<(V2.AreaSummary & RowMeta)>[] = [
    {
      columnId: 'farm-name',
      header: { render: translate('FARM') },
      render: (zs) => zs.farmName && (
        <View style={{ flexWrap: 'wrap' }}>
          <Text category="p2">{zs.farmName}</Text>
          <Text category="p2">
            {` (${StringUtility.formatDecimal(zs.farmArea)} ac)`}
          </Text>
        </View>
      ),
      flex: 1.5,
    },
    {
      columnId: 'field-name',
      header: { render: translate('FIELD') },
      render: (zs) => zs.fieldName && (
        <View style={{ flexWrap: 'wrap' }}>
          <Text category="p2">{zs.fieldName}</Text>
          <Text category="p2">
            {`(${StringUtility.formatDecimal(zs.fieldArea, 0)} ac)`}
          </Text>
        </View>
      ),
      flex: 1.5,
    },
    {
      columnId: 'crop-zone-name',
      header: { render: translate('CROP_ZONE_SENTENCE') },
      render: (zs) => zs.cropZoneName && (
        <View style={{ flexWrap: 'wrap' }}>
          <Text category="p2">{zs.cropZoneName}</Text>
          <Text category="p2">
            {`(${StringUtility.formatDecimal(zs.cropZoneArea, 0)} ac)`}
          </Text>
        </View>
      ),
      flex: 1.5,
    },
    (selections.fieldDetails.cropType && {
      columnId: 'crop-type',
      header: { render: translate('CROP') },
      render: (zs) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <View style={styles.cropColor}>
            <View style={{ width: 8, height: 8, borderRadius: 8, backgroundColor: zs.cropColor }} />
          </View>
          <Text category="p2">
            {`${translate(zs.cropType)} | ${translate(zs.cropSubType)}`}
          </Text>
        </View>
      ),
      flex: selections.fieldDetails.cropType && 1.5,
    }),
    (selections.fieldDetails.passName && {
      columnId: 'field-details',
      header: { render: translate('PASS') },
      render: (zs) => zs.passName,
      flex: selections.fieldDetails.passName && 1,
    }),
    {
      columnId: 'product-sku-name',
      header: { render: translate('PRODUCT') },
      render: (zs) => (
        <View>
          <Text category="p2" style={{ maxWidth: 120 }}>
            {ProductUtilities.buildProductName(
              {
                name: zs.productName,
                productSkuName: zs.productSkuName,
              },
              selections.options?.name,
              selections.options?.skuName,
            )}
          </Text>
        </View>
      ),
      flex: 1.5,
    },
    (selections.fieldDetails.costPerAcre && {
      columnId: 'cost-per-acre',
      header: {
        render: `${translate('APPLICATION_COST_AC')}`,
        align: 'left',
      },
      render: (zs) => StringUtility.formatCurrency(zs.costPerAcre ?? 0),
      flex: selections.fieldDetails.costPerAcre && 1,
      align: 'left',
    }),
    (selections.fieldDetails.rateApplied && {
      columnId: 'rate-applied',
      header: {
        render: `${translate('RATE_PER_ACRE')}`,
        align: 'left',
      },
      render: (zs) => `${StringUtility.formatDecimal(zs.rateApplied, 3)}`
      + ` ${translate(zs.rateAppliedUom)}`,
      flex: selections.fieldDetails.rateApplied && 1,
      align: 'left',
    }),
    (selections.fieldDetails.unitsRequired && {
      columnId: 'units-required',
      header: {
        render: (
          <View style={{ flexWrap: 'wrap', flex: 1 }}>
            <Text category="p2">
              {translate('UNITS_REQUIRED') as string}
            </Text>
          </View>
        ),
      },
      render: (zs) => `${StringUtility.formatDecimal(zs.unitsRequired, 2)}`
      + ` ${translate(zs.unitsRequiredUom)}`,
      flex: 1.5,
    }),
  ];

  return (
    <View>
      <VSpacer size="10" />
      <VSpacer size="3" />
      <VSpacer size="1" />
      <View style={styles.headerContainer}>
        <Text category="h6">{`${translate('FIELD_DETAILS')}`}</Text>
      </View>
      <VSpacer size="5" />
      <Search
        onChangeText={(searchTerm) => searchFieldDetails(searchTerm)}
        onClear={resetSearch}
        onSelect={onSelect}
        options={getSearchOptions(rows)}
        size="medium"
        testID="farm-plan-deliverable-field-details-search"
        value={search}
      />
      <VSpacer size="8" />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text category="p2">
          {
          fieldCount === 1
            ? `${fieldCount} ${translate('FIELD')}`
            : `${fieldCount} ${translate('FIELDS')}`
          }
        </Text>
        {rows.length > INITIAL_NUMBER_OF_ROWS && (
          <View style={{ flexDirection: 'row' }}>
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={showMoreHandler}
              testID="show-details-link"
            >
              {showDetails ? `${translate('COLLAPSE_ALL')}`
                : `${translate('SHOW_ALL')}`}
            </TextLink>
            <HSpacer size="2" />
            <Icon
              fill={ThemeColors.SECONDARY}
              name={!showDetails ? 'ChevronDown' : 'ChevronUp'}
              size="medium"
              testID="show-details-icon"
            />
          </View>
        )}
      </View>
      <VSpacer size="5" />
      <FilterTable
        columns={columns}
        currentPage={0}
        data={rows}
        defaultColumnFilters={[]}
        expanderChevronStatus="primary"
        filterOptions={[]}
        footerRow={rows.length > INITIAL_NUMBER_OF_ROWS && (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <VSpacer size="7" />
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={showMoreHandler}
              testID="show-details-link"
            >
              {showDetails ? `${translate('COLLAPSE_ALL')}`
                : `${translate(rows.length - numberOfRows === 1
                  ? 'MORE_ROW' : 'MORE_ROWS', { count: rows.length - numberOfRows })}`}
            </TextLink>
            <VSpacer size="8" />
          </View>
        )}
        noFilters
        numberOfRows={numberOfRows}
        onPageChange={() => { }}
        onUpdateFilter={() => { }}
        testID="farm-plan-deliverable-per-field-sumamry-table"
      />
    </View>
  );
};
