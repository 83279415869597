import React, { useState } from 'react';
import { Button, HSpacer, Modal } from '@design';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { BusinessLocationSelect } from '../../../../components/shared/Input/BusinessLocationSelect';

const styles = StyleSheet.create({
  footer: {
    paddingTop: 40,
    paddingLeft: 0,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export interface BusinessLocationModalProps {
  initialLocationId: string | null,
  onUpdateLocation(locationId: string | null): void,
  onCancel(): void,
}

export const BusinessLocationModal = ({
  initialLocationId,
  onUpdateLocation,
  onCancel,
}: BusinessLocationModalProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const [locationId, setLocationId] = useState(initialLocationId);

  const renderFooterAccessory = ({
    primaryButtonProp,
    secondaryButtonProp,
    spacerProp,
  }) => (
    <>
      <Button
        {...primaryButtonProp}
        onPress={onCancel}
        testID='business-location-modal-cancel-button'
      >
        {translate<string>('CANCEL')}
      </Button>
      <HSpacer {...spacerProp} />
      <Button
        {...secondaryButtonProp}
        disabled={initialLocationId === locationId}
        onPress={() => onUpdateLocation(locationId)}
        testID='business-location-modal-update-button'
      >
        {translate<string>('UPDATE')}
      </Button>
    </>
  );

  return (
    <Modal
      footerAccessory={renderFooterAccessory}
      footerStyle={styles.footer}
      hideCloseButton
      onClose={onCancel}
      testID='business-location-modal'
      title={translate('EDIT_BUSINESS_LOCATION')}
      visible
    >
      <BusinessLocationSelect
        locationId={locationId}
        onUpdateLocation={setLocationId}
        optionWindowPlacement='top'
        testID='input-business-location'
      />
    </Modal>
  );
};
