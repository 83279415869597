import { useFeatureFlags } from '@design';
import { FeatureFlags } from '@shared/enums';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { BusinessApi } from '../utilities/api';

export const useFulfillmentLocations = () => {
  const { currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const { isFlagOn } = useFeatureFlags();
  const isIntegrationsActive = isFlagOn(currentBusinessId, FeatureFlags.INTEGRATIONS);
  const invalidateFulfillmentLocations = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.FULFILLMENT_LOCATION_LIST, currentBusinessId])
  ), [currentBusinessId, queryClient]);

  const { data: fulfillmentLocations, isFetching: isFulfillmentLocationsLoading } = useQuery(
    [QueryKeys.FULFILLMENT_LOCATION_LIST, currentBusinessId],
    () => BusinessApi.getFulfillmentLocations(currentBusinessId),
    {
      enabled: !!currentBusinessId && isIntegrationsActive,
    },
  );

  return {
    fulfillmentLocations,
    invalidateFulfillmentLocations,
    isFulfillmentLocationsLoading,
  };
};
