import React, { ReactText } from 'react';
import { styled, StyledComponentProps, StyleType } from '@ui-kitten/components';
import { FalsyFC, FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { View } from 'react-native';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { TextProps } from '../Text/Text';
import { ButtonProps } from '../Button/Button';
import { IconButtonProps } from '../IconButton/IconButton';
import { SpacerProps } from '../Spacer/HSpacer';
import { VSpacer } from '../Spacer/VSpacer';

interface PageHeaderProps extends StyledComponentProps {
  accessoryRight?: RenderProp<{
    buttonProps: Partial<ButtonProps>,
    iconButtonProps: Partial<IconButtonProps>,
  }>,
  accessorySpacer?: RenderProp<SpacerProps>,
  children: string | RenderProp<TextProps>,
  testID: string,
}

interface PageHeaderState {
}

@styled('PageHeader')
export class PageHeader extends React.Component<PageHeaderProps, PageHeaderState> {
  private getComponentStyle = (source: StyleType) => {
    const button = PropsServiceHelper.allWithPrefixMapped(source, 'button');
    const iconButton = PropsServiceHelper.allWithPrefixMapped(source, 'iconButton');
    const text = PropsServiceHelper.allWithPrefixMapped(source, 'text');

    return {
      button,
      iconButton,
      text,
    };
  };

  render () {
    const { accessoryRight, accessorySpacer, children, eva, testID } = this.props;

    const styles = this.getComponentStyle(eva.style);

    return (
      <>
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'stretch',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          testID={`${testID}-page-header`}
        >
          <View style={{ flexDirection: 'row' }}>
            <FalsyText
              category="h3"
              component={children as ReactText}
              style={styles.text}
            />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <FalsyFC
              buttonProps={{ size: 'large', style: styles.button }}
              // @ts-ignore
              component={accessoryRight}
              iconButtonProps={{ size: 'large', style: styles.iconButton }}
            />
          </View>
        </View>
        <FalsyFC
          // @ts-ignore
          component={accessorySpacer}
          fallback={<VSpacer size="9" />}
        />
      </>
    );
  }
}
