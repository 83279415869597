import { Dimensions, View, TouchableOpacity, StyleSheet, ViewStyle } from 'react-native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSpinner, Button, Card, DataPoint, Header, HSpacer, IconButton, Text, VSpacer, LargeModal } from '@design';
import { ScreenSizes, useScreenSizing } from '../../../hooks/useScreenSizing';
import HubspotForm from '../../../ui-components/components/HubSpot/HubspotForm';
import { LinkingUtility } from '../../../utilities';
import { email, phone, knowledgeBaseLink } from '../../../constants';

const styles = StyleSheet.create({
  card: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
  container: {
    flexGrow: 1,
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: 24,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    gap: 24,
  },
  rowMobile: {
    flexGrow: 1,
    gap: 24,
  },
  rowItem: {
    flex: 1,
    minWidth: 270,
  },
  modalContainer: {
    paddingBottom: 0,
  },
  modalFooter: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    marginBottom: 32,
    right: 0,
    bottom: 0,
  },
});

type HelpProps = {
  style?: ViewStyle,
};

export const Help = ({ style }: HelpProps) => {
  const [translate] = useTranslation('common');
  const screenSizing = useScreenSizing();
  const [openFormModal, setOpenFormModal] = useState(false);
  const deviceWidth = Dimensions.get('window').width;

  const ModalFooter = () => (
    <View style={styles.modalFooter}>
      <Button
        appearance="outline"
        design="floating"
        onPress={() => setOpenFormModal(false)}
        size="large"
        status="basic"
        testID="done-button"
      >
        {translate<string>('DONE')}
      </Button>
      <HSpacer size="9" />
    </View>
  );

  const hubspotFormPage = [
    <View style={{ alignItems: 'center' }}>
      <HubspotForm
        formId="6036e4fc-b561-486d-b2a4-70169dfd2131"
        loading={<ModalSpinner />}
        onSubmit={() => {
          return (
            <Text>
              {translate<string>('SUPPORT_MESSAGE_SUCCESS')}
            </Text>
          );
        }}
        portalId="6616488"
        region="na1"
      />
    </View>,
  ];

  const phoneSupportCard = (
    <View style={styles.rowItem}>
      {screenSizing === ScreenSizes.Wide ? (
        <TouchableOpacity onPress={() => LinkingUtility.callTelephone(phone)}>
          <Card testID="phone">
            <View style={styles.card}>
              <DataPoint label={translate<string>('RALLY_SUPPORT')} testID="phone-rally-support">
                <Text>{phone}</Text>
              </DataPoint>
              <IconButton
                appearance="outline"
                onPress={() => LinkingUtility.callTelephone(phone)}
                size="large"
                status="primary"
                testID="phone-icon"
              >
                PhoneCall
              </IconButton>
            </View>
          </Card>
        </TouchableOpacity>
      ) : (
        <Card testID="phone">
          <View style={styles.card}>
            <DataPoint label={translate<string>('RALLY_SUPPORT')} testID="phone-rally-support">
              <Text>{phone}</Text>
            </DataPoint>
            <IconButton
              appearance="outline"
              size="large"
              status="primary"
              testID="phone-icon"
            >
              PhoneCall
            </IconButton>
          </View>
        </Card>
      )}
    </View>
  );

  const emailSupportCard = (
    <View style={styles.rowItem}>
      <TouchableOpacity onPress={() => LinkingUtility.openEmail(email)}>
        <Card testID="email">
          <View style={styles.card}>
            <DataPoint label={translate<string>('RALLY_SUPPORT')} testID="email-rally-support">
              <Text>{email}</Text>
            </DataPoint>
            <IconButton
              appearance="outline"
              onPress={() => LinkingUtility.openEmail(email)}
              size="large"
              status="primary"
              testID="email-icon"
            >
              Email
            </IconButton>
          </View>
        </Card>
      </TouchableOpacity>
    </View>
  );

  const supportRequestCard = (
    <View style={styles.rowItem}>
      <TouchableOpacity onPress={() => setOpenFormModal(true)}>
        <Card testID="form">
          <View style={styles.card}>
            <Button
              onPress={() => setOpenFormModal(true)}
              size="medium"
              testID="submit-request-button"
            >
              {translate<string>('SUBMIT_REQUEST')}
            </Button>
            <IconButton
              appearance="outline"
              onPress={() => setOpenFormModal(true)}
              size="large"
              status="primary"
              testID="submit-request-icon"
            >
              Inbox
            </IconButton>
          </View>
        </Card>
      </TouchableOpacity>
    </View>
  );

  const knowledgeBaseCard = (
    <View style={styles.rowItem}>
      <TouchableOpacity onPress={() => LinkingUtility.openUrl(knowledgeBaseLink)}>
        <Card testID="knowledge-base-card">
          <View style={styles.card}>
            <DataPoint testID="knowledge-base">
              <Text>{translate<string>('KNOWLEDGE_BASE')}</Text>
            </DataPoint>
            <IconButton
              appearance="outline"
              onPress={() => LinkingUtility.openUrl(knowledgeBaseLink)}
              size="large"
              status="primary"
              testID="email-icon"
            >
              Book
            </IconButton>
          </View>
        </Card>
      </TouchableOpacity>
    </View>
  );

  const hubspotModal = (
    <LargeModal
      containerStyle={styles.modalContainer}
      footer={ModalFooter}
      pages={hubspotFormPage}
      testID="hubspot-form-modal"
      title={translate<string>('REQUEST')}
      visible={openFormModal}
    />
  );

  const rowStyle = deviceWidth < 694 ? styles.rowMobile : styles.row;

  return (
    <View style={style}>
      <Header testID="help" title={translate('HELP')} />
      <VSpacer size="8" />
      <View style={styles.container}>
        <View style={rowStyle}>
          {phoneSupportCard}
          {emailSupportCard}
        </View>
        <View style={rowStyle}>
          {supportRequestCard}
          {knowledgeBaseCard}
        </View>
        {hubspotModal}
      </View>
    </View>
  );
};
