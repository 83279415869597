import { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { StatisticsCropYearType } from '@shared/interfaces/api';
import { StatisticsApi } from '../utilities/api/StatisticsApi';
import { QueryKeys } from '../constants';

export const useCropYears = (businessId: string, type: StatisticsCropYearType = null) => {
  const [cropYears, setCropYears] = useState<string[]>([]);
  const [error, setError] = useState<Error>(null);
  const queryClient = useQueryClient();

  const readCropYearsToState = (data) => {
    setCropYears(data.map((y) => y.toString()));
  };

  const invalidateCropYears = useCallback(() => {
    return queryClient.invalidateQueries(QueryKeys.CROP_YEARS);
  }, [queryClient]);

  const { data, isLoading } = useQuery(
    [QueryKeys.CROP_YEARS, businessId],
    () => (StatisticsApi.getCropYears(businessId, type)),
    {
      enabled: !!businessId,
      onError: setError,
      onSuccess: readCropYearsToState,
      staleTime: 5 * 60 * 1000,
    },
  );

  if (data && !cropYears.length && data?.length > 0) {
    readCropYearsToState(data);
  }

  return { cropYears, error, invalidateCropYears, isLoading };
};
