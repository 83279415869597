import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { Card, Tab, TabView, useFeatureFlags } from '@design';
import { Routes } from '../../../constants';
import { useHistory, useParams } from '../../../router';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { testId } from '../../../utilities/testId';
import { QuickQuoteTabView } from './QuickQuoteTabView';
import { GrowerDeliverableTab } from './GrowerDeliverableTab/GrowerDeliverableTab';
import { ProgramListTab } from './ProgramTab/ProgramListTab';
import { TankMixListTab } from './TankMixTab/TankMixListTab';
import { FarmPlanTab } from './FarmPlanTab/FarmPlanTab';

const styles = StyleSheet.create({
  tabView: {
    height: '100%',
  },
  grow: {
    flex: 1,
    backgroundColor: '#1C1C1C',
    paddingHorizontal: 0,
  },
});

const Tabs = ['tank-mixes', 'programs', 'farm-plans', 'quick-quote', 'deliverables'];

const getTabRoute = (tab: number) => Routes.PREPARE_REVIEW.replace(/:tab\?/, Tabs[tab]);

const PrepareReviewTabView: FunctionComponent = () => {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();
  const { user, currentBusinessId } = useAuthentication();
  const [selectedIndex, setSelectedIndex] = useState(Tabs.includes(tab) ? Tabs.indexOf(tab) : 0);
  const [translate] = useTranslation(['prepare', 'common']);
  const { isFlagOn, isFlagsInit } = useFeatureFlags();

  useEffect(() => {
    if (!tab) {
      // tab is now an optional parameter on prepare-review, so it can be undefined.
      // here we redirect to the first tab (even if it changes over time)
      // we could just allow the default url, but then the first tab url
      // and the default url would have the same tab value and clicking the menu
      // item clears the breadcrumb action (based on SideBarItem navigation)
      // but doesn't change the tab value in this component, so clicking the
      // sidebar while on the first tab will clear the breadcrumb action
      // this will cycle values through tab value and clear, then reset the action
      // it flashes, but it isn't gone.
      // I'm unsure how to fix this.  We need to rethink breadcrumbs as a whole related
      // maybe to url, or some other way to properly manage when to reset breadcrumbs
      // and when not to.
      history.replace(getTabRoute(0));
      return;
    }

    if (Tabs[selectedIndex] !== tab) {
      setSelectedIndex(Tabs.findIndex((doc) => doc === tab));
    }
  }, [history, translate, tab, user, isFlagOn, currentBusinessId, selectedIndex]);

  const onTabSelect = (index: number) => {
    const tabRoute = getTabRoute(index);
    setSelectedIndex(index);
    history.replace(tabRoute);
  };

  return (
    <View {...testId('prepare-review-view')} style={styles.grow}>
      { isFlagsInit && (
        <TabView
          {...testId('prepare-review-tab-view')}
          indicatorStyle={{ width: `${100}%` }}
          onSelect={(index) => onTabSelect(index)}
          selectedIndex={selectedIndex}
          style={[styles.grow, styles.tabView]}
          tabBarStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
        >
          <Tab testID="tank-mix-tab" title={translate('TAB_TITLE_PRODUCT_MIX') as string}>
            <Card style={styles.grow} testID="tank-mix-tab-card">
              <TankMixListTab />
            </Card>
          </Tab>
          <Tab {...testId('program-tab')} title={translate('TAB_TITLE_PROGRAM') as string}>
            <Card style={styles.grow} testID="program-tab-card">
              <ProgramListTab />
            </Card>
          </Tab>
          <Tab {...testId('farm-plan-tab')} title={translate('TAB_TITLE_FARM_PLAN') as string}>
            <Card style={styles.grow} testID="farm-plan-tab-card">
              <FarmPlanTab nativeId="farmPlanListView" />
            </Card>
          </Tab>
          <Tab {...testId('quick-quote-tab')} title={translate('TAB_TITLE_QUICK_QUOTE') as string}>
            <Card style={styles.grow} testID="quick-quote-tab-card">
              <QuickQuoteTabView />
            </Card>
          </Tab>
          <Tab {...testId('grower-deliverables-tab')} title={translate('TAB_TITLE_GROWER_DELIVERABLE') as string}>
            <Card {...testId('deliverable-list-view')} style={styles.grow}>
              <GrowerDeliverableTab />
            </Card>
          </Tab>
        </TabView>
      )}
    </View>
  );
};

export default PrepareReviewTabView;
