import { DetailedApiError } from './DetailedApiError';
import { ApiError } from './ApiError';
import { Routes } from '../../constants';
import { LinkingUtility } from '../Linking';

export class ApiUtils {
  /**
   * Parses the response error json that gets returned in case of a bad request response
   */
  public static handleError = async (response: Response, endpoint: string) => {
    if (response.status === 400 || response.status === 409 || response.status === 404) {
      const errorMessage = await response.json();
      throw new DetailedApiError(
        errorMessage.code || response.status.toString(),
        errorMessage.message,
        errorMessage,
      );
    }
    if (response.status === 401 && endpoint !== 'users/current') {
      LinkingUtility.openUrl(Routes.LOGOUT, { sameTab: true });
      return null;
    }
    throw new ApiError(response.status);
  };
}
