import React, { useCallback, useMemo } from 'react';
import { StyleSheet, StyleProp, View, ViewStyle } from 'react-native';
import { ChildrenWithProps } from '@ui-kitten/components/devsupport';
import { TabElement, TabProps, TabBar } from '@ui-kitten/components';
import { TabView } from './TabView';

interface TabContainerProps {
  selectedIndex: number;
  onSelect: (index: number) => void;
  tabBarStyle?: StyleProp<ViewStyle>;
  indicatorStyle?: StyleProp<ViewStyle>;
  children: ChildrenWithProps<TabProps>,
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  contentWrapperStyle?: StyleProp<ViewStyle>;
  nativeID?: string;
}

const styles = StyleSheet.create({ container: { overflow: 'hidden' } });

class TabViewChildElement {
  tab: TabElement;
  content: React.ReactElement;
}

class TabViewChildren {
  tabs: TabElement[] = [];
  contents: React.ReactElement[] = [];
}

const renderComponentChild = (element: TabElement, index: number): TabViewChildElement => ({
  tab: React.cloneElement(element, { key: index }),
  content: React.cloneElement(element.props.children, { key: index }),
});

const renderComponentChildren = (source: ChildrenWithProps<TabProps>): TabViewChildren => {
  const tabChildren = React.Children.toArray(source) as TabElement[];

  return tabChildren.reduce((acc: TabViewChildren, element: TabElement, index: number) => {
    const { tab, content } = renderComponentChild(element, index);
    return {
      tabs: [...acc.tabs, tab],
      contents: [...acc.contents, content],
    };
  }, new TabViewChildren());
};

/**
 * Custom Tab View implementation which uses UI Kitten Tab as it's building block
 * But replaces the View that renders out the tab content itself.
 * Changes compared to UI Kitten Tab view:
 *   - Horizontal swiping is not supported between tabs(which cause multiple bugs)
 *   - Tab content is lazy loaded and is mounted on the page only when it is opened
 */
export const TabContainer = (props: TabContainerProps) => {
  const {
    onSelect,
    style,
    selectedIndex,
    children,
    tabBarStyle,
    indicatorStyle,
    contentContainerStyle,
    contentWrapperStyle,
    nativeID,
  } = props;

  const onBarSelect = useCallback((index: number) => {
    onSelect(index);
  }, [onSelect]);

  const { tabs, contents } = useMemo(
    () => renderComponentChildren(children),
    [children],
  );

  return (
    <View
      style={[styles.container, style]}
    >
      <TabBar
        indicatorStyle={indicatorStyle}
        nativeID={nativeID}
        onSelect={onBarSelect}
        selectedIndex={selectedIndex}
        style={tabBarStyle}
      >
        {tabs}
      </TabBar>
      <TabView
        contentContainerStyle={contentContainerStyle}
        contentWrapperStyle={contentWrapperStyle}
        contents={contents}
        selectedIndex={selectedIndex}
      />
    </View>
  );
};

export default TabContainer;
