import { ApiBusiness, ApiBusinessContact } from '@shared/interfaces/api';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../constants';
import { BusinessApi } from '../../../utilities/api';

interface BusinessOptions {
  business: ApiBusiness,
  contacts: ApiBusinessContact[],
  fullAddress: string,
  initialBusiness: ApiBusiness,
  isLoading: boolean,
}

const DEFAULT_BUSINESS: ApiBusiness = {
  address1: '',
  businessName: '',
  city: '',
  contactEmail: '',
  country: '',
  postalCode: '',
  state: '',
  telephone: '',
  contacts: [],
};

export const DEFAULT_CONTACT: ApiBusinessContact = {
  contactName: '',
  contactEmail: '',
  contactTelephone: null,
  isDeliverableDefault: false,
};

export const generateBlankContact = (): ApiBusinessContact => DEFAULT_CONTACT;

export function useBusiness ({ businessId, onError }: {
  businessId: string | null,
  onError?: () => void,
}) {
  const [options, setOptions] = useState<BusinessOptions>({
    business: DEFAULT_BUSINESS,
    contacts: [generateBlankContact()],
    fullAddress: '',
    initialBusiness: DEFAULT_BUSINESS,
    isLoading: !!businessId,
  });

  useQuery([QueryKeys.BUSINESS_DETAILS, businessId],
    async () => BusinessApi.getBusiness(businessId), {
      enabled: !!businessId,
      onSuccess: (data) => {
        setOptions({
          business: data,
          contacts: data.contacts ?? [generateBlankContact()],
          fullAddress: `${data.address1}, ${data.city}, ${data.state}, ${data.postalCode}`,
          initialBusiness: data,
          isLoading: false,
        });
      },
      onError: () => onError?.(),
    },
  );

  return {
    ...options,
    setBusiness: (
      newBusiness: ApiBusiness,
      updateInitialBusiness = false,
    ) => {
      newBusiness.contacts = newBusiness.contacts?.filter((contact) => {
        return !(!contact.contactName && !contact.contactEmail);
      });

      setOptions({
        business: newBusiness,
        contacts: newBusiness.contacts,
        fullAddress:
          `${newBusiness.address1}, ${newBusiness.city}, ${newBusiness.state}, ${newBusiness.postalCode}`,
        initialBusiness: updateInitialBusiness
          ? newBusiness : options.initialBusiness,
        isLoading: options.isLoading,
      });
    },
  };
}
