import React, { Component } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import {
  FalsyText,
  Overwrite,
  RenderProp,
  TouchableWeb,
  TouchableWebElement,
  TouchableWebProps,
} from '@ui-kitten/components/devsupport';
import { StyleType } from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Alignment } from '@theme/variant-interfaces/Alignment';
import { FalsyIcon } from '../FalsyIcon/FalsyIcon';
import { IconProps } from '../Icon/Icon';

export type TableHeaderBackground = 'transparent';
export type SortDirection = 'ASC' | 'DESC';

type TableHeaderStyledProps = Overwrite<StyledComponentProps, {}>;

export interface TableHeaderProps extends TouchableWebProps, TableHeaderStyledProps {
  children?: React.ReactNode;
  accessoryLeft?: RenderProp<Partial<IconProps>>;
  accessoryRight?: RenderProp<Partial<IconProps>>;
  sortDirection?: SortDirection;
  sortableId?: string;
  style?: StyleProp<ViewStyle>;
  align?: Alignment;
  tableHeaderBackground?: TableHeaderBackground;
  testID: string;
}

// disabling max-len rule to stop linter from complaining about documentation
/**
 * The Header component for a table.
 * Accepts children types of Text or IconButton components, or a simple string.
 *
 * @property {ReactNode} children -
 * Components to render as the main content within the table header.
 *
 * @property {RenderProp<Partial<IconProps>>} accessoryLeft -
 * Icon accessory to be placed to the left of the children.
 *
 * @property {RenderProp<Partial<IconProps>>} accessoryRight -
 * Icon accessory to be placed to the right of the children.
 *
 * @property {boolean} isSorted -
 * Controls if the column is sorted and if the sort icon should be rendered.
 * Renders to the far right of the header.
 *
 * @property {'ASC' | 'DESC'} sortDirection -
 * Controls the direction fo the sort icon within the header.
 *
 * @property {() => void} onPress -
 * Function to execute when the header is pressed. This is where your sort logic will go.
 *
 * @property {StyleProp<ViewStyle>} style - Custom style sent to the table header.
 *
 */

@styled('TableHeader')
export class TableHeader extends Component<TableHeaderProps> {
  private getComponentStyle = (source: StyleType) => {
    const { height, paddingRight, paddingHorizontal, ...containerStyles } = source;

    // borderColor: color-basic-transparent-100
    return {
      tableHeader: {
        height,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        ...containerStyles,
      },
      sortedTableHeader: {
      },
      leftIcon: PropsServiceHelper.allWithPrefixMapped(source, 'leftAccessory'),
      rightIcon: PropsServiceHelper.allWithPrefixMapped(source, 'rightAccessory'),
      text: PropsServiceHelper.allWithPrefixMapped(source, 'text'),
      sortIcon: PropsServiceHelper.allWithPrefixMapped(source, 'sortIcon'),
      accessory: PropsServiceHelper.allWithPrefixMapped(source, 'accessory'),
    };
  };

  public render (): TouchableWebElement {
    const {
      children,
      accessoryLeft,
      accessoryRight,
      sortDirection,
      onPress,
      eva,
      style,
      ...containerProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    // eslint-disable-next-line no-nested-ternary
    const sortIcon = sortDirection
      ? sortDirection === 'ASC'
        ? 'ArrowUpward'
        : 'ArrowDownward'
      : null;

    const renderChildren = (): React.ReactElement => {
      if (typeof children === 'string') {
        return (
          <FalsyText
            category="s2"
            component={children}
            ellipsizeMode="tail"
            style={evaStyle.text}
          />
        );
      }
      if (React.isValidElement(children)) {
        return React.cloneElement(children);
      }
      return null;
    };

    const wrapper = onPress ? (
      <TouchableWeb onPress={onPress} />
    ) : (
      <View />
    );

    return (
      React.cloneElement(wrapper, {
        style: [
          evaStyle.tableHeader,
          sortDirection && evaStyle.sortedTableHeader,
          { flexDirection: 'row', alignItems: 'center' },
          style,
        ],
        ...containerProps,
      }, (
        <>
          <FalsyIcon
            component={accessoryLeft}
            size="small"
            style={evaStyle.leftIcon}
          />
          {renderChildren()}
          <FalsyIcon
            component={accessoryRight}
            size="small"
            style={evaStyle.rightIcon}
          />
          <FalsyIcon
            component={sortIcon}
            size="medium"
            style={evaStyle.sortIcon}
          />
        </>
      ))
    );
  }
}
