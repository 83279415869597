export default {
  ACCEPTED_REVENUE_BY_PRODUCT_CATEGORY: 'Accepted Revenue by Product Category',
  ADMIN: 'admin',
  COMPANY_REVENUE_IN_FUNNEL: 'Company Revenue in Sales Funnel',
  CORPORATE_LOCATION: 'Corporate',
  ERROR_LOADING_SALES_FUNNEL: 'There was an error loading company revenue data.',
  ERROR_LOADING_SALES_FUNNEL_LOCATION: 'There was an error loading sales funnel revenue by location.',
  LAST_UPDATED: 'Last updated {{ date }}',
  ORDER_DISCOUNTS: '(-{{ orderDiscounts }} order discounts',
  ORDER_DISCOUNT_MESSAGE: '(This amount does not include order discounts)',
  ORDER_TOTAL_AFTER_DISCOUNTS: '= {{ totalAfterDiscounts }})',
  SALES_FUNNEL_REVENUE_BY_LOCATION: 'Sales Funnel Revenue by Location',
  SALES_FUNNEL_REVENUE_BY_SALESPERSON: 'Sales Funnel Revenue by Salesperson',
  THERE_IS_NO_DATA_TO_DISPLAY: 'There is no order data to display.',
  TOTAL_AFTER_DISCOUNTS: '(-{{ discounts }} total order discounts = {{ total }})',
  TOTAL_COMPANY_REVENUE: 'Total Company Revenue: {{ total }}',
  TOTAL_EXCLUDING_ORDER_DISCOUNTS: 'Total excluding order discounts',
  TOTAL_INCLUDING_ORDER_DISCOUNTS: 'Total including order discounts',
  TOTAL_REVENUE_FIGURES_MESSAGE: 'Total revenue figures do not include order discounts',
  TOTAL_REVENUE_IN_FUNNEL: 'Total Company Revenue in Sales Funnel: {{ total }}',
};
