/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, FC, useCallback } from 'react';
import { Divider, Popover } from '@ui-kitten/components';
import { View, StyleSheet, ScrollView } from 'react-native';
import { AccordionItem, FilterMenu, HSpacer, SidePanel, Text, Button } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  accordionItem: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  divider: {
    backgroundColor: 'black',
  },
  sidePanelFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    marginTop: 24,
    marginBottom: 36,
    marginRight: 20,
  },
  counterContainer: {
    backgroundColor: '#42AAFF',
    borderRadius: 50,
    width: 21,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  counterValue: {
    color: '#232323',
  },
});

type Selections = { [key: string]: boolean };

export const FilterMenuDemo: FC = () => {
  const [sidePanelVisible, setSidePanelVisible] = useState(false);
  const [singleSelectPopoverVisible, setSingleSelectPopoverVisible] = useState(false);
  const [multiSelectPopoverVisible, setMultiSelectPopoverVisible] = useState(false);
  const [data] = useState([
    {
      id: '1',
      label: 'Aurelia Hall',
    },
    {
      id: '2',
      label: 'Florence Becker',
    },
    {
      id: '3',
      label: 'George Hall',
    },
    {
      id: '4',
      label: 'Deborah Hall',
    },
    {
      id: '5',
      label: 'Angel Gray',
    },
    {
      id: '6',
      label: 'Clint Becker',
    },
    {
      id: '7',
      label: 'Leroy Hayes',
    },
    {
      id: '8',
      label: 'Elijah Hall',
    },
    {
      id: '9',
      label: 'The Most Honorable John Jonathon Johnson Junior',
    },
    {
      id: '10',
      label: 'Aleksandra Hayes',
    },
  ]);

  const [singleSelectionsSP, setSingleSelectionsSP] = useState<Selections>({});
  const [multiSelectionsSP, setMultiSelectionsSP] = useState<Selections>({});
  const [popoverSingleSelections, setPopoverSingleSelections] = useState<Selections>({});
  const [popoverMultiSelections, setPopoverMultiSelections] = useState<Selections>({});

  const mapOptions = useCallback((selections) => (
    Object.keys(selections)
      .filter((key) => !!selections[key])
      .map((id) => data.find((user) => id === user.id).label)
  ), [data]);

  const clearAll = useCallback(() => {
    setPopoverMultiSelections({});
    setPopoverSingleSelections({});
    setSingleSelectionsSP({});
    setMultiSelectionsSP({});
  }, []);

  const renderCounter = (filters: object) => (
    <View
      style={styles.counterContainer}
    >
      <Text category="c1" style={styles.counterValue}>
        {Object.keys(filters).length}
      </Text>
    </View>
  );

  return (
    <>
      <DemoContainer label="FilterMenu">
        <DemoBlock style={{ flex: 2 }}>
          <Button appearance="outline" onPress={() => { setSidePanelVisible(true); }} testID="test-button">Open SidePanel</Button>
        </DemoBlock>
        <DemoBlock style={{ flex: 1 }}>
          <Popover
            anchor={() => (
              <Button
                appearance="outline"
                onPress={() => setSingleSelectPopoverVisible(true)}
                size="medium"
                status="primary"
                testID="test-button"
              >
                Single Select Popover
              </Button>
            )}
            onBackdropPress={() => setSingleSelectPopoverVisible(false)}
            placement="bottom start"
            style={{ paddingTop: 16 }}
            visible={singleSelectPopoverVisible}
          >
            <FilterMenu
              onSelect={(option) => setPopoverSingleSelections({ [option]: true })}
              options={data.slice(0, 6)}
              selectedOptions={popoverSingleSelections}
              testID="demo-1-filter-menu"
            />
          </Popover>
        </DemoBlock>
        <DemoBlock style={{ flex: 1 }}>
          <Popover
            anchor={() => (
              <Button
                appearance="outline"
                onPress={() => setMultiSelectPopoverVisible(true)}
                size="medium"
                status="primary"
                testID="test-button"
              >
                Multi Select Popover
              </Button>
            )}
            onBackdropPress={() => setMultiSelectPopoverVisible(false)}
            placement="bottom start"
            style={{ paddingTop: 16 }}
            visible={multiSelectPopoverVisible}
          >
            <FilterMenu
              multiSelect
              onSelect={(option) => setPopoverMultiSelections((prev) => ({ ...prev, [option]: !prev[option] }))}
              options={data}
              selectedOptions={popoverMultiSelections}
              testID="demo-2-filter-menu"
            />
          </Popover>
        </DemoBlock>
        <DemoBlock style={{ flex: 1 }}>
          <Button
            appearance="outline"
            onPress={() => {
              setPopoverMultiSelections({});
              setPopoverSingleSelections({});
              setSingleSelectionsSP({});
              setMultiSelectionsSP({});
            }}
            size="medium"
            status="primary"
            testID="test-button"
          >
            Clear all
          </Button>

        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Selections">
        <View style={{ flex: 2 }}>
          <DemoBlock>
            <Text>SP Multiselect</Text>
            {
              mapOptions(multiSelectionsSP).map((id, i) => (<Text key={i}>{id}</Text>))
            }
          </DemoBlock>
          <DemoBlock>
            <Text>SP Single Select</Text>
            {
              mapOptions(singleSelectionsSP).map((id, i) => (<Text key={i}>{id}</Text>))
            }
          </DemoBlock>
        </View>

        <DemoBlock style={{ flex: 1 }}>
          <Text>Multi Select Popover</Text>
          {
            mapOptions(popoverMultiSelections).map((id, i) => (<Text key={i}>{id}</Text>))
          }
        </DemoBlock>
        <DemoBlock style={{ flex: 1 }}>
          <Text>Single Select Popover</Text>
          {
            mapOptions(popoverSingleSelections).map((id, i) => (<Text key={i}>{id}</Text>))
          }
        </DemoBlock>
        <View style={{ flex: 1 }} />
      </DemoContainer>
      <SidePanel
        header="Filters"
        onClose={() => setSidePanelVisible(false)}
        testID="sidepanel"
        visible={sidePanelVisible}
      >
        {/*
            Not wrapping these accordion items in an accordion since FilterMenu is also a virtualized list.
            Nesting virtualized lists theoretically should be allowed, but in practice my console blows up with warnings
            https://github.com/necolas/react-native-web/issues/1560
        */}
        <ScrollView>
          <Divider style={styles.divider} />
          <AccordionItem
            accessoryRight={Object.keys(multiSelectionsSP).length > 0
              && (() => renderCounter(multiSelectionsSP))}
            style={styles.accordionItem}
            testID="filter-menu-demo-item-multi-select"
            title="User Multi Select"
          >
            <FilterMenu
              multiSelect
              onSelect={(option) => setMultiSelectionsSP((prev) => ({ ...prev, [option]: !prev[option] }))}
              options={data}
              selectedOptions={multiSelectionsSP}
              testID="demo-3-filter-menu"
            />
          </AccordionItem>
          <Divider style={styles.divider} />
          <AccordionItem
            accessoryRight={Object.keys(singleSelectionsSP).length > 0
              && (() => renderCounter(singleSelectionsSP))}
            style={styles.accordionItem}
            testID="filter-menu-demo-item-single-select"
            title="User Single Select"
          >
            <FilterMenu
              onSelect={(option) => setSingleSelectionsSP({ [option]: true })}
              options={data}
              selectedOptions={singleSelectionsSP}
              testID="demo-4-filter-menu"
            />
          </AccordionItem>
          <Divider style={styles.divider} />
        </ScrollView>
        <View style={styles.sidePanelFooter}>
          <Button
            appearance="ghost"
            onPress={clearAll}
            status="basic"
            testID="test-button"
          >
            Clear all
          </Button>
          <HSpacer size="5" />
          <Button
            onPress={() => setSidePanelVisible(false)}
            status="primary"
            testID="test-button"
          >
            Show all
          </Button>
        </View>
      </SidePanel>
    </>
  );
};
