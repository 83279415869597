import React, { useEffect, useState } from 'react';
import { ApiGrowerCropZone } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
import { Header } from '@design';
import { View, StyleSheet } from 'react-native';
import { useStyleSheet } from '@ui-kitten/components';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';
import { StringUtility } from '../../../../utilities';

type CropZoneDataType = RowMeta & ApiGrowerCropZone;

const CropZoneRowDetails = (cropZones: ApiGrowerCropZone[]) => {
  const [translate] = useTranslation(['common', 'growers']);
  const [cropZoneData, setCropZoneData] = useState<CropZoneDataType[]>(null);

  const styles = useStyleSheet({
    line: {
      borderBottomColor: 'color-basic-transparent-100',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
  });

  useEffect(() => {
    setCropZoneData(Object.values(cropZones)?.map((cropZone, idx) => ({
      ...cropZone,
      rowId: `row:${idx}|${cropZone.id}`,
      hasDetails: false,
    })));
  }, [cropZones]);

  const columns: IColumn<CropZoneDataType>[] = [
    {
      columnId: 'zone-name',
      header: {
        render: '',
      },
      render: (cz) => cz.zoneName,
      flex: 1,
    },
    {
      columnId: 'crop-zone-acres',
      header: {
        render: '',
        align: 'right',
      },
      render: (cz) => `${StringUtility.localizeNumber(cz.areaValue, 3)} ${translate(cz.areaUnitType)}`,
      flex: 0.5,
      align: 'right',
    },
  ];

  return (
    <View style={{ flex: 1, justifyContent: 'flex-start' }}>
      <Header level="4" testID="crop-zones-header" title={translate<string>('CROP_ZONES')} titleHint />
      <View style={styles.line} />
      <SortableTable<CropZoneDataType>
        columns={columns}
        data={cropZoneData}
        showHeaders="no"
        tableId="crop-zones-table"
        testID="crop-zones-parent-table"
      />
    </View>
  );
};

export default CropZoneRowDetails;
