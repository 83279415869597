import { Button, HSpacer } from '@design';
import { OrderStatus } from '@shared/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { testId } from '../../../../../utilities';
import {
  ConfirmationModal,
} from '../../../../components/shared/ConfirmationModal/ConfirmationModal';
import { ConfirmSaveDialog } from '../ConfirmSaveDialog/ConfirmSaveDialog';

const styles = StyleSheet.create({
  actionButtons: {
    position: 'absolute',
    right: 40,
    bottom: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  footer: { flexDirection: 'row' },
});

export interface EditModeFooterProps {
  onSave?(): void,
  onCancel(): void,
  orderStatus: OrderStatus,
}
export function EditModeFooter ({
  onSave,
  onCancel,
  orderStatus,
}: EditModeFooterProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const [cancelEditVisible, setCancelEditVisible] = useState(false);
  const [saveConfirmationVisible, setSaveConfirmationVisible] = useState(false);

  const handleOnSave = () => {
    if (orderStatus === OrderStatus.ACCEPTED) {
      setSaveConfirmationVisible(true);
    } else {
      onSave?.();
    }
  };

  return (
    <>
      <ConfirmationModal
        cancelText={translate('CONTINUE_EDITING')}
        confirmText={translate('QUIT_EDITING')}
        messageText={translate('CANCEL_EDIT_DIALOG_DESCRIPTION')}
        onCancel={() => setCancelEditVisible(false)}
        onConfirm={onCancel}
        title={translate('CANCEL_EDIT_DIALOG_TITLE')}
        visible={cancelEditVisible}
      />
      <ConfirmSaveDialog
        onCancel={() => setSaveConfirmationVisible(false)}
        onSave={async () => {
          setSaveConfirmationVisible(false);
          onSave?.();
        }}
        visible={saveConfirmationVisible}
      />
      <View {...testId('edit-mode-footer')} style={styles.actionButtons}>
        <Button
          appearance="outline"
          design="floating"
          onPress={!onSave ? onCancel : () => setCancelEditVisible(true)}
          size="large"
          status="basic"
          {...testId('edit-mode-footer-cancel-button')}
        >
          {(translate<string>('CANCEL'))}
        </Button>
        <HSpacer size="8" />
        <Button
          design="floating"
          disabled={!onSave}
          onPress={handleOnSave}
          size="large"
          {...testId('edit-mode-footer-save-button')}
        >
          {translate<string>('SAVE_CHANGES')}
        </Button>
      </View>
    </>
  );
}
