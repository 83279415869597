import React from 'react';
import {
  StyleProp, StyleSheet, View, ViewProps, ViewStyle,
} from 'react-native';
import { ThemeColors, Sizing } from '../../../constants';

const styles = StyleSheet.create({
  card: {
    backgroundColor: ThemeColors.DARKEST_GRAY,
    borderRadius: Sizing.BASE_SPACING,
    paddingVertical: Sizing.DOUBLE_SPACING,
    paddingHorizontal: 3 * Sizing.BASE_SPACING,
  },
  header: { marginBottom: Sizing.DOUBLE_SPACING },
  body: { padding: Sizing.HALF_SPACING },
  footer: { marginTop: Sizing.DOUBLE_SPACING },
});

export interface CardProps extends ViewProps {
  header?: JSX.Element;
  footer?: JSX.Element;
  children: any;
  disabled?: boolean;
  contentStyle?: StyleProp<ViewStyle>;
}

export const StyledCard = (props: CardProps) => (
  <View style={[styles.card, props.style]} testID="business-details-table">
    {props.header && <View style={styles.header}>{props.header}</View>}
    <View style={[props.contentStyle]}>{props.children}</View>
    {props.footer && <View style={styles.footer}>{props.footer}</View>}
  </View>
);
