import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ApiCrop } from '@shared/interfaces/api';
import { Text, Card, HSpacer } from '@design';
import { StringUtility } from '../../../../utilities';

const styles = StyleSheet.create({
  colorIndicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 12,
  },
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

interface CropCardProps {
  buttonLeft: JSX.Element,
  buttonRight: JSX.Element,
  crop: ApiCrop;
}

export const CropCard = ({
  buttonLeft,
  buttonRight,
  crop,
}: CropCardProps) => {
  const [translate] = useTranslation(['businesses', 'common']);

  return (
    <Card testID="crop-card">
      <View style={styles.mainContainer}>
        <View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={[{ backgroundColor: crop.color }, styles.colorIndicator]} />
            <HSpacer size="5" />
            <Text category="h6">
              {`${translate<string>(crop.cropType)} | ${translate<string>(crop.subType)}`}
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <HSpacer size="10" />
            <Text appearance="hint" category="p1">
              {`${translate('CROP_MARKET_PRICE_LABEL')}:`}
            </Text>
            <HSpacer size="2" />
            <Text category="p1">
              {`${StringUtility.formatCurrency(crop.price)}/${translate(crop.priceUom)}`}
            </Text>
            <HSpacer size="7" />
            <Text appearance="hint" category="p1">
              {`${translate('YIELD_GOAL_PER_ACRE_LABEL')}:`}
            </Text>
            <HSpacer size="2" />
            <Text category="p1">
              {`${StringUtility.localizeNumber(
                crop.yieldGoal,
                2,
                true,
              )}/${translate(crop.yieldGoalUom)}`}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          {buttonLeft}
          {
            !!buttonRight
            && (
            <>
              <HSpacer size="5" />
              {buttonRight}
            </>
            )
          }
        </View>
      </View>
    </Card>
  );
};
