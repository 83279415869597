/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { TankMixDeliverableV0 } from '@shared/interfaces';
import { Divider } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { Icon } from '@design';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { RallyLogoHeader } from '../../../../svg/RallyLogoHeader';
import { Button, Text } from '../../../../ui-components';
import { Sizing, ThemeColors } from '../../../../constants';
import { QuadRow } from '../../../../ui-components/components/QuadRow/QuadRow';
import { DataPoint } from '../../../../ui-components/components/DataPoint/DataPoint';

const styles = StyleSheet.create({
  wrapper: {
    margin: 'auto',
    flex: 1,
    padding: Sizing.BASE_SPACING,
    justifyContent: 'space-around',
  },
  pageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titles: {
    flex: 1,
  },
  headerRow: {
    marginVertical: Sizing.HALF_SPACING,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tankMixName: {
    fontSize: 1.25 * Sizing.BASE_SPACING,
    fontWeight: 'bold',
  },
  headerLogo: {
    marginTop: -45,
    marginBottom: -45,
  },
  divider: {
    backgroundColor: 'white',
    marginVertical: Sizing.HALF_SPACING,
  },
  layoutLandscape: {
    flexDirection: 'row',
    flex: 1,
  },
  layoutPortrait: {
    flexDirection: 'column',
    flex: 1,
  },
  layoutGroup: {
    flex: 0.5,
  },
  headerData: {
    fontSize: Sizing.BASE_SPACING,
  },
  headerIcon: {
    color: ThemeColors.PRIMARY,
    marginRight: Sizing.HALF_SPACING,
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
  },
  centeredContent: {
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  dataPoints: {
    marginVertical: Sizing.DOUBLE_SPACING,
  },
});

interface Props {
  snapshot: TankMixDeliverableV0.Snapshot,
  selections: TankMixDeliverableV0.Selections,
  deliverableId: string;
}

export const TankMixDeliverableMobileView: FunctionComponent<Props> = ({
  snapshot,
  selections,
  deliverableId,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  const windowDimensions = useWindowDimensions();
  const isLandscape = windowDimensions.width > windowDimensions.height;

  const [totalCostPerAcre, setTotalCostPerAcre] = useState(0);

  useEffect(() => {
    const deliverableCost = snapshot.OVERVIEW?.TOTAL_COST_PER_AREA?.data;
    setTotalCostPerAcre(Number(deliverableCost) || 0);
  }, [snapshot.OVERVIEW]);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, flexShrink: 0 }} nativeID="vscroll">
      <ScrollView contentContainerStyle={{ flex: 1 }} horizontal nativeID="hscroll">
        {snapshot && selections && (
          <View nativeID="wrapper" style={styles.wrapper}>
            <View style={styles.pageHeader}>
              <View style={styles.titles}>
                <View style={styles.headerRow}>
                  <Text>{translate('TANK_MIX_DELIVERABLE_FOR')}</Text>
                </View>
                <View style={styles.headerRow}>
                  <Text style={styles.tankMixName}>
                    {snapshot.HEADER?.TANK_MIX_NAME?.data || ''}
                  </Text>
                </View>
              </View>
              {isLandscape && (
                <View style={[styles.headerLogo]}>
                  <View style={[styles.headerRow]}>
                    <RallyLogoHeader />
                  </View>
                </View>
              )}
            </View>
            <Divider style={styles.divider} />
            <View
              style={isLandscape ? styles.layoutLandscape : styles.layoutPortrait}
            >
              <View style={styles.layoutGroup}>
                <View style={styles.headerRow}>
                  <Icon fill={ThemeColors.PRIMARY} name="GrowersPlanning" style={[styles.headerIcon]} testID="grower-icon" />
                  <Text style={styles.headerData}>
                    {snapshot.HEADER?.GROWER_NAME?.data || ''}
                  </Text>
                </View>
                <View style={styles.headerRow}>
                  <Icon fill={ThemeColors.PRIMARY} name="GrowersBusiness" style={[styles.headerIcon]} testID="business-icon" />
                  <Text style={styles.headerData}>
                    {snapshot.HEADER?.BUSINESS_NAME?.data || ''}
                  </Text>
                </View>
                <View style={styles.headerRow}>
                  <Icon fill={ThemeColors.PRIMARY} name="Person" style={[styles.headerIcon]} testID="user-icon" />
                  <Text style={styles.headerData}>
                    {snapshot.HEADER?.BUSINESS_USER_NAME?.data || ''}
                  </Text>
                </View>
                {snapshot.HEADER?.LOCATION_NAME?.data && (
                  <View style={styles.headerRow}>
                    <Icon fill={ThemeColors.PRIMARY} name="Navigation2" style={[styles.headerIcon]} testID="location-icon" />
                    <Text style={styles.headerData}>
                      {snapshot.HEADER?.LOCATION_NAME?.data}
                    </Text>
                  </View>
                )}
              </View>

              <View style={[styles.layoutGroup, styles.layoutPortrait]}>
                <View style={[styles.centeredContent, styles.dataPoints]}>
                  <QuadRow
                    cells={[
                      <DataPoint
                        label={translate('AVERAGE_COST_PER_ACRE')}
                        value={StringUtility.formatCurrencyShort(totalCostPerAcre)}
                      />,
                      <DataPoint
                        label={translate('PRODUCT_COUNT_LABEL')}
                        value={snapshot.PRODUCT_SUMMARY?.length}
                      />,
                    ]}
                  />
                </View>

                <View style={styles.centeredContent}>
                  <Button
                    onPress={() => LinkingUtility.openUrl(`${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`)}
                    size="xsmall"
                    status="primary"
                  >
                    {translate('VIEW_PDF')}
                  </Button>
                </View>
              </View>

            </View>

            {!isLandscape && (
              <View style={styles.centeredContent}>
                <RallyLogoHeader />
              </View>
            )}

          </View>
        )}
      </ScrollView>
    </ScrollView>
  );
};
