import { VSpacer, Header, Card, Text, TextLink, DataPoint } from '@design';
import { AreaPlannedResult } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import { AcresPlannedViewAllCropsModal } from '../FarmPlanForm/AcresPlannedViewAllCropsModal';

interface FarmPlanAcresPlannedProps {
  acresPlannedSummary: AreaPlannedResult;
  passes: number;
}

const abridgedAcresPlannedLimit = 3;

export const FarmPlanAcresPlanned = ({
  acresPlannedSummary,
  passes,
}: FarmPlanAcresPlannedProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showViewAllCropsModal, setShowViewAllCropsModal] = useState(false);
  const styles = useStyleSheet({
    card: {
      flex: 1,
    },
    viewAllProducts: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flex: 1,
      flexDirection: 'row',
    },
  });
  const abridgedCrops = acresPlannedSummary.crops.slice(0, abridgedAcresPlannedLimit);
  const isAbridged = acresPlannedSummary.crops.length > abridgedCrops.length;

  return (
    <View style={{ flex: 1 }}>
      <Header
        level="3"
        testID="farm-plan-acres-planned"
        title={translate('ACRES_PLANNED')}
      />
      <VSpacer size="4" />
      <Card style={styles.card} testID="acres-planned-card">
        <View>
          <DescriptionListItem
            label={translate('TOTAL_ACRES_PLANNED')}
            testID="farm-plan-total-acres-planned"
            textCategory="p2"
          >
            <Text category="p2" testID="farm-plan-total-acres-planned-value">
              {`${
                StringUtility.localizeNumber(acresPlannedSummary.planned, 0)
              }/${
                StringUtility.localizeNumber(acresPlannedSummary.total, 0)
              } ${translate('ACRES')}`}
            </Text>
          </DescriptionListItem>
          <VSpacer size="2" />
          <DescriptionListItem
            label={translate('NUMBER_OF_PASSES')}
            testID="farm-plan-number-of-passes"
            textCategory="p2"
          >
            <Text category="p2" testID="farm-plan-number-of-passes-value">{passes}</Text>
          </DescriptionListItem>
          <VSpacer size="7" />
          {acresPlannedSummary.crops.length ? (
            <DataPoint flex label={translate<string>('CROPS')} testID="crops">
              <>
                {abridgedCrops?.map((crop, idx) => {
                  const { areaValue, type, subType, percent } = crop;
                  const { areaUnitType } = acresPlannedSummary;
                  return (
                    <Fragment key={`${type}-${subType}`}>
                      <View key={`${type}-${subType}`} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Text
                          category="p2"
                          numberOfLines={1}
                          style={{ flex: 1.5 }}
                          testID={`${type} | ${subType}-${idx}`}
                        >
                          {`${translate<string>(type)} | ${translate<string>(subType)}`}
                        </Text>
                        <Text
                          category="p2"
                          numberOfLines={1}
                          style={{ flex: 1 }}
                          testID={`cost-per-area-${idx}`}
                        >
                          {translate<string>('PER_SHORT', {
                            area: StringUtility.formatCurrencyAccounting(crop.costPerArea) ?? 0,
                            areaUnitType,
                          })}
                        </Text>
                        <Text
                          category="p2"
                          numberOfLines={1}
                          style={{ flex: 1, textAlign: 'right' }}
                          testID={`percent-acres-${idx}`}
                        >
                          {`${percent}%, ${StringUtility.localizeNumber(areaValue, 0)}`
                          + ` ${translate<string>(areaUnitType === 'ac' ? 'ACRES' : areaUnitType)}`}
                        </Text>
                      </View>
                      {abridgedCrops.length > 1 && <VSpacer size="3" />}
                    </Fragment>
                  );
                })}
              </>
            </DataPoint>
          ) : (
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Text>{translate<string>('NO_CROPS')}</Text>
            </View>
          )}
          {isAbridged && (
            <>
              <VSpacer size="3" />
              <View style={styles.viewAllProducts}>
                <TextLink
                  appearance="secondary"
                  category="p2"
                  onPress={() => setShowViewAllCropsModal(true)}
                  testID="view-all-crops"
                >
                  {translate<string>('VIEW_ALL_CROPS')}
                </TextLink>
              </View>
            </>
          )}
        </View>
      </Card>
      {showViewAllCropsModal && (
        <AcresPlannedViewAllCropsModal
          areaUnitType={acresPlannedSummary.areaUnitType}
          crops={acresPlannedSummary.crops}
          onClose={() => setShowViewAllCropsModal(false)}
          visible={showViewAllCropsModal}
        />
      )}
    </View>
  );
};
