import React, { FC } from 'react';
import { View } from 'react-native';
import { FalsyFC, RenderProp } from '@ui-kitten/components/devsupport';
import { useStyleSheet } from '@ui-kitten/components';
import { ButtonProps, HSpacer, IconProps, Text, TextLinkProps, TextProps } from '@design';

interface ActionsHeaderProps extends TextProps {
  header: string,
  iconAccessory?: RenderProp<Partial<IconProps>>,
  buttonAccessory?: RenderProp<Partial<ButtonProps>>,
  textLinkAccessory?: RenderProp<Partial<TextLinkProps>>,
}

const ActionsHeader: FC<ActionsHeaderProps> = ({
  header,
  iconAccessory,
  buttonAccessory,
  textLinkAccessory,
  ...textProps
}) => {
  const themedStyles = useStyleSheet({
    container: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      minHeight: 32,
    },
    spacer: {
      flexGrow: 1,
    },
    icon: {
      tintColor: 'text-hint-color',
      paddingLeft: 13,
    },
    link: {
      fontSize: 14,
    },
    button: {
      padding: 0,
      paddingLeft: 16,
    },
    accessories: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    linkContainer: {
      flexDirection: 'row',
      paddingLeft: 24,
    },
  });

  return (
    <View style={themedStyles.container}>
      <Text {...textProps}>{header}</Text>
      {
        iconAccessory
          && (
          <FalsyFC
            component={iconAccessory}
            style={themedStyles.icon}
          />
          )
      }
      <HSpacer style={themedStyles.spacer} />
      <View style={themedStyles.accessories}>
        {
          textLinkAccessory
            && (
            <View style={themedStyles.linkContainer}>
              <FalsyFC
                appearance="secondary"
                component={textLinkAccessory}
                size="medium"
                style={themedStyles.link}
              />
            </View>
            )
        }
        {
          buttonAccessory && (
          <View style={themedStyles.button}>
            <FalsyFC
              component={buttonAccessory}
            />
          </View>
          )
        }
      </View>
    </View>
  );
};

export default ActionsHeader;
