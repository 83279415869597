import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  StyleSheet,
  View,
  LayoutChangeEvent,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CheckBox,
  FilterInput,
  Icon,
  Radio,
  RadioGroup,
  Text,
} from '../../ui-components';
import { Sizing, ThemeColors } from '../../constants';

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    padding: Sizing.BASE_SPACING,
    borderBottomColor: ThemeColors.MID_GRAY,
    borderBottomWidth: 1,
  },
  headerText: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
    marginBottom: Sizing.BASE_SPACING,
  },
  content: { padding: Sizing.BASE_SPACING },
  filterHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: Sizing.HALF_SPACING,
    borderBottomColor: ThemeColors.LIGHTER_GRAY,
    borderBottomWidth: 1,
  },
  flexLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  toggleIcon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
  filterDrawer: { padding: Sizing.HALF_SPACING },
  drawerContainer: { overflow: 'hidden' },
  filterItem: { marginBottom: Sizing.HALF_SPACING },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

export enum FilterType {
  CHECKBOX,
  SEARCHABLE_CHECKBOX,
  RADIO,
  TOGGLE,
  AUTOCOMPLETE,
  TEXT_INPUT,
}

export interface IFilterOption {
  label: string,
  value: any,
}

export type IFilterSettings = {
  label: string,
  field: string,
  openByDefault?: boolean;
} & (
  {
    type: FilterType.TEXT_INPUT | FilterType.AUTOCOMPLETE
  } | {
    type: (
      FilterType.CHECKBOX
      | FilterType.RADIO
      | FilterType.TOGGLE
      | FilterType.SEARCHABLE_CHECKBOX
    ),
    options: IFilterOption[]
  }
);

export interface IFilterRowProps {
  filter: IFilterSettings,
  selections: any[],
  updateSelections: (field: string, selections: any[]) => void;
  openByDefault?: boolean;
}

export interface IFilterProps {
  onSubmit: (selections: any) => void;
  filters: IFilterSettings[];
  selections: any;
}

const FilterRow = (rowProps: IFilterRowProps) => {
  const { updateSelections, openByDefault } = rowProps;
  const [drawerHeight, setDrawerHeight] = useState(-1);
  // By default every checkbox type filter is collapsed, unless
  // defined otherwise via props
  const defaultExpanded = rowProps.filter.type === FilterType.CHECKBOX
    ? _.defaultTo(openByDefault, false)
    : true;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [selections, setSelections] = useState(rowProps.selections);

  const expandAnim: Animated.Value = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    setSelections(rowProps.selections);
  }, [rowProps.selections]);

  /**
   * Toggles expansion of the drawer
   */
  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (expanded) {
      Animated.timing(expandAnim, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(expandAnim, {
        toValue: drawerHeight,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
    return !expanded;
  };

  /**
   * Gets the initial height of the drawer content
   * @param event The layout event
   */
  const initDrawerHeight = (event: LayoutChangeEvent) => {
    if (drawerHeight < 0) {
      setDrawerHeight(event.nativeEvent.layout.height);
      if (expanded) {
        Animated.timing(expandAnim, {
          toValue: event.nativeEvent.layout.height,
          duration: 0,
          useNativeDriver: false,
        }).start();
      }
    }
  };

  /**
   * Sets the selection of an option value
   * @param value The value of the option
   * @param selected Whether it's selected
   */
  const setValueSelected = (value: any, selected: boolean) => {
    let newSelections = _.cloneDeep(selections);
    if (rowProps.filter.type === FilterType.RADIO) {
      newSelections = [value];
    } else if (_.isEmpty(newSelections)) {
      if (selected) {
        newSelections = [value];
      }
    } else if (selected) {
      if (!_.includes(selections, value)) {
        newSelections = [...selections, value];
      }
    } else {
      newSelections = _.without(selections, value);
    }

    setSelections(newSelections);
    updateSelections(rowProps.filter.field, newSelections);
  };

  const setInputValue = (value: string[]) => {
    const newSelections = _.cloneDeep(value);
    setSelections(newSelections);
    updateSelections(rowProps.filter.field, newSelections);
  };

  let filterInput = <></>;
  if (rowProps.filter.type === FilterType.CHECKBOX) {
    filterInput = (
      <>
        {rowProps.filter.options.sort(
          (a, b) => a.label.localeCompare(b.label),
        ).map((option) => (
          <CheckBox
            checked={_.includes(selections, option.value)}
            onChange={(checked) => setValueSelected(option.value, checked)}
            style={styles.filterItem}
            key={`filter-checkbox-${option.label}-${option.value}`}
            // @ts-ignore
            nativeID={option.label}
          >
            {option.label}
          </CheckBox>
        ))}
      </>
    );
  }
  if (rowProps.filter.type === FilterType.RADIO) {
    const radioOptions = rowProps.filter.options;
    filterInput = (
      <RadioGroup
        onChange={(i) => setValueSelected(radioOptions[i].value, true)}
        selectedIndex={_.indexOf(
          _.map(radioOptions, 'value'), _.head(selections),
        )}
      >
        {_.map(rowProps.filter.options, (option) => (
          <Radio key={option.value}>{option.label}</Radio>
        ))}
      </RadioGroup>
    );
  }
  if (rowProps.filter.type === FilterType.TEXT_INPUT) {
    filterInput = (
      <View style={styles.inputContainer}>
        <FilterInput
          nativeID={`${rowProps.filter.field}-input`}
          onChangeText={(value: string) => setInputValue([value])}
          onClear={() => setInputValue([])}
          value={_.defaultTo(_.head(selections), '')}
        />
      </View>
    );
  }

  return (
    <View key={rowProps.filter.field} style={styles.content}>
      <View
        onStartShouldSetResponder={() => toggleExpanded()}
        style={styles.filterHeader}
      >
        <View nativeID={rowProps.filter.label} style={styles.flexLeft}>
          <Text style={{ fontWeight: 'bold' }}>{rowProps.filter.label}</Text>
        </View>
        <View style={styles.flexRight}>
          <TouchableOpacity
            onPress={toggleExpanded}
          >
            <Icon
              fill={ThemeColors.PRIMARY}
              name={expanded ? 'chevron-down-outline' : 'chevron-right-outline'}
              style={styles.toggleIcon}
            />
          </TouchableOpacity>
        </View>
      </View>
      {(expanded || drawerHeight > 0) && (
      <Animated.View
        style={[
          styles.drawerContainer,
          drawerHeight < 0 && {
            position: 'absolute',
            top: -5000,
          },
          drawerHeight >= 0 && { height: expandAnim },
        ]}
      >
        <View onLayout={initDrawerHeight} style={styles.filterDrawer}>
          {filterInput}
        </View>
      </Animated.View>
      )}
    </View>
  );
};

export const Filters = (props: IFilterProps) => {
  const [translate] = useTranslation(['common']);
  const [allSelections, setAllSelections] = useState(_.cloneDeep(props.selections));

  /**
   * Updates all filter selections when a field changes
   * @param field The field updated
   * @param selections The field selections
   */
  const updateSelections = (field: string, selections: any[]) => {
    if (_.isEmpty(selections)) {
      delete allSelections[field];
      setAllSelections(allSelections);
      return;
    }

    setAllSelections(_.set(allSelections, field, selections));
  };

  return (
    <>
      <View style={styles.header}>
        <View style={{ flexDirection: 'row' }}>
          <Button
            nativeID="clear-button"
            onPress={() => setAllSelections({})}
            status="secondary"
          >
            {translate('CLEAR')}
          </Button>
          <Button
            nativeID="apply-button"
            onPress={() => props.onSubmit(allSelections)}
            style={{ marginLeft: Sizing.HALF_SPACING }}
          >
            {translate('APPLY')}
          </Button>
        </View>
      </View>
      <ScrollView>
        {props.filters.map((filter) => (
          <FilterRow
            filter={filter}
            key={`filter-row-${filter.label}-${filter.field}`}
            openByDefault={filter.openByDefault}
            selections={_.get(allSelections, filter.field, [])}
            updateSelections={updateSelections}
          />
        ))}
      </ScrollView>
    </>
  );
};
