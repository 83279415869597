import React from 'react';
import { View } from 'react-native';
import { Text } from '../Text/Text';
import { FilterCategory, Selections } from './Filter';

const renderFilterIndicator = (filterIndicatorCircleStyle: Record<string, any>) => (
  <View style={filterIndicatorCircleStyle} />
);

export interface DropdownValueProps {
  options: FilterCategory,
  selections: Selections,
  dropdownContainerStyle: Record<string, any>,
  filterIndicatorCircleStyle: Record<string, any>,
}
export function DropdownValue ({
  options,
  selections,
  dropdownContainerStyle,
  filterIndicatorCircleStyle,
}: DropdownValueProps) {
  const hasFiltersSelected = Object.keys(selections)
    .find((val) => selections[val] ?? true);

  return (
    <View style={dropdownContainerStyle}>
      <Text>
        {options.columnLabel}
      </Text>
      {hasFiltersSelected && renderFilterIndicator(filterIndicatorCircleStyle)}
    </View>
  );
}
