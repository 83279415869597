import { Button, Dialog, HSpacer, Text } from '@design';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { testId } from '../../../../../utilities';
import { AcreageModal } from './AcreageModal';

const styles = StyleSheet.create({
  title: {
    marginRight: 32 - 8, // 32 - Dialog paddingRight
  },
});

export interface EditAcreageDialogProps {
  visible: boolean,
  acreage: number,
  onAcreageChange: (acreage: number) => void;
  onCancel(): void,
}
export function EditAcreageDialog ({
  visible,
  acreage,
  onAcreageChange,
  onCancel,
}: EditAcreageDialogProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const [acreageVisible, setAcreageVisible] = useState(false);

  return acreageVisible ? (
    <AcreageModal
      {...testId('edit-acreage-dialog-modal')}
      initialAcreage={acreage}
      onAcreageChange={(newAcreage) => {
        setAcreageVisible(false);
        onAcreageChange(newAcreage);
      }}
      onCancel={() => {
        setAcreageVisible(false);
        onCancel();
      }}
    />
  ) : (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...testId('edit-acreage-dialog-cancel')}
            {...primaryButtonProp}
            appearance="outline"
            status="basic"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...testId('edit-acreage-dialog-continue')}
            {...secondaryButtonProp}
            onPress={() => setAcreageVisible(true)}
          >
            {translate<string>('CONFIRM_EDIT_ACREAGE')}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="edit-acreage-dialog"
      title={translate('EDIT_ACREAGE_DIALOG_TITLE')}
      visible={visible}
    >
      <Text category="p2" status="basic" style={styles.title}>
        {translate<string>('EDIT_ACREAGE_DIALOG_DESCRIPTION')}
      </Text>
    </Dialog>
  );
}
