import {
  Button,
  DataPoint,
  HSpacer,
  Icon,
  IconButton,
  NumericInput,
  Select,
  SelectItem,
  TableCell,
  TableRow,
  Text,
  VSpacer,
} from '@design';
import { ApiBusinessLocation, ApiPrice, ApiPriceType } from '@shared/interfaces/api';
import { IndexPath } from '@ui-kitten/components/devsupport';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { ColorCircleIndicator } from '../../components/shared/ColorCircleIndicator/ColorCircleIndicator';
import { ThemeColors } from '../../../constants/ThemeColors';
import { Sizing } from '../../../constants/Sizing';

export interface MultiplePricingTableRowProps {
  index: number,
  location?: ApiBusinessLocation,
  onCorporateInfoMessage?: () => void,
  onDelete?: (price: ApiPrice) => void,
  onPricesChanged?: (prices: ApiPrice[]) => void,
  prices: ApiPrice[],
  priceTypes: ApiPriceType[],
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  priceType: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const MultiplePricingTableRow = ({
  index,
  location,
  onCorporateInfoMessage,
  onDelete,
  onPricesChanged,
  prices,
  priceTypes,
}: MultiplePricingTableRowProps) => {
  const [translate] = useTranslation(['common', 'products']);
  const [showRowDetails, setShowRowDetails] = useState<boolean>(false);

  const filteredPrices = useMemo(() => {
    if (location) {
      return prices.filter((p) => p.locationId === location.id);
    }
    return prices.filter((p) => !p.locationId);
  }, [location, prices]);

  const filteredPriceTypes = useMemo(() => {
    return priceTypes.filter(
      (p) => !filteredPrices.some((fp) => fp.priceTypeId === p.id),
    );
  }, [priceTypes, filteredPrices]);

  const locationName = useMemo(() => {
    if (location && filteredPrices.length === 0) {
      return location.locationName;
    }
    if (location && filteredPrices.length > 0) {
      return `${location.locationName} (${filteredPrices.length})`;
    }
    return translate<string>('CORPORATE_LOCATION');
  }, [translate, location, filteredPrices]);

  const addPrice = useCallback(() => {
    const newPrices = [...prices, {
      priceTypeId: null,
      unitPrice: 0,
      locationId: location?.id,
    }];
    onPricesChanged(newPrices);
  }, [location?.id, onPricesChanged, prices]);

  const deletePrice = useCallback((price: ApiPrice) => {
    if (!price.id) {
      prices.splice(prices.findIndex((p) => p === price), 1);
      onPricesChanged([...prices]);
    } else {
      onDelete?.(price);
    }
  }, [onDelete, onPricesChanged, prices]);

  const renderRow = useCallback((price: ApiPrice, priceIndex: number) => {
    return (
      <View key={`${price.priceTypeId}-${price.locationId}`}>
        {
          (priceIndex > 0) && <VSpacer size="8" />
        }
        <View style={styles.row}>
          <NumericInput
            isRequired
            label={translate<string>('PRICE')}
            onChangeValue={(value) => {
              price.unitPrice = value;
              onPricesChanged([...prices]);
            }}
            prefix="$"
            style={{ width: 160 }}
            testID={`location-price-amount-${index}-${priceIndex}`}
            value={price.unitPrice}
          />
          <HSpacer size="7" />
          { !location ? (
            <DataPoint
              label={translate<string>('PRICE_TYPE')}
              style={{ marginTop: 30 }}
              testID={`corporate-location-price-type-${index}-${priceIndex}`}
              valueStyle={styles.priceType}
            >
              <View style={styles.row}>
                { (priceTypes.find((pt) => pt.id === price.priceTypeId)?.isDefault)
                  && (
                  <>
                    <ColorCircleIndicator
                      testID={`default-location-price-type-${index}-${priceIndex}`}
                    />
                    <HSpacer size="4" />
                  </>
                  )}
                <Text category="p2">
                  {priceTypes.find((pt) => pt.id === price.priceTypeId)?.name}
                </Text>
              </View>
            </DataPoint>
          ) : (
            <>
              <Select
                isRequired
                label={translate<string>('PRICE_TYPE')}
                onSelect={(idx: IndexPath | IndexPath[]) => {
                  const { id } = filteredPriceTypes[(idx as IndexPath).row];
                  const updatedPrices = prices.map((p) => {
                    if (p === price) {
                      return { ...p, priceTypeId: id };
                    }
                    return p;
                  });
                  onPricesChanged(updatedPrices);
                }}
                style={{ width: 160 }}
                testID={`location-price-type-${index}-${priceIndex}`}
                value={priceTypes.find((pt) => pt.id === price.priceTypeId)?.name ?? null}
              >
                {filteredPriceTypes
                  .map((type, priceTypeIndex) => (
                    <SelectItem
                      key={type.id}
                      testID={`location-price-type-option-${index}-${priceIndex}-${priceTypeIndex}`}
                      title={type.name}
                    />
                  ))}
              </Select>
              <HSpacer size="4" />
              <IconButton
                appearance="ghost"
                onPress={() => deletePrice(price)}
                status="basic"
                style={{ marginTop: 30 }}
                testID={`delete-location-price-button-${index}-${priceIndex}`}
              >
                Trash
              </IconButton>
            </>
          )}
        </View>
      </View>
    );
  }, [deletePrice, filteredPriceTypes, index, location, onPricesChanged, priceTypes, prices, translate]);

  const renderAddAnotherPriceButton = useCallback(() => {
    return (
      <>
        <VSpacer size="7" />
        <View style={{ width: 150 }}>
          <Button
            accessoryLeft={<Icon name="Plus" testID={`add-another-location-price-button-icon-${index}`} />}
            appearance="ghost"
            disabled={
              filteredPrices.some((p) => !p.priceTypeId)
              || filteredPrices.length === priceTypes.length
            }
            onPress={addPrice}
            size="small"
            testID={`add-another-location-price-button-${index}`}
          >
            {`${translate('ADD_ANOTHER_PRICE')}`}
          </Button>
        </View>
      </>
    );
  }, [addPrice, filteredPrices, index, priceTypes.length, translate]);

  const renderHeaderButton = useCallback(() => {
    if (!location) {
      return (
        <TouchableOpacity
          onPress={() => onCorporateInfoMessage?.()}
          testID="corporate-message-button"
        >
          <Icon
            fill={ThemeColors.SECONDARY}
            name="Info"
            size="medium"
            style={[{ marginRight: Sizing.EM }]}
            testID="corporate-message-button-icon"
          />
        </TouchableOpacity>
      );
    }

    if (filteredPrices.length === 0) {
      return (
        <Button
          accessoryLeft={<Icon name="Plus" testID={`add-location-price-button-icon-${index}`} />}
          appearance="ghost"
          onPress={() => {
            setShowRowDetails(true);
            addPrice();
          }}
          size="small"
          testID={`add-location-price-button-${index}`}
        >
          {translate('ADD_PRICE')}
        </Button>
      );
    }

    return null;
  }, [addPrice, filteredPrices.length, index, location, onCorporateInfoMessage, translate]);

  return (
    <TableRow
      key={location?.id}
      rowDetails={showRowDetails && filteredPrices.length > 0 && (
        <View style={{ left: -15 }} testID={`location-price-row-details-${index}`}>
          <>
            {filteredPrices.map((price, priceIndex) => (renderRow(price, priceIndex)))}
            {!!location && (renderAddAnotherPriceButton())}
          </>
        </View>
      )}
      testID={`location-price-row-${index}`}
    >
      <TableCell style={{ width: 60 }} testID="details-button" usePadding={false}>
        { ((!location && priceTypes.length > 0)
          || (!!location && filteredPrices.length > 0))
          && (
            <IconButton
              appearance="ghost"
              onPress={() => {
                setShowRowDetails(!showRowDetails);
              }}
              size="medium"
              status="basic"
              testID={`location-price-details-button-${index}`}
            >
              {showRowDetails ? 'ChevronDown' : 'ChevronRight'}
            </IconButton>
          )}
      </TableCell>
      <TableCell style={{ width: !location ? 375 : 305 }} testID="name">
        <Text category="s2" testID={`location-name-${index}`}>{locationName}</Text>
      </TableCell>
      <TableCell testID="header-button">
        {renderHeaderButton()}
      </TableCell>
    </TableRow>
  );
};
