import { ApiDeliverablePublic } from '@shared/interfaces/api';
import {
  FarmPlanDeliverableV2 as V2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import React from 'react';
import {
  FarmPlanDeliverableDesktopView,
} from '../../../../src/pages/Deliverable/FarmPlanDeliverableDesktopView';
import { DeliverableRenderer } from '../../DeliverableRenderer';
import { FarmPlanDeliverableMobileView } from './FarmPlanDeliverableMobileView';

export class FarmPlanDeliverableV2PublicRender implements DeliverableRenderer {
  private deliverable: ApiDeliverablePublic<V2.Snapshot, V2.Selections>;
  private isPublicPage: boolean;

  constructor (deliverable: ApiDeliverablePublic) {
    this.deliverable = deliverable;
    this.isPublicPage = true;
  }

  getPlanName (): string {
    return this.deliverable.deliverableData.header.farmPlanName;
  }

  getGrowerName (): string {
    return this.deliverable.deliverableData.header.growerName;
  }

  getBusinessName (): string {
    return this.deliverable.deliverableData.header.businessName;
  }

  getBusinessLocation (): string {
    return this.deliverable.deliverableData.header.locationName;
  }

  getDesktopView () {
    return (
      <FarmPlanDeliverableDesktopView
        deliverableId={this.deliverable.publicId}
        isPublicPage={this.isPublicPage}
        name={this.deliverable.name}
        note={this.deliverable.note}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
        user={this.deliverable.user}
      />
    );
  }

  getMobileView () {
    return (
      <FarmPlanDeliverableMobileView
        deliverableId={this.deliverable.publicId}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
      />
    );
  }
}
