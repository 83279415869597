import { Button, Dialog, List, Text, VSpacer } from '@design';
import { ApiProduct } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DescriptionList, { DescriptionListDirection } from '../DescriptionList/DescriptionList';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';

interface ProductsMissingModalProps {
  onClose: () => void,
  products: ApiProduct[],
  visible: boolean,
}

export const ProductsMissingModal = ({
  onClose,
  products,
  visible,
}: ProductsMissingModalProps) => {
  const [translate] = useTranslation(['common', 'errors']);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp }) => (
        <Button
          {...primaryButtonProp}
          onPress={onClose}
          testID="products-missing-close-button"
        >
          {translate('CLOSE')}
        </Button>
      )}
      onClose={onClose}
      testID="products-missing-modal"
      title={translate('PRODUCTS_NOT_FOUND_MODAL_TITLE_DYNAMICS')}
      visible={visible}>
      <Text>{translate('ERROR_SAVING_ORDER_PRODUCTS_MISSING_DYNAMICS')}</Text>
      <VSpacer size="4" />
      <Text>{translate('PRODUCTS_NOT_FOUND_MODAL_TITLE_DYNAMICS')}:</Text>
      <List testID="products-missing-list">
        {products.map((product) => (
          <DescriptionList direction={DescriptionListDirection.STACKED} key={product.id}>
            <DescriptionListItem label="Product Name" text={product.name} />
            <DescriptionListItem label="SKU" text={product.skuName} />
            <DescriptionListItem label="UoM" text={product.unitUoM} />
          </DescriptionList>
        ))}
      </List>
    </Dialog>
  );
};
