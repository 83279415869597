import React from 'react';
import {
  Modal as UIKModal,
  ModalProps as UIKModalProps,
  StyleType,
} from '@ui-kitten/components';
import { Dimensions, StyleSheet } from 'react-native';
import { useGlobalBanner } from '../Application/GlobalBannerProvider';

const styles = StyleSheet.create({
  defaultModalStyles: {
    alignItems: 'center', 
    justifyContent: 'space-around',
    width: '100%',
    height: '100%',
  },
});

export const BannerAwareModal: React.FC<UIKModalProps> = ({
  style,
  backdropStyle,
  ...modalProps
}) => {
  const { bannerHeight } = useGlobalBanner();
  const bannerAwareStyles: StyleType = {};

  if ((style?.valueOf() as StyleType)?.height === '100%' && bannerHeight) {
    bannerAwareStyles.top = bannerHeight;
    bannerAwareStyles.height = Dimensions.get('window').height - bannerHeight;
  }

  return (
    <UIKModal
      backdropStyle={[{ marginTop: bannerHeight }, backdropStyle]}
      style={[styles.defaultModalStyles, bannerAwareStyles, style ]}
      {...modalProps}
    />
  );
};
