import { SharedConfig } from '@shared/constants';
import { TankMixUtilities, toShortDate } from '@shared/utils';
import React, { FC, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ApiTankMixComponent, ApiTankMix, GrowerEndpoint } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
import {
  ViewRow,
  DataPoint,
  Modal,
  Text,
  TextLink,
  VSpacer,
  useToast,
} from '@design';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { GrowerApi } from '../../../../utilities/api';

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: '#000',
    opacity: 0.7,
  },
});

export const TankMixListTabRowDetails: FC<ApiTankMix> = (tankmix: ApiTankMix) => {
  const { createdAt, lastModified, isActive } = tankmix;
  const { createToast } = useToast();
  const [translate] = useTranslation(['common', 'prepare', 'tankMix']);
  const { currentBusinessId: businessId } = useAuthentication();
  const [showMoreModalVisibility, setShowMoreModalVisibility] = useState(false);
  const allPassComponents = TankMixUtilities.getAllPassComponentsForTankMixes([tankmix]).sort(
    (a, b) => {
      if (a.product?.name < b.product?.name) return -1;
      return 1;
    },
  );

  const { data: customers, isFetched: areCustomersFetched } = useQuery(
    [QueryKeys.GROWER_LIST, businessId, tankmix.assignedGrowers],
    async () => GrowerApi.getGrowers({
      assigned: false,
      businessId,
      ids: tankmix.assignedGrowers,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
      limit: SharedConfig.MAX_PAGE_LIMIT,
    }),
    {
      onError: () => {
        createToast(translate('ERROR_FETCH_GROWERS'));
      },
    },
  );

  const customersText = useMemo(() => {
    if (areCustomersFetched) {
      return tankmix.assignedGrowers?.map((id) => (
        customers.data.find((customer) => customer.id === id)?.legalName
      ))?.join(', ') || '';
    } else {
      return '';
    }
  }, [areCustomersFetched, customers, tankmix.assignedGrowers]);

  const abridgedPassComponents = allPassComponents.slice(0, 6).sort((a, b) => {
    if (a.product?.name < b.product?.name) return -1;
    return 1;
  });

  const isAbridged = allPassComponents.length !== abridgedPassComponents.length;
  const renderPassComponents = (components: ApiTankMixComponent[]) => (
    <>
      {components.map((component) => {
        const applicationRate = `${component.rate} ${component.rateUom}`;
        const productName = component.product
          ? `${component.product.name} (${component.product.skuName})`
          : '';
        return (
          <Text category="p2" key={component.id}>
            {`${productName}: ${translate('APPLICATION_PER_ACRE', { applicationRate })}`}
          </Text>
        );
      })}
    </>
  );

  return (
    <>
      <ViewRow style={{ justifyContent: 'space-between' }}>
        <DataPoint
          label={`${translate('PRODUCTS_AND_APPLICATIONS')}`}
          testID="products-and-applications"
        >
          <>
            {renderPassComponents(abridgedPassComponents)}
            {isAbridged
              && (
                <>
                  <TextLink
                    appearance="secondary"
                    category="p2"
                    onPress={() => setShowMoreModalVisibility(true)}
                    testID="show-more-link"
                  >
                    {`${translate('SHOW_MORE')}`}
                  </TextLink>
                  <Modal
                    backdropStyle={styles.backdrop}
                    onClose={() => setShowMoreModalVisibility(false)}
                    testID="show-more-modal"
                    title={translate('PRODUCTS_AND_APPLICATIONS')}
                    visible={showMoreModalVisibility}
                  >
                    {renderPassComponents(allPassComponents)}
                  </Modal>
                </>
              )}
          </>
        </DataPoint>
        <DataPoint
          label={`${translate('DATE_CREATED')}`}
          testID="date-created"
        >
          {toShortDate(createdAt)}
        </DataPoint>
        <DataPoint
          label={`${translate('DATE_UPDATED')}`}
          testID="date-updated"
        >
          {toShortDate(lastModified)}
        </DataPoint>
      </ViewRow>
      <VSpacer size="8" />
      <ViewRow style={{ justifyContent: 'space-between' }}>
        <DataPoint
          label={`${translate('STATUS')}`}
          testID="status"
        >
          {isActive ? `${translate('ACTIVE')}`
            : `${translate('INACTIVE')}`}
        </DataPoint>
        <DataPoint
          label={`${translate('CUSTOMERS')}`}
          testID="customers"
        >
          {customersText}
        </DataPoint>
      </ViewRow>
    </>
  );
};
