import { IFixed, IFlex, IFixedOrFlex } from './interfaces';

function isFixedWidth (c: IFixedOrFlex): c is IFixed {
  return Object.hasOwnProperty.call(c, 'width');
}
function isFlexWidth (c: IFixedOrFlex): c is IFlex {
  return Object.hasOwnProperty.call(c, 'flex');
}
export const columnWidthStyle = (columnProps: IFixedOrFlex) => {
  if (isFixedWidth(columnProps)) {
    return {
      width: columnProps.width,
    };
  }
  if (isFlexWidth(columnProps)) {
    return {
      flex: columnProps.flex,
    };
  }
  return null;
};
