import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { ContentScroll, ContentScrollProps } from './ContentScroll';

interface Props extends ContentScrollProps { }

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  fillOldContent: {
    marginBottom: -32,
    flex: 1,
  },
  horizontalSpace: {
    paddingHorizontal: 32,
  },
});

// When the new global nav and header are done, this wrapper should
// be modified to work with the new page content container.
// there is a negative margin to counteract the current content padding
// that could be removed
export const StickyHeaderPage: FC<Props> = ({
  children,
  headerContent,
  footerContent,
  scrollViewProps = {},
  ...contentScrollProps
}) => {
  const {
    style: scrollStyle,
    ...restScrollViewProps
  } = scrollViewProps;

  return (
    <ContentScroll
      footerContent={(
        <>
          {footerContent && (
            <View>
              {footerContent}
            </View>
          )}
        </>
      )}
      headerContent={headerContent && (
        <View style={[styles.horizontalSpace]}>
          {headerContent}
        </View>
      )}
      scrollViewProps={{
        style: [scrollStyle],
        ...restScrollViewProps,
      }}
      style={styles.fillOldContent}
      {...contentScrollProps}
    >
      {children}
    </ContentScroll>
  );
};
