import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect, SvgProps } from 'react-native-svg';

export const RallyLogo = (props: SvgProps) => (
  <Svg viewBox="0 0 115 24" {...props}>
    <G clipPath="url(#clip0)">
      <Path d="M21.3414 0C22.0466 0 22.723 0.280975 23.2216 0.781113C23.7203 1.28125 24.0004 1.95958 24.0004 2.66689V24H18.6824V2.66689C18.6824 1.95958 18.9625 1.28125 19.4612 0.781113C19.9599 0.280975 20.6362 0 21.3414 0V0Z" fill="#F4FF24" />
      <Path d="M8.93357 6.22205C9.63879 6.22205 10.3151 6.50302 10.8138 7.00316C11.3125 7.5033 11.5926 8.18163 11.5926 8.88893V23.9998H6.27454V8.88893C6.27454 8.18163 6.55468 7.5033 7.05335 7.00316C7.55201 6.50302 8.22835 6.22205 8.93357 6.22205V6.22205Z" fill="#F4FF24" />
      <Path d="M2.72983 12.4443C3.43505 12.4443 4.11139 12.7253 4.61005 13.2254C5.10872 13.7256 5.38886 14.4039 5.38886 15.1112V23.9999H0.0708008V15.1112C0.0708008 14.4039 0.350948 13.7256 0.849613 13.2254C1.34828 12.7253 2.02461 12.4443 2.72983 12.4443Z" fill="#F4FF24" />
      <Path d="M15.1377 12.4443C15.8429 12.4443 16.5192 12.7253 17.0179 13.2254C17.5165 13.7256 17.7967 14.4039 17.7967 15.1112V23.9999H12.4786V15.1112C12.4786 14.4039 12.7588 13.7256 13.2574 13.2254C13.7561 12.7253 14.4324 12.4443 15.1377 12.4443Z" fill="#F4FF24" />
      <Path d="M17.7963 0H12.3586V11.4033H17.7963V0Z" fill="#969A3D" />
      <Path d="M5.43769 0H0V11.4033H5.43769V0Z" fill="#969A3D" />
      <Path d="M11.5915 0H6.15381V5.45376H11.5915V0Z" fill="#969A3D" />
    </G>
    <Path d="M51.6979 23C51.3255 22.6276 50.9818 21.4531 50.8958 20.5365C50.8958 19.9922 50.8099 19.0755 50.7813 18.2448C50.6667 14.6641 49.7786 12.7448 46.9427 12.3151C48.0599 12.1146 49.0625 11.6849 49.9505 11.0547C50.8672 10.3958 51.3255 9.25 51.3255 7.55989C51.3255 3.32031 48.6615 1 43.7057 1H36V23H40.8984V14.3203H43.0469C45.0807 14.3203 45.6823 15.4661 45.7109 17.0417V18.9323C45.7396 19.6484 45.7396 20.1641 45.7682 20.4792C45.8542 21.4818 46.026 22.5703 46.6276 23H51.6979ZM46.2839 7.58854C46.2839 9.96615 44.9661 10.7109 42.8177 10.7109H40.8984V4.38021H42.2448C44.7943 4.38021 46.2839 5.38281 46.2839 7.58854Z" fill="#E8E8E8" />
    <Path d="M60.25 14.8646C60.25 14.8646 60.3645 14.3776 60.5651 13.375C60.7942 12.3724 61.0234 11.4844 61.2239 10.6823C61.4244 9.90885 61.6536 9.07812 61.8541 8.16146C62.0833 7.24479 62.2265 6.5 62.2838 5.95573C62.3697 6.5 62.513 7.24479 62.7135 8.16146C62.914 9.07812 63.1145 9.90885 63.3151 10.6536L63.9739 13.375C64.2031 14.3776 64.3177 14.8646 64.3177 14.8646H60.25ZM65.4635 1H59.4192L53.3463 23H58.1875L59.3333 18.5026H65.3203L66.5807 23H71.5651L65.4635 1Z" fill="#E8E8E8" />
    <Path d="M86.402 19.1042H78.4385V1H73.5401V23H86.402V19.1042Z" fill="#E8E8E8" />
    <Path d="M101.03 19.1042H93.0663V1H88.1679V23H101.03V19.1042Z" fill="#E8E8E8" />
    <Path d="M110.094 1L106.399 9.47917L102.704 1H97.4328L103.706 14.1771V23H108.605V14.1771L114.85 1H110.094Z" fill="#E8E8E8" />
    <Defs>
      <ClipPath id="clip0">
        <Rect fill="white" height="24" width="24" />
      </ClipPath>
    </Defs>
  </Svg>
);
