import {
  Button,
  Filter,
  Header,
  HSpacer,
  Icon,
  ModalSpinner,
  Text,
  useToast,
  VSpacer,
} from '@design';
import { PlanStatus } from '@shared/enums';
import {
  ApiDiscount,
  ApiFarmPlan,
  ApiProduct,
  ApiUserAccount,
  DiscountEndpoint,
} from '@shared/interfaces/api';
import { FarmUtility } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useParams } from 'react-router-dom';
import { DiscountModalTitle, Routes } from '../../../../../constants';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessDiscounts } from '../../../../../hooks/useBusinessDiscounts';
import { useFarmPlan } from '../../../../../hooks/useFarmPlan';
import { useHistory } from '../../../../../router';
import { DetailedApiError } from '../../../../../utilities/api/DetailedApiError';
import {
  OrderDetailsDiscountModal,
} from '../../../../components/shared/FarmPlan/OrderDetailsDiscountModal';
import {
  CustomerMissingRequestFieldsModal,
} from '../../../../components/shared/IntegrationOrder/CustomerMissingRequireFieldsModal';
import {
  PrimaryContactMissingModal,
} from '../../../../components/shared/IntegrationOrder/PrimaryContactMissingModal';
import {
  ProductsMissingModal,
} from '../../../../components/shared/IntegrationOrder/ProductsMissingModal';
import {
  SalesPersonMissingModal,
} from '../../../../components/shared/IntegrationOrder/SalesPersonMissingModal';
import { ProductCard } from '../../../../components/shared/ProductCard/ProductCard';
import { DiscountsModal } from '../DiscountsModal';
import { FarmPlanAcresPlanned } from '../FarmPlanOrderDetails/FarmPlanAcresPlanned';
import { FarmPlanCostSummary } from '../FarmPlanOrderDetails/FarmPlanCostSummary';
import { FarmPlanDetails } from '../FarmPlanOrderDetails/FarmPlanDetails';
import { ViewFieldDetailsModal } from '../ViewFieldDetailsModal';
import { FarmPlanOverviewFooter } from './FarmPlanOverviewFooter';

const defaultFilters = [];

export const FarmPlanOverviewPage = () => {
  const [translate] = useTranslation(['common', 'errors', 'farmPlans', 'productCard']);
  const { currentBusinessId } = useAuthentication();
  const { businessDiscountsList } = useBusinessDiscounts();
  const { createToast } = useToast();
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [businessId, setBusinessId] = useState(currentBusinessId);
  const [fieldDetailsModalVisible, setFieldDetailsModalVisible] = useState(false);
  const [orderDetailsDiscountModalVisible, setOrderDetailsDiscountModalVisible] = useState(false);
  const [discountsModalVisible, setDiscountsModalVisible] = useState(false);
  const [showProductsMissingModal, setShowProductsMissingModal] = useState(false);
  const [showPrimaryContactMissingModal, setShowPrimaryContactMissingModal] = useState(false);
  const [productsMissing, setProductsMissing] = useState<ApiProduct[]>([]);
  const [showSalesPersonMissingModal, setShowSalesPersonMissingModal] = useState(false);
  const [showCustomerFieldsMissingModal, setShowCustomerFieldsMissingModal] = useState(false);
  const [salespersonMissing, setSalespersonMissing] = useState<ApiUserAccount>();
  const history = useHistory();

  useEffect(() => {
    if (businessId !== currentBusinessId) {
      history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'farm-plans'));
    }
    return () => setBusinessId('');
  }, [businessId, currentBusinessId, history]);

  const styles = useStyleSheet({
    list: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    scrollView: {
      paddingBottom: 150 - 32, // 32 = wrapper padding bottom
    },
  });

  const { grower: customerId, id: farmPlanId } = useParams<{
    grower?: string;
    id: string;
  }>();

  const {
    crops,
    farmPlan,
    farms,
    farmsWithPrograms,
    hasAcceptedOrders,
    initialFarmPlan,
    isLoading,
    passes,
    setFarmPlan,
    summaries,
  } = useFarmPlan({
    farmPlanId,
    defaultCustomerId: customerId,
    onError: (error: Error) => {
      if (error instanceof DetailedApiError
        && error.details.details.error === 'products-missing-dynamics') {
        setShowProductsMissingModal(true);
        setProductsMissing(error.details.details.products);
      } else if (error instanceof DetailedApiError
        && error.details.details.error === 'salesperson-not-found') {
        setShowSalesPersonMissingModal(true);
        setSalespersonMissing(farmPlan.userAccount);
      } else if (error instanceof DetailedApiError
        && error.details.details.error === 'primary-contact-missing') {
        setShowPrimaryContactMissingModal(true);
      } else if (error instanceof DetailedApiError
        && error.details.details.error === 'missing-required-customer-fields') {
        setShowCustomerFieldsMissingModal(true);
      } else {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      }
    },
    useSkuPackagesRequired: false,
  });

  const farmPlanRef = useRef<ApiFarmPlan | undefined>();
  useEffect(() => {
    farmPlanRef.current = farmPlan;
  }, [farmPlan]);

  const handleAddDiscount = useCallback(
    async (newDiscount: DiscountEndpoint.Save.Request, productId?: string) => setFarmPlan({
      ...farmPlanRef.current,
      growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries
        .map(
          (productSummary) => (productSummary.productId === productId ? {
            ...productSummary,
            discounts: [...productSummary.discounts, newDiscount] as ApiDiscount[],
          } : productSummary),
        ),
    }),
    [setFarmPlan],
  );

  const handleDeleteDiscount = useCallback(
    (discountIdx?: number, productId?: string) => setFarmPlan({
      ...farmPlanRef.current,
      growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries
        .map(
          (productSummary) => (productSummary.productId === productId ? {
            ...productSummary,
            discounts: productSummary.discounts.filter(
              (discount, _index) => _index !== discountIdx,
            ),
          } : productSummary),
        ),
    }),
    [setFarmPlan],
  );

  const handleEditDiscount = useCallback((
    newDiscount: DiscountEndpoint.Save.Request,
    discountIdx?: number,
    productId?: string,
  ) => setFarmPlan({
    ...farmPlanRef.current,
    growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries
      .map(
        (productSummary) => (productSummary.productId === productId ? {
          ...productSummary,
          discounts: productSummary.discounts.map((discount, _index) => (
            _index === discountIdx ? newDiscount : discount
          )) as ApiDiscount[],
        } : productSummary),
      ),
  }), [setFarmPlan]);

  const handlePackageQuantityChange = useCallback(
    (packageQuantity: number, totalCost: number, productId: string) => setFarmPlan({
      ...farmPlanRef.current,
      growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries.map(
        (productSummary) => (productSummary.productId === productId ? {
          ...productSummary,
          productSkuPackagesContracted: packageQuantity,
          totalCost,
        } : productSummary),
      ),
    }),
    [setFarmPlan],
  );
  const handlePriceLockedChange = useCallback(
    (unitPriceLocked: boolean, unitPrice: number, productId: string) => setFarmPlan({
      ...farmPlanRef.current,
      growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries?.map(
        (productSummary) => (productSummary.productId === productId ? {
          ...productSummary,
          unitPriceLocked,
          unitPrice,
        } : productSummary),
      ),
    }),
    [setFarmPlan],
  );

  const handlePlanStatusChange = useCallback(
    (planStatus: PlanStatus, productId: string) => setFarmPlan({
      ...farmPlanRef.current,
      growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries?.map(
        (productSummary) => (productSummary.productId === productId ? {
          ...productSummary,
          planStatus,
        } : productSummary),
      ),
    }),
    [setFarmPlan],
  );

  const filteredProducts = useMemo(() => {
    if (!search) {
      return farmPlan.growerFarmPlanProductSummaries ?? [];
    }

    return farmPlan.growerFarmPlanProductSummaries?.filter(
      (productSummary) => productSummary.product?.skuName.toLowerCase().includes(
        search.toLowerCase(),
      ),
    );
  }, [search, farmPlan.growerFarmPlanProductSummaries]);

  const updateFarmPlan = async (newFarmPlan: Partial<ApiFarmPlan>): Promise<ApiFarmPlan> => {
    setFarmPlan(newFarmPlan as ApiFarmPlan, true);
    return newFarmPlan as ApiFarmPlan;
  };

  const handleCancelSku = useCallback((productId?: string) => setFarmPlan({
    ...farmPlanRef.current,
    growerFarmPlanProductSummaries: farmPlanRef.current.growerFarmPlanProductSummaries?.map(
      (productSummary) => (productSummary.productId === productId ? {
        ...productSummary,
        cancelled: true,
      } : productSummary),
    ),
  }),
  [setFarmPlan]);

  return (
    <>
      {isLoading && <ModalSpinner visible />}
      <ScrollView
        style={styles.scrollView}
        testID="farm-plan-order-details-page"
      >
        <Text appearance="hint" category="overline">
          {translate<string>('FARM_PLAN_ORDER_DETAILS')}
        </Text>
        <Text category="h3" testID="farm-plan-order-details-name">{farmPlan.planName}</Text>
        <VSpacer size="8" />
        <View style={styles.list}>
          <FarmPlanDetails farmPlan={farmPlan} updateFarmPlan={updateFarmPlan} />
          <HSpacer size="7" />
          <FarmPlanAcresPlanned
            acresPlannedSummary={summaries.acresPlanned}
            passes={passes}
          />
          <HSpacer size="7" />
          <FarmPlanCostSummary
            onViewDiscounts={() => setDiscountsModalVisible(true)}
            profitCalculation={summaries.profit}
          />
        </View>
        <VSpacer size="8" />
        <Header level="3" testID="order-details-header" title={translate('ORDER_DETAILS')} />
        <Filter
          accessoryRight={() => (
            <View style={{ flexDirection: 'row' }}>
              <Button
                accessoryLeft={(buttonProps) => (
                  <Icon {...buttonProps} name="Pricetags" testID="add-order-discount-button-icon" />
                )}
                appearance="ghost"
                onPress={() => setOrderDetailsDiscountModalVisible(true)}
                size="medium"
                status="basic"
                testID="add-order-discount-button"
              >
                {translate<string>('ADD_ORDER_DISCOUNT')}
              </Button>
              <Button
                accessoryLeft={(buttonProps) => (
                  <Icon {...buttonProps} name="GrowersViewDetails" testID="view-field-details-button-icon" />
                )}
                appearance="ghost"
                onPress={() => setFieldDetailsModalVisible(true)}
                size="medium"
                status="basic"
                testID="view-field-details-button"
              >
                {translate<string>('VIEW_FIELD_DETAILS')}
              </Button>
            </View>
          )}
          defaultColumnFilters={defaultFilters}
          expandAllAppearance="default"
          filterOptions={[]}
          noFilters
          onExpand={setExpanded}
          onUpdateFilter={(filter) => setSearch(filter.search)}
          placeHolderText={translate('SEARCH_PRODUCTS')}
          showExpandAllOption
          showSelectAll
          testID="farm-plan-order-details-page-filter"
          totalResults={filteredProducts.length}
          totalResultsText={translate(
            filteredProducts.length === 1 ? 'ONE_PRODUCT' : 'COUNT_PRODUCTS',
            { count: filteredProducts.length },
          )}
        />
        <VSpacer size="5" />
        {filteredProducts?.length ? filteredProducts.map((productSummary, productIdx) => (
          <Fragment key={productSummary.id}>
            <ProductCard
              expanded={expanded}
              initialProductSummary={initialFarmPlan.growerFarmPlanProductSummaries?.find(
                ({ id }) => id === productSummary.id)}
              locationId={farmPlan.businessLocationId}
              onAddDiscount={handleAddDiscount}
              onCancelSku={handleCancelSku}
              onDeleteDiscount={handleDeleteDiscount}
              onEditDiscount={handleEditDiscount}
              onPackageQuantityChange={handlePackageQuantityChange}
              onPlanStatusChange={handlePlanStatusChange}
              onPriceLockedChange={handlePriceLockedChange}
              priceTypeId={farmPlan.priceTypeId}
              productId={productSummary.productId}
              productIdx={productIdx}
              productSummary={productSummary}
            />
            {filteredProducts.length > 1 && <VSpacer size="5" />}
          </Fragment>
        )) : (
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text>
              {translate<string>(!search ? 'NO_PRODUCTS' : 'NO_PRODUCTS_MATCH_SEARCH')}
            </Text>
          </View>
        )}
      </ScrollView>
      {orderDetailsDiscountModalVisible && (
        <OrderDetailsDiscountModal
          businessDiscountsList={businessDiscountsList}
          discountSkuEditActionTitle={DiscountModalTitle.ADD_ORDER_DISCOUNT}
          discounts={farmPlan.discounts}
          onApply={(newDiscount) => {
            setFarmPlan({
              ...farmPlan,
              discounts: [...farmPlan.discounts, newDiscount] as ApiDiscount[],
            });
            setOrderDetailsDiscountModalVisible(false);
          }}
          onClose={() => setOrderDetailsDiscountModalVisible(false)}
        />
      )}
      {discountsModalVisible && (
        <DiscountsModal
          businessDiscountsList={businessDiscountsList}
          discounts={farmPlan.discounts}
          onClose={() => {
            setDiscountsModalVisible(false);
          }}
          onDelete={async (discountIdx, productId) => {
            if (!productId) {
              setFarmPlan({
                ...farmPlan,
                discounts: farmPlan.discounts.filter(
                  (discount, index) => index !== discountIdx,
                ),
              });
            } else {
              await handleDeleteDiscount(discountIdx, productId);
            }
          }}
          onEdit={async (newDiscount, discountIdx, productId) => {
            if (!productId) {
              setFarmPlan({
                ...farmPlan,
                discounts: farmPlan.discounts.map((discount, index) => (
                  index === discountIdx ? newDiscount : discount
                )) as ApiDiscount[],
              });
            } else {
              await handleEditDiscount(newDiscount, discountIdx, productId);
            }
          }}
          productSummaries={
            summaries.products.sort((a, b) => a.productId.localeCompare(b.productId))
          }
          profitSummary={{
            ...summaries.profit,
            products: summaries.profit.products.sort(
              (a, b) => a.productId.localeCompare(b.productId),
            ),
          }}
        />
      )}
      {fieldDetailsModalVisible && (
        <ViewFieldDetailsModal
          crops={crops}
          growerFarms={farms}
          onClose={() => setFieldDetailsModalVisible(false)}
          zoneSummaries={FarmUtility.getZoneProductSummaries(farmPlanId, farmsWithPrograms)}
        />
      )}
      <FarmPlanOverviewFooter
        farmPlan={farmPlan}
        hasAcceptedOrders={hasAcceptedOrders}
        initialFarmPlan={initialFarmPlan}
        setFarmPlan={setFarmPlan}
      />
      {showProductsMissingModal &&
        <ProductsMissingModal
          onClose={() => setShowProductsMissingModal(false)}
          products={productsMissing}
          visible
        />
      }
      {showSalesPersonMissingModal &&
        <SalesPersonMissingModal
          onClose={() => setShowSalesPersonMissingModal(false)}
          salesperson={salespersonMissing}
          visible
        />
      }
      {showPrimaryContactMissingModal &&
        <PrimaryContactMissingModal
          onClose={() => setShowPrimaryContactMissingModal(false)}
          visible
        />
      }
      {showCustomerFieldsMissingModal &&
        <CustomerMissingRequestFieldsModal
          onClose={() => setShowCustomerFieldsMissingModal(false)}
          visible
        />
      }
    </>
  );
};
