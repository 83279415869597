import { useState } from 'react';
import { useQuery } from 'react-query';
import { ImageURISource } from 'react-native';
import { LogoApi } from '../utilities/api/LogoApi';
import { QueryKeys } from '../constants';

export const useLogo = (businessId: string) => {
  const [logo, setLogo] = useState<ImageURISource>(null);
  const [error, setError] = useState<Error>(null);
  const getLogo = async () => {
    return businessId ? LogoApi.getLogo(businessId) : null;
  };
  const readDataToState = (res) => {
    if (res) {
      const reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onload = () => {
        const base64Data = reader.result;
        setLogo({ uri: base64Data as string });
      };
    } else {
      setLogo(null);
    }
  };

  const { data, isLoading } = useQuery(
    [QueryKeys.BUSINESS_LOGO, businessId],
    getLogo,
    {
      onError: setError,
      onSuccess: readDataToState,
      staleTime: 5 * 60 * 1000,
    },
  );

  if (data && !logo) {
    readDataToState(data);
  }

  return { logo, error, isLoading };
};
