import React, { FunctionComponent } from 'react';
import {
  CheckBox as UIKCheckbox,
  CheckBoxProps as UIKCheckboxProps,
} from '@ui-kitten/components';
import { FalsyText } from '@ui-kitten/components/devsupport';
import { Status } from '@theme/variant-interfaces/Status';
import { getTestIdWithPostfix } from '../../../../utilities';

export interface CheckBoxProps extends UIKCheckboxProps {
  status?: Status,
  testID: string,
}

export const CheckBox: FunctionComponent<CheckBoxProps> = ({
  children,
  testID = undefined,
  ...props
}) => (
  <UIKCheckbox
    testID={getTestIdWithPostfix(testID, props.checked ? 'checked' : 'unchecked')}
    {...props}
  >
    {({ style: evaStyle, ...evaProps }) => (
      <FalsyText
        {...evaProps}
        component={children}
        style={[
          {
            letterSpacing: 0.2,
          },
          evaStyle,
        ]}
      />
    )}
  </UIKCheckbox>
);
