import { Icon, Text } from '@design';
import {
  FarmPlanDeliverableV1,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v1/FarmPlanDeliverableV1';
import { get as _get, isNil as _isNil } from 'lodash';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Sizing, ThemeColors } from '../../../../constants';
import { ModalUtility } from '../../../../elements/content';
import { Card } from '../../../../ui-components';
import { StringUtility } from '../../../../utilities';

interface ColumnWidths {
  productSkuName: number,
  packagesContracted: number,
  unitPrice: number,
  priceLocked: number,
  costPerAcre: number,
  totalCost: number,
  planStatus: number,
  infoModal: number,
}

interface FarmPlanProductDiscountSummaryProps {
  selections?: FarmPlanDeliverableV1.Selections;
  discounts: FarmPlanDeliverableV1.ProductDiscount[];
  discountedPrice: FarmPlanDeliverableV1.CalculatedProductCost;
  columnWidths: ColumnWidths
}

const styles = StyleSheet.create({
  simulatedTableRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  summaryTableText: {
    fontSize: 16,
    marginBottom: Sizing.BASE_SPACING,
  },
  quickViewWrapper: {
    flexDirection: 'row',
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  icon: {
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
    marginLeft: 8,
  },
});

const InfoModal: FC = () => {
  const [translate] = useTranslation(['farmPlans']);

  return (
    <View nativeID="info-modal">
      <Card disabled testID="info-modal-card">
        <Text style={[styles.largeText, { textAlign: 'center' }]}>
          {translate('DISCOUNT_INFO_MESSAGE_1') as string}
        </Text>
        <Text style={[styles.largeText, { textAlign: 'center' }]}>
          {translate('DISCOUNT_INFO_MESSAGE_2') as string}
        </Text>
      </Card>
    </View>
  );
};

const FarmPlanDeliverableProductDiscountSummaryQuickView: FC<
  FarmPlanProductDiscountSummaryProps
> = ({
  selections,
  discounts,
  discountedPrice,
  columnWidths,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);
  const showField = useCallback((name: string) => (_isNil(selections)
    ? true : _get(selections, `orderDetails.${name}`) === true), [selections]);

  return (
    <>
      {(showField('totalCost') || showField('costPerAcre') || showField('unitPrice')) && (
        <View style={[styles.quickViewWrapper, { flexDirection: 'column' }]}>
          {discounts.map((discount) => (
            <View key={discount.name} style={styles.simulatedTableRow}>
              <View style={{ flex: 0.5 }} />
              <View style={{ flex: columnWidths.productSkuName }}>
                <Text numberOfLines={1} style={styles.summaryTableText}>
                  {discount.name}
                  <TouchableOpacity onPress={() => ModalUtility.show(<InfoModal />)}>
                    <Icon
                      name="Info"
                      style={styles.icon}
                      testID="info-icon"
                    />
                  </TouchableOpacity>
                </Text>
              </View>
              <View style={{ flex: columnWidths.packagesContracted }}>
                <Text style={[styles.summaryTableText, { color: ThemeColors.DISCOUNT }]}>
                  {`${translate('PERCENT_OFF', { percent: StringUtility.formatDecimal(discount.percent, 1) })}`}
                </Text>
              </View>
              {showField('unitPrice') && (
                <View style={{ flex: columnWidths.unitPrice }}>
                  <Text style={[styles.summaryTableText, { color: ThemeColors.DISCOUNT }]}>
                    {StringUtility.formatDiscount((discount.dollarsPerUnit))}
                  </Text>
                </View>
              )}
              {showField('priceLocked') && (
                <View style={{ flex: columnWidths.priceLocked }} />
              )}
              {showField('costPerAcre') && (
                <View style={{ flex: columnWidths.costPerAcre }}>
                  <Text style={[styles.summaryTableText, { color: ThemeColors.DISCOUNT }]}>
                    {`${StringUtility.formatDiscount(discount.dollarsPerAcre)}`}
                  </Text>
                </View>
              )}
              {showField('totalCost') && (
                <View style={{ flex: columnWidths.totalCost }}>
                  <Text style={[styles.summaryTableText, { color: ThemeColors.DISCOUNT }]}>
                    {`${StringUtility.formatDiscount(discount.dollarsTotal)}`}
                  </Text>
                </View>
              )}
              <View style={{ flex: columnWidths.planStatus }} />
              <View style={{ flex: columnWidths.infoModal }} />
            </View>
          ))}
          <View style={styles.simulatedTableRow}>
            <View style={{ flex: 0.5 }} />
            <Text style={[styles.summaryTableText, { flex: columnWidths.productSkuName }]}>
              {translate('DISCOUNTED_PRICE') as string}
            </Text>
            <View style={{ flex: columnWidths.packagesContracted }} />
            {showField('unitPrice') && (
              <View style={{ flex: columnWidths.unitPrice }}>
                <Text style={styles.summaryTableText}>
                  {StringUtility.formatCurrencyAccounting(discountedPrice.perUnit)}
                </Text>
              </View>
            )}
            {showField('priceLocked') && (
              <View style={{ flex: columnWidths.priceLocked }} />
            )}
            {showField('costPerAcre') && (
              <View style={{ flex: columnWidths.costPerAcre }}>
                <Text style={styles.summaryTableText}>
                  {StringUtility.formatCurrencyAccounting(discountedPrice.perAcre)}
                </Text>
              </View>
            )}

            {showField('totalCost') && (
              <View style={{ flex: columnWidths.totalCost }}>
                <Text style={styles.summaryTableText}>
                  {StringUtility.formatCurrencyAccounting(discountedPrice.total)}
                </Text>
              </View>
            )}
            <View style={{ flex: columnWidths.planStatus }} />
            <View style={{ flex: columnWidths.infoModal }} />
          </View>
        </View>
      )}
    </>
  );
};

export default FarmPlanDeliverableProductDiscountSummaryQuickView;
