import { Card, Header, Icon, NotesModal, TextLink, VSpacer } from '@design';
import { Mode } from '@design/NotesModal/NotesModal';
import { IntegrationType } from '@shared/enums';
import { ApiFarmPlan } from '@shared/interfaces/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../../hooks/useIntegration';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import { getCustomerLabel } from '../../../../../utilities';
import {
  BillingShippingContactsModal,
} from '../../../../components/shared/BillingShippingContactsModal/BillingShippingContactsModal';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import {
  ExternalDisplayIdText,
} from '../../../../components/shared/IntegrationOrder/ExternalDisplayIdText';
import { ExternalIdsModal } from '../../../../components/shared/IntegrationOrder/ExternalIdsModal';
import { useCustomerContacts } from '../../../../../hooks/useCustomerContacts';

interface FarmPlanDetailsProps {
  farmPlan: ApiFarmPlan,
  updateFarmPlan: (farmPlan: Partial<ApiFarmPlan>) => void,
}

export const FarmPlanDetails = ({
  farmPlan,
  updateFarmPlan,
}: FarmPlanDetailsProps) => {
  const [translate] = useTranslation(['farmPlans', 'common', 'integration']);
  const { priceTypes } = usePriceTypeList();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [showExternalIdsModal, setShowExternalIdsModal] = useState(false);
  const { currentBusinessId } = useAuthentication();
  const { data: contacts } = useCustomerContacts(farmPlan.growerId);
  const { integration } = useIntegration({ businessId: currentBusinessId });
  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );

  const externalDisplayIds = farmPlan.growerFarmPlanProductSummaries?.map(
    (summary) => summary.externalDisplayId,
  ).filter(Boolean) ?? [];

  return (
    <View style={{ flex: 1 }}>
      {showContactsModal && (
        <BillingShippingContactsModal
          billingContact={contacts.find((c) => c.id === farmPlan.billingContactId)}
          onClose={() => setShowContactsModal(false)}
          shippingContact={contacts.find((c) => c.id === farmPlan.shippingContactId)}
          showModal={showContactsModal}
        />
      )}
      {showNotesModal && (
        <NotesModal
          mode={farmPlan.note ? Mode.EDIT : Mode.ADD}
          note={farmPlan.note ?? ''}
          onClose={() => setShowNotesModal(false)}
          onDelete={() => updateFarmPlan({ ...farmPlan, note: null })}
          setNote={(note) => updateFarmPlan({ ...farmPlan, note })}
        />
      )}
      {showExternalIdsModal &&
        <ExternalIdsModal
          externalIds={externalDisplayIds}
          onClose={() => setShowExternalIdsModal(false)}
          visible
        />
      }
      <Header
        level="3"
        testID="farm-plan-details"
        title={translate('DETAILS')}
      />
      <VSpacer size="4" />
      <Card style={{ flex: 1 }} testID="farm-plan-details-card">
        {hasBusinessCentralIntegration &&
          <>
            <DescriptionListItem
              label={translate('EXTERNAL_ID')}
              testID="farm-plan-external-id"
              text={
                externalDisplayIds.length > 1 ? (
                  <TextLink
                    appearance="secondary"
                    category="p2"
                    onPress={() => setShowExternalIdsModal(true)}
                    testID="farm-plan-show-external-ids-link"
                  >
                    {translate<string>('VIEW_IDS')}
                  </TextLink>
                ) : (
                  <ExternalDisplayIdText externalDisplayId={externalDisplayIds[0]} />
                )
              }
              textCategory="p2"
            />
            <VSpacer size="4" />
          </>
        }
        <DescriptionListItem
          label={translate('CUSTOMER')}
          testID="farm-plan-customer"
          text={getCustomerLabel(farmPlan.grower)}
          textCategory="p2"
        />
        <VSpacer size="4" />
        <DescriptionListItem
          label={translate('SALESPERSON')}
          testID="farm-plan-salesperson"
          text={farmPlan.userAccount?.fullName}
          textCategory="p2"
        />
        <VSpacer size="4" />
        <DescriptionListItem
          label={translate('BUSINESS')}
          testID="farm-plan-business"
          text={farmPlan.business?.businessName}
          textCategory="p2"
        />
        <VSpacer size="4" />
        <DescriptionListItem
          label={translate('PHONE_NUMBER')}
          testID="farm-plan-phone-number"
          text={farmPlan.userAccount?.telephone}
          textCategory="p2"
        />
        <VSpacer size="4" />
        <DescriptionListItem
          label={translate('LOCATION')}
          testID="farm-plan-location"
          text={farmPlan.businessLocation?.locationName ?? translate('CORPORATE_LOCATION')}
          textCategory="p2"
        />
        <VSpacer size="4" />
        {(!!farmPlan.priceTypeId && priceTypes) && (
          <>
            <DescriptionListItem
              label={translate('PRICE_TYPE')}
              testID="farm-plan-price-type"
              text={
                priceTypes.find((pt) => pt.id === farmPlan.priceTypeId)?.name
              }
              textCategory="p2"
            />
            <VSpacer size="4" />
          </>
        )}
        {!!farmPlan.cropYear && (
          <>
            <DescriptionListItem
              label={translate('CROP_YEAR')}
              testID="farm-plan-crop-year"
              text={`${farmPlan.cropYear}`}
              textCategory="p2"
            />
            <VSpacer size="4" />
          </>
        )}
        <>
          <DescriptionListItem
            label={translate('BILLING_AND_SHIPPING_CONTACTS')}
            testID="farm-plan-billing-shipping-contact"
            textCategory="p2"
          >
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowContactsModal(true)}
              testID="farm-plan-billing-shipping-contact-link"
            >
              {translate<string>('VIEW')}
            </TextLink>
          </DescriptionListItem>
          <VSpacer size="4" />
        </>
        <DescriptionListItem
          label={translate('NOTE')}
          testID="farm-plan-note"
          textCategory="p2"
        >
          <TextLink
            accessoryLeft={(iconProps) => (!farmPlan.note ? <Icon name="Plus" testID="add-note-icon" {...iconProps} /> : <></>)}
            accessoryRight={(iconProps) => (farmPlan.note ? <Icon name="Edit" testID="view-note-icon" {...iconProps} /> : <></>)}
            appearance="secondary"
            category="p2"
            onPress={() => setShowNotesModal(true)}
            testID="farm-plan-note-link"
          >
            {translate<string>(farmPlan.note ? 'VIEW_NOTE' : 'ADD_NOTE')}
          </TextLink>
        </DescriptionListItem>
      </Card>
    </View>
  );
};
