import React, { Component } from 'react';
import {
  StyleProp,
  ViewStyle,
  View,
  ViewProps,
} from 'react-native';
import {
  Overwrite,
} from '@ui-kitten/components/devsupport';
import {
  Interaction,
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { RowDetail } from './RowDetail';

type TableRowStyledProps = Overwrite<StyledComponentProps, {}>;

export interface TableRowProps extends ViewProps, TableRowStyledProps {
  appearance?: 'default' | 'header';
  onSelect?: (selected: boolean) => void;
  noBottomBorder?: boolean;
  rowDetails?: React.ReactNode;
  rowDetailId: string;
  rowDetailStyle?: StyleProp<ViewStyle>;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  testID: string;
}

/**
 * A single row within the table component.
 * Accepts children of type TableRow
 *
 * @property {ChildrenWithProps<TableHeaderProps | TableCellProps>} children -
 * TableHeader or TableCell components to render within the table row.
 *
 * @property {StyleProp<ViewStyle>} style - Custom style sent to the row component.
 *
 */

@styled('TableRow')
export class TableRow extends Component<TableRowProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<TableRowProps> = {
    selected: false,
  };

  private onMouseEnter = (): void => {
    this.props.eva.dispatch([Interaction.HOVER]);
  };

  private onMouseLeave = (): void => {
    this.props.eva.dispatch([]);
  };

  private onPress = (): void => {
    this.props.eva.dispatch([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.props.onSelect && this.props.onSelect(!this.props.selected);
  };

  private getComponentStyle = (source: StyleType) => {
    const { flexDirection, ...tableRowStyles } = source;
    return {
      tableRow: {
        ...tableRowStyles,
      },
      dataRow: {
        flexDirection,
      },
    };
  };

  public render () {
    const {
      children,
      rowDetails,
      eva,
      style,
      rowDetailId,
      rowDetailStyle,
      noBottomBorder,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View
        style={[evaStyle.tableRow, noBottomBorder && { borderBottomWidth: 0 }]}
        {...{
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
          onPress: this.onPress,
        }}
      >
        <View
          style={[evaStyle.dataRow, style]}
          {...viewProps}
        >
          {children}
        </View>
        {rowDetails && (
          <RowDetail style={[{ marginLeft: 72 }, rowDetailStyle]} testID={rowDetailId}>
            {rowDetails}
          </RowDetail>
        )}
      </View>
    );
  }
}
