import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VSpacer, HSpacer, Text, Input, ResponsiveView } from '@design';
import { FlatList, View } from 'react-native';

interface IPasswordFormProps {
  onChangePassword: (password: string) => void;
  onFormValidation: (isValid: boolean) => void;
  error?: string
}

export const PasswordForm: FC<IPasswordFormProps> = ({
  onChangePassword,
  onFormValidation,
  error,
}) => {
  const [translate] = useTranslation(['login']);
  const [displayError, setDisplayError] = useState<string>(error);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [retype, setRetype] = useState<string>('');

  useEffect(() => {
    setDisplayError(error);
  }, [error]);

  useEffect(() => {
    // check form validation after every change to password and retype
    // the output of this will determine if the form is ready to submit
    // this should NOT cause errors to be shown while typing

    const hasPassword = !!password;
    const hasRetype = !!retype;
    const match = password === retype;

    const isValid = hasPassword
      && hasRetype
      && match;

    if (password === retype) {
      // only clear an existing error when passwords become matching
      // do not set the error condition here as the user is still typing
      setPasswordMismatch(false);
    }

    onFormValidation(isValid);
  }, [password, retype, onFormValidation]);

  const checkMismatch = useCallback(() => {
    // used to set error condition on mismatch
    // should be used only with onBlur to set the error after user is finished with field
    // only set error if both fields have a value
    setPasswordMismatch(!!password && !!retype && password !== retype);
  }, [password, retype]);

  const typePassword = useCallback((newPassword) => {
    if (displayError) {
      setDisplayError('');
    }
    setPassword(newPassword);
    onChangePassword(newPassword);
  }, [displayError, onChangePassword]);

  const RULES = [
    {
      no: '1',
      rule: translate('PASSWORD_RULE_LENGTH'),
    },
    {
      no: '2',
      rule: translate('PASSWORD_RULE_EMAIL'),
    },
    {
      no: '3',
      rule: translate('PASSWORD_RULE_NAME'),
    },
  ];

  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <ResponsiveView large={4} medium={8} small={6} xLarge={4} xSmall={4}>
        <VSpacer size="10" />
        <VSpacer size="8" />
        <Input
          caption={displayError}
          label={translate<string>('NEW_PASSWORD')}
          onBlur={checkMismatch}
          onChangeText={typePassword}
          secureTextEntry
          size="medium"
          status={passwordMismatch || displayError ? 'danger' : undefined}
          testID="password"
          value={password}
        />
        <VSpacer size="8" />
        <Input
          caption={passwordMismatch ? translate<string>('PASSWORD_MISMATCH') : null}
          label={translate<string>('REENTER_PASSWORD')}
          onBlur={checkMismatch}
          onChangeText={(next) => setRetype(next)}
          secureTextEntry
          size="medium"
          status={passwordMismatch ? 'danger' : undefined}
          testID="verify-password"
          value={retype}
        />
        <VSpacer size="8" />
        <FlatList
          ListHeaderComponent={() => (
            <>
              <Text appearance="hint" category="p2">
                {translate<string>('PASSWORD_MUST')}
              </Text>
              <VSpacer size="4" />
            </>
          )}
          data={RULES}
          keyExtractor={(item) => item.no}
          renderItem={(data) => (
            <View style={{ flexDirection: 'row' }}>
              <HSpacer size="3" />
              <Text appearance="hint" category="p2">
                {'\u2022'}
                <HSpacer size="3" />
                {data.item.rule}
              </Text>
            </View>
          )}
        />
      </ResponsiveView>
    </View>
  );
};
