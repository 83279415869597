export default {
  ADD: 'Add',
  ADD_OPTION: 'Add option',
  ADD_OTHER: 'Add other',
  ADD_PROPERTY_CALL_TO_ACTION: 'Get started by adding a property.',
  ADD_PROPERTY_TITLE: 'Add Property',
  DELETE_PROPERTY_GROUP: 'Delete property group?',
  DELETE_PROPERTY_GROUP_WARNING: 'Deleting this property group will remove all customer data associated with it.  This action cannot be undone.',
  DELETE_PROPERTY_GROUP_CHALLENGE_MESSAGE: 'Are you sure you want to delete this property group?',
  OPTION_TITLE: 'Option',
  PROPERTIES_TAB_DESCRIPTION: 'Create and manage the customer properties you want to track in Growers Rally.',
  PROPERTIES_TAB_CALL_TO_ACTION: 'Get started by creating a property group.',
  PROPERTY_CREATE_BUTTON_TITLE: 'Create Property Group',
  PROPERTY_EDIT_TITLE: 'Edit Property Group',
  PROPERTY_GROUP: 'Property group',
  PROPERTY_GROUPS: 'Property groups',
  PROPERTY_GROUP_CREATED: 'Property group successfully created',
  PROPERTY_GROUP_DELETED: 'Property group successfully deleted',
  PROPERTY_GROUP_UPDATED: 'Property group successfully updated',
  PROPERTY_GROUP_NAME: 'Property group name',
  PROPERTY_GROUP_DESCRIPTION: 'Property group description',
  PROPERTY_LABEL: 'Property label',
  PROPERTY_TYPE: 'Property type',
  UNTITLED_PROPERTY: 'Untitled property',
  dropdown: 'Drop Down',
  multiSelect: 'Multi Select',
  paragraph: 'Paragraph',
  shortAnswer: 'Short Answer',
  singleSelect: 'Single Select',
};
