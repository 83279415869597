/**
 * A thrown api error in case the HTTP response contains details
 * about the error that can be used by the UI to display user friendly
 * error messages
 */
export class DetailedApiError extends Error {
  constructor (public code: string, message: string, public details: any) {
    super(message);
  }
}

export const errorIsDetailedApiError = (error: Error): error is DetailedApiError => (
  Object.hasOwnProperty.call(error, 'code')
);

export const detailsHasErrors = (details: any) : details is { errors: any[] } => (
  Object.hasOwnProperty.call(details, 'errors')
  && Array.isArray(details.errors)
);
