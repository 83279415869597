import {
  Card,
  HSpacer,
  IconButton,
  NumericInput,
  Text,
  VSpacer,
} from '@design';
import React, { ReactNode } from 'react';
import { TouchableOpacity, View, ViewProps, StyleSheet } from 'react-native';
import { ApiFormField } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
interface PropertyMenuItemProps extends ViewProps {
  field: ApiFormField,
  leftAccessory?: ReactNode,
  isOnlyProperty: boolean,
  isSelected: boolean,
  onChangeOrder?: (order: number) => void,
  onDelete: () => void,
  onPress?: (field: ApiFormField) => void,
}

const styles = StyleSheet.create({
  unselectedItem: {
    minHeight: 64,
    paddingVertical: 12,
    paddingHorizontal: 12,
    paddingBottom: 12,
  },
  selectedItem: {
    backgroundColor: 'rgba(255,255,255,.1)',
    minHeight: 64,
    paddingVertical: 12,
    paddingHorizontal: 12,
    paddingBottom: 12,
  },
});
  
const inputHeight = 48;
const inputWidth = 72;
  
export const PropertyMenuItem = ({
  field,
  isOnlyProperty,
  isSelected,
  leftAccessory,
  onChangeOrder,
  onDelete,
  onPress,
  ...viewProps
}: PropertyMenuItemProps) => {
  const [translate] = useTranslation(['propertyGroups']);
  const { name, order, type } = field;
  
  return (
    <Card
      style={isSelected ? styles.selectedItem : styles.unselectedItem}
      testID="property-menu-item"
      {...viewProps}
    >
      <TouchableOpacity
        onPress={() => onPress(field)}
      >
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View>
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              {leftAccessory && (
                <>
                  {leftAccessory}
                  <HSpacer size="5"/>
                </>
              )}
              <NumericInput
                inputContainerStyle={{ height: inputHeight, width: inputWidth }}
                onChangeValue={(newOrder) => onChangeOrder(newOrder)}
                precision={0}
                testID="property-order-input"
                textStyle={{ textAlign: 'center' }}
                value={order + 1}
              />
            </View>
          </View>
          <HSpacer size="5"/>
          <View style={{ flex: 1 }}>
            <VSpacer size="2"/>
            <Text category="s2" testID="property-name">
              {name}
            </Text>
            <VSpacer size="3" />
            <Text category="c1" testID="property-type" wrap>
              {translate(type)} 
            </Text>
          </View>
          <HSpacer size="5"/>
          <View>
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              <IconButton
                appearance="ghost"
                disabled={isOnlyProperty}
                onPress={onDelete}
                size="large"
                status="basic"
                testID="property-delete-icon-button"
              >
                Trash
              </IconButton>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </Card>
  );
};
