import React, { FC, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ThemeColors, Sizing } from '../../constants';
import { Text } from '../../ui-components';

interface Props {
  note: string;
}
const NUMBER_OF_LINES = 1;
const LINE_HEIGHT = Sizing.EM;
const styles = StyleSheet.create({
  noteRow: {
    flex: 1,
    marginTop: Sizing.EM,
    paddingBottom: Sizing.EM,
    borderBottomColor: ThemeColors.MID_GRAY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  noteText: {
    lineHeight: LINE_HEIGHT,
  },
});
export const ShowNotes: FC<Props> = ({ note }) => {
  const [translate] = useTranslation(['deliverable']);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const showNotes = () => setIsCollapsed(!isCollapsed);
  const onLayout = (e) => {
    if (shouldCollapse) {
      return;
    }
    const { height } = e.nativeEvent.layout;
    const countOfLines = Math.floor(height / LINE_HEIGHT);
    if (countOfLines > NUMBER_OF_LINES) {
      setShouldCollapse(true);
      setIsCollapsed(true);
    }
  };
  return (
    <View
      style={[styles.noteRow, isCollapsed && { flexDirection: 'row' }]}
    >
      <View onLayout={onLayout} style={{ flex: 1 }}>
        <Text numberOfLines={isCollapsed ? NUMBER_OF_LINES : undefined} style={{ flex: 1 }}>
          {note}
        </Text>
      </View>
      {shouldCollapse && (
        <Text
          onPress={showNotes}
          style={[
            { color: ThemeColors.PRIMARY },
            isCollapsed && { marginRight: Sizing.DOUBLE_SPACING },
            !isCollapsed && { alignSelf: 'flex-end' },
          ]}
        >
          {isCollapsed ? translate('READ_MORE') : translate('READ_LESS')}
        </Text>
      )}
    </View>
  );
};
