import React, { FunctionComponent, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useHistory } from 'react-router-dom';
import { CheckBox, IndexPath } from '@ui-kitten/components';
import { Card, Icon, IconName, IconNames, Input, Select, SelectItem } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  content: {
    borderColor: '#FFF',
    borderStyle: 'dashed',
    borderWidth: 2,
    flex: 1,
    minHeight: 50,
    maxHeight: 75,
    minWidth: 400,
  },
});

const CardKnobs: FunctionComponent = () => {
  const [title, setTitle] = useState('Testing Title');
  const [subtitle, setSubtitle] = useState('Testing Subtitle');
  const [icon, setIcon] = useState<IconName>(undefined);
  const [hasOverflowMenu, setHasOverflowMenu] = useState(false);
  const [hasBody, setHasBody] = useState(true);
  const [menuIcon, setMenuIcon] = useState<IconName>(undefined);

  const history = useHistory();

  const menuItems = [
    {
      title: 'Manage Users',
      key: 1,
      action: () => {
        history.push('/users');
      },
    },
    {
      title: 'Option 2 (disabled)',
      key: 2,
      disabled: true,
      action: () => null,
    },
    {
      title: 'Option 3',
      key: 3,
      action: () => null,
    },
    {
      title: 'Option 4',
      key: 4,
      action: () => null,
    },
    {
      title: 'Option 5',
      key: 5,
      action: () => null,
    },
    {
      title: 'Option 6',
      key: 6,
      action: () => null,
    },
  ];

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
        <Input
          label="Title"
          onChangeText={setTitle}
          testID="test-input"
          value={title}
        />
        <Input
          label="Subtitle"
          onChangeText={setSubtitle}
          testID="test-input"
          value={subtitle}
        />
        <Select
          label="headerAccessoryRight"
          onSelect={(ip: IndexPath | IndexPath[]) => setIcon(IconNames[(ip as IndexPath).row - 1])}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === icon) + 1)}
          testID="card-demo-header-select"
          value={icon || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="card-right-icon" title="None" />,
            ...IconNames.map((n, i) => <SelectItem key={n} testID={`card-right-icon-name-dropdown-value-${i}`} title={n} />),
          ]}
        </Select>
        <Select
          label="menuIcon"
          onSelect={(ip: IndexPath | IndexPath[]) => setMenuIcon(
            IconNames[(ip as IndexPath).row - 1],
          )}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === icon) + 1)}
          testID="card-demo-menu-selector"
          value={menuIcon || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="card-left-icon" title="None" />,
            ...IconNames.map((n, i) => <SelectItem key={n} testID={`card-left-icon-name-dropdown-value-${i}`} title={n} />),
          ]}
        </Select>
        <CheckBox
          checked={hasOverflowMenu}
          onChange={setHasOverflowMenu}
        >
          hasOverflowMenu
        </CheckBox>
        <CheckBox
          checked={hasBody}
          onChange={setHasBody}
        >
          showBodyContainer
        </CheckBox>
      </View>
      <DemoContainer style={{ height: 1000 }}>
        <DemoBlock style={{ marginHorizontal: 'auto' }}>
          <Card
            accent={(props) => (
              (icon || null) && <Icon name={icon} testID="test-card-icon" {...props} />
            )}
            menuIcon={menuIcon}
            menuItems={hasOverflowMenu && menuIcon && menuItems}
            subTitle={subtitle}
            testID="test-card"
            title={title}
          >
            {hasBody && <View style={styles.content} />}
          </Card>
        </DemoBlock>
      </DemoContainer>
    </>
  );
};

export const CardDemo: FunctionComponent = () => (
  <View style={{ alignSelf: 'stretch' }}>
    <ScrollView>
      <CardKnobs />
    </ScrollView>
  </View>
);
