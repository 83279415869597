import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import _ from 'lodash';
import { Input, Pagination, Text } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const PaginationDemo: FunctionComponent = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);

  const changePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const modifyTotalPages = (val : string) => {
    const intValue: number = parseInt(val, 10);
    if (!_.isNaN(intValue)) {
      setTotalPages(intValue);
      setPage(1);
    } else {
      setTotalPages(0);
      setPage(1);
    }
  };

  return (
    <View>
      <DemoContainer>
        <DemoBlock>
          <Input label="total pages (number only)" onChangeText={modifyTotalPages} testID="demo-input" value={String(totalPages)} />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock>
          <Text>Pagination with 7 displayed pages</Text>
          <Pagination
            currentPage={page}
            displayPages={7}
            onChangePage={changePage}
            totalPages={totalPages}
          />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock>
          <Text>Pagination with 9 displayed pages</Text>
          <Pagination
            currentPage={page}
            displayPages={9}
            onChangePage={changePage}
            totalPages={totalPages}
          />
        </DemoBlock>
      </DemoContainer>
    </View>
  );
};
