import {
  Badge,
  Button,
  Card,
  CheckBox,
  Dialog,
  HSpacer,
  Icon,
  IconButton,
  Modal,
  NumericInput,
  Select,
  SelectItem,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { ProductCategory } from '@shared/enums';
import {
  ApiFulfillmentLocation,
  ApiProductOrderComponent,
  ProductOrderEndpoint,
} from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Sizing, ThemeColors } from '../../../../../constants';
import { StringUtility } from '../../../../../utilities';
import CalculateQuantityDetails, {
  CalculatedQuantity,
  ComponentCalculatedQuantityMap,
} from '../CalculateQuantity/CalculateQuantityDetails';
import { CalculateQuantityModal } from '../CalculateQuantity/CalculateQuantityModal';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  rightAligned: {
    alignItems: 'flex-end',
  },
  centerAligned: {
    alignItems: 'center',
  },
  spaceBetweenRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spaceBetweenRowReverse: {
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  qtyTextStyles: {
    width: 30,
    textAlign: 'center',
  },
  input: {
    width: 109,
  },
  flexOne: {
    flex: 1,
  },
  dialogFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  isDiscountsAppliedButton: {
    position: 'absolute',
    minWidth: 150,
    top: 42,
  },
  text: {
    flexShrink: 1,
    flex: 1,
    width: 1,
  },
});

interface ProductCardProps {
  acreage?: number,
  buttonAccessory?: JSX.Element,
  calculatedQuantities?: ComponentCalculatedQuantityMap,
  component: ApiProductOrderComponent,
  details?: JSX.Element,
  disallowDeleteComponent?: boolean,
  disallowEditCalculateQuantity?: boolean,
  disallowNewCalculateQuantity?: boolean,
  fulfillmentLocationId?: string | null,
  fulfillmentLocations?: ApiFulfillmentLocation[],
  index: number,
  initPrice: number,
  isDraft?: boolean,
  locationId?: string,
  onCalculatedQuantitiesChange?: (calculatedQuantities: ComponentCalculatedQuantityMap) => void;
  onComponentChange?: (component: ProductOrderEndpoint.Component.Update.Request) => void,
  onComponentRemoved?: (componentId: string) => void,
  priceTypeId?: string,
  viewMode?: boolean,
}

const ProductCard = ({
  acreage,
  buttonAccessory,
  component,
  calculatedQuantities,
  details,
  disallowDeleteComponent = false,
  disallowEditCalculateQuantity = false,
  disallowNewCalculateQuantity = false,
  fulfillmentLocationId = null,
  fulfillmentLocations,
  index,
  initPrice,
  locationId,
  onCalculatedQuantitiesChange,
  onComponentChange,
  onComponentRemoved,
  priceTypeId,
  viewMode,
}: ProductCardProps) => {
  const [translate] = useTranslation(['productOrders', 'productCard', 'errors', 'products', 'prepare', 'common']);
  const [priceLocked, setPriceLocked] = useState<boolean>(
    (!_.isNil(component.packagePrice)),
  );
  const [showPriceLockDialog, setShowPriceLockDialog] = useState<boolean>(false);
  const [showRemoveProductDialog, setShowRemoveProductDialog] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showCalculateQuantityModal, setShowCalculateQuantityModal] = useState(false);
  const [showCalculateQuantityMessageModal, setShowCalculateQuantityMessageModal] = useState(false);
  const productCardDisabled = !component.product.isActive || viewMode;

  useEffect(() => {
    setPriceLocked(
      (!_.isNil(component.packagePrice)) || component.packagePrice === initPrice,
    );
  }, [initPrice, component.packagePrice]);

  const updateComponent = useCallback((updates: ProductOrderEndpoint.Component.Update.Request) => {
    onComponentChange?.({
      ...component,
      ...updates,
    } as ProductOrderEndpoint.Component.Update.Request);
  }, [component, onComponentChange]);

  const getComponentCalculatedQuantity = () => {
    if (!calculatedQuantities) {
      return null;
    }
    return calculatedQuantities[component.id];
  };

  const removeComponentQuantityCalculate = () => {
    delete calculatedQuantities[component.id];
    onCalculatedQuantitiesChange?.({ ...calculatedQuantities });
  };

  const addToComponentQuantities = (
    calculatedQuantity: CalculatedQuantity,
  ) => {
    calculatedQuantities[component.id] = calculatedQuantity;
    onCalculatedQuantitiesChange?.({ ...calculatedQuantities });
  };

  const showCalculateQuantityDetails = !!getComponentCalculatedQuantity()
    && component.product.isActive;
  const hasDetails = !!details || showCalculateQuantityDetails;
  const showCalculateQuantityButton = (
    !disallowNewCalculateQuantity && !getComponentCalculatedQuantity()
  );
  const showDeleteComponentButton = !viewMode && !disallowDeleteComponent;

  const packageUnitQuantity = component.product?.packageUnitQuantity ?? 0;
  const totalUnits = component.packageQuantity * packageUnitQuantity;
  const unitUomText = translate(component.product?.unitUoM ?? 'UNIT');
  const totalUnitsText = `${StringUtility.localizeNumber(totalUnits, 3)} ${unitUomText}`;

  return (
    <Card testID={`product-card-${index}`}>
      {/* Product deactivated warning */}
      {!component.product.isActive && (
        <>
          <View style={styles.row}>
            <Icon
              name="AlertTriangle"
              status="warning"
              testID={`product-card-deactivated-warning-icon-${index}`}
            />
            <HSpacer size="2" />
            <Text
              category="p1"
              testID={`product-card-deactivated-warning-message-${index}`}
            >
              {translate<string>('PRODUCT_DEACTIVATED_WARNING')}
            </Text>
          </View>
          <VSpacer size="5" />
        </>
      )}
      {/* SKU/Product Price */}
      <View style={styles.spaceBetweenRow}>
        <Text
          category="h6"
          style={styles.text}
          testID={`product-card-name-${index}`}
        >
          {component.product.skuName}
        </Text>
        <HSpacer size="7" />
        <View style={[styles.column, styles.rightAligned]}>
          <Text
            category="h3"
            testID={`product-card-total-${index}`}
          >
            {StringUtility.formatCurrency(
              CalculationUtility.getProductOrderComponentTotal(
                component,
                priceTypeId,
                locationId,
                component.discounts?.length > 0,
              ),
            )}
          </Text>
          {
            component.discounts?.length > 0
              && (
                <Badge
                  status="success"
                  style={styles.isDiscountsAppliedButton}
                  testID={`product-card-is-discount-applied-${index}`}
                >
                  {translate<string>('DISCOUNT_APPLIED').toUpperCase()}
                </Badge>
              )
          }
        </View>
      </View>
      <VSpacer size="4" />
      {/* SKU QTY/Unit Price/UOM/Price Locked */}
      <View style={styles.row}>
        {/* SKU QTY input */}
        <View style={styles.input}>
          <NumericInput
            label={translate<string>('SKU_QTY')}
            minValue={0}
            onChangeValue={(value) => {
              updateComponent({ packageQuantity: value });
            }}
            precision={4}
            readOnly={productCardDisabled}
            readOnlyLabel={false}
            testID={`product-card-quantity-${index}`}
            value={component.packageQuantity}
          />
        </View>
        <HSpacer size="7" />
        {/* Unit price input */}
        <NumericInput
          label={translate<string>('UNIT_PRICE')}
          // Value is null when the number is backspaced
          onChangeValue={(value) => updateComponent({
            packagePrice: value === initPrice ? null : (value ?? 0),
          })}
          precision={2}
          prefix="$"
          readOnly={productCardDisabled}
          readOnlyLabel={false}
          style={styles.input}
          testID={`product-card-unit-price-input-${index}`}
          value={
            component.packagePrice === undefined || component.packagePrice === null
              ? initPrice
              : component.packagePrice
          }
        />
        <HSpacer size="4" />
        {/* UOM text */}
        <View style={styles.column}>
          <VSpacer size="2" />
          <VSpacer size="8" />
          <View style={styles.row}>
            <Text testID={`product-card-product-per-uom-${index}`}>
              {translate<string>('PER_UOM', { UoM: translate<string>(component.product.unitUoM ?? 'UNIT') })}
            </Text>
            <HSpacer size="7" />
            {/* Price locked/unlocked text/checkbox */}
            {productCardDisabled ? (
              <>
                <Icon name={priceLocked ? 'Lock' : 'GrowersUnlock'} status="basic" testID={`product-card-text-price-locked-icon-${index}`} />
                <HSpacer size="2" />
                <Text testID={`product-card-text-price-locked-${index}`}>
                  {translate<string>(priceLocked ? 'PRICE_LOCKED' : 'PRICE_UNLOCKED')}
                </Text>
              </>
            ) : (
              <CheckBox
                checked={priceLocked}
                onChange={(checked) => {
                  if (!checked) {
                    if (component.packagePrice === initPrice) {
                      setPriceLocked(false);
                      updateComponent({ packagePrice: null });
                    } else {
                      setShowPriceLockDialog(true);
                    }
                  } else {
                    updateComponent({ packagePrice: initPrice });
                    setPriceLocked(checked);
                  }
                }}
                testID={`product-card-price-locked-${index}`}
              >
                {translate<string>('LOCK_PRICE')}
              </CheckBox>
            )}
          </View>
        </View>
      </View>
      <VSpacer size="5" />
      <View style={styles.row}>
        <Text
          appearance="hint"
          category="p2"
          testID={`product-card-sku-price-label-${index}`}
        >
          {translate<string>('SKU_PRICE')}
        </Text>
        <HSpacer size="3" />
        <Text
          category="p2"
          testID={`product-card-sku-price-${index}`}
        >
          {StringUtility.formatCurrency(
            CalculationUtility.getProductSkuPrice(
              component.product,
              priceTypeId,
              locationId,
            ),
          )}
        </Text>
        <HSpacer size="5" />
        <Text
          appearance="hint"
          category="p2"
          testID={`product-card-units-per-sku-${index}`}
        >
          {translate<string>('UNITS_PER_SKU')}
        </Text>
        <HSpacer size="3" />
        <Text
          category="p2"
          testID={`product-card-package-unit-quantity-${index}`}
        >
          {`${component.product.packageUnitQuantity} ${translate<string>(component.product.unitUoM)}`}
        </Text>
        <HSpacer size="5" />
        <Text
          appearance="hint"
          category="p2"
          testID={`product-card-total-units-label-${index}`}
        >
          {`${translate<string>('TOTAL_UNITS')}:`}
        </Text>
        <HSpacer size="3" />
        <Text
          category="p2"
          testID={`product-card-total-units-${index}`}
        >
          {totalUnitsText}
        </Text>
      </View>
      <VSpacer size="5" />
      {/* show/hide details && calculate qty button */}
      <View style={styles.row}>
        <View style={[styles.spaceBetweenRowReverse, styles.centerAligned]}>
          <View style={[styles.row, styles.centerAligned]}>
            {showCalculateQuantityButton && (
              <>
                {component.product.category !== ProductCategory.OTHER && (
                  <Button
                    accessoryLeft={(iconProps) => (
                      <Icon
                        name="GrowersCalculate"
                        testID={`product-list-add-discount-icon-${index}`}
                        {...iconProps}
                      />
                    )}
                    appearance="outline"
                    onPress={() => setShowCalculateQuantityModal(true)}
                    size="small"
                    status="primary"
                    testID={`calculate-quantity-button-${index}`}
                  >
                    {translate<string>('CALCULATE_QTY')}
                  </Button>
                )}
                {component.product.category === ProductCategory.OTHER && (
                  <TouchableOpacity
                    onPress={() => setShowCalculateQuantityMessageModal(true)}
                    testID={`calculate-quantity-other-product-info-${index}`}
                  >
                    <Icon
                      fill={ThemeColors.SECONDARY}
                      name="Info"
                      size="medium"
                      style={[{ marginRight: Sizing.EM }]}
                      testID={`calculate-quantity-other-product-info-icon-${index}`}
                    />
                  </TouchableOpacity>
                )}
              </>
            )}
            {!!buttonAccessory && (
              <>
                <HSpacer size="1" />
                <HSpacer size="5" />
              </>
            )}
            {buttonAccessory}
            {showDeleteComponentButton && (
              <>
                {(!!buttonAccessory) && (
                  <>
                    <HSpacer size="1" />
                    <HSpacer size="5" />
                  </>
                )}
                <IconButton
                  appearance="ghost"
                  onPress={() => setShowRemoveProductDialog(true)}
                  status="primary"
                  testID={`product-card-remove-component-button-${index}`}
                >
                  Trash
                </IconButton>
              </>
            )}
          </View>
          {hasDetails && (
            <TextLink
              accessoryRight={(iconProps) => (
                <Icon
                  name={showDetails ? 'ChevronUp' : 'ChevronDown'}
                  testID={`product-card-details-text-link-icon-${index}`}
                  {...iconProps}
                />
              )}
              appearance="secondary"
              category="p2"
              onPress={() => setShowDetails((prev) => !prev)}
              testID={`product-card-details-text-link-${index}`}
            >
              {translate<string>(showDetails ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
            </TextLink>
          )}
          {!!fulfillmentLocations?.length && (
            <Select
              label={translate<string>('FULFILLMENT_LOCATION')}
              onSelect={(i) => {
                const location = fulfillmentLocations[(i as IndexPath).row - 1];
                updateComponent({ fulfillmentLocationId: location?.id ?? null });
              }}
              placeholder={translate<string>('NONE')}
              readonly={viewMode}
              readOnlyLabel={false}
              testID="fulfillment-location-select"
              value={fulfillmentLocations.find(
                (location) => location.id === fulfillmentLocationId,
              )?.name ?? translate<string>('NONE')}
            >
              <>
                <SelectItem
                  key={''}
                  testID="fulfillment-location-select-none"
                  title={translate<string>('NONE')}
                />
                {fulfillmentLocations.map((location, i) => (
                  <SelectItem
                    key={location.id}
                    testID={`fulfillment-location-select-${i}`}
                    title={location.name}
                  />
                ))}
              </>
            </Select>
          )}
        </View>
      </View>
      {/* Details */}
      <View>
        {showDetails && hasDetails && (
          <>
            {!!details && (
              <>
                <VSpacer size="5" />
                {details}
              </>
            )}
            {showCalculateQuantityDetails && (
              <>
                <VSpacer size="6" />
                <CalculateQuantityDetails
                  calculatedQuantity={getComponentCalculatedQuantity()}
                  component={component}
                  locationId={locationId}
                  onDelete={() => {
                    removeComponentQuantityCalculate();
                    const updatedComponent = {
                      ...component,
                      acreage: null,
                      applicationRate: null,
                      applicationRateUom: null,
                      packageQuantity: 1,
                    } as ProductOrderEndpoint.Component.Update.Request;
                    updateComponent(updatedComponent);
                  }}
                  onEdit={() => setShowCalculateQuantityModal(true)}
                  priceTypeId={priceTypeId}
                  readOnly={viewMode || disallowEditCalculateQuantity}
                  unitPriceOverride={component.packagePrice}
                />
              </>
            )}
          </>
        )}
      </View>
      <Dialog
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <Button
              {...primaryButtonProp}
              appearance="outline"
              status="basic"
              testID={`product-card-cancel-unlock-price-${index}`}
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              {...secondaryButtonProp}
              disabled={productCardDisabled}
              onPress={() => {
                setPriceLocked(false);
                updateComponent({ packagePrice: null });
                setShowPriceLockDialog(false);
              }}
              testID={`product-card-confirm-unlock-price-${index}`}
            >
              {translate<string>('CONFIRM_UNLOCK_PRICE')}
            </Button>
          </>
        )}
        footerStyle={styles.dialogFooter}
        onClose={() => setShowPriceLockDialog(false)}
        testID={`product-card-revert-price-lock-dialog-${index}`}
        title={translate('CONFIRM_UNLOCK_PRICE_DIALOG_TITLE')}
        visible={showPriceLockDialog}
      >
        <Text
          category="p2"
          testID={`product-card-unlock-price-warning-${index}`}
        >
          {translate<string>('UNLOCK_PRICE_WARNING', {
            current: StringUtility.formatCurrency(component.packagePrice),
            unit: translate<string>(component.product.unitUoM),
            default: StringUtility.formatCurrency(initPrice),
          })}
        </Text>
      </Dialog>
      <Dialog
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <Button
              {...primaryButtonProp}
              appearance="outline"
              status="basic"
              testID={`product-card-cancel-remove-product-${index}`}
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              {...secondaryButtonProp}
              onPress={() => {
                onComponentRemoved(component.productId);
                setShowRemoveProductDialog(false);
              }}
              testID={`product-card-confirm-remove-product-${index}`}
            >
              {translate<string>('CONFIRM_REMOVE_PRODUCT')}
            </Button>
          </>
        )}
        footerStyle={styles.dialogFooter}
        onClose={() => setShowRemoveProductDialog(false)}
        testID={`product-card-remove-product-dialog-${index}`}
        title={translate<string>('CONFIRM_REMOVE_PRODUCT_DIALOG_TITLE')}
        visible={showRemoveProductDialog}
      />
      {showCalculateQuantityModal && (
        <CalculateQuantityModal
          acres={acreage}
          calculatedQuantity={getComponentCalculatedQuantity()}
          component={component}
          isVisible
          locationId={locationId}
          onApply={(calculatedQuantity) => {
            const updatedComponent = {
              ...component,
              acreage: calculatedQuantity.acreage,
              packageQuantity: calculatedQuantity.quantity,
              applicationRate: calculatedQuantity.applicationRate,
              applicationRateUom: calculatedQuantity.applicationUom,
            } as ProductOrderEndpoint.Component.Update.Request;
            updateComponent(updatedComponent);
            addToComponentQuantities(calculatedQuantity);
            setShowCalculateQuantityModal(false);
          }}
          onCancel={() => setShowCalculateQuantityModal(false)}
        />
      )}
      {showCalculateQuantityMessageModal && (
        <Modal
          footerAccessory={({ primaryButtonProp }) => (
            <>
              <Button
                {...primaryButtonProp}
                appearance="outline"
                onPress={() => setShowCalculateQuantityMessageModal(false)}
                status="primary"
                testID="close-button"
              >
                {translate<string>('CLOSE')}
              </Button>
            </>
          )}
          hideCloseButton
          onClose={() => setShowCalculateQuantityMessageModal(false)}
          testID="calculate-quantity-message-modal"
          visible
        >
          <Text>
            {translate<string>('CALCULATE_QUANTITY_NOT_AVAILABLE_MESSAGE')}
          </Text>
        </Modal>
      )}
    </Card>
  );
};

export default ProductCard;
