import { ApiGrowerFarm } from '@shared/interfaces/api';
import * as _ from 'lodash';

export class FarmValidation {
  static requiredFieldsMissing (farms: ApiGrowerFarm[]): boolean {
    return _.some(farms, (farm) => _.isEmpty(farm.farmName)
      // all farms have a field
      || _.isEmpty(farm.growerFields)
      // All growerFields have a name and area, if no zones
      || _.some(
        farm.growerFields, (field) => !field.fieldName
          || (_.isEmpty(field.cropZones) && !field.areaValue),
      )
      // All zones have a name and area
      || _.some(farm.growerFields, (field) => _.some(
        field.cropZones, (cropZone) => !cropZone.zoneName || !cropZone.areaValue,
      )));
  }

  static validateNames (farms: ApiGrowerFarm[]): { error: string, name: string } | null {
    const farmNames = farms.map((farm) => farm.farmName);
    if (_.uniq(farmNames).length !== farmNames.length) {
      return {
        error: 'FARM_NAME_DUPLICATE',
        name: FarmValidation.getFirstDuplicateName(farmNames),
      };
    }
    for (let i = 0; i < farms.length; i += 1) {
      const fields = farms[i].growerFields;
      const fieldNames = fields.map((field) => field.fieldName);
      if (_.uniq(fieldNames).length !== fieldNames.length) {
        return {
          error: 'FIELD_NAME_DUPLICATE',
          name: FarmValidation.getFirstDuplicateName(fieldNames),
        };
      }

      for (let j = 0; j < fields.length; j += 1) {
        const zones = fields[j].cropZones;
        const zoneNames = zones.map((zone) => zone.zoneName);

        if (_.uniq(zoneNames).length !== zoneNames.length) {
          return {
            error: 'ZONE_NAME_DUPLICATE',
            name: FarmValidation.getFirstDuplicateName(zoneNames),
          };
        }
      }
    }
    return null;
  }

  private static getFirstDuplicateName (names: string[]) {
    const nameCounts = _.countBy(names, (name) => name);
    let duplicateName = '';
    // eslint-disable-next-line consistent-return
    _.forEach(nameCounts, (count, name) => {
      if (count > 1) {
        duplicateName = name;
        return false;
      }
    });

    return duplicateName;
  }
}
