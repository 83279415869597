import React, { FunctionComponent } from 'react';
import {
  RadioGroup as UIKRadioGroup,
  RadioGroupProps as UIKRadioGroupProps,
} from '@ui-kitten/components';

interface RadioGroupProps extends UIKRadioGroupProps {
  testID: string,
}

export const RadioGroup: FunctionComponent<RadioGroupProps> = (props) => (
  <UIKRadioGroup
    {...props}
  />
);
