import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { IndexPath } from '@ui-kitten/components/devsupport';
import { Input, Icon, IconName, IconNames, Text, Select, SelectItem } from '@design';
import { InputSizes } from '@theme/variant-interfaces/Size';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  inputContainer: {
    marginTop: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    flex: 1,
  },
  items: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    marginHorizontal: 16,
    flex: 1,
    borderBottomColor: '#fff',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  name: {
    fontSize: 16,
  },
  initials: {
    fontSize: 12,
  },
});

const DATA = [
  {
    id: 4927,
    name: 'Pearl E White',
    initials: 'PEW',
  },
  {
    id: 1749,
    name: 'Rusty E Fossat',
    initials: 'REF',
  },
  {
    id: 3702,
    name: 'Sherman Wadd Evver',
    initials: 'SWE',
  },
  {
    id: 4085,
    name: 'Faye K Kinnitt',
    initials: 'FKK',
  },
  {
    id: 3124,
    name: 'Doris O Open',
    initials: 'DOO',
  },
  {
    id: 1679,
    name: 'Justin J Thyme',
    initials: 'JJT',
  },
  { id: 5232,
    name: 'Harry R Pitts',
    initials: 'HRP',
  },
  { id: 5015,
    name: 'Al Gore Rythim',
    initials: 'AGR',
  },
  {
    id: 6497,
    name: 'Pearl E. Black',
    initials: 'PEB',
  },
  {
    id: 4249,
    name: 'Val V Veeta',
    initials: 'VVV',
  },
];

const filter = (item, query) => Object.values(item).join(' ').toLowerCase().includes(query.toLowerCase());

const Item = ({ values }) => (
  <View style={styles.items}>
    <Text style={styles.name}>{values.name}</Text>
    <Text style={styles.initials}>
      initials:
      {values.initials}
    </Text>
  </View>
);

export const AutoCompleteDemo = () => {
  const [value, setValue] = useState('');
  const [data] = useState(DATA);
  const [displayedData, setDisplayedData] = useState([]);
  const [status, setStatus] = useState<Status>('basic');
  const [iconLeft, setIconLeft] = useState<IconName>(undefined);
  const [iconRight, setIconRight] = useState<IconName>('Close');
  const [placeholder, setPlaceholder] = useState('Placeholder');

  useEffect(() => {
    setDisplayedData(DATA);
  }, []);

  const onSelect = (index: number) => {
    setValue(displayedData[index].name);
  };

  const onChangeText = (query: string) => {
    setValue(query);
    setDisplayedData(DATA.filter((item) => filter(item, query)));
  };

  const onClear = () => {
    setValue('');
    setDisplayedData(data);
  };

  const leftIconRender = (props) => (
    <Icon name={iconLeft} testID="left-icon" {...props} />
  );

  const rightIconRender = (props) => (
    <TouchableOpacity onPressIn={onClear}>
      <Icon name={iconRight} testID="right-icon" {...props} />
    </TouchableOpacity>
  );

  return (
    <View style={{ backgroundColor: '#212121' }}>
      <DemoContainer label="AutoComplete" style={{ flexDirection: 'column' }}>
        <View style={styles.container}>
          <DemoBlock>
            <Select
              label="accessoryLeft"
              onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
                IconNames[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)}
              testID="auto-complete-accessory-demo-left-selector"
              value={iconLeft || 'None'}
            >
              {[
                <SelectItem key="noicon" testID="auto-complete-left-icon" title="None" />,
                ...IconNames.map((n, i) => (
                  <SelectItem key={n} testID={`auto-complete-left-icon-name-${i}`} title={n} />
                )),
              ]}
            </Select>
          </DemoBlock>
          <DemoBlock>
            <Select
              label="accessoryRight"
              onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
                IconNames[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
              testID="auto-complete-demo-accessory-right-selector"
              value={iconRight || 'Close'}
            >
              {[
                <SelectItem key="noicon" testID="auto-complete-right-icon" title="Close" />,
                ...IconNames.map((n, i) => (
                  <SelectItem key={n} testID={`auto-complete-right-icon-name-${i}`} title={n} />
                )),
              ]}
            </Select>
          </DemoBlock>
          <DemoBlock>
            <Select
              label="status"
              onSelect={(ip: IndexPath | IndexPath[]) => setStatus(Statuses[(ip as IndexPath).row])}
              selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
              testID="auto-complete-demo-accessory-status-selector"
              value={status}
            >
              {Statuses.map((s, i) => (
                <SelectItem key={s} testID={`auto-complete-status-dropdown-value-${i}`} title={s} />
              ))}
            </Select>
          </DemoBlock>
          <DemoBlock>
            <Input
              label="Placeholder..."
              onChangeText={setPlaceholder}
              testID="test-input"
              value={placeholder}
            />
          </DemoBlock>
        </View>
        <View style={styles.container}>
          <DemoBlock style={styles.inputContainer}>
            {InputSizes.map((size) => (
              <Input
                accessoryLeft={iconLeft && leftIconRender}
                accessoryRight={value.length > 0 && iconRight && rightIconRender}
                autoCompleteOnClear={onClear}
                autoCompleteOnSelect={onSelect}
                autoCompleteOptions={displayedData}
                caption="Search by Name or Initials"
                key={size}
                label={size.toUpperCase()}
                onChangeText={onChangeText}
                placeholder={placeholder}
                size={size}
                status={status}
                testID="test-input"
                value={value}
              />
            ))}
          </DemoBlock>
        </View>
      </DemoContainer>
      <DemoContainer style={{ flexDirection: 'column', marginTop: 32 }}>
        {displayedData.map((items) => (
          <View key={items.id}>
            <Item values={items} />
          </View>
        ))}
      </DemoContainer>
    </View>
  );
};
