import React, { useEffect, useState, useCallback } from 'react';
import { Card, CheckBox, HSpacer, Icon, LargeModal, NumericInput, Text, VSpacer } from '@design';
import { StyleSheet, View } from 'react-native';
import { ApiCrop, ApiCropLogic, ApiCropLogicPass, ApiCropLogicScenarioParameters, ApiPriceType, CropLogicEndpoint } from '@shared/interfaces/api';
import { CropLogicUtilities } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ProgramNameInput } from './ProgramNameInput';
import { CropSelect } from '../../../components/shared/Input/CropSelect';
import { BusinessUserSelect } from '../../../components/shared/Input/BusinessUserSelect';
import { BusinessLocationSelect } from '../../../components/shared/Input/BusinessLocationSelect';
import { PriceTypeSelect } from '../../../components/shared/Input/PriceTypeSelect';
import { ButtonBar } from '../../../components/shared/ButtonBar';
import { usePriceTypeList } from '../../../../hooks/usePriceTypeList';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  card: {
    flex: 1,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
    width: 545,
  },
  calcContainer: {
    flexDirection: 'row',
  },
  calcField: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
  },
  calInput: {
    flex: 1,
  },
  uomContainer: {
    height: 40,
    marginTop: 28.3,
    justifyContent: 'center',
  },
  operationalCosts: {
    width: 176,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
});

interface ProgramFormModalProps {
  canEditAccount: boolean,
  onCancel: () => void,
  onProgramChange: (program: ApiCropLogic) => void,
  onSave: () => void,
  program: ApiCropLogic,
}

interface ScenarioParametersProps {
  handleChange: (name: keyof ApiCropLogicScenarioParameters, value: number) => void,
  scenarioParameters: ApiCropLogicScenarioParameters,
}

const ScenarioParameters = ({
  handleChange,
  scenarioParameters,
}: ScenarioParametersProps) => {
  const { operationalCosts, price, priceUom, yieldGoal, yieldGoalUom } = scenarioParameters;

  const [translate] = useTranslation(['common', 'programs']);
  const [localYieldGoal, setLocalYieldGoal] = useState(yieldGoal);
  const [localPrice, setLocalPrice] = useState(price);
  const [localOpCosts, setLocalOpCosts] = useState(operationalCosts);

  return (
    <>
      <View style={styles.calcContainer}>
        <View style={styles.calcField}>
          <NumericInput
            isRequired
            label={translate<string>('YIELD_GOAL')}
            onChangeValue={(newYieldGoal) => {
              setLocalYieldGoal(newYieldGoal);
              handleChange('yieldGoal', newYieldGoal);
            }}
            style={styles.calInput}
            testID="program-form-modal-yield-goal-field"
            value={localYieldGoal}
          />
          <HSpacer size="3" />
          <View style={styles.uomContainer}>
            <Text
              category="p1"
              testID="program-form-modal-yield-goal-uom"
            >
              {translate<string>('UOM_PER_ACRE_PARSED',
                { UoM: translate<string>(yieldGoalUom) })}
            </Text>
          </View>
        </View>
        <HSpacer size="7" />
        <View style={styles.calcField}>
          <NumericInput
            isRequired
            label={translate<string>('MARKET_PRICE')}
            onChangeValue={(newPrice) => {
              setLocalPrice(newPrice);
              handleChange('price', newPrice);
            }}
            prefix="$"
            style={styles.calInput}
            testID="program-form-modal-crop-market-price-field"
            value={localPrice}
          />
          <HSpacer size="3" />
          <View
            style={styles.uomContainer}
          >
            <Text
              category="p1"
              testID="program-form-modal-price-uom"
            >
              {translate<string>('PER_UOM',
                { UoM: translate<string>(priceUom) })}
            </Text>
          </View>
        </View>
      </View>
      <VSpacer size="7" />
      <View style={styles.calcContainer}>
        <View style={styles.calcField}>
          <NumericInput
            label={translate<string>('OPERATIONAL_COST_AC')}
            onChangeValue={(newOpCosts) => {
              setLocalOpCosts(newOpCosts);
              handleChange('operationalCosts', newOpCosts);
            }}
            prefix="$"
            style={styles.calInput}
            testID="program-form-modal-operational-cost-per-acre-field"
            value={localOpCosts}
          />
          <HSpacer size="3" />
          <View accessible style={styles.hidden}>
            <Text category="p1">
              {translate<string>('UOM_PER_ACRE_PARSED',
                { UoM: translate<string>(yieldGoalUom) })}
            </Text>
          </View>
        </View>
        <HSpacer size="7" />
        <View style={styles.calcField} />
      </View>
    </>
  );
};

export const ProgramFormModal = ({
  canEditAccount,
  onCancel,
  onProgramChange,
  onSave,
  program,
}: ProgramFormModalProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const { defaultPriceType } = usePriceTypeList();
  const [selectedCropType, setSelectedCropType] = useState<ApiCrop>(undefined);
  const [selectedPriceType, setSelectedPriceType] = useState<ApiPriceType>(defaultPriceType);
  const [useScenarioParameters, setUseScenarioParameters] = useState(false);
  const [nameValidated, setNameValidated] = useState(true);

  const handleCropTypeSelect = (
    crop: ApiCrop,
    passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[],
  ) => {
    onProgramChange({
      ...program,
      passes: passes as ApiCropLogicPass[],
      cropSubType: crop.subType,
      cropType: crop.cropType,
      scenarioParameters: {
        ...program.scenarioParameters,
        price: crop.price,
        priceUom: crop.priceUom,
        yieldGoal: crop.yieldGoal,
        yieldGoalUom: crop.yieldGoalUom,
      },
    });

    setUseScenarioParameters(false);
    setSelectedCropType(crop);
  };

  const handlePriceTypeSelect = useCallback((priceType: ApiPriceType) => {
    const { id: priceTypeId } = priceType;
    onProgramChange({
      ...program,
      priceTypeId,
    });
    setSelectedPriceType(priceType);
  }, [onProgramChange, program]);

  const formValid = useCallback(() => {
    const { isFieldsValid, scenarioParamsValid } = CropLogicUtilities.validateCropLogic(
      program,
      useScenarioParameters,
      program.scenarioParameters,
    );

    return isFieldsValid && scenarioParamsValid && selectedCropType && nameValidated;
  }, [useScenarioParameters, selectedCropType, nameValidated, program]);

  useEffect(() => {
    if (program && !program.priceTypeId && defaultPriceType) {
      onProgramChange({
        ...program,
        priceTypeId: defaultPriceType.id,
      });
      setSelectedPriceType(defaultPriceType);
    }
  }, [program, defaultPriceType, onProgramChange]);

  const handleUpdateOwner = useCallback((userAccountId: string) => {
    onProgramChange({
      ...program,
      userAccountId,
    });
  }, [onProgramChange, program]);

  const handleUpdateName = useCallback((logicName: string) => {
    onProgramChange({
      ...program,
      logicName,
    });
  }, [onProgramChange, program]);

  const handleChange = _.debounce((name: keyof ApiCropLogicScenarioParameters, value) => {
    onProgramChange({
      ...program,
      scenarioParameters: {
        ...program.scenarioParameters,
        [name]: name === 'operationalCosts' ? (value ?? 0) : value,
      },
    });
  }, 150);

  const handleSave = useCallback(() => {
    if (!useScenarioParameters) {
      const { scenarioParameters, ...newProgram } = program;
      onProgramChange(newProgram);
    }

    onSave();
  }, [onProgramChange, onSave, useScenarioParameters, program]);

  const FormPage = [
    <View style={styles.container} testID="program-form-modal">
      <Card style={styles.card} testID="program-form-modal-card">
        <Text category="label">
          {translate<string>('DETAILS')}
        </Text>
        <VSpacer size="9" />
        {canEditAccount && (
          <>
            <BusinessUserSelect
              onUpdateOwner={handleUpdateOwner}
              ownerId={program.userAccountId}
              testId="program-form-modal-owner-selector"
            />
            <VSpacer size="7" />
          </>
        )}
        <ProgramNameInput
          name={program.logicName}
          onUpdateName={handleUpdateName}
          onUpdateValidate={setNameValidated}
          programId={program.id}
          valid={nameValidated}
        />
        <VSpacer size="7" />
        <CropSelect
          crop={selectedCropType}
          onUpdateCrop={handleCropTypeSelect}
          passes={program.passes}
          testID="program-form-modal-crop-type"
        />
        <VSpacer size="9" />
        {selectedCropType && (
          <CheckBox
            checked={useScenarioParameters}
            disabled={!program.cropType}
            onChange={() => setUseScenarioParameters(!useScenarioParameters)}
            testID="program-form-modal-use-scenario-parameters-checkbox"
          >
            {translate<string>('USE_SCENARIO_PARAMETERS')}
          </CheckBox>
        )}
        {useScenarioParameters && (
          <View testID="program-form-modal-use-scenario-parameters-section">
            <VSpacer size="9" />
            <BusinessLocationSelect
              locationId={program.scenarioParameters?.businessLocationId}
              onUpdateLocation={(businessLocationId) => {
                onProgramChange({
                  ...program,
                  scenarioParameters: {
                    ...program.scenarioParameters,
                    businessLocationId,
                  },
                });
              }}
              testID="program-form-modal-business-locations-selector"
            />
            <PriceTypeSelect
              isRequired
              onUpdatePriceType={handlePriceTypeSelect}
              selectedPriceType={selectedPriceType}
              testID="program-form-modal-price-type"
              vSpacerSize="7"
            />
            <VSpacer size="7" />
            <ScenarioParameters
              handleChange={handleChange}
              scenarioParameters={program?.scenarioParameters}
            />
          </View>
        )}
      </Card>
    </View>,
  ];

  return (
    <LargeModal
      footer={() => (
        <ButtonBar
          accessoryRight={(iconsProps) => <Icon name="ArrowForward" testID="program-form-modal-button-icon" {...iconsProps} />}
          buttonBarType="grouped"
          disableRightAction={!formValid()}
          leftAction={onCancel}
          leftButtonText={translate<string>('CANCEL')}
          rightAction={handleSave}
          rightButtonText={translate<string>('NEXT')}
          size="giant"
          testID="program-form-modal-button"
        />
      )}
      pages={FormPage}
      testID="program-form-large-modal"
      title={translate<string>('CREATE_PROGRAM')}
      visible
    />
  );
};
