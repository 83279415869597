import { HSpacer, Text } from '@design';
import {
  FarmPlanDeliverableV2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { useTheme } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StringUtility } from '../../../../utilities';
import { IColumn, RowMeta } from '../../SortableTable';
import { CustomRow } from '../CustomRow/CustomRow';

export interface OrderDetailsTableRowDetailsProps {
  columns: IColumn<(FarmPlanDeliverableV2.ProductSummary & RowMeta)>[],
  discounts: FarmPlanDeliverableV2.ProductDiscount[],
  discountedPrice: FarmPlanDeliverableV2.CalculatedProductCost,
}
export function OrderDetailsTableRowDetails ({
  discounts,
  discountedPrice,
  columns,
}: OrderDetailsTableRowDetailsProps) {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'common', 'prepare']);
  const theme = useTheme();

  return (
    <>
      {discounts.map((discount, index) => (
        <CustomRow
          columns={columns}
          key={index} // eslint-disable-line react/no-array-index-key
          rowDetail
          style={{
            borderBottomWidth: 1,
            borderBottomColor: (index + 1 === discounts.length)
              ? theme['color-basic-control-transparent-600']
              : theme['color-basic-control-transparent-100'],
          }}
          values={{
            productSku: {
              children: (
                <Text category="p2" numberOfLines={1}>
                  {discount.name}
                  {(!!discount.percent) && (
                    <>
                      <HSpacer size="4" />
                      |
                      <HSpacer size="4" />
                      <Text category="p2" status="success">
                        {translate<string>('PERCENT_OFF', { percent: discount.percent })}
                      </Text>
                    </>
                  )}
                </Text>
              ),
              flex: 4.5,
            },
            packagesContracted: { flex: 0 },
            costPerAcre: {
              children: (
                <Text category="p2" status="success">
                  {StringUtility.formatDiscount(discount.dollarsPerAcre)}
                </Text>
              ),
              align: 'right',
            },
            unitPrice: {
              children: (
                <Text category="p2" status="success">
                  {StringUtility.formatDiscount(discount.dollarsPerUnit)}
                </Text>
              ),
              align: 'right',
            },
            priceLocked: null,
            totalCost: {
              children: (
                <Text category="p2" status="success">
                  {StringUtility.formatDiscount(discount.dollarsTotal)}
                </Text>
              ),
              align: 'right',
            },
          }}
        />
      ))}
      <CustomRow
        columns={columns}
        rowDetail
        values={{
          productSku: {
            children: <Text>{translate<string>('DISCOUNTED_PRICE')}</Text>,
          },
          packagesContracted: null,
          costPerAcre: {
            children: (
              <Text>
                {StringUtility.formatCurrencyAccounting(discountedPrice.perAcre)}
              </Text>
            ),
            align: 'right',
          },
          unitPrice: {
            children: (
              <Text>
                {StringUtility.formatCurrencyAccounting(discountedPrice.perUnit)}
              </Text>
            ),
            align: 'right',
          },
          priceLocked: null,
          totalCost: {
            children: (
              <Text>
                {StringUtility.formatCurrencyAccounting(discountedPrice.total)}
              </Text>
            ),
            align: 'right',
          },
        }}
      />
    </>
  );
}
