import React, { useCallback, useEffect } from 'react';
import { Routes } from '../../constants/Routes';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { Text } from '../../ui-components';
import { useHistory } from '../../router';

export const Logout = () => {
  const { logout } = useAuthentication();
  const history = useHistory();

  const logoutUser = useCallback(async () => {
    await logout();
    history.push(Routes.LOGIN);
  }, [logout, history]);

  useEffect(() => {
    logoutUser();
  });

  return <Text>Logging out...</Text>;
};
