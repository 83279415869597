import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Icon, Input, Button, Text, VSpacer, HSpacer, TextLink, useToast } from '@design';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { useStyleSheet } from '@ui-kitten/components';
import { Routes, Sizing, ThemeColors } from '../../constants';
import { AuthApi } from '../../utilities/api';
import DeviceSizes from './DeviceSizes';
import { useScreenSizing } from '../../hooks/useScreenSizing';
import { useHistory } from '../../router';

export const ForgotPassword = ({ translate }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [submitted, setSubmitted] = useState(null);
  const sizeSetting = useScreenSizing();
  const { createToast } = useToast();

  const styles = useStyleSheet({
    icon: {
      width: 1.5 * Sizing.EM,
      height: 1.5 * Sizing.EM,
      marginRight: Sizing.EM,
    },
    container: {
      justifyContent: 'center',
    },
    center: {
      alignItems: 'center',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    resendLink: { fontSize: 18, fontWeight: '700' },
    text: { marginBottom: Sizing.HALF_SPACING },
    buttonText: {
      width: Sizing.EM * 16.5,
      justifyContent: 'center',
    },
    errorMessage: {
      padding: Sizing.HALF_SPACING,
      color: 'color-danger-500',
      textAlign: 'center',
      backgroundColor: 'color-danger-transparent-100',
    },
  });

  const contactRallySupport = () => history.push(Routes.PUBLIC_HELP);

  const submitForm = async () => {
    try {
      setSubmitting(true);
      await AuthApi.requestPasswordReset(email);
      setPasswordError(null);
      setSubmitted(true);
    } catch (err) {
      setPasswordError(translate('UNEXPECTED_ERROR'));
    }
    setSubmitting(false);
  };

  const resendEmail = async () => {
    try {
      await submitForm();
      createToast({
        children: translate('LINK_RESENT'),
        status: 'success',
        testID: 'toast-content-element',
      });
    } catch (err) {
      createToast({
        children: translate('LINK_RESEND_ERROR'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
  };

  return (
    <View
      style={[
        styles.container,
        { width: DeviceSizes[sizeSetting].containerSize },
      ]}
      testID="recover-password-view"
    >
      <ScrollView>
        {!submitted ? (
          <>
            <View style={styles.center}>
              <Text category="h3" style={[styles.text]}>{translate('RECOVER_PASSWORD')}</Text>
              <Text appearance="hint" category="p2">{translate('RECOVER_PASSWORD_SUBHEADER')}</Text>
            </View>

            <VSpacer size={DeviceSizes[sizeSetting].bottomTitleSpace as SpacerSize} />

            {passwordError && (
              <>
                <Text category="p2" style={styles.errorMessage} testID="error-message">
                  {passwordError}
                </Text>
                <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
              </>
            )}

            <Input
              label={translate('USER_NAME')}
              onChangeText={(nextValue) => setEmail(nextValue)}
              status={passwordError ? 'danger' : undefined}
              testID="username"
              value={email}
            />
            <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
            <View style={styles.center}>
              <Button
                appearance={!email || submitting ? 'outline' : 'filled'}
                disabled={!email || submitting}
                onPress={submitForm}
                status="primary"
                style={styles.buttonText}
                testID="login-btn"
              >
                {translate('EMAIL_RECOVERY_LINK')}
              </Button>
            </View>
            <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Text
                appearance="hint"
                category="p2"
                testID="info-message"
              >
                {translate('DONT_HAVE_ACCOUNT') as string}
              </Text>
              <HSpacer size="2" />
              <TextLink
                appearance="secondary"
                category="p2"
                onPress={contactRallySupport}
                testID="call-link"
              >
                {translate('EMAIL_GROWERS') as string}
              </TextLink>
            </View>
          </>
        ) : (
          <>
            <View style={styles.center}>
              <Text category="h3" style={[styles.text]}>{translate('CHECK_EMAIL')}</Text>
              <Text appearance="hint" category="p2" style={{ textAlign: 'center' }}>
                {translate('EMAIL_HAS_BEEN_SENT')}
              </Text>
            </View>
            <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
            <Input
              label={translate('USER_NAME')}
              onChangeText={(nextValue) => setEmail(nextValue)}
              status={passwordError ? 'danger' : undefined}
              testID="username"
              value={email}
            />
            <VSpacer size={DeviceSizes[sizeSetting].bottomTitleSpace as SpacerSize} />
            <View style={[styles.center, styles.row]}>
              <Button
                accessoryLeft={(props) => (
                  <>
                    <Icon name="Refresh" testID="resend-link-icon" {...props} />
                    <HSpacer size="3" />
                  </>
                )}
                appearance="ghost"
                onPress={resendEmail}
                testID="resend-link"
              >
                {translate('RESEND_LINK')}
              </Button>
            </View>
            <VSpacer size={DeviceSizes[sizeSetting].bottomTitleSpace as SpacerSize} />
            <View style={styles.row}>
              <Text
                appearance="hint"
                category="p2"
                testID="info-message"
              >
                {translate('STILL_HAVING_PROBLEMS') as string}
              </Text>
              <HSpacer size="2" />
              <TextLink
                category="p2"
                onPress={contactRallySupport}
                style={[{ color: ThemeColors.SECONDARY }]}
                testID="call-link"
              >
                {translate('EMAIL_GROWERS') as string}
              </TextLink>
            </View>
          </>
        )}
      </ScrollView>
    </View>
  );
};
