import React, { useState } from 'react';
import { Button, HSpacer, Modal } from '@design';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { CropYearPickerSelect } from '../../../../components/shared/Input/CropYearPickerSelect';

const styles = StyleSheet.create({
  footer: {
    paddingTop: 40,
    paddingLeft: 0,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttons: {
    flexDirection: 'row',
  },
});

export interface CropYearModalProps {
  cropYear: number,
  onClose(): void,
  onUpdateCropYear?(cropYear: number): void,
}

export const CropYearModal = ({
  onClose,
  onUpdateCropYear,
  ...props
}: CropYearModalProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const [cropYear, setCropYear] = useState(props.cropYear);

  const viewOnly = !onUpdateCropYear;

  const renderFooterAccessory = ({
    primaryButtonProp,
    secondaryButtonProp,
    spacerProp,
  }) => (
    <View style={styles.buttons}>
      <Button
        testID="crop-year-modal-cancel-button"
        {...primaryButtonProp}
        onPress={onClose}
      >
        {translate<string>('CANCEL')}
      </Button>
      <HSpacer {...spacerProp} />
      <Button
        testID="crop-year-modal-apply-button"
        {...secondaryButtonProp}
        onPress={() => onUpdateCropYear(cropYear)}
      >
        {translate<string>('APPLY')}
      </Button>
    </View>
  );

  return (
    <Modal
      footerAccessory={!viewOnly && renderFooterAccessory}
      footerStyle={styles.footer}
      hideCloseButton={!viewOnly}
      onClose={onClose}
      scrollableContent
      showScrollIndicator
      testID="crop-year-modal"
      title={translate<string>('EDIT_CROP_YEAR')}
      visible
    >
      <View>
        <CropYearPickerSelect
          cropYear={cropYear}
          onChange={(newCropYear) => {
            setCropYear(newCropYear);
          }}
        />
      </View>
    </Modal>
  );
};
