import { VSpacer } from '@design';
import {
  ApiDiscount,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
} from '@shared/interfaces/api';
import { CalculatedProductDiscount, CalculationUtility } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import DetailHeader from '../../../../components/shared/DetailHeader/DetailHeader';
import { ProductDiscountItem } from './ProductDiscountItem';
import { UseBusinessDiscountsListProps } from '../../../../../hooks/useBusinessDiscounts';

export interface ProductDiscountsProps {
  businessDiscountsList: UseBusinessDiscountsListProps,
  component?: ApiProductOrderComponent,
  isDraft: boolean,
  onEdit: (
    discount: ApiDiscount,
    discountIndex: number,
    tankMixComponent?: ApiProductOrderProductMixComponent,
  ) => void,
  onDelete: (
    discount: ApiDiscount,
    discountIndex: number,
    tankMixComponent?: ApiProductOrderProductMixComponent,
  ) => void,
  locationId?: string,
  priceTypeId?: string,
  productMixComponents?: ApiProductOrderProductMixComponent[],
  viewMode?: boolean,
}

export const ProductDiscounts: FC<ProductDiscountsProps> = ({
  businessDiscountsList,
  component,
  isDraft,
  locationId,
  onEdit,
  onDelete,
  priceTypeId,
  productMixComponents,
  viewMode,
}) => {
  const {
    businessDiscountsListData: currentBusinessDiscounts,
    areBusinessDiscountsFetched,
  } = businessDiscountsList;

  const [translate] = useTranslation(['productOrders', 'common']);
  const { defaultPriceTypeId } = usePriceTypeList();

  const calculatedDiscounts = useMemo(() => {
    if (component) {
      return CalculationUtility.calculateProductOrderProductDiscounts(
        component,
        priceTypeId ?? defaultPriceTypeId,
        locationId,
      ).discounts;
    } else if (productMixComponents) {
      return productMixComponents.map(productMixComponent => {
        return CalculationUtility.calculateProductOrderProductDiscounts(
          productMixComponent,
          priceTypeId ?? defaultPriceTypeId,
          locationId,
        ).discounts;
      }).flat();
    }
    return [];
  }, [component, defaultPriceTypeId, productMixComponents, priceTypeId, locationId]);

  const flattenedProductMixDiscounts = useMemo(() => {
    if (productMixComponents) {
      return productMixComponents.map(
        c => c.discounts.map(
          (discount, localIndex) => ({
            component: c,
            discount,
            localIndex,
            productName: c.product?.skuName,
          }),
        ),
      ).flat();
    }
    return [];
  }, [productMixComponents]);

  const isDiscountEditable = useCallback((discount: CalculatedProductDiscount) => {
    if (areBusinessDiscountsFetched) {
      return !currentBusinessDiscounts?.data?.some((currentBusinessDiscount) => {
        return currentBusinessDiscount?.name === discount.name;
      });
    }
    return true;
  }, [areBusinessDiscountsFetched, currentBusinessDiscounts]);

  const themedStyles = useStyleSheet(
    {
      line: {
        borderTopWidth: 1,
        borderTopColor: 'color-basic-transparent-100',
      },
      container: {
        backgroundColor: 'color-basic-transparent-0',
        paddingHorizontal: 16,
        paddingTop: 4,
        paddingBottom: 40,
      },
    },
  );

  if (calculatedDiscounts.length === 0) {
    return <></>;
  }

  return (
    <View style={themedStyles.container}>
      <DetailHeader header={translate<string>('DISCOUNTS')} />
      {
        calculatedDiscounts.map((calculatedDiscount, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <View key={index}>
            <VSpacer size={index === 0 ? '3' : '5'} />
            <View style={themedStyles.line} />
            <VSpacer size="5" />
            <ProductDiscountItem
              calculatedDiscount={calculatedDiscount}
              discount={component
                ? component.discounts[index]
                : flattenedProductMixDiscounts[index].discount
              }
              index={index}
              isDraft={isDraft}
              isEditable={isDiscountEditable(calculatedDiscount)}
              onDelete={() => onDelete(
                component?.discounts?.[index] || flattenedProductMixDiscounts?.[index]?.discount,
                !!component ? index : flattenedProductMixDiscounts?.[index]?.localIndex,
                flattenedProductMixDiscounts?.[index]?.component,
              )}
              onEdit={() => onEdit(
                component?.discounts?.[index] || flattenedProductMixDiscounts?.[index]?.discount,
                !!component ? index : flattenedProductMixDiscounts?.[index]?.localIndex,
                flattenedProductMixDiscounts?.[index]?.component,
              )}
              prefix={flattenedProductMixDiscounts[index]?.productName}
              readOnly={(component?.product && !component.product.isActive)}
              viewMode={viewMode}
            />
          </View>
        ))
      }
    </View>
  );
};
