import { ApiDeliverablePublic } from '@shared/interfaces/api';
import {
  FarmPlanDeliverableV1 as V1,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v1/FarmPlanDeliverableV1';
import React from 'react';
import { DeliverableRenderer } from '../../DeliverableRenderer';
import { FarmPlanDeliverableDesktopView } from './FarmPlanDeliverableDesktopView';
import { FarmPlanDeliverableMobileView } from './FarmPlanDeliverableMobileView';

export class FarmPlanDeliverableV1PublicRender implements DeliverableRenderer {
  private deliverable: ApiDeliverablePublic<V1.Snapshot, V1.Selections>;

  constructor (deliverable: ApiDeliverablePublic) {
    this.deliverable = deliverable;
  }

  getPlanName (): string {
    return this.deliverable.deliverableData.header.farmPlanName;
  }

  getGrowerName (): string {
    return this.deliverable.deliverableData.header.growerName;
  }

  getBusinessName (): string {
    return this.deliverable.deliverableData.header.businessName;
  }

  getBusinessLocation (): string {
    return this.deliverable.deliverableData.header.locationName;
  }

  getDesktopView () {
    return (
      <FarmPlanDeliverableDesktopView
        deliverableId={this.deliverable.publicId}
        note={this.deliverable.note}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
      />
    );
  }

  getMobileView () {
    return (
      <FarmPlanDeliverableMobileView
        deliverableId={this.deliverable.publicId}
        selections={this.deliverable.selections}
        snapshot={this.deliverable.deliverableData}
      />
    );
  }
}
