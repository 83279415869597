import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Card, IndexPath, Modal } from '@ui-kitten/components';
import { Button, Input, Select, SelectItem, useBanner, BannerProps } from '@design';
import { BannerStatus, BannerStatuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const BannerDemo: FunctionComponent = () => {
  const [text, setText] = useState('Program XYZ already exists.');
  const [status, setStatus] = useState<BannerStatus>('info');

  const { createBanner, destroyBanner } = useBanner();

  const [showModal, setShowModal] = useState(false);
  const [idx, setIdx] = useState(1);

  const bannerProps = (bannerText: string): BannerProps => ({
    children: text + bannerText,
    status,
    testID: 'test-toast',
    actionAccessory: ({ dismissProps, actionProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button
          {...dismissProps}
          appearance="ghost"
          size="small"
          status="basic"
          testID="test-button"
        >
          Dismiss
        </Button>
        <Button
          {...actionProps}
          appearance="ghost"
          size="small"
          status="basic"
          testID="test-button"
        >
          Action
        </Button>
      </View>
    ) });

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Input
            label="Text"
            onChangeText={setText}
            testID="test-input"
            value={text}
          />
          <Select
            label="status"
            onSelect={(ip: IndexPath | IndexPath[]) => setStatus(
              BannerStatuses[(ip as IndexPath).row],
            )}
            selectedIndex={new IndexPath(BannerStatuses.findIndex((s) => s === status))}
            testID="banner-demo-status-selector"
            value={status}
          >
            {BannerStatuses.map((s, i) => (
              <SelectItem key={s} testID={`banner-demo-status-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
        </View>
        <DemoContainer>
          <DemoBlock>
            <Button
              onPress={() => {
                createBanner(bannerProps(` - Banner number # ${idx}`));
                setIdx((prevIdx) => prevIdx + 1);
              }}
              testID="test-button"
            >
              Make Banner
            </Button>
          </DemoBlock>
          <DemoBlock>
            <Button onPress={() => setShowModal(true)} testID="test-button">Modal</Button>
          </DemoBlock>
          <DemoBlock>
            <Button onPress={() => destroyBanner()} testID="test-button">Destroy last</Button>
          </DemoBlock>
        </DemoContainer>
      </Card>
      {showModal && (
        <Modal backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} visible>
          <Card
            footer={(props) => (
              <View {...props}>
                <Button
                  onPress={() => setShowModal(false)}
                  testID="test-button"
                >
                  Close Modal
                </Button>
              </View>
            )}
            testID="test-card"
          >
            <Button onPress={() => createBanner(bannerProps(` - Banner number # ${idx}`))} testID="test-button">
              Make Banner
            </Button>
          </Card>
        </Modal>
      )}
    </View>
  );
};
