export default {
  ADD_ANOTHER_PRICE: 'Add another price',
  ADD_COMPONENT_SUBTITLE: 'Unchecking a component already added to the pass will remove it from the pass.',
  ADD_COMPONENTS: 'Add components',
  ADD_LOCATION_PRICE: 'Add Location Price',
  ADD_PRICE: 'Add price',
  AVAILABLE_PRODUCT_MIXES: 'Available Product Mixes',
  AVAILABLE_PRODUCTS: 'Available Products',
  BUSINESS_LOCATION: 'Business Location',
  BUSINESS_NAME: 'Business Name',
  BUSINESS_PRODUCT_SUB_CATEGORY: 'Business Product Category',
  CATEGORY: 'Category',
  CHEMICAL: 'Chemical',
  CHEMICAL_PRODUCT_BUTTON: 'Create chemical product',
  COMPONENT_COUNT: '{{count}} Component',
  COMPONENTS_COUNT: '{{count}} Components',
  CORPORATE_LOCATION: 'Corporate',
  CORPORATE_PRICING_INFO_DIALOG_MESSAGE: 'Corporate pricing is the default business pricing for the product. It is used in orders that aren\'t assigned to a business location.',
  CORPORATE_PRICING_INFO_DIALOG_TITLE: 'Corporate',
  CREATE_CHEMICAL_PRODUCT: 'Create Chemical Product',
  CREATE_FERTILIZER_PRODUCT: 'Create Fertilizer Product',
  CREATE_OTHER_PRODUCT: 'Create Other Product',
  CREATE_SEED_PRODUCT: 'Create Seed Product',
  CROP: 'Crop',
  CROP_SUBTYPE: 'Crop Subtype',
  CROP_TYPE: 'Crop Type',
  DATE_LAST_MODIFIED: 'Date last modified',
  DEFAULT_PRICE: 'Default Price',
  DELETE_LOCATION_PRICE_DIALOG_MESSAGE: '{{usages}} orders using the "{{location}} | {{priceType}}" will be updated to reflect corporate pricing.\n\nDeliverables will not be affected.',
  DELETE_LOCATION_PRICE_DIALOG_TITLE: 'Delete location price?',
  DENSITY: 'Density',
  DRY: 'Dry',
  EDIT: 'Edit',
  EDIT_CHEMICAL_PRODUCT: 'Edit Chemical Product',
  EDIT_FERTILIZER_PRODUCT: 'Edit Fertilizer Product',
  EDIT_OTHER_PRODUCT: 'Edit Other Product',
  EDIT_SEED_PRODUCT: 'Edit Seed Product',
  ERROR_BUSINESS_CATEGORIES: 'There was an error loading business categories!',
  ERROR_BUSINESS_LOCATIONS: 'There was an error loading business locations!',
  ERROR_LOADING_PRODUCT: 'Unable to load that product',
  ERROR_PRODUCT_SKU_ALREADY_USED: 'Product SKU already used by another product',
  ERROR_PRICE_TYPES_CHANGED: 'The price types for this product have been updated since opening this form. Please try again.',
  FERTILIZER: 'Fertilizer',
  FERTILIZER_PRODUCT_BUTTON: 'Create fertilizer product',
  FORMULATION: 'Formulation',
  FORMULATION_UPDATE_WARNING: 'In order to maintain the integrity of existing programs and farm plans, once a product has been created with a specific formulation, the only way to update that formulation is to create a new product.',
  LB_PER_GAL: 'lb/gal',
  LBS_PER_UNIT: 'Pounds Per Unit',
  LIQ_DENSITY: 'Liq. Density',
  LIQUID: 'Liquid',
  LOCATION_PRICE: 'Location Price',
  LOCATION_PRICE_DUPLICATE_WARNING: 'Only one price per location is allowed!',
  LOCATION_PRICING: 'Location Pricing',
  MANUFACTURER: 'Manufacturer',
  MANUFACTURER_MESSAGE: 'Manufacturer can only be added through import',
  NO_COMPONENTS: 'No components',
  NO_PRODUCTS: 'There are no available products',
  OTHER_PRODUCT_BUTTON: 'Create other product',
  PACKAGE: 'Package',
  PACKAGE_NAME: 'Package Name',
  PRICE_UNIT: 'Price/unit',
  PRICING: 'Pricing',
  PRODUCTS: 'Products',
  PRODUCT_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this product?',
  PRODUCT_CATEGORY: 'Product Category',
  PRODUCT_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this product?',
  PRODUCT_DETAILS: 'Product Details',
  PRODUCT_EDIT_SUCCESS: '{{name}} successfully edited',
  PRODUCT_ID: 'Product ID',
  PRODUCT_NAME: 'Product Name',
  PRODUCT_SKU_NAME: 'Product SKU Name',
  PRODUCT_SKU_UNIT_PRICE: 'Product SKU Unit Price',
  PRODUCT_SUCCESS: '{{name}} successfully created',
  SEED: 'Seed',
  SEEDS_PER_UNIT: 'Seeds Per Unit',
  SEED_PRODUCT: 'Seed Product',
  SEED_PRODUCT_BUTTON: 'Create seed product',
  SELECT_COMPONENT: 'Select a component type to view available components that can be added to this pass',
  SELECTED_PRODUCTS: 'Selected Products',
  SKU_NAME: 'SKU Name',
  SKU_PRICE: 'SKU Price',
  SKU_UNIT_QUANTITY: 'SKU Unit Quantity',
  SUB_CATEGORY: 'Sub category',
  TAB_TITLE_CHEMICAL: 'Chemical',
  TAB_TITLE_FERTILIZER: 'Fertilizer',
  TAB_TITLE_OTHER: 'Other Products',
  TAB_TITLE_SEED: 'Seed',
  UNIT_PRICE: 'Unit Price',
  UNITS_PER_SKU: 'Units per SKU',
  UPDATE_PRODUCT: 'Update product?',
  UPLOAD_PRODUCTS_CSV: 'Upload CSV file with Products using ',
  VIEW_PRICING: 'View pricing',
  YES_UPDATE: 'Yes, update',
};
