import { Button, Icon } from '@design';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';

export interface RemoveFarmButtonProps {
  onRemoveFarm?(): void,
}

export default function RemoveFarmButton ({ onRemoveFarm }: RemoveFarmButtonProps) {
  const [translate] = useTranslation(['growers', 'common']);
  const [visible, setVisible] = useState(false);

  return (
    <>
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('YES_DELETE_FARM')}
        messageText={translate('REMOVE_FARM_DIALOG_DESCRIPTION')}
        onCancel={() => setVisible(false)}
        onConfirm={onRemoveFarm}
        status="warning"
        title={translate('REMOVE_FARM_DIALOG_TITLE')}
        visible={visible}
      />
      <Button
        accessoryLeft={(props) => <Icon name="Trash" testID="farm-delete-icon" {...props} />}
        appearance="ghost"
        disabled={!onRemoveFarm}
        onPress={() => setVisible(true)}
        size="medium"
        status="basic"
        testID="farm-delete-button"
      >
        {translate<string>('DELETE_FARM')}
      </Button>
    </>
  );
}
