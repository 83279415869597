import { View } from 'react-native';
import React from 'react';
import { HSpacer } from '@design';
import { GlobalHeader } from '../../../elements/navigation/GlobalHeader';
import { Help } from './Help';
import { ScreenSizes, useScreenSizing } from '../../../hooks/useScreenSizing';

const Spacer = () => {
  const sizeSetting = useScreenSizing();
  const isDesktop = sizeSetting === ScreenSizes.Wide;

  return (
    <>
      {isDesktop ? (
        <>
          <HSpacer size="5" />
          <HSpacer size="6" />
        </>
      ) : (
        <HSpacer size="5" />
      )}
    </>
  );
};

export const PublicHelp = () => {
  return (
    <>
      <GlobalHeader />
      <View style={{ flexDirection: 'row' }}>
        <Spacer />
        <Help style={{ flex: 1 }} />
        <Spacer />
      </View>
    </>
  );
};
