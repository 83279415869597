import { INavigationSectionProps } from '@design/Navigation/Navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '@shared/enums';
import { Permissions, RoleUtility } from '@shared/utils';
import { Navigation } from '@design';
import { canSeeReports } from '../../utilities/ReportUtility';
import {
  getBusinessProductListPage,
  getBusinessRoute,
  Routes,
} from '../../constants';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';

export const Sidebar = () => {
  const [translate] = useTranslation('sidebar');
  const { user, currentBusinessId } = useAuthentication();

  const helpSection: INavigationSectionProps[] = [{
    label: translate('HELP'),
    navItems: [{
      label: 'Help',
      route: Routes.HELP,
      leftAccessory: 'QuestionMarkCircle',
    }],
  }];

  Object.freeze(helpSection);

  const navigationSections: INavigationSectionProps[] = [
    {
      label: translate('ADMIN'),
      navItems: [
        {
          label: translate('USERS'),
          route: Routes.USER_LIST,
          leftAccessory: 'People',
        },
        {
          label: translate('BUSINESS'),
          route: currentBusinessId
            ? getBusinessRoute(currentBusinessId)
            : getBusinessRoute(user.businessId),
          leftAccessory: 'GrowersBusiness',
          orUserType: [UserType.BUSINESS, UserType.INTERNAL],
          disabled: !currentBusinessId,
        },
        {
          label: translate('PRODUCTS'),
          route: getBusinessProductListPage(),
          leftAccessory: 'GrowersProduct',
          disabled: !currentBusinessId,
        },
      ],
    },
    {
      label: translate('PLANNING'),
      navItems: [
        {
          label: translate('PREPARE_AND_REVIEW'),
          route: Routes.PREPARE_REVIEW.replace(/:tab\?/, 'tank-mixes'),
          leftAccessory: 'GrowersPrepareReview',
          disabled: !currentBusinessId,
        },
        {
          label: translate('CUSTOMER_PLANNING'),
          route: Routes.CUSTOMER_PLANNING.replace(/:tab/, 'customers'),
          leftAccessory: 'GrowersPlanning',
          disabled: !currentBusinessId,
        },
      ],
    },
  ];

  navigationSections[0].navItems.push({
    label: translate('DASHBOARD'),
    route: Routes.DASHBOARD,
    leftAccessory: 'PieChart',
    disabled: !currentBusinessId,
  });

  if (canSeeReports(user)) {
    navigationSections[0].navItems.push({
      label: translate('REPORTS'),
      route: Routes.REPORTS,
      leftAccessory: 'FileText',
      orPermissions: [Permissions.MODIFY_BUSINESS_OBJECTS],
      disabled: !currentBusinessId,
    });
  }

  if (
    RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES)
    && user.lastName.endsWith('UX')
  ) {
    navigationSections.push({
      label: 'UX',
      navItems: [
        {
          label: 'UX Sandbox',
          route: Routes.UX_SANDBOX,
          leftAccessory: 'Square',
        },
      ],
    });
  }

  return (
    <Navigation
      helpSection={helpSection}
      sections={navigationSections}
      testID="sidebar-navigation"
    />
  );
};
