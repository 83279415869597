import * as _ from 'lodash';
import Constants from 'expo-constants';
import { AppManifest } from 'expo-constants/build/Constants.types';

export class Environment {
  static get (key: string): string {
    return _.get(Constants, ['manifest', 'extra', key], '');
  }

  static getManifest (): AppManifest {
    return Constants.manifest;
  }
}
