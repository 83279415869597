import React, { FC, ReactElement } from 'react';
import { View } from 'react-native';
import { FalsyFC, RenderProp } from '@ui-kitten/components/devsupport';
import { useStyleSheet } from '@ui-kitten/components';
import {
  ButtonProps,
  FalsyIcon,
  IconProps,
  IconName,
  Text,
  TextLinkProps,
} from '@design';
import { testId } from '../../../../utilities';

interface DetailHeaderProps {
  header: string,
  iconAccessoryRight?: RenderProp<Partial<IconProps>> | IconName,
  iconAccessoryLeft?: RenderProp<Partial<IconProps>> | IconName,
  buttonAccessory?: RenderProp<Partial<ButtonProps>>,
  textLinkAccessory?: RenderProp<Partial<TextLinkProps>>,
}

const DetailHeader: FC<DetailHeaderProps> = ({
  header,
  iconAccessoryRight,
  iconAccessoryLeft,
  buttonAccessory,
  textLinkAccessory,
}) => {
  const themedStyles = useStyleSheet({
    container: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    icon: {
      tintColor: 'text-hint-color',
      padding: 12,
    },
    link: {
      padding: 12,
    },
    headerText: {
      paddingBottom: 10,
      paddingTop: 16,
    },
    row: {
      flexDirection: 'row',
    },
  });

  return (
    <View style={themedStyles.container}>
      <View style={themedStyles.headerText}>
        <Text category="label" {...testId('detail-header-text')}>{header}</Text>
      </View>
      <View style={themedStyles.row}>
        <FalsyFC
          {...testId('detail-header-button')}
          appearance="outline"
          component={buttonAccessory as RenderProp<Partial<ReactElement>>}
          size="small"
          status="basic"
        />
        <FalsyFC
          {...testId('detail-header-text-link')}
          component={textLinkAccessory}
          style={themedStyles.link}
        />
        <FalsyIcon
          {...testId('detail-header-icon-left')}
          component={iconAccessoryLeft}
          style={themedStyles.icon}
        />
        <FalsyIcon
          {...testId('detail-header-icon-right')}
          component={iconAccessoryRight}
          style={themedStyles.icon}
        />
      </View>
    </View>
  );
};

export default DetailHeader;
