export class NumberInputUtils {
  public static trailingDecimalZeroRegex = /^(\d+\.\d*?)(0+$)/g;
  /**
   * Returns the number of decimal numbers for a number
   */
  public static getNumberDecimalDigitCount (value: number) {
    if (Math.floor(value) !== value) {
      return value.toString().split('.')[1].length || 0;
    }
    return 0;
  }

  /**
   * Rounds a number to the number of decimal points
   * Example: roundNumber(125.356, 2) = 1235.36
   */
  public static roundNumber (value: number, decimalNumber: number): number {
    return Number(`${Math.round(+(`${value}e${decimalNumber}`))}e${-decimalNumber}`);
  }

  /**
   * Returns the number of decimal digits for a number represented as string
   */
  public static getDecimalDigitCount (value: string): number {
    return (value.split('.').length > 1
      ? value.split('.')[1].length : 0);
  }
}
