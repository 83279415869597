import { Autocomplete, AutocompleteItem, Icon } from '@ui-kitten/components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Sizing } from '../../../constants/Sizing';
import { ThemeColors } from '../../../constants/ThemeColors';
import { testId } from '../../../utilities/testId';

interface AutocompleteInputProps {
  options: Option[];
  label: string;
  caption?: string;
  inline?: boolean,
  onSelect: (selection: string | number) => void;
  onChange: (query: string) => void;
  disabled?: boolean;
  nativeId?: string;
}

export interface Option {
  label: string,
  value: string,
  selected: boolean,
  idx: number,
}

const styles = StyleSheet.create({
  caption: {
    position: 'absolute',
    top: 3.5 * Sizing.BASE_SPACING,
    fontSize: 0.8 * Sizing.EM,
    marginTop: 0.5 * Sizing.QUARTER_SPACING,
    color: ThemeColors.INPUT,
    fontStyle: 'italic',
    paddingLeft: Sizing.BASE_SPACING,
  },
  inputContainer: {
    backgroundColor: ThemeColors.DARK_GRAY,
    borderTopLeftRadius: Sizing.QUARTER_SPACING,
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    marginBottom: Sizing.BASE_SPACING,
  },
  input: {
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: 3,
    borderColor: ThemeColors.DARK_GRAY,
    borderBottomColor: ThemeColors.DARK_GRAY,
  },
  focusedInput: {
    borderColor: ThemeColors.PRIMARY,
    borderBottomColor: ThemeColors.PRIMARY,
  },
  inline: {
    height: 2.5 * Sizing.EM,
  },
  iconWrapper: {
    position: 'absolute',
    right: Sizing.HALF_SPACING,
    height: 3.4 * Sizing.EM,
    justifyContent: 'center',
  },
  icon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
  label: {
    color: ThemeColors.INPUT,
    fontSize: 0.8 * Sizing.EM,
    position: 'absolute',
    top: Sizing.HALF_SPACING,
    left: Sizing.BASE_SPACING,
  },
  focusedLabel: { color: ThemeColors.PRIMARY },
  text: {
    fontSize: 1.1 * Sizing.BASE_SPACING,
    color: ThemeColors.TEXT,
    paddingTop: Sizing.EM,
    paddingBottom: Sizing.EM,
  },
  withLabelText: {
    paddingTop: 1.75 * Sizing.BASE_SPACING,
    paddingBottom: Sizing.QUARTER_SPACING,
  },
  optionText: {
    color: ThemeColors.TEXT,
    fontSize: Sizing.BASE_SPACING,
  },
  disabled: {
    opacity: 0.5,
  },
});

export const AutocompleteInput: FunctionComponent<AutocompleteInputProps> = ({
  options,
  label,
  caption = '',
  inline,
  onSelect: onSelectCallback,
  onChange,
  disabled,
  nativeId,
}) => {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const selectedIdx = options.findIndex((option) => option.selected);
    if (selectedIdx !== -1) setValue(options[selectedIdx].label);
  }, [options]);

  const onSelect = (index: number) => {
    setValue(options[index].label);
    onSelectCallback(options[index].value);
  };

  const onChangeText = (query: string) => {
    setValue(query);
    onChange(query);
    onSelectCallback(null);
  };

  const renderOption = (item, index) => (
    <AutocompleteItem
      key={index}
    >
      <Text
        style={styles.optionText}
        {...testId(`${nativeId}-option-${index}`)}
      >
        {item.label}
      </Text>
    </AutocompleteItem>
  );

  const showLabel = (options.find((s) => s.selected === true));

  return (
    <View
      {...testId(nativeId)}
      style={[
        { marginBottom: Sizing.BASE_SPACING },
        disabled && styles.disabled,
      ]}
    >
      <View
        style={[styles.inputContainer]}
        {...testId(`${nativeId}-label`)}
      >
        {!inline && showLabel && label && (
        <Text
          style={[
            styles.label,
            focused && styles.focusedLabel,
          ]}
        >
          {label}
        </Text>
        )}
        <View style={styles.iconWrapper}>
          <Icon
            fill={ThemeColors.LIGHT_GRAY}
            name={focused ? 'chevron-up-outline' : 'chevron-down-outline'}
            style={[
              styles.icon,
            ]}
            {...testId(`${nativeId}-icon`)}
          />
        </View>
        <Autocomplete
          {...testId(`${nativeId}-input`)}
          disabled={disabled}
          onBlur={() => setFocused(false)}
          onChangeText={onChangeText}
          onFocus={() => setFocused(true)}
          onSelect={onSelect}
          placeholder={label}
          placeholderTextColor={ThemeColors.INPUT}
          size="oldSystem"
          style={[
            styles.input,
            focused && styles.focusedInput,
          ]}
          textStyle={[
            styles.text,
            showLabel && styles.withLabelText,
          ]}
          value={value}
        >
          {options.map(renderOption)}
        </Autocomplete>
      </View>
      {!inline && caption !== '' && (
        <Text
          {...testId(`${nativeId}-caption`)}
          style={[
            styles.caption,
            focused && styles.focusedLabel,
          ]}
        >
          {caption}
        </Text>
      )}
    </View>
  );
};
