import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import { Icon } from '@design';
import { Sizing, ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6 * Sizing.EM,
    paddingHorizontal: Sizing.BASE_SPACING,
    paddingVertical: Sizing.QUARTER_SPACING,
    backgroundColor: ThemeColors.DARK_GRAY,
  },
  buttonWithIcon: { paddingHorizontal: Sizing.BASE_SPACING },
  text: {
    fontSize: 0.9 * Sizing.EM,
    color: ThemeColors.TEXT,
  },
  icon: {
    width: Sizing.EM,
    height: Sizing.EM,
    marginLeft: Sizing.HALF_SPACING,
  },
});

export interface ActionLabelProps extends ViewProps {
  onDismiss?: () => void,
  children?: any,
  nativeID?: string;
  style?: any;
  disabled?: boolean;
}

export const StyledActionLabel = (props: ActionLabelProps) => (
  <View
    {...props}
    style={[styles.label, props.style]}
  >
    <Text
      nativeID={props.nativeID}
      style={styles.text}
    >
      {props.children}
    </Text>
    {!props.disabled && (
      <TouchableOpacity onPress={() => props.onDismiss && props.onDismiss()}>
        <Icon
          fill={ThemeColors.PRIMARY}
          inside={ThemeColors.CLOSE}
          name="Close"
          style={styles.icon}
          testID="dismiss-icon"
        />
      </TouchableOpacity>
    )}
  </View>
);
