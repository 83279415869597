import { Badge, Button, Card, Header, HSpacer, Icon, Text, VSpacer } from '@design';
import { PlannedFarm } from '@shared/interfaces';
import { ApiCropLogic, ApiPlanningParameter } from '@shared/interfaces/api';
import { CalculationUtility, FarmUtility, use } from '@shared/utils';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { CheckBox } from '../../../../../ui-components';
import { StringUtility } from '../../../../../utilities';
import { FarmPlanRowDetails } from '../../../FarmPlan/FarmPlanRowDetails';
import { AreaSelectionProps } from './FarmPlanProgramAssignment';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    flex: 1,
  },
});

export interface FarmCardProps {
  expanded?: boolean,
  index: number,
  onAssignProgram: () => void,
  onChangeSelections: (selections: AreaSelectionProps) => void,
  onEditProgram: (program: ApiCropLogic, areaId: string) => void,
  onRemoveProgram: (areaId: string) => void,
  plannedFarm: PlannedFarm,
  planningParameters: ApiPlanningParameter[],
  selections: { [id: string]: boolean },
  testID: string,
}

export const FarmCard = ({
  expanded,
  index,
  onChangeSelections,
  onEditProgram,
  onAssignProgram,
  onRemoveProgram,
  plannedFarm,
  planningParameters,
  selections,
  testID,
}: FarmCardProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showFields, setShowFields] = useState(expanded);
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => setShowFields(expanded), [expanded]);

  const fieldsCount = plannedFarm.growerFields.length;
  const cropZonesCount = plannedFarm.growerFields
    .reduce((acc, f) => f.cropZones.length + acc, 0);
  const programsCount = plannedFarm.growerFields
    .reduce((acc, plannedField) => {
      const fieldsPrograms = !plannedField.cropLogic ? 0 : 1;
      const cropZonePrograms = plannedField.cropZones
        .filter((cropZone) => !!cropZone.cropLogic).length;
      return fieldsPrograms + cropZonePrograms + acc;
    }, 0);

  const acres = useMemo(
    () => StringUtility.localizeNumber(FarmUtility.getFarmArea(plannedFarm), 3),
    [plannedFarm],
  );

  const isFieldSelected = useMemo(() => {
    return plannedFarm.growerFields.some((field) => {
      if (selections[field.id]) {
        return true;
      }
      return field.cropZones.some((cropZone) => {
        if (selections[cropZone.id]) {
          return true;
        }
        return false;
      });
    });
  }, [plannedFarm.growerFields, selections]);

  useEffect(() => {
    const allFieldsSelected = plannedFarm.growerFields
      .every((field) => selections[field.id]);
    setIsSelected(allFieldsSelected);
  }, [plannedFarm.growerFields, selections]);

  const toggleFieldsSelection = useCallback(() => {
    const newSelections = { ...selections };
    plannedFarm.growerFields.forEach((field) => {
      newSelections[field.id] = !isSelected;
      field.cropZones.forEach((cropZone) => {
        newSelections[cropZone.id] = !isSelected;
      });
    });
    onChangeSelections({ ...newSelections });
  }, [isSelected, plannedFarm.growerFields, selections, onChangeSelections]);

  const TotalCost = use(CalculationUtility.calculateFarmCosts(plannedFarm), (pricedFarm) => {
    if (!pricedFarm.cost) { return null; }

    return <Text
      category="h6"
      numberOfLines={1}
      testID={`${testID}-total-cost`}
    >
      {StringUtility.formatCurrency(pricedFarm.cost)}
      <HSpacer size="3"/>
      <Text category="h6" style={{ color: GrowersDarkTheme['color-basic-600'] }}>|</Text>
      <HSpacer size="3"/>
    </Text>;
  });

  return (
    <Card testID={`${testID}-${index}`}>
      <View style={styles.container}>
        <View>
          <VSpacer size="3" />
          <CheckBox
            checked={isSelected}
            onChange={toggleFieldsSelection}
          />
        </View>
        <HSpacer size="7" />
        <View style={styles.header}>
          <Header
            childrenAlign="flex-start"
            level="2"
            subTitle={
              `${translate('FIELDS_COUNT', { count: fieldsCount })
              } | ${translate('CROP_ZONES_COUNT', { count: cropZonesCount })}`
            }
            subTitleHint
            testID={`${testID}-header`}
            title={plannedFarm.farmName}
            titleMaxWidth="100%"
          >
            {TotalCost}
            <Text
              category="h6"
              numberOfLines={1}
              testID={`${testID}-total-acres`}
            >
              {acres + ' ' + translate<string>('ACRES')}
            </Text>
          </Header>
          <VSpacer size="5" />
          <View style={styles.row}>
            {!programsCount ? <View /> : (
              <Badge
                status="info"
                testID={`${testID}-program-count`}
              >
                {translate<string>('PROGRAMS_COUNT', { count: programsCount }).toUpperCase()}
              </Badge>
            )}
            <View style={styles.row}>
              <Button
                accessoryLeft={showFields ? (chevronProps) => (
                  <Icon {...chevronProps} name="Minus" testID={`${testID}-show-fields-icon`} />
                ) : undefined}
                appearance="outline"
                onPress={() => setShowFields(!showFields)}
                size="medium"
                status="basic"
                testID={`${testID}-show-fields`}
              >
                {translate<string>(showFields ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
              </Button>
              <HSpacer size="7" />
              <Button
                accessoryLeft={(p) => <Icon name="Plus" testID={`${testID}-apply-program-icon`} {...p} />}
                appearance="outline"
                disabled={!isFieldSelected}
                onPress={onAssignProgram}
                size="medium"
                testID={`${testID}-apply-program`}
              >
                {translate<string>('APPLY_PROGRAM')}
              </Button>
            </View>
          </View>
        </View>
      </View>
      {showFields && (
        <>
          <VSpacer size="5" />
          <FarmPlanRowDetails
            farm={plannedFarm}
            farmIndex={index}
            onChangeSelections={onChangeSelections}
            onEditProgram={onEditProgram}
            onRemoveProgram={onRemoveProgram}
            planningParams={planningParameters}
            selections={selections}
          />
        </>
      )}
    </Card>
  );
};
