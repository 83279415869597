import React, { Component, ReactNode } from 'react';
import { StyleProp, View, ViewProps, ViewStyle } from 'react-native';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import {
  Overwrite,
  TouchableWebElement,
} from '@ui-kitten/components/devsupport';
import { StyleType } from '@ui-kitten/components/theme';

type RowDetailStyledProps = Overwrite<StyledComponentProps, {}>;

export interface RowDetailProps extends ViewProps, RowDetailStyledProps {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}

/**
 *
 */

@styled('RowDetail')
export class RowDetail extends Component<RowDetailProps> {
  private getComponentStyle = (source: StyleType) => ({
    rowDetail: source,
  });

  public render (): TouchableWebElement {
    const {
      children,
      eva,
      style,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View style={[evaStyle.rowDetail, style]} {...viewProps}>
        {children}
      </View>
    );
  }
}
