import React, { FC, useLayoutEffect, useState, useMemo } from 'react';
import { useWindowDimensions, View, ViewProps } from 'react-native';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import * as _ from 'lodash';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';

// There is currently a proposal issue and PR to add a Numeric range type to TypeScript,
// that will eliminate these long number unions.
// https://github.com/microsoft/TypeScript/issues/43505 -The issue thread
// https://github.com/sindresorhus/type-fest/pull/319 -The PR

type XSmallType = 1 | 2 | 3 | 4;
type SmallType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
type MediumToXLargeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ScreenSizeType = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';

export interface ResponsiveViewProps extends ViewProps, StyledComponentProps {
  xSmall?: XSmallType,
  small?: SmallType,
  medium?: MediumToXLargeType,
  large?: MediumToXLargeType,
  xLarge?: MediumToXLargeType,
  children: React.ReactNode,
}

const screenBreakpoints = {
  xSmall: {
    min: 0,
    max: 600,
    cols: 4,
  },
  small: {
    min: 600,
    max: 905,
    cols: 8,
  },
  medium: {
    min: 905,
    max: 1240,
    cols: 12,
  },
  large: {
    min: 1240,
    max: 1440,
    cols: 12,
  },
  xLarge: {
    min: 1440,
    max: 9999,
    cols: 12,
  },
};

const colWidths = {
  xSmall: 23,
  small: 11.75,
  medium: 8,
  large: 8,
  xLarge: 8,
};

export const ResponsiveViewRaw: FC<ResponsiveViewProps> = ({
  xSmall,
  small,
  medium,
  large,
  xLarge,
  eva,
  children,
  style,
  ...viewProps
}: ResponsiveViewProps) => {
  const { width: windowWidth } = useWindowDimensions();
  const [cols, setCols] = useState<number>(4);
  const [screenSize, setScreenSize] = useState<ScreenSizeType>('small');

  // TODO: Need to find a better and performant algorithm to find the
  // proper & logical 'lowestProvidedSize'.

  // For example: <ResponsiveView xSmall={4} medium={6} ... />
  // small should use the value of 4 and not 6.

  // Another Example: <ResponsiveView xSmall={4} small={6} xLarge={3} ... />
  // medium & large should use 6 and not 3.
  const lowestProvidedSize = _.without([xSmall, small, medium, large, xLarge], null, undefined);
  const defaultSize = !_.isUndefined(_.last(lowestProvidedSize)) ? _.last(lowestProvidedSize) : 4;

  const calcElemSize = (elSizeProp: number, screen: number) => {
    let elSize;

    if (_.isUndefined(elSizeProp)) {
      elSize = defaultSize;
    } else {
      elSize = elSizeProp;
    }

    return `${(elSize * screen)}%`;
  };

  useLayoutEffect(() => {
    if (_.inRange(windowWidth, screenBreakpoints.xSmall.min, screenBreakpoints.xSmall.max)) {
      setCols(screenBreakpoints.xSmall.cols);
      setScreenSize('xSmall');
    } else if (_.inRange(windowWidth, screenBreakpoints.small.min, screenBreakpoints.small.max)) {
      setCols(screenBreakpoints.small.cols);
      setScreenSize('small');
    } else if (_.inRange(windowWidth, screenBreakpoints.medium.min, screenBreakpoints.medium.max)) {
      setCols(screenBreakpoints.medium.cols);
      setScreenSize('medium');
    } else if (_.inRange(windowWidth, screenBreakpoints.large.min, screenBreakpoints.large.max)) {
      setCols(screenBreakpoints.large.cols);
      setScreenSize('large');
    } else if (_.inRange(windowWidth, screenBreakpoints.xLarge.min, screenBreakpoints.xLarge.max)) {
      setCols(screenBreakpoints.xLarge.cols);
      setScreenSize('xLarge');
    }
  }, [windowWidth]);

  const elemSize = useMemo(() => {
    const sizes = {
      xSmall: () => calcElemSize(xSmall, colWidths[screenSize]),
      small: () => calcElemSize(small, colWidths[screenSize]),
      medium: () => calcElemSize(medium, colWidths[screenSize]),
      large: () => calcElemSize(large, colWidths[screenSize]),
      xLarge: () => calcElemSize(xLarge, colWidths[screenSize]),
    };

    return sizes[screenSize]();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cols, screenSize]);

  const componentStyle = {
    xSmall: PropsServiceHelper.allWithPrefixMapped(eva.style, 'xSmall'),
    small: PropsServiceHelper.allWithPrefixMapped(eva.style, 'small'),
    medium: PropsServiceHelper.allWithPrefixMapped(eva.style, 'medium'),
    large: PropsServiceHelper.allWithPrefixMapped(eva.style, 'large'),
    xLarge: PropsServiceHelper.allWithPrefixMapped(eva.style, 'xLarge'),
  };

  return (
    <View {...viewProps} style={[componentStyle[screenSize], { width: elemSize }, style]}>
      {children}
    </View>
  );
};

export const ResponsiveView: FC<ResponsiveViewProps> = styled('ResponsiveView')(ResponsiveViewRaw);
