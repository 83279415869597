import { Button, HSpacer, Modal, Select, SelectItem, Text } from '@design';
import { ApiFulfillmentLocation } from '@shared/interfaces/api';
import { IndexPath, StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

const styles = StyleService.create({
  title: {
    color: 'text-hint-color',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    marginBottom: 25,
    width: 218,
  },
  description: {
    marginBottom: 25,
  },
  footer: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    flexDirection: 'row',
    display: 'flex',
  },
});

interface SetFulfillmentLocationButtonProps {
  fulfillmentLocations: ApiFulfillmentLocation[],
  onFulfillmentLocationSelect: (locationId: string | null) => void;
}

export const SetFulfillmentLocationButton: FC<SetFulfillmentLocationButtonProps> = ({
  fulfillmentLocations,
  onFulfillmentLocationSelect,
}) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const [show, setShow] = useState(false);
  const themedStyles = useStyleSheet(styles);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  return (
    <>
      <Modal
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <View style={themedStyles.footer}>
              <Button
                {...primaryButtonProp}
                onPress={() => setShow(false)}
                testID="cancel-set-fulfillment-location-button"
              >
                {translate<string>('CANCEL')}
              </Button>
              <HSpacer {...spacerProp} />
              <Button
                {...secondaryButtonProp}
                appearance="filled"
                onPress={() => {
                  onFulfillmentLocationSelect(selectedLocationId);
                  setShow(false);
                }}
                testID="apply-fulfillment-location-button"
              >
                {translate<string>('APPLY')}
              </Button>
            </View>
          </>
        )}
        footerStyle={[
          themedStyles.footer,
          { justifyContent: 'flex-end' },
        ]}
        hideCloseButton
        onClose={() => setShow(false)}
        testID="set-fulfillment-location-modal"
        title={translate<string>('SET_FULFILLMENT_LOCATION')}
        visible={show}
      >
        <Text category="p2" style={themedStyles.description}>
          {translate<string>('SET_FULFILLMENT_LOCATION_DESCRIPTION')}
        </Text>
        <Select
          label={translate<string>('FULFILLMENT_LOCATION')}
          onSelect={(i) => {
            const location = fulfillmentLocations[(i as IndexPath).row - 1];
            setSelectedLocationId(location?.id ?? null);
          }}
          placeholder={translate<string>('NONE')}
          readOnlyLabel={false}
          testID="fulfillment-location-select"
          value={fulfillmentLocations.find(
            (location) => location.id === selectedLocationId,
          )?.name ?? translate<string>('NONE')}
        >
          <>
            <SelectItem
              key={''}
              testID="fulfillment-location-select-none"
              title={translate<string>('NONE')}
            />
            {fulfillmentLocations.map((location, i) => (
              <SelectItem
                key={location.id}
                testID={`fulfillment-location-select-${i}`}
                title={location.name}
              />
            ))}
          </>
        </Select>
      </Modal>
      <Button
        appearance="outline"
        onPress={() => setShow(true)}
        size="small"
        testID="set-fulfillment-location-open-button"
      >
        {translate<string>('SET_FULFILLMENT_LOCATION')}
      </Button>
    </>
  );
};
