export default {
  ACCEPTED_REPORT: 'Accepted Order Details Report',
  ACCEPTED_REPORT_DESCRIPTION: 'This report aggregates all SKUs and orders that have an accepted status within GROWERS Rally at the time you generate this report. The report contains information such as product price and unit quantity aligned with owners, growers, and the order it was associated with.  All order specific discounts applied will be displayed whenever any single SKU from that order is considered accepted. If a percent discount was included, that percent is calculated based on the order total, not just the accepted SKU(s).',
  BUSINESS_PRICING_REPORT: 'Business Pricing Report',
  BUSINESS_PRICING_REPORT_DESCRIPTION: 'This report displays the unit price at which all product SKUs sold within Quick Quote and Farm Plan orders within your business. It can be used to compare discounted prices within orders against the current unit price of the product.',
  DESCRIPTION: 'Description',
  REPORTS: 'Reports',
  REPORT_NAME: 'Report Name',
  SALES_INSIGHT_REPORT: 'Sales Insight Report',
  SALES_INSIGHT_REPORT_DESCRIPTION: 'This report aggregates all SKUs and Orders within your business at the time you generate the report. It shows you the sales status of each record and can be used to compare sales progress against forecasted progress for the year.',
};
