import { Select, SelectItem, useToast } from '@design';
import { Country, State } from '@shared/constants';
import { IndexPath } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { BusinessLocationApi } from '../../../../utilities/api';

const emptyArray = [];

export interface BusinessCountrySelectProps {
  country?: Country['name'],
  onUpdateCountry(country: Country['name'], states: State[]): void,
}

export const BusinessCountrySelect = ({
  country,
  onUpdateCountry,
}: BusinessCountrySelectProps) => {
  const [translate] = useTranslation(['businesses', 'common']);

  const { createToast } = useToast();

  const { data: countries, isLoading } = useQuery(
    [QueryKeys.COUNTRIES],
    BusinessLocationApi.getCountries,
    {
      placeholderData: emptyArray,
      onSuccess: (data) => {
        const selectedCountry = data.find(({ name }) => name === country) ?? data[0];
        onUpdateCountry(selectedCountry.name, selectedCountry.states);
      },
      onError: () => {
        createToast({
          children: translate<string>('ERROR_BUSINESS_COUNTRIES'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      },
    },
  );

  return (
    <Select
      disabled={isLoading}
      isRequired
      label={translate<string>('COUNTRY')}
      onSelect={(idx: IndexPath | IndexPath[]) => {
        const { name, states } = countries[(idx as IndexPath).row];
        onUpdateCountry(name, states);
      }}
      testID="business-country-selector"
      value={country}
    >
      {countries.map(({ name }, index) => (
        <SelectItem key={name} testID={`business-country-dropdown-value-${index}`} title={name} />
      ))}
    </Select>
  );
};
