import React, { FC, useEffect } from 'react';
import { View } from 'react-native';
import { UserType } from '@shared/enums';
import { Permissions, RoleUtility } from '@shared/utils';
import { Navigation, Text } from '@design';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';

const navigationSections = [
  {
    label: 'First Heading',
    navItems: [
      {
        label: 'Item 1',
        route: '/ux-sandbox/Navigation/',
        leftAccessory: 'People',
        rightAccessory: 'GrowersAreaCropZone',
      },
      {
        label: 'Item 2',
        route: '/ux-sandbox/Navigation/#item2',
        leftAccessory: 'GrowersBusiness',
      },
      {
        label: 'Manage Products',
        route: '/',
        orUserType: UserType.BUSINESS,
      },
    ],
  },
  {
    label: 'Second Heading',
    navItems: [
      {
        label: 'Item 1',
        route: '/ux-sandbox/Navigation/',
        leftAccessory: 'GrowersProduct',
        rightAccessory: 'FileText',
      },
      {
        label: 'Item 2',
        route: '/ux-sandbox/Navigation/#item2',
        leftAccessory: 'GrowersPrepareReview',
      },
    ],
  },
];

const uxSandbox = {
  label: 'UX Sandbox',
  route: '/ux-sandbox',
  leftAccessory: 'GrowersAreaCropZone',
};

export const NavigationDemo: FC = () => {
  const { user } = useAuthentication();

  useEffect(() => {
    if (
      RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES)
        && user.lastName.endsWith('UX')
        && !navigationSections[1].navItems.includes(uxSandbox)
    ) {
      navigationSections[1].navItems.push(uxSandbox);
    }
  }, [user]);

  return (
    <View style={{ height: '100%' }}>
      <View style={{ height: 700, paddingTop: 0 }}>
        <Navigation sections={navigationSections} testID="navigation-demo" />
        <View style={{ flex: 1, marginLeft: 68 }}>
          <View>
            <Text category="h2" status="primary">This text should be hidden</Text>
          </View>
        </View>
        <View style={{ flex: 1 }} />
      </View>
    </View>
  );
};
