import React from 'react';
import { View, ViewProps } from 'react-native';
import { BannerElement } from '../../Banner/Banner';
import { BannerManager, BannerManagerPresenting } from './BannerManager';

interface BannerManagerPanelState {
  components: Map<string, BannerElement>;
  bannerQueue: string[];
}

export class BannerManagerPanel extends React.Component<
{}, BannerManagerPanelState>
  implements BannerManagerPresenting {
  // eslint-disable-next-line react/state-in-constructor
  public state: BannerManagerPanelState = {
    components: new Map(),
    bannerQueue: [],
  };

  public componentDidMount (): void {
    BannerManager.mount(this);
  }

  public componentWillUnmount (): void {
    BannerManager.unmount();
  }

  private areThereAnyComponents = (): boolean => {
    return this.state.components && this.state.components.size !== 0;
  };

  public add (element: BannerElement, id: string) {
    const newBannerQueue = this.state.bannerQueue;
    newBannerQueue.push(id);
    this.setState((prevState) => ({
      components: prevState.components.set(id, element),
      bannerQueue: newBannerQueue,
    }));
  }

  public remove () {
    const { components, bannerQueue } = this.state;
    components.delete(bannerQueue.shift());
    this.setState({ components, bannerQueue });
  }

  private renderBanner = (id: string): BannerElement => {
    return React.cloneElement(this.state.components.get(id), { key: id });
  };

  private renderBanners = (): BannerElement => {
    return this.renderBanner(this.state.bannerQueue[0]);
  };

  public render (): React.ReactElement<ViewProps> {
    return (
      <View nativeID="Banner-manager-panel" style={{ alignItems: 'flex-end' }}>
        {this.areThereAnyComponents() && this.renderBanners()}
      </View>
    );
  }
}
