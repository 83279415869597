import {
  Accordion,
  AccordionItem,
  Button,
  CheckBox,
  HSpacer,
  Input,
  Modal,
  Text,
  useToast,
  VSpacer,
} from '@design';
import {
  FarmPlanDeliverableV2 as V2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { Spinner, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { GrowerDeliverableApi } from '../../../../../../utilities/api';
import { useAppContext } from '../../../../../../contexts/AppContext';
import { useFarmPlan } from '../../../../../../hooks/useFarmPlan';

const DELIVERABLE_VERSION = 2;

type CheckboxGroup = {
  group: SelectionGroup,
  initExpanded: boolean,
  options: CheckboxControlAny[],
  translationKey: string,
};

type SelectionGroup = keyof V2.Selections;
type SelectionKey<T extends SelectionGroup> = keyof V2.Selections[T];

type CheckboxControl<T extends SelectionGroup> = {
  disabled: boolean,
  group: T,
  key: SelectionKey<T>,
  translationKey: string,
};
type CheckboxControlAny = CheckboxControl<any> & {
  key: string,
};

type GroupState = {
  [key in SelectionGroup]?: 'all' | 'some' | 'none'
};

function createCheckbox<T extends SelectionGroup> (
  group: T,
  translationKey: string,
  key?: SelectionKey<T>,
): CheckboxControl<T> {
  return {
    disabled: key === undefined,
    group,
    key,
    translationKey,
  };
}

export interface CreateDeliverableFarmPlanModalProps {
  farmPlanId: string,
  onBeforeCreateDeliverable?: () => Promise<void>,
  onClose: () => Promise<void>,
  showNoteCheckbox?: boolean,
  showSpinner?: boolean,
}
export const CreateDeliverableFarmPlanModal: FC<CreateDeliverableFarmPlanModalProps> = ({
  farmPlanId,
  onBeforeCreateDeliverable,
  onClose,
  showNoteCheckbox = false,
  showSpinner = true,
}) => {
  const [name, setName] = useState('');
  const [translate] = useTranslation(['common', 'farmPlans', 'deliverable', 'errors']);
  const [groupState, setGroupState] = useState<GroupState>({});
  const [selectionOptions] = useState<CheckboxGroup[]>([
    {
      initExpanded: false,
      translationKey: 'OPTIONS',
      group: 'options',
      options: [
        createCheckbox('options', 'PRODUCT_NAME', 'name'),
        createCheckbox('options', 'SKU_NAME', 'skuName'),
      ],
    },
    {
      initExpanded: false,
      translationKey: 'PRICING_DETAILS',
      group: 'pricingDetails',
      options: [
        createCheckbox('pricingDetails', 'PRICE_TYPE', 'priceType'),
        createCheckbox('pricingDetails', 'PRODUCT_COST_PER_ACRE', 'productCostPerAcre'),
        createCheckbox('pricingDetails', 'UNIT_PRICE', 'productUnitPrice'),
        createCheckbox('pricingDetails', 'TOTAL_PRODUCT_COST', 'productTotalCost'),
        createCheckbox('pricingDetails', 'PRODUCT_SKU_PRICE', 'productSkuPrice'),
        createCheckbox('pricingDetails', 'COST_SUMMARY', 'costSummary'),
      ],
    },
    {
      initExpanded: true,
      translationKey: 'ORDER_DETAILS',
      group: 'orderDetails',
      options: [
        createCheckbox('orderDetails', 'ACRES_APPLIED', 'acresApplied'),
        createCheckbox('orderDetails', 'PRODUCT_SKU_PACKAGES_REQUIRED', 'productSkuPackagesRequired'),
        createCheckbox('orderDetails', 'AVERAGE_RATE_APPLIED', 'averageRateApplied'),
        createCheckbox('orderDetails', 'PRODUCT_UNITS_REQUIRED', 'productUnitsRequired'),
        createCheckbox('orderDetails', 'TOTAL_UNITS', 'totalUnits'),
        ...(showNoteCheckbox ? [createCheckbox('orderDetails', 'NOTE', 'note')] : []),
      ],
    },
    {
      initExpanded: false,
      translationKey: 'FIELD_DETAILS',
      group: 'fieldDetails',
      options: [
        createCheckbox('fieldDetails', 'APPLICATION_COST_PER_ACRE', 'costPerAcre'),
        createCheckbox('fieldDetails', 'CROP_TYPE', 'cropType'),
        createCheckbox('fieldDetails', 'NUMBER_OF_PASSES', 'numberOfPasses'),
        createCheckbox('fieldDetails', 'PASS_NAME', 'passName'),
        createCheckbox('fieldDetails', 'RATE_APPLIED', 'rateApplied'),
        createCheckbox('fieldDetails', 'UNITS_REQUIRED', 'unitsRequired'),
      ],
    },
  ]);
  const [saving, setSaving] = useState<boolean>(false);
  const {
    isLoading,
    farmPlan,
  } = useFarmPlan({
    farmPlanId,
  });
  useEffect(() => {
    if (!name && farmPlan.planName) {
      setName(
        `${farmPlan.planName} ${DateTime.local().toLocaleString(DateTime.DATETIME_SHORT)}`,
      );
    }
  // this only need to trigger when the farmPlan changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmPlan]);
  const [selections, setSelections] = useState<V2.Selections>({
    fieldDetails: {
      costPerAcre: true,
      cropType: true,
      numberOfPasses: true,
      passName: true,
      rateApplied: true,
      unitsRequired: true,
    },
    options: {
      name: true,
      skuName: true,
    },
    orderDetails: {
      acresApplied: true,
      averageRateApplied: true,
      note: true,
      priceLocked: true,
      productSkuPackagesRequired: true,
      productUnitsRequired: true,
      totalUnits: true,
    },
    pricingDetails: {
      productSkuPrice: true,
      productCostPerAcre: true,
      productTotalCost: true,
      productUnitPrice: true,
      costSummary: true,
      priceType: true,
    },
  });
  const { setModalProps } = useAppContext();
  const { createToast } = useToast();

  const toggleSelection = useCallback((group: SelectionGroup, key: string) => (
    setSelections((oldSelections) => ({
      ...oldSelections,
      [group]: {
        ...oldSelections[group],
        [key]: !oldSelections[group][key],
      },
    }))
  ), []);

  const styles = useStyleSheet({
    row: {
      flexDirection: 'row',
      flex: 1,
    },
    checkbox: {
      paddingBottom: 27,
      flexDirection: 'row',
      alignItems: 'center',
      width: '50%',
      paddingRight: 10,
    },
    spinnerContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 560,
    },
    divider: {
      borderTopWidth: 1,
      borderTopColor: 'color-basic-transparent-100',
      backgroundColor: 'color-basic-transparent-100',
    },
    accordionItem: {
      paddingBottom: 18,
      paddingTop: 23,
      paddingRight: 25,
      paddingLeft: 55,
    },
    modalBody: {
      maxHeight: 600,
    },
    accordionTitle: {
      textTransform: 'capitalize',
    },
    selectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });

  const isSelectionChecked = useCallback((group: SelectionGroup, key: string) => {
    const selection = selections[group][key];
    return selection || selection === undefined;
  }, [selections]);

  const toggleGroup = useCallback((group: SelectionGroup) => {
    const groupInfo = selectionOptions.find((s) => s.group === group);
    const hasDefaultValues = groupInfo?.options.some((o) => o.disabled);

    let newGroupState = (
      groupState[group] === 'all' || groupState[group] === 'some'
        ? 'none'
        : 'all'
    );

    if (hasDefaultValues) {
      newGroupState = groupState[group] === 'all' ? 'some' : 'all';
    }

    setGroupState((prev) => ({
      ...prev,
      [group]: newGroupState,
    }));

    setSelections((prev) => {
      const newGroupSelection = { ...prev[group] };
      Object.keys(newGroupSelection).forEach((key) => {
        const option = groupInfo.options.find((o) => o.key === key);
        if (!option?.disabled) {
          newGroupSelection[key] = newGroupState === 'all';
        }
      });
      return {
        ...prev,
        [group]: newGroupSelection,
      };
    });
  }, [groupState, selectionOptions]);

  useEffect(() => {
    let { priceLocked } = selections.orderDetails;
    if (
      selections.orderDetails.priceLocked
      && !selections.pricingDetails.productUnitPrice
      && !selections.pricingDetails.productTotalCost
      && !selections.pricingDetails.productSkuPrice
      && !selections.pricingDetails.productCostPerAcre
    ) {
      priceLocked = false;
    } else if (
      !selections.orderDetails.priceLocked
      && (selections.pricingDetails.productUnitPrice
      || selections.pricingDetails.productTotalCost
      || selections.pricingDetails.productSkuPrice
      || selections.pricingDetails.productCostPerAcre)
    ) {
      priceLocked = true;
    }
    if (priceLocked !== selections.orderDetails.priceLocked) {
      setSelections((oldSelections) => ({
        ...oldSelections,
        orderDetails: {
          ...oldSelections.orderDetails,
          priceLocked,
        },
      }));
    }
  }, [selections]);

  const onSubmit = async () => {
    try {
      setSaving(true);

      await onBeforeCreateDeliverable?.();

      const { createdDeliverable } = await GrowerDeliverableApi.createGrowerDeliverable({
        name,
        selections,
        farmPlanId,
        note: farmPlan.note,
        version: DELIVERABLE_VERSION,
      });

      await onClose();

      if (createdDeliverable) {
        setModalProps({
          type: 'shareDeliverable',
          customerId: farmPlan.growerId,
          deliverable: createdDeliverable,
          isNew: true,
        });
      }
    } catch (err) {
      createToast({
        children: translate<string>('DELIVERABLE_FAILURE'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
    setSaving(false);
  };

  useEffect(() => {
    if (selectionOptions.length === 0) {
      return;
    }

    const newGroupState = { ...groupState };

    Object.keys(selections).forEach((group) => {
      const options = selectionOptions.find((s) => s.group === group)
        ?.options || [];
      const selectedOptions = [];

      for (const option of options) {
        if (selections[group][option.key] || selections[group][option.key] === undefined) {
          selectedOptions.push(option);
        }
      }

      if (selectedOptions.length === options.length) {
        newGroupState[group] = 'all';
      } else if (selectedOptions.length > 0) {
        newGroupState[group] = 'some';
      } else {
        newGroupState[group] = 'none';
      }
    });
    if (!_.isEqual(newGroupState, groupState)) {
      setGroupState(newGroupState);
    }
  }, [selections, selectionOptions, groupState]);

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            testID="create-deliverable-modal-cancel-button"
            {...primaryButtonProp}
            disabled={saving}
            onPress={onClose}
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            testID="create-deliverable-modal-save-button"
            {...secondaryButtonProp}
            disabled={
              saving
              || !name
              || (!selections.options.name && !selections.options.skuName)
            }
            onPress={onSubmit}
          >
            {translate<string>('CREATE_DELIVERABLE')}
          </Button>
        </>
      )}
      onClose={onClose}
      testID="create-deliverable-modal"
      title={translate<string>('CREATE_DELIVERABLE')}
      visible
      width={637}
    >
      <View style={isLoading ? styles.spinnerContainer : styles.modalBody}>
        {showSpinner && isLoading && (
          <Spinner size="giant" />
        )}
        {!isLoading && (
          <>
            <VSpacer size="4" />
            <Input
              isRequired
              label={translate<string>('DELIVERABLE_NAME')}
              onChangeText={setName}
              testID="name-modal-input"
              value={name}
            />
            <VSpacer size="8" />
            <View style={styles.divider} />
            <Accordion dividerStyle={styles.divider} testID="create-deliverable-farmPlan-modal-list">
              {
                selectionOptions.map((option, index) => (
                  <AccordionItem
                    initExpanded={option.initExpanded}
                    key={option.group}
                    style={styles.accordionItem}
                    testID={`create-deliverable-modal-group-${index}`}
                    title={(
                      <View>
                        <CheckBox
                          checked={groupState[option.group] === 'all'}
                          indeterminate={groupState[option.group] === 'some'}
                          onChange={() => toggleGroup(option.group)}
                          testID={`create-deliverable-modal-header-checkbox-${index}`}
                        />
                        <HSpacer size="5" />
                        <Text
                          category="h6"
                          style={styles.accordionTitle}
                        >
                          {translate<string>(option.translationKey)}
                        </Text>
                      </View>
                    )}
                  >
                    <>
                      <View>
                        <View style={styles.selectionContainer}>
                          {option.options
                            .map((subOption, subOptionIndex) => (
                              <View key={subOption.translationKey} style={styles.checkbox}>
                                <CheckBox
                                  checked={isSelectionChecked(option.group, subOption.key)}
                                  disabled={subOption.disabled}
                                  onChange={() => toggleSelection(option.group, subOption.key)}
                                  testID={`create-deliverable-modal-checkbox-left-${subOptionIndex}`}
                                />
                                <HSpacer size="4" />
                                <Text category="p2">{translate<string>(subOption.translationKey)}</Text>
                              </View>
                            ))}
                        </View>
                      </View>
                    </>
                  </AccordionItem>
                ))
              }
            </Accordion>
          </>
        )}
      </View>
    </Modal>
  );
};
