import {
  CenteredSpinner,
  DataPoint,
  HSpacer,
  MenuItem,
  Modal,
  OverflowMenu,
  Search,
  Select,
  SelectItem,
  Text,
  Toggle,
  useToast,
  ViewRow,
  VSpacer,
} from '@design';
import { ApiCrop, ApiCropLogic } from '@shared/interfaces/api';
import { FarmUtility } from '@shared/utils';
import { Divider, IndexPath, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo, TouchableOpacity, View } from 'react-native';
import { useQuery } from 'react-query';
import { CropLogicResult } from '@shared/interfaces';
import { Ionicons } from '@expo/vector-icons';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { StringUtility } from '../../../utilities';
import { CropLogicApi } from '../../../utilities/api';
import { QueryKeys } from '../../../constants';
import { useBusinessCrops } from '../../../hooks/useBusinessCrops';

export interface FarmPlanProgramSelectorProps {
  assignedPrograms: ApiCropLogic[],
  onProgramSelected: (program: ApiCropLogic, businessLevel: boolean) => void,
}

export const FarmPlanProgramSelector = ({
  assignedPrograms = [],
  onProgramSelected,
}: FarmPlanProgramSelectorProps) => {
  const [translate] = useTranslation(['common', 'farmPlans', 'prepare']);
  const { currentBusinessId } = useAuthentication();
  const { createToast } = useToast();
  const { businessCrops } = useBusinessCrops({ businessId: currentBusinessId });
  const [cropSelection, setCropSelection] = useState<number>(0);
  const [businessLevel, setBusinessLevel] = useState(true);
  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState<CropLogicResult | null>(null);

  const styles = useStyleSheet({
    divider: {
      backgroundColor: 'color-basic-control-transparent-100',
    },
    flexLeft: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    listItem: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 3,
      alignItems: 'center',
    },
    programName: {
      flexGrow: 1,
      maxWidth: 288,
    },
  });

  const formatCrop = useCallback((crop: ApiCrop) => (
    `${translate(crop.cropType)} | ${translate(crop.subType)}`
  ), [translate]);

  const { data: programs, isFetching } = useQuery(
    [QueryKeys.PROGRAM_LIST, currentBusinessId],
    () => CropLogicApi.getAllCropLogicsForBusiness(currentBusinessId, [true]),
    {
      enabled: businessCrops.length > 0,
      onError: () => createToast(translate('UNEXPECTED_ERROR'), 'warning'),
    },
  );

  const filteredPrograms = useMemo(() => {
    let result = FarmUtility.addCropsToLogics(
      businessLevel ? programs?.data ?? [] : assignedPrograms,
      businessCrops,
    );

    if (search) {
      result = result.filter(
        (c) => c.logicName.toLowerCase().includes(search.toLowerCase()),
      );
    }

    if (cropSelection) {
      const crop = businessCrops[cropSelection - 1];
      result = result.filter(
        (c) => c.cropType === crop.cropType && c.cropSubType === crop.subType,
      );
    }

    return result;
  }, [businessLevel, programs, assignedPrograms, search, cropSelection, businessCrops]);

  const renderItem = ({ item, index }: ListRenderItemInfo<CropLogicResult>) => (
    <TouchableOpacity
      key={item.id}
      onPress={() => onProgramSelected(item, businessLevel)}
      testID={`program-row-${index}`}
    >
      <Divider style={styles.divider} />
      <View style={styles.listItem}>
        <HSpacer size="7" />
        <View>
          <Ionicons
            name="ellipse"
            size={6}
            style={{
              color: item.crop?.color,
            }}
            testID={`program-crop-color-${index}`}
          />
        </View>
        <HSpacer size="7" />
        <View style={styles.programName}>
          <Text category="p1" testID={`program-name-${index}`}>
            {item.logicName}
          </Text>
        </View>
        <OverflowMenu testID="program-details-overflow-menu">
          <MenuItem
            onPress={() => setCurrentProgram(item)}
            testID={`program-details-${index}`}
            title={translate<string>('DETAILS')}
          />
        </OverflowMenu>
        <HSpacer size="4" />
      </View>
    </TouchableOpacity>
  );

  const currentProgramScenarioOrMarket = useMemo(() => (
    currentProgram?.scenarioParameters ?? currentProgram?.crop
  ), [currentProgram]);

  const programsCount = useMemo(() => {
    const count = filteredPrograms.length;

    return translate(
      `${businessLevel ? 'PROGRAM_TEMPLATE' : 'APPLIED_PROGRAM'}${count !== 1 ? 'S' : ''}_COUNT`,
      { count },
    );
  }, [businessLevel, translate, filteredPrograms]);

  return (
    <>
      <Modal
        onClose={() => setCurrentProgram(null)}
        testID="program-details-modal"
        title={currentProgram?.logicName}
        visible={!!currentProgram}
      >
        <ViewRow>
          <DataPoint
            flex
            label={`${translate('CROP')}`}
            testID="program-details-modal-crop"
          >
            {`${translate(currentProgram?.cropType)} | ${translate(currentProgram?.cropSubType)}`}
          </DataPoint>
          <DataPoint
            flex
            label={`${translate('EXPECTED_CROP_MARKET_PRICE')}`}
            testID="program-details-modal-crop-price"
          >
            {!currentProgramScenarioOrMarket
              ? translate<string>('NOT_APPLICABLE')
              : `${StringUtility.formatCurrency(currentProgramScenarioOrMarket.price)} ${translate(currentProgramScenarioOrMarket.priceUom)}`}
          </DataPoint>
        </ViewRow>
        <VSpacer size="9" />
        <ViewRow>
          <DataPoint
            flex
            label={`${translate('EXPECTED_OPERATIONAL_COST')}`}
            testID="program-details-modal-crop-cost"
          >
            {StringUtility.formatCurrency(
              currentProgram?.scenarioParameters?.operationalCosts ?? 0,
            )}
          </DataPoint>
          <DataPoint
            flex
            label={`${translate('YIELD_GOAL_PER_ACRE')}`}
            testID="program-details-modal-crop-yield"
          >
            {!currentProgramScenarioOrMarket
              ? translate<string>('NOT_APPLICABLE')
              : `${currentProgramScenarioOrMarket.yieldGoal} ${translate(currentProgramScenarioOrMarket.yieldGoalUom)}`}
          </DataPoint>
        </ViewRow>
      </Modal>
      <View>
        <Search
          onChangeText={setSearch}
          onClear={() => setSearch('')}
          size="medium"
          testID="program-selector-search"
          value={search}
        />
      </View>
      <VSpacer size="8" />
      <View>
        <Text appearance="hint" category="c1">{translate<string>('FILTER_BY')}</Text>
        <VSpacer size="5" />
        <Select
          onSelect={(i) => setCropSelection((i as IndexPath).row)}
          placeholder={translate<string>('CROP')}
          style={{ maxHeight: '100%' }}
          testID="program-selector-crop-filter"
          value={cropSelection > 0
            ? formatCrop(businessCrops[cropSelection - 1]) : ''}
        >
          <>
            <SelectItem testID="business-crops-dropdown-value" title={translate<string>('NONE')} />
            {businessCrops.map((crop, index) => (
              <SelectItem
                key={formatCrop(crop)}
                testID={`business-crop-dropdown-value-${index}`}
                title={formatCrop(crop)}
              />
            ))}
          </>
        </Select>
      </View>
      <VSpacer size="7" />
      <View style={styles.flexLeft}>
        <Toggle
          checked={!businessLevel}
          onChange={(checked) => setBusinessLevel(!checked)}
          testID="program-selector-show-applied"
        >
          {translate<string>('SHOW_APPLIED_PROGRAMS')}
        </Toggle>
      </View>
      <VSpacer size="9" />
      {!isFetching && (
        <Text category="p2" testID="program-selector-result-count">
          {programsCount}
        </Text>
      )}
      <VSpacer size="5" />
      <View style={{ marginLeft: -20, marginRight: -20 }}>
        {isFetching ? (
          <CenteredSpinner />
        ) : (
          <FlatList
            data={filteredPrograms}
            keyExtractor={(value) => value.id}
            renderItem={renderItem}
            testID="program-selector-table"
          />
        )}
      </View>
    </>
  );
};
