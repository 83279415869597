import React from 'react';
import {
  View,
  TextProps,
  ViewProps,
} from 'react-native';
import { isEmpty as _isEmpty } from 'lodash';
import {
  FalsyText,
  Overwrite,
  LiteralUnion,
  RenderProp,
} from '@ui-kitten/components/devsupport';
import {
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { VSpacer } from '../Spacer/VSpacer';
import { CardMenu, CardMenuItemProps } from './CardMenu';
import { IconName, IconProps } from '../Icon/Icon';
import { FalsyIcon } from '../FalsyIcon/FalsyIcon';

type CardStyledProps = Overwrite<StyledComponentProps, {
  appearance?: LiteralUnion<'outline'>;
}>;

export interface CardProps extends ViewProps, CardStyledProps {
  children?: React.ReactNode;
  title?: RenderProp<TextProps> | React.ReactText;
  subTitle?: RenderProp<TextProps> | React.ReactText;
  accent?: RenderProp<Partial<IconProps>> | IconName;
  menuIcon?: RenderProp<Partial<IconProps>> | IconName;
  menuItems?: CardMenuItemProps[];
  elevation?: 6 | 12;
  testID: string;
}

export type CardElement = React.ReactElement<CardProps>;
/**
 * Cards contain content and actions about a single subject.
 *
 * @extends React.Component
 *
 * @property {ReactNode} children - Component to render within the card.
 *
 * @property {RenderProp<TextProps> | React.ReactText} title -
 * String to render as the Title of the Card.
 *
 * @property {RenderProp<TextProps> | React.ReactText} subTitle -
 * String to render as the Sub-title of the Card beneath the Title.
 *
 * @property {ReactElement | (IconProps) => ReactElement | IconName} accent -
 * Function component to render accent Icon in header.
 *
 * @property {CardMenuItemProps[]} menuItems - Array of CardMenuItemProps to render
 * in a dropdown menu in the header of the Card.
 *
 * @property {TouchableOpacityProps}
 * ...TouchableOpacityProps - Any props applied to TouchableOpacity component.
 *
 */

// TODO: Replace the outer View with ResponsiveContainer to avoid duplicate left & right margins
@styled('Card')
export class Card extends React.Component<CardProps> {
  private getComponentStyle = (source: StyleType) => {
    const {
      elevation,
      ...containerParameters
    } = source;

    return {
      container: containerParameters,
      elevation: {
        ...elevation,
      },
      body: PropsServiceHelper.allWithPrefixMapped(source, 'body'),
      accent: PropsServiceHelper.allWithPrefixMapped(source, 'accent'),
      menu: {
        marginLeft: PropsServiceHelper.allWithPrefixMapped(source, 'menuItems').marginLeft,
      },
      headerTitle: PropsServiceHelper.allWithPrefixMapped(source, 'headerTitle'),
    };
  };

  public render () {
    const {
      eva,
      style,
      children,
      title,
      subTitle,
      accent,
      menuItems,
      menuIcon,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);
    const hasTitles = title || subTitle;
    const hasHeader = accent
    || (!_isEmpty(menuItems) && menuItems.filter(Boolean).length > 0)
    || hasTitles;
    const hasIcons = accent
    || ((!_isEmpty(menuItems) && menuItems.filter(Boolean).length > 0) && menuIcon);

    return (
      <View
        {...viewProps}
        style={[evaStyle.container, evaStyle.elevation, style]}
      >
        {hasHeader && (
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={[evaStyle.headerTitle, { flex: 1 }]}>
              <View style={[{ flexDirection: 'column' }]}>
                <FalsyText category="h6" component={title} testID="card-title" />
                {title && subTitle && (<VSpacer size="3" />)}
                <FalsyText category="c1" component={subTitle} testID="card-subTitle" />
              </View>
            </View>
            {hasIcons && (
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'baseline' }}>
                <FalsyIcon
                  component={accent}
                  style={evaStyle.accent}
                />
                <View style={evaStyle.menu}>
                  <CardMenu
                    icon={menuIcon}
                    iconStyle={{ padding: 0 }}
                    menuItems={menuItems}
                  />
                </View>
              </View>
            )}
          </View>
        )}
        {hasHeader && children && (
          <VSpacer size="7" />
        )}
        {children && (
          <View style={[{ flex: 1, width: '100%' }, evaStyle.body]}>
            {children}
          </View>
        )}
      </View>
    );
  }
}
