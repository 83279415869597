import React, { FC, useEffect, useState } from 'react';
import { View, ViewProps } from 'react-native';
import { styled, StyledComponentProps, StyleType } from '@ui-kitten/components';
import { Ionicons } from '@expo/vector-icons';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { StepIndicatorSize } from '@theme/variant-interfaces/Size';
import { testId } from '../../../../utilities/testId';

type Position = 'absolute' | 'relative';
type Direction = 'horizontal' | 'vertical';

export interface StepIndicatorProps extends ViewProps, StyledComponentProps {
  /**
   * @data
   * Any data that is in an array
   */
  data: any[];
  /**
   * @currentPage
   * The current page of the form
   */
  currentPage: number;
  /**
   * @position
   * Accepts only { position: absolute }
   * for setting the Step Indicator in the proper position
   * defaults to 'relative'
   */
  position?: Position;
  /**
   * @direction
   * The direction of the step indicator dots
   */
  direction?: Direction;
  /**
   * @size
   */
  size?: StepIndicatorSize
}

const getComponentStyle = (source: StyleType) => {
  const dotStyles = PropsServiceHelper.allWithPrefixMapped(source, 'dot');
  const currentStyle = PropsServiceHelper.allWithPrefixMapped(source, 'current');
  const verticalStepStyles = PropsServiceHelper.allWithPrefixMapped(source, 'verticalStep');
  const horizontalStepStyles = PropsServiceHelper.allWithPrefixMapped(source, 'horizontalStep');
  const verticalDotStyles = PropsServiceHelper.allWithPrefixMapped(source, 'verticalDot');
  const horizontalDotStyles = PropsServiceHelper.allWithPrefixMapped(source, 'horizontalDot');
  const { ...containerStyles } = source;
  return {
    container: containerStyles,
    dot: dotStyles,
    current: currentStyle,
    vertical: verticalStepStyles,
    horizontal: horizontalStepStyles,
    verticalDot: verticalDotStyles,
    horizontalDot: horizontalDotStyles,
  };
};

const Indicator = ({ current, index, evaStyle, direction, isLast }) => {
  const isSelected = current === index;
  return (
    <Ionicons
      name="ellipse"
      size={evaStyle.dot.borderRadius}
      style={[
        direction === 'vertical'
          ? evaStyle.verticalDot
          : evaStyle.horizontalDot,
        !isSelected
          ? { ...evaStyle.dot, color: evaStyle.dot.color }
          : { ...evaStyle.dot, color: evaStyle.current.color },
        isLast && { marginRight: 0 },
      ]}
    />
  );
};

const RawStepIndicator: FC<StepIndicatorProps> = ({
  data,
  currentPage,
  eva,
  style,
  position = 'relative',
  direction = 'vertical',
  testID,
  nativeID,
  ...viewProps
}) => {
  const evaStyle = getComponentStyle(eva.style);
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  return (
    <View style={[evaStyle[direction], { position }]} {...testId('step-indicator')} {...viewProps}>
      {data.map((_: any, index: number) => (
        <Indicator
          current={current}
          direction={direction}
          evaStyle={evaStyle}
          index={index}
          isLast={index === data.length - 1}
          key={index.toString()}
        />
      ))}
    </View>
  );
};

export const StepIndicator: FC<StepIndicatorProps> = styled('StepIndicator')(RawStepIndicator);
