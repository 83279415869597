import React, { FC } from 'react';
import { View, ViewProps } from 'react-native';

export const ViewRightAlign: FC<ViewProps> = ({ children, style, ...viewProps }) => (
  <View
    style={[{ flexDirection: 'row', justifyContent: 'flex-end', flex: 1 }, style]}
    {...viewProps}
  >
    {children}
  </View>
);
