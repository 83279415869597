// The max is set to the same as the min of the next screen size because of
// how the lodash inRange function works.
// It checks if n is between start and up to but NOT INCLUDING, end.
export const screenBreakpoints = {
  xSmall: {
    min: 0,
    max: 600,
    cols: 4,
  },
  small: {
    min: 600,
    max: 905,
    cols: 8,
  },
  medium: {
    min: 905,
    max: 1240,
    cols: 12,
  },
  large: {
    min: 1240,
    max: 1440,
    cols: 12,
  },
  xLarge: {
    min: 1440,
    max: 9999,
    cols: 12,
  },
};

export type ScreenSizeType = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';

export interface GutterWidths {
  xSmall: number,
  small: number,
  medium: number,
  large: number,
  xLarge: number,
}
