import {
  Badge,
  CenteredSpinner,
  Icon,
  NumericInput,
  Select,
  SelectItem,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  VSpacer,
} from '@design';
import { AgriculturalUnit, AreaUnitType, FormulationType, FormulationUom } from '@shared/enums';
import { ApiTankMixComponent } from '@shared/interfaces/api';
import { CalculationUtility, ProductUtilities } from '@shared/utils';
import { IndexPath } from '@ui-kitten/components';
import React, { FC, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import {
  HandleSpace,
  ReorderList,
} from '../../../../../ui-components/components/ReorderList/ReorderList';
import { StringUtility } from '../../../../../utilities';

enum ComponentTypeSelection {
  ADJUVANT = 'ADJUVANT',
  CARRIER = 'CARRIER',
}

interface TankMixComponentProps {
  component: ApiTankMixComponent,
  locationId: string,
  onUpdateComponent?(component: ApiTankMixComponent): void
  onRemoveComponent?(): void,
  priceTypeId: string,
}
const TankMixComponent: FC<TankMixComponentProps> = ({
  component,
  locationId,
  onUpdateComponent,
  onRemoveComponent,
  priceTypeId,
}) => {
  const [translate] = useTranslation(['tankMix', 'common']);
  const { calculateComponentCostPerAcre } = CalculationUtility;

  const isAcre = component.product.unitUoM === AreaUnitType.Acre;
  const isUnit = component.product.unitUoM === AgriculturalUnit.Unit;
  const isAcreOrUnit = isAcre || isUnit;

  let costPerAcreFormatted = StringUtility.formatCurrency(0);

  const costPerAcre = calculateComponentCostPerAcre(
    component,
    priceTypeId,
    locationId,
  );
  costPerAcreFormatted = StringUtility.formatCurrency(costPerAcre);
  
  const getUomOptions = useMemo(() => (
    ProductUtilities.getProductApplicationUoMs(component.product)),
  [component.product]);

  const componentTypeOptions = useMemo(() => (
    component.product.chemicalFertilizerProduct.formulation === FormulationType.DRY
      ? [' ', ComponentTypeSelection.ADJUVANT]
      : [' ', ComponentTypeSelection.ADJUVANT, ComponentTypeSelection.CARRIER]
  ), [component.product.chemicalFertilizerProduct.formulation]);

  const componentTypeText = useMemo(() => (
    (component.adjuvant && translate<string>(ComponentTypeSelection.ADJUVANT))
    || (component.carrier && translate<string>(ComponentTypeSelection.CARRIER))
  ), [component.adjuvant, component.carrier, translate]);

  const rateUomText = useMemo(() => {
    return component.rateUom ? translate(component.rateUom) : undefined;
  }, [component.rateUom, translate]);

  return (
    <Table style={{ flex: 1 }} testID="tank-mix-component-list-table">
      <TableRow noBottomBorder testID="tank-mix-component-no-bottom-border-row">
        <TableCell style={{ flex: 1.25 }} testID="sku-name">
          <Text wrap>{component.product.skuName}</Text>
        </TableCell>
        <TableCell style={{ flex: 0.7, alignItems: 'center' }} testID="inactive">
          {!component.product.isActive && (
            <Badge status="warning" testID="warning">
              {translate<string>('INACTIVE')}
            </Badge>
          )}
        </TableCell>
        <TableCell style={{ flex: 1 }} testID="rate" usePadding={!onUpdateComponent}>
          {!onUpdateComponent ? (
            StringUtility.formatDecimal(component.rate)
          ) : (
            <NumericInput
              onChangeValue={(rate) => onUpdateComponent({ ...component, rate })}
              readOnly={isAcre}
              readOnlyLabel={false}
              style={{ width: '100%', borderBottomWidth: 0 }}
              testID={`tank-mix-component-rate-${component.productId}`}
              value={component.rate}
            />
          )}
        </TableCell>
        <TableCell style={{ flex: 1 }} testID="rate-uom" usePadding={!onUpdateComponent}>
          {!onUpdateComponent ? rateUomText : (
            <Select
              inputStyle={{ borderBottomWidth: 0 }}
              onSelect={(ip: IndexPath | IndexPath[]) => onUpdateComponent({
                ...component,
                rateUom: getUomOptions[(ip as IndexPath).row] as FormulationUom,
              })}
              placeholder={<Text />}
              readOnlyLabel={false}
              readonly={isAcreOrUnit}
              style={{ width: '100%' }}
              testID={`tank-mix-component-rateUom-${component.productId}`}
              value={rateUomText}
            >
              {getUomOptions.map((uom, index) => (
                <SelectItem
                  key={uom}
                  testID={`tank-mix-component-rateUom-${index}-${component.productId}-${uom}`}
                  title={translate<string>(uom)}
                />
              ))}
            </Select>
          )}
        </TableCell>
        <TableCell style={{ flex: 1 }} testID="type" usePadding={!onUpdateComponent}>
          {!onUpdateComponent ? componentTypeText : (
            <Select
              inputStyle={{ borderBottomWidth: 0 }}
              onSelect={(ip: IndexPath | IndexPath[]) => onUpdateComponent({
                ...component,
                adjuvant: (ip as IndexPath).row === 1,
                carrier: (ip as IndexPath).row === 2,
              })}
              placeholder={<Text />}
              style={{ width: '100%' }}
              testID={`tank-mix-component-type-${component.productId}`}
              value={componentTypeText}
            >
              {componentTypeOptions.map((componentType, index) => (
                <SelectItem
                  key={componentType}
                  testID={`tank-mix-component-type-${index}-${component.productId}-${componentType}`}
                  title={translate<string>(componentType)}
                />
              ))}
            </Select>
          )}
        </TableCell>
        <TableCell style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'flex-end' }} testID="cost-per-acre">
          <Text>{costPerAcreFormatted}</Text>
        </TableCell>
        {!!onRemoveComponent && (
          <TableCell style={{ flex: 0.2, justifyContent: 'center' }} testID="remove">
            <TouchableOpacity onPress={onRemoveComponent}>
              <Icon name="Trash" testID="remove-icon" />
            </TouchableOpacity>
          </TableCell>
        )}
      </TableRow>
    </Table>
  );
};

interface TankMixComponentListProps {
  components: ApiTankMixComponent[],
  emptyListContent: () => ReactElement,
  isLoading?: boolean,
  locationId: string,
  onUpdateComponents?(newComponents: ApiTankMixComponent[]): void,
  onDragStart(): void,
  onDragEnd(): void,
  priceTypeId: string,
}
export const TankMixComponentList: FC<TankMixComponentListProps> = ({
  components,
  emptyListContent,
  isLoading,
  locationId,
  onUpdateComponents,
  onDragStart,
  onDragEnd,
  priceTypeId,
}) => {
  const [translate] = useTranslation(['tankMix', 'common']);
  const { calculateComponentCostPerAcre } = CalculationUtility;

  const totalCostPerAcre = useMemo(() => components.map((component) => {
    return calculateComponentCostPerAcre(
      component,
      priceTypeId,
      locationId,
    );
  }).reduce((a, b) => a + b, 0), [
    calculateComponentCostPerAcre,
    components,
    locationId,
    priceTypeId,
  ]);

  const editMode = !!onUpdateComponents;

  const updateComponent = (index: number, component: ApiTankMixComponent) => {
    const newComponents = [...components];
    newComponents[index] = component;
    onUpdateComponents(newComponents);
  };

  const removeComponent = (index: number) => {
    const newComponents = [...components];
    newComponents.splice(index, 1);
    onUpdateComponents(newComponents);
  };

  return (
    <View>
      <Table testID="tank-mix-component-table-header">
        <TableRow appearance="header" testID="tank-mix-component-table-row-edit-mode">
          {editMode && (
            <TableHeader
              style={{ width: HandleSpace }}
              testID="tank-mix-component-table-header-edit-mode"
            />
          )}
          <TableHeader
            style={{ flex: 1.25 }}
            testID="tank-mix-component-table-header-component"
          >
            {translate<string>('COMPONENT')}
          </TableHeader>
          {/* Extra spacing for inactive badge */}
          {components.some((item) => item.product.isActive) && (
            <TableHeader
              style={{ flex: 0.7, alignItems: 'center' }}
              testID="tank-mix-component-table-header-inactive"
            />
          )}
          <TableHeader
            style={{ flex: 1 }}
            testID="tank-mix-component-table-header-rate-per-acre"
          >
            {translate<string>('RATE_PER_ACRE')}
          </TableHeader>
          <TableHeader
            style={{ flex: 1 }}
            testID="tank-mix-component-table-header-rate-uom"
          >
            {translate<string>('RATE_UOM')}
          </TableHeader>
          <TableHeader
            style={{ flex: 1 }}
            testID="tank-mix-component-table-header-intended-use"
          >
            {translate<string>('INTENDED_USE')}
          </TableHeader>
          <TableHeader
            style={{ flex: 1, justifyContent: 'flex-end' }}
            testID="tank-mix-component-table-header-cost-per-acre"
          >
            {translate<string>('COST_PER_ACRE')}
          </TableHeader>
          {editMode && (
            <TableHeader
              style={{ flex: 0.2 }}
              testID="tank-mix-component-table-header--edit-mode-2"
            />
          )}
        </TableRow>
      </Table>
      {components.length > 0 ? (
        <>
          <ReorderList
            hasBottomBorder
            items={components}
            keyExtractor={(component) => component.productId}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onOrder={onUpdateComponents}
            renderItem={(component, index) => (
              <TankMixComponent
                component={component}
                locationId={locationId}
                onRemoveComponent={editMode ? () => removeComponent(index) : undefined}
                onUpdateComponent={
                  editMode
                    ? (newComponent) => updateComponent(index, newComponent)
                    : undefined
                }
                priceTypeId={priceTypeId}
              />
            )}
          />
          <TableRow noBottomBorder style={{ flex: 1, borderBottomWidth: 0 }} testID="handle-spacing-row">
            {editMode && <TableCell style={{ width: HandleSpace }} testID="handle-spacing" />}
            <TableCell style={{ flex: 1.25 }} testID="total">
              <Text category="s1" style={{ paddingHorizontal: 0, paddingVertical: 0 }}>
                {translate<string>('TOTAL')}
              </Text>
            </TableCell>
            {/* Extra spacing for inactive badge */}
            {!components.some((item) => item.product.isActive) && (
              <TableCell style={{ flex: 0.7 }} testID="spacing-1" />
            )}
            <TableCell style={{ flex: 3 }} testID="spacing-2" />
            <TableCell style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'flex-end' }} testID="total-cost-per-acre">
              <Text category="s1" style={{ paddingHorizontal: 0, paddingVertical: 0 }}>
                {StringUtility.formatCurrency(totalCostPerAcre)}
              </Text>
            </TableCell>
            {editMode && <TableCell style={{ flex: 0.15 }} testID="spacing-3" />}
          </TableRow>
        </>
      ) : (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <VSpacer size="11" />
          {isLoading ? (
            <CenteredSpinner testID="loading-spinner" />
          ) : (
            emptyListContent()
          )}
          <VSpacer size="11" />
        </View>
      )}
    </View>
  );
};
