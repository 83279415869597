/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { ChildrenWithProps } from '@ui-kitten/components/devsupport';
import {
  ViewPager,
  ViewPagerProps,
} from '@ui-kitten/components/ui/viewPager/viewPager.component';
import { PanGestureHandler } from 'react-native-gesture-handler';
import {
  TabElement,
  TabProps,
} from './tab';
import { TabBar } from './tabBar';

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  pagerContainer: {
    top: -1,
    overflow: 'hidden',
    borderTopColor: 'rgba(175, 175, 175, 0.16)',
    borderTopWidth: 1,
  },
});

class TabViewChildElement {
  tab: TabElement;
  content: React.ReactElement;
}

class TabViewChildren {
  tabs: TabElement[] = [];
  contents: React.ReactElement[] = [];
}

export interface TabViewProps extends ViewPagerProps<TabProps> {
  tabBarStyle?: StyleProp<ViewStyle>;
  indicatorStyle?: StyleProp<ViewStyle>;
  indicatorTotalWidth?: number;
  onSwipe?: (event) => void;
}

export type TabViewElement = React.ReactElement<TabViewProps>;

export class TabView extends React.Component<TabViewProps> {
  private viewPagerRef = React.createRef<ViewPager>();
  private tabBarRef = React.createRef<TabBar>();

  private onBarSelect = (index: number): void => {
    this.props.onSelect && this.props.onSelect(index);
  };

  private onPagerOffsetChange = (offset: number): void => {
    this.props.onOffsetChange && this.props.onOffsetChange(offset);
  };

  private renderComponentChild = (element: TabElement, index: number): TabViewChildElement => ({
    tab: React.cloneElement(element, { key: index }),
    content: element.props.children,
  });

  private renderComponentChildren = (source: ChildrenWithProps<TabProps>): TabViewChildren => {
    const children = React.Children.toArray(source) as TabElement[];

    return children.reduce((acc: TabViewChildren, element: TabElement, index: number) => {
      const { tab, content } = this.renderComponentChild(element, index);
      return {
        tabs: [...acc.tabs, tab],
        contents: [...acc.contents, content],
      };
    }, new TabViewChildren());
  };

  private renderInner () {
    const {
      style,
      selectedIndex = 0,
      children,
      tabBarStyle,
      indicatorStyle,
      shouldLoadComponent = ((index) => index === selectedIndex),
      ...viewProps
    } = this.props;
    const { tabs, contents } = this.renderComponentChildren(children);

    return (
      <View
        {...viewProps}
        style={[styles.container, style]}
      >
        <TabBar
          indicatorStyle={indicatorStyle}
          onSelect={this.onBarSelect}
          ref={this.tabBarRef}
          selectedIndex={selectedIndex}
          style={tabBarStyle}
        >
          {tabs}
        </TabBar>
        <ViewPager
          ref={this.viewPagerRef}
          {...viewProps}
          onOffsetChange={this.onPagerOffsetChange}
          onSelect={undefined} // needed to overwrite property from viewProps
          selectedIndex={selectedIndex}
          shouldLoadComponent={shouldLoadComponent}
          style={[styles.pagerContainer, style]}
          swipeEnabled={false}
        >
          {contents}
        </ViewPager>
      </View>
    );
  }

  public render (): React.ReactElement<ViewProps> {
    const {
      onSwipe,
    } = this.props;

    if (onSwipe) {
      return (
        <PanGestureHandler
          onEnded={(e) => onSwipe(e)}
        >
          {this.renderInner()}
        </PanGestureHandler>
      );
    }
    return this.renderInner();
  }
}
