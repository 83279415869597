import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, VSpacer, Input } from '@design';
import { useTranslation } from 'react-i18next';
import { ApiForm } from '@shared/interfaces/api';
import PropertyGroupValidation from './PropertyGroupValidation';
import { StringUtility } from '../../../../utilities';

const styles = StyleSheet.create({
  centeredContainer: {
    alignItems: 'center',
    flex: 3,
    height: 462,
  },
  textStyle: {
    minHeight: 162,
    overflow: 'visible',
  },
});

const { DescriptionMaxLength, NameMaxLength } = PropertyGroupValidation.Constants;
export interface PropertyGroupDetailsPageProps {
  data?: ApiForm,
  setPropertyGroup: React.Dispatch<React.SetStateAction<ApiForm>>,
}

export const PropertyGroupDetailsPage = ({
  data,
  setPropertyGroup,
}: PropertyGroupDetailsPageProps) => {
  const [translate] = useTranslation(['common', 'propertyGroups']);

  const descriptionCaption = () => {
    const remaining = StringUtility.charactersRemaining(data?.description ?? '', DescriptionMaxLength);
    return translate('CHARACTERS_REMAINING', { remaining, maxCharacters: DescriptionMaxLength });
  };

  return (
    <View style={styles.centeredContainer}>
      <View style={{ width: 489 }}>
        <Text category="label">{translate<string>('DETAILS')}</Text>
        <VSpacer size="7" />
        <Input
          isRequired
          label={translate('PROPERTY_GROUP_NAME')}
          maxLength={NameMaxLength}
          onChangeText={(name) => setPropertyGroup({ ...data, name })}
          testID="property-group-name"
          value={data?.name ?? ''}
        />
        <VSpacer size="7" />
        <Input
          caption={descriptionCaption()}
          isRequired
          label={translate<string>('PROPERTY_GROUP_DESCRIPTION')}
          maxLength={DescriptionMaxLength}
          multiline
          onChangeText={(description) => setPropertyGroup({ ...data, description })}
          status='basic'
          testID="property-group-description"
          textStyle={styles.textStyle}
          value={data?.description}
        />
      </View>
    </View>
  );
};
