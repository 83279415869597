import { Button } from '@design';
import { ApiTag } from '@shared/interfaces/api';
import React, { useState } from 'react';
import { View } from 'react-native';
import { AddContactTagsModal } from '../../../components/shared/CustomTags/AddContactTagsModal';
import { CustomTags } from '../../../components/shared/CustomTags/CustomTags';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const demoTags: ApiTag[] = [
  {
    id: '1',
    name: 'Management',
    isDefault: false,
  },
  {
    id: '2',
    name: 'High Priority',
    isDefault: false,
  },
  {
    id: '3',
    name: 'New Regional Contact',
    isDefault: false,
  },
  {
    id: '4',
    name: 'Third Party',
    isDefault: false,
  },
  {
    id: '5',
    name: 'Team Lead',
    isDefault: false,
  },
];

export const CustomTagsDemo = () => {
  const [tags, setTags] = useState(demoTags);
  const [showAddContactTagsModal, setShowAddContactTagsModal] = useState(false);

  return (
    <View style={{ backgroundColor: '#212121' }}>
      <DemoContainer>
        <DemoBlock style={{ padding: 24 }}>
          <Button
            appearance="outline"
            onPress={() => setShowAddContactTagsModal(true)}
            size="small"
            status="basic"
            testID="demo">Show Add Contact Tags Modal
          </Button>
          <View style={{ padding: 24 }}>
            <CustomTags
              onChangeTags={() => setTags}
              tags={tags.map((t) => t.name)}
              testID="custom-tags-demo"
            />
          </View>
        </DemoBlock>
      </DemoContainer>
      {showAddContactTagsModal && (
        <AddContactTagsModal
          allowCustomTags={true}
          assignedTags={[]}
          businessId="1234"
          initialTags={tags}
          onClose={() => setShowAddContactTagsModal(false)}
          onSave={(selectedTags) => {
            setShowAddContactTagsModal(false);
            alert(`Selected tags: ${selectedTags.join(', ')}`);
          }}
        />
      )}
    </View>
  );
};
