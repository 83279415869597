import { DeliverableType } from '@shared/enums';
import { TankMixDeliverableV0 } from '@shared/interfaces';
import {
  ApiDeliverable,
  ApiDeliverablePublic,
  GrowerDeliverableEndpoint,
} from '@shared/interfaces/api';
import {
  FarmPlanDeliverableV2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import qs from 'qs';
import { Client } from './Client';

interface CreateGrowerDeliverableBase {
  name: string,
  farmPlanId: string,
  note: string,
}
interface CreateGrowerDeliverableV2 extends CreateGrowerDeliverableBase {
  version: 2,
  selections: FarmPlanDeliverableV2.Selections,
}

type CreateGrowerDeliverable = (
  CreateGrowerDeliverableV2
);

export class GrowerDeliverableApi {
  static createGrowerDeliverable (
    params: CreateGrowerDeliverable,
  ): Promise<{ createdDeliverable: ApiDeliverable }> {
    const {
      name, selections, farmPlanId, note, version,
    } = params;
    return Client('growerDeliverables', { body: {
      name,
      selections,
      farmPlanId,
      type: DeliverableType.FARM_PLAN,
      note,
      version,
    } });
  }

  static createTankMixGrowerDeliverable (
    name: string,
    selections: TankMixDeliverableV0.Selections,
    growerId: string,
    tankMixId: string,
    note: string,
  ): Promise<{ createdDeliverable: ApiDeliverable }> {
    return Client('growerDeliverables', { body: {
      name,
      selections,
      growerId,
      tankMixId,
      type: DeliverableType.TANK_MIX,
      note,
    } });
  }

  static getGrowerDeliverable<Data, Selections> (
    id: string,
  ): Promise<ApiDeliverable<Data, Selections>> {
    return Client(`growerDeliverables/${id}`);
  }

  static getPublicGrowerDeliverable<Data, Selections> (
    publicId: string,
  ): Promise<ApiDeliverablePublic<Data, Selections>> {
    return Client(`growerDeliverables/public/${publicId}`);
  }

  static getDeliverableShareEvents (
    deliverableId: string,
  ): Promise<GrowerDeliverableEndpoint.ShareEvents.Get.Response> {
    return Client(
      `growerDeliverables/${deliverableId}/shareEvents`,
    );
  }

  static listGrowerDeliverables (
    params: GrowerDeliverableEndpoint.List.Query,
  ): Promise<GrowerDeliverableEndpoint.List.Response> {
    return Client(`growerDeliverables?${qs.stringify(params, { arrayFormat: 'comma' })}`);
  }

  static deleteDeliverable (
    id: string,
  ): Promise<boolean> {
    return Client(`growerDeliverables/${id}`, {
      method: 'DELETE',
    });
  }

  static sendLinkToContacts (
    deliverableId: string,
    contacts: GrowerDeliverableEndpoint.SendLinkToContacts.Request,
  ): Promise<GrowerDeliverableEndpoint.SendLinkToContacts.Response> {
    return Client(
      `growerDeliverables/${deliverableId}/sendToContacts`,
      { body: contacts },
    );
  }
}
