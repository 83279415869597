import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScrollView, StyleSheet, TouchableOpacity, useWindowDimensions, View,
  Text } from 'react-native';
import { Divider, Icon, Card } from '..';
import { Sizing, ThemeColors } from '../../constants';
import { ModalUtility, ToasterUtility } from '../../elements/content';
import { LogErrorApi } from '../../utilities/api/LogErrorApi';

export const UnexpectedError = (e: Error, errorTitle: string, context: object = {}) => {
  LogErrorApi.logError(e, {
    ...context,
    errorTitle,
  });
  ToasterUtility.error({
    children: (
      <Text>
        {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
        <TranslateKey namespace="common" translationKey="UNEXPECTED_ERROR" />
        {` - ${errorTitle}` }
      </Text>
    ),
    actions: [
      {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        label: <TranslateKey namespace="common" translationKey="DETAILS" />,
        status: 'secondary',
        action: () => {
          const promise = new Promise<void>((resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ModalUtility.show(<UnexpectedErrorDetails error={e} />);
            reject();
          });
          return promise;
        },
      },
    ],
    testID: 'toast-content-element',
  });
};

const styles = StyleSheet.create({
  card: {
    minWidth: 40 * Sizing.EM,
    maxHeight: 30 * Sizing.EM,
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    flex: 1,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: Sizing.BASE_SPACING,
  },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  icon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
});

const UnexpectedErrorDetails:FunctionComponent<{ error: Error }> = ({ error }) => {
  const [translate] = useTranslation('common');
  const dimensions = useWindowDimensions();
  const Header = (
    <View style={{ flexDirection: 'row' }}>
      <View style={[styles.flexLeft, { flex: 1 }]}>
        <Text style={styles.header}>{translate('ERROR_DETAILS')}</Text>
      </View>
      <View style={[styles.flexRight, { flex: 1 }]}>
        <TouchableOpacity onPress={() => ModalUtility.hide()}>
          <Icon fill={ThemeColors.TEXT} name="close-outline" style={styles.icon} />
        </TouchableOpacity>
      </View>
    </View>
  );

  const { message, stack, ...rest } = error;
  let json: string = null;
  if (Object.keys(rest).length > 0) {
    json = JSON.stringify(rest);
  }

  return (
    <Card header={Header} testID="unexpected-error-card">
      <ScrollView style={{
        maxWidth: dimensions.width - (20 * Sizing.EM),
        maxHeight: dimensions.height - (20 * Sizing.EM),
      }}
      >
        <View style={{ marginBottom: Sizing.BASE_SPACING }}>
          <Text>
            {'Error Message: '}
            {message}
          </Text>
        </View>
        {
          json && (
          <View style={{ marginBottom: Sizing.BASE_SPACING }}>
            <Divider style={{ marginBottom: Sizing.BASE_SPACING }} />
            <Text>
              Other Data:
              {' '}
              {json}
            </Text>
          </View>
          )
        }
        {
          stack && (
            <View style={{ marginBottom: Sizing.BASE_SPACING }}>
              <Divider style={{ marginBottom: Sizing.BASE_SPACING }} />
              <Text>{stack}</Text>
            </View>
          )
        }
      </ScrollView>
    </Card>
  );
};

// this is a hacky component to translate a key from a namespace
// I had to create it for keys that are translated outside of react components.
// It's private to limit it to UnexpectedError component.
// Before you make this public, we need to discuss it.
interface ITranslateKeyProps {
  translationKey: string,
  namespace: string,
}

const TranslateKey: FunctionComponent<ITranslateKeyProps> = ({
  translationKey,
  namespace,
}) => {
  const [translate] = useTranslation(namespace);

  return <>{translate(translationKey)}</>;
};
