import React from 'react';
import { StyleSheet, Text, View, ViewProps } from 'react-native';
import { ThemeColors, Sizing } from '../../../constants';

const styles = StyleSheet.create({
  label: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: Sizing.QUARTER_SPACING,
    borderRadius: Sizing.QUARTER_SPACING,
    backgroundColor: ThemeColors.TEXT,
  },
  text: {
    fontSize: 0.75 * Sizing.BASE_SPACING,
    color: ThemeColors.BACKGROUND,
  },
  labelInfo: { backgroundColor: ThemeColors.INFO },
  labelSuccess: { backgroundColor: ThemeColors.SUCCESS },
  labelWarning: { backgroundColor: ThemeColors.WARNING },
  labelDanger: { backgroundColor: ThemeColors.DANGER },
});

export interface StyledLabelProps extends ViewProps {
  status?: string,
  title: string,
  nativeID?: string,
}

export const StyledLabel = (props: StyledLabelProps) => (
  <View style={[
    styles.label,
    props.status === 'info' && styles.labelInfo,
    props.status === 'success' && styles.labelSuccess,
    props.status === 'warning' && styles.labelWarning,
    props.status === 'danger' && styles.labelDanger,
    props.style,
  ]}
  >
    <Text nativeID={props.nativeID} style={styles.text}>{props.title}</Text>
  </View>
);
