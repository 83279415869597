import React, { FunctionComponent, ReactElement } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Sizing } from '../../../constants';

const styles = StyleSheet.create({
  quadRowWrapper: {
    flexDirection: 'row',
  },
  quadRowCell: {
    borderLeftWidth: 1,
    borderLeftColor: 'white',
    paddingHorizontal: Sizing.BASE_SPACING,
  },
  quadRowCell0: {
    borderLeftWidth: 0,
  },
});

interface QuadRowProps extends ViewProps {
  cells: ReactElement[];
  paddingVertical?: number;
}
export const QuadRow: FunctionComponent<QuadRowProps> = ({
  cells,
  paddingVertical = Sizing.HALF_SPACING,
  style: viewStyle,
  ...viewProps
}) => (
  <View style={[styles.quadRowWrapper, viewStyle]} {...viewProps}>
    {cells.map((cell, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <View key={`cell${index}`} style={[styles.quadRowCell, { paddingVertical }, styles[`quadRowCell${index}`]]}>
        {cell}
      </View>
    ))}
  </View>
);
