import { EmptyState, Text, VSpacer } from '@design';
import { ApiCompanyRevenueByStatus } from '@shared/interfaces/api/StatisticsEndpoint';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ContentType } from 'recharts/types/component/Label';
import { StringUtility } from '../../../../utilities';
import {
  AXIS_COLOR,
  BAR_SIZE,
  CHART_COLORS,
  CHART_STYLE,
  ChartContainer,
  LABEL_BACKGROUND_COLOR,
  LABEL_COLOR,
  LabelText,
  LegendLabel,
  RULES_COLOR,
  TICK_WIDTH,
} from './helpers';

const MIN_HEIGHT = 32;

function CustomLabel (labelProps: {
  x: number,
  y: number,
  value: number,
  width: number,
  height: number,
  fill: string,
}) {
  return ((labelProps.height < MIN_HEIGHT) || !labelProps.value) ? null : (
    <>
      <line
        stroke={AXIS_COLOR}
        x1={labelProps.x}
        x2={labelProps.x + labelProps.width + TICK_WIDTH}
        y1={labelProps.y}
        y2={labelProps.y}
      />
      <LabelText
        verticalAlign="center"
        x={labelProps.x + labelProps.width + TICK_WIDTH}
        y={labelProps.y}
      >
        {StringUtility.formatFlatCurrencyShortBasic(labelProps.value)}
      </LabelText>
    </>
  );
}

function CustomTotalLabel (labelProps: {
  fill: string,
  height: number,
  value: number,
  width: number,
  x: number,
  y: number
}) {
  return !labelProps.value ? null : (
    <LabelText
      align="center"
      verticalAlign="top"
      x={labelProps.x + labelProps.width / 2}
      y={labelProps.y - MIN_HEIGHT}
    >
      {StringUtility.formatFlatCurrencyShortBasic(labelProps.value)}
    </LabelText>
  );
}

function CustomTooltip (tooltipProps, customTooltipFormatter) {
  if (!tooltipProps.active || !tooltipProps.payload) {
    return null;
  }

  const label = customTooltipFormatter
    ? customTooltipFormatter(tooltipProps)
    : tooltipProps.label;

  return (
    <View
      style={{
        backgroundColor: 'white',
        padding: 12,
        borderColor: LABEL_COLOR,
        borderWidth: 1,
      }}
    >
      <Text style={{ color: LABEL_COLOR }}>{label}</Text>
      <VSpacer size="4" />
      {tooltipProps.payload.reverse().map(({ name, value, dataKey, payload }) => (
        <Text style={{ color: LABEL_COLOR }}>
          {`${name}: ${StringUtility.formatCurrency(
            dataKey === 'orderDiscounts' ? payload.total : value,
          )}`}
        </Text>
      ))}
    </View>
  );
}

export interface StatusStackedBarChartProps<T extends ApiCompanyRevenueByStatus> {
  title: string;
  data: T[],
  xDataKey: string,
  tooltipLabelFormatter?(tooltipProps: any): string;
  xTickFormatter?(value: any, index: number): string;
  xTick?(props: any): React.ReactElement;
  xTickHeight?: number;
}
export default function StatusStackedBarChart<T extends ApiCompanyRevenueByStatus> ({
  title,
  data,
  tooltipLabelFormatter,
  xDataKey,
  xTickFormatter,
  xTick,
  xTickHeight,
}: StatusStackedBarChartProps<T>) {
  const [translate] = useTranslation(['dashboard', 'common']);
  const showBrush = data?.length > 3;
  const emptyData = !data.length || data.every((d) => !d.total);

  const chartData = [
    { dataKey: 'unlabeled', color: CHART_COLORS[0], name: translate('UNLABELED') },
    { dataKey: 'shared', color: CHART_COLORS[1], name: translate('SHARED') },
    { dataKey: 'accepted', color: CHART_COLORS[2], name: translate('ACCEPTED') },
    { dataKey: 'orderDiscounts', color: 'transparent', name: translate('TOTAL') },
  ];

  const subTitle = data.some((d) => d.orderDiscounts > 0)
    ? translate('TOTAL_REVENUE_FIGURES_MESSAGE')
    : '';

  return (
    <ChartContainer
      legend={!emptyData && chartData.map(({ dataKey, color, name }) => (
        <React.Fragment key={dataKey}>
          {dataKey !== 'orderDiscounts' && <LegendLabel color={color} text={name} />}
        </React.Fragment>
      ))}
      subTitle={subTitle}
      testID={`sales-funnel-revenue-by-${xDataKey}`}
      title={title}
    >
      {emptyData ? (
        <EmptyState testID="no-data" title={translate('THERE_IS_NO_DATA_TO_DISPLAY')} />
      ) : (
        <BarChart
          data={data}
          height={500}
          margin={{ top: 70, right: 20, left: 20, bottom: 20 }}
          style={CHART_STYLE}
          width={600}
        >
          <CartesianGrid stroke={RULES_COLOR} vertical={false} />
          <XAxis
            dataKey={xDataKey}
            fontSize="0.8em"
            height={xTickHeight ?? 30}
            stroke={AXIS_COLOR}
            tick={xTick}
            tickFormatter={xTickFormatter}
          />
          <YAxis
            stroke={AXIS_COLOR}
            tickCount={6}
            tickFormatter={(label) => StringUtility.formatFlatCurrencyShortBasic(label)}
          />
          {showBrush && (
            <Brush
              dataKey={xDataKey}
              endIndex={2}
              stroke="black"
              tickFormatter={() => ''}
              traveller={<></>}
              travellerWidth={0}
            >
              <BarChart>
                {chartData.map(({ dataKey, color }) => (
                  <Bar
                    dataKey={dataKey}
                    fill={color}
                    key={dataKey}
                    stackId="status"
                  />
                ))}
              </BarChart>
            </Brush>
          )}
          <Tooltip
            content={(tooltipProps) => (
              CustomTooltip(tooltipProps, tooltipLabelFormatter)
            )}
            cursor={{ fill: LABEL_BACKGROUND_COLOR }}
          />
          {chartData.map(({ dataKey, color, name }) => (
            <Bar
              barSize={BAR_SIZE}
              dataKey={dataKey}
              fill={color}
              name={name}
              stackId="status"
            >
              <LabelList
                content={(dataKey === 'orderDiscounts'
                  ? CustomTotalLabel
                  : CustomLabel) as ContentType}
                id={`sales-funnel-revenue-by-${xDataKey}-bar-label`}
                valueAccessor={(value) => value[dataKey === 'orderDiscounts' ? 'total' : dataKey]}
              />
            </Bar>
          ))}
        </BarChart>
      )}
    </ChartContainer>
  );
}
