import { Button, CheckBox, HSpacer, Icon, Text, VSpacer } from '@design';
import {
  ApiProductOrderProductMixComponent,
} from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import DetailHeader from '../../../src/components/shared/DetailHeader/DetailHeader';
import { StringUtility } from '../../../utilities';
import { calculateTankMixTotalCostPerAcre } from './helpers';
import { LockPriceConfirmationDialog } from './LockPriceConfirmationDialog';
import _ from 'lodash';

interface ProductOrderProductMixComponentsTableProps {
  components: ApiProductOrderProductMixComponent[],
  locationId: string,
  onAddDiscount?: (
    component: ApiProductOrderProductMixComponent,
  ) => void,
  onLockPrice: (
    component: ApiProductOrderProductMixComponent,
    isLocked: boolean,
  ) => void,
  priceTypeId: string,
  viewMode?: boolean,
}

interface ComponentItemProps {
  component: ApiProductOrderProductMixComponent,
  index: number,
  locationId: string,
  onAddDiscount?: (
    component: ApiProductOrderProductMixComponent,
  ) => void,
  onLockPrice: (
    component: ApiProductOrderProductMixComponent,
    isLocked: boolean,
  ) => void,
  priceTypeId: string,
  styles,
  viewMode?: boolean,
}

export const ComponentItem: FC<ComponentItemProps> = ({
  component,
  index,
  locationId,
  onAddDiscount,
  onLockPrice,
  priceTypeId,
  styles,
  viewMode,
}) => {
  const [showPriceLockDialog, setShowPriceLockDialog] = useState<boolean>(false);
  const [translate] = useTranslation(['productOrders', 'products', 'common']);
  const [priceLocked, setPriceLocked] = useState<boolean>(!_.isNil(component.packagePrice));

  const unitPrice = useMemo(() => (
    CalculationUtility.getApplicableProductPrice(
      component.product,
      priceTypeId,
      locationId,
    )
  ), [component.product, priceTypeId, locationId]);

  const costPerAcre = useMemo(() => (
    calculateTankMixTotalCostPerAcre(
      [component],
      priceTypeId,
      locationId,
    )
  ), [component, priceTypeId, locationId]);

  useEffect(() => {
    setPriceLocked(
      (!_.isNil(component.packagePrice)) || component.packagePrice === unitPrice,
    );
  }, [unitPrice, component.packagePrice]);

  return (
    <>
      {showPriceLockDialog && (
        <LockPriceConfirmationDialog
          initPrice={unitPrice}
          onCancel={() => setShowPriceLockDialog(false)}
          onConfirm={() => {
            onLockPrice(component, false);
            setShowPriceLockDialog(false);
          }}
          packagePrice={component.packagePrice}
          unitUoM={component.product.unitUoM}
          visible
        />
      )}
      <View key={component.productId}>
        <VSpacer size="5" />
        <View style={[styles.row, styles.center]}>
          <Text category="s1">
            {component.product?.skuName}
          </Text>
          <HSpacer size="7" />
          {!component.product?.isActive ? (
            <>
              <Icon
                name="AlertTriangle"
                status="warning"
                testID={`product-card-deactivated-warning-icon-${index}`}
              />
              <HSpacer size="2" />
              <Text
                category="p1"
                testID={`product-card-deactivated-warning-message-${index}`}
              >
                {translate<string>('PRODUCT_DEACTIVATED_WARNING')}
              </Text>
            </>
          ) : (
            <>
              {viewMode ? (
                <>
                  <Icon
                    name={priceLocked ? 'Lock' : 'GrowersUnlock'}
                    status="basic" testID={`product-card-text-price-locked-icon-${index}`}
                  />
                  <HSpacer size="3" />
                  <Text testID={`product-card-text-price-locked-${index}`}>
                    {translate<string>(priceLocked ? 'PRICE_LOCKED' : 'PRICE_UNLOCKED')}
                  </Text>
                </>
              ) : (
                <CheckBox
                  checked={priceLocked}
                  onChange={(checked) => {
                    if (!checked) {
                      if (component.packagePrice === unitPrice) {
                        setPriceLocked(false);
                        onLockPrice(component, false);
                      } else {
                        setShowPriceLockDialog(true);
                      }
                    } else {
                      onLockPrice(component, checked);
                      setPriceLocked(checked);
                    }
                  }}
                  testID={`product-card-price-locked-${index}`}
                >
                  {translate<string>('LOCK_PRICE')}
                </CheckBox>
              )}
            </>
          )}
        </View>
        <VSpacer size="5" />
        <View style={[styles.row, styles.spaceBewteen]}>
          <View style={styles.row}>
            <Text
              appearance="hint"
              category="p2"
              testID={`product-card-unit-price-label-${index}`}
            >
              {`${translate<string>('UNIT_PRICE')}:`}
            </Text>
            <HSpacer size="3" />
            <Text
              category="p2"
              testID={`product-card-unit-price-${index}`}
            >
              {StringUtility.formatCurrency(component.packagePrice ?? unitPrice, '$')}
            </Text>
            <HSpacer size="5" />
            <Text
              appearance="hint"
              category="p2"
              testID={`product-card-rate-label-${index}`}
            >
              {`${translate<string>('RATE_PER_AC')}:`}
            </Text>
            <HSpacer size="3" />
            <Text
              category="p2"
              testID={`product-card-rate-${index}`}
            >
              {component.applicationRate}
            </Text>
            <HSpacer size="5" />
            <Text
              appearance="hint"
              category="p2"
              testID={`product-card-uom-label-${index}`}
            >
              {`${translate<string>('UOM')}:`}
            </Text>
            <HSpacer size="3" />
            <Text
              category="p2"
              testID={`product-card-uom-${index}`}
            >
              {translate(component.applicationRateUom)}
            </Text>
            <HSpacer size="5" />
            <Text
              appearance="hint"
              category="p2"
              testID={`product-card-intended-use-label-${index}`}
            >
              {`${translate<string>('INTENDED_USE')}:`}
            </Text>
            <HSpacer size="3" />
            <Text
              category="p2"
              testID={`product-card-intended-use-${index}`}
            >
              {translate<string>(component.intendedUse?.toUpperCase())}
            </Text>
            <HSpacer size="5" />
            <Text
              appearance="hint"
              category="p2"
              testID={`product-card-cost-ac-label-${index}`}
            >
              {`${translate<string>('COST_PER_AC')}:`}
            </Text>
            <HSpacer size="3" />
            <Text
              category="p2"
              testID={`product-card-cost-ac-${index}`}
            >
              {StringUtility.formatCurrency(costPerAcre, '$')}
            </Text>
          </View>
          {!!onAddDiscount && component.product?.isActive && (
            <Button
              accessoryLeft={(iconProps) => <Icon name="Pricetags" testID={`tank-mix-add-discount-icon-${index}`} {...iconProps} />}
              appearance="outline"
              disabled={viewMode}
              onPress={() => {
                onAddDiscount(component);
              }}
              size="small"
              status="primary"
              testID={`product-list-add-discount-${index}`}
            >
              {translate<string>('ADD_DISCOUNT')}
            </Button>
          )}
        </View>
        <VSpacer size="6" />
        <Divider style={styles.divider} />
      </View>
    </>
  );
};

export const ProductOrderProductMixComponentsTable: FC<ProductOrderProductMixComponentsTableProps> = ({
  components,
  locationId,
  onAddDiscount,
  onLockPrice,
  priceTypeId,
  viewMode = false,
}) => {

  const styles = useStyleSheet({
    container: {
      backgroundColor: 'color-basic-transparent-0',
      paddingHorizontal: 16,
      paddingTop: 4,
      paddingBottom: 40,
    },
    divider: {
      backgroundColor: 'color-basic-transparent-100',
    },
    row: {
      flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap',
    },
    center: {
      alignItems: 'center',
    },
    spaceBewteen: {
      justifyContent: 'space-between',
    },
  });

  const [translate] = useTranslation(['productOrders', 'products', 'common']);

  return (
    <View style={styles.container}>
      <DetailHeader header={translate<string>('COMPONENTS')} />
      <VSpacer size="7" />
      <Divider style={styles.divider}/>
      {components.map((component, index) => (
        <ComponentItem
          component={component}
          index={index}
          key={component.id}
          locationId={locationId}
          onAddDiscount={onAddDiscount}
          onLockPrice={onLockPrice}
          priceTypeId={priceTypeId}
          styles={styles}
          viewMode={viewMode}
        />
      ))}
    </View>
  );
};
