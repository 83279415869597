import { CheckBox, Input, Text, VSpacer } from '@design';
import { ApiPriceType, ApiProductOrder } from '@shared/interfaces/api';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useCustomerContacts } from '../../../hooks/useCustomerContacts';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { testId } from '../../../utilities';
import { GrowerApi, UserApi } from '../../../utilities/api';
import { BusinessLocationSelect } from '../../components/shared/Input/BusinessLocationSelect';
import { CropYearPickerSelect } from '../../components/shared/Input/CropYearPickerSelect';
import {
  CustomerContactMode,
  CustomerContactSelect,
} from '../../components/shared/Input/CustomerContactSelect';
import { PriceTypeSelect } from '../../components/shared/Input/PriceTypeSelect';
import { ChangePriceTypeConfirmationModal } from './ChangePriceTypeConfirmationModal';
import { CustomerSelect } from '../../components/shared/Input/CustomerSelect';

const styles = StyleService.create({
  ownerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  ownerLabel: {
    color: 'text-hint-color',
    fontSize: 14,
  },
  ownerName: {
    fontSize: 14,
  },
});

interface ProductOrderBasicDetailsProps {
  onProductOrderChange: (productOrder: ApiProductOrder) => void;
  productOrder: ApiProductOrder,
  showContactDesignation?: boolean,
}

export const ProductOrderBasicDetails: FC<ProductOrderBasicDetailsProps> = ({
  onProductOrderChange,
  productOrder,
  showContactDesignation = false,
}) => {
  const { priceTypes, defaultPriceType } = usePriceTypeList();
  const [selectedPriceType, setSelectedPriceType] = useState<ApiPriceType>(null);
  const [previousSelectedPriceType, setPreviousSelectedPriceType] = useState<ApiPriceType>(null);
  const [showPriceTypeModal, setShowPriceTypeModal] = useState(false);
  const { user } = useAuthentication();
  const [translate] = useTranslation(['productOrders', 'errors', 'products', 'common']);
  const themedStyles = useStyleSheet(styles);

  const { data: userData } = useQuery(
    [QueryKeys.USER, productOrder.ownerId ?? user.id],
    () => UserApi.getUserById(productOrder.ownerId ?? user.id),
    { enabled: !!user.id },
  );

  const { data: productOrderGrower } = useQuery(
    [QueryKeys.GROWER, productOrder.growerId],
    () => GrowerApi.getGrower(productOrder.growerId),
    { enabled: !!productOrder.growerId },
  );

  const { total: totalCustomerContacts } = useCustomerContacts(productOrder.growerId);

  const checkBillingShippingSame = () => {
    if (productOrder.billingContactId === productOrder.shippingContactId) {
      onProductOrderChange({ ...productOrder, shippingContactId: null });
    } else {
      onProductOrderChange({
        ...productOrder,
        shippingContactId: productOrder.billingContactId,
      });
    }
  };

  useEffect(() => {
    const currentPriceType = priceTypes.find((priceType) => (
      priceType.id === productOrder.priceTypeId
    ));
    if (!currentPriceType) {
      setSelectedPriceType(defaultPriceType);
    } else {
      setSelectedPriceType(currentPriceType);
    }
  }, [defaultPriceType, priceTypes, productOrder.priceTypeId]);

  const showCustomerContactInputFields = (
    productOrder.growerId && showContactDesignation && totalCustomerContacts > 0
  );

  return (
    <>
      <View style={[themedStyles.ownerContainer]}>
        <Text style={[themedStyles.ownerLabel]}>Owner: </Text>
        <Text style={[themedStyles.ownerName]} {...testId('owner-name-label')}>
          {userData?.fullName}
        </Text>
      </View>
      <VSpacer size="8" />
      <Input
        defaultValue={productOrder.name}
        isRequired
        label={translate('ORDER_NAME') as string}
        onChangeText={(name) => {
          onProductOrderChange({ ...productOrder, name });
        }}
        testID="order-name-field"
      />
      <VSpacer size="8" />
      <CustomerSelect
        customerId={productOrderGrower?.id}
        onUpdateCustomer={(growerId) => {
          onProductOrderChange({
            ...productOrder,
            growerId: growerId,
            shippingContactId: null,
            billingContactId: null,
          });
        }}
        testID="growers-selector"
      />
      {showCustomerContactInputFields && (
        <>
          <VSpacer size="8" />
          <CustomerContactSelect
            customerContactId={productOrder.billingContactId}
            customerId={productOrder.growerId}
            mode={CustomerContactMode.BILLING}
            onUpdateCustomerContact={
              (billingContactId) => onProductOrderChange({ ...productOrder, billingContactId })
            }
          />
          <VSpacer size="8" />
          <CustomerContactSelect
            customerContactId={productOrder.shippingContactId}
            customerId={productOrder.growerId}
            mode={CustomerContactMode.SHIPPING}
            onUpdateCustomerContact={
              (shippingContactId) => onProductOrderChange({ ...productOrder, shippingContactId })
            }
          />
          <VSpacer size="8" />
          <CheckBox
            checked={
              productOrder.billingContactId
              && (productOrder.billingContactId === productOrder.shippingContactId)
            }
            onChange={checkBillingShippingSame}
            testID="shipping-same-as-billing-checkbox"
          >
            {translate<string>('SHIPPING_SAME_AS_BILLING')}
          </CheckBox>
        </>
      )}
      <VSpacer size="8" />
      <BusinessLocationSelect
        locationId={productOrder.locationId ?? null}
        onUpdateLocation={(locationId) => onProductOrderChange({ ...productOrder, locationId })}
      />
      <PriceTypeSelect
        isRequired
        onUpdatePriceType={(priceType) => {
          if (priceType !== selectedPriceType) {
            setSelectedPriceType(priceType);
            setPreviousSelectedPriceType(selectedPriceType);
            if (productOrder.isDraft) {
              setShowPriceTypeModal(true);
            } else {
              onProductOrderChange({ ...productOrder, priceTypeId: priceType.id });
            }
          }
        }}
        selectedPriceType={selectedPriceType}
      />
      <VSpacer size="8" />
      <CropYearPickerSelect
        cropYear={productOrder.cropYear ?? null}
        onChange={(cropYear) => onProductOrderChange({ ...productOrder, cropYear })}
      />
      <ChangePriceTypeConfirmationModal
        newPriceType={selectedPriceType}
        oldPriceType={previousSelectedPriceType}
        onCancel={() => {
          setSelectedPriceType(previousSelectedPriceType);
          setShowPriceTypeModal(false);
          onProductOrderChange({ ...productOrder, priceTypeId: previousSelectedPriceType.id });
        }}
        onConfirm={() => {
          setShowPriceTypeModal(false);
          onProductOrderChange({ ...productOrder, priceTypeId: selectedPriceType.id });
        }}
        visible={showPriceTypeModal}
      />
    </>
  );
};
