import { BannerProps, Button, Icon, IconButton, Text, useBanner, useToast, VSpacer } from '@design';
import { ApiUserAccount } from '@shared/interfaces/api';
import {
  FarmPlanDeliverableV2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, ViewStyle } from 'react-native';
import { useQueryClient } from 'react-query';
import { RoleUtility, Permissions } from '@shared/utils';
import { GrowerDeliverableApi } from '../../../utilities/api/GrowerDeliverableApi';
import { QueryKeys } from '../../../constants/QueryKeys';
import { useHistory, useRouteMatch, useLocation } from '../../../router';
import { Environment, LinkingUtility } from '../../../utilities';
import { ButtonBar } from '../../components/shared/ButtonBar';
import OrderDetails from '../../components/shared/FarmPlan/OrderDetails';
import { FarmPlanDeliverablePerFieldSummary } from './FarmPlanDeliverablePerFieldSummary';
import { FarmPlanDeliverableSummary } from './FarmPlanDeliverableSummary';
import { DeleteDeliverableDialog } from '../PrepareReview/GrowerDeliverableTab/components/DeleteDeliverable/DeleteDeliverableDialog';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { Routes } from '../../../constants';

interface FarmPlanDeliverableProps {
  deliverableId: string;
  name?: string;
  note?: string;
  publicId?: string,
  selections: FarmPlanDeliverableV2.Selections;
  shared?: boolean,
  snapshot: FarmPlanDeliverableV2.Snapshot;
  user?: ApiUserAccount;
  isPublicPage?: boolean;
}

export const FarmPlanDeliverableDesktopView = ({
  deliverableId,
  name,
  note,
  publicId,
  selections,
  shared,
  snapshot,
  user,
}: FarmPlanDeliverableProps) => {
  const [translate] = useTranslation([
    'deliverable',
    'farmPlans',
    'growers',
    'prepare',
    'common',
  ]);
  const history = useHistory();
  const match = useRouteMatch(Routes.GROWER_DELIVERABLE_PUBLIC);
  const location = useLocation();
  const isPublicPage = match?.url === location.pathname;
  const queryClient = useQueryClient();
  const { user: currentUser } = useAuthentication();
  const [
    deleteDeliverableModalVisible,
    setDeleteDeliverableModalVisible,
  ] = useState<boolean>(false);
  const { createBanner } = useBanner();
  const { createToast } = useToast();
  const isOwner = !isPublicPage && (user.id === currentUser.id);
  const isSuperUser = RoleUtility.roleHasPermission(
    user.userRole, Permissions.ACCESS_ALL_BUSINESSES,
  );
  const canDeleteDeliverable = (!isSuperUser && isOwner && !shared);

  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ),
  });

  const showFieldDetails = Object.values(selections.fieldDetails).some(Boolean);

  const handleDeleteDeliverable = async () => {
    try {
      await GrowerDeliverableApi.deleteDeliverable(deliverableId);
      await queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST);
      setDeleteDeliverableModalVisible(false);
      createToast({
        children: `${translate('DELIVERABLE_DELETED')}`,
        status: 'success',
        testID: 'toast-content-element',
      });
      history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'deliverables'));
    } catch (err) {
      createBanner(bannerProps(translate('DELETE_DELIVERABLE_ERROR')));
    }
  };

  const trashStyles: ViewStyle = canDeleteDeliverable ? {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } : null;

  return (
    <>
      <ScrollView style={{ flex: 1 }}>
        <VSpacer size="3" />
        <Text appearance="hint" category="overline">
          {`${translate('FARM_PLAN_DELIVERABLE')}`}
        </Text>
        <VSpacer size="3" />
        <View style={trashStyles}>
          <Text category="h3" testID="farm-plan-name">{name}</Text>
          {canDeleteDeliverable && (
            <View>
              <IconButton
                appearance="ghost"
                onPress={() => setDeleteDeliverableModalVisible(!deleteDeliverableModalVisible)}
                status="basic"
                testID="delete-button"
              >
                <Icon name="Trash" testID="delete-button-icon" />
              </IconButton>
            </View>
          )}
        </View>
        <VSpacer size="10" />
        {/* Details / Acres Planned / Cost Summary */}
        <FarmPlanDeliverableSummary
          note={note}
          selections={selections}
          snapshot={snapshot}
          user={user}
        />
        <OrderDetails
          overview={snapshot.overview}
          products={snapshot.products}
          selections={selections}
        />
        {showFieldDetails && (
          <FarmPlanDeliverablePerFieldSummary
            selections={selections}
            zoneSummaries={snapshot.areas}
          />
        )}
      </ScrollView>
      <ButtonBar
        accessoryLeft={(props) => !isPublicPage && (
          <Icon name="ArrowBack" testID="back-button-icon" {...props} />
        )}
        accessoryRight={(props) => (
          <Icon name="Download" testID="download-pdf-button-icon" {...props} />
        )}
        hideLeftButton={isPublicPage}
        leftAction={() => history.goBack()}
        leftButtonText={translate('BACK')}
        rightAction={() => LinkingUtility.openUrl(
          `${Environment.get(
            'API_URL',
          )}/growerDeliverables/public/${isPublicPage ? deliverableId : publicId}/pdf`,
          { newTab: true },
        )}
        rightButtonText={translate('DOWNLOAD_PDF')}
        testID="download-pdf-button"
      />
      <DeleteDeliverableDialog
        name={name}
        onClose={() => {
          setDeleteDeliverableModalVisible(false);
        }}
        onDelete={handleDeleteDeliverable}
        visible={deleteDeliverableModalVisible}
      />
    </>
  );
};
