import {
  ApiCompanyRevenue,
  ApiRevenue,
  StatisticsCropYearType,
} from '@shared/interfaces/api';
import qs from 'qs';
import { Client } from './Client';

export class StatisticsApi {
  static getCompanyRevenue (businessId: string, cropYear?: number[]): Promise<ApiCompanyRevenue> {
    const query = qs.stringify({ businessId, cropYear }, { arrayFormat: 'comma' });
    return Client(`statistics/company-revenue?${query}`);
  }

  static getOrderRevenue (businessId: string, cropYear?: number[]): Promise<ApiRevenue> {
    const query = qs.stringify({ businessId, cropYear }, { arrayFormat: 'comma' });
    return Client(`statistics/revenue?${query}`);
  }

  static getCropYears (businessId: string, type: StatisticsCropYearType = null): Promise<number[]> {
    const params: {
      businessId: string;
      type?: StatisticsCropYearType;
    } = { businessId };

    if (type) {
      params.type = type;
    }

    const query = qs.stringify(params, { arrayFormat: 'comma' });
    return Client(`statistics/crop-years?${query}`);
  }
}
