import { Environment } from '../Environment';
import { ApiUtils } from './ApiUtils';

export type ClientOptions = {
  body?: object | FormData,
  isImage?: boolean
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'PATCH',
};

export const Client = async <T = any>(
  endpoint,
  options: ClientOptions = {},
): Promise<T> => {
  const { body, isImage: image = false, method = body ? 'POST' : 'GET' } = options;
  const headers: HeadersInit = {
    Accept: 'application/json',
    Authorization: undefined,
    [Environment.get('API_KEY_NAME')]: Environment.get('API_KEY'),
  };

  if (body) {
    headers['Content-Type'] = 'application/json';
  }

  const config: RequestInit = {
    credentials: 'include',
    headers,
    method,
  };

  if (body && !(body instanceof FormData)) {
    config.body = JSON.stringify(body);
  }

  const response = await fetch(`${Environment.get('API_URL')}/${endpoint}`, config);

  if (!response.ok) {
    await ApiUtils.handleError(response, endpoint);
  }
  if (response.status === 204) {
    return null;
  }

  if (image) {
    return response.blob() as any;
  }

  return response.json();
};
