import {
  Button,
  CenteredSpinner,
  Filter,
  Icon,
  SidePanel,
  Text,
  useToast,
  VSpacer,
} from '@design';
import { ApiCrop, ApiGrowerFarm, ApiPlanningParameter } from '@shared/interfaces/api';
import { AnnualPlanningUtility, FarmUtility, getPredicateFn } from '@shared/utils';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useHistory, useParams } from '../../../../router';
import { BusinessApi, GrowerApi, GrowerFarmApi } from '../../../../utilities/api';
import { ButtonBar } from '../../../components/shared/ButtonBar';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { CropCard, PlanningParameterOptions } from './CropCard/CropCard';
import { CropSidePanel } from './CropSidePanel';

type PropertyType = 'price' | 'yieldGoal';

export interface SetPlanningParameterOptions extends PlanningParameterOptions {
  value: number,
  property: PropertyType,
}

export type SetPlanningParameters = (
  options: SetPlanningParameterOptions
) => void;

interface CropMutationOptions {
  customerId: string,
  newParameters: ApiPlanningParameter[],
}

interface UpdateCropsOptions {
  deletedCrops?: ApiCrop[],
  farms: ApiGrowerFarm[],
  id: string,
  planningParameters: ApiPlanningParameter[],
  userSelectedCrops: ApiCrop[],
}

export const PlanningParametersTab = () => {
  const [translate] = useTranslation(['growers', 'common']);
  const history = useHistory();
  const { grower: customerId } = useParams<{ grower: string }>();
  const { currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const { createToast } = useToast();
  const [cropSelectorVisible, setCropSelectorVisible] = useState(false);
  const [cropsToUpdate, setCropsToUpdate] = useState<ApiCrop[]>([]);
  const [cropToDelete, setCropToDelete] = useState<ApiCrop>(null);
  const [deleteCropConfirmation, setDeleteCropConfirmation] = useState(false);
  const [farms, setFarms] = useState<ApiGrowerFarm[]>([]);
  const [removedCrops, setRemovedCrops] = useState<ApiCrop[]>([]);
  const [removedCropsConfirmationVisible, setRemovedCropsConfirmationVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCrops, setSelectedCrops] = useState<ApiCrop[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  // Working copy of planningParameters
  const [planningParametersCopy, setPlanningParametersCopy] = (
    useState<ApiPlanningParameter[]>([])
  );
  // Original to compare to working copy
  const [planningParameters, setPlanningParameters] = (
    useState<ApiPlanningParameter[]>([])
  );
  const { syncPlanningParameters } = GrowerApi;

  const getCustomer = async () => GrowerApi.getGrower(customerId);
  const { isFetching: isCustomerFetching, data: customer } = useQuery(
    [QueryKeys.GROWER],
    getCustomer,
  );

  const getData = async () => {
    const [customerFarms, crops] = await Promise.all([
      GrowerFarmApi.getFarms(customerId),
      BusinessApi.getBusinessCrops(currentBusinessId),
      GrowerApi.getGrower(customerId),
    ]);
    return { customerFarms, crops };
  };
  const {
    isLoading: arePlanningParametersLoading,
    isFetching: arePlanningParametersFetching,
  } = useQuery([
    QueryKeys.CUSTOMER_PLANNING_PARAMETERS,
    currentBusinessId,
    customer,
    planningParameters,
    selectedCrops,
  ], getData, {
    enabled: !!customer,
    onError: (error: Error) => createToast({
      children: translate<string>('UNEXPECTED_ERROR', { error }),
      status: 'warning',
      testID: 'toast-content-element',
    }),
    onSuccess: ({ customerFarms, crops }) => {
      const uniquePlanningParamCrops = _.uniqBy(
        customer.planningParameters,
        (gpp: ApiPlanningParameter) => [gpp.cropType, gpp.cropSubType].join(),
      );
      const uniqueBusinessCrops = crops.filter((crop) => (
        !!uniquePlanningParamCrops.find((gpp) => (
          gpp.cropType === crop.cropType
          && gpp.cropSubType === crop.subType
        ))
      ));
      const planningParameterData = AnnualPlanningUtility.createPlanningParameters(
        customer.id,
        customer.planningParameters,
        uniqueBusinessCrops,
        customerFarms.data,
      );
      setFarms(customerFarms.data ?? []);
      setSelectedCrops(uniqueBusinessCrops ?? []);
      setPlanningParameters(planningParameterData ?? []);
      setPlanningParametersCopy(planningParameterData ?? []);
    },
  });

  useEffect(() => {
    if (selectedCrops.length >= 0 && !arePlanningParametersFetching) {
      setTotalResults(selectedCrops.length);
    }
  }, [arePlanningParametersFetching, selectedCrops.length]);

  const filteredFarms = useMemo(() => {
    if (_.isEmpty(search)) {
      return farms;
    }
    return farms.filter((farm) => FarmUtility.farmMatchesSearch(farm, search, false));
  }, [search, farms]);

  const formChanged = useMemo(() => !_.isEqual(planningParameters, planningParametersCopy),
    [planningParameters, planningParametersCopy]);

  const updatePlanningParameters = useCallback((options: SetPlanningParameterOptions): void => {
    const { crop, cropZone, farm, field, property, value } = options;
    let newPlanningParameters = _.cloneDeep(planningParametersCopy);
    const predicateFn = getPredicateFn(customerId, crop, farm, field, cropZone);
    const planningParameterIndex = newPlanningParameters.findIndex(predicateFn);
    if (planningParameterIndex === -1) {
      newPlanningParameters.push(AnnualPlanningUtility.createPlanningParameter(
        customerId,
        crop,
        farm,
        field,
        cropZone,
      ));
    } else {
      const editedParameter = newPlanningParameters[planningParameterIndex];
      editedParameter[property] = value || 0;
    }
    if (property === 'yieldGoal') {
      newPlanningParameters = AnnualPlanningUtility.reCalculateParentYieldGoals(
        crop,
        farms,
        newPlanningParameters,
        farm,
        field,
        cropZone,
      );
    }
    setPlanningParametersCopy(newPlanningParameters);
  }, [customerId, farms, planningParametersCopy]);

  const mutatePlanningParameters = useMutation(
    (options: CropMutationOptions) => {
      const { customerId: customerID, newParameters } = options;
      return syncPlanningParameters(
        customerID,
        newParameters,
      );
    }, {
      onError: (err: Error) => {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR', { error: err.message }),
          status: 'warning',
          testID: 'toast-content-element',
        });
      },
      onSuccess: (data) => {
        setPlanningParameters(data);
        setPlanningParametersCopy(data);
        queryClient.invalidateQueries([QueryKeys.GROWER]);
        createToast({
          children: translate<string>('PLANNING_PARAMS_UPDATED'),
          status: 'success',
          testID: 'toast-content-element',
        });
      },
    },
  );

  const getDeletedCrops = (crops: ApiCrop[]) => (
    selectedCrops.filter(({ cropType, subType }) => !crops.some((c) => (
      c.cropType === cropType && c.subType === subType
    ))));

  const updateCrops = async (options: UpdateCropsOptions) => {
    const {
      farms: customerFarms,
      id,
      planningParameters: existingPlanningParameters,
      userSelectedCrops,
    } = options;
    const updateCropsParameters = async (): Promise<void> => {
      setSelectedCrops(userSelectedCrops);
      const newParameters = AnnualPlanningUtility.createPlanningParameters(
        id,
        existingPlanningParameters,
        userSelectedCrops,
        customerFarms,
      );
      await mutatePlanningParameters.mutateAsync({
        customerId: id,
        newParameters,
      });
    };
    await updateCropsParameters();
  };

  const deletePlanningParameterForCrop = useCallback(async (crop: ApiCrop) => {
    const newPlanningParameters = _.cloneDeep(planningParametersCopy);
    const planningParametersToSave = (
      newPlanningParameters.filter((gpp) => !(
        gpp.cropType === crop.cropType
        && gpp.cropSubType === crop.subType
      ))
    );
    setSelectedCrops((oldSelectedCrops) => (
      oldSelectedCrops.filter((c) => !(
        c.cropType === crop.cropType
        && c.subType === crop.subType
      ))
    ));
    await mutatePlanningParameters.mutateAsync({
      customerId,
      newParameters: planningParametersToSave,
    });
  }, [mutatePlanningParameters, customerId, planningParametersCopy]);

  const onSubmit = async () => {
    await mutatePlanningParameters.mutateAsync({
      customerId,
      newParameters: planningParametersCopy,
    });
  };

  return (
    <>
      <ScrollView>
        <Filter
          accessoryRight={() => (
            <Button
              accessoryLeft={(addCropProps) => <Icon name="Plus" testID="add-crop-button-icon" {...addCropProps} />}
              onPress={() => setCropSelectorVisible(true)}
              size="medium"
              testID="add-crop-button"
            >
              {translate<string>('ADD_CROP')}
            </Button>
          )}
          defaultColumnFilters={[]}
          filterOptions={[]}
          noFilters
          onUpdateFilter={(filter) => setSearch(filter.search)}
          placeHolderText={translate('SEARCH_FIELDS_CROP_ZONES')}
          testID="planning-parameters-tab-filter"
          totalResults={(arePlanningParametersFetching || isCustomerFetching)
            ? undefined
            : totalResults}
          totalResultsText={translate('CROP_COUNT', { count: totalResults })}
        />
        <VSpacer size="5" />
        {(selectedCrops.length && !arePlanningParametersFetching) ? (
          selectedCrops.map((cropItem, index) => (
            <Fragment key={`${cropItem.cropType}|${cropItem.subType}`}>
              <CropCard
                crop={cropItem}
                customer={customer}
                farms={filteredFarms}
                index={index}
                onDelete={() => {
                  setCropToDelete(cropItem);
                  setDeleteCropConfirmation(true);
                }}
                planningParameters={planningParametersCopy}
                updatePlanningParameters={updatePlanningParameters}
              />
              {selectedCrops.length > 1 && <VSpacer size="5" />}
            </Fragment>
          ))
        ) : (
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <VSpacer size="9" />
            {(arePlanningParametersFetching || isCustomerFetching) ? (
              <CenteredSpinner testID="loading-spinner" />
            ) : (
              <Text category="p2" testID="no-data-message">
                {translate('NO_PLANNING_PARAMS_MSG') as string}
              </Text>
            )}
          </View>
        )}
      </ScrollView>
      <ButtonBar
        accessoryLeft={(backProps) => <Icon name="ArrowBack" testID="planning-parameter-button-icon" {...backProps} />}
        disableRightAction={!formChanged || mutatePlanningParameters.isLoading}
        hideRightButton={!formChanged}
        leftAction={() => history.goBack()}
        leftButtonText={translate('BACK')}
        rightAction={onSubmit}
        rightButtonText={translate('SAVE')}
        testID="planning-parameter-button"
      />
      <SidePanel
        header={translate<string>('ADD_CROPS')}
        onClose={() => setCropSelectorVisible(false)}
        testID="crops-side-panel"
        visible={cropSelectorVisible}
      >
        <CropSidePanel
          businessId={currentBusinessId}
          crops={selectedCrops}
          isLoading={mutatePlanningParameters.isLoading}
          onCancel={() => setCropSelectorVisible(false)}
          onSubmit={(crops: ApiCrop[]) => {
            const deletedCrops = getDeletedCrops(crops);
            setCropSelectorVisible(false);
            setCropsToUpdate(crops);
            if (deletedCrops.length) {
              setRemovedCrops(deletedCrops);
              setRemovedCropsConfirmationVisible(true);
            } else {
              updateCrops({
                farms,
                id: customerId,
                planningParameters: planningParametersCopy,
                userSelectedCrops: crops,
              });
            }
          }}
        />
      </SidePanel>
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('YES_DELETE')}
        disabled={mutatePlanningParameters.isLoading}
        messageText={translate(
          'CONFIRM_REMOVING_CROP_PLANNING_PARAM_MESSAGE',
          { crops: removedCrops?.map((c) => (
            `'${translate(c.cropType)} | ${translate(c.subType)}'`
          )).join(', ') },
        )}
        onCancel={() => setRemovedCropsConfirmationVisible(false)}
        onConfirm={async () => {
          const deletedCrops = getDeletedCrops(cropsToUpdate);
          await updateCrops({
            deletedCrops,
            farms,
            id: customerId,
            planningParameters: planningParametersCopy,
            userSelectedCrops: cropsToUpdate,
          }).then(() => {
            if (!arePlanningParametersFetching && !arePlanningParametersLoading) {
              setRemovedCropsConfirmationVisible(false);
            }
          });
        }}
        status="warning"
        testID="remove-crop-planning-parameter-confirmation-side-panel-modal"
        title={translate('CONFIRM_REMOVING_CROP_PLANNING_PARAM_TITLE')}
        visible={removedCropsConfirmationVisible}
      />
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('YES_DELETE')}
        disabled={mutatePlanningParameters.isLoading}
        messageText={translate(
          'CONFIRM_REMOVING_CROP_PLANNING_PARAM_MESSAGE',
          { crops: `${translate(cropToDelete?.cropType)} | ${translate(cropToDelete?.subType)}` },
        )}
        onCancel={() => setDeleteCropConfirmation(false)}
        onConfirm={async () => {
          await deletePlanningParameterForCrop(cropToDelete).then(() => {
            if (!arePlanningParametersFetching && !arePlanningParametersLoading) {
              setDeleteCropConfirmation(false);
            }
          });
        }}
        status="warning"
        testID="delete-crop-planning-parameter-confirmation-modal"
        title={translate('CONFIRM_REMOVING_CROP_PLANNING_PARAM_TITLE')}
        visible={deleteCropConfirmation}
      />
    </>
  );
};
