import { CheckBox, Input, VSpacer } from '@design';
import { ApiBusinessContact } from '@shared/interfaces/api';
import { validateEmail, validatePhoneNumber } from '@shared/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface BusinessContactFormProps {
  contact: ApiBusinessContact,
  onContactChange(contact: ApiBusinessContact): void,
}

export const BusinessContactForm = ({
  contact,
  onContactChange,
}: BusinessContactFormProps) => {
  const [translate] = useTranslation(['businesses', 'common']);
  const isRequired = [
    contact.contactName,
    contact.contactEmail,
  ].some((v) => !!v);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  return (
    <>
      <VSpacer size="8" />
      <Input
        isRequired={isRequired}
        label={translate<string>('NAME')}
        onChangeText={(contactName) => {
          onContactChange({ ...contact, contactName });
        }}
        testID="business-contact-name-field"
        value={contact.contactName}
      />
      <VSpacer size="8" />
      <Input
        caption={emailError ? translate<string>('VALID_EMAIL_REQUIRED') : undefined}
        isRequired={isRequired}
        keyboardType="email-address"
        label={translate<string>('CONTACT_EMAIL')}
        onBlur={() => setEmailError(
          !!contact.contactEmail && !validateEmail(contact.contactEmail),
        )}
        onChangeText={(contactEmail) => {
          onContactChange({ ...contact, contactEmail });
        }}
        status={emailError ? 'danger' : undefined}
        testID="business-contact-email-field"
        value={contact.contactEmail}
      />
      <VSpacer size="8" />
      <Input
        caption={phoneError ? translate<string>('VALID_PHONE_REQUIRED') : undefined}
        keyboardType="phone-pad"
        label={translate<string>('CONTACT_PHONE_NUMBER')}
        onBlur={() => setPhoneError(
          !!contact.contactTelephone && !validatePhoneNumber(contact.contactTelephone),
        )}
        onChangeText={(contactTelephone) => {
          onContactChange({ ...contact, contactTelephone });
        }}
        status={phoneError ? 'danger' : undefined}
        testID="business-contact-phone-field"
        value={contact.contactTelephone || ''}
      />
      <VSpacer size="8" />
      <CheckBox
        checked={contact.isDeliverableDefault}
        onChange={(checked) => onContactChange({ ...contact, isDeliverableDefault: checked })}
        testID="business-contact-deliverable-checkbox"
      >
        {translate('CONTACT_SEND_DELIVERABLES_TEXT')}
      </CheckBox>
    </>
  );
};
