import {
  Button,
  CheckBox,
  HSpacer,
  Icon,
  IconButton,
  Input, 
  Radio,
  Select,
  SelectItem,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { FormFieldType, FormFieldTypesWithOptions } from '@shared/enums';
import { ApiFormField, ApiFormFieldOption } from '@shared/interfaces/api';
import { IndexPath } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
export interface PropertyEditorProps {
  formField?: ApiFormField,
  addProperty: () => void,
  setProperty: (index: number, property: ApiFormField) => void,
}

const inputLabelOffset = 28.3;
const forbiddenCharacters = ['|'];

const styles = StyleSheet.create({
  emptyState: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 80, 
    paddingHorizontal: 48,
    paddingTop: 64,
  },
  largeIcon: {
    height: 48,
    width: 48,
  },
});

const otherOption = { id: null, other: true, value: 'Other' };
const defaultOption = { id: null, other: false, value: '' };
const defaultOptions = Array(3).fill(defaultOption);
const getDefaultOptionsForType = (fieldType) => {
  const formFieldTypes = FormFieldTypesWithOptions;
  return (formFieldTypes.includes(fieldType) ? defaultOptions : []);
};

export const PropertyEditor = ({
  addProperty,
  formField,
  setProperty,
}: PropertyEditorProps) => {
  const [translate] = useTranslation(['common', 'propertyGroups']);
  const [formFieldType, setFormFieldType] = useState(formField?.type ?? FormFieldType.ShortAnswer);
  const [options, setOptions] = useState<ApiFormFieldOption[]>([]);
  const [formFieldName, setFormFieldName] = useState(formField?.name ?? '');

  const formFieldTypes = Object.values(FormFieldType);
  const getItemIndicator = (index: number) => {
    if (formFieldType === FormFieldType.MultiSelect) {
      return <CheckBox disabled testID={`checkbox-indicator-${index}`} />;
    }
    if (formFieldType === FormFieldType.SingleSelect) {
      return <Radio disabled testID={`radio-indicator-${index}`} />;
    }
    if (formFieldType === FormFieldType.Dropdown) {
      return (
        <Text disabled testID={`dropdown-indicator-${index}`}>
          {index}.
        </Text>
      );
    }
  };

  useEffect(() => {
    setFormFieldName(formField?.name);
    setFormFieldType(formField?.type);
    setOptions(formField?.options ?? getDefaultOptionsForType(formField?.type));
  }, [formField]);

  const formFieldTypesWithOther = [FormFieldType.SingleSelect, FormFieldType.MultiSelect];
  const showAddOther = formFieldTypesWithOther.includes(formFieldType) && !options?.find((o) => o.other);

  const emptyState = (
    <View style={styles.emptyState}>
      <Icon
        name="List"
        status="primary"
        style={styles.largeIcon}
        testID="empty-state-icon"
      />
      <VSpacer size="5" />
      <Text category="h6">
        {translate('ADD_PROPERTY_CALL_TO_ACTION')}
      </Text>
      <VSpacer size="7" />
      <Button
        accessoryLeft={<Icon name="Plus" status="primary" testID="add-icon" />}
        appearance="outline"
        onPress={addProperty}
        testID="empty-state-button"
      >
        {translate('ADD_PROPERTY_TITLE')}
      </Button>
    </View>
  );

  const reorderOptions = (newOptions: ApiFormFieldOption[]) => {
    const optionsWithoutOther = newOptions.filter((o) => !o.other);
    const other = newOptions.find((option) => option.other);

    return other ? optionsWithoutOther.concat(other) : optionsWithoutOther;
  };

  const optionActions = (
    <View style={{ alignItems: 'center', flexDirection: 'row' }}>
      {options?.length && getItemIndicator(options.length + 1)}
      <HSpacer size="5" />
      <TextLink
        accessoryLeft={<Icon name="Plus" status="primary" testID="add-icon" />}
        appearance="primary"
        onPress={() => {
          const newOptions = [
            ...options,
            defaultOption,
          ];
          setOptions(reorderOptions(newOptions));
        }}
        testID="add-option"
      >
        {translate('ADD_OPTION')}
      </TextLink>
      {showAddOther && (
        <>
          <HSpacer size="5" />
          <Text disabled>{translate('OR')}</Text>
          <HSpacer size="5" />
          <TextLink
            accessoryLeft={<Icon name="Plus" status="primary" testID="add-icon" />}
            appearance="primary"
            onPress={() => setOptions([...options, otherOption])}
            testID="add-other-text-link"
          >
            {translate('ADD_OTHER')}
          </TextLink>
        </>
      )}
    </View>
  );

  const handleChangeOptionValue = (newValue: string, index: number) => {
    const newOptions = [...options];
    newOptions[index] = {
      ...newOptions[index],
      value: newValue,
    };
    setOptions(newOptions);
    setProperty(formField.order, {
      ...formField,
      name: formFieldName,
      type: formFieldType,
      options: newOptions,
    });
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
    setProperty(formField.order, {
      ...formField,
      name: formFieldName,
      type: formFieldType,
      options: newOptions,
    });
  };

  return (
    <View style={{ padding: 24 }}>
      {!formField ? emptyState : (
        <>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 4 }}>
              <Input
                isRequired
                label={translate('PROPERTY_LABEL')}
                maxLength={50}
                onChangeText={(name) => {
                  if (forbiddenCharacters.some((illegal) => name.includes(illegal))) {
                    return;
                  }
                  setFormFieldName(name);
                  setProperty(formField.order, {
                    ...formField,
                    name: name,
                    type: formFieldType,
                    options: formField.options ?? getDefaultOptionsForType(formFieldType),
                  });
                }}
                placeholder={translate<string>('UNTITLED_PROPERTY')}
                size="large"
                testID="property-name-input"
                value={formFieldName}
              />
            </View>
            <HSpacer size="5" />
            <View style={{ flex: 3 }}>
              <Select
                disabled={!!formField.id}
                label={translate('PROPERTY_TYPE')}
                onSelect={(indexPath) => {
                  const newType = formFieldTypes[(indexPath as IndexPath).row];
                  const ops = getDefaultOptionsForType(newType);
                  setOptions(ops);
                  setFormFieldType(newType);
                  setProperty(formField.order, {
                    ...formField,
                    name: formFieldName,
                    type: newType,
                    options: ops,
                  });
                }}
                size="large"
                testID="property-type-select"
                value={translate(formFieldType)}
              >
                {formFieldTypes.map((type, index) => (
                  <SelectItem 
                    key={type}
                    testID={`property-type-select-item-${index}`}
                    title={translate(type)}
                  />
                ))}
              </Select>
            </View>
          </View>
          {FormFieldTypesWithOptions.includes(formFieldType) && (
            <View>
              {options?.map((option, index) => (
                <View key={index}>
                  <VSpacer size="7" />
                  <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <View style={{ marginTop: inputLabelOffset }}>
                      {getItemIndicator(index + 1)}
                    </View>
                    <HSpacer size="5" />
                    <View style={{ flex: 1 }}>
                      <Input
                        disabled={option.other}
                        label={
                          option.other 
                            ? translate('OTHER') 
                            : `${translate('OPTION_TITLE')} ${index + 1}`
                        }
                        maxLength={50}
                        onChangeText={(newValue) => handleChangeOptionValue(newValue, index)}
                        testID={`option-input-${index}`}
                        value={option.value}
                      />
                    </View>
                    <HSpacer size="2" />
                    <View style={{ marginTop: inputLabelOffset }}>
                      <IconButton
                        appearance="ghost"
                        onPress={() => handleRemoveOption(index)}
                        status="basic"
                        testID={`option-close-button-${index}`}
                      >
                        Close
                      </IconButton>
                    </View>
                  </View>
                </View>
              ))}
              <VSpacer size="7" />
              {optionActions}
            </View>
          )}
        </>
      )}
    </View>
  );
};
