import React, { FunctionComponent, ReactElement } from 'react';
import { useWindowDimensions } from 'react-native';
import { WithHeader } from '../../elements/navigation/WithHeader';

interface Props {
  desktop?: ReactElement,
  mobile: ReactElement,
}

export const DeliverableResponsive: FunctionComponent<Props> = ({
  desktop,
  mobile,
}) => {
  const windowDimensions = useWindowDimensions();

  if (windowDimensions.width > 900 && desktop) {
    return (
      <WithHeader>
        {desktop}
      </WithHeader>
    );
  }

  return mobile;
};
