/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
/* eslint-disable max-len */
import React from 'react';
import {
  Animated,
  GestureResponderEvent,
  ImageProps,
  ListRenderItemInfo,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TargetedEvent,
  TextProps,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {
  ChildrenWithProps,
  FalsyFC,
  FalsyText,
  IndexPath,
  RenderProp,
  TouchableWeb,
  TouchableWebElement,
  TouchableWebProps,
  Overwrite,
  LiteralUnion,
  TouchableWithoutFeedback,
} from '@ui-kitten/components/devsupport';
import {
  Interaction,
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { List } from '@ui-kitten/components/ui/list/list.component';
import { Popover } from '@ui-kitten/components/ui/popover/popover.component';
import { ChevronDown } from '@ui-kitten/components/ui/shared/chevronDown.component';
import { SelectGroupProps } from '@ui-kitten/components/ui/select/selectGroup.component';
import {
  SelectItemDescriptor,
  SelectService,
} from '@ui-kitten/components/ui/select/select.service';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Status } from '@theme/variant-interfaces/Status';
import { InputSize } from '@theme/variant-interfaces/Size';
import { SelectItemElement, SelectItemProps } from './SelectItem';
import { Text } from '../Text/Text';

type SelectStyledProps = Overwrite<StyledComponentProps, {
  appearance?: LiteralUnion<'default'>;
}>;

export interface SelectProps extends TouchableWebProps, SelectStyledProps, WithTranslation {
  accessoryLeft?: RenderProp<Partial<ImageProps>>,
  accessoryRight?: RenderProp<Partial<ImageProps>>,
  caption?: RenderProp<TextProps> | React.ReactText,
  children?: ChildrenWithProps<SelectItemProps | SelectGroupProps>,
  disabled?: boolean,
  inputStyle?: StyleProp<ViewStyle>,
  isRequired?: boolean,
  keepCaptionSpace?: boolean,
  keepLabelSpace?: boolean,
  label?: RenderProp<TextProps> | React.ReactText,
  multiSelect?: boolean,
  onSelect?: (index: IndexPath | IndexPath[]) => void,
  placeholder?: RenderProp<TextProps> | React.ReactText,
  readonly?: boolean,
  readOnlyLabel?: boolean,
  selectedIndex?: IndexPath | IndexPath[],
  size?: InputSize,
  status?: Status,
  testID: string,
  value?: RenderProp<TextProps> | React.ReactText,
}

export type SelectElement = React.ReactElement<SelectProps>;

interface State {
  listVisible: boolean;
}

const CHEVRON_DEG_COLLAPSED: number = -180;
const CHEVRON_DEG_EXPANDED: number = 0;
const CHEVRON_ANIM_DURATION: number = 200;

/**
 * A dropdown menu for displaying selectable options.
 * Select should contain SelectItem or SelectGroup components to provide a useful component.
 *
 * @extends React.Component
 *
 * @method {() => void} show - Sets options list visible.
 *
 * @method {() => void} hide - Sets options list invisible.
 *
 * @method {() => void} focus - Focuses input field and sets options list visible.
 *
 * @method {() => void} blur - Removes focus from input field and sets options list invisible.
 *
 * @method {() => boolean} isFocused - Whether input field is currently focused and options list is visible.
 *
 * @method {() => void} clear - Removes all text from the Select.
 *
 * @property {ReactElement<SelectItemProps> | ReactElement<SelectItemProps>[]} children -
 * Items to be rendered within options list.
 *
 * @property {IndexPath | IndexPath[]} selectedIndex - Index or array of indices of selected options.
 * IndexPath `row: number, section?: number` - position of element in sectioned list.
 * Select becomes sectioned when SelectGroup is rendered within children.
 *
 * @property {(IndexPath | IndexPath[]) => void} onSelect - Called when option is pressed.
 * Called with `row: number` for non-grouped items.
 * Called with `row: number, section: number` for items rendered within group,
 * where row - index of item in group, section - index of group in list.
 * Called with array if *multiSelect* was set to true.
 *
 * @property {ReactText | ReactElement | (TextProps) => ReactElement} value - String, number or a function component
 * to render for the selected options.
 * By default, calls *toString()* for each index in selectedIndex property.
 * If it is a function, expected to return a Text.
 *
 * @property {boolean} multiSelect - Whether multiple options can be selected.
 * If true, calls onSelect with IndexPath[] in arguments.
 * Otherwise, with IndexPath in arguments.
 *
 * @property {ReactText | ReactElement | (TextProps) => ReactElement} placeholder - String, number or a function component
 * to render when there is no selected option.
 * If it is a function, expected to return a Text.
 *
 * @property {ReactText | ReactElement | (TextProps) => ReactElement} label - String, number or a function component
 * to render above input field.
 * If it is a function, expected to return a Text.
 *
 * @property {ReactText | ReactElement | (TextProps) => ReactElement} caption - String, number or a function component
 * to render below the input field.
 * If it is a function, expected to return a Text.
 *
 * @property {ReactElement | (ImageProps) => ReactElement} accessoryLeft - Function component
 * to render to start of the text.
 * Expected to return an Image.
 *
 * @property {ReactElement | (ImageProps) => ReactElement} accessoryRight - Function component
 * to render to end of the text.
 * Expected to return an Image.
 *
 * @property {string} status - Status of the component.
 * Can be `basic`, `primary`, `success`, `info`, `warning`, `danger` or `control`.
 * Defaults to *basic*.
 * Use *control* status when needed to display within a contrast container.
 *
 * @property {string} size - Size of the component.
 * Can be `small`, `medium` or `large`.
 * Defaults to *medium*.
 *
 * @property {() => void} onFocus - Called when options list becomes visible.
 *
 * @property {() => void} onBlur - Called when options list becomes invisible.
 *
 * @property {boolean} isRequired - denotes if the field is required.
 *
 * @property {TouchableOpacityProps} ...TouchableOpacityProps - Any props applied to TouchableOpacity component.
 *
 * @overview-example SelectSimpleUsage
 *
 * @overview-example SelectIndexType
 * Select works with special index object - IndexPath.
 * For non-grouped items in select, there is only a `row` property.
 * Otherwise, `row` is an index of option within the group, section - index of group in options list.
 * ```
 * interface IndexPath {
 *   row: number;
 *   section?: number;
 * }
 * ```
 *
 * @overview-example SelectMultiSelect
 * Multiple options can be selected if `multiSelect` property is configured.
 * For multiple options, `onSelect` function is called with array if indices.
 *
 * @overview-example SelectWithGroups
 * Options may be grouped within `SelectGroup` component.
 *
 * @overview-example SelectDisplayValue
 * By default, Select displays a value by building strings for selected indices.
 * For a real-word examples, a `value` property should be configured.
 *
 * @overview-example SelectStates
 * Select can be disabled with `disabled` property.
 *
 * @overview-example SelectDisabledOptions
 * Same for options.
 *
 * @overview-example SelectStatus
 * It also may be marked with `status` property, which is useful within forms validation.
 * An extra status is `control`, which is designed to be used on high-contrast backgrounds.
 *
 * @overview-example SelectAccessories
 * Select may contain labels, captions and inner views by configuring `accessoryLeft` or `accessoryRight` properties.
 * Within Eva, Select accessories are expected to be images or [svg icons](guides/icon-packages).
 *
 * @overview-example SelectSize
 * To resize Select, a `size` property may be used.
 *
 * @overview-example SelectStyling
 * Select and it's inner views can be styled by passing them as function components.
 * ```
 * import { Select, SelectItem, Text } from '@ui-kitten/components';
 *
 * <Select
 *   label={evaProps => <Text {...evaProps}>Label</Text>}
 *   caption={evaProps => <Text {...evaProps}>Caption</Text>}>
 *   <SelectItem title={evaProps => <Text {...evaProps}>Option 1</Text>} />
 * </Select>
 * ```
 *
 * @overview-example SelectTheming
 * In most cases this is redundant, if [custom theme is configured](guides/branding).
 */

@styled('Select')
class SelectRaw extends React.Component<SelectProps, State> {
  static defaultProps = {
    placeholder: 'Select Option',
    selectedIndex: [],
  };

  public state: State = {
    listVisible: false,
  };

  private service: SelectService = new SelectService();
  private popoverRef = React.createRef<Popover>();
  private expandAnimation: Animated.Value = new Animated.Value(0);

  componentDidMount (): void {
    window.addEventListener('resize', this.setOptionsListInvisible);
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.setOptionsListInvisible);
  }

  private get isMultiSelect (): boolean {
    return this.props.multiSelect;
  }

  private get data (): any[] {
    return React.Children.toArray(this.props.children || []);
  }

  private get selectedIndices (): IndexPath[] {
    if (!this.props.selectedIndex) {
      return [];
    }
    return Array.isArray(this.props.selectedIndex) ? this.props.selectedIndex : [this.props.selectedIndex];
  }

  private get expandToRotateInterpolation () {
    return this.expandAnimation.interpolate({
      inputRange: [CHEVRON_DEG_COLLAPSED, CHEVRON_DEG_EXPANDED],
      outputRange: [`${CHEVRON_DEG_COLLAPSED}deg`, `${CHEVRON_DEG_EXPANDED}deg`],
    });
  }

  public focus = (): void => {
    this.setOptionsListVisible();
  };

  public blur = (): void => {
    this.setOptionsListInvisible();
  };

  public isFocused = (): boolean => {
    return this.state.listVisible;
  };

  public clear = (): void => {
    this.props.onSelect && this.props.onSelect(null);
  };

  private onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    const interactions = [Interaction.HOVER];
    if (this.state.listVisible) {
      // based on how modals overlay the entire screen, it is impossible to both be active
      // and mouse enter, but this is here in case modals are modified in the future
      interactions.push(Interaction.ACTIVE);
    }
    this.props.eva.dispatch(interactions);
    this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  private onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    const interactions = [];
    if (this.state.listVisible) {
      // listVisible turning true triggers the mouse leave due to modal overlay entire screen
      // so here we need to retain the active status
      interactions.push(Interaction.ACTIVE);
    }
    this.props.eva.dispatch(interactions);
    this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  private onPress = (): void => {
    this.setOptionsListVisible();
  };

  private onPressIn = (event: GestureResponderEvent): void => {
    // there is no active state set here to match the functionality of input
    // if input is updated to go active on press in, then it can be activated here also
    this.props.onPressIn && this.props.onPressIn(event);
  };

  private onPressOut = (event: GestureResponderEvent): void => {
    // there is no revert of active state here for one of two scenarios
    // 1. press in, drag out = no press out, onMouseLeave would remove active state
    // 2. press in, press out = on press = open list = remain active
    this.props.onPressOut && this.props.onPressOut(event);
  };

  private onItemPress = (descriptor: SelectItemDescriptor): void => {
    if (this.props.onSelect) {
      const selectedIndices = this.service.selectItem(this.isMultiSelect, descriptor, this.selectedIndices);
      !this.isMultiSelect && this.setOptionsListInvisible();
      this.props.onSelect(selectedIndices);
    }
  };

  private onBackdropPress = (): void => {
    this.setOptionsListInvisible();
  };

  private onListVisible = (): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.createExpandAnimation(-CHEVRON_DEG_COLLAPSED).start(() => {
      this.props.onFocus && this.props.onFocus(null);
    });
  };

  private onListInvisible = (): void => {
    this.props.eva.dispatch([]);
    this.createExpandAnimation(CHEVRON_DEG_EXPANDED).start(() => {
      this.props.onBlur && this.props.onBlur(null);
    });
  };

  private getComponentStyle = (style: StyleType) => {
    const {
      popoverMaxHeight,
      popoverBorderRadius,
      popoverBorderColor,
      popoverBorderWidth,
      paddingHorizontal,
      elevation,
      ...inputParameters
    } = style;

    const captionStyles = PropsServiceHelper.allWithPrefixMapped(style, 'caption');
    const helpTextStyles = PropsServiceHelper.allWithPrefixMapped(style, 'helpText');
    const iconLeftStyles = PropsServiceHelper.allWithPrefixMapped(style, 'iconLeft');
    const iconRightStyles = PropsServiceHelper.allWithPrefixMapped(style, 'iconRight');
    const iconStyles = PropsServiceHelper.allWithPrefixMapped(style, 'icon');
    const labelStyles = PropsServiceHelper.allWithPrefixMapped(style, 'label');
    const placeholderStyles = PropsServiceHelper.allWithPrefixMapped(style, 'placeholder');
    const textStyles = PropsServiceHelper.allWithPrefixMapped(style, 'text');

    return {
      input: {
        paddingHorizontal,
        ...inputParameters,
      },
      text: textStyles,
      placeholder: {
        marginHorizontal: textStyles.marginHorizontal,
        ...placeholderStyles,
      },
      icon: iconStyles,
      iconLeft: iconLeftStyles,
      iconRight: iconRightStyles,
      label: {
        paddingHorizontal,
        ...labelStyles,
      },
      caption: {
        paddingHorizontal,
        ...captionStyles,
      },
      popover: {
        borderColor: popoverBorderColor,
        borderRadius: popoverBorderRadius,
        borderWidth: popoverBorderWidth,
        maxHeight: popoverMaxHeight,
      },
      elevation: {
        ...elevation,
      },
      helpTextStyles,
    };
  };

  private setOptionsListVisible = (): void => {
    const hasData: boolean = this.data.length > 0;
    hasData && this.setState({ listVisible: true }, this.onListVisible);
  };

  private setOptionsListInvisible = (): void => {
    this.setState({ listVisible: false }, this.onListInvisible);
  };

  private createExpandAnimation = (toValue: number): Animated.CompositeAnimation => {
    return Animated.timing(this.expandAnimation, {
      toValue,
      duration: CHEVRON_ANIM_DURATION,
      useNativeDriver: false,
    });
  };

  private cloneItemWithProps = (el: SelectItemElement, props: SelectItemProps): SelectItemElement => {
    const nestedElements = React.Children.map(el.props.children as SelectItemElement, (nestedEl: SelectItemElement, index: number) => {
      const descriptor = this.service.createDescriptorForNestedElement(nestedEl, props.descriptor, index);
      const selected: boolean = this.service.isSelected(descriptor, this.selectedIndices);

      return this.cloneItemWithProps(nestedEl, { ...props, descriptor, selected, disabled: false });
    });

    return React.cloneElement(el, { ...props, ...el.props }, nestedElements);
  };

  private renderItem = ({ item, index }: ListRenderItemInfo<SelectItemElement>): SelectItemElement => {
    const { testID } = this.props;
    const descriptor = this.service.createDescriptorForElement(item, this.isMultiSelect, index);
    const disabled: boolean = this.service.isDisabled(descriptor);
    const selected: boolean = this.service.isSelected(descriptor, this.selectedIndices);

    return this.cloneItemWithProps(item, {
      descriptor,
      selected,
      disabled,
      onPress: this.onItemPress,
      testID: `${testID}-${index}`,
    });
  };

  private renderDefaultIconElement = (evaStyle): React.ReactElement => {
    const {
      margin,
      marginBottom,
      marginHorizontal,
      marginLeft,
      marginRight,
      marginTop,
      tintColor,
      ...svgStyle
    } = evaStyle;

    // when icon margin is asymetric, this keeps the icon rotating around its center rather than its center offset by the margin difference
    const marginStyle = { margin, marginHorizontal, marginLeft, marginRight, marginTop, marginBottom };
    return (
      <View style={marginStyle}>
        <Animated.View style={{ transform: [{ rotate: this.expandToRotateInterpolation }] }}>
          <ChevronDown fill={tintColor} style={svgStyle} />
        </Animated.View>
      </View>
    );
  };

  private getValue = () => {
    const { value: propValue } = this.props;
    const value = propValue || this.service.toStringSelected(this.selectedIndices);

    return value;
  };

  private renderInputElement = (props: SelectProps, evaStyle: any): TouchableWebElement => {
    const {
      accessoryLeft,
      accessoryRight,
      disabled,
      inputStyle,
      placeholder,
      readonly,
      ...restProps
    } = props;

    const value = this.getValue();

    const textStyle: TextStyle = value && evaStyle.text;
    const interactive = !(disabled || readonly);

    const wrapper = interactive
      ? (
        <TouchableWeb
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onPress={this.onPress}
          onPressIn={this.onPressIn}
          onPressOut={this.onPressOut}
        />
      ) : (
        <View />
      );

    return (
      React.cloneElement(wrapper, {
        style: [
          styles.input,
          evaStyle.input,
          !interactive && { borderBottomWidth: 1, borderBottomColor: 'transparent' },
          inputStyle,
        ],
        ...restProps,
      }, (
        <>
          <FalsyFC
            component={accessoryLeft}
            style={[evaStyle.icon, evaStyle.iconLeft]}
          />
          <FalsyText
            component={value || placeholder}
            ellipsizeMode="tail"
            numberOfLines={1}
            style={[styles.text, evaStyle.placeholder, textStyle]}
          />
          <FalsyFC
            component={accessoryRight}
            fallback={this.renderDefaultIconElement(
              StyleSheet.flatten([evaStyle.icon, evaStyle.iconRight]),
            )}
            style={[evaStyle.icon, evaStyle.iconRight]}
          />
        </>
      ))
    );
  };

  private getLabel = () => {
    const {
      label,
      readonly,
      keepLabelSpace,
      readOnlyLabel = true,
      t,
    } = this.props;

    if (keepLabelSpace) {
      return (textProps) => <Text {...textProps}> </Text>;
    }

    if (readonly && readOnlyLabel) {
      return (textProps) => (
        <Text {...textProps}>
          {label}
          {` (${t('READ_ONLY')})`}
        </Text>
      );
    }

    return label;
  };

  private getHelpText = () => {
    const {
      t,
    } = this.props;

    return (textProps) => (
      <Text {...textProps}>
        {`${t('REQUIRED')}`}
      </Text>
    );
  };

  public render (): React.ReactElement<ViewProps> {
    const {
      eva,
      style,
      caption: propCaption,
      children,
      keepLabelSpace,
      keepCaptionSpace,
      isRequired = false,
      ...touchableProps
    } = this.props;

    const evaStyle = this.getComponentStyle(eva.style);

    const caption = keepCaptionSpace ? (textProps) => <Text {...textProps}> </Text> : propCaption;
    const interactive = !(touchableProps.disabled || touchableProps.readonly);

    const wrapper = interactive ? <TouchableWithoutFeedback testID={`${this.props.testID}-container`} />
      : (
        <div
          aria-disabled={touchableProps.disabled ? true : undefined}
          aria-readonly={touchableProps.readonly ? true : undefined}
          data-testid={`${this.props.testID}-container`}
        />
      );

    return (
      React.cloneElement(wrapper, {
        style,
        onPress: interactive && this.onPress,
        disabled: !interactive,
      }, (
        <>
          <View style={{ flexDirection: 'row' }}>
            <FalsyText
              category="p2"
              component={this.getLabel()}
              style={[styles.label, evaStyle.label, { flex: 1 }]}
            />
            {isRequired && (
              <FalsyText
                category="c1"
                component={this.getHelpText()}
                style={[evaStyle.helpTextStyles, { marginBottom: 8, paddingRight: 12 }]}
              />
            )}
          </View>
          <Popover
            anchor={() => this.renderInputElement(touchableProps, evaStyle)}
            contentContainerStyle={evaStyle.elevation}
            fullWidth
            onBackdropPress={this.onBackdropPress}
            ref={this.popoverRef}
            style={[styles.popover, evaStyle.popover]}
            visible={this.state.listVisible}
          >
            <List
              bounces={false}
              data={this.data}
              renderItem={this.renderItem}
              style={styles.list}
              testID={`${this.props.testID}-list`}
            />
          </Popover>
          <FalsyText
            category="c1"
            component={caption}
            style={[styles.caption, evaStyle.caption]}
          />
        </>
      ))
    );
  }
}

const styles = StyleSheet.create({
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popover: {
    overflow: 'hidden',
  },
  list: {
    flexGrow: 0,
  },
  text: {
    flex: 1,
    textAlign: 'left',
  },
  label: {
    textAlign: 'left',
  },
  caption: {
    textAlign: 'left',
  },
});

export const Select = withTranslation('common')(SelectRaw);
