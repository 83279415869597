import { ResponsiveView } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import React from 'react';
import { ScrollView } from 'react-native';
import {
  DeliverableProductOrder,
} from '../../../../src/pages/ProductOrder/components/ViewDeliverable/DeliverableProductOrder';
import { Footer } from '../../../../src/pages/ProductOrder/components/ViewDeliverable/Footer';
import { Environment, LinkingUtility } from '../../../../utilities';

export interface ProductOrderDeliverableDesktopViewProps {
  deliverable: ProductOrderDeliverableV0.DeliverablePublic,
}
export function ProductOrderDeliverableDesktopView (
  { deliverable }: ProductOrderDeliverableDesktopViewProps,
) {
  return (
    <ResponsiveView medium={12} style={{ maxWidth: 1440, height: '100%', alignSelf: 'center' }}>
      <ScrollView>
        <DeliverableProductOrder deliverable={deliverable} />
      </ScrollView>
      <Footer
        onDownloadPdf={() => LinkingUtility.openUrl(
          `${Environment.get('API_URL')}/growerDeliverables/public/${deliverable.publicId}/pdf`,
          { newTab: true },
        )}
      />
    </ResponsiveView>
  );
}
