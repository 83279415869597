import { Permissions, RoleUtility, userHasPermission } from '@shared/utils';
import { styled } from '@ui-kitten/components/theme';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Routes, Sizing } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { Modal, ModalUtility } from '../elements/content/Modal';
import { SidePanel, SidePanelUtility } from '../elements/content/SidePanel';
import { Toaster } from '../elements/content/Toaster';
import {
  BusinessSelectorBanner,
  BusinessSelectorModal,
} from '../elements/navigation/BusinessSelector';
import { GlobalHeader } from '../elements/navigation/GlobalHeader';
import { Sidebar } from '../elements/navigation/Sidebar';
import {
  BusinessFormPage,
  BusinessListPage,
  PrepareReviewPage,
  ReportsPage,
} from '../pages';
import { AccessDeniedPage } from '../pages/AccessDeniedPage';
import { AccessDeniedSuperPage } from '../pages/AccessDeniedSuperPage';
import { CustomerPlanningPage } from '../src/pages/CustomerPlanning/CustomerPlanningPage';
import { Help } from '../src/pages/Help/Help';
import { VerifyEmail } from '../pages/auth/VerifyEmail';
import { BusinessLocationFormPage } from '../pages/BusinessLocationFormPage';
import { DeliverableAuthPage } from '../pages/Deliverable/DeliverableAuthPage';
import { Redirect, Route, useHistory, useLocation } from '../router';
import { FeatureFlagList } from '../src/pages/Admin/FeatureFlags/FeatureFlagList';
import { BusinessDetails } from '../src/pages/BusinessDetails/BusinessDetails';
import { CustomerDetailsPage } from '../src/pages/CustomerPlanning';
import { FarmPlanFormPage } from '../src/pages/CustomerPlanning/FarmPlanTab/FarmPlanForm';
import {
  FarmPlanOverviewPage,
} from '../src/pages/CustomerPlanning/FarmPlanTab/FarmPlanForm/FarmPlanOverviewPage';
import { DashboardPage } from '../src/pages/Dashboard/DashboardPage';
import { ProgramDetailsPage } from '../src/pages/PrepareReview/ProgramTab/ProgramDetailsPage';
import { TankMixFormPage } from '../src/pages/PrepareReview/TankMixTab/TankMixForm';
import { ProductListPage } from '../src/pages/ProductList/ProductListPage';
import { UserList } from '../src/pages/User/UserList';
import { UXSandbox } from '../src/pages/ux-sandbox/UXSandbox';
import { SharedModals } from './SharedModals';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
    marginLeft: 68,
  },
  pageContent: {
    flex: 1,
    overflow: 'hidden',
    padding: Sizing.DOUBLE_SPACING,
  },
});

export interface ILoggedInRoute {
  url: string | string[],
  Component: (params?: any) => JSX.Element,
  requirePermission?: Permissions,
  requiredSuperBusinessSelect?: boolean,
}

const LoggedInViewRaw = ({ eva }) => {
  const location: Location = useLocation();
  const history = useHistory();
  const {
    user,
    currentBusinessId,
    logout,
  } = useAuthentication();
  const [showBusinessSelectModal, setShowBusinessSelectModal] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [sidePanelHeader, setSidePanelHeader] = useState(null);
  const [sidePanelContent, setSidePanelContent] = useState(null);

  // Checks if the current location (the url bar) matches the history 'match' prop
  // when a user is logged in. If they do not match we update the location
  useEffect(() => {
    if (location.pathname.match('auth/login')) {
      history.replace(Routes.LOGGED_IN);
    }
  }, [history, location.pathname]);

  // Tie singleton utilities to this element
  useEffect(() => {
    SidePanelUtility.setShow((content: JSX.Element, header?: any) => {
      setSidePanelContent(content);
      setSidePanelHeader(header);
      setShowSidePanel(true);
    });
    ModalUtility.setShow((content: JSX.Element) => {
      setModalContent(content);
      setShowModal(true);
    });
    ModalUtility.setHide(() => {
      setShowModal(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * List of Routes:
   * url: the url to apply the logic to
   * name: What to call the breadcrumb when it's not the main crumb
   * title: The 'bold' main name on the left side of the breadcrumb
   * Component: the component to be rendered to the url
   * permissions: List of permissions that are required to access this route
   */
  const routes: ILoggedInRoute[] = [
    {
      url: Routes.ACCESS_DENIED,
      Component: AccessDeniedPage,
    },
    {
      url: Routes.HELP,
      Component: Help,
    },
    {
      url: Routes.BUSINESS_LIST,
      Component: BusinessListPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.BUSINESS_DETAILS,
      Component: BusinessDetails,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.USER_BUSINESS_CREATE,
      Component: BusinessFormPage,
      requirePermission: Permissions.ACCESS_ALL_BUSINESSES,
    },
    {
      url: Routes.BUSINESS_EDIT,
      Component: BusinessFormPage,
      requirePermission: Permissions.ACCESS_ALL_BUSINESSES,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.BUSINESS_CREATE_LOCATION,
      Component: BusinessLocationFormPage,
      requirePermission: Permissions.MODIFY_BUSINESS_OBJECTS,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.BUSINESS_EDIT_LOCATION,
      Component: BusinessLocationFormPage,
      requirePermission: Permissions.MODIFY_BUSINESS_OBJECTS,
      requiredSuperBusinessSelect: true,
    },
    {
      url: [
        Routes.PROGRAM_CREATE,
        Routes.PROGRAM_EDIT,
      ],
      Component: ProgramDetailsPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.DASHBOARD,
      Component: DashboardPage,
    },
    {
      url: [
        Routes.TANK_MIX_CREATE,
        Routes.TANK_MIX_VIEW,
        Routes.TANK_MIX_EDIT,
      ],
      Component: TankMixFormPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.PREPARE_REVIEW,
      Component: PrepareReviewPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: [
        Routes.FARM_PLAN_CREATE,
        Routes.FARM_PLAN_CREATE_FROM_GROWER,
        Routes.FARM_PLAN_EDIT,
        Routes.FARM_PLAN_EDIT_FROM_GROWER,
      ],
      Component: FarmPlanFormPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.FARM_PLAN_OVERVIEW,
      Component: FarmPlanOverviewPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.GROWER_DELIVERABLE,
      Component: DeliverableAuthPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.CUSTOMER_PLANNING.replace(/:tab\?/, 'customers'),
      Component: CustomerPlanningPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.GROWER_DETAILS,
      Component: CustomerDetailsPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.PRODUCT_LIST,
      Component: ProductListPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.USER_LIST,
      Component: UserList,
    },
    {
      url: Routes.VERIFY_EMAIL,
      Component: VerifyEmail,
    },
    {
      url: Routes.REPORTS,
      Component: ReportsPage,
      requiredSuperBusinessSelect: true,
    },
    {
      url: Routes.UX_SANDBOX,
      Component: UXSandbox,
    },
    {
      url: Routes.FEATURE_FLAGS,
      Component: FeatureFlagList,
    },
  ];

  const renderedRoutes = routes.map(({
    url,
    Component,
    requirePermission,
    requiredSuperBusinessSelect,
  }) => {
    let RenderedComponent = Component;
    /**
     * requirePermission returns a number from the enum
     * Since it is sometimes undefined a check is needed
     */
    if (requirePermission !== undefined
      && !RoleUtility.roleHasPermission(user?.userRole, requirePermission)
    ) {
      RenderedComponent = AccessDeniedPage;
    }

    /** *
     * Super user did not select a business which is required for this page
     * Display a error landing page to warn the user to select a business.
     */
    if (requiredSuperBusinessSelect && !currentBusinessId) {
      RenderedComponent = AccessDeniedSuperPage;
    }

    return (
      <Route
        exact
        key={url instanceof Array ? url.join('') : url}
        path={url}
        render={(props) => (
          <RenderedComponent {...props} />
        )}
      />
    );
  });

  return (
    <>
      <BusinessSelectorBanner showModal={() => setShowBusinessSelectModal(true)} />
      <View nativeID="logged-in-view" style={[eva.style, styles.container]}>
        <View style={[eva.style, styles.container]}>
          <Sidebar />
          <View style={styles.wrapper}>
            <GlobalHeader logout={logout} user={user} />
            <KeyboardAwareScrollView
              contentContainerStyle={styles.pageContent}
              extraHeight={-Sizing.HEADER_HEIGHT}
              resetScrollToCoords={{
                x: 0,
                y: 0,
              }}
              scrollEnabled={false}
            >
              <Route exact path={Routes.LOGGED_IN}>
                {userHasPermission(user, Permissions.ACCESS_ALL_BUSINESSES)
                  ? <Redirect to={Routes.USER_LIST} />
                  : <Redirect to={Routes.PREPARE_REVIEW.replace(/:tab/, 'tank-mixes')} />}
              </Route>
              {renderedRoutes}

            </KeyboardAwareScrollView>
          </View>
          <Toaster />
          {showSidePanel && (
            <SidePanel
              afterHide={setShowSidePanel}
              content={sidePanelContent}
              header={sidePanelHeader}
            />
          )}
          <Modal content={modalContent} visible={showModal} />
          <BusinessSelectorModal
            hide={() => { setShowBusinessSelectModal(false); }}
            visible={showBusinessSelectModal}
          />
          <SharedModals />
        </View>
      </View>
    </>
  );
};

export const LoggedInView = styled('App')(LoggedInViewRaw);
