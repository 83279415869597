import { ApiDeliverable, ApiDeliverablePublic } from '@shared/interfaces/api';
import { DeliverableRenderer } from '../DeliverableRenderer';
import { ProductOrderDeliverableV0Renderer } from './v0/ProductOrderDeliverableV0Renderer';

export class ProductOrderDeliverableRenderFactory {
  static getRenderer = (deliverable: ApiDeliverable | ApiDeliverablePublic):
    DeliverableRenderer => (
    new ProductOrderDeliverableV0Renderer(deliverable)
  );
}
