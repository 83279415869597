/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ChipStatus } from '@theme/variant-interfaces/Status';
import React from 'react';
import {
  GestureResponderEvent,
  NativeSyntheticEvent,
  StyleSheet,
  TargetedEvent,
  ImageProps,
  Text,
} from 'react-native';
import {
  FalsyFC,
  RenderProp,
  TouchableWeb,
  TouchableWebProps,
  Overwrite,
  PropsService,
} from '@ui-kitten/components/devsupport';
import {
  Interaction,
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { Icon } from '../Icon/Icon';

export type Appearance = 'filled' | 'outline';

export const Appearances: Appearance[] = [
  'filled', 'outline',
];

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type ChipStyledProps = Overwrite<StyledComponentProps, {}>;

export interface ChipProps extends TouchableWebProps, ChipStyledProps {
  accessoryLeft?: RenderProp<Partial<ImageProps>>,
  accessoryRight?: RenderProp<Partial<ImageProps>>,
  appearance?: Appearance,
  status?: ChipStatus,
  testID: string,
}

type ChipElement = React.ReactElement<ChipProps>;

@styled('Chip')
export class Chip extends React.Component<ChipProps> {
  private onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([Interaction.HOVER]);
    this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  private onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([]);
    this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  private onFocus = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([Interaction.FOCUSED]);
    this.props.onFocus && this.props.onFocus(event);
  };

  private onBlur = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([]);
    this.props.onBlur && this.props.onBlur(event);
  };

  private onPressIn = (event: GestureResponderEvent): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.props.onPressIn && this.props.onPressIn(event);
  };

  private onPressOut = (event: GestureResponderEvent): void => {
    if (this.props.disabled) return;

    this.props.eva.dispatch([]);
    this.props.onPressOut && this.props.onPressOut(event);
  };

  private getComponentStyle = (style: StyleType) => {
    const textStyles = PropsService.allWithPrefix(style, 'text');
    const iconStyles = PropsService.allWithPrefix(style, 'icon');
    const {
      backgroundColor,
      borderColor,
      borderRadius,
      borderWidth,
      marginLeft,
      marginRight,
      minHeight,
    } = style;

    return {
      container: {
        backgroundColor,
        borderColor,
        borderRadius,
        borderWidth,
        minHeight,
        marginLeft: marginLeft ?? 6,
        marginRight: marginRight ?? 6,
      },
      text: {
        color: textStyles.textColor,
        fontFamily: textStyles.textFontFamily,
        fontSize: textStyles.textFontSize,
        fontWeight: textStyles.textFontWeight,
        letterSpacing: textStyles.textLetterSpacing,
        lineHeight: textStyles.textLineHeight,
        marginHorizontal: textStyles.textMarginHorizontal,
      },
      iconRight: {
        tintColor: iconStyles.iconTintColor,
        marginRight: iconStyles.iconOutsideMargin,
        marginLeft: iconStyles.iconInsideMargin,
      },
      iconLeft: {
        tintColor: iconStyles.iconTintColor,
        marginRight: iconStyles.iconInsideMargin,
        marginLeft: iconStyles.iconOutsideMargin,
      },
    };
  };

  public render (): ChipElement {
    const {
      eva,
      style,
      accessoryLeft,
      accessoryRight,
      children,
      testID,
      ...touchableProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <TouchableWeb
        {...touchableProps}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onPressIn={this.onPressIn}
        onPressOut={this.onPressOut}
        style={[evaStyle.container, styles.container, style]}
        testID={testID}
      >
        <FalsyFC
          component={accessoryLeft}
          style={evaStyle.iconLeft}
        />
        <Text
          style={evaStyle.text}
          testID={`${testID}-label`}
        >
          {children ?? ' '}
        </Text>
        <FalsyFC
          component={accessoryRight}
          fallback={<Icon name="Close" style={evaStyle.iconRight} testID={`${testID}-close-icon`} />}
          style={evaStyle.iconRight}
        />
      </TouchableWeb>
    );
  }
}
