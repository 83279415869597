import React from 'react';
import Svg, { G, Mask, Path, SvgProps } from 'react-native-svg';

export const SwapUpDownOutline = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path clipRule="evenodd" d="M8.00038 6.99998L8.00038 20C8.00038 20.552 7.55338 21 7.00038 21C6.44738 21 6.00038 20.552 6.00038 20L6.00038 6.91798L4.79038 8.47598C4.45138 8.91198 3.82238 8.99098 3.38738 8.65198C2.95038 8.31298 2.87138 7.68398 3.21038 7.24898L6.21038 3.38698C6.40138 3.13998 6.69638 2.99798 7.00938 2.99998C7.32038 3.00198 7.61338 3.14998 7.80038 3.39998L10.8004 7.39998C10.9354 7.57998 11.0004 7.79098 11.0004 7.99898C11.0004 8.30298 10.8624 8.60398 10.6004 8.79998C10.1584 9.13098 9.53138 9.04198 9.20038 8.59998L8.00038 6.99998ZM15.0004 17L15.0004 3.99998C15.0004 3.44798 15.4474 2.99998 16.0004 2.99998C16.5534 2.99998 17.0004 3.44798 17.0004 3.99998L17.0004 17.082L18.2104 15.524C18.5494 15.088 19.1784 15.009 19.6134 15.348C19.8674 15.545 20.0004 15.84 20.0004 16.138C20.0004 16.353 19.9324 16.569 19.7904 16.751L16.7904 20.613C16.5994 20.86 16.3044 21.002 15.9914 21C15.6804 20.998 15.3874 20.85 15.2004 20.6L12.2004 16.6C11.8694 16.157 11.9584 15.531 12.4004 15.2C12.8424 14.869 13.4694 14.958 13.8004 15.4L15.0004 17Z" fillRule="evenodd" />
    <Mask height="18" id="mask0" mask-type="alpha" mask-units="userSpaceOnUse" width="18" x="3" y="3">
      <Path clipRule="evenodd" d="M8.00038 6.99998L8.00038 20C8.00038 20.552 7.55338 21 7.00038 21C6.44738 21 6.00038 20.552 6.00038 20L6.00038 6.91798L4.79038 8.47598C4.45138 8.91198 3.82238 8.99098 3.38738 8.65198C2.95038 8.31298 2.87138 7.68398 3.21038 7.24898L6.21038 3.38698C6.40138 3.13998 6.69638 2.99798 7.00938 2.99998C7.32038 3.00198 7.61338 3.14998 7.80038 3.39998L10.8004 7.39998C10.9354 7.57998 11.0004 7.79098 11.0004 7.99898C11.0004 8.30298 10.8624 8.60398 10.6004 8.79998C10.1584 9.13098 9.53138 9.04198 9.20038 8.59998L8.00038 6.99998ZM15.0004 17L15.0004 3.99998C15.0004 3.44798 15.4474 2.99998 16.0004 2.99998C16.5534 2.99998 17.0004 3.44798 17.0004 3.99998L17.0004 17.082L18.2104 15.524C18.5494 15.088 19.1784 15.009 19.6134 15.348C19.8674 15.545 20.0004 15.84 20.0004 16.138C20.0004 16.353 19.9324 16.569 19.7904 16.751L16.7904 20.613C16.5994 20.86 16.3044 21.002 15.9914 21C15.6804 20.998 15.3874 20.85 15.2004 20.6L12.2004 16.6C11.8694 16.157 11.9584 15.531 12.4004 15.2C12.8424 14.869 13.4694 14.958 13.8004 15.4L15.0004 17Z" fillRule="evenodd" />
    </Mask>
    <G mask="url(#mask0)" />
  </Svg>
);
