import React from 'react';
import { Animated, GestureResponderEvent, StyleSheet, View } from 'react-native';
import {
  EvaProp,
  Layout,
  Modal as UIKModal,
  ModalProps as UIKModalProps,
  styled,
  StyleType,
} from '@ui-kitten/components';
import { FalsyFC, FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { ButtonProps } from '../Button/Button';
import { SpacerProps } from '../Spacer/HSpacer';
import { Icon, IconProps } from '../Icon/Icon';
import { WindowBlur } from '../SimpleLayout/WindowBlur';

interface DialogProps extends UIKModalProps {
  eva?: EvaProp,
  visible: boolean,
  width?: number,
  height?: number,
  title?: string,
  status?: 'danger' | 'warning' | 'info',
  icon?: RenderProp<{ iconProp: Partial<IconProps> }>
  footerStyle?: StyleType;
  backdropStyle?: StyleType,
  footerAccessory: RenderProp<{
    primaryButtonProp: Partial<ButtonProps>,
    secondaryButtonProp: Partial<ButtonProps>,
    spacerProp: Partial<SpacerProps>
  }>,
  onClose?: (event: GestureResponderEvent) => void,
  blurIntensity?: number,
  blurTint?: string,
  testID: string,
}

interface DialogPropsState {
  endAnimation: boolean,
  modalAnimation: Animated.Value,
}

const styles = StyleSheet.create({
  footer: {
    justifyContent: 'flex-end',
  },
  icon: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginRight: 12,
  },
});

@styled('Dialog')
export class Dialog extends React.Component<DialogProps, DialogPropsState> {
  mounted = false;

  constructor (props) {
    super(props);
    this.state = {
      endAnimation: false,
      modalAnimation: new Animated.Value(0),
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentDidUpdate (prevProps) {
    /** Restart the animation if the dialog modal is closed as it does not get unmounted */
    if (prevProps.visible === false && this.props.visible === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ endAnimation: false, modalAnimation: new Animated.Value(0) });
    }

    /** Start animation that updates the opacity to create a fade in animation */
    if (this.props.visible && !this.state.endAnimation) {
      this.animateModal();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  animateModal = () => {
    Animated.timing(this.state.modalAnimation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start(() => {
      if (this.mounted) {
        this.setState({ endAnimation: true });
      }
    });
  };

  private getComponentStyle = (source: StyleType) => {
    const titleStyles = PropsServiceHelper.allWithPrefixMapped(source, 'title');
    const iconStyles = PropsServiceHelper.allWithPrefixMapped(source, 'icon');
    const footerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'footer');
    const buttonStyles = PropsServiceHelper.allWithPrefixMapped(source, 'button');
    const backdropStyles = PropsServiceHelper.allWithPrefixMapped(source, 'backdrop');
    const headerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'header');
    const bodyStyles = PropsServiceHelper.allWithPrefixMapped(source, 'modalBody');
    const surfaceStyles = PropsServiceHelper.allWithPrefixMapped(source, 'surface');
    const actionStyles = PropsServiceHelper.allWithPrefixMapped(source, 'action');

    return {
      action: actionStyles,
      title: titleStyles,
      icon: iconStyles,
      footer: footerStyles,
      button: buttonStyles,
      backdrop: backdropStyles,
      header: headerStyles,
      body: bodyStyles,
      surface: {
        ...surfaceStyles,
        ...source.elevation,
      },
    };
  };

  public render () {
    const {
      eva,
      style,
      children,
      visible,
      width = 480,
      height,
      status,
      title,
      icon,
      backdropStyle,
      footerStyle,
      footerAccessory,
      onClose = () => {},
      blurIntensity: propBlurIntensity,
      blurTint: propBlurTint,
      testID,
      ...otherProps
    } = this.props;

    const evaStyle = this.getComponentStyle(eva.style);
    const animateBlurEffect = {
      opacity: this.mounted ? this.state.modalAnimation : 1,
    };
    const { blurTint, webBlurIntensity } = eva.style;

    const blurIntensity = webBlurIntensity;

    return (
      <UIKModal
        backdropStyle={[evaStyle.backdrop, backdropStyle]}
        testID={testID}
        visible={visible}
        {...otherProps}
      >
        {/* note that this method results in a BlurView filling the modal and
          * covering the backdrop.  The backdrop will no longer be clickable
          * and onBackdrop press will do nothing.  If you need an onBackdropPress
          * for dialog, consider copying it out of otherProps and nesting a
          * TouchableWeb with the onPress inside the WindowBlur.
          */}
        <WindowBlur
          intensity={propBlurIntensity || blurIntensity}
          style={{
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
          tint={propBlurTint || blurTint}
        >
          <Animated.View style={animateBlurEffect}>
            <Layout style={[evaStyle.surface, { width, height }]}>
              {title && (
                <View style={evaStyle.header}>
                  {status && (
                    <View style={styles.icon}>
                      <Icon
                        name={status === 'warning' ? 'AlertTriangle' : 'AlertCircle'}
                        size="large"
                        status={status}
                        testID={`${testID}-status-icon`}
                      />
                    </View>
                  )}
                  <FalsyText component={title} style={[evaStyle.title, { flex: 1 }]} testID={`${testID}-title`} />
                </View>
              )}
              {!!children && (
                <View style={[evaStyle.body, !title && { paddingTop: 0 }]} testID={`${testID}-body`}>
                  {children}
                </View>
              )}
              <View style={[evaStyle.footer, styles.footer, footerStyle]}>
                <FalsyFC
                  component={footerAccessory as any}
                  primaryButtonProp={{
                    status: 'basic',
                    size: 'small',
                    appearance: 'outline',
                    onPress: (e) => onClose(e),
                  }}
                  secondaryButtonProp={{ status, size: 'small', appearance: 'outline' }}
                  spacerProp={{ size: '5' }}
                  testID={`${testID}-footer`}
                />
              </View>
            </Layout>
          </Animated.View>
        </WindowBlur>
      </UIKModal>
    );
  }
}
