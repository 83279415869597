import { Input, useToast } from '@design';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { TankMixApi } from '../../../../../utilities/api';

export interface TankMixNameInputProps {
  name: string;
  onUpdateName(name: string): void;
  onUpdateValidate(valid: boolean): void;
  readonly?: boolean,
  tankMixId?: string;
}
export function TankMixNameInput ({
  name,
  onUpdateName,
  onUpdateValidate,
  readonly,
  tankMixId,
}: TankMixNameInputProps) {
  const [translate] = useTranslation(['prepare', 'tankMix', 'common']);
  const { createToast } = useToast();
  const { currentBusinessId } = useAuthentication();
  const [nameInput, setNameInput] = useState(name);
  const [error, setError] = useState('');

  const handleChangeText = async () => {
    if (!nameInput) {
      onUpdateName(nameInput);
      onUpdateValidate(true);
      setError('');
      return;
    }

    try {
      const { data } = await TankMixApi.listTankMix({
        businessId: currentBusinessId,
        name: nameInput.trim(),
      });

      const [tankMix] = data;
      const isValid = !tankMix || tankMix.id === tankMixId;
      onUpdateName(nameInput);
      onUpdateValidate(isValid);
      setError(!isValid ? translate<string>('PRODUCT_MIX_ALREADY_EXISTS', { name: nameInput }) : '');
    } catch {
      onUpdateName(nameInput);
      onUpdateValidate(false);
      createToast({
        children: translate<string>('UNEXPECTED_ERROR'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
  };

  return (
    <Input
      caption={error}
      isRequired
      label={translate<string>('PRODUCT_MIX_NAME')}
      onBlur={handleChangeText}
      onChangeText={setNameInput}
      readonly={readonly}
      status={!error ? 'basic' : 'danger'}
      testID="tank-mix-name-field"
      value={nameInput}
    />
  );
}
