import { Button, HSpacer, Icon, Modal, Text, TextLink } from '@design';
import { NumericInput } from '@design/Input/NumericInput';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';

const styles = StyleService.create({
  title: {
    color: 'text-hint-color',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  input: {
    marginBottom: 25,
    width: 218,
  },
  description: {
    marginBottom: 25,
  },
  footer: {
    justifyContent: 'space-between',
    paddingLeft: 0,
    flexDirection: 'row',
    display: 'flex',
  },
});

interface AcreageButtonProps {
  acreage: number;
  onAcreageChange: (acreage: number) => void;
}

export const AcreageButton: FC<AcreageButtonProps> = ({
  onAcreageChange,
  acreage,
}) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const [show, setShow] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(acreage);
  const themedStyles = useStyleSheet(styles);

  useEffect(() => {
    setCurrentValue(acreage);
    setOnEdit(false);
  }, [acreage, show]);

  const saveAcreage = () => {
    if (confirmEdit) {
      setOnEdit(true);
      setConfirmEdit(false);
    } else {
      setShow(false);
      onAcreageChange(currentValue);
    }
  };

  const getButtonText = (): string => {
    if (confirmEdit) {
      return translate('CONFIRM_EDIT_ACREAGE');
    }
    if (onEdit) {
      return translate('SAVE_CHANGES');
    }
    return translate('ADD_ACREAGE');
  };

  const getTitle = (): string => {
    if (confirmEdit) {
      return translate('EDIT_ACREAGE_DIALOG_TITLE');
    }
    return translate(onEdit ? 'EDIT_ORDER_ACREAGE' : 'ADD_ORDER_ACREAGE');
  };

  return (
    <>
      <Modal
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            {onEdit && (
              <Button
                {...primaryButtonProp}
                accessoryLeft={(props) => <Icon name="Trash" testID="button-remove-acreage-icon" {...props} />}
                onPress={() => {
                  onAcreageChange(null);
                  setShow(false);
                  setConfirmEdit(false);
                  setOnEdit(false);
                }}
                testID="button-remove-acreage"
              >
                {translate<string>('REMOVE_ACREAGE')}
              </Button>
            )}
            <View style={themedStyles.footer}>
              <Button
                {...primaryButtonProp}
                onPress={() => setShow(false)}
                testID="button-cancel-add-acreage"
              >
                {translate<string>('CANCEL')}
              </Button>
              <HSpacer {...spacerProp} />
              <Button
                {...secondaryButtonProp}
                appearance={confirmEdit ? 'outline' : 'filled'}
                disabled={Number.isNaN(currentValue) || currentValue < 0 || !currentValue}
                onPress={saveAcreage}
                testID="button-save-add-acreage"
              >
                {getButtonText()}
              </Button>
            </View>
          </>
        )}
        footerStyle={[
          themedStyles.footer,
          { justifyContent: onEdit ? 'space-between' : 'flex-end' },
        ]}
        hideCloseButton
        onClose={() => setShow(false)}
        testID="modal-add-acreage"
        title={getTitle()}
        visible={show}
      >
        <Text category="p2" style={themedStyles.description}>
          {confirmEdit
            ? (translate<string>('EDIT_ACREAGE_DIALOG_DESCRIPTION'))
            : (translate<string>('ACREAGE_ADD_DESCRIPTION'))}
        </Text>
        {!confirmEdit && (
          <NumericInput
            label={translate<string>('ACRES_LABEL')}
            minValue={0}
            onChangeValue={setCurrentValue}
            precision={3}
            style={themedStyles.input}
            testID="input-add-acreage"
            value={currentValue}
          />
        )}
      </Modal>
      {acreage > 0 ? (
        <View style={themedStyles.textContainer} testID="value-add-acreag">
          <Text category="p1" style={themedStyles.title}>
            {`${translate('ORDER_ACREAGE')}: `}
          </Text>
          <Text category="p1" status="basic" testID="acreage-value">
            {StringUtility.localizeNumber(acreage, 3)}
          </Text>
          <TextLink
            accessoryRight={(props) => <Icon name="Edit" testID="edit-acreage" {...props} />}
            appearance="secondary"
            category="h6"
            onPress={() => {
              setShow(true);
              setConfirmEdit(true);
            }}
            testID="button-edit-add-acreage"
          />
        </View>
      ) : (
        <Button
          appearance="outline"
          onPress={() => {
            setShow(true);
          }}
          size="small"
          testID="button-open-add-acreage"
        >
          {translate<string>('ADD_ORDER_ACREAGE')}
        </Button>
      )}
    </>
  );
};
