import { VSpacer } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { sortBy } from '@shared/utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import ActionsHeader from '../../../../components/shared/ActionsHeader/ActionsHeader';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import ProductCard from './ProductCard';
import ProductMixCard from './ProductMixCard';

export interface ProductListProps {
  deliverable: ProductOrderDeliverableV0.DeliverablePublic,
}
export const ProductList: FC<ProductListProps> = ({ deliverable }) => {
  const [translate] = useTranslation(['productOrders', 'common']);

  const { overview, products, productMixes } = deliverable.deliverableData;
  const { acreage } = deliverable.selections.basicInfo;
  const showAcreage = acreage && !!(overview.acreage);

  const combinedComponents: { 
    product?: ProductOrderDeliverableV0.ProductSummary,
    productMix?: ProductOrderDeliverableV0.ProductMixSummary, 
  }[] = [
    ...(products || []).map(p => (
      { product: p }
    )), 
    ...(productMixes || []).map(p => (
      { productMix: p }
    )),
  ];

  return (
    <View>
      <ActionsHeader
        buttonAccessory={showAcreage && (
          <DescriptionListItem
            label={translate<string>('ACREAGE')}
            testID="product-list-acreage"
            text={StringUtility.localizeNumber(overview.acreage, 3)}
          />
        )}
        header={translate('PRODUCTS_WITH_COUNT', { count: (products?.length ?? 0) + (productMixes?.length ?? 0) })}
        testID="product-list-count-header"
      />
      <VSpacer size="5" />
      {sortBy(combinedComponents, (component) => (
        component?.product?.name ||
        component?.productMix?.name || ''
      ).toLowerCase()).map((component, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={index}>
          {!!component.product && (
            <ProductCard
              index={index}
              product={component.product }
              selections={deliverable.selections.products}
            />
          )}
          {!!component.productMix && (
            <ProductMixCard
              index={index}
              productMix={component.productMix}
            />
          )}
          <VSpacer size="5" />
        </View>
      ))}
    </View>
  );
};
