import { DeliverableType } from '@shared/enums';
import {
  ApiDeliverable,
  ApiProductOrder,
  ApiProductOrderDuplicate,
  ApiProductOrderFiltersQuery,
  ApiProductOrderFiltersResponse,
  ApiProductOrderListQuery,
  ApiProductOrderListResponse,
  PaginatedData, ProductOrderEndpoint,
} from '@shared/interfaces/api';
import {
  ProductOrderDeliverableV0,
} from '@shared/interfaces/GrowerDeliverable/ProductOrderDeliverable/v0/ProductOrderDeliverableV0';
import qs from 'qs';
import { Client } from './Client';

export class ProductOrderApi {
  // https://growers.stoplight.io/docs/rally-api/b3A6MzUzMDMzMDc-get-product-orders
  static getProductOrder (id: string): Promise<ApiProductOrder> {
    return Client(`product-orders/${id}`);
  }

  // https://growers.stoplight.io/docs/rally-api/b3A6MzUyNTg2ODA-create-product-orders
  static createProductOrder (
    productOrder: ProductOrderEndpoint.Create.Request,
  ): Promise<ApiProductOrder> {
    return Client('product-orders', {
      method: 'POST',
      body: productOrder,
    });
  }

  static duplicateProductOrder (
    id: string,
    productOrder: ApiProductOrderDuplicate,
  ): Promise<ApiProductOrder> {
    return Client(`product-orders/${id}/duplicate`, {
      method: 'POST',
      body: productOrder,
    });
  }

  static cancelProductOrder (
    id: string,
  ): Promise<ApiProductOrder> {
    return Client(`product-orders/${id}/cancel`, {
      method: 'POST',
    });
  }

  // https://growers.stoplight.io/docs/rally-api/b3A6MzY3MTYxNTM-update-product-orders
  static updateProductOrder (
    id: string,
    productOrder: ProductOrderEndpoint.Update.Request,
  ): Promise<ApiProductOrder> {
    return Client(`product-orders/${id}`, {
      method: 'PATCH',
      body: productOrder,
    });
  }

  // https://growers.stoplight.io/docs/rally-api/b3A6MzUzMDMzMDg-delete-product-orders
  static deleteProductOrder (
    id: string,
  ): Promise<void> {
    return Client(`product-orders/${id}`, { method: 'DELETE' });
  }

  // https://growers.stoplight.io/docs/rally-api/b3A6MzY3MTYxNTQ-list-product-orders
  static getProductOrders (
    filters: ApiProductOrderListQuery,
  ): Promise<PaginatedData<ApiProductOrderListResponse>> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });

    return Client(`product-orders?${query}`);
  }

  static getProductOrderFilters (
    filters: ApiProductOrderFiltersQuery,
  ): Promise<ApiProductOrderFiltersResponse> {
    const query = qs.stringify(filters, { arrayFormat: 'comma' });

    return Client(`product-orders/filters?${query}`);
  }

  static createDeliverable (
    name: string,
    productOrderId: string,
    selections: ProductOrderDeliverableV0.Selections,
  ): Promise<{ createdDeliverable: ApiDeliverable }> {
    return Client(`product-orders/${productOrderId}/deliverables`, { body: {
      name,
      selections,
      type: DeliverableType.PRODUCT_ORDER,
    } });
  }
}
