import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FontDisplay, useFonts } from 'expo-font';
import { matchPath } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import { Spinner } from '@ui-kitten/components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { darkTheme } from './src/theme/dark/darkTheme';
import { styled } from '@ui-kitten/components/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@design/Application/ThemeProvider';
import { FeatureFlagProvider, useFeatureFlags } from '@design/Application/FeatureFlagProvider';
import { GlobalBannerProvider } from '@design/Application/GlobalBannerProvider';
import i18n from './libs/@i18n';
import { PublicRoutes, Routes } from './constants';
import { LoginView } from './views/LoginView';
import { LoggedInView } from './views/LoggedInView';
import { PublicView } from './views/PublicView';
import { Route, Router, useLocation } from './router';
import {
  AuthenticationProvider,
  SessionState,
  useAuthentication,
} from './contexts/dataSync/AuthenticationContext';
import { SidePanelProvider } from './elements/content';
import 'react-native-gesture-handler';

/* eslint-disable global-require */
const fonts = {
  Roboto: {
    uri: require('./assets/fonts/Roboto/Roboto-Medium.ttf'),
    FontDisplay: FontDisplay.FALLBACK,
  },
  RobotoItalic: require('./assets/fonts/Roboto/Roboto-MediumItalic.ttf'),
  RobotoBold: require('./assets/fonts/Roboto/Roboto-Bold.ttf'),
  RobotoBoldItalic: require('./assets/fonts/Roboto/Roboto-BoldItalic.ttf'),
};
/* eslint-enable global-require */

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    // this is here to highlight any text that is not wrapped in a Text component
    // unwrapped text crashes iOS
    // every Text component has a style class or inline style to set text color,
    // which will override this pink.
    color: 'pink',
    backgroundColor: '#1c1c1c',
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App () {
  const [loaded] = useFonts(fonts);
  if (!loaded) {
    return null;
  }

  const MainViewRaw = ({ eva }) => {
    const { sessionState } = useAuthentication();
    const { isFlagsInit } = useFeatureFlags();
    const location = useLocation();

    for (const route of PublicRoutes) {
      const match = matchPath(location.pathname, route);
      if (match) {
        return (<PublicView />);
      }
    }

    if (sessionState === SessionState.LOGGED_OUT && isFlagsInit) {
      return <LoginView />;
    }

    if (sessionState === SessionState.LOGGED_IN && isFlagsInit) {
      return <Route component={LoggedInView} path={Routes.LOGGED_IN} />;
    }

    return (
      <View style={[eva.style, styles.container, styles.spinnerContainer]}>
        <Spinner size="giant" />
      </View>
    );
  };

  const MainView = styled('App')(MainViewRaw);

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <FeatureFlagProvider>
              <ThemeProvider>
                <MuiThemeProvider theme={darkTheme}>
                  <View style={styles.container}>
                    <GlobalBannerProvider>
                      <SidePanelProvider>
                        <MainView />
                      </SidePanelProvider>
                    </GlobalBannerProvider>
                  </View>
                </MuiThemeProvider>
              </ThemeProvider>
            </FeatureFlagProvider>
          </AuthenticationProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </Router>
  );
}
