/* eslint-disable react/no-array-index-key */
import React, { Fragment, ReactText } from 'react';
import { TextProps, View } from 'react-native';
import { Modal, DataPoint, VSpacer } from '@design';
import { RenderProp } from '@ui-kitten/components/devsupport';

interface OrderDetailsModalItems {
  label: string,
  value: ReactText | RenderProp<TextProps>,
  testID?: string,
}

export interface OrderDetailsModalProps {
  data: OrderDetailsModalItems[],
  onClose: () => void;
  title: string,
  testID?: string,
}

const getChunks = (input: OrderDetailsModalItems[], size: number) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
};

const OrderDetailsModal = ({ data, onClose, title, testID }: OrderDetailsModalProps) => {
  const CHUNK_SIZE = 2;
  const chunks = getChunks(data, CHUNK_SIZE);

  return (
    <Modal
      onClose={onClose}
      testID={testID}
      title={title}
      visible
    >
      {chunks.map((chunk, idx) => (
        <Fragment key={idx}>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
            {chunk.map((info: OrderDetailsModalItems) => (
              <DataPoint
                key={info.label}
                label={info.label}
                style={{ flex: (1 / CHUNK_SIZE) }}
                testID={info.testID}
              >
                {info.value}
              </DataPoint>
            ))}
          </View>
          {idx !== chunks.length - 1 && <VSpacer size="8" />}
        </Fragment>
      ))}
    </Modal>
  );
};

export default OrderDetailsModal;
