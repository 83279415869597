import {
  Button,
  CenteredSpinner,
  HSpacer,
  Pagination,
  Search,
  Select,
  SelectItem,
  Text,
  VSpacer,
} from '@design';
import {
  AgriculturalUnit,
  AreaUnitType,
  CropLogicComponentCategory,
  CropSubType,
  CropType,
  FormulationType,
  ProductCategory,
} from '@shared/enums';
import { TankMixComponentCategory } from '@shared/enums/TankMixComponentCategory';
import {
  ApiCrop,
  ApiCropLogicPassComponent,
  ApiProduct,
  ApiProductSubCategory,
  ApiTankMixComponent,
  ApiTankMixListQuery,
} from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import { Divider } from '@ui-kitten/components';
import * as _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { StringUtility } from '../../utilities';
import { BusinessApi, ProductApi, TankMixApi } from '../../utilities/api';
import { ComponentSelectPanelItem } from './ComponentSelectPanelItem';

const styles = StyleSheet.create({
  header: {
    alignItems: 'flex-end',
    borderBottomWidth: 1,
  },
  flexLeft: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  price: {
  },
  totalItems: {
    paddingHorizontal: 20,
  },
  pagination: {
    marginVertical: 20,
    alignItems: 'center',
    paddingHorizontal: '25%',
  },
});

interface ComponentSelectorType {
  type: CropLogicComponentCategory;
  category: string;
  subCategories: ApiProductSubCategory[];
}

interface IComponentFilter {
  category?: string;
  businessCategoryId?: string;
  cropType?: CropType;
  cropSubType?: CropSubType;
  formulation?: FormulationType
}

export interface ComponentSelectPanelProps {
  businessId: string,
  components: ApiTankMixComponent[],
  cropSubType?: CropSubType,
  excludeCategory?: CropLogicComponentCategory[],
  locationId?: string,
  onClose: () => void,
  priceTypeId: string,
  programSavedComponents: ApiCropLogicPassComponent[],
  selectedCrop?: ApiCrop,
  setPassComponents: (components: (ApiCropLogicPassComponent | ApiTankMixComponent)[]) => void,
}

/**
 * Merges the list of queried products with the ones
 * that were previously saved as a component to a crop logic
 * */
const concatAlreadyUsedInactiveProducts = (
  filters: IComponentFilter,
  cropLogicSavedComponents: ApiCropLogicPassComponent[],
  queryProducts: ApiProduct[],
): ApiProduct[] => {
  const sc = filters.category && filters.category[0];
  const componentArr = cropLogicSavedComponents.filter(
    (clsc) => clsc.componentCategory === sc,
  );
  const productArr = componentArr.map((c) => c.product);
  return _.uniqBy(queryProducts.concat(productArr), (d) => d.id);
};

export const ComponentSelectPanel: FC<ComponentSelectPanelProps> = ({
  businessId,
  components,
  excludeCategory,
  locationId,
  onClose,
  priceTypeId,
  programSavedComponents,
  selectedCrop,
  setPassComponents,
}) => {
  const [translate] = useTranslation(['tankMix', 'common']);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [paginatedComponentList, setPaginatedComponentList] = (
    useState<ApiCropLogicPassComponent[]>([])
  );
  const [allComponentsList, setAllComponentsList] = useState<ApiCropLogicPassComponent[]>([]);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [categoryList, setCategoryList] = useState<ComponentSelectorType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CropLogicComponentCategory>(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedFormulation, setSelectedFormulation] = useState<FormulationType>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);

  const formulationOptions = [FormulationType.DRY, FormulationType.LIQUID];

  useEffect(() => {
    setSelectedComponents(components.map((c) => (c.product.id)));
  }, [components]);

  const toggleComponentSelection = (componentId: string) => {
    const selected = [...selectedComponents];
    selected.slice(selected.indexOf(componentId), 1);
    if (selected.includes(componentId)) {
      selected.splice(selected.indexOf(componentId), 1);
    } else {
      selected.push(componentId);
    }
    setSelectedComponents(selected);
  };

  const getComponentPrice = (component: ApiCropLogicPassComponent, locId: string)
  : { displayPrice: number, displayPriceUom: string } => (component.product
    ? {
      displayPrice: CalculationUtility.getApplicableProductPrice(
        component.product,
        priceTypeId,
        locId,
      ),
      displayPriceUom: translate(component.product.unitUoM),
    }
    : {
      displayPrice: CalculationUtility.calculateTankMixComponentCostPerAcre(
        component.tankMix,
        priceTypeId,
        locId,
      ).totalCostPerAcre,
      displayPriceUom: translate('ACRE'),
    });

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      try {
        const result = await BusinessApi.getBusinessProductCategories(businessId);
        const newCategoryList: ComponentSelectorType[] = result
          .filter((c) => c.category !== ProductCategory.OTHER && c.category !== ProductCategory.PRODUCT_MIX)
          .map((c) => ({
            category: c.category as string,
            subCategories: c.subCategories,
            type: CropLogicComponentCategory[c.category],
          }));
        newCategoryList.push({
          category: CropLogicComponentCategory.TANK_MIX,
          type: CropLogicComponentCategory.TANK_MIX,
          subCategories: [],
        });

        const filteredCategoryList = newCategoryList.filter((r) => (
          !excludeCategory.includes(CropLogicComponentCategory[r.category])
        ));

        setCategoryList(filteredCategoryList);
      } catch (err) {
        setError('UNEXPECTED_ERROR');
      }
      setLoading(false);
    };
    getCategories();
  }, [businessId, excludeCategory]);

  useEffect(() => {
    let res = [];
    if (selectedCategory !== undefined) {
      const catRes = categoryList.find(
        (cat) => cat.category === CropLogicComponentCategory[selectedCategory],
      );
      res = (catRes && catRes.subCategories) || [];
    }
    setSubCategoryList(res);
  }, [selectedCategory, categoryList]);

  const getComponents = useCallback(async (filters: IComponentFilter) => {
    const isProduct = selectedCategory !== CropLogicComponentCategory.TANK_MIX;
    setLoading(true);
    try {
      if (isProduct) {
        const result = await ProductApi.list({
          businessCategoryId: [filters.businessCategoryId],
          businessId,
          category: filters.category as unknown as ProductCategory,
          cropSubType: [filters.cropSubType],
          cropType: [filters.cropType],
          formulation: [filters.formulation],
          isActive: ['true'],
          page: currentPage,
          search,
        });
        const res = concatAlreadyUsedInactiveProducts(
          filters, programSavedComponents, result.data,
        );
        setTotalItems(result.total);
        setLastPage(result.lastPage);
        const data = res.sort((a, b) => a.name.localeCompare(b.name)).map((r) => ({
          componentCategory: filters.category,
          id: r.id,
          product: r,
        } as ApiCropLogicPassComponent));
        setPaginatedComponentList(data);
        setAllComponentsList((currentList) => (
          [...currentList, ...data]
        ));
      } else {
        const options: ApiTankMixListQuery = {
          businessId,
          cropSubType: filters.cropSubType ? [filters.cropSubType] : [],
          cropType: [filters.cropType],
          isActive: ['true'],
          page: currentPage,
          search,
        };
        const tankMixes = await TankMixApi.listTankMix(options);
        setTotalItems(tankMixes.total);
        setLastPage(tankMixes.lastPage);
        const res = tankMixes.data.map((tankMix) => ({
          componentCategory: filters.category,
          id: tankMix.id,
          name: tankMix.name,
          tankMix,
        })) as ApiCropLogicPassComponent[];

        setPaginatedComponentList(res);
        setAllComponentsList((currentList) => [...currentList, ...res]);
      }
    } catch (err) {
      setError('UNEXPECTED_ERROR');
    } finally {
      setLoading(false);
    }
  }, [
    businessId,
    programSavedComponents,
    currentPage,
    search,
    selectedCategory,
  ]);

  const fetchComponents = useCallback(async () => {
    const filters: IComponentFilter = {
      category: selectedCategory,
      businessCategoryId: selectedSubCategoryId,
      formulation: selectedFormulation,
      cropType: selectedCrop?.cropType,
      cropSubType: selectedCrop?.subType,
    };
    if (!_.isNil(selectedCategory)) {
      await getComponents(filters);
    }
  }, [
    selectedCrop,
    getComponents,
    selectedCategory,
    selectedFormulation,
    selectedSubCategoryId,
  ]);

  useEffect(() => {
    fetchComponents();
  }, [fetchComponents]);

  // This interface is going to need to be smart enough to work with
  // products, tank-mixes, etc. We can assume that any type we search
  // for in this list will have an id and a name. The components under
  // the passes shouldn't be ICropLogicPassComponents until they're
  // coming in or out of the API.
  const getComponentRows = () => paginatedComponentList.map((component) => {
    const { displayPrice, displayPriceUom } = getComponentPrice(component, locationId);
    const isSelected = selectedComponents.includes(component.id);

    return (
      <ComponentSelectPanelItem
        key={component.id}
        onSelect={() => toggleComponentSelection(component.id)}
        selected={isSelected}
      >
        <Text
          category="p1"
          style={{
            wordBreak: 'break-all',
          }}
          testID="component-name"
        >
          {component.product.skuName}
        </Text>
        <Text
          appearance="hint"
          category="c1"
        >
          {`${StringUtility.formatCurrency(displayPrice)} / ${displayPriceUom}`}
        </Text>
      </ComponentSelectPanelItem>
    );
  });

  const onSelectCategory = (cat) => {
    if (cat.row !== undefined && cat.row !== selectedCategory) {
      setSelectedFormulation(undefined);
      setSelectedSubCategory(undefined);
      setSelectedSubCategoryId(undefined);
    }
    setSelectedCategory(CropLogicComponentCategory[categoryList[cat.row].category]);
  };

  const onSelectSubCategory = (subCat) => {
    setSelectedSubCategory(subCategoryList[subCat.row - 1]?.name);
    setSelectedSubCategoryId(subCategoryList[subCat.row - 1]?.id);
  };

  const onSelectFormulation = (formulation) => {
    setSelectedFormulation(formulationOptions[formulation.row]);
  };

  const renderTotalItems = () => (
    <Text
      appearance="hint"
      category="p2"
      style={styles.totalItems}
      testID="component-selector-total-items"
    >
      <>
        {totalItems > 1 ? translate('ITEMS_COUNT', { count: totalItems })
          : translate('ITEM_SINGLE_COUNT')}
      </>
    </Text>
  );

  const onSubmit = (selectedComp: any[]) => {
    const isProduct = selectedCategory !== CropLogicComponentCategory.TANK_MIX;
    const selected = allComponentsList
      .filter((comp) => selectedComp.includes(comp.product.id))
      .map((comp) => {
        const isAcre = comp.product.unitUoM === AreaUnitType.Acre;
        const isUnit = comp.product.unitUoM === AgriculturalUnit.Unit;
        const isAcreOrUnit = isAcre || isUnit;
        if (isAcreOrUnit) {
          if (isAcre) {
            comp.rate = 1;
            comp.rateUom = AreaUnitType.Acre;
          }
          if (isUnit) {
            comp.rateUom = AgriculturalUnit.Unit;
          }
        }
        return {
          id: comp.product.id,
          product: comp.product,
          tankMixId: comp.tankMix?.id,
          productId: comp.id,
          componentCategory: isProduct
            ? TankMixComponentCategory[selectedCategory]
            : CropLogicComponentCategory[selectedCategory],
          formulation: comp.product?.chemicalFertilizerProduct?.formulation ?? FormulationType.DRY,
          rate: comp.rate,
          rateUom: comp.rateUom,
        } as ApiTankMixComponent;
      });
    const seen = new Set();
    const previousComponents = components.filter((val) => selectedComp.includes(val.product.id));
    const finalList = [...previousComponents, ...selected].filter((el) => {
      const dupe = seen.has(el.productId);
      seen.add(el.productId);
      return !dupe;
    });
    setPassComponents(finalList);
    onClose();
  };

  return (
    <>
      <ScrollView>
        <View style={{ paddingHorizontal: 20 }}>
          <Search
            onChangeText={(value) => {
              setSearch(value);
              setCurrentPage(0);
            }}
            onClear={() => setSearch('')}
            onSelect={(i) => setSearch(allComponentsList[i].name)}
            size="medium"
            testID="component-search"
            value={search}
          />
          <VSpacer size="8" />
          <View>
            <Text appearance="hint" category="c1">{translate('FILTER_BY') as string}</Text>
            <VSpacer size="5" />
            <Select
              label={translate('TYPE') as string}
              onSelect={onSelectCategory}
              placeholder="Select an option...."
              style={{ maxHeight: '100%' }}
              testID="component-type-selector"
              value={translate(selectedCategory) as string || null}
            >
              {categoryList.filter(Boolean).map((c, i) => (
                <SelectItem
                  key={CropLogicComponentCategory[c.category]}
                  selected={CropLogicComponentCategory[c.category] === selectedCategory}
                  testID={`crop-category-dropdown-value-${i}`}
                  title={translate(c.category) as string}
                />
              ))}
            </Select>
            <VSpacer size="7" />
            {!_.isEmpty(subCategoryList) && (
              <>
                <Select
                  label={translate('CATEGORY') as string}
                  onSelect={onSelectSubCategory}
                  testID="sub-category-selector"
                  value={translate(selectedSubCategory) as string || null}
                >
                  {[
                    (
                      <SelectItem
                        key={null}
                        testID={`sub-category-dropdown-value-${translate(selectedSubCategory)}`}
                        title={translate('NONE') as string}
                      />
                    ),
                    ...subCategoryList.map((sc) => (
                      <SelectItem
                        key={sc.id}
                        selected={sc.name === selectedSubCategory}
                        testID={`sub-category-dropdown-value-${sc.name}`}
                        title={sc.name}
                      />
                    ))]}
                </Select>
                <VSpacer size="7" />
              </>
            )}
            {(selectedCategory === CropLogicComponentCategory.CHEMICAL
              || selectedCategory === CropLogicComponentCategory.FERTILIZER
            ) && (
              <Select
                label={translate('FORMULATION') as string}
                // clearable
                onSelect={onSelectFormulation}
                testID="formulation-selector"
                value={translate(selectedFormulation) as string || null}
              >
                {formulationOptions.map((formulation, index) => (
                  <SelectItem
                    key={formulation}
                    selected={selectedFormulation === formulation}
                    testID={`formulation-dropdown-value-${index}`}
                    title={translate(formulation) as string}
                  />
                ))}
              </Select>
            )}
          </View>
        </View>
        <VSpacer size="9" />
        <View>
          {!error && (
            <View nativeID="components-list-view" style={{}} testID="components-list-view">
              {isLoading && (
                <CenteredSpinner />
              )}
              {_.isEmpty(paginatedComponentList) ? (
                !isLoading && (
                  <>
                    <View style={styles.spinnerContainer}>
                      <Text category="p1">{translate('NO_COMPONENTS') as string}</Text>
                    </View>
                  </>
                )
              ) : (
                <>
                  {renderTotalItems()}
                  <VSpacer size="5" />
                  <Divider style={{ backgroundColor: `${GrowersDarkTheme['color-basic-transparent-100']}` }} />
                  <VSpacer size="6" />
                  <ScrollView>
                    {getComponentRows()}
                  </ScrollView>
                  <View style={styles.pagination}>
                    <Pagination
                      currentPage={currentPage + 1}
                      displayPages={7}
                      onChangePage={(selectedPage) => setCurrentPage(selectedPage - 1)}
                      totalPages={lastPage + 1}
                    />
                  </View>

                </>
              )}
            </View>
          )}
        </View>
      </ScrollView>
      <View nativeID="component-selector-footer" style={styles.header}>
        <VSpacer size="7" />
        <View style={{ flexDirection: 'row' }}>
          <Button
            appearance="outline"
            onPress={onClose}
            size="large"
            status="basic"
            testID="cancel-button"
          >
            {translate('CANCEL') as string}
          </Button>
          <HSpacer size="4" />
          <Button
            onPress={() => onSubmit(selectedComponents)}
            size="large"
            testID="submit-button"
          >
            {translate('APPLY') as string}
          </Button>
          <HSpacer size="6" />
        </View>
        <VSpacer size="8" />
      </View>
    </>
  );
};
