import {
  Header,
  Tab,
  TabView,
  useFeatureFlags,
  VSpacer,
} from '@design';
import { FeatureFlags } from '@shared/enums';
import { RoleUtility, Permissions } from '@shared/utils/Permissions';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../constants';
import { useParams } from '../../../router';
import { CustomerListTab } from './CustomerListTab';
import { PropertiesTab } from './PropertiesTab';
import { TagsTab } from './TagsTab';

const tabs = [
  'customers',
  'tags',
  'properties',
];

export const CustomerPlanningPage = () => {
  const { tab: tabName } = useParams<{ tab: string }>();
  const history = useHistory();
  const [translate] = useTranslation(['common', 'growers']);
  const scrollRef = useRef<ScrollView>();
  const [selectedIndex, setSelectedIndex] = useState(!!tabName ? tabs.indexOf(tabName) : 0);
  const { isFlagOn } = useFeatureFlags();
  const { currentBusinessId, user } = useAuthentication();

  const isAdminOrInternal = (
    RoleUtility.roleHasPermission(user.userRole, Permissions.MODIFY_BUSINESS_OBJECTS)
  );

  useEffect(() => {
    if (!tabName) {
      history.replace(
        Routes.CUSTOMER_PLANNING
          .replace(/:tab/, tabs[0]),
      );
    }
    if (tabs[selectedIndex] !== tabName) {
      setSelectedIndex(tabs.findIndex((name) => name === tabName));
    }
  }, [history, selectedIndex, tabName]);

  const onTabSelect = (index: number) => {
    setSelectedIndex(index);
    history.replace(
      Routes.CUSTOMER_PLANNING
        .replace(/:tab/, tabs[index]),
    );
  };

  return (
    <ScrollView ref={scrollRef}>
      <Header
        level="1"
        testID="customer-planning-header"
        title={translate('CUSTOMER_PLANNING')}
      />
      <VSpacer size="8" />
      <TabView
        onSelect={onTabSelect}
        selectedIndex={selectedIndex}
        shouldLoadComponent={(index) => index === selectedIndex}
        swipeEnabled={false}
        testID="customer-planning-tab-view"
      >
        <Tab
          testID="customers-tab"
          title={translate('CUSTOMERS')}
        >
          <CustomerListTab />
        </Tab>
        <Tab
          testID="tags-tab"
          title={translate('TAGS')}
        >
          <TagsTab />
        </Tab>
        {(isFlagOn(currentBusinessId, FeatureFlags.PROPERTY_GROUPS) && isAdminOrInternal) &&
          <Tab
            testID="properties-tab"
            title={translate('PROPERTIES')}
          >
            <PropertiesTab />
          </Tab>
        }
      </TabView>
    </ScrollView>
  );
};
