import { Button, HSpacer, Icon } from '@design';
import { OrderStatus } from '@shared/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 40,
    bottom: 40,
  },
  actionButtons: {
    position: 'absolute',
    right: 40,
    bottom: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  footer: { flexDirection: 'row' },
});

export interface ViewModeFooterProps {
  isDraft: boolean,
  onCancelOrder(): void,
  onClose(): void,
  onEdit?(): void,
  onShareDeliverable?(): void,
  showCancelOrder?: boolean,
  status: OrderStatus,
}
export function ViewModeFooter ({
  isDraft,
  onCancelOrder,
  onClose,
  onEdit,
  onShareDeliverable,
  showCancelOrder,
  status,
}: ViewModeFooterProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);

  return (
    <>
      <View style={styles.container} testID="view-mode-footer">
        <Button
          appearance="outline"
          design="floating"
          onPress={onClose}
          size="large"
          status="basic"
          testID="view-mode-footer-close-button"
        >
          {translate<string>('CLOSE')}
        </Button>
      </View>
      <View style={styles.actionButtons}>
        {showCancelOrder && (
          <>
            <Button
              accessoryLeft={(props) => <Icon {...props} name="Slash" testID="view-mode-footer-cancel-order-button-icon" />}
              appearance="outline"
              onPress={onCancelOrder}
              size="large"
              status="primary"
              testID="view-mode-footer-cancel-order-button"
            >
              {(translate<string>('CANCEL_ORDER'))}
            </Button>
            <HSpacer size="8" />
          </>
        )}
        {(!!onEdit && (status !== (OrderStatus.ACCEPTED) || isDraft)) && (
          <>
            <Button
              accessoryLeft={(props) => <Icon {...props} name="Edit" testID="view-mode-footer-edit-button-icon" />}
              appearance="outline"
              design="floating"
              onPress={onEdit}
              size="large"
              status="basic"
              testID="view-mode-footer-edit-button"
            >
              {(translate<string>('EDIT'))}
            </Button>
            <HSpacer size="8" />
          </>
        )}
        {!!onShareDeliverable && (
          <Button
            design="floating"
            onPress={onShareDeliverable}
            size="large"
            testID="view-mode-footer-share-deliverable-button"
          >
            {translate<string>('CREATE_AND_SHARE_DELIVERABLE')}
          </Button>
        )}
      </View>
    </>
  );
}
