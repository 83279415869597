import {
  Modal,
  TextLink,
  Button,
  Header,
  VSpacer,
  useBanner,
  ComboBox,
} from '@design';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import { ApiGrowerContact } from '@shared/interfaces/api';
import DescriptionList, { DescriptionListDirection } from '../DescriptionList/DescriptionList';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';
import { useCustomerContacts } from '../../../../hooks/useCustomerContacts';
import { PopperPlacementType } from '@mui/material';
import { DefaultTags } from '@shared/enums';
import { ContactTagBadges } from '../CustomTags/ContactTagBadges';

export enum CustomerContactMode {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING',
}

export interface CustomerContactSelectProps {
  customerContactId?: string | null;
  customerId: string;
  mode: CustomerContactMode
  onUpdateCustomerContact(
    customerContactId: string | null,
    customerContact?: ApiGrowerContact,
  ): void;
  optionWindowMaxHeight?: number,
  optionWindowPlacement?: PopperPlacementType,
}

export const CustomerContactSelect = ({
  customerContactId = null,
  customerId,
  mode,
  onUpdateCustomerContact,
  optionWindowMaxHeight,
  optionWindowPlacement,
}: CustomerContactSelectProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const { showBanner } = useBanner();
  const [customerContact, setCustomerContact] = useState<ApiGrowerContact>(null);
  const [showModal, setShowModal] = useState(false);
  const [shownErrorId, setShownErrorId] = useState('');

  const styles = useStyleSheet({
    linkWrapper: {
      marginLeft: 12,
      marginTop: 8,
    },
    textLink: {
      fontSize: 12,
    },
    divider: {
      backgroundColor: 'text-hint-color',
    },
  });

  const { isLoading, data: contacts, error } = useCustomerContacts(customerId);

  useEffect(() => {
    if (!isLoading) {
      const contact = contacts.find(
        ({ id }) => id === customerContactId,
      );

      setCustomerContact(contact);
    }
  }, [customerContactId, contacts, isLoading]);

  const modeLowerCase = mode.toLowerCase();

  useEffect(() => {
    if (error && customerId !== shownErrorId) {
      setShownErrorId(customerId);
      showBanner(translate('ERROR_CUSTOMER_CONTACTS', { type: modeLowerCase }));
    }
  }, [
    customerId,
    error,
    modeLowerCase,
    showBanner,
    shownErrorId,
    translate,
  ]);

  const renderContactTagBadges = (contact: ApiGrowerContact) => {
    const billingShippingTags = contact?.tags.filter((tag) => (
      tag === DefaultTags.BillingContact || tag === DefaultTags.ShippingContact
    ));
    return (
      <ContactTagBadges tags={billingShippingTags} testID="customer-contact-select-option" />
    );
  };

  return (
    <>
      <Modal
        footerAccessory={({
          secondaryButtonProp,
        }) => (
          <Button {...secondaryButtonProp} onPress={() => setShowModal(false)} testID="close-button">
            {translate<string>('CLOSE')}
          </Button>
        )}
        hideCloseButton
        onClose={() => setShowModal(false)}
        testID={`view-contact-info-modal-${modeLowerCase}`}
        title={translate('CONTACT_INFO')}
        visible={showModal}
      >
        <Header level="4" testID={`contact-info-header-${modeLowerCase}`} title={translate(`${mode}_CONTACT`)} />
        <Divider style={styles.divider} />
        <VSpacer size="6" />
        <DescriptionList direction={DescriptionListDirection.STACKED}>
          <DescriptionListItem
            label={translate<string>('NAME')}
            testID={`contact-info-name-${modeLowerCase}`}
            text={`${customerContact?.firstName} ${customerContact?.lastName}`}
            textAccessory={renderContactTagBadges(customerContact)}
            textCategory="p2"
          />
          <DescriptionListItem
            label={translate<string>('EMAIL')}
            testID={`contact-info-email-${modeLowerCase}`}
            text={customerContact?.email}
            textCategory="p2"
          />
          <DescriptionListItem
            label={translate<string>('PHONE')}
            testID={`contact-info-phone-${modeLowerCase}`}
            text={customerContact?.telephone}
            textCategory="p2"
          />
          <DescriptionListItem
            label={translate<string>('ADDRESS')}
            testID={`contact-info-address-${modeLowerCase}`}
            text={customerContact?.address1}
            textCategory="p2"
          />
        </DescriptionList>
      </Modal>
      {contacts && (
        <ComboBox
          disabled={isLoading}
          getOptionLabel={(contact) => `${contact?.firstName} ${contact?.lastName}`}
          id={`customer-contact-${modeLowerCase}-ComboBox`}
          onChangeValue={(contact) => onUpdateCustomerContact(
            contact?.id,
            contact,
          )}
          optionWindowMaxHeight={optionWindowMaxHeight}
          optionWindowPlacement={optionWindowPlacement}
          options={contacts}
          renderOptionAccessory={renderContactTagBadges}
          testID={`business-customer-${modeLowerCase}-selector`}
          topLabel={translate<string>(`${mode}_CONTACT`)}
          value={customerContact ?? null}
        />
      )}
      <View style={styles.linkWrapper}>
        {
        !!customerContactId && (
        <TextLink
          appearance="secondary"
          onPress={() => setShowModal(true)}
          style={styles.textLink}
          testID={`view-contact-info-link-${modeLowerCase}`}
        >
          {translate<string>('VIEW_CONTACT_INFO')}
        </TextLink>
        )
      }
      </View>
    </>
  );
};
