import { Card } from '@ui-kitten/components';
import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { IconButton, List, ListItem, ListSeparator } from '@design';
import DescriptionList from '../../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../../components/shared/DescriptionList/DescriptionListItem';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const ListDemo: FunctionComponent = () => {
  const separators: ListSeparator[] = ['none', 'top', 'bottom', 'both'];

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="separator">
          {separators.map((s) => (
            <DemoBlock key={s} label={s} style={{ alignItems: 'flex-start' }}>
              <List separator={s} testID="demo-list">
                <ListItem
                  sublineText="Secondary line text"
                  title="List Item 1"
                />

                <ListItem
                  sublineDataPoints={(props) => (
                    <DescriptionList {...props}>
                      <DescriptionListItem label="label1" text="Text" />
                      <DescriptionListItem label="label2" text="Text" />
                      <DescriptionListItem label="label3" text="Text" />
                    </DescriptionList>
                  )}
                  title="List Item 2"
                />
                <ListItem
                  iconButtons={(props) => (
                    <>
                      <IconButton {...props} testID="test-button">Trash</IconButton>
                      <IconButton {...props} testID="test-button">Edit</IconButton>
                    </>
                  )}
                  sublineText="Secondary line text"
                  title="List Item 3"
                />
                <ListItem
                  iconButtons={(props) => (
                    <>
                      <IconButton {...props} testID="test-button">Trash</IconButton>
                      <IconButton {...props} testID="test-button">Edit</IconButton>
                    </>
                  )}
                  sublineDataPoints={(props) => (
                    <DescriptionList {...props}>
                      <DescriptionListItem label="label1" text="Text" />
                      <DescriptionListItem label="label2" text="Text" />
                      <DescriptionListItem label="label3" text="Text" />
                    </DescriptionList>
                  )}
                  title="List Item 4"
                />
              </List>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
    </View>
  );
};
