import { Icon, ReorderingList, useToast, VSpacer } from '@design';
import { ApiForm } from '@shared/interfaces/api';
import { userIsInternal } from '@shared/utils';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { PropertyGroupApi } from '../../../../utilities/api/PropertyGroupApi';
import { DoubleConfirmationModal } from './DoubleConfirmationModal';
import { PropertyGroupCard } from './PropertyGroupCard';

interface PropertyGroupListProps {
  onEdit: (form: ApiForm) => void,
  propertyGroups: ApiForm[],
  setPropertyGroups: React.Dispatch<React.SetStateAction<ApiForm[]>>,
}

export const PropertyGroupList = ({
  onEdit,
  propertyGroups,
  setPropertyGroups,
}: PropertyGroupListProps) => {
  const [translate] = useTranslation(['propertyGroups', 'common']);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [cursor, setCursor] = useState('move');
  const { createToast } = useToast();
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
  const [deletingPropertyGroupIndex, setDeletingPropertyGroupIndex] = useState(null);
  const { user } = useAuthentication();

  const onDragEnd = useCallback(() => {
    setScrollEnabled(true);
    setCursor('move');
  }, []);
  
  const moveArrayElement = (fromIndex: number, toIndex: number) => {
    const element = propertyGroups[fromIndex];
    propertyGroups.splice(fromIndex, 1);
    propertyGroups.splice(toIndex, 0, element);
    setPropertyGroups([...propertyGroups]);
  };
  
  const reOrderArrayElements = (array: ApiForm[]) => {
    return array.map((property, index) => {
      property.order = index;
      return property;
    });
  };

  const reOrderPropertyGroups = () => {
    const reOrderedElements = reOrderArrayElements(propertyGroups);
    setPropertyGroups(reOrderedElements);
    PropertyGroupApi.reorderPropertyGroups(
      reOrderedElements.map((pg) => {
        return pg.id;
      }),
    );
  };

  const deletePropertyGroup = async (index: number) => {
    const id = propertyGroups[index].id;
    await PropertyGroupApi.deletePropertyGroup(id).then(() => {
      createToast({
        children: translate<string>('PROPERTY_GROUP_DELETED'),
        status: 'success',
        testID: 'property-group-deleted-success-toast',
      });
    });
    // we need to assume it was deleted on the backend
    // because invalidating the cache glitches the drag&drop
    let propList = [...propertyGroups];
    propList.splice(index, 1);
    const reOrderedElements = reOrderArrayElements(propList);
    setPropertyGroups(reOrderedElements);
  };
  
  const dragHandle = (index) => {
    return (
      <Icon
        cursor={cursor}
        name="GrowersDragHandle"
        style={{ height: 24, width: 24 }}
        testID={`drag-handle-${index}`}
      />
    );
  };

  return (
    propertyGroups?.length && 
    <ScrollView
      scrollEnabled={scrollEnabled}
      testID="property-list-scrolling-wrapper"
    >
      <ReorderingList
        handle={(index) => dragHandle(index)}
        hasBottomBorder={false}
        items={propertyGroups}
        keyExtractor={(component) => component.id}
        onDragEnd={onDragEnd}
        onDragStart={() => setCursor('ns-resize')}
        onOrder={(items) => {
          if (userIsInternal(user)) return;
          const reOrderedForms = items.map((form, index) => {
            form.order = index;
            return form;
          });
          setPropertyGroups([...reOrderedForms]);
          PropertyGroupApi.reorderPropertyGroups(
            items.map((pg) => {
              return pg.id;
            }),
          );
        }}
        readOnly={userIsInternal(user)}
        renderItem={(form, index) => (
          <View 
            key={index}
            style={{ flex: 1 }}
          >
            <PropertyGroupCard
              createdByName="Santa"
              form={form}
              onChangeOrder={(newIndex) => {
                if (!newIndex) return;
                moveArrayElement(index, newIndex - 1);
                reOrderPropertyGroups();
              }}
              onDelete={() => {
                setDeletingPropertyGroupIndex(index);
                setIsDeleteConfirmationVisible(true);
              }}
              onEdit={() => onEdit(form)}
              readOnly={userIsInternal(user)}
            />
            <VSpacer size='3'/>
          </View>
        )}
      />
      {isDeleteConfirmationVisible && 
        <DoubleConfirmationModal
          cancelText={translate('CANCEL')}
          challengeText={translate('DELETE_PROPERTY_GROUP_CHALLENGE_MESSAGE')}
          confirmText={translate('DELETE')}
          messageText={translate('DELETE_PROPERTY_GROUP_WARNING')}
          onCancel={
            () => setIsDeleteConfirmationVisible(false)
          }
          onConfirm={async () => {
            setIsDeleteConfirmationVisible(false);
            deletePropertyGroup(deletingPropertyGroupIndex);
            setDeletingPropertyGroupIndex(null);
          }}
          status="warning"
          title={translate('DELETE_PROPERTY_GROUP')}
          visible={true}
        />
      }
    </ScrollView>
  );
};
