import {
  Button,
  Card,
  CenteredSpinner,
  HSpacer,
  Input,
  LargeModal,
  NumericInput,
  Select,
  SelectItem,
  Text,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import {
  AgriculturalUnit,
  AreaUnitType,
  FormulationType,
  MassUnit,
  ProductCategory,
  ProductUom,
  VolumeUnit,
} from '@shared/enums';
import {
  ApiPrice,
  ApiProduct,
  ApiProductCategory,
  ApiProductSubCategory,
  ProductEndpoint,
} from '@shared/interfaces/api/';
import { ProductUtilities } from '@shared/utils';
import { Status } from '@theme/variant-interfaces/Status';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useQuery, useQueryClient } from 'react-query';
import { getBusinessProductListPage, QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { useHistory, useParams } from '../../../router';
import { BusinessApi, ProductApi } from '../../../utilities/api';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { MultiplePricingTable } from './MultiplePricingTable';
import { getDefaultPrices } from './utils';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 545,
  },
  footer: {
    position: 'absolute',
    flexDirection: 'row',
    right: 132,
    bottom: 32,
    width: 100,
  },
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
});

const flattenProduct = (
  product: ApiProduct,
): ProductEndpoint.Create.ChemFert & { id: string, manufacturer: string } => {
  const {
    businessCategoryId,
    category,
    externalId,
    id,
    isActive,
    manufacturer,
    name,
    packageName,
    packageUnitQuantity,
    skuName,
    unitUoM,
  } = product;

  const priceList = product.prices.map((price) => ({
    id: price.id,
    locationId: price.businessLocationId,
    priceTypeId: price.priceTypeId,
    unitPrice: price.price,
  }));

  return {
    businessCategoryId,
    category: category as ProductCategory.FERTILIZER | ProductCategory.CHEMICAL,
    externalId,
    formulation: product.chemicalFertilizerProduct?.formulation,
    id,
    isActive,
    lbsPerGal: product.chemicalFertilizerProduct?.lbsPerGal,
    prices: !_.isEmpty(priceList) ? priceList : [],
    manufacturer,
    name,
    packageName,
    packageUnitQuantity,
    skuName,
    unitUoM: unitUoM as MassUnit | VolumeUnit,
  };
};

interface ProductModalProps {
  isVisible: boolean,
  productId?: string,
  modalToggle?: () => void,
}

type FertilizerChemicalCategories = ProductCategory.CHEMICAL | ProductCategory.FERTILIZER;

export const ProductModal: FC<ProductModalProps> = ({
  isVisible,
  productId,
  modalToggle,
}) => {
  const history = useHistory();
  const { tab: categoryPathParam } = useParams<any>();
  const queryClient = useQueryClient();
  const { currentBusinessId, currentBusiness } = useAuthentication();
  const { createToast } = useToast();
  const { createBanner } = useBanner();
  const [translate] = useTranslation(['prepare', 'products', 'common']);
  const [formLoaded, setFormLoaded] = useState(false);
  const [category, setCategory] = useState<FertilizerChemicalCategories>(null);
  const [businessCategorySelection, setBusinessCategorySelection] = useState(null);
  const [error, setError] = useState('');
  const [initError, setInitError] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<Status>('basic');
  const [categoryUoMs, setCategoryUoMs] = useState([]);
  const [name, setName] = useState('');
  const [skuName, setSkuName] = useState('');
  const [externalProductId, setExternalProductId] = useState('');
  const [unitUoM, setUnitUoM] = useState<ProductUom>(null);
  const [lbsPerGal, setLbsPerGal] = useState<number | null>(null);
  const [packageName, setPackageName] = useState('');
  const [packageUnitQuantity, setPackageUnitQuantity] = useState<number | null>(null);
  const [productManufacturer, setProductManufacturer] = useState('');
  const [prices, setPrices] = useState<ApiPrice[]>([]);
  const { businessLocations } = useBusinessLocationList(currentBusinessId);
  const [formulation, setFormulation] = useState<FormulationType>(null);
  const [businessCategories, setBusinessCategories] = useState<ApiProductCategory[]>([]);
  const [subCategories, setSubCategories] = useState<ApiProductSubCategory[]>([]);
  const [businessProductData, setbusinessProductData] = useState<ApiProduct>(null);
  const { priceTypes, invalidatePriceTypeList } = usePriceTypeList();
  const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState<boolean>(false);

  const nameInputRef = useRef(null);
  const skuNameInputRef = useRef(null);
  const packageNameInputRef = useRef(null);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevVisible = usePrevious(isVisible);

  const clearForm = useCallback(() => {
    setCategoryUoMs([]);
    setName('');
    if (nameInputRef.current !== null) {
      nameInputRef.current.clear();
    }
    setSkuName('');
    if (skuNameInputRef.current !== null) {
      skuNameInputRef.current.clear();
    }
    setExternalProductId('');
    setUnitUoM(null);
    setLbsPerGal(null);
    setPackageName('');
    if (packageNameInputRef.current !== null) {
      packageNameInputRef.current.clear();
    }
    setPackageUnitQuantity(null);
    setPrices(getDefaultPrices(priceTypes));
    setFormulation(null);
    setBusinessCategories([]);
    setSubCategories([]);
    setBusinessCategorySelection(null);
    setStatus('basic');
    setError('');
    setInitError('');
    setLoading(false);
    setbusinessProductData(null);
  }, [priceTypes]);

  useEffect(() => {
    (function reloadPriceTypesOnOpen () {
      if (!prevVisible && isVisible) {
        invalidatePriceTypeList();
      }
    }());
  }, [
    invalidatePriceTypeList,
    isVisible,
    prevVisible,
  ]);

  useEffect(() => {
    (function setPricesOnPriceTypeChange () {
      if (!productId) {
        setPrices(getDefaultPrices(priceTypes));
      }
    }());
  }, [priceTypes, productId]);

  // Type of product we're creating/editing: Fertilizer || Chemical
  useEffect(() => {
    setCategory(categoryPathParam);
  }, [categoryPathParam]);

  const isSavedProductUoMAcreOrUnit = (
    businessProductData?.unitUoM === AgriculturalUnit.Unit
    || businessProductData?.unitUoM === AreaUnitType.Acre
  );
  useEffect(() => {
    if (!_.isNil(category)) {
      const rawCategoryUoMs = ProductUtilities.getProductUoMs(category, formulation);
      if (!businessProductData) {
        setCategoryUoMs(rawCategoryUoMs);
      } else if (!isSavedProductUoMAcreOrUnit) {
        const convertibleUoMs = rawCategoryUoMs.filter((uom) =>
          uom !== AgriculturalUnit.Unit && uom !== AreaUnitType.Acre);
        setCategoryUoMs(convertibleUoMs);
      }
    }
  }, [category, businessProductData, formulation, isSavedProductUoMAcreOrUnit]);

  const getCategories = async () => {
    const categories = await BusinessApi.getBusinessProductCategories(currentBusinessId);
    return { categories };
  };

  const {
    isError: isBusinessCategoriesError,
    isFetching: isBusinessCategoriesLoading,
  } = useQuery(
    [QueryKeys.BUSINESS_CATEGORIES, currentBusinessId, productId, isVisible],
    getCategories, {
      enabled: isVisible && !!currentBusinessId,
      onError: () => {
        setInitError('ERROR_BUSINESS_LOCATIONS');
      },
      onSuccess: ({ categories }) => {
        setBusinessCategories(categories);
      },
    },
  );

  useEffect(() => {
    const subCategoryEntries = businessCategories.find((cat) => (
      cat.category === category))?.subCategories ?? [];
    setSubCategories([{
      id: '',
      name: translate('NONE'),
    },
    ...subCategoryEntries?.sort((a, b) => a.name.localeCompare(b.name))]);
  }, [businessCategories, category, translate]);

  useEffect(() => {
    setLoading(isBusinessCategoriesLoading);
    if (initError && isBusinessCategoriesError) {
      modalToggle();
      history.replace(getBusinessProductListPage(category));
    }
  }, [
    category,
    history,
    initError,
    isBusinessCategoriesError,
    isBusinessCategoriesLoading,
    modalToggle,
  ]);

  const getProduct = async () => ProductApi.get(currentBusinessId, productId);

  const {
    isError: isBusinessProductError,
    isFetching: isBusinessProductLoading,
  } = useQuery<ApiProduct, Error>([
    QueryKeys.BUSINESS_PRODUCT_DATA,
    currentBusinessId,
    isVisible,
    category,
    productId,
  ],
  getProduct, {
    enabled: isVisible && !!productId && !!currentBusinessId,
    onSuccess: (data: ApiProduct) => {
      setbusinessProductData(data);
    },
    onError: () => {
      setInitError(translate('ERROR_LOADING_PRODUCT'));
      history.replace(getBusinessProductListPage(category));
    },
  });

  useEffect(() => {
    setLoading(isBusinessProductLoading);
    if (businessProductData && productId && !formLoaded) {
      const flatProduct = flattenProduct(businessProductData);
      setCategory(flatProduct.category);
      setFormulation(flatProduct.formulation);
      setPrices(getDefaultPrices(priceTypes, flatProduct.prices));
      setName(flatProduct.name);
      setPackageName(flatProduct.packageName);
      setPackageUnitQuantity(flatProduct.packageUnitQuantity);
      setProductManufacturer(flatProduct.manufacturer ?? '');
      setSkuName(flatProduct.skuName);
      setExternalProductId(flatProduct.externalId ?? '');
      setUnitUoM(flatProduct.unitUoM);
      setLbsPerGal(flatProduct.lbsPerGal);
    } else if (!productId) {
      clearForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessProductData,
    error,
    initError,
    isBusinessProductError,
    isBusinessProductLoading,
    productId,
    translate,
  ]);

  useEffect(() => {
    if (businessProductData && subCategories.length > 1 && productId && !formLoaded) {
      const flatProduct = flattenProduct(businessProductData);
      setBusinessCategorySelection(
        subCategories.sort().findIndex((sub) => sub.id === flatProduct.businessCategoryId),
      );
      setFormLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subCategories,
    businessProductData,
    productId,
  ]);

  useEffect(() => {
    if (prevVisible && !isVisible) {
      setFormLoaded(false);
      clearForm();
    }
  }, [clearForm, isVisible, prevVisible]);

  // Clear form on modal close
  useEffect(() => {
    if (error || initError) {
      createBanner({
        status: 'warning',
        children: error
          ? `${translate(error)}`
          : `${translate(initError)}`,
        testID: 'error-banner',
        actionAccessory: ({ dismissProps }) => (
          <View style={styles.banner}>
            <Button
              {...dismissProps}
              appearance="ghost"
              size="small"
              status="basic"
              testID="dismiss-button"
            >
              {`${translate('DISMISS')}`}
            </Button>
          </View>
        ),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, initError, translate]);

  const requiresLbsPerGal = 
    category === ProductCategory.FERTILIZER
    && formulation === FormulationType.LIQUID
    && unitUoM === MassUnit.Ton;

  const formValid = (): boolean => {
    const requiredFields = [
      name,
      skuName,
      unitUoM,
      packageName,
      currentBusinessId,
    ];
    const requiredNumbers = [packageUnitQuantity];
    if (requiresLbsPerGal) {
      requiredNumbers.push(lbsPerGal);
    }
    const countOfPrices = [];
    businessLocations.forEach((location) => {
      countOfPrices.push(
        prices.filter(
          (price) => price.locationId === location.id,
        ).length,
      );
    });

    const hasRequiredFields = !_.some(requiredFields, (val) => _.isEmpty(val));
    const hasRequiredNumbers = _.every(requiredNumbers, (num) => !_.isNaN(num) && num !== null);
    const validPrices = prices.every(
      (p) => p.priceTypeId && (p.unitPrice !== null && p.unitPrice !== undefined),
    );

    return hasRequiredFields
      && hasRequiredNumbers
      && validPrices;
  };

  const onSubmit = async (toggleModal: () => void) => {
    try {
      setError('');

      const params: ProductEndpoint.Create.ChemFert = {
        businessCategoryId: subCategories.sort()[businessCategorySelection]?.id || null,
        category,
        externalId: externalProductId.trim() || null,
        formulation,
        lbsPerGal: requiresLbsPerGal ? lbsPerGal : null,
        prices,
        name,
        packageName,
        packageUnitQuantity,
        skuName,
        unitUoM: unitUoM as MassUnit | VolumeUnit,
      };
      if (!productId) {
        await ProductApi.create(currentBusinessId, params);
        createToast({
          status: 'success',
          children: `${translate('PRODUCT_SUCCESS', { name })}`,
          testID: 'toast-content-element',
        });
        await queryClient.invalidateQueries(QueryKeys[`${category}_LIST`]);
        clearForm();
      } else {
        await ProductApi.update(currentBusinessId, productId, params);
        createToast({
          status: 'success',
          children: `${translate('PRODUCT_EDIT_SUCCESS', { name })}`,
          testID: 'toast-content-element',
        });
        await queryClient.invalidateQueries(QueryKeys[`${category}_LIST`]);
      }
      toggleModal();
    } catch (err) {
      if (err instanceof DetailedApiError) {
        if (err.code === 'already-existing-product-sku') {
          setError('ERROR_PRODUCT_SKU_ALREADY_USED');
          setStatus('warning');
        } else if (err.message === 'Default price is required for price type'
                   || err.message === 'Price type not found') {
          setError('ERROR_PRICE_TYPES_CHANGED');
          invalidatePriceTypeList();
          clearForm();
        }
      } else setError('UNEXPECTED_ERROR');
    }
  };

  const onSelectSubCategory = (selection: IndexPath) => {
    setBusinessCategorySelection(selection.row);
  };

  const onSetSkuName = useCallback((value: React.SetStateAction<string>) => {
    setSkuName(value);
    setStatus('basic');
  }, []);

  const sortedFormulations = Object.values(FormulationType).sort();
  const handleSelectFormulation = useCallback((selection: IndexPath | IndexPath[]) => {
    setFormulation(sortedFormulations[(selection as IndexPath).row]);
  }, [sortedFormulations]);

  const onPricesChanged = useCallback((newPrices: ApiPrice[]) => {
    setPrices(newPrices);
  }, []);

  const getModalTitle = (): string => {
    if (isVisible) {
      if (!productId) {
        return `${translate(`CREATE_${category}_PRODUCT`)}`;
      }
      return `${translate(`EDIT_${category}_PRODUCT`)}`;
    }
    return '';
  };

  const Footer = (
    submitModal: (cb: () => void) => void,
    toggleModal: () => void,
  ) => (
    <View style={styles.footer}>
      <Button
        appearance="outline"
        design="floating"
        onPress={() => {
          if (!productId) {
            clearForm();
          }
          toggleModal();
        }}
        status="basic"
        testID={`${category.toLowerCase()}-product-modal-cancel-button`}
      >
        {translate('CANCEL') as string}
      </Button>
      <HSpacer size="6" />
      <Button
        design="floating"
        disabled={!formValid()}
        onPress={() => (!productId
          ? submitModal(toggleModal)
          : setShowUpdateConfirmationModal(true))}
        testID={`${category.toLowerCase()}-product-modal-submit-button`}
      >
        {translate('SAVE') as string}
      </Button>
    </View>
  );

  const ProductFormPage = [
    <View style={{ alignItems: 'center', width: '100%' }}>
      {loading && <CenteredSpinner />}
      {!loading && (
        <View style={styles.container}>
          <KeyboardAwareScrollView>
            <Card
              style={{
                flex: 1,
                backgroundColor: 'transparent',
                shadowColor: 'transparent',
              }}
              testID="create-product-form"
            >
              <Text category="label">
                {`${translate('PRODUCT_DETAILS')}`}
              </Text>
              <VSpacer size="9" />
              <Input
                isRequired
                label={`${translate('PRODUCT_NAME')}`}
                onChangeText={setName}
                testID="product-name-field"
                value={name}
              />
              <VSpacer size="7" />
              <Input
                isRequired
                label={`${translate('SKU_NAME')}`}
                onChangeText={onSetSkuName}
                status={status}
                testID="sku-name-field"
                value={skuName}
              />
              <VSpacer size="7" />
              <Input
                label={`${translate('PRODUCT_ID')}`}
                maxLength={50}
                onChangeText={setExternalProductId}
                status={status}
                testID="external-product-id-field"
                value={externalProductId}
              />
              {subCategories.length > 1 && (
                <>
                  <VSpacer size="7" />
                  <Select
                    label={`${translate('SUB_CATEGORY')}`}
                    onSelect={(i) => onSelectSubCategory(i as IndexPath)}
                    testID="subcategory-selector"
                    value={subCategories.sort()[businessCategorySelection]?.name ?? translate<string>('NONE')}
                  >
                    {subCategories.map((item, index) => (
                      <SelectItem
                        key={item.id}
                        testID={`product-sub-category-dropdown-value-${index}`}
                        title={item.name}
                      />
                    ))}
                  </Select>
                </>
              )}

              <VSpacer size="7" />
              {!!productId && (
                <>
                  <Input
                    label={`${translate('MANUFACTURER')}`}
                    readonly
                    testID="product-manufacturer"
                    value={productManufacturer}
                  />
                  <Text
                    appearance="hint"
                    category="c1"
                    style={{ paddingLeft: 10, paddingTop: 8 }}
                  >
                    {translate<string>('MANUFACTURER_MESSAGE')}
                  </Text>
                  <VSpacer size="8" />
                </>
              )}
              {productId ? (
                <Input
                  caption={productId && `${translate('FORMULATION_UPDATE_WARNING')}`}
                  label={`${translate('FORMULATION')}`}
                  readonly
                  testID="formulation-selector"
                  value={`${translate(formulation)}`}
                />
              ) : (
                <Select
                  isRequired
                  label={`${translate('FORMULATION')}`}
                  onSelect={handleSelectFormulation}
                  testID="formulation-selector"
                  value={`${translate(formulation)}`}
                >
                  {sortedFormulations.map((item, index) => (
                    <SelectItem
                      key={item}
                      testID={`product-formuation-type-dropdown-value-${index}`}
                      title={`${translate(item)}`}
                    />
                  ))}
                </Select>
              )}
              <VSpacer size="12" />
              <Text category="label">{`${translate('PACKAGE')}`}</Text>
              <VSpacer size="9" />
              <Input
                isRequired
                label={`${translate('PACKAGE_NAME')}`}
                onChangeText={setPackageName}
                testID="package-name-field"
                value={packageName}
              />
              <VSpacer size="8" />
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <NumericInput
                    isRequired
                    label={`${translate('UNITS_PER_SKU')}`}
                    onChangeValue={setPackageUnitQuantity}
                    precision={3}
                    testID="package-unit-quantity-field"
                    value={packageUnitQuantity}
                  />
                </View>
                <HSpacer size="2" />
                <HSpacer size="6" />
                <View style={{ flex: 1, alignSelf: 'flex-end' }}>
                  <Select
                    disabled={!formulation || isSavedProductUoMAcreOrUnit}
                    isRequired
                    label={`${translate('UOM')}`}
                    onSelect={(sel: IndexPath | IndexPath[]) => setUnitUoM(
                      categoryUoMs[(sel as IndexPath).row],
                    )}
                    testID="uom-field"
                    value={`${translate(unitUoM)}`}
                  >
                    {categoryUoMs.map((uom, index) => (
                      <SelectItem
                        key={uom}
                        testID={`product-category-uom-dropdown-value-${index}-${translate(uom)}`}
                        title={`${translate(uom)}`}
                      />
                    ))}
                  </Select>
                </View>
              </View>
              {requiresLbsPerGal && (
                <>
                  <VSpacer size="8" />
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 1 }}>
                      <NumericInput
                        isRequired
                        label={`${translate('DENSITY')}`}
                        onChangeValue={setLbsPerGal}
                        precision={4}
                        testID="density-field"
                        value={lbsPerGal}
                      />
                    </View>
                    <HSpacer size="2" />
                    <HSpacer size="6" />
                    <View style={{ flex: 1, justifyContent: 'flex-start', marginBottom: 10 }}>
                      <VSpacer size="9" />
                      <Text category="p2">{translate<string>('LB_PER_GAL')}</Text>
                    </View>
                  </View>
                </>
              )}
              <VSpacer size="12" />
              <MultiplePricingTable
                businessLocations={businessLocations}
                onPricesChanged={onPricesChanged}
                priceTypes={priceTypes}
                prices={prices}
              />
            </Card>
          </KeyboardAwareScrollView>
        </View>
      )}
    </View>,
  ];

  return (
    <>
      {showUpdateConfirmationModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('YES_UPDATE')}
          onCancel={() => {
            setShowUpdateConfirmationModal(false);
          }}
          onConfirm={() => {
            onSubmit(modalToggle);
            setShowUpdateConfirmationModal(false);
          }}
          status="warning"
          title={translate('UPDATE_PRODUCT')}
          visible={showUpdateConfirmationModal}
        />
      )}
      <LargeModal
        footer={() => Footer(onSubmit, modalToggle)}
        pages={ProductFormPage}
        subTitle={currentBusiness?.businessName}
        testID="product-large-modal"
        title={getModalTitle()}
        visible={isVisible}
      />
    </>
  );
};
