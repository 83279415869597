import { Modal } from '@ui-kitten/components';
import React, { FC } from 'react';
import { CenteredSpinner } from './CenteredSpinner';

interface Props {
  visible?: boolean;
}

export const ModalSpinner: FC<Props> = ({
  visible = true,
}) => (
  <Modal testID="spinning" visible={visible}>
    <CenteredSpinner size="giant" />
  </Modal>
);
