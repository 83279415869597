/* eslint-disable max-len */
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { View } from 'react-native';
import { Card, IndexPath } from '@ui-kitten/components';
import { Accordion, AccordionItem, Icon, IconName, IconNames, Select, SelectItem, Button } from '@design';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ExpandedState {
  [key: number]: () => void
}
const AccordionExpandedDemo: FunctionComponent = () => {
  const [iconRight, setIconRight] = useState<IconName>('AlertCircle');
  const [status, setStatus] = useState<Status>('basic');
  const [refs] = useState({
    accordionRef1: useRef<AccordionItem>(null),
    accordionRef2: useRef<AccordionItem>(null),
    accordionRef3: useRef<AccordionItem>(null),
    accordionRef4: useRef<AccordionItem>(null),
    accordionRef5: useRef<AccordionItem>(null),
  });

  const collapseAll = useCallback(() => {
    Object.values(refs).forEach((ref) => {
      ref?.current?.collapse();
    });
  }, [refs]);

  const expandAll = useCallback(() => {
    Object.values(refs).forEach((ref) => {
      ref?.current?.expand();
    });
  }, [refs]);

  const demoIconProps = {
    name: iconRight,
    status,
    testID: 'demo-icon',
  };

  return (
    <DemoContainer label="Accordion - Expanded" style={{ flexDirection: 'column' }}>
      <DemoBlock style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button
          appearance="outline"
          onPress={() => collapseAll()}
          size="medium"
          status="primary"
          testID="test-button"
        >
          Collapse all
        </Button>
        <Button
          appearance="outline"
          onPress={() => expandAll()}
          size="medium"
          status="primary"
          testID="test-button"
        >
          Expand all
        </Button>
        <Select
          label="accessoryRight"
          onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(IconNames[(ip as IndexPath).row - 1])}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
          testID="accordion-demo-accessory-right-selector"
          value={iconRight || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="accordion-demo-right-icon-dropdown-value" title="None" />,
            ...IconNames.map((n, i) => (
              <SelectItem key={n} testID={`accordion-demo-right-icon-name-dropdown-value-${i}`} title={n} />
            )),
          ]}
        </Select>
        <Select
          label="Accessory Status"
          onSelect={(ip: IndexPath | IndexPath[]) => setStatus(Statuses[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(Statuses.indexOf(status))}
          testID="accordion-demo-accessory-status-selector"
          value={status}
        >
          {Statuses.map((s, i) => (
            <SelectItem key={s} testID={`accordion-demo-accessory-status-dropdown-value-${i}`} title={s} />
          ))}
        </Select>
      </DemoBlock>
      <DemoBlock style={{ flex: 1 }}>
        <Accordion testID="accordion-demo-list">
          <AccordionItem
            accessoryRight={iconRight && ((props) => <Icon {...demoIconProps} {...props} />)}
            ref={refs.accordionRef1}
            testID="accordion-expanded-demo-item-1"
            title="1. Lorem ipsum dolor sit amet"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis placerat vestibulum. Nunc ac ex hendrerit, sollicitudin eros nec, congue nulla. Vestibulum efficitur neque ac odio aliquam maximus. Quisque quis viverra nulla. Vivamus malesuada, tellus sed cursus egestas, enim leo facilisis ipsum, eu fringilla leo libero quis velit. Morbi molestie augue purus, in volutpat diam elementum et. Maecenas sollicitudin faucibus dui sed vestibulum. Sed aliquet nisi ac est pharetra varius. Praesent eleifend eros eu nunc tincidunt viverra. Etiam pulvinar id sem non sodales.
          </AccordionItem>
          <AccordionItem
            accessoryRight={iconRight && ((props) => <Icon {...demoIconProps} {...props} />)}
            ref={refs.accordionRef2}
            testID="accordion-expanded-demo-item-2"
            title="2. Nulla eget maximus tortor"
          >
            Nulla eget maximus tortor. Integer fringilla mi sagittis semper varius. Nam quis augue magna. Nunc et mauris ut dui accumsan lobortis. Suspendisse congue lorem id lobortis sagittis. Phasellus et ligula eget magna semper euismod ac vel mi. Maecenas vel blandit tortor, ac auctor justo.
          </AccordionItem>
          <AccordionItem
            accessoryRight={iconRight && ((props) => <Icon {...demoIconProps} {...props} />)}
            ref={refs.accordionRef3}
            testID="accordion-expanded-demo-item-3"
            title="3. Aliquam nec tortor"
          >
            Aliquam nec tortor eu quam semper porta nec eu tortor. Ut quis blandit nunc. Suspendisse pretium sed metus quis consequat. Duis sagittis, nisi ac egestas consectetur, velit nulla euismod lacus, vitae hendrerit sem felis at tortor. Morbi accumsan, risus ut condimentum commodo, nibh augue rutrum lorem, ut bibendum augue ante quis tellus. Etiam suscipit at elit sit amet scelerisque. Suspendisse et scelerisque nisl.
          </AccordionItem>
          <AccordionItem
            accessoryRight={iconRight && ((props) => <Icon {...demoIconProps} {...props} />)}
            ref={refs.accordionRef4}
            testID="accordion-expanded-demo-item-4"
            title="4. Maecenas vitae posuere tortor"
          >
            Maecenas vitae posuere tortor. Vivamus volutpat sem sit amet rhoncus interdum. Phasellus vitae vestibulum felis, nec viverra nunc. Phasellus eget arcu sit amet eros faucibus accumsan quis at libero. Maecenas justo libero, dignissim vitae cursus nec, luctus vitae enim. Phasellus vestibulum vitae sem eu molestie. Nam id eros volutpat, vehicula ex sit amet, dapibus erat. Nullam vel mauris elit. Mauris rhoncus in magna vel accumsan. Vestibulum tempor congue aliquet. Fusce egestas, velit eu ullamcorper vestibulum, enim magna vulputate lectus, eget dapibus felis tortor et quam. Donec vitae ornare libero. Etiam lacus nulla, volutpat id pellentesque sed, aliquet at mauris. Donec pharetra, turpis ac venenatis rutrum, est magna vehicula turpis, sit amet malesuada ex eros non est. Integer feugiat vel diam vel dictum. Aliquam nisl ante, ultrices nec auctor ac, mollis sed metus.
          </AccordionItem>
          <AccordionItem
            accessoryRight={iconRight && ((props) => <Icon {...demoIconProps} {...props} />)}
            ref={refs.accordionRef5}
            testID="accordion-expanded-demo-item-5"
            title="5. Phasellus ut diam"
          >
            Phasellus ut diam at ex tincidunt bibendum. Cras mi orci, porttitor nec gravida ut, tempor ut ipsum. Proin interdum, dolor ut dapibus feugiat, mauris enim accumsan sapien, et ornare arcu dolor ultricies tortor. Vivamus a convallis risus. Nulla in dui in felis fringilla eleifend a a leo. Nam quis quam sapien. Praesent consequat mi quis consequat lacinia. Vestibulum bibendum nibh nec gravida ultricies. Curabitur in ligula vel mi sodales consequat at eu ex. Pellentesque turpis metus, congue id nunc vitae, lacinia gravida mauris. Vivamus at ipsum quis velit vestibulum imperdiet. Donec posuere fringilla dolor.
          </AccordionItem>
        </Accordion>
      </DemoBlock>
    </DemoContainer>
  );
};

export const AccordionDemo: FunctionComponent = () => (
  <View style={{ alignSelf: 'stretch' }}>
    <Card testID="test-card">
      <DemoContainer label="Default (all collapsed)">
        <DemoBlock style={{ flex: 1 }}>
          <Accordion testID="accordion-collapsed-demo-list">
            <AccordionItem testID="accordion-collapsed-demo-item-1" title="1. Lorem ipsum dolor sit amet">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis placerat vestibulum. Nunc ac ex hendrerit, sollicitudin eros nec, congue nulla. Vestibulum efficitur neque ac odio aliquam maximus. Quisque quis viverra nulla. Vivamus malesuada, tellus sed cursus egestas, enim leo facilisis ipsum, eu fringilla leo libero quis velit. Morbi molestie augue purus, in volutpat diam elementum et. Maecenas sollicitudin faucibus dui sed vestibulum. Sed aliquet nisi ac est pharetra varius. Praesent eleifend eros eu nunc tincidunt viverra. Etiam pulvinar id sem non sodales.
            </AccordionItem>
            <AccordionItem testID="accordion-collapsed-demo-item-2" title="2. Nulla eget maximus tortor">
              Nulla eget maximus tortor. Integer fringilla mi sagittis semper varius. Nam quis augue magna. Nunc et mauris ut dui accumsan lobortis. Suspendisse congue lorem id lobortis sagittis. Phasellus et ligula eget magna semper euismod ac vel mi. Maecenas vel blandit tortor, ac auctor justo.
            </AccordionItem>
          </Accordion>
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Initialize opened">
        <DemoBlock style={{ flex: 1 }}>
          <Accordion testID="accordion-init-expanded-list">
            <AccordionItem
              initExpanded
              testID="accordion-init-expanded-demo-item-1"
              title="1. Lorem ipsum dolor sit amet"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mollis placerat vestibulum. Nunc ac ex hendrerit, sollicitudin eros nec, congue nulla. Vestibulum efficitur neque ac odio aliquam maximus. Quisque quis viverra nulla. Vivamus malesuada, tellus sed cursus egestas, enim leo facilisis ipsum, eu fringilla leo libero quis velit. Morbi molestie augue purus, in volutpat diam elementum et. Maecenas sollicitudin faucibus dui sed vestibulum. Sed aliquet nisi ac est pharetra varius. Praesent eleifend eros eu nunc tincidunt viverra. Etiam pulvinar id sem non sodales.
            </AccordionItem>
            <AccordionItem
              initExpanded
              testID="accordion-init-expanded-demo-item-2"
              title="2. Nulla eget maximus tortor"
            >
              Nulla eget maximus tortor. Integer fringilla mi sagittis semper varius. Nam quis augue magna. Nunc et mauris ut dui accumsan lobortis. Suspendisse congue lorem id lobortis sagittis. Phasellus et ligula eget magna semper euismod ac vel mi. Maecenas vel blandit tortor, ac auctor justo.
            </AccordionItem>
          </Accordion>
        </DemoBlock>
      </DemoContainer>
      <AccordionExpandedDemo />
    </Card>
  </View>
);
