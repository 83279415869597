import { Card, IndexPath } from '@ui-kitten/components';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Chip, Icon, IconName, IconNames, Input, Select, SelectItem } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const ChipDemo: FunctionComponent = () => {
  const [text, setText] = useState<string>('Label');
  const [iconLeft, setIconLeft] = useState<IconName>(null);
  const [iconRight, setIconRight] = useState<IconName>(null);
  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer>
          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
            <Select
              label="accessoryLeft"
              onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
                IconNames[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)}
              testID="chip-demo-accessory-left-selector"
              value={iconLeft || 'None'}
            >
              {[
                <SelectItem key="noicon" testID="chip-demo-icon-left" title="None" />,
                ...IconNames.map((n, i) => (
                  <SelectItem key={n} testID={`chip-demo-icon-left-name-${i}`} title={n} />
                )),
              ]}
            </Select>
            <Input
              label="Chip Text"
              onChangeText={setText}
              testID="test-input"
              value={text}
            />
            <Select
              label="accessoryRight"
              onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
                IconNames[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
              testID="chip-demo-accessory-right-selector"
              value={iconRight || 'None'}
            >
              {[
                <SelectItem key="noicon" testID="chip-demo-icon-right" title="None" />,
                ...IconNames.map((n, i) => (
                  <SelectItem key={n} testID={`chip-demo-icon-right-name-${i}`} title={n} />
                )),
              ]}
            </Select>
          </View>
        </DemoContainer>
      </Card>

      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="Chips">
          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
            <DemoBlock label="Default">
              <Chip
                accessoryLeft={iconLeft && ((props) => <Icon name={iconLeft} testID="demo-chip-1-left-icon" {...props} />)}
                accessoryRight={iconRight && ((props) => <Icon name={iconRight} testID="demo-chip-1-right-icon" {...props} />)}
                onPress={() => {}}
                testID="demo-chip-1"
              >
                {text}
              </Chip>
            </DemoBlock>
            <DemoBlock label="Disabled">
              <Chip
                accessoryLeft={iconLeft && ((props) => <Icon name={iconLeft} testID="demo-chip-2-left-icon" {...props} />)}
                accessoryRight={iconRight && ((props) => <Icon name={iconRight} testID="demo-chip-2-right-icon" {...props} />)}
                disabled
                onPress={() => {}}
                testID="demo-chip-2"
              >
                {text}
              </Chip>
            </DemoBlock>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            <Chip
              accessoryLeft={iconLeft && ((props) => <Icon name={iconLeft} testID="demo-chip-3-left-icon" {...props} />)}
              accessoryRight={iconRight && ((props) => <Icon name={iconRight} testID="demo-chip-3-right-icon" {...props} />)}
              onPress={() => {}}
              testID="demo-chip-3"
            >
              {text}
            </Chip>

            <Chip
              accessoryLeft={iconLeft && ((props) => <Icon name={iconLeft} testID="demo-chip-4-left-icon" {...props} />)}
              accessoryRight={iconRight && ((props) => <Icon name={iconRight} testID="demo-chip-4-right-icon" {...props} />)}
              disabled
              onPress={() => {}}
              testID="demo-chip-4"
            >
              {text}
            </Chip>
          </View>
        </DemoContainer>
      </Card>

    </View>
  );
};
