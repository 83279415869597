import React, { useCallback } from 'react';
import { Toast, ToastElement, ToastProps } from '../../Toast/Toast';

export interface ToastManagerPresenting {
  add(element: ToastElement, id: string): void;
  remove(id: string): void;
}

/**
 * Toast manger handles creating Toast components and mounting them to the dom so that
 * Toast can be created functionally vs compositionally
 */
class ToastManagerType {
  panel: ToastManagerPresenting | null = null;

  public mount (panel: ToastManagerPresenting | null): void {
    this.panel = panel;
  }

  public unmount (): void {
    this.panel = null;
  }

  public generateUniqueComponentKey = (): string => {
    return Math.random().toString(36).substring(2);
  };

  public create (props: ToastProps, keyOverride?: string): string {
    if (this.panel) {
      const key: string = keyOverride || this.generateUniqueComponentKey();

      this.panel.add((
        <Toast
          {...props}
          onClose={() => this.panel.remove(key)}
        />
      ), key);

      return key;
    }
    return '';
  }

  public destroy (id: string) {
    this.panel.remove(id);
  }
}

export const ToastManager = new ToastManagerType();

// bind here to get references correct and avoid another function that has
// the same parameters as ToastManager methods.
export const useToast = () => {
  const createToast = useCallback((props: ToastProps | string, idOverride?: string) => {
    let toastProps: ToastProps;
    if (typeof props === 'string') {
      toastProps = {
        children: props,
        testID: 'toast-content-element',
      };
    } else {
      toastProps = props;
    }
    return ToastManager.create(toastProps, idOverride);
  }, []);

  const destroyToast = useCallback((toastId: string) => ToastManager.destroy(toastId), []);

  return { createToast, destroyToast };
};
