import { FilterMenuOptions } from '@design/FilterMenu/FilterMenu';
import { FarmUtility, generateId } from '@shared/utils';
import { ApiCropLogic, ApiFarmPlan, ApiPlanningParameter } from '@shared/interfaces/api';
import _ from 'lodash';
import {
  Button,
  Filter,
  FilterCategory,
  Header,
  Icon,
  Text,
  VSpacer,
} from '@design';
import { CropLogicResult, PlannedFarm } from '@shared/interfaces';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { FarmCard } from './FarmCard';
import { FarmPlanProgramSidePanel } from '../../../FarmPlan/FarmPlanProgramSidePanel';
import { ProgramForm } from './Program/ProgramForm';

interface FarmPlanProgramAssignmentProps {
  createMode: boolean,
  cropLogics: ApiCropLogic[],
  farmPlan: ApiFarmPlan,
  plannedFarms: PlannedFarm[],
  planningParameters: ApiPlanningParameter[],
  setFarmPlan(
    newFarmPlan: ApiFarmPlan,
    updateInitialFarmPlan: boolean,
    newFarmsWithPrograms?: PlannedFarm[] | null,
  ): void,
}

export interface AreaSelectionProps {
  [id: string]: boolean,
}

interface FilterProps {
  application?: string[]
  crops?: string[],
  search?: string,
}

export const FarmPlanProgramAssignment = ({
  cropLogics,
  farmPlan,
  plannedFarms,
  planningParameters,
  setFarmPlan,
}: FarmPlanProgramAssignmentProps) => {
  const [translate] = useTranslation(['common', 'farmPlans', 'prepare']);
  const defaultFilters = ['application', 'crops'];
  const [expanded, setExpanded] = useState(false);
  const [areaSelections, setAreaSelections] = useState<AreaSelectionProps>({});
  const [filter, setFilter] = useState<FilterProps>({});
  const [farms, setFarms] = useState(plannedFarms);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showCreateEditProgram, setShowCreateEditProgram] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<ApiCropLogic>(null);
  const [canUseInitialName, setCanUseInitialName] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState<string>(null);
  const [showAssignProgram, setShowAssignProgram] = useState(false);
  const [applyProgramToAllFarms, setApplyProgramToAllFarms] = useState(false);
  const [farmToApplyProgram, setFarmToApplyProgram] = useState(null);
  const [filterCategories, setFilterCategories] = useState<FilterCategory[]>([
    {
      columnLabel: translate<string>('APPLICATION'),
      columnKey: 'application',
      columns: [
        {
          label: translate('APPLIED'),
          id: 'true',
        },
        {
          label: translate('NOT_APPLIED'),
          id: 'false',
        },
      ],
      preserve: true,
    },
    {
      columnLabel: translate<string>('CROP'),
      columnKey: 'crops',
      columns: [],
      preserve: true,
    },
  ]);

  const [filteredFarms, setFilterFarms] = useState<PlannedFarm[]>([]);

  useEffect(() => {
    if (plannedFarms?.length >= 1) {
      if (farms.length !== plannedFarms.length) {
        setFarms(plannedFarms);
      }
      const programs = plannedFarms.map(FarmUtility.getCropLogicsForFarm).flat();
      const cropColumns = _.uniqBy(programs.reduce((init, program) => {
        const { cropType, cropSubType } = program;
        if (cropType && cropSubType) {
          init.push({
            label: `${translate<string>(cropType)} | ${translate<string>(cropSubType)}`,
            id: FarmUtility.getCrop(program),
          });
        }
        return init;
      }, [] as FilterMenuOptions[]), 'id');
      setFilterCategories((prevFilterCategories) => prevFilterCategories.map((filterCategory) => {
        if (filterCategory.columnKey === 'crops') {
          return {
            ...filterCategory,
            columns: cropColumns,
          };
        }
        return filterCategory;
      }));
    }
  }, [farms.length, plannedFarms, translate]);

  useEffect(() => {
    setFarms(plannedFarms);
  }, [plannedFarms]);

  useEffect(() => {
    if (farms?.length) {
      if (!filter || !Object.keys(filter).length) {
        setFilterFarms(farms);
        return;
      }

      const { search, ...otherFilters } = filter;
      const newFilteredFarms = farms.filter((farm) => {
        const validSearch = !search ? true : FarmUtility.farmMatchesSearch(farm, search);

        let validFilter = true;
        if (Object.keys(otherFilters).length) {
          const application = !otherFilters.application
            ? []
            : otherFilters.application.map((v) => v === 'true');

          if (otherFilters.crops) {
            const programs = plannedFarms.map(FarmUtility.getCropLogicsForFarm).flat();
            otherFilters.crops = otherFilters.crops.filter(
              (crop) => programs.find((p) => FarmUtility.getCrop(p) === crop),
            );

            if (!otherFilters.crops.length) {
              otherFilters.crops = null;
            }
          }

          validFilter = FarmUtility.farmMatchesFilters(farm, {
            ...otherFilters,
            application,
            crop: otherFilters.crops,
          });
        }

        return validSearch && validFilter;
      });
      setFilterFarms(newFilteredFarms);
    }
  }, [farms, filter, plannedFarms]);

  const handleEditProgram = useCallback(async (program: ApiCropLogic, areaId: string) => {
    setSelectedAreaId(areaId);
    setSelectedProgram(program);
    setShowCreateEditProgram(true);

    const sameProgramCount = farms.reduce((acc, farmWithProgram) => (
      farmWithProgram.growerFields.reduce((subAcc, field) => {
        if (field.cropLogic?.id === program.id) {
          subAcc += 1;
        }

        if (field.cropZones.length) {
          field.cropZones.forEach((cropZone) => {
            if (cropZone.cropLogic?.id === program.id) {
              subAcc += 1;
            }
          });
        }

        return subAcc;
      }, acc)
    ), 0);

    setCanUseInitialName(sameProgramCount === 1);
  }, [farms]);

  const toggleAllSelections = useCallback(() => {
    const newAreaSelections = {};
    filteredFarms.forEach((farm) => {
      farm.growerFields.forEach((growerField) => {
        newAreaSelections[growerField.id] = !isSelectAll;
        growerField.cropZones.forEach((cropZone) => {
          newAreaSelections[cropZone.id] = !isSelectAll;
        });
      });
    });
    setAreaSelections(newAreaSelections);
  }, [filteredFarms, isSelectAll]);

  useEffect(() => {
    const allSelected = filteredFarms.every((farm) => {
      const allFieldsSelected = farm.growerFields.every(
        (growerField) => areaSelections[growerField.id],
      );
      return allFieldsSelected;
    });
    setIsSelectAll(allSelected);
  }, [areaSelections, filteredFarms]);

  const isFieldSelected = useMemo(
    () => Object.values(areaSelections).some((v) => v),
    [areaSelections],
  );

  const getProgramMaxNumber = useCallback((
    programToAssign: CropLogicResult,
    selectedAreas: AreaSelectionProps,
    farmId?: string,
  ) => {
    const getCurrentNumber = (name: string) => (
      Number(name.match(/ - (\d+)$/)?.[1] ?? '1')
    );

    let programMaxNumber = 0;
    const programName = programToAssign.logicName.replace(/ - \d+$/, '');
    const regex = new RegExp(`${programName}(?: - \\d+)?$`);

    farms.forEach((farmWithProgram) => {
      const isProgramInUse = (id: string, name: string) => (
        (!selectedAreas[id] || (!!farmId && farmWithProgram.id !== farmId))
        && regex.test(name)
      );

      let currentName = '';
      farmWithProgram.growerFields.forEach((field) => {
        currentName = field.cropLogic?.logicName ?? '';
        if (isProgramInUse(field.id, currentName)) {
          const currentNumber = getCurrentNumber(currentName);
          if (currentNumber > programMaxNumber) {
            programMaxNumber = currentNumber;
          }
        }

        if (field.cropZones.length) {
          field.cropZones.forEach((cropZone) => {
            currentName = cropZone.cropLogic?.logicName ?? '';
            if (isProgramInUse(cropZone.id, currentName)) {
              const currentNumber = getCurrentNumber(currentName);
              if (currentNumber > programMaxNumber) {
                programMaxNumber = currentNumber;
              }
            }
          });
        }
      });
    });

    return programMaxNumber;
  }, [farms]);

  const applyProgram = useCallback((
    programToAssign: CropLogicResult,
    queryAreaSelections?: {},
    isEditing = false,
    businessLevel = false,
  ) => {
    const assignedCrop = cropLogics.find(({ id }) => id === programToAssign.id);

    if (assignedCrop && !isEditing) {
      programToAssign = {
        ...programToAssign,
        ...assignedCrop,
      };
    }

    setShowAssignProgram(false);

    const programLogic = programToAssign;
    delete programToAssign.scenarioParameters;

    const selectedAreas = queryAreaSelections ?? areaSelections;

    const programMaxNumber = businessLevel ? getProgramMaxNumber(
      programToAssign,
      selectedAreas,
      applyProgramToAllFarms ? undefined : farmToApplyProgram,
    ) + 1 : 1;

    const newProgram = businessLevel ? {
      ...programLogic,
      id: `LOCAL-${generateId()}`,
      logicName: `${programLogic.logicName}${programMaxNumber === 1 ? '' : ` - ${programMaxNumber}`}`,
    } : programLogic;

    const newFarmsWithPrograms = plannedFarms.map((farm) => {
      if (!queryAreaSelections && !applyProgramToAllFarms && farm.id !== farmToApplyProgram) {
        return farm;
      }

      const farmChanged = farm.growerFields.some((field) => (
        !!selectedAreas[field.id]
        || field.cropZones.some((cropZone) => selectedAreas[cropZone.id])
      ));

      if (!farmChanged) {
        return farm;
      }

      const newFarm = _.cloneDeep(farm);
      newFarm.growerFields.forEach((field) => {
        if (!field.cropZones.length && selectedAreas[field.id]) {
          field.cropLogic = newProgram;
        } else if (field.cropZones.length) {
          field.cropZones.forEach((cropZone) => {
            if (selectedAreas[cropZone.id]) {
              cropZone.cropLogic = newProgram;
            }
          });
        }
      });

      return newFarm;
    });

    setAreaSelections({});
    setFarms(newFarmsWithPrograms);

    setFarmPlan(farmPlan, false, newFarmsWithPrograms);
  }, [
    applyProgramToAllFarms,
    areaSelections,
    cropLogics,
    farmPlan,
    farmToApplyProgram,
    getProgramMaxNumber,
    plannedFarms,
    setFarmPlan,
  ]);

  const handleRemoveProgram = useCallback(async (areaId: string) => {
    const newFarmsWithPrograms = filteredFarms.map((farmWithProgram) => {
      const newFarm = { ...farmWithProgram };
      newFarm.growerFields.forEach((field) => {
        if (field.id === areaId) {
          field.cropLogic = null;
        } else if (field.cropZones.length) {
          field.cropZones.forEach((cropZone) => {
            if (cropZone.id === areaId) {
              cropZone.cropLogic = null;
            }
          });
        }
      });

      return newFarm;
    });

    setFarms(newFarmsWithPrograms);

    setFarmPlan(farmPlan, false, newFarmsWithPrograms);
  }, [farmPlan, filteredFarms, setFarmPlan]);

  const handleSaveProgram = useCallback((program: ApiCropLogic, applyToAll: boolean) => {
    if (!selectedProgram || !applyToAll) {
      program.id = `LOCAL-${generateId()}`;
    }

    if (selectedProgram) {
      let newSelectedAreas = { [selectedAreaId]: true };
      if (applyToAll) {
        farms.forEach((farmWithProgram) => {
          farmWithProgram.growerFields.forEach((field) => {
            if (field.cropLogic?.id === program.id) {
              newSelectedAreas = { ...newSelectedAreas, [field.id]: true };
            }

            if (field.cropZones.length) {
              field.cropZones.forEach((cropZone) => {
                if (cropZone.cropLogic?.id === program.id) {
                  newSelectedAreas = { ...newSelectedAreas, [cropZone.id]: true };
                }
              });
            }
          });
        });
      }

      applyProgram(program, newSelectedAreas, true);
      setSelectedProgram(null);
      setSelectedAreaId(null);
    } else {
      applyProgram(program);
    }

    setShowCreateEditProgram(false);
  }, [applyProgram, farms, selectedAreaId, selectedProgram]);

  const handleProgramSelected = useCallback((program: ApiCropLogic, businessLevel: boolean) => {
    applyProgram(program, undefined, false, businessLevel);
  }, [applyProgram]);

  return (
    <>
      <Header
        level="2"
        subTitle={translate('PROGRAM_ASSIGNMENT_SUB_TITLE')}
        subTitleHint
        testID="program-assignment-header"
        title={translate<string>('PROGRAM_ASSIGNMENT')}
      />
      <Filter
        accessoryRight={(filterProps) => (
          <Button
            {...filterProps}
            accessoryLeft={(buttonProps) => <Icon {...buttonProps} name="Plus" testID="apply-program-to-all-selected-button-icon" />}
            appearance="outline"
            disabled={!isFieldSelected}
            onPress={() => {
              setShowAssignProgram(true);
              setApplyProgramToAllFarms(true);
            }}
            size="medium"
            testID="apply-program-to-all-selected-button"
          >
            {translate<string>('APPLY_PROGRAM_TO_ALL_SELECTED_ITEMS')}
          </Button>
        )}
        defaultColumnFilters={defaultFilters}
        expandAllAppearance="default"
        filterOptions={filterCategories}
        isSelectAll={isSelectAll}
        noFilters={false}
        onExpand={setExpanded}
        onSelectAll={toggleAllSelections}
        onUpdateFilter={setFilter}
        showExpandAllOption
        showSelectAll
        testID="farm-plan-program-assignment-filter"
        totalResults={filteredFarms.length}
        totalResultsText={translate('FARMS_COUNT', { count: filteredFarms.length })}
      />
      <VSpacer size="5" />
      {filteredFarms?.length ? filteredFarms.map((farm, index) => (
        <Fragment key={farm.farmName}>
          <FarmCard
            expanded={expanded}
            index={index}
            onAssignProgram={() => {
              setShowAssignProgram(true);
              setFarmToApplyProgram(farm.id);
            }}
            onChangeSelections={setAreaSelections}
            onEditProgram={handleEditProgram}
            onRemoveProgram={handleRemoveProgram}
            plannedFarm={farm}
            planningParameters={planningParameters}
            selections={areaSelections}
            testID="farm-card"
          />
          {filteredFarms.length > 1 && <VSpacer size="5" />}
        </Fragment>
      )) : (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text>
            {translate<string>('NO_FARMS_MSG')}
          </Text>
        </View>
      )}
      <FarmPlanProgramSidePanel
        allAssignedPrograms={cropLogics}
        boundaryLinks={farmPlan.growerFarmPlanLogicBoundaryLinks}
        onClose={() => {
          setShowAssignProgram(false);
          setFarmToApplyProgram(null);
          setApplyProgramToAllFarms(false);
        }}
        onCreateProgram={() => setShowCreateEditProgram(true)}
        onProgramSelected={handleProgramSelected}
        visible={showAssignProgram}
      />
      {showCreateEditProgram && (
        <ProgramForm
          assignedPrograms={cropLogics}
          businessLocationId={farmPlan.businessLocationId}
          canUseInitialName={canUseInitialName}
          customerId={farmPlan.growerId}
          onCancel={() => {
            setShowCreateEditProgram(false);
            setSelectedProgram(null);
            setSelectedAreaId(null);
          }}
          onSave={handleSaveProgram}
          priceTypeId={farmPlan.priceTypeId}
          program={selectedProgram}
        />
      )}
    </>
  );
};
