import { DataPoint, ViewRow, VSpacer } from '@design';
import { IntegrationType } from '@shared/enums';
import { CropTypeAndSubtype } from '@shared/interfaces';
import { ApiFarmPlan } from '@shared/interfaces/api';
import { toShortDate } from '@shared/utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../hooks/useIntegration';
import { ExternalIdsDisplay } from '../../../components/shared/IntegrationOrder/ExternalIdsDisplay';
import { RowDetailCropSummary } from './RowDetailCropSummary';
import { RowDetailProductSummary } from './RowDetailProductSummary';

export const FarmPlanListRowDetails: FC<{ farmPlan: ApiFarmPlan }> = (
  { farmPlan },
) => {
  const {
    createdAt,
    updatedAt,
    businessLocation,
    cropYear,
  } = farmPlan;
  const [translate] = useTranslation(['common', 'farmPlans', 'integration', 'prepare']);
  const { currentBusinessId } = useAuthentication();
  const { integration } = useIntegration({ businessId: currentBusinessId });
  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );
  const uniqueCrops = new Set<CropTypeAndSubtype>();
  farmPlan.cropLogics?.forEach((cl) => {
    uniqueCrops.add({
      cropType: cl.cropType,
      cropSubType: cl.cropSubType,
    });
  });

  const productInfo = (farmPlan.growerFarmPlanProductSummaries ?? []).map((summary) => ({
    applicationRate: summary.avgRateApplied,
    applicationRateUom: summary.avgRateAppliedUom,
    externalDisplayId: summary.externalDisplayId,
    id: summary.product.id,
    name: summary.product.name,
    skuName: summary.product.skuName,
  }));

  const externalDisplayIds = Array.from(
    new Set(productInfo.map((info) => info.externalDisplayId)),
  ).filter(Boolean).sort();

  return (
    <>
      <ViewRow>
        <DataPoint
          label={translate<string>('BUSINESS_LOCATION')}
          style={{ flex: 1 }}
          testID="business-location"
        >
          {businessLocation?.locationName ?? translate<string>('NOT_APPLICABLE')}
        </DataPoint>
        <DataPoint
          label={translate<string>('CROP_YEAR')}
          style={{ flex: 1 }}
          testID="crop-year"
        >
          {cropYear}
        </DataPoint>
        <DataPoint
          label={translate<string>('CROP_TYPE')}
          style={{ flex: 1 }}
          testID="crop-type"
        >
          <RowDetailCropSummary cropInfo={Array.from(uniqueCrops)} />
        </DataPoint>
        <DataPoint
          label={translate<string>('PRODUCTS_AND_APPLICATIONS')}
          style={{ flex: 1.5 }}
          testID="products"
        >
          <RowDetailProductSummary productInfo={productInfo} />
        </DataPoint>
      </ViewRow>
      <VSpacer size="9" />
      <ViewRow>
        <DataPoint
          label={translate<string>('DATE_CREATED')}
          style={{ flex: 1 }}
          testID="date-created"
        >
          {toShortDate(createdAt)}
        </DataPoint>
        <DataPoint
          label={translate<string>('DATE_UPDATED')}
          style={{ flex: 1 }}
          testID="date-updated"
        >
          {toShortDate(updatedAt)}
        </DataPoint>
        {hasBusinessCentralIntegration && (
          <DataPoint
            flex
            label={translate<string>('EXTERNAL_ID')}
            testID="data-point-external-id"
          >
            <ExternalIdsDisplay externalIds={externalDisplayIds} />
          </DataPoint>
        )}
      </ViewRow>
    </>
  );
};
