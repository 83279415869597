import { ApiForm, ApiFormField } from '@shared/interfaces/api';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { PropertiesList } from './PropertiesList';
import { Button, Card, Text, VSpacer } from '@design';
import { PropertyEditor } from './PropertyEditor';
import DefaultProperty from './DefaultProperty';
interface PropertyListPageProps {
  data: ApiForm,
  setPropertyGroup: React.Dispatch<React.SetStateAction<ApiForm>>,
}

const styles = StyleSheet.create({
  pageHeader: {
    flex:1,
    marginLeft: 36,
    marginRight: 36,
    marginTop: 24,
  },
  pageContainer: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 36,
    marginRight: 36,
  },
  listHeader: {
    flex: 1,
    top: -16,
    flexDirection: 'row',
    minWidth: '30%',
  },
  listContainer: {
    width: '30%',
    marginLeft: 36,
  },
  addButtonStyle: {
    position: 'absolute',
    right: 0, 
    width: 59, 
    padding: 8,
  },
  editorCard: {
    position: 'absolute', 
    top: 20, 
    left: '30%', 
    width: '65%',
    marginLeft: 48,
  },
});

export const PropertyListPage = ({
  data,
  setPropertyGroup,
}:PropertyListPageProps) => {
  const [translate] = useTranslation(['common']);
  const [selectedProperty, setSelectedProperty] = useState<ApiFormField>(data?.fields?.length ? data.fields[0] : null);
  const [properties, setProperties] = useState<ApiFormField[]>(
    (!data?.fields?.length) ? [] : data?.fields,
  );

  useEffect(() => {
    setPropertyGroup({
      ...data,
      fields: properties,
    });  
    // Disable the lint rule here to prevent a circular dependency with the data prop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [properties, setPropertyGroup]);

  const addNewProperty = () => {
    const newProperty = {
      ...DefaultProperty,
      order: properties.length,
    };
    setProperties([...properties, newProperty]);
    setSelectedProperty(newProperty);
  };

  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      <View style={styles.pageHeader}>
        <Text category="h6">{data.name}</Text>
        <VSpacer size="2" />
        <Text
          appearance="hint" 
          category="p2"
        >
          {data?.description}
        </Text>
      </View>
      <VSpacer size="6" />
      <View>
        <View style={styles.listContainer}>
          <View style={styles.listHeader}>
            <Text category="label" style={{ marginTop: 6 }}>
              {translate<string>('PROPERTIES')}
            </Text>
            <Button
              appearance="ghost"
              onPress={() => addNewProperty()}
              size="tiny"
              style={styles.addButtonStyle}
              testID="add-property-button"
            >
              +{translate<string>('ADD')}
            </Button>
          </View>
          <View>
            <PropertiesList
              propertyList={properties}
              selectedProperty={selectedProperty}
              setProperties={setProperties}
              setSelectedProperty={setSelectedProperty}
            />
          </View>
        </View>
        <Card 
          style={styles.editorCard}
          testID="property-editor-card"
        >
          <PropertyEditor
            addProperty={addNewProperty}
            formField={selectedProperty}
            setProperty={(index, property) => {
              const props = [...properties];
              props[index] = property;
              setProperties(props);
            }}
          />
        </Card>
      </View>
    </View>
  );
};
