import * as _ from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  View,
} from 'react-native';
import { Sizing, ThemeColors } from '../../../constants';
import { NumberInputUtils } from '../../utils/NumberInputUtils';

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: ThemeColors.DARK_GRAY,
    borderTopLeftRadius: Sizing.QUARTER_SPACING,
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    marginBottom: Sizing.DOUBLE_SPACING,
    borderBottomColor: ThemeColors.DARK_GRAY,
    borderBottomWidth: 3,
  },
  input: {
    color: ThemeColors.TEXT,
    fontFamily: 'Roboto',
    fontSize: 1.1 * Sizing.BASE_SPACING,
    paddingHorizontal: Sizing.BASE_SPACING,
    paddingTop: 1.75 * Sizing.BASE_SPACING,
    paddingBottom: Sizing.QUARTER_SPACING,
  },
  focused: { borderBottomColor: ThemeColors.PRIMARY },
  danger: { borderBottomColor: ThemeColors.DANGER },
  label: {
    color: ThemeColors.INPUT,
    fontSize: 0.8 * Sizing.EM,
    position: 'absolute',
    top: Sizing.HALF_SPACING,
    left: Sizing.BASE_SPACING,
  },
  caption: {
    position: 'absolute',
    top: 3.5 * Sizing.BASE_SPACING,
    fontSize: 0.8 * Sizing.EM,
    marginTop: 0.5 * Sizing.QUARTER_SPACING,
    color: ThemeColors.INPUT,
    fontStyle: 'italic',
    paddingLeft: Sizing.BASE_SPACING,
  },
  limit: {
    position: 'absolute',
    right: 0,
    top: 0 - Sizing.EM - 4,
    fontSize: 0.8 * Sizing.EM,
    marginTop: 0.5 * Sizing.QUARTER_SPACING,
    color: ThemeColors.INPUT,
    fontStyle: 'italic',
    paddingLeft: Sizing.BASE_SPACING,
  },
  focusedText: { color: ThemeColors.PRIMARY },
  dangerText: { color: ThemeColors.DANGER },
  inline: { marginBottom: 0 },
  inlineInput: {
    paddingTop: Sizing.HALF_SPACING,
    paddingBottom: Sizing.HALF_SPACING,
  },
  currencyContainer: {
    color: ThemeColors.TEXT,
    fontSize: 1.1 * Sizing.BASE_SPACING,
    position: 'absolute',
    top: 1.75 * Sizing.BASE_SPACING,
    left: Sizing.BASE_SPACING,
  },
  currencyContainerInline: {
    top: Sizing.HALF_SPACING,
  },
  withCurrency: {
    paddingLeft: 2 * Sizing.BASE_SPACING,
  },
  withPercent: {
    paddingLeft: 2.25 * Sizing.BASE_SPACING,
  },
});

export enum InputType {
  TEXT,
  NUMERIC,
  CURRENCY,
  PERCENTAGE,
}

export interface InputProps extends TextInputProps {
  label?: string;
  caption?: string;
  status?: 'danger';
  disabled?: boolean;
  inline?: boolean;
  type?: InputType;
  numericProps?: NumericInputProps;
  showLimit?: boolean;
  collapsed?: boolean;
  collapsedSuffix?: string;
  testID: string;
}

interface NumericInputProps {
  decimalPoints?: number;
  numericValue?: number;
  valueChanged: (value: number) => void;
}

export const StyledInput = (props: InputProps) => {
  const {
    type = InputType.TEXT,
    numericProps,
    onChangeText,
    ...rest
  } = props;
  const [focused, setFocused] = useState(false);
  const [suffix, setSuffix] = useState('');
  const prevValue = useRef(_.toNumber(rest.value));

  const changeText = useCallback((value: string) => {
    if (type === InputType.NUMERIC && numericProps) {
      // This logic is rediculous.
      // we should factor out a numeric input and migrate to that with more sane props
      // possibly having the input and output be number
      // if we create a number input/output we should also create a currency input
      // with string input/output that can format the trailing decimals.
      const { valueChanged, decimalPoints } = numericProps;
      setSuffix('');
      if (_.isEmpty(value)) {
        prevValue.current = null;
        valueChanged(null);
        return;
      }
      const newValue = _.toNumber(value);
      const separatorAllowed = decimalPoints > 0;
      if (_.isNaN(newValue)
        || value.indexOf('e') !== -1
        || (!separatorAllowed && value.indexOf('.') !== -1)) {
        return;
      }
      const hasTrailingZeroDecimals = value.match(NumberInputUtils.trailingDecimalZeroRegex);
      const digitCount = NumberInputUtils.getDecimalDigitCount(value);
      const endsWithDecimalSeparator = value.endsWith('.');

      // We store the suffix that does not actually change the value (. .0 .00 etc)
      // so the users input won't be reset on parsing those numbers
      if ((hasTrailingZeroDecimals || value.endsWith('.'))) {
        const parsedVal = parseFloat(value);
        const parsedDecimalLength = NumberInputUtils.getNumberDecimalDigitCount(parsedVal);
        const newSuffix = value.replace(_.toString(parsedVal), '');

        const allowedSuffixLength = newSuffix.startsWith('.')
          ? decimalPoints - parsedDecimalLength + 1
          : decimalPoints - parsedDecimalLength;
        setSuffix(newSuffix.substr(0, allowedSuffixLength));
      }

      if (!endsWithDecimalSeparator && digitCount <= decimalPoints) {
        prevValue.current = newValue;
        valueChanged(newValue);
      } else if (endsWithDecimalSeparator && prevValue.current !== newValue) {
        prevValue.current = newValue;
        valueChanged(newValue);
      }
    } else {
      onChangeText(value);
    }
  }, [type, numericProps, onChangeText]);

  /**
     * Returns the string that will be passed down to the RN TextInput as the value
     * @param inputType Type of the StyledInput
     * @param value The value that is passed from the
     * @param numberSuffix Suffix that is appended in case of an incomplete number(. trailing 0)
     */
  const getInputTextValue = (
    inputType: InputType,
    value: string,
    numberSuffix: string,
  ): string => {
    if (inputType === InputType.NUMERIC && numericProps) {
      if (!_.isNil(numericProps.numericValue)) {
        return _.toString(
          NumberInputUtils.roundNumber(numericProps.numericValue,
            numericProps.decimalPoints),
        ) + numberSuffix;
      }
      return '';
    }

    const textValue = (value?.length && (props.collapsed)
      ? `${(props.value as string).substring(0, 54)}${props.collapsedSuffix ?? ''}` : value);
    return textValue;
  };

  return (
    <View style={[
      styles.inputContainer,
      focused && styles.focused,
      props.status === 'danger' && styles.danger,
      props.inline && styles.inline,
      props.disabled && { opacity: 0.5 },
    ]}
    >
      {props.label && (
        <Text
          style={[
            styles.label,
            focused && styles.focusedText,
            props.status === 'danger' && styles.dangerText,
          ]}
        >
          {props.label}
        </Text>
      )}

      {type === InputType.CURRENCY && (
        <Text
          style={[
            styles.currencyContainer,
            props.inline && styles.currencyContainerInline,
            focused && styles.focusedText,
            props.status === 'danger' && styles.dangerText,
          ]}
        >
          <Text>$</Text>
        </Text>
      )}
      {type === InputType.PERCENTAGE && (
        <Text
          style={[
            styles.currencyContainer,
            props.inline && styles.currencyContainerInline,
            focused && styles.focusedText,
            props.status === 'danger' && styles.dangerText,
          ]}
        >
          <Text>%</Text>
        </Text>
      )}
      <TextInput
        {..._.omit(props, 'inline')}
        editable={!props.disabled}
        keyboardType={type === InputType.NUMERIC
          ? 'numeric'
          : rest.keyboardType || 'default'}
        onBlur={(e) => {
          setFocused(false);
          if (rest.onBlur) {
            rest.onBlur(e);
          }
        }}
        onChangeText={changeText}
        onFocus={(e) => {
          if (props.onFocus) {
            props.onFocus(e);
          }
          setFocused(true);
        }}
        style={[
          styles.input,
          props.inline && styles.inlineInput,
          props.style,
          type === InputType.CURRENCY && styles.withCurrency,
          type === InputType.PERCENTAGE && styles.withPercent,
        ]}
        value={
          getInputTextValue(type, props.value, suffix)
        }
      />
      {!props.inline && props.caption !== '' && (
        <Text
          style={
            [
              styles.caption,
              focused && styles.focusedText,
              props.status === 'danger' && styles.dangerText,
            ]
          }
        >
          {props.caption}
        </Text>
      )}
      {!_.isNil(props.showLimit) && props.maxLength && (
        <Text
          style={[
            styles.limit,
            focused && styles.focusedText,
            props.status === 'danger' && styles.dangerText,
          ]}
        >
          {(props.maxLength - (props.value?.length ?? 0)).toLocaleString()}
        </Text>
      )}
    </View>
  );
};
