import { ComboBox, useToast } from '@design';
import { SharedConfig } from '@shared/constants';
import { GrowerEndpoint } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { GrowerApi } from '../../../../utilities/api';

export interface CustomerSelectProps {
  customerId?: string,
  disabled?: boolean,
  onUpdateCustomer(customerId: string): void,
  readonly?: boolean,
  testID: string,
}

export const CustomerSelect = ({
  customerId,
  disabled,
  onUpdateCustomer,
  readonly,
  testID,
}: CustomerSelectProps) => {
  const [translate] = useTranslation(['errors', 'common']);
  const { currentBusinessId } = useAuthentication();
  const { createToast } = useToast();

  const { data: customers, isLoading } = useQuery(
    [QueryKeys.GROWER_LIST],
    async () => GrowerApi.getGrowers({
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
      limit: SharedConfig.MAX_PAGE_LIMIT,
      businessId: currentBusinessId,
    }),
    {
      onError: () => {
        createToast({
          children: translate<string>('ERROR_FETCH_GROWERS'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      },
    },
  );

  const isDisabled = isLoading || disabled;
  const selectedCustomer = customers?.data.find(({ id }) => id === customerId);

  return (
    <ComboBox
      disabled={isDisabled}
      getOptionLabel={(customer) => customer?.legalName}
      id='customer-ComboBox'
      isRequired
      onChangeValue={(customer) => onUpdateCustomer(customer?.id)}
      options={customers?.data}
      readOnly={readonly}
      testID={testID}
      topLabel={translate<string>('CUSTOMER')}
      value={selectedCustomer ?? null}
    />
  );
};
