export default {
  ADMIN: 'Admin',
  BUSINESS: 'Business',
  BUSINESSES: 'Businesses',
  CUSTOMER_PLANNING: 'Customer Planning',
  DASHBOARD: 'Dashboard',
  PLANNING: 'Planning',
  PREPARE_AND_REVIEW: 'Prepare & Review',
  PRODUCTS: 'Products',
  REPORTS: 'Reports',
  USERS: 'Users',
  HELP: 'Help',
};
