import { Badge, Card, Header, HSpacer, Icon, IconButton, Text, TextLink, VSpacer } from '@design';
import { CropLogicComponentCategory } from '@shared/enums';
import {
  ApiCropLogicPass,
  ApiCropLogicPassComponent,
  ApiTankMix, CropLogicEndpoint,
} from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { TankMixDetailsModal } from '../../../components/shared/TankMixDetailsModal/TankMixDetailsModal';
import { StringUtility } from '../../../../utilities';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';

type PassComponentDataType = RowMeta & ApiCropLogicPassComponent;

export interface PassCardProps {
  businessLocationId?: string,
  canEdit: boolean,
  index: number,
  onDelete(index: number): void,
  onEdit(pass: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request), index: number): void,
  pass: ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request,
  priceTypeId: string,
  showDetails?: boolean,
}

export const PassCard = ({
  businessLocationId,
  canEdit,
  index,
  onDelete,
  onEdit,
  pass,
  priceTypeId,
  showDetails = false,
}: PassCardProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const [componentDetailsVisible, setComponentDetailsVisible] = useState(showDetails);
  const [deletePassModalVisible, setDeletePassModalVisible] = useState(false);
  const [showProductMixDetails, setShowProductMixDetails] = useState(false);
  const [productMix, setProductMix] = useState<ApiTankMix>(null);

  useEffect(() => {
    setComponentDetailsVisible(showDetails);
  }, [showDetails]);

  const componentCost = useCallback((component) => {
    if (component.tankMixId) {
      return CalculationUtility.calculateTankMixComponentCostPerAcre(
        component.tankMix,
        priceTypeId,
        businessLocationId,
      ).totalCostPerAcre;
    }
    return CalculationUtility.calculateComponentCostPerAcre(
      component,
      priceTypeId,
      businessLocationId,
    );
  }, [businessLocationId, priceTypeId]);

  const costPerAc = useMemo(() => (
    pass.components.map((component) => {
      return componentCost(component);
    }).reduce((a, b) => a + b, 0)
  ), [pass.components, componentCost]);

  const passComponentData = useMemo(
    () => pass.components.map((component, idx): PassComponentDataType => ({
      ...component,
      rowId: `row:${idx}|${component.id}`,
      hasDetails: false,
    })),
    [pass.components],
  );

  const columns: IColumn<(PassComponentDataType)>[] = [
    {
      columnId: 'pass-component-name',
      header: {
        render: translate('NAME'),
      },
      render: (component) => {
        const InactiveBadge = () => (
          <Badge status="warning" testID="deactivated-product-badge">
            {translate<string>('INACTIVE')}
          </Badge>
        );

        if (component.componentCategory === CropLogicComponentCategory.TANK_MIX) {
          return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  category="p2"
                  style={{
                    wordBreak: 'break-word',
                  }}
                  wrap
                >
                  {component.name}
                </Text>
                <HSpacer size="2" />
                <TouchableOpacity onPress={() => {
                  setProductMix(component?.tankMix);
                  setShowProductMixDetails(true);
                }}
                >
                  <Icon name="Info" status="info" testID="info-icon" />
                </TouchableOpacity>
              </View>
              { (
                !component.tankMix?.isActive
              ) && (
                <InactiveBadge />
              )}
            </View>
          );
        }
        return (
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <Text wrap>{component.product.skuName}</Text>
            {
              !component.product?.isActive && (
                <InactiveBadge />
              )
            }
          </View>
        );
      },
      flex: 1,
    },
    {
      columnId: 'pass-component-rate',
      header: {
        render: translate('RATE_AC'),
      },
      render: ({ rate, rateUom }) => (
        `${StringUtility.localizeNumber(rate, 3)} ${translate<string>(rateUom)}`
      ),
      flex: 0.5,
    },
    {
      columnId: 'pass-component-cost-ac',
      header: {
        render: translate('COST_AC'),
        align: 'right',
      },
      render: (component) => {
        return <Text>{StringUtility.formatCurrency(componentCost(component))}</Text>;
      },
      flex: 0.5,
      align: 'right',
    },
  ];

  return (
    <>
      <Card style={{ flex: 1 }} testID={`pass-card-${index}`}>
        <Header
          childrenAlign="flex-start"
          level="2"
          subTitle={translate(
            pass.components.length > 1 ? 'COUNT_COMPONENTS' : 'COUNT_COMPONENT',
            { count: pass.components.length },
          )}
          subTitleHint
          testID={`pass-card-header-${index}`}
          title={pass.name}
          titleMaxWidth="100%"
        >
          <Text
            category="h6"
            numberOfLines={1}
            testID={`total-acres-${index}`}
          >
            {translate<string>('TOTAL_COST_AC_AMOUNT', {
              amount: StringUtility.formatCurrency(costPerAc),
            })}
          </Text>
        </Header>
        <VSpacer size="5" />
        <View
          style={{
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextLink
            accessoryRight={(chevronProps) => (
              <Icon
                {...chevronProps}
                name={componentDetailsVisible ? 'ChevronUp' : 'ChevronDown'}
                testID={`show-hide-details-icon-${index}`}
              />
            )}
            appearance="secondary"
            category="p2"
            onPress={() => setComponentDetailsVisible(!componentDetailsVisible)}
            testID={`show-hide-details-${index}`}
          >
            {translate<string>((componentDetailsVisible) ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
          </TextLink>
          {canEdit && (
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
              <IconButton
                appearance="ghost"
                onPress={() => onEdit(pass, index)}
                size="large"
                status="basic"
                testID={`edit-icon-${index}`}
              >
                Edit
              </IconButton>
              <HSpacer size="2" />
              <IconButton
                appearance="ghost"
                onPress={() => setDeletePassModalVisible(true)}
                size="large"
                status="basic"
                testID={`delete-icon-${index}`}
              >
                Trash
              </IconButton>
            </View>
          )}
        </View>
        {componentDetailsVisible && (
          <SortableTable<PassComponentDataType>
            columns={columns}
            data={passComponentData}
            tableId="pass-components-table"
            testID="pass-components-parent-table"
          />
        )}
      </Card>
      {deletePassModalVisible && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('YES_DELETE')}
          messageText={translate('ALL_COMPONENTS_IN_THIS_PASS_WILL_BE_DELETED')}
          onCancel={() => setDeletePassModalVisible(false)}
          onConfirm={() => {
            onDelete(index);
            setDeletePassModalVisible(false);
          }}
          title={translate('DELETE_PASS')}
          visible
        />
      )}
      {showProductMixDetails && (
        <TankMixDetailsModal
          businessLocationId={businessLocationId}
          onClose={() => setShowProductMixDetails(false)}
          priceTypeId={priceTypeId}
          tankMix={productMix}
          visible
        />
      )}
    </>
  );
};
