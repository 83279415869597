import React from 'react';
import { ColorValue } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

export interface ISvgProps extends SvgProps {
  inside: ColorValue,
}

export const ViewDetailsIcon = (props: ISvgProps) => (
  <Svg {...props} viewBox="0 0 24 24">
    <Path clipRule="evenodd" d="M17 4C17.5523 4 18 4.44772 18 5V15C18 15.5523 18.4477 16 19 16C19.5523 16 20 15.5523 20 15V5C20 3.34315 18.6569 2 17 2L7 2C5.34315 2 4 3.34315 4 5L4 19C4 20.6569 5.34315 22 7 22H14C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20H7C6.44771 20 6 19.5523 6 19L6 5C6 4.44772 6.44771 4 7 4L17 4Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M16.0014 13.0007C16.0014 15.2102 14.2102 17.0014 12.0007 17.0014C9.79117 17.0014 8 15.2102 8 13.0007C8 10.7912 9.79117 9 12.0007 9C14.2102 9 16.0014 10.7912 16.0014 13.0007ZM12.0007 15.0014C13.1056 15.0014 14.0014 14.1056 14.0014 13.0007C14.0014 11.8957 13.1056 11 12.0007 11C10.8957 11 10 11.8957 10 13.0007C10 14.1056 10.8957 15.0014 12.0007 15.0014Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M13.3537 14.7397C13.7745 14.382 14.4056 14.4332 14.7633 14.854L18.2388 18.9432C18.5965 19.364 18.5453 19.9951 18.1245 20.3528C17.7037 20.7105 17.0726 20.6593 16.7149 20.2384L13.2393 16.1492C12.8817 15.7284 12.9328 15.0973 13.3537 14.7397Z" fillRule="evenodd" />
  </Svg>
);
