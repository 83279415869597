import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EnResources from './translations/en';

i18n
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    // Translation jsons are flat. Modularization is achieved via namespaces instead
    keySeparator: false,
    react: {
      wait: true,
      nsMode: 'fallback',
      useSuspense: false,
    },
    interpolation: { escapeValue: false },
    debug: process.env.NODE_ENV === 'development',
    // not needed for react as it escapes by default
    resources: { en: EnResources },
  });

export default i18n;
