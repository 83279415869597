import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { UserApi } from '../utilities/api';
import { QueryKeys } from '../constants';

interface UseUserProps {
  userAccountId?: string,
  onError?(error: Error): void,
}

export const useUser = ({ userAccountId, onError }: UseUserProps) => {
  const queryClient = useQueryClient();
  const invalidateBusinessUser = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.USER, userAccountId])
  ), [queryClient, userAccountId]);

  const { data: user, isFetching: isUserLoading } = useQuery(
    [QueryKeys.USER, userAccountId],
    () => UserApi.getUserById(userAccountId),
    {
      enabled: !!userAccountId,
      onError: (error: Error) => onError?.(error),
    },
  );

  return {
    invalidateBusinessUser,
    isUserLoading,
    user,
  };
};
