import { useStyleSheet } from '@ui-kitten/components';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  SortDirection,
  TableHeader,
  TableRow,
  Table,
  TableProps,
  HSpacer,
  CenteredSpinner,
} from '@design';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { IColumn, RowMeta } from './interfaces';
import { columnWidthStyle } from './functions';
import { SortableRow } from './SortableRow';
import { styles } from './styles';
import { Chevron } from './Chevron';
import { Text } from '../DesignSystem/Text/Text';
import { TableBackground } from '../DesignSystem/Table/Table';
import { TableHeaderBackground } from '../DesignSystem/Table/TableHeader';

interface SortableTableProps<T extends RowMeta>
  extends TableProps {
  columns: IColumn<T>[];
  data: T[];
  expandAll?: boolean;
  expanderChevronStatus?: EvaStatus,
  footerRow?: React.ReactElement;
  isLoading?: boolean,
  noDataMessage?: string,
  numberOfRows?: number;
  onExpandAll?: () => void;
  onSort?: (columnId: string, direction: SortDirection) => void;
  rowDetail?: (data: T) => React.ReactElement;
  /** 'only' means only render headers, no data rows
   *  'no' means only render the data rows without the headers
   *  'yes' means to render the headers and the data rows.
   *  default = 'yes'
   */
  showHeaders?: 'only' | 'no' | 'yes';
  tableBackground?: TableBackground;
  tableHeaderBackground?: TableHeaderBackground;
  tableId?: string;
  onRowExpand?: (data: T, expanded: boolean) => void;
}

/**
 * This component is semi-controlled.  It will reset based on new props coming in,
 * but it handles state internally for you.
 * - The current sort col and direction.
 * - expanded rows
 * Make sure your column array is not changing on every render cycle
 */

export const SortableTable = <T extends RowMeta>({
  columns,
  data,
  expandAll: propExpandAll,
  expanderChevronStatus = 'basic',
  footerRow,
  isLoading,
  noDataMessage,
  numberOfRows,
  onExpandAll = () => {},
  onRowExpand,
  onSort = () => {},
  rowDetail,
  showHeaders = 'yes',
  tableBackground,
  tableHeaderBackground,
  tableId,
  ...tableProps
}: PropsWithChildren<SortableTableProps<T>>) => {
  const [translate] = useTranslation(['common']);
  const [expandAll, setExpandAll] = useState<boolean>();

  const filteredColumns = columns.filter((c) => c);
  const sortedColumn = columns.find((c) => c?.header?.sortDirection);

  const [sortedHeader, setSortedHeader] = useState(sortedColumn?.columnId ?? '');
  const [sortDirection, setSortDirection] = useState<SortDirection>(sortedColumn?.header?.sortDirection ?? 'DESC');

  const themeStyle = useStyleSheet({
    spacer: {
      backgroundColor: 'background-basic-color-2',
    },
  });

  useEffect(() => {
    setExpandAll(propExpandAll);
  }, [propExpandAll]);

  return (
    <Table testID={tableId} {...tableProps} tableBackground={tableBackground}>
      {showHeaders !== 'no' && (
        <TableRow appearance="header" testID="header-row">
          {!rowDetail ? (
            <HSpacer
              size="5"
              style={[
                themeStyle.spacer,
                tableHeaderBackground && { backgroundColor: tableHeaderBackground,
                }]}
            />
          ) : (
            <TableHeader
              style={styles.expanderColumn}
              tableHeaderBackground={tableHeaderBackground}
              testID="detail-expander"
            >
              <Chevron
                nativeID="header-chevron"
                onPress={() => {
                  setExpandAll(!expandAll);
                  onExpandAll();
                }}
                open={expandAll}
                status={expanderChevronStatus}
                testID="header-chevron"
              />
            </TableHeader>
          )}
          {filteredColumns.map(({
            columnId,
            header: {
              render,
              style,
              sortable,
              reverseInitialSort,
              ...headerProps
            },
            ...columnProps
          }) => (
            <TableHeader
              {...headerProps}
              key={columnId}
              onPress={sortable ? (() => {
                const newSortCol = columnId;
                let newSortDir:SortDirection = reverseInitialSort ? 'DESC' : 'ASC';
                if (newSortCol === sortedHeader) {
                  if (sortDirection === 'ASC') {
                    newSortDir = 'DESC';
                  } else {
                    newSortDir = 'ASC';
                  }
                }
                setSortedHeader(newSortCol);
                setSortDirection(newSortDir);
                onSort(newSortCol, newSortDir);
              }) : undefined}
              sortDirection={sortedHeader === columnId ? sortDirection : undefined}
              style={[
                columnWidthStyle(columnProps),
                style,
              ]}
              tableHeaderBackground={tableHeaderBackground}
              testID={columnId}
            >
              {render}
            </TableHeader>
          ))}
        </TableRow>
      )}
      {showHeaders !== 'only' && (
        (data?.length > 0 && !isLoading) ? (
          data.slice(0, numberOfRows).map((rowData) => (
            <SortableRow
              columns={filteredColumns}
              controlledExpand={expandAll}
              data={rowData}
              expanderChevronStatus={expanderChevronStatus}
              key={rowData.rowId}
              onRowExpand={onRowExpand}
              rowDetail={rowDetail}
            />
          ))
        ) : (
          <View style={styles.nothingToDisplay}>
            {isLoading ? (
              <CenteredSpinner testID="loading-spinner" />
            ) : (
              <Text category="p2" testID="no-data-message">
                {noDataMessage ?? translate('NO_DATA') as string}
              </Text>
            )}
          </View>
        )
      )}
      {!!footerRow && (
        <TableRow testID="sortable-table-footer">{footerRow}</TableRow>
      )}
    </Table>
  );
};
