export default {
  ADD_A_PASS_TO_YOUR_PROGRAM: 'Add a pass to your program',
  ADD_PASS_TITLE: 'Add Pass',
  ALL_COMPONENTS_IN_THIS_PASS_WILL_BE_DELETED: 'All components in this pass will be deleted. Are you sure you want to proceed?',
  APPLICATION_PER_ACRE: '{{ applicationRate }}/ac',
  APPLY_TO_ALL: 'Apply to all',
  APPLY_TO_SELECTED: 'Apply to selected',
  ARE_YOU_SURE_YOU_WANT_TO_ACTIVATE_THIS_PROGRAM: 'Are you sure you want to activate this program?',
  CHANGE_CROP_TYPE_CONFIRM: 'Are you sure you want to change the crop type from {{ cropName }} to {{ newCropName }}?\nThis will remove any applied Product Mixes and Seed specific products from this program.',
  ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_THIS_PROGRAM: 'Are you sure you want to deactivate this program?',
  CHANGES_TO_THIS_PROGRAM_HAVE_NOT_BEEN_SAVED: 'Changes to this Program have not been saved.',
  COMPONENT: 'Component',
  COMPONENTS: 'Components',
  COMPONENTS_COUNT: 'Components ({{ count }})',
  COST_AC: 'Cost/ac',
  COUNT_COMPONENT: '{{ count }} Component',
  COUNT_COMPONENTS: '{{ count }} Components',
  COUNT_PASS: '{{ count }} pass',
  COUNT_PASSES: '{{ count }} passes',
  CREATE_AND_APPLY_PROGRAM: 'Create & Apply Program',
  CREATE_PROGRAM: 'Create Program',
  CREATE_PROGRAM_ON_FARM_PLAN: 'Create Program on Farm Plan',
  DELETE_PASS: 'Delete pass?',
  DISCARD_CHANGES: 'Discard Changes?',
  DISCARD_THIS_PROGRAM: 'Discard this Program?',
  EDIT_PASS: 'Edit pass',
  EDIT_PROGRAM_ON_FARM_PLAN: 'Edit Program on Farm Plan',
  EDIT_SCENARIO_PARAMETERS: 'Edit scenario parameters',
  MARKET_PRICE: 'Market price',
  NO_PASSES: 'No passes',
  OPERATIONAL_COST: 'Operational cost',
  OPERATIONAL_COST_AC: 'Operational cost/ac',
  PASSES: 'Passes',
  PASS_WAS_SUCCESSFULLY_DELETED_FROM_PROGRAM: 'Pass was successfully deleted from program',
  PER_UOM: 'per/{{UoM}}',
  PRODUCT_APPLICATIONS: 'Product Applications',
  PRODUCT_DEACTIVATED_WARNING: 'This product no longer exists',
  PRODUCT_MIX_DETAILS: 'Product Mix details',
  PROFIT_SUMMARY: 'Profit summary',
  PROGRAM: 'Program',
  PROGRAM_ERROR: 'Error updating Program',
  PROGRAM_NAME: 'Program name',
  PROGRAM_NAME_ALREADY_EXISTS: 'Program {{name}} already exists',
  PROGRAM_SUCCESSFULLY_CREATED: 'Program successfully created',
  PROGRAM_SUCCESSFULLY_DUPLICATED: 'Program successfully duplicated',
  PROGRAM_SUCCESSFULLY_UPDATED: 'Program successfully updated',
  PROGRAM_UPDATED: 'Program updated',
  RATE_AC: 'Rate/ac',
  SAVE_PASS: 'Save pass',
  SAVE_PROGRAM: 'Save program',
  SCENARIO_PARAMETERS: 'Scenario parameters',
  TANK_MIX_CONTAINS_INACTIVE_COMPONENTS: 'This product mix contains inactive components',
  TANK_MIX_DETAILS: 'Tank mix details',
  TANK_MIX_DOES_NOT_CONTAIN_ACTIVE_COMPONENTS: 'This product mix does not contain any active components',
  THIS_PROGRAM_HAS_NOT_YET_BEEN_CREATED: 'This Program has not yet been created.',
  TOTAL_COST_AC_AMOUNT: 'Total cost/ac: {{ amount }}',
  USE_SCENARIO_PARAMETERS: 'Use scenario parameters',
};
