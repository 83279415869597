import { Button, Dialog, HSpacer, Text, useToast } from '@design';
import { ApiUserAccount } from '@shared/interfaces/api';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UnexpectedError } from '../../../ui-components/utils/UnexpectedError';
import { UserApi } from '../../../utilities/api';

interface Props {
  user?: ApiUserAccount;
  onCancel: () => void;
  onUserUpdated: (updatedUser: ApiUserAccount) => void;
}

export const ChangeUserStatusDialog: FC<Props> = ({
  user,
  onCancel,
  onUserUpdated,
}) => {
  const { t } = useTranslation(['common', 'users']);
  const { createToast } = useToast();

  const updateUser = useCallback(() => {
    const { id, isActive } = user;
    UserApi.updateUser(id, { isActive: !isActive }).then((updatedUser) => {
      onUserUpdated(updatedUser);
      createToast({
        status: 'success',
        children: t('USER_UPDATED') as string,
        testID: 'toast-content-element',
      });
    }).catch((err) => {
      UnexpectedError(err, 'Error Updating User', {
        id,
        isActive,
      });
      onCancel();
    });
  }, [createToast, onCancel, onUserUpdated, t, user]);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button {...primaryButtonProp} testID="cancel-button">{t('CANCEL') as string}</Button>
          <HSpacer {...spacerProp} />
          <Button {...secondaryButtonProp} onPress={updateUser} testID="activate-deactivate-button">
            {t(!user?.isActive ? 'ACTIVATE' : 'DEACTIVATE') as string}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="change-user-status-dialog"
      visible={!!user}
    >
      <Text>
        {t(!user?.isActive ? 'USER_ACTIVATE_MODAL_WARNING' : 'USER_DEACTIVATE_MODAL_WARNING') as string}
      </Text>
    </Dialog>
  );
};
