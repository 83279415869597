import { useState } from 'react';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { QueryKeys } from '../constants';
import { useQuery } from 'react-query';
import { GrowerApi } from '../utilities/api';
import { Permissions, RoleUtility } from '@shared/utils';

export const useCustomerTotals = ({
  onError,
  isEnabled = true,
}: { isEnabled?: boolean, onError?: (err?: unknown) => void }) => {
  const { currentBusinessId, user } = useAuthentication();
  const [customerTotals, setCustomerTotals] = useState(0);
  const userGreaterThanBizStandard = RoleUtility.roleHasPermission(
    user.userRole, Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const { isSuccess } = useQuery(
    [QueryKeys.GROWER_LIST, currentBusinessId],
    async () => GrowerApi.getGrowers({
      assigned: !userGreaterThanBizStandard,
      businessId: currentBusinessId,
    }),
    {
      enabled: isEnabled,
      onError: (err) => { onError?.(err); },
      onSuccess: (data) => { setCustomerTotals(data.total); },
    },
  );

  return { customerTotals, isSuccess };
};
