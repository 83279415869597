import React, { ReactElement, ReactNode } from 'react';
import { merge } from 'lodash';
import { SchemaProcessor } from '@eva-design/processor';
import {
  CustomSchemaType,
  SchemaType,
  ThemeStyleType,
} from '@eva-design/dss';
import { ThemeProviderProps } from '@ui-kitten/components';
import { StyleProvider } from '@ui-kitten/components/theme/style/styleProvider.component';
import { AppContextProvider } from '../../../../contexts/AppContext';
import { ErrorPage } from '../../shared/ErrorPage/ErrorPage';

interface EvaRuntimeProcessingProps {
  mapping: SchemaType;
  customMapping?: CustomSchemaType;
}

interface EvaBuildtimeProcessingProps {
  styles: ThemeStyleType;
}

type EvaProcessingProps = EvaRuntimeProcessingProps | EvaBuildtimeProcessingProps;

export type ApplicationProviderProps = EvaProcessingProps & ThemeProviderProps & {
  notificationLayer?: ReactElement,
};
export type ApplicationProviderElement = React.ReactElement<ApplicationProviderProps>;

interface State {
  styles: ThemeStyleType;
}

interface ErrorState {
  hasError: boolean
}

interface ErrorBoundaryProps {
  children: ReactNode,
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: ErrorState = {
    hasError: false,
  };

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };

  render () {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorPage /> : children;
  }
}

export class ApplicationProvider extends React.Component<ApplicationProviderProps, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    styles: (this.props as EvaBuildtimeProcessingProps).styles,
  };

  private schemaProcessor: SchemaProcessor = new SchemaProcessor();

  constructor (props: ApplicationProviderProps) {
    super(props);

    if (!this.state.styles) {
      const { mapping, customMapping } = this.props as EvaRuntimeProcessingProps;
      this.state.styles = this.createStyles(mapping, customMapping);
    }
  }

  private createStyles = (mapping: SchemaType, custom: CustomSchemaType): ThemeStyleType => {
    const customizedMapping: SchemaType = merge({}, mapping, custom);
    return this.schemaProcessor.process(customizedMapping);
  };

  public render (): React.ReactNode {
    return (
      <StyleProvider
        styles={this.state.styles}
        theme={this.props.theme}
      >
        <ErrorBoundary>
          <AppContextProvider>
            {this.props.children}
            {this.props.notificationLayer}
          </AppContextProvider>
        </ErrorBoundary>
      </StyleProvider>
    );
  }
}
