import React from 'react';
import { useWindowDimensions, StyleSheet, View, ScrollView } from 'react-native';
import { Modal } from '../../ui-components';
// import { Sizing } from '../../constants';
// import { useTranslation } from 'react-i18next';

class MUtility {
  show: (content: JSX.Element) => void;
  hide: () => void;

  /**
   * Sets the show function
   * @param show The function to pass show commands to
   */
  setShow = (show: (content: JSX.Element) => void) => {
    this.show = show;
  };

  /**
   * Sets the show function
   * @param show The function to pass show commands to
   */
  setHide = (hide: () => void) => {
    this.hide = hide;
  };
}

const ModalUtility = new MUtility();

export interface IModalProps {
  content?: JSX.Element;
  visible: boolean
}

const IModal = (props: IModalProps) => {
  const styles = StyleSheet.create({ popupContentBackdrop: { backgroundColor: 'rgba(0, 0, 0, 0.75)' } });
  const { height } = useWindowDimensions();

  return (
    <View>
      <Modal
        backdropStyle={styles.popupContentBackdrop}
        onBackdropPress={ModalUtility.hide}
        style={{ maxHeight: height }}
        visible={props.visible}
      >
        <ScrollView style={{ maxHeight: height, marginVertical: '2%' }}>
          {props.content}
        </ScrollView>
      </Modal>
    </View>
  );
};

export { ModalUtility, IModal as Modal };
