import {
  isFarmPlanDeliverablePublic,
  isProductOrderDeliverable,
  isTankMixDeliverablePublic,
} from '@shared/interfaces';
import { ApiDeliverablePublic } from '@shared/interfaces/api';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from '../../router';
import { Alert, CenteredSpinner } from '../../ui-components';
import { GrowerDeliverableApi } from '../../utilities/api';
import { FarmPlanDeliverablePublicPage } from './FarmPlanDeliverable/FarmPlanDeliverablePublicPage';
import {
  ProductOrderDeliverablePublicPage,
} from './ProductOrderDeliverable/ProductOrderDeliverablePublicPage';
import { TankMixDeliverablePublicPage } from './TankMixDeliverable/TankMixDeliverablePublicPage';

export const DeliverablePublicPage: FunctionComponent = () => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);
  const [initError, setInitError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [deliverable, setDeliverable] = useState<ApiDeliverablePublic<any>>(null);
  const { deliverableId } = useParams<{ deliverableId: string }>();

  useEffect(() => {
    const getDeliverable = async () => {
      try {
        const inDeliverable = await GrowerDeliverableApi.getPublicGrowerDeliverable(deliverableId);
        setDeliverable(inDeliverable);
      } catch {
        setInitError(translate('ERROR_DELIVERABLE_LOADING'));
      }
      setIsLoading(false);
    };
    getDeliverable();
  }, [deliverableId, translate]);

  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (initError) {
    return <Alert level="danger" text={initError} />;
  }

  if (isFarmPlanDeliverablePublic(deliverable)) {
    return <FarmPlanDeliverablePublicPage deliverable={deliverable} />;
  }

  if (isTankMixDeliverablePublic(deliverable)) {
    return <TankMixDeliverablePublicPage deliverable={deliverable} />;
  }

  if (isProductOrderDeliverable(deliverable)) {
    return <ProductOrderDeliverablePublicPage deliverable={deliverable} />;
  }

  return <Alert level="danger" text="Could not display deliverable." />;
};
