export default {
  ACRES_APPLIED: 'Acres Applied',
  ACRES_APPLIED_SENTENCE: 'Acres applied',
  ACRES_PLANNED: 'Acres planned',
  ACTIONS: 'Actions',
  APPLICATION_COST_AC: 'Application Cost/ac',
  AVERAGE_COST_PER_ACRE: 'Avg cost/ac',
  AVERAGE_RATE_APPLIED: 'Average Rate Applied',
  BUSINESS_NAME: 'Business Name',
  CONTACTS_SELECTED: '{{count}} contacts selected',
  CONTACT_SELECTED: '{{count}} contact selected',
  COST_SUMMARY: 'Cost Summary',
  CUSTOMER: 'Customer',
  CUSTOMER_CONTACT: 'Customer Contact',
  CUSTOMER_CONTACTS: 'Customer Contacts',
  DELETE_DELIVERABLE_CONFIRM: 'Yes, delete',
  DELETE_DELIVERABLE_ERROR: 'Error deleting deliverable',
  DELETE_DELIVERABLE_TEXT: 'This deliverable has not been shared with any customers.',
  DELETE_DELIVERABLE_TITLE: 'Are you sure you want to delete {{name}}?',
  DELIVERABLE_ACTIVITY_LOG: 'Deliverable Activity Log',
  DELIVERABLE_DELETED: 'Deliverable deleted',
  DELIVERABLE_FAILURE: 'There was an error generating the deliverable.',
  DELIVERABLE_RECIPIENT_FAILURE: 'There was an error while sending emails/texts to selected grower contacts',
  DELIVERABLE_SENT: 'Deliverable was sent to selected recipients.',
  DELIVERABLE_SENT_TO: 'Sent to {{recipient}} via {{type}}',
  DELIVERABLE_SUCCESS: 'Deliverable {{name}} was generated.',
  DELIVERABLE_TYPE: 'Type',
  DELIVERABLE_TYPE_FARM_PLAN: 'Farm Plan',
  DELIVERABLE_TYPE_PRODUCT_ORDER: 'Product Order',
  DISCOUNTED_PRICE: 'Discounted Price',
  DISCOUNTS: 'Discount',
  DOWNLOAD_PDF: 'Download PDF',
  EMAIL: 'Email',
  FARM_PLAN_DELIVERABLE: 'Farm Plan Deliverable',
  FARM_PLAN_DELIVERABLE_FOR: 'Farm plan deliverable for:',
  GENERATE: 'Generate',
  INTENDED_USE: 'Intended use',
  INTERNAL_CONTACTS: 'Internal Contacts',
  NAME_YOUR_DELIVERABLE: 'Name your deliverable:',
  NOTES: 'Notes',
  NO_CONTACTS_WARNING: 'To share your deliverable, you\'ll need to have contacts set up. Please close this window and add at least one contact for your customer.',
  OPTIONS: 'Options',
  ORDER_DETAILS: 'Order Details',
  PACKAGES_CONTRACTED: 'Packages Contracted',
  PLAN_NAME: 'Plan Name',
  PLAN_STATUS: 'Plan status',
  PRICING_DETAILS: 'Pricing Details',
  PRODUCT: 'Product',
  PRODUCTS_COUNT: '({{productCount}} Products)',
  PRODUCT_COST_PER_ACRE: 'Product Cost (per acre)',
  PRODUCT_COUNT_1: '({{productCount}} Product)',
  PRODUCT_COUNT_LABEL: 'Product count',
  PRODUCT_NAME: 'Product Name',
  PRODUCT_SKU_NAME: 'Product SKU Name',
  PRODUCT_SKU_PACKAGES_REQUIRED: 'Product SKU Packages Required',
  PRODUCT_SKU_PRICE: 'Product SKU Price',
  PRODUCT_UNITS_REQUIRED: 'Product Units Required',
  QUICK_QUOTE: 'Quick Quote',
  RATE_APPLIED: 'Rate Applied',
  RATE_APPLIED_UOM: 'Rate Applied Unit of Measure',
  READ_LESS: 'Read less',
  READ_MORE: 'Read more',
  RETAIL_UNIT_PRICE: 'Retail Unit Price',
  SALESPERSON: 'Salesperson',
  SELECT_GROWER: 'Select Grower',
  SEND_THIS_DELIVERABLE_TO_YOUR_CONTACTS: 'Send this deliverable to your contacts',
  SHARE: 'Share',
  SHARE_DELIVERABLE: 'Share deliverable',
  SHARE_DELIVERABLE_CREATED: '{{name}} successfully created',
  SHARE_DELIVERABLE_FOR: 'Share Deliverable for {{name}}',
  SKU_NAME: 'SKU Name',
  TANK_MIX_DELIVERABLE: 'Tank mix deliverable:',
  TANK_MIX_DELIVERABLE_FOR: 'Tank mix deliverable for:',
  TEXT: 'Text',
  TOTAL_ACRES_PLANNED: 'Total acres planned',
  TOTAL_COST: 'Total cost',
  TOTAL_PRODUCT_COST: 'Total Product Cost',
  TOTAL_TANK_MIX_COST: 'Total Tank Mix Cost',
  UNITS_REQUIRED: 'Units Required',
  UNITS_REQUIRED_UOM: 'Units Required Unit of Measure',
  UNIT_PRICE: 'Unit Price',
  VIEW_ACTIVITY_LOG: 'View Activity Log',
  VIEW_DELIVERABLE: 'View Deliverable',
  VIEW_DELIVERABLE_POST: ' on the ‘Prepare & Review’ page under the ‘Deliverable’ tab.',
  VIEW_ONLINE: 'View Online',
  VIEW_PDF: 'View PDF',
  ZONE_PRODUCT_SUMMARY_NAME: 'Zone Product Summary Name',
};
