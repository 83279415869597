import React, { FC, useState } from 'react';
import { GestureResponderEvent } from 'react-native';
import {
  ModalProps as UIKModalProps,
} from '@ui-kitten/components';
import { HSpacer, Text, Button, Dialog, RadioGroup, Radio, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';

interface DoubleConfirmationModalProps extends UIKModalProps {
  cancelText: string,
  challengeText?: string,
  confirmText?: string,
  disabled?: boolean,
  messageText?: string,
  onCancel: () => void,
  onConfirm: (event: GestureResponderEvent) => void,
  status?: 'danger' | 'warning',
  title?: string,
  visible: boolean,
  width?: number,
}

export const DoubleConfirmationModal: FC<DoubleConfirmationModalProps> = ({
  cancelText,
  challengeText,
  confirmText,
  messageText,
  onCancel,
  onConfirm,
  status,
  title,
  visible,
  width = 480,
}: DoubleConfirmationModalProps) => {
  const [translate] = useTranslation(['common']);
  const [isArmed, setIsArmed] = useState(false);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            appearance="outline"
            onPress={onCancel}
            testID="cancel-button"
          >
            {cancelText}
          </Button>
          {confirmText && (
            <>
              <HSpacer {...spacerProp} />
              <Button
                {...secondaryButtonProp}
                disabled={!isArmed}
                onPress={onConfirm}
                testID="confirm-button"
              >
                {confirmText}
              </Button>
            </>
          )}
        </>
      )}
      status={status}
      testID="double-confirmation-dialog"
      title={title}
      visible={visible}
      width={width}
    >
      <Text category="p1">
        {messageText}
      </Text>
      <VSpacer size="6" />
      <Text appearance="hint" category="p2">
        {challengeText}
      </Text>
      <VSpacer size="6" />
      <RadioGroup
        onChange={(index) => {
          const currentIndex = isArmed ? 0 : 1;
          if (index !== currentIndex) setIsArmed(!isArmed);
        }}
        selectedIndex={isArmed ? 0 : 1}
        style={{ flexDirection: 'row' }}
        testID="confirmation-challenge-radio-group"
      >
        <Radio
          testID="yes-option"
        >
          {translate<string>('YES')}
        </Radio>
        <Radio
          testID="no-option"
        >
          {translate<string>('NO')}
        </Radio>
      </RadioGroup>
    </Dialog>
  );
};
