import React, { useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { TFunction } from 'i18next';
import { Button, HSpacer, Text, TextLink, useToast, VSpacer } from '@design';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { useHistory, useLocation } from '../../router';
import { QueryUtility } from '../../utilities';
import { AuthApi } from '../../utilities/api';
import { Routes, Sizing, ThemeColors } from '../../constants';
import { usePasswordErrorToMessage } from '../../utilities/Password';
import { PasswordForm } from './PasswordForm';
import DeviceSizes from './DeviceSizes';
import { useScreenSizing } from '../../hooks/useScreenSizing';

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  container: {
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  biggerFont: { fontSize: 1.25 * Sizing.EM },
  text: { marginBottom: Sizing.BASE_SPACING },
  buttonWidth: {
    width: Sizing.BASE_SPACING * 7.5,
    justifyContent: 'center',
    marginLeft: 0,
  },
  largeButtonWidth: {
    width: Sizing.BASE_SPACING * 10,
    justifyContent: 'center',
    paddingLeft: '0em',
    paddingRight: '0em',
    marginLeft: 0,
  },
});

export interface ResetPasswordProps {
  create?: boolean;
  translate: TFunction;
}

export const ResetPassword = ({ create, translate }: ResetPasswordProps) => {
  const history = useHistory();
  const passwordErrorToMessage = usePasswordErrorToMessage();
  const [password, setPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [submitted, setSubmitted] = useState(null);

  const sizeSetting = useScreenSizing();
  const { createToast } = useToast();

  const { search: searchParams } = useLocation();
  const { token } = QueryUtility.getQueryParams(searchParams);

  const contactRallySupport = () => history.push(Routes.PUBLIC_HELP);

  const submitForm = async () => {
    try {
      setSubmitting(true);
      await AuthApi.resetPassword(token as string, password);
      setPasswordError(null);
      setSubmitted(true);
    } catch (err) {
      setPasswordError(passwordErrorToMessage(err as Error));
      setSubmitting(false);
      if ((err as { code: string }).code === 'invalid-reset-token') {
        createToast({
          status: 'warning',
          children: (
            <Text>Please re-enter your email for a new link.</Text>
          ),
          testID: 'toast-content-element',
        });
        history.push(Routes.FORGOT_PASSWORD);
      }
    }
    setSubmitting(false);
  };

  return (
    <View style={[
      styles.container,
      { width: DeviceSizes[sizeSetting].containerSize },
    ]}
    >
      <ScrollView>
        {!submitted ? (
          <>
            <View style={styles.center}>
              <Text category="h3" style={[styles.text]}>
                {translate<string>(create ? 'CREATE_PASSWORD' : 'CHANGE_PASSWORD')}
              </Text>
            </View>

            <VSpacer size={DeviceSizes[sizeSetting].bottomTitleSpace as SpacerSize} />

            <PasswordForm
              error={passwordError}
              onChangePassword={(nextValue) => setPassword(nextValue)}
              onFormValidation={setPasswordIsValid}
            />

            <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />

            <View style={styles.center}>
              <View style={styles.row}>
                <Button
                  appearance="outline"
                  onPress={() => history.push(Routes.LOGIN)}
                  status="basic"
                  style={[{ marginRight: Sizing.HALF_SPACING }]}
                  testID="cancel-btn"
                >
                  {translate<string>('CANCEL')}
                </Button>
                <HSpacer size="7" />
                <Button
                  appearance={!passwordIsValid || submitting ? 'outline' : 'filled'}
                  disabled={!passwordIsValid || submitting}
                  onPress={submitForm}
                  status="primary"
                  style={styles.buttonWidth}
                  testID="submit-btn"
                >
                  {translate<string>('SUBMIT')}
                </Button>
              </View>
            </View>
          </>
        ) : (
          <View style={styles.center}>
            <Text category="h3" style={[styles.text]}>
              {translate<string>('PASSWORD_CREATED')}
            </Text>
            <Text appearance="hint" category="p2" style={{ textAlign: 'center' }}>
              {translate<string>('NEW_PASSWORD_CREATED')}
            </Text>

            <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
            <Button
              appearance="filled"
              onPress={() => history.push(Routes.LOGIN)}
              status="primary"
              testID="back-to-login-btn"
            >
              {translate<string>('BACK_TO_LOGIN')}
            </Button>
          </View>
        )}

        <View style={styles.center}>
          <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
          <View style={styles.row}>
            <Text
              appearance="hint"
              category="p2"
              testID="info-message"
            >
              {translate('STILL_HAVING_PROBLEMS') as string}
            </Text>
            <HSpacer size="2" />
            <TextLink
              category="p2"
              onPress={contactRallySupport}
              style={[{ color: ThemeColors.SECONDARY }]}
              testID="call-link"
            >
              {translate('EMAIL_GROWERS') as string}
            </TextLink>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
