import { Button, Chip, HSpacer, Icon, IconButton, Input, Modal, useToast, VSpacer } from '@design';
import { ApiTag } from '@shared/interfaces/api';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { maxTagLength } from './CustomTags';
import { DetailedApiError } from '../../../../utilities/api/DetailedApiError';
import { BusinessApi } from '../../../../utilities/api';
import { QueryKeys } from '../../../../constants';
import { useMutation, useQueryClient } from 'react-query';

const styles = StyleSheet.create({
  tags: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: 12,
  },
  input: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
  },
});

interface AddContactTagsModalProps {
  allowCustomTags: boolean,
  assignedTags: string[],
  businessId: string,
  initialTags: ApiTag[],
  onClose: () => void,
  onSave: (selectedTags: string[]) => void,
}

export const AddContactTagsModal = ({
  allowCustomTags,
  assignedTags,
  businessId,
  initialTags,
  onClose,
  onSave,
}: AddContactTagsModalProps) => {
  const [translate] = useTranslation(['common', 'businesses']);
  const [tags, setTags] = useState(initialTags.map((t) => t.name) ?? []);
  const [customTagName, setCustomTagName] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>(assignedTags);

  const trimmedCustomTagName = customTagName.trim();
  const isDuplicateName = () => tags.includes(trimmedCustomTagName);

  const queryClient = useQueryClient();
  const { createToast } = useToast();

  const getMutationOptions = (toastMessage: string) => ({
    onError: (err: DetailedApiError) => {
      createToast({
        children: err.message,
        status: 'warning',
        testID: 'tags-mutation-error-toast',
      });
    },
    onSuccess: () => {
      createToast(toastMessage, 'success');
      queryClient.invalidateQueries([QueryKeys.BUSINESS_TAGS, businessId]);
    },
  });

  const { mutate: createTag } = useMutation(
    (name: string) => BusinessApi.createTag(businessId, name),
    getMutationOptions(translate('NEW_TAG_CREATED')),
  );
  const { mutate: deleteTag } = useMutation(
    (id: string) => BusinessApi.deleteTag(businessId, id),
    getMutationOptions(translate('CUSTOM_TAG_DELETED')),
  );
  const { mutate: updateTag } = useMutation(
    ({ id, name }: { id: string, name: string }) => (
      BusinessApi.updateTag(businessId, id, name)
    ),
    getMutationOptions(translate('CUSTOM_TAG_UPDATED')),
  );

  const customTags = initialTags?.filter((tag) => !tag.isDefault) ?? [];
  const customTagNames = customTags.map(({ name }) => name);

  const handleChangeTags = (newTagNames: string[]) => {
    if (newTagNames.length > customTags.length) {
      const tagNameToCreate = newTagNames.find((name) => !tags.includes(name));
      createTag(tagNameToCreate);
    } else if (newTagNames.length < customTags.length) {
      const tagIdToDelete = customTags.find(({ name }) => !newTagNames.includes(name))?.id;
      deleteTag(tagIdToDelete);
    } else {
      const updatedName = newTagNames.find((name) => !customTagNames.includes(name));
      const tagIdToUpdate = customTags.find(({ name }) => !newTagNames.includes(name))?.id;
      if (updatedName && tagIdToUpdate) updateTag({ id: tagIdToUpdate, name: updatedName });
    }
  };

  const tagChips = (
    <View style={styles.tags}>
      {tags.map((tag) => (
        <Chip
          accessoryLeft={selectedTags.includes(tag) && (
            <Icon name="Checkmark" testID={`add-contact-tags-tag-${tag}-check-icon`} />
          )}
          accessoryRight={<Fragment/>}
          appearance="outline"
          key={tag}
          onPress={() => {
            if (selectedTags.includes(tag)) {
              setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
            } else {
              setSelectedTags([...selectedTags, tag]);
            }
          }}
          status={selectedTags.includes(tag) ? 'primary' : 'default'}
          testID={`add-contact-tags-tag-${tag}`}
        >
          {tag}
        </Chip>
      ))}
    </View>
  );

  const inputRow = (
    <View style={styles.row}>
      <Input
        caption={isDuplicateName() && translate('CUSTOM_TAG_ALREADY_EXISTS')}
        maxLength={maxTagLength}
        onChangeText={setCustomTagName}
        placeholder={translate('CREATE_CUSTOM_TAG')}
        status={isDuplicateName() ? 'danger' : undefined}
        style={styles.input}
        testID="add-contact-tags-input"
        value={customTagName}
      />
      <HSpacer size="3" />
      <View style={styles.row}>
        <IconButton
          appearance="outline"
          disabled={!trimmedCustomTagName || tags.includes(trimmedCustomTagName)}
          onPress={() => {
            setTags([...tags, trimmedCustomTagName]);
            setSelectedTags([...selectedTags, trimmedCustomTagName]);
            handleChangeTags([...tags, trimmedCustomTagName]);
            setCustomTagName('');
          }}
          status="primary"
          testID="add-contact-tags-input-save-button"
        >
          <Icon
            name="Checkmark"
            testID="add-contact-tags-input-save-button-icon"
          />
        </IconButton>
        <HSpacer size="3" />
        <IconButton
          appearance="outline"
          disabled={!customTagName}
          onPress={() => setCustomTagName('')}
          status="basic"
          testID="add-contact-tags-input-clear-button"
        >
          <Icon
            name="Close"
            testID="add-contact-tags-input-clear-button-icon"
          />
        </IconButton>
      </View>
    </View>
  );

  return (
    <Modal
      footerAccessory={() => (
        <View style={{ flexDirection: 'row' }}>
          <Button
            appearance="ghost"
            onPress={onClose}
            size="medium"
            status="basic"
            testID="add-contact-tags-cancel-button"
          >
            {translate('CANCEL')}
          </Button>
          <HSpacer size="3"/>
          <Button
            onPress={() => {
              onSave(selectedTags);
            }}
            size="medium"
            testID="add-contact-tags-save-button"
          >
            {translate('SAVE')}
          </Button>
        </View>
      )}
      hideCloseButton
      onClose={onClose}
      testID="add-contact-tags-modal"
      title={translate('ADD_CONTACT_TAGS')}
      visible
    >
      {tagChips}
      <VSpacer size="9" />
      {allowCustomTags && inputRow}
    </Modal>
  );
};

