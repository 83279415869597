import { View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { ApiGrower } from '@shared/interfaces/api';
import { Card, Tab, TabView } from '@design';
import { useTranslation } from 'react-i18next';
import { useStyleSheet } from '@ui-kitten/components';
import { useHistory } from '../../../router';
import { Routes } from '../../../constants';
import { AssignmentTab } from './AssignmentTab/AssignmentTab';
import { CustomerOverviewTab } from './CustomerOverviewTab';
import { FarmPlanTab } from './FarmPlanTab/FarmPlanTab';
import { FarmTabsList } from './FarmsTab/FarmTabsList';
import { PlanningParametersTab } from './PlanningParameters/PlanningParametersTab';

interface CustomerDetailsTabProps {
  customer: ApiGrower;
  tab?: string;
}

const Tabs = [
  'info',
  'farms',
  'farm-plans',
  'assignment',
  'annual-planning',
];

export const CustomerTabView = (props: CustomerDetailsTabProps) => {
  const { tab, customer } = props;
  const [translate] = useTranslation(['common']);
  const history = useHistory();

  const styles = useStyleSheet({
    card: {
      paddingBottom: 0,
      paddingTop: 24,
    },
    tabView: {
      height: '100%',
    },
    grow: {
      flex: 1,
      backgroundColor: 'color-basic-1000',
      paddingHorizontal: 0,
    },
  });

  const [selectedIndex, setSelectedIndex] = useState(Tabs.includes(tab) ? Tabs.indexOf(tab) : 0);

  useEffect(() => {
    if (!tab) {
      history.replace(
        Routes.GROWER_DETAILS
          .replace(/:grower/, customer?.id)
          .replace(/:tab/, Tabs[0]),
      );
    }
    if (Tabs[selectedIndex] !== tab) {
      setSelectedIndex(Tabs.findIndex((doc) => doc === tab));
    }
  }, [customer, history, selectedIndex, tab]);

  const onTabSelect = (index: number) => {
    setSelectedIndex(index);
    history.replace(
      Routes.GROWER_DETAILS
        .replace(/:grower/, customer?.id)
        .replace(/:tab/, Tabs[index]),
    );
  };

  return (
    <View style={styles.grow} testID="customer-tab-view">
      <TabView
        indicatorStyle={{ width: `${100}%` }}
        onSelect={onTabSelect}
        selectedIndex={selectedIndex}
        shouldLoadComponent={(index) => index === selectedIndex}
        style={[styles.grow, styles.tabView]}
        swipeEnabled={false}
        testID="customer-tab-view"
      >
        <Tab
          testID="info-tab"
          title={translate<string>('TAB_TITLE_OVERVIEW')}
        >
          <Card style={[styles.grow, styles.card]} testID="customer-card">
            <CustomerOverviewTab customer={customer} />
          </Card>
        </Tab>
        <Tab
          testID="farms-tab"
          title={translate<string>('TAB_TITLE_FARMS')}
        >
          <FarmTabsList />
        </Tab>
        <Tab
          testID="farm-plans-tab"
          title={translate<string>('TAB_TITLE_FARM_PLANS')}
        >
          <FarmPlanTab />
        </Tab>
        <Tab
          testID="assignment-tab"
          title={translate<string>('TAB_TITLE_ASSIGNMENT')}
        >
          <View>
            <AssignmentTab customer={customer} />
          </View>
        </Tab>
        <Tab
          testID="planning-parameters-tab"
          title={translate<string>('TAB_TITLE_PLANNING_PARAMETERS')}
        >
          <PlanningParametersTab />
        </Tab>
      </TabView>
    </View>
  );
};
