/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { Component, Ref } from 'react';
import {
  GestureResponderEvent,
  NativeSyntheticEvent,
  StyleSheet,
  TargetedEvent,
  View,
} from 'react-native';
import {
  RenderProp,
  TouchableWeb,
  TouchableWebElement,
} from '@ui-kitten/components/devsupport';
import { Interaction, StyleType } from '@ui-kitten/components/theme';
import { styled } from '@ui-kitten/components';
import { ButtonProps } from '../Button/Button';
import { IconProps, IconName } from '../Icon/Icon';
import { FalsyIcon } from '../FalsyIcon/FalsyIcon';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export interface IconButtonProps extends Omit<ButtonProps, 'children' | 'ref'> {
  buttonRef?: Ref<IconButton>;
  children: RenderProp<Partial<IconProps>> | IconName | string;
  testID: string;
}

@styled('IconButton')
export class IconButton extends Component<IconButtonProps> {
  private onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.HOVER]);
    this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  private onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  private onFocus = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.FOCUSED]);
    this.props.onFocus && this.props.onFocus(event);
  };

  private onBlur = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    this.props.onBlur && this.props.onBlur(event);
  };

  private onPressIn = (event: GestureResponderEvent): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.props.onPressIn && this.props.onPressIn(event);
  };

  private onPressOut = (event: GestureResponderEvent): void => {
    this.props.eva.dispatch([]);
    this.props.onPressOut && this.props.onPressOut(event);
  };

  private getComponentStyle = (source: StyleType) => {
    const {
      iconSize,
      tintColor,
      iconMarginHorizontal,
      size,
      ...containerParameters
    } = source;

    return {
      container: {
        height: size,
        width: size,
        ...containerParameters,
      },
      icon: {
        width: iconSize,
        height: iconSize,
        tintColor,
        marginHorizontal: iconMarginHorizontal || 0,
      },
    };
  };

  public render (): TouchableWebElement {
    const {
      buttonRef,
      children,
      eva,
      size,
      status,
      style,
      ...touchableProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View pointerEvents={touchableProps.disabled ? 'none' : 'auto'}>
        <TouchableWeb
          {...touchableProps}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onPressIn={this.onPressIn}
          onPressOut={this.onPressOut}
          ref={buttonRef as Ref<TouchableWeb>}
          style={[evaStyle.container, styles.container, style]}
        >
          <FalsyIcon
            component={children}
            style={[evaStyle.icon]}
          />
        </TouchableWeb>
      </View>
    );
  }
}
