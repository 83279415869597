import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 2,
  },
  inputContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: 'black',
  },
  datePickerPanelContainer: {
    zIndex: 999,
    width: 376,
    height: 376,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  datePickerPanelHeaderContainer: { flex: 25 },
  datePickerPanelContentContainer: { flex: 75 },
  yearPickerElementOuterContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  yearPickerElementInnerContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  yearPickerElement: {
    display: 'flex',
    flex: 1,
  },
  yearPickerElementRow: {
    flex: 1,
    padding: 4,
  },
  yearPickerElementNavigationButton: {
    height: 24,
    width: 24,
  },
  monthElement: { padding: 7 },
  monthPickerElementContainer: {
    flex: 1,
    padding: 4,
  },
  monthPickerElement: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 20,
  },
  monthPickerElementRow: { flex: 1 },
  headerElement: {
    paddingVertical: 25,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  monthAndYearSelectorElementContainer: { flex: 25 },
  monthSelectorElementContainer: {
    flex: 1,
    flexDirection: 'row',
    marginRight: 10,
  },
  monthSelectorElementNavigationButton: {
    height: 24,
    width: 24,
  },
  yearSelectorElementContainer: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 10,
  },
  yearSelectorElementNavigationButton: {
    height: 24,
    width: 24,
  },
  datePickerElementContainer: {
    paddingBottom: 35,
    paddingHorizontal: 20,
    flex: 75,
  },
  datePickerElement: { flex: 1 },
  datePickerElementRow: { flex: 1 },
});
