import React, { FunctionComponent } from 'react';
import { Radio as UIKRadio, RadioProps as UIKRadioProps } from '@ui-kitten/components';
import { FalsyText } from '@ui-kitten/components/devsupport';
import { StyleProp, ViewStyle } from 'react-native';
import { Status } from '@theme/variant-interfaces/Status';

export interface RadioProps extends UIKRadioProps {
  status?: Status
  testID: string,
}
const containerStyle: StyleProp<ViewStyle> = {
  marginTop: 0,
  marginBottom: 0,
  alignItems: 'flex-start',
  maxWidth: 380,
};
const textStyle = {
  lineHeight: 20,
  letterSpacing: 0.2,
};
export const Radio: FunctionComponent<RadioProps> = (props) => {
  const {
    children,
    style,
    ...radioProps
  } = props;
  return (
    <UIKRadio
      {...radioProps}
      style={[containerStyle, style]}
    >
      {({ style: evaStyle, ...evaProps }) => (
        <FalsyText
          {...evaProps}
          component={children}
          style={[evaStyle, textStyle]}
        />
      )}
    </UIKRadio>
  );
};
