import { Input, Select, SelectItem } from '@design';
import { State } from '@shared/constants';
import { IndexPath } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface BusinessStateInputProps {
  state?: string,
  onUpdateState(state: string): void,
  states?: State[],
}
export function BusinessStateInput ({
  state,
  onUpdateState,
  states,
}: BusinessStateInputProps) {
  const [translate] = useTranslation(['businesses', 'common']);

  return !states?.length ? (
    <Input
      defaultValue={state}
      isRequired
      label={translate<string>('STATE')}
      onChangeText={(newState) => {
        onUpdateState(newState);
      }}
      testID="business-state-input"
    />
  ) : (
    <Select
      isRequired
      label={translate<string>('STATE')}
      onSelect={(idx: IndexPath | IndexPath[]) => {
        const { name } = states[(idx as IndexPath).row];
        onUpdateState(name);
      }}
      selectedIndex={state
        ? new IndexPath(states.findIndex((s) => s.name === state))
        : new IndexPath(0)}
      testID="business-state-selector"
      value={state || (states && states.length > 0 ? states[0].name : '')}
    >
      {states.map(({ name }, index) => (
        <SelectItem key={name} testID={`business-state-dropdown-value-${index}`} title={name} />
      ))}
    </Select>
  );
}
