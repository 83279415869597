interface TestIds {
  nativeID: string;
  testID: string;
}
type TestID = (id: string, postfix?: string | number) => TestIds;

export const getTestIdWithPostfix = (id: string, postfix?: string | number) => {
  if (!id) {
    return null;
  }
  if (postfix === undefined) {
    return id;
  }
  return `${id}-${postfix}`;
};

/**
 * @deprecated testID
 */
export const testId: TestID = (testID: string, postfix?: string | number) => {
  const id = getTestIdWithPostfix(testID, postfix);
  return {
    nativeID: id,
    testID: id,
  };
};
