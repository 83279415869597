import { DiscountType } from '@shared/enums';
import { ApiDiscount } from '@shared/interfaces/api';
import _ from 'lodash';

export class StringUtility {
  static charactersRemaining (str: string, maxLength: number) {
    const remaining = maxLength - str.length;
    return remaining < 0 ? 0 : remaining;
  }

  /**
   * Utility to return proper case of words
   * @param input The string to fix
   * @returns Each of the first letter of every word being capitalized
   */
  static toProperCase (input: string) {
    const words = input.replace(/(_|-)/g, ' ').toLocaleLowerCase().split(' ');
    return _.reduce(words, (memo, word) => `${memo} ${_.upperFirst(word)}`, '');
  }

  /**
   * Special toFixed that properly rounds
   * @param num The number to fix
   * @param length The number of digits after the decimal
   * @returns The fixed decimal number
   */
  static roundToFixed = (num: number, length: number = 3) => {
    if (_.isNaN(num) || _.isNil(num)) {
      return '';
    }

    let n = Number(num) * (10 ** length);
    n = Math.round(n);
    n *= (10 ** -length);

    return n.toFixed(length);
  };

  /**
   * Limits the decimal points of a number
   * @param num The number to parse
   * @param length The maximum number of digits after the decimal to include
   * @returns A string with no decimals if it's an integer, or 1-$length digits after the decimal
   */
  static formatDecimal = (num: number, length: number = 3): string => {
    if (_.isNaN(num) || _.isNil(num)) {
      return '';
    }

    if (!(num % 1)) { // If it's a whole number
      return num.toString();
    }

    let str = StringUtility.roundToFixed(num, length);
    while (str[str.length - 1] === '0' && length > 0) {
      str = str.slice(0, str.length - 1);
    }

    return str.endsWith('.') ? str.slice(0, str.length - 1) : str;
  };

  /**
   * Localizes a number to make it more readable
   * @param num The number to parse
   * @param length The maximum number of digits after the decimal to include
   * @param forceFixed If true, do not trim off trailing zeroes from the decimal.
   * @returns A string with no decimals if it's an integer, or 1-$length digits after the decimal
   */
  static localizeNumber = (num: number, length: number = 0, forceFixed?: boolean): string => {
    if (_.isNaN(num) || _.isNil(num)) {
      return '';
    }

    // TODO: lang value should be the current language of react-i18next
    const lang = 'en'; // should be defined for iOS

    let str = Number(StringUtility.roundToFixed(Number(num), length)).toLocaleString(lang, {
      minimumFractionDigits: length,
      maximumFractionDigits: length,
    });
    if (!forceFixed && str.indexOf('.') > -1) {
      while (str[str.length - 1] === '0') {
        str = str.slice(0, str.length - 1);
      }
    }
    if (str.indexOf('.') === str.length - 1) {
      str = str.slice(0, str.length - 1);
    }

    return str;
  };

  /**
   * Formats a number to two decimal currency with negative like $-xx.xx
   * @param num The number to format
   * @param symbol The currency symbol
   * @returns The formatted currency.
   */
  static formatCurrency = (num: number, symbol: string = '$', decimals: number = 2) => (
    `${symbol}${StringUtility.localizeNumber(num, decimals, true) || '0'}`
  );

  /**
   * Formats a number to two decimal currency with negative like ($xx.xx)
   * @param num The number to format
   * @param symbol The currency symbol
   * @returns The formatted currency.
   */
  static formatCurrencyAccounting = (num: number, symbol: string = '$') => {
    if (num < 0) {
      return `(${StringUtility.formatCurrency(num * -1, symbol)})`;
    }
    return StringUtility.formatCurrency(num, symbol);
  };

  /**
   * Formats a number to integer currency with negative like ($xx.xx)
   * @param num The number to format
   * @param symbol The currency symbol
   * @returns The formatted currency.
   */
  static formatFlatCurrencyAccounting = (num: number, symbol: string = '$') => {
    if (num < 0) {
      return `(${StringUtility.formatCurrency(num * -1, symbol, 0)})`;
    }
    return StringUtility.formatCurrency(num, symbol, 0);
  };

  static formatFlatDiscount = (num: number, symbol: string = '−$') => (
    `(${StringUtility.formatCurrency(num, symbol, 0)})`
  );
  static formatDiscount = (num: number, symbol: string = '−$') => (
    `(${StringUtility.formatCurrency(num, symbol)})`
  );

  static formatDiscountAmount (
    discount: Pick<ApiDiscount, 'discountType' | 'dollars' | 'percent'>,
  ) {
    let discountAmt: string;
    switch (discount.discountType) {
      case DiscountType.DOLLARS:{
        discountAmt = this.formatCurrency(discount.dollars);
        break;
      }
      case DiscountType.PERCENT: {
        discountAmt = `${this.formatDecimal(discount.percent, 4)}%`;
        break;
      }
      default:
        throw new Error('Discount type not allowed');
    }
    return discountAmt;
  }

  /**
   * Formats a number to integer currency
   * @param num The number to format
   * @param symbol The currency symbol
   * @returns The formatted currency
   */
  static formatFlatCurrency = (num: number, symbol: string = '$') => (
    `${symbol}${StringUtility.localizeNumber(num, 0, true) || '0'}`
  );

  /**
   * Formats a number as currency, dropping the cents after 100k and moving
   * to abbreviations after 1M
   * @param num the number to format
   * @param symbol the currency symbol
   */
  static formatCurrencyShort = (num: number, symbol: string = '$') => {
    if (num < 1000000) {
      return StringUtility.formatCurrency(num, symbol);
    }

    return StringUtility.formatFlatCurrencyShort(num);
  };

  /**
   * Formats a number to integer currency, but shortens numbers over 1M
   * to a 3 decimal fixed number with M, B, T suffix
   * @param num The number to format
   * @param symbol The currency symbol
   * @returns The formatted currency
   */
  static formatFlatCurrencyShort = (num: number, symbol: string = '$') => {
    let suffix = '';
    let adjusted = Math.round(num);

    if (adjusted >= 10 ** 12) {
      adjusted = Math.round(num / 10 ** 9) / 1000;
      suffix = 'T';
    } else if (adjusted >= 10 ** 9) {
      adjusted = Math.round(num / 10 ** 6) / 1000;
      suffix = 'B';
      if (adjusted === 1000) {
        adjusted = 1;
        suffix = 'T';
      }
    } else if (adjusted >= 10 ** 6) {
      adjusted = Math.round(num / 1000) / 1000;
      suffix = 'M';
      if (adjusted === 1000) {
        adjusted = 1;
        suffix = 'B';
      }
    }

    const localized = StringUtility.localizeNumber(adjusted, suffix ? 3 : 0);

    return `${symbol}${localized}${suffix}`;
  };

  // Copy of the function above, but with slightly different
  // rounding rules and an added K suffix. The one above is (probably?) ok
  // for its use in deliverables, but it rounds so much the numbers quickly
  // stop adding up. I can't think of a better name that isn't already taken.
  static formatFlatCurrencyShortBasic = (
    num: number,
    symbol: string = '$',
  ) => {
    let suffix = '';
    let adjusted = Math.round(num);

    if (adjusted >= 10 ** 12) {
      adjusted = (num / 10 ** 9) / 1000;
      suffix = 'T';
    } else if (adjusted >= 10 ** 9) {
      adjusted = (num / 10 ** 6) / 1000;
      suffix = 'B';
      if (Math.round(adjusted) === 1000) {
        adjusted = 1;
        suffix = 'T';
      }
    } else if (adjusted >= 10 ** 6) {
      adjusted = (num / 1000) / 1000;
      suffix = 'M';
      if (Math.round(adjusted) === 1000) {
        adjusted = 1;
        suffix = 'B';
      }
    } else if (adjusted >= 10 ** 3) {
      adjusted = (num / 1000);
      suffix = 'K';
      if (Math.round(adjusted) === 1000) {
        adjusted = 1;
        suffix = 'M';
      }
    }

    const localized = StringUtility.localizeNumber(adjusted, 2);

    return `${symbol}${localized}${suffix}`;
  };

  /**
 * If a string has a trailing number, increment it, otherwise append one.
 * @example "Test" becomes "Test 2", "Test 2" becomes "Test 3".
 */
  static incrementTrailingNumber = (str: string, items: string[]) => {
    let count = 1;

    for (const item of items) {
      if (item.startsWith(str)) {
        const num = item.slice(str.length + 1);
        if (num.match(/\d+$/)) {
          count = Math.max(count, parseInt(num, 10));
        }
      }
    }
    count += 1;
    return `${str} ${count}`;
  };

  static truncate = (text: string, max = 20) => {
    return (text.length < max) ? text : `${text.substring(0, max)}...`;
  };
}
