import React, { FunctionComponent, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Filter, Text } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const status = {
  columnLabel: 'Status',
  columnKey: 'status',
  columns: [ // FilterCategory array
    { label: 'Active', id: 'active' },
    { label: 'Inactive', id: 'inactive' },
  ],
};

const location = {
  columnLabel: 'Location',
  columnKey: 'location',
  columns: [ // FilterCategory array
    { label: 'North Carolina', id: 'north carolina', disabled: false },
    { label: 'New York uwidwq dwq dhwqi dqwhi wqdi dwiqwdhiwd hiqdw iqdw hiqwd hiwdq', id: 'new york', disabled: false },
    { label: 'Florida', id: 'florida', disabled: false },
    { label: 'Texas', id: 'texas', disabled: false },
    { label: 'Virginia', id: 'virginia', disabled: false },
    { label: 'State1', id: 'State1', disabled: false },
    { label: 'State11', id: 'State11', disabled: false },
    { label: 'State111', id: 'State111', disabled: false },
    { label: 'State1111', id: 'State1111', disabled: false },
    { label: 'State12', id: 'State12', disabled: false },
    { label: 'State122', id: 'State122', disabled: false },
    { label: 'State1222', id: 'State1222', disabled: false },
    { label: 'State12222', id: 'State12222', disabled: false },
  ],
};

const farm = {
  columnLabel: 'Farms',
  columnKey: 'farm',
  columns: [ // FilterCategory array
    { label: 'Daves farm', id: 'daves farm', disabled: false },
    { label: 'Daves sisters farm', id: 'daves sisters farm', disabled: false },
    { label: 'Daves brothers farm', id: 'daves brothers farm', disabled: false },
    { label: 'Daves Dads farm', id: 'daves dads farm', disabled: false },
    { label: 'Daves moms farm', id: 'daves moms farm', disabled: false },
  ],
};

const product = {
  columnLabel: 'Product',
  columnKey: 'product',
  columns: [ // FilterCategory array
    { label: 'Wheat', id: 'wheat', disabled: false },
    { label: 'Beans', id: 'beans', disabled: false },
    { label: 'Coffee', id: 'coffee', disabled: false },
    { label: 'Corn', id: 'corn', disabled: false },
    { label: 'Potatos', id: 'potatos', disabled: false },
    { label: 'Canola', id: 'canola', disabled: false },
    { label: 'Carrots', id: 'carrots', disabled: false },
  ],
};
const cropType = {
  columnLabel: 'Product',
  columnKey: 'product',
  columns: [ // FilterCategory array
    { label: 'Alfalfa', id: 'Alfalfa', disabled: false },
    { label: 'Asparagus', id: 'Asparagus', disabled: false },
    { label: 'Avocado', id: 'Avocado', disabled: false },
    { label: 'Beans', id: 'Beans', disabled: false },
    { label: 'Beetroot', id: 'Beetroot', disabled: false },
    { label: 'Bananas', id: 'Bananas', disabled: false },
    { label: 'Broccoli', id: 'Broccoli', disabled: false },
    { label: 'Oranges', id: 'Oranges', disabled: false },
    { label: 'Blueberries', id: 'Blueberries', disabled: false },
    { label: 'Apples', id: 'Apples', disabled: false },
    { label: 'Other1', id: 'Other1', disabled: false },
    { label: 'Other2', id: 'Other2', disabled: false },
    { label: 'Other3', id: 'Other3', disabled: false },
    { label: 'Other4', id: 'Other4', disabled: false },
  ],
};

const acreage = {
  columnLabel: 'Acreage',
  columnKey: 'acreage',
  columns: [ // FilterCategory array
    { label: '1 acres', id: '1_acres', disabled: false },
    { label: '10 acres', id: '10_acres', disabled: false },
    { label: '100 acres', id: '100_acres', disabled: false },
    { label: '1000 acres', id: '1000_acres', disabled: false },
    { label: '10000 acres', id: '10000_acres', disabled: false },
  ],
};

const acreage2 = {
  columnLabel: 'Acreage2',
  columnKey: 'acreage2',
  columns: [ // FilterCategory array
    { label: '1 acres', id: '1_acres', disabled: false },
    { label: '10 acres', id: '10_acres', disabled: false },
    { label: '100 acres', id: '100_acres', disabled: false },
    { label: '1000 acres', id: '1000_acres', disabled: false },
    { label: '10000 acres', id: '10000_acres', disabled: false },
  ],
};

const defaultFilters = [status, location, farm, product, cropType, acreage, acreage2];
const defaultColumnFilters = ['status', 'location', 'farm'];

export const FilterDemo: FunctionComponent = () => {
  const [filter1, setFilter1] = useState({});
  const [filter2, setFilter2] = useState({});
  const [noFilters, setNoFilters] = useState(false);
  const [filterOptions, setFilterOptions] = useState(null);

  useEffect(() => {
    const newFilterOptions = new Map();
    defaultFilters.forEach((val) => {
      newFilterOptions.set(val.columnKey, val);
    });
    setFilterOptions(newFilterOptions);
  }, []);

  return (
    <View>
      <DemoContainer>
        <DemoBlock style={{ flexDirection: 'row' }}>
          <Text category="h5">Toggle filters on/off: </Text>
          <Button onPress={() => setNoFilters(!noFilters)} testID="test-button">Toggle Filters</Button>
        </DemoBlock>
      </DemoContainer>
      {!noFilters
      && (
      <View>
        <DemoContainer>
          <View style={{ width: '90%', height: 60, borderWidth: 1, borderColor: 'white' }}>
            <Text>Generated API Query: </Text>
            <Text>{`${filter1}`}</Text>
          </View>
        </DemoContainer>
        <DemoContainer>
          <DemoBlock>
            <Text>Search Bar with Filters</Text>
            {filterOptions && (
            <Filter
              defaultColumnFilters={defaultColumnFilters}
              filterOptions={filterOptions}
              key={1}
              noFilters={noFilters}
              onUpdateFilter={(filter) => setFilter1(filter)}
              testID="demo-1-filter"
              totalResults={4}
            />
            )}
          </DemoBlock>
        </DemoContainer>
      </View>
      )}
      {noFilters
      && (
      <View>
        <DemoContainer>
          <View style={{ width: '90%', height: 60, borderWidth: 1, borderColor: 'white' }}>
            <Text>Generated API Query: </Text>
            <Text>{`${filter2}`}</Text>
          </View>
        </DemoContainer>
        <DemoContainer>
          <DemoBlock>
            <Text>Search Bar without Filters</Text>
            {filterOptions && (
            <Filter
              defaultColumnFilters={defaultColumnFilters}
              filterOptions={filterOptions}
              key={2}
              noFilters={noFilters}
              onUpdateFilter={(filter) => setFilter2(filter)}
              testID="demo-2-filter"
              totalResults={4}
            />
            )}
          </DemoBlock>
        </DemoContainer>
      </View>
      )}
    </View>
  );
};
