import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    margin: 5,
  },
  roundButton: {
    borderRadius: 360,
    padding: 0,
    width: 30,
    height: 30,
  },
});
