import { TextLink } from '@design';
import React, { useState } from 'react';
import { ExternalDisplayIdText } from './ExternalDisplayIdText';
import { useTranslation } from 'react-i18next';
import { ExternalIdsModal } from './ExternalIdsModal';

interface ExternalIdsDisplayProps {
  externalIds: string[],
}

export const ExternalIdsDisplay = ({ externalIds }: ExternalIdsDisplayProps) => {
  const [showExternalIdsModal, setShowExternalIdsModal] = useState(false);
  const [translate] = useTranslation(['integration']);
  if (externalIds.length === 0) {
    return;
  }
  return (
    <>
      {externalIds.length === 1 ? (
        <ExternalDisplayIdText externalDisplayId={externalIds[0]} />
      ) : (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => setShowExternalIdsModal(true)}
        >
          {translate<string>('VIEW_IDS')}
        </TextLink>
      )}
      {showExternalIdsModal &&
        <ExternalIdsModal
          externalIds={externalIds}
          onClose={() => setShowExternalIdsModal(false)}
          visible
        />
      }
    </>
  );
};
