import { HSpacer, Logo } from '@design';
import { useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { useLogo } from '../../hooks/useLogo';
import { PoweredByGrowersLogo } from '../../svg/PoweredByGrowersLogo';

const styles = StyleSheet.create({
  logosContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  spacer: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
});

export function DeliverableLogo () {
  const { currentBusinessId } = useAuthentication();
  const { logo } = useLogo(currentBusinessId);

  const divider = useStyleSheet({
    line: {
      borderWidth: StyleSheet.hairlineWidth,
      borderRightColor: 'text-hint-color',
      borderLeftColor: 'transparent',
      borderTopColor: 'transparent',
      borderBottomColor: 'transparent',
      height: 48,
    },
  });

  return (
    <View style={styles.logosContainer}>
      {logo && (
        <>
          <Logo
            shape="square"
            size="large-rectangle"
            source={logo}
            style={{ paddingLeft: 0 }}
            testID="business-logo"
          />
          <View style={styles.spacer}>
            <HSpacer size="4" style={divider.line} />
          </View>
          <HSpacer size="7" />
        </>
      )}
      <PoweredByGrowersLogo testID="powered-by-growers-logo" />
    </View>
  );
}
