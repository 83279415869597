import React, { FC, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Button, HSpacer, Icon, Input, LargeModal, Text } from '@design';
import { SharedConfig } from '@shared/constants';
import { ConfirmationModal } from '../../shared/ConfirmationModal/ConfirmationModal';

const styles = StyleSheet.create({
  containerStyle: {
    paddingBottom: 0,
  },
  footer: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    marginBottom: 32,
    right: 0,
    bottom: 0,
  },
  noteText: {
    marginTop: 36,
    width: 660,
    maxHeight: 496,
  },
  textStyle: {
    minHeight: '45vh',
    minWidth: 660,
    overflow: 'visible',
  },
});

export enum Mode {
  ADD,
  EDIT,
  VIEW,
}

interface NotesModalProps {
  mode: Mode,
  note: string,
  onClose: () => void,
  onDelete?: () => void,
  setNote?: (note: string) => void,
}
const MaxTextLimit = SharedConfig.MAX_NOTE_LIMIT;
const RemainingCharactersLimit = 100;
export const NotesModal: FC<NotesModalProps> = ({
  mode,
  note,
  onClose,
  onDelete,
  setNote,
}: NotesModalProps) => {
  const [translate] = useTranslation(['common']);
  const [currentNote, setCurrentNote] = useState(note);
  const [haveChanges, setHaveChanges] = useState(false);
  const [deleteNoteDialogVisible, setDeleteNoteDialogVisible] = useState(false);
  const title = useMemo(() => {
    switch (mode) {
      case Mode.VIEW:
        return translate<string>('NOTE');
      case Mode.EDIT:
        return translate<string>('EDIT_NOTE');
      default:
        return translate<string>('CREATE_NOTE');
    }
  }, [mode, translate]);
  const isViewMode = mode === Mode.VIEW;

  useEffect(() => {
    if (currentNote !== note && !haveChanges) {
      setHaveChanges(true);
    } else if (currentNote === note && haveChanges) {
      setHaveChanges(false);
    }
  }, [currentNote, haveChanges, note]);

  const noteLength = () => {
    if (currentNote.length > MaxTextLimit - RemainingCharactersLimit) {
      if (currentNote.length > MaxTextLimit) {
        return `${MaxTextLimit - currentNote.length} ${translate('CHARACTERS')}`;
      }
      return `${translate('CHARACTERS_REMAINING', { remaining: MaxTextLimit - currentNote.length, maxCharacters: MaxTextLimit })}`;
    }
    return `${MaxTextLimit} ${translate('CHARACTER_LIMIT')}`;
  };

  const Footer = () => (
    <View style={styles.footer}>
      <Button
        appearance="outline"
        design="floating"
        onPress={onClose}
        size="large"
        status="basic"
        testID="cancel-button"
      >
        {translate<string>('CANCEL')}
      </Button>
      { !isViewMode && (
        <>
          <HSpacer size="7" />
          <Button
            design="floating"
            disabled={
              currentNote.length === 0
              || (mode === Mode.EDIT && !haveChanges)
              || currentNote.length > MaxTextLimit
            }
            onPress={() => {
              setNote(currentNote);
              onClose();
            }}
            size="large"
            status="primary"
            testID="submit-button"
          >
            {translate<string>(mode === Mode.ADD ? 'SAVE' : 'SAVE_CHANGES')}
          </Button>
        </>
      )}
      <HSpacer size="9" />
    </View>
  );

  const NotePage = [
    <View>
      <View style={{ alignItems: 'center' }}>
        { isViewMode ? (
          <ScrollView
            style={styles.noteText}
          >
            <Text
              category="p1"
              testID="notes-modal-view-note-text"
            >
              {note}
            </Text>
          </ScrollView>
        ) : (
          <Input
            caption={noteLength()}
            label={translate<string>('NOTE')}
            multiline
            onChangeText={setCurrentNote}
            placeholder={translate<string>('START_TYPING_HERE')}
            status={currentNote.length > MaxTextLimit ? 'danger' : 'basic'}
            testID="notes-modal-input"
            textStyle={styles.textStyle}
            value={currentNote}
          />
        )}
      </View>
    </View>,
  ];

  return (
    <>
      <LargeModal
        containerStyle={styles.containerStyle}
        footer={Footer}
      // TODO: This will be implemented in the future
      //   headerButton={(
      //     <Button
      //       accessoryLeft={(iconProps) => <Icon name="Plus" {...iconProps} />}
      //       appearance="ghost"
      //       onPress={onClose}
      //       size="large"
      //       status="basic"
      //       testID="add-template-button"
      //     >
      //       {translate<string>('APPLY_NOTE_TEMPLATE')}
      //     </Button>
      // )}
        headerButton={(buttonProps) => {
          if (!isViewMode) {
            return (
              <Button
                {...buttonProps}
                accessoryLeft={(iconProps) => <Icon name="Trash" testID="delete-note-button-icon" {...iconProps} />}
                appearance="ghost"
                disabled={!note.length}
                onPress={() => setDeleteNoteDialogVisible(true)}
                size="large"
                status="basic"
                testID="delete-note-button"
              >
                {translate<string>('DELETE_NOTE')}
              </Button>
            );
          }
          return null;
        }}
        pages={NotePage}
        testID="note-view-modal"
        title={title}
        visible
      />
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('YES_DELETE')}
        onCancel={() => setDeleteNoteDialogVisible(false)}
        onConfirm={async () => {
          setDeleteNoteDialogVisible(false);
          onDelete();
          onClose();
        }}
        status="warning"
        title={translate('DELETE_NOTE')}
        visible={deleteNoteDialogVisible}
      />
    </>
  );
};
