import { HSpacer, Text, VSpacer } from '@design';
import { Category } from '@theme/variant-interfaces/Category';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { FalsyText } from '@ui-kitten/components/devsupport';
import React from 'react';
import { FlexAlignType, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

type Level = 'globalDesktop' | 'globalMobile' | '1' | '2' | '3' | '4';
interface LevelOption {
  minHeight: number,
  paddingTop: number,
  maxWidth: number,
  titleCategory: Category,
  hSpacer: SpacerSize,
}

const LEVELS: { [key in Level]: LevelOption } = {
  globalDesktop: { minHeight: 72, paddingTop: 27, maxWidth: 650, titleCategory: 'c1', hSpacer: '8' },
  globalMobile: { minHeight: 48, paddingTop: 15, maxWidth: 650, titleCategory: 'c1', hSpacer: '8' },
  1: { minHeight: 48, paddingTop: 9, maxWidth: 650, titleCategory: 'h3', hSpacer: '8' },
  2: { minHeight: 40, paddingTop: 10, maxWidth: 540, titleCategory: 'h6', hSpacer: '7' },
  3: { minHeight: 32, paddingTop: 8, maxWidth: 440, titleCategory: 's1', hSpacer: '7' },
  4: { minHeight: 40, paddingTop: 13, maxWidth: 340, titleCategory: 'label', hSpacer: '7' },
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignSelf: 'stretch',
  },
  headerLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  actions: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minWidth: 'auto',
  },
});

export interface Props {
  children?: React.ReactNode,
  childrenAlign?: FlexAlignType,
  childrenStyle?: StyleProp<ViewStyle>,
  leftAccessory?(paddingTop: number): React.ReactNode,
  level?: Level,
  overline?: string,
  subTitle?: string,
  subTitleHint?: boolean,
  testID: string,
  title?: string,
  titleHint?: boolean,
  titleMaxWidth?: number | string,
}
export function Header ({
  children,
  childrenAlign = 'center',
  childrenStyle,
  leftAccessory,
  level = '1',
  overline,
  subTitle,
  subTitleHint,
  testID,
  title,
  titleHint,
  titleMaxWidth,
  ...props
}: Props) {
  const { minHeight, paddingTop, maxWidth, titleCategory, hSpacer } = LEVELS[level];

  return (
    <View style={styles.container} testID={testID} {...props}>
      <FalsyText appearance="hint" category="overline" component={overline} />
      <View style={[styles.headerLine, { minHeight }]}>
        {leftAccessory?.(paddingTop)}
        <Text
          appearance={titleHint ? 'hint' : 'default'}
          category={titleCategory}
          style={{ paddingTop, maxWidth: titleMaxWidth || maxWidth }}
          testID={`${testID}-title`}
        >
          {title}
        </Text>
        {!!children && (
          <View style={[styles.actions, { paddingTop, alignSelf: childrenAlign }, childrenStyle]}>
            <HSpacer size={hSpacer} />
            {children}
          </View>
        )}
      </View>
      {!!subTitle && (
        <>
          <VSpacer size="2" />
          <Text
            appearance={subTitleHint && 'hint'}
            category="p2"
            testID={`${testID}-subtitle`}
          >
            {subTitle}
          </Text>
        </>
      )}
    </View>
  );
}
