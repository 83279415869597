import { Badge, Card, HSpacer, Icon, IconButton, Modal, Text, TextLink } from '@design';
import { ApiGrower, ApiGrowerContact } from '@shared/interfaces/api';
import { ScreenSizes, useScreenSizing } from '../../../../hooks/useScreenSizing';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import { LinkingUtility } from '../../../../utilities';
import { getCountryFromAbbreviation, getStateAbbreviation } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';

type PickedGrower = Pick<ApiGrower, 'country' | 'county'>;
type PickedContact = Pick<ApiGrowerContact,
  | 'address1'
  | 'address2'
  | 'city'
  | 'country'
  | 'email'
  | 'firstName'
  | 'isActive'
  | 'isPrimary'
  | 'lastName'
  | 'postalCode'
  | 'state'
  | 'tags'
  | 'telephone'
>;
interface ContactCardProps {
  canEdit: boolean,
  contact: PickedContact,
  customer: PickedGrower,
  onEdit: () => void,
}

interface LocationModalProps {
  contact: PickedContact,
  customer: PickedGrower,
  onClose: () => void,
}

const styles = StyleSheet.create({
  activeRow: { flexDirection: 'row', flex: 1, alignItems: 'center' },
  wrapper: { flexDirection: 'row', padding: 8, paddingRight: 12, alignItems: 'center' },
});

const LocationModal = ({ contact, customer, onClose }: LocationModalProps) => {
  const [translate] = useTranslation(['common']);

  const addressLine = [contact.address1, contact.address2].filter(Boolean).join(' ');
  const postalCode = contact.postalCode ? ' ' + contact.postalCode : '';
  const stateAbbv = getStateAbbreviation(contact.state);
  const cityStateLine = [contact.city, stateAbbv].filter(Boolean).join(', ') + postalCode;
  const countryLine = getCountryFromAbbreviation(contact.country || customer.country);

  const contactInfo = [
    addressLine,
    cityStateLine,
    customer.county,
    countryLine,
  ].filter(Boolean);

  return (
    <Modal
      onClose={onClose}
      style={{ borderRadius: 16 }}
      testID="contact-location-modal"
      title={translate('LOCATION')}
      visible
      width={360}
    >
      <View>
        {contactInfo.map((info, index) => (
          <Text key={index}>{info}</Text>
        ))}
      </View>
    </Modal>
  );
};

const ContactWrapper = ({
  children,
  style,
}: { children: React.ReactNode, style?: ViewProps['style'] }) => (
  <View style={[styles.wrapper, style]}>
    {children}
  </View>
);

export const ContactCard = ({
  canEdit = true,
  contact,
  customer,
  onEdit,
}: ContactCardProps) => {
  const [translate] = useTranslation(['common']);
  const [isLocationVisible, setIsLocationVisible] = useState(false);
  const screenSize = useScreenSizing();
  const isDesktop = screenSize === ScreenSizes.Wide;
  const contactName = `${contact.firstName} ${contact.lastName}`;

  const sharedDotStyle: ViewStyle = {
    borderRadius: 3,
    height: 6,
    width: 6,
  };
  const dotStyles = useStyleSheet({
    active: { ...sharedDotStyle, backgroundColor: 'color-success-500' },
    inactive: { ...sharedDotStyle, backgroundColor: 'color-danger-500' },
  });

  const ActiveRow = (
    <View style={styles.activeRow}>
      <View style={contact.isActive ? dotStyles.active : dotStyles.inactive} />
      <HSpacer size="2" />
      <Text
        category="overline"
        status={contact.isActive ? 'success' : 'danger'}
        testID={`contact-card-${contact.isActive ? 'active' : 'inactive'}`}
      >
        {translate(contact.isActive ? 'ACTIVE' : 'INACTIVE').toUpperCase()}
      </Text>
    </View>
  );

  const ContactName = (
    <ContactWrapper style={{ flex: 1 }}>
      <Text category="p2" testID="contact-card-name">
        {contactName}
      </Text>
    </ContactWrapper>
  );

  const Phone = (
    <ContactWrapper>
      <TextLink
        accessoryLeft={(props) => (
          <Icon name="Phone" size="small" testID="contact-card-telephone-icon" {...props} />
        )}
        category="c2"
        onPress={() => LinkingUtility.callTelephone(contact.telephone)}
        style={{ alignItems: 'center' }}
        testID="contact-card-phone-number"
      >
        {contact.telephone}
      </TextLink>
    </ContactWrapper>
  );

  const Email = (
    <ContactWrapper style={{ flex: 1 }}>
      <TextLink
        accessoryLeft={(props) => (
          <Icon name="Email" size="small" testID="contact-card-email-icon" {...props} />
        )}
        category="c2"
        onPress={() => LinkingUtility.openEmail(contact.email)}
        style={{ wordBreak: 'break-all' }}
        testID="contact-card-email-address"
      >
        {contact.email}
      </TextLink>
    </ContactWrapper>
  );

  const PrimaryContact = (
    <View style={{ flex: 1, paddingLeft: 8 }}>
      <Text category="c1" testID="contact-card-primary-contact">
        {translate('PRIMARY_CONTACT_SENTENCE')}
      </Text>
    </View>
  );

  const locationInfo = [
    contact.city ?? null,
    getStateAbbreviation(contact.state),
  ].filter(Boolean).join(', ');

  const Location = () => (
    <ContactWrapper>
      <TextLink
        accessoryLeft={(props) => (
          <Icon name="Pin" size="small" testID="contact-card-map-icon" {...props} />
        )}
        category="c2"
        onPress={() => setIsLocationVisible(true)}
        testID="contact-card-location"
      >
        {locationInfo}
      </TextLink>
    </ContactWrapper>
  );

  const EditButton = (
    <IconButton
      appearance="ghost"
      onPress={onEdit}
      size="large"
      status="basic"
      testID="contact-card-edit-button"
    >
      Edit
    </IconButton>
  );

  const contactRowStyle: ViewStyle = {
    flexDirection: isDesktop ? 'row' : 'column',
    flexWrap: 'wrap',
    flex: 1,
  };

  return (
    <>
      <Card style={{ paddingHorizontal: 16, paddingBottom: 16, paddingTop: 16 }} testID="contact-card">
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            {ActiveRow}
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={contactRowStyle}>
                {ContactName}
                <View style={{ flexDirection: 'row' }}>
                  {contact.telephone && Phone}
                  {contact.email && Email}
                </View>
                {(contact.city || contact.state) && <Location />}
              </View>
            </View>
            <View>
              {contact.isPrimary && PrimaryContact}
            </View>
            {contact.tags.length > 0 && (
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {contact.tags.map((tag, index) => (
                  <View key={`${tag}-${index}`} style={{ marginLeft: 8, marginRight: 12, marginVertical: 4 }}>
                    <Badge
                      appearance="tag"
                      testID={`contact-card-tag-${index}`}
                    >
                      {tag}
                    </Badge>
                  </View>
                ))}
              </View>
            )}
          </View>
          {canEdit && (
            <View style={{ justifyContent: 'center' }}>
              {EditButton}
            </View>
          )}
        </View>
      </Card>
      {isLocationVisible && (
        <LocationModal
          contact={contact}
          customer={customer}
          onClose={() => setIsLocationVisible(false)}
        />
      )}
    </>
  );
};
