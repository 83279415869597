import React, { FC } from 'react';
import { View } from 'react-native';
import { FalsyText } from '@ui-kitten/components/devsupport';
import { useStyleSheet } from '@ui-kitten/components';
import {
  StepIndicator,
  StepIndicatorProps,
} from '@design';
import { styles } from './HeadedStepIndicatorStyles';

interface HeadedStepIndicatorProps extends StepIndicatorProps {
  heading?: string;
  subTitle?: string;
}

const HeadedStepIndicator: FC<HeadedStepIndicatorProps> = ({
  data,
  currentPage,
  style,
  position,
  heading,
  subTitle,
  ...viewProps
}) => {
  const themedStyles = useStyleSheet(styles);

  return (
    <View style={[themedStyles.container, style]} {...viewProps}>
      <StepIndicator
        currentPage={currentPage}
        data={data}
        direction="horizontal"
        position={position}
      />
      <FalsyText component={heading} style={themedStyles.heading} />
      <FalsyText component={subTitle} style={themedStyles.subTitle} />
    </View>
  );
};

export default HeadedStepIndicator;
