import { PaletteOptions } from '@mui/material';
import { basic, error, info, primary, success, warning } from './colors';

export const palette : PaletteOptions = {
  primary: {
    main: primary[500],
  },
  secondary: {
    main: basic[300],
  },
  background: {
    default: basic[1000],
    paper: basic[900],
  },
  text: {
    primary: basic[200],
    disabled: basic['transparent-600'],
    secondary: basic.default,
  },
  warning: {
    main: warning[500],
  },
  error: {
    main: error[500],
  },
  info: {
    main: info[500],
  },
  success: {
    main: success[500],
  },
  action: {
    disabledBackground: basic['transparent-300'],
  },
};
