/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import { Card, ColorCircle, ColorSelect, Text } from '@design';
import { CropColor, CropColors, ECropColor } from '@theme/variant-interfaces/Colors';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  demoBlock: {
    flex: 1,
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
  colors: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  },
});

export const ColorSelectDemo: React.FC = () => {
  const [selection, setSelection] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<CropColor>();

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <DemoContainer>
        <Card style={{ flex: 1 }} testID="test-card" title="Color Circle">
          <DemoBlock style={styles.container}>
            <View nativeID="default" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 8 }}>
              <ColorCircle
                onPressIn={() => setSelection(!selection)}
                selected={selection}
                testID="demo-circle"
              />
            </View>
            {CropColors.map((color, i) => (
              <View
                key={`${color}-${i}`}
                nativeID={color}
                style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 8 }}
              >
                <ColorCircle
                  color={color}
                  onPress={() => setSelection(!selection)}
                  selected={selection}
                  testID={`demo-circle-${i}`}
                />
              </View>
            ))}
          </DemoBlock>
        </Card>
      </DemoContainer>
      <DemoContainer style={styles.container}>
        <Card style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#212121', height: 250 }} testID="test-card">
          <Text category="h6">Color Select</Text>
          <DemoBlock>
            <ColorSelect
              label="Color"
              onSelect={(ip: IndexPath | IndexPath[]) => setSelectedColor(
                CropColors[(ip as IndexPath).row],
              )}
              selectedIndex={new IndexPath(CropColors.findIndex((c) => c === selectedColor))}
              testID="demo"
              value={selectedColor}
            >
              {CropColors.map((color, i) => <ColorCircle color={color} key={color} testID={`demo-circle-${i}`} />)}
            </ColorSelect>
            <Text category="h6">{selectedColor}</Text>
            <Text
              category="h6"
              style={{
                // if we are going to be having text in the same colors as the circles
                // consider adding a variant group to text for 'cropColor' that uses
                // the same variables in the mapping file.
                color: ECropColor[selectedColor],
                marginTop: 16,
              }}
            >
              {ECropColor[selectedColor]}
            </Text>
          </DemoBlock>
        </Card>
      </DemoContainer>
    </View>
  );
};
