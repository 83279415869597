import { Card, IndexPath } from '@ui-kitten/components';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Badge, Select, Icon, IconName, IconNames, Input, SelectItem } from '@design';
import { BadgeStatuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const BadgeDemo: FunctionComponent = () => {
  const [text, setText] = useState<string>('LABEL');
  const [iconLeft, setIconLeft] = useState<IconName>(null);
  const [iconRight, setIconRight] = useState<IconName>(null);

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer>
          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
            <Select
              label="accessoryLeft"
              onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
                IconNames[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)}
              testID="badge-demo-accessory-left-selector"
              value={iconLeft || 'None'}
            >
              {[
                <SelectItem key="noicon" testID="badge-demo-left-icon-dropdown-value" title="None" />,
                ...IconNames.map((n, i) => (
                  <SelectItem key={n} testID={`badge-demo-left-icon-name-dropdown-value-${i}`} title={n} />
                )),
              ]}
            </Select>
            <Input
              label="Badge Text"
              onChangeText={setText}
              testID="test-input"
              value={text}
            />
            <Select
              label="accessoryRight"
              onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
                IconNames[(ip as IndexPath).row - 1],
              )}
              selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
              testID="badge-demo-accessory-right-selector"
              value={iconRight || 'None'}
            >
              {[
                <SelectItem key="noicon" testID="badge-demo-right-icon-dropdown-value" title="None" />,
                ...IconNames.map((n, i) => (
                  <SelectItem key={n} testID={`badge-demo-right-icon-name-dropdown-value-${i}`} title={n} />
                )),
              ]}
            </Select>
          </View>
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1, backgroundColor: '#262626' }} testID="test-card">
        <DemoContainer label="Badges">
          <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>
            {BadgeStatuses.map((status) => (
              <DemoBlock>
                <Badge
                  accessoryLeft={iconLeft && ((props) => <Icon name={iconLeft} testID="left-icon" {...props} />)}
                  accessoryRight={iconRight && ((props) => <Icon name={iconRight} testID="right-icon" {...props} />)}
                  status={status}
                  testID={status}
                >
                  {`${text} - ${status}`}
                </Badge>
              </DemoBlock>
            ))}
          </View>
        </DemoContainer>
        <DemoContainer label="Tag">
            <DemoBlock>
              <Badge appearance="tag" testID="test-tag">Badge Text</Badge>
            </DemoBlock>
        </DemoContainer>
      </Card>
    </View>
  );
};
