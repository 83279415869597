import { GrowerEndpoint } from '@shared/interfaces/api';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import { Button, HSpacer, Icon, Input, Modal, Text, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { StringUtility } from '../../../utilities';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';

interface CustomerNoteModalProps {
  note?: string,
  onClose: () => void,
  onUpdateNote(note: string): void,
  viewMode?: boolean,
  visible: boolean,
}

const styles = StyleSheet.create({
  noteContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: -8,
    height: 555,
  },
  textStyle: {
    height: 229,
    overflow: 'visible',
  },
  footer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const CustomerNoteModal = (
  props: CustomerNoteModalProps,
) => {
  const {
    onClose,
    onUpdateNote,
    visible,
  } = props;

  const [translate] = useTranslation(['common', 'growers']);
  const [deleteNoteDialogVisible, setDeleteNoteDialogVisible] = useState(false);
  const [note, setNote] = useState(props.note ?? '');
  const [viewMode, setViewMode] = useState<boolean>(props.viewMode ?? false);
  const { width: windowWidth } = useWindowDimensions();

  const getModalTile = () => {
    if (props.note === null) {
      return translate<string>('ADD_NOTE');
    } if (viewMode) {
      return translate<string>('NOTE');
    }

    return translate<string>('EDIT_NOTE');
  };

  const renderFooterAccessory = ({
    primaryButtonProp,
    secondaryButtonProp,
    spacerProp,
  }) => {
    if (props.note === null) {
      return (
        <>
          <Button
            testID="customer-note-modal-cancel-button"
            {...primaryButtonProp}
            onPress={onClose}
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            disabled={!note}
            testID="customer-note-modal-save-button"
            {...secondaryButtonProp}
            onPress={() => onUpdateNote(note)}
          >
            {translate<string>('SAVE')}
          </Button>
        </>
      );
    } if (viewMode) {
      return (
        <View style={styles.footer}>
          <Button
            accessoryLeft={<Icon {...props} name="Edit" testID="customer-note-modal-edit-button-icon" />}
            appearance="ghost"
            onPress={() => {
              setViewMode(false);
            }}
            size="medium"
            status="basic"
            testID="customer-note-modal-edit-button"
          >
            <Text appearance="hint" category="c2">
              {translate<string>('EDIT_NOTE')}
            </Text>
          </Button>
          <Button
            testID="customer-note-modal-close-button"
            {...secondaryButtonProp}
            appearance="filled"
            onPress={onClose}
          >
            {translate<string>('CLOSE')}
          </Button>
        </View>
      );
    }

    return (
      <View style={styles.footer}>
        <Button
          accessoryLeft={<Icon {...props} name="Trash" testID="customer-note-modal-delete-button-icon" />}
          appearance="ghost"
          onPress={() => {
            setDeleteNoteDialogVisible(true);
          }}
          size="medium"
          status="basic"
          testID="customer-note-modal-delete-button"
        >
          <Text appearance="hint" category="c2">
            {translate<string>('DELETE_NOTE')}
          </Text>
        </Button>
        <View style={{ flexDirection: 'row' }}>
          <Button
            testID="customer-note-modal-cancel-button"
            {...primaryButtonProp}
            onPress={onClose}
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            disabled={!note}
            testID="customer-note-modal-save-changes-button"
            {...secondaryButtonProp}
            onPress={() => onUpdateNote(note)}
          >
            {translate<string>('SAVE_CHANGES')}
          </Button>
        </View>
      </View>
    );
  };

  const remaining = StringUtility.localizeNumber(
    GrowerEndpoint.MaxNoteLength - (note?.length ?? 0),
  );

  return (
    <>
      <Modal
        footerAccessory={renderFooterAccessory}
        hideCloseButton
        style={{ minWidth: windowWidth > 800 ? 721 : undefined }}
        testID="customer-note-modal"
        title={getModalTile()}
        visible={visible}
      >
        {
          !viewMode ? (
            <>
              <Input
                defaultValue={note}
                maxLength={GrowerEndpoint.MaxNoteLength}
                multiline
                onChangeText={setNote}
                placeholder={translate('START_TYPING')}
                size="large"
                testID="customer-note-modal-input"
                textStyle={styles.textStyle}
              />
              <VSpacer size="3" />
              <Text appearance="hint" category="c1">
                {translate<string>('CHARACTERS_REMAINING', { remaining, maxCharacters: GrowerEndpoint.MaxNoteLength })}
              </Text>
            </>
          ) : (
            <View style={styles.noteContainer}>
              <ScrollView>
                <Text category="p2" testID="customer-note-modal-note">{note}</Text>
              </ScrollView>
            </View>
          )
        }
      </Modal>
      <ConfirmationModal
        cancelText={translate('NO_KEEP_EDITING')}
        confirmText={translate('YES_DELETE')}
        onCancel={() => setDeleteNoteDialogVisible(false)}
        onConfirm={async () => {
          setDeleteNoteDialogVisible(false);
          onUpdateNote(null);
        }}
        status="warning"
        title={translate('CONFIRM_DELETE_NOTE_DIALOG_TITLE')}
        visible={deleteNoteDialogVisible}
      />
    </>
  );
};
