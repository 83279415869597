import {
  Button,
  FilterCategory,
  FilterTable,
  HSpacer,
  Icon,
  MenuItem,
  OverflowMenu,
  SortDirection,
  Text,
  TextLink,
  ToastProps,
  useToast,
} from '@design';
import { Selections } from '@design/Filter/Filter';
import { EnumTranslations, GrowerType } from '@shared/enums';
import { ApiGrowerListItem, GrowerEndpoint } from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import { stringSort } from '@shared/utils/utils';
import { Status } from '@theme/variant-interfaces/Status';
import { DocumentResult } from 'expo-document-picker';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { QueryKeys, Routes } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { StringUtility } from '../../../utilities';
import { BusinessApi, GrowerApi } from '../../../utilities/api';
import { CSVResponse, ExportToCSV } from '../../../utilities/helpers/exportCSV';
import { ExportModal, UploadModal } from '../../components/shared';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { IColumn, RowMeta } from '../../components/SortableTable';
import { CustomerModal } from '../CustomerDetails';
import { CustomerRowDetails } from './CustomerRowDetails';

const styles = StyleSheet.create({
  flexStart: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
});

type ToggleStatusType = {
  id: string,
  isActive: boolean,
};

const DEFAULT_PAGE_OPTIONS: GrowerEndpoint.List.Query = {
  page: 0,
  sort: GrowerEndpoint.List.Sort.UPDATED_AT,
  sortDesc: true,
  isActive: ['true'],
};

// TODO: support translate method
const FILTER_OPTIONS: FilterCategory[] = [
  {
    columnLabel: 'Status',
    columnKey: 'isActive',
    columns: [{ label: 'Active', id: 'true' }, { label: 'Inactive', id: 'false' }],
  },
  {
    columnLabel: 'Type',
    columnKey: 'growerType',
    columns: Object.keys(GrowerType).map((key) => ({
      label: EnumTranslations[GrowerType[key]],
      id: GrowerType[key],
    })),
  },
  {
    columnLabel: 'Has acres',
    columnKey: 'hasAcres',
    columns: [{ label: 'Yes', id: 'true' }, { label: 'No', id: 'false' }],
  },
  {
    columnLabel: 'Assigned users',
    columnKey: 'assignedUserId',
    columns: [],
  },
];

const SUPER_STANDARD_FILTER_OPTIONS = FILTER_OPTIONS.filter(
  (item) => item.columnKey !== 'assignedUserId',
);

const DEFAULT_FILTERS: Map<string, Selections> = new Map([
  ['isActive', { true: true }],
]);

const DEFAULT_COLUMN_FILTERS = ['isActive', 'growerType', 'hasAcres', 'assignedUserId'];
const SUPER_STANDARD_COLUMN_FILTER = DEFAULT_COLUMN_FILTERS.filter((item) => item !== 'assignedUserId');

const getImportTemplate = async (translate: any, setError: any) => {
  try {
    const data: CSVResponse = await GrowerApi.getCsvTemplate();
    ExportToCSV(data.csv, translate('CUSTOMERS'));
  } catch (err) {
    setError('UNEXPECTED_ERROR');
  }
};

export const CustomerListTab = () => {
  const history = useHistory();
  const { user, currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const [translate] = useTranslation(['growers', 'common']);
  const [error, setError] = useState<Error>(null);
  const [customers, setCustomers] = useState<(ApiGrowerListItem & RowMeta)[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ToggleStatusType>(null);
  const [customerId, setCustomerId] = useState('');
  const [pageOptions, setPageOptions] = useState<GrowerEndpoint.List.Query>(DEFAULT_PAGE_OPTIONS);
  const [isCustomerFormVisible, setIsCustomerFormVisible] = useState(false);
  const [isActivationModalVisible, setActivationModalVisible] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const { createToast } = useToast();
  const toastProps = useCallback((bannerText: string, status: Status = 'warning'): ToastProps => ({
    children: (
      <View>
        <Text>
          {bannerText}
        </Text>
      </View>
    ),
    status,
    testID: 'toast-content-element',
  }), []);
  const isSuperUser = RoleUtility.roleHasPermission(
    user.userRole,
    Permissions.ACCESS_ALL_BUSINESSES,
  );

  const isStandardUser = !RoleUtility.roleHasPermission(
    user.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const getCustomerList = async () => (
    GrowerApi.getGrowers({ businessId: currentBusinessId, isActive: ['true', 'false'], ...pageOptions })
  );
  const {
    data: customerListResponse,
    isLoading,
    isFetching,
  } = useQuery<GrowerEndpoint.List.Response, Error>(
    [QueryKeys.CUSTOMER_LIST, currentBusinessId, pageOptions],
    getCustomerList,
    {
      onError: (err) => {
        createToast(toastProps(translate<string>('UNEXPECTED_ERROR', { error: err.message })));
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (res) => {
        setCustomers(res.data.map((customer, idx) => ({
          ...customer,
          hasDetails: true,
          rowId: `row:${idx}|${customer.id}`,
        })));
      },
    },
  );

  useQuery(
    [QueryKeys.USER, currentBusinessId],
    () => BusinessApi.getUsersWithGrowerAssignment(currentBusinessId),
    {
      enabled: !isSuperUser && !!customers,
      onError: (err: Error) => {
        createToast(toastProps(translate<string>('UNEXPECTED_ERROR', { error: err.message })));
        setPageOptions({ ...pageOptions, page: 0 });
      },
      onSuccess: (assignedUserData) => {
        FILTER_OPTIONS.forEach((filterOption) => {
          switch (filterOption.columnKey) {
            case 'assignedUserId':
              filterOption.columns = assignedUserData.map((owner) => ({
                label: owner.fullName,
                id: owner.id,
              })).sort((a, b) => stringSort(a.label, b.label)) || [];
              filterOption.columns.splice(filterOption.columns.length, 0, {
                label: translate('ASSIGNED_USER_NONE'),
                id: null,
              });
              break;
            default:
          }
        });
      },
    },
  );

  useEffect(() => {
    if (error) {
      createToast(toastProps(error.message));
    }
  }, [createToast, error, toastProps]);

  const refreshList = (keepFilters: boolean = false) => {
    if (!keepFilters) {
      setPageOptions(DEFAULT_PAGE_OPTIONS);
    }
    queryClient.invalidateQueries(QueryKeys.CUSTOMER_LIST);
  };

  const toggleStatus = useMutation(
    [QueryKeys.CUSTOMER_LIST],
    ({ id, isActive }: ToggleStatusType) => (
      GrowerApi.updateGrower(id, { isActive: !isActive })
    ), {
      onError: (err: Error) => {
        createToast(toastProps(translate<string>('UNEXPECTED_ERROR', { error: err.message })));
      },
      onSuccess: () => {
        refreshList();
        setActivationModalVisible(false);
        createToast(toastProps(translate<string>('CUSTOMER_UPDATED')));
      },
    },
  );

  useEffect(() => {
    if (!isCustomerFormVisible) {
      setCustomerId('');
    }
  }, [isCustomerFormVisible]);

  const columns: IColumn<(ApiGrowerListItem & RowMeta)>[] = [
    {
      columnId: 'legalName',
      header: {
        render: translate('CUSTOMER_NAME'),
        sortable: true,
      },
      render: (customer) => (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => history.push(Routes.GROWER_DETAILS.replace(/:grower/, customer.id).replace(/:tab/, 'info'))}
          wrap
        >
          {customer.legalName}
        </TextLink>
      ),
      flex: 2,
    },
    {
      columnId: 'totalAcres',
      header: {
        render: translate('TOTAL_ACRES'),
      },
      render: (customer) => StringUtility.localizeNumber(customer.totalAcres, 0),
      flex: 1,
    },
    {
      columnId: 'county',
      header: {
        render: translate('COUNTY'),
        sortable: true,
      },
      render: (customer) => customer.county || '',
      flex: 1,
    },
    {
      columnId: 'state',
      header: {
        render: translate('STATE'),
        sortable: true,
      },
      render: (customer) => customer.state || '',
      flex: 1,
    },
    {
      columnId: 'growerType',
      header: {
        render: translate('TYPE'),
      },
      render: (customer) => `${translate(customer.growerType)}`,
      flex: 1,
    },
    {
      columnId: 'menu',
      header: {
        render: null,
      },
      render: (customer) => (
        <OverflowMenu
          placement="bottom end"
          testID="options"
        >
          <MenuItem
            onPress={() => {
              setCustomerId(customer.id);
              setIsCustomerFormVisible(true);
            }}
            testID="grower-edit"
            title={`${translate('EDIT')}`}
          />
          {customer.isActive && (
            <MenuItem
              onPress={() => {
                history.push(Routes.GROWER_DETAILS.replace(/:grower/, customer.id).replace(/:tab/, 'assignment'));
              }}
              testID="grower-assignment"
              title={`${translate('ASSIGN')}`}
            />
          )}
          <MenuItem
            onPress={() => {
              setSelectedCustomer({
                id: customer.id,
                isActive: customer.isActive,
              });
              setActivationModalVisible(true);
            }}
            testID="grower-activation"
            title={`${translate(customer.isActive ? 'DEACTIVATE' : 'ACTIVATE')}`}
          />
        </OverflowMenu>
      ),
      width: 72,
    },
  ];

  const onSort = (column: string, direction: SortDirection) => {
    setPageOptions((options) => ({
      ...options,
      page: 0,
      sort: GrowerEndpoint.List.Sort[column] ?? column,
      sortDesc: direction === 'DESC',
    }));
  };

  const createCustomerButton = (
    <Button
      accessoryLeft={(props) => <Icon name="Plus" testID="create-grower-icon" {...props} />}
      onPress={() => setIsCustomerFormVisible(true)}
      testID="create-grower"
    >
      {translate('CREATE_CUSTOMER')}
    </Button>
  );

  const exportGrowerData = useCallback(async (
    businessId: string,
    filters: GrowerEndpoint.List.Query,
  ) => {
    try {
      const queryFilters = {
        businessId,
        ...filters,
      };
      const data: CSVResponse = await GrowerApi.exportGrowers(queryFilters, businessId);
      ExportToCSV(data.csv, translate('CUSTOMERS'));
    } catch (err) {
      createToast(toastProps(translate<string>('UNEXPECTED_ERROR', { error: (err as Error).message })));
      setPageOptions(({ ...pageOptions, page: 0 }));
    }
  }, [createToast, pageOptions, toastProps, translate]);

  const ExportButton = (props) => (
    <Button
      accessoryLeft={(p) => <Icon name="FileText" testID="export-icon" {...p} />}
      appearance="ghost"
      onPress={() => setIsExportModalVisible(true)}
      size="medium"
      status="basic"
      {...props}
    >
      {translate<string>('EXPORT')}
    </Button>
  );

  const ImportButton = (props) => (
    RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES) && (
      <Button
        accessoryLeft={(p) => <Icon name="Upload" testID="import-icon" {...p} />}
        appearance="ghost"
        onPress={() => setIsImportModalVisible(true)}
        size="medium"
        status="basic"
        {...props}
      >
        {translate<string>('IMPORT')}
      </Button>
    ));

  return (
    <>
      <FilterTable
        accessoryRight={(props) => (
          <View style={{ flexDirection: 'row' }}>
            <ExportButton {...props} />
            <>
              <HSpacer size="3" />
              <ImportButton {...props} />
            </>
            {createCustomerButton}
          </View>
        )}
        columns={columns}
        currentPage={pageOptions.page + 1}
        data={customers}
        defaultColumnFilters={
          isSuperUser || isStandardUser
            ? SUPER_STANDARD_COLUMN_FILTER : DEFAULT_COLUMN_FILTERS
        }
        defaultFilters={DEFAULT_FILTERS}
        filterOptions={
          isSuperUser || isStandardUser
            ? SUPER_STANDARD_FILTER_OPTIONS : FILTER_OPTIONS
        }
        isLoading={isFetching}
        noDataMessage={isLoading ? ' ' : translate('NO_AVAILABLE_USERS')}
        noFilters={false}
        onPageChange={(page: number) => {
          setPageOptions((options) => ({ ...options, page: page - 1 }));
        }}
        onSort={onSort}
        onUpdateFilter={(filter) => {
          setPageOptions({ ...filter, page: 0 });
        }}
        rowDetail={(props) => <CustomerRowDetails {...props} />}
        tableId="customer-list-page"
        testID="customer-list-page-table"
        totalPages={(customerListResponse?.lastPage ?? 0) + 1}
        totalResults={customerListResponse?.total ?? 0}
      />
      {isCustomerFormVisible && (
        <CustomerModal
          growerId={customerId}
          modalToggle={() => setIsCustomerFormVisible(false)}
          refreshPage={() => refreshList(true)}
        />
      )}
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate(selectedCustomer?.isActive
          ? 'DEACTIVATE'
          : 'ACTIVATE')}
        messageText={translate(selectedCustomer?.isActive
          ? 'CUSTOMER_DEACTIVATE_MODAL_WARNING'
          : 'CUSTOMER_ACTIVATE_MODAL_WARNING')}
        onCancel={() => {
          setSelectedCustomer(null);
          setActivationModalVisible(false);
        }}
        onConfirm={() => toggleStatus.mutate(selectedCustomer)}
        title={translate(selectedCustomer?.isActive
          ? 'DEACTIVATE_CUSTOMER'
          : 'ACTIVATE_CUSTOMER')}
        visible={isActivationModalVisible}
      />
      {isExportModalVisible && (
        <ExportModal
          exportButtonText={translate('DOWNLOAD')}
          exportText={translate('EXPORT_CUSTOMER_SUBTITLE')}
          onClose={() => setIsExportModalVisible(false)}
          onExport={() => exportGrowerData(currentBusinessId,
            pageOptions)}
        />
      )}
      {isImportModalVisible && (
        <UploadModal
          onCancel={() => setIsImportModalVisible(false)}
          onDone={async () => {
            setIsImportModalVisible(false);
            await queryClient.invalidateQueries(QueryKeys.CUSTOMER_LIST);
          }}
          onFileUpload={(fileInfo: DocumentResult) => (
            GrowerApi.importGrowers(fileInfo, currentBusinessId)
          )}
        >
          <View style={styles.flexStart}>
            <Text>
              {translate<string>('UPLOAD_CUSTOMER_CSV')}
              <TextLink
                appearance="secondary"
                onPress={() => getImportTemplate(translate, setError)}
              >
                {translate<string>('THIS_TEMPLATE')}
              </TextLink>
            </Text>
          </View>
        </UploadModal>
      )}
    </>
  );
};
