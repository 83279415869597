import { Button, Dialog, HSpacer, Text, useToast } from '@design';
import { ProductCategory } from '@shared/enums';
import { IProduct } from '@shared/interfaces';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants';
import { ProductApi } from '../../../utilities/api';

interface ChangeProductStatusProps {
  product: Pick<IProduct, 'isActive' | 'id' | 'name'>,
  productCategory: ProductCategory;
  businessId: string,
  isVisible: boolean,
  onCancel: () => void,
}

export const ChangeProductStatusDialog: FC<ChangeProductStatusProps> = ({
  product, productCategory, businessId, onCancel, isVisible,
}: ChangeProductStatusProps) => {
  const [translate] = useTranslation(['products', 'common']);
  const { createToast } = useToast();
  const queryClient = useQueryClient();

  const updateProduct = useCallback(async () => {
    const { id, isActive, name } = product;
    try {
      await ProductApi.update(businessId, id, { isActive: !isActive });
      createToast({
        status: 'success',
        children: `${translate('PRODUCT_EDIT_SUCCESS', { name })}`,
        testID: 'toast-content-element',
      });
      await queryClient.invalidateQueries(QueryKeys[`${productCategory}_LIST`]);
      onCancel();
    } catch (err) {
      createToast({
        status: 'warning',
        children: `${translate('UNEXPECTED_ERROR', { error: (err as Error).message })}`,
        testID: 'toast-content-element',
      });
    }
  }, [businessId, createToast, onCancel, product, productCategory, queryClient, translate]);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button {...primaryButtonProp} testID="cancel-button">{`${translate('CANCEL')}`}</Button>
          <HSpacer {...spacerProp} />
          <Button {...secondaryButtonProp} onPress={updateProduct} testID="activate-deactivate-button">
            {translate(!product?.isActive ? 'ACTIVATE' : 'DEACTIVATE') as string}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="change-product-status-dialog"
      visible={isVisible}
    >
      <Text>
        {translate<string>(product?.isActive
          ? 'PRODUCT_DEACTIVATE_MODAL_WARNING'
          : 'PRODUCT_ACTIVATE_MODAL_WARNING')}
      </Text>
    </Dialog>
  );
};
