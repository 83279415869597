import { HSpacer, Icon, Text, VSpacer } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import React from 'react';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import DetailHeader from '../../../../../src/components/shared/DetailHeader/DetailHeader';
import _ from 'lodash';

interface ProductMixComponentsProps {
  components: ProductOrderDeliverableV0.ProductMixProductSummary[],
}

const ProductMixComponents = ({
  components,
}: ProductMixComponentsProps) => {
  const [translate] = useTranslation(['productOrders', 'products', 'common', 'prepare']);

  const styles = useStyleSheet({
    center: {
      alignItems: 'center',
    },
    container: {
      backgroundColor: 'color-basic-transparent-0',
      paddingHorizontal: 16,
      paddingTop: 4,
      paddingBottom: 40,
    },
    divider: {
      backgroundColor: 'color-basic-transparent-100',
    },
    row: {
      flexDirection: 'row',
      flex: 1,
      flexWrap: 'wrap',
    },
    spaceBewteen: {
      justifyContent: 'space-between',
    },
  });

  return (
    <View style={styles.container}>
      <DetailHeader header={translate<string>('COMPONENTS')} />
      <VSpacer size="4" />
      <Divider style={styles.divider}/>
      {components.map((tankMixComponent, index) => (
        <View key={index}>
          <VSpacer size="5" />
          <View style={[styles.row, styles.center]}>
            <Text category="s1">
              {tankMixComponent.productSkuName}
            </Text>
            <HSpacer size="7" />
            {/* isActive can be null/undefined, so show this only when isActive = false */}
            {tankMixComponent.isActive === false && (
              <>
                <Icon
                  name="AlertTriangle"
                  status="warning"
                  testID={`product-card-deactivated-warning-icon-${index}`}
                />
                <HSpacer size="2" />
                <Text
                  category="p1"
                  testID={`product-card-deactivated-warning-message-${index}`}
                >
                  {translate<string>('PRODUCT_DEACTIVATED_WARNING')}
                </Text>
              </>
            )}
            {/* Older deliverables don't contain this property, default to true if null/undefined */}
            {!_.isNil(tankMixComponent.isPriceLocked)
              && (tankMixComponent.isActive ?? true) && (
              <>
                <Icon
                  name={tankMixComponent.isPriceLocked ? 'Lock' : 'GrowersUnlock'}
                  status="basic" testID={`product-card-text-price-locked-icon-${index}`}
                />
                <HSpacer size="3" />
                <Text testID={`product-card-text-price-locked-${index}`}>
                  {translate<string>(tankMixComponent.isPriceLocked ? 'PRICE_LOCKED' : 'PRICE_UNLOCKED')}
                </Text>
              </>
            )}
          </View>
          <VSpacer size="5" />
          <View style={[styles.row, styles.spaceBewteen]}>
            <View style={styles.row}>
              <Text
                appearance="hint"
                category="p2"
                testID={`product-card-unit-price-label-${index}`}
              >
                {`${translate<string>('UNIT_PRICE')}:`}
              </Text>
              <HSpacer size="3" />
              <Text
                category="p2"
                testID={`product-card-unit-price-${index}`}
              >
                {StringUtility.formatCurrency(tankMixComponent.unitPrice)}
              </Text>
              <HSpacer size="5" />
              <Text
                appearance="hint"
                category="p2"
                testID={`product-card-rate-label-${index}`}
              >
                {`${translate<string>('RATE_PER_AC')}:`}
              </Text>
              <HSpacer size="3" />
              <Text
                category="p2"
                testID={`product-card-rate-${index}`}
              >
                {tankMixComponent.applicationRate}
              </Text>
              <HSpacer size="5" />
              <Text
                appearance="hint"
                category="p2"
                testID={`product-card-uom-label-${index}`}
              >
                {`${translate<string>('UOM')}:`}
              </Text>
              <HSpacer size="3" />
              <Text
                category="p2"
                testID={`product-card-uom-${index}`}
              >
                {translate(tankMixComponent.applicationRateUom)}
              </Text>
              <HSpacer size="5" />
              <Text
                appearance="hint"
                category="p2"
                testID={`product-card-intended-use-label-${index}`}
              >
                {`${translate<string>('INTENDED_USE')}:`}
              </Text>
              <HSpacer size="3" />
              <Text
                category="p2"
                testID={`product-card-intended-use-${index}`}
              >
                {translate<string>(tankMixComponent.intendedUse?.toUpperCase())}
              </Text>
              <HSpacer size="5" />
              <Text
                appearance="hint"
                category="p2"
                testID={`product-card-cost-ac-label-${index}`}
              >
                {`${translate<string>('COST_PER_AC')}:`}
              </Text>
              <HSpacer size="3" />
              <Text
                category="p2"
                testID={`product-card-cost-ac-${index}`}
              >
                {StringUtility.formatCurrency(tankMixComponent.pricePerAcre)}
              </Text>
            </View>
          </View>
          <VSpacer size="6" />
          <Divider style={styles.divider} />
        </View>
      ))}
    </View>
  );
};

export default ProductMixComponents;
