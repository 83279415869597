import { UserRole } from '@shared/enums';
import React, { useEffect, useState } from 'react';
import { Button, Card, DataPoint, Header, HSpacer, Icon, Text, VSpacer } from '@design';
import {
  ApiBusinessLocation,
  ApiCropLogic,
  ApiPriceType,
  ApiUserAccount,
  CropLogicEndpoint,
} from '@shared/interfaces/api';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { CalculationUtility, PlanningParameters } from '@shared/utils';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { StringUtility } from '../../../../utilities';
import { LineSpacer } from '../../../components/shared';
import DescriptionList, { DescriptionListDirection } from '../../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../../components/shared/DescriptionList/DescriptionListItem';
import { ColorCircleIndicator } from '../../../components/shared/ColorCircleIndicator/ColorCircleIndicator';
import { ProgramDetailsForm } from './ProgramDetailsForm';
import { ProgramScenarioParametersForm } from './ProgramScenarioParametersForm';
import { useBusinessCrops } from '../../../../hooks/useBusinessCrops';

const styles = StyleSheet.create({
  colorIndicator: {
    alignItems: 'flex-end',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 2.5,
  },
  list: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  section: {
    flex: 1,
    height: 252,
  },
  card: {
    flex: 1,
  },
  dataPoint: {
    paddingBottom: 4,
  },
  profitSummary: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  HeaderWithIcon: {
    paddingTop: 0,
  },
  oneThirdHeight: {
    maxHeight: '33.3%',
  },
});

interface ProfitSummaryRowProps {
  status?: EvaStatus,
  title: string,
  value: number,
}

interface ProgramDetailsInfoProps {
  businessLocation: ApiBusinessLocation,
  canEdit: boolean,
  onSave: (program: CropLogicEndpoint.Create.Request, updateInitialProgram: boolean) => void,
  priceType: ApiPriceType
  program: ApiCropLogic,
  user: ApiUserAccount,
}

export const ProgramDetailsInfo = ({
  businessLocation,
  canEdit,
  onSave,
  priceType,
  program,
  user,
}: ProgramDetailsInfoProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const { user: authUser } = useAuthentication();
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const [showEditScenarioParametersModal, setShowEditScenarioParametersModal] = useState(false);
  const { businessCrops } = useBusinessCrops({ businessId: program.businessId });

  const [cropPlanningInformation, setCropPlanningInformation] = useState({
    planningParameters: {
      price: null,
      priceUom: null,
      yieldGoal: null,
      yieldGoalUom: null,
    },
    revenue: null,
    cost: null,
    profit: null,
  });

  const {
    cropSubType,
    cropType,
    logicName,
    scenarioParameters,
  } = program;
  const {
    operationalCosts,
    price,
    priceUom,
    yieldGoal,
    yieldGoalUom,
  } = scenarioParameters || {};
  const useScenarioParameters = !!scenarioParameters;

  useEffect(() => {
    const selectedBusinessCrop = businessCrops?.find((businessCrop) => (
      businessCrop.cropType === cropType && businessCrop.subType === cropSubType
    ));
    if (selectedBusinessCrop) {
      const currentParameters = {
        price: selectedBusinessCrop.price,
        priceUom: selectedBusinessCrop.priceUom,
        yieldGoal: selectedBusinessCrop.yieldGoal,
        yieldGoalUom: selectedBusinessCrop.yieldGoalUom,
      };

      const planningParameters: PlanningParameters = {
        price: price ?? currentParameters.price,
        priceUom: priceUom ?? currentParameters.priceUom,
        yieldGoal: yieldGoal ?? currentParameters.yieldGoal,
        yieldGoalUom: yieldGoalUom ?? currentParameters.yieldGoalUom,
      };

      const revenue = CalculationUtility.getCropLogicIncome(
        planningParameters,
        cropType,
        cropSubType,
      );

      const cost = CalculationUtility.calculateCropLogicCostPerAcre(
        program,
        program.priceTypeId,
        useScenarioParameters && scenarioParameters.businessLocationId,
      ) + (operationalCosts ?? 0);

      const roundedRevenue = CalculationUtility.roundToPlaces(revenue, 2);
      const roundedCost = CalculationUtility.roundToPlaces(cost, 2);
      const profit = roundedRevenue - roundedCost;

      setCropPlanningInformation({
        planningParameters,
        revenue,
        cost,
        profit,
      });
    }
  }, [
    businessCrops,
    cropType,
    cropSubType,
    price,
    priceUom,
    yieldGoal,
    yieldGoalUom,
    program,
    useScenarioParameters,
    scenarioParameters,
    operationalCosts,
  ]);

  const getStatus = (value: number): EvaStatus => {
    if (value > 0) {
      return 'primary';
    }

    if (value < 0) {
      return 'warning';
    }

    return 'basic';
  };

  const profitStatus: EvaStatus = getStatus(cropPlanningInformation.profit);

  const ProfitSummaryRow = ({
    status = 'basic',
    title,
    value,
  }: ProfitSummaryRowProps) => {
    const titleTestId = title.toLowerCase();

    return (
      <View style={styles.profitSummary}>
        <Text
          category="p1"
          testID={`program-details-info-profit-summary${titleTestId}-title`}
        >
          {title}
        </Text>
        <Text
          category="p1"
          status={status}
          testID={`program-details-info-profit-summary-${titleTestId}-value`}
        >
          {translate<string>('COST_PER_AC_CALC', {
            cost: StringUtility.formatCurrency(value),
          })}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.list}>
      <View style={styles.section}>
        <Header
          childrenStyle={styles.HeaderWithIcon}
          level="3"
          testID="details-header"
          title={translate<string>('DETAILS')}
        >
          {canEdit && (
            <Button
              accessoryLeft={(editProps) => (
                <Icon
                  name="Edit"
                  testID="program-details-info-edit-details-button-icon"
                  {...editProps}
                />
              )}
              appearance="ghost"
              onPress={() => {
                setShowEditDetailsModal(true);
              }}
              size="small"
              status="basic"
              testID="program-details-info-edit-details-button"
            />
          )}
        </Header>
        <VSpacer size="3" />
        <Card style={styles.card} testID="program-details-card">
          <DataPoint
            flex
            label={translate<string>('PROGRAM_NAME')}
            labelStyle={styles.dataPoint}
            style={styles.oneThirdHeight}
            testID="program-name"
          >
            {logicName}
          </DataPoint>
          <VSpacer size="5" />
          {authUser.userRole === UserRole.SUPER && (
            <>
              <DataPoint
                flex
                label={translate<string>('OWNER')}
                labelStyle={styles.dataPoint}
                testID="owner"
              >
                {user?.fullName}
              </DataPoint>
              <VSpacer size="5" />
            </>
          )}
          <DataPoint
            flex
            label={translate<string>('CROP')}
            labelStyle={styles.dataPoint}
            testID="crop"
          >
            {`${translate<string>(cropType)} | ${translate<string>(cropSubType)}`}
          </DataPoint>
        </Card>
      </View>
      <HSpacer size="7" />
      <View style={styles.section}>
        <Header level="3" testID="profit-summary-header" title={translate<string>('PROFIT_SUMMARY')} />
        <VSpacer size="3" />
        <Card style={styles.card} testID="profit-summary-card">
          <ProfitSummaryRow
            title="Revenue"
            value={cropPlanningInformation.revenue}
          />
          <VSpacer size="5" />
          <ProfitSummaryRow
            title="Cost"
            value={cropPlanningInformation.cost}
          />
          <LineSpacer size="5" />
          <ProfitSummaryRow
            status={profitStatus}
            title="Profit"
            value={cropPlanningInformation.profit}
          />
        </Card>
      </View>
      <HSpacer size="7" />
      <View style={styles.section}>
        <Header
          childrenStyle={styles.HeaderWithIcon}
          level="3"
          testID="scenario-parameters-header"
          title={translate<string>('SCENARIO_PARAMETERS')}
        >
          {canEdit && (
            <Button
              accessoryLeft={(editProps) => (
                <Icon
                  name="Edit"
                  testID="program-details-info-edit-scenario-parameters-button-icon"
                  {...editProps}
                />
              )}
              appearance="ghost"
              onPress={() => {
                setShowEditScenarioParametersModal(true);
              }}
              size="small"
              status="basic"
              testID="program-details-info-edit-scenario-parameters-button"
            />
          )}
        </Header>
        <VSpacer size="3" />
        <Card style={styles.card} testID="scenario-parameters-card">
          <DescriptionList direction={DescriptionListDirection.STACKED}>
            <DescriptionListItem
              label={translate('YIELD_GOAL')}
              testID="program-details-info-scenario-parameters-yield-goal"
              text={translate('COST_UOM_PER_ACRE', {
                cost: cropPlanningInformation.planningParameters.yieldGoal,
                uom: translate(cropPlanningInformation.planningParameters.yieldGoalUom),
              })}
              textCategory="p2"
            />
            <DescriptionListItem
              label={translate('CROP_MARKET_PRICE')}
              testID="program-details-info-scenario-parameters-market_price"
              text={translate('COST_UOM_PER_ACRE', {
                cost: StringUtility.formatCurrency(
                  cropPlanningInformation.planningParameters.price,
                ),
                uom: translate(cropPlanningInformation.planningParameters.priceUom),
              })}
              textCategory="p2"
            />
            <DescriptionListItem
              label={translate('OPERATIONAL_COST')}
              testID="program-details-info-scenario-parameters-operational_cost"
              text={translate('COST_PER_AC_CALC', {
                cost: StringUtility.formatCurrency(scenarioParameters?.operationalCosts ?? 0),
              })}
              textCategory="p2"
            />
            <DescriptionListItem
              label={translate('BUSINESS_LOCATION')}
              testID="program-details-info-scenario-parameters-business-location"
              text={scenarioParameters?.businessLocationId ? businessLocation?.locationName
                : translate('CORPORATE')}
              textCategory="p2"
            />
            <DescriptionListItem
              accessoryLeft={priceType?.isDefault ? () => (
                <ColorCircleIndicator
                  style={styles.colorIndicator}
                  testID="program-details-info-scenario-price-type"
                />
              ) : undefined}
              label={translate('PRICE_TYPE')}
              testID="program-details-info-scenario-parameters-price-type"
              text={priceType?.name}
              textCategory="p2"
            />
          </DescriptionList>
        </Card>
      </View>
      {showEditDetailsModal && (
        <ProgramDetailsForm
          onCancel={() => setShowEditDetailsModal(false)}
          onSave={(newProgram) => {
            onSave(newProgram, !!newProgram.id);
            setShowEditDetailsModal(false);
          }}
          program={program}
        />
      )}
      {showEditScenarioParametersModal && (
        <ProgramScenarioParametersForm
          onCancel={() => setShowEditScenarioParametersModal(false)}
          onSave={(newProgram) => {
            onSave(newProgram, !!(newProgram as ApiCropLogic).id);
            setShowEditScenarioParametersModal(false);
          }}
          program={program}
          programPriceType={priceType}
        />
      )}
    </View>
  );
};
