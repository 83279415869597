import { FarmPlanEndpoint } from '@shared/interfaces/api';
import qs from 'qs';
import { Client } from './Client';

const farmPlanUrl = 'farm-plans';

export class GrowerFarmPlanApi {
  static createFarmPlan (
    farmPlanData: FarmPlanEndpoint.Create.Request,
  ): Promise<FarmPlanEndpoint.Create.Response> {
    return Client(`${farmPlanUrl}`, { body: farmPlanData });
  }

  static updateFarmPlan (
    farmPlanId,
    updates: FarmPlanEndpoint.Update.Request,
  ): Promise<FarmPlanEndpoint.Update.Response> {
    return Client(`${farmPlanUrl}/${farmPlanId}`, {
      method: 'PATCH',
      body: updates,
    });
  }

  static getFarmPlan (farmPlanId: string): Promise<FarmPlanEndpoint.Get.Response> {
    return Client(`${farmPlanUrl}/${farmPlanId}`);
  }

  static listFarmPlans (
    filters: FarmPlanEndpoint.List.Query,
  ): Promise<FarmPlanEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`${farmPlanUrl}?${query}`);
  }
}
