import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import { Input, Select, SelectItem, CheckBox, Icon, IconName, IconNames, Button, Card } from '@design';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { InputSize, InputSizes } from '@theme/variant-interfaces/Size';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const testOptions = [
  {
    title: 'Option 1',
    key: 1,
  },
  {
    title: 'Option 2 (disabled)',
    key: 2,
    disabled: true,
  },
  {
    title: 'Option 3',
    key: 3,
  },
  {
    title: 'Option 4',
    key: 4,
  },
  {
    title: 'Option 5',
    key: 5,
  },
  {
    title: 'Option 6',
    key: 6,
  },
];

const getValue = (options: string[], selected: IndexPath | IndexPath[]) => {
  if (!selected) {
    return '';
  }
  if (Array.isArray(selected)) {
    return selected.map((s) => options[s.row]).join(', ');
  }
  return options[selected.row];
};

const SelectKnobs: FunctionComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState<IndexPath | IndexPath[]>(null);
  const [values, setValues] = useState<string[]>(['Option 1', 'Option 2', 'Option 3']);
  const [size, setSize] = useState<InputSize>('medium');
  const [status, setStatus] = useState<Status>('basic');
  const [label, setLabel] = useState('Label');
  const [caption, setCaption] = useState('Caption');
  const [placeholder, setPlaceholder] = useState('Placeholder');
  const [disabled, setDisabled] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [iconLeft, setIconLeft] = useState<IconName>(undefined);
  const [iconRight, setIconRight] = useState<IconName>(undefined);

  const leftIconRender = (props) => (
    <Icon name={iconLeft} testID="left-icon" {...props} />
  );
  const rightIconRender = (props) => (
    <Icon name={iconRight} testID="right-icon" {...props} />
  );

  return (
    <>
      <DemoContainer label="Sandbox">
        <DemoBlock>
          <Select
            label="size"
            onSelect={(ip: IndexPath | IndexPath[]) => setSize(InputSizes[(ip as IndexPath).row])}
            selectedIndex={new IndexPath(InputSizes.findIndex((s) => s === size))}
            testID="select-demo-size-selector"
            value={size}
          >
            {InputSizes.map((s, i) => (
              <SelectItem key={s} testID={`select-demo-input-size-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
        </DemoBlock>
        <DemoBlock>
          <Select
            label="status"
            onSelect={(ip: IndexPath | IndexPath[]) => setStatus(Statuses[(ip as IndexPath).row])}
            selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
            testID="select-demo-status-selector"
            value={status}
          >
            {Statuses.map((s, i) => (
              <SelectItem key={s} testID={`select-demo-input-status-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
        </DemoBlock>
        <DemoBlock>
          <Input label="label" onChangeText={setLabel} testID="demo-input" value={label} />
        </DemoBlock>
        <DemoBlock>
          <Input label="caption" onChangeText={setCaption} testID="demo-input" value={caption} />
        </DemoBlock>
        <DemoBlock>
          <Input label="placeholder" onChangeText={setPlaceholder} testID="demo-input" value={placeholder} />
        </DemoBlock>
        <DemoBlock>
          <CheckBox checked={disabled} onChange={setDisabled} testID="test-checkbox">disabled</CheckBox>
          <CheckBox checked={readonly} onChange={setReadonly} testID="test-checkbox">readonly</CheckBox>
          <CheckBox checked={multiSelect} onChange={setMultiSelect} testID="test-checkbox">multiSelect</CheckBox>
        </DemoBlock>
        <DemoBlock>
          <Input
            label="Options"
            onChangeText={(text) => setValues(text.split(','))}
            testID="demo-input"
            value={values.join(',')}
          />
        </DemoBlock>
        <DemoBlock>
          <Select
            label="accessoryLeft"
            onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
              IconNames[(ip as IndexPath).row - 1],
            )}
            selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)}
            testID="select-demo-accessory-left-selector"
            value={iconLeft || 'None'}
          >
            {[
              <SelectItem key="noicon" testID="select-demo-left-icon-dropdown-value" title="None" />,
              ...IconNames.map((n, i) => (
                <SelectItem key={n} testID={`select-demo-left-icon-name-dropdown-value-${i}`} title={n} />
              )),
            ]}
          </Select>
        </DemoBlock>
        <DemoBlock>
          <Select
            label="accessoryRight"
            onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
              IconNames[(ip as IndexPath).row - 1],
            )}
            selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
            testID="select-demo-accessory-right-selector"
            value={iconRight || 'None'}
          >
            {[
              <SelectItem key="noicon" testID="select-demo-right-icon-dropdown-value" title="None" />,
              ...IconNames.map((n, i) => (
                <SelectItem key={n} testID={`select-demo-right-icon-name-dropdown-value-${i}`} title={n} />
              )),
            ]}
          </Select>
        </DemoBlock>
        <DemoBlock>
          <Button onPress={() => setSelectedIndex(null)} testID="test-button">Clear Value</Button>
        </DemoBlock>
      </DemoContainer>
      <DemoContainer>
        <DemoBlock style={{ marginHorizontal: 'auto', maxWidth: '100%' }}>
          <Select
            accessoryLeft={iconLeft && leftIconRender}
            accessoryRight={iconRight && rightIconRender}
            caption={caption}
            disabled={disabled}
            label={label}
            multiSelect={multiSelect}
            onSelect={setSelectedIndex}
            placeholder={placeholder}
            readonly={readonly}
            selectedIndex={selectedIndex}
            size={size}
            status={status}
            style={{ maxWidth: '100%' }}
            testID="select-demo-knob-selector"
            value={getValue(values, selectedIndex)}
          >
            {
              // eslint-disable-next-line react/no-array-index-key
              values.map((v, i) => <SelectItem key={i} testID={`select-demo-container-dropdown-value-${v}`} title={v} />)
            }
          </Select>
        </DemoBlock>
      </DemoContainer>
    </>
  );
};

export const SelectDemo: FunctionComponent = () => {
  const [selectCommon, setSelectCommon] = useState<IndexPath>(null);
  const [selectedDemo2, setSelectedDemo2] = useState<IndexPath[]>(null);

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <DemoContainer label="size">
          {InputSizes.map((s) => (
            <DemoBlock key={s} label={s}>
              <Select
                caption="Caption"
                label="Label"
                onSelect={(i) => setSelectCommon(i as IndexPath)}
                placeholder="Placeholder"
                selectedIndex={selectCommon}
                size={s}
                testID="select-demo-size-selector"
                value={getValue(testOptions.map((t) => t.title), selectCommon)}
              >
                {
                  testOptions.map((o) => (
                    <SelectItem testID={`select-demo-test-size-dropdown-value-${o.key}`} {...o} />
                  ))
                }
              </Select>
            </DemoBlock>
          ))}
          {InputSizes.map((s) => (
            <DemoBlock key={s} label={`${s} disabled`}>
              <Select
                caption="Caption"
                disabled
                label="Label"
                onSelect={(i) => setSelectCommon(i as IndexPath)}
                placeholder="Placeholder"
                selectedIndex={selectCommon}
                size={s}
                testID="select-demo-disable-size-selector"
                value={getValue(testOptions.map((t) => t.title), selectCommon)}
              >
                {
                  testOptions.map((o) => (
                    <SelectItem testID={`select-demo-test-size-disable-dropdown-value-${o.key}`} {...o} />
                  ))
                }
              </Select>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer label="status">
          {Statuses.map((s) => (
            <DemoBlock key={s} label={s}>
              <Select
                caption="Caption"
                label="Label"
                onSelect={(i) => setSelectCommon(i as IndexPath)}
                placeholder="Placeholder"
                selectedIndex={selectCommon}
                status={s}
                testID="select-demo-status-selector"
                value={getValue(testOptions.map((t) => t.title), selectCommon)}
              >
                {
                  testOptions.map((o) => (
                    <SelectItem testID={`select-demo-test-status-dropdown-value-${o.key}`} {...o} />
                  ))
                }
              </Select>
            </DemoBlock>
          ))}
          {Statuses.map((s) => (
            <DemoBlock key={s} label={`${s} disabled`}>
              <Select
                caption="Caption"
                disabled
                label="Label"
                onSelect={(i) => setSelectCommon(i as IndexPath)}
                placeholder="Placeholder"
                selectedIndex={selectCommon}
                status={s}
                testID="select-demo-disable-status-selector"
                value={getValue(testOptions.map((t) => t.title), selectCommon)}
              >
                {
                  testOptions.map((o) => (
                    <SelectItem testID={`select-demo-test-status-disabled-dropdown-value-${o.key}`} {...o} />
                  ))
                }
              </Select>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <SelectKnobs />
      </Card>
      <Card testID="test-card">
        <DemoContainer label="Selectable Multiple">
          <Select
            caption="Caption"
            label="Label"
            multiSelect
            onSelect={(index) => setSelectedDemo2(index as IndexPath[])}
            placeholder="Placeholder"
            selectedIndex={selectedDemo2}
            style={{ maxWidth: 200 }}
            testID="select-demo-multiple-selector"
            value={getValue(testOptions.map((t) => t.title), selectedDemo2)}
          >
            {
              testOptions.map((o) => (
                <SelectItem testID={`select-demo-selectable-multiple-dropdown-value-${o.key}`} {...o} />
              ))
            }
          </Select>
        </DemoContainer>
      </Card>
    </View>
  );
};
