import {
  Card,
  HSpacer,
  Icon,
  IconButton,
  NumericInput,
  Select,
  SelectItem,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { AgriculturalUnit, AreaUnitType, CropLogicComponentCategory } from '@shared/enums';
import { ApiCropLogicPassComponent } from '@shared/interfaces/api';
import { CalculationUtility, ProductUtilities } from '@shared/utils';
import { IndexPath } from '@ui-kitten/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { StringUtility } from '../../../../utilities';
import { TankMixDetailsModal } from '../TankMixDetailsModal/TankMixDetailsModal';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  inputWidth: {
    width: 147,
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 318,
  },
  costPerAcContainer: {
    width: 184,
    flexDirection: 'column',
  },
});

export type ComponentCardProps = {
  component: ApiCropLogicPassComponent,
  businessLocationId?: string,
  onDelete(): void,
  onUpdate(component: ApiCropLogicPassComponent): void,
  priceTypeId: string,
  testID: string,
};

export const ComponentCard = ({
  component,
  businessLocationId,
  onDelete,
  onUpdate,
  priceTypeId,
  testID,
}: ComponentCardProps) => {
  const [translate] = useTranslation(['common', 'programs']);

  const isProductMix = component.componentCategory === CropLogicComponentCategory.TANK_MIX;
  const isAcre = !isProductMix && component.product.unitUoM === AreaUnitType.Acre;
  const isUnit = !isProductMix && component.product.unitUoM === AgriculturalUnit.Unit;
  const isAcreOrUnit = isAcre || isUnit;

  const [showTankMix, setShowTankMix] = useState(false);
  const rateUoms = isProductMix
    ? null
    : ProductUtilities.getProductApplicationUoMs(component.product);

  const costPerAc = CalculationUtility.calculateComponentCostPerAcre(
    component,
    priceTypeId,
    businessLocationId,
    undefined,
    component.rate,
    component.rateUom,
  );

  const selectedIndex = rateUoms?.findIndex((n) => n === component.rateUom) ?? -1;

  const isDeactivated = isProductMix
    ? (!component.tankMix?.isActive
      || (component.tankMix.components.some((x) => !x.product?.isActive)))
    : !component.product?.isActive;

  const message = (!isProductMix && ('PRODUCT_DEACTIVATED_WARNING'))
    || ((!component.tankMix.components.some(
      (x) => x.product?.isActive,
    )) && 'TANK_MIX_DOES_NOT_CONTAIN_ACTIVE_COMPONENTS')
    || 'TANK_MIX_CONTAINS_INACTIVE_COMPONENTS';
  
  return (
    <>
      <Card testID={testID}>
        {
          isDeactivated && (
            <>
              <View style={styles.row}>
                <Icon
                  name="AlertTriangle"
                  status="warning"
                  testID={`${testID}-deactivated-warning-icon`}
                />
                <HSpacer size="2" />
                <Text
                  category="p1"
                  testID={`${testID}-deactivated-warning-message`}
                >
                  {translate<string>(message)}
                </Text>
              </View>
              <VSpacer size="5" />
            </>
          )
        }
        <Text category="p1" testID={`${testID}-name`}>
          {isProductMix ? component.name : component.product?.skuName}
        </Text>
        {isProductMix ? (
          <View>
            <VSpacer size="3" />
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowTankMix(true)}
              testID={`${testID}-tank-mix-details-link`}
            >
              {translate<string>('PRODUCT_MIX_DETAILS')}
            </TextLink>
            <VSpacer size="5" />
            <View style={styles.buttonContainer}>
              <IconButton
                appearance="ghost"
                onPress={onDelete}
                size="medium"
                status="basic"
                testID={`${testID}-delete-button`}
              >
                Trash
              </IconButton>
            </View>
          </View>
        ) : (
          <View>
            <VSpacer size="5" />
            <View style={styles.container}>
              <View style={styles.inputContainer}>
                <NumericInput
                  label={translate<string>('RATE_PER_AC')}
                  minValue={0}
                  onChangeValue={(rate) => onUpdate({ ...component, rate })}
                  precision={3}
                  readOnly={isAcre}
                  readOnlyLabel={false}
                  style={styles.inputWidth}
                  testID={`${testID}-rate-per-ac-input`}
                  value={component.rate}
                />
                <HSpacer size="7" />
                <Select
                  label={translate<string>('RATE_UOM')}
                  onSelect={(ip: IndexPath | IndexPath[]) => {
                    onUpdate({
                      ...component,
                      rateUom: rateUoms[(ip as IndexPath).row],
                    });
                  }}
                  readOnlyLabel={false}
                  readonly={isAcreOrUnit}
                  selectedIndex={selectedIndex === -1 ? undefined : new IndexPath(selectedIndex)}
                  style={styles.inputWidth}
                  testID={`${testID}-rate-uom-input`}
                  value={component.rateUom ? translate(component.rateUom) : undefined}
                >
                  {rateUoms.map((uom, i) => (
                    <SelectItem
                      key={uom}
                      testID={`${testID}-rate-uom-${i}`}
                      title={translate<string>(uom)}
                    />
                  ))}
                </Select>
              </View>
              <HSpacer size="8" />
              <View style={styles.costPerAcContainer}>
                <View style={styles.row}>
                  <Text appearance="hint">
                    {`${translate<string>('COST_PER_AC')}:`}
                  </Text>
                  <HSpacer size="2" />
                  <Text testID={`${testID}-cost-per-ac`}>
                    {StringUtility.formatCurrency(costPerAc)}
                  </Text>
                </View>
                <VSpacer size="3" />
              </View>
              <HSpacer size="8" />
              <View style={styles.buttonContainer}>
                <IconButton
                  appearance="ghost"
                  onPress={onDelete}
                  size="medium"
                  status="basic"
                  testID={`${testID}-delete-button`}
                >
                  Trash
                </IconButton>
              </View>
            </View>
          </View>
        )}
      </Card>
      {showTankMix && (
        <TankMixDetailsModal
          businessLocationId={businessLocationId}
          onClose={() => {
            setShowTankMix(false);
          }}
          priceTypeId={priceTypeId}
          tankMix={component.tankMix}
          visible
        />
      )}
    </>
  );
};
