import { ApiTankMix } from '@shared/interfaces/api';
import { generateId } from '@shared/utils';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../../constants';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../../../hooks/useBusinessLocationList';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import { TankMixApi } from '../../../../../utilities/api';

export function useTankMix ({ tankMixId, duplicate = false, onError }: {
  tankMixId: string | null,
  duplicate?: boolean,
  onError?(): void,
}) {
  const { currentBusinessId, user } = useAuthentication();
  const { defaultPriceTypeId } = usePriceTypeList();

  const defaultTankMix = useMemo(() => ({
    assignedGrowers: [],
    businessId: currentBusinessId,
    businessLocationId: '',
    components: [],
    createdAt: new Date(),
    crops: [],
    id: generateId(),
    isActive: true,
    isEditable: true,
    lastModified: new Date(),
    manuallyDeactivated: false,
    name: '',
    ownerBusinessUserId: user.id,
    priceTypeId: defaultPriceTypeId,
    updatedAt: new Date(),
  }), [user.id, currentBusinessId, defaultPriceTypeId]);

  const [options, setOptions] = useState<{
    initialTankMix: ApiTankMix,
    isFetching: boolean,
    isLoading: boolean,
    isOwner: boolean,
    tankMix: ApiTankMix,
  }>({
    initialTankMix: defaultTankMix,
    isFetching: !!tankMixId,
    isLoading: !!tankMixId,
    isOwner: true,
    tankMix: defaultTankMix,
  });

  const {
    businessLocations,
    isLoading: isLocationListLoading,
  } = useBusinessLocationList(currentBusinessId);

  const { isLoading, isFetching } = useQuery(
    [QueryKeys.TANK_MIX, tankMixId, businessLocations],
    async () => {
      const data = await TankMixApi.getTankMix(tankMixId);

      if (duplicate) {
        const { businessLocationId, ...newData } = data;

        const hasLocation = businessLocations.some(
          (businessLocation) => businessLocation.id === businessLocationId,
        );

        return {
          ...newData,
          ownerBusinessUserId: user.id,
          businessLocationId: hasLocation ? businessLocationId : null,
        };
      }

      return data;
    },
    {
      enabled: !!tankMixId && !isLocationListLoading,
      onSuccess: (data) => {
        setOptions({
          tankMix: data,
          initialTankMix: data,
          isOwner: data.ownerBusinessUserId === user.id,
          isLoading,
          isFetching,
        });
      },
      onError: () => onError?.(),
    },
  );

  return {
    ...options,
    setTankMix: (
      newTankMix: ApiTankMix,
      updateInitialTankMix = false,
    ) => {
      setOptions({
        tankMix: {
          ...newTankMix,
          components: newTankMix.components.map((component) => {
            const exists = options.initialTankMix.components.some(
              ({ id }) => id === component.id,
            );

            return { ...component, id: exists ? component.id : undefined };
          }),
        },
        initialTankMix: updateInitialTankMix
          ? newTankMix : options.initialTankMix,
        isOwner: newTankMix.ownerBusinessUserId === user.id,
        isLoading: false,
        isFetching: false,
      });
    },
  };
}
