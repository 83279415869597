import { Card } from '@ui-kitten/components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { Radio, RadioGroup } from '@design';
import { Statuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const RadioDemo: FunctionComponent = () => {
  const [selectedColumn, setSelectedColumn] = useState<number>(null);
  const [selectedInline, setSelectedInline] = useState<number>(null);
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({});

  const toggleChecked = useCallback((key) => (
    setChecked((prev) => (
      {
        ...prev,
        [key]: !prev[key],
      }
    ))
  ), []);

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="status">
          {Statuses.map((status) => (
            <DemoBlock key={status}>
              <Radio
                checked={checked[status]}
                onChange={() => toggleChecked(status)}
                status={status}
                testID="radio-demo"
              >
                {status}
              </Radio>
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="radio group column">
          <DemoBlock>
            <RadioGroup
              onChange={setSelectedColumn}
              selectedIndex={selectedColumn}
              testID="test"
            >
              <Radio
                testID="radio-demo-option-1"
              >
                Option 1
              </Radio>
              <Radio
                testID="radio-demo-option-2"
              >
                Scuttle rigging scurvy cog lee nipper Letter of
                Marque transom Buccaneer Privateer. Chain Shot
                ho Letter of Marque hornswaggle booty fathom jack
                bounty maroon Barbary Coast.
              </Radio>
              <Radio
                testID="radio-demo-option-3"
              >
                Option 3
              </Radio>
              <Radio
                testID="radio-demo-option-4"
              >
                Option 4
              </Radio>
            </RadioGroup>
          </DemoBlock>
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="radio group row">
          <DemoBlock style={{ flex: 1 }}>
            <RadioGroup
              onChange={setSelectedInline}
              selectedIndex={selectedInline}
              style={{ flexDirection: 'row' }}
              testID="test"
            >
              <Radio
                testID="radio-demo-option-1"
              >
                Option 1
              </Radio>
              <Radio
                testID="radio-demo-option-2"
              >
                Option 2
              </Radio>
              <Radio
                testID="radio-demo-option-3"
              >
                Option 3
              </Radio>
              <Radio
                testID="radio-demo-option-4"
              >
                Option 4
              </Radio>
            </RadioGroup>
          </DemoBlock>
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="radio group disabled">
          <DemoBlock style={{ flex: 1 }}>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              testID="test"
            >
              <Radio
                disabled
                testID="radio-demo-option-1"
              >
                Option 1
              </Radio>
              <Radio
                disabled
                testID="radio-demo-option-2"
              >
                Option 2
              </Radio>
              <Radio
                disabled
                testID="radio-demo-option-3"
              >
                Option 3
              </Radio>
              <Radio
                disabled
                testID="radio-demo-option-4"
              >
                Option 4
              </Radio>
            </RadioGroup>
          </DemoBlock>
        </DemoContainer>
      </Card>
    </View>
  );
};
