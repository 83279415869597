import * as _ from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';

export class LocalStorageUtility {
  /**
   * Stores a value in local storage
   * @param key The key to store by
   * @param value The value to store
   */
  static async setValue (key: string, value: any): Promise<void> {
    if (value === null) {
      return AsyncStorage.removeItem(key);
    }
    return AsyncStorage.setItem(key, _.isObject(value) ? JSON.stringify(value) : value);
  }

  /**
   * Gets a value from local storage
   * @param key The key stored by
   * @returns The stored value
   */
  static async getValue (key: string): Promise<any> {
    try {
      const value = await AsyncStorage.getItem(key);
      try {
        return JSON.parse(value);
      } catch (err) {
        return value;
      }
    } catch (err) {
      return null;
    }
  }

  /**
   * Removes a value in local storage
   * @param key The key to store by
   */
  static async removeValue (key: string): Promise<void> {
    return AsyncStorage.removeItem(key);
  }
}
