import { BannerProps, Button, List, SidePanel, Text, useBanner, VSpacer } from '@design';
import { ApiDeliverableActivity } from '@shared/interfaces/api';
import { Spinner, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { GrowerDeliverableApi } from '../../../../utilities/api';

export interface DeliverableActivityLogSidePanelProps {
  deliverableId: string;
  deliverableName: string;
  visible: boolean;
  onClose: () => void;
}

export const DeliverableActivityLogSidePanel: FC<DeliverableActivityLogSidePanelProps> = ({
  deliverableId,
  deliverableName,
  visible,
  onClose,
}) => {
  const styles = useStyleSheet({
    item: {
      paddingLeft: 20,
      marginBottom: -4,
    },
    centered: {
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
  });

  const [translate] = useTranslation(['common', 'deliverable', 'errors']);

  const { createBanner } = useBanner();
  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) });

  const { isLoading, data: shareEvents } = useQuery<ApiDeliverableActivity[]>(
    [QueryKeys.DELIVERABLE, deliverableId],
    () => GrowerDeliverableApi.getDeliverableShareEvents(deliverableId),
    {
      enabled: !!deliverableId,
      onError: () => createBanner(bannerProps(translate('ERROR_FETCH_DELIVERABLE_SHARE_EVENTS'))),
    },
  );

  return (
    <SidePanel
      header={translate<string>('DELIVERABLE_ACTIVITY_LOG')}
      onClose={onClose}
      subline={deliverableName}
      testID="deliverable-activity-log-sidepanel"
      visible={visible}
    >
      <VSpacer size="2" />
      <VSpacer size="10" />

      { isLoading && (
        <View style={styles.centered}>
          <Spinner size="giant" />
        </View>
      )}

      { !isLoading && shareEvents?.length === 0 && (
        <View style={styles.centered}>
          <Text
            appearance="hint"
            category="p1"
            testID="deliverable-activity-log-sidepanel-nodata"
          >
            {translate<string>('NO_DATA')}
          </Text>
        </View>
      )}

      { !isLoading && shareEvents?.length > 0 && (
        <ScrollView>
          <List testID="deliverable-activity-log-sidepanel-list">
            {
            shareEvents?.map((shareEvent, index) => (
              <View key={shareEvent.id} style={styles.item}>
                <Text
                  appearance="hint"
                  category="p2"
                  testID={`deliverable-activity-log-sidepanel-item-title-${index}`}
                >
                  {translate<string>('DELIVERABLE_SENT_TO', {
                    recipient: shareEvent.recipientName,
                    type: translate(shareEvent.shareMethod),
                  })}
                </Text>
                <Text
                  category="p1"
                  testID={`deliverable-activity-log-sidepanel-item-subtitle-${index}`}
                >
                  {DateTime.fromISO(shareEvent.createdAt.toString())
                    .toLocaleString(DateTime.DATETIME_SHORT).replace(',', '')}
                </Text>
              </View>
            ))
          }
          </List>
        </ScrollView>
      )}

    </SidePanel>
  );
};
