import React, { FunctionComponent } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Text } from '../../index';
import { Sizing, ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  dataPoint: {
  },
  dataPointValue: {
    fontSize: 1.5 * Sizing.BASE_SPACING,
    color: ThemeColors.PRIMARY,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dataPointLabel: {
    fontSize: 0.9 * Sizing.BASE_SPACING,
    minWidth: 100,
    textAlign: 'center',
  },
  /* DataPoint component styles */
});

interface DataPointProps extends ViewProps {
  value: string | number;
  label: string;
}
export const DataPoint: FunctionComponent<DataPointProps> = ({
  value,
  label,
  style: viewStyle,
  ...viewProps
}) => (
  <View style={[styles.dataPoint, viewStyle]} {...viewProps}>
    <Text style={styles.dataPointValue}>{value}</Text>
    <Text style={styles.dataPointLabel}>{label}</Text>
  </View>
);
