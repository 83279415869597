import { Badge, Card, Header, useToast, VSpacer } from '@design';
import { Permissions, RoleUtility } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { BusinessApi } from '../../../utilities/api';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { CustomTags } from '../../components/shared/CustomTags/CustomTags';

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    width: 560,
  },
  defaultTags: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
  },
});

export const TagsTab = () => {
  const { currentBusinessId, user } = useAuthentication();
  const { createToast } = useToast();
  const queryClient = useQueryClient();
  const [translate] = useTranslation(['common', 'businesses']);

  const canModifyBusiness = (
    RoleUtility.roleHasPermission(user.userRole, Permissions.MODIFY_BUSINESS_OBJECTS)
  );

  const { data: tags } = useQuery(
    [QueryKeys.BUSINESS_TAGS, currentBusinessId],
    async () => {
      try {
        return await BusinessApi.getBusinessTags(currentBusinessId);
      } catch (err) {
        if (err instanceof DetailedApiError) {
          createToast({
            children: err.message,
            status: 'warning',
            testID: 'tags-query-error-toast',
          });
        }
      }
    },
  );

  const getMutationOptions = (toastMessage: string) => ({
    onError: (err: DetailedApiError) => {
      createToast({
        children: err.message,
        status: 'warning',
        testID: 'tags-mutation-error-toast',
      });
    },
    onSuccess: () => {
      createToast(toastMessage, 'success');
      queryClient.invalidateQueries([QueryKeys.BUSINESS_TAGS, currentBusinessId]);
    },
  });

  const { mutate: createTag } = useMutation(
    (name: string) => BusinessApi.createTag(currentBusinessId, name),
    getMutationOptions(translate('NEW_TAG_CREATED')),
  );

  const { mutate: deleteTag } = useMutation(
    (id: string) => BusinessApi.deleteTag(currentBusinessId, id),
    getMutationOptions(translate('CUSTOM_TAG_DELETED')),
  );

  const { mutate: updateTag } = useMutation(
    ({ id, name }: { id: string, name: string }) => (
      BusinessApi.updateTag(currentBusinessId, id, name)
    ),
    getMutationOptions(translate('CUSTOM_TAG_UPDATED')),
  );

  const defaultTags = tags?.filter((tag) => tag.isDefault) ?? [];
  const defaultTagNames = defaultTags.map(({ name }) => name);
  const customTags = tags?.filter((tag) => !tag.isDefault) ?? [];
  const customTagNames = customTags.map(({ name }) => name);

  const handleChangeTags = (newTagNames: string[]) => {
    if (newTagNames.length > customTags.length) {
      const tagNameToCreate = newTagNames.find((name) => !customTagNames.includes(name));
      createTag(tagNameToCreate);
    } else if (newTagNames.length < customTags.length) {
      const tagIdToDelete = customTags.find(({ name }) => !newTagNames.includes(name)).id;
      deleteTag(tagIdToDelete);
    } else {
      const updatedName = newTagNames.find((name) => !customTagNames.includes(name));
      const tagIdToUpdate = customTags.find(({ name }) => !newTagNames.includes(name)).id;
      updateTag({ id: tagIdToUpdate, name: updatedName });
    }
  };

  return (
    <View style={styles.container}>
      <Header level="4" testID="default-tags-header" title={translate('DEFAULT_TAGS')} />
      <View style={styles.defaultTags}>
        {defaultTags.map(({ name }) => (
          <Badge key={name} testID={`default-tag-${name}`}>
            {name}
          </Badge>
        ))}
      </View>
      <VSpacer size="8" />
      <Header level="2" testID="custom-tags-header" title={translate('CUSTOM_TAGS')} />
      <VSpacer size="5" />
      <Card testID="custom-tags-card">
        <CustomTags
          defaultTags={defaultTagNames}
          onChangeTags={handleChangeTags}
          readOnly={!canModifyBusiness}
          tags={customTagNames}
          testID="custom-tags"
        />
      </Card>
    </View>
  );
};
