import React, { Component } from 'react';
import { View } from 'react-native';
import { styled } from '@ui-kitten/components/theme';
import { SpacerProps } from './HSpacer';

@styled('Spacer')
export class VSpacer extends Component<SpacerProps> {
  public render () {
    const {
      eva,
      style,
      ...viewProps
    } = this.props;

    return (
      <View
        {...viewProps}
        style={[{ height: eva.style.pixels }, style]}
      />
    );
  }
}
