import React, { PropsWithChildren, useEffect, useState } from 'react';
import { EvaStatus, RenderProp } from '@ui-kitten/components/devsupport';
import { View } from 'react-native';
import { Card } from '@design/Card';
import { StyleType } from '@ui-kitten/components/theme';
import { TableProps, SortDirection } from '../Table';
import { IColumn, RowMeta } from '../../SortableTable/interfaces';
import { ButtonProps } from '../Button/Button';
import { Filter, FilterCategory, FilterDefault, FilterProps, Selections } from '../Filter/Filter';
import { SortableTable } from '../../SortableTable/SortableTable';
import { Pagination } from '../Pagination/Pagination';
import { VSpacer } from '../Spacer/VSpacer';
import { TableHeaderBackground } from '../Table/TableHeader';
import { TableBackground } from '../Table/Table';

export type VisibleHeaders = 'only' | 'no' | 'yes';

interface FilterTableProps<F extends FilterDefault, T extends RowMeta>
  extends TableProps, FilterProps<F> {
  accessoryRight?: RenderProp<Partial<ButtonProps>>,
  columns: IColumn<T>[];
  currentPage: number,
  data: T[];
  defaultColumnFilters: string[],
  defaultFilters?: Map<string, Selections>,
  /** 'only' means only render headers, no data rows
   *  'no' means only render the data rows without the headers
   *  'yes' means to render the headers and the data rows.
   *  default = 'yes'
   */
  expandAll?: boolean;
  expanderChevronStatus?: EvaStatus,
  filterOptions: FilterCategory[];
  footerRow?: React.ReactElement;
  isFilterDisabled?: boolean,
  isLoading?: boolean,
  noDataMessage?: string,
  numberOfRows?: number;
  tableBackground?: TableBackground,
  tableContainerStyle?: StyleType,
  tableHeaderBackground?: TableHeaderBackground,
  noFilters: boolean;
  onPageChange: (number) => void;
  onRowExpand?: (data: T, expanded: boolean) => void;
  onSort?: (columnId: string, direction: SortDirection) => void;
  onUpdateFilter: (filter: F) => void,
  rowDetail?: (data: T) => React.ReactElement;
  tableId?: string;
  tableRowId?: string;
  testID: string,
  totalPages?: number,
  totalResults?: number;
}

/**
 * This component is semi-controlled.  It will reset based on new props coming in,
 * but it handles state internally for you.
 * - The current sort col and direction.
 * - expanded rows
 * Make sure your column array is not changing on every render cycle
 */

export const FilterTable = <F extends FilterDefault, T extends RowMeta>({
  accessoryRight,
  columns,
  currentPage,
  data,
  defaultColumnFilters,
  defaultFilters,
  expandAll: propExpandAll,
  expanderChevronStatus = 'basic',
  filterOptions = [],
  footerRow,
  isFilterDisabled,
  isLoading,
  noDataMessage,
  noFilters,
  numberOfRows,
  onPageChange,
  onRowExpand,
  onSort = () => { },
  onUpdateFilter,
  rowDetail,
  searchBoxTestID,
  tableBackground,
  tableContainerStyle = {},
  tableHeaderBackground,
  tableId,
  tableRowId,
  testID,
  totalPages,
  totalResults,
}: PropsWithChildren<FilterTableProps<F, T>>) => {
  const [expandAll, setExpandAll] = useState<boolean>();

  useEffect(() => {
    setExpandAll(propExpandAll);
  }, [propExpandAll]);

  return (
    <View style={{ flexDirection: 'column' }} testID={testID}>
      <View>
        {filterOptions.length > 0 && (
          <Filter
            accessoryRight={accessoryRight}
            defaultColumnFilters={defaultColumnFilters}
            defaultFilters={defaultFilters}
            filterOptions={filterOptions}
            isFilterDisabled={isFilterDisabled}
            noFilters={noFilters}
            onUpdateFilter={onUpdateFilter}
            searchBoxTestID={searchBoxTestID}
            testID={`${testID}-filter`}
            totalResults={isLoading ? undefined : totalResults}
          />
        )}
      </View>
      <Card style={tableContainerStyle} testID={`${tableId}-card`}>
        <SortableTable
          columns={columns}
          data={data}
          expandAll={expandAll}
          expanderChevronStatus={expanderChevronStatus}
          footerRow={footerRow}
          isLoading={isLoading}
          noDataMessage={noDataMessage}
          numberOfRows={numberOfRows}
          onExpandAll={() => setExpandAll(!expandAll)}
          onRowExpand={onRowExpand}
          onSort={onSort}
          rowDetail={rowDetail}
          showHeaders="yes"
          tableBackground={tableBackground}
          tableHeaderBackground={tableHeaderBackground}
          tableId={tableRowId || 'userListView'}
          testID={tableRowId || 'userListView'}
        />
      </Card>
      <VSpacer size="8" />
      <View style={{ alignItems: 'center' }}>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            displayPages={9}
            onChangePage={onPageChange}
            totalPages={totalPages}
          />
        )}
      </View>
    </View>
  );
};
