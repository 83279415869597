import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const ProductSeed = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path d="M7.74542 14.5445C7.74542 16.2293 6.83284 17.2363 5.37271 17.2363C3.91258 17.2363 3 16.2293 3 14.5445C3 12.8597 4.46013 11.135 5.37271 11.135C6.28529 11.135 7.74542 12.8597 7.74542 14.5445Z" />
    <Path d="M13.1688 5.78836C13.1688 7.66037 12.2562 8.77917 10.796 8.77917C9.33592 8.77917 8.42334 7.66037 8.42334 5.78836C8.42334 3.91634 9.88347 2 10.796 2C11.7086 2 13.1688 3.91634 13.1688 5.78836Z" />
    <Path d="M10.1067 17.679C10.4443 15.8981 11.5763 15.0101 13.0645 15.2923C14.5528 15.5744 15.2812 16.8151 14.9435 18.596C14.6059 20.3769 12.772 21.9178 11.8418 21.7414C10.9116 21.5651 9.76902 19.4598 10.1067 17.679Z" />
    <Path d="M20.3075 10.044C21.367 11.5148 21.232 12.9471 20.0029 13.8325C18.7739 14.7179 17.3725 14.3923 16.313 12.9216C15.2535 11.4509 15.398 9.05991 16.1662 8.50653C16.9344 7.95315 19.248 8.5733 20.3075 10.044Z" />
  </Svg>
);
