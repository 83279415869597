import { Components } from '@mui/material';
import { basic, primary } from './colors';
import { typography } from './typography';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true,
    giant: true,
  }
}

export const components: Components = {
  MuiAutocomplete: {
    styleOverrides: {
      clearIndicator: {
        color: basic[200],
      },
      input: {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.2,
        lineHeight: 20.3,
      },
      inputRoot: {
        backgroundColor: basic['transparent-100'],
        borderBottom: `solid 1px ${basic[300]}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

      },
      listbox: {
        backgroundColor: basic[800],
      },
      popupIndicator: {
        color: basic[200],
      },
      paper: {
        backgroundColor: basic[900],
      },
      root: {
        paddingTop: 0,
      },
      '&:hover': {
        backgroundColor: primary[800],
      },
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'medium',
      variant: 'contained',
    },
    root: {
      classes: {
        buttonPadding: 0,
      },
      '&:hover': {
        backgroundColor: primary[400],
      },
    },
    styleOverrides: {
      sizeSmall: {
        borderRadius: 2,
        fontWeight: 700,
        fontSize: 12,
        padding: 8,
        height: 32,
        minWidth: 0,
        letterSpacing: 0.5,
        marginHorizontal: 4,
        textTransform: 'none',
      },
      sizeMedium: {
        borderRadius: 20,
        textFontWeight: typography.button.fontWeight,
        textFontSize: 14,
        paddingHorizontal: 16,
        paddingVertical: 12,
        height: 40,
        minWidth: 0,
        letterSpacing: 0.85,
        marginHorizontal: 6,
        textTransform: 'none',
      },
      sizeLarge: {
        borderRadius: 24,
        fontWeight: typography.button.fontWeight,
        fontSize: 16,
        paddingHorizontal: 12,
        paddingVertical: 12,
        height: 48,
        letterSpacing: 0.4,
        minWidth: 0,
        textTransform: 'none',
      },
      startIcon: {
        marginHorizontal: 0,
      },
      endIcon: {
        marginHorizontal: 0,
      },
    },
    variants: [
      {
        props: { size: 'tiny' },
        style: {
          borderRadius: 2,
          fontWeight: typography.button.fontWeight,
          fontSize: 10,
          padding: 6,
          height: 24,
          minWidth: 0,
          letterSpacing: 0.5,
          margin: 0,
          marginHorizontal: 4,
          textTransform: 'none',
        },
      },
      {
        props: { size: 'giant' },
        style: {
          borderRadius: 56,
          fontWeight: typography.button.fontWeight,
          fontSize: 18,
          paddingHorizontal: 24,
          paddingVertical: 16,
          margin: 0,
          height: 56,
          letterSpacing: 0.5,
          minWidth: 0,
          textTransform: 'none',
          lineHeight: 1.33,
        },
      },
      {
        props: { variant: 'text', color: 'info' },
        style: {
          color: 'rgb(100, 178, 189)',
        },
      },
    ],
  },
  MuiChip: {
    variants: [
      {
        props: { variant: 'filled' },
        style: {
          backgroundColor: basic['transparent-200'],
        },
      },
    ],
  },
  MuiInput: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        padding: '6px 12px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: basic['transparent-600'],
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: 'rgb(173, 173, 173)',
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgb(33, 33, 33)',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderColor: basic['transparent-600'],
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgb(33, 33 , 33)',
        padding: '28px 28px 32px',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: 'rgb(249, 249, 249)',
      },
    },
  },
} as Components;
