import React, { FC, useState } from 'react';
import { View } from 'react-native';
import { ApiFeatureFlag } from '@shared/interfaces/api/FeatureFlagEndpoint';
import { Button, Icon, Text, useFeatureFlags } from '@design';
import { SortableTable } from '../../../../elements/table';
import { FeatureFlagEdit } from './FeatureFlagEdit';

export const FeatureFlagList: FC = () => {
  const { flags } = useFeatureFlags();
  const [editFlag, setEditFlag] = useState<ApiFeatureFlag>();

  return (
    <>
      <FeatureFlagEdit featureFlag={editFlag} onFinish={() => setEditFlag(null)} />

      <Text>Date created comes from the database of when it was included.</Text>

      <SortableTable
        headers={
          [
            {
              field: 'flagName',
              label: 'Feature Flag Name',
            },
            {
              field: 'dateCreated',
              label: 'Date Created',
            },
            {
              field: 'onOff',
              label: 'On/Off',
            },
            {
              field: 'includeBusinesses',
              label: 'Include Businesses',
            },
            {
              field: 'excludeBusinesses',
              label: 'Exclude Businesses',
            },
            {
              field: 'actions',
              label: 'Actions',
            },
          ]
        }
        rows={
          flags.map((flag) => ({
            key: flag.name,
            columns: [
              <Text>{flag.name}</Text>,
              <Text>{flag.createdAt?.toString()}</Text>,
              <Text>{flag.enabled ? 'On' : 'Off'}</Text>,
              <Text>{flag.includedBusinessIds.join(', ')}</Text>,
              <Text>{flag.excludedBusinessIds.join(', ')}</Text>,
              <View style={{ flexDirection: 'row' }}>
                <Button
                  accessoryLeft={(props) => <Icon {...props} name="Edit" testID="edit-button-icon" />}
                  appearance="ghost"
                  onPress={() => setEditFlag(flag)}
                  size="medium"
                  testID="edit-button"
                />
              </View>,
            ],
          }))
        }
        widths={[1, 1, 1, 1, 1, 1]}
      />
    </>
  );
};
