import { Client } from './Client';

export class DataSyncApi {
  static pullChanges (userId: string, lastPulledAt: number) {
    return Client(`sync/${userId}?lastPulledAt=${lastPulledAt}`);
  }

  static pushChanges (userId: string, changes: any, lastPulledAt: number) {
    return Client(`sync/${userId}?lastPulledAt=${lastPulledAt}`, {
      method: 'POST',
      body: changes,
    });
  }
}
