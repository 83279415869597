import { ApiGrower, ApiGrowerContact } from '@shared/interfaces/api';
import { ContactCard } from '../../../components/shared/ContactCard/ContactCard';
import { VSpacer } from '@design';
import { DemoContainer } from '../components/DemoBlock';
import { View } from 'react-native';

const contact: ApiGrowerContact = {
  id: '123',
  address1: '123 Grassy Field Ln',
  firstName: 'Farmer',
  lastName: 'John',
  address2: '',
  businessId: 'abc',
  city: 'Farmville',
  country: 'US',
  state: 'North Carolina',
  email: 'growersEmail@growers.ag',
  growerId: 'abc123',
  isPrimary: false,
  postalCode: '11111',
  telephone: '910-867-5309',
  createdAt: new Date(),
  isActive: true,
  updatedAt: new Date(),
  tags: [],
};

const customer: Pick<ApiGrower, 'country' | 'county'> = {
  country: 'US',
  county: 'Rich Soil County',
};

export const ContactCardDemo = () => (
  <DemoContainer label="Contact Card" style={{ flexDirection: 'column' }}>
    <View>
      <VSpacer size="5" />
      <ContactCard
        canEdit
        contact={{
          ...contact,
          isPrimary: true,
        }}
        customer={customer}
        onEdit={() => { window.alert('On Edit pressed!'); }}
      />
      <VSpacer size="8" />
      <ContactCard
        canEdit={false}
        contact={{
          ...contact,
          isActive: false,
          email: null,
        }}
        customer={customer}
        onEdit={() => { window.alert('On Edit pressed!'); }}
      />
      <VSpacer size="8" />
      <ContactCard
        canEdit
        contact={{
          ...contact,
          tags: ['Billing', 'Shipping', 'Primary', 'Important', 'Main', 'Special', 'Decison Maker', 'Star Wars Fan'],
        }}
        customer={customer}
        onEdit={() => { window.alert('On Edit pressed!'); }}
      />
      <VSpacer size="8" />
      <ContactCard
        canEdit={false}
        contact={{
          ...contact,
          isActive: false,
          email: null,
          tags:['No longer with company'],
        }}
        customer={customer}
        onEdit={() => { window.alert('On Edit pressed!'); }}
      />
    </View>
  </DemoContainer>
);