import {
  Button,
  Header,
  HSpacer,
  Icon,
  Input,
  LargeModal,
  Text,
  VSpacer,
} from '@design';
import { ApiCropLogicPass, ApiCropLogicPassComponent } from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import _ from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { CropSubType, CropType } from '@shared/enums';
import { AddComponentModal } from '../../../pages/Program/AddComponent';
import { StringUtility } from '../../../../utilities';
import { ComponentCard } from './ComponentCard';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  page: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerWidth: {
    width: 660,
  },
  footerButtons: {
    position:'absolute',
    bottom: 32,
    right: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  topRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  nameInput: {
    width: 368,
  },
  costPerAcContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

export interface PassModalProps {
  businessLocationId?: string,
  cropSubType: CropSubType,
  cropType: CropType,
  customerId?: string,
  index?: number,
  onClose?(): void,
  onSave(pass: ApiCropLogicPass, index?: number): void,
  pass?: ApiCropLogicPass,
  priceTypeId: string,
}

export const PassModal = ({
  businessLocationId,
  cropSubType,
  cropType,
  customerId,
  index,
  onClose,
  onSave,
  pass,
  priceTypeId,
}: PassModalProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [name, setName] = useState<string>(pass?.name ?? '');
  const [components, setComponents] = useState(pass?.components ?? []);

  const editMode = useMemo(() => index !== undefined, [index]);

  const totalCostAc = useMemo(() => (
    components.map((component) => {
      if (component.tankMixId) {
        return CalculationUtility.calculateTankMixComponentCostPerAcre(
          component.tankMix,
          priceTypeId,
          businessLocationId,
        ).totalCostPerAcre;
      }
      return CalculationUtility.calculateComponentCostPerAcre(
        component,
        priceTypeId,
        businessLocationId,
      );
    }).reduce((a, b) => a + b, 0)
  ), [businessLocationId, components, priceTypeId]);

  const removeComponent = (id: string) => {
    setComponents(components.filter((component) => component.id !== id));
  };

  const updateComponent = useCallback((component: ApiCropLogicPassComponent) => {
    setComponents(components.map((c) => (c.id === component.id ? component : c)));
  }, [components]);

  const isValid = useMemo(() => {
    if (name === '' || components.length === 0) {
      return false;
    }

    const hasInvalidComponents = components.some(
      (component) => !component.tankMixId && (!component.rate || !component.rateUom),
    );

    return !hasInvalidComponents;
  }, [components, name]);

  const newPass = useMemo(() => ({
    ...pass,
    components,
    name: name.trim(),
  }), [components, name, pass]);

  const isPassSaved = useMemo(() => _.isEqual(newPass, pass), [newPass, pass]);

  const handleSave = useCallback(() => {
    onSave(newPass, index);
  }, [index, newPass, onSave]);

  return (
    <>
      <LargeModal
        footer={(
          <View style={styles.footerButtons}>
            {isPassSaved ? (
              <Button
                appearance="filled"
                design="floating"
                onPress={onClose}
                size="giant"
                status="primary"
                testID="pass-modal-done-button"
              >
                {translate<string>('DONE')}
              </Button>
            ) : (
              <>
                <Button
                  appearance="outline"
                  design="floating"
                  onPress={onClose}
                  size="giant"
                  status="basic"
                  testID="pass-modal-cancel-button"
                >
                  {translate<string>('CANCEL')}
                </Button>
                <HSpacer size="8" />
                <Button
                  appearance="filled"
                  capitalize
                  design="floating"
                  disabled={!isValid}
                  onPress={handleSave}
                  size="giant"
                  status="primary"
                  testID="pass-modal-save-pass-button"
                >
                  {translate<string>('SAVE_PASS')}
                </Button>
              </>
            )}
          </View>
        )}
        pages={[(
          <View style={styles.page}>
            <View style={styles.containerWidth}>
              <View style={styles.topRow}>
                <Input
                  isRequired
                  label={translate<string>('PASS_NAME')}
                  onChangeText={setName}
                  size="large"
                  style={styles.nameInput}
                  testID="pass-modal-name-input"
                  value={name}
                />
                <HSpacer size="7" />
                <View style={styles.costPerAcContainer}>
                  <View style={styles.row}>
                    <Text appearance="hint">
                      {`${translate('TOTAL_COST_AC')}:`}
                    </Text>
                    <HSpacer size="2" />
                    <Text testID="pass-modal-cost-per-ac">
                      {StringUtility.formatCurrency(totalCostAc)}
                    </Text>
                  </View>
                  <VSpacer size="4" />
                </View>
              </View>
              <VSpacer size="10" />
              <Header
                level="4"
                testID="pass-modal-components-header"
                title={(components.length
                  ? translate('COMPONENTS_COUNT', { count: components.length })
                  : translate('COMPONENTS')
                )}
              />
              <VSpacer size="6" />
              {components.map((component, idx) => (
                <Fragment key={component.id}>
                  <ComponentCard
                    businessLocationId={businessLocationId}
                    component={component}
                    onDelete={() => removeComponent(component.id)}
                    onUpdate={updateComponent}
                    priceTypeId={priceTypeId}
                    testID={`component-card-${idx}`}
                  />
                  <VSpacer size="5" />
                </Fragment>
              ))}
            </View>
            <VSpacer size="5" />
            <Button
              accessoryLeft={(props) => (<Icon name="Plus" testID="pass-modal-button-add-component-icon" {...props} />)}
              appearance="outline"
              onPress={() => setShowAddComponentModal(true)}
              size="medium"
              testID="pass-modal-button-add-component"
            >
              {translate<string>('ADD_COMPONENT')}
            </Button>
          </View>
        )]}
        testID="large-pass-modal"
        title={translate<string>(editMode ? 'EDIT_PASS' : 'ADD_PASS_TITLE')}
        visible
      />
      {showAddComponentModal && (
        <AddComponentModal
          businessLocationId={businessLocationId}
          components={components}
          cropSubType={cropSubType}
          cropType={cropType}
          customerId={customerId}
          onApply={(newComponents) => {
            setComponents(newComponents);
            setShowAddComponentModal(false);
          }}
          onClose={() => setShowAddComponentModal(false)}
          priceTypeId={priceTypeId}
        />
      )}
    </>
  );
};
