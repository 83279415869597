import React from 'react';
import Svg, { G, Mask, Path, SvgProps } from 'react-native-svg';

export const ArrowExpand = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path clipRule="evenodd" d="M7.28666 16.8089C7.06523 16.8089 6.84531 16.7126 6.69516 16.5261L3.03393 11.9761C2.80794 11.6947 2.81098 11.2928 3.04227 11.0152L6.83393 6.46524C7.10163 6.14371 7.58013 6.10049 7.90243 6.36818C8.22396 6.63587 8.26718 7.11438 7.99873 7.43591L4.60519 11.5089L7.8774 15.5751C8.13978 15.9012 8.08822 16.3789 7.76138 16.6413C7.62184 16.7543 7.45349 16.8089 7.28666 16.8089Z" fillRule="evenodd" />
    <Mask height="11" id="mask0" mask-type="alpha" mask-units="userSpaceOnUse" width="7" x="2" y="6">
      <Path clipRule="evenodd" d="M7.28666 16.8089C7.06523 16.8089 6.84531 16.7126 6.69516 16.5261L3.03393 11.9761C2.80794 11.6947 2.81098 11.2928 3.04227 11.0152L6.83393 6.46524C7.10163 6.14371 7.58013 6.10049 7.90243 6.36818C8.22396 6.63587 8.26718 7.11438 7.99873 7.43591L4.60519 11.5089L7.8774 15.5751C8.13978 15.9012 8.08822 16.3789 7.76138 16.6413C7.62184 16.7543 7.45349 16.8089 7.28666 16.8089Z" fill="white" fillRule="evenodd" />
    </Mask>
    <G mask="url(#mask0)" />
    <Path clipRule="evenodd" d="M17.4835 16.8077C17.3121 16.8077 17.1399 16.7501 16.9981 16.6318C16.6766 16.3641 16.6334 15.8856 16.9011 15.564L20.2954 11.491L17.0232 7.42484C16.7608 7.09876 16.8123 6.62101 17.1384 6.35862C17.4653 6.09624 17.9423 6.14781 18.2054 6.47389L21.8666 11.0239C22.0926 11.3052 22.0896 11.7071 21.8583 11.9847L18.0666 16.5347C17.9165 16.7144 17.7011 16.8077 17.4835 16.8077Z" fillRule="evenodd" />
    <Mask height="11" id="mask1" mask-type="alpha" mask-units="userSpaceOnUse" width="7" x="16" y="6">
      <Path clipRule="evenodd" d="M17.4835 16.8077C17.3121 16.8077 17.1399 16.7501 16.9981 16.6318C16.6766 16.3641 16.6334 15.8856 16.9011 15.564L20.2954 11.491L17.0232 7.42484C16.7608 7.09876 16.8123 6.62101 17.1384 6.35862C17.4653 6.09624 17.9423 6.14781 18.2054 6.47389L21.8666 11.0239C22.0926 11.3052 22.0896 11.7071 21.8583 11.9847L18.0666 16.5347C17.9165 16.7144 17.7011 16.8077 17.4835 16.8077Z" fill="white" fillRule="evenodd" />
    </Mask>
    <G mask="url(#mask1)" />
  </Svg>
);
