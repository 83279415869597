import {
  Badge,
  Card,
  HSpacer,
  Icon,
  NumericInput,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import ProductMixComponents from './ProductMixComponents';
import { ProductMixDiscounts } from './ProductMixDiscounts';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  rightAligned: {
    alignItems: 'flex-end',
  },
  centerAligned: {
    alignItems: 'center',
  },
  spaceBetweenRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  qtyWrapper: {
    width: 109,
    height: '100%',
  },
  qtyTextStyles: {
    width: 30,
    textAlign: 'center',
  },
  input: {
    width: 109,
  },
  flexOne: {
    flex: 1,
  },
  dialogFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  isDiscountsAppliedButton: {
    position: 'relative',
    minWidth: 150,
  },
  text: {
    flexShrink: 1,
  },
});

interface ProductMixCardProps {
  index: number,
  productMix: ProductOrderDeliverableV0.ProductMixSummary,
}

const ProductMixCard = ({
  index,
  productMix,
}: ProductMixCardProps) => {
  const [translate] = useTranslation(['productOrders', 'products', 'common', 'prepare']);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const hasDiscounts = productMix.products.some(p => !!p.discounts.length);

  return (
    <Card testID={`product-mix-card-${index}`}>

      {/* SKU/Price */}
      <View style={styles.spaceBetweenRow}>
        <Text
          category="h6"
          style={styles.text}
          testID={`product-card-name-${index}`}
        >
          {productMix.name}
        </Text>
        <HSpacer size="7" />
        <View style={[styles.column, styles.rightAligned]}>
          <Text
            category="h3"
            testID={`product-card-total-${index}`}
            >
            {StringUtility.formatCurrency(productMix.total)}
          </Text>
          {hasDiscounts && (
            <Badge
              status="success"
              style={styles.isDiscountsAppliedButton}
              testID={`product-card-is-discount-applied-${index}`}
            >
              {translate<string>('DISCOUNT_APPLIED').toUpperCase()}
            </Badge>
          )}
        </View>
      </View>

      <VSpacer size="5" />

      <View style={styles.row}>
        {/* Acres Input */}
        <View style={styles.input}>
          <NumericInput
            disabled
            label={translate<string>('ACRES')}
            precision={3}
            style={styles.input}
            testID={`product-card-tank-mix-acres-${index}`}
            value={productMix.acreage}
          />
        </View>
        <HSpacer size="7" />
        <Text category="p2" style={{ marginTop: 37 }}>
          {translate('PER_ACRE_COST', { cost: StringUtility.formatCurrency(
            productMix.totalCostPerAcre,
          ) })}
        </Text>
      </View>

      <VSpacer size="5" />

      {/* Show Details link */}
      <TextLink
        accessoryRight={(iconProps) => (
          <Icon
            name={showDetails ? 'ChevronUp' : 'ChevronDown'}
            testID={`product-card-details-text-link-icon=${index}`}
            {...iconProps}
          />
        )}
        appearance="secondary"
        category="p2"
        onPress={() => setShowDetails((prev) => !prev)}
        testID={`product-card-details-text-link=${index}`}
      >
        {translate<string>(showDetails ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
      </TextLink>

      {/* Details */}
      {showDetails && (
        <>
          <VSpacer size="7" />
          <ProductMixComponents components={productMix.products} />
          <VSpacer size="7" />
          {hasDiscounts && (
            <ProductMixDiscounts components={productMix.products} />
          )}
        </>
      )}

    </Card>
  );
};

export default ProductMixCard;
