import { VSpacer } from '@design/Spacer';
import React, { useState, FC, useEffect, useRef } from 'react';
import { View, FlatList, TouchableOpacity, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import { Text } from '../Text/Text';
import { Input } from '../Input/Input';
import { Icon } from '../Icon/Icon';
import { SelectItem } from '../Select/SelectItem';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  footer: {
    alignItems: 'flex-end',
    padding: 8,
    paddingRight: 16,
  },
  list: {
    maxHeight: 400,
    paddingHorizontal: 4,
    maxWidth: 340,
    minWidth: 259,
  },
  inputWrapper: {
    paddingTop: 8,
    paddingBottom: 24,
    paddingHorizontal: 16,
    minWidth: 259,
  },
  textLinkWrapper: {
    paddingLeft: 17.5,
    paddingVertical: 8,
  },
  scrollContainer: {
    marginRight: 50,
    maxHeight: 400,
  },
});

const filter = (item: FilterMenuOptions, query: string) => (
  item?.label.toLowerCase().includes(query.toLowerCase())
);

export type FilterMenuOptions = {
  label: string,
  id: string,
  disabled?: boolean,
};

type FilterMenuProps = {
  initialSearchValue?: string,
  inputWrapperStyle?: StyleProp<ViewStyle>,
  multiSelect?: boolean,
  onSearch?: (query: string) => void,
  onSelect: (selectedId: string, value: boolean) => void,
  options: FilterMenuOptions[],
  searchSize?: 'medium' | 'large',
  selectedOptions: { [key: string]: boolean },
  showScrollBar?: boolean,
  sidePanel?: boolean,
  style?: StyleProp<ViewStyle>,
  testID: string,
  total?: number,
};

export const maxListSize = 50;
const minItemsToShowSearchBar = 13;

export const FilterMenu: FC<FilterMenuProps> = ({
  initialSearchValue,
  inputWrapperStyle,
  multiSelect,
  onSearch,
  onSelect,
  options,
  searchSize = 'medium',
  selectedOptions,
  showScrollBar = true,
  sidePanel,
  style: propsStyle,
  testID,
  total = 0,
}) => {
  const [translate] = useTranslation('common');
  const [value, setValue] = useState(initialSearchValue);
  const [displayOptions, setDisplayOptions] = useState(_.cloneDeep(options));
  const debounce = useRef(null);
  const onChangeText = (query: string) => {
    onSearch?.(query || null);
    setValue(query);
  };

  const [listLength, setListLength] = useState(options?.length);

  useEffect(() => {
    debounce.current = setInterval(() => {
      let filtered = options;
      if (value.length) {
        filtered = options.filter((item) => filter(item, value));
      }
      setDisplayOptions(filtered);
      setListLength(filtered?.length ?? 0);
    }, 250);

    return () => {
      clearInterval(debounce.current);
    };
  }, [onSearch, options, value]);

  const onClear = () => {
    if (onSearch) {
      onSearch(null);
    }
    setValue('');
    setDisplayOptions(options);
    setListLength(options.length);
  };

  const onSelection = (selection: string) => {
    onSelect(selection, !selectedOptions[selection]);
  };

  return (
    <FlatList
      ListFooterComponent={total > maxListSize && (
        <Text>
          {translate('SHOWING_FILTER', { listLength, totalResults: total })}
        </Text>
      )}
      ListFooterComponentStyle={styles.footer}
      ListHeaderComponent={total >= minItemsToShowSearchBar && (
        <View style={[
          styles.inputWrapper,
          inputWrapperStyle,
        ]}
        >
          <Input
            accessoryLeft={(iconProps) => (
              <Icon
                {...iconProps}
                name="Search"
                size="small"
                testID={`${testID}-input-search-icon`}
              />
            )}
            accessoryRight={!!value && ((iconProps) => (
              <TouchableOpacity onPress={onClear}>
                <Icon {...iconProps} name="Close" testID={`${testID}-input-clear-icon`} />
              </TouchableOpacity>
            ))}
            onChangeText={onChangeText}
            placeholder="Search"
            size={searchSize}
            status="none"
            testID={`${testID}-input`}
            value={value}
          />
        </View>
      )}
      data={displayOptions}
      extraData={selectedOptions}
      keyExtractor={(_info, idx) => idx.toString()}
      renderItem={({ index, item }) => {
        let outputTokens: string[];
        // Only do this if value is truthy. When value is an empty string
        // there's a "matching token" between every character which creates
        // a lot of extra work and slows down the render speed.
        const surrounded = item.label.replace(new RegExp(`(${value})`, 'ig'), '|$1|');
        outputTokens = surrounded.split('|');
        return (
          <>
            <SelectItem
              descriptor={{ multiSelect, index: new IndexPath(index) }}
              disabled={item.disabled}
              onPress={() => { if (multiSelect) onSelection(item.id); }}
              onPressIn={() => { if (!multiSelect) onSelection(item.id); }}
              selected={selectedOptions[item.id]}
              testID={`${testID}-dropdown-value-${item.label}`}
              title={(
                <Text
                  testID={`${testID}-dropdown-value-${item.label}-label`}
                >
                  {outputTokens === undefined
                    ? item.label
                    : outputTokens.map((token, i) => (
                      <Text
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${token}-${i}`}
                        style={i % 2 && { fontWeight: '900' }}
                      >
                        {token}
                      </Text>
                    ))
                  }
                </Text>
              )}
            />
            {sidePanel && <VSpacer size="2" />}
          </>
        );
      }}
      showsVerticalScrollIndicator={showScrollBar}
      style={[sidePanel ? {} : styles.list, propsStyle]}
      testID={testID}
    />
  );
};
