import { FeatureFlags } from '@shared/enums';
import { FeatureFlagEndpoint, ApiFeatureFlag } from '@shared/interfaces/api';
import { Client } from './Client';

export class FeatureFlagsApi {
  static getAllFeatureFlags () {
    return Client<ApiFeatureFlag[]>('featureflags');
  }

  static getFeatureFlag (flag: FeatureFlags) {
    return Client<FeatureFlagEndpoint.Get.Response>(`featureflags/${flag}`);
  }

  static updateFeatureFlag (flag: ApiFeatureFlag) {
    return Client<FeatureFlagEndpoint.Update.Response>(`featureFlags/${flag.name}`, {
      body: flag,
      method: 'PUT',
    });
  }
}
