/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/static-property-placement */
import React from 'react';
import {
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {
  ChildrenWithProps,
  Overwrite,
  LiteralUnion,
} from '@ui-kitten/components/devsupport';
import {
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import {
  TabElement,
  TabProps,
} from './tab';
import { TabIndicator } from './tabIndicator';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  item: {
    flex: 1,
  },
  indicator: {},
});

type TabBarStyledProps = Overwrite<StyledComponentProps, {
  appearance?: LiteralUnion<'default'>;
}>;

export interface TabBarProps extends ViewProps, TabBarStyledProps {
  children?: ChildrenWithProps<TabProps>;
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  indicatorStyle?: StyleProp<ViewStyle>;
}

export type TabBarElement = React.ReactElement<TabBarProps>;

@styled('RallyTabBar')
export class TabBar extends React.Component<TabBarProps> {
  static defaultProps: Partial<TabBarProps> = {
    selectedIndex: 0,
  };

  private tabIndicatorRef = React.createRef<TabIndicator>();

  private isTabSelected = (index: number): boolean => index === this.props.selectedIndex;

  private convertWidth = (width: number | string) => {
    // for percent values
    if (typeof (width) === 'string') return parseFloat(width.toString());

    // for pixel values
    return width;
  };

  private getComponentStyle = (source: StyleType) => {
    const {
      indicatorHeight,
      indicatorBorderRadius,
      indicatorBackgroundColor,
      ...containerParameters
    } = source;

    return {
      container: containerParameters,
      item: {},
      indicator: {
        height: indicatorHeight,
        borderRadius: indicatorBorderRadius,
        backgroundColor: indicatorBackgroundColor,
      },
    };
  };

  private renderTabElement = (
    element: TabElement,
    index: number,
  ): TabElement => React.cloneElement(element, {
    key: index,
    style: [styles.item, element.props.style],
    selected: this.isTabSelected(index),
    onSelect: () => this.onTabSelect(index),
  });

  private renderTabElements = (
    source: ChildrenWithProps<TabProps>,
  ): TabElement[] => React.Children.map(source, this.renderTabElement);

  private onTabSelect = (index: number): void => {
    this.props.onSelect && this.props.onSelect(index);
  };

  public scrollToIndex (params: { index: number, animated?: boolean }): void {
    this.tabIndicatorRef.current?.scrollToIndex(params);
  }

  public scrollToOffset (params: { offset: number, animated?: boolean }): void {
    this.tabIndicatorRef.current?.scrollToOffset(params);
  }

  public render (): React.ReactElement<ViewProps> {
    const {
      eva,
      style,
      testID,
      indicatorStyle,
      selectedIndex,
      children,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);
    const tabElements: TabElement[] = this.renderTabElements(children);

    return (
      <View testID={testID}>
        <View
          {...viewProps}
          style={[evaStyle.container, styles.container, style]}
        >
          {tabElements}
        </View>
        <TabIndicator
          indicatorTotalWidthPercent={this.convertWidth(StyleSheet.flatten(indicatorStyle).width)}
          positions={tabElements.length}
          ref={this.tabIndicatorRef}
          selectedPosition={selectedIndex}
          style={[evaStyle.indicator, styles.indicator, indicatorStyle]}
        />
      </View>
    );
  }
}
