import { Card, CheckBox, IndexPath } from '@ui-kitten/components';
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Select, Toggle, ToggleProps, Input, SelectItem } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const statuses: ToggleProps['status'][] = ['basic', 'primary', 'info', 'success', 'warning', 'danger'];
const sizes: ToggleProps['size'][] = ['small', 'large'];

export const ToggleDemo: FunctionComponent = () => {
  const [leftChecked, setLeftChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(true);
  const [status, setStatus] = useState<ToggleProps['status']>('primary');
  const [size, setSize] = useState<ToggleProps['size']>('small');
  const [disable, setDisable] = useState(false);
  const [text, setText] = useState('Label');

  const updateCustomStatus = (ip: IndexPath) => {
    setSize(sizes[ip.row]);
    setCustomChecked(!customChecked);
  };

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <DemoContainer label="size">
          { sizes.map((s) => (
            <DemoBlock key={s} label={s}>
              <Toggle checked={leftChecked} onChange={setLeftChecked} size={s} />
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer label="status">
          { statuses.map((statusOpt) => (
            <DemoBlock key={statusOpt} label={statusOpt}>
              <Toggle checked={leftChecked} onChange={setLeftChecked} status={statusOpt} />
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Input label="Text" onChangeText={setText} testID="demo-input" value={text} />
          <Select
            label="status"
            onSelect={(ip: IndexPath | IndexPath[]) => setStatus(statuses[(ip as IndexPath).row])}
            selectedIndex={new IndexPath(statuses.findIndex((s) => s === status))}
            testID="toggle-demo-status-selector"
            value={status}
          >
            {statuses.map((s, i) => (
              <SelectItem key={s} testID={`toggle-demo-status-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
          <Select
            label="size"
            onSelect={(ip: IndexPath | IndexPath[]) => updateCustomStatus(ip as IndexPath)}
            selectedIndex={new IndexPath(sizes.findIndex((s) => s === size))}
            testID="toggle-demo-size-selector"
            value={size}
          >
            {sizes.map((s, i) => (
              <SelectItem key={s} testID={`toggle-demo-size-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
          <CheckBox
            checked={disable}
            onChange={setDisable}
            style={{ alignSelf: 'center' }}
          >
            disabled
          </CheckBox>
        </View>
        <DemoContainer>
          <DemoBlock label="Customized Toggle" style={{ margin: 'auto' }}>
            <Toggle
              checked={customChecked}
              disabled={disable}
              onChange={setCustomChecked}
              size={size}
              status={status}
            >
              {text}
            </Toggle>
          </DemoBlock>
        </DemoContainer>

      </Card>
    </View>
  );
};
