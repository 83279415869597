import { ApiProductSummary } from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import React, { useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Modal, Text, TextLink } from '@design';

interface ProductProps {
  summary: ApiProductSummary,
}

const Product = ({ summary }: ProductProps) => {
  const [translate] = useTranslation(['prepare', 'common', 'farmPlans']);

  const avgRateApplied = CalculationUtility.roundToPlaces(summary.avgRateApplied, 2);
  const application = `${avgRateApplied} ${translate(summary.avgRateAppliedUom)}`;
  const productName = `${summary.product.name} (${summary.product.skuName})`;

  return (
    <Text category="p2">
      {`${productName}: ${translate('APPLICATION_PER_ACRE', { applicationRate: application })}`}
    </Text>
  );
};

interface ProductSummaryProps {
  products: ApiProductSummary[],
}

export const ProductSummary = ({ products }: ProductSummaryProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showModal, setShowModal] = useState(false);

  if (!products.length) {
    return (
      <Text category="p2">
        {translate<string>('NOT_APPLICABLE')}
      </Text>
    );
  }

  return (
    <>
      <Modal
        onClose={() => setShowModal(false)}
        testID="product-more-modal"
        title={translate('PRODUCTS')}
        visible={showModal}
      >
        {products.map((product) => (
          <View key={`${product.id}`} testID="value">
            <Product summary={product} />
          </View>
        ))}
      </Modal>
      <TextLink
        appearance="secondary"
        category="p2"
        onPress={() => setShowModal(true)}
      >
        {translate<string>('VIEW_ALL_PRODUCTS')}
      </TextLink>
    </>
  );
};
