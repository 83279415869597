const DeviceSizes = [
  { // > 600
    topVSpace: '15',
    topTitleSpace: '',
    bottomTitleSpace: '9',
    containerSize: 420,
    bottomVSpace: '9',
  },
  { // < 600 breakpoint
    topVSpace: '12',
    topTitleSpace: '',
    bottomTitleSpace: '8',
    containerSize: 320,
    bottomVSpace: '9',
  },
  { // < 320 breakpoint
    topVSpace: '12',
    topTitleSpace: '',
    bottomTitleSpace: '8',
    containerSize: '100%',
    bottomVSpace: '9',
  },
];

export default DeviceSizes;
