import { TypographyVariantsOptions } from '@mui/material/styles';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption1: true,
    caption2: true,
  }
}

interface CustomTypographyOptions extends TypographyVariantsOptions {
  caption1: React.CSSProperties,
  caption2: React.CSSProperties,
}

export const typography : CustomTypographyOptions = {
  button: {
    fontWeight: 700,
    fontSize: 14,
    textTransform: 'none',
  },
  h1: {
    fontSize: 48,
    fontWeight: 400,
  },
  h2: {
    fontSize: 34,
    fontWeight: 400,
  },
  h3: {
    fontSize: 26,
    fontWeight: 400,
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
  },
  h5: {
    fontSize: 24,
    fontWeight: 400,
  },
  h6: {
    fontSize: 20,
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 700,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.45,
    lineHeight: 1.45,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
    lineHeight: 1.45,
  },
  caption1: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    lineHeight: 1.5,
  },
  caption2: {
    fontSize: 12,
    fontWeight: 700,
  },
  overline: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.4,
    lineHeight: 1.5,
  },
};
