import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const PoweredByGrowersLogoMobile = () => (
  <Svg fill="none" height="48" testID="powered-by-growers-logo" viewBox="0 0 63 48" width="63">
    <Path d="M2.42889 5.39453H1.17108V4.64453H2.42889C2.63202 4.64453 2.79738 4.61458 2.92499 4.55469C3.0552 4.49479 3.15025 4.41406 3.21014 4.3125C3.27264 4.20833 3.30389 4.09115 3.30389 3.96094C3.30389 3.82552 3.27264 3.70052 3.21014 3.58594C3.15025 3.47135 3.0552 3.37891 2.92499 3.30859C2.79738 3.23568 2.63202 3.19922 2.42889 3.19922H1.48749V7H0.546082V2.44922H2.42889C2.81171 2.44922 3.13853 2.51432 3.40936 2.64453C3.6802 2.77474 3.88593 2.95312 4.02655 3.17969C4.16978 3.40365 4.24139 3.66146 4.24139 3.95312C4.24139 4.17188 4.20103 4.36979 4.1203 4.54688C4.03957 4.72396 3.92108 4.8763 3.76483 5.00391C3.60858 5.12891 3.41848 5.22526 3.19452 5.29297C2.97056 5.36068 2.71535 5.39453 2.42889 5.39453Z" fill="#ADADAD" />
    <Path d="M9.53671 4.61719V4.83594C9.53671 5.18229 9.48723 5.49219 9.38827 5.76562C9.28931 6.03906 9.14869 6.27344 8.96639 6.46875C8.78671 6.66146 8.57056 6.80859 8.31796 6.91016C8.06535 7.01172 7.78671 7.0625 7.48202 7.0625C7.17733 7.0625 6.89869 7.01172 6.64608 6.91016C6.39348 6.80859 6.17473 6.66146 5.98983 6.46875C5.80494 6.27344 5.66171 6.03906 5.56014 5.76562C5.46119 5.49219 5.41171 5.18229 5.41171 4.83594V4.61719C5.41171 4.27083 5.46119 3.96094 5.56014 3.6875C5.66171 3.41406 5.80363 3.17969 5.98593 2.98438C6.16822 2.78906 6.38566 2.64062 6.63827 2.53906C6.89087 2.4375 7.16952 2.38672 7.47421 2.38672C7.7815 2.38672 8.06014 2.4375 8.31014 2.53906C8.56275 2.64062 8.7802 2.78906 8.96249 2.98438C9.14738 3.17969 9.28931 3.41406 9.38827 3.6875C9.48723 3.96094 9.53671 4.27083 9.53671 4.61719ZM8.5953 4.83594V4.60938C8.5953 4.3724 8.57056 4.16276 8.52108 3.98047C8.4716 3.79818 8.39738 3.64453 8.29843 3.51953C8.20207 3.39453 8.08488 3.29948 7.94686 3.23438C7.80884 3.16927 7.65129 3.13672 7.47421 3.13672C7.29973 3.13672 7.14218 3.16927 7.00155 3.23438C6.86353 3.29948 6.74634 3.39453 6.64999 3.51953C6.55624 3.64453 6.48332 3.79818 6.43124 3.98047C6.38176 4.16276 6.35702 4.3724 6.35702 4.60938V4.83594C6.35702 5.07031 6.38176 5.27995 6.43124 5.46484C6.48332 5.64714 6.55754 5.80208 6.65389 5.92969C6.75285 6.05469 6.87134 6.15104 7.00936 6.21875C7.14999 6.28385 7.30754 6.31641 7.48202 6.31641C7.6591 6.31641 7.81535 6.28385 7.95077 6.21875C8.08879 6.15104 8.20598 6.05469 8.30233 5.92969C8.39869 5.80208 8.4716 5.64714 8.52108 5.46484C8.57056 5.27995 8.5953 5.07031 8.5953 4.83594Z" fill="#ADADAD" />
    <Path d="M12.1172 6.21875L13.0625 2.44922H13.6055L13.6211 3.21484L12.6289 7H12.0664L12.1172 6.21875ZM11.5195 2.44922L12.2891 6.17578L12.3008 7H11.707L10.6016 2.44922H11.5195ZM14.6641 6.17188L15.4297 2.44922H16.3437L15.2422 7H14.6523L14.6641 6.17188ZM13.8945 2.44922L14.8398 6.22656L14.8828 7H14.3242L13.332 3.21484L13.3516 2.44922H13.8945Z" fill="#ADADAD" />
    <Path d="M20.7992 6.25391V7H18.1937V6.25391H20.7992ZM18.4906 2.44922V7H17.5492V2.44922H18.4906ZM20.4633 4.27344V5.01953H18.1937V4.27344H20.4633ZM20.7914 2.44922V3.19922H18.1937V2.44922H20.7914Z" fill="#ADADAD" />
    <Path d="M22.0203 2.44922H23.8133C24.0919 2.44922 24.3419 2.47917 24.5633 2.53906C24.7846 2.59896 24.9721 2.6875 25.1258 2.80469C25.282 2.91927 25.4005 3.0625 25.4812 3.23438C25.5646 3.40365 25.6062 3.60026 25.6062 3.82422C25.6062 4.07161 25.562 4.28385 25.4734 4.46094C25.3849 4.63542 25.2599 4.77995 25.0984 4.89453C24.937 5.00911 24.7469 5.10156 24.5281 5.17188L24.2273 5.33594H22.6453L22.6375 4.58594H23.825C24.0125 4.58594 24.1674 4.55729 24.2898 4.5C24.4122 4.44271 24.5047 4.36328 24.5672 4.26172C24.6297 4.15755 24.6609 4.03776 24.6609 3.90234C24.6609 3.75911 24.6297 3.63542 24.5672 3.53125C24.5073 3.42448 24.4135 3.34245 24.2859 3.28516C24.1609 3.22786 24.0034 3.19922 23.8133 3.19922H22.9656V7H22.0203V2.44922ZM24.7781 7L23.6531 4.98438L24.6492 4.97656L25.7937 6.95703V7H24.7781Z" fill="#ADADAD" />
    <Path d="M30.2648 6.25391V7H27.6594V6.25391H30.2648ZM27.9562 2.44922V7H27.0148V2.44922H27.9562ZM29.9289 4.27344V5.01953H27.6594V4.27344H29.9289ZM30.257 2.44922V3.19922H27.6594V2.44922H30.257Z" fill="#ADADAD" />
    <Path d="M32.9625 7H31.9L31.9078 6.25391H32.9625C33.262 6.25391 33.5068 6.19922 33.6969 6.08984C33.887 5.97786 34.0263 5.81641 34.1148 5.60547C34.206 5.39453 34.2516 5.13802 34.2516 4.83594V4.60938C34.2516 4.38281 34.2255 4.18229 34.1734 4.00781C34.1239 3.83073 34.0471 3.68229 33.943 3.5625C33.8388 3.44271 33.706 3.35286 33.5445 3.29297C33.3857 3.23047 33.1969 3.19922 32.9781 3.19922H31.8805V2.44922H32.9781C33.3036 2.44922 33.6018 2.5013 33.8726 2.60547C34.1461 2.70964 34.3805 2.85807 34.5758 3.05078C34.7737 3.24089 34.926 3.46875 35.0328 3.73438C35.1422 4 35.1969 4.29427 35.1969 4.61719V4.83594C35.1969 5.26562 35.1018 5.64323 34.9117 5.96875C34.7242 6.29427 34.4625 6.54818 34.1266 6.73047C33.7932 6.91016 33.4052 7 32.9625 7ZM32.4312 2.44922V7H31.4898V2.44922H32.4312Z" fill="#ADADAD" />
    <Path d="M41.0414 5.01172H39.7836L39.7758 4.36328H40.8969C41.0661 4.36328 41.2094 4.34115 41.3265 4.29688C41.4437 4.2526 41.5323 4.1888 41.5922 4.10547C41.6547 4.01953 41.6859 3.91536 41.6859 3.79297C41.6859 3.65495 41.656 3.54167 41.5961 3.45312C41.5362 3.36458 41.4463 3.30078 41.3265 3.26172C41.2068 3.22005 41.0583 3.19922 40.8812 3.19922H40.1V7H39.1586V2.44922H40.8305C41.1117 2.44922 41.3617 2.47526 41.5805 2.52734C41.8018 2.57682 41.9906 2.65234 42.1469 2.75391C42.3031 2.85547 42.4216 2.98568 42.5023 3.14453C42.5857 3.30078 42.6273 3.48568 42.6273 3.69922C42.6273 3.88411 42.5805 4.05599 42.4867 4.21484C42.3956 4.3737 42.2549 4.5026 42.0648 4.60156C41.8747 4.70052 41.6286 4.75521 41.3265 4.76562L41.0414 5.01172ZM40.9984 7H39.518L39.9008 6.25391H40.9984C41.1807 6.25391 41.3305 6.22786 41.4476 6.17578C41.5674 6.1237 41.656 6.05339 41.7133 5.96484C41.7706 5.8737 41.7992 5.76953 41.7992 5.65234C41.7992 5.52214 41.7732 5.41016 41.7211 5.31641C41.6716 5.22005 41.5909 5.14583 41.4789 5.09375C41.3669 5.03906 41.2211 5.01172 41.0414 5.01172H40.0805L40.0844 4.36328H41.3031L41.518 4.61719C41.807 4.61198 42.0414 4.66276 42.2211 4.76953C42.4008 4.8737 42.5323 5.00911 42.6156 5.17578C42.7015 5.34245 42.7445 5.51562 42.7445 5.69531C42.7445 5.91667 42.7055 6.10938 42.6273 6.27344C42.5492 6.4349 42.4359 6.57031 42.2875 6.67969C42.139 6.78646 41.9568 6.86719 41.7406 6.92188C41.5245 6.97396 41.2771 7 40.9984 7Z" fill="#ADADAD" />
    <Path d="M45.5633 4.99609L46.8601 2.44922H47.8914L46.2078 5.48438H45.6766L45.5633 4.99609ZM44.7273 2.44922L46.032 5.01953L45.9031 5.48438H45.3797L43.6961 2.44922H44.7273ZM46.2508 4.86719V7H45.3094V4.86719H46.2508Z" fill="#ADADAD" />
    <Path d="M21.6068 15.5C20.8545 15.5 20.1191 15.7295 19.4936 16.1594C18.8681 16.5893 18.3805 17.2004 18.0926 17.9153C17.8047 18.6303 17.7294 19.417 17.8762 20.176C18.023 20.935 18.3852 21.6321 18.9172 22.1793C19.4491 22.7265 20.1269 23.0992 20.8647 23.2501C21.6026 23.4011 22.3674 23.3236 23.0624 23.0275C23.7574 22.7313 24.3515 22.2299 24.7694 21.5864C25.1874 20.943 25.4105 20.1865 25.4105 19.4127C25.4105 18.375 25.0097 17.3798 24.2964 16.646C23.5831 15.9122 22.6156 15.5 21.6068 15.5ZM22.557 19.987C22.557 20.7447 22.2948 21.369 21.6068 21.369C20.9188 21.369 20.6551 20.7447 20.6551 19.987V18.8338C20.6551 18.0761 20.9174 17.4503 21.6068 17.4503C22.2962 17.4503 22.557 18.0761 22.557 18.8338V19.987Z" fill="#ADADAD" />
    <Path d="M25.4591 26.3107H17.753C18.0856 25.5455 18.6264 24.8958 19.3101 24.4398C19.9938 23.9838 20.7912 23.7412 21.606 23.7412C22.4209 23.7412 23.2183 23.9838 23.902 24.4398C24.5857 24.8958 25.1265 25.5455 25.4591 26.3107Z" fill="#ADADAD" />
    <Path d="M4.42831 20.8962V22.8025H5.54201V22.8707C5.54201 23.8299 5.06766 24.3042 4.29572 24.3042C3.24684 24.3042 2.67525 23.5966 2.67525 21.9433V20.0552C2.67525 19.9703 2.67525 19.89 2.67525 19.8112C2.72534 18.3322 3.24242 17.7094 4.12926 17.7094C5.05292 17.7094 5.52728 18.3504 5.53907 19.8112H7.99924C7.98303 17.2108 6.78388 15.5 4.10127 15.5C1.58512 15.5 0.256329 17.2108 0.0353557 19.8112C0.0117852 20.0749 0 20.3492 0 20.628V21.419C0 24.4497 1.29638 26.4955 3.77128 26.4955C4.87025 26.4955 5.65692 26.109 5.93534 25.3498L6.13275 26.3091H8.01839V20.8962H4.42831Z" fill="#ADADAD" />
    <Path d="M16.5759 23.8981C16.4772 22.5676 16.1016 21.9584 15.1823 21.6569C16.1178 21.419 16.9693 20.5098 16.9693 19.0247C16.9693 16.9684 15.7392 15.6909 13.1007 15.6909H9.32947V26.3136H11.8707V22.6025H12.9019C13.7386 22.6025 14.017 23.0404 14.0657 24.0163C14.132 25.2816 14.2469 25.8893 14.4428 26.309H17.0503C16.7571 25.8544 16.6923 25.3498 16.5759 23.8981ZM13.002 20.5431H11.8707V17.8155H13.002C13.9684 17.8155 14.4443 18.3367 14.4443 19.199C14.4443 20.1233 13.9684 20.5431 13.002 20.5431Z" fill="#ADADAD" />
    <Path d="M34.9373 15.6865L33.856 21.8768H33.7927L32.8071 15.6865H30.4501L29.4189 21.8768H29.3526L28.3214 15.6865H25.7419L27.8426 26.3092H30.3838L31.5152 19.4992H31.58L32.704 26.3092H35.2467L37.3459 15.6865H34.9373Z" fill="#ADADAD" />
    <Path d="M40.8064 24.118V22.0162H43.7527V19.8522H40.8064V17.8823H44.7589V15.6865H38.2652V26.3092H44.7927V24.118H40.8064Z" fill="#ADADAD" />
    <Path d="M53.3503 23.8981C53.2516 22.5676 52.873 21.9584 51.9552 21.6569C52.8921 21.419 53.7422 20.5098 53.7422 19.0247C53.7422 16.9684 52.5135 15.6909 49.8751 15.6909H46.1097V26.3136H48.6509V22.6025H49.6821C50.5189 22.6025 50.7958 23.0404 50.8459 24.0163C50.9122 25.2816 51.0271 25.8893 51.2216 26.309H53.8291C53.5315 25.8544 53.4652 25.3498 53.3503 23.8981ZM49.7764 20.5431H48.645V17.8155H49.7764C50.7428 17.8155 51.2172 18.3367 51.2172 19.199C51.2172 20.1233 50.7428 20.5431 49.7764 20.5431Z" fill="#ADADAD" />
    <Path d="M59.4153 20.0461L57.895 19.5127C57.3529 19.3293 57.073 19.0247 57.073 18.5368C57.073 17.9306 57.4825 17.5245 58.1558 17.5245C59.1575 17.5245 59.6157 18.1822 59.6157 19.1914V19.3596H61.9109V19.0929C61.9109 16.9714 60.6307 15.5 58.172 15.5C56.0079 15.5 54.6364 16.8638 54.6364 18.8414C54.6364 20.6962 55.6853 21.5039 57.0789 21.9585L58.6434 22.4707C59.2665 22.6722 59.5627 22.9419 59.5627 23.4996C59.5627 24.1057 59.1207 24.4785 58.4151 24.4785C57.3838 24.4785 56.8403 23.8542 56.8403 22.8404V22.6889H54.5289V22.9253C54.5289 25.0831 55.9402 26.5 58.4327 26.5C60.5128 26.5 62.0714 25.3377 62.0714 23.1662C62.0714 21.4523 61.0874 20.6113 59.4153 20.0461Z" fill="#ADADAD" />
    <Path d="M12.3576 48C12.0645 47.7122 11.7939 46.8047 11.7262 46.0964C11.7262 45.6758 11.6586 44.9674 11.636 44.3255C11.5458 41.5586 10.8467 40.0755 8.61425 39.7435C9.49372 39.5885 10.283 39.2565 10.982 38.7695C11.7037 38.2604 12.0645 37.375 12.0645 36.069C12.0645 32.793 9.96728 31 6.06606 31H0V48H3.85612V41.293H5.5474C7.14848 41.293 7.62203 42.1784 7.64459 43.3958V44.8568C7.66714 45.4102 7.66714 45.8086 7.68969 46.0521C7.75734 46.8268 7.89264 47.668 8.3662 48H12.3576ZM8.09559 36.0911C8.09559 37.9284 7.05827 38.5039 5.36699 38.5039H3.85612V33.612H4.91599C6.92297 33.612 8.09559 34.3867 8.09559 36.0911Z" fill="#ADADAD" />
    <Path d="M19.0899 41.7135C19.0899 41.7135 19.1801 41.3372 19.338 40.5625C19.5184 39.7878 19.6988 39.1016 19.8566 38.4818C20.0145 37.8841 20.1949 37.2422 20.3527 36.5339C20.5331 35.8255 20.6459 35.25 20.691 34.8294C20.7586 35.25 20.8714 35.8255 21.0292 36.5339C21.1871 37.2422 21.3449 37.8841 21.5028 38.4596L22.0215 40.5625C22.2019 41.3372 22.2921 41.7135 22.2921 41.7135H19.0899ZM23.1941 31H18.4359L13.6553 48H17.4663L18.3683 44.5247H23.0813L24.0735 48H27.9973L23.1941 31Z" fill="#ADADAD" />
    <Path d="M39.6772 44.9896H33.4082V31H29.5521V48H39.6772V44.9896Z" fill="#ADADAD" />
    <Path d="M51.1924 44.9896H44.9234V31H41.0673V48H51.1924V44.9896Z" fill="#ADADAD" />
    <Path d="M58.3281 31L55.4191 37.5521L52.5101 31H48.3608L53.2993 41.1823V48H57.1554V41.1823L62.0714 31H58.3281Z" fill="#ADADAD" />
  </Svg>

);
