import { Card, Logo, Text, useToast } from '@design';
import { RectangleSize, Sizes } from '@theme/variant-interfaces/Size';
import React, { useState } from 'react';
import { View, ImageSourcePropType } from 'react-native';
import { useQuery } from 'react-query';
import { LogoApi } from '../../../../utilities/api/LogoApi';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';
import { QueryKeys } from '../../../../constants';

// Placeholder image used from https://picsum.photos/
const PLACEHOLDER_IMAGE = { uri: 'https://picsum.photos/id/112/200/300' };

export const LogoDemo = () => {
  const { currentBusinessId } = useAuthentication();
  const [image, setImage] = useState<ImageSourcePropType>(null);
  const { createToast, destroyToast } = useToast();
  const [toastId, setToastId] = useState(null);

  const getLogo = async () => {
    if (currentBusinessId) {
      return LogoApi.getLogo(currentBusinessId);
    }
    return null;
  };
  useQuery([QueryKeys.BUSINESS_LOGO, currentBusinessId], getLogo, {
    retry: 1,
    onError: (err) => {
      createToast({
        children: `There was an error ${err}`,
        testID: 'toast-content-element',
      });
    },
    onSuccess: (res) => {
      if (res === null) {
        const id = createToast({
          children: 'Customer does not have a logo defined, using stock photo',
          testID: 'toast-content-element',
        });
        setToastId(id);
        setImage(PLACEHOLDER_IMAGE);
      }
      if (res !== null) {
        destroyToast(toastId);
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = () => {
          const base64Data = reader.result;
          setImage({ uri: base64Data as string });
        };
      }
    },
  });

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <DemoContainer label="Circle" style={{ alignItems: 'center' }}>
          {Sizes.map((size) => (
            <DemoBlock
              key={size}
              label={size.toUpperCase()}
            >
              <Logo
                shape="circle"
                size={size}
                source={image || PLACEHOLDER_IMAGE}
                testID={`circle-${size}`}
              />
            </DemoBlock>
          ))}
        </DemoContainer>
        <DemoContainer label="Square" style={{ alignItems: 'center' }}>
          {Sizes.map((size) => (
            <DemoBlock
              key={size}
              label={size.toUpperCase()}
            >
              <Logo
                shape="square"
                size={size}
                source={image || PLACEHOLDER_IMAGE}
                testID={`square-${size}`}
              />
            </DemoBlock>
          ))}
        </DemoContainer>
        <DemoContainer label="Rectangle" style={{ alignItems: 'center' }}>
          {RectangleSize.map((size) => (
            <DemoBlock
              key={size}
              label={size.split('-').join(' ').toUpperCase()}
            >
              <Logo
                shape="square"
                size={size}
                source={image || PLACEHOLDER_IMAGE}
                testID={`rectangle-${size}`}
              />
            </DemoBlock>
          ))}
        </DemoContainer>
        <DemoContainer label="No Image" style={{ alignItems: 'center' }}>
          {Sizes.map((size) => (
            <DemoBlock
              key={size}
              label={size.toUpperCase()}
            >
              <Logo
                shape="circle"
                size={size}
                source={null}
                testID={`circle-${size}`}
                text="GR"
              />
            </DemoBlock>
          ))}
        </DemoContainer>
        <DemoContainer style={{ alignItems: 'center' }}>
          {Sizes.map((size) => (
            <DemoBlock
              key={size}
              label={size.toUpperCase()}
            >
              <Logo
                shape="square"
                size={size}
                source={null}
                testID={`square-${size}`}
                text="GR"
              />
            </DemoBlock>
          ))}
        </DemoContainer>
        <DemoContainer style={{ alignItems: 'center' }}>
          {RectangleSize.map((size) => (
            <DemoBlock
              key={size}
              label={size.toUpperCase()}
            >
              <Logo
                shape="square"
                size={size}
                source={null}
                testID={`rectangle-${size}`}
                text="GR"
              />
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Text category="c2">Placeholder image used from https://picsum.photos/</Text>
    </View>
  );
};
