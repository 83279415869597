import { HSpacer, Icon, IconButton, Text, VSpacer } from '@design';
import { ApplicationUom } from '@shared/enums';
import { ApiProductOrderComponent } from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { usePriceTypeList } from '../../../../../hooks/usePriceTypeList';
import { StringUtility, testId } from '../../../../../utilities';
import DescriptionList, {
  DescriptionListDirection,
} from '../../../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import DetailHeader from '../../../../components/shared/DetailHeader/DetailHeader';

export interface ComponentCalculatedQuantityMap {
  [componentId: string]: CalculatedQuantity,
}

export interface CalculatedQuantity {
  acreage: number,
  applicationRate: number,
  applicationUom: ApplicationUom,
  price: number,
  quantity: number,
}

export interface CalculateQuantityDetailsProps {
  calculatedQuantity: CalculatedQuantity,
  component: ApiProductOrderComponent,
  locationId: string,
  priceTypeId?: string,
  onDelete?: (componentId: string) => void,
  onEdit?: (componentId: string) => void,
  unitPriceOverride?: number,
  readOnly?: boolean,
}

const CalculateQuantityDetails: FC<CalculateQuantityDetailsProps> = ({
  calculatedQuantity,
  component,
  locationId,
  priceTypeId,
  onDelete,
  onEdit,
  unitPriceOverride,
  readOnly,
}) => {
  const [translate] = useTranslation(['productOrders', 'errors', 'products', 'prepare', 'growers', 'common']);
  const styles = useStyleSheet({
    container: {
      backgroundColor: 'color-basic-transparent-0',
      paddingHorizontal: 16,
      paddingTop: 4,
      paddingBottom: 24,
    },
    warningContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  });
  const { defaultPriceTypeId } = usePriceTypeList();

  const pricePerAcre = CalculationUtility.calculateComponentCostPerAcre(
    component,
    priceTypeId || defaultPriceTypeId,
    locationId,
    unitPriceOverride,
    calculatedQuantity.applicationRate,
    calculatedQuantity.applicationUom,
  );
  const { applicationRate, applicationUom } = calculatedQuantity;

  return (
    <View style={styles.container} {...testId('calculate-quantity-details-container')}>
      <DetailHeader
        header={translate('CALCULATE_QUANTITY') as string}
        iconAccessoryLeft={!!onEdit && !readOnly && ((props) => (
          <IconButton
            {...props}
            {...testId('calculate-quantity-edit')}
            appearance="ghost"
            onPress={() => onEdit(component.id)}
            size="medium"
            status="basic"
            testID="calculate-quantity-edit"
          >
            Edit
          </IconButton>
        ))}
        iconAccessoryRight={!!onDelete && !readOnly && ((props) => (
          <IconButton
            {...props}
            {...testId('calculate-quantity-delete')}
            appearance="ghost"
            onPress={() => onDelete(component.id)}
            size="medium"
            status="basic"
            testID="calculate-quantity-delete"
          >
            Trash
          </IconButton>
        ))}
      />
      {component.packageQuantity < calculatedQuantity.quantity && (
        <View {...testId('quantity-warning')} style={styles.warningContainer}>
          <Icon
            {...testId('quantity-warning-icon')}
            name="AlertTriangle"
            size="small"
            status="warning"
          />
          <HSpacer size="2" />
          <Text {...testId('quantity-warning-text')} category="p2">
            {translate<string>(
              'SKU_QUANTITY_NEEDED_WARNING',
              { quantity: StringUtility.localizeNumber(calculatedQuantity.quantity, 4) },
            )}
          </Text>
        </View>
      )}
      <VSpacer size="4" />
      <DescriptionList direction={DescriptionListDirection.STACKED}>
        <DescriptionListItem
          label={translate('ACREAGE')}
          testID="acreage"
          text={translate('ACRES_COUNT', {
            acres: StringUtility.localizeNumber(calculatedQuantity.acreage, 3),
          })}
        />
        <DescriptionListItem
          {...testId('application-rate-uom')}
          label={translate('APPLICATION_RATE')}
          text={translate('APPLICATION_RATE_UOM_PER_AC', {
            applicationRate: StringUtility.localizeNumber(applicationRate, 3),
            applicationRateUom: translate(applicationUom),
          })}
        />
        <DescriptionListItem
          {...testId('price-per-ac')}
          label={translate('PRICE')}
          text={translate('PRICE_PER_AC', {
            price: StringUtility.formatCurrency(pricePerAcre),
          })}
        />
        <DescriptionListItem
          {...testId('sku-quantity-needed')}
          label={translate('SKU_QUANTITY_NEEDED')}
          text={`${StringUtility.localizeNumber(calculatedQuantity.quantity, 4)} (rounded)`}
        />
      </DescriptionList>
    </View>
  );
};

export default CalculateQuantityDetails;
