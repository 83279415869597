import React, { FC, useEffect, useRef } from 'react';
import { ScrollView, ScrollViewProps, View, ViewProps } from 'react-native';

export interface ContentScrollProps extends ViewProps {
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  scrollViewProps?: Partial<ScrollViewProps>;
  setScrollRef?: (ref: React.MutableRefObject<ScrollView>) => void
}

export const ContentScroll: FC<ContentScrollProps> = ({
  children,
  headerContent,
  footerContent,
  scrollViewProps = {},
  setScrollRef,
  ...viewProps
}) => {
  const scrollRef = useRef<ScrollView>();

  useEffect(() => {
    if (setScrollRef) {
      setScrollRef(scrollRef);
    }
  }, [setScrollRef]);

  return (
    <View {...viewProps}>
      {headerContent && (
        <View>
          {headerContent}
        </View>
      )}
      <ScrollView
        {...scrollViewProps}
        ref={scrollRef}
      >
        {children}
      </ScrollView>
      {footerContent && (
        <View>
          {footerContent}
        </View>
      )}
    </View>
  );
};
