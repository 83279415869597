/* eslint-disable no-bitwise */
import React, { FC } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Sizing } from '../../../constants';

const styles = StyleSheet.create({
  boxWrapper: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  row2: {
    borderTopWidth: 1,
    borderTopColor: 'white',
  },
  column: {
    flex: 1,
    paddingHorizontal: Sizing.BASE_SPACING,
    paddingVertical: Sizing.HALF_SPACING,
  },
  column2: {
    borderLeftWidth: 1,
    borderLeftColor: 'white',
  },
});

interface MobileBoxProps extends ViewProps {
  dataValues: JSX.Element[];
  paddingBottom?: number;
  paddingTop?: number;
}

export const MobileBox: FC<MobileBoxProps> = ({
  dataValues,
  paddingBottom = 2 * Sizing.DOUBLE_SPACING,
  paddingTop = Sizing.DOUBLE_SPACING,
  style: viewStyle,
  ...viewProps
}) => {
  const upperCut = dataValues.length > 2 ? Math.floor(dataValues.length / 2) : dataValues.length;
  const upperValues = dataValues.slice(0, upperCut);
  const lowerValues = dataValues.slice(upperCut);

  const box = [
    upperValues,
    lowerValues,
  ];

  return (
    <View style={[styles.boxWrapper, { paddingTop, paddingBottom }]} {...viewProps}>
      {box.map((row, rowIndex) => (!!row.length) && (
        <View
          // eslint-disable-next-line react/no-array-index-key
          key={rowIndex}
          style={[
            styles.row,
            !!rowIndex && styles.row2,
          ]}
        >
          {row.map((column, columnIndex) => (
            <View
              // eslint-disable-next-line react/no-array-index-key
              key={columnIndex}
              style={[
                styles.column,
                !!columnIndex && styles.column2,
              ]}
            >
              {column}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};
