import * as _ from 'lodash';
import * as eva from '@eva-design/eva';
import { GrowersDarkTheme } from './GrowersDarkTheme';
import { GrowersLightTheme } from './GrowersLightTheme';
import * as GrowersMapping from './GrowersMapping.json';

export { GrowersIconsPack } from './icons/GrowersIconsPack';
export type GrowersThemeValue = 'light' | 'dark';

type IGrowersThemes = { [P in GrowersThemeValue]: any };

const mapping = _.cloneDeep(eva.mapping);
// reference https://github.com/eva-design/eva/blob/master/packages/eva/mapping.json
// for what you would be modifying in the mapping file
// for some reason, merging GrowersMapping to mapping as a whole results in a new 'default' level
// (default.components, default.strict). it's the result of importing from json as a module, so
// this was a simple solution
_.merge(mapping.components, GrowersMapping.components);
_.merge(mapping.strict, GrowersMapping.strict);

export const GrowersThemes: IGrowersThemes = {
  light: GrowersLightTheme,
  dark: GrowersDarkTheme,
};
export { mapping };
