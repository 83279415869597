import React from 'react';
import {
  ImageProps,
  TextProps,
  View,
  Animated,
  LayoutChangeEvent,
  ViewStyle,
  StyleProp,
} from 'react-native';
import {
  FalsyFC,
  RenderProp,
  FalsyText,
  TouchableWeb,
  Point,
} from '@ui-kitten/components/devsupport';
import {
  styled,
  StyledComponentProps,
  StyleType,
  Interaction,
} from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Icon } from '../Icon/Icon';

const COLLAPSED_HEIGHT: number = 0;
const INIT_EXPANDED_HEIGHT: number = 1;
const CHEVRON_DEG_EXPANDED: number = 180;
const ANIM_DURATION: number = 200;
const POSITION_OUTSCREEN: Point = Point.outscreen();

export interface AccordionItemProps extends StyledComponentProps {
  accessoryRight?: RenderProp<Partial<ImageProps>>,
  children?: string | number | RenderProp<TextProps>,
  initExpanded?: boolean,
  style?: StyleProp<ViewStyle>,
  testID: string,
  title: React.ReactText | RenderProp<TextProps>,
}

export type AccordionItemElement = React.ReactElement<AccordionItemProps>;

interface State {
  panelHeight: number;
  status: 'init' | 'open' | 'opening' | 'closing' | 'closed'
}

@styled('AccordionItem')
export class AccordionItem extends React.Component<AccordionItemProps> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State;
  private expandAnimation: Animated.Value;

  constructor (props: AccordionItemProps) {
    super(props);
    this.expandAnimation = (
      new Animated.Value(props.initExpanded ? INIT_EXPANDED_HEIGHT : COLLAPSED_HEIGHT)
    );

    this.state = {
      status: 'init',
      panelHeight: COLLAPSED_HEIGHT,
    };

    if (props.initExpanded) {
      props.eva.dispatch([Interaction.ACTIVE]);
    }
  }

  private get shouldMeasurePanel () {
    return this.state.status === 'init';
  }

  // workaround adapted from ui-kitten MenuGroup
  private get panelStyle (): Animated.WithAnimatedObject<ViewStyle> {
    if (this.shouldMeasurePanel) {
      // if expanded on render apply no styles and measure as it appears
      if (this.props.initExpanded) {
        return {};
      }
      // otherwise move offscreen to be measured.
      return { position: 'absolute', opacity: 0, top: POSITION_OUTSCREEN.y };
    }
    // if measured then control height through animation
    return { height: this.expandAnimation };
  }

  private get expandToRotateInterpolation () {
    return this.expandAnimation.interpolate({
      inputRange: [COLLAPSED_HEIGHT, this.state.panelHeight],
      outputRange: [`${COLLAPSED_HEIGHT}deg`, `${CHEVRON_DEG_EXPANDED}deg`],
    });
  }

  private onLayout = (event: LayoutChangeEvent) => {
    if (this.shouldMeasurePanel) {
      this.setState({
        panelHeight: event.nativeEvent.layout.height,
        status: this.props.initExpanded ? 'open' : 'closed',
      });
      if (this.props.initExpanded) {
        this.expandAnimation.setValue(event.nativeEvent.layout.height);
      }
    }
  };

  public expand = (): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.setState({ status: 'opening' });
    Animated.timing(this.expandAnimation, {
      toValue: this.state.panelHeight,
      duration: ANIM_DURATION,
      useNativeDriver: false,
    }).start(() => this.setState({ status: 'open' }));
  };

  public collapse = (): void => {
    this.props.eva.dispatch([]);
    this.setState({ status: 'closing' });
    Animated.timing(this.expandAnimation, {
      toValue: COLLAPSED_HEIGHT,
      duration: ANIM_DURATION,
      useNativeDriver: false,
    }).start(() => this.setState({ status: 'closed' }));
  };

  private getComponentStyle = (style: StyleType) => {
    const container = PropsServiceHelper.allWithPrefixMapped(style, 'container');
    const titleSection = PropsServiceHelper.allWithPrefixMapped(style, 'titleSection');
    const titleText = PropsServiceHelper.allWithPrefixMapped(style, 'titleText');
    const iconRight = PropsServiceHelper.allWithPrefixMapped(style, 'iconRight');
    const chevron = PropsServiceHelper.allWithPrefixMapped(style, 'defaultIcon');
    const panelText = PropsServiceHelper.allWithPrefixMapped(style, 'panelText');
    const panelSection = PropsServiceHelper.allWithPrefixMapped(style, 'panelSection');

    return {
      container,
      titleSection,
      titleText,
      iconRight,
      chevron,
      panelText,
      panelSection,
    };
  };

  private onPress = () => {
    if (this.state.status === 'open' || this.state.status === 'opening') {
      this.collapse();
    }
    if (this.state.status === 'closed' || this.state.status === 'closing') {
      this.expand();
    }
  };

  public render = (): AccordionItemElement => {
    const {
      eva,
      style,
      children,
      accessoryRight,
      title,
      testID,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View
        {...viewProps}
        style={[evaStyle.container]}
        testID={testID}
      >
        <TouchableWeb
          onPress={this.onPress}
          style={[evaStyle.titleSection]}
        >
          <FalsyText
            component={title}
            style={[evaStyle.titleText, { flexDirection: 'row', alignItems: 'center' }]}
          />
          <View style={{ flexDirection: 'row' }}>
            <FalsyFC
              component={accessoryRight}
              style={evaStyle.iconRight}
            />
            <Animated.View style={{ transform: [{ rotate: this.expandToRotateInterpolation }] }}>
              <Icon name="ChevronDown" style={evaStyle.chevron} testID={`${testID}-expand-icon`} />
            </Animated.View>
          </View>
        </TouchableWeb>
        <Animated.View style={[{ overflow: 'hidden' }, this.panelStyle]}>
          <View
            onLayout={this.onLayout}
            style={[
              evaStyle.panelSection,
              style,
              !this.shouldMeasurePanel && { height: this.state.panelHeight },
            ]}
          >
            <FalsyText
              component={this.props.children}
              style={[evaStyle.panelText]}
            />
          </View>
        </Animated.View>
      </View>
    );
  };
}
