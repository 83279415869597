export default {
  CONTACT_EMAIL_EXISTS: 'Row {{row}}: Grower contact email ({{value}}) already exists',
  CROP_MISMATCH: 'Row {{row}}: {{value.cropSubType}} subtype is not associated with the {{value.cropType}} crop type',
  CROP_ZONE_NAME_TAKEN: 'Row {{row}}: A crop zone already exists with the given crop zone name',
  CUSTOMER_MISSING_FIELDS_MODAL_TITLE_DYNAMICS: 'Customer missing required fields in Business Central',
  CSV_UPLOAD_FAILED: 'CSV file upload failed.',
  DUPLICATED_GROWER_IMPORT: 'Row {{row}}: Grower duplicates with another record in the upload file',
  DUPLICATED_PRODUCT_SKU: 'Row {{row}}: SKU {{value}} already exists',
  DUPLICATED_PRODUCT_SKU_IN_DATA: 'Row {{row}}: SKU {{value}} appears more than once in your data',
  DUPLICATED_USER_IMPORT: 'Row {{row}}: User duplicates with another record in the upload file',
  DUPLICATE_RECORD: 'Row {{row}}: Record already exists for {{field}} {{value}}',
  ERROR: 'Error',
  ERROR_DELETE_PRICE_TYPE: 'There was an error deleting the price type',
  ERROR_DELETE_PRODUCT_ORDER: 'There was an error deleting the quick quote',
  ERROR_FETCH_AFFECTED_PRODUCT_COUNT: 'An Error occurred while trying to retrieve the affected products count',
  ERROR_FETCH_CROP_YEARS: 'There was an error loading Crop Years',
  ERROR_FETCH_DELIVERABLE_SHARE_EVENTS: 'There was an error loading the share events for this deliverable',
  ERROR_FETCH_DELIVERABLES: 'There was an error loading Deliverables',
  ERROR_FETCH_GROWERS: 'There was an error loading Growers',
  ERROR_FETCH_PRODUCTS: 'There was an error loading Products',
  ERROR_FETCH_PRODUCT_FILTERS: 'There was an error loading the product filters',
  ERROR_FETCH_PRODUCT_ORDER: 'There was an error loading quick quote',
  ERROR_FETCH_PRODUCT_ORDERS: 'There was an error loading quick quote list',
  ERROR_FETCH_PRODUCT_ORDER_FILTERS: 'There was an error loading the quick quote filters',
  ERROR_FETCH_PRODUCT_PRICE_USAGES: 'There was an error loading the product price usage count',
  ERROR_SAVING_INTEGRATION: 'The integration could not be saved. Please check that your connection information is correct',
  ERROR_SAVING_ORDER_MISSING_CUSTOMER_FIELDS_DYNAMICS: 'This order cannot be sent to Business Central because the selected customer is missing required information in Business Central.\n\nThese fields include Territory Code, Customer Posting Group, and Customer Price Group.\n\nThis needs to be corrected before proceeding. A message was also sent to the Integration notification email on file. Contact your Business Admin for more information.',
  ERROR_SAVING_ORDER_MISSING_PRIMARY_CONTACT_DYNAMICS: 'This order cannot be sent to Business Central because the selected customer does not have a primary contact. Please set a primary contact for the customer and try again.',
  ERROR_SAVING_ORDER_PRODUCTS_MISSING_DYNAMICS: 'This order cannot be sent to Business Central because the following products do not exist in that system. This order will not be saved as “Accepted”. Please contact your office team for assistance and try again.',
  ERROR_SAVING_ORDER_PRODUCTS_MISSING_SALESPERSON_DYNAMICS: 'This order cannot be sent to Business Central because the following salesperson does not exist in that system. This order will not be saved as “Accepted”. Please contact your office team for assistance and try again.',
  ERROR_SAVING_PRICE_TYPES: 'There was an error saving the price types',
  ERROR_SAVING_QQ: 'There was an error saving the quick quote',
  ERROR_SYNCING_LOCATIONS: 'There was an error syncing locations',
  FARM_NAME_TAKEN: 'Row {{row}}: A farm already exists with the given farm name',
  FATAL_ERROR_MESSAGE: 'GROWERS Rally encountered an error.',
  FATAL_ERROR_RELOAD_MESSAGE: 'Please reload the page to try again.',
  FIELD_NAME_TAKEN: 'Row {{row}}: A field already exists with the given field name',
  INSUFFICIENT_PERMISSIONS: 'Row {{row}}: Insufficient permissions to set {{field}} as {{value}}',
  INVALID_COUNTRY: 'Row {{row}}: {{field}}: {{value}}',
  INVALID_EMAIL: 'Row {{row}}: Invalid email address: {{value}}',
  INVALID_ID: 'Row {{row}}: Invalid ID: {{value}}',
  INVALID_PHONE: 'Row {{row}}: Invalid phone for {{field}}: {{value}}',
  INVALID_STATE: 'Row {{row}}: {{field}}: {{value}}',
  INVALID_VALUE: 'Row {{row}}: Invalid value for {{field}}: {{value}}',
  OVER_CHARACTER_LIMIT: 'Row {{row}}: Over max {{value}} character limit for {{field}}',
  LBS_PER_UNIT_MUST_BE_1: 'Row {{row}}: lbs per Unit must be 1 when Unit UoM is ‘Pound’',
  LOCATIONS_SYNCED_SUCCESSFULLY: 'Locations synced successfully',
  MAX_PRICE_TYPES_REACHED: 'Can not create more than the allowed {{ maxPriceTypeAllowed }} price types',
  MISSING_ONE_OF_PAIR: 'Row {{row}}: {{field}} is required',
  MISSING_PRIMARY_CONTACT_MODAL_TITLE_DYNAMICS: 'A primary contact must be set for the customer',
  MISSING_REQUIRED_FIELD: 'Row {{row}}: Missing required field {{field}}',
  NAME_TAKEN: 'Name is already in use',
  NOT_ALLOWED_TO_DELETE_DEFAULT: 'Not allowed to delete default price type',
  PRODUCTS_NOT_FOUND_MODAL_TITLE_DYNAMICS: 'Products not found in Business Central',
  SALESPERSON_NOT_FOUND_MODAL_TITLE_DYNAMICS: 'Salesperson not found in Business Central',
  ROWS_FAILED: '{{count}} Rows Failed',
  ROW_FAILED: '1 Row Failed',
  TRY_AGAIN: 'Try again',
  UNKNOWN_ERROR: 'Row {{row}}: Unknown Error',
};
