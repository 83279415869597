import { ScrollView, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  CenteredSpinner,
  Filter,
  HSpacer,
  Icon,
  Text,
  TextLink,
  VSpacer,
  useToast,
} from '@design';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FarmUtility, Permissions, RoleUtility } from '@shared/utils';
import { DocumentResult } from 'expo-document-picker';
import { ApiGrowerFarm } from '@shared/interfaces/api';
import { useFarms } from '../../../../hooks/useFarms';
import { FarmValidation } from '../../../../utilities/validation/FarmValidation';
import { GrowerFarmApi } from '../../../../utilities/api';
import { useParams } from '../../../../router';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { FarmCard } from './FarmCard';
import FarmModal, { FarmModalProps } from './FarmModal';
import { defaultField } from './helpers';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { UploadModal } from '../../../components/shared';
import { CSVResponse, ExportToCSV } from '../../../../utilities/helpers/exportCSV';
import { DetailedApiError } from '../../../../utilities/api/DetailedApiError';

type FarmOptions = Pick<FarmModalProps, 'initialFarm' | 'editMode'>;

export const FarmTabsList = () => {
  const { grower: customerId } = useParams<{ grower: string }>();
  const [translate] = useTranslation(['common', 'growers']);
  const { user } = useAuthentication();
  const [search, setSearch] = useState('');
  const [farmOptions, setFarmOptions] = useState<FarmOptions>(null);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const queryClient = useQueryClient();
  const { createToast } = useToast();

  const { farms, isFarmsLoading } = useFarms({
    customerId,
    onError: () => createToast({
      children: translate<string>('ERROR_FARMS'),
      status: 'danger',
      testID: 'toast-content-element',
    }),
  });

  useEffect(() => {
    if (farms && !isFarmsLoading) {
      setTotalResults(farms.length);
    }
  }, [isFarmsLoading, farms]);

  const filteredFarms = useMemo(() => {
    if (!search) {
      setTotalResults(farms.length);
      return farms;
    }
    const results = farms.filter((f) => FarmUtility.farmMatchesSearch(f, search));
    setTotalResults(results.length);
    return results;
  }, [search, farms]);

  const getImportTemplate = async () => {
    try {
      const data: CSVResponse = await GrowerFarmApi.getCsvTemplate();
      ExportToCSV(data.csv, translate('FARMS'));
    } catch (err) {
      createToast({
        children: translate<string>('UNEXPECTED_ERROR', { error: (err as Error).message }),
        status: 'warning',
        testID: 'toast-content-element',
      });
    }
  };

  const upsertFarms = async (farm: ApiGrowerFarm) => {
    const allOtherFarms = farms.filter((f) => f.id !== farm.id);
    const nameConflict = FarmValidation.validateNames([...allOtherFarms, farm]);
    if (nameConflict) {
      createToast({
        status: 'warning',
        children: translate<string>(nameConflict.error, { name: nameConflict.name }),
        testID: 'toast-content-element',
      });
      return;
    }
    try {
      await GrowerFarmApi.updateFarms(
        customerId,
        { farms: [...allOtherFarms, farm] },
      );
      await queryClient.invalidateQueries(QueryKeys.CUSTOMER_FARM_LIST);
      createToast({
        children: translate<string>(farmOptions?.editMode ? 'FARM_UPDATED' : 'FARM_CREATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
      setFarmOptions(null);
    } catch (err) {
      if (err instanceof DetailedApiError && err.code === 'constraint-error') {
        createToast({
          children: translate<string>('FARM_NAME_ALREADY_EXISTS', { name: farm.farmName }),
          status: 'warning',
          testID: 'toast-content-element',
        });
      } else {
        createToast({
          children: translate<string>('UNEXPECTED_ERROR', { error: (err as Error).message }),
          status: 'warning',
          testID: 'toast-content-element',
        });
      }
    }
  };

  const deleteFarm = async (farmId: string) => {
    try {
      if (farmId) {
        const updatedFarmList = farms.filter((farm) => farm.id !== farmId);
        await GrowerFarmApi.updateFarms(
          customerId,
          { farms: updatedFarmList },
        );
        await queryClient.invalidateQueries(QueryKeys.CUSTOMER_FARM_LIST);
      }
      setShowDeleteConfirmationModal(false);
      setFarmOptions(null);
    } catch (err) {
      createToast({
        children: translate<string>('UNEXPECTED_ERROR', { error: (err as Error).message }),
        status: 'warning',
        testID: 'toast-content-element',
      });
    }
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <Filter
        accessoryRight={(props) => (
          <View style={{ flexDirection: 'row' }}>
            {
              RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES) && (
                <>
                  <Button
                    accessoryLeft={(p) => <Icon name="Upload" testID="import-button-icon" {...p} />}
                    appearance="ghost"
                    onPress={() => setShowImportModal(true)}
                    size="medium"
                    status="basic"
                    testID="import-button"
                    {...props}
                  >
                    {translate<string>('IMPORT')}
                  </Button>
                  <HSpacer size="3" />
                </>
              )
            }
            <Button
              accessoryLeft={(p) => <Icon name="Plus" testID="add-farm-icon" {...p} />}
              onPress={() => setFarmOptions({
                initialFarm: {
                  farmName: '',
                  growerFields: [defaultField],
                  growerId: customerId ?? '',
                },
              })}
              size="medium"
              testID="add-farm"
            >
              {translate<string>('ADD_FARM')}
            </Button>
          </View>
        )}
        defaultColumnFilters={[]}
        filterOptions={[]}
        noFilters
        onExpand={(expanded) => setShowDetails(expanded)}
        onUpdateFilter={(filter) => setSearch(filter.search)}
        showExpandAllOption
        testID="farm-tabs-list-filter"
        totalResults={isFarmsLoading ? undefined : totalResults}
        totalResultsText={translate('FARMS_COUNT', { count: totalResults })}
      />
      <VSpacer size="5" />
      {(filteredFarms?.length && !isFarmsLoading) ? filteredFarms.map((farm, index) => (
        <View key={farm.id}>
          <FarmCard
            farm={farm}
            index={index}
            onDelete={() => {
              setSelectedFarm(farm);
              setShowDeleteConfirmationModal(true);
            }}
            onEdit={() => {
              setSelectedFarm(farm);
              setFarmOptions({
                initialFarm: farm,
                editMode: true,
              });
            }}
            showDetails={showDetails}
          />
          {filteredFarms.length > 1 && <VSpacer size="5" />}
        </View>
      )) : (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <VSpacer size="9" />
          {isFarmsLoading ? (
            <CenteredSpinner testID="loading-spinner" />
          ) : (
            <Text testID="no-data-message">
              {translate<string>('NO_FARMS_MSG')}
            </Text>
          )}
        </View>
      )}
      {showImportModal && (
        <UploadModal
          hideCloseButton
          onCancel={() => setShowImportModal(false)}
          onDone={async () => {
            setShowImportModal(false);
            await queryClient.invalidateQueries(QueryKeys.CUSTOMER_FARM_LIST);
          }}
          onFileUpload={(fileInfo: DocumentResult) => (
            GrowerFarmApi.importFarms(fileInfo, customerId)
          )}
          showTitle={false}
        >
          <View>
            <Text>
              {translate<string>('UPLOAD_FARMS_CSV')}
              <TextLink
                appearance="secondary"
                onPress={() => getImportTemplate()}
              >
                {translate<string>('THIS_TEMPLATE')}
              </TextLink>
            </Text>
          </View>
        </UploadModal>
      )}
      <ConfirmationModal
        cancelText={translate('CANCEL')}
        confirmText={translate('YES_DELETE_FARM')}
        messageText={translate('FARM_DELETE_MODAL_WARNING')}
        onCancel={() => {
          setSelectedFarm(null);
          setShowDeleteConfirmationModal(false);
        }}
        onConfirm={() => deleteFarm(selectedFarm?.id)}
        status="warning"
        title={translate('DELETE_THIS_FARM')}
        visible={showDeleteConfirmationModal}
      />
      {!!farmOptions && (
        <FarmModal
          {...farmOptions}
          onClose={() => {
            setSelectedFarm(null);
            setFarmOptions(null);
          }}
          onRemoveFarm={() => deleteFarm(selectedFarm?.id)}
          onUpdateFarm={(farm: ApiGrowerFarm) => upsertFarms(farm)}
        />
      )}
    </ScrollView>
  );
};
