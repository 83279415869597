import { SharedConfig } from '@shared/constants';
import _ from 'lodash';
import qs from 'qs';
import {
  ApiTankMix,
  ApiTankMixListQuery,
  PaginatedData, TankMixEndpoint,
} from '@shared/interfaces/api';
import { Client } from './Client';

const tankMixUrl = 'tank-mixes';

export class TankMixApi {
  static getTankMix (tankMixId: string): Promise<ApiTankMix> {
    return Client(`${tankMixUrl}/${tankMixId}`);
  }

  static createTankMix (tankMix: TankMixEndpoint.Create.Request): Promise<ApiTankMix> {
    return Client(tankMixUrl, {
      body: _.pickBy(tankMix),
    });
  }

  static updateTankMix (id: string, tankMix: TankMixEndpoint.Update.Request): Promise<ApiTankMix> {
    return Client(`${tankMixUrl}/${id}`, {
      method: 'PATCH',
      body: {
        ..._.pickBy(tankMix),
        businessLocationId: tankMix.businessLocationId,
        isActive: tankMix.isActive,
      },
    });
  }

  static listTankMix (filters: ApiTankMixListQuery): Promise<PaginatedData<ApiTankMix>> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`${tankMixUrl}?${query}`);
  }

  static listAllTankMix (
    businessId: string,
    isActive?: boolean[],
  ): Promise<PaginatedData<ApiTankMix>> {
    const filters: ApiTankMixListQuery = {
      businessId,
      limit: SharedConfig.MAX_PAGE_LIMIT,
    };
    if (isActive) {
      filters.isActive = isActive.map((value) => Boolean(value).toString());
    }
    return this.listTankMix(filters);
  }

  static updateStatus (id: string, isActive: boolean): Promise<ApiTankMix> {
    return Client(`${tankMixUrl}/${id}`, {
      method: 'PATCH',
      body: {
        isActive,
      },
    });
  }

  static updateCustomers (id: string, assignedGrowers: string[]): Promise<ApiTankMix> {
    return Client(`${tankMixUrl}/${id}`, {
      method: 'PATCH',
      body: {
        assignedGrowers,
      },
    });
  }
}
