// this mapping is to translate stored hex codes to color-crop-xx variables
// the styling of crops is done in the mapping file using the variant group
// 'color' with these values duplicated in the mapping file.
// if we go to light mode, we will be able to display different colors than
// are stored in the database.  In the future, we should consider storing
// 'color-crop-xx in the database to not need this mapping enum and allow
// the colors to be changed in a light mode theme.
// this mapping will allow us to change the color values for crop-color-xx
// without a data migration on the hex codes, however this layout limits us
// to having as many colors as we have variables.

// in the future, if we give users the ability to choose arbitrary colors,
// then color circle should probably just accept 'color' as a string and
// put that directly to the background, rather than using variant groups.
// the mapping file can define a 'defaultColor', which is pulled off
// eva.style and used in the case that color is omitted (IE: on creation)
export enum ECropColor {
  'color-crop-01' = '#97C0A0',
  'color-crop-02' = '#BEDB39',
  'color-crop-03' = '#96ED89',
  'color-crop-04' = '#20DEB7',
  'color-crop-05' = '#0EEAFF',
  'color-crop-06' = '#04BFBF',
  'color-crop-07' = '#7ABAF2',
  'color-crop-08' = '#76FFFF',
  'color-crop-09' = '#FF74FF',
  'color-crop-10' = '#BD9CF6',
  'color-crop-11' = '#9BB4BF',
  'color-crop-12' = '#ADD5F7',
  'color-crop-13' = '#FF8A8A',
  'color-crop-14' = '#F2977A',
  'color-crop-15' = '#DCA682',
  'color-crop-16' = '#E89CCE',
  'color-crop-17' = '#FA9600',
  'color-crop-18' = '#EDDA99',
  'color-crop-19' = '#FFE11A',
  'color-crop-20' = '#FFF176',
}

export type CropColor =
  | 'color-crop-01'
  | 'color-crop-02'
  | 'color-crop-03'
  | 'color-crop-04'
  | 'color-crop-05'
  | 'color-crop-06'
  | 'color-crop-07'
  | 'color-crop-08'
  | 'color-crop-09'
  | 'color-crop-10'
  | 'color-crop-11'
  | 'color-crop-12'
  | 'color-crop-13'
  | 'color-crop-14'
  | 'color-crop-15'
  | 'color-crop-16'
  | 'color-crop-17'
  | 'color-crop-18'
  | 'color-crop-19'
  | 'color-crop-20';

export const CropColors: CropColor[] = [
  'color-crop-01',
  'color-crop-02',
  'color-crop-03',
  'color-crop-04',
  'color-crop-05',
  'color-crop-06',
  'color-crop-07',
  'color-crop-08',
  'color-crop-09',
  'color-crop-10',
  'color-crop-11',
  'color-crop-12',
  'color-crop-13',
  'color-crop-14',
  'color-crop-15',
  'color-crop-16',
  'color-crop-17',
  'color-crop-18',
  'color-crop-19',
  'color-crop-20',
];
