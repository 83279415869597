import React, { FC, useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { IconButton } from '@design';
import { EvaStatus } from '@ui-kitten/components/devsupport';

interface ChevronProps {
  open: boolean,
  onPress: () => void,
  status?: EvaStatus,
  testID: string,
  nativeID: string,
}

export const Chevron: FC<ChevronProps> = ({
  open,
  onPress,
  status = 'basic',
  ...restProps
}) => {
  const openValue = useRef(new Animated.Value(open ? 1 : 0));

  // I don't like these hard coded numbers, but without them, an initial expanded row has the
  // chevron rendered offset then shift to correct location after the onmeasure.
  // setting them to 0 makes the chevron not appear then appear.
  const [width, setWidth] = useState<number>(40);
  const [height, setHeight] = useState<number>(40);

  useEffect(() => {
    Animated.timing(openValue.current, {
      toValue: open ? 1 : 0,
      useNativeDriver: false,
      duration: 200,
    }).start();
  }, [open]);

  const rotateValue = openValue.current.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '90deg'],
  });

  return (
    <Animated.View
      style={{
        width,
        height,
        transform: [{
          rotate: rotateValue,
        }],
      }}
    >
      <IconButton
        appearance="ghost"
        onLayout={(event) => {
          const { width: lwidth, height: lheight } = event.nativeEvent.layout;
          setWidth(lwidth);
          setHeight(lheight);
        }}
        onPress={onPress}
        size="medium"
        status={status}
        {...restProps}
      >
        ChevronRight
      </IconButton>
    </Animated.View>
  );
};
