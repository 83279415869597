import { Modal, Table, TableCell, TableRow } from '@design';
import { AreaPlannedCrop } from '@shared/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { StringUtility } from '../../../../../utilities';

const styles = StyleSheet.create({
  cropNumber: {
    alignItems: 'flex-end',
  },
});

interface AcresPlannedViewAllCropsModalProps {
  areaUnitType: string,
  crops: AreaPlannedCrop[],
  onClose: () => void,
  visible: boolean,
}
export const AcresPlannedViewAllCropsModal = ({
  areaUnitType,
  crops,
  onClose,
  visible,
}: AcresPlannedViewAllCropsModalProps) => {
  const [translate] = useTranslation(['farmPlans', 'common']);

  return (
    <Modal
      maxHeight
      onClose={onClose}
      testID="view-all-crops-modal"
      title={translate<string>('VIEW_ALL_CROPS_MODAL_TITLE', { count: crops.length })}
      visible={visible}
      width={675}
    >
      <Table
        tableBackground="transparent"
        testID="view-all-crops-table"
      >
        <TableRow />
        {crops.map(({
          areaValue,
          costPerArea,
          percent,
          subType,
          type,
        }) => {
          const name = `${translate<string>(type)} | ${translate<string>(subType)}`;
          return (
            <TableRow key={name} testID={`planned-acres-row-${name}`}>
              <TableCell style={{ flex: 1 }} testID="name">
                {name}
              </TableCell>
              <TableCell style={[styles.cropNumber, { flex: 1 }]} testID="cost-per-area">
                {translate<string>('PER_SHORT', {
                  area: StringUtility.formatCurrencyAccounting(costPerArea) ?? 0,
                  areaUnitType,
                })}
              </TableCell>
              <TableCell style={[styles.cropNumber, { flex: 1 }]} testID="percent-per-area">
                {translate<string>('CROPS_PERCENT_PER_AREA', {
                  percent,
                  areaValue: StringUtility.localizeNumber(areaValue, 0),
                  area: translate(areaUnitType === 'ac' ? 'ACRES' : areaUnitType),
                })}
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
    </Modal>
  );
};
