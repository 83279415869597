import { ChildrenWithProps } from '@ui-kitten/components/devsupport';
import { styled, StyledComponentProps, StyleType } from '@ui-kitten/components/theme';
import React, { FC, Fragment } from 'react';
import { View, ViewProps } from 'react-native';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { testId } from '../../../../utilities/testId';
import { VSpacer } from '../Spacer';
import { ListItemProps } from './ListItem';

export type ListSeparator = 'none' | 'top' | 'bottom' | 'both';

export interface ListProps extends ViewProps, StyledComponentProps {
  children: ChildrenWithProps<ListItemProps>,
  separator?: ListSeparator,
  testID: string,
}

const getComponentStyle = (style: StyleType) => {
  const topSeparatorStyles = PropsServiceHelper.allWithPrefixMapped(style, 'topSeparator');
  const bottomSeparatorStyles = PropsServiceHelper.allWithPrefixMapped(style, 'bottomSeparator');

  return {
    topSeparator: topSeparatorStyles,
    bottomSeparator: bottomSeparatorStyles,
  };
};

const ListRaw: FC<ListProps> = ({
  eva,
  separator,
  testID,
  children,
}) => {
  const evaStyle = getComponentStyle(eva.style);

  return (
    <View>
      {[].concat(children).map((child, index) => (
        <Fragment
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {index > 0 && <VSpacer size="7" />}
          <View
            style={[
              (separator === 'top' || separator === 'both') && evaStyle.topSeparator,
              (separator === 'bottom' || separator === 'both') && evaStyle.bottomSeparator,
            ]}
            {...testId(testID, 'wrapper')}
          >
            {child}
          </View>
        </Fragment>
      ))}
    </View>
  );
};

export const List: FC<ListProps> = styled('List')(ListRaw);
