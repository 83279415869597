import {
  FarmPlanDeliverableV1,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v1/FarmPlanDeliverableV1';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Sizing, ThemeColors } from '../../../../constants';
import { ISortableTableHeaderProps, SortableTable } from '../../../../elements/table';
import { Card, Search, Text } from '../../../../ui-components';
import { StringUtility } from '../../../../utilities';

export interface DeliverableFieldSummaryProps {
  zoneSummaries: FarmPlanDeliverableV1.Snapshot['areas'],
  selections: FarmPlanDeliverableV1.Selections,
}

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  header: {
    marginRight: 1.2 * Sizing.BASE_SPACING,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  subHeader: {
    fontSize: 1.5 * Sizing.BASE_SPACING,
    marginRight: 1.2 * Sizing.BASE_SPACING,
  },
  showMore: {
    color: ThemeColors.PRIMARY,
    marginTop: Sizing.HALF_SPACING,
    textDecorationLine: 'none',
    fontSize: Sizing.BASE_SPACING,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: Sizing.BASE_SPACING,
  },
  actionBar: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: Sizing.BASE_SPACING,
  },
  search: {
    flex: 1,
    height: 2.5 * Sizing.EM,
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const INITIAL_NUMBER_OF_ROWS = 3;

export const FarmPlanDeliverablePerFieldSummary: FC<DeliverableFieldSummaryProps> = ({
  zoneSummaries,
  selections,
}) => {
  const [translate] = useTranslation(['farmPlans', 'growers', 'prepare', 'common']);
  const [search, setSearch] = useState('');
  const [numberOfRows, setNumbersOfRows] = useState(INITIAL_NUMBER_OF_ROWS);
  const [showMore, setShowMore] = useState(false);
  const [rows, setRows] = useState([]);

  const passHeaders: ISortableTableHeaderProps[] = useMemo(() => {
    if (!selections) {
      return [];
    }

    const headers = [
      {
        label: translate('FARM_NAME'),
        field: '',
      },
      {
        label: translate('FIELD_NAME'),
        field: '',
      },
      {
        label: translate('CROP_ZONE_NAME'),
        field: '',
      },
      selections.fieldDetails.cropType && {
        label: translate('CROP_TYPE'),
        field: '',
      },
      selections.fieldDetails.passName && {
        label: translate('PASS_NAME'),
        field: '',
      },
      {
        label: translate('PRODUCT_SKU_NAME'),
        field: '',
      },
      selections.fieldDetails.rateApplied && {
        label: `${translate('RATE')} ${translate('PER_ACRE_NOTE')}`,
        field: '',
      },
      selections.fieldDetails.unitsRequired && {
        label: translate('UNITS_REQUIRED'),
        field: '',
      },
    ];
    return headers.filter((header) => !!header);
  }, [translate, selections]);

  const tableWidths = useMemo(() => {
    if (!selections) {
      return [];
    }

    const widths = [
      1, // farm name
      1, // field name
      1, // crop zone name
      selections.fieldDetails.cropType && 1.5,
      selections.fieldDetails.passName && 1,
      1.5, // sku name
      selections.fieldDetails.rateApplied && 1,
      selections.fieldDetails.unitsRequired && 1,
    ];

    return widths.filter((width) => !!width);
  }, [selections]);

  const getRows = useCallback((searchStr?: string) => {
    if (!selections || !zoneSummaries) {
      return [];
    }

    const summaries = zoneSummaries.map((zoneSummary) => ({
      ...zoneSummary,
      key: [
        zoneSummary.farmName,
        zoneSummary.fieldName,
        zoneSummary.cropZoneName,
        zoneSummary.passName,
        zoneSummary.productSkuName,
        zoneSummary.passNumber,
        zoneSummary.cropType,
        zoneSummary.cropSubType,
      ].join(' '),
    }));

    summaries.sort((a, b) => a.key.localeCompare(b.key));

    let lastFarmName = '';
    let lastFieldName = '';
    let lastZoneName = '';

    const newRows = summaries.map((zoneSummary) => {
      const columns = [
        <>
          {(searchStr || lastFarmName !== zoneSummary.farmName) && (
            <>
              <Text
                numberOfLines={1}
                style={styles.largeText}
              >
                {zoneSummary.farmName}
              </Text>
              {zoneSummary.farmArea && (
                <Text
                  numberOfLines={1}
                  style={{ color: ThemeColors.LIGHTER_GRAY }}
                >
                  {`(${StringUtility.formatDecimal(zoneSummary.farmArea)} ac)`}
                </Text>
              )}
            </>
          )}
        </>,
        <>
          {(searchStr || lastFieldName !== zoneSummary.fieldName) && (
            <>
              <Text
                numberOfLines={1}
                style={styles.largeText}
              >
                {zoneSummary.fieldName}
              </Text>
              {zoneSummary.fieldArea && (
                <Text style={{ color: ThemeColors.LIGHTER_GRAY }}>
                  {`(${StringUtility.formatDecimal(zoneSummary.fieldArea, 0)} ac)`}
                </Text>
              )}
            </>
          )}
        </>,
        <>
          {(
            zoneSummary.cropZoneName
            && (searchStr || lastZoneName !== zoneSummary.cropZoneName)
          ) && (
            <>
              <Text
                numberOfLines={1}
                style={styles.largeText}
              >
                {zoneSummary.cropZoneName}
              </Text>
              {zoneSummary.cropZoneArea && (
                <Text style={{ color: ThemeColors.LIGHTER_GRAY }}>
                  {`(${StringUtility.formatDecimal(zoneSummary.cropZoneArea, 0)} ac)`}
                </Text>
              )}
            </>
          )}
        </>,
        selections.fieldDetails.cropType && (
          <>
            {zoneSummary.cropType && (
              <Text
                numberOfLines={1}
                style={[styles.largeText, { color: zoneSummary.cropColor }]}
              >
                {translate(zoneSummary.cropType)}
                {!!zoneSummary.cropSubType && ` | ${translate(zoneSummary.cropSubType)}`}
              </Text>
            )}
          </>
        ),
        selections.fieldDetails.passName && (
          <>
            <Text
              numberOfLines={1}
              style={[styles.largeText]}
            >
              {zoneSummary.passName}
            </Text>
          </>
        ),
        <Text
          numberOfLines={1}
          style={styles.largeText}
        >
          {zoneSummary.productSkuName}
        </Text>,
        selections.fieldDetails.rateApplied && (
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatDecimal(zoneSummary.rateApplied, 3)}
            {` ${translate(zoneSummary.rateAppliedUom)}`}
          </Text>
        ),
        selections.fieldDetails.unitsRequired && (
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatDecimal(zoneSummary.unitsRequired, 2)}
            {` ${translate(zoneSummary.unitsRequiredUom)}`}
          </Text>
        ),
      ];

      const row = {
        key: zoneSummary.key,
        columns: columns.filter((column) => !!column),
      };

      lastFarmName = zoneSummary.farmName;
      lastFieldName = zoneSummary.fieldName;
      lastZoneName = zoneSummary.cropZoneName || zoneSummary.fieldName;

      return row;
    });

    if (!searchStr) {
      return newRows;
    }

    return newRows.filter((row) => new RegExp(`.*${searchStr}.*`, 'ig').test(row?.key));
  }, [selections, translate, zoneSummaries]);

  useEffect(() => {
    setRows(getRows());
  }, [getRows, zoneSummaries]);

  const resetSearch = useCallback(() => {
    setSearch('');
    setRows(getRows());
  }, [getRows]);

  const fields = new Set(zoneSummaries.map(({ fieldName }) => fieldName));
  const fieldCount = fields.size;

  const showMoreHandler = () => {
    if (!showMore) {
      setNumbersOfRows(zoneSummaries.length);
    } else {
      setNumbersOfRows(INITIAL_NUMBER_OF_ROWS);
    }
    setShowMore(!showMore);
  };

  return (
    <Card style={{ marginTop: Sizing.BASE_SPACING }} testID="per-field-summary-card">
      <View style={{ paddingBottom: Sizing.BASE_SPACING }}>
        <View style={styles.headerContainer}>
          <Text
            numberOfLines={1}
            style={styles.header}
          >
            {translate('FIELD_DETAILS') as string}
          </Text>
          <Text
            numberOfLines={1}
            style={styles.subHeader}
          >
            {fieldCount > 1
              ? translate('FIELDS_COUNT', { fields: fieldCount })
              : translate('FIELD_COUNT', { field: fieldCount })}
          </Text>
          {rows.length > INITIAL_NUMBER_OF_ROWS
            && (
              <Text
                numberOfLines={1}
                onPress={showMoreHandler}
                style={[styles.subHeader, styles.showMore]}
              >
                {showMore ? translate('COLLAPSE_ALL') : translate('SHOW_DETAILS')}
              </Text>
            )}
        </View>
      </View>
      <View style={styles.actionBar}>
        <View style={styles.search}>
          <Search
            nativeID="search-bar"
            onChangeText={setSearch}
            onClear={() => resetSearch()}
            onSubmitEditing={() => setRows(getRows(search))}
            placeholder={`${translate('SEARCH')}...`}
            style={{ flex: 1 }}
            value={search}
          />
        </View>
        <View style={{ flex: 1 }} />
      </View>
      <ScrollView>
        <SortableTable
          headers={passHeaders}
          numberOfRows={numberOfRows}
          rows={rows}
          widths={tableWidths}
        />
        {rows.length > INITIAL_NUMBER_OF_ROWS
          && (
            <View style={{ alignItems: 'center', marginTop: Sizing.BASE_SPACING }}>
              <Text
                onPress={showMoreHandler}
                style={[styles.subHeader, styles.showMore]}
              >
                {showMore ? translate('COLLAPSE_ALL') : translate('MORE', { count: rows.length - numberOfRows })}
              </Text>
            </View>
          )}
      </ScrollView>
    </Card>
  );
};
