import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBar } from '../../../../components/shared/ButtonBar';

export interface EditModeFooterProps {
  disabled?: boolean,
  createMode?: boolean,
  onCancel(): void,
  onSave?(): void,
}

export const EditModeFooter = ({
  disabled,
  createMode,
  onCancel,
  onSave,
}: EditModeFooterProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  return (
    <ButtonBar
      buttonBarType="spaced"
      disableRightAction={disabled}
      leftAction={onCancel}
      leftButtonText={translate('CANCEL')}
      rightAction={onSave}
      rightButtonText={translate(createMode ? 'CREATE_FARM_PLAN' : 'SAVE_CHANGES')}
      testID="farm-plan-form-button"
    />
  );
};
