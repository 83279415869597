import React, { useCallback, useState } from 'react';
import { PropertyGroupCard } from '../../CustomerPlanning/PropertiesTab/PropertyGroupCard';
import { ScrollView, View } from 'react-native';
import { Icon, ReorderingList, VSpacer } from '@design';
import { ApiForm, ApiFormField } from '@shared/interfaces/api';
import { FormFieldType } from '@shared/enums';

const demoDescription = 'This is an example of a property group description.\
   These have a maximum length of 250 characters.\
   This description is optional and should be hidden if it is left blank.';

const base = {
  id: '',
  businessId: '',
  createdAt: new Date,
  updatedAt: new Date,
};
const demoFormField: ApiFormField = {
  ...base,
  name: 'test',
  options: [{ id: '', other: false, value: '' }],
  order: 1,
  type: FormFieldType.Dropdown,
};

const demoForms: ApiForm[] = [
  {
    ...base,
    id: '1',
    businessId: '',
    description: demoDescription,
    fields: Array(3).fill(demoFormField),
    formInstance: null,
    name: 'Property group 1',
    order: 0,
  },
  {
    ...base,
    id: '2',
    businessId: '',
    description: null,
    fields: Array(1).fill(demoFormField),
    formInstance: null,
    name: 'Property group 2',
    order:1,
  },
  {
    ...base,
    id: '3',
    businessId: '',
    description: demoDescription,
    fields: Array(99).fill(demoFormField),
    formInstance: null,
    name: 'Property group 3',
    order: 2,
  },
  {
    ...base,
    id: '4',
    businessId: '',
    description: demoDescription,
    fields: Array(99).fill(demoFormField),
    formInstance: null,
    name: 'Property group 4',
    order: 3,
  },
  {
    ...base,
    id: '5',
    businessId: '',
    description: demoDescription,
    fields: Array(99).fill(demoFormField),
    formInstance: null,
    name: 'Property group 5',
    order: 4,
  },
];

const names = ['John Wick', 'Harry Stamper', 'John McClane', 'Rick Sanchez', 'Morty Smith'];

export const PropertyGroupCardDemo = () => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [forms, setForms] = useState(demoForms);
  const [cursor, setCursor] = useState('move');

  const dragHandle = (index) => {
    return (
      <Icon
        cursor={cursor}
        name="GrowersDragHandle"
        style={{ height: 24, width: 24 }}
        testID={`drag-handle-${index}`}
      />
    );
  };

  const onDragEnd = useCallback(() => {
    setScrollEnabled(true);
    setCursor('move');
  }, []);

  const moveArrayElement = (fromIndex: number, toIndex: number) => {
    const element = forms[fromIndex];
    forms.splice(fromIndex, 1);
    forms.splice(toIndex, 0, element);
    setForms([...forms]);
  };

  const reOrderElements = () => {
    const reOrderedElements = forms.map((form, index) => {
      form.order = index;
      return form;
    });
    setForms(reOrderedElements);
  };

  return (
    <ScrollView
      scrollEnabled={scrollEnabled}
      testID="demo-scrollView"
    >
      <ReorderingList
        handle={(index) => dragHandle(index)}
        hasBottomBorder={false}
        items={forms}
        keyExtractor={(component) => component.id}
        onDragEnd={onDragEnd}
        onDragStart={() => setCursor('ns-resize')}
        onOrder={(items) => {
          const reOrderedForms = items.map((form, index) => {
            form.order = index;
            return form;
          });
          setForms([...reOrderedForms]);
        }}
        renderItem={(form, index) => (
          <View 
            key={index}
            style={{ flex: 1 }}
          >
            <PropertyGroupCard
              createdByName={names[index]}
              form={form}
              onChangeOrder={(newIndex) => {
                if (!newIndex) return;
                moveArrayElement(index, newIndex - 1);
                reOrderElements();
              }}
            />
            <VSpacer size='3'/>
          </View>
        )}
      />
    </ScrollView>
  );
};
