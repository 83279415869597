import { Card, Tab, TabView } from '@design';
import { ProductCategory } from '@shared/enums';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Routes, Sizing } from '../../../constants';
import { QueryKeys } from '../../../constants/QueryKeys';
import { useAppContext } from '../../../contexts/AppContext';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { ProductListTab } from './ProductListTab';
import { ProductModal } from './ProductModal';
import { SeedProductModal } from './SeedModal';

const styles = StyleSheet.create({
  backgroundColor: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  card: {
    paddingHorizontal: 0,
  },
  flexRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  grow: {
    flex: 1,
    backgroundColor: '#1C1C1C',
  },
  tabView: { height: '100%' },
  tabViewBar: {
    backgroundColor: 'transparent',
    marginTop: Sizing.DOUBLE_SPACING,
  },
});

export interface ProductListPageProps {
  inTab?: boolean;
}

const Tabs = ['SEED', 'FERTILIZER', 'CHEMICAL', 'OTHER'];

const getTabRoute = (tab: number) => Routes.PRODUCT_LIST.replace(/:tab\?/, Tabs[tab]);

export const ProductListPage = () => {
  const history = useHistory();
  const { user, currentBusinessId: businessId } = useAuthentication();
  const [translate] = useTranslation(['products', 'common']);
  const { tab: tabString = '' } = useParams<{ tab: string }>();
  const [tab] = tabString.split(',');
  const [selectedIndex, setSelectedIndex] = useState(Tabs.includes(tab) ? Tabs.indexOf(tab) : 0);
  const [seedModalVisibility, setSeedModalVisibility] = useState(false);
  const [productModalVisible, setProductModalVisible] = useState(false);
  const [productId, setProductId] = useState(null);
  const { currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const { setModalProps } = useAppContext();

  useEffect(() => {
    if (!tab) {
      history.replace(getTabRoute(0));
      return;
    }

    if (Tabs[selectedIndex] !== tab) {
      setSelectedIndex(Tabs.findIndex((doc) => doc === tab));
    }
  }, [history, translate, tab, user, businessId, selectedIndex]);

  const onTabSelect = (index: number) => {
    const tabRoute = getTabRoute(index);
    setSelectedIndex(index);
    history.replace(tabRoute);
  };

  const { businessLocations } = useBusinessLocationList(currentBusinessId);

  return (
    <>
      <View style={styles.grow}>
        <TabView
          indicatorStyle={{ width: `${100}%` }}
          onSelect={(index) => onTabSelect(index)}
          selectedIndex={selectedIndex}
          style={[styles.grow, styles.tabView]}
          tabBarStyle={styles.backgroundColor}
          testID="product-list-tab-view"
        >
          <Tab testID="seed-tab" title={translate('TAB_TITLE_SEED') as string}>
            <Card style={styles.grow} testID="seed-tab-card">
              <ProductListTab
                businessId={businessId}
                locations={businessLocations}
                modalToggle={(id: string) => {
                  queryClient.invalidateQueries(QueryKeys.PRICE_TYPE_LIST);
                  setProductId(id);
                  setSeedModalVisibility(!seedModalVisibility);
                }}
                productCategory={ProductCategory.SEED}
              />
            </Card>
          </Tab>
          <Tab testID="fertilizer-tab" title={translate('TAB_TITLE_FERTILIZER') as string}>
            <Card style={styles.grow} testID="fertilizer-tab-card">
              <ProductListTab
                businessId={businessId}
                locations={businessLocations}
                modalToggle={(id: string) => {
                  queryClient.invalidateQueries(QueryKeys.PRICE_TYPE_LIST);
                  setProductId(id);
                  setProductModalVisible(!productModalVisible);
                }}
                productCategory={ProductCategory.FERTILIZER}
              />
            </Card>
          </Tab>
          <Tab testID="chemical-tab" title={translate('TAB_TITLE_CHEMICAL') as string}>
            <Card style={styles.grow} testID="chemical-tab-card">
              <ProductListTab
                businessId={businessId}
                locations={businessLocations}
                modalToggle={(id: string) => {
                  queryClient.invalidateQueries(QueryKeys.PRICE_TYPE_LIST);
                  setProductId(id);
                  setProductModalVisible(!productModalVisible);
                }}
                productCategory={ProductCategory.CHEMICAL}
              />
            </Card>
          </Tab>
          <Tab testID="other-products-tab" title={translate('TAB_TITLE_OTHER') as string}>
            <Card style={styles.grow} testID="other-products-tab-card">
              <ProductListTab
                businessId={businessId}
                locations={businessLocations}
                modalToggle={(id: string) => {
                  queryClient.invalidateQueries(QueryKeys.PRICE_TYPE_LIST);
                  setModalProps({
                    type: 'otherProductModal',
                    category: ProductCategory.OTHER,
                    productId: id,
                  });
                }}
                productCategory={ProductCategory.OTHER}
              />
            </Card>
          </Tab>
        </TabView>
      </View>
      <SeedProductModal
        id={productId}
        isVisible={seedModalVisibility}
        modalToggle={() => {
          if (seedModalVisibility) {
            setSeedModalVisibility(!seedModalVisibility);
            queryClient.invalidateQueries(QueryKeys.SEED_LIST);
          } else { setSeedModalVisibility(!seedModalVisibility); }
        }}
      />
      <ProductModal
        isVisible={productModalVisible}
        modalToggle={() => {
          if (productModalVisible) {
            setProductModalVisible(!productModalVisible);
          } else { setProductModalVisible(false); }
        }}
        productId={productId}
      />
    </>
  );
};
