import * as eva from '@eva-design/eva';

// https://www.figma.com/file/gq5VxGvUWUq7qlh0pjNcXm/Rally-Design-System---Components?node-id=181%3A85

export const GrowersDarkTheme = {
  ...eva.dark,
  // Controls the hint color For more info see https://akveo.github.io/react-native-ui-kitten/docs/guides/branding#text-and-icons
  'text-hint-color': '#ADADAD',
  'text-color-alternate': '#232323',

  'color-primary-100': '#FDFDE0',
  'color-primary-200': '#FCFCC2',
  'color-primary-300': '#F9FAA4',
  'color-primary-400': '#F4F68E',
  'color-primary-500': '#EDF354',
  'color-primary-600': '#CBCF58',
  'color-primary-700': '#A9AD44',
  'color-primary-800': '#888B33',
  'color-primary-900': '#707327',
  'color-primary-container': 'rgb(92, 100, 12)',
  'color-primary-transparent-100': 'rgba(237, 243, 84, 0.08)',
  'color-primary-transparent-200': 'rgba(237, 243, 84, 0.16)',
  'color-primary-transparent-300': 'rgba(237, 243, 84, 0.24)',
  'color-primary-transparent-400': 'rgba(237, 243, 84, 0.32)',
  'color-primary-transparent-500': 'rgba(237, 243, 84, 0.4)',
  'color-primary-transparent-600': 'rgba(237, 243, 84, 0.48)',
  'color-primary-focus': '$color-primary-200',
  'color-primary-hover': '$color-primary-400',
  'color-primary-default': '$color-primary-500',
  'color-primary-active': '$color-primary-300',
  'color-primary-disabled': '$color-basic-transparent-300',
  'color-primary-focus-border': '$color-primary-700',
  'color-primary-hover-border': '$color-primary-hover',
  'color-primary-default-border': '$color-primary-default',
  'color-primary-active-border': '$color-primary-600',
  'color-primary-disabled-border': '$color-primary-disabled',
  'color-primary-transparent-focus': '$color-primary-transparent-200',
  'color-primary-transparent-hover': 'rgba(237, 243, 84, 0.04)',
  'color-primary-transparent-default': '$color-primary-transparent-100',
  'color-primary-transparent-active': '$color-primary-transparent-100',
  'color-primary-transparent-disabled': '$color-basic-transparent-200',
  'color-primary-transparent-focus-border': '$color-primary-700',
  'color-primary-transparent-hover-border': '$color-primary-400',
  'color-primary-transparent-default-border': '$color-primary-500',
  'color-primary-transparent-active-border': '$color-primary-600',
  'color-primary-transparent-disabled-border': '$color-basic-transparent-300',

  'color-secondary-100': '#FDFDE0',
  'color-secondary-200': '#D2F7F3',
  'color-secondary-300': '#B6E9E7',
  'color-secondary-400': '#9AD2D6',
  'color-secondary-500': '#64B2BD',
  'color-secondary-600': '#5A8EA0',
  'color-secondary-700': '#416E85',
  'color-secondary-800': '#2C506A',
  'color-secondary-900': '#1D3B57',
  'color-secondary-default': '$color-secondary-500',
  'color-secondary-hover': '$color-secondary-400',
  'color-secondary-focus': '$color-secondary-200',
  'color-secondary-active': '$color-secondary-300',
  'color-secondary-disabled': '$color-basic-transparent-300',
  'color-secondary-transparent': 'rgba(237, 243, 84, 0.04)',
  'color-secondary-transparent-100': 'rgba(237, 243, 84, 0.08)',
  'color-secondary-transparent-200': 'rgba(237, 243, 84, 0.16)',
  'color-secondary-transparent-300': 'rgba(237, 243, 84, 0.24)',
  'color-secondary-transparent-400': 'rgba(237, 243, 84, 0.32)',
  'color-secondary-transparent-500': 'rgba(237, 243, 84, 0.4)',
  'color-secondary-transparent-600': 'rgba(237, 243, 84, 0.48)',

  'color-success-100': '$color-success-500',
  'color-success-200': '$color-success-500',
  'color-success-300': 'rgb(140, 250, 199)',
  'color-success-400': '$color-success-500',
  'color-success-500': '#00D68F', // rgb(0, 214, 143)
  'color-success-600': '$color-success-500',
  'color-success-700': '$color-success-500',
  'color-success-800': '$color-success-500',
  'color-success-900': '$color-success-500',
  'color-success-transparent-100': 'rgba(0, 214, 143, 0.08)',
  'color-success-transparent-200': 'rgba(0, 214, 143, 0.16)',
  'color-success-transparent-300': 'rgba(140, 250, 199, 0.16)',
  'color-success-transparent-400': 'rgba(0, 214, 143, 0.32)',
  'color-success-transparent-500': 'rgba(0, 214, 143, 0.4)',
  'color-success-transparent-600': 'rgba(0, 214, 143, 0.48)',
  'color-success-focus': '$color-success-600',
  'color-success-hover': '$color-success-400',
  'color-success-default': '$color-success-500',
  'color-success-active': '$color-success-600',
  'color-success-disabled': '$color-basic-transparent-300',
  'color-success-focus-border': '$color-success-700',
  'color-success-hover-border': '$color-success-hover',
  'color-success-default-border': '$color-success-default',
  'color-success-active-border': '$color-success-active',
  'color-success-disabled-border': '$color-success-disabled',
  'color-success-transparent-focus': '$color-success-transparent-300',
  'color-success-transparent-hover': '$color-success-transparent-200',
  'color-success-transparent-default': '$color-success-transparent-100',
  'color-success-transparent-active': '$color-success-transparent-300',
  'color-success-transparent-disabled': '$color-basic-transparent-200',
  'color-success-transparent-focus-border': '$color-success-500',
  'color-success-transparent-hover-border': '$color-success-500',
  'color-success-transparent-default-border': '$color-success-500',
  'color-success-transparent-active-border': '$color-success-500',
  'color-success-transparent-disabled-border': '$color-basic-transparent-300',

  'color-info-100': '$color-info-500',
  'color-info-200': '$color-info-500',
  'color-info-300': 'rgb(148, 203, 255)',
  'color-info-400': '#42AAFF',
  'color-info-500': '#0095FF', // rgb(0, 149, 255)
  'color-info-600': '$color-info-500',
  'color-info-700': '$color-info-500',
  'color-info-800': '$color-info-500',
  'color-info-900': '$color-info-500',
  'color-info-transparent-100': 'rgba(0, 149, 255, 0.08)',
  'color-info-transparent-200': 'rgba(0, 149, 255, 0.16)',
  'color-info-transparent-300': 'rgba(148, 203, 255, 0.16)',
  'color-info-transparent-400': 'rgba(0, 149, 255, 0.32)',
  'color-info-transparent-500': 'rgba(0, 149, 255, 0.4)',
  'color-info-transparent-600': 'rgba(0, 149, 255, 0.48)',
  'color-info-focus': '$color-info-600',
  'color-info-hover': '$color-info-400',
  'color-info-default': '$color-info-500',
  'color-info-active': '$color-info-600',
  'color-info-disabled': '$color-basic-transparent-300',
  'color-info-focus-border': '$color-info-700',
  'color-info-hover-border': '$color-info-hover',
  'color-info-default-border': '$color-info-default',
  'color-info-active-border': '$color-info-active',
  'color-info-disabled-border': '$color-info-disabled',
  'color-info-transparent-focus': '$color-info-transparent-300',
  'color-info-transparent-hover': '$color-info-transparent-200',
  'color-info-transparent-default': '$color-info-transparent-100',
  'color-info-transparent-active': '$color-info-transparent-300',
  'color-info-transparent-disabled': '$color-basic-transparent-200',
  'color-info-transparent-focus-border': '$color-info-500',
  'color-info-transparent-hover-border': '$color-info-500',
  'color-info-transparent-default-border': '$color-info-500',
  'color-info-transparent-active-border': '$color-info-500',
  'color-info-transparent-disabled-border': '$color-basic-transparent-300',

  'color-warning-100': '$color-warning-500',
  'color-warning-200': '$color-warning-500',
  'color-warning-300': '$color-warning-500',
  'color-warning-400': 'rgb(255, 201, 77)',
  'color-warning-500': '#FFAA00', // rgb(255, 170, 0)
  'color-warning-600': '$color-warning-500',
  'color-warning-700': '$color-warning-500',
  'color-warning-800': '$color-warning-500',
  'color-warning-900': '$color-warning-500',
  'color-warning-transparent-100': 'rgba(255, 170, 0, 0.08)',
  'color-warning-transparent-200': 'rgba(255, 170, 0, 0.16)',
  'color-warning-transparent-300': 'rgba(255, 170, 0, 0.24)',
  'color-warning-transparent-400': 'rgba(255, 201, 77, 0.16)',
  'color-warning-transparent-500': 'rgba(255, 170, 0, 0.4)',
  'color-warning-transparent-600': 'rgba(255, 170, 0, 0.48)',
  'color-warning-focus': '$color-warning-600',
  'color-warning-hover': '$color-warning-400',
  'color-warning-default': '$color-warning-500',
  'color-warning-active': '$color-warning-600',
  'color-warning-disabled': '$color-basic-transparent-300',
  'color-warning-focus-border': '$color-warning-700',
  'color-warning-hover-border': '$color-warning-hover',
  'color-warning-default-border': '$color-warning-default',
  'color-warning-active-border': '$color-warning-active',
  'color-warning-disabled-border': '$color-warning-disabled',
  'color-warning-transparent-focus': '$color-warning-transparent-300',
  'color-warning-transparent-hover': '$color-warning-transparent-200',
  'color-warning-transparent-default': '$color-warning-transparent-100',
  'color-warning-transparent-active': '$color-warning-transparent-300',
  'color-warning-transparent-disabled': '$color-basic-transparent-200',
  'color-warning-transparent-focus-border': '$color-warning-500',
  'color-warning-transparent-hover-border': '$color-warning-500',
  'color-warning-transparent-default-border': '$color-warning-500',
  'color-warning-transparent-active-border': '$color-warning-500',
  'color-warning-transparent-disabled-border': '$color-basic-transparent-300',

  'color-danger-100': '$color-danger-500',
  'color-danger-200': '$color-danger-500',
  'color-danger-300': '$color-danger-500',
  'color-danger-400': '$color-danger-500',
  'color-danger-500': '#FA7672', // rgb(250, 118, 114)
  'color-danger-600': 'rgb(236, 103, 98)',
  'color-danger-700': '$color-danger-500',
  'color-danger-800': '$color-danger-500',
  'color-danger-900': '$color-danger-500',
  'color-danger-transparent-100': 'rgba(250, 118, 114, 0.08)',
  'color-danger-transparent-200': 'rgba(250, 118, 114, 0.16)',
  'color-danger-transparent-300': 'rgba(250, 118, 114, 0.24)',
  'color-danger-transparent-400': 'rgba(250, 118, 114, 0.32)',
  'color-danger-transparent-500': 'rgba(250, 118, 114, 0.4)',
  'color-danger-transparent-600': 'rgba(236, 103, 98, 0.16)',
  'color-danger-focus': '$color-danger-600',
  'color-danger-hover': '$color-danger-400',
  'color-danger-default': '$color-danger-500',
  'color-danger-active': '$color-danger-600',
  'color-danger-disabled': '$color-basic-transparent-300',
  'color-danger-focus-border': 'color-danger-700',
  'color-danger-hover-border': '$color-danger-hover',
  'color-danger-default-border': '$color-danger-default',
  'color-danger-active-border': '$color-danger-active',
  'color-danger-disabled-border': '$color-danger-disabled',
  'color-danger-transparent-focus': '$color-danger-transparent-300',
  'color-danger-transparent-hover': '$color-danger-transparent-200',
  'color-danger-transparent-default': '$color-danger-transparent-100',
  'color-danger-transparent-active': '$color-danger-transparent-300',
  'color-danger-transparent-disabled': '$color-basic-transparent-200',
  'color-danger-transparent-focus-border': '$color-danger-500',
  'color-danger-transparent-hover-border': '$color-danger-500',
  'color-danger-transparent-default-border': '$color-danger-500',
  'color-danger-transparent-active-border': '$color-danger-500',
  'color-danger-transparent-disabled-border': '$color-basic-transparent-300',

  'color-basic-100': '#FFFFFF',
  'color-basic-200': '#F9F9F9',
  'color-basic-300': '#F1F1F1',
  'color-basic-400': '#E8E8E8',
  'color-basic-500': '#CDCDCD',
  'color-basic-600': '#AFAFAF',
  'color-basic-700': '#404040',
  'color-basic-800': '#2F2F2F',
  'color-basic-900': '#212121',
  'color-basic-1000': '#1C1C1C',
  'color-basic-1100': '#121212',
  'color-basic-transparent-0': 'rgba(175, 175, 175, 0.04)',
  'color-basic-transparent-100': 'rgba(175, 175, 175, 0.08)',
  'color-basic-transparent-200': 'rgba(175, 175, 175, 0.16)',
  'color-basic-transparent-300': 'rgba(175, 175, 175, 0.24)',
  'color-basic-transparent-400': 'rgba(175, 175, 175, 0.32)',
  'color-basic-transparent-500': 'rgba(175, 175, 175, 0.40)',
  'color-basic-transparent-600': 'rgba(175, 175, 175, 0.48)',
  'color-basic-control-transparent-100': 'rgba(255, 255, 255, 0.08)',
  'color-basic-control-transparent-200': 'rgba(255, 255, 255, 0.16)',
  'color-basic-control-transparent-300': 'rgba(255, 255, 255, 0.24)',
  'color-basic-control-transparent-400': 'rgba(255, 255, 255, 0.32)',
  'color-basic-control-transparent-500': 'rgba(255, 255, 255, 0.40)',
  'color-basic-control-transparent-600': 'rgba(255, 255, 255, 0.48)',
  'color-basic-focus': '$color-basic-300',
  'color-basic-hover': '$color-basic-400',
  'color-basic-default': '$color-basic-500',
  'color-basic-active': '$color-basic-200',
  'color-basic-disabled': '$color-basic-transparent-300',
  'color-basic-focus-border': '$color-basic-500',
  'color-basic-hover-border': '$color-basic-hover',
  'color-basic-default-border': '$color-basic-default',
  'color-basic-active-border': '$color-basic-active',
  'color-basic-disabled-border': '$color-basic-disabled',
  'color-basic-transparent-focus': '$color-basic-transparent-300',
  'color-basic-transparent-hover': '$color-basic-transparent-100',
  'color-basic-transparent-default': '$color-basic-transparent-200',
  'color-basic-transparent-active': '$color-basic-transparent-200',
  'color-basic-transparent-disabled': '$color-basic-transparent-400',
  'color-basic-transparent-focus-border': '$color-basic-600',
  'color-basic-transparent-hover-border': '$color-basic-600',
  'color-basic-transparent-default-border': '$color-basic-600',
  'color-basic-transparent-active-border': '$color-basic-600',
  'color-basic-transparent-disabled-border': '$color-basic-transparent-300',

  'color-control-focus': '$color-basic-300',
  'color-control-hover': '$color-basic-200',
  'color-control-default': '$color-basic-100',
  'color-control-active': '$color-basic-300',
  'color-control-disabled': '$color-basic-transparent-300',
  'color-control-focus-border': '$color-basic-500',
  'color-control-hover-border': '$color-control-hover',
  'color-control-default-border': '$color-control-default',
  'color-control-active-border': '$color-control-active',
  'color-control-disabled-border': '$color-control-disabled',
  'color-control-transparent-focus': '$color-basic-control-transparent-300',
  'color-control-transparent-hover': '$color-basic-control-transparent-200',
  'color-control-transparent-default': '$color-basic-control-transparent-100',
  'color-control-transparent-active': '$color-basic-control-transparent-300',
  'color-control-transparent-disabled': '$color-basic-transparent-200',
  'color-control-transparent-focus-border': '$color-basic-100',
  'color-control-transparent-hover-border': '$color-basic-100',
  'color-control-transparent-default-border': '$color-basic-100',
  'color-control-transparent-active-border': '$color-basic-100',
  'color-control-transparent-disabled-border': '$color-basic-transparent-300',

  // Crop colors
  'color-crop-placeholder': '$text-hint-color',
  'color-crop-01': '#97C0A0',
  'color-crop-02': '#BEDB39',
  'color-crop-03': '#96ED89',
  'color-crop-04': '#20DEB7',
  'color-crop-05': '#0EEAFF',
  'color-crop-06': '#04BFBF',
  'color-crop-07': '#7ABAF2',
  'color-crop-08': '#76FFFF',
  'color-crop-09': '#FF74FF',
  'color-crop-10': '#BD9CF6',
  'color-crop-11': '#9BB4BF',
  'color-crop-12': '#ADD5F7',
  'color-crop-13': '#FF8A8A',
  'color-crop-14': '#F2977A',
  'color-crop-15': '#DCA682',
  'color-crop-16': '#E89CCE',
  'color-crop-17': '#FA9600',
  'color-crop-18': '#EDDA99',
  'color-crop-19': '#FFE11A',
  'color-crop-20': '#FFF176',

  // controls check mark color of checkboxes, etc
  'text-control-color': '#232325',

  'text-basic-color': '$color-basic-200',

  'banner-color-default': '#000000',
  'banner-color-info': '#0F2738',
  'banner-color-success': '#0F3126',
  'banner-color-danger': '#352423',
  'banner-color-warning': '#382A0F',

  'banner-text-color-default': '$color-basic-200',
  'banner-text-color-info': '#94CBFF',
  'banner-text-color-success': '#8CFAC7',
  'banner-text-color-danger': '$color-danger-500',
  'banner-text-color-warning': '#FFC94D',

  'tag-color-default': '#C9C6BE',
};
