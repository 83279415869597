import { ApiDeliverable } from '@shared/interfaces/api';
import { FunctionComponent } from 'react';
import { TankMixDeliverableRenderFactory } from './TankMixDeliverableRenderFactory';

interface Props {
  deliverable: ApiDeliverable;
}

export const TankMixDeliverableAuthPage: FunctionComponent<Props> = ({
  deliverable,
}) => {
  const renderer = TankMixDeliverableRenderFactory.getRenderer(deliverable);

  return renderer.getDesktopView();
};
