import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTranslation } from 'react-i18next';
import { styled } from '@ui-kitten/components/theme';
import { VSpacer } from '@design';
import { Redirect, Route, Switch } from '../router';
import { Routes, Sizing } from '../constants';
import { RallyLogo, RallyLogoMobile } from '../svg/RallyLogo';
import { Login } from '../pages/auth/Login';
import { ForgotPassword } from '../pages/auth/ForgotPassword';
import { ResetPassword } from '../pages/auth/ResetPassword';
import { VerifyEmail } from '../pages/auth/VerifyEmail';
import { Toaster } from '../elements/content';
import { ScreenSizes, useScreenSizing } from '../hooks/useScreenSizing';
import { Logout } from '../pages/auth/Logout';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  panel: {
    alignItems: 'center',
  },
  rightPanelContent: {
    flex: 1,
    paddingLeft: Sizing.BASE_SPACING,
    paddingRight: Sizing.BASE_SPACING,
    maxWidth: 30 * Sizing.EM,
  },
  backgroundImage: {
    minWidth: '100%',
    minHeight: '100%',
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logo: {
    width: 15 * Sizing.EM,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const LoginViewRaw = ({ eva }) => {
  const [translate] = useTranslation(['login', 'common']);
  const sizeSetting = useScreenSizing();

  return (
    <ScrollView contentContainerStyle={[eva.style, styles.container]}>
      <KeyboardAwareScrollView
        contentContainerStyle={styles.panel}
        resetScrollToCoords={{
          x: 0,
          y: 0,
        }}
      >
        {sizeSetting > ScreenSizes.Wide
          ? <VSpacer size="10" />
          : <VSpacer size="13" />}
        <View style={styles.rightPanelContent}>
          <View style={styles.logoContainer}>
            <View style={styles.logo}>
              {sizeSetting > ScreenSizes.Wide
                ? <RallyLogoMobile />
                : <RallyLogo />}
            </View>
          </View>
          <VSpacer size="11" />
          <Switch>
            <Route
              path={Routes.CREATE_PASSWORD}
              render={() => <ResetPassword create translate={translate} />}
            />
            <Route
              path={Routes.VERIFY_EMAIL}
              render={() => <VerifyEmail />}
            />
            <Route
              path={Routes.RESET_PASSWORD}
              render={() => <ResetPassword translate={translate} />}
            />
            <Route
              path={Routes.FORGOT_PASSWORD}
              render={() => <ForgotPassword translate={translate} />}
            />
            <Route
              path={Routes.LOGIN}
              render={() => <Login translate={translate} />}
            />
            <Route
              path={Routes.LOGOUT}
              render={() => <Logout />}
            />
            <Route render={() => <Redirect to={Routes.LOGIN} />} />
          </Switch>
        </View>
      </KeyboardAwareScrollView>
      <Toaster />
    </ScrollView>
  );
};

export const LoginView = styled('App')(LoginViewRaw);
