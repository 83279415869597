import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Icon, Text } from '@design';
import { ModalUtility } from '../../../../elements/content';
import { Card } from '../../../../ui-components';
import { StringUtility } from '../../../../utilities';
import { Sizing, ThemeColors } from '../../../../constants';
import { testId } from '../../../../utilities/testId';

interface FarmPlanProductSummaryProps {
  selections?: any;
  avgRateApplied: number;
  avgRateAppliedUom: string;
  acresApplied: number;
  productUnitsRequired: number;
  productSkuPackagesRequired: number;
  productSkuPrice: number;
  productSkuName: string;
}

const styles = StyleSheet.create({
  quickViewSubsection: {
    margin: Sizing.BASE_SPACING,
    minWidth: '44%',
  },
  quickViewWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  quickViewInfoHeader: {
    fontWeight: 'bold',
    color: ThemeColors.LIGHTER_GRAY,
  },
  card: {
    width: 40 * Sizing.EM,
  },
  cardHeader: {
    marginHorizontal: 'auto',
    marginBottom: Sizing.DOUBLE_SPACING,
  },
  icon: {
    position: 'absolute',
    top: -1 * Sizing.BASE_SPACING,
    right: -2 * Sizing.BASE_SPACING,
    width: 2 * Sizing.EM,
    height: 2 * Sizing.EM,
  },
});

export const FarmPlanDeliverableProductSummaryQuickView: FC<FarmPlanProductSummaryProps> = ({
  selections,
  productUnitsRequired,
  productSkuPrice,
  productSkuPackagesRequired,
  acresApplied,
  avgRateApplied,
  avgRateAppliedUom,
  productSkuName,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  const showField = useCallback((name: string) => (_.isNil(selections)
    ? true : _.get(selections, `orderDetails.${name}`) === true), [selections]);

  return (
    <Card style={styles.card} testID="product-summary-quick-view-card">
      <TouchableOpacity onPress={() => ModalUtility.hide()}>
        <Icon
          fill={ThemeColors.LIGHTER_GRAY}
          name="Close"
          style={[styles.icon, { marginLeft: Sizing.EM }]}
          testID="close-icon"
        />
      </TouchableOpacity>
      <View {...testId(`${productSkuName}-heading`)} style={styles.cardHeader}>
        <Text category="h3" style={{ textAlign: 'center' }}>{productSkuName}</Text>
      </View>
      <View style={styles.quickViewWrapper}>
        {showField('averageRateApplied') && (
          <View {...testId('avg-rate-applied')} style={styles.quickViewSubsection}>
            <Text
              {...testId('label')}
              style={styles.quickViewInfoHeader}
            >
              {translate('AVG_RATE_APPLIED') as string}
            </Text>
            <Text
              {...testId('value')}
            >
              {`${StringUtility.formatDecimal(avgRateApplied)} ${translate(avgRateAppliedUom)}${translate('PER_AC')}`}
            </Text>
          </View>
        )}
        {showField('acresApplied') && (
          <View {...testId('acres-applied')} style={styles.quickViewSubsection}>
            <Text
              {...testId('label')}
              style={styles.quickViewInfoHeader}
            >
              {translate('ACRES_APPLIED') as string}
            </Text>
            <Text
              {...testId('value')}
            >
              {StringUtility.formatDecimal(acresApplied, 2)}
            </Text>
          </View>
        )}
        {showField('productUnitsRequired') && (
          <View {...testId('units-required')} style={styles.quickViewSubsection}>
            <Text
              {...testId('label')}
              style={styles.quickViewInfoHeader}
            >
              {translate('UNITS_REQUIRED') as string}
            </Text>
            <Text
              {...testId('value')}
            >
              {StringUtility.formatDecimal(productUnitsRequired, 2)}
            </Text>
          </View>
        )}
        {showField('productSkuPackagesRequired') && (
          <View {...testId('sku-packages-required')} style={styles.quickViewSubsection}>
            <Text
              {...testId('label')}
              style={styles.quickViewInfoHeader}
            >
              {translate('SKU_PACKAGES_REQUIRED') as string}
            </Text>
            <Text
              {...testId('value')}
            >
              {StringUtility.formatDecimal(productSkuPackagesRequired, 2)}
            </Text>
          </View>
        )}
        {showField('productSkuPrice') && (
          <View {...testId('product-sku-price')} style={styles.quickViewSubsection}>
            <Text
              {...testId('label')}
              style={styles.quickViewInfoHeader}
            >
              {translate('PRODUCT_SKU_PRICE') as string}
            </Text>
            <Text
              {...testId('value')}
            >
              {StringUtility.formatCurrencyAccounting(productSkuPrice)}
            </Text>
          </View>
        )}
      </View>
    </Card>
  );
};
