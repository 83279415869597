import {
  Badge,
  Header,
  HSpacer,
  IconButton,
  ModalSpinner,
  Tab,
  TabView,
  Text,
  ToastProps,
  Toggle,
  useBanner,
  useFeatureFlags,
  useToast,
  VSpacer,
} from '@design';
import { FeatureFlags } from '@shared/enums';
import { BusinessEndpoint } from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKeys, Routes } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useHistory, useParams } from '../../../router';
import { LinkingUtility } from '../../../utilities';
import { BusinessApi } from '../../../utilities/api';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { CropTab } from './CropTab/CropTab';
import { useBusiness } from './helpers';
import { DetailsTab } from './DetailsTab';
import { IntegrationTab } from './IntegrationTab/IntegrationTab';
import { LocationTab } from './LocationTab/LocationTab';
import { ProductSettingsTab } from './ProductSettingsTab/ProductSettingsTab';
import BusinessContactCreate = BusinessEndpoint.BusinessContactCreate;

const styles = StyleSheet.create({
  indicator: {
    width: '100%',
  },
});

const TABS = ['details', 'crops', 'locations', 'product-categories', 'integrations'];

export const BusinessDetails = () => {
  const { business: businessId, tab } = useParams<{ business: string, tab: string }>();
  const history = useHistory();
  const scrollRef = useRef<ScrollView>();
  const queryClient = useQueryClient();
  const [translate] = useTranslation(['businesses', 'common', 'errors']);
  const { currentBusinessId, user, updateBusinessList } = useAuthentication();
  const [selectedIndex, setSelectedIndex] = useState(
    TABS.includes(tab) ? TABS.indexOf(tab) : 0,
  );
  const [activeDialogVisible, setActiveDialogVisible] = useState(false);
  const [isBusinessActive, setIsBusinessActive] = useState(true);
  const { showBanner } = useBanner();
  const { isFlagOn } = useFeatureFlags();

  const { createToast } = useToast();
  const toastProps = (toastText: string, status: ToastProps['status'] = 'warning'): ToastProps => ({
    children: toastText,
    status,
    testID: 'toast-content-element',
  });

  const {
    isLoading,
    business,
    fullAddress,
  } = useBusiness({
    businessId,
    onError: () => showBanner(translate('UNEXPECTED_ERROR'), 'warning'),
  });

  useEffect(() => {
    setIsBusinessActive(business.isActive);
  }, [business]);

  useEffect(() => {
    if (businessId !== currentBusinessId) {
      history.replace(
        Routes.BUSINESS_DETAILS.replace(/:business/, currentBusinessId).replace(/:tab/, TABS[selectedIndex]),
      );
    }
  }, [businessId, currentBusinessId, history, selectedIndex]);

  const changeTab = (index: number) => {
    if (index === selectedIndex) {
      return;
    }
    setSelectedIndex(index);
    history.replace(
      Routes.BUSINESS_DETAILS.replace(/:business/, businessId).replace(/:tab/, TABS[index]),
    );
  };

  const onPageChange = () => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  };

  const canEditBusiness = (
    RoleUtility.roleHasPermission(user.userRole, Permissions.ACCESS_ALL_BUSINESSES)
  );

  const canModifyBusiness = business?.isActive && RoleUtility.canEditBusiness(user, businessId);

  const toggleActiveStatus = useMutation(
    (isActive: boolean) => (
      BusinessApi.updateBusiness(currentBusinessId, { isActive: !isActive })
    ),
    {
      onError: (error: Error) => {
        createToast(toastProps(translate('UNEXPECTED_ERROR', { error: error.message })));
      },
      onSuccess: (biz) => {
        setIsBusinessActive(biz.isActive);
        createToast(toastProps(translate('BUSINESS_UPDATED'), 'success'));
        queryClient.invalidateQueries(QueryKeys.BUSINESS_DETAILS);
        updateBusinessList();
      },
    },
  );

  const { mutate: updateBusinessContacts } = useMutation(
    (contacts: BusinessContactCreate.Request[]) => (
      BusinessApi.updateBusiness(currentBusinessId, { contacts })
    ),
    {
      onError: (error: Error) => {
        createToast(toastProps(translate('UNEXPECTED_ERROR', { error: error.message })));
      },
      onSuccess: (updatedBusiness) => {
        const oldContactCount = business.contacts.length;
        const newContactCount = updatedBusiness.contacts.length;
        let toastText: string;
        if (oldContactCount > newContactCount) {
          toastText = translate('CONTACT_DELETED_TOAST');
        } else if (oldContactCount < newContactCount) {
          toastText = translate('CONTACT_ADDED_TOAST');
        } else {
          toastText = translate('CONTACT_UPDATED_TOAST');
        }
        createToast(toastProps(toastText, 'success'));
        queryClient.invalidateQueries(QueryKeys.BUSINESS_DETAILS);
      },
    },
  );

  return (
    <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false}>
      <Header testID="business-name-header" title={business.businessName}>
        {canEditBusiness ? (
          <Toggle
            checked={isBusinessActive}
            onChange={() => setActiveDialogVisible(true)}
          >
            {translate<string>('ACTIVE')}
          </Toggle>
        ) : (
          <Badge status={business.isActive ? 'success' : 'basic'} testID="business-active-badge">
            <Text category="overline">
              {translate<string>(business.isActive ? 'ACTIVE' : 'INACTIVE')}
            </Text>
          </Badge>
        )}
        <HSpacer size="7" />
        <IconButton
          appearance="ghost"
          disabled={!business.telephone}
          onPress={() => LinkingUtility.callTelephone(business.telephone)}
          size="large"
          status="basic"
          testID="phone-button"
        >
          Phone
        </IconButton>
        <HSpacer size="3" />
        <IconButton
          appearance="ghost"
          disabled={!business.contactEmail}
          onPress={() => LinkingUtility.openEmail(business.contactEmail)}
          size="large"
          status="basic"
          testID="email-button"
        >
          Email
        </IconButton>
        <HSpacer size="3" />
        <IconButton
          appearance="ghost"
          onPress={() => LinkingUtility.openMaps(fullAddress)}
          size="large"
          status="basic"
          testID="map-link-button"
        >
          PaperPlane
        </IconButton>
      </Header>
      <VSpacer size="8" />
      {isLoading ? (
        <ModalSpinner visible={isLoading} />
      ) : (
        <TabView
          indicatorStyle={styles.indicator}
          onSelect={(index) => changeTab(index)}
          selectedIndex={selectedIndex}
          shouldLoadComponent={(index) => index === selectedIndex}
          testID="business-details-tab-view"
        >
          <Tab
            testID="business-details-details-tab"
            title={translate<string>('TAB_TITLE_DETAILS')}
          >
            <>
              <VSpacer size="8" />
              <DetailsTab
                business={business}
                scrollToTop={onPageChange}
                updateContacts={updateBusinessContacts}
              />
            </>
          </Tab>
          <Tab
            testID="business-details-crops-tab"
            title={translate<string>('CROPS')}
          >
            <>
              <VSpacer size="8" />
              <CropTab business={business} businessId={businessId} />
            </>
          </Tab>
          <Tab
            testID="business-details-locations-tab"
            title={translate<string>('TAB_TITLE_LOCATIONS')}
          >
            <>
              <VSpacer size="8" />
              <LocationTab business={business} onPageChange={onPageChange} />
            </>
          </Tab>
          <Tab
            testID="business-details-product-categories-tab"
            title={translate<string>('TAB_TITLE_PRODUCT_SETTINGS')}
          >
            <>
              <VSpacer size="8" />
              <ProductSettingsTab
                businessId={businessId}
                canModifyBusiness={canModifyBusiness}
                onPageChange={onPageChange}
              />
            </>
          </Tab>
          {isFlagOn(currentBusinessId, FeatureFlags.INTEGRATIONS) && canModifyBusiness && (
            <Tab
              testID="integration-tab"
              title={translate<string>('Integration')}
            >
              <>
                <VSpacer size="8" />
                <IntegrationTab />
              </>
            </Tab>
          )}
        </TabView>
      )}
      {activeDialogVisible && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate(isBusinessActive ? 'DEACTIVATE' : 'ACTIVATE')}
          messageText={translate(isBusinessActive
            ? 'BUSINESS_DEACTIVATE_MODAL_WARNING'
            : 'BUSINESS_ACTIVATE_MODAL_WARNING')}
          onCancel={() => setActiveDialogVisible(false)}
          onConfirm={() => {
            toggleActiveStatus.mutate(isBusinessActive);
            setActiveDialogVisible(false);
          }}
          title={translate(isBusinessActive
            ? 'BUSINESS_DEACTIVATE_TITLE'
            : 'BUSINESS_ACTIVATE_TITLE')}
          visible={activeDialogVisible}
        />
      )}
    </ScrollView>
  );
};
