import React, { PropsWithChildren, useEffect, useState } from 'react';
import { HSpacer, TableCell, TableRow } from '@design';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { Chevron } from './Chevron';
import { columnWidthStyle } from './functions';
import { IColumn, RowMeta } from './interfaces';
import { styles } from './styles';

export interface SortableRowProps<T extends RowMeta> {
  columns: IColumn<T>[],
  controlledExpand?: boolean,
  data: T,
  expanderChevronStatus?: EvaStatus,
  onRowExpand?: (data: T, expanded: boolean) => void,
  rowDetail: (data: T) => React.ReactElement,
}

export const SortableRow = <T extends RowMeta>({
  columns,
  controlledExpand,
  data,
  expanderChevronStatus = 'basic',
  onRowExpand,
  rowDetail,
}: PropsWithChildren<SortableRowProps<T>>) => {
  const {
    rowDetailId,
    rowId,
    hasDetails,
    initDetail,
  } = data;

  const [expanded, setExpanded] = useState(initDetail);

  useEffect(() => {
    setExpanded(data.initDetail);
  }, [data]);

  useEffect(() => {
    if (controlledExpand === false || controlledExpand === true) {
      setExpanded(controlledExpand);
    }
  }, [controlledExpand]);

  const showDetailColumn = !!rowDetail;
  const canShowDetails = showDetailColumn && hasDetails;
  const showDetails = canShowDetails && expanded;

  return (
    <TableRow
      key={rowId}
      rowDetailId={rowDetailId || (rowId && `row-detail|${rowId}`)}
      rowDetails={showDetails && rowDetail(data)}
      testID={rowId}
    >
      {showDetailColumn ? (
        <TableCell
          style={styles.expanderColumn}
          testID="detail-expander"
          usePadding={false}
        >
          {canShowDetails && rowDetail(data) && (
            <Chevron
              nativeID="row-chevron"
              onPress={() => {
                onRowExpand?.(data, !expanded);
                setExpanded(!expanded);
              }}
              open={showDetails}
              status={expanderChevronStatus}
              testID="row-chevron"
            />
          )}
        </TableCell>
      ) : (
        <HSpacer size="5" />
      )}
      {columns.map(({ columnId, render, align, noWrap, ...columnProps }) => (
        <TableCell
          align={align}
          key={columnId}
          noWrap={noWrap}
          style={columnWidthStyle(columnProps)}
          testID={columnId}
          usePadding={columnProps.header?.render !== null}
        >
          {(render)(data, { expanded, setExpanded })}
        </TableCell>
      ))}
    </TableRow>
  );
};
