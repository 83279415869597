import {
  Badge,
  BannerProps,
  Button,
  Icon,
  LargeModal,
  NotesModal,
  ToastProps,
  useBanner,
} from '@design';
import { ToastManager, useToast } from '@design/Application/Toast/ToastManager';
import { Mode as NoteMode } from '@design/NotesModal/NotesModal';
import { OrderStatus } from '@shared/interfaces';
import {
  ApiProduct,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  ApiUserAccount,
  ProductEndpoint,
  ProductOrderEndpoint,
} from '@shared/interfaces/api';
import { toFullDate } from '@shared/utils';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAppContext } from '../../../contexts/AppContext';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { ProductApi } from '../../../utilities/api';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { ProductOrderApi } from '../../../utilities/api/ProductOrderApi';
import {
  EditBillingShippingContactsModal,
} from '../../components/shared/BillingShippingContactsModal/EditBillingShippingContactsModal';
import {
  CustomerMissingRequestFieldsModal,
} from '../../components/shared/IntegrationOrder/CustomerMissingRequireFieldsModal';
import {
  PrimaryContactMissingModal,
} from '../../components/shared/IntegrationOrder/PrimaryContactMissingModal';
import {
  ProductsMissingModal,
} from '../../components/shared/IntegrationOrder/ProductsMissingModal';
import {
  SalesPersonMissingModal,
} from '../../components/shared/IntegrationOrder/SalesPersonMissingModal';
import { EditAcreageDialog } from './components/Acreage/EditAcreageDialog';
import {
  EditBusinessLocationDialog,
} from './components/BusinessLocation/EditBusinessLocationDialog';
import CancelOrderConfirmationDialog
  from './components/CancelOrderConfirmation/CancelOrderConfirmationDialog';
import { CreateDeliverableModal } from './components/CreateDeliverableModal/CreateDeliverableModal';
import { CropYearModal } from './components/CropYear/CropYearModal';
import { PriceTypeDialog } from './components/PriceTypeDialog';
import { EditModeFooter } from './components/ViewProductOrder/EditModeFooter';
import { ViewModeFooter } from './components/ViewProductOrder/ViewModeFooter';
import { handleAcceptedStatus, useProductOrder } from './helpers';
import { ProductOrderReviewCreate } from './ProductOrderReviewCreate';

enum Mode { VIEW, EDIT }

const styles = StyleSheet.create({
  isDraftBadge: {
    maxHeight: 26,
    marginTop: 7,
    marginRight: 8,
  },
});

export interface ViewProductOrderModalProps {
  editMode?: boolean,
  onClose: (event?) => Promise<void>,
  productOrderId?: string,
  updatedComponents?: ApiProductOrderComponent[],
  updatedProductMixComponents?: ApiProductOrderProductMixComponent[],
}

const ViewProductOrderModal: FC<ViewProductOrderModalProps> = ({
  editMode = false,
  onClose,
  productOrderId,
  updatedComponents,
  updatedProductMixComponents,
}) => {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const [initialMode] = useState(editMode ? Mode.EDIT : Mode.VIEW);

  const { user } = useAuthentication();

  const defaultNoteOptions = { visible: false, viewMode: false };
  const defaultCropYearOptions = { visible: false, viewMode: false };
  const [noteOptions, setNoteOptions] = useState(defaultNoteOptions);
  const [cropYearOptions, setCropYearOptions] = useState(defaultCropYearOptions);
  const [isUpdated, setIsUpdated] = useState(false);
  const [businessLocationVisible, setBusinessLocationVisible] = useState(false);
  const [acreageVisible, setAcreageVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [createDeliverableModalVisible, setCreateDeliverableModalVisible] = useState(false);
  const [priceTypeDialogVisible, setPriceTypeDialogVisible] = useState(false);
  const [editContactDialog, setEditContactDialog] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string>();
  const [showProductsMissingModal, setShowProductsMissingModal] = useState(false);
  const [productsMissing, setProductsMissing] = useState<ApiProduct[]>([]);
  const [showSalesPersonMissingModal, setShowSalesPersonMissingModal] = useState(false);
  const [salespersonMissing, setSalespersonMissing] = useState<ApiUserAccount>();
  const [showPrimaryContactMissingModal, setShowPrimaryContactMissingModal] = useState(false);
  const [showCustomerFieldsMissingModal, setShowCustomerFieldsMissingModal] = useState(false);

  const { createBanner } = useBanner();
  const { createToast, destroyToast } = useToast();

  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate('DISMISS') as string}
        </Button>
      </View>
    ) });

  const {
    calculatedQuantities,
    initialProductOrder,
    isLoading,
    productOrder,
    setProductOrder,
  } = useProductOrder({
    productOrderId,
    updatedComponents,
    updatedProductMixComponents,
    onError: () => createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_ORDER'))),
  });

  useEffect(() => {
    setSelectedLocationId(productOrder.locationId);
  }, [productOrder.locationId]);

  const { setModalProps } = useAppContext();
  const isOwner = productOrder.ownerId === user.id;

  const onEdit = () => {
    if (!productOrder.isDraft) {
      setModalProps({
        type: 'viewProductOrder',
        productOrderId,
        editMode: true,
      });
    } else {
      setModalProps(null);
      setTimeout(() => {
        setModalProps({
          type: 'productOrder',
          productOrderId,
        });
      }, 50);
    }
  };

  const onAddProducts = () => {
    setModalProps(null);
    setTimeout(() => {
      setModalProps({
        type: 'productOrder',
        addProductsMode: true,
        productOrderId,
        updatedComponents: productOrder.components,
        updatedProductMixComponents: productOrder.productMixComponents,
      });
    }, 50);
  };

  const handleOnCancelOrder = () => {
    setConfirmationModalVisible(true);
  };

  const handleOnCancelOrderConfirm = async () => {
    setConfirmationModalVisible(false);
    const res = await ProductOrderApi.cancelProductOrder(productOrderId);
    setProductOrder(res, true);
  };

  const showSuccessToast = async () => {
    const toastId = ToastManager.generateUniqueComponentKey();
    const toastProps: ToastProps = {
      children: `${translate('QUICK_QUOTE_SAVED')}`,
      status: 'success',
      accessoryRight: (props) => (
        <Button
          {...props}
          onPress={() => {
            destroyToast(toastId);
            setModalProps({
              type: 'viewProductOrder',
              productOrderId: productOrder.id,
            });
          }}
          status="basic"
          testID="view-quick-quote-link"
        >
          {translate<string>('VIEW_QUICK_QUOTE')}
        </Button>
      ),
      testID: 'toast-content-element',
    };
    createToast(toastProps, toastId);
  };

  const isProductOrderAccepted = isOwner
    && !productOrder.isDraft
    && productOrder.status === OrderStatus.ACCEPTED
    && !productOrder.cancelled;

  const footer = editMode ? (
    <EditModeFooter
      onCancel={async () => {
        if (initialMode === Mode.VIEW) {
          setModalProps({
            type: 'viewProductOrder',
            productOrderId,
          });
          setProductOrder(initialProductOrder);
        } else {
          await onClose();
        }
      }}
      onSave={!(_.isEqual(initialProductOrder, productOrder)) && (
        async () => {
          try {
            const possibleAcceptedProductOrder = handleAcceptedStatus(productOrder);
            const { id, ...newProductOrder } = possibleAcceptedProductOrder;
            const res = await ProductOrderApi.updateProductOrder(
              id,
              newProductOrder as ProductOrderEndpoint.Update.Request,
            );

            if (initialMode === Mode.VIEW) {
              setProductOrder(res, true);
              setIsUpdated(true);
              setModalProps({
                type: 'viewProductOrder',
                productOrderId,
              });
              showSuccessToast();
            } else {
              showSuccessToast();
              await onClose();
            }
          } catch (error) {
            if (error instanceof DetailedApiError
              && error.details.details.error === 'products-missing-dynamics') {
              setShowProductsMissingModal(true);
              setProductsMissing(error.details.details.products);
            } else if (error instanceof DetailedApiError
              && error.details.details.error === 'salesperson-not-found') {
              setShowSalesPersonMissingModal(true);
              setSalespersonMissing(productOrder.owner);
            } else if (error instanceof DetailedApiError
              && error.details.details.error === 'primary-contact-missing') {
              setShowPrimaryContactMissingModal(true);
            } else if (error instanceof DetailedApiError
              && error.details.details.error === 'missing-required-customer-fields') {
              setShowCustomerFieldsMissingModal(true);
            } else {
              createBanner(bannerProps(translate('ERROR_SAVING_QQ')));
            }
            setProductOrder(initialProductOrder);
          }
        }
      )}
      orderStatus={productOrder.status}
    />
  ) : (
    <ViewModeFooter
      isDraft={productOrder.isDraft}
      onCancelOrder={handleOnCancelOrder}
      onClose={async () => {
        await onClose();
        if (isUpdated) {
          showSuccessToast();
        }
      }}
      onEdit={isOwner && onEdit}
      onShareDeliverable={(isOwner && !productOrder.isDraft) && (
        () => setCreateDeliverableModalVisible(true)
      )}
      showCancelOrder={isProductOrderAccepted}
      status={productOrder.status}
    />
  );

  const { currentBusinessId: businessId } = useAuthentication();

  useQuery([
    QueryKeys.PRODUCT_PRICING,
    selectedLocationId,
    productOrder.priceTypeId,
    productOrder.components,
  ], () => {
    if (productOrder.components.length === 0) {
      return {};
    }

    return ProductApi.getPricing({
      productIds: productOrder.components.map(({ productId }) => productId),
      businessId,
      priceTypeId: productOrder.priceTypeId,
      locationId: selectedLocationId ?? '',
    });
  }, {
    onSuccess: (data: ProductEndpoint.Pricing.Response) => {
      const components = productOrder.components.map((component) => {
        const price = data?.[component.productId]?.[productOrder.priceTypeId];
        component.product.prices = [{
          createdAt: component.createdAt,
          updatedAt: component.updatedAt,
          id: null,
          isActive: true,
          businessLocationId: selectedLocationId ?? null,
          price,
          productId: component.productId,
          priceTypeId: productOrder.priceTypeId,
        }];
        return component;
      });

      setProductOrder({
        ...productOrder,
        components,
        locationId: selectedLocationId,
      });
      setBusinessLocationVisible(false);
    },
  });

  const showAddMoreProducts = !isProductOrderAccepted && !productOrder.isDraft && editMode;

  return (
    <>
      { createDeliverableModalVisible && (
        <CreateDeliverableModal
          onClose={async () => {
            setCreateDeliverableModalVisible(false);
          }}
          productOrderId={productOrder.id}
        />
      )}
      <EditBusinessLocationDialog
        locationId={productOrder.locationId ?? null}
        onCancel={() => setBusinessLocationVisible(false)}
        onUpdateLocation={(locationId) => setSelectedLocationId(locationId)}
        visible={businessLocationVisible}
      />
      <EditAcreageDialog
        acreage={productOrder.acreage ?? 0}
        onAcreageChange={(acreage) => {
          setProductOrder({ ...productOrder, acreage });
          setAcreageVisible(false);
        }}
        onCancel={() => setAcreageVisible(false)}
        visible={acreageVisible}
      />
      {noteOptions.visible && (
        <NotesModal
          mode={noteOptions.viewMode ? NoteMode.VIEW : NoteMode.EDIT}
          note={productOrder.note ?? ''}
          onClose={() => setNoteOptions(defaultNoteOptions)}
          onDelete={() => setProductOrder({ ...productOrder, note: null })}
          setNote={(note) => setProductOrder({ ...productOrder, note })}
        />
      )}
      {cropYearOptions.visible && (
        <CropYearModal
          cropYear={productOrder.cropYear}
          onClose={() => setCropYearOptions(defaultCropYearOptions)}
          onUpdateCropYear={!cropYearOptions.viewMode && (
            (cropYear) => {
              setProductOrder({ ...productOrder, cropYear });
              setCropYearOptions(defaultCropYearOptions);
            }
          )}
        />
      )}
      {editContactDialog && (
        <EditBillingShippingContactsModal
          billingContactId={productOrder.billingContactId ?? null}
          customerId={productOrder.growerId}
          onClose={() => setEditContactDialog(false)}
          onUpdateCustomerContact={(contacts) => {
            const { billingContactId, shippingContactId } = contacts;
            setProductOrder({
              ...productOrder,
              billingContactId,
              shippingContactId,
            });
          }}
          shippingContactId={productOrder.shippingContactId ?? null}
        />
      )}
      {priceTypeDialogVisible && (
      <PriceTypeDialog
        onClose={() => setPriceTypeDialogVisible(false)}
        onUpdatePriceType={(priceTypeId) => setProductOrder({ ...productOrder, priceTypeId })}
        priceTypeId={productOrder.priceTypeId}
      />
      )}
      <LargeModal
        footer={!isLoading && footer}
        headerButton={
          <>
            {showAddMoreProducts && (
              <Button
                accessoryLeft={(props) => <Icon {...props} name="Plus" testID="view-mode-footer-add-products-button-icon" />}
                appearance="outline"
                onPress={onAddProducts}
                size="large"
                status="primary"
                testID="view-mode-footer-add-products-button"
              >
                {(translate<string>('ADD_MORE_PRODUCTS'))}
              </Button>
            )}
            {productOrder.isDraft && (
              <View>
                <Badge
                  status="warning"
                  style={styles.isDraftBadge}
                  testID="quick-quote-isdraft-badge"
                >
                  {translate<string>('DRAFT').toUpperCase()}
                </Badge>
              </View>
            )}
          </>
        }
        pages={[
          <ProductOrderReviewCreate
            calculatedQuantities={calculatedQuantities}
            disallowDeleteComponent={productOrder.components.length <= 1}
            disallowEditCalculateQuantity={!editMode}
            disallowNewCalculateQuantity={!editMode}
            onEditAcreage={editMode && (() => setAcreageVisible(true))}
            onEditBusinessLocation={editMode && (() => setBusinessLocationVisible(true))}
            onEditContacts={editMode && (() => setEditContactDialog(true))}
            onEditCropYear={
              editMode && (
                () => setCropYearOptions({ visible: true, viewMode: false })
              )
            }
            onEditNote={
              editMode && (
                () => setNoteOptions({ visible: true, viewMode: false })
              )
            }
            onEditPriceType={editMode && (() => setPriceTypeDialogVisible(true))}
            onProductOrderChange={setProductOrder}
            onStatusSelected={
              editMode && (
                (status) => setProductOrder({ ...productOrder, status })
              )
            }
            onViewNote={() => setNoteOptions({ visible: true, viewMode: true })}
            productOrder={productOrder}
            viewMode={!editMode}
          />,
        ]}
        subTitle={translate('LAST_UPDATED_ON', { date: toFullDate(productOrder.updatedAt) })}
        testID="product-order-modal"
        title={productOrder.name}
        titleOverline={translate('QUICK_QUOTE_ORDER')}
        visible
      />
      <CancelOrderConfirmationDialog
        onCancel={() => setConfirmationModalVisible(false)}
        onConfirm={handleOnCancelOrderConfirm}
        productOrder={productOrder}
        visible={confirmationModalVisible}
      />
      {showProductsMissingModal &&
        <ProductsMissingModal
          onClose={() => setShowProductsMissingModal(false)}
          products={productsMissing}
          visible
        />
      }
      {showSalesPersonMissingModal &&
        <SalesPersonMissingModal
          onClose={() => setShowSalesPersonMissingModal(false)}
          salesperson={salespersonMissing}
          visible
        />
      }
      {showPrimaryContactMissingModal &&
        <PrimaryContactMissingModal
          onClose={() => setShowPrimaryContactMissingModal(false)}
          visible
        />
      }
      {showCustomerFieldsMissingModal &&
        <CustomerMissingRequestFieldsModal
          onClose={() => setShowCustomerFieldsMissingModal(false)}
          visible
        />
      }
    </>
  );
};

export default ViewProductOrderModal;
