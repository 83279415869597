import { CheckBox } from '@design/CheckBox/CheckBox';
import React, { useRef, useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import { EvaProp, styled, StyleType } from '@ui-kitten/components';
import { FalsyFC, LiteralUnion, RenderProp } from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { TextLink } from '@design/TextLink/TextLink';
import { Text } from '../Text/Text';
import { Search } from '../Search/Search';
import { Chip } from '../Chip/Chip';
import { HSpacer } from '../Spacer/HSpacer';
import { Icon } from '../Icon/Icon';
import { VSpacer } from '../Spacer/VSpacer';
import { SidePanel } from '../SidePanel/SidePanel';
import { Button, ButtonProps } from '../Button/Button';
import { SidePanelFilterItem } from './SidePanelFilterItem';
import { FilterMenuOptions } from '../FilterMenu/FilterMenu';
import { MainFilterSelects } from './MainFilterSelects';

const styles = StyleSheet.create({
  totalResultRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
export interface FilterDefault {
  search?: string,
}

export interface Selections { [key: string]: boolean }

export interface FilterProps<T extends FilterDefault> extends ViewProps {
  accessoryRight?: RenderProp<Partial<ButtonProps>>,
  autoClearSelections?: boolean,
  defaultColumnFilters: string[],
  defaultFilters?: Map<string, Selections>,
  eva?: EvaProp,
  expandAllAppearance?: LiteralUnion<'default' | 'secondary'>,
  filterOptions: FilterCategory[],
  isFilterDisabled?: boolean,
  isSelectAll?: boolean,
  minFiltersToShowMore?: number;
  noFilters: boolean,
  noSearchBox?: boolean,
  onExpand?: (expanded: boolean) => void,
  onSelectAll?: () => void,
  onUpdateFilter: (filter: T) => void,
  placeHolderText?: string,
  searchBoxTestID?: string,
  showExpandAllOption?: boolean,
  showMoreFiltersButton?: boolean,
  showSelectAll?: boolean,
  testID: string,
  totalResults?: number,
  totalResultsText?: string,
}

export interface FilterCategory {
  columnLabel: string,
  columnKey: string,
  columns: FilterMenuOptions[],
  onSearch?: (item: string) => void,
  preserve?: boolean,
  singleSelection?: boolean,
  total?: number,
}

const emptyFilters = new Map();

const SHOW_MORE_MIN_FILTERS = 3;

const FilterRaw = <T extends FilterDefault> ({
  accessoryRight,
  autoClearSelections,
  defaultColumnFilters,
  defaultFilters = emptyFilters,
  eva,
  expandAllAppearance,
  filterOptions,
  isFilterDisabled,
  isSelectAll = false,
  minFiltersToShowMore,
  noFilters,
  noSearchBox,
  onExpand,
  onSelectAll,
  onUpdateFilter,
  placeHolderText,
  searchBoxTestID,
  showExpandAllOption,
  showSelectAll,
  showMoreFiltersButton,
  totalResults,
  totalResultsText,
  testID,
  ...viewProps
}: FilterProps<T>) => {
  const [translate] = useTranslation(['common']);
  const [sidePanelFilterCount, setSidePanelFilterCount] = useState(0);
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [allFilterOptions, setAllFilterOptions] = useState<Map<string, FilterCategory> | null>(
    null,
  );
  const [allFilterSelections, setAllFilterSelections] = useState<Map<string, Selections> | null>(
    null,
  );
  const [isSelectedAll, setIsSelectedAll] = useState(isSelectAll);
  useEffect(() => {
    setIsSelectedAll(isSelectAll);
  }, [isSelectAll]);
  const [areDefaultsApplied, setAreDefaultsApplied] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const accordionSidePanel = useRef<SidePanel>(null);

  useEffect(() => {
    if (!autoClearSelections || !allFilterSelections) return;

    const newFilterSelections = new Map<string, Selections>();
    const filterSelections = Array.from(allFilterSelections.keys());

    for (const filterSelection of filterSelections) {
      if (!defaultColumnFilters.includes(filterSelection)) {
        const values = allFilterSelections.get(filterSelection);
        Object.keys(values).forEach((valueKey) => { values[valueKey] = false; });
      }

      newFilterSelections.set(filterSelection, allFilterSelections.get(filterSelection));
    }

    setAllFilterSelections(newFilterSelections);

    // We disable the lint rule here to prevent an infinite loop on allFilterSelections
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultColumnFilters, autoClearSelections]);

  useEffect(() => {
    const newFilterOptions = new Map<string, FilterCategory>();
    const newFilterSelections = new Map<string, Selections>();

    filterOptions?.forEach((val) => {
      const categoryFilterIndexes = {};
      newFilterOptions.set(val.columnKey, val);
      val?.columns?.forEach((option) => {
        categoryFilterIndexes[option.id] = val.preserve
          ? (allFilterSelections?.get(val.columnKey)?.[option.id] ?? false)
          : false;
      });
      newFilterSelections.set(val.columnKey, categoryFilterIndexes);
    });

    if (!areDefaultsApplied && defaultFilters.size) {
      for (const filter of defaultFilters.keys()) {
        const newSelections = newFilterSelections.get(filter);
        const overrideSelections = { ...newSelections, ...defaultFilters.get(filter) };
        newFilterSelections.set(filter, overrideSelections);
      }
      setAreDefaultsApplied(true);
    }

    setAllFilterOptions(newFilterOptions);
    setAllFilterSelections(newFilterSelections);
    // We disable the lint rule here to prevent an infinite loop on allFilterSelections
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters, JSON.stringify(Array.from(filterOptions))]);

  const clearTimeoutRef = () => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
  };

  const generateQuery = (
    filters: Map<string, Selections>, searchText: string,
  ) => {
    clearTimeoutRef();

    const newFilterQuery = {} as T;
    if (searchText !== '') {
      newFilterQuery.search = searchText.toLowerCase();
    }

    filters.forEach((selections, category) => {
      const activeFilters = Object.keys(selections)
        .filter((key) => selections[key]);
      if (activeFilters.length > 0) {
        newFilterQuery[category] = activeFilters;
      }
    });

    onUpdateFilter(newFilterQuery);
  };

  const handleSearchChangeText = (text?: string) => {
    setSearch(text);
    clearTimeoutRef();
    timeoutRef.current = window.setTimeout(() => {
      generateQuery(allFilterSelections, text);
    }, 500);
  };

  const handleSearchClear = () => {
    setSearch('');
    generateQuery(allFilterSelections, '');
  };

  const updateSidePanelFilterCount = (filterSelections: Map<string, Selections>) => {
    const allSidePanelSelections = new Map(filterSelections);
    let filterCount = 0;
    allSidePanelSelections.forEach((selections) => {
      filterCount += Object.keys(selections)
        .filter((key) => selections[key]).length;
    });

    setSidePanelFilterCount(filterCount);
  };

  /** Called when user selects a new filter to add/remove */
  const updateSelections = (
    key: string, category: string, value: boolean, singleSelection?: boolean,
  ) => {
    const newFilterSelections = new Map(allFilterSelections);
    const newSelectedFilter = newFilterSelections.get(category);

    if (singleSelection) {
      // eslint-disable-next-line guard-for-in
      for (const filterKey in newSelectedFilter) {
        newSelectedFilter[filterKey] = false;
      }
    }
    newSelectedFilter[key] = value;

    newFilterSelections.set(category, newSelectedFilter);

    generateQuery(newFilterSelections, search);
    updateSidePanelFilterCount(newFilterSelections);
  };

  // Updates count on mount
  useEffect(() => {
    const newFilterSelections = new Map(allFilterSelections);
    updateSidePanelFilterCount(newFilterSelections);
  }, [allFilterSelections]);

  const clearFilterSelections = () => {
    /** Creates a default filter idx for keeping track of selected dropdowns */
    const newFilterSelections = new Map(allFilterSelections);

    newFilterSelections.forEach((val, key) => {
      const newCategorySelections = { ...val };
      Object.keys(newCategorySelections)
        .forEach((selection) => { newCategorySelections[selection] = false; });
      newFilterSelections.set(key, newCategorySelections);
    });

    updateSidePanelFilterCount(newFilterSelections);
    setAllFilterSelections(newFilterSelections);
  };

  const deleteAllFilters = () => {
    clearFilterSelections();
    generateQuery(new Map(), search);
  };

  const renderSidePanelFilterItems = (
    sideMenuAccordionTop: Record<string, any>,
    sideMenuAccordionBottom: Record<string, any>,
  ) => {
    const allSidePanelOptions = new Map(allFilterOptions);
    const output = [];

    const lastIndex = allSidePanelOptions.size - 1;
    let idx = 0;

    allSidePanelOptions.forEach((options, category) => {
      const { columns } = allFilterOptions.get(category);
      if (columns?.length === 0) {
        return;
      }

      const selections = allFilterSelections.get(category);
      output.push(
        <View
          key={`${options.columnKey}_SidePanelFilterItem`}
          style={[(sideMenuAccordionTop),
            (idx === lastIndex && sideMenuAccordionBottom), {}]}
        >
          <SidePanelFilterItem
            category={options.columnLabel}
            filterOptions={columns}
            filterSelections={selections}
            onSearch={options.onSearch}
            testID={`${testID}-side-panel-filter-item-${idx}`}
            total={options.total}
            updateSelections={(option, value) => updateSelections(option, category, value)}
          />
        </View>,
      );
      idx += 1;
    });

    return output;
  };

  const openSidePanel = () => {
    accordionSidePanel.current.open();
  };

  const closeSidePanel = () => {
    accordionSidePanel.current.close();
  };

  const hasActiveFilters = (category: string) => {
    const filterSelections = allFilterSelections.get(category);
    return !!Object.keys(filterSelections)
      .find((key) => filterSelections[key]);
  };

  const hasAnyActiveFilters = () => {
    const filterSelections = Array.from(allFilterSelections.keys());
    return !!filterSelections.find((category) => (hasActiveFilters(category)));
  };

  const renderFilterChips = (
    chipCategoryStyle: Record<string, any>,
    chipStyle: Record<string, any>,
    clearFiltersButton: Record<string, any>,
  ) => {
    const activeFilters = [];
    const filterKeys = Array.from(allFilterSelections.keys());
    let activeCategoryIdx = 0;

    filterKeys.forEach((category) => {
      const selections = allFilterOptions.get(category).columns;

      if (hasActiveFilters(category)) {
        if (activeCategoryIdx !== 0) {
          activeFilters.push(
            <HSpacer key={`${category}_Spacer`} size="7" />,
          );
        }

        activeFilters.push(
          <Text category="c1" key={`${category}_Text`} style={chipCategoryStyle}>
            {`${allFilterOptions.get(category).columnLabel}:`}
          </Text>,
        );
        activeCategoryIdx += 1;

        const categorySelections = allFilterSelections.get(category);

        Object.keys(categorySelections)
          .forEach((val) => {
            const selection = selections.find(({ id }) => id === val);
            if (selection && categorySelections[val]) {
              activeFilters.push(
                <Chip
                  accessoryRight={(
                    <TouchableOpacity
                      onPress={
                        () => updateSelections(val, category, false)
                      }
                      testID={`${testID}-close-${category}`}
                    >
                      <Icon name="Close" testID={`${testID}-close-icon-${category}`} />
                    </TouchableOpacity>
                  )}
                  key={category + val}
                  style={[chipStyle]}
                  testID={`${testID}-${category}`}
                >
                  <Text testID={`${testID}-${category}-label-text`}>{selection.label}</Text>
                </Chip>,
              );
            }
          });
      }
    });

    activeFilters.push(
      <View key="Clear_all">
        <Button
          appearance="ghost"
          onPress={deleteAllFilters}
          size="small"
          style={clearFiltersButton}
          testID={`${testID}-clear-all`}
        >
          {translate<string>('CLEAR_ALL')}
        </Button>
        <VSpacer size="4" />
      </View>,
    );

    return activeFilters;
  };

  const renderFilterIndicator = (filterIndicatorCircleStyle: Record<string, any>) => (
    <View style={filterIndicatorCircleStyle} />
  );

  const renderMoreFiltersButton = (filterIndicatorCircleStyle: Record<string, any>) => {
    let sideMenuFiltersSelected = false;
    allFilterSelections.forEach((selections, key) => {
      if (!defaultColumnFilters.includes(key)) {
        Object.keys(selections)
          .find((val) => {
            if (selections[val]) {
              sideMenuFiltersSelected = true;
              return true;
            }
            return false;
          });
      }
    });

    return (
      <Button
        accessoryRight={sideMenuFiltersSelected ? (
          () => renderFilterIndicator(filterIndicatorCircleStyle)) : <></>}
        appearance="ghost"
        onPress={openSidePanel}
        size="small"
        status="basic"
        testID={`${testID}-more-filters-button`}
      >
        {translate<string>('MORE_FILTERS')}
      </Button>
    );
  };

  const getComponentStyle = (source: StyleType) => {
    const mainFilterContainerStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'mainFilterContainer',
    );
    const searchContainerStyle = PropsServiceHelper.allWithPrefixMapped(source, 'searchContainer');
    const optionalButtonContainerStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'optionalButtonContainer',
    );
    const filterBarContainerStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'filterBarContainer',
    );
    const filterbarStyle = PropsServiceHelper.allWithPrefixMapped(source, 'filterbar');
    const filterByTextStyle = PropsServiceHelper.allWithPrefixMapped(source, 'filterByText');
    const displayedFiltersStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'displayedFilters',
    );
    const selectStyle = PropsServiceHelper.allWithPrefixMapped(source, 'select');
    const clearFiltersButtonStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'clearFiltersButton',
    );
    const sidePanelFooterStyle = PropsServiceHelper.allWithPrefixMapped(source, 'sidePanelFooter');
    const filterChipCategoryStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'filterChipCategory',
    );
    const chipFilterStyle = PropsServiceHelper.allWithPrefixMapped(source, 'chipFilter');
    const optionalButtonsStyle = PropsServiceHelper.allWithPrefixMapped(source, 'optionalButtons');
    const filterIndicatorCircleStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'filterIndicatorCircle',
    );
    const dropdownContainerStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'dropdownContainer',
    );
    const sideMenuAccordionTopStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'sideMenuAccordionTop',
    );
    const sideMenuAccordionBottomStyle = PropsServiceHelper.allWithPrefixMapped(
      source,
      'sideMenuAccordionBottom',
    );

    return {
      mainFilterContainer: mainFilterContainerStyle,
      searchContainer: searchContainerStyle,
      optionalButtonContainer: optionalButtonContainerStyle,
      filterBarContainer: filterBarContainerStyle,
      filterbar: filterbarStyle,
      filterByText: filterByTextStyle,
      displayedFilters: displayedFiltersStyle,
      select: selectStyle,
      clearFiltersButton: clearFiltersButtonStyle,
      sidePanelFooter: sidePanelFooterStyle,
      filterChipCategory: filterChipCategoryStyle,
      chipFilter: chipFilterStyle,
      optionalButtons: optionalButtonsStyle,
      filterIndicatorCircle: filterIndicatorCircleStyle,
      dropdownContainer: dropdownContainerStyle,
      sideMenuAccordionTop: sideMenuAccordionTopStyle,
      sideMenuAccordionBottom: sideMenuAccordionBottomStyle,
    };
  };

  const evaStyle = getComponentStyle(eva.style);
  return allFilterSelections && (
    <View
      style={{ flexDirection: 'column' }}
      testID={testID}
      {...viewProps}
    >
      {(!!accessoryRight || !noSearchBox) && (
        <View style={evaStyle.mainFilterContainer}>
          {!noSearchBox && (
            <View style={evaStyle.searchContainer}>
              <Search
                onChangeText={handleSearchChangeText}
                onClear={handleSearchClear}
                placeholder={placeHolderText}
                size="medium"
                testID={(searchBoxTestID ?? `${testID}-search`)}
                value={search}
              />
            </View>
          )}
          <View style={evaStyle.optionalButtonContainer}>
            {accessoryRight && (
              <FalsyFC
                component={accessoryRight as RenderProp<Partial<ButtonProps>>}
              />
            )}
          </View>
        </View>
      )}
      {!noFilters
        && (
          <View style={evaStyle.filterBarContainer}>
            <Text category="c1" style={evaStyle.filterByText}>
              {translate<string>('FILTER_BY')}
            </Text>
            {allFilterOptions
              && defaultColumnFilters.every((filter) => !!allFilterOptions.get(filter))
              && defaultColumnFilters.slice(0, 3).map((filterName) => (
                <MainFilterSelects
                  dropdownContainerStyle={evaStyle.dropdownContainer}
                  filterIndicatorCircleStyle={evaStyle.filterIndicatorCircle}
                  isFilterDisabled={isFilterDisabled}
                  key={filterName}
                  onUpdateSelections={(key, cat, val) => updateSelections(key, cat, val,
                    allFilterOptions.get(filterName).singleSelection)}
                  options={allFilterOptions.get(filterName)}
                  selectStyle={evaStyle.select}
                  selections={allFilterSelections.get(filterName)}
                />
              ))}
            {allFilterOptions.size > (minFiltersToShowMore || SHOW_MORE_MIN_FILTERS)
              && (showMoreFiltersButton
                || showMoreFiltersButton === undefined)
              && renderMoreFiltersButton(evaStyle.filterIndicatorCircle)}
          </View>
        )}
      {hasAnyActiveFilters()
        && (
          <View style={[evaStyle.displayedFilters]}>
            {renderFilterChips(
              evaStyle.filterChipCategory,
              evaStyle.chipFilter,
              evaStyle.clearFiltersButton,
            )}
          </View>
        )}
      <View
        style={[
          styles.totalResultRow,
          !noFilters && { marginTop: 32 }, { marginBottom: 8 },
        ]}
      >
        <View>
          {totalResults !== undefined
            && (
              <View
                testID={`${testID}-results-count`}
              >
                <Text category="p2">
                  {totalResultsText || translate<string>('RESULT_COUNT', { count: totalResults })}
                </Text>
              </View>
            )}
        </View>
        <View style={{ flexDirection: 'row' }}>
          {showSelectAll && onSelectAll && (
            <CheckBox
              checked={isSelectedAll}
              onChange={() => {
                setIsSelectedAll(!isSelectAll);
                onSelectAll();
              }}
              testID={`${testID}-select-all`}
            >
              {translate<string>('FILTER_SELECT_ALL')}
            </CheckBox>
          )}
          {showSelectAll && showExpandAllOption && <HSpacer size="8" />}
          {
            showExpandAllOption && onExpand && (
              <TextLink
                accessoryRight={(props) => (
                  <Icon
                    {...props}
                    name={expanded ? 'ChevronUp' : 'ChevronDown'}
                    testID={`${testID}-expand-collapse-icon`}
                  />
                )}
                appearance={expandAllAppearance || 'secondary'}
                category="p2"
                onPress={() => {
                  setExpanded(!expanded);
                  onExpand(!expanded);
                }}
                testID={`${testID}-expand-collapse-link`}
              >
                {translate<string>(expanded ? 'COLLAPSE_ALL' : 'EXPAND_ALL')}
              </TextLink>
            )
          }
        </View>
      </View>
      {!noFilters && (
        <SidePanel
          header={translate<string>('FILTER_COUNT', { count: sidePanelFilterCount })}
          onClose={() => { }}
          ref={accordionSidePanel}
          testID="filter-sidepanel"
        >
          <VSpacer size="9" />
          <ScrollView>
            {renderSidePanelFilterItems(
              evaStyle.sideMenuAccordionTop,
              evaStyle.sideMenuAccordionBottom,
            )}
          </ScrollView>
          <View style={evaStyle.sidePanelFooter}>
            <Button
              appearance="outline"
              onPress={deleteAllFilters}
              status="basic"
              testID={`${testID}-clear-all-filters-button`}
            >
              {translate<string>('CLEAR_ALL')}
            </Button>
            <HSpacer size="5" />
            <Button
              onPress={closeSidePanel}
              status="primary"
              testID={`${testID}-show-results-button`}
            >
              {translate<string>('SHOW_RESULTS')}
            </Button>
          </View>
        </SidePanel>
      )}
    </View>
  );
};

export const Filter: FC<FilterProps<any>> = styled('Filter')(FilterRaw);
