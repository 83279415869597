import { Icon, ReorderingList, VSpacer } from '@design';
import { ApiFormField } from '@shared/interfaces/api';
import React, { useCallback, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { PropertyMenuItem } from './PropertyMenuItem';

interface PropertiesListProps {
  propertyList?: ApiFormField[],
  selectedProperty: ApiFormField,
  setProperties: React.Dispatch<React.SetStateAction<ApiFormField[]>>,
  setSelectedProperty: React.Dispatch<React.SetStateAction<ApiFormField>>,
}

export const PropertiesList = ({
  propertyList = [],
  selectedProperty,
  setProperties,
  setSelectedProperty,
}: PropertiesListProps) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [cursor, setCursor] = useState('move');
  
  const dragHandle = (index) => {
    return (
      <Icon
        cursor={cursor}
        name="GrowersDragHandle"
        style={{ height: 24, width: 24 }}
        testID={`drag-handle-${index}`}
      />
    );
  };
  
  const onDragEnd = useCallback(() => {
    setScrollEnabled(true);
    setCursor('move');
  }, []);
  
  const moveArrayElement = (fromIndex: number, toIndex: number) => {
    const element = propertyList[fromIndex];
    propertyList.splice(fromIndex, 1);
    propertyList.splice(toIndex, 0, element);
    setProperties([...propertyList]);
  };
  
  const reOrderArrayElements = (array: ApiFormField[]) => {
    return array.map((property, index) => {
      property.order = index;
      return property;
    });
  };

  const reOrderProperties = () => {
    const reOrderedElements = reOrderArrayElements(propertyList);
    setProperties(reOrderedElements);
  };

  const deleteField = (index: number) => {
    const propList = [...propertyList];
    propList.splice(index, 1);
    const reOrderedElements = reOrderArrayElements(propList);
    setProperties(reOrderedElements);
    if (reOrderedElements.length > 0) setSelectedProperty(reOrderedElements[0]);
    else setSelectedProperty(null);
  };
  
  return (
    <ScrollView
      scrollEnabled={scrollEnabled}
      testID="property-list-scrolling-wrapper"
    >
      <ReorderingList
        handle={(index) => dragHandle(index)}
        hasBottomBorder={false}
        items={propertyList}
        keyExtractor={(component) => component.id}
        onDragEnd={onDragEnd}
        onDragStart={() => setCursor('ns-resize')}
        onOrder={(properties) => {
          const reOrderedForms = properties.map((property, index) => {
            property.order = index;
            return property;
          });
          setProperties(reOrderedForms);
        }}
        renderItem={(field, index) => (
          <View 
            key={`index-${field.id}`}
            style={{ flex: 1 }}
          >
            <PropertyMenuItem
              field={field}
              isOnlyProperty={propertyList.length === 1}
              isSelected={selectedProperty && (selectedProperty.order === field.order)}
              onChangeOrder={(newIndex) => {
                if (!newIndex) return;
                moveArrayElement(index, newIndex - 1);
                reOrderProperties();
              }}
              onDelete={() => deleteField(field.order)}
              onPress={() => setSelectedProperty(field)}
            />
            <VSpacer size='3'/>
          </View>
        )}
      />
    </ScrollView>
  );
};
  