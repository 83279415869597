import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { VSpacer, HSpacer } from '@design';
import { OrderSummary } from './OrderSummary';
import { ProductList } from './ProductList';
import { BasicInformationHeader } from './BasicInformationHeader';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 36,
  },
  row: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 2,
  },
  rightContainer: {
    flex: 1,
  },
});

export interface DeliverableProductOrderProps {
  deliverable?: ProductOrderDeliverableV0.DeliverablePublic,
}
export const DeliverableProductOrder: FC<DeliverableProductOrderProps> = ({
  deliverable,
}) => {
  if (!deliverable) {
    return null;
  }

  const { costSummary } = deliverable.selections;
  const showOrderSummary = costSummary.discounts
    || costSummary.retailPrice
    || costSummary.totalCost;

  return (
    <View style={styles.container}>
      <BasicInformationHeader deliverable={deliverable} />
      <VSpacer size="8" />
      <View style={styles.row}>
        <View style={styles.leftContainer}>
          <ProductList deliverable={deliverable} />
        </View>
        {showOrderSummary && (
          <>
            <HSpacer size="7" />
            <View style={styles.rightContainer}>
              <OrderSummary deliverable={deliverable} />
            </View>
          </>
        )}
      </View>
    </View>
  );
};
