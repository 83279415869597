import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { CheckBox, HSpacer } from '@design';

const styles = StyleSheet.create({
  checkBox: {
    marginTop: 2,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 20,
    alignItems: 'flex-start',
    minHeight: 'auto',
  },
  children: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

interface ComponentSelectPanelItemProps {
  selected: boolean;
  onSelect: () => void;
  children: React.ReactNode
}

export const ComponentSelectPanelItem = ({
  selected,
  onSelect,
  children,
}: ComponentSelectPanelItemProps) => (
  <TouchableOpacity onPress={onSelect} style={styles.container}>
    <CheckBox
      checked={selected}
      onChange={onSelect}
      style={styles.checkBox}
      testID="select-component-checkbox"
    />
    <HSpacer size="4" />
    <View style={styles.children}>
      {children}
    </View>
  </TouchableOpacity>
);
