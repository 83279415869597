export default {
  ACTIVE: 'Active',
  ASSIGN_LOCATION: 'Assign location',
  BUSINESS_ADMIN: 'Business Admin',
  BUSINESS_ASSIGNMENT: 'Business assignment',
  BUSINESS_STANDARD: 'Business Standard',
  CANCEL: 'Cancel',
  CREATE_INTERNAL_USER: 'Create Internal User',
  CREATE_USER: 'Create User',
  EDIT_USER: 'Edit User',
  EMAIL: 'Email',
  EMAIL_ALREADY_EXISTS: 'The email address is used by another user',
  EMAIL_VERIFICATION_SENT: 'A verification email was sent to the new email address.',
  ERROR_CREATING_USER: 'Error creating user',
  ERROR_UPDATING_USER: 'Error updating user',
  FILTERS: 'Filters',
  FIRST_NAME: 'First Name',
  INACTIVE: 'Inactive',
  LAST_NAME: 'Last Name',
  LOCATION_ASSIGNMENT: 'Location Assignment',
  LOCATION_NAME: 'Location Name',
  NAME: 'Name',
  NO_BUSINESS_TO_ASSIGN: 'No business to assign to',
  NO_USERS: 'There are no available users',
  PHONE: 'Phone',
  SEARCH: 'Search',
  SELECT_USER_ROLE: 'Select a user role',
  SELECT_USER_TYPE: 'Select a user type',
  STATUS: 'Status',
  UNEXPECTED_ERROR: 'An unexpected error occurred.',
  UPLOAD_USERS_CSV: 'Upload CSV file with Users using ',
  USERS: 'Users',
  USER_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this user?',
  USER_CREATED: 'User Created',
  USER_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this user?',
  USER_DELETE_MODAL_WARNING: 'Are you sure you want to permanently this user? This action cannot be undone.',
  USER_DETAILS: 'User Details',
  USER_LOAD_ERROR: 'There was an error while loading the business users',
  USER_ROLE: 'User Role',
  USER_TYPE: 'User Type',
  USER_UPDATED: 'User Updated',
};
