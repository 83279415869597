import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const AreaField = (props: SvgProps) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <Path clipRule="evenodd" d="M22.2857 3.71429L1.71429 3.71428L1.71428 20.2857H22.2857V3.71429ZM1.71429 2C0.767512 2 0 2.76751 0 3.71428V20.2857C0 21.2325 0.767511 22 1.71428 22H22.2857C23.2325 22 24 21.2325 24 20.2857V3.71429C24 2.76751 23.2325 2 22.2857 2H1.71429Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M18.1807 6.30352C15.7076 8.12047 12.7152 10.4619 10.589 12.5881C8.9065 14.2706 7.31861 16.491 6.14576 18.3036C5.56089 19.2075 5.08233 20.0051 4.75019 20.5763C4.58417 20.8619 4.45485 21.0907 4.36724 21.2478C4.32344 21.3263 4.29008 21.3868 4.26778 21.4275L4.24271 21.4735L4.23655 21.4848L4.23509 21.4875C4.23509 21.4875 4.23471 21.4882 3.85737 21.285C3.48003 21.0819 3.48006 21.0818 3.4801 21.0817L3.48257 21.0772L3.48945 21.0644L3.51602 21.0158C3.53934 20.9732 3.57378 20.9107 3.61871 20.8301C3.70856 20.6691 3.84039 20.4359 4.00919 20.1455C4.3467 19.565 4.83242 18.7555 5.42613 17.8379C6.61041 16.0077 8.23681 13.7281 9.9829 11.982C12.157 9.80786 15.1929 7.43503 17.6732 5.61277C18.9156 4.69991 20.0232 3.92198 20.8202 3.37218C21.2187 3.09724 21.5398 2.87925 21.7614 2.72985C21.8722 2.65515 21.9582 2.59759 22.0166 2.55863L22.0832 2.51431L22.1058 2.49927C22.1059 2.49921 22.1063 2.49897 22.3427 2.85647L22.579 3.214L22.5574 3.22832L22.4924 3.2716C22.4351 3.30986 22.3502 3.36668 22.2405 3.4406C22.0212 3.58845 21.7027 3.8047 21.3069 4.07773C20.5152 4.62386 19.4148 5.39678 18.1807 6.30352Z" fillRule="evenodd" />
    <Path d="M10.7143 17.8571C10.7143 18.3305 10.3305 18.7143 9.85714 18.7143C9.38376 18.7143 9 18.3305 9 17.8571C9 17.3838 9.38376 17 9.85714 17C10.3305 17 10.7143 17.3838 10.7143 17.8571Z" />
    <Path d="M14.7143 13.8571C14.7143 14.3305 14.3305 14.7143 13.8571 14.7143C13.3838 14.7143 13 14.3305 13 13.8571C13 13.3838 13.3838 13 13.8571 13C14.3305 13 14.7143 13.3838 14.7143 13.8571Z" />
    <Path d="M20.5717 8.85714C20.5717 9.33053 20.188 9.71429 19.7146 9.71429C19.2412 9.71429 18.8574 9.33053 18.8574 8.85714C18.8574 8.38376 19.2412 8 19.7146 8C20.188 8 20.5717 8.38376 20.5717 8.85714Z" />
    <Path d="M20.5717 14.0007C20.5717 14.4741 20.188 14.8578 19.7146 14.8578C19.2412 14.8578 18.8574 14.4741 18.8574 14.0007C18.8574 13.5273 19.2412 13.1436 19.7146 13.1436C20.188 13.1436 20.5717 13.5273 20.5717 14.0007Z" />
    <Path d="M18.8569 17.4284C18.8569 17.9018 18.4731 18.2856 17.9997 18.2856C17.5263 18.2856 17.1426 17.9018 17.1426 17.4284C17.1426 16.955 17.5263 16.5713 17.9997 16.5713C18.4731 16.5713 18.8569 16.955 18.8569 17.4284Z" />
    <Path clipRule="evenodd" d="M4.28516 5.85655C4.28516 5.61986 4.47703 5.42798 4.71373 5.42798H6.42801C6.66471 5.42798 6.85658 5.61986 6.85658 5.85655C6.85658 6.09324 6.66471 6.28512 6.42801 6.28512H4.71373C4.47703 6.28512 4.28516 6.09324 4.28516 5.85655Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M12 5.0001C12 4.76341 12.1919 4.57153 12.4286 4.57153L14.1429 4.57153C14.3796 4.57153 14.5714 4.76341 14.5714 5.0001C14.5714 5.2368 14.3796 5.42868 14.1429 5.42868L12.4286 5.42868C12.1919 5.42868 12 5.2368 12 5.0001Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M8.57227 8.42784C8.57227 8.19115 8.76414 7.99927 9.00084 7.99927L10.7151 7.99927C10.9518 7.99927 11.1437 8.19115 11.1437 8.42784C11.1437 8.66453 10.9518 8.85641 10.7151 8.85641L9.00084 8.85641C8.76414 8.85641 8.57227 8.66453 8.57227 8.42784Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M4.28516 10.8565C4.28516 10.6199 4.47703 10.428 4.71373 10.428L6.42801 10.428C6.66471 10.428 6.85658 10.6199 6.85658 10.8566C6.85658 11.0932 6.66471 11.2851 6.42801 11.2851L4.71373 11.2851C4.47703 11.2851 4.28516 11.0932 4.28516 10.8565Z" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M2.57227 15.2862C2.57227 15.0495 2.76414 14.8577 3.00084 14.8577H4.71512C4.95182 14.8577 5.14369 15.0495 5.14369 15.2862C5.14369 15.5229 4.95182 15.7148 4.71512 15.7148H3.00084C2.76414 15.7148 2.57227 15.5229 2.57227 15.2862Z" fillRule="evenodd" />
  </Svg>
);
