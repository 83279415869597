import {
  ApiDeliverable,
  ApiDeliverablePublic,
} from '@shared/interfaces/api';
import { DeliverableRenderer } from '../DeliverableRenderer';
import { TankMixDeliverableV0PublicRender } from './v0/TankMixDeliverableV0PublicRender';
import { TankMixDeliverableV0Render } from './v0/TankMixDeliverableV0Render';
import { TankMixDeliverableV1Render } from './v1/TankMixDeliverableV1Render';
import { TankMixDeliverableV1PublicRender } from './v1/TankMixDeliverableV1PublicRender';

export class TankMixDeliverableRenderFactory {
  static getRenderer = (deliverable: ApiDeliverable): DeliverableRenderer => {
    switch (deliverable.deliverableVersion) {
      case 0:
        return new TankMixDeliverableV0Render(deliverable);
      case 1:
        return new TankMixDeliverableV1Render(deliverable);
      default:
        throw new Error(`Renderer not found for ${deliverable.deliverableType} version ${deliverable.deliverableVersion}`);
    }
  };

  static getPublicRenderer = (deliverable: ApiDeliverablePublic) : DeliverableRenderer => {
    switch (deliverable.deliverableVersion) {
      case 0:
        return new TankMixDeliverableV0PublicRender(deliverable);
      case 1:
        return new TankMixDeliverableV1PublicRender(deliverable);

      default:
        throw new Error(`Renderer not found for ${deliverable.deliverableType} version ${deliverable.deliverableVersion}`);
    }
  };
}
