import React, { useState, useRef, useCallback, ReactElement, PropsWithChildren, useEffect } from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  PanResponder,
  Animated,
  ListRenderItem,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { Icon } from '@design';
import { useStyleSheet } from '@ui-kitten/components';
import { ThemeColors, Sizing } from '../../../constants';

const SCROLL_THRESHOLD = 50;
const SCROLL_SPEED = 20;
const HandleSize = 1.5 * Sizing.EM;
const HandleMargin = Sizing.HALF_SPACING;
export const HandleSpace = HandleSize + HandleMargin;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  handle: {
    alignSelf: 'center',
    width: HandleSize,
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: HandleMargin,
  },
  icon: {
    width: HandleSize,
    height: HandleSize,
    paddingLeft: 16,
  },
});

// https://www.youtube.com/watch?v=tsM3N_7bNcE

function immutableMove<T> (arr: T[], from: number, to: number) {
  return arr.reduce((prev, current, idx, self) => {
    if (from === to) {
      prev.push(current);
    }
    if (idx === from) {
      return prev;
    }
    if (from < to) {
      prev.push(current);
    }
    if (idx === to) {
      prev.push(self[from]);
    }
    if (from > to) {
      prev.push(current);
    }
    return prev;
  }, [] as T[]);
}

interface ReorderListProps<T> {
  items: T[],
  renderItem: (item: T, index: number) => ReactElement,
  onOrder?: (items: T[]) => void,
  keyExtractor: (item: T) => string,
  onDragStart?: () => void,
  onDragEnd?: () => void,
  rowStyle?: StyleProp<ViewStyle>,
  hasBottomBorder?: boolean,
}

function ReorderList<T> (props: PropsWithChildren<ReorderListProps<T>>) {
  const {
    items,
    renderItem,
    onOrder,
    keyExtractor,
    rowStyle,
    hasBottomBorder,
  } = props;

  const lineStyle = useStyleSheet({
    bottomBorder: {
      borderBottomColor: 'color-basic-control-transparent-100',
      borderBottomWidth: 0.5,
    },
  });
  const [dragging, setDragging] = useState<boolean>(false);
  // closure buster for non-hooky functions
  const draggingRef = useRef(dragging);
  draggingRef.current = dragging;

  // distance between top of item and where the mouse was when drag started
  // should be negative
  const [dragOffset, setDragOffset] = useState(0);
  // closure buster for non-hooky functions
  const dragOffsetRef = useRef(dragOffset);
  dragOffsetRef.current = dragOffset;

  // the index of the element in internalItems that is being dragged
  // this changes as you drag and reorder
  const [dragIndex, setDragIndex] = useState(-1);

  // y value of top of item being dragged, relative to top of list viewport
  const point = useRef(new Animated.ValueXY());

  const containerRef = useRef<View>();
  const flatList = useRef<FlatList<T>>();

  // index of item mouse is currently over
  const currentIndexRef = useRef(0);
  // y coordinate of mouse, relative to top of list viewport
  const currentYRef = useRef(0);

  // the y coordinate of the top of the list viewport on the page
  const containerTopRef = useRef(0);
  // the height of the list viewport
  const containerHeightRef = useRef(0);
  // the current scroll value of the list
  const listScrollRef = useRef(0);

  const itemRefs = useRef<View[]>([]);
  // an array of item heights in the same order as internalItems
  // allows for variable height items and proper index calculating
  const rowHeightRef = useRef([]);

  // function to reset all internal state when dragging stops
  const reset = useCallback(() => {
    setDragging(false);
    // update current immediately for next animation frame
    draggingRef.current = false;
    setDragIndex(-1);
  }, []);
  // closure buster for non-hooky functions
  const resetRef = useRef(reset);
  resetRef.current = reset;

  // internal copy of items, modified while dragging
  const [internalItems, setInternalItems] = useState([...items]);
  const internalItemsRef = useRef(internalItems);
  internalItemsRef.current = internalItems;
  // semi controlled by consumer
  useEffect(() => {
    reset();
    setInternalItems([...items]);
  }, [items, reset]);

  // closure buster for non-hooky functions
  const onOrderRef = useRef(onOrder);
  onOrderRef.current = onOrder;

  // give a y coordinate relative to viewport
  // find the index of the item that y coordinate is within
  const yToIndex = useCallback((y: number) => {
    let index = -1;
    let height = 0;
    // y relative to top of list (potentially outside viewport)
    const listY = listScrollRef.current + y;
    // add item height until we pass listY value
    while (height < listY && index < internalItemsRef.current.length) {
      index += 1;
      height += rowHeightRef.current[index];
    }

    // lower bound
    if (index < 0) {
      return 0;
    }

    // upper bound
    if (index > internalItemsRef.current.length - 1) {
      return internalItemsRef.current.length - 1;
    }

    return index;
  }, []);

  // give an item index, find the y coordinate of the top of that item
  const indexToY = useCallback((index: number) => {
    let height = 0;
    for (let i = 0; i < index; i += 1) {
      height += rowHeightRef.current[i];
    }
    return height - listScrollRef.current;
  }, []);

  const animateList = useCallback(() => {
    requestAnimationFrame(() => {
      if (!draggingRef.current) {
        return;
      }

      // scroll the container if mouse near top or bottom
      if ((currentYRef.current + SCROLL_THRESHOLD) > containerHeightRef.current) {
        flatList.current.scrollToOffset({
          offset: listScrollRef.current + SCROLL_SPEED,
          animated: false,
        });
      } else if ((currentYRef.current - SCROLL_THRESHOLD) < 0) {
        flatList.current.scrollToOffset({
          offset: listScrollRef.current - SCROLL_SPEED,
          animated: false,
        });
      }

      // check y value see if we need to reorder
      const newIndex = yToIndex(currentYRef.current);
      if (currentIndexRef.current !== newIndex) {
        // reorder items
        setInternalItems((
          immutableMove(internalItemsRef.current, currentIndexRef.current, newIndex)
        ));
        // reorder row heights
        rowHeightRef.current = (
          immutableMove(rowHeightRef.current, currentIndexRef.current, newIndex)
        );
        setDragIndex(newIndex);
        currentIndexRef.current = newIndex;
      }

      animateList();
    });
  }, [yToIndex]);

  const panResponder = useRef(
    PanResponder.create({
      // parameters to all these functions are evt, gestureState
      // Ask to be the responder:
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,

      onPanResponderGrant: (evt, gestureState) => {
        // The gesture has started. Show visual feedback so the user knows
        // what is happening!
        // gestureState.d{x,y} will be set to zero now

        // prevent selection on web
        evt.preventDefault();

        const promises = [];

        // calculate container and row heights at beginning of drag so that we don't have
        // to keep it all up to date while the user scrolls and interacts with the page
        promises.push(new Promise<void>((resolve) => (
          containerRef.current.measure((x, y, width, height, pageX, pageY) => {
            containerTopRef.current = pageY;
            containerHeightRef.current = height;
            resolve();
          })
        )));

        itemRefs.current.forEach((ir, index) => promises.push(new Promise<void>((resolve) => (
          ir.measure((x, y, width, height) => {
            rowHeightRef.current[index] = height;
            resolve();
          })
        ))));

        Promise.all(promises).then(() => {
          // gestureState.y0 is relative to page viewport
          // startMouseY relative to list viewport
          const startMouseY = gestureState.y0 - containerTopRef.current;

          const newCurrentIndex = yToIndex(startMouseY);
          const topOfIndex = indexToY(newCurrentIndex);
          const mouseOffset = topOfIndex - startMouseY;
          setDragOffset(mouseOffset);

          currentIndexRef.current = newCurrentIndex;
          currentYRef.current = startMouseY;

          Animated.event([{ y: point.current.y }], { useNativeDriver: false })({
            y: startMouseY + mouseOffset,
          });
          setDragging(true);
          draggingRef.current = true;
          setDragIndex(newCurrentIndex);
          if (props.onDragStart) {
            props.onDragStart();
          }
          animateList();
        });
      },
      onPanResponderMove: (evt, gestureState) => {
        // gestureState.moveY is relative to page viewport.
        // nextMouseY relative to top of list viewport
        const nextMouseY = gestureState.moveY - containerTopRef.current;
        currentYRef.current = nextMouseY;
        // point is y coordinate of top of dragging item
        Animated.event([{ y: point.current.y }], { useNativeDriver: false })({
          y: (
            nextMouseY + dragOffsetRef.current
          ),
        });
      },
      onPanResponderTerminationRequest: () => false,
      onPanResponderRelease: () => {
        // The user has released all touches while this view is the
        // responder. This typically means a gesture has succeeded
        resetRef.current();
        if (props.onDragEnd) {
          props.onDragEnd();
        }
        onOrderRef.current(internalItemsRef.current);
      },
      onPanResponderTerminate: () => {
        // Another component has become the responder, so this gesture
        // should be cancelled
        resetRef.current();
        if (props.onDragEnd) {
          props.onDragEnd();
        }
        onOrderRef.current(internalItemsRef.current);
      },
      // Returns whether this component should block native components from becoming the JS
      // responder. Returns true by default. Is currently only supported on android.
      onShouldBlockNativeResponder: () => true,
    }),
  );

  const internalRenderItem: ListRenderItem<T> = ({ item, index }) => {
    if (!item) {
      return null;
    }

    let opacity = 1;
    if (dragIndex === index) {
      opacity = 0;
    }
    if (index < 0) {
      opacity = 0.5;
    }

    return (
      <View
        ref={(viewRef) => { if (index >= 0) { itemRefs.current[index] = viewRef; } }}
        style={[
          styles.row,
          {
            opacity,
          },
          hasBottomBorder && lineStyle.bottomBorder,
          rowStyle,
        ]}
      >
        {(!!onOrder) && (
          <View
            {...(index < 0 ? {} : panResponder.current.panHandlers)}
            style={styles.handle}
          >
            <Icon fill={ThemeColors.TEXT} name="GrowersDragHandle" style={styles.icon} testID={`drag-handle-${index}`} />
          </View>
        )}
        {renderItem(item, index)}
      </View>
    );
  };

  return (
    <View
      ref={containerRef}
      style={styles.container}
    >
      {dragging && (
        <Animated.View
          style={{
            position: 'absolute',
            zIndex: 2,
            top: point.current.getLayout().top,
            width: '100%',
          }}
        >
          {internalRenderItem({
            item: internalItemsRef.current[dragIndex],
            index: -1,
            separators: null,
          })}
        </Animated.View>
      )}
      <FlatList<T>
        data={internalItemsRef.current}
        keyExtractor={keyExtractor}
        onScroll={(e) => { listScrollRef.current = e.nativeEvent.contentOffset.y; }}
        ref={flatList}
        renderItem={internalRenderItem}
        scrollEnabled={!dragging}
        scrollEventThrottle={16}
        style={{ width: '100%', position: 'relative' }}
      />
    </View>
  );
}
export { ReorderList };
