import { Button, Header, HSpacer, Icon, MenuItem, OverflowMenu, TextLink, useTheme } from '@design';
import { Permissions, RoleUtility } from '@shared/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ApiUserAccount } from '@shared/interfaces/api';
import { RallyLogoHeader } from '../../svg/RallyLogoHeader';
import { Routes } from '../../constants';
import { useAppContext } from '../../contexts/AppContext';
import { ScreenSizes, useScreenSizing } from '../../hooks/useScreenSizing';
import { Environment } from '../../utilities';
import { DeliverableLogo } from './DeliverableLogo';

const environment = Environment.get('ENVIRONMENT');

const styles = StyleSheet.create({
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface GlobalHeaderProps {
  logout?: () => Promise<void>,
  user?: ApiUserAccount,
}

export const GlobalHeader = ({ logout, user }: GlobalHeaderProps) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const history = useHistory();
  const historyState: any = history.location?.state;
  const { theme, toggleTheme } = useTheme();
  const { setModalProps } = useAppContext();
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const sizeSetting = useScreenSizing();
  const isDesktop = sizeSetting === ScreenSizes.Wide;
  const isAdmin = RoleUtility.roleHasPermission(user?.userRole, Permissions.ACCESS_ALL_BUSINESSES);

  const logoutUser = useCallback(async () => {
    await logout();
    history.push(Routes.LOGIN);
  }, [logout, history]);

  const gotoAccount = useCallback(() => setModalProps({
    type: 'userFormModal',
    userId: user?.id,
    actionType: 'EDIT_USER',
  }), [setModalProps, user]);

  useEffect(() => {
    if (historyState?.visibleModal) {
      gotoAccount();
    }
  }, [gotoAccount, historyState]);

  const isSuperAdmin = RoleUtility.roleHasPermission(
    user?.userRole,
    Permissions.ACCESS_ALL_BUSINESSES,
  );

  const menuItems = [
    {
      title: translate('ACCOUNT'),
      action: gotoAccount,
    },
    {
      title: translate('LOG_OUT'),
      action: logoutUser,
    },
    (isAdmin && {
      title: translate('VERSION_INFO'),
      action: () => history.push(Routes.VERSION),
    }),
    (isAdmin && user?.fullName.endsWith('UX') && {
      title: `Switch to ${theme === 'dark' ? 'light' : 'dark'} mode.`,
      action: toggleTheme,
    }),
    (isAdmin && {
      title: 'Feature Flagging',
      action: () => history.push(Routes.FEATURE_FLAGS),
    }),
  ].filter(Boolean);

  const Spacer = () => (
    <>
      <HSpacer size="5" />
      {isDesktop && <HSpacer size="6" />}
    </>
  );

  const deliverableMatch = useRouteMatch(Routes.GROWER_DELIVERABLE);
  const helpMatch = useRouteMatch(Routes.PUBLIC_HELP);
  const location = useLocation();
  const isDeliverablePage = useMemo(() => {
    return deliverableMatch?.url === location.pathname;
  }, [deliverableMatch, location]);

  const isPublicHelpPage = useMemo(() => {
    return helpMatch?.url === location.pathname;
  }, [helpMatch, location.pathname]);

  return (
    <Header
      leftAccessory={(paddingTop) => (
        <View style={[styles.title, { paddingTop }]}>
          <Spacer />
          {isDeliverablePage && (
            <>
              <DeliverableLogo />
              <Spacer />
            </>
          )}
          {isPublicHelpPage && (
            <>
              <RallyLogoHeader />
              <Spacer />
            </>
          )}
        </View>
      )}
      level={isDesktop ? 'globalDesktop' : 'globalMobile'}
      testID="global-header"
      title={environment !== 'prod' ? `${environment}` : ''}
    >
      {isPublicHelpPage && (
        <Button
          onPress={() => history.push(Routes.LOGIN)}
          size="small"
          testID="help-login"
        >
            {translate<string>('SIGN_IN')}
        </Button>
      )}
      {!isSuperAdmin && user && (
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Flash" testID="create-product-order-icon" />}
          onPress={() => setModalProps({ type: 'productOrder' })}
          size={isDesktop ? 'small' : 'tiny'}
          testID="create-product-order"
        >
          {translate<string>('CREATE_PRODUCT_ORDER')}
        </Button>
      )}
      {user && (
        <>
          <HSpacer size={isDesktop ? '7' : '3'} />
          <OverflowMenu
            anchor={() => (
              <TextLink
                category="p2"
                onPress={() => setMenuVisible(true)}
                testID="header-username-button"
              >
                {user?.fullName}
              </TextLink>
            )}
            onBackdropPress={() => setMenuVisible(false)}
            testID="global-header-overflow-menu"
            visible={menuVisible}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={`MenuItem-${item.title}`}
                onPress={() => {
                  item.action();
                  setMenuVisible(false);
                }}
                testID={item.title}
                title={item.title}
                {...item}
              />
            ))}
          </OverflowMenu>
        </>
      )}
      <Spacer />
    </Header>
  );
};
