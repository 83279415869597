import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DataPoint, IconButton, ViewRow, Text, VSpacer } from '@design';
import { PlannedArea } from '@shared/interfaces';
import { useTranslation } from 'react-i18next';
import { CalculationParametersUtility } from '@shared/utils';
import { ApiPlanningParameter } from '@shared/interfaces/api';
import { ThemeColors, Sizing } from '../../../constants';
import { StringUtility } from '../../../utilities';

interface ProgramCardProps {
  area: PlannedArea,
  areaType?: 'cropZone' | 'field',
  indexes: number[],
  onEditProgram: () => void,
  planningParameters: ApiPlanningParameter[],
  onRemoveProgram: (areaId: string) => void,
  testID: string,
}

const styles = StyleSheet.create({
  bar: {
    height: '100%',
    width: 4,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataPoint: {
    width: '25%',
  },
  information: {
    paddingHorizontal: Sizing.BASE_SPACING,
    paddingBottom: Sizing.BASE_SPACING,
    flex: 1,
    backgroundColor: ThemeColors.DARKEST_GRAY,
  },
});

export const ProgramCard = ({
  area,
  areaType = 'cropZone',
  indexes,
  onEditProgram,
  onRemoveProgram,
  planningParameters,
  testID,
}: ProgramCardProps) => {
  const { cropLogic, id } = area;

  const [translate] = useTranslation(['farmPlans', 'growers', 'common', 'prepare']);

  const matchingParams = CalculationParametersUtility.getPlanningParameters(
    planningParameters,
    cropLogic,
    areaType,
    area,
  );

  return (
    <ViewRow testID={testID}>
      <View style={[
        styles.bar,
        { backgroundColor: cropLogic.crop?.color || ThemeColors.PRIMARY },
      ]}
      />
      <View style={styles.information}>
        <ViewRow style={styles.header}>
          <View>
            <Text category="h6" testID="program-name">{cropLogic.logicName}</Text>
          </View>
          <ViewRow>
            <IconButton
              appearance="ghost"
              onPress={onEditProgram}
              size="medium"
              status="basic"
              testID={`farm-card-edit-icon-${indexes.join('-')}`}
            >
              Edit
            </IconButton>
            <IconButton
              appearance="ghost"
              onPress={() => onRemoveProgram(id)}
              size="medium"
              status="basic"
              testID={`farm-card-delete-icon-${indexes.join('-')}`}
            >
              Trash
            </IconButton>
          </ViewRow>
        </ViewRow>
        <VSpacer size="2" />
        <ViewRow>
          <DataPoint
            flex
            label={translate<string>('CROP')}
            style={styles.dataPoint}
            testID="crop"
          >
            {`${translate(cropLogic.cropType)} - ${translate(cropLogic.cropSubType)}`}
          </DataPoint>
          <DataPoint
            flex
            label={translate<string>('PASSES')}
            style={styles.dataPoint}
            testID="passes"
          >
            {cropLogic.passes?.length}
          </DataPoint>
          <DataPoint
            flex
            label={translate<string>('TOTAL_COST_AC')}
            style={styles.dataPoint}
            testID="total-cost"
          >
            {StringUtility.formatCurrencyAccounting(cropLogic.costPerArea || 0)}
          </DataPoint>
          <DataPoint
            flex
            label={translate<string>('YIELD_GOAL')}
            style={styles.dataPoint}
            testID="yield-goal"
          >
            {`${StringUtility.formatDecimal(
              matchingParams.yieldGoal,
              2,
            )} ${translate('UOM_PER_ACRE_PARSED', {
              UoM: translate(matchingParams.yieldGoalUom || cropLogic.crop?.priceUom),
            })}`}
          </DataPoint>
        </ViewRow>
      </View>
    </ViewRow>
  );
};
