export default {
  ADD_INTEGRATION: 'Add Integration',
  DELETE_INTEGRATION: 'Delete Integration',
  DELETE_INTEGRATION_CONFIRM_MESSAGE: 'Are you sure you want to delete this integration?',
  EDIT_INTEGRATION: 'Edit Integration',
  EXTERNAL_ID: 'External ID',
  EXTERNAL_IDS_MODAL_TITLE: 'External IDs',
  ORDER: 'Order',
  QUOTE: 'Quote',
  VIEW_IDS: 'View IDs',
};
