import {
  Button,
  Card,
  HSpacer,
  Icon,
  IconButton,
  NumericInput,
  Text,
  VSpacer,
} from '@design';
import { ScreenSizes, useScreenSizing } from '../../../../hooks/useScreenSizing';
import React, { ReactNode } from 'react';
import { TouchableOpacity, View, ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ApiForm } from '@shared/interfaces/api';
interface PropertyGroupCardProps extends ViewProps {
  createdByName: string,
  form: ApiForm,
  leftAccessory?: ReactNode,
  onChangeOrder: (order: number) => void,
  onDelete?: () => void,
  onEdit?: () => void,
  onPress?: (form: ApiForm) => void,
  readOnly?: boolean,
}

const inputHeight = 48;
const inputWidth = 72;

// padding must be specified individually to override mapping file
const styles = StyleSheet.create({
  paddingDesktop: {
    paddingBottom: 24,
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  paddingMobile: {
    paddingBottom: 12,
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
});

export const PropertyGroupCard = ({
  createdByName,
  leftAccessory,
  form,
  onChangeOrder,
  onDelete,
  onEdit,
  onPress,
  readOnly = false,
  ...viewProps
}: PropertyGroupCardProps) => {
  const [translate] = useTranslation(['common']);
  const screenSize = useScreenSizing();
  const isDesktop = screenSize === ScreenSizes.Wide;
  const hSpacerSize = isDesktop ? '7' : '5';

  const { description, name, order } = form;

  const sublineRow = (
    <View style={{ flexDirection: isDesktop ? 'row' : 'column', flexWrap: 'wrap' }}>
      <Text appearance="hint" category="p2" testID="property-group-card-created-by">
        {`${description}`}
      </Text>
    </View>
  );

  return (
    <Card
      style={isDesktop ? styles.paddingDesktop : styles.paddingMobile}
      testID="property-group-card"
      {...viewProps}
    >
      <TouchableOpacity
        onPress={() => {
          if (onPress) {
            onPress(form);
          }
        }}
      >
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View>
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              {leftAccessory && (
                <>
                  {leftAccessory}
                  <HSpacer size={hSpacerSize}/>
                </>
              )}
              <NumericInput
                disabled={readOnly}
                inputContainerStyle={{ height: inputHeight, width: inputWidth }}
                onChangeValue={(newOrder) => onChangeOrder(newOrder)}
                precision={0}
                testID="property-group-card-order-input"
                textStyle={{ textAlign: 'center' }}
                value={order + 1}
              />
            </View>
          </View>
          <HSpacer size={hSpacerSize}/>
          <View style={{ flex: 1 }}>
            <VSpacer size="2"/>
            <Text category="h6" testID="property-group-card-name">
              {name}
            </Text>
            <VSpacer size="3" />
            {!!description && sublineRow}
          </View>
          <HSpacer size={hSpacerSize}/>
          <View>
            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
              {isDesktop ? (
                <Button
                  accessoryLeft={<Icon name="Edit" testID="edit-icon"/>}
                  appearance="outline"
                  disabled={readOnly}
                  onPress={() => onEdit()}
                  status="basic"
                  testID="property-group-card-edit-button"
                >
                  {translate('EDIT')}
                </Button>
              ) : (
                <IconButton
                  appearance="ghost"
                  disabled={readOnly}
                  onPress={() => onEdit()}
                  size="large"
                  status="basic"
                  testID="property-group-card-edit-icon"
                >
                  Edit
                </IconButton>
              )}
              <HSpacer size="3"/>
              <View>
                <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                  <IconButton
                    appearance="ghost"
                    disabled={readOnly}
                    onPress={onDelete}
                    size="large"
                    status="basic"
                    testID="property-delete-icon-button"
                  >
                    Trash
                  </IconButton>
                </View>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </Card>
  );
};
