import { VSpacer } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import DescriptionList, {
  DescriptionListDirection,
} from '../../../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../../../components/shared/DescriptionList/DescriptionListItem';
import DetailHeader from '../../../../components/shared/DetailHeader/DetailHeader';

export interface CalculateQuantityDetailsProps {
  product: ProductOrderDeliverableV0.ProductSummary,
  selections: ProductOrderDeliverableV0.Selections['products'],
  index: number,
}
const CalculateQuantityDetails: FC<CalculateQuantityDetailsProps> = ({
  product,
  selections,
  index,
}) => {
  const [translate] = useTranslation(['common', 'productOrders']);
  const styles = useStyleSheet({
    container: {
      backgroundColor: 'color-basic-transparent-0',
      paddingHorizontal: 16,
      paddingTop: 4,
      paddingBottom: 24,
    },
    warningContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  });

  const { applicationRate, costPerAcre } = selections;

  return (
    <View style={styles.container} testID={`calculate-quantity-details-container-${index}`}>
      <DetailHeader header={translate('CALCULATE_QUANTITY')} />
      <VSpacer size="4" />
      <DescriptionList direction={DescriptionListDirection.STACKED}>
        <DescriptionListItem
          label={translate('ACREAGE')}
          testID={`calculate-acreage-${index}`}
          text={translate(product.acreage > 1 ? 'ACRES_COUNT' : 'ACRE_COUNT', {
            acres: StringUtility.localizeNumber(product.acreage, 3),
          })}
        />
        {applicationRate && product.applicationRate && product.applicationRateUom && (
          <DescriptionListItem
            label={translate('APPLICATION_RATE')}
            testID={`calculate-quantity-application-rate-${index}`}
            text={translate('APPLICATION_RATE_UOM_PER_AC', {
              applicationRate: StringUtility.localizeNumber(product.applicationRate, 3),
              applicationRateUom: translate(product.applicationRateUom),
            })}
          />
        )}
        {costPerAcre && (
          <DescriptionListItem
            label={translate('PRICE')}
            testID={`calculate-quantity-price-${index}`}
            text={translate('PRICE_PER_AC', {
              price: StringUtility.formatCurrency(product.pricePerAcre),
            })}
          />
        )}
        <DescriptionListItem
          label={translate('SKU_QUANTITY_NEEDED')}
          testID={`caculate-quantity-sku-needed-${index}`}
          text={StringUtility.localizeNumber(product.quantityRequired, 4)}
        />
      </DescriptionList>
    </View>
  );
};

export default CalculateQuantityDetails;
