import { VSpacer } from '@design';
import { ApiProductSummary } from '@shared/interfaces/api';
import { useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../utilities';
import DescriptionList, { DescriptionListDirection } from '../DescriptionList/DescriptionList';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';
import { ExternalDisplayIdText } from '../IntegrationOrder/ExternalDisplayIdText';

export interface FarmPlanSummaryProps {
  price: number,
  productSummary: ApiProductSummary,
  productSkuPrice: number,
}
export const FarmPlanSummary = ({
  price,
  productSummary,
  productSkuPrice,
}: FarmPlanSummaryProps) => {
  const [translate] = useTranslation(['common', 'productCard']);

  const themedStyles = useStyleSheet(
    {
      container: {
        backgroundColor: 'color-basic-transparent-0',
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: 24,
        flex: 1,
      },
    },
  );

  return (
    <View style={themedStyles.container} testID="product_details_card">
      {!!productSummary.externalDisplayId && (
        <>
          <DescriptionList direction={DescriptionListDirection.INLINE}>
            <DescriptionListItem
              label={translate('EXTERNAL_ID')}
              testID="external-id"
              text={<ExternalDisplayIdText externalDisplayId={productSummary.externalDisplayId} />}
              textCategory="p2"
            />
          </DescriptionList>
          <VSpacer size="3" />
        </>
      )}
      <DescriptionList direction={DescriptionListDirection.INLINE}>
        <DescriptionListItem
          label={translate('AVG_RATE_APPLIED')}
          testID="avg-rate-applied"
          text={translate('COST_UOM_PER_ACRE', {
            cost: StringUtility.localizeNumber(productSummary.avgRateApplied, 3),
            uom: translate(productSummary.avgRateAppliedUom),
          })}
          textCategory="p2"
        />
        <DescriptionListItem
          label={translate('RETAIL_UNIT_PRICE')}
          testID="retail-unit-price"
          text={StringUtility.formatCurrencyAccounting(price)}
          textCategory="p2"
        />
        <DescriptionListItem
          label={translate('SKU_PACKAGES_REQUIRED')}
          testID="sku-packages-required"
          text={StringUtility.localizeNumber(productSummary.productSkuPackagesRequired, 2)}
          textCategory="p2"
        />
      </DescriptionList>
      <VSpacer size="3" />
      <DescriptionList direction={DescriptionListDirection.INLINE}>
        <DescriptionListItem
          label={translate('ACRES_APPLIED')}
          testID="acres-applied"
          text={StringUtility.localizeNumber(productSummary.acresApplied, 3)}
          textCategory="p2"
        />
        <DescriptionListItem
          label={translate('UNITS_REQUIRED')}
          testID="units-required"
          text={StringUtility.localizeNumber(productSummary.productUnitsRequired, 2)}
          textCategory="p2"
        />
        <DescriptionListItem
          label={translate('PRODUCT_SKU_PRICE')}
          testID="product-sku-price"
          text={StringUtility.formatCurrency(productSkuPrice)}
          textCategory="p2"
        />
      </DescriptionList>
    </View>
  );
};
