import React from 'react';
import { ListRenderItemInfo, FlatList } from 'react-native';
import { ChildrenWithProps, PropsService } from '@ui-kitten/components/devsupport';
import { styled, StyleType } from '@ui-kitten/components/theme';

import {
  ListElement,
  ListProps,
  Divider,
} from '@ui-kitten/components';

import { AccordionItemProps, AccordionItemElement } from './AccordionItem';

type AccordionListProps = Omit<ListProps, 'data' | 'renderItem'>;

export interface AccordionProps extends AccordionListProps {
  children?: ChildrenWithProps<AccordionItemProps>,
  dividerStyle?: StyleType,
  testID: string,
}

@styled('Accordion')
export class Accordion extends React.Component<AccordionProps> {
  private get data (): any[] {
    return React.Children.toArray(this.props.children || []);
  }

  private cloneItemWithProps = (
    element: React.ReactElement,
    props: AccordionItemProps,
  ): React.ReactElement => React.cloneElement(element, { ...element.props, ...props });

  private renderItem = (
    info: ListRenderItemInfo<AccordionItemElement>,
  ): React.ReactElement => this.cloneItemWithProps(info.item, { ...info.item.props });

  private getComponentStyle = (style: StyleType) => {
    const dividerStyles = PropsService.allWithPrefix(style, 'divider');
    return {
      divider: {
        height: dividerStyles.dividerHeight,
        backgroundColor: dividerStyles.dividerColor,
      },
    };
  };

  public render (): ListElement {
    const { appearance, eva, style, children, dividerStyle, ...listProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <FlatList
        {...listProps}
        ItemSeparatorComponent={() => <Divider style={[evaStyle.divider, dividerStyle]} />}
        data={this.data}
        keyExtractor={(_, idx) => idx.toString()}
        renderItem={this.renderItem}
        style={[{ width: '100%' }, style]}
      />
    );
  }
}
