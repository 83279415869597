import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { PropertyEditor } from '../../CustomerPlanning/PropertiesTab/PropertyEditor';
import { ThemeColors } from '../../../../constants';
import { ApiFormField } from '@shared/interfaces/api';
import { FormFieldType } from '@shared/enums';
import { Button, Modal, VSpacer } from '@design';

const base = {
  id: '1',
  businessId: '',
  createdAt: new Date,
  updatedAt: new Date,
};
const demoFormField: ApiFormField = {
  ...base,
  name: 'Test',
  options: [
    { id: 'a', other: false, value: 'foo' },
    { id: 'b', other: true, value: 'Other' },
  ],
  order: 1,
  type: FormFieldType.MultiSelect,
};

export const PropertyEditorDemo: FunctionComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <View style={{ maxWidth: 800 }}>
      <View style={{ flexDirection: 'row' }}>
        <Button onPress={() => setIsModalVisible(true)} size="large" testID="edit-modal-button">
          Open Edit Modal
        </Button>
      </View>
      <VSpacer size="7" />
      <View style={{ backgroundColor: ThemeColors.BACKGROUND }}>
        <PropertyEditor 
          addProperty={() => {}}
          formField={demoFormField}
          setProperty={() => {}}
        />
      </View>
      {isModalVisible && (
        <Modal
          maxHeight
          onClose={() => setIsModalVisible(false)}
          testID="demo-modal"
          title="Edit Property Group"
          visible
          width={570}
        >
          <PropertyEditor 
            addProperty={() => {}}
            formField={demoFormField}
            setProperty={() => {}} 
          />
        </Modal>
      )}
    </View>
  );
};