import {
  Card,
  CenteredSpinner,
  Header,
  Input,
  LargeModal,
  Select,
  SelectItem,
  Text,
  ToastProps,
  useToast,
  VSpacer,
} from '@design';
import { Country, State } from '@shared/constants';
import { ApiBusinessLocation, BusinessLocationEndpoint } from '@shared/interfaces/api';
import { validateEmail, validatePhoneNumber, validatePostalCode } from '@shared/utils';
import { Status } from '@theme/variant-interfaces/Status';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useDebounce } from '../../../../hooks/useDebounce';
import { BusinessLocationApi } from '../../../../utilities/api';
import { ButtonBar } from '../../../components/shared/ButtonBar';

interface LocationModalProps {
  location: ApiBusinessLocation,
  editMode: boolean,
  toggle: () => void,
  visible: boolean,
}

const styles = StyleSheet.create({
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 489,
    backgroundColor: 'transparent',
  },
});

export const LocationModal = ({ location, editMode, toggle, visible }: LocationModalProps) => {
  const [translate] = useTranslation(['businessLocations', 'businesses', 'common']);
  const { currentBusinessId } = useAuthentication();
  const queryClient = useQueryClient();
  const { createToast } = useToast();
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [initialData, setInitialData] = useState<BusinessLocationEndpoint.Create.Request>(null);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const [allCountries, setAllCountries] = useState<Country[]>([]);
  const [allStates, setAllStates] = useState<State[]>([]);
  const [emailError, setEmailError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const { debounce } = useDebounce();

  const toastProps = (toastText: string, status: Status): ToastProps => ({
    children: toastText,
    status,
    testID: 'toast-content-element',
  });

  const clearForm = () => {
    setName('');
    setEmail('');
    setTelephone('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setState('');
    setCounty('');
    setCountry('');
    setPostalCode('');
  };

  useEffect(() => {
    if (location) {
      setName(location.locationName);
      setEmail(location.locationEmail);
      setTelephone(location.locationTelephone);
      setAddress1(location.address1);
      setAddress2(location.address2);
      setCity(location.city);
      setState(location.state);
      setCounty(location.county);
      setCountry(location.country);
      setPostalCode(location.postalCode);
      setInitialData({
        address1: location.address1,
        address2: location.address2,
        businessId: location.businessId,
        city: location.city,
        county: location.county,
        country: location.country,
        locationEmail: location.locationEmail,
        locationName: location.locationName,
        locationTelephone: location.locationTelephone,
        postalCode: location.postalCode,
        state: location.state,
      });
    } else if (!location || !visible) {
      clearForm();
    }
  }, [location, visible]);

  const getCountries = async () => BusinessLocationApi.getCountries();
  const { isLoading } = useQuery([
    QueryKeys.COUNTRIES,
  ], getCountries, {
    onError: () => createToast(toastProps(translate('UNEXPECTED_ERROR'), 'warning')),
    onSuccess: (data) => setAllCountries(data),
  });

  const getTitle = () => (
    editMode
      ? translate('EDIT_BUSINESS_LOCATION_TITLE')
      : translate('ADD_BUSINESS_LOCATION_TITLE')
  );

  const formValid = (): boolean => {
    const data = {
      address1,
      address2,
      businessId: currentBusinessId,
      city,
      country,
      county,
      locationEmail: email,
      locationName: name,
      locationTelephone: telephone,
      postalCode,
      state,
    };
    // check if data is equal to initial data
    const isEqual = _.isEqual(data, initialData);
    const hasValidPhone = (
      !telephone
      || telephone.length === 0
      || validatePhoneNumber(telephone)
    );

    const hasValidEmail = !email || email.length === 0 || validateEmail(email);

    const hasValidPostalCode = (
      !postalCode
      || postalCode.length === 0
      || validatePostalCode(postalCode, country)
    );
    return name && hasValidPhone && hasValidEmail && hasValidPostalCode && !isEqual;
  };

  const onSubmitMutation = useMutation(
    [QueryKeys.BUSINESS_LOCATION],
    (businessLocation: BusinessLocationEndpoint.Create.Request) => {
      return !editMode
        ? BusinessLocationApi.createBusinessLocation(currentBusinessId, businessLocation)
        : BusinessLocationApi.updateBusinessLocation(
          currentBusinessId,
          location.id,
          businessLocation,
        );
    }, {
      onError: (error: Error) => {
        setSaving(false);
        if (error.message === 'duplicate location') {
          setIsDuplicateError(true);
        } else {
          createToast(toastProps(
            translate<string>('UNEXPECTED_ERROR', { error: error.name }),
            'warning',
          ));
        }
        // This prevent the page from crashing when the error is not handled
        return new Promise(() => {});
      },
      onSuccess: () => {
        setSaving(false);
        queryClient.invalidateQueries(QueryKeys.BUSINESS_LOCATION_LIST);
        createToast(toastProps(translate<string>(!editMode
          ? 'BUSINESS_LOCATION_SUCCESSFULLY_ADDED'
          : 'BUSINESS_LOCATION_SUCCESSFULLY_EDITED'),
        'success'));
        toggle();
      },
    },
  );

  const onSubmit = async () => {
    setSaving(true);
    setIsDuplicateError(false);
    const businessLocation: BusinessLocationEndpoint.Create.Request = {
      address1,
      address2,
      businessId: currentBusinessId,
      city,
      county,
      country,
      locationName: name,
      locationEmail: email || null,
      locationTelephone: telephone || null,
      postalCode,
      state,
    };
    return onSubmitMutation.mutateAsync(businessLocation);
  };

  useEffect(() => {
    if (allCountries.length && country) {
      const selectedCountry = allCountries.find((c) => c.name === country);
      const displayStates = selectedCountry.states;
      setAllStates(displayStates);

      setCountry(selectedCountry.name);
    }
  }, [allCountries, country]);

  const onChangeCountry = (selection: IndexPath | IndexPath[]) => {
    const selectedCountry = allCountries[(selection as IndexPath).row].name;
    if (selectedCountry !== country) {
      setState('');
    }
    setCountry(selectedCountry);
  };

  const BusinessLocationFormPage = [
    <View style={{ alignItems: 'center', width: '100%' }}>
      <KeyboardAwareScrollView>
        {isLoading && <CenteredSpinner />}
        <Card style={styles.card} testID="business-location-card">
          <Header level="4" testID="business-location-details-header" title={translate('BUSINESS_LOCATION_DETAILS')} />
          <VSpacer size="8" />
          <View>
            <Input
              caption={isDuplicateError ? translate<string>('DUPLICATE_BUSINESS_LOCATION') : undefined}
              isRequired
              label={`${translate('BUSINESS_LOCATION_NAME')}`}
              onChangeText={setName}
              status={isDuplicateError ? 'danger' : undefined}
              testID="business-location-name"
              value={name}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              caption={emailError && translate<string>('VALID_EMAIL_REQUIRED')}
              label={`${translate('EMAIL_ADDRESS')}`}
              onBlur={() => setEmailError(!!email && !validateEmail(email))}
              onChangeText={setEmail}
              status={emailError ? 'danger' : undefined}
              testID="email"
              value={email}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              caption={telephoneError && translate<string>('VALID_PHONE_REQUIRED')}
              label={`${translate('PHONE_NUMBER')}`}
              onBlur={() => (
                setTelephoneError(!!telephone
                  && !validatePhoneNumber(telephone))
              )}
              onChangeText={setTelephone}
              status={telephoneError ? 'danger' : undefined}
              testID="phone-number"
              value={telephone}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Select
              label={`${translate('COUNTRY')}`}
              onSelect={onChangeCountry}
              testID="location-country-selector"
              value={country}
            >
              {allCountries.map((item, index) => (
                <SelectItem
                  key={item.name}
                  testID={`location-modal-country-dropdown-value-${index}`}
                  title={item.name}
                />
              ))}
            </Select>
          </View>
          <VSpacer size="8" />
          <View>
            {
              allStates.length > 0 ? (
                <Select
                  disabled={!country}
                  label={`${translate('STATE')}`}
                  onSelect={(selection: IndexPath | IndexPath[]) => {
                    setState(allStates[(selection as IndexPath).row].name);
                  }}
                  testID="location-state-selector"
                  value={state}
                >
                  {allStates.map((item, index) => (
                    <SelectItem
                      key={item.name}
                      testID={`location-modal-state-dropdown-value-${index}`}
                      title={item.name}
                    />
                  ))}
                </Select>
              ) : (
                <Input
                  disabled={!country}
                  label={`${translate('STATE')}`}
                  onChangeText={setState}
                  testID="location-state-input"
                  value={state}
                />
              )
            }
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              label={`${translate('COUNTY')}`}
              onChangeText={setCounty}
              testID="county"
              value={county}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              label={`${translate('CITY')}`}
              onChangeText={setCity}
              testID="city"
              value={city}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              caption={postalCodeError && translate<string>('VALID_POSTAL_CODE_REQUIRED')}
              label={`${translate('POSTAL_CODE')}`}
              onBlur={() => (
                setPostalCodeError(!!postalCode
                  && !validatePostalCode(postalCode, country))
              )}
              onChangeText={setPostalCode}
              status={postalCodeError ? 'danger' : undefined}
              testID="postal-code"
              value={postalCode}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              label={`${translate('ADDRESS1')}`}
              onChangeText={setAddress1}
              testID="address1"
              value={address1}
            />
          </View>
          <VSpacer size="8" />
          <View>
            <Input
              label={`${translate('ADDRESS2')}`}
              onChangeText={setAddress2}
              testID="address2"
              value={address2}
            />
          </View>
        </Card>
      </KeyboardAwareScrollView>
    </View>,
  ];

  const Footer = () => (
    <ButtonBar
      buttonBarType="grouped"
      disableRightAction={!formValid() || saving}
      leftAction={() => toggle()}
      leftButtonText={translate('CANCEL')}
      rightAction={() => debounce(onSubmit)}
      rightButtonText={editMode
        ? translate('SAVE_CHANGES')
        : translate('SAVE')}
      testID="location-button"
    />
  );

  return (
    <LargeModal
      footer={() => <Footer />}
      pages={BusinessLocationFormPage}
      testID="business-location-form-modal"
      title={getTitle()}
      visible
    >
      <Text>LocationModal</Text>
    </LargeModal>
  );
};
