import { Button, HSpacer, Input, Text, TextLink, useToast, VSpacer } from '@design';
import { Subscription } from '@shared/enums';
import { parseEnum } from '@shared/enums/EnumUtils';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { useStyleSheet } from '@ui-kitten/components';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes, Sizing, ThemeColors } from '../../constants';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { useScreenSizing } from '../../hooks/useScreenSizing';
import { Environment, LocalStorageUtility, QueryUtility } from '../../utilities';
import DeviceSizes from './DeviceSizes';

export const Login = ({ translate }) => {
  const history = useHistory();
  const { authenticate } = useAuthentication();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [problem, setProblem] = useState('');
  const { search: searchParams } = useLocation();
  const sizeSetting = useScreenSizing();
  const { createToast } = useToast();
  const {
    invalidVerificationToken,
    targetApp,
    verifiedEmail,
  } = QueryUtility.getQueryParams(searchParams);

  const styles = useStyleSheet({
    container: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    submitButtonContainer: {
      alignItems: 'center',
    },
    statusText: { marginBottom: Sizing.BASE_SPACING },
    errorMessage: {
      padding: Sizing.HALF_SPACING,
      color: 'color-danger-500',
      textAlign: 'center',
      backgroundColor: 'color-danger-transparent-100',
    },
  });

  const contactRallySupport = () => history.push(Routes.PUBLIC_HELP);

  const submitForm = async () => {
    try {
      setSubmitting(true);
      await authenticate(email.toLowerCase(), password);
      setLoginError(null);
      if (targetApp) {
        const subscription = parseEnum(Subscription, targetApp);
        if (subscription === Subscription.TEST) {
          window.location.href = `${Environment.get('LOGIN_TEST_URL')}/loginStatus`;
          return;
        }
      }
      history.push(Routes.LOGGED_IN);
    } catch (err) {
      const code = _.get(err, 'code', 500) as number;
      if (code < 500) {
        if (code === 429) {
          setLoginError(translate('RATE_LIMIT_EXCEEDED'));
        } else {
          setLoginError(translate('INVALID_CREDENTIALS'));
          setPassword('');
        }
      } else {
        setLoginError(translate('UNEXPECTED_ERROR'));
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const onLoadProblem = async () => {
      if (await LocalStorageUtility.getValue('PROBLEM')) {
        setProblem(translate('PROBLEM'));
        await LocalStorageUtility.removeValue('PROBLEM');
      }
      return (
        problem && createToast({
          status: 'danger',
          children: <Text>{problem}</Text>,
          testID: 'toast-content-element',
        })
      );
    };
    onLoadProblem();
  }, [translate, problem, createToast]);

  return (
    <View style={[
      styles.container,
      { width: DeviceSizes[sizeSetting].containerSize },
    ]}
    >
      <ScrollView>
        <Text
          category="h3"
          style={{ textAlign: 'center' }}
        >
          {translate('LOGIN')}
        </Text>
        <VSpacer size={DeviceSizes[sizeSetting].bottomTitleSpace as SpacerSize} />

        {loginError && (
        <>
          <Text category="p2" style={styles.errorMessage} testID="error-message">
            {loginError}
          </Text>
          <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
        </>
        )}

        {invalidVerificationToken && (
        <Text
          style={[
            styles.statusText,
            { color: ThemeColors.DANGER },
          ]}
          testID="invalid-verification"
        >
          {translate('VERIFICATION_TOKEN_INVALID')}
        </Text>
        )}
        {verifiedEmail && (
        <Text
          style={[
            styles.statusText,
            { color: ThemeColors.SUCCESS },
          ]}
          testID="invalid-verification"
        >
          {translate('VERIFICATION_SUCCESSFUL')}
        </Text>
        )}
        <Input
          autoCapitalize="none"
          keyboardType="email-address"
          label={translate('USER_NAME')}
          onChangeText={(nextValue) => setEmail(nextValue)}
          onSubmitEditing={submitForm}
          status={loginError ? 'danger' : undefined}
          testID="username"
          value={email}
        />
        <VSpacer size="8" />
        <Input
          label={translate('PASSWORD')}
          onChangeText={(nextValue) => setPassword(nextValue)}
          onSubmitEditing={submitForm}
          secureTextEntry
          status={loginError ? 'danger' : undefined}
          testID="password"
          value={password}
        />
        <VSpacer size="8" />
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => history.push(Routes.FORGOT_PASSWORD)}
          testID="forgot-password"
        >
          {translate('FORGOT_PASSWORD') as string}
        </TextLink>
        <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
        <View style={styles.submitButtonContainer}>
          <Button
            appearance={(!email || !password || submitting) ? 'outline' : 'filled'}
            disabled={!email || !password || submitting}
            onPress={submitForm}
            status="primary"
            testID="login-btn"
          >
            {translate('LOGIN')}
          </Button>
        </View>
        <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Text
            appearance="hint"
            category="p2"
            testID="info-message"
          >
            {translate('DONT_HAVE_ACCOUNT') as string}
          </Text>
          <HSpacer size="2" />
          <TextLink
            appearance="secondary"
            category="p2"
            onPress={contactRallySupport}
            testID="call-link"
          >
            {translate('EMAIL_GROWERS') as string}
          </TextLink>
        </View>
      </ScrollView>
    </View>
  );
};
