import { Button, CheckBox, HSpacer, Modal, NumericInput, Text, VSpacer } from '@design';
import { ApiCropLogic, ApiPriceType, CropLogicEndpoint } from '@shared/interfaces/api';
import { CropLogicUtilities } from '@shared/utils';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessCrops } from '../../../../hooks/useBusinessCrops';
import { usePriceTypeList } from '../../../../hooks/usePriceTypeList';
import { BusinessLocationSelect } from '../../../components/shared/Input/BusinessLocationSelect';
import { PriceTypeSelect } from '../../../components/shared/Input/PriceTypeSelect';

const styles = StyleSheet.create({
  card: {
    flex: 1,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
    width: 545,
  },
  calcContainer: {
    flexDirection: 'row',
  },
  calcField: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
  },
  calInput: {
    flex: 1,
  },
  uomContainer: {
    height: 40,
    marginTop: 28.3,
    justifyContent: 'center',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
});

interface ProgramScenarioParametersFormProps {
  onCancel: () => void,
  onSave: (program: CropLogicEndpoint.Create.Request) => void,
  program: ApiCropLogic,
  programPriceType: ApiPriceType
}

export const ProgramScenarioParametersForm = ({
  onCancel,
  onSave,
  program,
  programPriceType,
}: ProgramScenarioParametersFormProps) => {
  const {
    cropType,
    cropSubType,
  } = program;
  const [translate] = useTranslation(['common', 'programs']);
  const [newProgram, setNewProgram] = useState<CropLogicEndpoint.Create.Request>(program);
  const { defaultPriceType, defaultPriceTypeId } = usePriceTypeList();
  const [useScenarioParameters, setUseScenarioParameters] = useState(!!program.scenarioParameters);
  const [selectedPriceType, setSelectedPriceType] = useState<ApiPriceType>(
    programPriceType || defaultPriceType,
  );
  const { currentBusinessId } = useAuthentication();
  const { businessCrops } = useBusinessCrops({ businessId: currentBusinessId });
  const selectedCrop = useMemo(() => {
    return businessCrops.find((crop) => crop.cropType === cropType
    && crop.subType === cropSubType);
  }, [
    businessCrops,
    cropType,
    cropSubType,
  ]);

  const handlePriceTypeSelect = (priceType: ApiPriceType) => {
    const { id: priceTypeId } = priceType;
    setNewProgram({
      ...newProgram,
      priceTypeId,
    });
    setSelectedPriceType(priceType);
  };

  const isEqual = useMemo(() => _.isEqual(program, newProgram), [program, newProgram]);
  const isValid = useMemo(() => {
    if (!newProgram.scenarioParameters) { return true; }

    const { isFieldsValid, scenarioParamsValid } = CropLogicUtilities.validateCropLogic(
      newProgram,
      useScenarioParameters,
      newProgram.scenarioParameters,
    );

    return isFieldsValid && scenarioParamsValid;
  }, [newProgram, useScenarioParameters]);

  const toggleScenarioParameters = () => {
    const isUseScenario = !useScenarioParameters;
    if (isUseScenario && !program.scenarioParameters) {
      setNewProgram({
        ...newProgram,
        priceTypeId: defaultPriceTypeId,
        scenarioParameters: {
          businessLocationId: null,
          operationalCosts: 0,
          price: selectedCrop.price,
          priceUom: selectedCrop.priceUom,
          yieldGoal: selectedCrop.yieldGoal,
          yieldGoalUom: selectedCrop.yieldGoalUom,
        },
      });
    } else if (isUseScenario && !newProgram.scenarioParameters) {
      setNewProgram({
        ...newProgram,
        priceTypeId: program.priceTypeId,
        scenarioParameters: program.scenarioParameters,
      });
    } else if (!isUseScenario) {
      setNewProgram({
        ...newProgram,
        priceTypeId: defaultPriceTypeId,
        scenarioParameters: null,
      });
    }
    setUseScenarioParameters(isUseScenario);
  };

  return (
    <Modal
      footerAccessory={({
        primaryButtonProp,
        secondaryButtonProp,
        spacerProp,
      }) => (
        <>
          <Button
            {...primaryButtonProp}
            onPress={onCancel}
            testID="close-edit-program-modal"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            disabled={isEqual || !isValid}
            onPress={() => onSave(newProgram)}
            testID="save-edit-program-modal"
          >
            {translate<string>('SAVE_CHANGES')}
          </Button>
        </>
      )}
      hideCloseButton
      testID="program-scenario-parameters-modal"
      title={translate<string>('EDIT_SCENARIO_PARAMETERS')}
      visible
      width={545}
    >
      <CheckBox
        checked={useScenarioParameters}
        disabled={!newProgram.cropType}
        onChange={toggleScenarioParameters}
        testID="program-form-modal-use-scenario-parameters-checkbox"
      >
        {translate<string>('USE_SCENARIO_PARAMETERS')}
      </CheckBox>
      {useScenarioParameters && (
      <View testID="program-form-modal-use-scenario-parameters-section">
        <VSpacer size="9" />
        <BusinessLocationSelect
          locationId={newProgram.scenarioParameters?.businessLocationId}
          onUpdateLocation={(businessLocationId) => {
            setNewProgram({
              ...newProgram,
              scenarioParameters: {
                ...newProgram.scenarioParameters,
                businessLocationId,
              },
            });
          }}
          optionWindowMaxHeight={300}
          testID="program-form-modal-business-locations-selector"
        />
        <PriceTypeSelect
          isRequired
          onUpdatePriceType={handlePriceTypeSelect}
          selectedPriceType={selectedPriceType}
          testID="program-form-modal-price-type"
          vSpacerSize="7"
        />
        <VSpacer size="7" />
        <View style={styles.calcContainer}>
          <View style={styles.calcField}>
            <NumericInput
              isRequired
              label={translate<string>('YIELD_GOAL')}
              onChangeValue={(yieldGoal) => {
                setNewProgram({
                  ...newProgram,
                  scenarioParameters: {
                    ...newProgram.scenarioParameters,
                    yieldGoal,
                  },
                });
              }}
              style={styles.calInput}
              testID="program-form-modal-yield-goal-field"
              value={newProgram.scenarioParameters?.yieldGoal}
            />
            <HSpacer size="3" />
            <View
              style={styles.uomContainer}
            >
              <Text
                category="p1"
                testID="program-form-modal-yield-goal-uom"
              >
                {translate<string>('UOM_PER_ACRE_PARSED',
                  { UoM: translate<string>(newProgram.scenarioParameters?.yieldGoalUom) })}
              </Text>
            </View>
          </View>
          <HSpacer size="7" />
          <View style={styles.calcField}>
            <NumericInput
              isRequired
              label={translate<string>('MARKET_PRICE')}
              onChangeValue={(price) => {
                setNewProgram({
                  ...newProgram,
                  scenarioParameters: {
                    ...newProgram.scenarioParameters,
                    price,
                  },
                });
              }}
              prefix="$"
              style={styles.calInput}
              testID="program-form-modal-crop-market-price-field"
              value={newProgram.scenarioParameters?.price}
            />
            <HSpacer size="3" />
            <View
              style={styles.uomContainer}
            >
              <Text
                category="p1"
                testID="program-form-modal-price-uom"
              >
                {translate<string>('PER_UOM',
                  { UoM: translate<string>(newProgram.scenarioParameters?.priceUom) })}
              </Text>
            </View>
          </View>
        </View>
        <VSpacer size="7" />
        <View style={styles.calcContainer}>
          <View style={styles.calcField}>
            <NumericInput
              label={translate<string>('OPERATIONAL_COST_AC')}
              onChangeValue={(operationalCosts) => {
                setNewProgram({
                  ...newProgram,
                  scenarioParameters: {
                    ...newProgram.scenarioParameters,
                    operationalCosts: operationalCosts ?? 0,
                  },
                });
              }}
              prefix="$"
              style={styles.calInput}
              testID="program-form-modal-operational-cost-per-acre-field"
              value={newProgram.scenarioParameters?.operationalCosts}
            />
            <HSpacer size="3" />
            <View
              accessible
              style={styles.hidden}
            >
              <Text
                category="p1"
              >
                {translate<string>('UOM_PER_ACRE_PARSED',
                  { UoM: translate<string>(newProgram.scenarioParameters?.yieldGoalUom) })}
              </Text>
            </View>
          </View>
          <HSpacer size="7" />
          <View style={styles.calcField} />
        </View>
      </View>
      )}
    </Modal>
  );
};
