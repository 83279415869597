import { BusinessLocationEndpoint } from '@shared/interfaces/api';
import { Permissions, userHasPermission } from '@shared/utils';
import { validateEmail, validatePhoneNumber } from '@shared/utils/validators';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Sizing } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../hooks/useBusinessLocationList';
import { useHistory, useLocation, useParams } from '../router';
import { Alert, Button, Card, Input, Select, Spinner, Text } from '../ui-components';
import { QueryUtility } from '../utilities';
import { BusinessLocationApi } from '../utilities/api';
import { DetailedApiError } from '../utilities/api/DetailedApiError';
import { LogErrorApi } from '../utilities/api/LogErrorApi';

const determineIsEditPage = (locationId?: string): boolean => !_.isEmpty(locationId);

const styles = StyleSheet.create({
  base: {
    flex: 1,
    overflow: 'hidden',
    marginTop: Sizing.BASE_SPACING,
    paddingTop: Sizing.BASE_SPACING,
  },
  container: { flexDirection: 'row' },
  card: {
    flex: 1,
    margin: Sizing.EM,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  lastButton: { marginLeft: Sizing.BASE_SPACING },
  headerText: {
    flex: 1,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  footerControl: { marginHorizontal: 2 },
  input: { padding: Sizing.EM },
  autoInputWrapper: { padding: Sizing.EM },
  businessAssignRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Sizing.BASE_SPACING,
  },
  assignedBusinessRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Sizing.BASE_SPACING,
  },
  businessAssignRowText: {
    flex: 1,
    fontSize: 1.1 * Sizing.EM,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

  },
});

export const BusinessLocationFormPage = () => {
  const [translate] = useTranslation(['businessLocations', 'businesses', 'common']);
  const { location: locationId } = useParams<{ location: string }>();
  const { currentBusinessId, user } = useAuthentication();
  const { invalidateBusinessLocationList } = useBusinessLocationList(currentBusinessId);

  const history = useHistory();

  const initialEditMode = determineIsEditPage(locationId);
  const [isEditPage, setEditPage] = useState(determineIsEditPage(locationId));
  const [fetchingLocation, setFetchingLocation] = useState(initialEditMode);

  const [error, setError] = useState('');
  const [formInitErrorKey, setFormInitErrorKey] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(true);

  const [allCountryList, setAllCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const { search: searchParams } = useLocation();
  const { redirectTo } = QueryUtility.getQueryParams(searchParams);

  // Initialize the form and load the list of supported countries on mount
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setIsFormLoading(true);
        const countries = await BusinessLocationApi.getCountries();
        setAllCountryList(countries);
      } catch (err) {
        setFormInitErrorKey('UNEXPECTED_ERROR');
      } finally {
        setIsFormLoading(false);
      }
    };
    fetchFormData();
  }, []);

  // Update the isFormLoading which displays a loading indicator for the user
  // If country list is still loading, or the form is in edit mode and is
  // still fetching the location, it's true
  useEffect(() => {
    const formDataFetching = _.isEmpty(allCountryList);
    const isLocationDataFetching = isEditPage && fetchingLocation;

    setIsFormLoading(formDataFetching && isLocationDataFetching);
  }, [allCountryList, isEditPage, fetchingLocation]);

  useEffect(() => {
    const isEdit: boolean = determineIsEditPage(locationId);
    setEditPage(isEdit);
  }, [locationId]);

  // Fetch the location based on id, when any of the url parameters change
  useEffect(() => {
    const fetchLocation = async () => {
      setFetchingLocation(true);
      setFormInitErrorKey('');
      try {
        const location = (
          await BusinessLocationApi.getBusinessLocation(locationId)
        );
        setName(location.locationName);
        setEmail(location.locationEmail);
        setTelephone(location.locationTelephone);
        setAddress1(location.address1);
        setAddress2(location.address2);
        setCity(location.city);
        setCounty(location.county);
        setPostalCode(location.postalCode);

        const locationCountry = allCountryList.find((c) => c.name === location.country);
        if (locationCountry) {
          setSelectedCountry(locationCountry);
          setState(location.state);
          setCountry(location.country);
        }
      } catch (err) {
        setFormInitErrorKey('ERROR_FETCH_BUSINESS_LOCATION');
      }
      setFetchingLocation(false);
    };
    if (isEditPage && !_.isEmpty(allCountryList)) {
      fetchLocation();
    }
  }, [allCountryList, currentBusinessId, isEditPage, locationId]);

  const redirectUser = () => {
    history.push(redirectTo);
  };

  /**
   * Invoked when the user changes the currently selected country.
   * Sets the selectedCountry and resets the stateSearchValue and state values
   * @param selection
   */
  const onCountrySelectChanged = (selection) => {
    setSelectedCountry(allCountryList.find((c) => c.name === selection.value));
    setState('');
  };

  // When countries are loaded and the selected country changes, initialize that
  // state selector and county/country fields
  useEffect(() => {
    if (!_.isEmpty(allCountryList) && !_.isNil(selectedCountry)) {
      // Update the state input with the selected countries' states and reset the state value
      const displayStates = selectedCountry.states;
      setStateList(displayStates);

      // Store the countries name in the country field when a country is selected
      setCountry(selectedCountry.name);
    }
  }, [allCountryList, selectedCountry]);

  const submitForm = async () => {
    // Replace empty strings with nulls
    const formData: BusinessLocationEndpoint.Create.Request = {
      locationName: name,
      locationEmail: email || null,
      locationTelephone: telephone || null,
      address1: address1 || null,
      address2: address2 || null,
      city: city || null,
      state: state || null,
      county: county || null,
      postalCode: postalCode || null,
      country: country || null,
    };

    setError('');
    try {
      if (isEditPage) {
        await BusinessLocationApi.updateBusinessLocation(currentBusinessId, locationId, formData);
      } else {
        if (userHasPermission(user, Permissions.ACCESS_ALL_BUSINESSES)) {
          formData.businessId = currentBusinessId;
        }
        await BusinessLocationApi.createBusinessLocation(currentBusinessId, formData);
      }
      invalidateBusinessLocationList();
      redirectUser();
    } catch (err) {
      if (err instanceof DetailedApiError && err.code === 'locationName-exists') {
        setError(translate('ERROR_BUSINESS_LOCATION_NAME_ALREADY_USED', { name }));
      } else {
        LogErrorApi.logError(err as Error);
        setError(translate('ERROR_CREATING_BUSINESS_LOCATION'));
      }
    }
  };

  /**
   * Validates the value provided in the form
   * @returns true if every field is valid, false otherwise
   */
  const formValid = (): boolean => {
    const requiredFields = [name];
    const hasRequiredFields = _.every(requiredFields, (value) => !_.isEmpty(value));

    const hasValidPhone = (
      !telephone
      || telephone.length === 0
      || validatePhoneNumber(telephone)
    );
    const hasValidEmail = !email || email.length === 0 || validateEmail(email);

    return hasRequiredFields && hasValidPhone && hasValidEmail;
  };

  const Header = (props) => (
    <View {...props}>
      <Text style={styles.headerText}>{translate('BUSINESS_LOCATION_DETAILS') as string}</Text>
    </View>
  );

  const Footer = (props) => (
    <View {...props} style={[props.style, styles.footer]}>
      <Button
        nativeID="cancel-button"
        onPress={() => redirectUser()}
        status="secondary"
      >
        {translate('CANCEL') as string}
      </Button>
      <Button
        disabled={!formValid()}
        nativeID="save-button"
        onPress={submitForm}
        style={styles.lastButton}
      >
        {translate('SAVE') as string}
      </Button>
    </View>
  );

  if (isFormLoading || fetchingLocation) {
    return (<Spinner />);
  }

  return (
    <ScrollView style={styles.base}>
      {!!error && <Alert level="danger" text={error} />}
      {!!formInitErrorKey && <Alert level="danger" text={translate(formInitErrorKey)} />}
      {!formInitErrorKey && (
        <View style={styles.container}>
          <Card footer={<Footer />} header={<Header />} style={styles.card} testID="business-location-card">
            <Input
              caption={translate('REQUIRED') as string}
              label={`${translate('BUSINESS_LOCATION_NAME')}`}
              onChangeText={(nextValue) => setName(nextValue)}
              style={styles.input}
              testID="business-location-name"
              value={name}
            />
            <Input
              caption={(email && !validateEmail(email) && translate('VALID_EMAIL_REQUIRED') as string)}
              label={`${translate('EMAIL_ADDRESS')}`}
              onChangeText={(nextValue) => setEmail(nextValue)}
              status={(email && !validateEmail(email) && 'danger') || undefined}
              style={styles.input}
              testID="email"
              value={email}
            />
            <Input
              caption={(telephone && !validatePhoneNumber(telephone) && translate('VALID_PHONE_REQUIRED') as string)}
              label={`${translate('PHONE_NUMBER')}`}
              onChangeText={(nextValue) => setTelephone(nextValue)}
              status={(telephone && !validatePhoneNumber(telephone) && 'danger') || undefined}
              style={styles.input}
              testID="phone-number"
              value={telephone}
            />
            <Select
              label={`${translate('COUNTRY')}`}
              nativeID="location-country-selector"
              onSelect={(selection) => onCountrySelectChanged(selection)}
              options={allCountryList.map((value) => ({
                value: value.name,
                label: value.name,
                selected: value === selectedCountry,
              }))}
            />
            {_.isEmpty(stateList)
              ? (
                <Input
                  disabled={_.isEmpty(country)}
                  label={`${translate('STATE')}`}
                  onChangeText={(nextValue) => setState(nextValue)}
                  style={styles.input}
                  testID="location-state-input"
                  value={state}
                />
              ) : (
                <Select
                  disabled={_.isEmpty(country)}
                  label={`${translate('STATE')}`}
                  nativeID="location-state-selector"
                  onSelect={(selectedState) => setState(selectedState.value)}
                  options={stateList.map((value) => ({
                    value: value.name,
                    label: value.name,
                    selected: value.name === state,
                  }))}
                />
              )}
            <Input
              label={`${translate('COUNTY')}`}
              onChangeText={(nextValue) => setCounty(nextValue)}
              style={styles.input}
              testID="county"
              value={county}
            />
            <Input
              label={`${translate('CITY')}`}
              onChangeText={(nextValue) => setCity(nextValue)}
              style={styles.input}
              testID="city"
              value={city}
            />
            <Input
              label={`${translate('POSTAL_CODE')}`}
              onChangeText={(nextValue) => setPostalCode(nextValue)}
              style={styles.input}
              testID="postal-code"
              value={postalCode}
            />
            <Input
              label={`${translate('ADDRESS1')}`}
              onChangeText={(nextValue) => setAddress1(nextValue)}
              style={styles.input}
              testID="address1"
              value={address1}
            />
            <Input
              label={`${translate('ADDRESS2')}`}
              onChangeText={(nextValue) => setAddress2(nextValue)}
              style={styles.input}
              testID="address2"
              value={address2}
            />
          </Card>
          <View style={{ flex: 1 }} />
        </View>
      )}
    </ScrollView>
  );
};
