import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native';
import {
  FarmPlanDeliverableV2 as V2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { CenteredSpinner, Icon, Logo, VSpacer } from '@design';
import { useQuery } from 'react-query';
import { useLogo } from '../../../../hooks/useLogo';
import { PoweredByGrowersLogoMobile } from '../../../../svg/PoweredByGrowersLogoMobile';
import {
  PoweredByGrowersLogoMobileCentered,
} from '../../../../svg/PoweredByGrowersLogoMobileCentered';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { RallyLogoHeader } from '../../../../svg/RallyLogoHeader';
import { Button, Text } from '../../../../ui-components';
import { QueryKeys, Sizing, ThemeColors } from '../../../../constants';
import { DataPoint } from '../../../../ui-components/components/DataPoint/DataPoint';
import { QuadRow } from '../../../../ui-components/components/QuadRow/QuadRow';
import { MobileBox } from '../../../../ui-components/components/MobileBox/MobileBox';
import { GrowerDeliverableApi } from '../../../../utilities/api';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    padding: Sizing.BASE_SPACING,
    justifyContent: 'space-around',
  },
  pageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titles: {
    flex: 1,
  },
  headerLogo: {
    marginTop: -45,
    marginBottom: -45,
  },
  headerLandscape: {
    flexDirection: 'row',
  },
  headerPortrait: {
    flexDirection: 'column',
  },
  headerGroup: {
    flex: 0.5,
  },
  divider: {
    backgroundColor: 'white',
    marginVertical: Sizing.HALF_SPACING,
  },
  headerRow: {
    marginVertical: Sizing.HALF_SPACING,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerData: {
    fontSize: Sizing.BASE_SPACING,
  },
  headerIcon: {
    color: ThemeColors.PRIMARY,
    marginRight: Sizing.HALF_SPACING,
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
  },
  farmPlanName: {
    fontSize: 1.25 * Sizing.BASE_SPACING,
    fontWeight: 'bold',
  },
  dataPointsPortrait: {
    margin: 1.75 * Sizing.BASE_SPACING,
  },
  dataPointsLandscape: {
    margin: 1.5 * Sizing.BASE_SPACING,
  },
  centeredContent: {
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  row: {
    flexDirection: 'row',
  },
});

interface Props {
  selections: V2.Selections;
  snapshot: V2.Snapshot;
  deliverableId: string;
}

export const FarmPlanDeliverableMobileView: React.FunctionComponent<Props> = ({
  selections,
  snapshot,
  deliverableId,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  const [showAcresPlanned, setShowAcresPlanned] = useState(false);
  const [showProductCount, setShowProductCount] = useState(false);
  const [showCostSummary, setShowCostSummary] = useState(false);

  const [costPerAcre, setCostPerAcre] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [acresPlanned, setAcresPlanned] = useState(0);
  const windowDimensions = useWindowDimensions();
  const isLandscape = windowDimensions.width > windowDimensions.height;

  const getUser = async () => GrowerDeliverableApi.getPublicGrowerDeliverable(deliverableId);
  const [user, setUser] = useState(null);

  useQuery([QueryKeys.DELIVERABLE, deliverableId], getUser, {
    onSuccess: (res) => setUser(res.user),
  });

  const { logo, error, isLoading } = useLogo(user?.businessId);

  const themedStyles = useStyleSheet({
    logoDivider: {
      marginHorizontal: 24,
      height: 48,
      width: 1,
      backgroundColor: 'text-hint-color',
    },
  });

  useEffect(() => {
    if (!selections || !snapshot) {
      return;
    }
    setShowProductCount(true);
    setShowAcresPlanned(true);
    setShowCostSummary(selections.pricingDetails.costSummary);

    setTotalCost(snapshot.overview.orderTotal);
    setAcresPlanned(snapshot.overview.acresPlanned);
    setCostPerAcre(snapshot.overview.orderTotalPerAcre);

    const productSummary = snapshot.products;
    const uniqueProducts = new Set(productSummary.map((product) => product.productSkuName));
    setProductCount(uniqueProducts.size);
  }, [selections, snapshot]);

  const dataValues = [
    showAcresPlanned && (
      <DataPoint
        label={translate('ACRES_PLANNED')}
        value={StringUtility.localizeNumber(acresPlanned)}
      />
    ),
    showProductCount && (
      <DataPoint
        label={translate('PRODUCT_COUNT_LABEL')}
        value={StringUtility.localizeNumber(productCount)}
      />
    ),
    showCostSummary && (
      <DataPoint
        label={translate('TOTAL_COST')}
        value={StringUtility.formatFlatCurrencyShort(totalCost)}
      />
    ),
    showCostSummary && (
      <DataPoint
        label={translate('AVERAGE_COST_PER_ACRE')}
        value={StringUtility.formatFlatCurrencyShort(costPerAcre)}
      />
    ),
  ].filter((dataValue) => !!dataValue);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, flexShrink: 0 }} nativeID="vscroll">
      <ScrollView contentContainerStyle={{ flex: 1 }} horizontal nativeID="hscroll">
        {selections && snapshot && (
          <View nativeID="wrapper" style={styles.wrapper}>
            <View style={styles.pageHeader}>
              <View style={styles.titles}>
                <View style={styles.headerRow}>
                  <Text>{translate('FARM_PLAN_DELIVERABLE_FOR')}</Text>
                </View>
                <View style={styles.headerRow}>
                  <Text style={styles.farmPlanName}>
                    {snapshot.header.farmPlanName || ''}
                  </Text>
                </View>
              </View>
              {isLandscape && (
                <View style={[styles.headerLogo]}>
                  <View style={[styles.headerRow]}>
                    <RallyLogoHeader />
                  </View>
                </View>
              )}
            </View>
            <Divider style={styles.divider} />
            <View
              style={isLandscape ? styles.headerLandscape : styles.headerPortrait}
            >
              <View style={styles.headerGroup}>
                <View style={styles.headerRow}>
                  <Icon fill={ThemeColors.PRIMARY} name="GrowersPlanning" style={[styles.headerIcon]} testID="grower-icon" />
                  <Text style={styles.headerData}>
                    {snapshot.header.growerName || ''}
                  </Text>
                </View>
                <View style={styles.headerRow}>
                  <Icon fill={ThemeColors.PRIMARY} name="GrowersBusiness" style={[styles.headerIcon]} testID="business-icon" />
                  <Text style={styles.headerData}>
                    {snapshot.header.businessName || ''}
                  </Text>
                </View>
              </View>
              <View style={styles.headerGroup}>
                <View style={styles.headerRow}>
                  <Icon fill={ThemeColors.PRIMARY} name="Person" style={[styles.headerIcon]} testID="user-icon" />
                  <Text style={styles.headerData}>
                    {snapshot.header.businessUserName || ''}
                  </Text>
                </View>
                {snapshot.header.locationName && (
                  <View style={styles.headerRow}>
                    <Icon fill={ThemeColors.PRIMARY} name="Navigation2" style={[styles.headerIcon]} testID="location-icon" />
                    <Text style={styles.headerData}>
                      {snapshot.header.locationName}
                    </Text>
                  </View>
                )}
              </View>
            </View>

            {!isLandscape && <MobileBox dataValues={dataValues} />}
            {isLandscape && (
              <View style={styles.centeredContent}>
                <QuadRow cells={dataValues} style={styles.dataPointsLandscape} />
              </View>
            )}
            <View style={styles.centeredContent}>
              <Button
                onPress={() => LinkingUtility.openUrl(`${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`)}
                status="primary"
              >
                {translate('VIEW_PDF')}
              </Button>
              {!isLandscape && (
                <>
                  <VSpacer size="9" />
                  {isLoading && <CenteredSpinner />}
                  {
                    !!logo && (
                      <View style={styles.row}>
                        <Logo
                          padding={0}
                          shape="square"
                          size="large-rectangle"
                          source={logo}
                          testID="large-modal-logo"
                        />
                        <View style={themedStyles.logoDivider} />
                        <PoweredByGrowersLogoMobile />
                      </View>
                    )
                  }
                  {
                    ((!logo || error) && !isLoading) && (<PoweredByGrowersLogoMobileCentered />)
                  }
                </>
              )}
            </View>
          </View>
        )}
      </ScrollView>
    </ScrollView>
  );
};
