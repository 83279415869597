import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { errorIsDetailedApiError } from './api/DetailedApiError';

export const usePasswordErrorToMessage = (): (error: Error) => string => {
  const [translate] = useTranslation(['users', 'login', 'common']);

  const result = useCallback((error: Error) => {
    if (errorIsDetailedApiError(error)) {
      switch (error.code) {
        case 'short-password':
          return translate('PASSWORD_MUST_BE_EIGHT_CHARACTERS');
        case 'no-character':
          return translate('PASSWORD_MUST_HAVE_CHARACTER');
        case 'no-number':
          return translate('PASSWORD_MUST_HAVE_NUMBER');
        case 'no-special':
          return translate('PASSWORD_MUST_HAVE_SPECIAL');
        case 'invalid-reset-token':
          return translate('INVALID_RESET_TOKEN');
        case 'email-match-password':
          return translate('PASSWORD_CANT_BE_EMAIL');
        case 'name-match-password':
          return translate('PASSWORD_CANT_BE_NAME');
        default:
          return translate('UNEXPECTED_ERROR');
      }
    }

    // TODO: Fix this properly
    // UnexpectedError(error, 'Password Error');
    return translate('PASSWORD_INVALID');
  }, [translate]);

  return result;
};
