import React, { Component } from 'react';
import { View, ViewProps } from 'react-native';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import {
  Overwrite,
} from '@ui-kitten/components/devsupport';
import { StyleType } from '@ui-kitten/components/theme';

export type TableBackground = 'transparent';
export type TableBorder = 'top';

type TableStyledProps = Overwrite<StyledComponentProps, {}>;

export interface TableProps extends TableStyledProps, ViewProps {
  tableBackground?: TableBackground;
  tableBorder?: TableBorder;
  testID: string,
}

/**
 * The base table component.
 * Accepts children of type TableRow
 *
 * @property {ChildrenWithProps<TableRowProps>} children -
 * TableRow component to render within the table row.
 *
 * @property {StyleProp<ViewStyle>} style - Custom style sent to the table.
 *
 */

@styled('Table')
export class Table extends Component<TableProps> {
  private getComponentStyle = (source: StyleType) => {
    const { backgroundColor, borderTopColor, borderTopWidth } = source;
    return {
      table: {
        backgroundColor,
        borderTopColor,
        borderTopWidth,
      },
    };
  };

  public render () {
    const {
      children,
      eva,
      style,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View style={[evaStyle.table, style]} {...viewProps}>
        {children}
      </View>
    );
  }
}
