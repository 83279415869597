import React, { FunctionComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { AreaPlannedResult } from '@shared/utils';
import { testId } from '../../utilities/testId';
import { Sizing, ThemeColors } from '../../constants';
import { Text } from '../../ui-components';
import { StringUtility } from '../../utilities';

const styles = StyleSheet.create({
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  summaryCardHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  summaryTotal: {
    flexDirection: 'row',
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: 1,
    padding: Sizing.BASE_SPACING,
  },
  summaryCallout: {
    marginHorizontal: Sizing.DOUBLE_SPACING,
    marginBottom: Sizing.BASE_SPACING,
    padding: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.DARKEST_GRAY,
    borderRadius: Sizing.HALF_SPACING,
    alignItems: 'center',
  },
  acresPlannedCrop: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
});

export interface FarmPlanAcresPlannedSummaryResult extends AreaPlannedResult {
  passes?: number;
}

export interface FarmPlanAcresPlannedSummaryFields {
  acresPlannedPerCrop: boolean;
  passes: boolean;
}

interface FarmPlanAcresPlannedSummaryProps {
  planned: FarmPlanAcresPlannedSummaryResult;
  showField?: FarmPlanAcresPlannedSummaryFields;
  costPerAreaVisible?: boolean;
}

export const FarmPlanAcresPlannedSummary
: FunctionComponent<FarmPlanAcresPlannedSummaryProps> = (props) => {
  const {
    planned,
    showField = {
      acresPlannedPerCrop: true,
      passes: false,
    },
    costPerAreaVisible = false,
  } = props;
  const [translate] = useTranslation(['farmPlans', 'growers', 'prepare', 'common']);

  return (
    <>
      <View style={{ padding: Sizing.BASE_SPACING }}>
        <Text style={styles.summaryCardHeader}>{translate('ACRES_PLANNED')}</Text>
      </View>
      <View style={styles.summaryCallout}>
        <Text style={{ paddingRight: Sizing.BASE_SPACING }}>{translate('TOTAL_ACRES_PLANNED')}</Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <Text nativeID="acres-planned-total-summary" style={[styles.summaryCardHeader, { marginRight: Sizing.QUARTER_SPACING }]}>
            {StringUtility.localizeNumber(planned.planned, 0)}
            /
            {StringUtility.localizeNumber(planned.total, 0)}
          </Text>
          <Text style={{ paddingBottom: 0.125 * Sizing.EM }}>
            {translate(planned.areaUnitType === 'ac' ? 'ACRES' : planned.areaUnitType)}
          </Text>
        </View>
      </View>
      {showField.passes && (
        <View style={styles.summaryCallout}>
          <Text style={{ paddingRight: Sizing.BASE_SPACING }}>
            {translate('NUMBER_OF_PASSES')}
          </Text>
          <View
            {...testId('number-of-passes')}
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <Text style={[styles.summaryCardHeader, { marginRight: Sizing.QUARTER_SPACING }]}>
              {planned.passes}
            </Text>
          </View>
        </View>
      )}
      {showField.acresPlannedPerCrop && planned.crops.map((crop) => (
        <View
          {...testId('acres-planned')}
          key={`${crop.type} ${crop.subType}`}
          style={styles.summaryTotal}
        >
          <View style={styles.acresPlannedCrop}>
            <Text
              {...testId(crop.subType ? `${crop.type}-${crop.subType}`
                : crop.type)}
              numberOfLines={1}
              style={[styles.largeText, { flex: 1 }]}
            >
              {
                crop.subType
                  ? `${translate(crop.type)} | ${translate(crop.subType)}`
                  : translate(crop.type)
              }
            </Text>
            <Text
              {...testId(`${crop.subType ? `${crop.type}-${crop.subType}`
                : crop.type}-cost-per-acre`)}
              style={[styles.largeText, { flex: 1 }]}
            >
              {costPerAreaVisible && translate('PER_SHORT',
                {
                  area: StringUtility.formatCurrencyAccounting(crop.costPerArea) ?? 0,
                  areaUnitType: planned.areaUnitType,
                })}
            </Text>
            <Text
              {...testId(`${crop.subType ? `${crop.type}-${crop.subType}`
                : crop.type}-area-value`)}
              style={styles.largeText}
            >
              {crop.percent}
              %,&nbsp;
              {StringUtility.localizeNumber(crop.areaValue, 0)}
              &nbsp;
              {translate(planned.areaUnitType === 'ac' ? 'ACRES' : planned.areaUnitType)}
            </Text>
          </View>
        </View>
      ))}
    </>
  );
};
