import { VSpacer } from '@design/Spacer';
import React, { FC, useRef, useState } from 'react';
import {
  Animated,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewProps,
} from 'react-native';
import {
  styled,
  StyleType,
  StyledComponentProps,
} from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { RallyLogoHeader } from '../../../../svg/RallyLogoHeader';
import { INavigationItemProps, NavItem } from './NavItem';
import { Icon } from '../Icon/Icon';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  logo: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
  },
  overflow: {
    width: '100%',
  },
  navigation: {
    height: '100%',
    position: 'absolute',
    zIndex: 9999,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  section: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  flex: {
    flex: 1,
  },
});

export interface INavigationSectionProps {
  label: string;
  navItems: INavigationItemProps[];
}

export interface NavigationProps extends ViewProps, StyledComponentProps {
  expanded?: boolean,
  helpSection?: INavigationSectionProps[],
  sections: INavigationSectionProps[],
  testID: string,
}

const getComponentStyle = (source: StyleType) => {
  const sectionStyles = PropsServiceHelper.allWithPrefixMapped(source, 'section');
  const headerLogoStyles = PropsServiceHelper.allWithPrefixMapped(source, 'headerLogo');
  const expandedHeaderLogoStyles = PropsServiceHelper.allWithPrefixMapped(source, 'expandedHeaderLogo');
  const footerLogoStyles = PropsServiceHelper.allWithPrefixMapped(source, 'footerLogo');
  const containerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'container');
  const expandedContainerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'expandedContainer');
  const collapseIconStyles = PropsServiceHelper.allWithPrefixMapped(source, 'collapseIcon');
  const expandedCollapseIconStyles = PropsServiceHelper.allWithPrefixMapped(source, 'expandedCollapseIcon');
  const sectionSeparatorStyles = PropsServiceHelper.allWithPrefixMapped(source, 'sectionSeparator');
  const sectionSeparatorExpandedStyles = PropsServiceHelper.allWithPrefixMapped(source, 'sectionSeparatorExpanded');
  const sectionHeaderStyles = PropsServiceHelper.allWithPrefixMapped(source, 'sectionHeader');

  const {
    backgroundColor,
    elevation,
    ...baseStyles
  } = source;

  return {
    container: {
      backgroundColor,
      ...baseStyles,
    },
    section: sectionStyles,
    headerLogoStyles,
    expandedHeaderLogoStyles,
    footerLogoStyles,
    containerStyles,
    expandedContainerStyles,
    collapseIconStyles,
    expandedCollapseIconStyles,
    sectionSeparatorContainer: {
      paddingTop: sectionSeparatorStyles.paddingTop,
      paddingBottom: sectionSeparatorStyles.paddingBottom,
    },
    sectionSeparatorExpandedContainer: {
      paddingTop: sectionSeparatorExpandedStyles.paddingTop,
      paddingBottom: sectionSeparatorExpandedStyles.paddingBottom,
    },
    sectionSeparator: {
      borderWidth: sectionSeparatorStyles.borderWidth,
      borderColor: sectionSeparatorStyles.borderColor,
    },
    sectionHeaderStyles,
    elevation,
  };
};

const NavigationRaw: FC<NavigationProps> = ({
  eva,
  style,
  helpSection,
  sections,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const widthAnim = useRef(new Animated.Value(68)).current;
  const opacityAnim = useRef(new Animated.Value(1)).current;

  const evaStyles = getComponentStyle(eva.style);

  const expand = () => {
    Animated.parallel([
      Animated.timing(widthAnim, {
        toValue: 260,
        useNativeDriver: false,
        duration: 150,
      }),
      Animated.timing(opacityAnim, {
        toValue: 0,
        useNativeDriver: false,
        duration: 150,
      }),
    ]).start();
  };

  const collapse = () => {
    Animated.parallel([
      Animated.timing(widthAnim, {
        toValue: 68,
        useNativeDriver: false,
        duration: 200,
      }),
      Animated.timing(opacityAnim, {
        toValue: 1,
        useNativeDriver: false,
        duration: 200,
      }),
    ]).start();
  };

  const toggleExpand = () => {
    if (isExpanded) {
      collapse();
    } else {
      expand();
    }

    setIsExpanded(!isExpanded);
  };

  const renderSection = (links: INavigationSectionProps[], showLine?: boolean) => {
    return (
      <>
        {links?.map((section, index, sectionArray) => (
          <View
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${section}`}
            testID={section.label}
          >
            {isExpanded && (
              <Text
                appearance="hint"
                category="label"
                numberOfLines={1}
                style={[
                  evaStyles.expandedContainerStyles,
                  evaStyles.sectionHeaderStyles,
                  styles.section,
                ]}
              >
                {section.label}
              </Text>
            )}
            {section.navItems?.map((navItem) => (
              <NavItem
                expanded={isExpanded}
                key={navItem.label}
                onNavigate={isExpanded ? toggleExpand : undefined}
                style={evaStyles.containerStyles}
                {...navItem}
              />
            ))}
            {((index + 1 < sectionArray.length && index + 1 !== sectionArray.length)
            || showLine) && (
              <>
                <VSpacer size="3" />
                <View style={[
                  evaStyles.sectionSeparatorContainer,
                  isExpanded && evaStyles.sectionSeparatorExpandedContainer,
                ]}
                >
                  <View style={[evaStyles.sectionSeparator]} />
                </View>
              </>
            )}
          </View>
        ))}
      </>
    );
  };

  return (
    <>
      {isExpanded && (
        <TouchableOpacity onPress={toggleExpand} style={[styles.navigation, styles.overflow]} />
      )}
      <Animated.View
        style={[
          styles.navigation,
          evaStyles.container,
          style,
          { width: widthAnim },
          isExpanded ? evaStyles.elevation : undefined,
        ]}
      >
        <View style={evaStyles.containerStyles}>
          {isExpanded ? (
            <View style={styles.logo}>
              <RallyLogoHeader />
            </View>
          ) : (
            <Icon
              name="RallyIcon"
              style={
                StyleSheet.flatten([
                  evaStyles.headerLogoStyles,
                  isExpanded && evaStyles.expandedHeaderLogoStyles,
                ])
              }
              testID="rally-logo-icon"
            />
          )}
        </View>
        <VSpacer size="5" />
        <View
          style={
            StyleSheet.flatten([
              evaStyles.collapseIconStyles,
              isExpanded && evaStyles.expandedCollapseIconStyles,
            ])
          }
        >
          <IconButton
            appearance="ghost"
            onPress={toggleExpand}
            status="basic"
            testID="expand-and-collapse-button"
          >
            {isExpanded ? 'ArrowBack' : 'ArrowForward'}
          </IconButton>
        </View>
        <ScrollView
          contentContainerStyle={styles.scrollView}
          showsVerticalScrollIndicator={false}
        >
          {renderSection(sections)}
          <View style={styles.flex} />
          {!!helpSection?.length && renderSection(helpSection, true)}
          <Animated.View style={[evaStyles.containerStyles, { opacity: opacityAnim }]}>
            <VSpacer size="8" />
            <Icon
              name="GrowersIcon"
              style={evaStyles.footerLogoStyles}
              testID="growers-icon"
            />
          </Animated.View>
        </ScrollView>
      </Animated.View>
    </>
  );
};

export const Navigation = styled('Navigation')(NavigationRaw);
