import {
  LargeModal, useToast,
} from '@design';
import { ApiPriceType } from '@shared/interfaces/api';
import { getCurrentDateTime } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { PriceTypeApi } from '../../../../utilities/api/PriceTypeApi';
import { ButtonBar } from '../../../components/shared/ButtonBar';
import {
  EditFormPriceType,
  ProductSettingsEditPriceTypeForm,
} from './ProductSettingsEditPriceTypeForm';

interface ProductSettingsPriceTypeModalProps {
  isLoading: boolean,
  onClose: () => void,
  onDelete: (id: string) => void,
  priceTypes: ApiPriceType[],
}

export const ProductSettingsPriceTypeModal = ({
  isLoading,
  onClose,
  onDelete,
  priceTypes,
}: ProductSettingsPriceTypeModalProps) => {
  const { currentBusinessId } = useAuthentication();
  const [translate] = useTranslation(['businesses', 'common', 'errors']);
  const { createToast } = useToast();
  const [formErrorIds, setFormErrorIds] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [priceTypeNames, setPriceTypeNames] = useState<EditFormPriceType[]>([]);
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);

  const onChangeText = (text: string, index: number) => {
    if (!isDirty) {
      setIsDirty(true);
    }
    const newPriceTypeNames = priceTypeNames.map((priceTypeName, i) => {
      if (i === index) {
        return { ...priceTypeName, name: text };
      }

      return priceTypeName;
    });
    setPriceTypeNames(newPriceTypeNames);
  };

  const onAddPriceType = () => {
    setPriceTypeNames([...priceTypeNames, { key: getCurrentDateTime(), name: '' }]);
  };

  const handleDelete = async (id: string, index: number) => {
    if (id) {
      onDelete(id);
    } else {
      setPriceTypeNames(priceTypeNames.filter((v, i) => i !== index));
    }
  };

  const isPriceTypeNamesUnique = () => {
    let isUnique = true;
    const ids = [];
    const names: string[] = [];
    priceTypeNames.forEach(({ id, key, name }) => {
      if (names.includes(name.trim().toLowerCase())) {
        isUnique = false;
        ids.push(id || key);
      }
      if (name) {
        names.push(name.trim().toLowerCase());
      }
    });
    return { isUnique, ids };
  };

  const isOriginalPriceTypeName = (id, name) => {
    return priceTypes.find((v) => v.id === id)?.name === name.trim();
  };

  const mapPriceTypeNamesToApiCalls = (): Promise<ApiPriceType>[] => {
    return priceTypeNames.reduce((apiCalls, currentPriceType) => {
      const { id, name } = currentPriceType;
      if ((id && name) && !isOriginalPriceTypeName(id, name)) {
        apiCalls.push(PriceTypeApi.update(id, { name }));
      } else if (!id && name) {
        apiCalls.push(PriceTypeApi.create({ businessId: currentBusinessId, name }));
      }
      return apiCalls;
    }, []);
  };

  const onSubmit = async () => {
    setFormErrorIds([]);
    const { isUnique, ids } = isPriceTypeNamesUnique();
    if (!isUnique) {
      setFormErrorIds(ids);
      return;
    }
    setSaving(true);
    try {
      await Promise.all(mapPriceTypeNamesToApiCalls()).then((values) => {
        if (values.length) {
          createToast({
            children: translate<string>('PRICE_TYPES_SUCCESSFULLY_SAVED'),
            status: 'success',
            testID: 'toast-content-element',
          });
        }
      });
      queryClient.invalidateQueries([QueryKeys.PRICE_TYPE_LIST]);
      setSaving(false);
      onClose();
    } catch (error) {
      setSaving(false);
      createToast({
        children: translate<string>('ERROR_SAVING_PRICE_TYPES'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
  };

  useEffect(() => {
    if (priceTypes && priceTypes.length) {
      setPriceTypeNames(priceTypes.map(({ id, isDefault, name }) => ({ id, isDefault, name })));
    }
  }, [priceTypes]);

  useEffect(() => {
    if (priceTypeNames && priceTypeNames.length) {
      let valid = true;
      priceTypeNames.forEach(({ name }) => {
        if (!name.trim()) {
          valid = false;
        }
      });
      setIsValid(valid);
    }
  }, [priceTypeNames]);

  const Footer = () => (
    <ButtonBar
      disableRightAction={!isDirty || !isValid || saving}
      leftAction={() => onClose()}
      leftButtonText={translate<string>('CANCEL')}
      rightAction={onSubmit}
      rightButtonText={translate<string>('SAVE')}
      testID="edit-price-type-form-modal-footer"
    />
  );

  return (
    <>
      <LargeModal
        footer={() => <Footer />}
        pages={[
          <ProductSettingsEditPriceTypeForm
            addPriceType={onAddPriceType}
            errorIds={formErrorIds}
            isLoading={isLoading}
            onChangeText={onChangeText}
            onDelete={handleDelete}
            priceTypes={priceTypeNames}
          />]}
        testID="edit-price-type-form-modal"
        title={translate<string>('EDIT_PRICE_TYPE')}
        visible
      />
    </>
  );
};
