import React, { FunctionComponent, useEffect, useState } from 'react';
import { IndexPath, Layout, Text, Toggle } from '@ui-kitten/components';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useHistory, useParams } from 'react-router-dom';
import { ResponsiveContainer, ResponsiveView, Select, SelectItem, useTheme, VSpacer } from '@design';
import { AccordionDemo } from './demos/AccordionDemo';
import { AutoCompleteDemo } from './demos/AutoCompleteDemo';
import { BadgeDemo } from './demos/BadgeDemo';
import { BannerDemo } from './demos/BannerDemo';
import { ButtonDemo } from './demos/ButtonDemo';
import { ButtonBarDemo } from './demos/ButtonBarDemo';
import { CardDemo } from './demos/CardDemo';
import { CheckBoxDemo } from './demos/CheckBoxDemo';
import { ComboBoxDemo } from './demos/ComboBoxDemo';
import { ContactCardDemo } from './demos/ContactCardDemo';
import { ChipDemo } from './demos/ChipDemo';
import { ColorDemo } from './demos/ColorDemo';
import { ColorSelectDemo } from './demos/ColorSelectDemo';
import { CustomTagsDemo } from './demos/CustomTagsDemo';
import { DataPointDemo } from './demos/DataPointDemo';
import { DialogDemo } from './demos/DialogDemo';
import { EmptyStateDemo } from './demos/EmptyState';
import { FilterDemo } from './demos/FilterDemo';
import { FilterMenuDemo } from './demos/FilterMenuDemo';
import { FilterTableDemo } from './demos/FilterTableDemo';
import { FullPageModalDemo } from './demos/FullPageModalDemo';
import { GlobalBannerDemo } from './demos/GlobalBannerDemo';
import { HeaderDemo } from './demos/Header';
import { IconDemo } from './demos/IconDemo';
import { InputDemo } from './demos/InputDemo';
import { LayoutDemo } from './demos/LayoutDemo';
import { ListDemo } from './demos/ListDemo';
import { ListModalDemo } from './demos/ListModalDemo';
import { LogoDemo } from './demos/LogoDemo';
import { ModalDemo } from './demos/ModalDemo';
import { NavigationDemo } from './demos/NavigationDemo';
import { PageHeaderDemo } from './demos/PageHeaderDemo';
import { PaginationDemo } from './demos/PaginationDemo';
import { ProductCardDemo } from './demos/ProductCardDemo';
import { ProductOrderProductCardDemo } from './demos/ProductOrderProductCardDemo';
import { RadioDemo } from './demos/RadioDemo';
import { Routes } from '../../../constants';
import { SearchDemo } from './demos/SearchDemo';
import { SelectDemo } from './demos/SelectDemo';
import { ShareDeliverableModalDemo } from './demos/ShareDeliverableModalDemo';
import { SidePanelDemo } from './demos/SidePanelDemo';
import { SpacerDemo } from './demos/SpacerDemo';
import { StepIndicatorDemo } from './demos/StepIndicatorDemo';
import { SubNavigationDemo } from './demos/SubNavigationDemo';
import { TableDemo } from './demos/TableDemo';
import { TabsDemo } from './demos/TabsDemo';
import { TextDemo } from './demos/TextDemo';
import { TextLinkDemo } from './demos/TextLinkDemo';
import { ToastDemo } from './demos/ToastDemo';
import { ToggleDemo } from './demos/ToggleDemo';
import { PropertyEditorDemo } from './demos/PropertyEditorDemo';
import { PropertyGroupCardDemo } from './demos/PropertyGroupCardDemo';

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'flex-start',
  },
  darkModeSetting: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bar: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
});

const components: { label: string, component: React.FunctionComponent }[] = [
  {
    label: 'Pagination',
    component: PaginationDemo,
  },
  {
    label: 'Text',
    component: TextDemo,
  },
  {
    label: 'Text Link',
    component: TextLinkDemo,
  },
  {
    label: 'Button',
    component: ButtonDemo,
  },
  {
    label: 'Filter',
    component: FilterDemo,
  },
  {
    label: 'FilterTable',
    component: FilterTableDemo,
  },
  {
    label: 'Selects',
    component: SelectDemo,
  },
  {
    label: 'Input',
    component: InputDemo,
  },
  {
    label: 'Icon',
    component: IconDemo,
  },
  {
    label: 'Toggle',
    component: ToggleDemo,
  },
  {
    label: 'Radio',
    component: RadioDemo,
  },
  {
    label: 'Checkbox',
    component: CheckBoxDemo,
  },
  {
    label: 'Card',
    component: CardDemo,
  },
  {
    label: 'Chip',
    component: ChipDemo,
  },
  {
    label: 'Dialog',
    component: DialogDemo,
  },
  {
    label: 'EmptyState',
    component: EmptyStateDemo,
  },
  {
    label: 'ShareDeliverableModal',
    component: ShareDeliverableModalDemo,
  },
  {
    label: 'Spacer',
    component: SpacerDemo,
  },
  {
    label: 'Modal',
    component: ModalDemo,
  },
  {
    label: 'Full Page Modal',
    component: FullPageModalDemo,
  },
  {
    label: 'Badge',
    component: BadgeDemo,
  },
  {
    label: 'Colors',
    component: ColorDemo,
  },
  {
    label: 'Toast',
    component: ToastDemo,
  },
  {
    label: 'Accordion',
    component: AccordionDemo,
  },
  {
    label: 'Banner',
    component: BannerDemo,
  },
  {
    label: 'Data Point',
    component: DataPointDemo,
  },
  {
    label: 'Table',
    component: TableDemo,
  },
  {
    label: 'Search',
    component: SearchDemo,
  },
  {
    label: 'Header',
    component: HeaderDemo,
  },
  {
    label: 'Page Header',
    component: PageHeaderDemo,
  },
  {
    label: 'Side Panel',
    component: SidePanelDemo,
  },
  {
    label: 'Color Select',
    component: ColorSelectDemo,
  },
  {
    label: 'Navigation',
    component: NavigationDemo,
  },
  {
    label: 'Autocomplete',
    component: AutoCompleteDemo,
  },
  {
    label: 'FilterMenu',
    component: FilterMenuDemo,
  },
  {
    label: 'Global Banner',
    component: GlobalBannerDemo,
  },
  {
    label: 'Step Indicator',
    component: StepIndicatorDemo,
  },
  {
    label: 'Tabs',
    component: TabsDemo,
  },
  {
    label: 'Layout',
    component: LayoutDemo,
  },
  {
    label: 'List',
    component: ListDemo,
  },
  {
    label: 'ListModal',
    component: ListModalDemo,
  },
  {
    label: 'Customer Logo',
    component: LogoDemo,
  },
  {
    label: 'Product Card',
    component: ProductCardDemo,
  },
  {
    label: 'Product Card (QQ)',
    component: ProductOrderProductCardDemo,
  },
  {
    label: 'ProperyGroupCard in ReorderingList',
    component: PropertyGroupCardDemo,
  },
  {
    label: 'Button Bar',
    component: ButtonBarDemo,
  },
  {
    label: '[MUI] Combo Box',
    component: ComboBoxDemo,
  },
  {
    label: 'Contact Card',
    component: ContactCardDemo,
  },
  {
    label: 'Custom Tags',
    component: CustomTagsDemo,
  },
  {
    label: 'Sub Navigation',
    component: SubNavigationDemo,
  },
  {
    label: 'Property Editor',
    component: PropertyEditorDemo,
  },
];

components.sort((a, b) => a.label.localeCompare(b.label));
const defaultToTab = (tab: string) => {
  const index = components.findIndex((c) => c.label === tab);
  if (index >= 0) {
    return new IndexPath(index);
  }
  return null;
};

export const UXSandbox: FunctionComponent = () => {
  const { theme, toggleTheme } = useTheme();
  const { demo = '' } = useParams<{ demo: string }>();
  const history = useHistory();
  const [selectedComponent, setSelectedComponent] = useState<IndexPath>(defaultToTab(demo));

  useEffect(() => {
    setSelectedComponent(defaultToTab(demo));
  }, [demo]);

  const RenderComponent = components[selectedComponent?.row]?.component;

  return (
    <ResponsiveContainer>
      <Layout level="3" nativeID="sandbox-root" style={styles.root}>
        <ResponsiveView medium={12} small={8} style={styles.bar} xSmall={4}>
          <ResponsiveView medium={4}>
            <Select
              label="Component"
              onSelect={(index) => {
                history.push(Routes.UX_SANDBOX.replace(':demo?', components[(index as IndexPath).row].label));
                setSelectedComponent(index as IndexPath);
              }}
              placeholder="Choose a demo..."
              selectedIndex={selectedComponent}
              testID="ux-sandbox-selector"
              value={components[selectedComponent?.row]?.label}
            >
              {
                components.map((c, i) => (
                  <SelectItem key={c.label} testID={`ux-component-dropdown-value-${i}`} title={c.label} />
                ))
              }
            </Select>
          </ResponsiveView>
          <View style={styles.darkModeSetting}>
            <Text>Light Mode </Text>
            <Toggle
              checked={theme === 'dark'}
              onChange={() => toggleTheme()}
            />
            <Text> Dark Mode</Text>
          </View>
        </ResponsiveView>
        <VSpacer size="5" />
        <ScrollView style={{ alignSelf: 'stretch' }}>
          {RenderComponent && <RenderComponent />}
        </ScrollView>
      </Layout>
    </ResponsiveContainer>
  );
};
