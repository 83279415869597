import { Button, Dialog, HSpacer, Text, useToast } from '@design';
import { ApiUserAccount } from '@shared/interfaces/api';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UnexpectedError } from '../../../ui-components/utils/UnexpectedError';
import { UserApi } from '../../../utilities/api';

interface Props {
  user?: ApiUserAccount;
  onCancel: () => void;
  onUserDeleted: (oldUser: ApiUserAccount) => void;
}

export const DeleteUserDialog: FC<Props> = ({
  user,
  onCancel,
  onUserDeleted,
}) => {
  const { t } = useTranslation(['common', 'users']);
  const { createToast } = useToast();

  const deleteUser = useCallback(() => {
    const { id } = user;
    UserApi.deleteUser(id).then(() => {
      onUserDeleted(user);
      createToast({
        status: 'success',
        children: t('USER_UPDATED') as string,
        testID: 'toast-content-element',
      });
    }).catch((err) => {
      UnexpectedError(err, 'Error Deleting User', {
        id,
      });
      onCancel();
    });
  }, [createToast, onCancel, onUserDeleted, t, user]);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button {...primaryButtonProp} testID="cancel-button">{t('CANCEL') as string}</Button>
          <HSpacer {...spacerProp} />
          <Button {...secondaryButtonProp} onPress={deleteUser} testID="delete-button">
            {t('DELETE') as string}
          </Button>
        </>
      )}
      onClose={onCancel}
      status="danger"
      testID="delete-user-dialog"
      visible={!!user}
    >
      <Text>
        {t('USER_DELETE_MODAL_WARNING') as string}
      </Text>
    </Dialog>
  );
};
