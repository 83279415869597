import {
  Button,
  HSpacer,
  Input as DesignSystemInput,
  Modal,
  Text,
  Toggle,
  useToast,
  VSpacer,
} from '@design';
import { GrowerContactEndpoint } from '@shared/interfaces/api';
import { validateEmail, validatePhoneNumber } from '@shared/utils/validators';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, StyleSheet } from 'react-native';
import { StateSelect } from '../../components/shared/Selects/StateSelect';
import { AddContactTagsModal } from '../../components/shared/CustomTags/AddContactTagsModal';
import { CountrySelect } from '../../components/shared/Selects/CountrySelect';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { ContactTagsChipSet } from './ContactTagsChipSet';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../constants/QueryKeys';
import { BusinessApi } from '../../../utilities/api/BusinessApi';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { RoleUtility, Permissions } from '@shared/utils';
import _ from 'lodash';

const modalWidth = 560;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 512,
  },
  inputStyles: {
    width: 496,
    paddingLeft: 0,
    paddingRight: 0,
    margin: 0,
  },
  toggleGroupStyle: {
    flexDirection: 'row',
    marginBottom: 24,
    alignItems: 'center',
  },
});

export interface ContactModalProps {
  data: GrowerContactEndpoint.Update.Request,
  isEdit?: boolean,
  growerId: string,
  isOnlyContact?: boolean,
  onCancel: () => void,
  onCreate: (contact: GrowerContactEndpoint.Create.Request) => void,
  onUpdate: (contact: GrowerContactEndpoint.Update.Request) => void,
}

const Input = (props) => {
  return (
    <>
      <DesignSystemInput
        size="medium"
        style={styles.inputStyles}
        {...props}
      />
      <VSpacer size="7" />
    </>
  );
};

export const ContactModal = ({
  data,
  isEdit = false,
  growerId,
  isOnlyContact = false,
  onCancel,
  onCreate,
  onUpdate,
}: ContactModalProps) => {
  const [translate] = useTranslation(['common', 'errors', 'users', 'growers']);
  const [isAddTagsModalVisible, setIsAddTagsModalVisible] = useState(false);
  const { currentBusinessId, user } = useAuthentication();

  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [email, setEmail] = useState(data?.email);
  const [emailError, setEmailError] = useState(false);
  const [telephoneError, setTelephoneError] = useState(false);

  const [isPrimary, setIsPrimary] = useState(data?.isPrimary || isOnlyContact);

  const [telephone, setTelephone] = useState(data?.telephone);
  const [address1, setAddress1] = useState(data?.address1);
  const [address2, setAddress2] = useState(data?.address2);
  const [country, setCountry] = useState(data?.country);
  const [countryName, setCountryName] = useState(data?.country);
  const [state, setState] = useState(data?.state);
  const [city, setCity] = useState(data?.city);
  const [postalCode, setPostalCode] = useState(data?.postalCode);

  const [states, setStates] = useState([]);
  const [assignedTags, setAssignedTags] = useState(data?.tags ?? []);

  const { createToast } = useToast();
  const isSuperOrAdmin = RoleUtility.roleHasPermission(
    user.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const { data: businessTags } = useQuery(
    [QueryKeys.BUSINESS_TAGS, currentBusinessId],
    async () => {
      try {
        return await BusinessApi.getBusinessTags(currentBusinessId);
      } catch (err) {
        if (err instanceof DetailedApiError) {
          createToast({
            children: err.message,
            status: 'warning',
            testID: 'business-tags-query-error-toast',
          });
        }
      }
    },
  );

  const formValid = (): boolean => {
    let newData = { ...data };

    newData.firstName = firstName;
    newData.lastName = lastName;
    newData.email = email;
    newData.isPrimary = isPrimary;
    newData.telephone = telephone;
    newData.address1 = address1;
    newData.address2 = address2;
    newData.country = country;
    newData.state = state;
    newData.city = city;
    newData.postalCode = postalCode;
    newData.tags = assignedTags;

    if (_.isEqual(data, newData)) {
      return false;
    }

    if (!!telephone && !validatePhoneNumber(telephone)) return false;
    if (!!email && !validateEmail(email)) return false;
    if (firstName && lastName) return true;
    return false;
  };

  const saveForm = () => {
    const contactData = {
      businessId: currentBusinessId,
      growerId,
      firstName,
      lastName,
      email: email || null,
      isPrimary,
      telephone: telephone || null,
      address1: address1 || null,
      address2: address2 || null,
      country,
      state: state || null,
      city: city || null,
      postalCode: postalCode || null,
      tags: assignedTags,
    };

    if (!data) {
      onCreate(contactData);
      return;
    }
    onUpdate(contactData);
  };

  const formContent = () => (
    <ScrollView
      scrollToOverflowEnabled
      showsVerticalScrollIndicator
      style={styles.container}
      testID="contact-form"
    >
      <Input
        isRequired
        onChangeText={setFirstName}
        placeholder={translate<string>('FIRST_NAME')}
        testID="contact-form-first-name"
        value={firstName}
      />
      <Input
        isRequired
        onChangeText={setLastName}
        placeholder={translate<string>('LAST_NAME')}
        testID="contact-form-last-name"
        value={lastName}
      />
      <Input
        autoCapitalize="none"
        caption={translate<string>(emailError ? 'VALID_EMAIL_REQUIRED' : '')}
        defaultValue={email}
        keyboardType="email-address"
        onBlur={() => setEmailError(!!email && !validateEmail(email))}
        onChangeText={setEmail}
        placeholder={translate<string>('EMAIL')}
        status={emailError ? 'danger' : undefined}
        testID="contact-form-email"
      />

      <View style={styles.toggleGroupStyle}>
        <Toggle
          checked={isPrimary || isOnlyContact}
          disabled={data?.isPrimary || isOnlyContact}
          onChange={(checked) => setIsPrimary(checked)}
          size="small"
          status="basic"
        />
        <HSpacer size="4" />
        <Text
          appearance="hint"
          category="c2"
        >
          {translate<string>('SET_AS_PRIMARY')}
        </Text>
      </View>

      <Input
        caption={telephoneError && translate<string>('VALID_PHONE_REQUIRED')}
        defaultValue={telephone}
        keyboardType="phone-pad"
        onBlur={() => setTelephoneError(!!telephone && !validatePhoneNumber(telephone))}
        onChangeText={setTelephone}
        placeholder={translate<string>('PHONE')}
        status={telephoneError ? 'danger' : undefined}
        testID="phone-field"
      />
      <Input
        onChangeText={setAddress1}
        placeholder={translate<string>('ADDRESS1')}
        testID="contact-form-address-one"
        value={address1}
      />
      <Input
        onChangeText={setAddress2}
        placeholder={translate<string>('ADDRESS2')}
        testID="contact-form-address-two"
        value={address2}
      />
      <CountrySelect
        country={countryName}
        onUpdateCountry={(name, abbreviation, statesList) => {
          setCountry(abbreviation);
          setCountryName(name);
          setStates(statesList);
        }}
        style={[styles.inputStyles, { marginBottom: 24 }]}
        testID="contact-form-country"
      />
      <StateSelect
        onUpdateState={(selectedState) => setState(selectedState)}
        state={state}
        states={states}
        style={[styles.inputStyles, { marginBottom: 24 }]}
        testID="contact-form-state"
      />
      <Input
        onChangeText={setCity}
        placeholder={translate<string>('CITY')}
        testID="contact-form-city"
        value={city}
      />
      <Input
        onChangeText={setPostalCode}
        placeholder={translate<string>('POSTAL_CODE')}
        testID="contact-form-zip"
        value={postalCode}
      />
      <View style={{ alignItems: 'flex-start' }}>
        <ContactTagsChipSet
          assignedTags={assignedTags}
          handleOnAddTags={() => { setIsAddTagsModalVisible(true); }}
          handleOnTagRemove={(index) => {
            const tags = [...assignedTags];
            tags.splice(index, 1);
            setAssignedTags(tags);
          }}
        />
      </View>
    </ScrollView>
  );

  return (
    <>
      <Modal
        footerAccessory={() => (
          <>
            <Button
              appearance="ghost"
              onPress={onCancel}
              size="medium"
              status="basic"
              testID="contact-modal-cancel-button"
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer size="4" />
            <Button
              disabled={!formValid()}
              onPress={saveForm}
              size="medium"
              testID="contact-modal-save-button"
            >
              {translate<string>('SAVE')}
            </Button>
          </>
        )}
        hideCloseButton
        maxHeight
        style={{ backgroundColor: '#181817' }}
        testID="contact-form-modal"
        visible
        width={modalWidth}
      >
        <>
          <Text>{
            isEdit 
              ? translate<string>('EDIT_CONTACT_TITLE') 
              : translate<string>('ADD_CONTACT_TITLE')}
          </Text>
          <VSpacer size="7" />
          {formContent()}
        </>
      </Modal>
      {isAddTagsModalVisible &&
        <AddContactTagsModal
          allowCustomTags={isSuperOrAdmin}
          assignedTags={assignedTags}
          businessId={currentBusinessId}
          initialTags={businessTags}
          onClose={() => setIsAddTagsModalVisible(false)}
          onSave={(tags) => {
            setIsAddTagsModalVisible(false);
            setAssignedTags(tags);
          }}
        />
      }
    </>
  );
};
