import { SharedConfig } from '@shared/constants';
import { UserType } from '@shared/enums';
import {
  ApiUserAccount,
  ApiUserAccountCreate,
  ApiUserAccountQuerySort,
  ApiUserAccountUpdate,
  ApiUpdatePassword,
  ImportEndpoint,
  UserAccountEndpoint,
} from '@shared/interfaces/api';
import * as _ from 'lodash';
import qs from 'qs';
import { CSVResponse } from '../helpers/exportCSV';
import { Client } from './Client';

export class UserApi {
  static async getUsers (
    filters: UserAccountEndpoint.List.Request,
  ): Promise<UserAccountEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`users?${query}`);
  }

  static async getUserById (id: string): Promise<ApiUserAccount> {
    return Client(`users/${id}`);
  }

  static async getAllUsersForBusiness (
    businessId: string,
  ): Promise<UserAccountEndpoint.List.Response> {
    const filters: UserAccountEndpoint.List.Request = {
      businessId,
      limit: SharedConfig.MAX_PAGE_LIMIT,
      sort: ApiUserAccountQuerySort.lastName,
    };
    return this.getUsers(filters);
  }

  static async getGrowerAssignments (): Promise<string[]> {
    return Client('users/growerAssignments');
  }

  static async createUser (user: ApiUserAccountCreate): Promise<ApiUserAccount> {
    return Client('users', {
      method: 'POST',
      body: user,
    });
  }

  static async updateUser (id: string, user: ApiUserAccountUpdate): Promise<ApiUserAccount> {
    return Client(`users/${id}`, {
      method: 'PATCH',
      body: user,
    });
  }

  /**
   * Update an existing users email in API
   * @param token string
   * @returns Promise<void>
   */
  static async updateEmail (token: string): Promise<void> {
    return Client(`users/updateEmail?token=${token}`, { method: 'POST' });
  }

  /**
   * Change the logged in user's password
   * @param newPassword string
   * @returns Promise<void>
   */
  static async changePassword (newPassword: string): Promise<void> {
    const body: ApiUpdatePassword = {
      newPassword,
    };
    return Client('changePassword', {
      method: 'POST',
      body,
    });
  }

  /**
   * Permanently deletes user in API
   * @param id string
   * @returns Promise<void>
   */
  static async deleteUser (id: string): Promise<void> {
    return Client(`users/${id}`, { method: 'DELETE' });
  }

  static async exportUsers (filters: any, businessId?: string)
    : Promise<CSVResponse> {
    let filterParams = '';
    const businessIdParam = businessId ? `businessId=${businessId}` : '';
    if (Object.keys(filters).length) {
      _.forEach(Object.keys(filters), (key, idx) => {
        const prefix = !idx ? '' : '&';
        filterParams += `${prefix}${key}=${filters[key].toString()}`;
      });
    }

    const joinedParams = _.join(
      _.filter([
        filterParams,
        businessIdParam,
      ], (str) => !_.isEmpty(str)),
      '&',
    );

    const query = !_.isEmpty(joinedParams) ? `?${joinedParams}` : '';

    return Client(`export/users${query}`);
  }

  static async getCsvTemplate (): Promise<CSVResponse> {
    return Client('export/template/users');
  }

  static async importUsers (fileInfo: any): Promise<ImportEndpoint.ImportResult> {
    const body = { csv: fileInfo.uri };
    return Client('import/users', {
      body,
      method: 'POST',
    });
  }

  static getAllBusinessUsers (): Promise<UserAccountEndpoint.List.Response> {
    const options: UserAccountEndpoint.List.Request = {
      limit: SharedConfig.MAX_PAGE_LIMIT,
      sort: ApiUserAccountQuerySort.firstName,
      sortDesc: false,
      userType: [UserType.BUSINESS],
    };
    const query = qs.stringify(options, { arrayFormat: 'comma' });
    return Client(`users?${query}`);
  }
}
