import { Header, VSpacer } from '@design';
import { ApplicationUom } from '@shared/enums';
import { useStyleSheet } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../utilities';
import DescriptionList, { DescriptionListDirection } from '../DescriptionList/DescriptionList';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';

export interface CalculateQuantityDetailsProps {
  applicationRate?: number,
  applicationRateUom?: ApplicationUom,
  pricePerAcre?: number,
  quantityRequired?: number,
}

export const CalculateQuantityDetails = ({
  applicationRate,
  applicationRateUom,
  pricePerAcre,
  quantityRequired,
}: CalculateQuantityDetailsProps) => {
  const [translate] = useTranslation(['common', 'productCard']);

  const styles = useStyleSheet({
    container: {
      backgroundColor: 'color-basic-transparent-0',
      paddingHorizontal: 16,
      paddingTop: 4,
      paddingBottom: 24,
    },
  });

  return (
    <View style={styles.container} testID="calculate-quantity-details-container">
      <Header level="4" testID="calculate-quantity-details-header" title={translate('CALCULATE_QUANTITY')} />
      <VSpacer size="4" />
      <DescriptionList direction={DescriptionListDirection.STACKED}>
        {!!applicationRate && !!applicationRateUom && (
          <DescriptionListItem
            label={translate('APPLICATION_RATE')}
            testID="calculate-quantity-details-application-rate"
            text={translate('APPLICATION_RATE_UOM_PER_AC', {
              applicationRate: StringUtility.localizeNumber(applicationRate, 3),
              applicationRateUom: translate(applicationRateUom),
            })}
          />
        )}
        <DescriptionListItem
          label={translate('PRICE')}
          testID="calculate-quantity-details-price-per-ac"
          text={translate('PRICE_PER_AC', {
            price: StringUtility.formatCurrency(pricePerAcre),
          })}
        />
        <DescriptionListItem
          label={translate('SKU_QUANTITY_NEEDED')}
          testID="calculate-quantity-details-sku-quantity-needed"
          text={StringUtility.localizeNumber(quantityRequired, 4)}
        />
      </DescriptionList>
    </View>
  );
};
