import { ScrollView, StyleSheet, View } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { Search, Text, VSpacer } from '@design';
import { ApiCrop } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
import { useStyleSheet } from '@ui-kitten/components';
import _ from 'lodash';
import { useBusinessCrops } from '../../../../hooks/useBusinessCrops';
import { ComponentSelectPanelItem } from '../../ComponentSelectPanelItem';
import { IColumn, RowMeta, SortableTable } from '../../../components/SortableTable';
import { ButtonBar } from '../../../components/shared/ButtonBar';

interface CropSidePanelProps {
  businessId: string,
  crops: ApiCrop[];
  isLoading: boolean,
  onCancel: () => void,
  onSubmit: (crops: ApiCrop[]) => void,
}

type CropTableType = IColumn<(RowMeta & ApiCrop)>;

export const CropSidePanel = (props: CropSidePanelProps) => {
  const [translate] = useTranslation(['growers', 'common']);

  const styles = useStyleSheet({
    cropBorder: {
      flex: 1,
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: 'color-basic-control-transparent-100',
      maxHeight: 1,
    },
  });
  const { businessId, crops: planningParamCrops, isLoading, onCancel, onSubmit } = props;
  const { businessCrops } = useBusinessCrops({ businessId });
  const [userSelectedCrops, setUserSelectedCrops] = useState<ApiCrop[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setUserSelectedCrops(planningParamCrops);
  }, [planningParamCrops]);

  const onCropSelected = (crop: ApiCrop, selected: boolean) => {
    if (selected) {
      const filteredCrops = userSelectedCrops.filter((selectedCrop) => (
        selectedCrop.cropType !== crop.cropType
        || selectedCrop.subType !== crop.subType
      ));
      setUserSelectedCrops(filteredCrops);
    } else {
      setUserSelectedCrops([...userSelectedCrops, crop]);
    }
  };

  const filteredCrops = useMemo(() => {
    if (!search) {
      return businessCrops;
    }
    const regex: RegExp = new RegExp(`.*${search}.*`, 'i');
    return businessCrops.filter((crop) => (
      regex.test(crop.cropType) || regex.test(crop.subType)));
  }, [search, businessCrops]);

  const rows = useMemo(() => filteredCrops?.map((crop, idx) => ({
    ...crop,
    hasDetails: false,
    rowId: `row|${idx}|${crop.cropType}${crop.subType}`,
  })), [filteredCrops]) || [];

  const onSelectSearch = (idx: number) => {
    const searchCropSelected = `${translate(filteredCrops[idx].cropType)} | ${translate(filteredCrops[idx].subType)}`;
    setSearch(searchCropSelected);
  };

  const columns: CropTableType[] = [
    {
      columnId: 'crop',
      header: null,
      render: (crop) => {
        const businessCrop = businessCrops.find((c) => (
          c.cropType === crop.cropType
          && c.subType === crop.subType
        ));
        const isSelected = userSelectedCrops.some((c) => (
          c.cropType === crop.cropType
          && c.subType === crop.subType
        ));
        return (
          <ComponentSelectPanelItem
            onSelect={() => onCropSelected(businessCrop, isSelected)}
            selected={isSelected}
          >
            <Text>
              {`${translate(crop.cropType)} | ${translate(crop.subType)}`}
            </Text>
          </ComponentSelectPanelItem>
        );
      },
      flex: 1,
    },
  ];

  return (
    <>
      <ScrollView>
        <View style={{ marginHorizontal: 20 }}>
          <Search
            onChangeText={(value) => {
              setSearch(value);
            }}
            onClear={() => setSearch('')}
            onSelect={onSelectSearch}
            size="medium"
            testID="crop-search"
            value={search}
          />
          <VSpacer size="9" />
          <Text appearance="hint" category="p2">
            {translate<string>('CROPS_SELECTED_COUNT', { count: userSelectedCrops.length })}
          </Text>
          <VSpacer size="5" />
        </View>
        <View style={styles.cropBorder} />
        <SortableTable
          columns={columns}
          data={rows}
          footerRow={!rows.length && (
            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <VSpacer size="7" />
              <Text>{translate<string>('NO_CROPS_TO_ADD')}</Text>
              <VSpacer size="7" />
            </View>
          )}
          showHeaders="no"
          tableBackground="transparent"
          tableId="crop-side-panel-list"
          testID="crop-side-panel-list-table"
        />
        <VSpacer size="11" />
      </ScrollView>
      <ButtonBar
        buttonBarType="grouped"
        disableRightAction={isLoading
          || !rows.length
          || _.isEqual(userSelectedCrops, planningParamCrops)}
        leftAction={onCancel}
        leftButtonText={translate('CANCEL')}
        rightAction={() => onSubmit(userSelectedCrops)}
        rightButtonText={translate('APPLY')}
        testID="crop-side-panel-button"
      />
    </>
  );
};
