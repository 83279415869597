import React, { FC, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { StyleSheet, View } from 'react-native';
import * as _ from 'lodash';
import { RenderProp, TouchableWeb } from '@ui-kitten/components/devsupport';
import {
  Interaction,
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { Permissions, RoleUtility } from '@shared/utils';
import { UserType } from '@shared/enums';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { IconProps, IconName } from '../Icon/Icon';
import { Text } from '../Text/Text';
import { FalsyIcon } from '../FalsyIcon/FalsyIcon';
import { testId } from '../../../../utilities/testId';

const styles = StyleSheet.create({
  navItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    flex: 1,
  },
});

const getComponentStyle = (source: StyleType) => {
  const accessoryStyles = PropsServiceHelper.allWithPrefixMapped(source, 'accessory');
  const iconStyles = PropsServiceHelper.allWithPrefixMapped(source, 'icon');
  const rightAccessoryStyles = PropsServiceHelper.allWithPrefixMapped(source, 'rightAccessory');
  const leftAccessoryStyles = PropsServiceHelper.allWithPrefixMapped(source, 'leftAccessory');
  const textStyles = PropsServiceHelper.allWithPrefixMapped(source, 'text');

  const {
    textColor,
    paddingLeft,
    paddingRight,
    ...baseStyles
  } = source;

  return {
    navItem: baseStyles,
    accessoryStyles,
    iconStyles,
    rightAccessoryStyles,
    leftAccessoryStyles,
    textStyles,
    contentPaddingLeft: {
      paddingLeft,
    },
    contentPaddingRight: {
      paddingRight,
    },
  };
};

export interface INavigationItemProps extends StyledComponentProps {
  label: string,
  route: string,
  onNavigate?(): void,
  leftAccessory?: RenderProp<Partial<IconProps>> | IconName,
  rightAccessory?: RenderProp<Partial<IconProps>> | IconName,
  orPermissions?: Permissions[],
  orUserType?: UserType[],
  nativeID?: string,
  disabled?: boolean,
  expanded?: boolean,
  style?: any,
}
const NavItemRaw: FC<INavigationItemProps> = ({
  eva,
  label,
  route: propRoute,
  onNavigate,
  leftAccessory,
  rightAccessory,
  orPermissions,
  orUserType,
  disabled = false,
  expanded,
  style,
}) => {
  const history = useHistory();
  const location = useLocation();
  const evaStyles = getComponentStyle(eva.style);
  const { user } = useAuthentication();
  const [isActive, setIsActive] = useState<boolean>(false);

  const compareRoutes = () => {
    if (location.pathname.startsWith(propRoute)) {
      return true;
    }

    const locationComponents = location.pathname.split('/').filter((r) => !!r);

    if (locationComponents.length <= 1) {
      return false;
    }

    const propRouteComponents = propRoute.split('/').filter((r) => !!r);

    return locationComponents
      .filter((v, i) => i !== locationComponents.length - 1)
      .map((p, index) => p === (propRouteComponents?.[index] ?? ''))
      .every((c) => c);
  };

  useEffect(() => {
    if (compareRoutes()) {
      setIsActive(true);
      eva.dispatch([Interaction.ACTIVE]);
    } else if (isActive) {
      setIsActive(false);
      eva.dispatch([]);
    }

    // dispatch not included - unable to memoize the dispatch function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, location.pathname, propRoute]);

  /**
   * Navigates to the provided route
   * @param route The route to navigate to
   */
  const navigateTo = (route: string) => {
    if (location.pathname !== route) {
      history.push(route);
    }
  };

  // User must have permission to see the item
  if (!!orPermissions
    && !RoleUtility.roleHasSomePermissions(user.userRole, orPermissions)
  ) {
    return null;
  }

  // Guard based on User Type
  if (!!orUserType && !_.includes(orUserType, user.userType)) {
    return null;
  }

  return (
    <TouchableWeb
      disabled={disabled}
      onMouseEnter={(disabled || isActive) ? undefined : () => eva.dispatch([Interaction.HOVER])}
      onMouseLeave={(disabled || isActive) ? undefined : () => eva.dispatch([])}
      onPress={() => {
        navigateTo(propRoute);
        onNavigate?.();
      }}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <View
        {...testId(label)}
        style={[styles.navItem, evaStyles.navItem, style]}
      >
        <FalsyIcon
          component={leftAccessory}
          disabled={disabled}
          status={isActive ? 'primary' : 'basic'}
          style={[
            evaStyles.leftAccessoryStyles,
            evaStyles.iconStyles,
            evaStyles.accessoryStyles,
          ]}
        />
        <Text
          category="p2"
          disabled={disabled}
          numberOfLines={1}
          status={isActive ? 'primary' : 'basic'}
          style={[evaStyles.textStyles, styles.text, { flexWrap: 'nowrap' }]}
        >
          {expanded && label}
        </Text>
        {expanded && (
          <FalsyIcon
            component={rightAccessory}
            disabled={disabled}
            style={[
              evaStyles.rightAccessoryStyles,
              evaStyles.iconStyles,
              evaStyles.accessoryStyles,
            ]}
          />
        )}
      </View>
    </TouchableWeb>
  );
};

export const NavItem = styled('NavItem')(NavItemRaw);
