import { EvaIcon } from '@ui-kitten/eva-icons/evaIcon.component';
import { CollapseCircleOutline } from './eva-extension/collapse-circle-outline';
import { ExpandCircleOutline } from './eva-extension/expand-circle-outline';
import { FireOutline } from './eva-extension/fire-outline';
import { HeightOutline } from './eva-extension/height-outline';
import { LoaderOutline } from './eva-extension/loader-outline';
import { SwapUpDownOutline } from './eva-extension/swap-up-down-outline';
import { TimerOutline } from './eva-extension/timer-outline';
import { WeightOutline } from './eva-extension/weight-outline';

export const EvaExtensionIconsPack = {
  name: 'eva-extension',
  icons: {
    'collapse-circle-outline': new EvaIcon(CollapseCircleOutline),
    'expand-circle-outline': new EvaIcon(ExpandCircleOutline),
    'fire-outline': new EvaIcon(FireOutline),
    'height-outline': new EvaIcon(HeightOutline),
    'loader-outline': new EvaIcon(LoaderOutline),
    'swap-up-down-outline': new EvaIcon(SwapUpDownOutline),
    'timer-outline': new EvaIcon(TimerOutline),
    'weight-outline': new EvaIcon(WeightOutline),
  },
};
