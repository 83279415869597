/* eslint-disable react/jsx-sort-props */
import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import {
  Card,
  CheckBox,
  Input,
  Select,
  SelectItem,
  Icon,
  IconName,
  IconNames,
  ResponsiveView,
  ValidatedInput,
  Text,
} from '@design';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { InputSize, InputSizes } from '@theme/variant-interfaces/Size';
import { Sizing } from '../../../../constants';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';
import { NumericInput } from '../../../components/DesignSystem/Input/NumericInput';
import { DiscountType } from '@shared/enums';
import { ApiDiscount } from '@shared/interfaces/api';
import { DiscountInputSelect } from '../../../components/shared/Input/DiscountInputSelect';

const InputKnobs: FunctionComponent = () => {
  const [size, setSize] = useState<InputSize>('medium');
  const [status, setStatus] = useState<Status>('basic');
  const [disable, setDisable] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [label, setLabel] = useState('Label');
  const [caption, setCaption] = useState('Caption');
  const [placeholder, setPlaceholder] = useState('Placeholder...');
  const [iconLeft, setIconLeft] = useState<IconName | undefined>(undefined);
  const [iconRight, setIconRight] = useState<IconName | null>(null);

  const [currencyValue, setCurrencyValue] = useState<number | null>(null);
  const [decimalPrecision, setDecimalPrecision] = useState<number | null>(2);
  const [numericPlaceholder, setNumericPlaceholder] = useState('Placeholder...');
  const [numericSize, setNumericSize] = useState<InputSize>('medium');
  const [numericStatus, setNumericStatus] = useState<Status>('basic');
  const [numericDisable, setNumericDisable] = useState(false);
  const [numericReadonly, setNumericReadonly] = useState(false);
  const [numericLabel, setNumericLabel] = useState('Label');
  const [numericCaption, setNumericCaption] = useState('Caption');
  const [numericIconLeft, setNumericIconLeft] = useState<IconName | null>(null);
  const [numericIconRight, setNumericIconRight] = useState<IconName | null>(null);
  const [numericPrefix, setNumericPrefix] = useState('$ ');
  const [numericSuffix, setNumericSuffix] = useState('');
  const [numericIsRequired, setNumericIsRequired] = useState<boolean>(false);
  const [numericErrorMessage, setNumericErrorMessage] = useState('Required!');
  const [numericLeftToRightMode, setNumericLeftToRightMode] = useState(true);

  return (
    <>
      <Card testID="test-card">
        <DemoContainer label="numeric sandbox">
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Input
              label="label"
              value={numericLabel}
              testID="demo-input"
              onChangeText={setNumericLabel}
            />
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Input
              label="caption"
              value={numericCaption}
              testID="demo-input"
              onChangeText={setNumericCaption}
            />
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Input
              label="placeholder"
              value={numericPlaceholder}
              testID="demo-input"
              onChangeText={setNumericPlaceholder}
            />
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Select
              style={{ paddingRight: Sizing.DOUBLE_SPACING }}
              label="size"
              onSelect={(index: IndexPath | IndexPath[]) => setNumericSize(
                InputSizes[(index as IndexPath).row],
              )}
              selectedIndex={new IndexPath(InputSizes.findIndex((s) => s === numericSize))}
              testID="input-demo-selector"
              value={numericSize}
            >
              {InputSizes.map((s, i) => (
                <SelectItem testID={`input-demo-size-dropdown-value-${i}`} title={s} key={s} />
              ))}
            </Select>
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Select
              label="status"
              onSelect={(index) => setNumericStatus(Statuses[(index as IndexPath).row])}
              selectedIndex={new IndexPath(Statuses.findIndex((s) => s === numericStatus))}
              testID="input-demo-status-selector"
              value={numericStatus}
            >
              {Statuses.map((s, i) => (
                <SelectItem testID={`input-demo-status-dropdown-value-${i}`} title={s} key={s} />
              ))}
            </Select>
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={1}>
            <CheckBox onChange={setNumericDisable} checked={numericDisable} testID="test-checkbox">
              disabled
            </CheckBox>
            <CheckBox onChange={setNumericReadonly} checked={numericReadonly} testID="test-checkbox">
              readonly
            </CheckBox>
            <CheckBox
              onChange={setNumericIsRequired}
              checked={numericIsRequired}
              testID="test-checkbox"
            >
              isRequired
            </CheckBox>
            <CheckBox
              onChange={setNumericLeftToRightMode}
              checked={numericLeftToRightMode}
              testID="test-checkbox"
            >
              leftToRightMode
            </CheckBox>
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Select
              label="accessoryLeft"
              selectedIndex={
                new IndexPath(IconNames.findIndex((n) => n === numericIconLeft) + 1)
              }
              value={numericIconLeft || 'None'}
              onSelect={(ip: IndexPath | IndexPath[]) => {
                setNumericIconLeft(IconNames[(ip as IndexPath).row - 1]);
              }}
              testID="input-demo-accessory-left-selector"
            >
              {[
                <SelectItem testID="input-demo-responsive-left-icon-dropdown-value" title="None" key="noicon" />,
                ...IconNames.map((name, index) => (
                  <SelectItem testID={`input-demo-responsive-left-icon-name-dropdown-value-${index}`} title={name} key={name} />
                )),
              ]}
            </Select>
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Select
              label="accessoryRight"
              selectedIndex={
                new IndexPath(IconNames.findIndex((n) => n === numericIconRight) + 1)
              }
              testID="input-demo-accessory-right-selector"
              value={numericIconRight || 'None'}
              onSelect={(ip: IndexPath | IndexPath[]) => setNumericIconRight(
                IconNames[(ip as IndexPath).row - 1],
              )}
            >
              {[
                <SelectItem testID="input-demo-responsive-right-icon-name-dropdown-value" title="None" key="noicon" />,
                ...IconNames.map((name, index) => (
                  <SelectItem testID={`input-demo-responsive-right-icon-name-dropdown-value-${index}`} title={name} key={name} />
                )),
              ]}
            </Select>
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <NumericInput
              label="Decimal Precision"
              value={decimalPrecision}
              onChangeValue={setDecimalPrecision}
              precision={0}
              testID="demo-input"
            />
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={1}>
            <Input
              label="prefix"
              value={numericPrefix}
              onChangeText={setNumericPrefix}
              testID="demo-input"
            />
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={1}>
            <Input
              label="suffix"
              value={numericSuffix}
              onChangeText={setNumericSuffix}
              testID="demo-input"
            />
          </ResponsiveView>
          <ResponsiveView xSmall={4} small={8} medium={6} large={2}>
            <Input
              label="errorMessage"
              onChangeText={setNumericErrorMessage}
              testID="demo-input"
              value={numericErrorMessage}
            />
          </ResponsiveView>
        </DemoContainer>
        <DemoContainer>
          <DemoBlock
            label="currency"
            style={{
              margin: 'auto',
            }}
          >
            <NumericInput
              accessoryLeft={(props) => (
                (numericIconLeft || null) && <Icon name={numericIconLeft} testID="demo-input-left-icon" {...props} />
              )}
              accessoryRight={(props) => (
                (numericIconRight || null) && <Icon name={numericIconRight} testID="demo-input-right-icon" {...props} />
              )}
              caption={numericCaption}
              disabled={numericDisable}
              errorMessage={numericErrorMessage}
              isRequired={numericIsRequired}
              label={numericLabel}
              leftToRightMode={numericLeftToRightMode}
              onChangeValue={setCurrencyValue}
              placeholder={numericPlaceholder}
              precision={decimalPrecision}
              prefix={numericPrefix}
              readonly={numericReadonly}
              size={numericSize}
              status={numericStatus}
              suffix={numericSuffix}
              testID="demo-input"
              value={currencyValue}
            />
          </DemoBlock>
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <DemoContainer label="sandbox">
          <DemoBlock>
            <Input
              label="label"
              value={label}
              onChangeText={setLabel}
              testID="demo-input"
            />
          </DemoBlock>
          <DemoBlock>
            <Input
              label="caption"
              value={caption}
              onChangeText={setCaption}
              testID="demo-input"
            />
          </DemoBlock>
          <DemoBlock>
            <Input
              label="placeholder"
              onChangeText={setPlaceholder}
              testID="demo-input"
              value={placeholder}
            />
          </DemoBlock>
          <DemoBlock>
            <Select
              style={{ paddingRight: Sizing.DOUBLE_SPACING }}
              label="size"
              onSelect={(index) => setSize(InputSizes[(index as IndexPath).row])}
              selectedIndex={new IndexPath(InputSizes.findIndex((s) => s === size))}
              testID="demo-input"
              value={size}
            >
              {InputSizes.map((s, i) => (
                <SelectItem testID={`input-demo-size-dropdown-value-${i}`} title={s} key={s} />
              ))}
            </Select>
          </DemoBlock>
          <DemoBlock>
            <Select
              label="status"
              onSelect={(index) => setStatus(Statuses[(index as IndexPath).row])}
              selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
              testID="input-demo-status-selector"
              value={status}
            >
              {Statuses.map((s, i) => (
                <SelectItem testID={`input-demo-status-dropdown-value-${i}`} title={s} key={s} />
              ))}
            </Select>
          </DemoBlock>
          <DemoBlock>
            <CheckBox onChange={setDisable} checked={disable} testID="test-checkbox">
              disabled
            </CheckBox>
            <CheckBox onChange={setReadonly} checked={readonly} testID="test-checkbox">
              readonly
            </CheckBox>
          </DemoBlock>
          <DemoBlock>
            <Select
              label="accessoryLeft"
              selectedIndex={
                new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)
              }
              testID="input-demo-accessory-left-selector"
              value={iconLeft || 'None'}
              onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
                IconNames[(ip as IndexPath).row - 1],
              )}
            >
              {[
                <SelectItem testID="input-demo-left-icon-dropdown-value" title="None" key="noicon" />,
                ...IconNames.map((name, index) => (
                  <SelectItem testID={`input-demo-left-icon-name-dropdown-value-${index}`} title={name} key={name} />
                )),
              ]}
            </Select>
          </DemoBlock>
          <DemoBlock>
            <Select
              label="accessoryRight"
              selectedIndex={
                new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)
              }
              testID="input-demo-accessory-right-selector"
              value={iconRight || 'None'}
              onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
                IconNames[(ip as IndexPath).row - 1],
              )}
            >
              {[
                <SelectItem testID="input-demo-right-icon-dropdown-value" title="None" key="noicon" />,
                ...IconNames.map((name, index) => (
                  <SelectItem testID={`input-demo-right-icon-dropdown-value-${index}`} title={name} key={name} />
                )),
              ]}
            </Select>
          </DemoBlock>
        </DemoContainer>
        <DemoContainer>
          <DemoBlock
            style={{
              margin: 'auto',
            }}
          >
            <Input
              accessoryLeft={(props) => (
                (iconLeft || null) && <Icon name={iconLeft} testID="demo-input-left-icon" {...props} />
              )}
              accessoryRight={(props) => (
                (iconRight || null) && <Icon name={iconRight} testID="demo-input-right-icon" {...props} />
              )}
              caption={caption}
              disabled={disable}
              label={label}
              placeholder={placeholder}
              readonly={readonly}
              size={size}
              status={status}
              testID="demo-input"
            />
          </DemoBlock>
        </DemoContainer>
      </Card>
    </>
  );
};

const DiscountInputSelectDemo = () => {
  const [discount, setDiscount] = useState<Partial<ApiDiscount>>({
    discountType: DiscountType.DOLLARS,
  });

  return (
    <DemoContainer label="Discount Input Select">
      <DemoBlock>
        <DiscountInputSelect
          discount={discount}
          setDiscount={setDiscount}
          testID="test"
        />
        <Text>{JSON.stringify(discount, null, 2)}</Text>
      </DemoBlock>
    </DemoContainer>
  );
};

export const InputDemo: FunctionComponent = () => (
  <View style={{ alignSelf: 'stretch' }} nativeID="demo-page">
    <Card testID="test-card">
      <DemoContainer label="validated">
        <DemoBlock label="required">
          <ValidatedInput
            label="Label"
            size="medium"
            caption="Caption"
            placeholder="Placeholder"
            errorMessage="ERROR!"
            isRequired
            testID="demo-input"
          />
        </DemoBlock>
      </DemoContainer>
    </Card>
    <Card testID="test-card">
      <DemoContainer label="size">
        {InputSizes.map((s) => (
          <DemoBlock key={s} label={s}>
            <Input
              caption="Caption"
              label="Label"
              placeholder="Placeholder"
              size={s}
              testID="demo-input"
            />
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <Card testID="test-card">
      <DemoContainer label="status">
        {Statuses.map((s) => (
          <DemoBlock key={s} label={s}>
            <Input
              caption="Caption"
              isRequired
              label="Label"
              placeholder="Placeholder"
              status={s}
              testID="demo-input"
            />
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <InputKnobs />
    <Card testID="test-card">
      <DemoContainer label="Single Empty" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <DemoBlock>
          <Input label="Label" placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" caption="Helper Text" placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" caption="Helper Text" placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Single Empty Disabled" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <DemoBlock>
          <Input label="Label" disabled placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" disabled placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" disabled caption="Helper Text" placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" disabled caption="Helper Text" placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Single Filled" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <DemoBlock>
          <Input label="Label" value="Input Text" placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" value="Input Text" placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" value="Input Text" caption="Helper Text" placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" value="Input Text" caption="Helper Text" placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Single Filled Disabled" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <DemoBlock>
          <Input label="Label" value="Input Text" disabled placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" value="Input Text" disabled placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" value="Input Text" disabled caption="Helper Text" placeholder="Placeholder Text" testID="demo-input" />
        </DemoBlock>
        <DemoBlock>
          <Input label="Label" value="Input Text" disabled caption="Helper Text" placeholder="Placeholder Text" accessoryRight={(p) => <Icon name="ChevronDown" testID="demo-icon" {...p} />} testID="demo-input" />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Multiline Empty" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <DemoBlock label="Default">
          <Input multiline label="Label" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Default">
          <Input multiline label="Label" caption="Helper Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Disabled">
          <Input multiline disabled label="Label" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Disabled">
          <Input multiline disabled label="Label" caption="Helper Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Read only">
          <Input multiline readonly label="Label" caption="Helper Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="Multiline Filled" style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <DemoBlock label="Default">
          <Input multiline label="Label" value="Input Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Default">
          <Input multiline label="Label" value="Input Text" caption="Helper Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Disabled">
          <Input multiline disabled label="Label" value="Input Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Disabled">
          <Input multiline disabled label="Label" value="Input Text" caption="Helper Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
        <DemoBlock label="Read only">
          <Input multiline readonly label="Label" value="Input Text" caption="Helper Text" placeholder="Placeholder Text" textStyle={{ minHeight: 16 * 5 }} testID="demo-input" />
        </DemoBlock>
      </DemoContainer>
      <DiscountInputSelectDemo />
    </Card>
  </View>
);
