import { ProductMixIntendedUse } from '@shared/enums';
import { OrderStatus } from '@shared/interfaces';
import {
  ApiDiscount,
  ApiProductOrder,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
} from '@shared/interfaces/api';
import { CalculationUtility, ProductUtilities } from '@shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { ProductOrderApi } from '../../../utilities/api/ProductOrderApi';
import {
  ComponentCalculatedQuantityMap,
} from './components/CalculateQuantity/CalculateQuantityDetails';

export function calculateQuantity (
  component: ApiProductOrderComponent,
  acreage: number,
  priceTypeId: string,
  locationId: string,
) {
  if (component.applicationRate && component.applicationRateUom && !!acreage) {
    const requiredPackages = (ProductUtilities.getRequiredProductUnitsPerAcre(
      component.product,
      component.applicationRate,
      component.applicationRateUom,
    ) / component.product.packageUnitQuantity) * acreage;
    const roundedPackages = CalculationUtility.roundToPlaces(requiredPackages, 4);
    return {
      acreage,
      quantity: roundedPackages,
      applicationRate: component.applicationRate,
      applicationUom: component.applicationRateUom,
      price: CalculationUtility.getApplicableProductPrice(
        component.product,
        priceTypeId,
        locationId,
      ),
    };
  }
  return null;
}

export function isProductMixModified (
  productMixComponents: ApiProductOrderProductMixComponent[],
) {
  if (!productMixComponents || productMixComponents.length === 0) {
    return false;
  }

  const isAnyPackagePriceSpecified = productMixComponents.some(
    x => x.packagePrice !== undefined && x.packagePrice !== null,
  );
  const isAnyIntendedUseModified = productMixComponents.some(component => {
    const tankMixComponent = component.tankMix?.components.find(x => x.productId === component.productId);
    if (!tankMixComponent) {
      return false;
    }
    return !(
      (tankMixComponent.adjuvant && component.intendedUse === ProductMixIntendedUse.Adjuvant)
      || (tankMixComponent.carrier && component.intendedUse === ProductMixIntendedUse.Carrier)
      || (!tankMixComponent.adjuvant && !tankMixComponent.carrier && !component.intendedUse)
    );
  });
  const isAnyRateModified = productMixComponents.some(component => {
    const tankMixComponent = component.tankMix?.components.find(x => x.productId === component.productId);
    if (!tankMixComponent) {
      return false;
    }
    return tankMixComponent.rate !== component.applicationRate;
  });
  const isAnyRateUomModified = productMixComponents.some(component => {
    const tankMixComponent = component.tankMix?.components.find(x => x.productId === component.productId);
    if (!tankMixComponent) {
      return false;
    }
    return tankMixComponent.rateUom !== component.applicationRateUom;
  });

  return isAnyPackagePriceSpecified || isAnyIntendedUseModified
    || isAnyRateModified || isAnyRateUomModified;
}

export function calculateTankMixTotalCostPerAcre (
  tankMixComponents: ApiProductOrderProductMixComponent[],
  priceTypeId?: string,
  locationId?: string,
  acres: number = 1,
  includeDiscounts: boolean = false,
): number {
  if (!tankMixComponents) {
    return 0;
  }

  const discountTotal = includeDiscounts 
    ? tankMixComponents.map(productMixComponent => {
      return CalculationUtility.calculateProductOrderProductDiscounts(
        productMixComponent,
        priceTypeId || productMixComponent.tankMix.priceTypeId,
        locationId || productMixComponent.tankMix.businessLocationId,
        acres,
      ).discountTotals.total;
    }).flat().reduce((a, b) => a + b, 0) : 0;

  return (tankMixComponents.map((tankMixComponent) => (
    (tankMixComponent.applicationRateUom && tankMixComponent.applicationRate)
      ? CalculationUtility.calculateProductCostPerAcre(
        tankMixComponent.product,
        tankMixComponent.applicationRate,
        tankMixComponent.applicationRateUom,
        tankMixComponent.packagePrice ??
          CalculationUtility.getApplicableProductPrice(
            tankMixComponent.product,
            priceTypeId,
            locationId,
          ),
      ) : 0
  )).reduce((a, b) => a + b, 0) * acres) - discountTotal;
}

export function countGroupedTankMixes (
  tankMixComponents: ApiProductOrderProductMixComponent[],
): number {

  if (!tankMixComponents) {
    return 0;
  }

  const uniqueTankMixIds = Array.from(new Set(tankMixComponents.map(x => x.tankMixId)));
  return uniqueTankMixIds.length;
}

export function groupByTankMixId (
  tankMixComponents: ApiProductOrderProductMixComponent[],
): ApiProductOrderProductMixComponent[] {

  if (!tankMixComponents) {
    return [];
  }

  const uniqueTankMixIds = Array.from(new Set(tankMixComponents.map(x => x.tankMixId)));
  const groupedTankMixes = [];

  for (const tankMixId of uniqueTankMixIds) {
    groupedTankMixes.push(tankMixComponents.find(x => x.tankMixId === tankMixId));
  }

  return groupedTankMixes;
}

export function calculateQuantities (
  data: ApiProductOrder,
  priceTypeId: string,
) {
  const quantities = {};

  data.components?.forEach((component) => {
    if ((component.acreage || data.acreage)
        && component.applicationRate
        && component.applicationRateUom
    ) {
      quantities[component.id] = calculateQuantity(
        component,
        component.acreage ?? data.acreage,
        priceTypeId,
        data.locationId,
      );
    }
  });

  return quantities;
}

export const handleAcceptedStatus = (productOrder: ApiProductOrder) => {
  if (productOrder.status === OrderStatus.ACCEPTED && !productOrder.isDraft) {
    const acceptedComponents = productOrder.components.map((component) => ({
      ...component,
      packagePrice: component.packagePrice !== undefined
        ? component.packagePrice
        : CalculationUtility.getApplicableProductPrice(
          component.product,
          productOrder.priceTypeId,
          productOrder.locationId,
        ),
    }));
    productOrder.components = acceptedComponents;
  }
  return productOrder;
};

interface ProductOrderOptions {
  initialProductOrder: ApiProductOrder,
  isLoading: boolean,
  isOwner: boolean,
  productOrder: ApiProductOrder,
}

export function useProductOrder ({
  productOrderId,
  duplicate = false,
  onError,
  updatedComponents,
  updatedProductMixComponents,
}: {
  productOrderId: string | null,
  duplicate?: boolean,
  onError?(): void,
  updatedComponents?: ApiProductOrderComponent[],
  updatedProductMixComponents?: ApiProductOrderProductMixComponent[],
}) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const { currentBusinessId, user } = useAuthentication();
  const { defaultPriceTypeId } = usePriceTypeList();
  const {
    businessLocations,
    isLoading: isBusinessListLoading,
  } = useBusinessLocationList(currentBusinessId);

  const defaultProductOrder: ApiProductOrder = useMemo(() => ({
    acceptedAt: null,
    acreage: null,
    billingContactId: null,
    components: [],
    createdAt: null,
    cropYear: null,
    discounts: [],
    externalDisplayId: null,
    externalId: null,
    growerId: null,
    id: null,
    isActive: true,
    locationId: null,
    modified: null,
    name: '',
    note: null,
    ownerId: user.id,
    shippingContactId: null,
    status: OrderStatus.UNLABELED,
    priceTypeId: defaultPriceTypeId,
    updatedAt: null,
  }), [defaultPriceTypeId, user.id]);

  const [options, setOptions] = useState<ProductOrderOptions>({
    productOrder: defaultProductOrder,
    initialProductOrder: defaultProductOrder,
    isOwner: true,
    isLoading: true,
  });

  const [calculatedQuantities, setCalculatedQuantities] = (
    useState<ComponentCalculatedQuantityMap>({})
  );

  const clearDiscountIds = (discounts: ApiDiscount[]) => {
    discounts?.forEach((discount) => {
      delete discount.id;
    });
  };

  useQuery(
    [QueryKeys.PRODUCT_ORDER, productOrderId, businessLocations],
    async () => {
      const data = await ProductOrderApi.getProductOrder(productOrderId);
      setCalculatedQuantities(calculateQuantities(data, data.priceTypeId));

      if (duplicate) {
        const { owner, location, locationId, note, ...newData } = data;

        const hasLocation = businessLocations.some(
          (businessLocation) => businessLocation.id === locationId,
        );

        clearDiscountIds(data.discounts);
        newData.components = [];
        newData.productMixComponents = [];

        return {
          ...newData,
          cancelled: false,
          isDraft: true,
          ownerId: user.id,
          locationId: hasLocation ? locationId : null,
          name: translate('COPY_OF_ORDER_NAME', { orderName: data.name }),
          status: OrderStatus.UNLABELED,
        };
      }

      return data;
    },
    {
      enabled: !!productOrderId && !isBusinessListLoading,
      onSuccess: (data: ApiProductOrder) => {
        setOptions({
          productOrder: {
            ...data,
            components: updatedComponents || data.components,
            productMixComponents: updatedProductMixComponents || data.productMixComponents,
          },
          initialProductOrder: data,
          isOwner: data.ownerId === user.id,
          isLoading: false,
        });
      },
      onError: () => onError?.(),
    },
  );

  return {
    ...options,
    setProductOrder: (
      newProductOrder: ApiProductOrder,
      updateInitialProductOrder = false,
    ) => {
      setCalculatedQuantities(
        calculateQuantities(newProductOrder, newProductOrder.priceTypeId),
      );
      setOptions({
        productOrder: newProductOrder,
        initialProductOrder: updateInitialProductOrder
          ? newProductOrder : options.initialProductOrder,
        isOwner: newProductOrder.ownerId === user.id,
        isLoading: false,
      });
    },
    calculatedQuantities,
    setCalculatedQuantities,
  };
}
