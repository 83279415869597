import React, { useState } from 'react';
import { LayoutChangeEvent, StyleSheet, View, ViewProps } from 'react-native';
import { ThemeColors, Sizing } from '../../../constants';
import { StyledText as Text } from './Text';

const styles = StyleSheet.create({
  badge: {
    paddingVertical: 0.125 * Sizing.EM,
    paddingHorizontal: Sizing.QUARTER_SPACING,
    borderRadius: Sizing.EM,
    position: 'absolute',
    top: -Sizing.HALF_SPACING,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  badgeText: {
    color: ThemeColors.WHITE,
    fontSize: 0.8 * Sizing.EM,
    textAlign: 'center',
    height: Sizing.BASE_SPACING,
    minWidth: Sizing.BASE_SPACING,
  },
});

export interface BadgeProps extends ViewProps {
  level?: 'danger' | 'warning' | 'success' | 'info';
  style?: any;
  text: string;
}

export const StyledBadge = (props: BadgeProps) => {
  const level = props.level || 'info';
  const [width, setWidth] = useState(0);

  /**
   * Gets the initial height of the drawer content
   * @param event The layout event
   */
  const initWidth = (event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  };

  return (
    <View
      onLayout={initWidth}
      style={[
        styles.badge,
        { backgroundColor: ThemeColors[level.toUpperCase()] },
        { right: -width + Sizing.HALF_SPACING },
        props.style,
      ]}
    >
      <Text style={styles.badgeText}>{props.text}</Text>
    </View>
  );
};
