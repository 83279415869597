import React from 'react';
import Svg, { G, Path, Rect, Defs, ClipPath } from 'react-native-svg';
import { Sizing } from '../constants';

export const RallyLogoHeader = () => (
  <Svg
    height={7 * Sizing.BASE_SPACING}
    viewBox={`0 0 ${14.5 * Sizing.BASE_SPACING} ${2.5 * Sizing.BASE_SPACING}`}
    width={7 * Sizing.BASE_SPACING}
  >
    <G clipPath="url(#clip0)">
      <Path
        d="M59.5781 -3.05176e-05C61.5468 -3.05176e-05 63.4349 0.784357 64.827 2.18058C66.2191 3.5768 67.0012 5.47048 67.0012 7.44503V67H52.1549V7.44503C52.1549 5.47048 52.937 3.5768 54.3291 2.18058C55.7212 0.784357 57.6093 -3.05176e-05 59.5781 -3.05176e-05V-3.05176e-05Z"
        fill="#F4FF24"
      />
      <Path
        d="M24.9396 17.3701C26.9083 17.3701 28.7964 18.1544 30.1885 19.5507C31.5806 20.9469 32.3627 22.8406 32.3627 24.8151V66.9996H17.5164V24.8151C17.5164 22.8406 18.2985 20.9469 19.6906 19.5507C21.0827 18.1544 22.9708 17.3701 24.9396 17.3701V17.3701Z"
        fill="#F4FF24"
      />
      <Path
        d="M7.62067 34.7406C9.58941 34.7406 11.4775 35.525 12.8696 36.9212C14.2617 38.3174 15.0438 40.2111 15.0438 42.1856V66.9997H0.19754V42.1856C0.19754 40.2111 0.979618 38.3174 2.37172 36.9212C3.76383 35.525 5.65193 34.7406 7.62067 34.7406Z"
        fill="#F4FF24"
      />
      <Path
        d="M42.2594 34.7406C44.2281 34.7406 46.1162 35.525 47.5083 36.9212C48.9004 38.3174 49.6825 40.2111 49.6825 42.1856V66.9997H34.8362V42.1856C34.8362 40.2111 35.6183 38.3174 37.0104 36.9212C38.4025 35.525 40.2906 34.7406 42.2594 34.7406Z"
        fill="#F4FF24"
      />
      <Path
        d="M49.6813 0.418365H34.5011V32.2526H49.6813V0.418365Z"
        fill="#969A3D"
      />
      <Path d="M15.1802 0.418365H0V32.2526H15.1802V0.418365Z" fill="#969A3D" />
      <Path
        d="M32.3597 0.573792H17.1795V15.7989H32.3597V0.573792Z"
        fill="#969A3D"
      />
    </G>
    <Path
      d="M107.068 67C106.415 66.3467 105.812 64.2865 105.661 62.6785C105.661 61.7237 105.51 60.1157 105.46 58.6585C105.259 52.3772 103.701 49.0105 98.7267 48.2567C100.686 47.905 102.445 47.1512 104.003 46.0457C105.611 44.89 106.415 42.88 106.415 39.9152C106.415 32.4782 101.742 28.408 93.0485 28.408H79.5312V67H88.124V51.7742H91.8927C95.4605 51.7742 96.5157 53.7842 96.566 56.548V59.8645C96.6162 61.1207 96.6162 62.0252 96.6665 62.578C96.8172 64.3367 97.1187 66.2462 98.174 67H107.068ZM97.571 39.9655C97.571 44.1362 95.2595 45.4427 91.4907 45.4427H88.124V34.3375H90.4857C94.958 34.3375 97.571 36.0962 97.571 39.9655Z"
      fill="#FEFEFE"
    />
    <Path
      d="M122.07 52.729C122.07 52.729 122.271 51.8747 122.623 50.116C123.025 48.3572 123.427 46.7995 123.779 45.3925C124.13 44.0357 124.532 42.5785 124.884 40.9705C125.286 39.3625 125.537 38.056 125.638 37.1012C125.789 38.056 126.04 39.3625 126.392 40.9705C126.743 42.5785 127.095 44.0357 127.447 45.3422L128.603 50.116C129.005 51.8747 129.206 52.729 129.206 52.729H122.07ZM131.216 28.408H120.613L109.96 67H118.452L120.462 59.1107H130.964L133.175 67H141.919L131.216 28.408Z"
      fill="#FEFEFE"
    />
    <Path
      d="M167.946 60.166H153.976V28.408H145.383V67H167.946V60.166Z"
      fill="#FEFEFE"
    />
    <Path
      d="M193.605 60.166H179.636V28.408H171.043V67H193.605V60.166Z"
      fill="#FEFEFE"
    />
    <Path
      d="M209.506 28.408L203.024 43.282L196.542 28.408H187.296L198.3 51.523V67H206.893V51.523L217.848 28.408H209.506Z"
      fill="#FEFEFE"
    />
    <Path
      d="M97.135 11.6668H88.7627V15.3845H92.6617C92.6617 15.3845 92.6617 15.989 92.6314 16.3517C92.4199 19.1626 91.3015 20.6739 88.3697 20.6739C87.0096 20.6739 85.982 20.1298 85.317 19.0719C84.6521 18.0141 84.3196 15.8379 84.3196 12.6038C84.3196 8.97681 84.6218 6.52859 85.1961 5.22891C85.7704 3.92924 86.8585 3.26429 88.4302 3.26429C91.2411 3.26429 92.3292 5.22891 92.0874 8.28163L96.9838 7.97938C97.0745 5.65206 96.4398 3.71767 95.0192 2.23665C93.5986 0.755623 91.4224 0 88.4302 0C85.4984 0 83.171 0.967198 81.5087 2.90159C79.8463 4.80576 79 8.07006 79 12.634C79 17.0469 79.8765 20.0391 81.6296 21.6108C83.3826 23.1523 85.589 23.9381 88.2186 23.9381C90.3041 23.9381 92.1781 23.1221 93.2662 22.0038L93.6893 21.5504L95.0192 23.5754H97.135V11.6668Z"
      fill="#FEFEFE"
    />
    <Path
      d="M116.822 23.5754C116.429 23.1825 116.066 21.9433 115.975 20.9761C115.975 20.4018 115.885 19.4346 115.855 18.5581C115.734 14.78 114.797 12.7549 111.804 12.3015C112.983 12.09 114.041 11.6366 114.978 10.9717C115.945 10.2765 116.429 9.06748 116.429 7.28421C116.429 2.81092 113.618 0.3627 108.389 0.3627H100.258V23.5754H105.427V14.4173H107.694C109.84 14.4173 110.474 15.6263 110.505 17.2887V19.2835C110.535 20.0391 110.535 20.5832 110.565 20.9157C110.656 21.9735 110.837 23.1221 111.472 23.5754H116.822ZM111.109 7.31443C111.109 9.8231 109.719 10.609 107.452 10.609H105.427V3.92924H106.847C109.537 3.92924 111.109 4.98711 111.109 7.31443Z"
      fill="#FEFEFE"
    />
    <Path
      d="M118.093 11.9388C118.093 20.3716 121.267 23.9381 127.916 23.9381C130.939 23.9381 133.327 23.0314 135.08 21.1877C136.863 19.344 137.739 16.261 137.739 11.9388C137.739 3.95947 134.354 0 127.916 0C124.984 0 122.597 0.967198 120.783 2.87137C119 4.77554 118.093 7.79803 118.093 11.9388ZM123.443 11.9993C123.443 5.80319 124.712 3.38519 127.735 3.38519C129.095 3.38519 130.213 4.01992 131.09 5.31959C131.966 6.61926 132.39 8.82568 132.39 11.9993C132.39 15.2334 131.966 17.47 131.15 18.679C130.334 19.888 129.186 20.4925 127.705 20.4925C126.465 20.4925 125.438 19.888 124.622 18.679C123.836 17.47 123.443 15.2334 123.443 11.9993Z"
      fill="#FEFEFE"
    />
    <Path
      d="M160.215 0.3627L157.616 16.0192L154.744 0.3627H149.002L146.07 16.0192L143.41 0.3627H138.302L143.229 23.5754H148.73L151.873 8.16073L154.744 23.5754H160.306L165.202 0.3627H160.215Z"
      fill="#FEFEFE"
    />
    <Path
      d="M181.884 19.8276H172.182V13.6012H180.222V9.67198H172.182V4.08037H181.129V0.3627H167.074V23.5754H181.884V19.8276Z"
      fill="#FEFEFE"
    />
    <Path
      d="M200.796 23.5754C200.403 23.1825 200.041 21.9433 199.95 20.9761C199.95 20.4018 199.859 19.4346 199.829 18.5581C199.708 14.78 198.771 12.7549 195.779 12.3015C196.958 12.09 198.016 11.6366 198.953 10.9717C199.92 10.2765 200.403 9.06748 200.403 7.28421C200.403 2.81092 197.592 0.3627 192.363 0.3627H184.233V23.5754H189.401V14.4173H191.668C193.814 14.4173 194.449 15.6263 194.479 17.2887V19.2835C194.509 20.0391 194.509 20.5832 194.54 20.9157C194.63 21.9735 194.812 23.1221 195.446 23.5754H200.796ZM195.084 7.31443C195.084 9.8231 193.693 10.609 191.427 10.609H189.401V3.92924H190.822C193.512 3.92924 195.084 4.98711 195.084 7.31443Z"
      fill="#FEFEFE"
    />
    <Path
      d="M218.79 16.7144C218.79 13.9639 217.249 11.6064 213.985 10.2765L210.176 8.73501C207.849 7.79803 206.942 7.10286 206.942 5.53116C206.942 4.17104 207.97 3.29452 209.753 3.29452C211.567 3.29452 212.685 4.01992 212.685 6.19611C212.685 6.64949 212.624 7.04241 212.534 7.31443L217.067 7.19353C217.219 6.67971 217.37 6.04499 217.37 5.59161C217.37 3.92924 216.705 2.59935 215.375 1.5717C214.045 0.513824 212.292 0 210.146 0C207.516 0 205.552 0.634723 204.222 1.87395C202.892 3.11317 202.227 4.65464 202.227 6.52859C202.227 9.0977 203.224 11.2739 206.005 12.3318L209.421 13.6314C211.174 14.2964 212.352 14.9311 212.927 15.5658C213.531 16.1703 213.833 16.8655 213.833 17.6514C213.833 19.6764 212.504 20.553 210.176 20.553C207.758 20.553 206.882 19.4649 206.882 17.8025C206.882 17.0771 207.033 16.6842 207.184 16.2308L202.348 16.3819C202.197 16.8957 202.046 17.3793 202.046 18.1954C202.046 21.7317 204.917 23.9381 210.146 23.9381C215.858 23.9381 218.79 21.5202 218.79 16.7144Z"
      fill="#FEFEFE"
    />
    <Path
      d="M223.781 28.2644H219.183V29.3197H220.766V34.6964H222.198V29.3197H223.781V28.2644Z"
      fill="#FEFEFE"
    />
    <Path
      d="M231.014 28.2644H228.979C228.979 28.2644 228.87 28.7251 228.644 29.6379L228.041 32.1421L227.915 32.8037C227.873 33.0801 227.848 33.2224 227.848 33.2224C227.848 33.2224 227.823 33.0801 227.773 32.8037L227.647 32.1421L227.053 29.6463C226.835 28.7251 226.726 28.2644 226.726 28.2644H224.682V34.6964H226.006V30.8104C226.006 30.6094 225.997 30.3917 225.989 30.1488C225.981 29.9059 225.972 29.7803 225.972 29.7803C225.972 29.7803 225.997 29.9143 226.048 30.1823C226.106 30.4503 226.148 30.6597 226.19 30.8104L226.768 33.3229C226.986 34.2358 227.094 34.6964 227.094 34.6964H228.602C228.602 34.6964 228.711 34.2442 228.929 33.3397L229.515 30.8523C229.557 30.6932 229.599 30.4754 229.657 30.1991C229.716 29.9227 229.749 29.7803 229.749 29.7803C229.749 29.7803 229.741 29.9059 229.733 30.1656C229.724 30.4168 229.716 30.6429 229.716 30.8523V34.6964H231.014V28.2644Z"
      fill="#FEFEFE"
    />
    <Defs>
      <ClipPath id="clip0">
        <Rect fill="white" height="67" width="67" />
      </ClipPath>
    </Defs>
  </Svg>
);
