import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 14,
    marginTop: 16,
  },
  subTitle: {
    fontSize: 12,
    lineHeight: 16,
    marginTop: 8,
    color: 'text-hint-color',
  },
});
