import React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export const CollapseCircleOutline = (props: SvgProps) => (
  <Svg viewBox="-2 -2 24 24" {...props}>
    <Circle cx="10" cy="10" fill="none" r="9" stroke={props.fill} strokeWidth="2" />
    <Path d="M12.8571 4.28564L9.99996 7.14279L7.14282 4.28564" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <Path d="M7.14295 15.7141L10.0001 12.857L12.8572 15.7141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </Svg>
);
