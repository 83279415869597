import { ApiProductOrderListResponse } from '@shared/interfaces/api';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../../constants/QueryKeys';
import { useAppContext } from '../../../contexts/AppContext';
import { ProductOrderApi } from '../../../utilities/api/ProductOrderApi';
import CancelOrderConfirmationDialog
  from '../ProductOrder/components/CancelOrderConfirmation/CancelOrderConfirmationDialog';
import { DeleteProductOrderDialog } from './QuickQuoteTabView/DeleteProductOrderDialog';
import { ProductOrderList } from './QuickQuoteTabView/ProductOrderList';

export function QuickQuoteTabView () {
  const { setModalProps } = useAppContext();
  const [deleteProductOrder, setDeleteProductOrder] = useState<ApiProductOrderListResponse | null>(
    null,
  );
  const [cancelOrder, setCancelOrder] = useState<ApiProductOrderListResponse | null>(null);
  const isCancelOrderConfirmationVisible = !!cancelOrder;

  const queryClient = useQueryClient();

  const handleCreatePress = () => setModalProps({ type: 'productOrder' });

  const handleEditPress = (productOrderId: string, isDraft: boolean) => setModalProps(
    isDraft
      ? { type: 'productOrder', productOrderId }
      : { type: 'viewProductOrder', productOrderId, editMode: true },
  );

  const handleDuplicatePress = (productOrderId: string) => {
    setModalProps({
      type: 'productOrder',
      productOrderId,
      duplicate: true,
    });
  };

  const handleCreateDeliverablePress = (productOrderId: string) => {
    setModalProps({ type: 'createDeliverable', productOrderId });
  };

  // TODO: update handleViewDeliverablesPress
  const handleViewDeliverablesPress = undefined;

  const handleDeletePress = (productOrder: ApiProductOrderListResponse) => {
    setDeleteProductOrder(productOrder);
  };

  const handleCancelOrder = (productOrder: ApiProductOrderListResponse) => {
    setCancelOrder(productOrder);
  };

  const handleConfirmCancelOrder = async () => {
    await ProductOrderApi.cancelProductOrder(cancelOrder.id);
    await queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST);
    setCancelOrder(null);
  };

  return (
    <>
      <ProductOrderList
        onCancelOrder={handleCancelOrder}
        onCreateDeliverablePress={handleCreateDeliverablePress}
        onCreatePress={handleCreatePress}
        onDeletePress={handleDeletePress}
        onDuplicatePress={handleDuplicatePress}
        onEditPress={handleEditPress}
        onViewDeliverablesPress={handleViewDeliverablesPress}
      />
      <DeleteProductOrderDialog
        onCancelPress={() => setDeleteProductOrder(null)}
        onDeletePress={async () => {
          await queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_LIST);
          setDeleteProductOrder(null);
        }}
        productOrder={deleteProductOrder}
      />
      <CancelOrderConfirmationDialog
        onCancel={() => setCancelOrder(null)}
        onConfirm={handleConfirmCancelOrder}
        productOrder={cancelOrder}
        visible={isCancelOrderConfirmationVisible}
      />
    </>
  );
}
