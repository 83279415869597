import React, { FC, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { CalculationUtility } from '@shared/utils';
import { Modal, Text, TextLink } from '@design';

interface ProductSummaryProps {
  productInfo: {
    applicationRate: number, // summary rate
    applicationRateUom: string, // summary uom
    id: string, // the product id
    name: string, // product name
    skuName: string, // product sku name
  }[],
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: '#000',
    opacity: 0.7,
  },
});

export const RowDetailProductSummary: FC<ProductSummaryProps> = (
  { productInfo }: ProductSummaryProps,
) => {
  const [translate] = useTranslation(['common', 'prepare']);
  const [showMoreModalVisibility, setShowMoreModalVisibility] = useState(false);
  const abridgedProducts = productInfo.slice(0, 6).sort((a, b) => {
    if (a.name < b.name) return -1;
    return 1;
  });
  const isAbridged = productInfo.length !== abridgedProducts.length;

  const renderProducts = (prodInfo) => (
    <>
      {prodInfo.map(({ applicationRate, applicationRateUom, skuName, name, id }, index: number) => {
        const application = `${CalculationUtility.roundToPlaces(applicationRate, 2)} ${translate(applicationRateUom)}`;
        const productName = `${name} (${skuName})`;
        return (
          <Text category="p2" key={id} testID={`value-${index}`}>
            {`${productName}: ${translate('APPLICATION_PER_ACRE', { applicationRate: application })}`}
          </Text>
        );
      })}
    </>
  );

  return (
    <>
      {renderProducts(abridgedProducts)}
      {isAbridged
        && (
          <>
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowMoreModalVisibility(true)}
            >
              {`${translate('SHOW_MORE')}`}
            </TextLink>
            {showMoreModalVisibility
              && (
              <Modal
                backdropStyle={styles.backdrop}
                onClose={() => setShowMoreModalVisibility(false)}
                testID="show-more-modal"
                title={translate('PRODUCTS_AND_APPLICATIONS')}
                visible={showMoreModalVisibility}
              >
                {renderProducts(productInfo)}
              </Modal>
              )}
          </>
        )}
    </>
  );
};
