import React from 'react';

/**
 * Singleton service designed to manage modal components.
 *
 * @type ToastService
 *
 * @method {(
 * element: React.ReactElement<ToastPresentingBased>,
 * config: ToastPresentingConfig) => string}
 * show - Shows component in a modal window. Returns its id.
 *
 * @method {(identifier: string) => string}
 * hide - Hides component from a modal window and returns empty string.
 *
 * @method {(identifier: string, children: React.ReactNode) => void} u
 * pdate - Updates component from a modal window.
 *
 * @property {boolean} getShouldUseTopInsets -
 * returns `true` if StatusBar additional offset is not enabled, returns `false`
 * if StatusBar additional offset is enabled.
 *
 * @property {boolean} setShouldUseTopInsets -
 * true` value enables StatusBar additional offset, `false` disables StatusBar
 * additional offset
 *
 * @overview-example Simple Usage example
 * ToastService simple usage example.
 *
 * ```
 * import React from 'react';
 * import { Layout, Button, Text, ToastService } from '@ui-kitten/components';
 *
 * export const ToastServiceShowcase = () => {
 *
 *   const modalID = '';
 *
 *   const showToast = () => {
 *     const contentElement = this.renderToastContentElement();
 *     this.modalID = ToastService.show(contentElement, { onBackdropPress: this.hideToast });
 *   };
 *
 *   const hideToast = () => {
 *     ToastService.hide(this.modalID);
 *   };
 *
 *   const renderToastContentElement = () => {
 *     return (
 *       <Layout>
 *         <Text>Hi, I'm modal!</Text>
 *       </Layout>
 *     );
 *   };
 *
 *   return (
 *     <Layout>
 *       <Button onPress={showToast}>SHOW MODAL</Button>
 *       <Button onPress={hideToast}>HIDE MODAL</Button>
 *     </Layout>
 *   );
 * }
 * ```
 *
 * @overview-example StatusBar additional offset support configuration
 * ToastService could also control additional status bar offset configuration for all related
 * UI Kitten measurable elements like
 * [Toast](components/modal) and [Popover](components/popover).
 *
 * ```
 * import React from 'react';
 * import * as eva from '@eva-design/eva';
 * import { ApplicationProvider, Layout, Text, ToastService } from '@ui-kitten/components';
 *
 * ToastService.setShouldUseTopInsets = true //applies StatusBar additional offset
 *
 * const HomeScreen = () => (
 *   <Layout style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
 *     <Text category='h1'>HOME</Text>
 *   </Layout>
 * );
 *
 * export default () => {
 *
 *   return (
 *     <ApplicationProvider {...eva} theme={eva.light}>
 *       <HomeScreen />
 *     </ApplicationProvider>
 *   )
 * };
 * ```
 */
class ToastServiceType {
  panel: ToastPresenting | null = null;
  private shouldUseTopInsets: boolean = false;

  public mount (panel: ToastPresenting | null): void {
    this.panel = panel;
  }

  public unmount (): void {
    this.panel = null;
  }

  public show (element: React.ReactElement, config: ToastPresentingConfig): string {
    if (this.panel) {
      return this.panel.show(element, config);
    }
    return null;
  }

  public update (identifier: string, children: React.ReactElement): void {
    if (this.panel) {
      this.panel.update(identifier, children);
    }
  }

  public hide (identifier: string): string {
    if (this.panel) {
      return this.panel.hide(identifier);
    }
    return null;
  }

  public set setShouldUseTopInsets (state: boolean) {
    this.shouldUseTopInsets = state;
  }

  public get getShouldUseTopInsets (): boolean {
    return this.shouldUseTopInsets;
  }
}

export interface ToastPresentingConfig {
}

export interface ToastPresenting {
  show(element: React.ReactElement, config: ToastPresentingConfig): string;

  hide(identifier: string): string;

  update(identifier: string, children: React.ReactElement): void;
}

export const ToastService = new ToastServiceType();
