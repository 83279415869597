import {
  Header,
  ModalSpinner,
  ToastProps,
  Toggle,
  useToast,
  VSpacer,
} from '@design';
import { ApiGrower, ApiGrowerContact, GrowerEndpoint } from '@shared/interfaces/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys, Routes } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useHistory, useParams } from '../../../router';
import { GrowerApi } from '../../../utilities/api';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { CustomerTabView } from './CustomerTabView';

export const CustomerDetailsPage = () => {
  const { grower: customerId, tab } = useParams<{ grower: string, tab: string }>();
  const { currentBusinessId } = useAuthentication();
  const [businessId] = useState(currentBusinessId);
  const queryClient = useQueryClient();
  const [translate] = useTranslation(['growers', 'common']);
  const history = useHistory();

  const { createToast } = useToast();
  const toastProps = (toastText, status: ToastProps['status'] = 'warning'): ToastProps => ({
    children: toastText,
    status,
    testID: 'toast-content-element',
  });
  const [isCustomerActive, setIsCustomerActive] = useState(true);
  const [activeDialogVisible, setActiveDialogVisible] = useState(false);
  const [customer, setCustomer] = useState<ApiGrower>(null);
  const [primaryContact, setPrimaryContact] = useState<Pick<ApiGrowerContact, 'telephone' | 'email' | 'isPrimary'>>(null);

  const fetchDetails = async () => {
    const [growerData, contactData] = await Promise.all([
      GrowerApi.getGrower(customerId),
      GrowerApi.getGrowerContacts(
        customerId, { isActive: ['true', 'false'] },
      ),
    ]);
    return { customer: growerData, contacts: contactData.data };
  };
  useQuery(
    [QueryKeys.GROWER, customerId, currentBusinessId, customerId], fetchDetails, {
      onError: (err: Error) => createToast(toastProps(
        `${translate('UNEXPECTED_ERROR', { error: err })}`,
      )),
      onSuccess: (res) => {
        const { customer: c, contacts: cont } = res;
        setCustomer(c);
        setIsCustomerActive(c.isActive);
        const contactResult = cont.map((contact) => ({
          telephone: contact.telephone,
          email: contact.email,
          isPrimary: contact.isPrimary,
        }));
        setPrimaryContact(contactResult.filter((cnt) => cnt.isPrimary)[0]);
      },
    },
  );

  useEffect(() => {
    if (businessId !== currentBusinessId) {
      history.push(Routes.CUSTOMER_PLANNING.replace(/:tab\?/, 'customers'));
      queryClient.invalidateQueries(QueryKeys.CUSTOMER_LIST);
      queryClient.invalidateQueries(QueryKeys.GROWER);
    }
  }, [businessId, primaryContact, currentBusinessId, history, queryClient]);

  const toggleActiveStatus = useMutation(
    (person: GrowerEndpoint.Update.Request) => (
      GrowerApi.updateGrower(customerId, { isActive: !person.isActive })
    ), {
      onError: (err: Error) => {
        createToast(toastProps(err));
      },
      onSuccess: (data) => {
        setIsCustomerActive(data?.isActive);
        queryClient.invalidateQueries(QueryKeys.GROWER);
        createToast(toastProps(translate('CUSTOMER_UPDATED'), 'success'));
      },
    },
  );

  return (
    <ScrollView contentContainerStyle={{ flex: 1 }} showsVerticalScrollIndicator={false}>
      <Header testID="customer-legal-header" title={customer?.legalName}>
        <Toggle
          checked={isCustomerActive}
          onChange={() => setActiveDialogVisible(true)}
          testID="customer-status-toggle"
        >
          {translate<string>(isCustomerActive ? 'ACTIVE' : 'INACTIVE')}
        </Toggle>
      </Header>
      <VSpacer size="8" />
      {!customer ? (
        <ModalSpinner visible />
      ) : (
        <CustomerTabView
          customer={customer}
          tab={tab}
        />
      )}
      {activeDialogVisible && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate(isCustomerActive ? 'DEACTIVATE' : 'ACTIVATE')}
          messageText={translate(isCustomerActive
            ? 'CUSTOMER_DEACTIVATE_MODAL_WARNING' : 'CUSTOMER_ACTIVATE_MODAL_WARNING')}
          onCancel={() => setActiveDialogVisible(false)}
          onConfirm={() => {
            toggleActiveStatus.mutate(customer);
            setActiveDialogVisible(false);
          }}
          title={translate(isCustomerActive ? 'DEACTIVATE_CUSTOMER' : 'ACTIVATE_CUSTOMER')}
          visible={activeDialogVisible}
        />
      )}
    </ScrollView>
  );
};
