import {
  Badge,
  Button,
  Card,
  Dialog,
  HSpacer,
  Icon,
  IconButton,
  NumericInput,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { ApiProductOrderProductMixComponent, ProductOrderEndpoint } from '@shared/interfaces/api';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { EditProductMixModal } from '../../EditProductMixModal';
import { calculateTankMixTotalCostPerAcre, isProductMixModified } from '../../helpers';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  rightAligned: {
    alignItems: 'flex-end',
  },
  centerAligned: {
    alignItems: 'center',
  },
  spaceBetweenRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spaceBetweenRowReverse: {
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  qtyTextStyles: {
    width: 30,
    textAlign: 'center',
  },
  input: {
    width: 109,
  },
  flexOne: {
    flex: 1,
  },
  dialogFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  isDiscountsAppliedBadge: {
    position: 'absolute',
    minWidth: 150,
    top: 42,
  },
  isProductMixModifiedBadge: {
    position: 'absolute',
    minWidth: 170,
    top: 42,
  },
  text: {
    flexShrink: 1,
    flex: 1,
    width: 1,
  },
});

interface ProductMixCardProps {
  buttonAccessory?: JSX.Element,
  details?: JSX.Element,
  disallowDeleteComponent?: boolean,
  index: number,
  isDraft?: boolean,
  locationId?: string,
  onComponentChange?: (
    tankMixComponent: ProductOrderEndpoint.ProductMixComponent.Update.Request,
  ) => void,
  onComponentRemoved?: (productMixComponentId?: string) => void,
  onProductMixComponentsChange?: (
    components: ApiProductOrderProductMixComponent[],
    previousTankMixId?: string,
  ) => void,
  priceTypeId?: string,
  tankMixComponents: ApiProductOrderProductMixComponent[],
  viewMode?: boolean,
}

const ProductMixCard = ({
  buttonAccessory,
  details,
  disallowDeleteComponent = false,
  index,
  locationId,
  onComponentChange,
  onComponentRemoved,
  onProductMixComponentsChange,
  priceTypeId,
  tankMixComponents,
  viewMode,
}: ProductMixCardProps) => {

  const tankMixComponent = tankMixComponents?.[0];

  const [translate] = useTranslation(['productOrders', 'productCard', 'errors', 'products', 'prepare', 'common']);
  const [showEditTankMixModal, setShowEditTankMixModal] = useState<boolean>(false);
  const [showRemoveProductDialog, setShowRemoveProductDialog] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const productCardDisabled = viewMode;

  const updateComponent = useCallback((updates: ProductOrderEndpoint.Component.Update.Request) => {
    onComponentChange?.(
      {
        ...tankMixComponent,
        ...updates,
      } as ProductOrderEndpoint.ProductMixComponent.Update.Request,
    );
  }, [tankMixComponent, onComponentChange]);

  const showEditComponentButton = !viewMode && !!tankMixComponent;
  const showDeleteComponentButton = !viewMode && !disallowDeleteComponent;

  const isModified = useCallback(() => {
    return isProductMixModified(tankMixComponents);
  }, [tankMixComponents]);

  const totalCostPerAcre = useMemo(() => {
    if (!tankMixComponent) {
      return 0;
    }
    return calculateTankMixTotalCostPerAcre(
      tankMixComponents,
      priceTypeId,
      locationId,
    );
  }, [locationId, priceTypeId, tankMixComponent, tankMixComponents]);

  const isDiscountApplied = tankMixComponents?.some(c => c.discounts?.length > 0);

  return (
    <Card testID={`product-card-${index}`}>
      {/* Product deactivated warning */}
      {!tankMixComponent.tankMix?.isActive && (
        <>
          <View style={styles.row}>
            <Icon
              name="AlertTriangle"
              status="warning"
              testID={`product-card-deactivated-warning-icon-${index}`}
            />
            <HSpacer size="2" />
            <Text
              category="p1"
              testID={`product-card-deactivated-warning-message-${index}`}
            >
              {translate<string>('PRODUCT_DEACTIVATED_WARNING')}
            </Text>
          </View>
          <VSpacer size="5" />
        </>
      )}
      {/* SKU/Product Price */}
      <View style={styles.spaceBetweenRow}>
        <Text
          category="h6"
          style={styles.text}
          testID={`product-card-name-${index}`}
        >
          {tankMixComponent.tankMix?.name}
        </Text>
        <HSpacer size="7" />
        <View style={[styles.column, styles.rightAligned]}>
          <Text
            category="h3"
            testID={`product-card-total-${index}`}
          >
            {StringUtility.formatCurrency(
              calculateTankMixTotalCostPerAcre(
                tankMixComponents,
                priceTypeId,
                locationId,
                tankMixComponent.acreage,
                true,
              ),
            )}
          </Text>
          {
            isDiscountApplied && (
              <Badge
                status="success"
                style={styles.isDiscountsAppliedBadge}
                testID={`product-card-is-discount-applied-${index}`}
              >
                {translate<string>('DISCOUNT_APPLIED').toUpperCase()}
              </Badge>
            )
          }
          {isModified() && (
            <Badge
              status="basic"
              style={[styles.isProductMixModifiedBadge, { right: isDiscountApplied ? 160 : 0 }]}
              testID={`product-card-product-mix-modified-${index}`}
            >
              {translate<string>('PRODUCT_MIX_MODIFIED').toUpperCase()}
            </Badge>
          )}
        </View>
      </View>
      <VSpacer size="4" />
      <View style={styles.row}>
        {/* Acres Input */}
        <View style={styles.input}>
          <NumericInput
            label={translate<string>('ACRES_LABEL')}
            minValue={0}
            onChangeValue={(value) => {
              updateComponent({ acreage: value });
            }}
            precision={3}
            readOnly={productCardDisabled}
            readOnlyLabel={false}
            style={styles.input}
            testID={`product-card-tank-mix-acres-${index}`}
            value={tankMixComponent.acreage}
          />
        </View>
        <HSpacer size="7" />
        <Text category="p2" style={{ marginTop: 37 }}>
          {translate('PER_ACRE_COST', { cost: StringUtility.formatCurrency(totalCostPerAcre) })}
        </Text>
      </View>
      {!buttonAccessory && !showEditComponentButton && (
        <VSpacer size="3" />
      )}
      {/* show/hide details */}
      <View style={styles.row}>
        <View style={[styles.spaceBetweenRowReverse, styles.centerAligned]}>
          <View style={[styles.row, styles.centerAligned]}>
            {!!buttonAccessory && (
              <>
                <HSpacer size="1" />
                <HSpacer size="5" />
              </>
            )}
            {buttonAccessory}
            {showEditComponentButton && (
              <>
                {(!!buttonAccessory) && (
                  <>
                    <HSpacer size="1" />
                    <HSpacer size="5" />
                  </>
                )}
                <IconButton
                  appearance="ghost"
                  onPress={() => setShowEditTankMixModal(true)}
                  status="primary"
                  testID={`product-card-edit-component-button-${index}`}
                >
                  Edit
                </IconButton>
              </>
            )}
            {showDeleteComponentButton && (
              <>
                {(!!buttonAccessory) && (
                  <>
                    <HSpacer size="1" />
                    <HSpacer size="5" />
                  </>
                )}
                <IconButton
                  appearance="ghost"
                  onPress={() => setShowRemoveProductDialog(true)}
                  status="primary"
                  testID={`product-card-remove-component-button-${index}`}
                >
                  Trash
                </IconButton>
              </>
            )}
          </View>
          <TextLink
            accessoryRight={(iconProps) => (
              <Icon
                name={showDetails ? 'ChevronUp' : 'ChevronDown'}
                testID={`product-card-details-text-link-icon-${index}`}
                {...iconProps}
              />
            )}
            appearance="secondary"
            category="p2"
            onPress={() => setShowDetails((prev) => !prev)}
            testID={`product-card-details-text-link-${index}`}
          >
            {translate<string>(showDetails ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
          </TextLink>
        </View>
      </View>
      {/* Details */}
      <View>
        {showDetails && (
          <>
            {!!details && (
              <>
                <VSpacer size="5" />
                {details}
              </>
            )}
          </>
        )}
      </View>
      <Dialog
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <Button
              {...primaryButtonProp}
              appearance="outline"
              status="basic"
              testID={`product-card-cancel-remove-product-${index}`}
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              {...secondaryButtonProp}
              onPress={() => {
                onComponentRemoved(
                  tankMixComponent?.tankMixId,
                );
                setShowRemoveProductDialog(false);
              }}
              testID={`product-card-confirm-remove-product-${index}`}
            >
              {translate<string>('CONFIRM_REMOVE_PRODUCT')}
            </Button>
          </>
        )}
        footerStyle={styles.dialogFooter}
        onClose={() => setShowRemoveProductDialog(false)}
        testID={`product-card-remove-product-dialog-${index}`}
        title={translate<string>(
          'CONFIRM_REMOVE_PRODUCT_MIX_DIALOG_TITLE',
        )}
        visible={showRemoveProductDialog}
      />
      {showEditTankMixModal && (
        <EditProductMixModal
          components={tankMixComponents}
          locationId={locationId}
          onApply={(components, previousTankMixId) => {
            onProductMixComponentsChange?.(components, previousTankMixId);
            setShowEditTankMixModal(false);
          }}
          onCancel={() => setShowEditTankMixModal(false)}
          priceTypeId={priceTypeId}
          visible
        />
      )}
    </Card>
  );
};

export default ProductMixCard;
