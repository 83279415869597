import { Icon } from '@design';
import { TankMixDeliverableV0 } from '@shared/interfaces';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Sizing, ThemeColors } from '../../../../constants';
import {
  SimpleTableCell,
  SimpleTableHeader,
  SimpleTableHeaderCell,
  SimpleTableRow,
} from '../../../../elements/table/SimpleTable';
import { Button, Card, Text } from '../../../../ui-components';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { ShowNotes } from '../../ShowNotes';

interface Props {
  snapshot: TankMixDeliverableV0.Snapshot;
  selections: TankMixDeliverableV0.Selections;
  name?: string;
  deliverableId: string;
  note?: string,
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  flexCenter: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  header: {
    flex: 1,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  base: {
    flex: 1,
    overflow: 'hidden',
  },
  summaryCard: {
    flex: 1,
    borderRadius: Sizing.HALF_SPACING,
    backgroundColor: ThemeColors.DARK_GRAY,
    marginRight: Sizing.BASE_SPACING,
  },
  summaryCardHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  summaryTotal: {
    flexDirection: 'row',
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: 1,
    padding: Sizing.BASE_SPACING,
  },
  summaryCallout: {
    marginHorizontal: Sizing.DOUBLE_SPACING,
    marginBottom: Sizing.BASE_SPACING,
    padding: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.DARKEST_GRAY,
    borderRadius: Sizing.HALF_SPACING,
    alignItems: 'center',
  },
  icon: {
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  superText: { fontSize: 1.5 * Sizing.EM },
  totalContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: Sizing.DOUBLE_SPACING,
    paddingBottom: Sizing.DOUBLE_SPACING,
    borderBottomLeftRadius: Sizing.BASE_SPACING,
    borderBottomRightRadius: Sizing.BASE_SPACING,
  },
  totalText: {
    fontSize: 1.2 * Sizing.EM,
    fontWeight: 'bold',
    marginBottom: Sizing.BASE_SPACING,
    paddingLeft: Sizing.HALF_SPACING,
  },
  total: {
    fontSize: 1.2 * Sizing.EM,
    fontWeight: 'bold',
    marginBottom: Sizing.BASE_SPACING,
    paddingRight: Sizing.HALF_SPACING,
  },
  notesRowsContainer: {
    flex: 1,
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  notesContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: Sizing.BASE_SPACING,
  },
  notesHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
    marginRight: 1.2 * Sizing.BASE_SPACING,
  },
});

type Groups = keyof Props['selections'];
type Fields<T extends Groups> = keyof Props['selections'][T];

export const TankMixDeliverableDesktopView: FunctionComponent<Props> = ({
  selections,
  snapshot,
  note,
  name,
  deliverableId,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  // eslint-disable-next-line prefer-arrow-callback,func-names
  const showField = useCallback(function <T extends Groups>(
    group: T,
    field: Fields<T>,
  ) {
    if (!selections) {
      return false;
    }
    if (!selections[group]) {
      return false;
    }
    if (!selections[group][field]) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return selections[group][field]['enabled'] === true;
  }, [selections]);

  // eslint-disable-next-line prefer-arrow-callback,func-names
  const showGroup = useCallback(function <T extends Groups>(
    group: T,
  ) {
    if (!selections) {
      return false;
    }
    if (!selections[group]) {
      return false;
    }
    return Object.keys(selections[group]).some((f) => showField(group, f as Fields<T>));
  }, [selections, showField]);

  return (
    <View
      style={{
        flex: 1,
        marginTop: Sizing.HALF_SPACING,
      }}
    >
      {snapshot && selections && (
        <View style={[styles.headerContainer, { marginBottom: Sizing.BASE_SPACING }]}>
          <View style={[styles.flexLeft, { flex: 1 }]}>
            <Text
              numberOfLines={1}
              style={styles.header}
            >
              {name || `${translate('TANK_MIX_DELIVERABLE')} `}
            </Text>
          </View>
          <View style={styles.flexRight}>
            <Button
              iconLeft="download-outline"
              onPress={() => LinkingUtility.openUrl(
                `${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`,
                { newTab: true },
              )}
              status="secondary"
            >
              {translate('DOWNLOAD_PDF')}
            </Button>
          </View>
        </View>
      )}
      <ScrollView nativeID="farm-plan-creation-view" style={styles.base}>
        <View style={{ flexDirection: 'row' }}>
          {showGroup('HEADER') && (
            <View style={styles.summaryCard}>
              {showField('HEADER', 'TANK_MIX_NAME') && (
                <View style={{ padding: Sizing.BASE_SPACING }}>
                  <Text
                    numberOfLines={1}
                    style={styles.summaryCardHeader}
                  >
                    {snapshot.HEADER.TANK_MIX_NAME.data}
                  </Text>
                </View>
              )}
              {showField('HEADER', 'GROWER_NAME') && (
                <View style={styles.summaryTotal}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.PRIMARY}
                      name="GrowersPlanning"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="grower-icon"
                    />
                    <Text
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {snapshot.HEADER.GROWER_NAME.data}
                    </Text>
                  </View>
                </View>
              )}
              {showField('HEADER', 'BUSINESS_NAME') && (
                <View style={styles.summaryTotal}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.PRIMARY}
                      name="GrowersBusiness"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="business-icon"
                    />
                    <Text
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {snapshot.HEADER.BUSINESS_NAME.data}
                    </Text>
                  </View>
                </View>
              )}
              {showField('HEADER', 'BUSINESS_USER_NAME') && (
                <View style={styles.summaryTotal}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.PRIMARY}
                      name="Person"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="user-icon"
                    />
                    <Text
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {snapshot.HEADER.BUSINESS_USER_NAME.data}
                    </Text>
                  </View>
                </View>
              )}
              {showField('HEADER', 'LOCATION_NAME')
                && !!snapshot?.HEADER?.LOCATION_NAME?.data
                && (
                  <View style={styles.summaryTotal}>
                    <View style={[styles.flexLeft, { flex: 1 }]}>
                      <Icon
                        fill={ThemeColors.PRIMARY}
                        name="Navigation2"
                        style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                        testID="location-icon"
                      />
                      <Text
                        numberOfLines={1}
                        style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                      >
                        {snapshot?.HEADER?.LOCATION_NAME?.data}
                      </Text>
                    </View>
                  </View>
                )}
            </View>
          )}
          {showField('OVERVIEW', 'TOTAL_COST_PER_AREA') && (
            <View style={[styles.summaryCard, { marginRight: 0 }]}>
              <View style={{ padding: Sizing.BASE_SPACING }}>
                <Text
                  numberOfLines={1}
                  style={styles.summaryCardHeader}
                >
                  {translate('COST_SUMMARY')}
                </Text>
              </View>
              <View style={[styles.summaryCallout, styles.flexCenter, { flexDirection: 'column' }]}>
                <Text>
                  {translate('TOTAL_TANK_MIX_COST')}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                  <Text style={styles.superText}>
                    {StringUtility.formatCurrency(
                      snapshot.OVERVIEW.TOTAL_COST_PER_AREA.data,
                    )}
                  </Text>
                  <Text>
                    {translate('PER_AC')}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>
        {!!note && (
          <Card style={{ marginTop: Sizing.BASE_SPACING }} testID="note-card">
            <View style={styles.notesContainer}>
              <Text
                numberOfLines={1}
                style={styles.notesHeader}
              >
                { translate('NOTES') as string }
              </Text>
              <View style={styles.notesRowsContainer}>
                <ShowNotes note={note} />
              </View>
            </View>
          </Card>
        )}
        {showGroup('PRODUCT_SUMMARY') && (
          <Card
            header={(
              <View style={styles.headerContainer}>
                <Text style={styles.header}>{translate('PRODUCTS')}</Text>
              </View>
            )}
            style={{ marginTop: Sizing.BASE_SPACING }}
            testID="product-summary-card"
          >
            <SimpleTableHeader>
              {showField('PRODUCT_SUMMARY', 'SKU_NAME') && (
                <SimpleTableHeaderCell flex={1} label={translate('PRODUCT_SKU')} />
              )}
              {showField('PRODUCT_SUMMARY', 'RATE') && (
                <SimpleTableHeaderCell label={translate('RATE_PER_AC')} width={100} />
              )}
              {showField('PRODUCT_SUMMARY', 'INTENDED_USE') && (
                <SimpleTableHeaderCell label={translate('INTENDED_USE')} width={150} />
              )}
              {showField('PRODUCT_SUMMARY', 'COST') && (
                <SimpleTableHeaderCell align="right" label={translate('COST_PER_AC')} width={100} />
              )}
            </SimpleTableHeader>
            {snapshot.PRODUCT_SUMMARY.map((ps, i) => (
              <SimpleTableRow key={ps.SKU_NAME?.data || i}>
                {showField('PRODUCT_SUMMARY', 'SKU_NAME') && (
                  <SimpleTableCell flex={1}>
                    <Text>{ps.SKU_NAME.data}</Text>
                  </SimpleTableCell>
                )}
                {showField('PRODUCT_SUMMARY', 'RATE') && (
                  <SimpleTableCell width={100}>
                    <Text>{ps.RATE.data}</Text>
                  </SimpleTableCell>
                )}
                {showField('PRODUCT_SUMMARY', 'INTENDED_USE') && (
                  <SimpleTableCell width={150}>
                    <Text>{ps.INTENDED_USE.data}</Text>
                  </SimpleTableCell>
                )}
                {showField('PRODUCT_SUMMARY', 'COST') && (
                  <SimpleTableCell align="right" width={100}>
                    <Text>{StringUtility.formatCurrency(ps.COST.data)}</Text>
                  </SimpleTableCell>
                )}
              </SimpleTableRow>
            ))}
            {showField('OVERVIEW', 'TOTAL_COST_PER_AREA') && (
            <View style={styles.totalContainer}>
              <View>
                <Text style={styles.totalText}>{translate('TOTAL')}</Text>
              </View>
              <View>
                <Text style={styles.total}>
                  {StringUtility.formatCurrencyAccounting(
                    snapshot.OVERVIEW.TOTAL_COST_PER_AREA.data,
                  )}
                </Text>
              </View>
            </View>
            )}
          </Card>
        )}
      </ScrollView>
    </View>
  );
};
