import { ApiForm } from '@shared/interfaces/api';

const PropertyGroupValidation = {
  Constants: {
    NameMaxLength: 50,
    DescriptionMaxLength: 250,
  },
  Validate: (form: ApiForm, page: number, originalForm?: ApiForm) => {
    const constants = PropertyGroupValidation.Constants;
    const formDescriptionLength = form?.description?.length ?? 0;
    const formNameLength = form?.name?.length ?? 0;

    const validationState = [
      [ // page 1 validations
        (formNameLength > 0) && formNameLength <= constants.NameMaxLength,
        ((formDescriptionLength > 0) && (formDescriptionLength <= constants.DescriptionMaxLength)),
      ].every(Boolean),
      [ // page 2 validations
        !!form?.fields?.length,
        (form?.fields?.every((field) => field.name.trim() !== '')),
        (form?.fields?.every((field) => {
          return field?.options?.every((option) => option.value.trim() !== '');
        })),
        (JSON.stringify(form) !== JSON.stringify(originalForm)),
      ].every(Boolean),
    ];

    return validationState[page];
  },
};

export default PropertyGroupValidation;
