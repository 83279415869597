import {
  HSpacer,
  LargeModal,
  Search,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  VSpacer,
} from '@design';
import { Ionicons } from '@expo/vector-icons';
import { IGrowerFarmPlanZoneProductSummary } from '@shared/interfaces';
import { ApiCrop, ApiGrowerCropZone, ApiGrowerFarm, ApiGrowerField } from '@shared/interfaces/api';
import { FarmUtility } from '@shared/utils';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ThemeColors } from '../../../../constants';
import { StringUtility } from '../../../../utilities';
import { ButtonBar } from '../../../components/shared/ButtonBar';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    paddingHorizontal: 36,
    marginTop: -26,
    width: '100%',
  },
  headerTableRow: {
    justifyContent: 'space-between',
  },
  tableCell: {
    flex: 1,
  },
  headerCell: {
    backgroundColor: 'transparent',
    flex: 1,
  },
});

interface ViewFieldDetailsModalProps {
  crops: ApiCrop[],
  growerFarms: ApiGrowerFarm[],
  onClose: () => void,
  zoneSummaries: IGrowerFarmPlanZoneProductSummary[],
}

interface SummaryRow extends IGrowerFarmPlanZoneProductSummary {
  cropColor: string,
  farm: ApiGrowerFarm,
  field?: ApiGrowerField,
  key: string,
  zone?: ApiGrowerCropZone,
}

export const ViewFieldDetailsModal = ({
  crops,
  growerFarms,
  onClose,
  zoneSummaries,
}: ViewFieldDetailsModalProps) => {
  const [translate] = useTranslation(['farmPlans', 'growers', 'prepare', 'common']);
  const [search, setSearch] = useState('');

  const summaries: SummaryRow[] = zoneSummaries.map((zoneSummary) => {
    const farm = growerFarms.find((f) => (
      f.growerFields
        .map((field) => field.id)
        .includes(zoneSummary.growerFieldId)
    ));
    const field = farm.growerFields.find((f) => f.id === zoneSummary.growerFieldId);
    const zone = field.cropZones.find((z) => z.id === zoneSummary.growerCropZoneId);
    const crop = crops.find((c) => c.cropType === zoneSummary.cropType);

    const key = [
      farm?.farmName,
      field?.fieldName,
      zone?.zoneName || '',
      zoneSummary.pass,
      zoneSummary.passName,
      zoneSummary.tankMixNumber,
      zoneSummary.cropType,
      zoneSummary.cropSubType,
      zoneSummary.product?.skuName,
    ].join(' ');

    return _.assignIn(zoneSummary, {
      farm,
      field,
      zone,
      cropColor: crop?.color || ThemeColors.TEXT,
      key,
    });
  });

  const renderRows = () => {
    let lastFarmId = '';
    let lastFieldId = '';
    let lastZoneId = '';

    const rows = summaries.map((zoneSummary, index) => {
      let tankMixTag = '';

      if (zoneSummary.tankMixNumber) {
        tankMixTag += `(mix-${zoneSummary.tankMixNumber}`;
        tankMixTag += zoneSummary.isCarrier ? '-c)' : ')';
      }

      const row = {
        key: zoneSummary.key,
        element: (
          <TableRow key={zoneSummary.key} testID={`field-details-row-${zoneSummary.key}`}>
            {/* Farm */}
            <TableCell style={styles.tableCell} testID={`field-details-farm-${index}`}>
              { search || lastFarmId !== zoneSummary.farm?.id ? (
                `${zoneSummary.farm?.farmName} (${StringUtility.formatDecimal(
                  FarmUtility.getFarmArea(zoneSummary.farm), 0,
                )} ${translate('ACRES_SHORT')})`
              ) : null }
            </TableCell>
            {/* Field */}
            <TableCell style={styles.tableCell} testID={`field-details-field-${index}`}>
              { search || lastFieldId !== zoneSummary.growerFieldId ? (
                `${zoneSummary.field?.fieldName} (${StringUtility.formatDecimal(
                  zoneSummary.field?.areaValue, 0,
                )} ${translate('ACRES_SHORT')})`
              ) : null }
            </TableCell>
            {/* Crop Zone */}
            <TableCell style={styles.tableCell} testID={`field-details-crop-zone-${index}`}>
              { zoneSummary.growerCropZoneId
                && (search || lastZoneId !== zoneSummary.growerCropZoneId) ? (
                  `${zoneSummary.zone?.zoneName} (${StringUtility.formatDecimal(
                    zoneSummary.zone?.areaValue, 0,
                  )} ${translate('ACRES_SHORT')})`) : null }
            </TableCell>
            {/* Crop */}
            <TableCell style={styles.tableCell} testID={`field-details-crop-${index}`}>
              <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                <Ionicons
                  name="ellipse"
                  size={6}
                  style={{
                    color: zoneSummary.cropColor,
                  }}
                  testID={`field-details-crop-color-${index}`}
                />
                <HSpacer size="4" />
                <Text category="p2" testID={`field-details-crop-${index}`}>
                  {`${translate(zoneSummary.cropType)} | ${translate(zoneSummary.cropSubType)}`}
                </Text>
              </View>
            </TableCell>
            {/* Pass name */}
            <TableCell style={styles.tableCell} testID={`field-details-pass-name-${index}`}>
              {zoneSummary.passName}
            </TableCell>
            {/* Product SKU */}
            <TableCell style={styles.tableCell} testID={`field-details-product-sku-${index}`}>
              {`${zoneSummary.product?.skuName} ${tankMixTag}`}
            </TableCell>
            {/* Rate/ac */}
            <TableCell style={styles.tableCell} testID={`field-details-rate-ac-${index}`}>
              {`${StringUtility.formatDecimal(
                zoneSummary.rateApplied,
                3,
              )} ${translate<string>(zoneSummary.rateAppliedUom)}`}
            </TableCell>
            {/* Units required */}
            <TableCell style={styles.tableCell} testID={`field-details-units-required-${index}`}>
              {`${StringUtility.formatDecimal(
                zoneSummary.unitsRequired, 2,
              )} ${translate<string>(zoneSummary.unitsRequiredUom)}`}
            </TableCell>
          </TableRow>),
      };

      lastFarmId = zoneSummary.farm?.id;
      lastFieldId = zoneSummary.growerFieldId;
      lastZoneId = zoneSummary.growerCropZoneId || zoneSummary.growerFieldId;

      return row;
    });

    if (!search) {
      return rows.map((r) => r.element);
    }

    return rows.map((row) => {
      if (new RegExp(`.*${search}.*`, 'ig').test(row?.key)) {
        return row.element;
      }
      return null;
    }).filter((row) => !!row);
  };

  const formatHeader = (header: string) => {
    return header[0].toUpperCase() + header.substring(1).toLowerCase();
  };

  const fieldCount = new Set(summaries.map((s) => s.growerFieldId)).size;

  const formPage = [
    <View style={styles.container}>
      <KeyboardAwareScrollView>
        <Search
          onChangeText={(value) => setSearch(value)}
          onClear={() => setSearch('')}
          size="medium"
          testID="view-field-details-search"
          value={search}
        />
        <VSpacer size="8" />
        <Text category="p2">
          {fieldCount !== 1
            ? `${fieldCount} ${translate<string>('FIELDS').toLowerCase()}`
            : `${fieldCount} ${translate<string>('FIELD').toLowerCase()}`}
        </Text>
        <VSpacer size="5" />
        <Table
          tableBackground="transparent"
          testID="view-field-details-table"
        >
          <TableRow
            appearance="header"
            style={[styles.headerCell, styles.headerTableRow]}
            testID="view-field-details-table-row-farm"
          >
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-farm"
            >
              <Text category="s2">{`${translate('FARM')}`}</Text>
            </TableHeader>
            <TableHeader
              style={styles.headerCell}
              testID="view-field-details-table-header-field"
            >
              <Text category="s2">{`${translate('FIELD')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-crop-zone"
            >
              <Text category="s2">{`${formatHeader(translate('CROP_ZONE'))}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-crop"
            >
              <Text category="s2">{`${translate('CROP')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-pass"
            >
              <Text category="s2">{`${translate('PASS')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-product-sku"
            >
              <Text category="s2">{`${translate('PRODUCT_SKU')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-rate-per-acre"
            >
              <Text category="s2">{`${translate('RATE_PER_ACRE')}`}</Text>
            </TableHeader>
            <TableHeader
              style={[styles.headerCell]}
              testID="view-field-details-table-header-units-required"
            >
              <Text category="s2">{`${formatHeader(translate('UNITS_REQUIRED'))}`}</Text>
            </TableHeader>
          </TableRow>
          { renderRows() }
        </Table>
      </KeyboardAwareScrollView>
    </View>,
  ];

  return (
    <LargeModal
      footer={() => (
        <ButtonBar
          buttonBarType="grouped"
          disableRightAction={false}
          rightAction={onClose}
          rightButtonText={translate('CLOSE')}
          size="giant"
          testID="close-button"
        />
      )}
      pages={formPage}
      testID="field-details-large-modal"
      title={translate<string>('FIELD_DETAILS')}
      visible
    />
  );
};
