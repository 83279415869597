import React from 'react';
import { View, ImageProps } from 'react-native';
import { Button, ButtonProps, HSpacer } from '@design';
import { RenderProp } from '@ui-kitten/components/devsupport';
import { StyleService } from '@ui-kitten/components';
import { useDebounce } from '../../../hooks/useDebounce';

const styles = StyleService.create({
  spacedButtonLeft: {
    position: 'absolute',
    maxHeight: 100,
    marginBottom: 32,
    marginHorizontal: 24,
    bottom: 0,
    justifyContent: 'flex-end',
    left: 0,
  },
  spacedButtonRight: {
    position: 'absolute',
    maxHeight: 100,
    right: 0,
    bottom: 0,
    marginBottom: 32,
    marginHorizontal: 24,
    justifyContent: 'flex-end',
  },
  groupedButton: {
    position: 'absolute',
    maxHeight: 100,
    marginBottom: 32,
    marginHorizontal: 24,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
});

type ButtonBarType = 'grouped' | 'spaced';

interface ButtonBarProps extends Omit<ButtonProps, 'testID'> {
  accessoryLeft?: RenderProp<Partial<ImageProps>>;
  accessoryRight?: RenderProp<Partial<ImageProps>>;
  buttonBarType?: ButtonBarType;
  disabledLeftAction?: boolean;
  disableRightAction?: boolean;
  hideRightButton?: boolean;
  hideLeftButton?: boolean;
  leftAction?(): void;
  leftButtonText?: string;
  rightAction?(): void;
  rightButtonText?: string;
  testID: string;
}

export const ButtonBar = (props: ButtonBarProps) => {
  const {
    accessoryLeft,
    accessoryRight,
    buttonBarType = 'spaced',
    disabledLeftAction,
    disableRightAction,
    hideRightButton,
    leftAction,
    leftButtonText,
    hideLeftButton,
    rightAction,
    rightButtonText,
    testID,
    ...restProps
  } = props;

  const { debounce } = useDebounce();

  const ButtonLeft = (hideLeftButton || (!leftButtonText && !accessoryLeft)) ? null : (
    <Button
      accessoryLeft={accessoryLeft}
      appearance="outline"
      capitalize
      design="floating"
      disabled={!leftAction}
      onPress={leftAction}
      size="large"
      status="basic"
      testID={`${testID}-left`}
      {...restProps}
    >
      {leftButtonText}
    </Button>
  );

  const ButtonRight = (hideRightButton || (!rightButtonText && !accessoryRight)) ? null : (
    <Button
      accessoryRight={accessoryRight}
      design="floating"
      disabled={disableRightAction}
      onPress={() => debounce(rightAction)}
      size="large"
      testID={`${testID}-right`}
      {...restProps}
      capitalize
    >
      {rightButtonText}
    </Button>
  );

  return buttonBarType === 'spaced' ? (
    <>
      {!!ButtonLeft && (
        <View style={styles.spacedButtonLeft}>
          {ButtonLeft}
        </View>
      )}
      {!!ButtonRight && (
        <View style={styles.spacedButtonRight}>
          {ButtonRight}
        </View>
      )}
    </>
  ) : (
    <View style={styles.groupedButton}>
      {ButtonLeft}
      {(!ButtonLeft || !ButtonRight) ? null : <HSpacer size="8" />}
      {ButtonRight}
    </View>
  );
};
