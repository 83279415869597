import { DataPoint, ViewRow, VSpacer } from '@design';
import { IntegrationType } from '@shared/enums';
import { CropTypeAndSubtype } from '@shared/interfaces';
import { ApiFarmPlan } from '@shared/interfaces/api';
import { toShortDateTime } from '@shared/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../hooks/useIntegration';
import { ExternalIdsDisplay } from '../../../components/shared/IntegrationOrder/ExternalIdsDisplay';
import { CropSummary } from './CropSummary';
import { ProductSummary } from './ProductSummary';

export interface FarmPlanDetailsProps {
  farmPlan: ApiFarmPlan,
}
export const FarmPlanDetails = ({ farmPlan }: FarmPlanDetailsProps) => {
  const {
    createdAt,
    updatedAt,
    businessLocation,
    cropYear,
  } = farmPlan;
  const [translate] = useTranslation(['common', 'farmPlans', 'integration']);
  const { currentBusinessId } = useAuthentication();
  const { integration } = useIntegration({ businessId: currentBusinessId });
  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );
  const uniqueCrops: CropTypeAndSubtype[] = [];
  farmPlan.cropLogics?.forEach((cropLogic) => {
    const crop = {
      cropSubType: cropLogic.cropSubType,
      cropType: cropLogic.cropType,
    };
    if (!uniqueCrops.find((c) => c.cropType === cropLogic.cropType)) {
      uniqueCrops.push(crop);
    }
  });

  const products = farmPlan.growerFarmPlanProductSummaries ?? [];

  const externalDisplayIds = products.map(
    (summary) => summary.externalDisplayId,
  ).filter(Boolean);

  return (
    <>
      <ViewRow>
        <DataPoint
          flex
          label={translate('BUSINESS_LOCATION')}
          testID="business-location"
        >
          {businessLocation?.locationName ?? `${translate('NOT_APPLICABLE')}`}
        </DataPoint>
        <DataPoint
          flex
          label={translate('CROP_YEAR')}
          testID="crop-year"
        >
          {cropYear}
        </DataPoint>
        <DataPoint
          flex
          label={translate('PRODUCTS_COUNT', { count: products.length })}
          testID="products"
        >
          <ProductSummary products={products} />
        </DataPoint>
      </ViewRow>
      <VSpacer size="9" />
      <ViewRow>
        <DataPoint
          flex
          label={translate('CROPS')}
          testID="crop-type"
        >
          <CropSummary crops={Array.from(uniqueCrops).sort()} />
        </DataPoint>
        <DataPoint
          flex
          label={translate('LAST_UPDATED')}
          testID="date-updated"
        >
          {toShortDateTime(updatedAt)}
        </DataPoint>
        <DataPoint
          flex
          label={translate('DATE_CREATED')}
          testID="date-created"
        >
          {toShortDateTime(createdAt)}
        </DataPoint>
      </ViewRow>
      {hasBusinessCentralIntegration &&
        <>
          <VSpacer size="9" />
          <ViewRow>
            <DataPoint
              flex
              label={translate('EXTERNAL_ID')}
              testID="crop-type"
            >
              <ExternalIdsDisplay externalIds={externalDisplayIds} />
            </DataPoint>
          </ViewRow>
        </>
      }
    </>
  );
};
