export default {
  ADD_COMPONENT: 'Add component',
  ADD_CUSTOMERS_TITLE: 'Add Customers',
  ADJUVANT: 'Adjuvant',
  CANCEL_EDIT_DIALOG_DESCRIPTION_NEW: 'Your Product Mix will not be saved.',
  CANCEL_EDIT_DIALOG_DESCRIPTION_EDIT: 'Discard Changes?',
  CANCEL_EDIT_DIALOG_TITLE: 'Cancel Product Mix?',
  CARRIER: 'Carrier',
  COMPONENT: 'Component',
  COMPONENTS: 'Components',
  CONTINUE_EDITING: 'Continue editing',
  CORPORATE_LOCATION: 'Corporate',
  COST_PER_ACRE: 'Cost/ac',
  CREATED: 'Created',
  CREATE_PRODUCT_MIX: 'Create Product Mix',
  CUSTOMERS_PRODUCTMIX_ASSOCIATION: 'Customers associated with this product mix',
  DUPLICATE_DESCRIPTION: 'Please rename this Product Mix',
  EDIT_DETAILS: 'Edit details',
  GETTING_PRODUCT_MIXES: 'Getting Product Mixes',
  GENERATE_DELIVERABLE: 'Generate Deliverable',
  INTENDED_USE: 'Intended use',
  IN_USE: 'In use',
  NO_COMPONENTS: 'No Components',
  ORDER_NAME: 'Order Name',
  PRODUCT_MIX_ALREADY_EXISTS: 'Product Mix {{name}} already exists',
  PRODUCT_MIX_DETAILS: 'Product Mix Details',
  PRODUCT_MIX_DUPLICATED: 'Product Mix duplicated',
  PRODUCT_MIX_NAME: 'Product Mix Name',
  PRODUCT_MIX_UPDATED: 'Product Mix updated',
  QUIT_EDITING: 'Yes, exit without saving',
  RATE_PER_ACRE: 'Rate/ac',
  SAVE_CHANGES: 'Save changes',
  SELECT_CUSTOMERS_DESCRIPTION: 'Select customers to be associated with this product mix',
};
