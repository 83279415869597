import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ApiFeatureFlag } from '@shared/interfaces/api/FeatureFlagEndpoint';
import { FeatureFlags } from '@shared/enums';
import { FeatureFlagsApi } from '../../../../utilities/api/FeatureFlagsApi';
import { useAuthentication, SessionState } from '../../../../contexts/dataSync/AuthenticationContext';

interface IFeatureFlagContext {
  flags: ApiFeatureFlag[];
  isFlagsInit: boolean;
  isFlagOn: (businessId: string, flag: FeatureFlags) => boolean;
  removeFlag: (flag: ApiFeatureFlag) => void;
  updateFlag: (flag: ApiFeatureFlag) => void;
}

export const FeatureFlagContext = React.createContext<IFeatureFlagContext>(null);

interface FeatureFlagProviderProps {
  children: ReactNode,
}

// TODO currently this provider uses the api directly
// that means that feature flags will not be available offline
// to fix this, we need to hoist ApiServiceProvider above FeatureFlagProvider
export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const { sessionState } = useAuthentication();
  const [flags, setFlags] = React.useState<ApiFeatureFlag[]>([]);
  const [isInit, setIsInit] = useState(false);
  const [initRequest, setIsInitRequest] = useState(false);

  const pendingFlags = useRef<ApiFeatureFlag[]>([]);

  useEffect(() => {
    async function getFlags () {
      const apiFlags = await FeatureFlagsApi.getAllFeatureFlags();
      setFlags(apiFlags);
      setIsInit(true);
    }
    if (!initRequest) {
      getFlags();
      setIsInitRequest(true);
    }

    if (sessionState === SessionState.LOGGED_IN) {
      // Here we are finding flags that are in memory but without an id
      // these flags were likely created on the login page or some other public
      // page while not logged in.  We will create the flags now that the user
      // is logged in so that they can be edited in the future.
      const createFlags = flags.filter((flag) => !flag.id);
      createFlags.forEach((createFlag) => {
        FeatureFlagsApi.getFeatureFlag((createFlag.name as FeatureFlags)).then((newFlag) => {
          setFlags((oldFlags) => oldFlags.map((oldFlag) => (
            oldFlag === createFlag
              ? newFlag
              : oldFlag
          )));
        });
      });
    }
  }, [flags, initRequest, sessionState]);

  const removeFlag = useCallback((flag: ApiFeatureFlag) => {
    setFlags((oldFlags) => oldFlags.filter((oldFlag) => oldFlag !== flag));
  }, []);

  const updateFlag = useCallback((flag: ApiFeatureFlag) => {
    setFlags((oldFlags) => {
      const index = oldFlags.findIndex((oldFlag) => oldFlag.id === flag.id);
      const newFlags = [...oldFlags];
      newFlags[index] = { ...flag };
      return newFlags;
    });
  }, []);

  const isFlagOn = useCallback((businessId: string, flag: FeatureFlags) => {
    let flagObj: ApiFeatureFlag = flags.find((f) => f.name === flag);
    if (!flagObj) {
      // check pending to avoid hammering the server on page load.
      flagObj = pendingFlags.current.find((f) => f.name === flag);
    }
    if (!flagObj) {
      // when a flag is first accessed in an environment, or on page load before getFlags completes,
      // flags will not be defined yet.  In that case, we will init them to an off state then ask
      // from the server.  If it is the first time the server has seen the flag requested, then
      // it will create and return.  Either way, after the flag is returned from the server, this
      // initial value will be replaced by the true server value and via state change triger a
      // re-render of those requesting the flag.
      flagObj = {
        createdAt: new Date(),
        id: null,
        updatedAt: new Date(),
        name: flag,
        enabled: false,
        isActive: true,
        includedBusinessIds: [],
        excludedBusinessIds: [],
      };
      pendingFlags.current.push(flagObj);
      // timeout to prevent state update during render.
      setTimeout(() => setFlags([...flags, flagObj]));

      if (isInit && sessionState === SessionState.LOGGED_IN) {
        // when logged in, flags can be created by the api.
        // if there is a flag only available on public routes, then someone will
        // need to log in, then access the public route in order to create the flag
        // in the api.
        FeatureFlagsApi.getFeatureFlag(flag).then((newFlag) => {
          if (!newFlag) {
            return;
          }
          setFlags((oldFlags) => {
            const newFlags = [...oldFlags];
            const index = newFlags.findIndex((f) => f.name === newFlag.name);
            if (index >= 0) {
              newFlags[index] = newFlag;
            } else {
              newFlags.push(newFlag);
            }
            return newFlags;
          });
          // remove from pending flags as well.
          pendingFlags.current = pendingFlags.current.filter((f) => f.name !== newFlag.name);
        });
      }
    }

    if (flagObj.enabled) {
      const disabled = flagObj.excludedBusinessIds.includes(businessId);
      return !disabled;
    }

    return flagObj.includedBusinessIds.includes(businessId);
  }, [flags, isInit, sessionState]);

  return (
    <FeatureFlagContext.Provider
      value={{
        flags,
        isFlagOn,
        removeFlag,
        updateFlag,
        isFlagsInit: isInit,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
};
