import React, { FunctionComponent } from 'react';
import { Text as UIKText, TextProps as UIKTextProps } from '@ui-kitten/components';
import { Category } from '@theme/variant-interfaces/Category';
import { StyleSheet } from 'react-native';

interface TextCustomStyles {
  wordBreak?: 'break-all' | 'keep-all' | 'normal' | 'break-word';
}

export interface TextProps extends UIKTextProps {
  category?: Category,
  disabled?: boolean,
  testId?: string | undefined,
  wrap?: boolean,
  style?: UIKTextProps['style'] & TextCustomStyles,
}
const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    width: '100%',
    overflow: 'hidden',
  },
});

export const Text: FunctionComponent<TextProps> = (props) => {
  return <UIKText {...props} style={[props.style, props.wrap && styles.wrap]} />;
};
