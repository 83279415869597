import React from 'react';
import {
  DataPoint,
  Modal,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  VSpacer,
} from '@design';
import { Spinner } from '@ui-kitten/components';
import { ApiTankMix } from '@shared/interfaces/api';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { CropTypeAndSubtype } from '@shared/interfaces';
import { CalculationUtility } from '@shared/utils';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';
import { useUser } from '../../../../hooks/useUser';
import { StringUtility } from '../../../../utilities';

const styles = StyleSheet.create({
  crops: {
    paddingBottom: 4,
  },
  flex: {
    flex: 1,
  },
});

const baseTestId = 'tank-mix-details-modal';

interface TankMixDetailsModalProps {
  businessLocationId?: string,
  onClose: () => void,
  priceTypeId: string,
  tankMix: ApiTankMix,
  visible: boolean,
}

export const TankMixDetailsModal = ({
  businessLocationId,
  onClose,
  priceTypeId,
  tankMix,
  visible,
}: TankMixDetailsModalProps) => {
  const [translate] = useTranslation(['common', 'prepare']);
  const { isUserLoading, user } = useUser({
    userAccountId: tankMix.ownerBusinessUserId,
  });

  const {
    componentCosts,
    totalCostPerAcre,
  } = CalculationUtility.calculateTankMixComponentCostPerAcre(
    tankMix,
    priceTypeId,
    businessLocationId || tankMix.businessLocationId,
  );

  const getCropName = (crop: CropTypeAndSubtype) => {
    return `${translate<string>(crop.cropType)} | ${translate<string>(crop.cropSubType)}`;
  };

  return (
    <Modal
      headerVSpacerSize="6"
      maxHeight
      onClose={onClose}
      subTitle={(
        <DescriptionListItem
          label={translate<string>('OWNER')}
          testID={`${baseTestId}-owner`}
          text={!isUserLoading ? user?.fullName : <Spinner size="tiny" />}
          textCategory="p2"
        />
      )}
      testID={baseTestId}
      title={tankMix.name}
      visible={visible}
      width={637}
    >
      <DataPoint
        flex
        label={translate<string>('CROPS')}
        labelStyle={styles.crops}
        testID={`${baseTestId}-crops`}
      >
        {!!tankMix.crops && tankMix.crops.map(getCropName).join(', ')}
      </DataPoint>
      <VSpacer size="9" />
      <Table
        tableBackground="transparent"
        tableBorder="top"
        testID={`${baseTestId}-cost-table`}
      >
        <TableRow
          appearance="header"
          testID={`${baseTestId}-cost-table-header`}
        >
          <TableHeader
            style={styles.flex}
            tableHeaderBackground="transparent"
            testID={`${baseTestId}-cost-table-header-product-sku`}
          >
            {translate<string>('PRODUCT_SKU')}
          </TableHeader>
          <TableHeader
            style={styles.flex}
            tableHeaderBackground="transparent"
            testID={`${baseTestId}-cost-table-header-rate-per-acre`}
          >
            {translate<string>('RATE_PER_ACRE')}
          </TableHeader>
          <TableHeader
            style={styles.flex}
            tableHeaderBackground="transparent"
            testID={`${baseTestId}-cost-table-header-rate-uom`}
          >
            {translate<string>('RATE_UOM')}
          </TableHeader>
          <TableHeader
            align="right"
            style={styles.flex}
            tableHeaderBackground="transparent"
            testID={`${baseTestId}-cost-table-header-cost-ac`}
          >
            {translate<string>('COST_AC')}
          </TableHeader>
        </TableRow>
        { componentCosts.map(({
          costPerAcre,
          product,
          rate,
          rateUom,
        }, index) => {
          return (
            <TableRow
              key={product.skuName}
              testID={`${baseTestId}-cost-table-${index}`}
            >
              <TableCell
                style={styles.flex}
                testID={`${baseTestId}-cost-table-${index}-productSKU`}
              >
                {product.isActive
                  ? product.skuName
                  : `${product.skuName} (${translate<string>('INACTIVE')})`}
              </TableCell>
              <TableCell
                style={styles.flex}
                testID={`${baseTestId}-cost-table-${index}-ratePerAcre`}
              >
                { rate }
              </TableCell>
              <TableCell
                style={styles.flex}
                testID={`${baseTestId}-cost-table-${index}-rateUoM`}
              >
                { translate<string>(rateUom) }
              </TableCell>
              <TableCell
                align="right"
                style={styles.flex}
                testID={`${baseTestId}-cost-table-${index}-costPerAcre`}
              >
                <Text>{ StringUtility.formatCurrency(costPerAcre) }</Text>
              </TableCell>
            </TableRow>
          );
        }) }
        <TableRow
          testID={`${baseTestId}-cost-table-total`}
        >
          <TableCell
            category="s2"
            style={{ flex: 3 }}
            testID={`${baseTestId}-cost-table-total-text`}
          >
            {translate<string>('TOTAL')}
          </TableCell>
          <TableCell
            align="right"
            category="s2"
            style={styles.flex}
            testID={`${baseTestId}-cost-table-total-value`}
          >
            <Text>{StringUtility.formatCurrency(totalCostPerAcre)}</Text>
          </TableCell>
        </TableRow>
      </Table>
    </Modal>
  );
};
