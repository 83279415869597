import { Button, Header, HSpacer, Icon, Input, LargeModal, Text, VSpacer } from '@design';
import { ApiGrowerFarm, ApiGrowerField, GrowerFarmEndpoint } from '@shared/interfaces/api';
import { FarmUtility } from '@shared/utils';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { StringUtility } from '../../../../utilities';
import { ButtonBar } from '../../../components/shared/ButtonBar';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { FarmField } from './FarmField';
import { defaultField } from './helpers';
import RemoveFarmButton from './RemoveFarmButton';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: 588,
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  nameInput: {
    flex: 1,
  },
  areaText: {
    width: 204,
  },
  fieldToAdd: {
    alignItems: 'center',
  },
});

export interface FarmModalProps {
  initialFarm: ApiGrowerFarm | GrowerFarmEndpoint.Create.Request,
  editMode?: boolean,
  onUpdateFarm(farm: GrowerFarmEndpoint.Create.Request): void,
  onRemoveFarm(): void,
  onClose: (event?) => void,
}

export default function FarmModal ({
  initialFarm,
  editMode,
  onUpdateFarm,
  onRemoveFarm,
  onClose,
}: FarmModalProps) {
  const [translate] = useTranslation(['growers', 'common']);
  const [cancelEditVisible, setCancelEditVisible] = useState(false);
  const [farm, setFarm] = useState(initialFarm);
  const { acres, hasFields } = useMemo(
    () => {
      const farmArea = FarmUtility.getFarmArea(farm);
      return {
        acres: farmArea,
        hasFields: !!farm.growerFields.length,
      };
    },
    [farm],
  );

  const validateCropZones = (cropZones: GrowerFarmEndpoint.Create.CropZone[]) => {
    return !cropZones.some((cropZone) => (
      !cropZone.zoneName.trim()
      || !cropZone.areaValue
    ));
  };

  const validateFields = (fields: GrowerFarmEndpoint.Create.Field[]) => {
    return !fields.some((field) => {
      const hasCropZones = !!field.cropZones.length;
      const areas = hasCropZones ? validateCropZones(field.cropZones) : field.areaValue;

      return (
        !field.fieldName.trim()
        || !areas
      );
    });
  };

  const formValid = (): boolean => {
    return !(
      !farm.farmName.trim()
      || !hasFields
      || !validateFields(farm.growerFields)
    );
  };

  const fieldToUpdate = (
    index: number,
    field: ApiGrowerField | GrowerFarmEndpoint.Create.Field,
  ) => {
    const newFields = [...farm.growerFields];
    newFields[index] = field;

    setFarm({ ...farm, growerFields: newFields });
  };

  const fieldToRemove = (index: number) => {
    const newFields = farm.growerFields.filter((field, idx) => idx !== index);

    setFarm({ ...farm, growerFields: newFields });
  };

  const fieldToAdd = () => {
    const newFields = [...farm.growerFields];
    newFields.push(defaultField);

    setFarm({ ...farm, growerFields: newFields });
  };

  const [farmNameError, setFarmNameError] = useState(false);

  return (
    <>
      <ConfirmationModal
        cancelText={translate('CONTINUE_EDITING')}
        confirmText={translate('ACTION_CANNOT_BE_UNDONE')}
        messageText={translate(
          editMode ? 'CANCEL_EDIT_DIALOG_DESCRIPTION_EDIT'
            : 'CANCEL_EDIT_DIALOG_DESCRIPTION_NEW',
        )}
        onCancel={() => setCancelEditVisible(false)}
        onConfirm={onClose}
        status="warning"
        title={translate('CANCEL_EDIT_DIALOG_TITLE')}
        visible={cancelEditVisible}
      />
      <LargeModal
        footer={(
          <ButtonBar
            buttonBarType="grouped"
            disableRightAction={_.isEqual(initialFarm, farm) || !formValid()}
            leftAction={_.isEqual(initialFarm, farm) ? onClose : () => setCancelEditVisible(true)}
            leftButtonText={translate('CLOSE')}
            rightAction={() => onUpdateFarm(farm)}
            rightButtonText={translate<string>(editMode ? 'SAVE_CHANGES' : 'ADD_FARM')}
            testID="farm-modal-button"
          />
        )}
        headerButton={<RemoveFarmButton onRemoveFarm={editMode ? onRemoveFarm : undefined} />}
        pages={[
          <KeyboardAwareScrollView contentContainerStyle={styles.container}>
            <View style={styles.row}>
              <Input
                isRequired
                label={translate<string>('FARM_NAME')}
                onBlur={() => setFarmNameError(!farm.farmName.trim())}
                onChangeText={(farmName) => {
                  setFarm({ ...farm, farmName });
                }}
                status={farmNameError ? 'danger' : 'basic'}
                style={styles.nameInput}
                testID="farm-name"
                value={farm.farmName}
              />
              <HSpacer size="7" />
              <View style={[styles.column, styles.areaText]}>
                <VSpacer size="9" />
                <Text status="p1">
                  {translate<string>('ACRES_COUNT', {
                    acres: StringUtility.localizeNumber(acres, 3),
                  })}
                </Text>
              </View>
            </View>
            <VSpacer size="9" />
            {hasFields && (
              <>
                <Header level="4" testID="farm-modal-fields-header" title={translate('FIELDS')} />
                <VSpacer size="4" />
                {farm.growerFields.map((field, index) => (
                  <FarmField
                    field={field}
                    indexField={index}
                    key={('id' in field && field.id) ?? index}
                    onRemoveField={() => fieldToRemove(index)}
                    onUpdateField={(newField) => fieldToUpdate(index, newField)}
                  />
                ))}
                <VSpacer size="8" />
              </>
            )}
            <View style={styles.fieldToAdd}>
              <Button
                accessoryLeft={(props) => <Icon name="Plus" testID="farm-field-add-button-icon" {...props} />}
                appearance="outline"
                disabled={!validateFields(farm.growerFields)}
                onPress={fieldToAdd}
                size="medium"
                testID="farm-field-add-button"
              >
                {translate<string>('ADD_FIELD')}
              </Button>
            </View>
          </KeyboardAwareScrollView>,
        ]}
        testID="farms-modal"
        title={translate<string>(editMode ? 'EDIT_FARM' : 'ADD_FARM')}
        visible
      />
    </>
  );
}
