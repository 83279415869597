import {
  ApiDiscount,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  DiscountEndpoint,
} from '@shared/interfaces/api';
import { CalculatedDiscount, CalculationUtility } from '@shared/utils';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Button, Card, Dialog, Icon, VSpacer, HSpacer, Text } from '@design';
import { testId } from '../../../../../utilities';
import ActionsHeader from '../../../../components/shared/ActionsHeader/ActionsHeader';
import { AddEditOrderDiscountModal } from './AddEditOrderDiscountModal';
import { OrderSummaryItem } from './OrderSummaryItem';
import { useBusinessDiscounts } from '../../../../../hooks/useBusinessDiscounts';

export interface OrderSummaryProps {
  acreage: number,
  components: ApiProductOrderComponent[],
  discounts: ApiDiscount[],
  isDraft: boolean,
  locationId: string,
  onDiscountsChange: (orderDiscounts: DiscountEndpoint.Save.Request[]) => void,
  priceTypeId?: string,
  productMixComponents?: ApiProductOrderProductMixComponent[],
  viewMode?: boolean,
}

export const OrderSummary: FC<OrderSummaryProps> = ({
  acreage,
  components,
  discounts,
  isDraft,
  locationId,
  onDiscountsChange,
  priceTypeId,
  productMixComponents,
  viewMode,
}) => {
  const themedStyles = useStyleSheet({
    centered: {
      alignItems: 'center',
    },
    addDiscountButton: {
      maxWidth: 150,
    },
    line: {
      borderWidth: 1,
      marginHorizontal: -8,
      borderColor: 'color-basic-transparent-100',
    },
    dialogFooter: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });
  const { businessDiscountsList } = useBusinessDiscounts();
  const {
    businessDiscountsListData: currentBusinessDiscounts,
    areBusinessDiscountsFetched,
  } = businessDiscountsList;
  const [translate] = useTranslation(['productOrders', 'common']);

  const [addEditOrderDiscountDialogVisible, setAddEditOrderDiscountDialogVisible] = useState(false);
  const [deleteDiscountConfirmationDialogVisible,
    setDeleteDiscountConfirmationDialogVisible] = useState(false);
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState<number>();

  const profitCalculation = useMemo(() => (
    CalculationUtility.getProductOrderProfitCalculation(
      components,
      discounts,
      priceTypeId,
      locationId,
      acreage,
      productMixComponents,
    )
  ), [acreage, productMixComponents, components, discounts, locationId, priceTypeId]);

  const retailPrice = useMemo(() => profitCalculation.productCosts, [profitCalculation]);

  const retailPricePerAcre = useMemo(() => (
    CalculationUtility.calculatePerAcre(retailPrice, acreage)
  ), [retailPrice, acreage]);

  const orderTotal = useMemo(() => (
    profitCalculation.costAfterDiscounts
  ), [profitCalculation]);

  const orderTotalPerAcre = useMemo(() => (
    CalculationUtility.calculatePerAcre(orderTotal, acreage)
  ), [orderTotal, acreage]);

  const orderSubtotal = useMemo(() => profitCalculation.subtotal, [profitCalculation]);

  const orderSubtotalPerAcre = useMemo(() => (
    CalculationUtility.calculatePerAcre(orderSubtotal, acreage)
  ), [orderSubtotal, acreage]);

  const skuDiscountsCount = [...components, ...(productMixComponents || [])].reduce(
    (
      partialSum,
      component,
    ) => partialSum + component.discounts?.length ?? 0,
    0,
  );

  const skuDiscountsTotal = useMemo(() => (
    profitCalculation.productDiscountTotal
  ), [profitCalculation]);

  const skuDiscountsTotalPerAcre = useMemo(() => (
    CalculationUtility.calculatePerAcre(skuDiscountsTotal, acreage)
  ), [skuDiscountsTotal, acreage]);

  const calculatedDiscounts = useMemo(() => (
    profitCalculation.discounts
  ), [profitCalculation]);

  const updateDiscounts = useCallback(
    (discountUpdates: DiscountEndpoint.Save.Request) => (discounts?.map((d, index) => {
      if (index === selectedDiscountIndex) {
        return { ...discounts[selectedDiscountIndex], ...discountUpdates };
      }
      return d;
    }) ?? []), [discounts, selectedDiscountIndex],
  );

  const handleAddEditDiscount = useCallback((discountUpdates: DiscountEndpoint.Save.Request) => {
    const updatedDiscounts = selectedDiscountIndex === undefined
      ? [...(discounts ?? []), discountUpdates]
      : updateDiscounts(discountUpdates);

    onDiscountsChange(updatedDiscounts as DiscountEndpoint.Save.Request[]);
    setAddEditOrderDiscountDialogVisible(false);
  }, [discounts, onDiscountsChange, selectedDiscountIndex, updateDiscounts]);

  const handleConfirmDeleteDiscount = useCallback(() => {
    const updatedDiscounts = discounts.filter((c, index) => (
      index !== selectedDiscountIndex
    ));
    onDiscountsChange(updatedDiscounts as DiscountEndpoint.Save.Request[]);
    setDeleteDiscountConfirmationDialogVisible(false);
  }, [discounts, onDiscountsChange, selectedDiscountIndex]);

  const isEditable = useCallback((discount: CalculatedDiscount) => {
    if (areBusinessDiscountsFetched) {
      return !currentBusinessDiscounts?.data?.some((currentBusinessDiscount) => {
        return currentBusinessDiscount?.name === discount.name;
      });
    }
    return true;
  }, [areBusinessDiscountsFetched, currentBusinessDiscounts?.data]);

  return (
    <View>
      {addEditOrderDiscountDialogVisible && (
        <AddEditOrderDiscountModal
          businessDiscountsList={businessDiscountsList}
          onApply={handleAddEditDiscount}
          onClose={() => setAddEditOrderDiscountDialogVisible(false)}
          orderDiscount={discounts[selectedDiscountIndex]}
          orderDiscounts={discounts}
        />
      )}
      <Dialog
        footerAccessory={({
          primaryButtonProp,
          secondaryButtonProp,
          spacerProp,
        }) => (
          <>
            <Button
              {...testId('delete-discount-cancel')}
              {...primaryButtonProp}
              appearance="outline"
              status="basic"
            >
              {translate<string>('CANCEL')}
            </Button>
            <HSpacer {...spacerProp} />
            <Button
              {...testId('delete-discount-confirm')}
              {...secondaryButtonProp}
              onPress={handleConfirmDeleteDiscount}
            >
              {translate<string>('CONFIRM_DELETE_DISCOUNT')}
            </Button>
          </>
        )}
        footerStyle={themedStyles.dialogFooter}
        onClose={() => setDeleteDiscountConfirmationDialogVisible(false)}
        testID="delete-discount-dialog"
        title={translate<string>('CONFIRM_DELETE_DISCOUNT_DIALOG_TITLE')}
        visible={deleteDiscountConfirmationDialogVisible}
      />
      <ActionsHeader header={translate<string>('ORDER_SUMMARY')} />
      <VSpacer size="5" />
      <Card testID="order-summary-card">
        <OrderSummaryItem
          perAcre={orderTotalPerAcre}
          testIdPostfix="total-top"
          title={translate<string>('TOTAL')}
          titleCategory="h4"
          titleValue={orderTotal}
          viewMode={viewMode}
        />
        <VSpacer size="7" />
        <View style={themedStyles.centered}>
          <Button
            {...testId('add-order-discount-button')}
            accessoryLeft={(iconProps) => (
              <Icon name="Pricetags" testID="add-order-discount-button-icon" {...iconProps} />
            )}
            appearance="outline"
            disabled={viewMode}
            onPress={() => {
              setSelectedDiscountIndex(undefined);
              setAddEditOrderDiscountDialogVisible(true);
            }}
            size="small"
            status={isDraft ? 'basic' : 'primary'}
            style={themedStyles.addDiscountButton}
          >
            {translate<string>('ADD_DISCOUNT_TO_ORDER')}
          </Button>
        </View>
        <VSpacer size="10" />
        <OrderSummaryItem
          isLineThrough={skuDiscountsCount > 0}
          perAcre={retailPricePerAcre}
          testIdPostfix="retail-price"
          title={translate<string>('RETAIL_PRICE')}
          titleValue={retailPrice}
        />
        {skuDiscountsCount > 0 && (
          <>
            <VSpacer size="8" />
            <OrderSummaryItem
              isDiscount
              perAcre={skuDiscountsTotalPerAcre}
              testIdPostfix="sku-discounts"
              title={translate<string>('SKU_DISCOUNTS_WITH_COUNT', { count: skuDiscountsCount })}
              titleValue={skuDiscountsTotal}
            />
            <VSpacer size="8" />
            <OrderSummaryItem
              isLineThrough={discounts.length > 0}
              perAcre={orderSubtotalPerAcre}
              testIdPostfix="order-subtotal"
              title={translate<string>('ORDER_SUBTOTAL')}
              titleValue={orderSubtotal}
            />
          </>
        )}
        {calculatedDiscounts.map((discount, index) => {
          const isDiscountEditable = isEditable(discount);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <View key={index}>
              <VSpacer size="8" />
              <OrderSummaryItem
                isDiscount
                isDraft={isDraft}
                isEditable={isDiscountEditable}
                onDelete={() => {
                  setDeleteDiscountConfirmationDialogVisible(true);
                  setSelectedDiscountIndex(index);
                }}
                onEdit={() => {
                  setAddEditOrderDiscountDialogVisible(true);
                  setSelectedDiscountIndex(index);
                }}
                perAcre={CalculationUtility.calculatePerAcre(discount.dollarsTotal, acreage)}
                testIdPostfix={`discount-${index}`}
                title={discount.name}
                titleValue={discount.dollarsTotal}
                viewMode={viewMode}
              />
            </View>
          );
        })}
        <VSpacer size="7" />
        <View style={themedStyles.line} />
        <VSpacer size="7" />
        <OrderSummaryItem
          perAcre={orderTotalPerAcre}
          testIdPostfix="total-bottom"
          title={translate<string>('TOTAL')}
          titleCategory="s1"
          titleValue={orderTotal}
        />
        <>
          <VSpacer size="7" />
          <Text appearance="hint" category="p2" testID="include-all-products-disclaimer">
            {translate<string>('PAC_INCLUDE_ALL_PRODUCTS_DISCLAIMER')}
          </Text>
        </>
      </Card>
      <VSpacer size="12" />
    </View>
  );
};
