import { MenuItem, OverflowMenu } from '@design';
import { OrderStatus } from '@shared/interfaces';
import { ApiProductOrderListResponse } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { testId } from '../../../../utilities';

export interface ProductOrderItemMenuProps {
  data: ApiProductOrderListResponse,
  onCancelOrder(productOrder: ApiProductOrderListResponse): void,
  onCreateDeliverable?(productOrderId: string): void,
  onDelete?(productOrder: ApiProductOrderListResponse): void,
  onDuplicate?(productOrderId: string): void,
  onEdit?(productOrderId: string, isDraft: boolean): void,
  onViewDeliverables?(productOrderId: string): void,
  showCancelOrder?: boolean,
  visible?: boolean;
}
export default function ProductOrderItemMenu ({
  data,
  onCancelOrder,
  onCreateDeliverable,
  onDelete,
  onDuplicate,
  onEdit,
  onViewDeliverables,
  showCancelOrder,
}: ProductOrderItemMenuProps) {
  const [translate] = useTranslation(['common', 'prepare', 'productOrders']);
  const showMenu = onEdit
    || onDuplicate
    || onCreateDeliverable
    || onViewDeliverables
    || onDelete;

  return showMenu ? (
    <OverflowMenu
      placement="bottom end"
      {...testId('options')}
    >
      {(!!onEdit && (data.isDraft || data.status !== OrderStatus.ACCEPTED)) && (
        <MenuItem
          {...testId('option-edit')}
          onPress={() => onEdit(data.id, data.isDraft)}
          title={translate<string>('EDIT')}
        />
      )}
      {!!onDuplicate && (
        <MenuItem
          {...testId('option-duplicate')}
          onPress={() => onDuplicate(data.id)}
          title={translate<string>('DUPLICATE')}
        />
      )}
      {!!onCreateDeliverable && (
        <MenuItem
          {...testId('option-create-deliverable')}
          onPress={() => onCreateDeliverable(data.id)}
          title={translate<string>('CREATE_DELIVERABLE')}
        />
      )}
      {!!onViewDeliverables && (
        <MenuItem
          {...testId('option-view-deliverables')}
          onPress={() => onViewDeliverables(data.id)}
          title={translate<string>('VIEW_DELIVERABLES')}
        />
      )}
      {!!showCancelOrder && (
        <MenuItem
          {...testId('option-cancel-order')}
          onPress={() => onCancelOrder(data)}
          title={translate<string>('CANCEL_ORDER')}
        />
      )}
      {!!onDelete && (
        <MenuItem
          {...testId('option-delete')}
          onPress={() => onDelete(data)}
          title={translate<string>('DELETE')}
        />
      )}
    </OverflowMenu>
  ) : null;
}
