import { Select, SelectItem, useToast } from '@design';
import { Country, State } from '@shared/constants';
import { IndexPath } from '@ui-kitten/components';
import { TouchableWebProps } from '@ui-kitten/components/devsupport';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { BusinessLocationApi } from '../../../../utilities/api';

const emptyArray = [];

export interface CountrySelectProps extends TouchableWebProps {
  country?: Country['name'],
  label?: string,
  onUpdateCountry(country: Country['name'], abbreviation: Country['abbreviation'], states: State[]): void,
  testID: string,
}

export const CountrySelect = ({
  country,
  label = '',
  onUpdateCountry,
  ...props
}: CountrySelectProps) => {
  const [translate] = useTranslation(['common']);

  const { createToast } = useToast();

  const { data: countries, isLoading } = useQuery(
    [QueryKeys.COUNTRIES],
    BusinessLocationApi.getCountries,
    {
      placeholderData: emptyArray,
      onSuccess: (data) => {
        const selectedCountry = data.find(({ name }) => name === country) ?? data[0];
        onUpdateCountry(selectedCountry.name, selectedCountry.abbreviation, selectedCountry.states);
      },
      onError: () => {
        createToast({
          children: translate<string>('ERROR_COUNTRIES'),
          status: 'danger',
          testID: 'country-error-toast',
        });
      },
    },
  );

  return (
    <Select
      disabled={isLoading}
      label={label}
      onSelect={(idx: IndexPath | IndexPath[]) => {
        const { abbreviation, name, states } = countries[(idx as IndexPath).row];
        onUpdateCountry(name, abbreviation, states);
      }}
      {...props}
      value={country}
    >
      {countries.map(({ name }, index) => (
        <SelectItem 
          key={name} 
          testID={`country-dropdown-value-${index}`} 
          title={name} 
        />
      ))}
    </Select>
  );
};
