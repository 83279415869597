import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Card, IndexPath } from '@ui-kitten/components';
import {
  Button,
  Input,
  Modal,
  Select,
  SelectItem,
  ToastProps,
  useToast,
} from '@design';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const ToastDemo: FunctionComponent = () => {
  const [text, setText] = useState('This is a toast');
  const [status, setStatus] = useState<Status>('primary');
  const [actionText, setActionText] = useState('');
  const [actionStatus, setActionStatus] = useState<Status>('primary');
  const [duration, setDuration] = useState('');

  const { createToast, destroyToast } = useToast();

  const [showModal, setShowModal] = useState(false);
  const [lastToast, setLastToast] = useState('');

  const toastProps: ToastProps = {
    children: text,
    status,
    accessoryRight: actionText
      ? (props) => <Button {...props} status={actionStatus} testID="test-button">{actionText}</Button>
      : undefined,
    duration: parseInt(duration, 10) || undefined,
    testID: 'toast-content-element',
  };

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Input label="Text" onChangeText={setText} testID="demo-input" value={text} />
          <Select
            label="status"
            onSelect={(ip: IndexPath | IndexPath[]) => setStatus(Statuses[(ip as IndexPath).row])}
            selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
            testID="toast-demo-status-selector"
            value={status}
          >
            {Statuses.map((s, i) => (
              <SelectItem key={s} testID={`toast-status-${i}`} title={s} />
            ))}
          </Select>
          <Input label="Action Text" onChangeText={setActionText} testID="demo-input" value={actionText} />
          <Select
            label="Action Status"
            onSelect={(ip: IndexPath | IndexPath[]) => setActionStatus(
              Statuses[(ip as IndexPath).row],
            )}
            selectedIndex={new IndexPath(Statuses.findIndex((s) => s === actionStatus))}
            testID="toast-demo-action-status-selector"
            value={actionStatus}
          >
            {Statuses.map((s, i) => (
              <SelectItem key={s} testID={`toast-action-status-dropdown-value-${i}`} title={s} />
            ))}
          </Select>

          <Input label="duration (milliseconds)" onChangeText={setDuration} testID="demo-input" value={duration} />
        </View>
        <DemoContainer>
          <DemoBlock>
            <Button
              onPress={() => {
                const lastId = createToast(toastProps);
                setLastToast(lastId);
              }}
              testID="test-button"
            >
              Make Toast
            </Button>
          </DemoBlock>
          <DemoBlock>
            <Button onPress={() => setShowModal(true)} testID="test-button">Modal</Button>
          </DemoBlock>
          <DemoBlock>
            <Button onPress={() => destroyToast(lastToast)} testID="test-button">Destroy last</Button>
          </DemoBlock>
        </DemoContainer>
      </Card>
      {showModal && (
        <Modal
          backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          testID="modal-test"
          visible
        >
          <Card
            footer={(props) => (
              <View {...props}>
                <Button
                  onPress={() => setShowModal(false)}
                  testID="test-button"
                >
                  Close Modal
                </Button>
              </View>
            )}
            testID="test-card"
          >
            <Button onPress={() => createToast(toastProps)} testID="test-button">
              Make Toast
            </Button>
          </Card>
        </Modal>
      )}
    </View>
  );
};
