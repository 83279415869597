import { IntegrationType } from '@shared/enums';
import { ApiProductOrder, ApiProductOrderListResponse } from '@shared/interfaces/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../../hooks/useIntegration';
import {
  ConfirmationModal,
} from '../../../../components/shared/ConfirmationModal/ConfirmationModal';

interface CancelOrderConfirmationDialogProps {
  onCancel(): void,
  onConfirm(): void,
  productOrder: ApiProductOrder | ApiProductOrderListResponse,
  visible?: boolean;
}

export default function CancelOrderConfirmationDialog ({
  onCancel,
  onConfirm,
  productOrder,
  visible,
}: CancelOrderConfirmationDialogProps) {
  const [translate] = useTranslation(['productOrders', 'common']);
  const { currentBusinessId } = useAuthentication();
  const { integration } = useIntegration({ businessId: currentBusinessId });
  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );
  const getMessageText = () => {
    if (hasBusinessCentralIntegration) {
      const isSalesQuote = (
        productOrder?.externalDisplayId && productOrder.externalDisplayId.includes('SQ')
      );
      return translate(
        isSalesQuote
          ? 'CANCEL_QUOTE_CONFIRMATION_MESSAGE_DYNAMICS'
          : 'CANCEL_ORDER_CONFIRMATION_MESSAGE_DYNAMICS',
        { email: integration?.notificationEmail },
      );
    }
    return translate('CANCEL_ORDER_CONFIRMATION_MESSAGE');
  };

  return (
    <ConfirmationModal
      cancelText={translate('BACK')}
      confirmText={translate('CANCEL_ORDER')}
      messageText={getMessageText()}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={translate('CANCEL_ORDER_CONFIRMATION_TITLE')}
      visible={visible}
    />
  );
}
