import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Routes } from '../../constants';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { useHistory, useLocation } from '../../router';
import { QueryUtility } from '../../utilities';
import { UserApi } from '../../utilities/api';

export const VerifyEmail = () => {
  const history = useHistory();
  const { logout } = useAuthentication();

  const { search: searchParams } = useLocation();
  const { token } = QueryUtility.getQueryParams(searchParams);

  useEffect(() => {
    const verifyEmail = async () => {
      if (token) {
        try {
          await UserApi.updateEmail(token);
          history.push(`${Routes.LOGIN}?verifiedEmail=true`);
        } catch (err) {
          history.push(`${Routes.LOGIN}?invalidVerificationToken=true`);
        }
        await logout();
      }
    };
    verifyEmail();
  }, [history, logout, token]);

  return (<View />);
};
