import React, { FC, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HSpacer, Button, useBanner, BannerProps, LargeModal } from '@design';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { BannerStatus } from '@theme/variant-interfaces/Status';
import { useMutation } from 'react-query';
import { PasswordForm } from './PasswordForm';
import { UserApi } from '../../../utilities/api';
import { usePasswordErrorToMessage } from '../../../utilities/Password';
import { useDebounce } from '../../../hooks/useDebounce';
import { QueryKeys } from '../../../constants';

const styles = StyleSheet.create({
  headerText: { fontWeight: '400' },
  footer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

interface ChangeUserPasswordFormProps {
  onClose: () => void,
}

export const ChangeUserPasswordForm: FC<ChangeUserPasswordFormProps> = (
  { onClose }: ChangeUserPasswordFormProps,
) => {
  const [translate] = useTranslation(['users', 'login', 'common']);
  const { debounce } = useDebounce();
  const passwordErrorToMessage = usePasswordErrorToMessage();
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const { createBanner } = useBanner();
  const bannerProps = (bannerText: string, status: BannerStatus): BannerProps => ({
    children: bannerText,
    status,
    testID: '',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="message-dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) });

  const submitForm = useMutation([QueryKeys.USER], (pass: string) => (
    UserApi.changePassword(pass)), {
    onError: (err: Error) => setError(passwordErrorToMessage(err)),
    onSuccess: () => {
      setError(null);
      createBanner(bannerProps(translate<string>('PASSWORD_UPDATED'), 'success'));
      onClose();
    },
  });

  const Footer = () => (
    <View style={styles.footer}>
      <Button
        appearance="outline"
        design="floating"
        onPress={onClose}
        size="large"
        status="basic"
        testID="cancel-button"
      >
        {translate<string>('CANCEL')}
      </Button>
      <HSpacer size="7" />
      <Button
        disabled={!passwordIsValid}
        onPress={() => debounce(() => submitForm.mutate(password))}
        size="large"
        status="primary"
        testID="submit-button"
      >
        {translate<string>('SAVE')}
      </Button>
      <HSpacer size="9" />
    </View>
  );

  return (
    <LargeModal
      footer={Footer}
      pages={[(
        <KeyboardAwareScrollView>
          <PasswordForm
            error={error}
            onChangePassword={(next) => setPassword(next)}
            onFormValidation={setPasswordIsValid}
          />
        </KeyboardAwareScrollView>
      )]}
      testID="change-password-view"
      title={translate<string>('CHANGE_PASSWORD')}
      visible
    />
  );
};
