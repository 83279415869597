import { TouchableWeb } from '@ui-kitten/components/devsupport';
import React, { FC, useEffect, useState } from 'react';
import { ScrollView, useWindowDimensions, View } from 'react-native';
import { ApiBusiness } from '@shared/interfaces/api';
import { EvaProp, styled, StyleType } from '@ui-kitten/components';
import { GlobalBanner, Icon, Input, Modal, Text, useGlobalBanner } from '@design';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { testId } from '../../utilities/testId';
import { useIsSidePanelOpen, SidePanelUtility } from '../content';

const getComponentStyle = (source: StyleType) => {
  const businessLinkStyle = PropsServiceHelper.allWithPrefixMapped(source, 'businessLink');
  const containerStyle = PropsServiceHelper.allWithPrefixMapped(source, 'container');
  const itemsStyle = PropsServiceHelper.allWithPrefixMapped(source, 'items');
  const textStyle = PropsServiceHelper.allWithPrefixMapped(source, 'text');
  const inputStyle = PropsServiceHelper.allWithPrefixMapped(source, 'input');
  const modalStyle = PropsServiceHelper.allWithPrefixMapped(source, 'modal');

  return {
    businessLink: businessLinkStyle,
    container: containerStyle,
    items: itemsStyle,
    text: textStyle,
    input: inputStyle,
    modal: modalStyle,
  };
};
export interface BusinessSelectorProps {
  eva?: EvaProp,
  showModal?: (event?) => void,
}

const BusinessSelectorBannerFC : FC<BusinessSelectorProps> = ({
  eva,
  showModal = () => {},
}) => {
  const { currentBusiness, availableBusinesses } = useAuthentication();
  const businessName = currentBusiness?.businessName;
  const evaStyle = getComponentStyle(eva.style);

  const action = (
    <TouchableWeb
      onPress={showModal}
      testID="business-selector-link-button"
    >
      <Text
        style={evaStyle.businessLink}
        testID="currently-selected-business-value"
      >
        {(businessName != null) ? businessName : 'Select a business'}
      </Text>
    </TouchableWeb>
  );

  return (availableBusinesses.length > 1) && (
    <GlobalBanner
      action={action}
      status="info"
      testID="global-banner-with-business-selector"
      title="Viewing as"
    />
  );
};

export interface BuinessSelectorModalProps {
  eva: EvaProp,
  visible: boolean,
  hide: () => void
}

const BusinessSelectorModalFC : FC<BuinessSelectorModalProps> = ({
  eva,
  visible,
  hide,
}) => {
  const { currentBusiness, availableBusinesses, selectBusiness } = useAuthentication();
  const [businessNameSearch, setBusinessNameSearch] = useState('');
  const { height: windowHeight } = useWindowDimensions();
  const { bannerHeight } = useGlobalBanner();
  const [highlight, setHighlight] = useState(null);
  const { isSidePanelOpen } = useIsSidePanelOpen();
  const filteredBusinesses: ApiBusiness[] = [];
  if (currentBusiness && !businessNameSearch) {
    filteredBusinesses.push({ id: null, businessName: 'Default Internal View' } as ApiBusiness);
  }

  useEffect(() => { setBusinessNameSearch(''); }, [visible]);

  useEffect(() => {
    if (!currentBusiness && isSidePanelOpen) {
      SidePanelUtility.hide();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusiness]);

  filteredBusinesses.push(
    ...availableBusinesses.filter((business) => (
      business.businessName.toLowerCase().includes(businessNameSearch.toLowerCase())
      && business.id !== currentBusiness?.id
    )),
  );

  const MODALHEIGHT = 400;

  const businessSelected = (business: ApiBusiness) => {
    const selectedBusiness = (business.id) ? business : null;
    selectBusiness(selectedBusiness);
    hide();
  };

  const evaStyle = getComponentStyle(eva.style);

  return (
    <Modal
      hideCloseButton
      style={[{
        top: (bannerHeight + 33 - ((windowHeight - MODALHEIGHT) / 2)),
        height: MODALHEIGHT,
        borderRadius: 0,
        padding: 0,
      }, evaStyle.modal]}
      visible={visible}
      width={458}
      // TODO: Why does the background flash black when you click outside the modal?
      onClose={hide}
      {...testId('business-selector-modal')}
    >
      <View style={evaStyle.container}>
        <Input
          accessoryLeft={(props) => <Icon name="Search" testID="business-selector-modal-search-input-icon" {...props} />}
          onChangeText={setBusinessNameSearch}
          style={evaStyle.input}
          value={businessNameSearch}
          {...testId('business-selector-modal-search-input')}
        />
        <ScrollView
          // TODO: Why can this view not fill the available space in its container?
          style={{ height: 320, marginTop: 16 }}
        >
          {filteredBusinesses.map((business, i) => (
            <TouchableWeb
              key={business.id}
              onPress={() => businessSelected(business)}
              style={[evaStyle.items, { backgroundColor: (highlight === i) ? evaStyle.items.highlightColor : 'transparent' }]}
              {...testId(`business-selector-modal-item-${i}`)}
              onMouseEnter={() => setHighlight(i)}
              onMouseLeave={() => setHighlight(null)}
            >
              <Text
                style={evaStyle.text}
                {...testId('business-selector-modal-item-business-name')}
              >
                {business.businessName}
                {(business.isActive === false) && '(Inactive)'}
              </Text>
            </TouchableWeb>
          ))}
        </ScrollView>
      </View>
    </Modal>
  );
};

export const BusinessSelectorModal = styled('BusinessSelectorModal')(BusinessSelectorModalFC);
export const BusinessSelectorBanner = styled('BusinessSelectorBanner')(BusinessSelectorBannerFC);
