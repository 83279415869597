import { Button, HSpacer, Dialog, Text } from '@design';
import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { StringUtility } from '../../../utilities';
import { ProductUom } from '@shared/enums';

export interface LockPriceConfirmationDialogProps {
  initPrice: number,
  onCancel: () => void,
  onConfirm: () => void,
  packagePrice: number,
  unitUoM: ProductUom,
  visible: boolean,
}

export const LockPriceConfirmationDialog: FC<LockPriceConfirmationDialogProps> = ({
  initPrice,
  onCancel,
  onConfirm,
  packagePrice,
  unitUoM,
  visible,
}) => {

  const styles = StyleSheet.create({
    dialogFooter: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  });

  const [translate] = useTranslation(['productOrders', 'products', 'common']);

  return (
    <Dialog
      footerAccessory={({
        primaryButtonProp,
        secondaryButtonProp,
        spacerProp,
      }) => (
        <>
          <Button
            {...primaryButtonProp}
            appearance="outline"
            status="basic"
            testID="lock-price-confirmation-dialog-cancel"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            onPress={onConfirm}
            testID="lock-price-confirmation-dialog-confirm"
          >
            {translate<string>('CONFIRM_UNLOCK_PRICE')}
          </Button>
        </>
      )}
      footerStyle={styles.dialogFooter}
      onClose={onCancel}
      testID="lock-price-confirmation-dialog"
      title={translate('CONFIRM_UNLOCK_PRICE_DIALOG_TITLE')}
      visible={visible}
    >
      <Text
        category="p2"
        testID="lock-price-confirmation-dialog-unlock-price-warning"
      >
        {translate<string>('UNLOCK_PRICE_WARNING', {
          current: StringUtility.formatCurrency(packagePrice),
          default: StringUtility.formatCurrency(initPrice),
          unit: translate<string>(unitUoM),
        })}
      </Text>
    </Dialog>
  );
};
