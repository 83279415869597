import React, { FunctionComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { PublicHeader } from './PublicHeader';
import { Sizing } from '../../constants';

const styles = StyleSheet.create({
  content: {
    marginTop: Sizing.HEADER_HEIGHT,
    padding: Sizing.DOUBLE_SPACING,
    flex: 1,
  },
});

interface WithHeaderProps {
  style?: object;
  children?: React.ReactNode;
}

export const WithHeader:FunctionComponent<WithHeaderProps> = ({ style, children }) => (
  <>
    <PublicHeader />
    <View style={[style, styles.content]}>
      {children}
    </View>
  </>
);
