import { ApiDeliverable } from '@shared/interfaces/api';
import {
  FarmPlanDeliverableV2 as V2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import React from 'react';
import {
  FarmPlanDeliverableDesktopView,
} from '../../../../src/pages/Deliverable/FarmPlanDeliverableDesktopView';
import { DeliverableRenderer } from '../../DeliverableRenderer';

export class FarmPlanDeliverableV2Render implements DeliverableRenderer {
  private deliverable: ApiDeliverable<V2.Snapshot, V2.Selections>;

  constructor (deliverable: ApiDeliverable) {
    this.deliverable = deliverable;
  }

  getPlanName (): string {
    return this.deliverable.deliverableData.header.farmPlanName;
  }

  getGrowerName (): string {
    return this.deliverable.deliverableData.header.growerName;
  }

  getBusinessName (): string {
    return this.deliverable.deliverableData.header.businessName;
  }

  getBusinessLocation (): string {
    return this.deliverable.deliverableData.header.locationName;
  }

  getDesktopView () {
    return (
      <FarmPlanDeliverableDesktopView
        deliverableId={this.deliverable.id}
        name={this.deliverable.name}
        note={this.deliverable.note}
        publicId={this.deliverable.publicId}
        selections={this.deliverable.selections}
        shared={this.deliverable.shared}
        snapshot={this.deliverable.deliverableData}
        user={this.deliverable.userAccount}
      />
    );
  }

  getMobileView () {
    // authorized users currently don't ever see a mobile view
    return null;
  }
}
