import {
  Button,
  Card,
  CenteredSpinner,
  HSpacer,
  Input,
  LargeModal,
  NumericInput,
  Select,
  SelectItem,
  Text,
  useBanner,
  useToast,
  VSpacer,
} from '@design';
import { AgriculturalUnit, MassUnit, ProductCategory, SeedProductUnitOfMeasure } from '@shared/enums';
import {
  ApiCrop,
  ApiPrice,
  ApiProduct,
  ApiProductCategory,
  ApiProductSubCategory,
  ProductEndpoint,
} from '@shared/interfaces/api';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import { Status } from '@theme/variant-interfaces/Status';
import * as _ from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getBusinessDetailListPageTab,
  getBusinessProductListPage,
  QueryKeys,
  Sizing,
} from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../hooks/useBusinessLocationList';
import { usePriceTypeList } from '../../../hooks/usePriceTypeList';
import { useHistory } from '../../../router';
import { IndexPath } from '../../../ui-components';
import { BusinessApi, ProductApi } from '../../../utilities/api';
import { DetailedApiError } from '../../../utilities/api/DetailedApiError';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { MultiplePricingTable } from './MultiplePricingTable';
import { getDefaultPrices } from './utils';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 545,
  },
  loading: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
});

/**
 * Flattens the data structure returned by the server to a simple structure
 * that matches the UI form
 * @param product
 */
const flattenProduct = (
  product: ApiProduct,
): ProductEndpoint.Create.Seed & { id: string, manufacturer: string } => {
  const {
    businessCategoryId,
    externalId,
    id,
    isActive,
    manufacturer,
    name,
    packageName,
    packageUnitQuantity,
    skuName,
    unitUoM,
  } = product;

  const pricesList: ApiPrice[] = product.prices.map((locPrice) => ({
    id: locPrice.id,
    locationId: locPrice.businessLocationId,
    priceTypeId: locPrice.priceTypeId,
    unitPrice: locPrice.price,
  }));

  return {
    businessCategoryId,
    category: ProductCategory.SEED,
    cropSubType: product.seedProduct?.cropSubType,
    cropType: product.seedProduct?.cropType,
    externalId,
    id,
    isActive,
    lbsPerUnit: product.seedProduct?.lbsPerUnit,
    prices: pricesList,
    manufacturer,
    name,
    packageName,
    packageUnitQuantity,
    seedsPerUnit: product.seedProduct?.seedsPerUnit,
    skuName,
    unitUoM: unitUoM as SeedProductUnitOfMeasure,
  };
};

export interface SeedProductModalProps {
  isVisible: boolean;
  id?: string;
  modalToggle?: () => void;
}

export const SeedProductModal: FunctionComponent<SeedProductModalProps> = ({
  isVisible,
  id: productId,
  modalToggle,
}) => {
  const history = useHistory();
  const { currentBusiness, currentBusinessId } = useAuthentication();
  const [translate] = useTranslation(['prepare', 'products', 'common']);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [initError, setInitError] = useState('');
  const queryClient = useQueryClient();
  const { category: categoryPathParam } = useParams<any>();
  const [category, setCategory] = useState(categoryPathParam || ProductCategory.SEED);
  const [categoryUoMs, setCategoryUoMs] = useState([]);
  const [name, setName] = useState('');
  const [skuName, setSkuName] = useState('');
  const [externalProductId, setExternalProductId] = useState('');
  const [cropType, setCropType] = useState(null);
  const [cropSubType, setCropSubType] = useState(null);
  const [businessCategorySelection, setBusinessCategorySelection] = useState(null);
  const [seedsPerUnit, setSeedsPerUnit] = useState<number | null>(null);
  const [lbsPerUnit, setLbsPerUnit] = useState<number | null>(null);
  const [unitUoM, setUnitUoM] = useState<number | null>(null);
  const [packageName, setPackageName] = useState('');
  const [packageUnitQuantity, setPackageUnitQuantity] = useState<number | null>(null);
  const [productManufacturer, setProductManufacturer] = useState('');
  const [prices, setPrices] = useState<ApiPrice[]>([]);
  const { businessLocations } = useBusinessLocationList(currentBusinessId);
  const { priceTypes, invalidatePriceTypeList } = usePriceTypeList();
  const { createToast } = useToast();
  const { createBanner } = useBanner();
  const [status, setStatus] = useState<Status>('basic');

  const [businessCategories, setBusinessCategories] = useState<ApiProductCategory[]>([]);
  const [businessCrops, setBusinessCrops] = useState<ApiCrop[]>(null);
  const [subCategories, setSubCategories] = useState<ApiProductSubCategory[]>([]);
  const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState<boolean>(false);

  const clearForm = useCallback(() => {
    setError('');
    setName('');
    setSkuName('');
    setExternalProductId('');
    setCropType(null);
    setCropSubType(null);
    setBusinessCategorySelection(null);
    setSeedsPerUnit(null);
    setLbsPerUnit(null);
    setPackageName('');
    setPackageUnitQuantity(null);
    setUnitUoM(null);
    setPrices(getDefaultPrices(priceTypes));
    setStatus('basic');
  }, [priceTypes]);

  const subCategoryItems = (businessId?: string) => {
    const items = subCategories.map((subCategory) => ({
      value: subCategory.id,
      label: subCategory.name,
    })).sort();

    if (businessId) {
      let row;
      items.forEach((v, i) => {
        if (v.value === businessId) row = i;
      });
      return row;
    }

    return [{
      value: '',
      label: translate('NONE'),
    }].concat(items);
  };

  useEffect(() => {
    setCropType(null);
    setPrices(getDefaultPrices(priceTypes));
    setBusinessCategorySelection(null);
  }, [currentBusinessId, priceTypes]);

  useEffect(() => {
    if (categoryPathParam) {
      const pathCategory = _.get(ProductCategory,
        _.defaultTo(categoryPathParam, '').toUpperCase(),
        ProductCategory.SEED) as ProductCategory;
      setCategory(pathCategory);
    }
  }, [categoryPathParam]);

  useEffect(() => {
    const categoryEntry = businessCategories.find(
      (businessCategory) => businessCategory.category === category,
    )?.subCategories ?? [];
    setSubCategories(categoryEntry);
  }, [category, businessCategories]);

  // TODO: Remove these compound get locations and categories calls
  const getBusinessCategories = async () => {
    return BusinessApi.getBusinessProductCategories(currentBusinessId);
  };

  const {
    data: businessCategoryData,
    isLoading: isBusinessCategoriesLoading,
  } = useQuery(
    [QueryKeys.BUSINESS_LOCATION, currentBusinessId, productId, isVisible],
    getBusinessCategories, {
      onError: () => {
        setInitError('ERROR_BUSINESS_LOCATIONS');
      },
      enabled: isVisible && !!currentBusinessId,
    },
  );

  useEffect(() => {
    setLoading(isBusinessCategoriesLoading);
    if (businessCategoryData) {
      setBusinessCategories(businessCategoryData);
    } else if (!productId || !isVisible) {
      clearForm();
    }
  }, [
    clearForm,
    businessCategoryData,
    isBusinessCategoriesLoading,
    isVisible,
    productId,
  ]);

  const getProduct = async () => ProductApi.get(currentBusinessId, productId);

  const {
    data: productData,
    isLoading: isProductLoading,
  } = useQuery([QueryKeys.SEED_LIST, currentBusinessId, productId, isVisible],
    getProduct, {
      onError: () => {
        setInitError('ERROR_LOADING_PRODUCT');
      },
      enabled: isVisible && !!productId && !!currentBusinessId,
    });

  const isSavedProductUoMUnit = productData?.unitUoM === AgriculturalUnit.Unit;
  useEffect(() => {
    const rawCategoryUoMs = [...SeedProductUnitOfMeasure].sort();
    if (!productData) {
      setCategoryUoMs(rawCategoryUoMs);
    } else if (!isSavedProductUoMUnit) {
      const convertibleUoMs = rawCategoryUoMs.filter((uom) => uom !== AgriculturalUnit.Unit);
      setCategoryUoMs(convertibleUoMs);
    }
  }, [isSavedProductUoMUnit, productData]);

  useEffect(() => {
    setLoading(isProductLoading);
    if (productData && productId) {
      const flatProduct = flattenProduct(productData);
      setBusinessCategorySelection(
        subCategoryItems().findIndex((
          sub: { value: string; },
        ) => sub.value === flatProduct.businessCategoryId),
      );
      setCategory(flatProduct.category);
      setCropSubType(flatProduct.cropSubType);
      setCropType(flatProduct.cropType);
      setLbsPerUnit(flatProduct.lbsPerUnit);
      setPrices(getDefaultPrices(priceTypes, flatProduct.prices));
      setName(flatProduct.name);
      setPackageName(flatProduct.packageName);
      setPackageUnitQuantity(flatProduct.packageUnitQuantity);
      setProductManufacturer(flatProduct.manufacturer ?? '');
      setSeedsPerUnit(flatProduct.seedsPerUnit);
      setSkuName(flatProduct.skuName);
      setExternalProductId(flatProduct.externalId ?? '');
      setUnitUoM(categoryUoMs.indexOf(flatProduct.unitUoM as SeedProductUnitOfMeasure));
    } else if (!productId) {
      clearForm();
    }
    if (initError) {
      history.replace(getBusinessProductListPage(category));
      modalToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    category,
    categoryUoMs,
    history,
    initError,
    isProductLoading,
    productData,
    translate,
    productId,
  ]);

  useEffect(() => {
    setBusinessCrops(null);
    const getBusinessCrops = async () => {
      try {
        setLoading(true);
        const crops = await BusinessApi.getBusinessCrops(currentBusinessId);
        setBusinessCrops(_.uniqBy(crops, (bc) => bc.cropType && bc.subType).sort());
      } catch (err) {
        setInitError(translate('ERROR_BUSINESS_CROPS'));
        setBusinessCrops(null);
      }
      setLoading(false);
    };
    getBusinessCrops();
  }, [currentBusinessId, translate]);

  useEffect(() => {
    if (error || initError) {
      createBanner({
        status: 'warning',
        children: error
          ? `${translate(error)}`
          : `${translate(initError)}`,
        testID: 'error-banner',
        actionAccessory: ({ dismissProps }) => (
          <View style={styles.banner}>
            <Button
              {...dismissProps}
              appearance="ghost"
              size="small"
              status="basic"
              testID="dismiss-button"
            >
              {`${translate('DISMISS')}`}
            </Button>
          </View>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, initError, translate]);

  /**
   * Tests the validity of the form
   * @returns Whether the form is valid
   */
  const formValid = (): boolean => {
    const requiredFields = [
      name,
      skuName,
      category[unitUoM],
      packageName,
      currentBusinessId,
    ];
    const requiredNumbers = [packageUnitQuantity];
    const countOfPrices = [];
    businessLocations.forEach((location) => {
      countOfPrices.push(
        prices.filter((price) => price.locationId === location?.id).length,
      );
    });

    requiredFields.push(cropType);
    requiredFields.push(cropSubType);
    if (categoryUoMs[unitUoM] !== AgriculturalUnit.Unit) {
      requiredNumbers.push(lbsPerUnit || seedsPerUnit || null);
    }

    const hasRequiredFields = !_.some(requiredFields, (val) => _.isEmpty(val));
    const hasRequiredNumbers = _.every(requiredNumbers, (num) => !_.isNaN(num) && num !== null);
    const validPrices = prices.every(
      (p) => p.priceTypeId && (p.unitPrice !== null && p.unitPrice !== undefined),
    );

    return hasRequiredFields
      && hasRequiredNumbers
      && validPrices;
  };

  const onSubmit = async (toggleModal: () => void) => {
    try {
      setError('');

      const params: ProductEndpoint.Create.Seed = {
        businessCategoryId: subCategoryItems()[businessCategorySelection]?.value || null,
        category,
        cropSubType,
        cropType,
        externalId: externalProductId.trim() || null,
        prices,
        lbsPerUnit: Number(lbsPerUnit),
        name,
        packageName,
        packageUnitQuantity,
        seedsPerUnit,
        skuName,
        unitUoM: categoryUoMs[unitUoM],
      };

      if (_.isEmpty(productId)) {
        await ProductApi.create(currentBusinessId, params);
        queryClient.invalidateQueries(QueryKeys.SEED_LIST);
        createToast({
          status: 'success',
          children: `${translate('PRODUCT_SUCCESS', { name })}`,
          testID: 'toast-content-element',
        });
      } else {
        await ProductApi.update(currentBusinessId, productId, params);
        queryClient.invalidateQueries(QueryKeys.SEED_LIST);
        createToast({
          status: 'success',
          children: `${translate('PRODUCT_EDIT_SUCCESS', { name })}`,
          testID: 'toast-content-element',
        });
      }
      toggleModal();
    } catch (err) {
      if (err instanceof DetailedApiError) {
        if (err.code === 'already-existing-product-sku') {
          setError(`${translate('ERROR_PRODUCT_SKU_ALREADY_USED')}`);
          setStatus('warning');
        } else if (err.message === 'Default price is required for price type'
          || err.message === 'Price type not found') {
          setError('ERROR_PRICE_TYPES_CHANGED');
          invalidatePriceTypeList();
          clearForm();
        }
      } else setError(`${translate('UNEXPECTED_ERROR')}`);
    }
  };

  // TODO: consider move this to a new component.
  const Footer = (
    submitModal: (callBack: () => void) => void,
    toggleModal: () => void,
  ) => (
    <View
      style={{
        position: 'absolute',
        flexDirection: 'row',
        right: 132,
        bottom: 32,
        width: 100,
      }}
    >
      <Button
        appearance="outline"
        design="floating"
        onPress={() => {
          if (!productId) {
            clearForm();
          }
          toggleModal();
        }}
        status="basic"
        testID="seed-product-modal-cancel-button"
      >
        {`${translate('CANCEL')}`}
      </Button>
      <HSpacer size="6" />
      <Button
        design="floating"
        disabled={!formValid()}
        onPress={() => (!productId
          ? submitModal(toggleModal)
          : setShowUpdateConfirmationModal(true))}
        testID="seed-product-modal-submit-button"
      >
        {`${translate('SAVE')}`}
      </Button>
    </View>
  );

  const onSelectCropType = (selection: IndexPath) => {
    const crop = businessCrops[selection.row];
    setCropType(crop.cropType);
    setCropSubType(crop.subType);
  };

  const onSelectSubCategory = (selection: IndexPath) => {
    setBusinessCategorySelection(selection.row);
  };

  const onSelectUnitUoM = (selection: IndexPath) => {
    const uom = categoryUoMs[selection.row];
    setUnitUoM(selection.row);
    if (uom === MassUnit.Pound) {
      setLbsPerUnit(1);
    }
  };

  const getTitle = () => {
    if (!productId) return `${translate('CREATE_SEED_PRODUCT')}`;
    return `${translate('EDIT_SEED_PRODUCT')}`;
  };

  const handleSkuNameChange = useCallback((val: string) => {
    setError('');
    setSkuName(val);
    setStatus('basic');
  }, []);

  const onPricesChanged = useCallback((p: ApiPrice[]) => {
    setPrices(p);
  }, []);

  return (
    <>
      {showUpdateConfirmationModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('YES_UPDATE')}
          onCancel={() => {
            setShowUpdateConfirmationModal(false);
          }}
          onConfirm={() => {
            onSubmit(modalToggle);
            setShowUpdateConfirmationModal(false);
          }}
          status="warning"
          title={translate('UPDATE_PRODUCT')}
          visible={showUpdateConfirmationModal}
        />
      )}
      <LargeModal
        footer={() => Footer(onSubmit, modalToggle)}
        pages={
          [
            <View style={{ alignItems: 'center', width: '100%' }}>
              {(!initError && !loading) && (
                <View style={styles.container}>
                  <KeyboardAwareScrollView>
                    <Card
                      style={{ flex: 1, backgroundColor: 'transparent', shadowColor: 'transparent' }}
                      testID="seed-product-modal-card"
                    >
                      <Text>{`${translate('PRODUCT_DETAILS')}`}</Text>
                      <VSpacer size="9" />
                      <Input
                        isRequired
                        label={`${translate('PRODUCT_NAME')}`}
                        onChangeText={setName}
                        testID="product-name-field"
                        value={name}
                      />
                      <VSpacer size="7" />
                      <Input
                        isRequired
                        label={`${translate('SKU_NAME')}`}
                        onChangeText={handleSkuNameChange}
                        status={status}
                        testID="sku-name-field"
                        value={skuName}
                      />
                      <VSpacer size="7" />
                      <Input
                        label={`${translate('PRODUCT_ID')}`}
                        maxLength={50}
                        onChangeText={setExternalProductId}
                        status={status}
                        testID="external-product-id-field"
                        value={externalProductId}
                      />
                      <VSpacer size="7" />
                      {!_.isEmpty(subCategories) && (
                        <Select
                          label={`${translate('SUB_CATEGORY')}`}
                          onSelect={(i) => onSelectSubCategory(i as IndexPath)}
                          testID="subcategory-selector"
                          value={subCategoryItems()[businessCategorySelection]?.label ?? translate<string>('NONE')}
                        >
                          {subCategoryItems().map((item, index) => (
                            <SelectItem key={item.value} testID={`seed-sub-category-dropdown-value-${index}`} title={item.label} />
                          ))}
                        </Select>
                      )}
                      <VSpacer size="7" />
                      {!!productId && (
                        <>
                          <Input
                            label={`${translate('MANUFACTURER')}`}
                            readonly
                            testID="seed-manufacturer"
                            value={productManufacturer}
                          />
                          <Text
                            appearance="hint"
                            category="c1"
                            style={{ paddingLeft: 10, paddingTop: 8 }}
                          >
                            {translate<string>('MANUFACTURER_MESSAGE')}
                          </Text>
                          <VSpacer size="8" />
                        </>
                      )}
                      {businessCrops && (
                        businessCrops.length ? (
                          <Select
                            isRequired
                            label={`${translate('CROP')}`}
                            onSelect={(i) => onSelectCropType(i as IndexPath)}
                            testID="crop-type-testId-selector"
                            value={(!cropType) ? '' : `${translate(cropType)} | ${translate(cropSubType)}`}
                          >
                            {businessCrops.sort().map((crop) => (
                              <SelectItem
                                key={`${crop.cropType}${crop.subType}`}
                                testID={`crop-type-dropdown-value-${translate(crop.cropType)} | ${translate(crop.subType)}`}
                                title={`${translate(crop.cropType)} | ${translate(crop.subType)}`}
                              />
                            ))}
                          </Select>
                        ) : (
                          <View style={{ marginBottom: Sizing.BASE_SPACING }}>
                            <Text style={{ color: GrowersDarkTheme['color-warning-500'] }}>{`${translate('NO_CROPS_WARNING')}`}</Text>
                            <View
                              style={[{
                                flexDirection: 'row',
                                marginTop: Sizing.HALF_SPACING,
                              }]}
                            >
                              <Button
                                onPress={() => history.push(getBusinessDetailListPageTab(currentBusinessId, 'crop-list'))}
                                status="secondary"
                                testID="add-crops-button"
                              >
                                {translate('ADD_CROPS').toString()}
                              </Button>
                            </View>
                          </View>
                        )
                      )}
                      <VSpacer size="7" />
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{
                          flex: 1, marginRight: Sizing.QUARTER_SPACING,
                        }}
                        >
                          <NumericInput
                            isRequired={categoryUoMs[unitUoM] !== AgriculturalUnit.Unit && !lbsPerUnit}
                            isValidated={false}
                            keyboardType="numeric"
                            label={`${translate('SEEDS_PER_UNIT')}`}
                            minValue={0}
                            onChangeValue={setSeedsPerUnit}
                            precision={0}
                            testID="seeds-per-unit-field"
                            value={seedsPerUnit}
                          />
                        </View>
                        <HSpacer size="6" />
                        <View style={{
                          flex: 1, marginRight: Sizing.QUARTER_SPACING,
                        }}
                        >
                          <NumericInput
                            disabled={categoryUoMs[unitUoM] === MassUnit.Pound}
                            isRequired={categoryUoMs[unitUoM] !== AgriculturalUnit.Unit && !seedsPerUnit}
                            isValidated={false}
                            keyboardType="numeric"
                            label={`${translate('LBS_PER_UNIT')}`}
                            minValue={0}
                            onChangeValue={setLbsPerUnit}
                            precision={0}
                            testID="lbs-per-unit-field"
                            value={lbsPerUnit}
                          />
                        </View>
                      </View>
                      <VSpacer size="12" />
                      <Text>{`${translate('PACKAGE')}`}</Text>
                      <VSpacer size="9" />
                      <Input
                        isRequired
                        label={`${translate('PACKAGE_NAME')}`}
                        onChangeText={setPackageName}
                        testID="package-name-field"
                        value={packageName}
                      />
                      <VSpacer size="8" />
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{
                          flex: 1, marginRight: Sizing.QUARTER_SPACING,
                        }}
                        >
                          <NumericInput
                            isRequired
                            label={`${translate('UNITS_PER_SKU')}`}
                            minValue={0}
                            onChangeValue={setPackageUnitQuantity}
                            precision={3}
                            testID="package-unit-quantity-field"
                            value={packageUnitQuantity}
                          />
                        </View>
                        <HSpacer size="6" />
                        <View style={{ flex: 1, alignSelf: 'flex-end' }}>
                          <Select
                            disabled={isSavedProductUoMUnit}
                            isRequired
                            label={`${translate('UOM')}`}
                            onSelect={(i) => onSelectUnitUoM(i as IndexPath)}
                            testID="uom-field-testId"
                            value={`${translate(categoryUoMs[unitUoM])}`}
                          >
                            {categoryUoMs.map((uom, index) => (
                              <SelectItem
                                key={uom}
                                testID={`seed-category-uom-dropdown-value-${index}`}
                                title={`${translate(uom)}`}
                              />
                            )).sort()}
                          </Select>
                        </View>
                      </View>
                      <VSpacer size="12" />
                      <MultiplePricingTable
                        businessLocations={businessLocations}
                        onPricesChanged={onPricesChanged}
                        priceTypes={priceTypes}
                        prices={prices}
                      />
                      {loading && <CenteredSpinner />}
                    </Card>
                  </KeyboardAwareScrollView>
                </View>
              )}
            </View>,
          ]
        }
        subTitle={currentBusiness?.businessName}
        testID="seed-large-modal"
        title={getTitle()}
        visible={isVisible}
      />
    </>
  );
};
