import {
  Button,
  DataPoint,
  FilterCategory,
  FilterTable,
  HSpacer,
  Icon,
  MenuItem,
  StickyHeaderPage,
  OverflowMenu,
  PageHeader,
  SortDirection,
  Text,
  TextLink,
  useToast,
  ViewRow,
  VSpacer,
} from '@design';
import {
  ApiUserAccount,
  ApiUserAccountQuerySort,
  PaginatedData,
  UserAccountEndpoint,
} from '@shared/interfaces/api';
import { Permissions, RoleUtility } from '@shared/utils';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { Selections } from 'src/components/DesignSystem/Filter/Filter';
import { QueryKeys } from '../../../constants';
import { useAppContext } from '../../../contexts/AppContext';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { UnexpectedError } from '../../../ui-components/utils/UnexpectedError';
import { StringUtility } from '../../../utilities';
import { UserApi } from '../../../utilities/api';
import { ExportToCSV } from '../../../utilities/helpers/exportCSV';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { UploadModal } from '../../components/shared/UploadModal/UploadModal';
import { IColumn, RowMeta } from '../../components/SortableTable';

const defaultFiltersSuper = ['isActive'];
const defaultFiltersBusiness = ['userRole', 'isActive'];

const filterOptions: FilterCategory[] = [
  {
    columnLabel: 'User role',
    columnKey: 'userRole',
    columns: [
      { label: 'Business Standard', id: 'BUSINESS_STANDARD' },
      { label: 'Business Admin', id: 'BUSINESS_ADMIN' }],
  },
  {
    columnLabel: 'Status',
    columnKey: 'isActive',
    columns: [{ label: 'Active', id: 'true' }, { label: 'Inactive', id: 'false' }],
  },
];

const defaultFilters: Map<string, Selections> = new Map([
  ['isActive', { true: true }],
]);

const styles = StyleSheet.create({
  flexStart: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  dataPoint: {
    width: '33%',
  },
  card: {
    flex: 1,
    marginHorizontal: 36,
    marginBottom: 19,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  pageHeaderContainer: {
    marginHorizontal: 20,
  },
});

const sortColDictionary = {
  'email': ApiUserAccountQuerySort.email,
  'name': ApiUserAccountQuerySort.firstName,
  'user-role': ApiUserAccountQuerySort.userRole,
  'user-type': ApiUserAccountQuerySort.userType,
};

export const UserList: FC = () => {
  const [translate] = useTranslation(['businesses', 'users', 'common']);
  const { user: authUser, currentBusinessId } = useAuthentication();
  const [scrollRef, setScrollRef] = useState<React.MutableRefObject<ScrollView>>();
  const queryClient = useQueryClient();
  const { setModalProps } = useAppContext();

  const canEditUser = RoleUtility.roleHasPermission(
    authUser?.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const canImportUsers = RoleUtility.roleHasPermission(
    authUser?.userRole,
    Permissions.ACCESS_ALL_BUSINESSES,
  );

  const [users, setUsers] = useState<(ApiUserAccount & RowMeta)[]>([]);
  const [changeStatusUser, setChangeStatusUser] = useState<ApiUserAccount | null>();
  const [deleteUser, setDeleteUser] = useState<ApiUserAccount | null>();
  const [resultCount, setResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [defaultColumnFilters, setDefaultColumnFilters] = useState([]);
  const [columnCategories] = useState(filterOptions);
  const { createToast } = useToast();
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState(false);
  const [disableConfirmModalVisible, setDisableConfirmModalVisible] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [pageOptions, setPageOptions] = useState<UserAccountEndpoint.List.Request>({
    isActive: ['true'],
    page: 0,
    sort: ApiUserAccountQuerySort.updatedAt,
    sortDesc: true,
  });

  const exportUserData = async () => {
    const appliedFilters: UserAccountEndpoint.List.Request = { ...pageOptions };

    if (!appliedFilters.isActive) {
      appliedFilters.isActive = ['true', 'false'];
    }

    const data = await UserApi.exportUsers(appliedFilters, currentBusinessId);
    ExportToCSV(data.csv, translate('USERS'));
  };

  const getImportTemplate = async () => {
    const data = await UserApi.getCsvTemplate();
    ExportToCSV(data.csv, translate('USERS'));
  };

  useEffect(() => {
    setDefaultColumnFilters(
      (!!currentBusinessId
        || !RoleUtility.roleHasPermission(authUser.userRole, Permissions.ACCESS_ALL_BUSINESSES))
        ? defaultFiltersBusiness : defaultFiltersSuper,
    );
  }, [currentBusinessId, authUser]);

  useEffect(() => {
    if (users === null) { return; }
    columnCategories.forEach((category) => {
      const key = category.columnKey;
      const uniqueKeys = {};

      if (category.columns.length === 0) {
        category.columns = users.filter(
          (result) => {
            const isUnique = !uniqueKeys[result[key]];
            uniqueKeys[result[key]] = true;
            return isUnique;
          },
        ).map((result) => ({
          label: translate(result[key]),
          id: result[key],
        }));
      }
    });
  }, [users, translate, columnCategories]);

  const columns: IColumn<(ApiUserAccount & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (data) => data.fullName,
      flex: 3,
    },
    {
      columnId: 'phone',
      header: {
        render: translate('PHONE'),
      },
      render: (data) => data.telephone,
      flex: 2,
    },
    {
      columnId: 'email',
      header: {
        render: translate('EMAIL'),
        sortable: true,
      },
      render: (data) => data.email,
      flex: 3,
    },
    {
      columnId: 'user-type',
      header: {
        render: translate('USER_TYPE'),
        sortable: true,
      },
      render: (data) => StringUtility.toProperCase(data.userType),
      flex: 2,
    },
    {
      columnId: 'user-role',
      header: {
        render: translate('USER_ROLE'),
        sortable: true,
      },
      render: (data) => StringUtility.toProperCase(data.userRole),
      flex: 2,
    },
    {
      columnId: 'menu',
      header: {
        render: null,
      },
      render: (data) => (
        canEditUser && (
          <OverflowMenu
            placement="bottom end"
            testID="options"
          >
            {(data.isActive && canEditUser) && (
              <MenuItem
                onPress={() => setModalProps({
                  type: 'userFormModal',
                  userId: data.id,
                  actionType: 'EDIT_USER',
                })}
                testID="option-edit"
                title={translate('EDIT') as string}
              />
            )}
            {(canEditUser && authUser.id !== data.id) && (
              <MenuItem
                onPress={() => {
                  setChangeStatusUser(data);
                  setDisableConfirmModalVisible(true);
                }}
                testID={data.isActive ? 'option-deactivate' : 'option-activate'}
                title={(data.isActive ? translate('DEACTIVATE') : translate('ACTIVATE')) as string}
              />
            )}
            {(
              RoleUtility.roleHasPermission(
                authUser.userRole,
                Permissions.DELETE_USERS,
              ) && authUser.id !== data.id
            ) && (
              <MenuItem
                onPress={() => {
                  setDeleteUser(data);
                  setDeleteConfirmModalVisible(true);
                }}
                testID="option-delete"
                title={translate('DELETE') as string}
              />
            )}
          </OverflowMenu>
        )
      ),
      width: 72, // 40 for icon, 32 for padding of the cell
    },
  ];

  const updateUser = (updatedUser) => {
    setChangeStatusUser(null);
    setUsers((prevUsers) => prevUsers.map((prevUser) => {
      if (prevUser.id !== updatedUser.id) {
        return prevUser;
      }
      return {
        ...prevUser,
        ...updatedUser,
      };
    }));
  };

  const onDisableUser = () => {
    const { id, isActive } = changeStatusUser;
    UserApi.updateUser(id, { isActive: !isActive }).then((updatedUser) => {
      updateUser(updatedUser);
      createToast({
        status: 'success',
        children: translate('USER_UPDATED') as string,
        testID: 'toast-content-element',
      });
    }).catch((err) => {
      UnexpectedError(err, 'Error Updating User', {
        id,
        isActive,
      });
    });
    setDisableConfirmModalVisible(false);
  };

  const getUsers = async () => {
    const appliedFilters: UserAccountEndpoint.List.Request = { ...pageOptions };

    if (!appliedFilters.isActive) {
      appliedFilters.isActive = ['true', 'false'];
    }

    return UserApi.getUsers({
      ...pageOptions,
      businessId: currentBusinessId,
      ...appliedFilters,
    });
  };

  const onDeleteUser = async () => {
    const { id } = deleteUser;
    await UserApi.deleteUser(id).then(() => {
      setDeleteUser(null);
      getUsers();
      createToast({
        status: 'success',
        children: translate('USER_UPDATED') as string,
        testID: 'toast-content-element',
      });
    }).catch((err) => {
      UnexpectedError(err, 'Error Deleting User', {
        id,
      });
    });
    setDeleteConfirmModalVisible(false);
    await queryClient.invalidateQueries(QueryKeys.USER);
  };

  const handleSort = (column: string, direction: SortDirection) => {
    const name = sortColDictionary[column];
    setPageOptions({ ...pageOptions, sort: name, sortDesc: direction === 'DESC' });
  };

  const { isFetching } = useQuery<PaginatedData<ApiUserAccount>, Error>(
    [QueryKeys.USER, currentBusinessId, pageOptions, defaultColumnFilters], getUsers, {
      enabled: currentBusinessId ? !!currentBusinessId
        : !!authUser,
      onError: (err) => {
        UnexpectedError(err, 'Unexpected error loading user list.');
      },
      onSuccess: (result) => {
        setPageCount(result.lastPage + 1);
        setResultCount(result.total);
        setUsers(result.data.map((user, index) => ({
          ...user,
          hasDetails: true,
          // row index here just to match ids on old page.
          rowId: `row:${index}|${user.id}|`,
          // row detail id here is to match old page
          rowDetailId: `row-drawer:${index}|${user.id}|`,
        })));
      },
    },
  );

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo({});
    }
  }, [scrollRef]);

  const disableUserProps = ({
    visible: disableConfirmModalVisible,
    onConfirm: onDisableUser,
    onCancel: () => {
      setChangeStatusUser(null);
      setDisableConfirmModalVisible(false);
    },
    messageText: translate(!changeStatusUser?.isActive
      ? 'USER_ACTIVATE_MODAL_WARNING' : 'USER_DEACTIVATE_MODAL_WARNING') as string,
    confirmText: translate(!changeStatusUser?.isActive ? 'ACTIVATE' : 'DEACTIVATE') as string,
    cancelText: translate('CANCEL') as string,
  });

  const deleteUserProps = ({
    visible: deleteConfirmModalVisible,
    onConfirm: onDeleteUser,
    onCancel: () => {
      setDeleteUser(null);
      setDeleteConfirmModalVisible(false);
    },
    status: 'warning',
    messageText: translate('USER_DELETE_MODAL_WARNING') as string,
    confirmText: translate('DELETE') as string,
    cancelText: translate('CANCEL') as string,
  });

  const confirmationModalProps = () => {
    if (deleteConfirmModalVisible) {
      return deleteUserProps;
    }
    return disableUserProps;
  };

  const buttonRow = canEditUser && (
    <View style={{ flexDirection: 'row' }}>
      {!currentBusinessId && (
        <Button
          accessoryLeft={<Icon name="Plus" testID="create-business-button-icon" />}
          appearance="ghost"
          onPress={() => setModalProps({ type: 'businessModal' })}
          size="large"
          status="basic"
          testID="create-business-button"
        >
          {translate('CREATE_BUSINESS') as string}
        </Button>
      )}
      <Button
        accessoryLeft={(iconProps) => (
          <Icon name="Plus" testID="create-user-button-icon" {...iconProps} />
        )}
        onPress={() => setModalProps({
          type: 'userFormModal',
          actionType: 'CREATE_USER',
        })}
        size="large"
        testID="create-user-button"
      >
        {translate(currentBusinessId ? 'CREATE_USER' : 'CREATE_INTERNAL_USER') as string}
      </Button>
    </View>
  );

  return (
    <StickyHeaderPage
      headerContent={(
        <>
          <PageHeader
            accessoryRight={buttonRow}
            accessorySpacer={<VSpacer size="1" />}
            testID="user-list"
          >
            {translate('USERS')}
          </PageHeader>
        </>
      )}
      scrollViewProps={{ stickyHeaderIndices: [1] }}
      setScrollRef={setScrollRef}
    >
      <FilterTable
        accessoryRight={() => (
          <View style={{ flexDirection: 'row' }}>
            <Button
              accessoryLeft={(iconProps) => (
                <Icon name="FileText" testID="export-users-icon" {...iconProps} />
              )}
              appearance="ghost"
              onPress={exportUserData}
              size="large"
              status="ghost"
              testID="export-users"
            >
              {translate('EXPORT') as string}
            </Button>
            <HSpacer size="5" />
            {canImportUsers && (
              <Button
                accessoryLeft={(iconProps) => (
                  <Icon name="Upload" testID="import-users-icon" {...iconProps} />
                )}
                appearance="ghost"
                onPress={() => setShowUploadModal(true)}
                size="large"
                status="ghost"
                testID="import-users"
              >
                {translate('IMPORT') as string}
              </Button>
            )}
          </View>
        )}
        columns={columns}
        currentPage={pageOptions.page + 1}
        data={users}
        defaultColumnFilters={defaultColumnFilters}
        defaultFilters={defaultFilters}
        filterOptions={columnCategories}
        isLoading={isFetching}
        noFilters={false}
        onPageChange={(page: number) => {
          setPageOptions({ ...pageOptions, page: page - 1 });
        }}
        onSort={handleSort}
        onUpdateFilter={(filter) => {
          setPageOptions({ page: 0, ...filter });
        }}
        rowDetail={(rowData) => (
          <>
            <ViewRow style={{ alignSelf: 'stretch' }}>
              <DataPoint
                label={translate('LOCATION_ASSIGNMENT') as string}
                style={styles.dataPoint}
                testID="location-assignment"
              >
                {(textProps) => {
                  if (!rowData.businessLocations?.length) {
                    return <Text {...textProps}>{translate('N/A') as string}</Text>;
                  }

                  return (
                    <>
                      {rowData.businessLocations?.map((location) => (
                        <Text key={location.id} {...textProps}>{location.locationName}</Text>
                      ))}
                    </>
                  );
                }}
              </DataPoint>
              <DataPoint
                label={translate('BUSINESS_ASSIGNMENT') as string}
                style={styles.dataPoint}
                testID="business-assignment"
              >
                {rowData.business?.businessName}
              </DataPoint>
              <DataPoint
                label={translate('EMAIL') as string}
                style={styles.dataPoint}
                testID="email"
              >
                {rowData.email}
              </DataPoint>
            </ViewRow>
            <VSpacer size="8" />
            <ViewRow style={{ alignSelf: 'stretch' }}>
              <DataPoint
                label={translate('PHONE') as string}
                style={styles.dataPoint}
                testID="phone"
              >
                {rowData.telephone || translate('N/A') as string}
              </DataPoint>
              <DataPoint
                label={translate('USER_CREATED') as string}
                style={styles.dataPoint}
                testID="user-created"
              >
                {new Date(rowData.createdAt).toLocaleString()}
              </DataPoint>
              <DataPoint
                label={translate('STATUS') as string}
                style={styles.dataPoint}
                testID="status"
              >
                {(rowData.isActive ? translate('ACTIVE') : translate('INACTIVE')) as string}
              </DataPoint>
            </ViewRow>
          </>
        )}
        tableId="userListView"
        testID="userListTable"
        totalPages={pageCount}
        totalResults={resultCount}
      />
      <ConfirmationModal
        {...confirmationModalProps()}
      />
      {showUploadModal && (
        <UploadModal
          onCancel={() => {
            setShowUploadModal(false);
          }}
          onDone={async () => {
            setShowUploadModal(false);
            await queryClient.invalidateQueries(QueryKeys.USER);
          }}
          onFileUpload={async (fileInfo) => {
            return UserApi.importUsers(fileInfo);
          }}
        >
          <View style={styles.flexStart}>
            <Text>{`${translate('UPLOAD_USERS_CSV')}`}</Text>
            <TextLink
              appearance="secondary"
              onPress={getImportTemplate}
            >
              {`${translate('THIS_TEMPLATE')}`}
            </TextLink>
          </View>
        </UploadModal>
      )}
      <VSpacer size="7" />
    </StickyHeaderPage>
  );
};
