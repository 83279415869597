import React from 'react';
import { useStyleSheet } from '@ui-kitten/components';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

interface ColorCircleIndicatorProps {
  style?: StyleProp<ViewStyle>
  testID: string
}

export const ColorCircleIndicator = ({ style, testID }: ColorCircleIndicatorProps) => {
  const styles = useStyleSheet({
    indicator: {
      backgroundColor: 'color-info-400',
      width: 6,
      height: 6,
      borderRadius: 50,
    },
  });
  return (
    <View style={StyleSheet.flatten([styles.indicator, style])} testID={testID} />
  );
};
