import { validateEmail, validatePhoneNumber } from '@shared/utils/validators';
import React, { FunctionComponent, useCallback } from 'react';
import { StyleProp, StyleSheet, TextStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Divider, Input, Text } from '../../ui-components';
import { Sizing, ThemeColors } from '../../constants';

interface BusinessContactFormProps {
  inputStyles: StyleProp<TextStyle>;
  onDataChanged: (field: string, value: string) => void;
  data: ContactFormData;
  nativeID?: string;
}

interface ContactFormData {
  contactName: string;
  contactEmail: string;
  contactTelephone: string;
}

const areFieldsTouched = (data: ContactFormData) => (
  !_.isEmpty(data.contactTelephone)
  || !_.isEmpty(data.contactEmail)
  || !_.isEmpty(data.contactName)
);

const styles = StyleSheet.create({ headerText: {
  flex: 1,
  fontSize: Sizing.EM,
  fontWeight: 'bold',
} });

const BusinessContactForm: FunctionComponent<BusinessContactFormProps> = (props) => {
  const { inputStyles, onDataChanged, data } = props;
  const { contactName, contactEmail, contactTelephone } = data;
  const [translate] = useTranslation(['businesses', 'common']);

  const onInputChanged = useCallback((field: string, value: string) => {
    onDataChanged(field, value);
  }, [onDataChanged]);

  const areFieldsRequired = areFieldsTouched(data);

  return (
    <>
      <Text style={styles.headerText}>
        {translate('CONTACT')}
      </Text>
      <Divider style={{
        marginTop: Sizing.HALF_SPACING,
        marginBottom: Sizing.BASE_SPACING,
        backgroundColor: ThemeColors.LIGHT_GRAY,
      }}
      />
      <Input
        caption={areFieldsRequired && translate('REQUIRED') as string}
        label={translate('NAME') as string}
        nativeID={props.nativeID && `${props.nativeID}-name`}
        onChangeText={(nextValue) => onInputChanged('contactName', nextValue)}
        style={inputStyles}
        testID="contact-name-input"
        value={contactName}
      />
      <Input
        caption={(contactTelephone && !validatePhoneNumber(contactTelephone) && translate('VALID_PHONE_REQUIRED') as string) || (areFieldsRequired && translate('REQUIRED') as string)}
        label={translate('CONTACT_PHONE_NUMBER') as string}
        nativeID={props.nativeID && `${props.nativeID}-phone`}
        onChangeText={(nextValue) => onInputChanged('contactTelephone', nextValue)}
        status={(contactTelephone && !validatePhoneNumber(contactTelephone) && 'danger') || undefined}
        style={inputStyles}
        testID="contact-phone-number-input"
        value={contactTelephone}
      />
      <Input
        caption={(contactEmail && !validateEmail(contactEmail) && translate('VALID_EMAIL_REQUIRED') as string) || (areFieldsRequired && translate('REQUIRED') as string)}
        label={translate('CONTACT_EMAIL') as string}
        nativeID={props.nativeID && `${props.nativeID}-email`}
        onChangeText={(nextValue) => onInputChanged('contactEmail', nextValue)}
        status={(contactEmail && !validateEmail(contactEmail) && 'danger') || undefined}
        style={inputStyles}
        testID="contact-email-input"
        value={contactEmail}
      />
    </>
  );
};

export default BusinessContactForm;
