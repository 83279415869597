import { isFarmPlanDeliverable, isTankMixDeliverable } from '@shared/interfaces';
import { ApiDeliverable } from '@shared/interfaces/api';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys, Routes } from '../../constants';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { useHistory, useParams } from '../../router';
import { Alert, CenteredSpinner } from '../../ui-components';
import { UnexpectedError } from '../../ui-components/utils/UnexpectedError';
import { GrowerDeliverableApi } from '../../utilities/api';
import { FarmPlanDeliverableAuthPage } from './FarmPlanDeliverable/FarmPlanDeliverableAuthPage';
import { TankMixDeliverableAuthPage } from './TankMixDeliverable/TankMixDeliverableAuthPage';

export const DeliverableAuthPage: FunctionComponent = () => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);
  const [initError, setInitError] = useState('');
  const [deliverable, setDeliverable] = useState<ApiDeliverable<unknown, unknown>>(null);
  const { id, grower: growerId } = useParams<{ id: string, grower: string }>();
  const { currentBusinessId } = useAuthentication();
  const [businessId] = useState(currentBusinessId);
  const history = useHistory();

  const getDeliverable = async () => GrowerDeliverableApi.getGrowerDeliverable(id);
  useQuery<ApiDeliverable>(
    [QueryKeys.DELIVERABLE, id, growerId, currentBusinessId], getDeliverable, {
      onError: (err) => {
        UnexpectedError(err as Error, 'Get Deliverable');
        setInitError(translate('ERROR_DELIVERABLE_LOADING'));
      },
      onSuccess: (deliverableData) => setDeliverable(deliverableData),
    },
  );

  useEffect(() => {
    if (businessId !== currentBusinessId) {
      history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'deliverables'));
    }
  }, [businessId, currentBusinessId, history]);

  if (initError) {
    return <Alert level="danger" text={initError} />;
  }

  if (deliverable) {
    if (isFarmPlanDeliverable(deliverable)) {
      return <FarmPlanDeliverableAuthPage deliverable={deliverable} />;
    }
    if (isTankMixDeliverable(deliverable)) {
      return <TankMixDeliverableAuthPage deliverable={deliverable} />;
    }
  }

  return <CenteredSpinner />;
};
