/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import { TouchableWeb, TouchableWebProps, Overwrite } from '@ui-kitten/components/devsupport';
import { Interaction, styled, StyledComponentProps, StyleType } from '@ui-kitten/components/theme';
import { NativeSyntheticEvent, TargetedEvent, GestureResponderEvent, View, StyleSheet } from 'react-native';
import { CheckMark } from '@ui-kitten/components/ui/shared/checkmark.component';
import { SelectItemDescriptor } from '@ui-kitten/components/ui/select/select.service';
import { Ionicons } from '@expo/vector-icons';
import { CropColor } from '@theme/variant-interfaces/Colors';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';

const styles = StyleSheet.create({
  container: {
    marginLeft: 8,
  },
  wrapper: {
    width: 10,
    height: 10,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
});

type ColorCircleStyledProps = Overwrite<StyledComponentProps, {}>;

type TouchableColorSelectProps = Overwrite<TouchableWebProps, {
  onPress?: (descriptor: SelectItemDescriptor, event?: GestureResponderEvent) => void;
  testID: string;
}>;

export interface ColorCircleProps extends
  ColorCircleStyledProps, TouchableColorSelectProps {
  color?: CropColor;
  selected?: boolean;
  descriptor?: SelectItemDescriptor;
  nativeID?: string;
}

export type ColorCircleElement = React.ReactElement<ColorCircleProps>;

@styled('ColorCircle')
export class ColorCircle extends React.Component<ColorCircleProps> {
  protected onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.HOVER]);
    this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  protected onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  protected onFocus = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.FOCUSED]);
    this.props.onFocus && this.props.onFocus(event);
  };

  protected onBlur = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    this.props.onBlur && this.props.onBlur(event);
  };

  private onPressIn = (event: GestureResponderEvent): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.props.onPressIn && this.props.onPressIn(event);
  };

  protected onPressOut = (event: GestureResponderEvent): void => {
    this.props.eva.dispatch([]);
    return this.props.onPressOut && this.props.onPressOut(event);
  };

  protected onPress = (event: GestureResponderEvent): void => {
    this.props.onPress && this.props.onPress(this.props.descriptor, event);
  };

  private getComponentStyle (style: StyleType) {
    const { height, width, borderRadius, justifyContent, alignItems, marginTop } = style;
    const outerCircle = PropsServiceHelper.allWithPrefixMapped(style, 'outer');
    const innerCircle = PropsServiceHelper.allWithPrefixMapped(style, 'inner');
    const icon = PropsServiceHelper.allWithPrefixMapped(style, 'icon');

    return {
      container: {
        width,
        height,
        borderRadius,
        justifyContent,
        alignItems,
        marginTop,
      },
      outer: outerCircle,
      inner: innerCircle,
      icon,
    };
  }

  render (): ColorCircleElement {
    const { testID, eva, color, style, selected, ...touchableProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View style={[evaStyle.container, styles.container, style]}>
        <TouchableWeb
          {...touchableProps}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onPress={this.onPress}
          onPressIn={this.onPressIn}
          onPressOut={this.onPressOut}
          style={{ justifyContent: 'center', alignItems: 'center' }}
          testID={testID}
        >
          <Ionicons
            name="ellipse"
            size={evaStyle.inner.radius}
            style={{
              color: evaStyle.inner.backgroundColor,
              position: 'absolute',
            }}
          />
          {selected && (
          <View style={styles.wrapper}>
            <CheckMark stroke="#232323" strokeWidth={4} style={evaStyle.icon} />
          </View>
          )}
          <Ionicons
            name="ellipse"
            size={evaStyle.outer.radius}
            style={{
              color: evaStyle.outer.backgroundColor,
              zIndex: -1,
              top: evaStyle.outer.top,
            }}
          />

        </TouchableWeb>
      </View>
    );
  }
}
