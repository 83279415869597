import React, { Component } from 'react';
import { ImageProps, StyleProp, View, ViewStyle } from 'react-native';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import {
  FalsyFC,
  FalsyText,
  Overwrite,
  RenderProp,
} from '@ui-kitten/components/devsupport';
import { StyleType } from '@ui-kitten/components/theme';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { Alignment } from '@theme/variant-interfaces/Alignment';
import { Category } from '@theme/variant-interfaces/Category';
import { TextProps } from '../Text/Text';

type TableCellStyledProps = Overwrite<StyledComponentProps, {}>;

export interface TableCellProps extends TableCellStyledProps {
  accessoryLeft?: RenderProp<Partial<ImageProps>>,
  accessoryRight?: RenderProp<Partial<ImageProps>>,
  align?: Alignment,
  category?: Category
  children?: React.ReactNode | RenderProp<{ textProps: TextProps }>,
  noWrap?: boolean,
  style?: StyleProp<ViewStyle>,
  testID: string,
  usePadding?: boolean,
}

/**
 * The data cell component for a table.
 * Accepts children types of Text, IconButton, Select, Input or Icon components, or a simple string.
 *
 * @property {React.ReactNode} children -
 * Components to render as the main content within the table cell.
 *
 * @property {RenderProp<Partial<ImageProps>>} accessoryLeft -
 * Icon accessory to be placed to the left of the children.
 *
 * @property {RenderProp<Partial<ImageProps>>} accessoryRight -
 * Icon accessory to be placed to the right of the children.
 *
 * @property {StyleProp<ViewStyle>} style - Custom style sent to the table data cell.
 *
 */

@styled('TableCell')
export class TableCell extends Component<TableCellProps> {
  private getComponentStyle = (source: StyleType) => {
    const { height, ...cellProperties } = source;
    return {
      tableCell: {
        height,
        ...cellProperties,
      },
      padding: {
        paddingVertical: 16,
      },
      leftIcon: PropsServiceHelper.allWithPrefixMapped(source, 'leftAccessory'),
      rightIcon: PropsServiceHelper.allWithPrefixMapped(source, 'rightAccessory'),
      text: PropsServiceHelper.allWithPrefixMapped(source, 'text'),
    };
  };

  public render () {
    const {
      accessoryLeft,
      accessoryRight,
      category,
      children,
      eva,
      noWrap,
      style,
      usePadding = true,
      ...viewProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    const renderChildren = (): React.ReactElement => {
      const textProps: TextProps = {
        category: category || 'p2',
        style: [evaStyle.text, { width: '100%' }],
        numberOfLines: noWrap ? 1 : 0,
        ellipsizeMode: 'tail',
      };

      if (typeof children === 'string' || typeof children === 'number') {
        return (
          <FalsyText
            component={children}
            {...textProps}
          />
        );
      }
      return (
        <FalsyFC
          // @ts-ignore
          component={children}
          textProps={textProps}
        />
      );
    };

    return (
      <View
        style={[
          evaStyle.tableCell,
          { flexDirection: 'row', alignItems: 'flex-start' },
          usePadding && evaStyle.padding,
          style,
        ]}
        {...viewProps}
      >
        <FalsyFC
          component={accessoryLeft}
          style={evaStyle.leftIcon}
        />
        {renderChildren()}
        <FalsyFC
          component={accessoryRight}
          style={evaStyle.rightIcon}
        />
      </View>
    );
  }
}
