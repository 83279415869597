import { DataPoint, Icon, Modal, Text, TextLink, ViewRow } from '@design';
import { CropLogicComponentCategory } from '@shared/enums';
import { ApiCropLogicListItem, ApiCropLogicPassComponent } from '@shared/interfaces/api';
import { toShortDate } from '@shared/utils';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import _ from 'lodash';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useBusinessLocation } from '../../../../hooks/useBusinessLocation';
import {
  TankMixDetailsModal,
} from '../../../components/shared/TankMixDetailsModal/TankMixDetailsModal';

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: '#000',
    opacity: 0.7,
  },
  infoIcon: {
    alignItems: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 3,
  },
});

export const ProgramListTabRowDetails: FC<ApiCropLogicListItem> = (
  { passes, createdAt, updatedAt, isActive, logicName, priceTypeId, scenarioParameters },
) => {
  const { businessLocation } = useBusinessLocation({
    businessLocationId: scenarioParameters?.businessLocationId,
  });
  const [translate] = useTranslation(['common', 'programs', 'prepare']);
  const allPassComponents = _.flatten(
    (passes || []).map((pass) => pass.components),
  ).sort((a, b) => {
    if (a.product?.name < b.product?.name) return -1;
    return 1;
  });
  const abridgedPassComponents = allPassComponents.slice(0, 6).sort((a, b) => {
    if (a.product?.name < b.product?.name) return -1;
    return 1;
  });
  const isAbridged = allPassComponents.length !== abridgedPassComponents.length;

  const [tankMixModalVisibility, setTankMixModalVisibility] = useState(null);
  const [showMoreModalVisibility, setShowMoreModalVisibility] = useState(false);

  const renderPassComponents = (components : ApiCropLogicPassComponent[]) => (
    <>
      {components.map((component, index) => {
        const applicationRate = `${component.rate} ${translate(component.rateUom)}`;
        const productName = component.product
          ? `${component.product.name} (${component.product.skuName})`
          : '';
        if (component.componentCategory === CropLogicComponentCategory.TANK_MIX) {
          if (!component.tankMix) return (<></>);
          return (
            <View
              key={component.id}
              style={{ flexDirection: 'row' }}
              testID="value"
            >
              <Text category="p2" style={{ paddingBottom: 8 }}>{component.tankMix.name}</Text>
              <TouchableOpacity onPress={() => { setTankMixModalVisibility(index); }}>
                <Icon
                  fill={GrowersDarkTheme['color-secondary-500']}
                  name="Info"
                  size="small"
                  status="info"
                  style={styles.infoIcon}
                  testID="info-icon"
                />
              </TouchableOpacity>
              <TankMixDetailsModal
                businessLocationId={businessLocation?.id}
                onClose={() => setTankMixModalVisibility(null)}
                priceTypeId={priceTypeId}
                tankMix={component.tankMix}
                visible={tankMixModalVisibility === index}
              />
            </View>
          );
        }
        return (
          <View
            key={component.id}
            style={{ flexDirection: 'row' }}
            testID="value"
          >
            <Text category="p2" style={{ paddingBottom: 8 }} wrap>
              {`${productName}: ${translate('APPLICATION_PER_ACRE', { applicationRate })}`}
            </Text>
          </View>
        );
      })}
    </>
  );

  return (
    <>
      <ViewRow style={{ flex: 1 }}>
        <DataPoint
          label={translate('NUMBER_OF_PASSES') as string}
          style={{ flex: 1 }}
          testID="number-of-passes"
        >
          {passes?.length || 0}
        </DataPoint>
        <DataPoint
          label={translate('PRODUCT_APPLICATIONS') as string}
          style={{ flex: 1 }}
          testID="pass-components"
        >
          <>
            {renderPassComponents(abridgedPassComponents)}
            {isAbridged
              && (
                <>
                  <TextLink
                    appearance="secondary"
                    category="p2"
                    onPress={() => setShowMoreModalVisibility(true)}
                  >
                    {`${translate('SHOW_MORE')}`}
                  </TextLink>
                  <Modal
                    backdropStyle={styles.backdrop}
                    onClose={() => setShowMoreModalVisibility(false)}
                    scrollableContent
                    subTitle={logicName}
                    testID="show-more-modal"
                    title={translate('PRODUCT_APPLICATIONS')}
                    visible={showMoreModalVisibility}
                  >
                    {renderPassComponents(allPassComponents)}
                  </Modal>
                </>
              )}
          </>
        </DataPoint>
        <DataPoint
          label={translate('DATE_CREATED') as string}
          style={{ flex: 0.5 }}
          testID="date-created"
        >
          {toShortDate(createdAt)}
        </DataPoint>
      </ViewRow>
      <ViewRow style={{ justifyContent: 'space-between' }}>
        <DataPoint
          label={translate('DATE_UPDATED') as string}
          style={{ flex: 1 }}
          testID="date-updated"
        >
          {toShortDate(updatedAt)}
        </DataPoint>
        <DataPoint style={{ flex: 1 }} testID="space">{' '}</DataPoint>
        <DataPoint
          label={translate('STATUS') as string}
          style={{ flex: 0.5 }}
          testID="status"
        >
          {translate(isActive ? 'ACTIVE' : 'INACTIVE') as string}
        </DataPoint>
      </ViewRow>
    </>
  );
};
