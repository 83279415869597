import React, { useEffect, useRef, Dispatch, SetStateAction, FC, useState, useContext } from 'react';
import { Animated, StyleSheet, View, TouchableOpacity } from 'react-native';
import { Icon, Text } from '../../ui-components';
import { Sizing, ThemeColors } from '../../constants';
import { testId } from '../../utilities/testId';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: ThemeColors.WHITE,
    opacity: 0.25,
  },
  sidePanel: {
    width: Sizing.SIDE_PANEL_WIDTH,
    height: '100%',
    position: 'absolute',
    top: 0,
    backgroundColor: ThemeColors.DARKEST_GRAY,
    borderTopLeftRadius: Sizing.DOUBLE_SPACING,
    borderBottomLeftRadius: Sizing.DOUBLE_SPACING,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    height: Sizing.HEADER_HEIGHT,
    paddingLeft: 1.5 * Sizing.BASE_SPACING,
    paddingRight: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.MID_GRAY,
    borderTopLeftRadius: Sizing.DOUBLE_SPACING,
  },
  headerLabel: {
    fontSize: 1.1 * Sizing.EM,
    marginLeft: Sizing.HALF_SPACING,
    textTransform: 'uppercase',
  },
  icon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
  flexLeft: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

class SPUtility {
  show: (content: JSX.Element, header?: any) => void;
  hide: () => void;
  header: any;

  /**
   * Sets the show function
   * @param show The function to pass show commands to
   */
  setShow = (show: (content: JSX.Element, header?: any) => void) => {
    this.show = show;
  };

  /**
   * Sets the hide function
   * @param show The function to pass show commands to
   */
  setHide = (hide: () => void) => {
    this.hide = hide;
  };
}

export const SidePanelUtility = new SPUtility();

const SidebarContext = React.createContext(null);

export const SidePanelProvider = ({ children }) => {
  const [isSidePanelOpen, onSidebarChange] = useState(false);
  return (
    <SidebarContext.Provider value={{ isSidePanelOpen, onSidebarChange }}>
      {children}
    </SidebarContext.Provider>
  );
};

/**
 * @returns boolean if SidePanel is open or closed.
 * @usage const { isSidePanelOpen } = useIsSidePanelOpen;
 */
export const useIsSidePanelOpen = () => {
  const { isSidePanelOpen, onSidebarChange } = useContext(SidebarContext);
  return { isSidePanelOpen, onSidebarChange };
};

export interface ISidePanelProps {
  header: {
    label: string,
    icon: string,
    iconPack?: string,
  },
  content?: JSX.Element;
  afterHide: Dispatch<SetStateAction<boolean>>;
}

export const SidePanel: FC<ISidePanelProps> = ({
  afterHide,
  header,
  content,
}) => {
  const visibleAnim: Animated.Value = useRef(
    new Animated.Value(-Sizing.SIDE_PANEL_WIDTH),
  ).current;
  const { onSidebarChange } = useIsSidePanelOpen();
  const [animationState, setAnimationState] = useState<'closed' | 'animating' | 'open'>('closed');

  useEffect(() => {
    if (animationState === 'closed') {
      onSidebarChange(false);
    }
    if (animationState === 'open') {
      onSidebarChange(true);
    }
    return () => onSidebarChange(false);
  }, [animationState, onSidebarChange]);

  const hide = () => {
    setAnimationState('animating');
    Animated.timing(visibleAnim, {
      toValue: -Sizing.SIDE_PANEL_WIDTH,
      duration: 300,
      useNativeDriver: false,
    }).start(() => {
      setAnimationState('closed');
      afterHide(false);
    });
    return true;
  };

  useEffect(() => {
    setAnimationState('animating');
    Animated.timing(visibleAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }).start(() => setAnimationState('open'));
    SidePanelUtility.setHide(() => {
      setAnimationState('animating');
      Animated.timing(visibleAnim, {
        toValue: -Sizing.SIDE_PANEL_WIDTH,
        duration: 300,
        useNativeDriver: false,
      }).start(() => {
        setAnimationState('closed');
        afterHide(false);
      });
    });
  }, [visibleAnim, afterHide]);

  return (
    <View nativeID="side-panel" style={styles.container}>
      <View onStartShouldSetResponder={hide} style={styles.backdrop} />
      <Animated.View {...testId(`animated-view-${animationState}`)} style={[styles.sidePanel, { right: visibleAnim }]}>
        <View style={styles.header}>
          {header && (
            <View style={styles.flexLeft}>
              <Icon
                fill={ThemeColors.SECONDARY}
                name={header.icon}
                pack={header.iconPack}
                style={styles.icon}
              />
              <Text nativeID="header-text" style={styles.headerLabel}>{header.label}</Text>
            </View>
          )}
          <TouchableOpacity onPress={hide} style={styles.flexRight}>
            <Icon fill={ThemeColors.TEXT} name="close-outline" style={styles.icon} />
          </TouchableOpacity>
        </View>
        {content}
      </Animated.View>
    </View>
  );
};
