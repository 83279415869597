import React, { FunctionComponent, useState } from 'react';
import { View } from 'react-native';
import { Button, HSpacer, Input, LargeModal, TextLink, VSpacer } from '@design';
import { ResponsiveView } from '@design/SimpleLayout/ResponsiveView';
import { DemoContainer } from '../components/DemoBlock';

export const FullPageModalDemo: FunctionComponent = () => {
  const [singleModalVisible, setSingleModalVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [modalTitle, setModalTitle] = useState('Modal Title');
  const [modalSubTitle, setModalSubTitle] = useState('Subtitle (optional)');
  const [modalOverline, setModalOverline] = useState('Modal Title Overline');
  const [modalStepHeader, setModalStepHeader] = useState('Step Heading');
  const [modalStepSubTitle, setModalStepSubTitle] = useState('Step SubTitle(optional)');

  const pages = [
    (
      <View style={{ alignItems: 'center' }}>
        <Input
          caption="Required"
          label="Full Name"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Email"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Phone"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Country"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="City"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Postal Code"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Primary Contact"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
      </View>
    ),
    (
      <View style={{ alignItems: 'center' }}>
        <Input
          caption="Required"
          label="Favorite 80's Rock Band"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Father's Middle Name"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Place of Origin"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="Favorite Mammal"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
      </View>
    ),
    (
      <View style={{ alignItems: 'center' }}>
        <Input
          caption="Required"
          label="Mother's Maiden Name"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
        <VSpacer size="10" />
        <Input
          caption="Required"
          label="First Pet"
          size="large"
          style={{ width: 549 }}
          testID="test-input"
        />
      </View>
    ),
  ];

  const headerButton = (
    <Button appearance="ghost" status="basic" testID="test-button">Demo</Button>
  );

  return (
    <DemoContainer>
      <ResponsiveView large={4} medium={6}>
        <Input
          label="Title"
          onChangeText={setModalTitle}
          testID="test-input"
          value={modalTitle}
        />
      </ResponsiveView>
      <HSpacer size="2" />
      <ResponsiveView large={4} medium={6}>
        <Input
          label="Sub Title"
          onChangeText={setModalSubTitle}
          testID="test-input"
          value={modalSubTitle}
        />
      </ResponsiveView>
      <HSpacer size="2" />
      <ResponsiveView large={4} medium={6}>
        <Input
          label="Title Overline"
          onChangeText={setModalOverline}
          testID="test-input"
          value={modalOverline}
        />
      </ResponsiveView>
      <HSpacer size="2" />
      <ResponsiveView large={4} medium={6}>
        <Input
          label="Step Indicator Heading"
          onChangeText={setModalStepHeader}
          testID="test-input"
          value={modalStepHeader}
        />
      </ResponsiveView>
      <HSpacer size="2" />
      <ResponsiveView large={4} medium={6}>
        <Input
          label="Step Indicator SubTitle"
          onChangeText={setModalStepSubTitle}
          testID="test-input"
          value={modalStepSubTitle}
        />
      </ResponsiveView>
      <HSpacer size="2" />
      <Button onPress={() => setSingleModalVisible(true)} size="large" testID="test-button">
        Show
      </Button>
      <LargeModal
        currentPage={pageNumber}
        footer={() => (
          <View style={{
            padding: 40,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          >
            <View style={{ justifyContent: 'flex-start', alignSelf: 'center' }}>
              <TextLink onPress={() => setSingleModalVisible(false)}>Cancel</TextLink>
            </View>
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
              <Button
                appearance="outline"
                onPress={() => setPageNumber(pageNumber > 0 ? pageNumber - 1 : pageNumber)}
                size="giant"
                status="basic"
                testID="test-button"
              >
                Prev
              </Button>
              <HSpacer size="7" />
              <Button
                onPress={() => {
                  setPageNumber(pageNumber < (pages.length - 1) ? pageNumber + 1 : pageNumber);
                }}
                size="giant"
                testID="test-button"
              >
                Next
              </Button>
            </View>
          </View>
        )}
        headerButton={headerButton}
        pages={pages}
        sectionHeading={modalStepHeader}
        sectionSubHeading={modalStepSubTitle}
        subTitle={modalSubTitle}
        testID="full-page-large-modal-demo"
        title={modalTitle}
        titleOverline={modalOverline}
        visible={singleModalVisible}
      />
    </DemoContainer>
  );
};
