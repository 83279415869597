import React, { FC, useLayoutEffect, useState, useMemo } from 'react';
import { useWindowDimensions, View, ViewProps } from 'react-native';
import { styled, StyledComponentProps } from '@ui-kitten/components';
import * as _ from 'lodash';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { GutterWidths, screenBreakpoints, ScreenSizeType } from './constants';

// There is currently a proposal issue and PR to add a Numeric range type to TypeScript,
// that will eliminate these long number unions.
// https://github.com/microsoft/TypeScript/issues/43505 -The issue thread
// https://github.com/sindresorhus/type-fest/pull/319 -The PR

type XSmallType = 1 | 2 | 3 | 4;
type SmallType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
type MediumToXLargeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface ResponsiveViewProps extends ViewProps, StyledComponentProps {
  xSmall?: XSmallType,
  small?: SmallType,
  medium?: MediumToXLargeType,
  large?: MediumToXLargeType,
  xLarge?: MediumToXLargeType,
  gutterWidths?: GutterWidths,
  children: React.ReactNode,
}

export const ResponsiveViewRaw: FC<ResponsiveViewProps> = ({
  xSmall,
  small,
  medium,
  large,
  xLarge,
  eva,
  children,
  style,
  gutterWidths: propGutterWidths,
  ...viewProps
}: ResponsiveViewProps) => {
  const { width: windowWidth } = useWindowDimensions();
  const [cols, setCols] = useState<number>(4);
  const [screenSize, setScreenSize] = useState<ScreenSizeType>('small');

  // TODO: Need to find a better and performant algorithm to find the
  // proper & logical 'lowestProvidedSize'.

  // For example: <ResponsiveView xSmall={4} medium={6} ... />
  // small should use the value of 4 and not 6.

  // Another Example: <ResponsiveView xSmall={4} small={6} xLarge={3} ... />
  // medium & large should use 6 and not 3.
  const lowestProvidedSize = _.without([xSmall, small, medium, large, xLarge], null, undefined);
  const defaultSize = !_.isUndefined(_.last(lowestProvidedSize)) ? _.last(lowestProvidedSize) : 4;

  const componentStyle = {
    xSmall: PropsServiceHelper.allWithPrefixMapped(eva.style, 'xSmall'),
    small: PropsServiceHelper.allWithPrefixMapped(eva.style, 'small'),
    medium: PropsServiceHelper.allWithPrefixMapped(eva.style, 'medium'),
    large: PropsServiceHelper.allWithPrefixMapped(eva.style, 'large'),
    xLarge: PropsServiceHelper.allWithPrefixMapped(eva.style, 'xLarge'),
  };

  const elGutterPercentage = () => {
    const gutterWidth = propGutterWidths
      ? propGutterWidths[screenSize]
      : componentStyle[screenSize].gutterWidth;

    const gutterPerc = (
      gutterWidth / windowWidth
    ) * 100;
    if (Math.floor(gutterPerc) === 0) {
      return gutterPerc;
    }
    return Math.floor(gutterPerc);
  };

  const calcElemSize = (elSizeProp: number, screen: number) => {
    let elSize;

    if (_.isUndefined(elSizeProp)) {
      elSize = defaultSize;
    } else {
      elSize = elSizeProp;
    }

    return `${((elSize / screen) * 100) - elGutterPercentage()}%`;
  };

  useLayoutEffect(() => {
    for (const [key, value] of Object.entries(screenBreakpoints)) {
      if (windowWidth > value.min && windowWidth < value.max) {
        setScreenSize(key as ScreenSizeType);
        setCols(screenBreakpoints[key].cols);
      }
    }
  }, [windowWidth]);

  const elemSize = useMemo(() => {
    const sizes = {
      xSmall: () => calcElemSize(xSmall, screenBreakpoints[screenSize].cols),
      small: () => calcElemSize(small, screenBreakpoints[screenSize].cols),
      medium: () => calcElemSize(medium, screenBreakpoints[screenSize].cols),
      large: () => calcElemSize(large, screenBreakpoints[screenSize].cols),
      xLarge: () => calcElemSize(xLarge, screenBreakpoints[screenSize].cols),
    };

    return sizes[screenSize]();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cols, screenSize]);

  return (
    <View
      {...viewProps}
      style={
      [
        componentStyle[screenSize],
        {
          width: elemSize, marginRight: `${elGutterPercentage()}%`,
        },
        style,
      ]
}
    >
      {children}
    </View>
  );
};

export const ResponsiveView: FC<ResponsiveViewProps> = styled('ResponsiveView')(ResponsiveViewRaw);
