/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, Search } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  list: {
    color: '#FFF',
    paddingBottom: 8,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
const data = [
  {
    id: '1',
    jobTitle: 'Auditor',
    email: 'Aurelia_Hall544@kideod.biz',
    name: 'Aurelia Hall',
  },
  {
    id: '2',
    jobTitle: 'Food Technologist',
    email: 'Florence_Becker116@corti.com',
    name: 'Florence Becker',
  },
  {
    id: '3',
    jobTitle: 'Electrician',
    email: 'George_Spencer879@bungar.biz',
    name: 'George Spencer',
  },
  {
    id: '4',
    jobTitle: 'Systems Administrator',
    email: 'Deborah_Davies9220@gompie.com',
    name: 'Deborah Davies',
  },
  {
    id: '5',
    jobTitle: 'Design Engineer',
    email: 'Angel_Gray972@dionrab.com',
    name: 'Angel Gray',
  },
  {
    id: '6',
    jobTitle: 'Doctor',
    email: 'Clint_Woodcock7992@ubusive.com',
    name: 'Clint Woodcock',
  },
  {
    id: '7',
    jobTitle: 'Electrician',
    email: 'Leroy_Richardson4876@bauros.biz',
    name: 'Leroy Richardson',
  },
  {
    id: '8',
    jobTitle: 'Dentist',
    email: 'Elijah_Baker5490@eirey.tech',
    name: 'Elijah Baker',
  },
  {
    id: '9',
    jobTitle: 'Stockbroker',
    email: 'Penelope_Ingham1093@kideod.biz',
    name: 'Penelope Ingham',
  },
  {
    id: '10',
    jobTitle: 'Webmaster',
    email: 'Aleksandra_Mccormick4652@deavo.com',
    name: 'Aleksandra Mccormick',
  },
];

const filter = (item, query) => item?.name.toLowerCase().includes(query.toLowerCase());

export const SearchDemo = () => {
  const [value, setValue] = useState('');
  const [farmData] = useState(data);
  const [displayedFarmData, setdisplayedFarmData] = useState([]);

  useEffect(() => {
    setdisplayedFarmData(farmData);
  }, [farmData]);

  const onSelect = (index) => {
    setValue(displayedFarmData[index].name);
  };

  const onChangeText = (query) => {
    setValue(query);
    setdisplayedFarmData(farmData.filter((item) => filter(item, query)));
  };

  const onClear = () => {
    setValue('');
    setdisplayedFarmData(farmData);
  };

  return (
    <>
      <DemoContainer label="Search">
        <View style={styles.container}>
          <DemoBlock label="Medium">
            <Search
              onChangeText={onChangeText}
              onClear={onClear}
              onSelect={onSelect}
              options={displayedFarmData}
              size="medium"
              testID="sandbox-demo-medium-search"
              value={value}
            />
          </DemoBlock>
          <DemoBlock label="Large">
            <Search
              onChangeText={onChangeText}
              onClear={onClear}
              onSelect={onSelect}
              options={displayedFarmData}
              size="large"
              testID="sandbox-demo-large-search"
              value={value}
            />
          </DemoBlock>
        </View>
      </DemoContainer>
      <View>
        {displayedFarmData.map((item) => (
          <Card key={item.id} style={{ flex: 1, marginBottom: 8 }} testID="test-card">
            <View style={styles.list}>
              <Text category="p1">
                Name:
                {' '}
                {item?.name}
              </Text>
              <Text category="p2">
                Email:
                {' '}
                {item?.email}
              </Text>
              <Text category="p2">
                Job Title:
                {' '}
                {item.jobTitle}
              </Text>
            </View>
          </Card>
        ))}
      </View>
    </>
  );
};
