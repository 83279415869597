export const basic = {
  100: '#FFFFFF',
  200: '#F9F9F9',
  300: '#F1F1F1',
  400: '#E8E8E8',
  500: '#CDCDCD',
  600: '#AFAFAF',
  700: '#404040',
  800: '#2F2F2F',
  900: '#212121',
  1000: '#1C1C1C',
  1100: '#121212',
  default: '#232323',
  'transparent-0': 'rgba(175, 175, 175, 0.04)',
  'transparent-100': 'rgba(175, 175, 175, 0.08)',
  'transparent-200': 'rgba(175, 175, 175, 0.16)',
  'transparent-300': 'rgba(175, 175, 175, 0.24)',
  'transparent-400': 'rgba(175, 175, 175, 0.32)',
  'transparent-500': 'rgba(175, 175, 175, 0.40)',
  'transparent-600': 'rgba(175, 175, 175, 0.48)',
};
  
export const primary = {
  100: '#FDFDE0',
  200: '#FCFCC2',
  300: '#F9FAA4',
  400: '#F4F68E',
  500: '#EDF354',
  600: '#CBCF58',
  700: '#A9AD44',
  800: '#888B33',
  900: '#707327',
  'transparent-100': 'rgba(237, 243, 84, 0.08)',
  'transparent-200': 'rgba(237, 243, 84, 0.16)',
  'transparent-300': 'rgba(237, 243, 84, 0.24)',
  'transparent-400': 'rgba(237, 243, 84, 0.32)',
  'transparent-500': 'rgba(237, 243, 84, 0.4)',
  'transparent-600': 'rgba(237, 243, 84, 0.48)',
};
  
export const secondary = {
  100: '#FDFDE0',
  200: '#D2F7F3',
  300: '#B6E9E7',
  400: '#9AD2D6',
  500: '#64B2BD',
  600: '#5A8EA0',
  700: '#416E85',
  800: '#2C506A',
  900: '#1D3B57',
  transparent: 'rgba(237, 243, 84, 0.04)',
  'transparent-100': 'rgba(237, 243, 84, 0.08)',
  'transparent-200': 'rgba(237, 243, 84, 0.16)',
  'transparent-300': 'rgba(237, 243, 84, 0.24)',
  'transparent-400': 'rgba(237, 243, 84, 0.32)',
  'transparent-500': 'rgba(237, 243, 84, 0.4)',
  'transparent-600': 'rgba(237, 243, 84, 0.48)',
};

export const success = {
  300: 'rgb(140, 250, 199)',
  500: '#00D68F',
  'transparent-100': 'rgba(0, 214, 143, 0.08)',
  'transparent-200': 'rgba(0, 214, 143, 0.16)',
  'transparent-300': 'rgba(140, 250, 199, 0.16)',
  'transparent-400': 'rgba(0, 214, 143, 0.32)',
  'transparent-500': 'rgba(0, 214, 143, 0.4)',
  'transparent-600': 'rgba(0, 214, 143, 0.48)',
};

export const info = {
  300: 'rgb(148, 203, 255)',
  400: '#42AAFF',
  500: '#0095FF',
  'transparent-100': 'rgba(0, 149, 255, 0.08)',
  'transparent-200': 'rgba(0, 149, 255, 0.16)',
  'transparent-300': 'rgba(148, 203, 255, 0.16)',
  'transparent-400': 'rgba(0, 149, 255, 0.32)',
  'transparent-500': 'rgba(0, 149, 255, 0.4)',
  'transparent-600': 'rgba(0, 149, 255, 0.48)',
};

export const warning = {
  400: 'rgb(255, 201, 77)',
  500: '#FFAA00',
  'transparent-100': 'rgba(255, 170, 0, 0.08)',
  'transparent-200': 'rgba(255, 170, 0, 0.16)',
  'transparent-300': 'rgba(255, 170, 0, 0.24)',
  'transparent-400': 'rgba(255, 201, 77, 0.16)',
  'transparent-500': 'rgba(255, 170, 0, 0.4)',
  'transparent-600': 'rgba(255, 170, 0, 0.48)',
};

export const error = {
  500: '#FA7672',
  600: 'rgb(236, 103, 98)',
  'transparent-100': 'rgba(250, 118, 114, 0.08)',
  'transparent-200': 'rgba(250, 118, 114, 0.16)',
  'transparent-300': 'rgba(250, 118, 114, 0.24)',
  'transparent-400': 'rgba(250, 118, 114, 0.32)',
  'transparent-500': 'rgba(250, 118, 114, 0.4)',
  'transparent-600': 'rgba(236, 103, 98, 0.16)',
};
  