import { VSpacer } from '@design/Spacer';
import React, { ReactElement } from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  Dimensions,
  TextProps,
  EmitterSubscription,
} from 'react-native';
import {
  EvaProp,
  Layout,
  ModalProps as UIKModalProps,
  styled,
  StyleType,
} from '@ui-kitten/components';
import { FalsyFC, FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { IconButton } from '../IconButton/IconButton';
import { ButtonProps } from '../Button/Button';
import { SpacerProps } from '../Spacer/HSpacer';
import { BannerAwareModal } from '../BannerAwareModal/BannerAwareModal';

interface ModalProps extends UIKModalProps {
  backdropStyle?: StyleType,
  bodyStyle?: StyleType,
  containerStyle?: StyleType,
  eva?: EvaProp,
  footerAccessory?: RenderProp<{
    primaryButtonProp: Partial<ButtonProps>,
    secondaryButtonProp: Partial<ButtonProps>,
    spacerProp: Partial<SpacerProps>
  }>,
  footerStyle?: StyleType,
  headerVSpacerSize?: SpacerSize,
  height?: number,
  hideCloseButton?: boolean,
  maxHeight?: boolean,
  onClose?: (event?) => void,
  scrollableContent?: boolean,
  showScrollIndicator?: boolean,
  subTitle?: string | RenderProp<TextProps>,
  testID: string,
  title?: string,
  visible: boolean,
  width?: number,
}

const styles = StyleSheet.create({
  footer: {
    justifyContent: 'flex-end',
  },
});

const MAX_HEIGHT = 0.96;

@styled('RallyModal')
export class Modal extends React.Component<ModalProps, { height? :number }> {
  private listener: EmitterSubscription;

  constructor (props: ModalProps) {
    super(props);
    this.state = {
      height: Dimensions.get('window').height,
    };
  }

  componentDidMount () {
    this.listener = Dimensions.addEventListener('change', this.updateDimensions);
  }
  componentWillUnmount () {
    this.listener.remove();
  }

  private getComponentStyle = (source: StyleType) => {
    const titleStyles = PropsServiceHelper.allWithPrefixMapped(source, 'title');
    const subTitleStyles = PropsServiceHelper.allWithPrefixMapped(source, 'subTitle');
    const footerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'footer');
    const buttonStyles = PropsServiceHelper.allWithPrefixMapped(source, 'button');
    const backdropStyles = PropsServiceHelper.allWithPrefixMapped(source, 'backdrop');
    const closeStyles = PropsServiceHelper.allWithPrefixMapped(source, 'close');
    const bodyStyles = PropsServiceHelper.allWithPrefixMapped(source, 'modalBody');
    const containerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'contentContainer');
    const surfaceStyles = PropsServiceHelper.allWithPrefixMapped(source, 'surface');
    const actionStyles = PropsServiceHelper.allWithPrefixMapped(source, 'action');
    const scrollBodyStyles = PropsServiceHelper.allWithPrefixMapped(source, 'modalScrollBody');

    const { elevation } = source;

    return {
      action: actionStyles,
      title: titleStyles,
      subTitle: subTitleStyles,
      footer: footerStyles,
      button: buttonStyles,
      backdrop: backdropStyles,
      close: closeStyles,
      body: bodyStyles,
      scrollBody: scrollBodyStyles,
      container: containerStyles,
      surface: {
        ...surfaceStyles,
        ...elevation,
      },
    };
  };

  updateDimensions = () => {
    this.setState({ height: Dimensions.get('window').height });
  };

  public render () {
    const {
      eva,
      style,
      children,
      visible,
      width = 480,
      headerVSpacerSize = '8',
      height,
      maxHeight = false,
      title,
      subTitle,
      footerStyle,
      containerStyle,
      bodyStyle,
      backdropStyle,
      footerAccessory,
      hideCloseButton = false,
      onClose = () => {},
      scrollableContent = false,
      showScrollIndicator = false,
      testID,
      ...otherProps
    } = this.props;

    const {
      height: windowHeight,
    } = this.state;

    const evaStyle = this.getComponentStyle(eva.style);

    const content = (
      <Layout style={[evaStyle.surface, { width, height }, style]} testID={testID}>
        <View style={[evaStyle.container, containerStyle]}>
          {!hideCloseButton && (
          <IconButton
            appearance="ghost"
            onPress={(e) => onClose(e)}
            size="medium"
            status="basic"
            style={evaStyle.close}
            testID="close-icon"
          >
            Close
          </IconButton>
          )}
          <FalsyText component={title} style={evaStyle.title} testID={`${testID}-title`} />
          {(!!title && !!subTitle) && <VSpacer size="4" />}
          <FalsyText component={subTitle} style={evaStyle.subTitle} testID={`${testID}-subtitle`} />
          {!!title && <VSpacer size={headerVSpacerSize} />}
          {scrollableContent ? (
            <ScrollView
              showsVerticalScrollIndicator={showScrollIndicator}
              style={[evaStyle.scrollBody, evaStyle.body, bodyStyle]}
            >
              {children}
            </ScrollView>
          ) : (
            <View style={evaStyle.body} testID="modal-data">
              {children}
            </View>
          )}
        </View>
        {!!footerAccessory && (
        <View style={[evaStyle.footer, styles.footer, footerStyle]}>
          <FalsyFC
            component={footerAccessory as RenderProp<Partial<ReactElement>>}
            primaryButtonProp={{ status: 'basic', size: 'small', appearance: 'outline' }}
            secondaryButtonProp={{ status: 'primary', size: 'small', appearance: 'filled' }}
            spacerProp={{ size: '4' }}
            testID={`${testID}-footer`}
          />
        </View>
        )}
      </Layout>
    );

    return (
      <BannerAwareModal
        backdropStyle={StyleSheet.flatten([evaStyle.backdrop, backdropStyle])}
        onBackdropPress={() => onClose()}
        visible={visible}
        {...otherProps}
      >
        {
          maxHeight ? (
            <ScrollView
              showsVerticalScrollIndicator={showScrollIndicator}
              style={{ maxHeight: windowHeight * MAX_HEIGHT }}
            >
              {content}
            </ScrollView>
          ) : (
            content
          )
        }
      </BannerAwareModal>
    );
  }
}
