import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  ViewProps,
  TouchableOpacity,
} from 'react-native';
import { Icon, Popover } from '@ui-kitten/components';
import { Sizing, ThemeColors } from '../../../constants';
import { StyledButton, TButtonStatus } from './Button';
import { testId } from '../../../utilities/testId';

// height of an item (as measured in chrome currently)
const itemHeight = 49;
// max number of items to show in a drop down
// should be a number + .5 so that overflow is cut off and indicates scrolling.
const maxVisibleItems = 4.5;

const styles = StyleSheet.create({
  icon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
  dropdown: {
    borderWidth: 0,
    borderRadius: Sizing.QUARTER_SPACING,
    maxHeight: maxVisibleItems * itemHeight,
  },
  dropdownItem: {
    backgroundColor: ThemeColors.DARK_GRAY,
    padding: Sizing.BASE_SPACING,
  },
  dropdownItemText: { color: ThemeColors.TEXT },
});

export interface DropdownProps extends ViewProps {
  icon?: string,
  button?: boolean,
  label?: string,
  status?: TButtonStatus,
  actions?: {
    label: string | React.ReactElement,
    action: Function,
    nativeID?: string;
  }[];
}

export const StyledDropdown = (props: DropdownProps) => {
  const [focused, setFocused] = useState(false);
  const popoverRef = useRef();
  const [listVisible, setListVisible] = useState(false);

  useEffect(() => {
    if (focused) {
      setListVisible(focused);
    }
  }, [focused]);

  const onBackdropPress = useCallback(() => {
    setListVisible(false);
  }, []);

  // set timeout views to correct issue that occurs only in automated test
  const onActionSelected = (action: any) => {
    setListVisible(false);
    setTimeout(() => action.action());
  };

  const renderDropdownElement = () => (
    <View>
      {props.button && (
        <StyledButton
          iconLeft={props.icon}
          nativeID={props.nativeID}
          onBlur={() => setFocused(false)}
          onPress={() => setFocused(!focused)}
          status={props.status}
        >
          {props.label}
        </StyledButton>
      )}
      {!props.button && props.icon && (
        <TouchableOpacity
          onBlur={() => setFocused(false)}
          onPress={() => setFocused(!focused)}
        >
          <Icon
            fill={(props.status === 'secondary' && ThemeColors.PRIMARY) || (props.status === 'tertiary' && ThemeColors.SECONDARY) || (props.status === 'ghost' && ThemeColors.SECONDARY) || ThemeColors.PRIMARY}
            name={props.icon}
            style={styles.icon}
          />
        </TouchableOpacity>
      )}
    </View>
  );

  return (
    <>
      <Popover
        anchor={() => renderDropdownElement()}
        onBackdropPress={onBackdropPress}
        placement="left"
        ref={popoverRef}
        style={[styles.dropdown]}
        visible={listVisible}
      >
        <ScrollView style={styles.dropdown}>
          {props.actions.map((action) => (
            <TouchableOpacity
              key={action.nativeID}
              onPress={() => onActionSelected(action)}
              style={styles.dropdownItem}
              {...testId(action?.nativeID)}
            >
              <Text
                nativeID={`${action?.nativeID}-text`}
                {...testId(`${action?.nativeID}-option`)}
                style={styles.dropdownItemText}
              >
                {action.label}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Popover>
    </>
  );
};
