import { Search, Text, VSpacer } from '@design';
import {
  AgriculturalUnit,
  CostType,
  DiscountType,
  PlanStatus,
  ProductCategory,
} from '@shared/enums';
import { ApiProductSummary } from '@shared/interfaces/api';
import { Card } from '@ui-kitten/components';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import {
  CalculateQuantityDetails,
} from '../../../components/shared/ProductCard/CalculateQuantityDetails';
import { ProductCard } from '../../../components/shared/ProductCard/ProductCard';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const filter = (name, query) => name.toLowerCase().includes(query.toLowerCase());

export const ProductCardDemo = () => {
  const [translate] = useTranslation(['farmPlans']);
  const [searchQuery, setSearchQuery] = useState('');
  const [priceLocked, setPriceLocked] = useState(true);
  const [unitPrice, setUnitPrice] = useState<number | undefined>();
  const [packageQuantity, setPackageQuantity] = useState(74);

  const productSkuNames = [
    'TestSkuName',
    'skuName 1',
    'sku-name2',
  ];

  const productSummaries: ApiProductSummary[] = productSkuNames.map((skuName) => ({
    acceptedAt: null,
    acresApplied: null,
    avgRateApplied: null,
    avgRateAppliedUom: null,
    cancelled: false,
    createdAt: new Date(),
    externalDisplayId: null,
    externalId: null,
    externalLineItemId: null,
    growerFarmPlanId: null,
    id: 'summary-id',
    isActive: true,
    planStatus: PlanStatus.ACCEPTED,
    productId: null,
    productUnitsRequired: null,
    productSkuPackagesRequired: null,
    totalCost: null,
    unitPriceLocked: priceLocked,
    unitPrice,
    updatedAt: new Date(),
    productSkuPackagesContracted: packageQuantity,
    product: {
      businessCategoryId: null,
      businessId: '',
      category: ProductCategory.SEED,
      createdAt: new Date(),
      externalId: null,
      id: 'product-id',
      isActive: true,
      manufacturer: null,
      name: 'name',
      packageName: 'packageName',
      packageUnitQuantity: 20,
      prices: [],
      skuName,
      unitUoM: AgriculturalUnit.Bushel,
      updatedAt: new Date(),
    },
    discounts: [
      {
        createdAt: new Date(),
        discountType: DiscountType.DOLLARS,
        id: 'discount-1',
        isActive: true,
        costType: CostType.TOTAL_COST,
        name: 'Dollar discount',
        percent: 0,
        dollars: 420,
        updatedAt: new Date(),
      },
      {
        createdAt: new Date(),
        discountType: DiscountType.PERCENT,
        costType: CostType.UNIT_PRICE,
        id: 'discount-2',
        isActive: true,
        name: 'Percent discount',
        percent: 30,
        dollars: 0,
        updatedAt: new Date(),
      },
    ],
  }));

  const displayedProductSummaries = useMemo(() => (
    searchQuery
      ? productSummaries.filter((summary) => filter(summary.product.skuName, searchQuery))
      : productSummaries
  ), [productSummaries, searchQuery]);

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card style={{ flex: 1, backgroundColor: '#262626' }}>
        <DemoContainer column label="ProductCard">
          <DemoBlock>
            <ProductCard
              locationId=""
              onAddDiscount={console.log}
              onDeleteDiscount={console.log}
              onEditDiscount={console.log}
              onPackageQuantityChange={setPackageQuantity}
              onPriceLockedChange={(isPriceLocked, price) => {
                setPriceLocked(isPriceLocked);
                setUnitPrice(price);
              }}
              priceTypeId=""
              productId={productSummaries[0].productId}
              productIdx={0}
              productSummary={productSummaries[0]}
            >
              <CalculateQuantityDetails
                applicationRate={10}
                applicationRateUom={AgriculturalUnit.KSDS}
                pricePerAcre={1}
                quantityRequired={3}
              />
            </ProductCard>
          </DemoBlock>
        </DemoContainer>
        <DemoContainer column label="Searchable Product Cards">
          <VSpacer size="6" />
          <Search
            onChangeText={setSearchQuery}
            onClear={() => setSearchQuery('')}
            placeholder={translate<string>('SEARCH_PRODUCTS')}
            size="medium"
            testID="product-card-search"
            value={searchQuery}
          />
          <VSpacer size="5" />
          {displayedProductSummaries.length ? (
            displayedProductSummaries.map((productSummary) => (
              <DemoBlock>
                <ProductCard
                  locationId=""
                  priceTypeId=""
                  productId={productSummary[0].productId}
                  productIdx={0}
                  productSummary={productSummary}
                />
              </DemoBlock>
            ))) : (
              <DemoBlock>
                <Text>{translate<string>('NO_PRODUCTS_MATCH_SEARCH')}</Text>
              </DemoBlock>
          )}
        </DemoContainer>
      </Card>
    </View>
  );
};
