import {
  Badge,
  Button,
  Card,
  CheckBox,
  HSpacer,
  Icon,
  IconButton,
  NumericInput,
  Select,
  SelectItem,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { IntegrationType, PlanStatus } from '@shared/enums';
import { ApiProductSummary, DiscountEndpoint } from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import { IndexPath } from '@ui-kitten/components';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { DiscountModalTitle } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessDiscounts } from '../../../../hooks/useBusinessDiscounts';
import { useIntegration } from '../../../../hooks/useIntegration';
import { StringUtility } from '../../../../utilities';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import DescriptionListItem from '../DescriptionList/DescriptionListItem';
import { OrderDetailsDiscountModal } from '../FarmPlan/OrderDetailsDiscountModal';
import { FarmPlanSummary } from './FarmPlanSummary';
import { ProductDiscounts } from './ProductDiscounts';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
  },
  row: {
    flexDirection: 'row',
  },
  rightAligned: {
    alignItems: 'flex-end',
  },
  centerAligned: {
    alignItems: 'center',
  },
  spaceBetweenRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  qtyWrapper: {
    width: 80,
    height: '100%',
  },
  unitPriceWrapper: {
    width: 109,
    height: '100%',
  },
  skuStatusSelectWrapper: {
    width: 150,
  },
  flexOne: {
    flex: 1,
  },
  text: {
    flexShrink: 1,
  },
});

const planStatuses = Object.keys(PlanStatus);

interface ProductCardActionProps {
  onAddDiscount?(discount: DiscountEndpoint.Save.Request, productId?: string): void,
  onCancelSku?(productId?: string): void,
  onDeleteDiscount?(discountIdx?: number, productId?: string): void,
  onEditDiscount?(
    discount: DiscountEndpoint.Save.Request,
    discountIdx?: number,
    productId?: string,
  ): void,
  onPackageQuantityChange?(packageQuantity: number, totalCost: number, productId: string): void,
  onPlanStatusChange?(planStatus: PlanStatus, productId?: string): void,
  onPriceLockedChange?(isPriceLocked: boolean, price: number | undefined, productId: string): void,
}

export interface ProductCardProps extends ProductCardActionProps {
  children?: React.ReactNode,
  expanded?: boolean,
  initialProductSummary?: ApiProductSummary,
  locationId: string,
  priceTypeId: string,
  productId: string,
  productIdx: number,
  productSummary: ApiProductSummary,
}

export const ProductCard = React.memo(
  ({
    children,
    expanded = false,
    initialProductSummary,
    locationId,
    onAddDiscount,
    onCancelSku,
    onDeleteDiscount,
    onEditDiscount,
    onPackageQuantityChange,
    onPlanStatusChange,
    onPriceLockedChange,
    priceTypeId,
    productId,
    productIdx,
    productSummary,
  }: ProductCardProps) => {
    const [translate] = useTranslation(['common', 'farmPlans', 'productCard']);

    const [packageQuantity, setPackageQuantity] = useState(
      productSummary.productSkuPackagesContracted ?? 0,
    );
    const [unitPrice, setUnitPrice] = useState(productSummary.unitPrice);
    const [showDetails, setShowDetails] = useState(expanded);
    const [discountModalVisible, setDiscountModalVisible] = useState(false);
    const [cancelSkuModalVisible, setCancelSkuModalVisible] = useState(false);
    const [deleteDiscountModalVisible, setDeleteDiscountModalVisible] = useState(false);
    const [priceLockedModalVisible, setPriceLockedModalVisible] = useState(false);
    const { currentBusinessId } = useAuthentication();
    const { businessDiscountsList } = useBusinessDiscounts();
    const { integration } = useIntegration({ businessId: currentBusinessId });

    useEffect(() => setShowDetails(expanded), [expanded]);

    const hasBusinessCentralIntegration = (
      !!integration && integration.type === IntegrationType.MSDynamics
    );

    const showFarmPlanSummary = (
      !!productSummary.avgRateApplied
      || !!productSummary.productSkuPackagesRequired
      || !!productSummary.acresApplied
      || !!productSummary.productUnitsRequired
    );
    const showDiscounts = productSummary.discounts?.length > 0;
    const hasDetails = showFarmPlanSummary || showDiscounts || !!children;

    const acceptedPlanStatus = useMemo(() => (
      initialProductSummary.planStatus === PlanStatus.ACCEPTED
    ),
    [initialProductSummary.planStatus]);

    const price = useMemo(() => CalculationUtility.getApplicableProductPrice(
      productSummary.product,
      priceTypeId,
      locationId,
    ), [locationId, priceTypeId, productSummary.product]);

    const { discounts, discountedCost } = useMemo(
      () => CalculationUtility.calculateFarmPlanProductDiscounts({
        ...productSummary,
        unitPrice: unitPrice ?? price,
      }),
      [price, productSummary, unitPrice],
    );

    const handlePriceLockedChange = useCallback((syncLocalPrice: boolean = false) => {
      onPriceLockedChange?.(
        !productSummary.unitPriceLocked,
        !productSummary.unitPriceLocked ? productSummary.unitPrice : price,
        productId,
      );
      if (syncLocalPrice) {
        setUnitPrice(price);
      }
    }, [onPriceLockedChange, price, productSummary, productId]);

    const handlePlanStatusChange = useCallback((i: IndexPath | IndexPath[]) => onPlanStatusChange?.(
      PlanStatus[planStatuses[(i as IndexPath).row]],
      productId,
    ), [onPlanStatusChange, productId]);

    const packageUnitQuantity = productSummary.product?.packageUnitQuantity ?? 0;
    const unitUomText = translate(productSummary.product?.unitUoM ?? 'UNIT');
    const totalCostSku = packageQuantity * packageUnitQuantity;
    const totalCost = totalCostSku * unitPrice;
    const costPerAcre = (productSummary.acresApplied > 0)
      ? totalCost / productSummary.acresApplied : 0;

    const unitsPerSkuText = `${StringUtility.localizeNumber(packageUnitQuantity, 3)} ${unitUomText}`;
    const totalUnitsText = `${StringUtility.localizeNumber(totalCostSku, 4)} ${unitUomText}`;

    const handlePackageQuantityChange = _.debounce((newPackageQuantity) => {
      onPackageQuantityChange?.(newPackageQuantity, totalCost, productId);
    }, 150);

    const handleUnitPriceChange = _.debounce((newUnitPrice) => {
      onPriceLockedChange?.(
        productSummary.unitPriceLocked || price !== newUnitPrice,
        newUnitPrice,
        productId,
      );
    }, 150);

    const getMessageText = () => {
      if (hasBusinessCentralIntegration) {
        const isSalesQuote = productSummary.externalDisplayId.includes('SQ');
        return translate(
          isSalesQuote
            ? 'CANCEL_SKU_QUOTE_WARNING_DYNAMICS'
            : 'CANCEL_SKU_ORDER_WARNING_DYNAMICS',
          { email: integration?.notificationEmail },
        );
      }
      return translate('CANCEL_SKU_WARNING');
    };

    return (
      <>
        <Card style={styles.container} testID={`product-card-${productIdx}`}>
          {(productSummary.cancelled || initialProductSummary.cancelled) && (
            <>
              <View style={styles.row}>
                <Icon
                  name="AlertTriangle"
                  status="warning"
                  testID={`product-card-deactivated-warning-icon-${productIdx}`}
                />
                <HSpacer size="2" />
                <Text
                  category="p1"
                  testID={`product-card-deactivated-warning-message-${productIdx}`}
                >
                  {translate<string>(initialProductSummary.cancelled
                    ? 'THIS_PRODUCT_HAS_BEEN_CANCELLED' : 'THIS_PRODUCT_WILL_BE_CANCELLED') }
                </Text>
              </View>
              <VSpacer size="5" />
            </>
          )}

          {/* SKU/Price */}
          <View style={styles.spaceBetweenRow}>
            <Text
              category="h6"
              style={styles.text}
              testID={`product-card-name-${productIdx}`}
            >
              {productSummary.product?.skuName}
            </Text>
            <HSpacer size="7" />
            <View style={styles.rightAligned}>
              <Text
                category="h6"
                testID={`product-card-total-${productIdx}`}
              >
                {StringUtility.formatCurrency(discountedCost.total)}
              </Text>
              {showDiscounts && (
                <>
                  <VSpacer size="3" />
                  <Badge
                    status="success"
                    testID={`product-card-is-discount-applied-${productIdx}`}
                  >
                    {translate<string>('DISCOUNT_APPLIED').toUpperCase()}
                  </Badge>
                  <VSpacer size="3" />
                </>
              )}
            </View>
          </View>

          {!showDiscounts && <VSpacer size="5" />}

          {/* QTY/Unit Price/UOM/Lock */}
          <View style={styles.row}>
            <View style={styles.qtyWrapper}>
              <NumericInput
                label={translate<string>('SKU_QTY')}
                minValue={0}
                onChangeValue={(newPackageQuantity) => {
                  setPackageQuantity(newPackageQuantity ?? 0);
                  handlePackageQuantityChange(newPackageQuantity ?? 0);
                }}
                precision={4}
                readOnly={!onPackageQuantityChange || acceptedPlanStatus}
                readOnlyLabel={false}
                showRelevantDecimalsOnly
                testID={`product-card-quantity-${productIdx}`}
                value={packageQuantity}
              />
            </View>
            <HSpacer size="7" />
            <View style={styles.unitPriceWrapper}>
              <NumericInput
                label={translate<string>('UNIT_PRICE')}
                onChangeValue={(newUnitPrice) => {
                  setUnitPrice(newUnitPrice ?? 0);
                  handleUnitPriceChange(newUnitPrice ?? 0);
                }}
                precision={2}
                prefix="$"
                readOnly={!onPriceLockedChange || acceptedPlanStatus}
                readOnlyLabel={false}
                testID={`product-card-unit-price-input-${productIdx}`}
                value={unitPrice ?? price}
              />
            </View>
            <HSpacer size="3" />
            <View>
              <VSpacer size="2" />
              <VSpacer size="8" />
              <View style={styles.row}>
                <Text testID={`product-card-product-per-uom-${productIdx}`}>
                  {translate<string>('PER_UOM', { UoM: unitUomText })}
                </Text>
              </View>
            </View>
            <HSpacer size="7" />
            <View>
              <VSpacer size="2" />
              <VSpacer size="8" />
              {(!onPriceLockedChange || acceptedPlanStatus) ? (
                <View style={styles.row}>
                  <Icon
                    name={productSummary.unitPriceLocked ? 'Lock' : 'GrowersUnlock'}
                    status="basic"
                    testID={`product-card-icon-price-locked-${productIdx}`}
                  />
                  <HSpacer size="3" />
                  <Text testID={`product-card-text-price-locked-${productIdx}`}>
                    {translate<string>(productSummary.unitPriceLocked ? 'PRICE_LOCKED' : 'PRICE_UNLOCKED')}
                  </Text>
                </View>
              ) : (
                <CheckBox
                  checked={productSummary.unitPriceLocked || unitPrice !== productSummary.unitPrice}
                  onChange={() => {
                    if (
                      productSummary.unitPriceLocked
                      && productSummary.unitPrice !== undefined
                      && price !== productSummary.unitPrice
                    ) {
                      setPriceLockedModalVisible(true);
                    } else {
                      handlePriceLockedChange();
                    }
                  }}
                  testID={`product-card-price-locked-${productIdx}`}
                >
                  {translate<string>('LOCK_PRICE')}
                </CheckBox>
              )}
            </View>
          </View>

          {/* SKU Price / Units Per SKU */}
          <View style={styles.row}>
            <View style={styles.flexOne}>
              <VSpacer size="5" />
              <View style={styles.row}>
                <DescriptionListItem
                  label={translate('UNITS_PER_SKU')}
                  testID={`product-card-units-per-sku-${productIdx}`}
                  text={unitsPerSkuText}
                  textCategory="p2"
                />
                <HSpacer size="5" />
                <DescriptionListItem
                  label={translate('TOTAL_UNITS')}
                  testID={`product-card-total-units-required-${productIdx}`}
                  text={totalUnitsText}
                  textCategory="p2"
                />
                <HSpacer size="5" />
                <DescriptionListItem
                  label={translate('COST_PER_AC')}
                  testID={`product-card-cost-per-ac-${productIdx}`}
                  text={StringUtility.formatCurrency(costPerAcre)}
                  textCategory="p2"
                />
              </View>

              <VSpacer size="5" />

              {/* Show Details link, SKU status Select, Add discount Button or Trash */}
              <View style={[styles.row, styles.centerAligned]}>
                <View style={styles.flexOne}>
                  {hasDetails && (
                    <TextLink
                      accessoryRight={(iconProps) => (
                        <Icon
                          name={showDetails ? 'ChevronUp' : 'ChevronDown'}
                          testID={`product-card-details-text-link-icon-${productIdx}`}
                          {...iconProps}
                        />
                      )}
                      appearance="secondary"
                      category="p2"
                      onPress={() => setShowDetails(!showDetails)}
                      testID={`product-card-details-text-link-${productIdx}`}
                    >
                      {translate<string>(showDetails ? 'HIDE_DETAILS' : 'SHOW_DETAILS')}
                    </TextLink>
                  )}
                </View>
                <HSpacer size="7" />

                {/* Status Select and Button container */}
                <View style={[styles.row, styles.centerAligned]}>
                  <View style={styles.skuStatusSelectWrapper}>
                    <Select
                      onSelect={handlePlanStatusChange}
                      readOnlyLabel={false}
                      readonly={!onPlanStatusChange || acceptedPlanStatus}
                      testID="plan-status-selector"
                      value={translate<string>(productSummary.planStatus ?? 'SET_PLAN_STATUS')}
                    >
                      {planStatuses.map((planStatus) => (
                        <SelectItem
                          key={planStatus}
                          testID={`plan-status-value-${planStatus}`}
                          title={translate<string>(planStatus)}
                        />
                      ))}
                    </Select>
                  </View>
                  <HSpacer size="7" />

                  {acceptedPlanStatus && !productSummary.cancelled && (
                    <IconButton
                      appearance="ghost"
                      onPress={() => setCancelSkuModalVisible(true)}
                      status="basic"
                      testID="cancel-sku-icon"
                    >
                      Trash
                    </IconButton>
                  )}

                  {!acceptedPlanStatus && (
                    <Button
                      accessoryLeft={(iconProps) => (
                        <Icon
                          name="Pricetags"
                          testID={`product-card-add-sku-discount-icon-${productIdx}`}
                          {...iconProps}
                        />
                      )}
                      appearance="outline"
                      disabled={!onAddDiscount}
                      onPress={() => setDiscountModalVisible(true)}
                      size="small"
                      status="basic"
                      testID={`product-card-add-sku-discount-${productIdx}`}
                    >
                      {translate<string>('ADD_SKU_DISCOUNT')}
                    </Button>
                  )}
                </View>
              </View>

              {/* Details */}
              {showDetails && hasDetails && (
                <>
                  <VSpacer size="5" />
                  {showFarmPlanSummary && (
                    <FarmPlanSummary
                      price={price}
                      productSkuPrice={packageUnitQuantity * price}
                      productSummary={productSummary}
                    />
                  )}
                  {(showFarmPlanSummary && showDiscounts) && <VSpacer size="7" />}
                  {showDiscounts && (
                    <ProductDiscounts
                      calculatedDiscounts={discounts}
                      discounts={productSummary.discounts}
                      onDeleteDiscount={onDeleteDiscount}
                      onEditDiscount={onEditDiscount}
                      productId={productId}
                    />
                  )}
                  {(showDiscounts && !!children) && <VSpacer size="7" />}
                  {children}
                </>
              )}
            </View>
          </View>
        </Card>
        {discountModalVisible && (
          <OrderDetailsDiscountModal
            businessDiscountsList={businessDiscountsList}
            discountSkuEditActionTitle={DiscountModalTitle.ADD_SKU_DISCOUNT}
            discounts={productSummary.discounts}
            onApply={(newDiscount) => {
              onAddDiscount(newDiscount, productId);
              setDiscountModalVisible(false);
            }}
            onClose={() => setDiscountModalVisible(false)}
            productId={productId}
          />
        )}
        {cancelSkuModalVisible && (
          <ConfirmationModal
            cancelText={translate('NO_KEEP_EDITING')}
            confirmText={translate('YES_CANCEL_SKU')}
            messageText={getMessageText()}
            onCancel={() => setCancelSkuModalVisible(false)}
            onConfirm={() => {
              onCancelSku(productSummary.productId);
              setCancelSkuModalVisible(false);
            }}
            status="warning"
            title={translate('CANCEL_SKU')}
            visible
          />
        )}
        {deleteDiscountModalVisible && (
          <ConfirmationModal
            cancelText={translate('BACK')}
            confirmText={translate('YES_DELETE')}
            onCancel={() => setDeleteDiscountModalVisible(false)}
            onConfirm={() => {
              onDeleteDiscount(undefined, productId);
              setDeleteDiscountModalVisible(false);
            }}
            status="warning"
            title={translate('DELETE_DISCOUNT')}
            visible
          />
        )}
        {priceLockedModalVisible && (
          <ConfirmationModal
            cancelText={translate('CANCEL')}
            confirmText={translate('UNLOCK')}
            messageText={translate<string>('UNLOCK_PRICE_WARNING', {
              current: StringUtility.formatCurrency(productSummary.unitPrice),
              unit: unitUomText,
              default: StringUtility.formatCurrency(price),
            })}
            onCancel={() => setPriceLockedModalVisible(false)}
            onConfirm={() => {
              handlePriceLockedChange(true);
              setPriceLockedModalVisible(false);
            }}
            status="warning"
            title={translate('UNLOCK_PRICE')}
            visible
          />
        )}
      </>
    );
  },
  (a, b) => _.isEqual(a.productSummary, b.productSummary)
    && a.expanded === b.expanded
    && _.isEqual(a.initialProductSummary, b.initialProductSummary)
    && a.locationId === b.locationId
    && a.priceTypeId === b.priceTypeId
    && a.productId === b.productId
  ,
);
