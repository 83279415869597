import {
  Badge,
  Button,
  DataPoint,
  HSpacer,
  Icon,
  SortDirection,
  TextLink,
  ViewRow,
  VSpacer,
} from '@design';
import { useBanner } from '@design/Application/Banner/BannerManager';
import { BannerProps } from '@design/Banner/Banner';
import { FilterCategory, Selections } from '@design/Filter/Filter';
import { FilterTable } from '@design/FilterTable/FilterTable';
import { IntegrationType, UserType } from '@shared/enums';
import { OrderStatus } from '@shared/interfaces';
import {
  ApiProductOrderListQuery,
  ApiProductOrderListQuerySort,
  ApiProductOrderListResponse,
  GrowerEndpoint,
  PaginatedData,
} from '@shared/interfaces/api';
import { Permissions, RoleUtility, stringSort, toShortDate } from '@shared/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../hooks/useIntegration';
import { ProductOrderApi } from '../../../../utilities/api/ProductOrderApi';
import { ExternalDisplayIdText } from '../../../components/shared/IntegrationOrder/ExternalDisplayIdText';
import { RowMeta } from '../../../components/SortableTable';
import ProductOrderItemMenu from './ProductOrderItemMenu';
import { ProductOrderStatus } from './ProductOrderStatus';
import { useCustomerTotals } from '../../../../hooks/useCustomerTotals';
import { maxListSize } from '@design/FilterMenu/FilterMenu';
import { GrowerApi } from '../../../../utilities/api/GrowerApi';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  badge: {
    alignSelf: 'baseline',
    textTransform: 'uppercase',
  },
});

const columnCategories: FilterCategory[] = [
  {
    columnLabel: 'Name',
    columnKey: 'name',
    columns: [],
  },
  {
    columnLabel: 'Customer',
    columnKey: 'growerId',
    columns: [],
  },
  {
    columnLabel: 'Owner',
    columnKey: 'ownerId',
    columns: [],
  },
  {
    columnLabel: 'Updated',
    columnKey: 'modified',
    columns: [],
  },
  {
    columnLabel: 'Sales status',
    columnKey: 'status',
    columns: [],
  },
  {
    columnLabel: 'Location',
    columnKey: 'locationId',
    columns: [],
  },
  {
    columnLabel: 'Crop year',
    columnKey: 'cropYear',
    columns: [],
  },
];

const defaultFilters: Map<string, Selections> = new Map([
  ['status', {
    UNLABELED: true,
    ACCEPTED: true,
    SHARED: true,
  }],
]);

const sortColDictionary = {
  growerId: 'growerName',
  ownerId: 'ownerName',
};

interface PageOption {
  filter: ApiProductOrderListQuery,
  page: number,
  sort: ApiProductOrderListQuerySort,
  sortDesc: boolean;
}

export interface ProductOrderListProps {
  onCancelOrder(productOrderId: ApiProductOrderListResponse): void,
  onCreateDeliverablePress?(productOrderId: string): void,
  onCreatePress(): void,
  onDeletePress?(productOrder: ApiProductOrderListResponse): void,
  onDuplicatePress?(productOrderId: string): void,
  onEditPress(productOrderId: string, isDraft: boolean): void,
  onViewDeliverablesPress?(productOrderId: string): void,
}
export function ProductOrderList (props: ProductOrderListProps) {
  const [translate] = useTranslation(['common', 'prepare', 'productOrders', 'errors']);
  const [productOrders, setProductOrders] = useState<(ApiProductOrderListResponse & RowMeta)[]>([]);
  const { user, currentBusinessId } = useAuthentication();
  const { setModalProps } = useAppContext();
  const queryClient = useQueryClient();
  const { createBanner } = useBanner();
  const { integration } = useIntegration({ businessId: currentBusinessId });
  const [customerSearch, setCustomerSearch] = useState('');

  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );
  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) });

  const [pageOptions, setPageOptions] = useState<PageOption>({
    page: 0,
    filter: {
      status: [
        OrderStatus.UNLABELED,
        OrderStatus.ACCEPTED,
        OrderStatus.SHARED,
      ],
    },
    sort: ApiProductOrderListQuerySort.modified,
    sortDesc: true,
  });

  const { customerTotals, isSuccess } = useCustomerTotals({
    onError: () => { createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_ORDER_FILTERS'))); },
  });

  const { data: customers, isSuccess: customersLoaded } = useQuery(
    [QueryKeys.GROWER_LIST, currentBusinessId, customerSearch],
    async () => GrowerApi.getGrowers({
      businessId: currentBusinessId,
      limit: maxListSize,
      search: customerSearch,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }),
    {
      enabled: isSuccess,
      onError: () => { setPageOptions({ ...pageOptions, page: 0 }); },
    },
  );

  useQuery(
    [QueryKeys.PRODUCT_ORDER_FILTERS, currentBusinessId],
    () => ProductOrderApi.getProductOrderFilters({ businessId: currentBusinessId }),
    {
      enabled: customersLoaded && isSuccess,
      onSuccess: ({ owners, businessLocations, cropYears }) => {
        columnCategories.forEach((category) => {
          switch (category.columnKey) {
            case 'growerId':
              category.columns = customers?.data?.map((customer) => ({
                label: customer.legalName,
                id: customer.id,
              })) || [];
              category.total = customerTotals;
              category.onSearch = setCustomerSearch;
              break;
            case 'ownerId':
              category.columns = owners?.map((owner) => ({
                label: owner.fullName,
                id: owner.id,
              })).sort((a, b) => stringSort(a.label, b.label)) || [];
              break;
            case 'status':
              category.columns = Object.keys(OrderStatus).map((key) => ({
                label: translate(OrderStatus[key]),
                id: OrderStatus[key],
              }));
              break;
            case 'locationId':
              category.columns = [
                {
                  label: translate('CORPORATE_LOCATION'),
                  id: 'null',
                },
                ...businessLocations.map((location) => ({
                  label: location.locationName,
                  id: location.id,
                })),
              ];
              break;
            case 'cropYear':
              category.columns = cropYears.sort().map((c) => ({
                label: c.toString(),
                id: c.toString(),
              }));
              break;
            default:
          }
        });
      },
      onError: () => {
        createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_ORDER_FILTERS')));
      },
    },
  );

  const getProductOrders = async (): Promise<PaginatedData<ApiProductOrderListResponse>> => {
    return ProductOrderApi.getProductOrders({
      businessId: currentBusinessId,
      ...pageOptions.filter,
      limit: 20,
      page: pageOptions.page,
      sort: pageOptions.sort,
      sortDesc: pageOptions.sortDesc,
    });
  };

  const {
    data: productOrdersResponse,
    isFetching,
    isLoading,
    isRefetching: isRefetchingProductOrderList,
  } = useQuery(
    [QueryKeys.PRODUCT_ORDER_LIST, currentBusinessId, pageOptions],
    getProductOrders,
    {
      keepPreviousData: true,
      onError: () => {
        createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_ORDERS')));
      },
      onSuccess: (productOrderListData) => {
        setProductOrders(
          productOrderListData.data.map((productOrder, index) => ({
            ...productOrder,
            hasDetails: true,
            rowId: `row:${index}|${productOrder.id}|`,
            rowDetailId: `row-drawer:${index}|${productOrder.id}|`,
          })),
        );
      },
    },
  );

  useEffect(() => {
    if (isRefetchingProductOrderList) {
      queryClient.invalidateQueries(QueryKeys.PRODUCT_ORDER_FILTERS);
    }
  }, [isRefetchingProductOrderList, queryClient]);

  const handleSort = (column: string, direction: SortDirection) => {
    setPageOptions({
      ...pageOptions,
      sort: sortColDictionary[column] ?? column,
      sortDesc: direction === 'DESC',
    });
  };

  const isSuperAdmin = RoleUtility.roleHasPermission(
    user.userRole,
    Permissions.ACCESS_ALL_BUSINESSES,
  );

  const renderSalesStatus = (rowData) => {
    if (rowData.status === OrderStatus.UNLABELED) {
      return <Icon name="Minus" testID="sales-status-empty-icon" />;
    }
    let salesStatusText = translate<string>(rowData.status);
    if (rowData.status === OrderStatus.ACCEPTED && rowData.cancelled) {
      salesStatusText += ` | ${translate('CANCELLED')}`;
    }
    return salesStatusText;
  };

  return (
    <ScrollView>
      <FilterTable<ApiProductOrderListQuery, ApiProductOrderListResponse & RowMeta>
        accessoryRight={!isSuperAdmin && (
          <Button
            accessoryLeft={(iconProps) => (
              <Icon name="Plus" testID="create-quick-quote-button-icon" {...iconProps} />
            )}
            onPress={() => props.onCreatePress()}
            size="medium"
            testID="create-quick-quote-button"
          >
            {translate<string>('CREATE_QUICK_QUOTE')}
          </Button>
        )}
        columns={[
          {
            columnId: 'name',
            header: {
              render: translate('NAME'),
              sortable: true,
            },
            render: (data) => (
              (
                <View style={styles.row}>
                  {data.isDraft && (
                    <>
                      <Badge
                        status="warning"
                        style={styles.badge}
                        testID="isDraft"
                      >
                        {translate<string>('DRAFT')}
                      </Badge>
                      <HSpacer size="3" />
                    </>
                  )}
                  <TextLink
                    appearance="secondary"
                    category="p2"
                    onPress={() => setModalProps({
                      type: 'viewProductOrder',
                      productOrderId: data.id,
                    })}
                    wrap
                  >
                    {data.name}
                  </TextLink>
                </View>
              )
            ),
            flex: 3,
          },
          {
            columnId: 'growerId',
            header: {
              render: translate('CUSTOMER'),
              sortable: true,
            },
            render: (data) => data.growerName,
            flex: 2,
          },
          {
            columnId: 'ownerId',
            header: {
              render: translate('OWNER'),
              sortable: true,
            },
            render: (data) => data.ownerName,
            flex: 3,
          },
          {
            columnId: 'modified',
            header: {
              render: translate('UPDATED'),
              sortable: true,
            },
            render: (data) => toShortDate(data.modified),
            flex: 2,
          },
          {
            columnId: 'status',
            header: {
              render: translate('SALES_STATUS'),
            },
            render: (data) => (
              <ProductOrderStatus isCancelled={data.cancelled} status={data.status} />
            ),
            width: 120,
          },
          user.userType !== UserType.INTERNAL && {
            columnId: 'menu',
            header: {
              render: null,
            },
            render: (data) => {
              const isOwner = data.ownerId === user.id;

              return (
                <ProductOrderItemMenu
                  data={data}
                  onCancelOrder={props.onCancelOrder}
                  onCreateDeliverable={
                    (isOwner && !data.isDraft) ? props.onCreateDeliverablePress : undefined
                  }
                  onDelete={isOwner && !data.shared ? props.onDeletePress : undefined}
                  onDuplicate={props.onDuplicatePress}
                  onEdit={isOwner ? props.onEditPress : undefined}
                  onViewDeliverables={isOwner ? props.onViewDeliverablesPress : undefined}
                  showCancelOrder={
                    isOwner
                    && !data.isDraft
                    && data.status === OrderStatus.ACCEPTED
                    && !data.cancelled
                  }
                />
              );
            },
            width: 72, // 40 for icon, 32 for padding of the cell
          },
        ]}
        currentPage={pageOptions.page + 1}
        data={productOrders}
        defaultColumnFilters={['growerId', 'ownerId', 'status']}
        defaultFilters={defaultFilters}
        filterOptions={columnCategories}
        isLoading={isFetching}
        noDataMessage={isLoading ? ' ' : translate('NO_PRODUCT_ORDERS')}
        noFilters={false}
        onPageChange={(page: number) => {
          setPageOptions({ ...pageOptions, page: page - 1 });
        }}
        onSort={handleSort}
        onUpdateFilter={(filter) => {
          setPageOptions({ ...pageOptions, page: 0, filter });
        }}
        rowDetail={(rowData) => (
          <>
            <ViewRow>
              <DataPoint
                flex
                label={translate<string>('NAME')}
                testID="data-point-name"
              >
                {rowData.name}
              </DataPoint>
              <DataPoint
                flex
                label={translate<string>('CUSTOMER')}
                testID="data-point-grower"
              >
                {rowData.growerName}
              </DataPoint>
              <DataPoint
                flex
                label={translate<string>('OWNER')}
                testID="data-point-owner"
              >
                {rowData.ownerName}
              </DataPoint>
              <DataPoint
                flex
                label={translate<string>('UPDATED')}
                testID="data-point-modified"
              >
                {new Date(rowData.modified).toLocaleString()}
              </DataPoint>
            </ViewRow>
            <VSpacer size="8" />
            <ViewRow>
              <DataPoint
                flex
                label={translate<string>('SALES_STATUS')}
                testID="data-point-status"
              >
                {renderSalesStatus(rowData)}
              </DataPoint>
              <DataPoint
                flex
                label={translate<string>('LAST_DELIVERABLE_SENT')}
                testID="data-point-deliverable"
              >
                {!rowData.shared ? translate<string>('NOT_APPLICABLE') : new Date(rowData.shared).toLocaleString()}
              </DataPoint>
              <DataPoint
                flex
                label={translate<string>('LOCATION')}
                testID="data-point-location"
              >
                {rowData.location?.locationName ?? translate<string>('CORPORATE_LOCATION')}
              </DataPoint>
              <DataPoint
                flex
                label={translate<string>('CROP_YEAR')}
                testID="data-point-cropyear"
              >
                {rowData.cropYear}
              </DataPoint>
            </ViewRow>
            {hasBusinessCentralIntegration && (
              <>
                <VSpacer size="8" />
                <ViewRow>
                  <DataPoint
                    flex
                    label={translate<string>('EXTERNAL_ID')}
                    testID="data-point-external-id"
                  >
                    <ExternalDisplayIdText externalDisplayId={rowData.externalDisplayId} />
                  </DataPoint>
                </ViewRow>
              </>
            )}
          </>
        )}
        tableId="productOrder"
        tableRowId="productOrderListView"
        testID="productOrderListTable"
        totalPages={(productOrdersResponse?.lastPage ?? 0) + 1}
        totalResults={productOrdersResponse?.total ?? 0}
      />
    </ScrollView>
  );
}
