import { UserType } from '@shared/enums';
import { ApiBusinessContact, BusinessEndpoint } from '@shared/interfaces/api';
import { validateEmail, validatePhoneNumber } from '@shared/utils/validators';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { getBusinessRoute, Routes, Sizing } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { useHistory, useLocation } from '../router';
import { Alert, Button, Card, Input, Select, Spinner, Text } from '../ui-components';
import { QueryUtility, ValidationUtility } from '../utilities';
import { BusinessApi, BusinessLocationApi } from '../utilities/api';
import BusinessContactForm from './BusinessForm/BusinessContactFormFields';

const styles = StyleSheet.create({
  base: {
    flex: 1,
    overflow: 'hidden',
    marginTop: Sizing.BASE_SPACING,
    paddingTop: Sizing.BASE_SPACING,
  },
  container: { flexDirection: 'row' },
  card: {
    flex: 1,
    marginTop: Sizing.BASE_SPACING,
  },
  header: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
    marginLeft: Sizing.HALF_SPACING,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  lastButton: { marginLeft: Sizing.BASE_SPACING },
  input: { },
  fullRow: { flexDirection: 'row' },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const determineIsEditPage = (businessId?: string): boolean => !_.isEmpty(businessId);

export const BusinessFormPage = ({ match }) => {
  const [translate] = useTranslation(['businesses', 'common']);
  const history = useHistory();
  const { user, currentBusinessId, updateBusinessList } = useAuthentication();
  const { business: businessId }: { business: string } = match.params;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formInitErrorKey, setFormInitErrorKey] = useState('');

  const { search: searchParams } = useLocation();
  const { redirectTo } = QueryUtility.getQueryParams(searchParams);

  const initialEditMode = determineIsEditPage(businessId);
  const [isEditPage, setEditPage] = useState(determineIsEditPage(businessId));
  const [fetchingBusiness, setFetchingBusiness] = useState(initialEditMode);

  const [businessName, setBusinessName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');

  const [allCountryList, setAllCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contactInitialValues = {
    contactName: null,
    contactEmail: null,
    contactTelephone: null,
    isDeliverableDefault: false,
  };

  const [primaryContact, setPrimaryContact] = useState<ApiBusinessContact>({
    ...contactInitialValues,
  });
  const [secondaryContact, setSecondaryContact] = useState<ApiBusinessContact>(
    contactInitialValues,
  );

  const redirectUser = useCallback(() => {
    if (!_.isEmpty(redirectTo)) {
      history.push(redirectTo);
    } else if (user?.userType === UserType.INTERNAL && !currentBusinessId) {
      history.push(Routes.USER_LIST);
    } else if (user?.userType === UserType.BUSINESS && !_.isEmpty(user.businessId)) {
      history.push(getBusinessRoute(user.businessId));
    } else {
      history.push(Routes.USER_LIST);
    }
  }, [currentBusinessId, history, redirectTo, user]);

  // Initialize the form and load the list of supported countries on mount
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setLoading(true);
        const countries = await BusinessLocationApi.getCountries();
        setAllCountryList(countries);
      } catch (err) {
        setError('UNEXPECTED_ERROR');
      } finally {
        setLoading(false);
      }
    };
    fetchFormData();
  }, []);

  useEffect(() => {
    const secondaryExists = Object.values(secondaryContact).some((v) => !!v);
    const removeSecondary = (
      !secondaryContact.contactEmail
      && !secondaryContact.contactName
      && !secondaryContact.contactTelephone
    );

    if (secondaryExists && removeSecondary) {
      setSecondaryContact(contactInitialValues);
    }
  }, [secondaryContact, contactInitialValues]);

  // Update the isFormLoading which displays a loading indicator for the user
  // If country list is still loading, or the form is in edit mode and is
  // still fetching the location, it's true
  useEffect(() => {
    const formDataFetching = _.isEmpty(allCountryList);
    const isBusinessDataFetching = isEditPage && fetchingBusiness;

    setLoading(formDataFetching && isBusinessDataFetching);
  }, [allCountryList, isEditPage, fetchingBusiness]);

  useEffect(() => {
    if (currentBusinessId) {
      if (businessId as string !== currentBusinessId as string) {
        redirectUser();
      }
    }
  }, [businessId, currentBusinessId, redirectUser]);

  useEffect(() => {
    const isEdit: boolean = determineIsEditPage(businessId);
    setEditPage(isEdit);
  }, [businessId]);

  useEffect(() => {
    const getBusinessData = async () => {
      try {
        setLoading(true);
        setFetchingBusiness(true);
        const businessData = await BusinessApi.getBusiness(businessId);
        setBusinessName(businessData.businessName);
        setContactEmail(businessData.contactEmail);
        setTelephone(businessData.telephone);
        setAddress1(businessData.address1);
        setAddress2(businessData.address2);
        setCity(businessData.city);
        setPostalCode(businessData.postalCode);
        setCounty(businessData.county);

        const businessCountry = allCountryList.find((c) => c.name === businessData.country);
        if (businessCountry) {
          setSelectedCountry(businessCountry);

          setState(businessData.state);
          setCountry(businessData.country);
        }
        // this will need to be updated once front end work is done
        const primaryContactData = businessData.contacts[0];
        const secondaryContactData = businessData.contacts[1];
        if (primaryContactData) {
          setPrimaryContact(primaryContactData);
        }
        if (secondaryContactData) {
          setSecondaryContact(secondaryContactData);
        }
      } catch (err) {
        setFormInitErrorKey('ERROR_LOADING_BUSINESS');
      }
      setFetchingBusiness(false);
      setLoading(false);
    };
    if (isEditPage && !_.isEmpty(allCountryList)) {
      void getBusinessData();
    }
  }, [isEditPage, businessId, allCountryList]);

  const submitForm = async () => {
    const formData: BusinessEndpoint.Create.Request = {
      businessName,
      contactEmail,
      telephone,
      address1,
      address2,
      city,
      county,
      state,
      postalCode,
      country,
      contacts: [primaryContact],
    };
    if (Object.values(secondaryContact).some((v) => !!v)) {
      formData.contacts.push(secondaryContact);
    }
    try {
      if (businessId) {
        await BusinessApi.updateBusiness(businessId, formData);
      } else {
        await BusinessApi.createBusiness(formData);
      }
      updateBusinessList();

      redirectUser();
    } catch (err) {
      if (err && (err as { code: string }).code === 'already-existing-business') {
        setError('BUSINESS_NAME_ALREADY_USED');
      } else {
        setError('ERROR_SAVING_BUSINESS');
      }
    }
  };

  const formValid = () => {
    const requiredFields = [
      businessName,
      contactEmail,
      address1,
      telephone,
      city,
      state,
      postalCode,
      country,
      primaryContact.contactName,
      primaryContact.contactEmail,
      primaryContact.contactTelephone,
    ];
    if (Object.values(secondaryContact).some((v) => !!v)) {
      requiredFields.push(
        secondaryContact.contactName,
        secondaryContact.contactEmail,
        secondaryContact.contactTelephone,
      );
    }

    const formValidations = [
      requiredFields.every((v) => !!v),
      ValidationUtility.validateBusinessName(businessName),
      validateEmail(contactEmail),
      validateEmail(primaryContact.contactEmail),
      !(secondaryContact.contactEmail)
      || validateEmail(secondaryContact.contactEmail),
      !telephone || validatePhoneNumber(telephone),
      validatePhoneNumber(primaryContact.contactTelephone),
      !(secondaryContact.contactTelephone)
      || validatePhoneNumber(secondaryContact.contactTelephone),
    ];

    return formValidations.every((v) => !!v);
  };

  const Header = (props) => (
    <View {...props}>
      <Text style={styles.header}>{translate('BUSINESS_DETAILS') as string}</Text>
    </View>
  );

  const Footer = () => (
    <View style={styles.footer}>
      <Button
        nativeID="cancel-button"
        onPress={redirectUser}
        status="secondary"
      >
        {translate('CANCEL') as string}
      </Button>
      <Button
        disabled={!formValid()}
        nativeID="save-button"
        onPress={submitForm}
        status="primary"
        style={styles.lastButton}
      >
        {translate('SAVE') as string}
      </Button>
    </View>
  );

  /**
   * Invoked when the user changes the currently selected country.
   * Sets the selectedCountry and resets the stateSearchValue and state values
   * @param selection
   */
  const onCountrySelectChanged = (selection) => {
    setSelectedCountry(allCountryList.find((c) => c.name === selection.value));
    setState('');
  };

  // When countries are loaded and the selected country changes, initialize that
  // state selector and county/country fields
  useEffect(() => {
    if (!_.isEmpty(allCountryList) && !_.isNil(selectedCountry)) {
      // Update the state input with the selected countries' states and reset the state value
      const displayStates = selectedCountry.states;
      setStateList(displayStates);

      // Store the countries name in the country field when a country is selected
      setCountry(selectedCountry.name);
    }
  }, [allCountryList, selectedCountry]);

  if (loading) {
    return (
      <View style={styles.base}>
        <Card
          footer={<Footer />}
          header={<Header />}
          style={{ marginTop: Sizing.BASE_SPACING }}
          testID="loading-card"
        >
          <View style={styles.spinnerContainer}>
            <Spinner size="giant" />
          </View>
        </Card>
      </View>
    );
  }

  return (
    <ScrollView nativeID="business-creation-view" style={styles.base} testID="business-creation-view">
      {!!error && <Alert level="danger" text={translate(error)} />}
      {!!formInitErrorKey && <Alert level="danger" text={translate(formInitErrorKey)} />}
      {!formInitErrorKey && (
        <View style={styles.container}>
          <Card footer={<Footer />} header={<Header />} style={styles.card} testID="business-card">
            <Input
              caption={translate('REQUIRED') as string}
              label={translate('BUSINESS_NAME') as string}
              nativeID="business-name"
              onChangeText={(nextValue) => setBusinessName(nextValue)}
              status={(businessName && !ValidationUtility.validateBusinessName(businessName) && 'danger') || undefined}
              style={styles.input}
              testID="business-name"
              value={businessName}
            />
            <Input
              caption={(contactEmail && !validateEmail(contactEmail) && translate('VALID_EMAIL_REQUIRED') as string) || translate('REQUIRED') as string}
              label={translate('BUSINESS_EMAIL') as string}
              nativeID="business-email"
              onChangeText={(nextValue) => setContactEmail(nextValue)}
              status={(contactEmail && !validateEmail(contactEmail) && 'danger') || undefined}
              style={styles.input}
              testID="business-email"
              value={contactEmail}
            />
            <Input
              caption={(telephone && !validatePhoneNumber(telephone) && translate('VALID_PHONE_REQUIRED') as string) || translate('REQUIRED') as string}
              label={translate('BUSINESS_TELEPHONE') as string}
              nativeID="business-phone"
              onChangeText={(nextValue) => setTelephone(nextValue)}
              status={(telephone && !validatePhoneNumber(telephone) && 'danger') || undefined}
              style={styles.input}
              testID="business-phone"
              value={telephone}
            />
            <Input
              caption={translate('REQUIRED') as string}
              label={translate('ADDRESS1') as string}
              nativeID="business-address1"
              onChangeText={(nextValue) => setAddress1(nextValue)}
              style={styles.input}
              testID="business-address1"
              value={address1}
            />
            <Input
              label={translate('ADDRESS2') as string}
              nativeID="business-address2"
              onChangeText={(nextValue) => setAddress2(nextValue)}
              style={styles.input}
              testID="business-address2"
              value={address2}
            />
            <Input
              caption={translate('REQUIRED') as string}
              label={translate('CITY') as string}
              nativeID="business-city"
              onChangeText={(nextValue) => setCity(nextValue)}
              style={styles.input}
              testID="business-city"
              value={city}
            />
            <Select
              caption={translate('REQUIRED') as string}
              label={`${translate('COUNTRY')}`}
              nativeID="business-country-selector"
              onSelect={(selection) => onCountrySelectChanged(selection)}
              options={allCountryList.map((value) => ({
                value: value.name,
                label: value.name,
                selected: selectedCountry === value,
              }))}
              testID="business-country-selector"
            />
            {
              _.isEmpty(stateList)
                ? (
                  <Input
                    caption={translate('REQUIRED') as string}
                    disabled={_.isEmpty(country)}
                    label={`${translate('STATE')}`}
                    nativeID="business-state-input"
                    onChangeText={(nextValue) => setState(nextValue)}
                    style={styles.input}
                    testID="business-state-input"
                    value={state}
                  />
                ) : (
                  <Select
                    caption={translate('REQUIRED') as string}
                    label={`${translate('STATE')}`}
                    nativeID="business-state-selector"
                    onSelect={(selectedState) => setState(selectedState.value)}
                    options={stateList.map((value) => ({
                      value: value.name,
                      label: value.name,
                      selected: value.name === state,
                    }))}
                    testID="business-state-selector"
                  />
                )
            }
            <Input
              label={translate('COUNTY') as string}
              nativeID="business-county"
              onChangeText={(nextValue) => setCounty(nextValue)}
              style={styles.input}
              testID="business-county"
              value={county}
            />
            <Input
              caption={translate('REQUIRED') as string}
              label={translate('ZIPCODE') as string}
              nativeID="business-zip-code"
              onChangeText={(nextValue) => setPostalCode(nextValue)}
              style={styles.input}
              testID="business-zip-code"
              value={postalCode}
            />
            <BusinessContactForm
              data={primaryContact}
              inputStyles={styles.input}
              nativeID="business-primary-contact"
              onDataChanged={(field, value) => setPrimaryContact({
                ...primaryContact, [field]: value,
              })}
            />
            <BusinessContactForm
              data={secondaryContact}
              inputStyles={styles.input}
              nativeID="business-secondary-contact"
              onDataChanged={(field, value) => setSecondaryContact({
                ...secondaryContact, [field]: value,
              })}
            />
          </Card>
          <View style={{ flex: 1 }} />
        </View>
      )}
    </ScrollView>
  );
};
