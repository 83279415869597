import { Card, CheckBox, IndexPath, Input } from '@ui-kitten/components';
import React, { FunctionComponent, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  HSpacer,
  Icon,
  IconName,
  IconNames,
  IconButton,
  Select,
  SelectItem,
  Text,
  TextLink,
} from '@design';
import { Appearance, Appearances, Designs } from '@design/Button/Button';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { Size, Sizes } from '@theme/variant-interfaces/Size';
import { GrowersDarkTheme } from '@theme/GrowersDarkTheme';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  instance: {
    marginVertical: 2,
  },
  contentWidth: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    margin: 2,
    justifyContent: 'center',
  },
});

const IconButtonKnobs: FunctionComponent = () => {
  const [icon, setIcon] = useState<IconName>('Flag');
  const [appearance, setAppearance] = useState<Appearance>('filled');
  const [size, setSize] = useState<Size>('medium');
  const [status, setStatus] = useState<Status>('primary');
  const [disable, setDisable] = useState(false);

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Select
          label="appearance"
          onSelect={(ip: IndexPath | IndexPath[]) => setAppearance(
            Appearances[(ip as IndexPath).row],
          )}
          selectedIndex={new IndexPath(Appearances.findIndex((a) => a === appearance))}
          testID="button-demo-appearance-selector"
          value={appearance}
        >
          {Appearances.map((a, i) => (
            <SelectItem key={a} testID={`button-demo-appearance-dropdown-value-${i}`} title={a} />
          ))}
        </Select>
        <Select
          label="size"
          onSelect={(ip: IndexPath | IndexPath[]) => setSize(Sizes[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(Sizes.findIndex((s) => s === size))}
          testID="button-demo-size-selector"
          value={size}
        >
          {Sizes.map((s, i) => (
            <SelectItem key={s} testID={`button-demo-size-dropdown-value-${i}`} title={s} />
          ))}
        </Select>
        <Select
          label="status"
          onSelect={(ip: IndexPath | IndexPath[]) => setStatus(Statuses[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
          testID="button-demo-status-selector"
          value={status}
        >
          {Statuses.map((s, i) => (
            <SelectItem key={s} testID={`button-demo-status-dropdown-value-${i}`} title={s} />
          ))}
        </Select>
        <View>
          <CheckBox
            checked={disable}
            onChange={setDisable}
          >
            disabled
          </CheckBox>
        </View>
        <Select
          label="icon"
          onSelect={(ip: IndexPath | IndexPath[]) => setIcon(IconNames[(ip as IndexPath).row - 1])}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === icon) + 1)}
          testID="button-demo-icon-selector"
          value={icon || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="button-demo-icon-dropdown-value" title="None" />,
            ...IconNames.map((n, i) => (
              <SelectItem key={n} testID={`button-demo-icon-name-dropdown-value-${i}`} title={n} />
            )),
          ]}
        </Select>
      </View>
      <DemoContainer>
        <DemoBlock label="Icon Button with options" style={{ marginHorizontal: 'auto' }}>
          <IconButton
            appearance={appearance}
            disabled={disable}
            size={size}
            status={status}
            testID="test-button"
          >
            {icon}
          </IconButton>
        </DemoBlock>
      </DemoContainer>
    </>
  );
};

const ButtonKnobs: FunctionComponent = () => {
  const [text, setText] = useState('Button');
  const [appearance, setAppearance] = useState<Appearance>('filled');
  const [size, setSize] = useState<Size>('medium');
  const [status, setStatus] = useState<Status>('primary');
  const [disable, setDisable] = useState(false);
  const [capitalize, setCapitalize] = useState(false);
  const [iconLeft, setIconLeft] = useState<IconName>(null);
  const [iconRight, setIconRight] = useState<IconName>(undefined);

  return (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Input label="Text" onChangeText={setText} value={text} />
        <Select
          label="appearance"
          onSelect={(ip: IndexPath | IndexPath[]) => setAppearance(
            Appearances[(ip as IndexPath).row],
          )}
          selectedIndex={new IndexPath(Appearances.findIndex((a) => a === appearance))}
          testID="button-demo-knob-appearance-selector"
          value={appearance}
        >
          {Appearances.map((a, i) => (
            <SelectItem key={a} testID={`button-knob-appearance-dropdown-value-${i}`} title={a} />
          ))}
        </Select>
        <Select
          label="size"
          onSelect={(ip: IndexPath | IndexPath[]) => setSize(Sizes[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(Sizes.findIndex((s) => s === size))}
          testID="button-demo-knob-size-selector"
          value={size}
        >
          {Sizes.map((s, i) => (
            <SelectItem key={s} testID={`button-knob-size-dropdown-value-${i}`} title={s} />
          ))}
        </Select>
        <Select
          label="status"
          onSelect={(ip: IndexPath | IndexPath[]) => setStatus(Statuses[(ip as IndexPath).row])}
          selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
          testID="button-demo-knob-status-selector"
          value={status}
        >
          {Statuses.map((s, i) => (
            <SelectItem key={s} testID={`button-knob-status-dropdown-value-${i}`} title={s} />
          ))}
        </Select>
        <View>
          <CheckBox
            checked={disable}
            onChange={setDisable}
          >
            disabled
          </CheckBox>
          <CheckBox
            checked={capitalize}
            onChange={setCapitalize}
          >
            capitalize
          </CheckBox>
        </View>
        <Select
          label="accessoryLeft"
          onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
            IconNames[(ip as IndexPath).row - 1],
          )}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)}
          testID="button-demo-knob-accessory-left-selector"
          value={iconLeft || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="button-knob-icon-left-dropdown-value" title="None" />,
            ...IconNames.map((n, i) => (
              <SelectItem key={n} testID={`button-knob-icon-left-name-dropdown-value-${i}`} title={n} />
            )),
          ]}
        </Select>
        <Select
          label="accessoryRight"
          onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
            IconNames[(ip as IndexPath).row - 1],
          )}
          selectedIndex={new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)}
          testID="button-demo-knob-accessory-right-selector"
          value={iconRight || 'None'}
        >
          {[
            <SelectItem key="noicon" testID="button-knob-icon-right-dropdown-value" title="None" />,
            ...IconNames.map((n, i) => (
              <SelectItem key={n} testID={`button-knob-icon-right-name-dropdown-value-${i}`} title={n} />
            )),
          ]}
        </Select>
      </View>
      <DemoContainer>
        <DemoBlock label="Button with options" style={{ marginHorizontal: 'auto', backgroundColor: GrowersDarkTheme['color-warning-100'] }}>
          {text && (
            <Button
              accessoryLeft={(props) => (
                (iconLeft || null) && <Icon name={iconLeft} {...props} testID="test-button-left-icon" />
              )}
              accessoryRight={(props) => (
                (iconRight || null) && <Icon name={iconRight} {...props} testID="test-button-right-icon" />
              )}
              appearance={appearance}
              capitalize={capitalize}
              disabled={disable}
              size={size}
              status={status}
              testID="test-button"
            >
              {text}
            </Button>
          )}
          {!text && (
            <Button
              accessoryLeft={(props) => (
                (iconLeft || null) && <Icon name={iconLeft} {...props} testID="test-button-left-icon" />
              )}
              accessoryRight={(props) => (
                (iconRight || null) && <Icon name={iconRight} {...props} testID="test-button-right-icon" />
              )}
              appearance={appearance}
              capitalize={capitalize}
              disabled={disable}
              size={size}
              status={status}
              testID="test-button"
            />
          )}
        </DemoBlock>
      </DemoContainer>
    </>
  );
};

export const ButtonDemo: FunctionComponent = () => (
  <View style={{ alignSelf: 'stretch' }}>
    <Card testID="test-card">
      <DemoContainer label="appearance">
        {Appearances.map((a) => (
          <DemoBlock key={a} label={a}>
            <Button appearance={a} testID="test-button">Button</Button>
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <Card testID="test-card">
      <DemoContainer label="design">
        {Designs.map((a) => (
          <DemoBlock key={a} label={a}>
            <Button design={a} testID="test-button">Button</Button>
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <Card testID="test-card">
      <DemoContainer label="size">
        {Sizes.map((s) => (
          <DemoBlock key={s} label={s}>
            <Button size={s} testID="test-button">Button</Button>
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <Card style={{ flex: 1 }} testID="test-card">
      <DemoContainer label="status">
        {Statuses.map((s) => (
          <DemoBlock key={s} label={s}>
            <Button status={s} testID="test-button">Button</Button>
          </DemoBlock>
        ))}
      </DemoContainer>
    </Card>
    <Card testID="test-card">
      <IconButtonKnobs />
    </Card>
    <Card testID="test-card">
      <ButtonKnobs />
    </Card>
    <Card testID="test-card">
      <Button testID="test-button">Default</Button>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="tiny" testID="test-button">Tiny</Button>
        <Button disabled size="tiny" testID="test-button">Tiny</Button>
        <Button appearance="outline" size="tiny" testID="test-button">Tiny</Button>
        <Button appearance="outline" disabled size="tiny" testID="test-button">Tiny</Button>
        <Button appearance="ghost" size="tiny" testID="test-button">Tiny</Button>
        <Button appearance="ghost" disabled size="tiny" testID="test-button">Tiny</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="tiny" status="basic" testID="test-button">Tiny</Button>
        <Button disabled size="tiny" status="basic" testID="test-button">Tiny</Button>
        <Button appearance="outline" size="tiny" status="basic" testID="test-button">Tiny</Button>
        <Button appearance="outline" disabled size="tiny" status="basic" testID="test-button">Tiny</Button>
        <Button appearance="ghost" size="tiny" status="basic" testID="test-button">Tiny</Button>
        <Button appearance="ghost" disabled size="tiny" status="basic" testID="test-button">Tiny</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="small" testID="test-button">Small</Button>
        <Button disabled size="small" testID="test-button">Small</Button>
        <Button appearance="outline" size="small" testID="test-button">Small</Button>
        <Button appearance="outline" disabled size="small" testID="test-button">Small</Button>
        <Button appearance="ghost" size="small" testID="test-button">Small</Button>
        <Button appearance="ghost" disabled size="small" testID="test-button">Small</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="small" status="basic" testID="test-button">Small</Button>
        <Button disabled size="small" status="basic" testID="test-button">Small</Button>
        <Button appearance="outline" size="small" status="basic" testID="test-button">Small</Button>
        <Button appearance="outline" disabled size="small" status="basic" testID="test-button">Small</Button>
        <Button appearance="ghost" size="small" status="basic" testID="test-button">Small</Button>
        <Button appearance="ghost" disabled size="small" status="basic" testID="test-button">Small</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button capitalize size="tiny" testID="test-button">tiny caps</Button>
        <Button capitalize size="small" status="basic" testID="test-button">small caps</Button>
        <Button appearance="outline" capitalize size="medium" testID="test-button">medium caps</Button>
        <Button appearance="ghost" capitalize size="large" testID="test-button">large caps</Button>
        <Button capitalize disabled size="giant" testID="test-button">giant caps</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="medium" testID="test-button">Medium</Button>
        <Button disabled size="medium" testID="test-button">Medium</Button>
        <Button appearance="outline" size="medium" testID="test-button">Medium</Button>
        <Button appearance="outline" disabled size="medium" testID="test-button">Medium</Button>
        <Button appearance="ghost" size="medium" testID="test-button">Medium</Button>
        <Button appearance="ghost" disabled size="medium" testID="test-button">Medium</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="medium" status="basic" testID="test-button">Medium</Button>
        <Button disabled size="medium" status="basic" testID="test-button">Medium</Button>
        <Button appearance="outline" size="medium" status="basic" testID="test-button">Medium</Button>
        <Button appearance="outline" disabled size="medium" status="basic" testID="test-button">Medium</Button>
        <Button appearance="ghost" size="medium" status="basic" testID="test-button">Medium</Button>
        <Button appearance="ghost" disabled size="medium" status="basic" testID="test-button">Medium</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="large" testID="test-button">Large</Button>
        <Button disabled size="large" testID="test-button">Large</Button>
        <Button appearance="outline" size="large" testID="test-button">Large</Button>
        <Button appearance="outline" disabled size="large" testID="test-button">Large</Button>
        <Button appearance="ghost" size="large" testID="test-button">Large</Button>
        <Button appearance="ghost" disabled size="large" testID="test-button">Large</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="large" status="basic" testID="test-button">Large</Button>
        <Button disabled size="large" status="basic" testID="test-button">Large</Button>
        <Button appearance="outline" size="large" status="basic" testID="test-button">Large</Button>
        <Button appearance="outline" disabled size="large" status="basic" testID="test-button">Large</Button>
        <Button appearance="ghost" size="large" status="basic" testID="test-button">Large</Button>
        <Button appearance="ghost" disabled size="large" status="basic" testID="test-button">Large</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="giant" testID="test-button">Giant</Button>
        <Button disabled size="giant" testID="test-button">Giant</Button>
        <Button appearance="outline" size="giant" testID="test-button">Giant</Button>
        <Button appearance="outline" disabled size="giant" testID="test-button">Giant</Button>
        <Button appearance="ghost" size="giant" testID="test-button">Giant</Button>
        <Button appearance="ghost" disabled size="giant" testID="test-button">Giant</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button size="giant" status="basic" testID="test-button">Giant</Button>
        <Button disabled size="giant" status="basic" testID="test-button">Giant</Button>
        <Button appearance="outline" size="giant" status="basic" testID="test-button">Giant</Button>
        <Button appearance="outline" disabled size="giant" status="basic" testID="test-button">Giant</Button>
        <Button appearance="ghost" size="giant" status="basic" testID="test-button">Giant</Button>
        <Button appearance="ghost" disabled size="giant" status="basic" testID="test-button">Giant</Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          testID="test-button"
        >
          Left Icon
        </Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          testID="test-button"
        >
          Right Icon
        </Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          testID="test-button"
        >
          Both Icons
        </Button>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          appearance="outline"
          testID="test-button"
        >
          Both Icons
        </Button>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          appearance="ghost"
          testID="test-button"
        >
          Both Icons
        </Button>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          disabled
          testID="test-button"
        >
          Both Icons
        </Button>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          appearance="outline"
          disabled
          testID="test-button"
        >
          Both Icons
        </Button>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          appearance="ghost"
          disabled
          testID="test-button"
        >
          Both Icons
        </Button>
      </View>
      <View style={[styles.instance, styles.contentWidth]}>
        <Button
          accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          accessoryRight={(props) => <Icon {...props} name="Star" testID="test-button-icon" />}
          size="small"
          testID="test-button"
        >
          Small Both Icons
        </Button>
      </View>
      <View style={[styles.instance, styles.button, styles.contentWidth]}>
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} disabled testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="outline" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="outline" disabled testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="ghost" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="ghost" disabled testID="test-button" />
      </View>
      <View style={[styles.instance, styles.button, styles.contentWidth]}>
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} status="basic" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} disabled status="basic" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="outline" status="basic" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="outline" disabled status="basic" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="ghost" status="basic" testID="test-button" />
        <Button accessoryLeft={(props) => <Icon {...props} name="Star" testID="test-button-icon" />} appearance="ghost" disabled status="basic" testID="test-button" />
      </View>
    </Card>
    <Card testID="test-card">
      <View>
        <Text>
          Defect Scenario: Buttons with size=giant and appearance=outline
          are not correctly sized when rendered in an absolutely positioned container.
        </Text>
        <View style={{
          flexDirection: 'row',
          height: 24,
          margin: 56,
          right: 0,
          bottom: 0,
          justifyContent: 'flex-end',
        }}
        >
          <TextLink>Cancel</TextLink>
          <HSpacer size="10" />
          <Button
            appearance="outline"
            size="giant"
            status="basic"
            testID="test-button"
          >
            Incorrect Size
          </Button>
          <Button
            appearance="filled"
            size="giant"
            status="basic"
            testID="test-button"
          >
            Correct Size
          </Button>
          <HSpacer size="7" />
          <Button
            size="giant"
            testID="test-button"
          >
            Next
          </Button>
        </View>
      </View>
    </Card>
  </View>
);
