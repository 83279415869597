import {
  Button,
  FilterCategory,
  FilterDefault,
  FilterTable,
  HSpacer,
  Icon,
  MenuItem,
  OverflowMenu,
  SortDirection,
  Text,
  TextLink,
  ToastProps,
  useToast,
} from '@design';
import { EnumTranslations, UserRole } from '@shared/enums';
import { ApiCropLogic, ApiCropLogicListItem, CropLogicEndpoint } from '@shared/interfaces/api';
import { FarmUtility, userIsInternal } from '@shared/utils';
import { Spinner } from '@ui-kitten/components';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { Selections } from 'src/components/DesignSystem/Filter/Filter';
import { Routes } from '../../../../constants';
import { QueryKeys } from '../../../../constants/QueryKeys';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessLocationList } from '../../../../hooks/useBusinessLocationList';
import { usePriceTypeList } from '../../../../hooks/usePriceTypeList';
import { useHistory } from '../../../../router/index';
import { StringUtility } from '../../../../utilities';
import { CropLogicApi } from '../../../../utilities/api';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { ChangeProgramStatusDialog } from './ChangeProgramStatusDialog';
import { ProgramListTabRowDetails } from './ProgramListTabRowDetails';

const styles = StyleSheet.create({
  spinnerContainer: {
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 999,
  },
});
interface PageOption {
  filter: {},
  page: number,
  sort: {
    name: CropLogicEndpoint.List.Sort,
    direction: SortDirection,
  },
}

interface ProgramToastError {
  text: string,
  err: Error,
}

const defaultFilters = ['isActive', 'cropType', 'userAccountId'];

const defaultPageOptions: PageOption = {
  page: 0,
  sort: {
    name: CropLogicEndpoint.List.Sort.updatedAt,
    direction: 'DESC',
  },
  filter: { isActive: ['true'] },
};

const defaultAppliedFilters: Map<string, Selections> = new Map([
  ['isActive', { true: true }],
]);

const filterOptions: FilterCategory[] = [
  {
    columnLabel: 'Status',
    columnKey: 'isActive',
    columns: [{ label: 'Active', id: 'true' }, { label: 'Inactive', id: 'false' }],
  },
  {
    columnLabel: 'Crop',
    columnKey: 'cropType',
    columns: [],
  },
  {
    columnLabel: 'User',
    columnKey: 'userAccountId',
    columns: [],
  },
];

const sortColDictionary = {
  logicName: CropLogicEndpoint.List.Sort.logicName,
  cropType: CropLogicEndpoint.List.Sort.cropType,
};

export const ProgramListTab = () => {
  const history = useHistory();
  const { user, currentBusinessId } = useAuthentication();
  const { createToast } = useToast();
  const queryClient = useQueryClient();
  const scrollRef = useRef<ScrollView>();
  const [translate] = useTranslation(['common', 'programs', 'prepare']);
  const [programs, setPrograms] = useState<(ApiCropLogicListItem & RowMeta)[]>([]);
  const [pageOptions, setPageOptions] = useState(defaultPageOptions);
  const [resultCount, setResultCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [programListError, setProgramListError] = useState<ProgramToastError>(null);
  const [columnCategories, setColumnCategories] = useState<FilterCategory[]>([]);
  const [programsLoaded, setProgramsLoaded] = useState(false);
  const [changeProgramStatus, setChangeProgramStatus] = (
    useState<ApiCropLogicListItem | null>(null)
  );
  const [isDuplicating, setIsDuplicating] = useState(false);
  const { businessLocations } = useBusinessLocationList(currentBusinessId);
  const { defaultPriceTypeId } = usePriceTypeList();

  const userIsSuper = userIsInternal(user);

  const getCropLogics = async () => CropLogicApi
    .getAllCropLogicsForBusiness(currentBusinessId, [true, false]);

  useQuery<CropLogicEndpoint.List.Response, Error>(
    [QueryKeys.PROGRAM_LIST_BUSINESS_CROPS, programsLoaded, currentBusinessId],
    getCropLogics, {
      enabled: programsLoaded,
      onError: (err) => {
        setColumnCategories(filterOptions);
        setProgramListError({ err, text: 'Getting program list' });
      },
      onSuccess: (cropLogics) => {
        if (!programsLoaded || cropLogics.data.length === 0) return;
        const newColumnCategories = _.cloneDeep(filterOptions);
        const userList = [];
        const cropList = [];

        const newProgramCropTypes = cropLogics.data.filter((cropLogic) => {
          const uniqueCropType = !cropList.includes(cropLogic.cropSubType);
          if (uniqueCropType) cropList.push(cropLogic.cropSubType);
          return uniqueCropType;
        }).sort((a, b) => a.cropType.localeCompare(b.cropType)).map((cropLogic) => ({
          label: `${EnumTranslations[cropLogic.cropType]} | ${EnumTranslations[cropLogic.cropSubType]}`,
          id: `${cropLogic.cropType},${cropLogic.cropSubType}`,
        }));

        const newProgramUsers = cropLogics.data.filter((cropLogic) => {
          const uniqueUser = !userList.includes(cropLogic.userAccount?.fullName);
          if (uniqueUser) userList.push(cropLogic.userAccount?.fullName);
          return uniqueUser;
        }).map((cropLogic) => ({
          label: cropLogic.userAccount?.fullName ?? '',
          id: cropLogic.userAccountId,
        }));

        newColumnCategories.find((category) => category.columnKey === 'cropType').columns = newProgramCropTypes;
        newColumnCategories.find((category) => category.columnKey === 'userAccountId').columns = newProgramUsers;
        setColumnCategories(newColumnCategories);
      },
    },
  );

  useEffect(() => {
    setColumnCategories(filterOptions);
    setProgramsLoaded(false);
    setPageOptions(defaultPageOptions);
  }, [currentBusinessId]);

  const getPrograms = () => {
    const appliedFilters: any = { ...pageOptions.filter };
    // when no isActive filters, default to display both active and inactive
    if (!appliedFilters.isActive) {
      appliedFilters.isActive = ['true', 'false'];
    }
    // separate crop type and crop subtype
    if (appliedFilters.cropType) {
      const newCropSubTypeFilters = [];
      const newCropTypeFilters = [];
      appliedFilters.cropType
        .forEach((cropType) => {
          newCropTypeFilters.push(cropType.split(',')[0]);
          newCropSubTypeFilters.push(cropType.split(',')[1]);
        });
      appliedFilters.cropSubType = newCropSubTypeFilters;
      appliedFilters.cropType = newCropTypeFilters;
    }
    return CropLogicApi.listCropLogic({
      page: pageOptions.page,
      sort: pageOptions.sort.name,
      sortDesc: pageOptions.sort.direction === 'DESC',
      businessId: userIsSuper ? currentBusinessId : undefined,
      ...appliedFilters,
    });
  };

  const {
    data: programsData,
    isFetching: isProgramFetching,
    isLoading: isProgramLoading,
    isError: isProgramError,
  } = useQuery<CropLogicEndpoint.List.Response, Error>(
    [QueryKeys.PROGRAM_LIST, pageOptions, currentBusinessId],
    getPrograms, {
      onError: (err) => {
        setPageOptions({ ...pageOptions, page: 0 });
        setProgramListError({ err, text: 'Getting Programs' });
      },
      onSuccess: () => {
        setProgramsLoaded(true);
      },
    },
  );

  const toastProps: ToastProps = {
    children: () => (
      <View>
        <Text>
          {translate('UNEXPECTED_ERROR', { error: programListError?.err?.message }) as string}
        </Text>
        <Text>{programListError.text}</Text>
      </View>
    ),
    status: 'danger',
    duration: 5000,
    testID: 'toast-content-element',
  };

  useEffect(() => {
    if (programsData) {
      const data = programsData.data.map((program, idx) => ({
        ...program,
        rowId: `row:${idx}|${program.id}|`,
        hasDetails: true,
      }));
      setPrograms(data);
      setPageCount(programsData.lastPage + 1);
      setResultCount(programsData.total);
      if (scrollRef?.current) {
        scrollRef.current?.scrollTo({
          y: 0,
          animated: true,
        });
      }
    }
    if (isProgramError && programListError) {
      createToast(toastProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProgramError, programListError, programsData, isProgramLoading]);

  const generateUnusedName = async (copyName: string) => {
    const { data } = await CropLogicApi.listCropLogic({
      businessId: currentBusinessId,
      logicName: [`${copyName}%`],
    });

    if (!data.length) {
      return copyName;
    }
    const namesInUse = data.map((p) => p.logicName);
    return StringUtility.incrementTrailingNumber(copyName, namesInUse);
  };

  const handleDuplicate = async (program: ApiCropLogic) => {
    try {
      setIsDuplicating(true);
      const {
        logicName,
        priceTypeId,
        scenarioParameters,
      } = program;

      const { businessLocationId } = scenarioParameters || {};
      const hasLocation = businessLocations.some(
        (businessLocation) => businessLocation.id === businessLocationId,
      ) || !businessLocationId;

      const newName = await generateUnusedName(
        translate<string>('COPY_OF_PROGRAM_NAME', { programName: logicName }),
      );

      const newProgram: ApiCropLogic = {
        ...program,
        logicName: newName,
        priceTypeId: hasLocation ? priceTypeId : defaultPriceTypeId,
        scenarioParameters: scenarioParameters ? {
          ...scenarioParameters,
          businessLocationId: hasLocation ? businessLocationId : null,
        } : null,
        userAccountId: user.id,
      };

      await CropLogicApi.createCropLogic(FarmUtility.stripCropLogic(newProgram));
      await queryClient.invalidateQueries(QueryKeys.PROGRAM_LIST);

      createToast({
        children: translate<string>('PROGRAM_SUCCESSFULLY_DUPLICATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
    } catch {
      createToast({
        children: translate<string>('UNEXPECTED_ERROR'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    } finally {
      setIsDuplicating(false);
    }
  };

  const handleSort = (column: string, direction: SortDirection) => {
    const name = sortColDictionary[column];
    setPageOptions({ ...pageOptions, page: 0, sort: { name, direction } });
  };

  const onChangeProgramStatus = (updatedProgram: ApiCropLogic) => {
    setChangeProgramStatus(null);
    queryClient.invalidateQueries(QueryKeys.PROGRAM_LIST);
    setPrograms((prevPrograms) => prevPrograms.map((prevProgram) => {
      if (prevProgram.id !== updatedProgram.id) {
        return prevProgram;
      }
      return {
        ...prevProgram,
        ...updatedProgram,
      };
    }));
  };

  const columns: IColumn<(ApiCropLogicListItem & RowMeta)>[] = [
    {
      columnId: 'logicName',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (program) => (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => history.push(Routes.PROGRAM_EDIT.replace(/:id/, program.id))}
          wrap
        >
          {program.logicName}
        </TextLink>
      ),
      flex: 1,
    },
    {
      columnId: 'userName',
      header: {
        render: translate('USER'),
      },
      render: (program) => program.userAccount.fullName,
      flex: 1,
    },
    {
      columnId: 'cropType',
      header: {
        render: translate('CROP_TYPE'),
        sortable: true,
      },
      render: (program) => `${translate(program.cropType)} | ${translate(program.cropSubType)}`,
      flex: 1,
    },
    {
      columnId: 'menu',
      header: {
        render: null,
      },
      render: (program) => {
        const canSeeMenu = !userIsSuper;
        const canEditItem = user?.id === program?.userAccountId;
        const canDuplicate = !userIsSuper;
        const canChangeActiveStatus = program.userAccountId === user.id
          || user.userRole === UserRole.BUSINESS_ADMIN;

        return (
          <>
            {canSeeMenu && (
              <OverflowMenu testID="overflow-menu">
                {(program.isActive && canEditItem)
                  ? (
                    <MenuItem
                      onPress={() => history.push(Routes.PROGRAM_EDIT.replace(/:id/, program.id))}
                      testID="edit-crop-logic"
                      title={translate('EDIT') as string}
                    />
                  ) : null}
                {canChangeActiveStatus ? (
                  <MenuItem
                    onPress={() => setChangeProgramStatus(program)}
                    testID="change-product-status"
                    title={translate(!program.isActive ? 'ACTIVATE' : 'DEACTIVATE') as string}
                  />
                ) : null}
                {canDuplicate ? (
                  <MenuItem
                    onPress={() => handleDuplicate(program)}
                    testID="program-list-kabob-menu-item-duplicate"
                    title={translate<string>('DUPLICATE')}
                  />
                ) : null}
              </OverflowMenu>
            )}
          </>
        );
      },
      flex: 0.1,
    },
  ];

  return (
    <>
      { isDuplicating && (
        <View style={styles.spinnerContainer}>
          <Spinner size="medium" />
        </View>
      )}
      <ScrollView ref={scrollRef}>
        <FilterTable<FilterDefault, ApiCropLogicListItem & RowMeta>
          accessoryRight={!userIsSuper && (
            <View style={{ flexDirection: 'row' }}>
              <HSpacer size="5" />
              <Button
                accessoryLeft={(iconProps) => (
                  <Icon name="Plus" testID="create-crop-logic-button-icon" {...iconProps} />
                )}
                appearance="filled"
                onPress={() => history.push(Routes.PROGRAM_CREATE)}
                size="medium"
                status="primary"
                testID="create-crop-logic-button"
              >
                {translate<string>('CREATE_PROGRAM')}
              </Button>
            </View>
          )}
          columns={columns}
          currentPage={pageOptions.page + 1}
          data={programs}
          defaultColumnFilters={defaultFilters}
          defaultFilters={defaultAppliedFilters}
          filterOptions={columnCategories}
          isLoading={isProgramFetching}
          noFilters={false}
          onPageChange={(pg: number) => {
            setPageOptions({ ...pageOptions, page: pg - 1 });
          }}
          onSort={handleSort}
          onUpdateFilter={(filter) => {
            setPageOptions({ ...pageOptions, page: 0, filter });
          }}
          rowDetail={(rowDetail) => <ProgramListTabRowDetails {...rowDetail} />}
          tableId="program"
          tableRowId="programListView"
          testID="program-table"
          totalPages={pageCount}
          totalResults={resultCount}
        />
        {/* Controls dialog when program isActive */}
        <ChangeProgramStatusDialog
          onCancel={() => setChangeProgramStatus(null)}
          onProgramUpdated={onChangeProgramStatus}
          program={changeProgramStatus}
        />
      </ScrollView>
    </>
  );
};
