import {
  BannerProps,
  Button,
  FilterCategory,
  FilterDefault,
  FilterTable,
  MenuItem,
  OverflowMenu,
  SortDirection,
  TextLink,
  useBanner,
} from '@design';
import { DeliverableType } from '@shared/enums';
import {
  ApiDeliverable,
  GrowerDeliverableEndpoint,
  GrowerEndpoint,
} from '@shared/interfaces/api';
import { Permissions, RoleUtility, toShortDate } from '@shared/utils';
import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys, Routes } from '../../../../constants';
import { useAppContext } from '../../../../contexts/AppContext';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { DeliverableRenderFactory } from '../../../../pages/Deliverable/DeliverableRenderFactory';
import { useHistory } from '../../../../router';
import { GrowerApi, GrowerDeliverableApi } from '../../../../utilities/api';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { DeleteDeliverableDialog } from './components/DeleteDeliverable/DeleteDeliverableDialog';
import { DeliverableActivityLogSidePanel } from './DeliverableActivityLogSidePanel';
import { useCustomerTotals } from '../../../../hooks/useCustomerTotals';
import { maxListSize } from '@design/FilterMenu/FilterMenu';

interface PageOption {
  filter: {},
  page: number,
  sort: {
    name: GrowerDeliverableEndpoint.List.Sort,
    direction: SortDirection,
  },
}

const defaultPageOptions: PageOption = {
  page: 0,
  filter: {},
  sort: { name: GrowerDeliverableEndpoint.List.Sort.updatedAt, direction: 'DESC' },
};

enum DeliverableTabFilterKeys {
  DELIVERABLE_TYPE = 'deliverableType',
  GROWER = 'growerId',
}

const sortColDictionary = {
  name: GrowerDeliverableEndpoint.List.Sort.deliverableName,
  type: GrowerDeliverableEndpoint.List.Sort.deliverableType,
  owner: GrowerDeliverableEndpoint.List.Sort.createdBy,
  'date-created': GrowerDeliverableEndpoint.List.Sort.createdAt,
};

export const GrowerDeliverableTab: FC = () => {
  const history = useHistory();
  const { user, currentBusinessId } = useAuthentication();
  const [translate] = useTranslation(['prepare', 'common', 'deliverable', 'errors']);
  const queryClient = useQueryClient();
  const [growerDeliverables, setGrowerDeliverables] = (
    useState<(ApiDeliverable & RowMeta)[]>([])
  );
  const [columnCategories, setColumnCategories] = useState<FilterCategory[]>([]);
  const [pageOptions, setPageOptions] = useState<PageOption>(defaultPageOptions);
  const [deleteDeliverable, setDeleteDeliverable] = useState<{ visible: boolean, name: string }>({
    visible: false,
    name: null,
  });
  const [deliverableId, setDeliverableId] = useState(null);
  const [
    deliverableActivityLogSidePanelVisible,
    setDeliverableActivityLogSidePanelVisible,
  ] = useState(false);
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [customerSearch, setCustomerSearch] = useState('');
  const [deliverablesLoaded, setDeliverablesLoaded] = useState(false);

  const { createBanner } = useBanner();
  const bannerProps = (bannerText: string): BannerProps => ({
    children: bannerText,
    status: 'danger',
    testID: 'error-banner',
    actionAccessory: ({ dismissProps }) => (
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
        <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
          {translate<string>('DISMISS')}
        </Button>
      </View>
    ) });

  const scrollRef = useRef<ScrollView>();

  const { setModalProps } = useAppContext();

  const filterOptions: FilterCategory[] = [
    {
      columnLabel: translate<string>('TYPE'),
      columnKey: DeliverableTabFilterKeys.DELIVERABLE_TYPE,
      columns: [
        { label: translate('QUICK_QUOTE'), id: DeliverableType.PRODUCT_ORDER },
        { label: translate('FARM_PLAN'), id: DeliverableType.FARM_PLAN },
      ],
    },
    {
      columnLabel: translate<string>('CUSTOMER'),
      columnKey: 'growerId',
      columns: [],
    },
  ];

  const isSuperUser = RoleUtility.roleHasPermission(
    user.userRole, Permissions.ACCESS_ALL_BUSINESSES,
  );

  const { customerTotals, isSuccess } = useCustomerTotals({
    onError: () => { setPageOptions(defaultPageOptions); },
  });

  useQuery(
    [QueryKeys.GROWER_LIST, currentBusinessId, customerSearch],
    async () => GrowerApi.getGrowers({
      businessId: currentBusinessId,
      limit: maxListSize,
      search: customerSearch,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }),
    {
      enabled: deliverablesLoaded && isSuccess,
      onError: () => { setPageOptions({ ...pageOptions, page: 0 }); },
      onSuccess: (customers) => {
        const newColumnCategories = [...columnCategories];
        const newGrowerOptions = customers.data.map((grower) => ({
          label: grower.legalName,
          id: grower.id,
        }));
        const growerFilter = newColumnCategories.find((category) => (
          category.columnKey === DeliverableTabFilterKeys.GROWER));
        growerFilter.columns = newGrowerOptions;
        growerFilter.total = customerTotals;
        growerFilter.onSearch = setCustomerSearch;
        setColumnCategories(newColumnCategories);
      }, 
    },
  );

  useEffect(() => {
    setColumnCategories(filterOptions);
    setPageOptions(defaultPageOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusinessId]);

  // Get grower deliverables
  const getGrowerDeliverables = () => (
    GrowerDeliverableApi.listGrowerDeliverables({
      page: pageOptions.page,
      sort: pageOptions.sort.name,
      sortDesc: pageOptions.sort.direction === 'DESC',
      ...(isSuperUser ? { businessId: currentBusinessId } : {}),
      ...pageOptions.filter,
    })
  );

  const { isFetching, data: growerDeliverablesResponse } = useQuery(
    [QueryKeys.DELIVERABLE_LIST, pageOptions, currentBusinessId],
    getGrowerDeliverables,
    {
      onError: () => {
        setPageOptions({ ...pageOptions, page: 0 });
        createBanner(bannerProps(translate('ERROR_FETCH_DELIVERABLES')));
      },
      onSuccess: (growerDeliverablesData) => {
        setDeliverablesLoaded(true);
        setGrowerDeliverables(
          growerDeliverablesData.data.map((growerDeliverable, index) => ({
            ...growerDeliverable,
            rowId: `row:${index}|${growerDeliverable.id}|`,
          })),
        );

        scrollRef.current?.scrollTo({
          y: 0,
          animated: true,
        });
      },
      enabled: !!scrollRef?.current,
    },
  );

  const handleSort = (column: string, direction: SortDirection) => {
    const name = sortColDictionary[column];
    setPageOptions({ ...pageOptions, page: 0, sort: { name, direction } });
  };

  const onDeleteDeliverable = async () => {
    try {
      await GrowerDeliverableApi.deleteDeliverable(deliverableId);
      await queryClient.invalidateQueries(QueryKeys.DELIVERABLE_LIST);
      setDeleteDeliverable({ visible: false, name: null });
      setDeliverableId(null);
    } catch (err) {
      createBanner(bannerProps(translate('DELETE_DELIVERABLE_ERROR')));
    }
  };

  // Columns
  const columns: IColumn<(ApiDeliverable & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (data) => (
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={() => {
            if (data.deliverableType === DeliverableType.PRODUCT_ORDER) {
              setModalProps({
                type: 'viewDeliverableModal',
                deliverablePublicId: data.publicId,
                shared: data.shared,
                deliverableId: data.id,
              });
            } else {
              history.push(
                Routes.GROWER_DELIVERABLE
                  .replace(/:grower/, data.growerId)
                  .replace(/:id/, data.id),
              );
            }
          }}
          testID={`grower-deliverable-table-link-${data.id}`}
          wrap
        >
          {data.name}
        </TextLink>
      ),
      flex: 4,
    },
    {
      columnId: 'plan-name',
      header: {
        render: translate('PLAN_NAME'),
      },
      render: (data) => DeliverableRenderFactory.getRenderer(
        data,
      ).getPlanName(),
      flex: 3,
    },
    {
      columnId: 'type',
      header: {
        render: translate('DELIVERABLE_TYPE'),
        sortable: true,
      },
      render: (data) => translate<string>(
        data.deliverableType === DeliverableType.PRODUCT_ORDER
          ? 'QUICK_QUOTE' : data.deliverableType,
      ),
      flex: 2,
    },
    {
      columnId: 'grower',
      header: {
        render: translate('CUSTOMER'),
      },
      render: (data) => DeliverableRenderFactory.getRenderer(
        data,
      ).getGrowerName(),
      flex: 3,
    },
    {
      columnId: 'location',
      header: {
        render: translate('BUSINESS_LOCATION'),
      },
      render: (data) => DeliverableRenderFactory.getRenderer(
        data,
      ).getBusinessLocation(),
      flex: 3,
    },
    {
      columnId: 'owner',
      header: {
        render: translate('OWNER'),
        sortable: true,
      },
      render: (data) => data.createdByName,
      flex: 3,
    },
    {
      columnId: 'date-created',
      header: {
        render: translate('DATE_CREATED'),
        sortable: true,
      },
      render: (data) => toShortDate(data.generationDate),
      flex: 2,
    },
    {
      columnId: 'menu',
      header: { render: null },
      render: (data) => {
        const isOwner = data.userAccountId === user?.id;

        const showDeleteDeliverableOption = (isOwner && !isSuperUser && !data.shared);
        const showShareDeliverableOption = (isOwner && !isSuperUser);
        const showActivityLog = data.shared;

        if (!showShareDeliverableOption && !showActivityLog && !showDeleteDeliverableOption) {
          return null;
        }

        return (
          <OverflowMenu testID="grower-deliverable-tab-overflow-menu">
            { showShareDeliverableOption && (
              <MenuItem
                onPress={() => {
                  setModalProps({
                    type: 'shareDeliverable',
                    customerId: data.growerId,
                    deliverable: data,
                    resending: true,
                  });
                }}
                testID={`grower-deliverable-table-share-${data.id}`}
                title={`${translate('SHARE')}`}
              />
            )}
            {showDeleteDeliverableOption && (
              <MenuItem
                onPress={() => {
                  setDeleteDeliverable({
                    visible: true,
                    name: data.name,
                  });
                  setDeliverableId(data.id);
                }}
                testID={`grower-deliverable-table-delete-${data.id}`}
                title={`${translate('DELETE')}`}
              />
            )}
            {showActivityLog && (
              <MenuItem
                onPress={() => {
                  setDeliverableActivityLogSidePanelVisible(true);
                  setSelectedDeliverable(data);
                }}
                testID={`grower-deliverable-table-activity-log-${data.id}`}
                title={`${translate('VIEW_ACTIVITY_LOG')}`}
              />
            )}
          </OverflowMenu>
        );
      },
      width: 120,
    },
  ];

  return (
    <>
      <DeliverableActivityLogSidePanel
        deliverableId={selectedDeliverable?.id}
        deliverableName={selectedDeliverable?.name}
        onClose={() => { setDeliverableActivityLogSidePanelVisible(false); }}
        visible={deliverableActivityLogSidePanelVisible}
      />
      <DeleteDeliverableDialog
        name={deleteDeliverable.name}
        onClose={() => {
          setDeleteDeliverable({ visible: false, name: null });
        }}
        onDelete={onDeleteDeliverable}
        visible={deleteDeliverable.visible}
      />
      <ScrollView ref={scrollRef} testID="grower-deliverable-tab">
        <FilterTable<FilterDefault, ApiDeliverable & RowMeta>
          columns={columns}
          currentPage={pageOptions.page + 1}
          data={growerDeliverables}
          defaultColumnFilters={filterOptions.map(({ columnKey }) => columnKey)}
          filterOptions={columnCategories}
          isLoading={isFetching}
          noFilters={false}
          onPageChange={(pg: number) => setPageOptions({ ...pageOptions, page: pg - 1 })}
          onSort={handleSort}
          onUpdateFilter={(filter) => {
            setPageOptions({ ...pageOptions, page: 0, filter });
          }}
          tableId="grower-deliverable"
          tableRowId="deliverableListView"
          testID="grower-deliverable-table"
          totalPages={(growerDeliverablesResponse?.lastPage ?? 0) + 1}
          totalResults={growerDeliverablesResponse?.total ?? 0}
        />
      </ScrollView>
    </>
  );
};
