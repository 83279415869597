import * as _ from 'lodash';
import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

// todo replace usage of this query utility with the useQuery hook
export class QueryUtility {
  static getQueryParams (searchParams: string): { [key: string]: any } {
    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    const params = {};

    let match;
    do {
      match = regex.exec(searchParams);
      if (match) {
        _.set(params, match[1], match[2].indexOf(',') > -1 ? match[2].split(',') : match[2]);
      }
    } while (match);

    return params;
  }
}

interface IQueryStringParams {
  [key: string]: string | string[] | true
}
type IQueryStringParamsFunction = (search: string) => IQueryStringParams;

// search is the query string of a url.  '?...'.  It does not include the hash.
// from a standard location object, it would be location.search
// the hash is stored in location.hash
export const parseParams: IQueryStringParamsFunction = (search: string) => {
  const returnResult: IQueryStringParams = {};
  search
    .replace(/^\?/, '')
    .split('&').forEach((kvp) => {
      const [key, value] = kvp.split('=');
      if (key === '') {
        return;
      }

      if (value === '' || value === null || value === undefined) {
        // presence of key with no value is shortcut to key=true
        returnResult[key] = true;
      } else if (value.includes(',')) {
        returnResult[key] = value.split(',');
      } else {
        returnResult[key] = value;
      }
    });

  return returnResult;
};

type IUseQueryFunction = () => IQueryStringParams;

export const useQueryParams: IUseQueryFunction = () => {
  const { search } = useLocation();
  const [params, setParams] = useState(parseParams(search));

  useEffect(() => {
    setParams(parseParams(search));
  }, [search]);

  return params;
};
