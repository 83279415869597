import { VSpacer } from '@design';
import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import DetailHeader from '../../../../components/shared/DetailHeader/DetailHeader';
import { ProductDiscountItem } from './ProductDiscountItem';

export interface ProductDiscountsProps {
  discounts: ProductOrderDeliverableV0.ProductDiscount[],
}

export const ProductDiscounts: FC<ProductDiscountsProps> = ({ discounts }) => {
  const [translate] = useTranslation(['productOrders', 'common']);

  const themedStyles = useStyleSheet(
    {
      line: {
        borderTopWidth: 1,
        borderTopColor: 'color-basic-transparent-100',
      },
      container: {
        backgroundColor: 'color-basic-transparent-0',
        paddingHorizontal: 16,
        paddingTop: 4,
        paddingBottom: 40,
      },
    },
  );

  return (
    <View style={themedStyles.container} testID="discounts-details-container">
      <DetailHeader header={translate<string>('DISCOUNTS')} />
      {discounts.map((discount, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={index}>
          <VSpacer size={index === 0 ? '3' : '5'} />
          <View style={themedStyles.line} />
          <VSpacer size="5" />
          <ProductDiscountItem discount={discount} index={index} />
        </View>
      ))}
    </View>
  );
};
