import { Button, HSpacer, Modal, VSpacer } from '@design';
import { ApiCrop, ApiCropLogic, ApiCropLogicPass, CropLogicEndpoint } from '@shared/interfaces/api';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useBusinessCrops } from '../../../../hooks/useBusinessCrops';
import { CropSelect } from '../../../components/shared/Input/CropSelect';
import { ProgramNameInput } from './ProgramNameInput';

interface ProgramDetailsFormProps {
  onCancel: () => void,
  onSave: (program: ApiCropLogic) => void,
  program: ApiCropLogic,
}

export const ProgramDetailsForm = ({
  onCancel,
  onSave,
  program,
}: ProgramDetailsFormProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const [selectedCropType, setSelectedCropType] = useState<ApiCrop>(undefined);
  const [newProgram, setNewProgram] = useState<ApiCropLogic>(program);
  const [nameValidated, setNameValidated] = useState(true);
  const { currentBusinessId } = useAuthentication();
  const { businessCrops } = useBusinessCrops({ businessId: currentBusinessId });

  useEffect(() => {
    const selectedCrop = businessCrops.find(({ cropType, subType }) => (
      cropType === program.cropType && subType === program.cropSubType
    ));
    setSelectedCropType(selectedCrop);
  }, [businessCrops, program.cropSubType, program.cropType]);

  const handleCropTypeSelect = (
    crop: ApiCrop,
    passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[],
  ) => {
    const {
      cropType,
      subType,
      price,
      priceUom,
      yieldGoal,
      yieldGoalUom,
    } = crop;

    const haveScenarioParameters = !!newProgram.scenarioParameters;
    setNewProgram({
      ...newProgram,
      cropSubType: subType,
      cropType,
      passes: passes as ApiCropLogicPass[],
      scenarioParameters: haveScenarioParameters ? {
        ...newProgram.scenarioParameters,
        price,
        priceUom,
        yieldGoal,
        yieldGoalUom,
      } : null,
    });
    setSelectedCropType(crop);
  };

  const isEqual = useMemo(() => _.isEqual(program, newProgram), [program, newProgram]);

  return (
    <Modal
      footerAccessory={({
        primaryButtonProp,
        secondaryButtonProp,
        spacerProp,
      }) => (
        <>
          <Button
            {...primaryButtonProp}
            onPress={onCancel}
            testID="close-edit-program-modal"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            disabled={isEqual || !nameValidated}
            onPress={() => onSave(newProgram)}
            testID="save-edit-program-modal"
          >
            {translate<string>('SAVE_CHANGES')}
          </Button>
        </>
      )}
      hideCloseButton
      testID="program-details-modal"
      title={translate<string>('EDIT_DETAILS')}
      visible
    >
      <View testID="program-form-modal">
        <ProgramNameInput
          name={newProgram.logicName}
          onUpdateName={(logicName) => {
            setNewProgram({
              ...newProgram,
              logicName,
            });
          }}
          onUpdateValidate={setNameValidated}
          programId={newProgram.id}
          valid={nameValidated}
        />
        <VSpacer size="7" />
        <CropSelect
          crop={selectedCropType}
          onUpdateCrop={handleCropTypeSelect}
          passes={program.passes}
          testID="program-form-modal-crop-type"
        />
      </View>
    </Modal>
  );
};
