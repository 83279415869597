import { VSpacer } from '@design';
import { ProductMixIntendedUse } from '@shared/enums';
import {
  ApiProduct,
  ApiProductOrder,
  ApiProductOrderComponent,
  ApiProductOrderProductMixComponent,
  ApiTankMix,
} from '@shared/interfaces/api';
import React, { FC, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  ProductFilterer,
  ProductFilters,
} from './components/ProductOrderSelectProducts/ProductFilterer';
import { ProductList } from './components/ProductOrderSelectProducts/ProductList';
import { SelectedProducts } from './components/ProductOrderSelectProducts/SelectedProducts';

interface SelectProductsProps {
  productOrder: ApiProductOrder,
  onProductOrderChange: (productOrder) => void;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 36,
  },
  row: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 1,
    paddingRight: 12,
  },
  rightContainer: {
    flex: 1,
    paddingLeft: 12,
  },
});

export const ProductOrderSelectProducts: FC<SelectProductsProps> = ({
  productOrder,
  onProductOrderChange,
}) => {
  const [filters, setFilters] = useState<ProductFilters>({
    search: '',
    category: null,
    crops: [],
    cropSubtypes: [],
    customers: [],
    formulations: [],
    subcategories: [],
    manufacturers: [],
  });

  const handleProductSelected = (
    product?: ApiProduct,
    productMix?: ApiTankMix,
  ) => {
    if (product) {
      onProductOrderChange({
        ...productOrder,
        components: [
          ...productOrder.components, {
            acreage: 1,
            discounts: [],
            isActive: true,
            packageQuantity: 1,
            productId: product.id,
            product,
          },
        ],
      });
    } else if (productMix) {
      onProductOrderChange({
        ...productOrder,
        productMixComponents: [
          ...productOrder.productMixComponents,
          ...productMix.components.map(tmc => ({
            acreage: 1,
            applicationRate: tmc.rate,
            applicationRateUom: tmc.rateUom,
            discounts: [],
            intendedUse: tmc.adjuvant
              ? ProductMixIntendedUse.Adjuvant
              : (tmc.carrier ? ProductMixIntendedUse.Carrier : null),
            isActive: true,
            productId: tmc.productId,
            product: tmc.product,
            tankMixId: productMix.id,
            tankMix: productMix,
          })),
        ],
      });
    }
  };

  const handleProductRemoved = (
    product?: ApiProductOrderComponent,
    productMix?: ApiProductOrderProductMixComponent,
  ) => {
    const components = product
      ? productOrder.components.filter((x) => x.productId !== product.productId)
      : productOrder.components;

    const productMixComponents = productMix
      ? productOrder.productMixComponents.filter((x) => x.tankMixId !== productMix.tankMixId)
      : productOrder.productMixComponents;
      
    onProductOrderChange({ ...productOrder, components, productMixComponents });
  };

  const handleClearAllPress = () => {
    onProductOrderChange({ ...productOrder, components: [], productMixComponents: [] });
  };

  return (
    <View style={styles.container}>
      <ProductFilterer
        customerId={productOrder.growerId}
        onFiltersChanged={setFilters}
      />
      <VSpacer size="9" />
      <View style={styles.row}>
        <View style={styles.leftContainer}>
          <ProductList
            filters={filters}
            locationId={productOrder?.locationId}
            onProductSelected={handleProductSelected}
            priceTypeId={productOrder?.priceTypeId}
            selectedProductMixes={productOrder.productMixComponents}
            selectedProducts={productOrder.components}
          />
        </View>
        <View style={styles.rightContainer}>
          <SelectedProducts
            locationId={productOrder.locationId}
            onClearAllPress={handleClearAllPress}
            onProductRemoved={handleProductRemoved}
            priceTypeId={productOrder?.priceTypeId}
            selectedProductMixes={productOrder.productMixComponents}
            selectedProducts={productOrder.components}
          />
        </View>
      </View>
    </View>
  );
};
