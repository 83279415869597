import React, { FunctionComponent } from 'react';
import { StyleSheet, Text, TouchableOpacity, TouchableOpacityProps } from 'react-native';
import { Icon } from '@ui-kitten/components';
import { Sizing, ThemeColors } from '../../../constants';
import { testId } from '../../../utilities/testId';

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 6 * Sizing.EM,
    paddingHorizontal: 2.5 * Sizing.EM,
    paddingVertical: Sizing.HALF_SPACING,
    backgroundColor: ThemeColors.PRIMARY,
  },
  buttonWithIcon: { paddingHorizontal: Sizing.BASE_SPACING },
  iconButton: { paddingHorizontal: Sizing.HALF_SPACING },
  text: {
    fontSize: 1.05 * Sizing.EM,
    color: ThemeColors.BACKGROUND,
  },
  secondary: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: ThemeColors.PRIMARY,
    borderWidth: 2,
  },
  secondaryText: { color: ThemeColors.PRIMARY },
  tertiary: { backgroundColor: ThemeColors.DARK_GRAY },
  tertiaryText: { color: ThemeColors.SECONDARY },
  ghost: { backgroundColor: 'rgba(0, 0, 0, 0)' },
  ghostText: { color: ThemeColors.TEXT },
  iconLeft: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
    marginRight: Sizing.HALF_SPACING,
  },
  iconButtonIcon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
  textSmall: {
    fontSize: 0.9375 * Sizing.EM,
  },
  textXSmall: {
    fontSize: 0.9375 * Sizing.EM,
  },
  buttonSmall: {
    paddingVertical: 0.3125 * Sizing.EM,
    paddingHorizontal: 2 * Sizing.EM,
  },
  buttonXSmall: {
    paddingVertical: Sizing.HALF_SPACING,
    paddingHorizontal: Sizing.BASE_SPACING,
  },
});

export type TButtonStatus = 'primary' | 'secondary' | 'tertiary' | 'ghost';
export type TButtonSize = 'large' | 'small' | 'xsmall';

export interface StyledButtonProps extends TouchableOpacityProps {
  status?: TButtonStatus,
  iconLeft?: string,
  iconPack?: string,
  textStyle?: any,
  children?: any,
  size?: TButtonSize;
  nativeID?: string;
}

export const StyledButton: FunctionComponent<StyledButtonProps> = (propsx) => {
  const {
    iconLeft,
    iconPack,
    status = 'primary',
    disabled,
    style,
    textStyle,
    nativeID,
    size = 'large',
    children,
  } = propsx;

  return (
    <TouchableOpacity
      {...propsx}
      {...testId(nativeID)}
      style={[
        styles.button,
        iconLeft && children && styles.buttonWithIcon,
        iconLeft && !children && styles.iconButton,
        status === 'secondary' && styles.secondary,
        status === 'tertiary' && styles.tertiary,
        status === 'ghost' && styles.ghost,
        disabled && { opacity: 0.35 },
        size === 'small' && styles.buttonSmall,
        size === 'xsmall' && styles.buttonXSmall,
        style,
      ]}
    >
      {iconLeft && (
        <Icon
          fill={(status === 'secondary' && ThemeColors.PRIMARY) || (status === 'tertiary' && ThemeColors.SECONDARY) || (status === 'ghost' && ThemeColors.SECONDARY) || ThemeColors.BACKGROUND}
          name={iconLeft}
          pack={iconPack}
          style={children ? styles.iconLeft : styles.iconButtonIcon}
        />
      )}
      <Text
        {...testId(`${nativeID}-button`)}
        style={[
          styles.text,
          status === 'secondary' && styles.secondaryText,
          status === 'tertiary' && styles.tertiaryText,
          status === 'ghost' && styles.ghostText,
          size === 'small' && styles.textSmall,
          size === 'xsmall' && styles.textSmall,
          textStyle,
        ]}
      >
        {children}
      </Text>
    </TouchableOpacity>
  );
};
