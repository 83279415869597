import React from 'react';
import {
  StyleSheet,
  ImageProps,
  TextProps,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native';
import {
  FalsyFC,
  RenderProp,
  PropsService,
  FalsyText,
} from '@ui-kitten/components/devsupport';
import {
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { BadgeStatus } from '@theme/variant-interfaces/Status';

export interface BadgeProps extends StyledComponentProps {
  appearance?: 'tag',
  accessoryLeft?: RenderProp<Partial<ImageProps>>;
  accessoryRight?: RenderProp<Partial<ImageProps>>;
  children?: RenderProp<TextProps> | React.ReactText;
  status?: BadgeStatus,
  style?: StyleProp<ViewStyle>,
  testID: string,
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

type BadgeElement = React.ReactElement<BadgeProps>;

@styled('Badge')
export class Badge extends React.Component<BadgeProps> {
  private getComponentStyle = (style: StyleType) => {
    const textStyles = PropsService.allWithPrefix(style, 'text');
    const iconStyles = PropsService.allWithPrefix(style, 'icon');
    const {
      backgroundColor,
      borderRadius,
      maxHeight,
      paddingHorizontal,
      paddingVertical,
      textTransform,
    } = style;

    return {
      container: {
        backgroundColor,
        borderRadius,
        maxHeight,
        paddingHorizontal,
        paddingVertical,
        textTransform,
      },
      text: {
        color: textStyles.textColor,
        fontFamily: textStyles.textFontFamily,
        fontSize: textStyles.textFontSize,
        fontWeight: textStyles.textFontWeight,
        letterSpacing: textStyles.textLetterSpacing,
        lineHeight: textStyles.textLineHeight,
      },
      iconSize: iconStyles.iconSize,
      iconRight: {
        marginLeft: iconStyles.iconInsideMargin,
        tintColor: iconStyles.iconTintColor,
      },
      iconLeft: {
        marginRight: iconStyles.iconInsideMargin,
        tintColor: iconStyles.iconTintColor,
      },
    };
  };

  public render (): BadgeElement {
    const { eva, style, accessoryLeft, accessoryRight, children, ...viewProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View
        {...viewProps}
        style={[evaStyle.container, styles.container, style]}
      >
        <FalsyFC
          component={accessoryLeft}
          size={evaStyle.iconSize}
          style={evaStyle.iconLeft}
        />
        <FalsyText component={children} style={evaStyle.text} />
        <FalsyFC
          component={accessoryRight}
          size={evaStyle.iconSize}
          style={evaStyle.iconRight}
        />
      </View>
    );
  }
}
