import { Select, SelectItem, useBanner } from '@design';
import { Permissions, RoleUtility } from '@shared/utils';
import { IndexPath } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { GrowerApi, UserApi } from '../../../../utilities/api';

const emptyArray = [];

export interface BusinessUserSelectProps {
  customerId?: string,
  disabled?: boolean,
  label?: string,
  onUpdateOwner(ownerId: string): void,
  ownerId?: string,
  readonly?: boolean,
  testId?: string,
}

export const BusinessUserSelect = ({
  customerId,
  disabled,
  label,
  onUpdateOwner,
  ownerId,
  readonly,
  testId = 'business-user-selector',
}: BusinessUserSelectProps) => {
  const [translate] = useTranslation(['common', 'prepare']);
  const { user, currentBusinessId } = useAuthentication();

  const { showBanner } = useBanner();

  const { data: businessUsers, isLoading } = useQuery(
    [QueryKeys.BUSINESS_USERS, currentBusinessId, customerId],
    async () => {
      if (customerId) {
        const res = await GrowerApi.getEligibleFarmPlanOwners(customerId);
        if (res.length > 0) {
          return res;
        }
      }

      const res = await UserApi.getAllUsersForBusiness(currentBusinessId);
      return res.data;
    },
    {
      initialData: emptyArray,
      onError: () => {
        showBanner(translate('ERROR_BUSINESS_USERS'));
      },
    },
  );

  const isOwnerDisabled = !RoleUtility
    .roleHasPermission(user.userRole, Permissions.MODIFY_OTHER_OWNER);

  const selectedIndex = businessUsers.findIndex(({ id }) => id === ownerId) ?? -1;

  return (
    <Select
      disabled={isLoading || isOwnerDisabled || disabled}
      isRequired
      label={label ?? translate<string>('OWNER')}
      onSelect={(idx: IndexPath | IndexPath[]) => {
        onUpdateOwner(businessUsers[(idx as IndexPath).row].id);
      }}
      readonly={readonly}
      selectedIndex={selectedIndex === -1 ? undefined : new IndexPath(selectedIndex)}
      testID={testId}
      value={businessUsers.find(
        (businessUser) => businessUser.id === ownerId,
      )?.fullName ?? ''}
    >
      {businessUsers.map((businessUser, index) => (
        <SelectItem
          key={businessUser.id}
          testID={`business-user-dropdown-value-${index}`}
          title={businessUser.fullName}
        />
      ))}
    </Select>
  );
};
