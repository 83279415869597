import React, { FC, useState } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { IndexPath } from '@ui-kitten/components';
import { Card, CheckBox, Input, Select, SelectItem, Icon, Text, ResponsiveView, IconName, IconNames } from '@design';
import { InputSize, InputSizes } from '@theme/variant-interfaces/Size';
import { Status, Statuses } from '@theme/variant-interfaces/Status';
import { DemoContainer } from '../components/DemoBlock';

interface InputKnobsProps {
  title?: string,
}

const InputKnobs: FC<InputKnobsProps> = ({ title }) => {
  const [size, setSize] = useState<InputSize>('medium');
  const [status, setStatus] = useState<Status>('basic');
  const [disable, setDisable] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const [label, setLabel] = useState('Label');
  const [caption, setCaption] = useState('Caption');
  const [placeholder, setPlaceholder] = useState('Placeholder...');
  const [iconLeft, setIconLeft] = useState<IconName>(undefined);
  const [iconRight, setIconRight] = useState<IconName>(undefined);

  return (
    <>
      <DemoContainer label={title}>
        <ResponsiveView
          medium={6}
          small={8}
          xSmall={4}
        >
          <Input
            label="label"
            onChangeText={setLabel}
            testID="demo-input"
            value={label}
          />
        </ResponsiveView>
        <ResponsiveView
          medium={6}
          small={8}
          xSmall={4}
        >
          <Input
            label="caption"
            onChangeText={setCaption}
            testID="demo-input"
            value={caption}
          />
        </ResponsiveView>
        <ResponsiveView
          large={4}
          medium={4}
          small={8}
          xLarge={4}
          xSmall={4}
        >
          <Input
            label="placeholder"
            onChangeText={setPlaceholder}
            testID="demo-input"
            value={placeholder}
          />
        </ResponsiveView>
        <ResponsiveView
          large={4}
          medium={4}
          small={8}
          xLarge={4}
          xSmall={4}
        >
          <Select
            label="size"
            onSelect={(index) => setSize(InputSizes[(index as IndexPath).row])}
            selectedIndex={new IndexPath(InputSizes.findIndex((s) => s === size))}
            testID="layout-demo-size-selector"
            value={size}
          >
            {InputSizes.map((s, i) => (
              <SelectItem key={s} testID={`input-size-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
        </ResponsiveView>
        <ResponsiveView
          large={4}
          medium={4}
          small={8}
          xLarge={4}
          xSmall={4}
        >
          <Select
            label="status"
            onSelect={(index) => setStatus(Statuses[(index as IndexPath).row])}
            selectedIndex={new IndexPath(Statuses.findIndex((s) => s === status))}
            testID="layout-demo-status-selector"
            value={status}
          >
            {Statuses.map((s, i) => (
              <SelectItem key={s} testID={`layout-demo-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
        </ResponsiveView>
      </DemoContainer>
      <DemoContainer>
        <ResponsiveView xSmall={4}>
          <CheckBox checked={disable} onChange={setDisable} testID="test-checkbox">
            disabled
          </CheckBox>
        </ResponsiveView>
        <ResponsiveView xSmall={4}>
          <CheckBox checked={readonly} onChange={setReadonly} testID="test-checkbox">
            readonly
          </CheckBox>
        </ResponsiveView>
      </DemoContainer>
      <DemoContainer>
        <ResponsiveView medium={6} small={4} xSmall={4}>
          <Select
            label="accessoryLeft"
            onSelect={(ip: IndexPath | IndexPath[]) => setIconLeft(
              IconNames[(ip as IndexPath).row - 1],
            )}
            selectedIndex={
              new IndexPath(IconNames.findIndex((n) => n === iconLeft) + 1)
            }
            testID="layout-demo-accessory-left-selector"
            value={iconLeft || 'None'}
          >
            {[
              <SelectItem key="noicon" testID="layout-demo-left-icons-selector" title="None" />,
              ...IconNames.map((name, index) => (
                <SelectItem key={name} testID={`layout-demo-left-icon-name-dropdown-value-${index}`} title={name} />
              )),
            ]}
          </Select>
        </ResponsiveView>
        <ResponsiveView small={4} xSmall={4}>
          <Select
            label="accessoryRight"
            onSelect={(ip: IndexPath | IndexPath[]) => setIconRight(
              IconNames[(ip as IndexPath).row - 1],
            )}
            selectedIndex={
              new IndexPath(IconNames.findIndex((n) => n === iconRight) + 1)
            }
            testID="layout-demo-accessory-right-selector"
            value={iconRight || 'None'}
          >
            {[
              <SelectItem key="noicon" testID="layout-demo-right-icon-selector" title="None" />,
              ...IconNames.map((name, index) => (
                <SelectItem key={name} testID={`layout-demo-right-icon-name-dropdown-value-${index}`} title={name} />
              )),
            ]}
          </Select>
        </ResponsiveView>
      </DemoContainer>
      <DemoContainer>
        <ResponsiveView medium={6} small={8} xSmall={4}>
          <Input
            accessoryLeft={(props) => (
              (iconLeft || null) && <Icon name={iconLeft} testID="demo-left-icon" {...props} />
            )}
            accessoryRight={(props) => (
              (iconRight || null) && <Icon name={iconRight} testID="demo-right-icon" {...props} />
            )}
            caption={caption}
            disabled={disable}
            label={label}
            placeholder={placeholder}
            readonly={readonly}
            size={size}
            status={status}
            testID="demo-input"
          />
        </ResponsiveView>
      </DemoContainer>
    </>
  );
};

export const LayoutDemo: FC = () => {
  const { width: windowWidth } = useWindowDimensions();
  return (
    <>
      <View>
        <Text>
          {windowWidth}
          px
        </Text>
      </View>
      <View nativeID="demo-page" style={{ alignSelf: 'stretch', flexDirection: 'row', flexWrap: 'wrap' }}>
        <ResponsiveView
          large={6}
          medium={6}
          small={8}
          style={{ marginBottom: 16 }}
          xLarge={6}
          xSmall={4}
        >
          <Card testID="test-card">
            <InputKnobs title="Card 1" />
          </Card>
        </ResponsiveView>
        <ResponsiveView
          large={6}
          medium={6}
          small={4}
          style={{ marginBottom: 16 }}
          xLarge={6}
          xSmall={4}
        >
          <Card testID="test-card">
            <InputKnobs title="Card 2" />
          </Card>
        </ResponsiveView>
        <ResponsiveView
          large={6}
          medium={6}
          small={4}
          style={{ marginBottom: 16 }}
          xLarge={6}
          xSmall={4}
        >
          <Card testID="test-card">
            <InputKnobs title="Card 3" />
          </Card>
        </ResponsiveView>
        <ResponsiveView
          large={12}
          medium={12}
          small={8}
          style={{ marginBottom: 16 }}
          xLarge={12}
          xSmall={4}
        >
          <Card testID="test-card">
            <InputKnobs title="Card 4" />
          </Card>
        </ResponsiveView>
      </View>
    </>
  );
};
