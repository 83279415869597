import { Header, Icon, ModalSpinner, useToast, VSpacer } from '@design';
import { ApiCropLogic, ApiCropLogicPass, CropLogicEndpoint } from '@shared/interfaces/api';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet } from 'react-native';
import { Routes } from '../../../../constants';
import { useHistory, useParams } from '../../../../router';
import { useProgram } from '../../../../hooks/useProgram';
import { ProgramFormModal } from './ProgramFormModal';
import { ProgramDetailsInfo } from './ProgramDetailsInfo';
import { ProgramDetailsPasses } from './ProgramDetailsPasses';
import { ButtonBar } from '../../../components/shared/ButtonBar';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';

const styles = StyleSheet.create({
  overline: {
    marginBottom: 9,
  },
  scrollView: {
    paddingBottom: 150 - 32, // 32 = wrapper padding bottom
  },
});

export const ProgramDetailsPage = () => {
  const [translate] = useTranslation(['common', 'programs']);
  const { createToast } = useToast();
  const history = useHistory();
  const { id: programId } = useParams<{ id?: string }>();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(!programId);
  const {
    businessLocation,
    canEditAccount,
    isLoading,
    isOwner,
    isProgramSaved,
    isProgramValid,
    priceType,
    program,
    setProgram,
    user,
  } = useProgram({
    programId,
    onCreate: () => {
      createToast({
        children: translate<string>('PROGRAM_SUCCESSFULLY_CREATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
      history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'programs'));
    },
    onError: () => createToast({
      children: translate<string>('ERROR_PROGRAM'),
      status: 'danger',
      testID: 'toast-content-element',
    }),
    onUpdate: () => createToast({
      children: translate<string>('PROGRAM_SUCCESSFULLY_UPDATED'),
      status: 'success',
      testID: 'toast-content-element',
    }),
  });
  const handleCancel = useCallback(() => {
    history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'programs'));
  }, [history]);

  const handleProgramChange = useCallback((newProgram: ApiCropLogic) => {
    setProgram(newProgram);
  }, [setProgram]);

  const confirmationText = useMemo(() => (!programId ? {
    title: translate('DISCARD_THIS_PROGRAM'),
    message: translate('THIS_PROGRAM_HAS_NOT_YET_BEEN_CREATED'),
  } : {
    title: translate('DISCARD_CHANGES'),
    message: translate('CHANGES_TO_THIS_PROGRAM_HAVE_NOT_BEEN_SAVED'),
  }), [programId, translate]);

  const handleUpdatePasses = useCallback(
    (passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[]) => {
      setProgram({
        ...program,
        passes: passes as ApiCropLogicPass[],
      });
    },
    [program, setProgram],
  );

  return (
    <>
      {isLoading && <ModalSpinner visible />}
      {showCreateModal ? (
        <ProgramFormModal
          canEditAccount={canEditAccount}
          onCancel={handleCancel}
          onProgramChange={handleProgramChange}
          onSave={() => setShowCreateModal(false)}
          program={program}
        />
      ) : (
        <ScrollView style={styles.scrollView} testID="program-details">
          <Header
            overline={translate<string>('PROGRAM')}
            testID="program-details-header"
            title={program.logicName}
          />
          <VSpacer size="10" />
          <ProgramDetailsInfo
            businessLocation={businessLocation}
            canEdit={isOwner}
            onSave={(newProgram, updateInitialProgram) => setProgram(
              newProgram as ApiCropLogic,
              updateInitialProgram,
            )}
            priceType={priceType}
            program={program}
            user={user}
          />
          <VSpacer size="10" />
          <ProgramDetailsPasses
            businessLocationId={businessLocation?.id}
            canEdit={isOwner}
            cropSubType={program.cropSubType}
            cropType={program.cropType}
            onUpdatePasses={handleUpdatePasses}
            passes={program.passes}
            priceTypeId={priceType?.id}
            user={user}
          />
        </ScrollView>
      )}

      <ConfirmationModal
        cancelText={translate<string>('CANCEL')}
        confirmText={translate<string>('DISCARD')}
        messageText={confirmationText.message}
        onCancel={() => setShowCancelModal(false)}
        onConfirm={handleCancel}
        status="warning"
        title={confirmationText.title}
        visible={showCancelModal}
      />
      {isProgramSaved ? (
        <ButtonBar
          accessoryLeft={(btnProps) => <Icon name="ArrowBack" testID="program-details-view-button-icon" {...btnProps} />}
          hideRightButton
          leftAction={handleCancel}
          leftButtonText={translate('BACK')}
          size="giant"
          testID="program-details-view-button"
        />
      ) : (
        <ButtonBar
          buttonBarType="grouped"
          disableRightAction={!isProgramValid}
          leftAction={() => setShowCancelModal(true)}
          leftButtonText={translate<string>('CANCEL')}
          rightAction={() => setProgram(program, true)}
          rightButtonText={translate<string>(!programId ? 'SAVE_PROGRAM' : 'SAVE_CHANGES')}
          size="giant"
          testID="program-details-edit-button"
        />
      )}
    </>
  );
};
