import { SubNavigation } from '@design/SubNavigation/SubNavigation';
import { useState } from 'react';
import { Text } from '@design';
import { DemoContainer } from '../components/DemoBlock';
import { View } from 'react-native';

const demoLabels = [
  'Details',
  'Notes',
  'Settings',
  'About',
];

export const SubNavigationDemo = () => {
  const [selectedLabel, setSelectedLabel] = useState(demoLabels[0]);

  return (
    <DemoContainer label="Sub Navigation">
      <View style={{ padding: 20, width: 300 }}>
        <SubNavigation
          labels={demoLabels}
          onChange={setSelectedLabel}
          selectedLabel={selectedLabel}
          testID="demo-sub-navigation"
        />
      </View>
      <View style={{ padding: 20 }}>
        <Text>
          {`You are viewing the ${selectedLabel} page.`}
        </Text>
      </View>
    </DemoContainer>
  );
};
