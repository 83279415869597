/* eslint-disable react/static-property-placement */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Animated,
  Easing,
  LayoutChangeEvent,
  ViewProps,
  ViewStyle,
} from 'react-native';
import { RTLService } from '@ui-kitten/components/devsupport';

export interface TabIndicatorProps extends ViewProps {
  positions: number;
  selectedPosition?: number;
  indicatorTotalWidthPercent?: number;
}

export type TabIndicatorElement = React.ReactElement<TabIndicatorProps>;

export class TabIndicator extends React.Component<TabIndicatorProps> {
  static defaultProps: Partial<TabIndicatorProps> = {
    selectedPosition: 0,
    indicatorTotalWidthPercent: 100,
  };
  private indicatorWidth: number = 0;
  private contentOffset: Animated.Value = new Animated.Value(0);

  public componentDidMount () {
    this.contentOffset.addListener(this.onContentOffsetAnimationStateChanged);
  }

  public componentDidUpdate () {
    const { selectedPosition: index } = this.props;

    this.scrollToIndex({
      index,
      animated: true,
    });
  }

  public componentWillUnmount () {
    this.contentOffset.removeAllListeners();
  }

  private onContentOffsetAnimationStateChanged = (state: { value: number }) => {
    // no-op
  };

  private onContentOffsetAnimationStateEnd = (result: { finished: boolean }) => {
    // no-op
  };

  private createOffsetAnimation = (
    params: { offset: number, animated?: boolean },
  ): Animated.CompositeAnimation => Animated.timing(this.contentOffset, {
    toValue: RTLService.select(params.offset, -params.offset),
    duration: 200,
    easing: Easing.linear,
    useNativeDriver: false,
  });

  private onLayout = (event: LayoutChangeEvent) => {
    this.indicatorWidth = event.nativeEvent.layout.width;

    this.scrollToOffset({
      offset: this.indicatorWidth * this.props.selectedPosition,
      animated: false,
    });
  };

  private getComponentStyle = (): ViewStyle => {
    const widthPercent: number = (
      this.props.indicatorTotalWidthPercent ?? 100
    ) / this.props.positions;

    return {
      width: `${widthPercent}%`,

      // @ts-ignore: RN has no types for `Animated` styles
      transform: [{ translateX: this.contentOffset }],
    };
  };

  /**
  * scrolls indicator to passed offset
  *
  * @param params (object) - {
  *  offset: number,
  *  animated: boolean | undefined
  * }
  */
  public scrollToOffset (params: { offset: number, animated?: boolean }) {
    this.createOffsetAnimation(params).start(this.onContentOffsetAnimationStateEnd);
  }

  /**
   * scrolls indicator to passed index
   *
   * @param params (object) - {
   *  index: number,
   *  animated: boolean | undefined
   * }
   */
  public scrollToIndex (params: { index: number, animated?: boolean }) {
    const { index, ...rest } = params;
    const offset: number = this.indicatorWidth * index;

    this.scrollToOffset({ offset, ...rest });
  }

  public render (): React.ReactElement<ViewProps> {
    const { style, ...viewProps } = this.props;
    const evaStyle: ViewStyle = this.getComponentStyle();

    return (
      <Animated.View
        {...viewProps}
        onLayout={this.onLayout}
        style={[style, evaStyle]}
      />
    );
  }
}
