export default {
  ACCESS_ACCOUNT: 'You can now access your account.',
  BACK_TO_LOGIN: 'Back to login',
  CANCEL: 'Cancel',
  CHANGE_PASSWORD: 'Change password',
  CHANGE_YOUR_PASSWORD: 'Change your password',
  CHECK_EMAIL: 'Check your email',
  CREATE_NEW_PASSWORD_HEADER: 'Create a new password',
  CREATE_PASSWORD: 'Create Your Password',
  CREATE_PASSWORD_SUBHEADER: 'In order to protect your account, make sure your password matches these criteria:',
  DONT_HAVE_ACCOUNT: 'Don\'t have an account or need help?',
  EMAIL_GROWERS: 'Contact Rally Support',
  EMAIL_HAS_BEEN_SENT: 'An email has been sent to your inbox from Rally Support. Please click the link in the email to continue.',
  EMAIL_INPUT_LABEL: 'Type your email address here',
  EMAIL_RECOVERY_LINK: 'Email recovery link',
  FORGOT_PASSWORD: 'Forgot password?',
  FORGOT_SUBHEADER: 'Don\'t worry, happens to the best of us.',
  INVALID_CREDENTIALS: 'Incorrect username or password',
  INVALID_RESET_TOKEN: 'Invalid reset token',
  LINK_RESEND_ERROR: 'There was an unexpected error resending the link.',
  LINK_RESENT: 'The link was resent to your email address.',
  LOGIN: 'Login',
  LOG_IN: 'Log in',
  NEED_HELP: 'Need help?',
  NEW_PASSWORD: 'New password',
  NEW_PASSWORD_CREATED: 'Your new password has been successfully created. You can now access your account.',
  PASSWORD: 'Password',
  PASSWORD_CANT_BE_EMAIL: 'Your password cannot match your email address',
  PASSWORD_CANT_BE_NAME: 'Your password cannot match your name',
  PASSWORD_CREATED: 'New password created',
  PASSWORD_INPUT_LABEL: 'Type your password here',
  PASSWORD_INVALID: 'The password does not match the requirements.',
  PASSWORD_MISMATCH: 'Passwords do not match',
  PASSWORD_MUST: 'Password must:',
  PASSWORD_MUST_BE_EIGHT_CHARACTERS: 'Password must be at least 8 characters long',
  PASSWORD_MUST_HAVE_CHARACTER: 'Password must contain an alphabetical character',
  PASSWORD_MUST_HAVE_NUMBER: 'Password must contain a number',
  PASSWORD_MUST_HAVE_SPECIAL: 'Password must contain a special character (!, @, #, $, %, &, *, ?)',
  PASSWORD_RULE_EMAIL: 'Not match your email address',
  PASSWORD_RULE_LENGTH: 'Be at least 8 characters',
  PASSWORD_RULE_NAME: 'Not match your name',
  PASSWORD_UPDATED: 'Your password was successfully updated.',
  PROBLEM: 'There was a problem with Rally and we had to log you out',
  RATE_LIMIT_EXCEEDED: 'Too many login attempts\nPlease wait 15 minutes before trying again',
  RECOVER_PASSWORD: 'Recover Password',
  RECOVER_PASSWORD_SUBHEADER: 'Enter your email and we’ll send you a link to reset your password',
  REENTER_PASSWORD: 'Re-enter new password',
  RESEND_LINK: 'Resend the link',
  STILL_HAVING_PROBLEMS: 'Still having problems?',
  UNEXPECTED_ERROR: 'An unexpected error occurred',
  USER_NAME: 'Email',
  VERIFICATION_SUCCESSFUL: 'Verification was successful. You may now log in with your new email address.',
  VERIFICATION_TOKEN_INVALID: 'The verification token is invalid or expired. Please log in and attempt to change your email address again.',
};
