import { Select, SelectItem } from '@design';
import { State } from '@shared/constants';
import { IndexPath } from '@ui-kitten/components';
import { TouchableWebProps } from '@ui-kitten/components/devsupport';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface StateSelectProps extends TouchableWebProps {
  label?: string,
  onUpdateState(state: string): void,
  state?: string,
  states?: State[],
  testID: string,
}
export function StateSelect ({
  label = '',
  onUpdateState,
  state,
  states,
  ...props
}: StateSelectProps) {
  const [translate] = useTranslation(['common']);

  return (
    <Select
      label={label}
      onSelect={(idx: IndexPath | IndexPath[]) => {
        const { name } = states[(idx as IndexPath).row];
        onUpdateState(name);
      }}
      selectedIndex={state
        ? new IndexPath(states.findIndex((s) => s.name === state))
        : new IndexPath(0)}
      value={state || (!state && translate<string>('STATE'))}
      {...props}
    >
      {states.map(({ name }, index) => (
        <SelectItem key={name} testID={`business-state-dropdown-value-${index}`} title={name} />
      ))}
    </Select>
  );
}
