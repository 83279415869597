import {
  BannerProps,
  Button,
  Filter,
  FilterCategory,
  HSpacer,
  Modal,
  useBanner,
  Text,
  VSpacer,
  Table,
  TableCell,
  TableRow,
  ViewRow,
  Pagination,
  CenteredSpinner,
  IconButton,
} from '@design';
import {
  AgriculturalUnit,
  AreaUnitType,
  CropLogicComponentCategory,
  CropSubType,
  CropType,
  FormulationType,
  ProductCategory,
} from '@shared/enums';
import {
  ApiCropLogicPassComponent,
  ApiProduct,
  ApiProductListQuery,
  ApiProductListQuerySort,
  ApiTankMix,
  GrowerEndpoint,
} from '@shared/interfaces/api';
import { CalculationUtility, generateId } from '@shared/utils';
import { CheckBox, useStyleSheet } from '@ui-kitten/components';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useQuery } from 'react-query';
import { StringUtility } from '../../../utilities';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { BusinessApi, GrowerApi, ProductApi, TankMixApi } from '../../../utilities/api';
import { TankMixDetailsModal } from '../../components/shared/TankMixDetailsModal/TankMixDetailsModal';
import { maxListSize } from '@design/FilterMenu/FilterMenu';
import { useCustomerTotals } from '../../../hooks/useCustomerTotals';

type ComponentFilter = {
  businessCategoryId: string[],
  category: CropLogicComponentCategory,
  customer: string[],
  formulation: FormulationType[],
  search: string,
};

type PassComponent = ApiProduct & ApiTankMix;

export interface AddComponentProps {
  businessLocationId: string,
  cropType: CropType,
  cropSubType: CropSubType,
  components?: ApiCropLogicPassComponent[],
  customerId?: string,
  onApply: (components: ApiCropLogicPassComponent[]) => void,
  onClose: () => void,
  priceTypeId: string,
}

export const AddComponentModal: FC<AddComponentProps> = ({
  businessLocationId,
  components = [],
  cropSubType,
  cropType,
  customerId,
  onClose,
  onApply,
  priceTypeId,
}) => {
  const [translate] = useTranslation([
    'common',
    'business',
    'products',
    'errors',
    'prepare',
  ]);
  const { currentBusinessId: businessId } = useAuthentication();
  const [defaultColumnFilters, setDefaultColumnFilters] = useState([
    'category',
  ]);
  const [filterOptions, setFilterOptions] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [shouldPresetCustomer, setShouldPresetCustomer] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedComponents, setSelectedComponents] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentComponents, setCurrentComponents] = useState({});
  const [products, setProducts] = useState<PassComponent[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showTankMix, setShowTankMix] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<ComponentFilter>({
    businessCategoryId: [],
    category: null,
    customer: [],
    formulation: [],
    search: '',
  });
  const ModalHeight = 760;
  const styles = useStyleSheet({
    marginTopOverride: {
      // This is for getting 32 pixels between the subtitle and the search box
      marginTop: -32,
    },
    selectComponent: {
      textAlign: 'center',
      paddingHorizontal: 32,
    },
    infoTableCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rowDetails: {
      paddingBottom: 32,
      paddingHorizontal: 0,
      paddingTop: 0,
      marginLeft: 50,
      borderTopWidth: 0,
    },
    tableRow: {
      justifyContent: 'space-between',
    },
    table: {
      minHeight: 115,
      backgroundColor: 'transparent',
      borderTopWidth: 1,
      borderTopColor: 'color-basic-transparent-100',
      maxHeight: '100%',
    },
    modalBody: {
      maxHeight: '100%',
    },
    selectedRow: {
      backgroundColor: 'color-basic-transparent-0',
    },
    modalContainer: {
      justifyContent: 'flex-start',
    },
    pagination: {
      alignItems: 'center',
      position: 'relative',
    },
  });
  const bannerProps = useCallback(
    (bannerText: string): BannerProps => ({
      testID: 'banner-component-modal',
      children: bannerText,
      status: 'danger',
      actionAccessory: ({ dismissProps }) => (
        <View
          style={{
            justifyContent: 'flex-end',
            flexDirection: 'row',
            flex: 1,
            padding: 0,
          }}
        >
          <Button
            {...dismissProps}
            appearance="ghost"
            size="small"
            status="basic"
            testID="dismiss-button"
          >
            {translate<string>('DISMISS')}
          </Button>
        </View>
      ),
    }),
    [translate],
  );
  const isTankMix = filters.category === CropLogicComponentCategory.TANK_MIX;
  const category = {
    columnLabel: translate('TYPE'),
    columnKey: 'category',
    singleSelection: true,
    preserve: true,
    columns: [
      CropLogicComponentCategory.SEED,
      CropLogicComponentCategory.FERTILIZER,
      CropLogicComponentCategory.CHEMICAL,
      CropLogicComponentCategory.TANK_MIX,
    ].map(
      (component) => ({
        label: translate<string>(component),
        id: component,
      }),
    ),
  };

  const formulation = {
    columnLabel: translate('FORMULATION'),
    columnKey: 'formulation',
    columns: [
      {
        label: translate<string>(FormulationType.DRY),
        id: FormulationType.DRY,
      },
      {
        label: translate<string>(FormulationType.LIQUID),
        id: FormulationType.LIQUID,
      },
    ],
  };

  const subcategory = {
    columnLabel: translate('SUBTYPE'),
    columnKey: 'businessCategoryId',
    columns: [],
  };

  const customer = {
    columnLabel: translate('CUSTOMER'),
    columnKey: 'customer',
    columns: [],
  };

  const noCategoryFilters = [category.columnKey];
  const tankMixFilters = [category.columnKey, customer.columnKey];

  const defaultFilters = [category];
  const { createBanner } = useBanner();

  const { customerTotals, isSuccess } = useCustomerTotals({
    onError: () => {
      createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_FILTERS')));
      setFilterOptions(new Map());
    },
  });

  const updateFilters = async () => {
    const categories = await BusinessApi.getBusinessProductCategories(
      businessId,
    );
    const customers = (await GrowerApi.getGrowers({
      businessId,
      limit: maxListSize,
      search: customerSearch,
      sort: GrowerEndpoint.List.Sort.LEGAL_NAME,
    }));
    return { categories, customers };
  };

  useEffect(() => {
    if (components.length) {
      const newSelectedComponents = {};
      components.forEach((component) => {
        newSelectedComponents[component.productId || component.tankMixId] = true;
      });
      setCurrentComponents(newSelectedComponents);
    }
  }, [components]);

  useQuery(
    [QueryKeys.PRODUCT_FILTERS, category, subcategory, formulation],
    updateFilters,
    {
      enabled: isSuccess,
      onError: () => {
        createBanner(bannerProps(translate('ERROR_FETCH_PRODUCT_FILTERS')));
        setFilterOptions(new Map());
      },
      onSuccess: ({ categories, customers }) => {
        setAllCategories(categories);
        setAllCustomers(customers.data);
        const newFilterOptions = new Map();
        defaultFilters.forEach((val) => {
          newFilterOptions.set(val.columnKey, val);
        });
        setFilterOptions(newFilterOptions);
      },
    },
  );

  const findCategories = (type: CropLogicComponentCategory) => (allCategories.find(
    (x) => x.category === type,
  )?.subCategories || []).map(
    (x) => ({ label: x.name, id: x.id }),
  );

  const onQueryChanged = async (
    filter: {
      businessCategoryId: string[],
      category: CropLogicComponentCategory[],
      customer: string[],
      formulation: FormulationType[],
      search: string,
    },
  ) => {
    const categoryValue = filter.category && filter.category.length > 0 ? filter.category[0] : null;
    if (filters.category !== categoryValue) {
      setPage(0);
    }
    const updateFilterOptions: FilterCategory[] = [category];
    switch (categoryValue) {
      case CropLogicComponentCategory.SEED: {
        const columns = findCategories(CropLogicComponentCategory.SEED);
        const seedFilters = [
          category.columnKey,
        ];
        if (columns.length) {
          seedFilters.push(subcategory.columnKey);
          updateFilterOptions.push({
            ...subcategory,
            columns,
          });
        }
        setDefaultColumnFilters(seedFilters);
        break;
      }
      case CropLogicComponentCategory.CHEMICAL: {
        const columns = findCategories(CropLogicComponentCategory.CHEMICAL);
        const chemicalFilters = [
          category.columnKey,
        ];
        if (columns.length) {
          chemicalFilters.push(subcategory.columnKey);
          updateFilterOptions.push({
            ...subcategory,
            columns,
          });
        }
        chemicalFilters.push(formulation.columnKey);
        updateFilterOptions.push(formulation);
        setDefaultColumnFilters(chemicalFilters);
        break;
      }
      case CropLogicComponentCategory.FERTILIZER: {
        const columns = findCategories(CropLogicComponentCategory.FERTILIZER);
        const fertilizerFilters = [
          category.columnKey,
        ];
        if (columns.length) {
          fertilizerFilters.push(subcategory.columnKey);
          updateFilterOptions.push({
            ...subcategory,
            columns,
          });
        }
        fertilizerFilters.push(formulation.columnKey);
        updateFilterOptions.push(formulation);
        setDefaultColumnFilters(fertilizerFilters);
        break;
      }
      case CropLogicComponentCategory.TANK_MIX:
        setDefaultColumnFilters(tankMixFilters);
        updateFilterOptions.push({
          ...customer,
          columns: allCustomers.map(({ legalName, id }) => ({ label: legalName, id })),
          total: customerTotals,
          onSearch: setCustomerSearch,
        });
        break;
      default:
        setDefaultColumnFilters(noCategoryFilters);
        setTotalPages(0);
        setTotalResults(0);
        break;
    }
    let newFilters: ComponentFilter;

    if (filter.category?.[0] !== selectedCategory) {
      setSelectedCategory(categoryValue);
      const newFilterOptions = new Map();
      updateFilterOptions.forEach((val) => {
        newFilterOptions.set(val.columnKey, val);
      });
      setFilterOptions(newFilterOptions);

      let doesCustomerHaveProductMixes = false;
      if (
        filter.category?.[0] === CropLogicComponentCategory.TANK_MIX
        && customerId
      ) {
        const customerMixes = await TankMixApi.listTankMix({
          assignedGrowers: [customerId],
          businessId,
          isActive: ['true'],
          limit: 1,
        });
        doesCustomerHaveProductMixes = !!customerMixes.data.length;
      }
      setShouldPresetCustomer(doesCustomerHaveProductMixes);
      newFilters = {
        search: filter.search || '',
        category: filter.category?.[0],
        customer: doesCustomerHaveProductMixes ? [customerId] : [],
        formulation: [],
        businessCategoryId: [],
      };
    } else {
      newFilters = {
        search: filter.search || '',
        category: categoryValue,
        customer: filter.customer || [],
        formulation: filter.formulation || [],
        businessCategoryId: filter.businessCategoryId || [],
      };
    }

    setFilters(newFilters);
  };

  const getProducts = async () => {
    if (isTankMix) {
      const queryFilter = {
        cropType: [cropType],
        cropSubType: [cropSubType],
        ...(filters?.customer ? { assignedGrowers: filters.customer } : {}),
      };

      return TankMixApi.listTankMix({
        ...queryFilter,
        businessId,
        isActive: ['true'],
        page,
        search: filters.search,
      });
    }

    const queryFilters: ApiProductListQuery = {
      ...(filters?.category
        ? { category: filters.category as unknown as ProductCategory }
        : undefined),
      ...(filters?.formulation?.length
        ? { formulation: filters.formulation }
        : undefined),
      ...(filters?.businessCategoryId?.length
        ? { businessCategoryId: filters.businessCategoryId }
        : ''),
      businessId,
    };
    if (filters.category === CropLogicComponentCategory.SEED) {
      queryFilters.cropType = [cropType];
      queryFilters.cropSubType = [cropSubType];
    }
    return ProductApi.list({
      ...queryFilters,
      search: filters.search,
      page,
      sort: ApiProductListQuerySort.name,
    });
  };
  const { isLoading, isFetching } = useQuery(
    [QueryKeys.PRODUCT_FILTERS, filters, businessId, page],
    getProducts,
    {
      enabled: !!filters.category,
      onError: (err: Error) => {
        createBanner(
          bannerProps(`${translate('UNEXPECTED_ERROR', { error: err })}`),
        );
      },
      onSuccess: (res) => {
        const productsResult = res.data.map((product, idx) => ({
          ...product,
          rowId: `${filters?.category.toLowerCase()}-row:${idx}|${product.id}|`,
          hasDetails: true,
        }));
        setProducts(productsResult);
        setTotalResults(res.total);
        setTotalPages(res.lastPage + 1);
      },
    },
  );

  const toggleComponent = (id: string, selected: boolean) => {
    const component = Object.keys(currentComponents).includes(id);
    if (component) {
      setCurrentComponents({
        ...currentComponents,
        [id]: selected,
      });
    } else {
      setSelectedComponents({
        ...selectedComponents,
        [id]: selected,
      });
      if (selected) {
        setSelectedProducts([
          ...selectedProducts,
          {
            ...products.find((p) => p.id === id),
            category: filters.category,
          },
        ]);
      } else {
        setSelectedProducts([
          ...selectedProducts.filter((p) => p.id !== id),
        ]);
      }
    }
  };

  const getComponentPrice = (
    component,
  ): {
    displayPrice: number;
    displayPriceUom: string;
  } => {
    switch (isTankMix) {
      case false:
        if (component.prices) {
          return {
            displayPrice: CalculationUtility.getApplicableProductPrice(
              component,
              priceTypeId,
              businessLocationId,
            ),
            displayPriceUom: translate(component.unitUoM),
          };
        }
        return {
          displayPrice: 0,
          displayPriceUom: '',
        };
      case true:
        if (component?.components) {
          return {
            displayPrice: CalculationUtility.calculateTankMixComponentCostPerAcre(
              component,
              priceTypeId,
              businessLocationId,
            ).totalCostPerAcre,
            displayPriceUom: translate('AC'),
          };
        }
        return {
          displayPrice: 0,
          displayPriceUom: '',
        };
      default:
        return {
          displayPrice: 0,
          displayPriceUom: '',
        };
    }
  };

  const isValid = useMemo(() => {
    const hasChanged = Object.keys(currentComponents).some((x) => !currentComponents[x]);
    return Object.values(selectedComponents).some((x) => x) || hasChanged;
  }, [currentComponents, selectedComponents]);

  const saveComponents = () => {
    const currentIds = Object.keys({
      ...currentComponents,
    }).filter((x) => currentComponents[x]);

    const currentComponentsToUpdate = components.filter(
      (component) => currentIds.includes(component.productId)
        || currentIds.includes(component.tankMixId),
    );

    const selectedComponentsToAdd = selectedProducts
      .map((product): ApiCropLogicPassComponent => {
        const isComponentTankMix = product.category === CropLogicComponentCategory.TANK_MIX;
        const isAcre = !isComponentTankMix && product?.unitUoM === AreaUnitType.Acre;
        const isUnit = !isComponentTankMix && product?.unitUoM === AgriculturalUnit.Unit;
        const isAcreOrUnit = isAcre || isUnit;
        const rateUom = isAcreOrUnit
          ? (isAcre ? AreaUnitType.Acre : AgriculturalUnit.Unit)
          : null;

        return {
          createdAt: new Date(),
          componentCategory: product.category,
          id: generateId(),
          name: isComponentTankMix ? product.name : product.skuName,
          product: isComponentTankMix ? null : product,
          productId: isComponentTankMix ? null : product.id,
          rate: isAcre ? 1 : null,
          rateUom,
          tankMix: isComponentTankMix ? product : null,
          tankMixId: isComponentTankMix ? product.id : null,
          updatedAt: new Date(),
        };
      });

    onApply([...currentComponentsToUpdate, ...selectedComponentsToAdd]);
  };

  const defaultFilterValues = useMemo(() => (
    (isTankMix && shouldPresetCustomer)
      ? new Map([['customer', { [customerId]: true }]])
      : undefined
  ), [customerId, isTankMix, shouldPresetCustomer]);

  return (
    <Modal
      bodyStyle={styles.modalBody}
      containerStyle={styles.modalContainer}
      footerAccessory={({
        primaryButtonProp,
        secondaryButtonProp,
        spacerProp,
      }) => (
        <>
          <Button {...primaryButtonProp} onPress={onClose} testID="close-add-component-modal">
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            disabled={!isValid}
            onPress={saveComponents}
            testID="apply-add-component-modal"
          >
            {translate<string>('APPLY')}
          </Button>
        </>
      )}
      height={ModalHeight}
      hideCloseButton
      maxHeight
      onClose={onClose}
      scrollableContent
      subTitle={filters.category && translate('ADD_COMPONENT_SUBTITLE')}
      testID="add-component-modal"
      title={translate('ADD_COMPONENTS')}
      visible
      width={680}
    >
      {filterOptions && (
        <View style={styles.marginTopOverride}>
          <Filter
            autoClearSelections
            defaultColumnFilters={defaultColumnFilters}
            defaultFilters={defaultFilterValues}
            filterOptions={filterOptions}
            noFilters={false}
            onUpdateFilter={onQueryChanged}
            showMoreFiltersButton={false}
            testID="select-products-filter"
            totalResults={(isFetching || !filters.category) ? undefined : totalResults}
            totalResultsText={translate(totalResults === 1 ? 'COMPONENT_COUNT' : 'COMPONENTS_COUNT', {
              count: totalResults,
            })}
          />
        </View>
      )}
      {!filters.category ? (
        <>
          <VSpacer size="10" />
          <Text category="h6" style={styles.selectComponent}>
            {translate<string>('SELECT_COMPONENT')}
          </Text>
          <VSpacer size="14" />
        </>
      ) : (
        <>
          <Table style={styles.table} testID="select-products-table">
            {!isLoading && products.map((product, index) => {
              const { displayPrice, displayPriceUom } = getComponentPrice(product);

              return (
                <TableRow
                  key={product.id}
                  rowDetailStyle={styles.rowDetails}
                  style={[
                    styles.tableRow,
                    !!selectedComponents[product.id] && styles.selectedRow,
                  ]}
                  testID={`add-component-${index}`}
                >
                  <TableCell style={[styles.infoTableCell, { flex: 1 }]} testID={`name-and-toggle-${index}`}>
                    <ViewRow style={{ flex: 1 }}>
                      <CheckBox
                        checked={
                          !!selectedComponents[product.id] || !!currentComponents[product.id]
                        }
                        onChange={(on: boolean) => toggleComponent(product.id, on)}
                        testID={`select-checkbox-${index}`}
                      />
                      <HSpacer size="5" />
                      <Text category="p2" testID={`product-name-${index}`} wrap>
                        {product.skuName || product.name}
                      </Text>
                    </ViewRow>
                  </TableCell>
                  <TableCell
                    style={isTankMix && {
                      paddingHorizontal: 8,
                      paddingVertical: 8,
                      paddingRight: 0,
                    }}
                    testID={`info-${index}`}
                  >
                    <View style={styles.infoTableCell}>
                      <Text appearance="hint" category="p2" testID={`product-price-${index}`}>
                        {`${StringUtility.formatCurrency(displayPrice)}/${displayPriceUom}`}
                      </Text>
                      {isTankMix && (
                        <>
                          <HSpacer size="5" />
                          <IconButton
                            appearance="ghost"
                            onPress={() => {
                              setProductDetails(product);
                              setShowTankMix(true);
                            }}
                            size="medium"
                            status="info"
                            testID={`info-button-${index}`}
                          >
                            Info
                          </IconButton>
                        </>
                      )}
                    </View>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
          <VSpacer size="6" />
        </>
      )}
      {isFetching && <CenteredSpinner />}
      {showTankMix && (
        <TankMixDetailsModal
          businessLocationId={businessLocationId}
          onClose={() => setShowTankMix(false)}
          priceTypeId={priceTypeId}
          tankMix={productDetails}
          visible
        />
      )}
      <View style={styles.pagination}>
        {totalPages > 1 && (
          <Pagination
            currentPage={page + 1}
            displayPages={9}
            onChangePage={(selectedPage) => setPage(selectedPage - 1)}
            totalPages={totalPages}
          />
        )}
      </View>
    </Modal>
  );
};
