import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ApiProductCategory } from '@shared/interfaces/api';
import { ProductCategory } from '@shared/enums';
import { Chip, Header, HSpacer, Icon, TextLink, VSpacer } from '@design';
import { ProductCategoryModal } from './ProductCategoryModal';
import { ProductCategoryInputRow } from './ProductCategoryInputRow';

const styles = StyleSheet.create({
  chipParent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});

interface ProductCategoryListItemProps {
  canModify: boolean;
  caption?: string;
  category: ApiProductCategory;
  onSubCategoryAdded: (subCategoryName: string) => void;
  onSubCategoryRemoved: (productCategory: ProductCategory, name: string) => void;
}

export const ProductCategoryListItem = ({
  canModify,
  caption,
  category,
  onSubCategoryAdded,
  onSubCategoryRemoved,
}: ProductCategoryListItemProps) => {
  const [translate] = useTranslation(['businesses', 'common']);
  const [subCategoryText, setSubCategoryText] = useState('');
  const [showModal, setShowModal] = useState(false);

  const addSubCategory = () => {
    if (subCategoryText) {
      onSubCategoryAdded(subCategoryText);
      setSubCategoryText('');
    }
  };

  const removeSubCategory = (
    productCategory: ProductCategory,
    name: string,
  ) => onSubCategoryRemoved(productCategory, name);

  const abridgedSubcategories = category.subCategories.slice(0, 3);

  const isAbridged = category.subCategories.length > abridgedSubcategories.length;

  const viewMoreLength = category.subCategories.length - abridgedSubcategories.length;

  return (
    <>
      <Header
        level="3"
        testID={`category-header-${category.category}`}
        title={translate(category.category === ProductCategory.OTHER
          ? 'OTHER_PRODUCTS'
          : category.category)}
      />
      <VSpacer size="5" />
      <ProductCategoryInputRow
        addSubCategory={() => addSubCategory()}
        canModify={canModify}
        category={category}
        flexInput
        setSubCategoryText={setSubCategoryText}
        subCategoryText={subCategoryText}
      />
      <VSpacer size="6" />
      <View
        style={styles.chipParent}
        testID={`${category.category}-categories`}
      >
        {abridgedSubcategories.map((subcategory, index) => {
          return (
            <Chip
              disabled={!canModify}
              key={subcategory.name}
              onPress={() => removeSubCategory(category.category, subcategory.name)}
              style={index === 0 ? { marginLeft: 0 } : undefined}
              testID={`${category.category}-subCategory`}
            >
              {subcategory.name}
            </Chip>
          );
        })}
        {isAbridged && (
          <>
            <HSpacer size="3" />
            <TextLink
              accessoryLeft={(props) => <Icon name="Plus" testID={`${category.category}-view-more-link-icon`} {...props} />}
              appearance="secondary"
              category="p2"
              onPress={() => setShowModal(true)}
            >
              {translate<string>('VIEW_MORE', { length: viewMoreLength })}
            </TextLink>
          </>
        )}
      </View>
      <VSpacer size="6" />
      <VSpacer size="9" />
      {showModal && (
        <ProductCategoryModal
          canModifyBusiness={canModify}
          caption={caption}
          category={category}
          onDone={() => setShowModal(false)}
          onSubCategoryAdded={() => addSubCategory()}
          onSubCategoryRemoved={(productCategory: ProductCategory, name: string) => (
            removeSubCategory(productCategory, name)
          )}
          setSubCategoryText={setSubCategoryText}
          subCategoryText={subCategoryText}
        />
      )}
    </>
  );
};
