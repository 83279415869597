import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Card } from '@design';
import {
  CropColors,
  ECropColor,
} from '@theme/variant-interfaces/Colors';
import { DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  swatchContainer: {
    margin: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  swatchDimensions: {
    width: 125,
    height: 25,
  },
});

export const ColorDemo: FC = () => (
  <View style={{ alignSelf: 'stretch' }}>
    <Card style={{ flex: 1 }} testID="test-card">
      <DemoContainer label="Crop Colors">
        <View style={styles.container}>
          {CropColors.map((color) => (
            <View key={color} style={styles.swatchContainer}>
              <Text
                style={[styles.swatchDimensions, { backgroundColor: ECropColor[color] }]}
              />
              <Text category="c2">{color}</Text>
            </View>
          ))}
        </View>
      </DemoContainer>
    </Card>
  </View>
);
