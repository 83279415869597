/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/static-property-placement */
import React from 'react';
import {
  ImageProps,
  NativeSyntheticEvent,
  StyleSheet,
  TargetedEvent,
} from 'react-native';
import {
  FalsyText,
  RenderProp,
  TouchableWeb,
  TouchableWebElement,
  TouchableWebProps,
  Overwrite,
  LiteralUnion,
} from '@ui-kitten/components/devsupport';
import {
  Interaction,
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import { TextProps } from '@ui-kitten/components/ui/text/text.component';
import {
  FalsyIcon,
} from '../FalsyIcon/FalsyIcon';
import { HSpacer } from '../Spacer/HSpacer';

type TabStyledProps = Overwrite<StyledComponentProps, {
  appearance?: LiteralUnion<'default'>;
}>;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export interface TabProps extends TouchableWebProps, TabStyledProps {
  children?: React.ReactElement;
  title?: RenderProp<TextProps> | React.ReactText;
  leftIcon?: RenderProp<Partial<ImageProps>>;
  rightIcon?: RenderProp<Partial<ImageProps>>;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
}

export type TabElement = React.ReactElement<TabProps>;

@styled('RallyTab')
export class Tab extends React.Component<TabProps> {
  static defaultProps: Partial<TabProps> = {
    selected: false,
  };

  private onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.HOVER]);
    this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  private onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  private onPress = (): void => {
    this.props.onSelect && this.props.onSelect(!this.props.selected);
  };

  private getComponentStyle = (source: StyleType) => {
    const {
      textMarginVertical,
      textFontFamily,
      textFontSize,
      textFontWeight,
      textColor,
      iconWidth,
      iconHeight,
      iconMarginVertical,
      iconTintColor,
      ...containerParameters
    } = source;

    return {
      container: containerParameters,
      icon: {
        width: iconWidth,
        height: iconHeight,
        marginVertical: iconMarginVertical,
        tintColor: iconTintColor,
      },
      title: {
        marginVertical: textMarginVertical,
        fontFamily: textFontFamily,
        fontSize: textFontSize,
        fontWeight: textFontWeight,
        color: textColor,
      },
    };
  };

  public render (): TouchableWebElement {
    const { eva, style, title, leftIcon, rightIcon, ...touchableProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <TouchableWeb
        {...touchableProps}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onPress={this.onPress}
        style={[evaStyle.container, styles.container, style]}
      >
        {leftIcon && (
          <FalsyIcon
            component={leftIcon}
            style={[evaStyle.icon]}
          />
        )}
        {title && (
          <>
            <HSpacer size="5" />
            <FalsyText
              component={title}
              style={evaStyle.title}
            />
            <HSpacer size="5" />
          </>
        )}
        {rightIcon && (
          <FalsyIcon
            component={rightIcon}
            style={[evaStyle.icon]}
          />
        )}
      </TouchableWeb>
    );
  }
}
