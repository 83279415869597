import { Button, Dialog, Text } from '@design';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CustomerMissingRequestFieldsModalProps {
  onClose: () => void,
  visible: boolean,
}

export const CustomerMissingRequestFieldsModal = ({
  onClose,
  visible,
}: CustomerMissingRequestFieldsModalProps) => {
  const [translate] = useTranslation(['common', 'errors']);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp }) => (
        <Button
          {...primaryButtonProp}
          onPress={onClose}
          testID="customer-missing-required-fields-close-button"
        >
          {translate('CLOSE')}
        </Button>
      )}
      onClose={onClose}
      testID="customer-missing-required-fields-modal"
      title={translate('CUSTOMER_MISSING_FIELDS_MODAL_TITLE_DYNAMICS')}
      visible={visible}
    >
      <Text>{translate('ERROR_SAVING_ORDER_MISSING_CUSTOMER_FIELDS_DYNAMICS')}</Text>
    </Dialog>
  );
};
