import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  Card,
  CheckBox,
  Icon,
  LargeModal,
  Text,
  VSpacer,
} from '@design';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTranslation } from 'react-i18next';
import { ApiFarmPlan, ApiPriceType } from '@shared/interfaces/api';
import { FarmUtility } from '@shared/utils';
import { PlannedFarm } from '@shared/interfaces';
import { ButtonBar } from '../../../../components/shared/ButtonBar';
import {
  BusinessLocationSelect,
} from '../../../../components/shared/Input/BusinessLocationSelect';
import { BusinessUserSelect } from '../../../../components/shared/Input/BusinessUserSelect';
import { CropYearPickerSelect } from '../../../../components/shared/Input/CropYearPickerSelect';
import { CustomerSelect } from '../../../../components/shared/Input/CustomerSelect';
import { PriceTypeSelect } from '../../../../components/shared/Input/PriceTypeSelect';
import { FarmPlanNameInput } from './FarmPlanNameInput';
import { ChangePriceTypeConfirmationModal } from '../../../ProductOrder/ChangePriceTypeConfirmationModal';
import {
  CustomerContactSelect,
  CustomerContactMode,
} from '../../../../components/shared/Input/CustomerContactSelect';
import { useCustomerContacts } from '../../../../../hooks/useCustomerContacts';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: 489,
  },
  card: {
    flex: 1,
    backgroundColor: 'transparent',
    shadowColor: 'transparent',
  },
  banner: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
    padding: 0,
  },
});

interface FarmPlanModalProps {
  editMode?: boolean,
  farmPlan: ApiFarmPlan,
  onCancel(farmPlan: ApiFarmPlan): void,
  onFarmPlanChange: (farmPlan: ApiFarmPlan) => void,
  onSave(): void,
  plannedFarms: PlannedFarm[],
  priceType: ApiPriceType,
  readonlyCustomer?: boolean,
}

export const FarmPlanModal = ({
  editMode,
  farmPlan,
  onCancel,
  onFarmPlanChange,
  onSave,
  plannedFarms,
  priceType,
  readonlyCustomer,
}: FarmPlanModalProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const initialFarmPlan = useRef(farmPlan).current;
  const [nameValidated, setNameValidated] = useState(true);
  const [showChangePriceTypeModal, setShowChangePriceTypeModal] = useState(false);
  const [selectedPriceType, setSelectedPriceType] = useState<ApiPriceType>();
  const { total: totalCustomerContacts } = useCustomerContacts(farmPlan.growerId);

  const farmPlanRef = useRef<ApiFarmPlan>(farmPlan);
  useEffect(() => {
    farmPlanRef.current = farmPlan;
  }, [farmPlan]);

  const formValid = (): boolean => {
    const requiredFields = [
      farmPlan.businessId,
      farmPlan.cropYear,
      farmPlan.growerId,
      farmPlan.planName,
      farmPlan.userAccountId,
      nameValidated,
    ];

    const areFieldsValid = requiredFields.every(Boolean);

    return areFieldsValid;
  };

  const hasAppliedPrograms = useMemo(() => plannedFarms.some(
    (farm) => FarmUtility.getCropLogicsForFarm(farm).length > 0,
  ), [plannedFarms]);

  const handlePriceTypeSelect = (updatePriceType: ApiPriceType) => {
    if (updatePriceType.id === priceType.id) {
      return;
    }

    if (farmPlan.growerFarmPlanProductSummaries?.length > 0) {
      setSelectedPriceType(updatePriceType);
      setShowChangePriceTypeModal(true);
    } else {
      onFarmPlanChange({ ...farmPlanRef.current, priceTypeId: updatePriceType.id });
    }
  };

  const checkBillingShippingSame = () => {
    if (farmPlan.billingContactId === farmPlan.shippingContactId) {
      onFarmPlanChange({ ...farmPlanRef.current, shippingContactId: null });
    } else {
      onFarmPlanChange({
        ...farmPlanRef.current,
        shippingContact: farmPlan.billingContact,
        shippingContactId: farmPlan.billingContactId,
      });
    }
  };

  const showCustomerContactInputFields = farmPlan.growerId
    && totalCustomerContacts > 0;

  const formPage = [
    <View style={styles.container}>
      <KeyboardAwareScrollView>
        <Card style={styles.card} testID="farm-plan-modal-card">
          <Text category="label">
            {translate<string>('DETAILS')}
          </Text>
          <VSpacer size="9" />
          <FarmPlanNameInput
            farmPlanId={farmPlan.id}
            name={farmPlan.planName}
            onUpdateName={(planName) => {
              onFarmPlanChange({ ...farmPlanRef.current, planName });
            }}
            onUpdateValidate={setNameValidated}
            valid={nameValidated}
          />
          <VSpacer size="8" />
          <CustomerSelect
            customerId={farmPlan.growerId}
            disabled={hasAppliedPrograms}
            onUpdateCustomer={(customerId) => {
              onFarmPlanChange({
                ...farmPlanRef.current,
                billingContact: null,
                billingContactId: null,
                growerId: customerId,
                shippingContact: null,
                shippingContactId: null,
                userAccountId: '',
              });
            }}
            readonly={readonlyCustomer}
            testID={'farm-plan-modal-customer-select'}
          />
          <VSpacer size="8" />
          <BusinessUserSelect
            customerId={farmPlan.growerId}
            disabled={!farmPlan.growerId}
            label={translate('SALESPERSON')}
            onUpdateOwner={(userAccountId) => {
              onFarmPlanChange({ ...farmPlanRef.current, userAccountId });
            }}
            ownerId={farmPlan.userAccountId}
          />
          {showCustomerContactInputFields && (
            <View>
              <VSpacer size="8" />
              <CustomerContactSelect
                customerContactId={farmPlan.billingContactId}
                customerId={farmPlan.growerId}
                mode={CustomerContactMode.BILLING}
                onUpdateCustomerContact={(billingContactId, billingContact) => {
                  onFarmPlanChange({
                    ...farmPlanRef.current,
                    billingContact,
                    billingContactId,
                  });
                }}
                optionWindowMaxHeight={190}
                optionWindowPlacement="bottom"
              />
              <VSpacer size="8" />
              <CustomerContactSelect
                customerContactId={farmPlan.shippingContactId}
                customerId={farmPlan.growerId}
                mode={CustomerContactMode.SHIPPING}
                onUpdateCustomerContact={(shippingContactId, shippingContact) => {
                  onFarmPlanChange({
                    ...farmPlanRef.current,
                    shippingContact,
                    shippingContactId,
                  });
                }}
                optionWindowMaxHeight={245}
                optionWindowPlacement="top"
              />
              <VSpacer size="8" />
              <CheckBox
                checked={
                  farmPlan.billingContactId
                  && (farmPlan.billingContactId === farmPlan.shippingContactId)
                }
                onChange={checkBillingShippingSame}
                testID="billing-shipping-checkbox"
              >
                {translate<string>('SHIPPING_SAME_AS_BILLING')}
              </CheckBox>
            </View>
          )}
          <VSpacer size="8" />
          <BusinessLocationSelect
            locationId={farmPlan.businessLocationId ?? null}
            onUpdateLocation={(businessLocationId) => {
              onFarmPlanChange({ ...farmPlanRef.current, businessLocationId });
            }}
          />
          <PriceTypeSelect
            isRequired
            onUpdatePriceType={handlePriceTypeSelect}
            selectedPriceType={priceType}
          />
          <VSpacer size="8" />
          <CropYearPickerSelect
            cropYear={farmPlan.cropYear}
            onChange={(cropYear) => onFarmPlanChange({ ...farmPlanRef.current, cropYear })}
          />
        </Card>
        <ChangePriceTypeConfirmationModal
          newPriceType={selectedPriceType}
          oldPriceType={priceType}
          onCancel={() => setShowChangePriceTypeModal(false)}
          onConfirm={() => {
            setShowChangePriceTypeModal(false);
            onFarmPlanChange({ ...farmPlanRef.current, priceTypeId: selectedPriceType.id });
          }}
          visible={showChangePriceTypeModal}
        />
      </KeyboardAwareScrollView>
    </View>,
  ];

  return (
    <LargeModal
      footer={() => (
        <ButtonBar
          accessoryRight={
            editMode ? undefined : (iconsProps) => <Icon name="ArrowForward" testID="farm-plan-button-icon" {...iconsProps} />
          }
          buttonBarType="grouped"
          disableRightAction={!formValid()}
          leftAction={() => onCancel(initialFarmPlan)}
          leftButtonText={translate('CANCEL')}
          rightAction={onSave}
          rightButtonText={translate<string>(editMode ? 'DONE' : 'NEXT')}
          testID="farm-plan-button"
        />
      )}
      pages={formPage}
      testID="farm-plan-large-modal"
      title={translate<string>(editMode ? 'EDIT_DETAILS' : 'CREATE_FARM_PLAN')}
      visible
    />
  );
};
