/* eslint-disable react/sort-comp */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/state-in-constructor */
import React from 'react';
import { StyledComponentProps, Popover, Interaction, styled, StyleType, List } from '@ui-kitten/components';
import { TouchableWebProps, IndexPath, TouchableWeb, TouchableWebElement, FalsyText } from '@ui-kitten/components/devsupport';
import { View, Animated, StyleSheet, GestureResponderEvent, NativeSyntheticEvent, TargetedEvent, ListRenderItemInfo, TouchableWithoutFeedback } from 'react-native';
import { SelectItemDescriptor, SelectService } from '@ui-kitten/components/ui/select/select.service';
import { ChevronDown } from '@ui-kitten/components/ui/shared/chevronDown.component';
import { CropColor } from '@theme/variant-interfaces/Colors';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { ColorCircleElement, ColorCircleProps, ColorCircle } from './ColorCircle';

const styles = StyleSheet.create({
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cropCircle: {
    flex: 1,
  },
});

interface ColorSelectProps extends TouchableWebProps, StyledComponentProps {
  label: string;
  nativeID?: string;
  onSelect?: (index: IndexPath | IndexPath[]) => void;
  selectedIndex?: IndexPath | IndexPath[];
  testID: string;
  value?: CropColor;
}

type ColorSelectElement = React.ReactElement<ColorSelectProps>;

interface State {
  listVisible: boolean
}

const CHEVRON_DEG_COLLAPSED: number = -180;
const CHEVRON_DEG_EXPANDED: number = 0;
const CHEVRON_ANIM_DURATION: number = 200;

@styled('ColorSelect')
export class ColorSelect extends React.Component<ColorSelectProps, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    selectedIndex: [],
  };

  public state: State = {
    listVisible: false,
  };
  private service: SelectService = new SelectService();
  private popoverRef = React.createRef<Popover>();
  private expandAnimation: Animated.Value = new Animated.Value(0);

  private get data (): any[] {
    return React.Children.toArray(this.props.children || []);
  }

  private get selectedIndices (): IndexPath[] {
    if (!this.props.selectedIndex) {
      return [];
    }
    return Array.isArray(this.props.selectedIndex)
      ? this.props.selectedIndex
      : [this.props.selectedIndex];
  }

  private createExpandAnimation = (toValue: number):
  Animated.CompositeAnimation => Animated.timing(this.expandAnimation, {
    toValue,
    duration: CHEVRON_ANIM_DURATION,
    useNativeDriver: false,
  });

  private onListVisible = (): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    this.createExpandAnimation(-CHEVRON_DEG_COLLAPSED).start(() => {
      this.props.onFocus && this.props.onFocus(null);
    });
  };

  private onListInvisible = (): void => {
    this.props.eva.dispatch([]);
    this.createExpandAnimation(CHEVRON_DEG_EXPANDED).start(() => {
      this.props.onBlur && this.props.onBlur(null);
    });
  };

  private setOptionsListVisible = (): void => {
    this.setState({ listVisible: true }, this.onListVisible);
  };

  private setOptionsListInvisible = (): void => {
    this.setState({ listVisible: false }, this.onListInvisible);
  };

  private get expandToRotateInterpolation () {
    return this.expandAnimation.interpolate({
      inputRange: [CHEVRON_DEG_COLLAPSED, CHEVRON_DEG_EXPANDED],
      outputRange: [`${CHEVRON_DEG_COLLAPSED}deg`, `${CHEVRON_DEG_EXPANDED}deg`],
    });
  }

  public focus = (): void => {
    this.setOptionsListVisible();
  };

  public blur = (): void => {
    this.setOptionsListInvisible();
  };

  public isFocused = (): boolean => this.state.listVisible;

  public clear = (): void => {
    this.props.onSelect && this.props.onSelect(null);
  };

  private onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    const interactions = [Interaction.HOVER];
    if (this.state.listVisible) {
      interactions.push(Interaction.ACTIVE);
    }
    this.props.eva.dispatch(interactions);
    this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  private onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    const interactions = [];
    if (this.state.listVisible) {
      interactions.push(Interaction.ACTIVE);
    }
    this.props.eva.dispatch(interactions);
    this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  private onPress = (): void => {
    this.setOptionsListVisible();
  };

  private onPressIn = (event: GestureResponderEvent): void => {
    this.props.onPressIn && this.props.onPressIn(event);
  };

  private onPressOut = (event: GestureResponderEvent): void => {
    this.props.onPressOut && this.props.onPressOut(event);
  };

  private onItemPress = (descriptor: SelectItemDescriptor): void => {
    if (this.props.onSelect) {
      const selectedIndices = this.service.selectItem(false, descriptor, this.selectedIndices);
      this.setOptionsListInvisible();
      this.props.onSelect(selectedIndices);
    }
  };

  private onBackdropPress = (): void => {
    this.setOptionsListInvisible();
  };

  private getComponentStyle = (style: StyleType) => {
    const placeholder = PropsServiceHelper.allWithPrefixMapped(style, 'placeholder');
    const icon = PropsServiceHelper.allWithPrefixMapped(style, 'icon');
    const popover = PropsServiceHelper.allWithPrefixMapped(style, 'popover');
    const input = PropsServiceHelper.allWithPrefixMapped(style, 'input');
    const label = PropsServiceHelper.allWithPrefixMapped(style, 'label');
    return {
      icon,
      input,
      label,
      placeholder,
      popover,
    };
  };

  private cloneItemWithProps = (el: ColorCircleElement, props: ColorCircleProps):
  ColorCircleElement => {
    const nestedElements = React.Children
      .map(
        el.props.children as ColorCircleElement, (
          nestedEl: ColorCircleElement,
          index: number,
        ) => {
          const descriptor = this.service.createDescriptorForNestedElement(
            nestedEl, props.descriptor, index,
          );
          const selected: boolean = this.service.isSelected(descriptor, this.selectedIndices);
          return this.cloneItemWithProps(nestedEl, { ...props, descriptor, selected });
        },
      );
    return React.cloneElement(el, { ...props, ...el.props }, nestedElements);
  };

  private renderItem = (
    { item, index }: ListRenderItemInfo<ColorCircleElement>,
  ): ColorCircleElement => {
    const { testID, nativeID } = this.props;
    const descriptor = this.service.createDescriptorForElement(item, false, index);
    const selected: boolean = this.service.isSelected(descriptor, this.selectedIndices);

    return this.cloneItemWithProps(item, {
      descriptor,
      selected,
      onPress: this.onItemPress,
      nativeID: `${nativeID}-${index}`,
      testID: `${testID}-${index}`,
    });
  };

  private iconElement = (evaStyle: any): React.ReactElement => {
    const {
      tintColor,
      margin,
      marginHorizontal,
      marginVertical,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      ...svgStyle
    } = evaStyle;
    const marginStyle = {
      margin, marginHorizontal, marginLeft, marginRight, marginTop, marginBottom,
    };

    return (
      <View style={marginStyle}>
        <Animated.View
          style={{ transform: [{ rotate: this.expandToRotateInterpolation }] }}
        >
          <ChevronDown fill={tintColor} style={svgStyle} />
        </Animated.View>
      </View>
    );
  };

  private renderInputElement = (
    props: Omit<ColorSelectProps, 'label'>, evaStyle: StyleType,
  ): TouchableWebElement => {
    const { value } = this.props;

    return (
      <TouchableWeb
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onPress={this.onPress}
        onPressIn={this.onPressIn}
        onPressOut={this.onPressOut}
        style={StyleSheet.flatten([styles.input, evaStyle.input])}
        testID={props.testID}
      >
        <ColorCircle
          color={value}
          style={evaStyle.placeholder}
          testID="current-circle"
        />
        {this.iconElement(evaStyle.icon)}
      </TouchableWeb>
    );
  };

  render (): ColorSelectElement {
    const { eva, style, children, label, ...touchableProps } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);
    return (
      <View style={{ flexDirection: 'column' }}>
        <FalsyText
          appearance="hint"
          category="p2"
          component={label}
          style={evaStyle.label}
        />
        <TouchableWithoutFeedback
          onPress={this.onPress}
          style={style}
        >
          <Popover
            anchor={() => this.renderInputElement(touchableProps, evaStyle)}
            onBackdropPress={this.onBackdropPress}
            placement="bottom start"
            ref={this.popoverRef}
            style={evaStyle.popover}
            visible={this.state.listVisible}
          >
            <List
              bounces={false}
              contentContainerStyle={{ justifyContent: 'space-evenly' }}
              data={this.data}
              numColumns={6}
              renderItem={this.renderItem}
              style={{ backgroundColor: evaStyle.popover.backgroundColor }}
            />
          </Popover>
        </TouchableWithoutFeedback>
      </View>
    );
  }
}
