import * as _ from 'lodash';
import { ProductCategory } from '@shared/enums';

export enum Routes {
  ACCESS_DENIED = '/access-denied',
  AUTH = '/auth',
  BUSINESS_CREATE_LOCATION = '/businesses/:business/location/create',
  BUSINESS_DETAILS = '/business/:business/details/:tab',
  BUSINESS_EDIT = '/business/:business/edit',
  BUSINESS_EDIT_LOCATION = '/businesses/:business/location/:location/edit',
  BUSINESS_LIST = '/business',
  CREATE_PASSWORD = '/auth/createPassword',
  CUSTOMER_PLANNING = '/growers/:tab',
  DASHBOARD = '/dashboard',
  FARM_PLAN_CREATE = '/farm-plans/create',
  FARM_PLAN_CREATE_FROM_GROWER = '/growers/:grower/farm-plans/create',
  FARM_PLAN_EDIT = '/farm-plans/:id/edit',
  FARM_PLAN_EDIT_FROM_GROWER = '/growers/:grower/farm-plans/:id/edit',
  FARM_PLAN_OVERVIEW = '/growers/:grower/farm-plans/:id/edit/overview',
  FEATURE_FLAGS = '/feature-flags',
  FORGOT_PASSWORD = '/auth/forgot',
  GROWER_CREATE = '/growers/create',
  GROWER_DELIVERABLE = '/growers/:grower/deliverables/:id',
  GROWER_DELIVERABLE_PUBLIC = '/deliverables/:deliverableId',
  GROWER_DETAILS = '/growers/:grower/details/:tab',
  GROWER_EDIT = '/growers/:grower/edit',
  HELP = '/help',
  INTERNAL_BUSINESS_CREATE_LOCATION = '/business-locations/create',
  LOGGED_IN = '/',
  LOGIN = '/auth/login',
  LOGOUT = '/auth/logout',
  PREPARE_REVIEW = '/prepare-review/:tab?',
  PRODUCT_CREATE = '/products/create/:category',
  PRODUCT_EDIT = '/products/:id/edit',
  PRODUCT_LIST = '/products/:tab?',
  PROGRAM_CREATE = '/programs/create',
  PROGRAM_EDIT = '/programs/:id/edit',
  PUBLIC_HELP = '/public/help',
  REPORTS = '/reports',
  RESET_PASSWORD = '/auth/resetPassword',
  TANK_MIX_CREATE = '/tank-mixes/create',
  TANK_MIX_EDIT = '/tank-mixes/:id/edit',
  TANK_MIX_VIEW = '/tank-mixes/:id',
  USER_BUSINESS_CREATE = '/users/business/create',
  USER_CHANGE_PASSWORD = '/users/:id/changePassword',
  USER_LIST = '/users',
  UX_SANDBOX = '/ux-sandbox/:demo?',
  VERIFY_EMAIL = '/users/updateEmail',
  VERSION = '/version',
}

export const PublicRoutes = [
  Routes.GROWER_DELIVERABLE_PUBLIC,
  Routes.PUBLIC_HELP,
  Routes.VERSION,
];

const appendRedirect = (baseUrl, redirectUrl) => (_.isEmpty(redirectUrl)
  ? baseUrl
  : `${baseUrl}?redirectTo=${redirectUrl}`);

/**
 * Creates a url for the business detail page
 * @param businessId Id of the business
 * @param activeTab Optional name of the active that should be preselected
 */
export const getBusinessRoute = (businessId, activeTab?: string): string => Routes.BUSINESS_DETAILS
  .replace(/:business/, businessId).replace(/:tab/, activeTab || 'info');

export const getBusinessEditRoute = (businessId, redirectTo?: string): string => (
  appendRedirect(Routes.BUSINESS_EDIT.replace(/:business/, businessId), redirectTo)
);

export const getCreateBusinessLocationRoute = (businessId, redirectTo?: string): string => (
  appendRedirect(Routes.BUSINESS_CREATE_LOCATION.replace(/:business/, businessId), redirectTo)
);

export const getEditBusinessLocationRoute = (
  businessId: string,
  locationId: string,
  redirectTo?: string,
): string => {
  const baseUrl = Routes.BUSINESS_EDIT_LOCATION
    .replace(':business', businessId)
    .replace(':location', locationId);
  return appendRedirect(baseUrl, redirectTo);
};

/**
 * Creates a url for the business detail page
 * @param growerId Id of the business
 * @param activeTab Optional name of the active that should be preselected
 */
export const getGrowerRoute = (growerId, activeTab?: string): string => Routes.GROWER_DETAILS
  .replace(/:grower/, growerId).replace(/:tab/, activeTab || 'info');

export const getGrowerEditRoute = (growerId, redirectTo?: string): string => (
  appendRedirect(Routes.GROWER_EDIT.replace(/:grower/, growerId), redirectTo)
);

export const getProductEditRoute = (productId: string, redirectTo?: string) => {
  const baseUrl = Routes.PRODUCT_EDIT.replace(/:id/, productId);
  return appendRedirect(baseUrl, redirectTo);
};

export const getBusinessProductListPage = (tab: string = '') => Routes.PRODUCT_LIST
  .replace(/:tab\?/, tab);

export const getBusinessDetailListPageTab = (businessId: string, tab: string = 'info') => (
  Routes.BUSINESS_DETAILS
    .replace(/:business/, businessId)
    .replace(/:tab/, tab)
);

export const getBusinessProductCreateRoute = (
  category: ProductCategory,
) => Routes.PRODUCT_CREATE
  .replace(/:category/, category);

export const getBusinessProductEditRoute = (
  productId: string,
) => Routes.PRODUCT_EDIT
  .replace(/:id/, productId);

export const getTankMixEditRoute = (
  tankMixId: string,
) => Routes.TANK_MIX_EDIT
  .replace(/:id/, tankMixId);

export const getTankMixDuplicateRoute = (
  tankMixId: string,
) => `${Routes.TANK_MIX_EDIT}?duplicate`
  .replace(/:id/, tankMixId);

// TODO: add when hard delete feature is needed
/* export const getTankMixDeleteRoute = (
  tankMixId: string,
) => `${Routes.TANK_MIX_EDIT}/delete`
  .replace(/:id/, tankMixId); */
