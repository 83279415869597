import { NumericInput, Select, SelectItem } from '@design';
import { DiscountType } from '@shared/enums';
import { ApiDiscount } from '@shared/interfaces/api';
import { IndexPath } from '@ui-kitten/components';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

interface DiscountInputSelectProps<T> {
  discount: T,
  setDiscount: (discount: T) => void,
  testID: string,
}

type DiscountOptionsObjectType = {
  discountType: DiscountType,
  label: 'DOLLAR_SIGN' | 'PERCENT_SIGN',
};

type DiscountSelectionProps = Partial<Pick<ApiDiscount, 'discountType' | 'dollars' | 'percent'>>;

const discountOptionsArray: DiscountOptionsObjectType[] = [
  {
    discountType: DiscountType.DOLLARS,
    label: 'DOLLAR_SIGN',
  },
  {
    discountType: DiscountType.PERCENT,
    label: 'PERCENT_SIGN',
  },
];

const selectWidth = 68;

export const DiscountInputSelect = <T extends DiscountSelectionProps>({
  discount,
  setDiscount,
  testID,
}: DiscountInputSelectProps<T>) => {
  const [translate] = useTranslation(['businesses']);

  useEffect(() => {
    if (!discount.discountType) {
      setDiscount({ ...discount, discountType: DiscountType.DOLLARS });
    }
  }, [discount, setDiscount]);

  const discountOptions = useMemo(() => discountOptionsArray.map(({ discountType, label }, index) => (
    <SelectItem
      key={discountType}
      testID={`${testID}-discount-type-option-${discountType}-${index}`}
      title={translate(label)}
    />
  )), [testID, translate]);

  const onSelectDiscountType = (selection: IndexPath) => {
    const selectedDiscountType = discountOptionsArray[selection.row].discountType;
    if (discount.discountType === selectedDiscountType) {
      if (selectedDiscountType === DiscountType.DOLLARS) {
        setDiscount({ ...discount, percent: undefined });
      } else {
        setDiscount({ ...discount, dollars: undefined });
      }
    } else {
      setDiscount({
        ...discount,
        discountType: selectedDiscountType,
        dollars: undefined,
        percent: undefined,
      });
    }
  };

  return (
    <View style={{ flexDirection: 'row' }}>
      <Select
        inputStyle={{ borderTopRightRadius: 0, paddingLeft: 16, width: selectWidth }}
        label={translate('AMOUNT')}
        onSelect={(idx: IndexPath | IndexPath[]) => onSelectDiscountType(idx as IndexPath)}
        style={{ width: selectWidth }}
        testID={`${testID}-discount-type-select`}
        value={translate(
          discount.discountType === DiscountType.DOLLARS ? 'DOLLAR_SIGN' : 'PERCENT_SIGN',
        )}
      >
        {discountOptions}
      </Select>
      <View style={{ width: StyleSheet.hairlineWidth * 2 }} />
      <NumericInput
        errorMessage={translate('DISCOUNT_MISSING_VALUE')}
        isRequired
        isValidated
        keepLabelSpace
        onChangeValue={(value) => {
          if (discount.discountType === DiscountType.DOLLARS) {
            setDiscount({ ...discount, dollars: value, percent: undefined });
          } else {
            setDiscount({ ...discount, percent: value, dollars: undefined });
          }
        }}
        precision={discount.discountType === DiscountType.DOLLARS ? 2 : 1}
        style={{ borderTopLeftRadius: 0, flex: 1 }}
        testID={`${testID}-discount-amount-input`}
        value={discount.discountType === DiscountType.DOLLARS
          ? discount.dollars
          : discount.percent}
        waitForBlur
      />
    </View>
  );
};
