import { FormFieldType } from '@shared/enums';
import { ApiFormField } from '@shared/interfaces/api';

const DefaultProperty: ApiFormField = {
  createdAt: null,
  id: '',
  updatedAt: null,
  options: null,
  order: 0,
  name: '',
  type: FormFieldType.ShortAnswer,
};

export default DefaultProperty;
