import { Badge, HSpacer, Text } from '@design';
import { IntegrationType } from '@shared/enums';
import { Category } from '@theme/variant-interfaces/Category';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../hooks/useIntegration';

interface ExternalDisplayIdTextProps {
  externalDisplayId: string,
  textCategory?: Category,
}

export const ExternalDisplayIdText = ({
  externalDisplayId,
  textCategory = 'p2',
}: ExternalDisplayIdTextProps) => {
  const { currentBusinessId } = useAuthentication();
  const [translate] = useTranslation('integration');
  const { integration } = useIntegration({ businessId: currentBusinessId });

  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );
  if (!externalDisplayId) {
    return;
  }
  const isSalesQuote = externalDisplayId.includes('SQ');
  if (!isSalesQuote || !hasBusinessCentralIntegration) {
    return <Text category={textCategory}>{externalDisplayId}</Text>;
  }
  return (
    <Text category={textCategory}>
      {externalDisplayId}
      <HSpacer size="2" />
      <Badge status="basic" testID="business-central-order-badge">
        {translate(isSalesQuote ? 'QUOTE' : 'ORDER')}
      </Badge>
    </Text>
  );
};
