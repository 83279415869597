import { Button, HSpacer, Icon, Input } from '@design';
import { ApiProductCategory } from '@shared/interfaces/api';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ViewStyle } from 'react-native';

const styles = StyleSheet.create({
  inputRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});

interface InputRowProps {
  addSubCategory: () => void,
  canModify: boolean,
  category: ApiProductCategory,
  flexInput?: boolean,
  setSubCategoryText: Dispatch<SetStateAction<string>>,
  subCategoryText: string,
  style?: ViewStyle
}

export const ProductCategoryInputRow = (props: InputRowProps) => {
  const {
    addSubCategory,
    canModify,
    category,
    flexInput,
    setSubCategoryText,
    style,
    subCategoryText,
  } = props;
  const [translate] = useTranslation(['common', 'businesses']);
  return (
    <View style={style || styles.inputRow}>
      <View style={{ flex: flexInput ? 1 : 0.5 }}>
        <Input
          disabled={!canModify}
          label={translate<string>('SUBCATEGORY_NAME')}
          onChangeText={(text) => setSubCategoryText(text)}
          onSubmitEditing={() => subCategoryText && addSubCategory()}
          testID={`${category.category}-input`}
          value={subCategoryText}
        />
      </View>
      <HSpacer size="7" />
      <Button
        accessoryLeft={(prop) => <Icon name="Plus" testID={`${category.category}-add-button-icon`} {...prop} />}
        disabled={!subCategoryText}
        onPress={() => subCategoryText && addSubCategory()}
        size="medium"
        testID={`${category.category}-add-button`}
      >
        {translate<string>('ADD')}
      </Button>
    </View>
  );
};
