import React, { useState } from 'react';
import { View } from 'react-native';
import { DataPoint, Input, Card, Text } from '@design';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const DataPointDemo = () => {
  const [label, setLabel] = useState('Label');
  const [text, setText] = useState('Text here');

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card" title="Data Point">
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Input
            label="Label"
            onChangeText={setLabel}
            testID="test-input"
            value={label}
          />
          <Input
            label="Text"
            onChangeText={setText}
            testID="test-input"
            value={text}
          />
        </View>
        <DemoBlock>
          <DataPoint label={label} testID="test">{text}</DataPoint>
        </DemoBlock>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer style={{ justifyContent: 'space-around' }}>
          <DemoBlock>
            <DataPoint label="Label" testID="test">Text here</DataPoint>
          </DemoBlock>
          <DemoBlock label="Maximum container width 352px">
            <DataPoint label="Label" testID="test">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
              nulla pariatur.
            </DataPoint>
            <Text style={{ borderBottomColor: '#7F66FF', borderBottomWidth: 0.5, width: 352, paddingTop: 16 }} />
          </DemoBlock>
          <DemoBlock>
            <DataPoint flex label="Label" testID="test">
              <View style={{ flexDirection: 'row' }}>
                <Text>
                  This DataPoint will flex the width of the container
                </Text>
                <Text>
                  by adding the prop `flex` to the DataPoint
                </Text>
              </View>
            </DataPoint>
          </DemoBlock>
        </DemoContainer>
      </Card>
    </View>
  );
};
