import React from 'react';
import { View, ViewProps } from 'react-native';
import { ToastElement } from '../../Toast/Toast';
import { ToastManager, ToastManagerPresenting } from './ToastManager';

interface ToastManagerPanelState {
  components: Map<string, ToastElement>;
}

export class ToastManagerPanel extends React.Component<
{}, ToastManagerPanelState>
  implements ToastManagerPresenting {
  // eslint-disable-next-line react/state-in-constructor
  public state: ToastManagerPanelState = {
    components: new Map(),
  };

  public componentDidMount (): void {
    ToastManager.mount(this);
  }

  public componentWillUnmount (): void {
    ToastManager.unmount();
  }

  private areThereAnyComponents = (): boolean => {
    return this.state.components && this.state.components.size !== 0;
  };

  public add (element: ToastElement, id: string) {
    this.setState((prevState) => ({
      components: prevState.components.set(id, element),
    }));
  }

  public remove (id: string) {
    const { components } = this.state;
    components.delete(id);
    this.setState({ components });
  }

  private renderToast = (id: string): ToastElement => {
    return React.cloneElement(this.state.components.get(id), { key: id });
  };

  private renderToasts = (): ToastElement[] => {
    return Array.from(this.state.components.keys()).map(this.renderToast);
  };

  public render (): React.ReactElement<ViewProps> {
    return (
      <View nativeID="toast-manager-panel" style={{ alignItems: 'flex-end' }}>
        {this.areThereAnyComponents() && this.renderToasts()}
      </View>
    );
  }
}
