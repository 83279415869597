import React from 'react';
import {
  View,
  Animated,
  ViewProps,
  ImageProps,
  TextProps,
} from 'react-native';
import {
  styled,
  StyleType,
  StyledComponentProps,
} from '@ui-kitten/components';
import { FalsyFC, FalsyText, RenderProp, TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { IconButton } from '../IconButton/IconButton';
import { BannerAwareModal } from '../BannerAwareModal/BannerAwareModal';

interface SidePanelProps extends ViewProps, StyledComponentProps {
  header?: React.ReactText | RenderProp<TextProps>,
  headerAccessoryLeft?: RenderProp<Partial<ImageProps>>,
  headerAccessoryRight?: RenderProp<Partial<ImageProps>>,
  onClose?: () => void,
  subline?: React.ReactText | RenderProp<TextProps>,
  testID: string,
  visible?: boolean,
}

const ANIM_DURATION = 300;

interface State {
  visible: boolean,
}

@styled('SidePanel')
export class SidePanel extends React.Component<SidePanelProps, State> {
  private expandAnimation: Animated.Value;
  constructor (props: SidePanelProps) {
    super(props);
    this.expandAnimation = new Animated.Value(-(props.eva.style.surfaceWidth as number));
    this.state = { visible: false };
  }

  public open = (): void => {
    this.setState({ visible: true });
    Animated.timing(this.expandAnimation, {
      toValue: 0,
      duration: ANIM_DURATION,
      useNativeDriver: false,
    }).start();
  };

  public close = (): void => {
    Animated.timing(this.expandAnimation, {
      toValue: -(this.props.eva.style.surfaceWidth as number),
      duration: ANIM_DURATION,
      useNativeDriver: false,
    }).start(() => {
      this.setState({ visible: false });
      this.props.onClose?.();
    });
  };

  componentDidUpdate = (prevProps: SidePanelProps) => {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.open();
      }
      if (!this.props.visible) {
        this.close();
      }
    }
  };

  private getComponentStyle = (source: StyleType) => {
    const title = PropsServiceHelper.allWithPrefixMapped(source, 'title');
    const backdrop = PropsServiceHelper.allWithPrefixMapped(source, 'backdrop');
    const header = PropsServiceHelper.allWithPrefixMapped(source, 'header');
    const subline = PropsServiceHelper.allWithPrefixMapped(source, 'subTitle');
    const body = PropsServiceHelper.allWithPrefixMapped(source, 'body');
    const surface = PropsServiceHelper.allWithPrefixMapped(source, 'surface');

    if (this.props.headerAccessoryLeft) {
      title.paddingLeft = 0;
    }

    return {
      title,
      subline,
      backdrop,
      header,
      body,
      surface: {
        ...surface,
        ...source.elevation,
      },
    };
  };

  public render () {
    const {
      children,
      eva,
      header,
      headerAccessoryLeft,
      headerAccessoryRight,
      style,
      subline,
      visible, // take this out because our visible is different than uikmodal visible
      ...otherProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <BannerAwareModal
        backdropStyle={evaStyle.backdrop}
        // top/left set to 0.01 is sort of a hack. With the addition of global banner, the
        // the modal doesn't seem to get the correct measurements for how to place the
        // container. UI kitten allows us to overwrite the values, but unfortunately NOT
        // with the number 0 because it's falsey (see UIK Modal line 81). 0.01 on the other
        // hand is truthy, but too small, so it's effectively 0 when rendered
        style={{ height: '100%', width: '100%', overflow: 'hidden', top: 0.01, left: 0.01 }}
        visible={this.state.visible}
        {...otherProps}
      >
        {/*
          This is a little outside the normal modal flow. The Touchable element is playing the
          role of backdrop tap listener. Normally you would tap the modal backdrop itself, but
          the problem is we need to position the surface on the side of the screen and if we want
          to use position: absolute, then the parent container (see UIKModal style above) has to be
          100% width and height. This unfortunately means that the parent container will cover the
          backdrop entirely, so it becomes impossible to tap it directly.
        */}
        <TouchableWithoutFeedback onPress={this.close} style={{ height: '100%', width: '100%' }} />
        <Animated.View style={[evaStyle.surface, { right: this.expandAnimation }]}>
          <View style={evaStyle.header}>
            <FalsyFC
              appearance="ghost"
              component={headerAccessoryLeft}
              size="large"
              status="basic"
              style={{}}
            />
            <FalsyText component={header} style={evaStyle.title} testID="sidepanel-title" />
            <View style={{ flexDirection: 'row' }}>
              <FalsyFC
                appearance="ghost"
                component={headerAccessoryRight}
                size="large"
                status="basic"
                style={{}}
              />
              <IconButton appearance="ghost" onPress={this.close} size="large" status="basic" testID="close-button">
                Close
              </IconButton>
            </View>
          </View>
          <FalsyText component={subline} style={evaStyle.subline} testID="sidepanel-subline" />
          <View style={[evaStyle.body, style]}>
            {children}
          </View>
        </Animated.View>
      </BannerAwareModal>
    );
  }
}
