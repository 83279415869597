import {
  Button,
  Card,
  Header,
  HSpacer,
  Icon,
  IconButton,
  Input,
  NumericInput,
  VSpacer,
} from '@design';
import { ApiGrowerCropZone, ApiGrowerField, GrowerFarmEndpoint } from '@shared/interfaces/api';
import { FarmUtility } from '@shared/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { FarmCropZone } from './FarmCropZone';
import { defaultCropZone } from './helpers';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  nameInput: {
    flex: 1,
  },
  areaInput: {
    width: 120,
  },
  addCropZone: {
    alignItems: 'flex-start',
  },
});

interface FarmFieldProps {
  field: ApiGrowerField | GrowerFarmEndpoint.Create.Field,
  indexField?: number,
  onUpdateField(field: ApiGrowerField | GrowerFarmEndpoint.Create.Field): void,
  onRemoveField?(): void;
}

export function FarmField ({
  field,
  indexField,
  onUpdateField,
  onRemoveField,
}: FarmFieldProps) {
  const [translate] = useTranslation(['growers', 'common']);

  const hasCropZone = !!field.cropZones.length;

  const updateCropZone = (index: number, cropZone: ApiGrowerCropZone) => {
    const newCropZones = [...field.cropZones];
    newCropZones[index] = cropZone;
    const fieldToUpdate = {
      ...field,
      cropZones: newCropZones,
    };
    onUpdateField({
      ...fieldToUpdate,
      areaValue: FarmUtility.getFieldArea(fieldToUpdate),
    });
  };

  const removeCropZone = (index: number) => {
    const newCropZones = field.cropZones.filter((cropZone, idx) => idx !== index);
    const areaValue = FarmUtility.getFieldArea({
      ...field,
      cropZones: newCropZones,
    });
    onUpdateField({ ...field, cropZones: newCropZones.length > 1 ? newCropZones : [], areaValue });
  };

  const addCropZone = () => {
    const newCropZones = [...field.cropZones];
    if (hasCropZone) {
      newCropZones.push(defaultCropZone);
    } else {
      const value1 = Math.floor(field.areaValue / 2);
      const value2 = field.areaValue - value1;
      newCropZones.push({ ...defaultCropZone, areaValue: value2 });
      newCropZones.push({ ...defaultCropZone, areaValue: value1 });
    }
    const fieldToUpdate = {
      ...field,
      cropZones: newCropZones,
    };
    onUpdateField({
      ...fieldToUpdate,
      areaValue: FarmUtility.getFieldArea(fieldToUpdate),
    });
  };

  const [fieldNameError, setFieldNameError] = useState(false);
  const [areaValueError, setAreaValueError] = useState(false);

  return (
    <Card testID={`field-card-${indexField}`}>
      <View style={styles.row}>
        <Input
          isRequired
          label={translate<string>('FIELD_NAME')}
          onBlur={() => setFieldNameError(!field.fieldName.trim())}
          onChangeText={(fieldName) => {
            onUpdateField({ ...field, fieldName });
          }}
          status={fieldNameError ? 'danger' : 'basic'}
          style={styles.nameInput}
          testID={`farm-field-name-${indexField}`}
          value={field.fieldName}
        />
        <HSpacer size="7" />
        <NumericInput
          caption={hasCropZone ? translate<string>('READ_ONLY') : undefined}
          isValidated={false}
          label={translate<string>('ACRES')}
          onBlur={() => setAreaValueError(!field.areaValue)}
          onChangeValue={hasCropZone ? undefined : (areaValue) => {
            onUpdateField({ ...field, areaValue });
          }}
          precision={3}
          readOnlyLabel={false}
          readonly={hasCropZone}
          status={(areaValueError && !hasCropZone) ? 'danger' : 'basic'}
          style={styles.areaInput}
          testID={`farm-field-areaValue-${indexField}`}
          value={FarmUtility.getFieldArea(field)}
        />
        <HSpacer size="5" />
        <View style={styles.column}>
          <VSpacer size="7" />
          <VSpacer size="2" />
          <IconButton
            appearance="ghost"
            onPress={onRemoveField}
            size="medium"
            status="basic"
            testID="farm-field-delete-button"
          >
            Trash
          </IconButton>
        </View>
      </View>
      <VSpacer size="3" />
      {hasCropZone && (
        <>
          <Header level="4" testID="crop-zones-header" title={translate('CROP_ZONES')} />
          {field.cropZones.map((cropZone, index) => (
            <FarmCropZone
              cropZone={cropZone}
              index={index}
              key={cropZone.id ?? index}
              onRemoveCropZone={() => removeCropZone(index)}
              onUpdateCropZone={(newCropZone) => updateCropZone(index, newCropZone)}
            />
          ))}
        </>
      )}
      <View style={styles.addCropZone}>
        <Button
          accessoryLeft={(props) => <Icon name="Plus" testID="crop-zone-add-button-icon" {...props} />}
          appearance="ghost"
          disabled={!field.fieldName}
          onPress={addCropZone}
          size="medium"
          status="basic"
          testID="crop-zone-add-button"
        >
          {translate<string>('ADD_CROP_ZONE')}
        </Button>
      </View>
    </Card>
  );
}
