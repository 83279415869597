import * as _ from 'lodash';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  ViewProps,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { CheckBox, Icon, Popover } from '@ui-kitten/components';
import { Sizing, ThemeColors } from '../../../constants';
import { testId } from '../../../utilities/testId';

const styles = StyleSheet.create({
  inputContainer: {
    backgroundColor: ThemeColors.DARK_GRAY,
    borderTopLeftRadius: Sizing.QUARTER_SPACING,
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    marginBottom: Sizing.DOUBLE_SPACING,
    borderBottomColor: ThemeColors.DARK_GRAY,
    borderBottomWidth: 3,
    height: 3.5 * Sizing.EM,
  },
  touchable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  input: {
    color: ThemeColors.TEXT,
    fontSize: 1.1 * Sizing.BASE_SPACING,
    paddingHorizontal: 0.75 * Sizing.BASE_SPACING,
    paddingTop: 1.75 * Sizing.BASE_SPACING,
    paddingBottom: Sizing.QUARTER_SPACING,
  },
  selected: {
    color: ThemeColors.TEXT,
    fontSize: 1.1 * Sizing.BASE_SPACING,
    paddingHorizontal: 0.75 * Sizing.BASE_SPACING,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  focused: { borderBottomColor: ThemeColors.PRIMARY },
  danger: { borderBottomColor: ThemeColors.DANGER },
  label: {
    color: ThemeColors.INPUT,
    fontSize: 0.8 * Sizing.EM,
    position: 'absolute',
    top: Sizing.HALF_SPACING,
    left: Sizing.BASE_SPACING,
  },
  caption: {
    position: 'absolute',
    top: 3.5 * Sizing.BASE_SPACING,
    fontSize: 0.8 * Sizing.EM,
    fontStyle: 'italic',
    marginTop: 0.5 * Sizing.QUARTER_SPACING,
    color: ThemeColors.INPUT,
    paddingLeft: Sizing.BASE_SPACING,
  },
  focusedText: { color: ThemeColors.PRIMARY },
  icon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
  },
  expandIcon: {
    width: 1.5 * Sizing.EM,
    height: 1.5 * Sizing.EM,
    position: 'absolute',
    right: Sizing.HALF_SPACING,
    top: Sizing.BASE_SPACING,
  },
  dropdown: {
    borderWidth: 0,
    borderRadius: Sizing.QUARTER_SPACING,
    maxHeight: 10 * Sizing.EM,
    flexGrow: 0,
  },
  dropdownItem: {
    backgroundColor: ThemeColors.DARK_GRAY,
    padding: Sizing.BASE_SPACING,
  },
  dropdownMultipleItem: {
    backgroundColor: ThemeColors.DARK_GRAY,
    padding: Sizing.BASE_SPACING,
    flexDirection: 'row',
  },
  dropdownItemText: { color: ThemeColors.TEXT },
  dropdownMultipleItemText: {
    color: ThemeColors.TEXT,
    paddingLeft: Sizing.HALF_SPACING,
  },
  inline: {
    marginBottom: 0,
    height: 2.5 * Sizing.EM,
  },
  inlineInput: {
    paddingTop: Sizing.HALF_SPACING,
    paddingBottom: Sizing.HALF_SPACING,
  },
  expandIconInline: { top: Sizing.HALF_SPACING },
});

export type SelectOption<T = any> = {
  label: string,
  value: T,
  selected?: boolean,
};

export interface SelectProps<T = any> extends ViewProps {
  label?: string;
  caption?: string;
  status?: string;
  disabled?: boolean,
  inline?: boolean,
  clearable?: boolean,
  options?: SelectOption<T>[];
  onSelect?: (selection: T | T[]) => void,
  multiSelect?: boolean;
  style?: StyleProp<ViewStyle>;
  numOfLines?: number,
}

export const StyledSelect = (props: SelectProps) => {
  const { options } = props;
  const [focused, setFocused] = useState(false);
  const popoverRef = useRef();
  const [listVisible, setListVisible] = useState(false);

  useEffect(() => {
    if (focused) {
      setListVisible(focused);
    }
  }, [focused]);

  const onBackdropPress = useCallback(() => {
    setListVisible(false);
  }, []);

  const selectOption = (index: number) => {
    if (props.onSelect) {
      props.onSelect(options[index]);
    }
    setFocused(false);
    setListVisible(false);
  };

  const toggleOption = (index: number) => {
    if (props.multiSelect) {
      options[index].selected = !options[index].selected;
      if (props.onSelect) {
        const selectedValues = _.filter(options, 'selected')
          .map((option) => option.value);
        props.onSelect(selectedValues);
      }
    }
  };

  const hasNoOptionSelected = (): boolean | string => (_.every(options, { selected: false }))
        && props.label;

  const renderInputElement = () => (
    <View style={[
      styles.inputContainer,
      focused && styles.focused,
      props.status === 'danger' && styles.danger,
      props.disabled && { opacity: 0.5 },
      props.inline && styles.inline,
    ]}
    >
      {(!props.clearable || !_.find(options, { selected: true })) && (
        <Icon
          fill={ThemeColors.LIGHT_GRAY}
          name="chevron-down-outline"
          style={[
            styles.expandIcon,
            props.inline && styles.expandIconInline,
            props.numOfLines && { flex: 0.25 },
          ]}
        />
      )}
      {!props.inline && props.label && (
      <Text
        nativeID={props.label}
        style={[
          styles.label,
          focused && styles.focusedText,
        ]}
      >
        {(_.find(options, { selected: true })
          || !_.every(options, { selected: false }))
            && props.label}
      </Text>
      )}
      <Text
        {...testId(`${props.nativeID}-value`)}
        numberOfLines={props.multiSelect ? 1 : props.numOfLines ?? undefined}
        style={[
          hasNoOptionSelected() ? styles.selected : styles.input,
          props.inline && styles.inlineInput,
          (props.multiSelect || props.numOfLines)
          && { paddingRight: Sizing.DOUBLE_SPACING },
        ]}
      >
        {hasNoOptionSelected()}
        {
        props.multiSelect
          ? _.filter(options, { selected: true }).map((option) => option.label).join(', ')
          : _.get(_.find(options, { selected: true }), 'label')
        }
      </Text>
      <TouchableOpacity
        {...testId(props.nativeID)}
        {...props}
        onBlur={() => setFocused(false)}
        onPress={() => setFocused(!focused)}
        style={[
          styles.touchable,
          props.style,
        ]}
      />
      {(props.clearable && _.find(options, { selected: true })) && (
        <TouchableOpacity
          onPress={() => {
            if (props.onSelect) {
              props.onSelect({ value: null });
            }
          }}
          style={[
            styles.expandIcon,
            props.inline && styles.expandIconInline,
          ]}
        >
          <Icon
            fill={ThemeColors.LIGHT_GRAY}
            name="close-outline"
            style={styles.icon}
          />
        </TouchableOpacity>
      )}
      {!props.inline && props.caption !== '' && (
      <Text
        nativeID={props.caption}
        style={[
          styles.caption,
          focused && styles.focusedText,
        ]}
      >
        {props.caption}
      </Text>
      )}
    </View>
  );

  const renderOption = (option, index) => {
    if (props.multiSelect) {
      return (
        <TouchableOpacity
          key={option.label}
          onPress={() => toggleOption(index)}
          style={styles.dropdownMultipleItem}
        >
          <CheckBox
            checked={option.selected}
            onChange={() => toggleOption(index)}
          />
          <Text
            nativeID={option.label}
            style={styles.dropdownMultipleItemText}
            testID={option.label}
          >
            {option.label}
          </Text>
        </TouchableOpacity>
      );
    }
    return (
      <TouchableOpacity
        key={`${option.label}-${option.value}`}
        onPress={() => selectOption(index)}
        style={styles.dropdownItem}
      >
        <Text
          nativeID={props.nativeID && `${props.nativeID}-${index}`}
          numberOfLines={props.numOfLines}
          style={styles.dropdownItemText}
          testID={props.nativeID && `${props.nativeID}-${index}`}
        >
          {option.label}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <Popover
        anchor={() => renderInputElement()}
        fullWidth
        onBackdropPress={onBackdropPress}
        ref={popoverRef}
        style={[styles.dropdown, props.style]}
        visible={listVisible}
      >
        <ScrollView
          style={[styles.dropdown, props.style]}
          testID={props.nativeID && `${props.nativeID}-list`}
        >
          {options.map((option, i) => (
            renderOption(option, i)
          ))}
        </ScrollView>
      </Popover>
    </>
  );
};
