import React, { FunctionComponent, ReactNode } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { Text } from '../../ui-components';
import { Sizing, ThemeColors } from '../../constants';

const styles = StyleSheet.create({
  tableHead: {
    flexDirection: 'row',
    backgroundColor: ThemeColors.MID_GRAY,
    borderTopLeftRadius: Sizing.HALF_SPACING,
    borderTopRightRadius: Sizing.HALF_SPACING,
  },
  tableHeaderText: {
    fontWeight: 'bold',
    alignItems: 'center',
    display: 'flex',
    color: ThemeColors.TEXT,
  },
  tableRow: {
    borderBottomColor: ThemeColors.MID_GRAY,
    borderBottomWidth: 1,
    flexDirection: 'row',
  },
  tableCell: {
    flexDirection: 'row',
    padding: Sizing.HALF_SPACING,
  },
});

interface SimpleTableHeaderProps {
  children: ReactNode,
}

export const SimpleTableHeader = ({ children }: SimpleTableHeaderProps) => (
  <View style={[styles.tableHead]}>
    {children}
  </View>
);

interface FlexCell { flex: number }
interface WidthCell { width: number }

type TableCellProps = (FlexCell | WidthCell) & {
  align?: 'left' | 'right';
} & ViewProps;

function isFlexCell (c: TableCellProps): c is FlexCell {
  return Object.hasOwnProperty.call(c, 'flex');
}

export const SimpleTableRow: FunctionComponent<ViewProps> = ({ children, style, ...viewProps }) => (
  <View style={[styles.tableRow, style]} {...viewProps}>
    {children}
  </View>
);

export const SimpleTableCell: FunctionComponent<TableCellProps> = ({
  children,
  style,
  align = 'left',
  ...cellProps
}) => (

  <View
    style={[
      styles.tableCell,
      {
        flex: isFlexCell(cellProps) ? cellProps.flex : null,
        width: !isFlexCell(cellProps) ? cellProps.width : null,
        justifyContent: align === 'right' ? 'flex-end' : null,
      },
      style,
    ]}
    {...cellProps}
  >
    {children}
  </View>
);

type TableHeaderCellProps = TableCellProps & {
  label: string;
};

export const SimpleTableHeaderCell: FunctionComponent<TableHeaderCellProps> = ({
  label,
  ...cellProps
}) => (
  <SimpleTableCell {...cellProps}>
    <Text style={[styles.tableHeaderText]}>
      {label}
    </Text>
  </SimpleTableCell>
);
