export type Status = 'basic' | 'primary' | 'info' | 'warning' | 'danger' | 'success';
export const Statuses: Status[] = [
  'basic',
  'primary',
  'info',
  'warning',
  'danger',
  'success',
];
Statuses.sort();

export type BannerStatus = 'info' | 'success' | 'warning' | 'danger' | 'default';
export const BannerStatuses: BannerStatus[] = [
  'info',
  'success',
  'warning',
  'danger',
  'default',
];
Statuses.sort();

export type BadgeStatus = 'basic' | 'success' | 'info' | 'warning' | 'danger';
export const BadgeStatuses: BadgeStatus[] = [
  'basic',
  'success',
  'info',
  'warning',
  'danger',
];
BadgeStatuses.sort();

export type ChipStatus = 'default' | 'primary';
export const ChipStatuses: ChipStatus[] = [
  'default',
  'primary',
];
Statuses.sort();

export type InputStatus = 'basic' | 'primary' | 'info' | 'warning' | 'danger' | 'success' | 'none';
export const InputStatuses: InputStatus[] = [
  'basic',
  'primary',
  'info',
  'warning',
  'danger',
  'success',
  'none',
];
InputStatuses.sort();
