import { Card, HSpacer, Modal, Text, TextLink, VSpacer, Header } from '@design';
import { ApiUserAccount } from '@shared/interfaces/api';
import {
  FarmPlanDeliverableV2,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v2/FarmPlanDeliverableV2';
import { useStyleSheet } from '@ui-kitten/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { BillingShippingContactsModal } from '../../components/shared/BillingShippingContactsModal/BillingShippingContactsModal';
import { StringUtility } from '../../../utilities';
import DescriptionList, {
  DescriptionListDirection,
} from '../../components/shared/DescriptionList/DescriptionList';
import DescriptionListItem from '../../components/shared/DescriptionList/DescriptionListItem';
import {
  FarmPlanAcresPlanned,
} from '../CustomerPlanning/FarmPlanTab/FarmPlanOrderDetails/FarmPlanAcresPlanned';

const styles = StyleSheet.create({
  card: {
    flex: 1,
    minHeight: 254,
    minWidth: 200,
  },
  rowSpacing: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  acresPlanned: {
    ...{
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
});

interface FarmPlanDeliverableSummaryProps {
  note?: string;
  selections: FarmPlanDeliverableV2.Selections;
  snapshot: FarmPlanDeliverableV2.Snapshot;
  user: ApiUserAccount;
}

export const FarmPlanDeliverableSummary = ({
  note,
  selections,
  snapshot,
  user,
}: FarmPlanDeliverableSummaryProps) => {
  const [translate] = useTranslation(['common', 'growers', 'deliverable', 'farmPlans']);
  const themeStyle = useStyleSheet({
    horizontalLine: {
      borderBottomColor: 'color-basic-transparent-100',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
  });

  const stacked = DescriptionListDirection.STACKED;
  const localize = StringUtility.localizeNumber;
  const formatCurrency = StringUtility.formatCurrencyAccounting;
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [showMoreCrops, setShowMoreCrops] = useState(false);
  const { overview, header, acresPlannedSummary } = snapshot;

  const acresPlanned = localize(snapshot.overview.acresPlanned, 0);
  const totalAcres = localize(snapshot.header.totalGrowerAcres, 0);
  const acresPlannedText = `${acresPlanned}/${totalAcres}`;

  const crops = Object.keys(overview.acresPlannedPerCrop).map((crop) => ({
    crop,
    areaValue: overview.acresPlannedPerCrop[crop],
  })).sort((a, b) => a.crop.localeCompare(b.crop));

  const abridgedCrops = crops.slice(0, 3);

  const isAbridged = crops.length !== abridgedCrops.length;

  const renderCrops = (input) => {
    return input.map((crop, idx) => {
      const percent = Math.floor(((crop.areaValue / overview.acresPlanned) * 100));
      return (
        <View
          key={crop.crop}
          style={[styles.rowSpacing]}
        >
          <Text category="p2" numberOfLines={1} style={{ flex: 1 }} testID={`crop-value-${idx}`}>
            {crop.crop}
          </Text>
          <HSpacer size="5" />
          <Text
            category="p2"
            numberOfLines={1}
            style={{ textAlign: 'right' }}
            testID={`crop-area-value-${idx}`}
          >
            {`${translate('CROPS_PERCENT_AREA_VALUE', {
              percent,
              areaValue: localize(crop.areaValue, 0),
            })}`}
          </Text>
        </View>
      );
    });
  };

  const hasDiscountNonZero = !!(
    snapshot.overview.discountTotal || snapshot.overview.discountPerAcre
  );

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {/* Details */}
      <View style={{ flex: 1, minWidth: 200 }}>
        <Header
          level="3"
          testID="farm-plan-details"
          title={translate('DETAILS')}
        />
        <VSpacer size="4" />
        <Card style={styles.card} testID="details-card">
          <View>
            <DescriptionListItem
              label={translate('CUSTOMER')}
              testID="grower-name"
              text={header.growerName}
              textCategory="p2"
            />
            {!!header.externalId && (
              <>
                <VSpacer size="3" />
                <DescriptionListItem
                  label={translate('CUSTOMER_ID')}
                  testID="customer-id"
                  text={header.externalId}
                  textCategory="p2"
                />
              </>
            )}
            <VSpacer size="3" />
            <DescriptionListItem
              label={translate('SALESPERSON')}
              testID="business-user-name"
              text={header.businessUserName}
              textCategory="p2"
            />
            <VSpacer size="3" />
            <DescriptionListItem
              label={translate('BUSINESS')}
              testID="business-name"
              text={header.businessName}
              textCategory="p2"
            />
            {!!user?.telephone && (
              <>
                <VSpacer size="3" />
                <DescriptionListItem
                  label={translate('PHONE')}
                  testID="phone"
                  text={user.telephone}
                  textCategory="p2"
                />
              </>
            )}
            {!!header.locationName && (
              <>
                <VSpacer size="3" />
                <DescriptionListItem
                  label={translate('LOCATION')}
                  testID="business-location-name"
                  text={header.locationName}
                  textCategory="p2"
                />
              </>
            )}
            {selections.pricingDetails.priceType && (
              <>
                <VSpacer size="3" />
                <DescriptionListItem
                  label={translate('PRICE_TYPE')}
                  testID="price-type"
                  text={String(header.priceTypeName)}
                  textCategory="p2"
                />
              </>
            )}
            {header.cropYear && (
              <>
                <VSpacer size="3" />
                <DescriptionListItem
                  label={translate('CROP_YEAR')}
                  testID="crop-year"
                  text={String(header.cropYear)}
                  textCategory="p2"
                />
              </>
            )}
            <>
              <VSpacer size="3" />
              <DescriptionListItem
                label={translate('BILLING_SHIPPING_CONTACTS')}
                testID="deliverable-billing-shipping"
                text={(props) => (
                  <TextLink
                    appearance="secondary"
                    {...props}
                    onPress={() => setIsContactModalVisible(true)}
                    testID="basic-info-billing-shipping-view-more"
                  >
                    {translate<string>('VIEW')}
                  </TextLink>
                )}
                textCategory="p2"
              />
            </>
            {!!note && (
              <>
                <VSpacer size="3" />
                <DescriptionListItem
                  label={translate('NOTES')}
                  testID="notes"
                  textCategory="p2"
                >
                  <TextLink
                    appearance="secondary"
                    category="p2"
                    onPress={() => setIsNoteVisible(true)}
                  >
                    {`${translate('VIEW_NOTE')}`}
                  </TextLink>
                </DescriptionListItem>
              </>
            )}
          </View>
        </Card>
      </View>
      <HSpacer size="7" />
      {/* Acres Planned */}
      {!acresPlannedSummary ? (
        <View style={{ flex: 1, minWidth: 200 }}>
          <Header
            level="3"
            testID="farm-plan-acres-planned"
            title={translate('ACRES_PLANNED')}
          />
          <VSpacer size="4" />
          <Card style={styles.card} testID="acres-planned-card">
            <DescriptionList direction={stacked}>
              <DescriptionListItem
                label={translate('TOTAL_ACRES_PLANNED')}
                testID="acres-planned-total-summary"
                text={acresPlannedText}
                textCategory="p2"
              />
              <DescriptionListItem
                label={translate('NUMBER_OF_PASSES')}
                testID="number-of-passes"
                text={`${overview.numberOfPasses}`}
                textCategory="p2"
              />
            </DescriptionList>
            <VSpacer size="5" />
            <View style={{ flex: 1 }}>
              <Text appearance="hint" category="c1">
                {translate('CROPS')}
              </Text>
              {renderCrops(abridgedCrops)}
              {showMoreCrops && (
                <Modal
                  onClose={() => setShowMoreCrops(false)}
                  testID="crops-modal"
                  title={translate('CROPS')}
                  visible
                >
                  {renderCrops(crops)}
                </Modal>
              )}
              {isAbridged && (
                <View style={{ justifyContent: 'flex-end', minHeight: 32 }}>
                  <TextLink
                    appearance="secondary"
                    category="c2"
                    onPress={() => setShowMoreCrops(true)}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    {translate('SHOW_ALL_CROPS')}
                  </TextLink>
                </View>
              )}
            </View>
          </Card>
        </View>
      ) : (
        <FarmPlanAcresPlanned
          acresPlannedSummary={acresPlannedSummary}
          passes={overview.numberOfPasses}
        />
      )}
      <HSpacer size="7" />
      {/*  Cost Summary */}
      {selections.pricingDetails.costSummary && (
        <View style={{ flex: 1, minWidth: 200 }}>
          <Header
            level="3"
            testID="farm-plan-cost-summary"
            title={translate('COST_SUMMARY')}
          />
          <VSpacer size="4" />
          <Card style={styles.card} testID="cost-summary-card">
            {hasDiscountNonZero && (
              <View>
                <View style={styles.rowSpacing}>
                  <Text>{`${translate('RETAIL_PRICE')}`}</Text>
                  <Text
                    style={{ textDecorationLine: 'line-through' }}
                    testID="expected-cost-total"
                  >
                    {formatCurrency(overview.cost)}
                  </Text>
                </View>
                <View style={styles.rowSpacing}>
                  <Text appearance="hint" category="p2">
                    {`${translate('PER_ACRE')}`}
                  </Text>
                  <Text
                    appearance="hint"
                    category="p2"
                    style={{ textDecorationLine: 'line-through' }}
                    testID="expected-cost-per-acre"
                  >
                    {formatCurrency(overview.costPerAcre)}
                  </Text>
                </View>
                <VSpacer size="6" />
                <View style={styles.rowSpacing}>
                  <Text>{`${translate('DISCOUNTS')}`}</Text>
                  <Text
                    status={overview.discountTotal > 0
                      ? 'success'
                      : 'basic'}
                    testID="discount-total"
                  >
                    {formatCurrency(-overview.discountTotal, '-$')}
                  </Text>
                </View>
                <View style={styles.rowSpacing}>
                  <Text appearance="hint" category="p2">
                    {`${translate('PER_ACRE')}`}
                  </Text>
                  <Text
                    appearance="hint"
                    category="p2"
                    status={overview.discountPerAcre > 0
                      ? 'success'
                      : 'basic'}
                    testID="discount-per-acre"
                  >
                    {StringUtility.formatDiscount(overview.discountPerAcre)}
                  </Text>
                </View>
                <VSpacer size="5" />
                <View style={themeStyle.horizontalLine} />
                <VSpacer size="5" />
              </View>
            )}
            <View>
              <View style={styles.rowSpacing}>
                <Text category="p1">{`${translate('TOTAL')}`}</Text>
                <Text testID="expected-cost-total">
                  {formatCurrency(snapshot.overview.orderTotal)}
                </Text>
              </View>
              <View style={styles.rowSpacing}>
                <Text appearance="hint" category="p2">
                  {`${translate('PER_ACRE')}`}
                </Text>
                <Text
                  appearance="hint"
                  category="p2"
                  testID="expected-cost-per-acre"
                >
                  {formatCurrency(overview.orderTotalPerAcre)}
                </Text>
              </View>
            </View>
          </Card>
        </View>
      )}
      {isNoteVisible && (
        <Modal
          maxHeight
          onClose={() => setIsNoteVisible(false)}
          testID="notes-modal"
          title={translate('NOTE')}
          visible
        >
          <ScrollView>
            <Text category="p1">{note}</Text>
          </ScrollView>
        </Modal>
      )}
      <BillingShippingContactsModal
        billingContact={header.billingContact}
        onClose={() => setIsContactModalVisible(false)}
        shippingContact={header.shippingContact}
        showModal={isContactModalVisible}
      />
    </View>
  );
};
