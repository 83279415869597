import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  expanderColumn: {
    width: 72, // 40 for icon, 32 for padding of the cell
  },
  nothingToDisplay: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '64px',
  },
});
