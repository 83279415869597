import React, { FC, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Modal, Text, TextLink } from '@design';

interface CropSummaryProps {
  cropInfo: CropTypeAndCropSubType[],
}

interface CropTypeAndCropSubType {
  cropType: string,
  cropSubType: string,
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: '#000',
    opacity: 0.7,
  },
});

export const RowDetailCropSummary: FC<CropSummaryProps> = (
  { cropInfo }: CropSummaryProps,
) => {
  const [translate] = useTranslation(['common', 'prepare']);
  const [showMoreModalVisibility, setShowMoreModalVisibility] = useState(false);

  const uniqueCrops = cropInfo.filter((obj, index, self) => (index === self.findIndex((crop) => (
    crop.cropSubType === obj.cropSubType && crop.cropType === obj.cropType
  ))));
  const abridgedCrops = uniqueCrops.slice(0, 6).sort((a, b) => {
    if (a.cropType < b.cropType) return -1;
    return 1;
  });
  const isAbridged = uniqueCrops.length !== abridgedCrops.length;

  const renderProducts = (cropArray) => (
    <>
      {cropArray.sort()?.map((crop: CropTypeAndCropSubType, index: number) => (
        <View key={`${crop.cropType}-${crop.cropSubType}`} testID={`value-${index}`}>
          <Text category="p2">
            {`${translate(crop.cropType)} | ${translate(crop.cropSubType)}`}
          </Text>
        </View>
      ))}
    </>
  );

  return (
    <>
      {renderProducts(abridgedCrops)}
      {isAbridged
        && (
          <>
            <TextLink
              appearance="secondary"
              category="p2"
              onPress={() => setShowMoreModalVisibility(true)}
            >
              {`${translate('SHOW_MORE')}`}
            </TextLink>
            {showMoreModalVisibility && (
              <Modal
                backdropStyle={styles.backdrop}
                onClose={() => setShowMoreModalVisibility(false)}
                testID="show-more-modal"
                title={translate('CROP_TYPE')}
                visible={showMoreModalVisibility}
              >
                {renderProducts(uniqueCrops)}
              </Modal>
            )}
          </>
        )}
    </>
  );
};
