import React, { FC } from 'react';
import { View, ViewProps } from 'react-native';

export const ViewRow: FC<ViewProps> = ({ children, style, ...viewProps }) => (
  <View
    style={[{ flexDirection: 'row' }, style]}
    {...viewProps}
  >
    {children}
  </View>
);
