import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const ExpandCircleOutline = (props: SvgProps) => (
  <Svg viewBox="-2 -2 24 24" {...props}>
    <Path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" fill="none" stroke={props.fill} strokeWidth="2" />
    <Path d="M10 15.5V4.5" strokeWidth="1.5" />
    <Path d="M6.99994 7.14233L9.99994 4.28519L12.9999 7.14233" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <Path d="M13.0001 12.8557L10.0001 15.7129L7.00006 12.8557" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </Svg>
);
