import { Card, DataPoint, Header, Text, TextLink, VSpacer } from '@design';
import { AreaPlannedResult } from '@shared/utils';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { LineSpacer } from '../../../../components/shared';
import { AcresPlannedViewAllCropsModal } from './AcresPlannedViewAllCropsModal';

const styles = StyleSheet.create({
  card: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  viewAllProducts: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
    flexDirection: 'row',
  },
});

interface TotalRowProps {
  planned: number,
  total: number,
}

const TotalRow = ({ planned, total }: TotalRowProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  return (
    <View style={styles.container}>
      <Text category="p1" testID="total-acres-planned-heading">
        {translate<string>('TOTAL_ACRES_PLANNED')}
        :
      </Text>
      <Text category="p1" testID="total-acres-planned-value">
        {`${StringUtility.localizeNumber(planned, 0)}/${StringUtility.localizeNumber(total, 0)}`}
      </Text>
    </View>
  );
};

export interface FarmPlanAcresPlannedProps {
  acresPlanned: AreaPlannedResult,
}

const abridgedAcresPlannedLimit = 3;

export const AcresPlanned = ({ acresPlanned }: FarmPlanAcresPlannedProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [showViewAllCropsModal, setShowViewAllCropsModal] = useState(false);

  const abridgedCrops = acresPlanned.crops.slice(0, abridgedAcresPlannedLimit);
  const isAbridged = acresPlanned.crops.length > abridgedCrops.length;

  return (
    <View style={styles.card}>
      <Header level="3" testID="acres-planned-header" title={translate<string>('ACRES_PLANNED')} />
      <VSpacer size="5" />
      <Card style={styles.card} testID="acres-planned-card">
        <TotalRow planned={acresPlanned.planned} total={acresPlanned.total} />
        <LineSpacer size="5" />
        {acresPlanned.crops.length ? (
          <DataPoint flex label={translate<string>('CROPS')} testID="crops">
            <>
              <VSpacer size="5" />
              {abridgedCrops?.map((crop, idx) => {
                const { costPerArea, areaValue, type, subType, percent } = crop;
                const { areaUnitType } = acresPlanned;
                return (
                  <Fragment key={`${type}-${subType}`}>
                    <View key={`${type}-${subType}`} style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Text
                        category="p2"
                        numberOfLines={1}
                        style={{ flex: 1.5 }}
                        testID={`${type}-${subType}-${idx}`}
                      >
                        {`${translate<string>(type)} | ${translate<string>(subType)}`}
                      </Text>
                      <Text
                        category="p2"
                        numberOfLines={1}
                        style={{ flex: 1 }}
                        testID={`cost-per-area-${idx}`}
                      >
                        {translate<string>('PER_SHORT', {
                          area: StringUtility.formatCurrencyAccounting(costPerArea) ?? 0,
                          areaUnitType,
                        })}
                      </Text>
                      <Text
                        category="p2"
                        numberOfLines={1}
                        style={{ flex: 1, textAlign: 'right' }}
                        testID={`percent-acres-${idx}`}
                      >
                        {`${percent}%, ${StringUtility.localizeNumber(areaValue, 0)}`
                          + ` ${translate<string>(areaUnitType === 'ac' ? 'ACRES' : areaUnitType)}`}
                      </Text>
                    </View>
                    {abridgedCrops.length > 1 && <VSpacer size="3" />}
                  </Fragment>
                );
              })}
            </>
          </DataPoint>
        ) : (
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Text>{translate<string>('NO_CROPS')}</Text>
          </View>
        )}
        {isAbridged && (
          <>
            <VSpacer size="3" />
            <View style={styles.viewAllProducts}>
              <TextLink
                appearance="secondary"
                category="p2"
                onPress={() => setShowViewAllCropsModal(true)}
                testID="view-all-crops"
              >
                {translate<string>('VIEW_ALL_CROPS')}
              </TextLink>
            </View>
          </>
        )}
        {showViewAllCropsModal && (
          <AcresPlannedViewAllCropsModal
            areaUnitType={acresPlanned.areaUnitType}
            crops={acresPlanned.crops}
            onClose={() => setShowViewAllCropsModal(false)}
            visible
          />
        )}
      </Card>
    </View>
  );
};
