/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { ReactText } from 'react';
import _ from 'lodash';
import {
  ImageProps,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
  Animated,
} from 'react-native';
import { SelectItem } from '@design';
import {
  Interaction,
  styled,
  StyledComponentProps,
  StyleType,
} from '@ui-kitten/components/theme';
import {
  FalsyFC,
  FalsyText,
  FlexViewCrossStyleProps,
  PropsService,
  RenderProp,
  WebEventResponder,
  WebEventResponderCallbacks,
  WebEventResponderInstance,
  Overwrite,
  LiteralUnion,
  TouchableWithoutFeedback,
} from '@ui-kitten/components/devsupport';
import {
  List,
  ListItemProps as UIKListItemProps,
  Popover,
  PopoverElement,
} from '@ui-kitten/components';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ChevronDown } from '@ui-kitten/components/ui/shared/chevronDown.component';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { InputStatus } from '@theme/variant-interfaces/Status';
import { InputSize } from '@theme/variant-interfaces/Size';
import { testId } from '../../../../utilities/testId';
import { TextProps, Text } from '../Text/Text';

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    overflow: 'hidden',
  },
  label: {
    textAlign: 'left',
  },
  captionLabel: {
    textAlign: 'left',
  },
  popover: {
    overflow: 'hidden',
  },
  list: {
    flexGrow: 0,
    overflow: 'hidden',
  },
  listItem: { flexDirection: 'row' },
  bold: { fontWeight: '900' },
});

type InputStyledProps = Overwrite<StyledComponentProps, {
  appearance?: LiteralUnion<'default'>,
}>;

interface ListItemProps extends UIKListItemProps {
  name: string,
}

export interface InputProps extends TextInputProps, InputStyledProps, WithTranslation {
  accessoryLeft?: RenderProp<Partial<ImageProps>>,
  accessoryRight?: RenderProp<Partial<ImageProps>>,
  autoCompleteOnClear?: () => void,
  autoCompleteOnSelect?: (index: number) => void,
  autoCompleteOptions?: ListItemProps[],
  caption?: RenderProp<TextProps> | React.ReactText,
  disabled?: boolean,
  inputContainerStyle?: StyleProp<ViewStyle>,
  isRequired?: boolean,
  keepLabelSpace?: boolean,
  keepCaptionSpace?: boolean,
  label?: RenderProp<TextProps> | React.ReactText,
  readonly?: boolean,
  readonlyLabel?: boolean,
  status?: InputStatus,
  size?: InputSize,
  textStyle?: StyleProp<TextStyle>,
  testID: string,
}

interface InputState {
  listVisible: boolean,
  captionHeight: number,
}

const CHEVRON_DEG_COLLAPSED: number = -180;
const CHEVRON_DEG_EXPANDED: number = 0;
const CHEVRON_ANIM_DURATION: number = 200;

export type InputElement = React.ReactElement<InputProps>;

/**
 * Inputs let users enter and edit text.
 *
 * @extends React.Component
 *
 * @property {string} value - A value displayed in input field.
 *
 * @property {(string) => void} onChangeText - Called when the value should be changed.
 *
 * @property {() => void} onFocus - Called when input field becomes focused.
 *
 * @property {() => void} onBlur - Called when input field looses focus.
 *
 * @property {string} placeholder - A string to be displayed when there is no value.
 *
 * @property {boolean} disabled - Whether input field is disabled.
 * This property overrides `editable` property of TextInput.
 *
 * @property {ReactElement | ReactText | (TextProps) => ReactElement} label
 * - String, number or a function component
 * to render above the input field.
 * If it is a function, expected to return a Text.
 *
 * @property {ReactElement | ReactText | (TextProps) => ReactElement} caption
 * - Function component to render below Input view.
 * Expected to return View.
 *
 * @property {ReactElement | (ImageProps) => ReactElement} accessoryLeft - Function component
 * to render to start of the text.
 * Expected to return an Image.
 *
 * @property {ReactElement | (ImageProps) => ReactElement} accessoryRight - Function component
 * to render to end of the text.
 * Expected to return an Image.
 *
 * @property {string} status - Status of the component.
 * Can be `basic`, `primary`, `success`, `info`, `warning`, `danger` or `control`.
 * Defaults to *basic*.
 * Useful for giving user a hint on the input validity.
 * Use *control* status when needed to display within a contrast container.
 *
 * @property {string} size - Size of the component.
 * Can be `small`, `medium` or `large`.
 * Defaults to *medium*.
 *
 * @property {StyleProp<TextStyle>} textStyle - Customizes the style of the text field.
 *
 * @property {TextInputProps} ...TextInputProps - Any props applied to TextInput component.
 *
 * @property {boolean} readonly - Set's input field to readonly and disables it.
 * Add's translated 'Read only' to right of label.
 *
 * @property {boolean} keepLabelSpace - removes the label if set.
 *
 * @property {boolean} keepCaptionSpace - removes the caption if set.
 *
 * @property {ListItemProps[]} autoCompleteOptions
 * - adds autocomplete to the Input with popover. autoCompleteOptions
 * is an array of ListItemProps.
 *
 * @property {(index: number) => void} autoCompleteOnSelect
 * - consumer provided function to set selected item when autocomplete is utilized
 *
 * @property {() => void} autoCompleteOnClear - consumer provided function to clear field.
 *
 * @property {boolean} isRequired - denotes if the field is required.
 *
 * @overview-example InputSimpleUsage
 *
 * @overview-example InputStates
 * Input can be disabled with `disabled` property.
 *
 * @overview-example InputStatus
 * Or marked with `status` property, which is useful within forms validation.
 * An extra status is `control`, which is designed to be used on high-contrast backgrounds.
 *
 * @overview-example InputAccessories
 * Input may contain labels, captions and inner views by configuring `accessoryLeft`
 * or `accessoryRight` properties. Within Eva, Input accessories are expected to be
 * images or [svg icons](guides/icon-packages).
 *
 * @overview-example InputSize
 * To resize Input, a `size` property may be used.
 *
 * @overview-example InputStyling
 * Input and it's inner views can be styled by passing them as function components.
 * ```
 * import { Input, Text } from '@ui-kitten/components';
 *
 * <Input
 *   textStyle={{ ... }}
 *   label={evaProps => <Text {...evaProps}>Label</Text>}
 *   caption={evaProps => <Text {...evaProps}>Caption</Text>}
 * />
 * ```
 *
 */
@styled('Input')
class InputRaw extends
  React.Component<InputProps, InputState> implements WebEventResponderCallbacks {
  // eslint-disable-next-line react/state-in-constructor
  public state: InputState = {
    listVisible: false,
    captionHeight: 0,
  };

  private popoverRef = React.createRef<Popover>();
  private textInputRef = React.createRef<TextInput>();
  private expandAnimation: Animated.Value = new Animated.Value(0);

  private webEventResponder: WebEventResponderInstance = WebEventResponder.create(this);

  private get expandToRotateInterpolation () {
    return this.expandAnimation.interpolate({
      inputRange: [CHEVRON_DEG_COLLAPSED, CHEVRON_DEG_EXPANDED],
      outputRange: [`${CHEVRON_DEG_COLLAPSED}deg`, `${CHEVRON_DEG_EXPANDED}deg`],
    });
  }

  private createExpandAnimation = (toValue: number):
  Animated.CompositeAnimation => Animated.timing(this.expandAnimation, {
    toValue,
    duration: CHEVRON_ANIM_DURATION,
    useNativeDriver: false,
  });

  private onListVisible = (): void => {
    this.props.eva.dispatch([Interaction.FOCUSED]);
    this.createExpandAnimation(-CHEVRON_DEG_COLLAPSED).start(() => {
      this.props.onFocus && this.props.onFocus(null);
    });
  };

  private onListInvisible = (): void => {
    this.props.eva.dispatch([]);
    this.createExpandAnimation(CHEVRON_DEG_EXPANDED).start(() => {
      this.props.onBlur && this.props.onBlur(null);
    });
  };

  public focus = (): void => {
    this.textInputRef.current?.focus();
  };

  public blur = (): void => {
    this.textInputRef.current?.blur();
  };

  public isFocused = (): boolean => (
    this.textInputRef.current?.isFocused()
  );

  public clear = (): void => {
    this.textInputRef.current?.clear();
  };

  private setOptionsListVisible = (): void => {
    if (!this.props.readonly) {
      const isAutocomplete: boolean = this.props.autoCompleteOptions !== undefined;
      isAutocomplete && this.setState({ listVisible: true }, this.onListVisible);
    }
  };

  public onMouseEnter = (): void => {
    const interactions = [Interaction.HOVER];
    if (this.isFocused()) {
      interactions.push(Interaction.FOCUSED);
    }
    this.props.eva.dispatch(interactions);
  };

  public onMouseLeave = (): void => {
    const interactions = [];
    if (this.isFocused()) {
      interactions.push(Interaction.FOCUSED);
    }
    this.props.eva.dispatch(interactions);
  };

  private onTextFieldFocus = (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
    this.props.eva.dispatch([Interaction.FOCUSED]);
    // patch for ui-kitten upgrade -> autocomplete functions as a Select
    // until new autocomplete component replaces it
    if (this.props.autoCompleteOptions && !this.state.listVisible) {
      this.blur();
    }
    this.setOptionsListVisible();
    this.props.onFocus && this.props.onFocus(event);
  };

  private onTextFieldBlur = (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
    // fixes a bug where the user needs to double-click on an autocomplete list item
    if (!this.state.listVisible) {
      this.props.eva.dispatch([]);
    }
    this.props.onBlur && this.props.onBlur(event);
  };

  private onItemPress = (item: number): void => {
    if (this.props.autoCompleteOnSelect) {
      this.setOptionsListInvisible();
      this.props.autoCompleteOnSelect(item);
    }
  };

  private setOptionsListInvisible = (): void => {
    this.setState({ listVisible: false }, this.onListInvisible);
  };

  private onBackdropPress = (): void => {
    this.blur();
    this.setOptionsListInvisible();
  };

  private getComponentStyle = (source: StyleType) => {
    const flatStyles: ViewStyle = StyleSheet.flatten(this.props.style);
    const { rest: inputContainerStyle, ...containerStyle } = PropsService
      .allWithRest(flatStyles, FlexViewCrossStyleProps);

    const {
      placeholderColor,
      paddingHorizontal,
      ...containerParameters
    } = source;

    const labelStyles = PropsServiceHelper.allWithPrefixMapped(source, 'label');
    const captionStyles = PropsServiceHelper.allWithPrefixMapped(source, 'caption');
    const iconStyles = PropsServiceHelper.allWithPrefixMapped(source, 'icon');
    const iconRightStyles = PropsServiceHelper.allWithPrefixMapped(source, 'iconRight');
    const iconLeftStyles = PropsServiceHelper.allWithPrefixMapped(source, 'iconLeft');
    const textStyles = PropsServiceHelper.allWithPrefixMapped(source, 'text');
    const { elevation, ...popoverStyles } = PropsServiceHelper.allWithPrefixMapped(source, 'popover');
    const listStyles = PropsServiceHelper.allWithPrefixMapped(source, 'list');
    const listItemStyles = PropsServiceHelper.allWithPrefixMapped(source, 'listItem');
    const helpTextStyles = PropsServiceHelper.allWithPrefixMapped(source, 'helpText');

    return {
      container: {
        ...containerStyle,
      },
      inputContainer: {
        paddingHorizontal,
        ...containerParameters,
        ...inputContainerStyle,
      },
      text: textStyles,
      placeholder: {
        color: placeholderColor,
      },
      icon: iconStyles,
      iconLeft: iconLeftStyles,
      iconRight: iconRightStyles,
      label: {
        paddingHorizontal,
        ...labelStyles,
      },
      captionLabel: {
        paddingHorizontal,
        ...captionStyles,
      },
      popover: {
        ...popoverStyles,
        ...elevation,
      },
      list: listStyles,
      listItem: listItemStyles,
      helpTextStyles,
    };
  };

  private getOptionList = (
    evaStyle: StyleType,
    renderInputFn: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
  ): PopoverElement => (
    <Popover
      anchor={() => renderInputFn}
      fullWidth
      onBackdropPress={this.onBackdropPress}
      ref={this.popoverRef}
      style={[styles.popover, evaStyle.popover,
        this.state.listVisible && this.props.caption
          && { marginTop: -(this.state.captionHeight + evaStyle.captionLabel.marginTop) },
      ]}
      visible={this.state.listVisible}
    >
      <List
        data={this.props.autoCompleteOptions}
        overScrollMode="auto"
        renderItem={(item) => {
          const escapedValue = this.props.value?.replace(/[.*+?^${}()[\]\\]/g, '\\$&') ?? '';
          const surrounded = item.item.name.replace(new RegExp(`(${escapedValue})`, 'ig'), '|$1|');
          const outputTokens = surrounded.split('|');
          return (
            <SelectItem
              key={item.index}
              onPress={() => this.onItemPress(item.index)}
              testID={`input-dropdown-value-${item.index}`}
              title={() => (
                <View
                  style={[styles.listItem, evaStyle.listItem]}
                  {...testId(this.props.testID ? `${this.props.testID}-${item.index}` : '')}
                >
                  {
                    outputTokens.map((token: string, i: number) => (
                      <Text
                        category="p2"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${token}-${i}`}
                        style={[
                          evaStyle.list,
                          i % 2 && styles.bold,
                        ]}
                      >
                        {token}
                      </Text>
                    ))
                  }
                </View>
              )}
            />
          );
        }}
        style={styles.list}
        testID={this.props.testID ? `${this.props.testID}-list` : ''}
      />
    </Popover>
  );

  private getLabel = () => {
    const {
      label,
      readonly,
      readonlyLabel = true,
      keepLabelSpace,
      t,
    } = this.props;

    if (keepLabelSpace) {
      return (textProps) => <Text {...textProps}> </Text>;
    }

    if (readonly && readonlyLabel) {
      return (textProps) => (
        <Text {...textProps}>
          {label}
          {` (${t('READ_ONLY')})`}
        </Text>
      );
    }

    return label;
  };

  private getHelpText = () => {
    const {
      t,
    } = this.props;

    return (textProps) => (
      <Text {...textProps}>
        {`${t('REQUIRED')}`}
      </Text>
    );
  };

  private getCaption = () : RenderProp<TextProps> | React.ReactText => {
    const {
      caption: propCaption,
      label,
      isRequired,
      t,
      ...textInputProps
    } = this.props;

    const { keepCaptionSpace } = textInputProps;

    const caption = keepCaptionSpace
      ? (textProps:
          JSX.IntrinsicAttributes
      & TextProps
      & { children?: React.ReactNode; }) => <Text {...textProps}> </Text>
      : propCaption;

    return caption;
  };

  private animatedIconElement = (evaStyle: StyleType): React.ReactElement => {
    const {
      tintColor,
      margin,
      marginHorizontal,
      marginVertical,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      ...svgStyle
    } = evaStyle;
    const marginStyle = {
      margin, marginHorizontal, marginLeft, marginRight, marginTop, marginBottom,
    };

    return (
      <View style={marginStyle}>
        <Animated.View
          style={{ transform: [{ rotate: this.expandToRotateInterpolation }] }}
        >
          <ChevronDown fill={tintColor} style={svgStyle} />
        </Animated.View>
      </View>
    );
  };

  private renderInputElement = (
    evaStyle: StyleType,
    inputContainerStyle: StyleProp<ViewStyle>,
    textStyle: StyleProp<TextStyle>,
    readonly: boolean,
    textInputProps: InputProps,
    autoCompleteOptions: ListItemProps[],
  ): InputElement => {
    const {
      accessoryLeft,
      accessoryRight,
    } = textInputProps;

    return (
      <TouchableWithoutFeedback
        disabled={readonly}
        onPress={this.focus}
        style={evaStyle.container}
      >
        <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
          <FalsyText
            category="p2"
            component={this.getLabel() as ReactText}
            style={[evaStyle.label, styles.label, { flex: 1 }]}
          />
          {this.props.isRequired && (
            <FalsyText
              category="c1"
              component={this.getHelpText()}
              style={[evaStyle.helpTextStyles, { marginBottom: 8, paddingRight: 12 }]}
            />
          )}
        </View>
        <View
          style={StyleSheet.flatten([
            evaStyle.inputContainer,
            styles.inputContainer,
            inputContainerStyle,
          ])}
        >
          <FalsyFC
            component={accessoryLeft}
            style={[evaStyle.icon, evaStyle.iconLeft]}
          />
          <TextInput
            disabled={readonly || textInputProps.disabled}
            placeholderTextColor={evaStyle.placeholder.color}
            ref={this.textInputRef}
            {...textInputProps}
            {...this.webEventResponder.eventHandlers}
            editable={!textInputProps.disabled && !readonly}
            onBlur={this.onTextFieldBlur}
            onFocus={this.onTextFieldFocus}
            style={StyleSheet.flatten([evaStyle.text, styles.text, textStyle])}
          />
          <FalsyFC
            component={accessoryRight}
            style={[evaStyle.icon, evaStyle.iconRight]}
          />
          {autoCompleteOptions?.length > 0 && this.animatedIconElement(evaStyle.icon)}
        </View>
        <FalsyText
          component={this.getCaption() as ReactText}
          onLayout={(event) => {
            this.setState({ captionHeight: event.nativeEvent.layout.height });
          }}
          style={[evaStyle.captionLabel, styles.captionLabel]}
        />
      </TouchableWithoutFeedback>
    );
  };

  public render (): React.ReactElement<ViewProps> {
    const {
      eva,
      inputContainerStyle,
      textStyle,
      readonly,
      keepLabelSpace,
      keepCaptionSpace,
      autoCompleteOnClear,
      autoCompleteOnSelect,
      autoCompleteOptions,
      ...textInputProps
    } = this.props;

    const memoizedGetComponentStyle = _.memoize(this.getComponentStyle);
    const evaStyle = memoizedGetComponentStyle(eva.style);
    const renderInputFn = this.renderInputElement(
      evaStyle, inputContainerStyle, textStyle, readonly, textInputProps, autoCompleteOptions,
    );

    return (
      <>
        {autoCompleteOptions !== undefined
          ? this.getOptionList(evaStyle, renderInputFn)
          : (renderInputFn)}
      </>
    );
  }
}
const InputMemo = React.memo(InputRaw);
export const Input = withTranslation('common', { withRef: true })(InputMemo);
