import { ProductOrderDeliverableV0 } from '@shared/interfaces';
import React from 'react';
import { DeliverableRenderer } from '../../DeliverableRenderer';
import { ProductOrderDeliverableDesktopView } from './ProductOrderDeliverableDesktopView';
import {
  ProductOrderDeliverableMobileView,
} from './ProductOrderDeliverableMobileView';

export class ProductOrderDeliverableV0Renderer implements DeliverableRenderer {
  constructor (
    private deliverable: ProductOrderDeliverableV0.Deliverable |
      ProductOrderDeliverableV0.DeliverablePublic,
  ) {}

  getBusinessLocation (): string {
    return this.deliverable.deliverableData.header.locationName;
  }

  getBusinessName (): string {
    return this.deliverable.deliverableData.header.businessName;
  }

  getDesktopView () {
    return !!this.deliverable && (
      <ProductOrderDeliverableDesktopView
        deliverable={this.deliverable as ProductOrderDeliverableV0.DeliverablePublic}
      />
    );
  }

  getGrowerName (): string {
    return this.deliverable.deliverableData.header.growerName;
  }

  getMobileView () {
    const deliverable = this.deliverable as ProductOrderDeliverableV0.Deliverable;
    return (
      <ProductOrderDeliverableMobileView
        deliverableId={deliverable.publicId}
        deliverableName={deliverable.name}
        selections={deliverable.selections}
        snapshot={deliverable.deliverableData}
      />
    );
  }

  getPlanName (): string {
    return this.deliverable.deliverableData.header.orderName;
  }
}
