import { SvgProps } from 'react-native-svg';
import { EvaIcon } from '@ui-kitten/eva-icons/evaIcon.component';
import { AreaCropZone } from './growers/area-crop-zone';
import { AreaFarm } from './growers/area-farm';
import { AreaField } from './growers/area-field';
import { ArrowExpand } from './growers/arrow-expand';
import { Calculate } from './growers/calculate';
import { Business } from './growers/business';
import { DragHandle } from './growers/drag-handle';
import { Planning } from './growers/planning';
import { PrepareReview } from './growers/prepare-review';
import { Product } from './growers/product';
import { ProductChemical } from './growers/product-chemical';
import { ProductFertilizer } from './growers/product-fertilizer';
import { ProductSeed } from './growers/product-seed';
import { Unlock } from './growers/unlock';
import { ViewDetailsIcon } from './growers/view-details-icon';
import { GrowersFooter } from './growers/nav-logo-growers-footer';
import { GrowersIcon } from './growers/nav-logo-growers-icon';
import { RallyIcon } from './growers/nav-logo-rally-icon';
import { RallyLogo } from './growers/nav-logo-rally';

export const GrowersIconsPack = {
  name: 'growers',
  icons: {
    'area-crop-zone': new EvaIcon(AreaCropZone),
    'area-farm': new EvaIcon(AreaFarm),
    'area-field': new EvaIcon(AreaField),
    'arrow-expand': new EvaIcon(ArrowExpand),
    calculate: new EvaIcon(Calculate),
    business: new EvaIcon(Business),
    planning: new EvaIcon(Planning),
    'prepare-review': new EvaIcon(PrepareReview),
    'product-chemical': new EvaIcon(ProductChemical),
    'product-fertilizer': new EvaIcon(ProductFertilizer),
    'product-seed': new EvaIcon(ProductSeed),
    product: new EvaIcon(Product),
    unlock: new EvaIcon(Unlock),
    'drag-handle': new EvaIcon(DragHandle),
    'view-details-icon': new EvaIcon(ViewDetailsIcon as (props: SvgProps) => JSX.Element),
    'nav-logo-growers-footer': new EvaIcon(GrowersFooter),
    'nav-logo-growers-icon': new EvaIcon(GrowersIcon),
    'nav-logo-rally-icon': new EvaIcon(RallyIcon),
    'nav-logo-rally': new EvaIcon(RallyLogo),
  },
};
