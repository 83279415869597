import React from 'react';
import { View, ViewProps } from 'react-native';
import {
  EvaProp,
  styled,
  StyleType,
} from '@ui-kitten/components';
import { PropsServiceHelper } from '@theme/helpers/PropServiceHelper';
import { TextLink } from '../TextLink/TextLink';
import { IconButton } from '../IconButton/IconButton';
import { testId } from '../../../../utilities/testId';

export interface PaginationProps extends ViewProps {
  currentPage: number,
  totalPages: number,
  displayPages: 5 | 7 | 9,
  eva?: EvaProp,
  disabled?: boolean, // disable user onPress
  onChangePage?: (page: number) => void, // This is a callback which changes the parents page number
}

interface PaginationState {
  displayedArr: string[],
}

@styled('Pagination')
export class Pagination extends React.Component<PaginationProps, PaginationState> {
  constructor (props) {
    super(props);
    this.state = {
      displayedArr: [],
    };
  }

  changePage = (page: string) => {
    let nextPage;
    if (this.props.disabled) return;
    if (page === '>') {
      nextPage = this.props.currentPage + 1;
    } else if (page === '<') {
      nextPage = this.props.currentPage - 1;
    } else {
      nextPage = parseInt(page, 10);
    }

    if (nextPage < 1 || nextPage > this.props.totalPages) return;
    this.props.onChangePage?.(nextPage);
  };

  displayPageComponent = (
    val: string,
    commonLinkStyle: Record<string, any>,
    selectedStyle: Record<string, any>,
    unselectedStyle: Record<string, any>,
  ) => {
    // '. ..' and '.. .' are different to allow for unique keys
    const displayValue = val.replace(' ', '');
    const intVal = parseInt(val, 10);
    const isNaN = Number.isNaN(intVal);
    const isCurrentPage = parseInt(val, 10) === this.props.currentPage;

    return (
      <TextLink
        disabled={isNaN}
        onPress={() => this.changePage(val)}
        style={[
          commonLinkStyle,
          isCurrentPage && selectedStyle,
          !isCurrentPage && unselectedStyle,
        ]}
        {...testId(`page-${displayValue}`)}
      >
        {displayValue}
      </TextLink>
    );
  };

  calculateDisplayArr = () => {
    const newDisplayArr = [];

    for (let x = 1; x <= this.props.totalPages; x += 1) {
      newDisplayArr.push(String(x));
    }

    if (newDisplayArr.length <= this.props.displayPages) {
      this.setState({ displayedArr: newDisplayArr });
      return;
    }

    let minWindow = this.props.currentPage - Math.floor(this.props.displayPages / 2);
    let maxWindow = this.props.currentPage + Math.floor(this.props.displayPages / 2);

    while (minWindow < 1) {
      minWindow += 1;
      maxWindow += 1;
    }

    while (maxWindow > this.props.totalPages) {
      minWindow -= 1;
      maxWindow -= 1;
    }

    const displaySlice = newDisplayArr.slice(minWindow - 1, maxWindow);

    if (displaySlice[minWindow - 1] !== '1') {
      displaySlice[1] = '. ..';
      displaySlice[0] = '1';
    }

    if (displaySlice[displaySlice.length - 1] !== String(this.props.totalPages)) {
      displaySlice[displaySlice.length - 2] = '.. .';
      displaySlice[displaySlice.length - 1] = String(this.props.totalPages);
    }

    this.setState({ displayedArr: displaySlice });
  };

  componentDidMount = () => {
    this.calculateDisplayArr();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentPage !== this.props.currentPage
        || prevProps.totalPages !== this.props.totalPages) {
      this.calculateDisplayArr();
    }
  };

  private getComponentStyle = (source: StyleType) => {
    const selectedStyles = PropsServiceHelper.allWithPrefixMapped(source, 'selectedPage');
    const unselectedStyles = PropsServiceHelper.allWithPrefixMapped(source, 'unselectedPage');
    const chevronStyles = PropsServiceHelper.allWithPrefixMapped(source, 'chevronButton');
    const commonTextLinkStyles = PropsServiceHelper.allWithPrefixMapped(source, 'commonTextLink');
    const selectedContainerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'selectedContainer');
    const unselectedContainerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'unselectedContainer');
    const commonContainerStyles = PropsServiceHelper.allWithPrefixMapped(source, 'commonContainer');
    const leftChevronStyles = PropsServiceHelper.allWithPrefixMapped(source, 'leftChevron');
    const rightChevronStyles = PropsServiceHelper.allWithPrefixMapped(source, 'rightChevron');

    return {
      common: commonTextLinkStyles,
      selected: selectedStyles,
      unselected: unselectedStyles,
      chevron: chevronStyles,
      commonContainer: commonContainerStyles,
      selectedContainer: selectedContainerStyles,
      unselectedContainer: unselectedContainerStyles,
      leftChevron: leftChevronStyles,
      rightChevron: rightChevronStyles,
    };
  };

  public render () {
    const {
      eva,
      ...viewProps
    } = this.props;

    const evaStyle = this.getComponentStyle(eva.style);

    return (
      <View
        style={{ flexDirection: 'row' }}
        {...testId('pagination')}
        {...viewProps}
      >
        <View
          style={[evaStyle.commonContainer, evaStyle.leftChevron]}
        >
          {
            this.props.currentPage > 1 && (
              <IconButton
                appearance="ghost"
                onPress={() => this.changePage('<')}
                size="medium"
                status="basic"
                testID="previous-page"
              >
                ChevronLeft
              </IconButton>
            )
          }
        </View>
        { this.props.totalPages > 1 && this.state.displayedArr.map((val) => (
          <View
            key={`pagination-item-${val}`}
            style={[
              evaStyle.commonContainer,
              parseInt(val, 10) === this.props.currentPage
                ? evaStyle.selectedContainer : evaStyle.unselectedContainer,
            ]}
          >
            { this.displayPageComponent(
              val,
              evaStyle.common,
              evaStyle.selected,
              evaStyle.unselected,
            )}
          </View>
        ))}
        <View style={[evaStyle.commonContainer, evaStyle.rightChevron]}>
          {
            this.props.currentPage < this.props.totalPages && (
            <IconButton
              appearance="ghost"
              onPress={() => this.changePage('>')}
              size="medium"
              status="basic"
              testID="next-page"
            >
              ChevronRight
            </IconButton>
            )
          }
        </View>
      </View>
    );
  }
}
