import React from 'react';
import {
  GestureResponderEvent,
  ImageProps,
  NativeSyntheticEvent,
  StyleSheet,
  TargetedEvent,
} from 'react-native';
import {
  Interaction,
  StyledComponentProps,
  StyleType,
  CheckBox,
  CheckBoxElement,
  TextProps,
  styled,
} from '@ui-kitten/components';
import {
  FalsyFC,
  FalsyText,
  PropsService,
  RenderProp,
  TouchableWeb,
  TouchableWebElement,
  TouchableWebProps,
  Overwrite,
  LiteralUnion,
} from '@ui-kitten/components/devsupport';
import { SelectItemDescriptor } from '@ui-kitten/components/ui/select/select.service';
import { Icon } from '../Icon/Icon';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  text: {
    flex: 1,
    textAlign: 'left',
  },
});

type SelectItemStyledProps = Overwrite<StyledComponentProps, {
  appearance?: LiteralUnion<'default' | 'grouped'>;
}>;

type TouchableSelectProps = Overwrite<TouchableWebProps, {
  onPress?: (descriptor: SelectItemDescriptor, event?: GestureResponderEvent) => void;
}>;

export interface SelectItemProps extends TouchableSelectProps, SelectItemStyledProps {
  title?: RenderProp<TextProps> | React.ReactText;
  accessoryLeft?: RenderProp<Partial<ImageProps>>;
  accessoryRight?: RenderProp<Partial<ImageProps>>;
  selected?: boolean;
  descriptor?: SelectItemDescriptor;
  testID: string;
}

export type SelectItemElement = React.ReactElement<SelectItemProps>;

/**
 * A single item in Select.
 * Items should be rendered within Select or SelectGroup children to provide a usable component.
 *
 * @extends React.Component
 *
 * @property {ReactText | ReactElement |
 *  (TextProps) => ReactElement} title - String, number or a function component
 * to render within the item.
 * If it is a function, expected to return a Text.
 *
 * @property {ReactElement | (ImageProps) => ReactElement} accessoryLeft - Function component
 * to render to start of the *title*.
 * Expected to return an Image.
 *
 * @property {ReactElement | (ImageProps) => ReactElement} accessoryRight - Function component
 * to render to end of the *title*.
 * Expected to return an Image.
 *
 * @property {TouchableOpacityProps}
 *  ...TouchableOpacityProps - Any props applied to TouchableOpacity component.
 *
 * @overview-example SelectItemSimpleUsage
 */

@styled('SelectOption')
export class SelectItem extends React.Component<SelectItemProps> {
  protected get isMultiSelect (): boolean {
    if (this.props.descriptor) {
      return this.props.descriptor.multiSelect;
    }
    return false;
  }

  protected onMouseEnter = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.HOVER]);
    return this.props.onMouseEnter && this.props.onMouseEnter(event);
  };

  protected onMouseLeave = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    return this.props.onMouseLeave && this.props.onMouseLeave(event);
  };

  protected onFocus = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([Interaction.FOCUSED]);
    return this.props.onFocus && this.props.onFocus(event);
  };

  protected onBlur = (event: NativeSyntheticEvent<TargetedEvent>): void => {
    this.props.eva.dispatch([]);
    return this.props.onBlur && this.props.onBlur(event);
  };

  protected onPress = (event: GestureResponderEvent): void => (
    this.props.onPress
    && this.props.onPress(this.props.descriptor, event)
  );

  protected onPressIn = (event: GestureResponderEvent): void => {
    this.props.eva.dispatch([Interaction.ACTIVE]);
    return this.props.onPressIn && this.props.onPressIn(event);
  };

  protected onPressOut = (event: GestureResponderEvent): void => {
    this.props.eva.dispatch([]);
    return this.props.onPressOut && this.props.onPressOut(event);
  };

  protected onAccessoryCheckedChange = (): void => (
    this.props.onPress
    && this.props.onPress(this.props.descriptor)
  );

  protected getComponentStyle = (style: StyleType) => {
    const { paddingHorizontal, paddingLeft, paddingVertical, backgroundColor } = style;

    const textStyles = PropsService.allWithPrefix(style, 'text');
    const iconStyles = PropsService.allWithPrefix(style, 'icon');

    return {
      container: {
        paddingHorizontal,
        paddingLeft,
        paddingVertical,
        backgroundColor,
      },
      text: {
        marginHorizontal: textStyles.textMarginHorizontal,
        fontFamily: textStyles.textFontFamily,
        fontSize: textStyles.textFontSize,
        fontWeight: textStyles.textFontWeight,
        color: textStyles.textColor,
      },
      icon: {
        width: iconStyles.iconWidth,
        height: iconStyles.iconHeight,
        marginHorizontal: iconStyles.iconMarginHorizontal,
        tintColor: iconStyles.iconTintColor,
      },
    };
  };

  private renderMultiAccessory = (evaStyle, disabled): CheckBoxElement => {
    if (!this.isMultiSelect) {
      return null;
    }

    return (
      <CheckBox
        checked={this.props.selected}
        disabled={disabled}
        onChange={this.onAccessoryCheckedChange}
        style={evaStyle}
      />
    );
  };

  private renderSingleAccessory = (evaStyle): CheckBoxElement => {
    if (this.isMultiSelect) {
      return null;
    }

    if (!this.props.selected) {
      return null;
    }

    return (
      <Icon
        name="Checkmark"
        style={evaStyle}
        testID="checked-icon"
      />
    );
  };

  public render (): TouchableWebElement {
    const {
      eva,
      style,
      title,
      accessoryLeft,
      accessoryRight,
      ...touchableProps
    } = this.props;
    const evaStyle = this.getComponentStyle(eva.style);
    const { disabled } = touchableProps;

    return (
      <TouchableWeb
        {...touchableProps}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onPress={this.onPress}
        onPressIn={this.onPressIn}
        onPressOut={this.onPressOut}
        style={[
          styles.container,
          evaStyle.container,
          style,
        ]}
      >
        <FalsyFC
          component={accessoryLeft}
          fallback={this.renderMultiAccessory(evaStyle.icon, disabled)}
          style={evaStyle.icon}
        />
        <FalsyText
          component={title}
          style={[
            styles.text,
            evaStyle.text,
          ]}
        />
        <FalsyFC
          component={accessoryRight}
          fallback={this.renderSingleAccessory(evaStyle.icon)}
          style={evaStyle.icon}
        />
      </TouchableWeb>
    );
  }
}
