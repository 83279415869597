import { useStyleSheet } from '@ui-kitten/components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Text, IconButton, VSpacer, HSpacer } from '@design';
import { StringUtility, testId } from '../../../../../utilities';
import { Category } from '../../../../themes/variant-interfaces/Category';

export interface OrderSummaryItemProps {
  isDisabled?: boolean,
  isDiscount?: boolean,
  isDraft?: boolean,
  isEditable?: boolean,
  isLineThrough?: boolean,
  onDelete?: () => void,
  onEdit?: () => void,
  perAcre?: number,
  testIdPostfix: string,
  title: string;
  titleCategory?: Category,
  titleValue: number,
  viewMode?: boolean,
}

export const OrderSummaryItem: FC<OrderSummaryItemProps> = ({
  isDisabled,
  isDiscount,
  isDraft,
  isEditable = true,
  isLineThrough,
  onDelete,
  onEdit,
  perAcre,
  testIdPostfix,
  title,
  titleValue,
  titleCategory = 'p1',
  viewMode,
}) => {
  const [translate] = useTranslation(['productOrders', 'common']);

  const styles = useStyleSheet(
    {
      container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      row: {
        flexDirection: 'row',
      },
      lineThrough: {
        textDecorationLine: 'line-through',
      },
      wrap: {
        flex: 1,
        flexWrap: 'wrap',
      },
    },
  );

  return (
    <>
      <View style={styles.container}>
        <Text
          style={styles.wrap}
          {...testId('order-summary-item-label', testIdPostfix)}
          category={titleCategory}
        >
          {title}
        </Text>
        <Text
          {...testId('order-summary-item-value', testIdPostfix)}
          category={titleCategory}
          status={(isDiscount && !isDisabled) ? 'success' : undefined}
          style={isLineThrough ? styles.lineThrough : undefined}
        >
          {isDiscount
            ? StringUtility.formatDiscount(titleValue)
            : StringUtility.formatCurrency(titleValue)}
        </Text>
      </View>
      <VSpacer size="2" />
      {!!perAcre && (
        <View style={styles.container}>
          <Text appearance="hint" category="p2">
            {translate<string>('PER_ACRE')}
          </Text>
          <Text
            {...testId('order-summary-item-per-acre', testIdPostfix)}
            appearance="hint"
            category="p2"
            status={(isDiscount && !isDisabled) ? 'success' : undefined}
            style={isLineThrough ? styles.lineThrough : undefined}
          >
            {isDiscount
              ? StringUtility.formatDiscount(perAcre)
              : StringUtility.formatCurrency(perAcre)}
          </Text>
        </View>
      )}
      {(onEdit || onDelete) && (
        <>
          <VSpacer size="2" />
          <View style={styles.row}>
            {!viewMode && (
              <>
                {!!onEdit && isEditable && (
                  <IconButton
                    appearance="ghost"
                    onPress={() => onEdit()}
                    size="small"
                    status={isDraft ? 'basic' : 'primary'}
                    testID={`order-summary-item-edit-button-${testIdPostfix}`}
                  >
                    Edit
                  </IconButton>
                )}
                {isEditable && <HSpacer size="3" />}
                {!!onDelete && (
                  <IconButton
                    appearance="ghost"
                    onPress={() => onDelete()}
                    size="small"
                    status={isDraft ? 'basic' : 'primary'}
                    testID={`order-summary-item-delete-button-${testIdPostfix}`}
                  >
                    Trash
                  </IconButton>
                )}
              </>
            )}
          </View>
        </>
      )}
    </>
  );
};
