import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Sizing, ThemeColors } from '../../../constants';

const styles = StyleSheet.create({
  alert: {
    position: 'relative',
    borderLeftWidth: Sizing.QUARTER_SPACING,
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    borderBottomRightRadius: Sizing.QUARTER_SPACING,
    marginBottom: Sizing.BASE_SPACING,
  },
  alertBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderTopRightRadius: Sizing.QUARTER_SPACING,
    borderBottomRightRadius: Sizing.QUARTER_SPACING,
    opacity: 0.25,
  },
  alertContent: { padding: Sizing.BASE_SPACING },
  alertText: {
    color: ThemeColors.WHITE,
    fontSize: 1.1 * Sizing.EM,
  },
});

export interface StyledAlertProps {
  level: 'danger' | 'warning' | 'success' | 'info';
  text?: string;
  style?: any;
  children?: any;
}

export const StyledAlert = (props: StyledAlertProps) => (
  <View
    style={[
      styles.alert,
      { borderLeftColor: ThemeColors[props.level.toUpperCase()] },
      props.style,
    ]}
  >
    <View
      style={[
        styles.alertBackground,
        { backgroundColor: ThemeColors[props.level.toUpperCase()] },
      ]}
    />
    <View style={styles.alertContent}>
      {props.text && <Text style={styles.alertText}>{props.text}</Text>}
      {props.children}
    </View>
  </View>
);
