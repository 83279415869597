export default {
  ACCEPTED: 'Accepted',
  ACRES_PLANNED: 'Acres Planned',
  ACRES: 'acres',
  ACRES_SHORT: 'ac',
  ACTIONS: 'Actions',
  ADD_FARMS: 'Add Farms',
  APPLICATION_COST_PER_ACRE: 'Application cost per acre',
  APPLIED_PROGRAMS_COUNT: '{{count}} Applied programs',
  APPLIED_PROGRAM_COUNT: '{{count}} Applied program',
  APPLY_DISCOUNT: 'Apply discount',
  APPLY_NEW_DISCOUNT: 'Apply New Discount',
  APPLY_PROGRAM: 'Apply Program',
  APPLY_PROGRAM_TO_ALL_SELECTED_ITEMS: 'Apply Program to all selected items',
  APPLY_SKU_DISCOUNT: 'Apply SKU discount',
  BILLING_AND_SHIPPING_CONTACTS: 'Billing and shipping contacts',
  CANCELLED_BADGE_TEXT: 'CANCELLED',
  CANCEL_SKU: 'Cancel SKU',
  CHANGES_YOU_MADE_SO_FAR_WILL_NOT_BE_SAVED: 'Changes you made so far will not be saved.',
  CLICK_HERE: 'click here',
  CONFIRM_CANCELLATION: 'Confirm cancellation',
  CORPORATE_LOCATION: 'Corporate',
  COST: 'Cost',
  COUNT_PRODUCTS: '{{ count }} Products',
  CREATE_DELIVERABLE: 'Create Deliverable',
  CREATE_DELIVERABLE_SENTENCE: 'Create deliverable',
  CREATE_FARM_PLAN: 'Create Farm Plan',
  CROP_ZONES_COUNT: '{{count}} Crop Zone(s)',
  CUSTOMER: 'Customer',
  DELETE_DISCOUNT: 'Delete discount?',
  DELIVERABLE_NAME: 'Farm Plan Deliverable name',
  DETAILS: 'Details',
  DISCARD_CHANGES: 'Discard changes?',
  DISCARD_FARM_PLAN: 'Discard this Farm Plan?',
  DISCOUNT: 'Discount',
  DISCOUNTS: 'Discounts',
  DISCOUNTS_SUCCESSFULLY_ADDED: 'Discounts successfully added',
  DISCOUNTS_SUCCESSFULLY_UPDATED: 'Discounts successfully updated',
  DISCOUNT_CUSTOM_NAME: 'Custom Discount Name',
  DISCOUNT_DUPLICATE: 'Discount already exists. Please change discount name to continue.',
  DISCOUNT_FAILURE: 'There was an error applying discounts.',
  DISCOUNT_INFO_MESSAGE_1: 'If a dollar discount is added, the percentage is calculated and rounded to 1 decimal place.',
  DISCOUNT_INFO_MESSAGE_2: 'If a percent discount is added, the dollar discount is calculated and rounded to 2 decimal places.',
  DISCOUNT_NAME: 'Discount Name',
  DISCOUNT_NAME_MUST_BE_UNIQUE: 'Discount name must be unique',
  DISCOUNT_TYPE: 'Discount Type',
  EDIT: 'Edit',
  EDIT_DETAILS: 'Edit Details',
  ERROR_DELIVERABLE_LOADING: 'There was an error loading the deliverable.',
  ERROR_FARM_PLAN: 'There was an unexpected error saving the farm plan.',
  ERROR_PROGRAMS_LOADING: 'There was an error loading the programs.',
  FARMS_COUNT: '{{count}} Farm(s)',
  FARM_PLAN: 'Farm Plan',
  FARM_PLANS_COUNT: '{{count}} Farm Plan(s)',
  FARM_PLAN_ALREADY_EXISTS: 'A farm plan with the name {{name}} already exists',
  FARM_PLAN_CANCEL_SKU_ACCEPT: 'Cancel SKU',
  FARM_PLAN_CANCEL_SKU_CONTINUE: 'Continue editing',
  FARM_PLAN_CANCEL_SKU_TITLE: 'Cancel SKU?',
  FARM_PLAN_CREATED: 'Farm Plan successfully created',
  FARM_PLAN_DETAILS: 'Farm Plan Details',
  FARM_PLAN_NOT_CREATED: 'This Farm Plan has not yet been created.',
  FARM_PLAN_ORDER_DETAILS: 'Farm Plan Order Details',
  FARM_PLAN_UPDATED: 'Farm Plan successfully updated',
  FIELDS_COUNT: '{{count}} Field(s)',
  FIELD_DETAILS: 'Field details',
  GENERATE_DELIVERABLE: 'Generate Deliverable',
  HIDE_DETAILS: 'Hide details',
  INFO_MESSAGE_1: 'Revenue is calculated from the Planning Parameters set within the Grower Details page.',
  INFO_MESSAGE_2: 'To set or adjust this grower\'s Yield Goals and Expected Market Price',
  KEEP_EDITING: 'Keep editing',
  LOCK_PRICE_MESSAGE: 'The current unit price for this product is {{currentPrice}} and unlocking will change the price to {{locationPrice}}.',
  NO_CROPS: 'No crops',
  NO_FARMS_MSG: 'No Farms',
  NO_FARMS_WARNING: '{{grower}} has no farms defined',
  NO_PRODUCTS: 'No products',
  NO_PRODUCTS_MATCH_SEARCH: 'No products match your search criteria.',
  ONE_OR_MORE_PRODUCTS_HAVE_BEEN_ACCEPTED: 'One or more products in this Farm Plan Order have been accepted. Once saved, the accepted products are locked and can no longer be changed.\n\nThis action cannot be undone.',
  ONE_OR_MORE_PRODUCTS_HAVE_BEEN_ACCEPTED_DYNAMICS: 'One or more products in this Farm Plan Order have been accepted. Once saved, the accepted products will be sent to Business Central, and can no longer be changed.\n\nThis action cannot be undone',
  ONE_PRODUCT: '1 Product',
  ORDER_DETAILS: 'Order details',
  ORDER_DISCOUNTS: 'Order Discounts',
  ORDER_SUBTOTAL: 'Order Subtotal',
  PASS_COUNT: '{{count}} Pass',
  PER_ACRE_DISCOUNTS: 'Per acre costs include all products contained within the order.',
  PER_ACRE_SHORT: '{{amount}}/ac',
  PER_SHORT: '{{area}}/{{areaUnitType}}',
  PLAN_NAME: 'Plan Name',
  PRICE_AC_TITLE: 'Price/ac',
  PRODUCTS_COUNT: 'Products ({{count}})',
  PRODUCT_COST_PER_UOM: '{{cost}}/{{uom}}',
  PRODUCT_SKU: 'Product SKU',
  PRODUCT_SKU_PRICE: 'Product Sku Price',
  PRODUCT_SKU_PRICE_SENTENCE: 'Product SKU price',
  PROFIT: 'Profit',
  PROFIT_SUMMARY: 'Profit Summary',
  PROGRAMS_COUNT: '{{count}} Program(s)',
  PROGRAM_ASSIGNMENT: 'Program Assignment',
  PROGRAM_ASSIGNMENT_SUB_TITLE: 'Select a farm, field and/or crop zone to add Programs to your Farm Plan.',
  PROGRAM_TEMPLATES_COUNT: '{{count}} Program templates',
  PROGRAM_TEMPLATE_COUNT: '{{count}} Program template',
  REJECTED: 'Rejected',
  REMOVE_LOGIC_MODAL_BUTTON: 'Remove program',
  REMOVE_LOGIC_MODAL_CONFIRMATION: 'This action cannot be undone. Are you sure you want to continue?',
  RETAIL_PRICE: 'Retail Price',
  REVENUE: 'Revenue',
  SALESPERSON: 'Salesperson',
  SAVE_FARM_PLAN_ORDER: 'Save Farm Plan order?',
  SEARCH_PRODUCTS: 'Search products',
  SELECT_A_DISCOUNT_TYPE: 'Select a discount type',
  SHARED: 'Shared',
  SHOW_APPLIED_PROGRAMS: 'Show applied programs',
  SHOW_DETAILS: 'Show details',
  TOTAL_ACRES_COUNT: 'Total acres: {{acres}}',
  TOTAL_ACRES_PLANNED: 'Total acres planned',
  TOTAL_COST: 'Total Cost',
  TOTAL_COST_SENTENCE_CASE: 'Total cost',
  TOTAL_ORDER_DISCOUNTS: 'Total order discounts',
  TOTAL_SAVINGS: 'Total savings',
  TOTAL_SKU_DISCOUNTS: 'Total SKU discounts',
  UNIT_PRICE_SENTENCE_CASE: 'Unit price',
  UNLOCK: 'Unlock',
  VIEW_ALL_CROPS: 'View all crops',
  VIEW_ALL_CROPS_MODAL_TITLE: 'Crops ({{count}})',
  VIEW_ALL_PRODUCTS: 'View all products',
  VIEW_DETAILS: 'View details',
  VIEW_DISCOUNTS: 'View discounts',
  VIEW_FIELD_DETAILS: 'View field details',
  VIEW_ORDER_DETAILS: 'View Order Details',
  YES_DISCARD_CHANGES: 'Yes, discard changes',
  YES_DISCARD_FARM_PLAN: 'Yes, discard Farm Plan',
  YES_SAVE: 'Yes, save',
};
