/**
 * Incorporates and repurposes code from
 * github.com/CaioQuirinoMedeiros/react-native-currency-input
 * to work with our component library
 */
import React, { FC, useMemo, useEffect, useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RenderProp } from '@ui-kitten/components/devsupport';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { TextProps } from '../Text/Text';
import { Input, InputProps } from './Input';
import { ValidatedInput } from './ValidatedInput';

export interface CurrencyInputProps extends Omit<InputProps, 'value'> {
  delimiter?: string;
  maxValue?: number;
  minValue?: number;
  onChangeValue?: (value: number | null) => void;
  precision?: number;
  prefix?: string;
  separator?: string;
  signPosition?: 'beforePrefix' | 'afterPrefix';
  suffix?: string;
  value: number | null;
}

export interface NumericInputProps extends CurrencyInputProps {
  errorMessage?: RenderProp<TextProps> | React.ReactText;
  isValidated?: boolean
  leftToRightMode?: boolean;
  onInputChange?: (s: string) => void
  readOnly?: boolean,
  readOnlyLabel?: boolean,
  showRelevantDecimalsOnly?: boolean,
  waitForBlur?: boolean,
}

export interface FormatNumberOptions {
  delimiter?: string;
  ignoreNegative?: boolean;
  precision?: number;
  prefix?: string;
  separator?: string;
  showRelevantDecimalsOnly?: boolean;
  signPosition?: 'beforePrefix' | 'afterPrefix';
  suffix?: string;
}

interface AddSignPrefixAndSuffixProps {
  prefix?: string;
  sign?: '+' | '-' | '';
  signPosition?: 'beforePrefix' | 'afterPrefix';
  suffix?: string;
}

// eslint-disable-next-line consistent-return
const addSignPrefixAndSuffix = (value: number | string, options: AddSignPrefixAndSuffixProps) => {
  const { prefix, sign, suffix, signPosition } = options;

  // eslint-disable-next-line default-case
  switch (signPosition) {
    case 'beforePrefix':
      return `${sign}${prefix}${value}${suffix}`;
    case 'afterPrefix':
      return `${prefix}${sign}${value}${suffix}`;
  }
};

const formatNumber = (input: number, options?: FormatNumberOptions) => {
  const {
    separator = '.',
    prefix = '',
    suffix = '',
    precision,
    showRelevantDecimalsOnly,
    ignoreNegative,
    delimiter = ',',
    signPosition = 'afterPrefix',
  } = options ?? {};

  const getFirstNonRelevantDecimalIndex = (decimals: string) => {
    for (let index = decimals.length - 1; index >= 0; index--) {
    	if (decimals[index] != '0') {
      	return index + 1;
      }
    }
    return 0;
  };

  const negative = ignoreNegative ? false : input < 0;
  const sign = negative ? '-' : '';

  const string = Math.abs(input).toFixed(precision);

  const parts = string.split('.');
  const buffer = [];

  let number = parts[0];
  while (number.length > 0) {
    buffer.unshift(number.substr(Math.max(0, number.length - 3), 3));
    number = number.substr(0, number.length - 3);
  }

  let formattedNumber = '';
  formattedNumber = buffer.join(delimiter);

  const decimals = parts[1];
  if (!!precision && decimals) {
    if (showRelevantDecimalsOnly) {
      const index = getFirstNonRelevantDecimalIndex(decimals);
      if (index > 0) {
        formattedNumber += separator + decimals.substring(0, index);
      }
    } else {
      formattedNumber += separator + decimals;
    }
  }

  return addSignPrefixAndSuffix(formattedNumber, {
    prefix,
    suffix,
    sign,
    signPosition,
  });
};

const NumericInputRaw: FC<NumericInputProps> = ({
  delimiter,
  errorMessage,
  isRequired,
  isValidated = true,
  leftToRightMode = true,
  maxValue = Number.MAX_SAFE_INTEGER,
  minValue = Number.MIN_SAFE_INTEGER,
  precision = 2,
  prefix = '',
  readOnly = false,
  readOnlyLabel = true,
  separator,
  showRelevantDecimalsOnly = false,
  signPosition = 'afterPrefix',
  suffix = '',
  onBlur,
  onChangeText,
  onChangeValue,
  onInputChange,
  t,
  value,
  waitForBlur,
  ...inputProps
}: NumericInputProps) => {
  const [startingWithSign, setStartingWithSign] = useState<'-' | '+' | null>();
  const [isFocused, setIsFocused] = useState(false);
  const noNegativeValues = typeof minValue === 'number' && minValue >= 0;
  const noPositiveValues = typeof maxValue === 'number' && maxValue <= 0;

  const formatNumberWithDefaultOptions = useCallback((valueToFormat: number) => {
    if (valueToFormat === null || valueToFormat === undefined) {
      return '';
    }

    return formatNumber(valueToFormat, {
      separator,
      prefix,
      suffix,
      precision,
      showRelevantDecimalsOnly,
      delimiter,
      ignoreNegative: noNegativeValues,
      signPosition,
    });
  }, [
    delimiter,
    noNegativeValues,
    prefix,
    precision,
    separator,
    showRelevantDecimalsOnly,
    suffix,
    signPosition,
  ]);

  const [leftToRightModeValue, setLeftToRightModeValue] = useState(
    formatNumberWithDefaultOptions(value),
  );

  useEffect(() => {
    if (value) {
      setLeftToRightModeValue(value.toString());
    }
  }, [value]);

  const formattedValue = useMemo(() => {
    if (leftToRightMode) {
      return leftToRightModeValue;
    }

    // eslint-disable-next-line no-compare-neg-zero
    if (!!value || value === 0 || value === -0) {
      return formatNumber(value, {
        separator,
        prefix,
        suffix,
        precision,
        showRelevantDecimalsOnly,
        delimiter,
        ignoreNegative: noNegativeValues,
        signPosition,
      });
    }
    return '';
  }, [
    value,
    separator,
    prefix,
    suffix,
    precision,
    delimiter,
    noNegativeValues,
    signPosition,
    showRelevantDecimalsOnly,
    leftToRightMode,
    leftToRightModeValue,
  ]);

  useEffect(() => {
    onChangeText?.(formattedValue);
  }, [formattedValue, onChangeText]);

  useEffect(() => {
    if (leftToRightMode && !isFocused) {
      setLeftToRightModeValue(formatNumberWithDefaultOptions(value));
    }
  }, [formatNumberWithDefaultOptions, isFocused, leftToRightMode, value]);

  const handleChangeText = useCallback((text: string) => {
    if (leftToRightMode) {
      const filteredText = text.replace(minValue >= 0 ? /[^0-9.]/g : /[^0-9-.]/g, '');
      const values = filteredText.split(separator ?? '.');
      const newValue = values.length > 1
        ? `${values[0]}${separator ?? '.'}${values[1].substring(0, precision)}`
        : filteredText;

      setLeftToRightModeValue(newValue);

      onChangeValue?.(newValue ? Number(newValue) : null);

      return;
    }

    let textWithoutPrefix = text;

    if (prefix) {
      textWithoutPrefix = text.replace(prefix, '');
      if (textWithoutPrefix === text) {
        textWithoutPrefix = text.replace(prefix.slice(0, -1), '');
      }
    }

    let textWithoutPrefixAndSufix = textWithoutPrefix;
    if (suffix) {
      const suffixRegex = new RegExp(`${suffix}([^${suffix}]*)$`);
      textWithoutPrefixAndSufix = textWithoutPrefix.replace(suffixRegex, '');

      if (textWithoutPrefixAndSufix === textWithoutPrefix) {
        textWithoutPrefixAndSufix = textWithoutPrefix.replace(suffix.slice(1), '');
      }
    }

    // Starting with a minus or plus sign
    if (/^(-|-0)$/.test(text) && !noNegativeValues) {
      setStartingWithSign('-');
      onChangeText?.(
        addSignPrefixAndSuffix(formattedValue, {
          prefix,
          suffix,
          sign: '-',
          signPosition,
        }),
      );
      return;
    }
    if (/^(\+|\+0)$/.test(text) && !noPositiveValues) {
      setStartingWithSign('+');
      onChangeText?.(
        addSignPrefixAndSuffix(formattedValue, {
          prefix,
          suffix,
          sign: '+',
          signPosition,
        }),
      );
    }
    setStartingWithSign(null);

    const isNegativeValue = textWithoutPrefixAndSufix.includes('-');

    const textNumericValue = textWithoutPrefixAndSufix.replace(/\D+/g, '');

    const numberValue = Number(textNumericValue) * (isNegativeValue ? -1 : 1);

    const zerosOnValue = textNumericValue.replace(/[^0]/g, '').length;

    let newValue: number | null;

    if (!textNumericValue || (!numberValue && zerosOnValue === precision)) {
      // Allow to clean the value instead of being 0
      newValue = null;
    } else {
      newValue = numberValue / 10 ** precision;
    }
    if (newValue && maxValue !== undefined && newValue > maxValue) {
      return;
    }
    if (newValue && minValue !== undefined && newValue < minValue) {
      return;
    }

    onChangeValue?.(newValue);
  }, [
    suffix,
    prefix,
    noNegativeValues,
    noPositiveValues,
    precision,
    maxValue,
    minValue,
    onChangeValue,
    onChangeText,
    formattedValue,
    signPosition,
    leftToRightMode,
    separator,
  ]);

  const textInputValue = React.useMemo(() => (startingWithSign
    ? addSignPrefixAndSuffix(formattedValue, {
      prefix,
      suffix,
      sign: startingWithSign,
      signPosition,
    })
    : formattedValue), [formattedValue, prefix, signPosition, startingWithSign, suffix]);

  const onFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setIsFocused(false);
    if (leftToRightMode) {
      if (!leftToRightModeValue) {
        onChangeValue?.(null);
        onBlur?.(e);
        return;
      }

      let newValue = Number(leftToRightModeValue.replace(/[^0-9-.]/g, ''));

      if (newValue && maxValue !== undefined && newValue > maxValue) {
        return;
      }
      if (newValue && minValue !== undefined && newValue < minValue) {
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(newValue)) {
        newValue = null;
      }

      setLeftToRightModeValue(
        formatNumberWithDefaultOptions(newValue),
      );

      if (waitForBlur) {
        setTimeout(() => {
          onChangeValue?.(newValue);
        }, 100);
      } else {
        onChangeValue?.(newValue);
      }
    }

    onBlur?.(e);
  };

  const { selection } = inputProps;

  return isValidated ? (
    <ValidatedInput
      errorMessage={errorMessage}
      isRequired={isRequired}
      keyboardType="numeric"
      onBlur={handleBlur}
      onChangeText={handleChangeText}
      onFocus={onFocus}
      readonly={readOnly}
      readonlyLabel={readOnlyLabel}
      selection={
        suffix && !leftToRightMode
          ? { start: Math.max(textInputValue.length - suffix.length, 0) }
          : selection
      }
      value={leftToRightMode ? leftToRightModeValue : textInputValue}
      {...inputProps}
    />
  ) : (
    <Input
      isRequired={isRequired}
      onBlur={handleBlur}
      onChangeText={handleChangeText}
      onFocus={onFocus}
      readonly={readOnly}
      readonlyLabel={readOnlyLabel}
      value={leftToRightMode ? leftToRightModeValue : textInputValue}
      {...inputProps}
    />
  );
};
const NumericInputMemo = React.memo(NumericInputRaw);
export const NumericInput = withTranslation('common', { withRef: true })(NumericInputMemo);
