import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  ReactNode,
} from 'react';
import { CreateDeliverableFarmPlanModalProps } from '../src/pages/CustomerPlanning/FarmPlanTab/FarmPlanForm/CreateDeliverableFarmPlanModal/CreateDeliverableFarmPlanModal';
import { BusinessModalProps } from '../src/pages/BusinessDetails/BusinessModal';
import {
  ShareDeliverableModalProps,
} from '../src/components/shared/ShareDeliverableModal/ShareDeliverableModal';
import { OtherProductModalProps } from '../src/pages/ProductList/OtherProductModal';
import {
  CreateDeliverableModalProps,
} from '../src/pages/ProductOrder/components/CreateDeliverableModal/CreateDeliverableModal';
import { ProductOrderModalProps } from '../src/pages/ProductOrder/ProductOrderModal';
import { ViewDeliverableModalProps } from '../src/pages/ProductOrder/ViewDeliverableModal';
import { ViewProductOrderModalProps } from '../src/pages/ProductOrder/ViewProductOrderModal';
import { UserFormModalProps } from '../src/pages/User/UserFormModal';

interface BusinessContext extends Omit<BusinessModalProps, 'onClose'> {
  type: 'businessModal',
}
interface CreateDeliverableContext extends Omit<CreateDeliverableModalProps, 'onClose'> {
  type: 'createDeliverable',
}
interface CreateDeliverableFarmPlanContext extends Omit<CreateDeliverableFarmPlanModalProps, 'onClose'> {
  type: 'createDeliverableFarmPlan',
}
interface OtherProductContext extends Omit<OtherProductModalProps, 'onClose'> {
  type: 'otherProductModal',
}
interface ProductOrderContext extends Omit<ProductOrderModalProps, 'onClose'> {
  type: 'productOrder',
}
interface ShareDeliverableContext extends Omit<ShareDeliverableModalProps, 'onClose'> {
  type: 'shareDeliverable',
}
interface UserFormContext extends Omit<UserFormModalProps, 'onClose'> {
  type: 'userFormModal',
}
interface ViewDeliverableContext extends Omit<ViewDeliverableModalProps, 'onClose'> {
  type: 'viewDeliverableModal',
}
interface ViewProductOrderContext extends Omit<ViewProductOrderModalProps, 'onClose'> {
  type: 'viewProductOrder',
}
export type ModalContext =
  BusinessContext |
  CreateDeliverableContext |
  CreateDeliverableFarmPlanContext |
  OtherProductContext |
  ProductOrderContext |
  ShareDeliverableContext |
  UserFormContext |
  ViewDeliverableContext |
  ViewProductOrderContext;

interface ErrorBannerContext {
  type: 'error',
  message: string,
}
export type BannerProps = ErrorBannerContext;

interface AppContextProps {
  modalProps: ModalContext | null,
  setModalProps: Dispatch<SetStateAction<ModalContext>>,
}

export const AppContext = React.createContext<AppContextProps | null>(null);

interface AppContextProviderProps {
  children: ReactNode,
}
// General use case provider for anything that has to be managed globally
export const AppContextProvider: FunctionComponent<AppContextProviderProps> = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalContext>(null);

  const value = useMemo(
    () => ({
      modalProps,
      setModalProps,
    }),
    [
      modalProps,
      setModalProps,
    ],
  );

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within AppContextProvider');
  }

  return context;
};
