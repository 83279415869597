import {
  Badge,
  Card,
  DataPoint,
  Header,
  HSpacer,
  Modal,
  Table,
  TableCell,
  TableRow,
  Text,
  TextLink,
  VSpacer,
} from '@design';
import { ApplicationUom } from '@shared/enums';
import { ApiProductSummary } from '@shared/interfaces/api';
import { CalculationUtility } from '@shared/utils';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, StyleSheet, View } from 'react-native';
import { StringUtility } from '../../../../../utilities';

const styles = StyleSheet.create({
  card: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  viewAllProducts: {
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
  },
  viewItems: {
    maxHeight: 400,
  },
});

const abridgedProductsLimit = 5;

export interface FarmPlanProductsProps {
  locationId: string | null,
  priceTypeId: string,
  products: ApiProductSummary[],
}

export const FarmPlanProducts = ({ locationId, priceTypeId, products }: FarmPlanProductsProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);
  const [viewAllProducts, setViewAllProducts] = useState(false);

  const abridgedProducts = products.slice(0, abridgedProductsLimit);
  const isAbridged = products.length > abridgedProducts.length;

  const getCostPerAcre = useCallback(({
    product,
    avgRateApplied,
    avgRateAppliedUom,
  }: ApiProductSummary) => StringUtility.formatCurrencyAccounting(
    CalculationUtility.calculateProductCostPerAcre(
      product,
      avgRateApplied,
      avgRateAppliedUom as ApplicationUom,
      CalculationUtility.getApplicableProductPrice(product, priceTypeId, locationId),
    ),
  ), [locationId, priceTypeId]);

  const ProductsTableHeader = () => (
    <Table tableBackground="transparent" testID="product-table-header">
      <TableRow appearance="header" testID="product-table-row">
        <TableCell style={{ flex: 1 }} testID="product-sku">
          {translate<string>('PRODUCT_SKU')}
        </TableCell>
        <TableCell testID="price-per-acre">
          {translate<string>('PRICE_AC_TITLE')}
        </TableCell>
      </TableRow>
    </Table>
  );

  return (
    <View style={styles.card}>
      <Header level="3" testID="products-header" title={translate<string>('PRODUCTS')} />
      <VSpacer size="5" />
      <Card style={styles.card} testID="products-card">
        <DataPoint
          flex
          label={translate<string>('COUNT_PRODUCTS', { count: products.length })}
          testID="products-count"
        >
          <>
            <VSpacer size="5" />
            {abridgedProducts.map((item, idx) => (
              <Fragment key={item.product.id}>
                <View style={styles.container}>
                  <Text
                    category="p2"
                    numberOfLines={1}
                    style={{ flex: 1.5 }}
                    testID={`product-name-${idx}`}
                  >
                    {item.product.skuName}
                  </Text>
                  {!item.product.isActive && (
                    <Badge status="danger" testID={`inactive-badge-${idx}`}>
                      {translate<string>('INACTIVE')}
                    </Badge>
                  )}
                  <Text
                    category="p2"
                    numberOfLines={1}
                    style={{ flex: 1, textAlign: 'right' }}
                    testID={`product-cost-per-acre-${idx}`}
                  >
                    {translate<string>('PER_ACRE_SHORT', { amount: getCostPerAcre(item) })}
                  </Text>
                </View>
                {abridgedProducts.length > 1 && <VSpacer size="3" />}
              </Fragment>
            ))}
          </>
        </DataPoint>
        {isAbridged && (
          <>
            <VSpacer size="3" />
            <View style={styles.viewAllProducts}>
              <TextLink
                appearance="secondary"
                category="p2"
                onPress={() => setViewAllProducts(true)}
                style={{ alignSelf: 'flex-end' }}
                testID="view-all-products"
              >
                {translate<string>('VIEW_ALL_PRODUCTS')}
              </TextLink>
            </View>
          </>
        )}
      </Card>
      <Modal
        onClose={() => setViewAllProducts(false)}
        subTitle={translate(
          products.length === 1 ? 'ONE_PRODUCT' : 'COUNT_PRODUCTS',
          { count: products.length },
        )}
        testID="view-all-products-modal"
        title={translate<string>('PRODUCTS')}
        visible={viewAllProducts}
      >
        <View style={styles.viewItems}>
          <FlatList
            ListHeaderComponent={ProductsTableHeader}
            data={products}
            keyExtractor={(item) => item.product.id}
            renderItem={({ item, index }) => {
              return (
                <Table tableBackground="transparent" testID="farm-plan-products-modal-table">
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{ flex: 1 }}
                      testID={`product-${index}-sku-name`}
                    >
                      {item.product.skuName}
                    </TableCell>
                    {!item.product.isActive && (
                      <TableCell testID={`product-${index}-inactive`}>
                        <>
                          <HSpacer size="4" />
                          <Badge status="danger" testID="danger">
                            {translate<string>('INACTIVE')}
                          </Badge>
                        </>
                      </TableCell>
                    )}
                    <TableCell
                      align="right"
                      testID={`product-${index}-cost-per-acre`}
                    >
                      {translate<string>('PER_ACRE_SHORT', { amount: getCostPerAcre(item) })}
                    </TableCell>
                  </TableRow>
                </Table>
              );
            }}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </Modal>
    </View>
  );
};
