import { ApiPriceType } from '@shared/interfaces/api';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { PriceTypeApi } from '../utilities/api/PriceTypeApi';

export interface UsePriceTypeList {
  defaultPriceType: ApiPriceType,
  defaultPriceTypeId: string,
  error: unknown,
  invalidatePriceTypeList: () => void,
  isLoading: boolean,
  priceTypes: ApiPriceType[],
}

const EMPTY_ARRAY = [];

export const usePriceTypeList = (
): UsePriceTypeList => {
  const queryClient = useQueryClient();
  const { currentBusinessId } = useAuthentication();

  const invalidatePriceTypeList = () => {
    queryClient.invalidateQueries([QueryKeys.PRICE_TYPE_LIST, currentBusinessId]);
  };

  const { data: priceTypes, isLoading, error } = useQuery(
    [QueryKeys.PRICE_TYPE_LIST, currentBusinessId],
    () => PriceTypeApi.list(currentBusinessId),
    {
      enabled: !!currentBusinessId,
      placeholderData: EMPTY_ARRAY,
      staleTime: 5 * 60 * 1000,
    },
  );

  const defaultPriceType = priceTypes?.find((priceType) => priceType.isDefault);

  return {
    defaultPriceType,
    defaultPriceTypeId: defaultPriceType?.id,
    error,
    invalidatePriceTypeList,
    isLoading,
    priceTypes: priceTypes ?? EMPTY_ARRAY,
  };
};
