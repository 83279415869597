import React, { useEffect, useState } from 'react';
import { Button, CheckBox, Dialog, HSpacer, VSpacer } from '@design';
import { useTranslation } from 'react-i18next';
import { CustomerContactSelect, CustomerContactMode } from '../Input/CustomerContactSelect';

interface EditBillingShippingContactsModalProps {
  billingContactId: string,
  customerId: string,
  onClose: () => void,
  onUpdateCustomerContact: (contacts: Contacts) => void,
  shippingContactId: string,
}

interface Contacts {
  billingContactId: string,
  shippingContactId: string,
}

export const EditBillingShippingContactsModal = (props: EditBillingShippingContactsModalProps) => {
  const {
    billingContactId,
    customerId,
    onClose,
    onUpdateCustomerContact,
    shippingContactId,
  } = props;
  const [translate] = useTranslation(['common', 'productOrders']);
  const [contacts, setContacts] = useState<Contacts>(null);

  useEffect(() => {
    setContacts({ billingContactId, shippingContactId });
  }, [billingContactId, shippingContactId]);

  const checkBillingShippingSame = () => {
    if (contacts.billingContactId === contacts.shippingContactId) {
      setContacts({ ...contacts, shippingContactId: null });
    } else {
      setContacts({
        ...contacts,
        shippingContactId: contacts.billingContactId,
      });
    }
  };

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            onPress={onClose}
            testID="edit-billing-shipping-contacts-cancel"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            onPress={() => {
              onUpdateCustomerContact(contacts);
              onClose();
            }}
            testID="edit-billing-shipping-contacts-apply"
          >
            {translate<string>('APPLY')}
          </Button>
        </>
      )}
      onClose={onClose}
      testID="edit-billing-shipping-contacts-dialog"
      title={translate('EDIT_CONTACTS')}
      visible
    >
      <CustomerContactSelect
        customerContactId={contacts?.billingContactId}
        customerId={customerId}
        mode={CustomerContactMode.BILLING}
        onUpdateCustomerContact={(billingId) => setContacts((prevContacts) => ({
          ...prevContacts,
          billingContactId: billingId,
        }))}
        optionWindowMaxHeight={230}
      />
      <VSpacer size="8" />
      <CustomerContactSelect
        customerContactId={contacts?.shippingContactId}
        customerId={customerId}
        mode={CustomerContactMode.SHIPPING}
        onUpdateCustomerContact={(shippingId) => setContacts((prevContacts) => ({
          ...prevContacts,
          shippingContactId: shippingId,
        }))}
        optionWindowPlacement="top"
      />
      <VSpacer size="8" />
      <CheckBox
        checked={
          contacts?.billingContactId
          && contacts?.billingContactId === contacts.shippingContactId
        }
        onChange={checkBillingShippingSame}
        testID="edit-billing-shipping-contacts-checkbox"
      >
        {translate<string>('SHIPPING_SAME_AS_BILLING')}
      </CheckBox>
    </Dialog>
  );
};
