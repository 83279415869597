import { pick } from '@shared/utils';
import React from 'react';
import { View } from 'react-native';
import { Sizing } from '../../constants';
import { useHistory } from '../../router';
import { Button, Text } from '../../ui-components';
import { Environment } from '../../utilities';

export const VersionPage = () => {
  const history = useHistory();

  return (
    <>
      <Text style={{ marginBottom: Sizing.BASE_SPACING }}>
        {JSON.stringify(pick(
          Environment.getManifest(),
          ['name', 'version', 'extra'],
        ), null, 4)}
      </Text>
      <View style={{ flexDirection: 'row' }}>
        <Button onPress={() => history.push('/')} status="secondary">Back</Button>
      </View>
    </>
  );
};
