import {
  Button,
  Header,
  HSpacer,
  Input,
  LargeModal,
  ModalSpinner,
  ToastProps,
  useToast,
  VSpacer,
} from '@design';
import { State } from '@shared/constants';
import { ApiBusinessContact, BusinessEndpoint } from '@shared/interfaces/api';
import { validateEmail, validatePhoneNumber, validatePostalCode } from '@shared/utils';
import { isEqual } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { BusinessApi } from '../../../utilities/api';
import { BusinessCountrySelect } from '../../components/shared/Input/BusinessCountrySelect';
import { BusinessStateInput } from '../../components/shared/Input/BusinessStateInput';
import { BusinessContactForm } from './BusinessContactForm';
import { generateBlankContact, useBusiness } from './helpers';
import { Icon } from '@ui-kitten/components';

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: 489,
  },
  actionButtons: {
    position: 'absolute',
    right: 40,
    bottom: 40,
    display: 'flex',
    flexDirection: 'row',
  },
  footer: { flexDirection: 'row' },
});

export interface BusinessModalProps {
  businessId?: string,
  editMode?: boolean,
  onClose: () => Promise<void>,
}

export const BusinessModal = ({
  businessId,
  editMode,
  onClose,
}: BusinessModalProps) => {
  const [translate] = useTranslation(['businesses', 'common']);
  const { createToast } = useToast();
  const { updateBusinessList } = useAuthentication();
  const {
    business,
    initialBusiness,
    setBusiness,
    isLoading,
  } = useBusiness({
    businessId,
    onError: () => createToast({
      children: translate<string>('UNEXPECTED_ERROR'),
      status: 'danger',
      testID: 'toast-content-element',
    }),
  });
  const [contacts, setContacts] = useState<Array<ApiBusinessContact>>([generateBlankContact()]);
  const [states, setStates] = useState<State[]>([]);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);

  const toastProps = (
    toastText: string,
    status: ToastProps['status'] = 'success',
  ): ToastProps => ({
    children: toastText,
    status,
    testID: 'toast-content-element',
  });

  const upsertBusiness = async (): Promise<boolean> => {
    try {
      const { id, ...newBusiness } = business;

      if (!id) {
        const res = (
          await BusinessApi.createBusiness({
            ...newBusiness,
            contacts,
          } as BusinessEndpoint.Create.Request)
        );
        createToast(toastProps(translate('BUSINESS_CREATED')));
        setBusiness(res, true);
      } else {
        const res = await BusinessApi.updateBusiness(
          id,
          newBusiness,
        );
        setBusiness(res, true);
        createToast(toastProps(translate('BUSINESS_UPDATED')));
      }

      updateBusinessList();
      return true;
    } catch (error) {
      createToast({
        children: translate<string>('ERROR_SAVING_BUSINESS'),
        status: 'danger',
        testID: 'toast-content-element',
      });
      return false;
    }
  };

  const formValid = (): boolean => {
    const requiredFields = [
      business.businessName,
      business.address1,
      business.city,
      business.country,
      business.state,
    ];

    return !(
      requiredFields.some((v) => !v)
      || !validateEmail(business.contactEmail)
      || !validatePhoneNumber(business.telephone)
      || !validatePostalCode(business.postalCode, business.country)
      || (!editMode && !contacts?.length)
      || (!editMode && contacts.some((contact) => (
        !contact.contactName
        || !validateEmail(contact.contactEmail)
      )))
    );
  };

  const addNewContact = () => setContacts([...contacts, generateBlankContact()]);

  return (
    <LargeModal
      footer={(
        <View style={styles.actionButtons} testID="footer">
          {!!onClose && (
            <Button
              appearance="outline"
              design="floating"
              onPress={onClose}
              size="large"
              status="basic"
              testID="footer-close-button"
            >
              {translate<string>('CANCEL')}
            </Button>
          )}
          <HSpacer size="8" />
          <Button
            design="floating"
            disabled={isEqual(initialBusiness, business) || !formValid()}
            onPress={async () => {
              const saved = await upsertBusiness();
              if (saved) {
                await onClose();
              }
            }}
            size="large"
            testID="footer-save-button"
          >
            {translate<string>('SAVE_CHANGES')}
          </Button>
        </View>
      )}
      key={businessId}
      pages={!isLoading && [
        <KeyboardAwareScrollView contentContainerStyle={styles.container}>
          <Header level="4" testID="business-details-header" title={translate('BUSINESS_DETAILS')} />
          <VSpacer size="9" />
          <Input
            isRequired
            label={translate<string>('BUSINESS_NAME')}
            onChangeText={(businessName) => setBusiness({ ...business, businessName })}
            testID="business-name-field"
            value={business.businessName}
          />
          <VSpacer size="8" />
          <Input
            caption={emailError ? translate<string>('VALID_EMAIL_REQUIRED') : undefined}
            isRequired
            keyboardType="email-address"
            label={translate<string>('BUSINESS_EMAIL')}
            onBlur={() => setEmailError(
              !!business.contactEmail && !validateEmail(business.contactEmail),
            )}
            onChangeText={(contactEmail) => setBusiness({ ...business, contactEmail })}
            status={emailError ? 'danger' : undefined}
            testID="business-email-field"
            value={business.contactEmail}
          />
          <VSpacer size="8" />
          <Input
            caption={phoneError ? translate<string>('VALID_PHONE_REQUIRED') : undefined}
            isRequired
            keyboardType="phone-pad"
            label={translate<string>('BUSINESS_TELEPHONE')}
            onBlur={() => setPhoneError(
              !!business.telephone && !validatePhoneNumber(business.telephone),
            )}
            onChangeText={(telephone) => setBusiness({ ...business, telephone })}
            status={phoneError ? 'danger' : undefined}
            testID="business-telephone-field"
            value={business.telephone}
          />
          <VSpacer size="8" />
          <Input
            isRequired
            label={translate<string>('ADDRESS_LINE_1')}
            onChangeText={(address1) => setBusiness({ ...business, address1 })}
            testID="business-address1-field"
            value={business.address1}
          />
          <VSpacer size="8" />
          <Input
            label={translate<string>('ADDRESS_LINE_2')}
            onChangeText={(address2) => setBusiness({ ...business, address2 })}
            testID="business-address2-field"
            value={business.address2 || ''}
          />
          <VSpacer size="8" />
          <Input
            isRequired
            label={translate<string>('CITY')}
            onChangeText={(city) => setBusiness({ ...business, city })}
            testID="business-city-field"
            value={business.city}
          />
          <VSpacer size="8" />
          <BusinessCountrySelect
            country={business.country}
            onUpdateCountry={(country, _states) => {
              setBusiness({ ...business, country, state: business.state ?? _states[0]?.name });
              setStates(_states);
            }}
          />
          <VSpacer size="8" />
          <BusinessStateInput
            onUpdateState={(state) => {
              setBusiness({ ...business, state });
            }}
            state={business.state}
            states={states}
          />
          <VSpacer size="8" />
          <Input
            label={translate<string>('COUNTY')}
            onChangeText={(county) => setBusiness({ ...business, county })}
            testID="business-county-field"
            value={business.county || ''}
          />
          <VSpacer size="8" />
          <Input
            caption={postalCodeError ? translate<string>('VALID_POSTAL_CODE_REQUIRED') : undefined}
            isRequired
            label={translate<string>('ZIPCODE')}
            onBlur={() => setPostalCodeError(
              !!business.postalCode && !validatePostalCode(business.postalCode, business.country),
            )}
            onChangeText={(postalCode) => setBusiness({ ...business, postalCode })}
            status={postalCodeError ? 'danger' : undefined}
            testID="business-zip-code-field"
            value={business.postalCode}
          />
          {!editMode && (
            <>
              <VSpacer size="12" />
              <Header
                level="4"
                testID="business-contact"
                title={translate('CONTACTS')}
              />
              {contacts.map((contact, idx) => (
                <BusinessContactForm
                  contact={contact}
                  key={idx}
                  onContactChange={(newContact) => {
                    const updatedContacts = [...contacts];
                    updatedContacts[idx] = newContact;
                    setContacts(updatedContacts);
                  }}
                />
              ))}
              <VSpacer size="8" />
              <View style={{ alignItems: 'flex-start' }}>
                <Button
                  accessoryLeft={(iconProps) => <Icon name="plus" {...iconProps} />}
                  appearance="ghost"
                  disabled={!contacts.every(({ contactEmail, contactName }) => (
                    validateEmail(contactEmail)
                    && contactName
                  ))}
                  onPress={() => addNewContact()}
                  size="medium"
                  testID="add-another-contact-button"
                >
                  {translate('ADD_ANOTHER_CONTACT')}
                </Button>
              </View>
            </>
          )}
          <ModalSpinner visible={isLoading} />
        </KeyboardAwareScrollView>,
      ]}
      testID="business-modal"
      title={translate<string>(editMode ? 'EDIT_BUSINESS' : 'CREATE_BUSINESS')}
      visible
    />
  );
};
