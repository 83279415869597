import { Badge, Button, HSpacer, Icon, IconButton, Input, VSpacer } from '@design';
import { Divider } from '@ui-kitten/components';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

export const maxTagLength = 50;

const styles = StyleSheet.create({
  badge: {
    paddingLeft: 8,
  },
  container: {
    width: 512,
  },
  divider: {
    backgroundColor: '#3F3E38',
    display: 'flex',
  },
  input: {
    flexGrow: 1,
  },
  tagRow: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 8,
  },
});

interface TagRowProps {
  existingTags?: string[],
  onDelete: () => void,
  onSave: (name: string) => void,
  readOnly?: boolean,
  tag: string,
  testID: string,
}

const TagRow = ({
  existingTags,
  onDelete,
  onSave,
  readOnly = false,
  tag,
  testID,
}: TagRowProps) => {
  const [translate] = useTranslation(['common', 'businesses']);
  const [name, setName] = useState('');
  const [editMode, setEditMode] = useState(false);

  const trimmedName = name.trim();
  const isDuplicateName = existingTags?.includes(trimmedName) && trimmedName !== tag;

  const clearIcon = (props) => (
    <TouchableOpacity onPressIn={() => setName('')}>
      <Icon name="Close" testID={`${testID}-input-clear-button`} {...props} />
    </TouchableOpacity>
  );

  const editModeButtons = (
    <>
      <IconButton
        appearance="outline"
        disabled={!trimmedName || trimmedName === tag || isDuplicateName}
        onPress={() => {
          onSave(trimmedName);
          setEditMode(false);
          setName('');
        }}
        status="primary"
        testID={`${testID}-save-button`}
      >
        <Icon
          name="Checkmark"
          testID={`${testID}-save-button-icon`}
        />
      </IconButton>
      <HSpacer size="3" />
      <IconButton
        appearance="outline"
        onPress={() => {
          setEditMode(false);
          setName('');
        }}
        status="basic"
        testID={`${testID}-close-button`}
      >
        <Icon
          name="Close"
          testID={`${testID}-close-button-icon`}
        />
      </IconButton>
    </>
  );

  const viewModeButtons = (
    <>
      <IconButton
        appearance="ghost"
        disabled={readOnly}
        onPress={() => {
          setName(tag);
          setEditMode(true);
        }}
        status="basic"
        testID={`${testID}-edit-button`}
      >
        <Icon
          name="Edit"
          testID={`${testID}-edit-button-icon`}
        />
      </IconButton>
      <HSpacer size="3" />
      <IconButton
        appearance="ghost"
        disabled={readOnly}
        onPress={onDelete}
        status="basic"
        testID={`${testID}-delete-button`}
      >
        <Icon
          name="Trash"
          testID={`${testID}-delete-button-icon`}
        />
      </IconButton>
    </>
  );

  return (
    <View style={styles.tagRow}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {editMode ? (
          <Input
            accessoryRight={name && clearIcon}
            caption={isDuplicateName && translate('CUSTOM_TAG_ALREADY_EXISTS')}
            maxLength={maxTagLength}
            onChangeText={setName}
            placeholder={translate('ENTER_CUSTOM_TAG_NAME')}
            status={isDuplicateName ? 'danger' : undefined}
            style={styles.input}
            testID={`${testID}-input`}
            value={name}
          />
        ) : (
          <Badge style={styles.badge} testID={`${testID}-badge`}>
            {tag}
          </Badge>
        )}
      </View>
      <HSpacer size="4" />
      <View style={{ flexDirection: 'row' }}>
        {editMode ? editModeButtons : viewModeButtons}
      </View>
    </View>
  );
};

interface CustomTagsProps {
  defaultTags?: string[],
  onChangeTags: (tags: string[]) => void,
  readOnly?: boolean,
  tags: string[],
  testID: string,
}

export const CustomTags = ({
  defaultTags = [],
  onChangeTags,
  readOnly = false,
  tags,
  testID,
}: CustomTagsProps) => {
  const [translate] = useTranslation(['common', 'businesses']);
  const [customTagName, setCustomTagName] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const existingTags = [...tags, ...defaultTags];
  const trimmedCustomTagName = customTagName.trim();
  const isDuplicateName = existingTags.includes(trimmedCustomTagName);

  const clearIcon = (props) => (
    <TouchableOpacity onPressIn={() => setCustomTagName('')}>
      <Icon name="CloseCircle" testID={`${testID}-input-clear-button`} {...props} />
    </TouchableOpacity>
  );

  const inputRow = (
    <View style={{ flexDirection: 'row' }}>
      <Input
        accessoryRight={customTagName && clearIcon}
        caption={isDuplicateName && translate('CUSTOM_TAG_ALREADY_EXISTS')}
        disabled={readOnly}
        maxLength={maxTagLength}
        onChangeText={setCustomTagName}
        placeholder={translate('ENTER_CUSTOM_TAG_NAME')}
        status={isDuplicateName ? 'danger' : undefined}
        style={styles.input}
        testID={`${testID}-input`}
        value={customTagName}
      />
      <HSpacer size="5" />
      <Button
        disabled={readOnly || !trimmedCustomTagName || isDuplicateName}
        onPress={() => {
          onChangeTags([...tags, trimmedCustomTagName]);
          setCustomTagName('');
        }}
        size="medium"
        testID={`${testID}-save-button`}
      >
        {translate('SAVE')}
      </Button>
    </View>
  );

  return (
    <>
      <View style={styles.container} testID={testID}>
        {inputRow}
        {tags.length > 0 && (
          <>
            <VSpacer size="9" />
            <View>
              <Divider style={styles.divider} />
              {tags.map((tag, index) => (
                <Fragment key={index}>
                  <TagRow
                    existingTags={existingTags}
                    onDelete={() => {
                      setSelectedTag(tag);
                      setShowDeleteConfirmationModal(true);
                    }}
                    onSave={(name) => {
                      onChangeTags(tags.map((tagName) => tagName === tag ? name : tagName));
                      setSelectedTag('');
                    }}
                    readOnly={readOnly}
                    tag={tag}
                    testID={`${testID}-row-${index}`}
                  />
                  <Divider style={styles.divider} />
                </Fragment>
              ))}
            </View>
          </>
        )}
      </View>
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('CONFIRM')}
          messageText={translate('DELETE_CUSTOM_TAG_MESSAGE')}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onConfirm={() => {
            onChangeTags(tags.filter((tag) => tag !== selectedTag));
            setShowDeleteConfirmationModal(false);
          }}
          title={translate('DELETE_CUSTOM_TAG')}
          visible
        />
      )}
    </>
  );
};
