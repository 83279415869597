import { BusinessLocationCountryList, Country } from '@shared/constants';
import { BusinessLocationEndpoint } from '@shared/interfaces/api';
import qs from 'qs';
import { Client } from './Client';

export class BusinessLocationApi {
  static async createBusinessLocation (
    businessId: string,
    location: BusinessLocationEndpoint.Create.Request,
  ): Promise<BusinessLocationEndpoint.Create.Response> {
    return Client('locations', {
      method: 'POST',
      body: location,
    });
  }

  static async updateBusinessLocation (
    businessId: string,
    id: string,
    location: BusinessLocationEndpoint.Update.Request,
  ): Promise<BusinessLocationEndpoint.Update.Response> {
    return Client(`locations/${id}`, {
      method: 'PATCH',
      body: location,
    });
  }

  /**
   * Returns the list of countries that can be selected in the location form.
   * API is asynchronous so it could be easily moved into backend later
   */
  static async getCountries (): Promise<Country[]> {
    return Promise.resolve(BusinessLocationCountryList);
  }

  static async getBusinessLocation (id: string): Promise<BusinessLocationEndpoint.Get.Response> {
    return Client(`locations/${id}`);
  }

  /**
   * Fetches a page of business locations
   */
  static async getLocations (
    filters: BusinessLocationEndpoint.List.Query,
  ): Promise<BusinessLocationEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', encode: false });
    return Client(`locations?${query}`);
  }
}
