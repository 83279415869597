import React, { useCallback, useState } from 'react';
import { Button, Filter, Header, Icon, Text, useToast, VSpacer } from '@design';
import { StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ApiCropLogicPass, ApiUserAccount, CropLogicEndpoint } from '@shared/interfaces/api';
import { CropSubType, CropType } from '@shared/enums';
import { PassModal } from '../../../components/shared/PassModal/PassModal';
import { PassCard } from './PassCard';

const styles = StyleSheet.create({
  addPassButton: {
    paddingTop: 0,
  },
  noPasses: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
interface ProgramDetailsPassesProps {
  businessLocationId: string,
  canEdit: boolean,
  cropSubType: CropSubType,
  cropType: CropType,
  customerId?: string,
  noPassesMessage?: string,
  onUpdatePasses: (passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[]) => void,
  passes: (ApiCropLogicPass | CropLogicEndpoint.PassCreate.Request)[],
  priceTypeId: string,
  user: ApiUserAccount,
}

export const ProgramDetailsPasses = ({
  businessLocationId,
  canEdit,
  cropSubType,
  cropType,
  customerId,
  noPassesMessage,
  onUpdatePasses,
  passes,
  priceTypeId,
}: ProgramDetailsPassesProps) => {
  const [translate] = useTranslation(['common', 'programs']);
  const { createToast } = useToast();
  const [passOptions, setPassOptions] = useState<{
    index?: number,
    pass?: ApiCropLogicPass,
    visible: boolean,
  }>({
    visible: false,
  });

  const handleSave = useCallback((newPass: ApiCropLogicPass, index?: number) => {
    if (index === undefined) {
      onUpdatePasses([...passes, { ...newPass, order: passes.length }]);
    } else {
      onUpdatePasses(passes.map((pass, currentIndex) => (
        currentIndex === index ? newPass : pass
      )));
    }

    setPassOptions({ visible: false });
  }, [onUpdatePasses, passes]);

  const handleDelete = useCallback((index: number) => {
    onUpdatePasses(
      passes.filter((pass, currentIndex) => currentIndex !== index),
    );
    createToast({
      children: translate<string>('PASS_WAS_SUCCESSFULLY_DELETED_FROM_PROGRAM'),
      status: 'success',
      testID: 'toast-content-element',
    });
  }, [createToast, onUpdatePasses, passes, translate]);

  const handleEdit = useCallback((pass: ApiCropLogicPass, index: number) => {
    setPassOptions({ visible: true, pass, index });
  }, []);

  return (
    <View>
      <Header
        childrenStyle={styles.addPassButton}
        level="2"
        testID="program-details-passes-passes-header"
        title={translate<string>('PASSES')}
      >
        {canEdit && (
          <Button
            accessoryLeft={(buttonProps) => (
              <Icon {...buttonProps} name="Plus" testID="program-details-passes-add-pass-button-icon" />
            )}
            appearance="outline"
            onPress={() => setPassOptions({ visible: true })}
            size="medium"
            testID="program-details-passes-add-pass-button"
          >
            {translate<string>('ADD_PASS')}
          </Button>
        )}
      </Header>
      {passes?.length > 0 ? (
        <>
          <VSpacer size="8" />
          <Filter
            defaultColumnFilters={[]}
            filterOptions={[]}
            noFilters
            noSearchBox
            onUpdateFilter={() => {}}
            testID="program-details-passes-passes-filter"
            totalResults={passes.length}
            totalResultsText={translate<string>(
              passes.length > 1 ? 'COUNT_PASSES' : 'COUNT_PASS',
              { count: passes.length },
            )}
          />
          {passes.map((pass, index) => (
            <View key={pass.id ?? index}>
              <PassCard
                businessLocationId={businessLocationId}
                canEdit={canEdit}
                index={index}
                onDelete={handleDelete}
                onEdit={handleEdit}
                pass={pass}
                priceTypeId={priceTypeId}
              />
              <VSpacer size="5" />
            </View>
          ))}
        </>
      ) : (
        <View style={styles.noPasses}>
          <VSpacer size="11" />
          <Text
            category="h6"
            testID="program-details-passes-no-passes"
          >
            {noPassesMessage ?? translate<string>('NO_PASSES')}
          </Text>
        </View>
      )}
      {passOptions.visible && (
        <PassModal
          businessLocationId={businessLocationId}
          cropSubType={cropSubType}
          cropType={cropType}
          customerId={customerId}
          index={passOptions.index}
          onClose={() => setPassOptions({ visible: false })}
          onSave={handleSave}
          pass={passOptions.pass}
          priceTypeId={priceTypeId}
        />
      )}
    </View>
  );
};
