import { CenteredSpinner, Text, useToast, VSpacer } from '@design';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { Permissions, RoleUtility } from '@shared/utils';
import {
  ApiCompanyRevenue,
  ApiRevenue,
} from '@shared/interfaces/api';
import { QueryKeys } from '../../../constants';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import { StringUtility } from '../../../utilities';
import { StatisticsApi } from '../../../utilities/api/StatisticsApi';
import { CropYearFilter } from '../../components/shared/Input/CropYearFilter';
import { RevenueByProductCategoryDonut } from './Charts/RevenueByProductCategoryDonut';
import { SalesFunnelChart } from './Charts/SalesFunnelChart';
import StatusStackedBarChart from './Charts/StatusStackedBarChart';

type DashboardResponse = ApiCompanyRevenue & ApiRevenue;

export const DashboardPage = () => {
  const { user, currentBusinessId } = useAuthentication();
  const [cropYears, setCropYears] = useState<number[]>([]);
  const { createToast } = useToast();
  const [translate] = useTranslation(['dashboard']);

  const isAdmin = RoleUtility.roleHasPermission(
    user?.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const { data, dataUpdatedAt, isLoading } = useQuery<DashboardResponse>(
    [QueryKeys.COMPANY_REVENUE, currentBusinessId, user.businessId, cropYears],
    async () => {
      const companyRevenue = await StatisticsApi.getCompanyRevenue(
        currentBusinessId ?? user.businessId, cropYears,
      );
      const revenueByStatus = isAdmin ? await StatisticsApi.getOrderRevenue(
        currentBusinessId ?? user.businessId, cropYears,
      ) : {
        byLocation: [],
        bySalesperson: [],
      };

      for (let i = 0; i < revenueByStatus.bySalesperson.length; i += 1) {
        (revenueByStatus.bySalesperson[i] as any).salespersonIndex = i;
      }

      return { ...companyRevenue, ...revenueByStatus };
    },
    {
      enabled: !!currentBusinessId,
      onError: () => {
        createToast({
          children: translate<string>('ERROR_LOADING_SALES_FUNNEL'),
          status: 'danger',
          testID: 'toast-content-element',
        });
      },
    },
  );

  return (
    <ScrollView>
      <Text>
        {translate<string>('LAST_UPDATED', {
          date: DateTime.fromSeconds(dataUpdatedAt / 1000).toFormat('f ZZZZ'),
        })}
      </Text>
      <>
        <VSpacer size="8" />
        <CropYearFilter onChange={(selectedCropYears) => setCropYears(selectedCropYears)} />
      </>
      { isLoading
        ? <CenteredSpinner size="giant" /> : (
          <>
            <VSpacer size="8" />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
              }}
            >
              {!!data && (
                <>
                  <SalesFunnelChart {...data.byStatus} />
                  <RevenueByProductCategoryDonut {...data.byProductCategory} />

                  {isAdmin && (
                    <>
                      <StatusStackedBarChart
                        data={data.byLocation}
                        title={translate('SALES_FUNNEL_REVENUE_BY_LOCATION')}
                        tooltipLabelFormatter={(props) => (
                          props.label ? props.label : translate('CORPORATE_LOCATION')
                        )}
                        xDataKey="locationName"
                        xTickFormatter={(label) => (
                          label === '' ? translate('CORPORATE_LOCATION') : StringUtility.truncate(label)
                        )}
                      />
                      <StatusStackedBarChart
                        data={data.bySalesperson}
                        title={translate('SALES_FUNNEL_REVENUE_BY_SALESPERSON')}
                        tooltipLabelFormatter={(props) => (
                          `${data.bySalesperson[props.label].salesperson}`
                        )}
                        xDataKey="salespersonIndex"
                        xTick={({ x, y, payload, ...rest }) => (
                          <g transform={`translate(${x},${y})`}>
                            <text dy="0.71em" x={0} y={0} {...rest}>
                              <tspan textAnchor="middle" x="0">
                                {!Number.isNaN(parseInt(payload.value, 10))
                                  ? StringUtility.truncate(
                                    data.bySalesperson?.[payload.value].salesperson,
                                  )
                                  : ''}
                              </tspan>
                              <tspan dy="1em" textAnchor="middle" x="0">
                                {!Number.isNaN(parseInt(payload.value, 10))
                                && data.bySalesperson?.[payload.value].admin
                                  ? `(${translate('ADMIN')})`
                                  : ''}
                              </tspan>
                            </text>
                          </g>
                        )}
                        xTickHeight={40}
                      />
                    </>
                  )}
                </>
              )}
            </View>
          </>
        )}
    </ScrollView>
  );
};
