import React, { FC } from 'react';
import { MenuItem as UIKMenuItem, MenuItemProps as UIKMenuItemProps } from '@ui-kitten/components';
import { MenuItemDescriptor } from '@ui-kitten/components/ui/menu/menu.service';
import { GestureResponderEvent } from 'react-native';

interface MenuItemProps extends UIKMenuItemProps {

}

export const MenuItem: FC<MenuItemProps> = (props) => (
  <UIKMenuItem
    {...props}
    onPress={
    (descriptor: MenuItemDescriptor, event?: GestureResponderEvent) => {
      setTimeout(() => { props.onPress?.(descriptor, event); }, 0);
    }
  }
  />
);
