import {
  Button,
  ButtonProps,
  FilterCategory,
  FilterTable,
  HSpacer,
  Icon,
  IconButton,
  Input,
  Modal,
  VSpacer,
} from '@design';
import { useTranslation } from 'react-i18next';
import { ApiDiscount, DiscountEndpoint } from '@shared/interfaces/api';
import { useEffect, useMemo, useState } from 'react';
import { IColumn, RowMeta } from '../../../components/SortableTable';
import { StringUtility } from '../../../../utilities';
import { DiscountType } from '@shared/enums';
import { ConfirmationModal } from '../../../components/shared/ConfirmationModal/ConfirmationModal';
import { conflictErrorCode, useBusinessDiscounts } from '../../../../hooks/useBusinessDiscounts';
import { DiscountInputSelect } from '../../../components/shared/Input/DiscountInputSelect';

interface DiscountsTableProps {
  businessId: string,
  canAddDiscount: boolean,
  onPageChange: () => void,
}

const defaultPageOptions = {
  limit: 10,
  page: 0,
};

const defaultColumnFilters = ['discountType'];

const defaultDiscount: Partial<ApiDiscount> = {
  name: '',
  discountType: DiscountType.DOLLARS,
};

export const DiscountsTable = ({
  businessId,
  canAddDiscount,
  onPageChange,
}: DiscountsTableProps) => {
  const [translate] = useTranslation(['common', 'businesses']);
  const [discounts, setDiscounts] = useState<(ApiDiscount & RowMeta)[]>();
  const [pageOptions, setPageOptions] = useState<DiscountEndpoint.List.Query>(defaultPageOptions);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [discountId, setDiscountId] = useState<string>();
  const [discount, setDiscount] = useState<Partial<ApiDiscount>>(defaultDiscount);
  const [showDeleteDiscountModal, setShowDeleteDiscountModal] = useState(false);
  const [showCreateDiscount, setShowCreateDiscount] = useState(false);
  const [caption, setCaption] = useState('');

  const filterOptions: FilterCategory[] = useMemo(() => ([
    {
      columnLabel: translate('TYPE'),
      columnKey: 'discountType',
      columns: [{
        id: DiscountType.DOLLARS,
        label: translate('DOLLAR_FILTER'),
      }, {
        id: DiscountType.PERCENT,
        label: translate('PERCENT_FILTER'),
      }],
    },
  ]), [translate]);
  const [columnCategories, setColumnCategories] = useState<FilterCategory[]>(filterOptions);

  const {
    businessDiscountsList,
    createBusinessDiscount,
    deleteBusinessDiscount,
    isBusinessDiscountsLoading,
  } = useBusinessDiscounts(pageOptions);

  const {
    businessDiscountsListData,
    isBusinessDiscountsDataLoading,
    hasDiscountsError,
  } = businessDiscountsList;

  useEffect(() => {
    if (!isBusinessDiscountsDataLoading) {
      const filteredDiscountData = businessDiscountsListData.data.map((disc) => ({
        ...disc,
        rowId: disc.id,
      }));
      setDiscounts(filteredDiscountData);
      setTotalPages(businessDiscountsListData.lastPage + 1);
      setTotalResults(businessDiscountsListData.total);
    }
  
    if (hasDiscountsError) {
      setPageOptions(defaultPageOptions);
      setColumnCategories(filterOptions);
    }
  }, [filterOptions, hasDiscountsError, isBusinessDiscountsDataLoading, businessDiscountsListData]);

  const {
    isError: isDiscountDeleteError,
    isSuccess: isDiscountDeleteSuccess,
    mutate: deleteDiscountMutation,
  } = deleteBusinessDiscount;

  useEffect(() => {
    if (isDiscountDeleteSuccess) {
      setDiscountId(null);
    }
    if (isDiscountDeleteError) {
      setPageOptions(defaultPageOptions);
      setColumnCategories(filterOptions);
    }
  }, [filterOptions, isDiscountDeleteError, isDiscountDeleteSuccess]);

  const {
    error: discountCreateError,
    isError: isDiscountCreateError,
    isSuccess: isDiscountCreateSuccess,
    mutate: createDiscount,
  } = createBusinessDiscount;

  useEffect(() => {
    if (isDiscountCreateSuccess) {
      setCaption('');
      setDiscount(defaultDiscount);
      setShowCreateDiscount(false);
    }
    if (isDiscountCreateError && discountCreateError.code === conflictErrorCode) {
      setCaption(discountCreateError.message);
    }
  }, [
    discountCreateError,
    isDiscountCreateError,
    isDiscountCreateSuccess,
  ]);  

  const columns: IColumn<(ApiDiscount & RowMeta)>[] = [
    {
      columnId: 'name',
      header: {
        render: translate('NAME'),
        sortable: true,
      },
      render: (data) => data.name,
      flex: 2,
    },
    {
      columnId: 'amount',
      header: {
        render: translate('AMOUNT'),
      },
      render: (data) => StringUtility.formatDiscountAmount(data),
      flex: 0.6,
      align: 'center',
    }];

  if (canAddDiscount) {
    columns.push(
      {
        columnId: 'delete',
        header: {
          render: '',
        },
        render: (data) => (
          <IconButton
            appearance="ghost"
            onPress={() => {
              setDiscountId(data.id);
              setShowDeleteDiscountModal(true);
            }}
            status="basic"
            testID="delete-discount-button"
          >
            Trash
          </IconButton>
        ),
        flex: 0.2,
      },
    );
  }

  const validDiscount = () => {
    if (!discount.name.trim()) {
      return false;
    }
    if (!discount.discountType) {
      return false;
    }
    if (discount.discountType === DiscountType.DOLLARS) {
      if (!discount.dollars) {
        return false;
      }
    } else if (discount.discountType === DiscountType.PERCENT) {
      if (!discount.percent) {
        return false;
      }
    }
    return true;
  };

  const CreateDiscountButton = (props: Partial<ButtonProps>) => {
    if (canAddDiscount) {
      return (
        <Button
          accessoryLeft={(iconProps) => (
            <Icon name="Plus" testID="create-discount-button-icon" {...iconProps} />
          )}
          onPress={() => {
            setShowCreateDiscount(true);
          }}
          size="medium"
          testID="create-discount-button"
          {...props}
        >
          {translate('CREATE_DISCOUNT')}
        </Button>
      );
    }
    return <></>;
  };

  const DiscountTable = (
    <FilterTable
      accessoryRight={CreateDiscountButton}
      columns={columns}
      currentPage={pageOptions.page + 1}
      data={discounts}
      defaultColumnFilters={defaultColumnFilters}
      filterOptions={columnCategories}
      isLoading={isBusinessDiscountsLoading}
      key={businessId}
      noDataMessage={translate('NO_DISCOUNTS')}
      noFilters={false}
      onPageChange={(page: number) => {
        setPageOptions((options) => ({ ...options, page: page - 1 }));
        onPageChange();
      }}
      onSort={(column, direction) => {
        setPageOptions({
          ...pageOptions,
          page: 0,
          sort: column,
          sortDesc: direction === 'DESC',
        });
      }}
      onUpdateFilter={(filter) => {
        setPageOptions({
          ...defaultPageOptions,
          ...filter,
        });
      }}
      testID="discounts-table"
      totalPages={totalPages}
      totalResults={totalResults}
    />
  );

  const DeleteDialog = (
    <ConfirmationModal
      cancelText={translate('CANCEL')}
      confirmText={translate<string>('YES_DELETE')}
      messageText={translate<string>('DELETE_DISCOUNT_MESSAGE')}
      onCancel={() => {
        setDiscountId(null);
        setShowDeleteDiscountModal(false);
      }}
      onConfirm={() => {
        setShowDeleteDiscountModal(false);
        deleteDiscountMutation(discountId);
      }}
      status="warning"
      testID="delete-discount-modal"
      title={translate('DELETE_DISCOUNT_TITLE')}
      visible={showDeleteDiscountModal}
    />
  );

  const CreateModal = (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            onPress={() => {
              setShowCreateDiscount(false);
              setDiscount(defaultDiscount);
              setCaption('');
            }}
            testID="discount-create-edit-cancel-button"
            {...primaryButtonProp}
          >
            {translate('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            disabled={!validDiscount()}
            onPress={(e) => {
              e.preventDefault();
              createDiscount(discount as DiscountEndpoint.Save.Request);
            }}
            testID="discount-create-edit-create-button"
            {...secondaryButtonProp}
          >
            {translate(!!discount.id ? 'SAVE_CHANGES' : 'CREATE_DISCOUNT')}
          </Button>
        </>
      )}
      hideCloseButton
      onClose={() => setShowCreateDiscount(false)}
      testID="create-edit-discount-modal"
      title={translate(!!discount.id ? 'EDIT_DISCOUNT' : 'CREATE_DISCOUNT')}
      visible={showCreateDiscount}
    >
      <Input
        caption={caption}
        isRequired
        label={translate('DISCOUNT_NAME')}
        onChangeText={(name) => {
          setDiscount({ ...discount, name });
          setCaption('');
        }}
        status={caption.length ? 'danger' : 'basic'}
        testID="name-input"
        value={discount.name ?? ''}
      />
      <VSpacer size="8" />
      <DiscountInputSelect
        discount={discount}
        setDiscount={setDiscount}
        testID="business-discounts-table"
      />
    </Modal>
  );

  return (
    <>
      {DiscountTable}
      {DeleteDialog}
      {CreateModal}
    </>
  );
};