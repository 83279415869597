import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Button, Icon, IconButton, PageHeader } from '@design';

export const PageHeaderDemo: FunctionComponent = () => (
  <View>
    <PageHeader testID="demo-page-text-only">Page Title</PageHeader>
    <PageHeader
      accessoryRight={({ buttonProps }) => (
        <Button
          accessoryLeft={(iconProps) => <Icon name="Plus" testID="test-button-icon" {...iconProps} />}
          {...buttonProps}
          testID="test-button"
        >
          Button
        </Button>
      )}
      testID="demo-page-button-text"
    >
      Page Title
    </PageHeader>
    <PageHeader
      accessoryRight={({ buttonProps, iconButtonProps }) => (
        <>
          <IconButton appearance="ghost" {...iconButtonProps} testID="test-button">Square</IconButton>
          <IconButton appearance="ghost" {...iconButtonProps} testID="test-button">Square</IconButton>
          <IconButton appearance="ghost" {...iconButtonProps} testID="test-button">Square</IconButton>
          <Button
            accessoryLeft={(iconProps) => <Icon name="Plus" testID="test-button-icon" {...iconProps} />}
            {...buttonProps}
            testID="test-button"
          >
            Button
          </Button>
        </>
      )}
      testID="demo-page-icon-button-text"
    >
      Page Title
    </PageHeader>
  </View>
);
