import { Button, Modal, HSpacer, Input, useToast, VSpacer } from '@design';
import { useAuthentication } from '../../../contexts/dataSync/AuthenticationContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { TankMixApi } from '../../../utilities/api';
import { Alert } from '../../../ui-components';

export interface SaveAsNewProductMixDialogProps {
  name: string,
  onCancel: () => void,
  onSave: (productMixName: string) => void,
  visible: boolean,
}
export function SaveAsNewProductMixDialog ({
  name: initialName,
  onCancel,
  onSave,
  visible,
}: SaveAsNewProductMixDialogProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const [name, setName] = useState(`${translate('CUSTOM')} ${initialName} - ${new Date().toLocaleString()}`);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);

  const { createToast } = useToast();
  const { currentBusinessId } = useAuthentication();

  const validateProductMixName = async () => {
    try {
      const { data } = await TankMixApi.listTankMix({
        businessId: currentBusinessId,
        name: name.trim(),
      });

      const isValid = data.length === 0;
      setIsDuplicateName(!isValid);
      setShowDuplicateMessage(!isValid);
    } catch {
      createToast({
        children: translate<string>('UNEXPECTED_ERROR'),
        status: 'danger',
        testID: 'toast-content-element',
      });
    }
  };

  return (
    <Modal
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...primaryButtonProp}
            appearance="outline"
            onPress={() => onCancel()}
            status="basic"
            testID="save-as-new-product-mix-dialog-cancel"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            disabled={!name || isDuplicateName}
            onPress={() => onSave(name)}
            testID="save-as-new-product-mix-dialog-save"
          >
            {translate<string>('SAVE')}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="edit-acreage-dialog"
      title={translate('SAVE_AS_NEW_PRODUCT_MIX')}
      visible={visible}
    >
      {showDuplicateMessage && (
        <View testID="save-as-new-product-mix-dialog-duplicate-error">
          <Alert level="danger" text={translate('DUPLICATE_TANK_MIX_NAME')} />
          <VSpacer size="7" />
        </View>
      )}
      <Input
        isRequired
        label={`${translate<string>('PRODUCT_MIX_NAME')}`}
        onBlur={validateProductMixName}
        onChangeText={(updatedName) => {
          setShowDuplicateMessage(false);
          setIsDuplicateName(true);
          setName(updatedName);
        }}
        testID="product-mix-name"
        value={name}
      />
    </Modal>
  );
}
