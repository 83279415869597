import { Card, IndexPath } from '@ui-kitten/components';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { View } from 'react-native';
import { Icon, Select, SelectItem, Input, IconNames } from '@design';
import { Sizes } from '@theme/variant-interfaces/Size';
import { Statuses } from '@theme/variant-interfaces/Status';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

export const IconDemo: FunctionComponent = () => {
  const [search, setSearch] = useState('');
  const [filteredIcons, setFilteredIcons] = useState(IconNames);

  const [size, setSize] = useState<IndexPath>(new IndexPath(4));
  const [status, setStatus] = useState<IndexPath>(new IndexPath(0));
  const [fill, setFill] = useState('');

  const onSearch = useCallback((searchText: string) => {
    setSearch(searchText);
    if (searchText) {
      const searchTerms = searchText.split(' ').filter((t) => !!t);
      const searchRegex = new RegExp(`(${searchTerms.join('|')})`, 'i');
      setFilteredIcons(IconNames.filter((name) => searchRegex.test(name as string)));
    } else {
      setFilteredIcons(IconNames);
    }
  }, []);

  const renderDemoProps: any = {
    size: Sizes[size.row],
    status: Statuses[status.row],
  };
  if (fill) {
    // this is weird because if you provide a fill, even if undefined, it overrides the tint color
    renderDemoProps.fill = fill;
  }

  return (
    <View style={{ alignSelf: 'stretch' }}>
      <Card testID="test-card">
        <DemoContainer label="size">
          <DemoBlock label="Default">
            <Icon name="Star" testID="test-icon" />
          </DemoBlock>
          {Sizes.map((s) => (
            <DemoBlock key={s} label={s}>
              <Icon name="Star" size={s} testID="test-icon" />
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card style={{ flex: 1 }} testID="test-card">
        <DemoContainer label="status">
          <DemoBlock label="Default">
            <Icon name="Star" testID="test-icon" />
          </DemoBlock>
          {Statuses.map((s) => (
            <DemoBlock key={s} label={s}>
              <Icon name="Star" status={s} testID="test-icon" />
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
      <Card testID="test-card">
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
          <Input
            label="Search"
            onChangeText={onSearch}
            testID="demo-input"
            value={search}
          />
          <Select
            label="Size"
            onSelect={(index) => setSize(index as IndexPath)}
            selectedIndex={size}
            testID="icon-demo-size-selector"
            value={Sizes[size.row]}
          >
            {Sizes.map((s, i) => (
              <SelectItem key={s} testID="icon-demo-size" title={i} />
            ))}
          </Select>
          <Select
            label="Status"
            onSelect={(index) => setStatus(index as IndexPath)}
            selectedIndex={status}
            testID="icon-demo-status-selector"
            value={Statuses[status.row]}
          >
            {Statuses.map((s, i) => (
              <SelectItem key={s} testID={`icon-demo-status-dropdown-value-${i}`} title={s} />
            ))}
          </Select>
          <Input
            label="Fill"
            onChangeText={setFill}
            testID="demo-input"
            value={fill}
          />
        </View>
        <DemoContainer label="name">
          {filteredIcons.map((icon) => (
            <DemoBlock
              key={icon}
              label={icon}
            >
              <Icon
                name={icon}
                testID="test-icon"
                {...renderDemoProps}
              />
            </DemoBlock>
          ))}
        </DemoContainer>
      </Card>
    </View>
  );
};
