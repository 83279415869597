export default {
  'FARM PLAN': 'Farm Plan',
  'TANK MIX': 'Tank Mix',
  ACRES_APPLIED: 'Acres Applied',
  ACRES_PLANNED: 'Acres Planned',
  ADD_CROPS: 'Add Crops',
  APPLICATION_PER_ACRE: '{{ applicationRate }}/ac',
  APPLIED: 'Applied',
  APPLY: 'Apply',
  APPLY_TO_ALL: 'Apply to all',
  APPLY_TO_SELECTED: 'Apply to selected',
  AVG_RATE_APPLIED: 'Avg rate applied',
  BUSINESS_ID_MISMATCH: 'Programs belong to a business that does not include the specified Growers',
  COMPONENT: 'Component',
  COMPONENTS: 'Components',
  CONFIRM_REMOVING_PRODUCTS_FROM_PASSES: 'Are you sure you want to change {{cropType}} and remove all {{cropType}} specific components from this Program?\nThis will remove any applied Product Mixes and Seed specific products.',
  COPY_OF_PROGRAM_NAME: 'Copy of {{ programName }}',
  COST: 'Cost',
  COST_PER_ACRE: 'Cost (per acre)',
  COST_AC: 'Cost/ac',
  COST_SUMMARY: 'Cost Summary',
  CREATE_FARM_PLAN: 'Create Farm Plan',
  CREATE_PRODUCT_MIX: 'Create Product Mix',
  CREATE_PROGRAM: 'Create Program',
  CREATE_QUICK_QUOTE: 'Create Quick Quote',
  CROPS: 'Crops',
  CROP_MARKET_PRICE: 'Crop Market Price',
  CROP_ZONE: 'Crop Zone',
  DISCOUNT: 'Discount',
  DISCOUNTED_PRICE: 'Discounted price',
  EDIT: 'Edit',
  ERROR_BUSINESSES: 'There was an error loading businesses',
  ERROR_BUSINESS_CROPS: 'There was an error loading business crops',
  ERROR_BUSINESS_LOCATIONS: 'There was an error loading business locations',
  ERROR_BUSINESS_USERS: 'There was an error loading business users',
  ERROR_CREATING_PROGRAM: 'There was an error while creating program!',
  ERROR_DELETE_COMPONENT: 'There was an error removing the component.',
  ERROR_FARMS: 'There was an error loading farms',
  ERROR_FARM_PLAN: 'There was an error loading farm plan',
  ERROR_PROGRAM: 'There was an error loading program',
  ERROR_PRODUCT_MIX: 'There was an error loading Product Mix',
  EXPECTED_CROP_MARKET_PRICE: 'Expected Crop Market Price (per unit)',
  EXPECTED_OPERATIONAL_COST: 'Expected Operational Cost (per acre)',
  FARM_PLAN: 'Farm Plan',
  FARM_PLAN_ORDER: 'Farm Plan Order',
  FARM_PLAN_OVERVIEW_BILLING_SHIPPING_CONTACTS: 'Billing/Shipping Contacts',
  FIELD: 'Field',
  FIELDS: 'Fields',
  FIELDS_COUNT: '({{fields}} Fields)',
  FIELD_COUNT: '({{field}} Field)',
  FIELD_DETAILS: 'Field Details',
  FIELD_SUMMARY: 'Field Summary',
  LOCK_PRICE: 'Lock price',
  MIXED_GROWERS: 'Not all Growers belong to the same business',
  MIXED_PROGRAMS: 'Not all Programs belong to the same business',
  NO_AVAILABLE_GROWERS: 'No available growers',
  NO_COMPONENTS: 'No Components',
  NO_CROPS_WARNING: 'Your business has not configured any crops.',
  NO_DELIVERABLES: 'No Deliverables',
  NO_FARM_PLANS: 'No Farm Plans',
  NO_GROWER_DELIVERABLES: 'No Grower Deliverables',
  NO_ITEMS: 'No items',
  NO_PRODUCT_ORDERS: 'No Quick Quotes',
  NO_PROGRAMS: 'No Programs',
  NUMBER_OF_PASSES: 'Number of Passes',
  OPERATIONAL_COST_PER_ACRE: 'Operational cost/ac',
  ORDER_DETAILS: 'Order Details',
  ORDER_DETAIL_SENTENCE: 'Order details',
  OWNER: 'Owner',
  PASS: 'Pass',
  PASSES: 'Passes',
  PER: 'per/{{UoM}}',
  PERCENT_OFF: '{{percent}}% OFF',
  PLAN_STATUS: 'Plan Status',
  PREPARE_AND_REVIEW: 'Prepare & Review',
  PRICE_TYPE: 'Price Type',
  PRODUCTS_AND_APPLICATIONS: 'Products & Applications',
  PRODUCTS_COUNT: '{{count}} Products',
  PRODUCT_APPLICATIONS: 'Product Applications',
  PRODUCT_COUNT: '1 Product',
  PRODUCT_MIX: 'Product Mix',
  PRODUCT_MIX_ACTIVATE_MODAL_WARNING: 'Are you sure you want to activate this Product Mix?',
  PRODUCT_MIX_ALREADY_EXISTS: 'Product Mix {{name}} already exists!',
  PRODUCT_MIX_CREATED: 'Product Mix created.',
  PRODUCT_MIX_DEACTIVATE_MODAL_WARNING: 'Are you sure you want to deactivate this Product Mix?',
  PRODUCT_MIX_UPDATED: 'Product Mix updated.',
  PRODUCT_SKU: 'Product SKU',
  PRODUCT_SKU_PACKAGES_CONTRACTED: 'Product SKU Packages Contracted',
  PROFIT: 'Profit',
  PROFIT_SUMMARY: 'Profit Summary',
  PROGRAM_ALREADY_EXISTS: 'Program {{name}} already exists',
  PROGRAM_DETAILS: 'Program Details',
  PROGRAM_FOR_FARM_PLAN: 'Program for Farm Plan: {{name}}',
  PROGRAM_NAME: 'Program Name',
  RATE: 'Rate',
  RATE_PER_ACRE: 'Rate/ac',
  RENAME_PROGRAM: 'Rename your Program before selecting \'Apply to selected\'.',
  RETAIL_UNIT_PRICE: 'Retail Unit Price',
  REVENUE: 'Revenue',
  SCENARIO_PARAMETERS: 'Scenario Parameters',
  SHOW_ALL: 'Show all',
  SHOW_DETAILS: 'Show details',
  SHOW_MORE: 'Show more',
  SKU_PACKAGES_CONTRACTED: 'SKU Packages Contracted',
  SKU_PACKAGES_REQUIRED: 'SKU packages required',
  SUBTOTAL: 'Subtotal',
  TAB_TITLE_FARM_PLAN: 'Farm Plan',
  TAB_TITLE_GROWER_DELIVERABLE: 'Deliverable',
  TAB_TITLE_PRODUCT_MIX: 'Product Mix',
  TAB_TITLE_PROGRAM: 'Program',
  TAB_TITLE_QUICK_QUOTE: 'Quick Quote',
  TANK_MIX: 'Product Mix',
  TOTAL: 'Total',
  TOTAL_ACRES_PLANNED: 'Total Acres Planned',
  TOTAL_COST_PER_ACRE: 'Total Cost/ac',
  UNITS_REQUIRED: 'Units Required',
  UNITS_REQUIRED_SENTENCE: 'Units required',
  UNIT_PRICE: 'Unit Price',
  UNIT_UNITS: 'Unit(s)',
  USE_SCENARIO_PARAMETERS: 'Use scenario parameters',
  VIEW_ALL_PRODUCTS: 'View all products',
  VIEW_FIELD_DETAILS: 'View Field Details',
  YIELD_GOAL_PER_ACRE: 'Yield Goal (per acre)',
};
