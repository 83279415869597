import {
  StyleSheet,
  View,
  ViewProps,
} from 'react-native';
import React, { Component, ReactNode } from 'react';
import {
  ToastResolver,
  ToastResolverProps,
} from './ToastResolver';
import {
  ToastPresenting,
  ToastPresentingConfig,
  ToastService,
} from './ToastService';

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    position: 'absolute',
    bottom: 112,
    right: 0,
    alignItems: 'flex-end',
    zIndex: 10000,
  },
});

interface ToastPanelChild extends ToastPresentingConfig {
  element: React.ReactElement;
}

export interface ToastPanelProps {
  children?: ReactNode,
}

interface ToastPanelState {
  components: Map<string, ToastPanelChild>;
}

export class ToastPanel extends Component<ToastPanelProps, ToastPanelState>
  implements ToastPresenting {
  // eslint-disable-next-line react/state-in-constructor
  public state: ToastPanelState = {
    components: new Map(),
  };

  public componentDidMount (): void {
    ToastService.mount(this);
  }

  public componentWillUnmount (): void {
    ToastService.unmount();
  }

  private areThereAnyComponents = (): boolean => {
    return this.state.components && this.state.components.size !== 0;
  };

  private generateUniqueComponentKey = (): string => {
    return Math.random().toString(36).substring(2);
  };

  public hide = (identifier: string): string => {
    const { components } = this.state;
    components.delete(identifier);
    this.setState({ components });
    return '';
  };

  public show (element: React.ReactElement, config: ToastPresentingConfig): string {
    const key: string = this.generateUniqueComponentKey();

    this.setState((prevState) => ({
      components: prevState.components.set(key, { ...config, element }),
    }));

    return key;
  }

  public update (identifier: string, children: React.ReactNode): void {
    const panelChild: ToastPanelChild = this.state.components.get(identifier);

    if (!panelChild) {
      return;
    }

    const childElement: React.ReactElement = panelChild.element;
    panelChild.element = React.cloneElement(childElement, childElement.props, children);

    const { components } = this.state;
    components.set(identifier, panelChild);
    this.forceUpdate();
  }

  private renderToast = (id: string): React.ReactElement<ToastResolverProps> => {
    return (
      <ToastResolver
        key={id}
      >
        {this.state.components.get(id).element}
      </ToastResolver>
    );
  };

  private renderToasts = (): React.ReactElement<ToastResolverProps>[] => {
    return Array.from(this.state.components.keys()).reverse().map(this.renderToast);
  };

  public render (): React.ReactElement<ViewProps> {
    return (
      <View nativeID="toast-panel" pointerEvents="box-none" style={styles.container}>
        {this.props.children}
        {this.areThereAnyComponents() && this.renderToasts()}
      </View>
    );
  }
}
