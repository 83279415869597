import {
  Badge,
  Button,
  Header,
  HSpacer,
  Icon,
  IconButton,
  Text,
  TextLink,
  Toggle,
} from '@design';
import { useStyleSheet } from '@ui-kitten/components';
import React, { useState } from 'react';
import { View } from 'react-native';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const OVERLINE = 'Overline';
const TITLE = 'Heading';
const SUB_TITLE = 'SubTitle';

export function HeaderDemo () {
  const styles = useStyleSheet({
    demoContainer: {
      flex: 1,
      flexDirection: 'column',
    },
    demoBlock: {
      flex: 1,
      borderWidth: 1,
      borderColor: 'color-basic-transparent-100',
    },
    playground: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
  });

  const [toggle, setToggle] = useState(true);

  return (
    <View>
      <DemoContainer label="Level 1" style={styles.demoContainer}>
        <DemoBlock style={styles.demoBlock}>
          <Header
            level="1"
            overline={OVERLINE}
            subTitle={SUB_TITLE}
            testID="level-1-header-1"
            title={TITLE}
          />
        </DemoBlock>
        <DemoBlock style={styles.demoBlock}>
          <Header
            level="1"
            overline={OVERLINE}
            subTitle={SUB_TITLE}
            testID="level-1-header-2"
            title={TITLE}
          >
            <Badge status="info" testID="label">
              <Text category="overline">label</Text>
            </Badge>
            <HSpacer size="7" />
            <Toggle checked={toggle} onChange={() => setToggle(!toggle)}>
              active
            </Toggle>
            <HSpacer size="7" />
            <IconButton
              appearance="ghost"
              size="large"
              status="basic"
              testID="test-button"
            >
              PaperPlane
            </IconButton>
            <HSpacer size="3" />
            <IconButton
              appearance="ghost"
              size="large"
              status="basic"
              testID="test-button"
            >
              Phone
            </IconButton>
            <HSpacer size="3" />
            <IconButton
              appearance="ghost"
              size="large"
              status="basic"
              testID="test-button"
            >
              Email
            </IconButton>
            <HSpacer size="3" />
            <Button
              accessoryLeft={(BtnProps) => <Icon name="Plus" testID="test-button-icon" {...BtnProps} />}
              size="large"
              testID="test-button"
            >
              Button
            </Button>
          </Header>
        </DemoBlock>
      </DemoContainer>

      <DemoContainer label="Level 2" style={styles.demoContainer}>
        <DemoBlock style={styles.demoBlock}>
          <Header level="2" subTitle={SUB_TITLE} testID="level-2-header-1" title={TITLE} />
        </DemoBlock>
        <DemoBlock style={styles.demoBlock}>
          <Header
            level="2"
            subTitle={SUB_TITLE}
            testID="level-2-header-2"
            title={TITLE}
          >
            <IconButton
              appearance="ghost"
              size="medium"
              status="basic"
              testID="test-button"
            >
              PaperPlane
            </IconButton>
            <HSpacer size="3" />
            <IconButton
              appearance="ghost"
              size="medium"
              status="basic"
              testID="test-button"
            >
              Phone
            </IconButton>
            <HSpacer size="5" />
            <Button
              appearance="outline"
              size="medium"
              testID="test-button"
            >
              Button
            </Button>
          </Header>
        </DemoBlock>
      </DemoContainer>

      <DemoContainer label="Level 3" style={styles.demoContainer}>
        <DemoBlock style={styles.demoBlock}>
          <Header level="3" testID="level-3-header-1" title={TITLE} />
        </DemoBlock>
        <DemoBlock style={styles.demoBlock}>
          <Header
            level="3"
            testID="level-3-header-2"
            title={TITLE}
          >
            <TextLink
              appearance="secondary"
              category="p2"
            >
              Link
            </TextLink>
            <HSpacer size="5" />
            <Button
              appearance="outline"
              size="small"
              testID="test-button"
            >
              Button
            </Button>
          </Header>
        </DemoBlock>
      </DemoContainer>

      <DemoContainer label="Level 4" style={styles.demoContainer}>
        <DemoBlock style={styles.demoBlock}>
          <Header level="4" testID="level-4-header-1" title={TITLE} />
        </DemoBlock>
        <DemoBlock style={styles.demoBlock}>
          <Header
            level="4"
            testID="level-4-header-2"
            title={TITLE}
          >
            <TextLink
              appearance="secondary"
              category="p2"
            >
              Link
            </TextLink>
            <HSpacer size="3" />
            <IconButton
              appearance="ghost"
              size="medium"
              status="basic"
              testID="test-button"
            >
              PaperPlane
            </IconButton>
            <HSpacer size="3" />
            <IconButton
              appearance="ghost"
              size="medium"
              status="basic"
              testID="test-button"
            >
              Phone
            </IconButton>
            <HSpacer size="3" />
            <Button
              appearance="outline"
              size="small"
              status="basic"
              testID="test-button"
            >
              Button
            </Button>
          </Header>
        </DemoBlock>
      </DemoContainer>
    </View>
  );
}
