import { Button } from '@design/Button/Button';
import { BannerStatus } from '@theme/variant-interfaces/Status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Banner, BannerElement, BannerProps } from '../../Banner/Banner';

export interface BannerManagerPresenting {
  add(element: BannerElement, id: string): void;
  remove(): void;
}

/**
 * Banner manager handles creating Banner components and mounting them to the dom so that
 * Banner can be created functionally vs compositionally
 */
class BannerManagerType {
  panel: BannerManagerPresenting | null = null;

  public mount (panel: BannerManagerPresenting | null): void {
    this.panel = panel;
  }

  public unmount (): void {
    this.panel = null;
  }

  private generateUniqueComponentKey = (): string => {
    return Math.random().toString(36).substring(2);
  };

  public create (props: BannerProps): string {
    if (this.panel) {
      const key: string = this.generateUniqueComponentKey();

      this.panel.add((
        <Banner
          {...props}
          onClose={() => this.panel.remove()}
        />
      ), key);

      return key;
    }
    return '';
  }

  public destroy () {
    this.panel.remove();
  }
}

export const BannerManager = new BannerManagerType();

// bind here to get references correct and avoid another function that has
// the same parameters as BannerManager methods.
export const useBanner = () => {
  const [translate] = useTranslation(['common']);

  return {
    showBanner: (bannerText: string, status?: BannerStatus) => BannerManager.create({
      children: bannerText,
      status: status ?? 'danger',
      testID: 'banner',
      actionAccessory: ({ dismissProps }) => (
        <View style={{ justifyContent: 'flex-end', flexDirection: 'row', flex: 1, padding: 0 }}>
          <Button {...dismissProps} appearance="ghost" size="small" status="basic" testID="dismiss-button">
            {translate<string>('DISMISS')}
          </Button>
        </View>
      ),
    }),
    createBanner: (props: BannerProps | string) => {
      let bannerProps: BannerProps;

      if (typeof props === 'string') {
        bannerProps = {
          children: props,
          testID: 'banner',
        };
      } else {
        bannerProps = props;
      }
      return BannerManager.create(bannerProps);
    },
    destroyBanner: () => BannerManager.destroy(),
  };
};
