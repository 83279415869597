import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Calculate = (props: SvgProps) => (
  <Svg viewBox="0 0 16 16" {...props}>
    <Path clipRule="evenodd" d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 12.6667H3.33333V3.33333H12.6667V12.6667Z" fill="#EDF354" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M7.5 5.14667H4.16667V6.14667H7.5V5.14667Z" fill="#EDF354" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M12 10.5H8.66667V11.5H12V10.5Z" fill="#EDF354" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M12 8.83333H8.66667V9.83333H12V8.83333Z" fill="#EDF354" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M5.33333 12H6.33333V10.6667H7.66667V9.66667H6.33333V8.33333H5.33333V9.66667H4V10.6667H5.33333V12Z" fill="#EDF354" fillRule="evenodd" />
    <Path clipRule="evenodd" d="M9.39333 7.3L10.3333 6.36L11.2733 7.3L11.98 6.59333L11.04 5.64667L11.98 4.70667L11.2733 4L10.3333 4.94L9.39333 4L8.68667 4.70667L9.62667 5.64667L8.68667 6.59333L9.39333 7.3Z" fill="#EDF354" fillRule="evenodd" />
  </Svg>
);
