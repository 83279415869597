import { HSpacer, NumericInput, Text } from '@design';
import {
  ApiCrop,
  ApiGrowerFarm,
  ApiGrowerField,
  ApiPlanningParameter,
} from '@shared/interfaces/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { IColumn, RowMeta, SortableTable } from '../../../../components/SortableTable';
import { SetPlanningParameters } from '../PlanningParametersTab';
import { GetPlanningParameters } from './CropCard';
import CropZoneTable from './CropZoneTable';

interface FieldTableProps {
  crop: ApiCrop,
  farm: ApiGrowerFarm,
  getCustomerPlanningParameter: GetPlanningParameters,
  updatePlanningParameters: SetPlanningParameters,
  testID?: string,
}

type FieldTableType = IColumn<(
  RowMeta
  & ApiGrowerField
  & Pick<ApiPlanningParameter, 'yieldGoal' | 'yieldGoalUom'>)>;

const expandedState = {};

const FieldTable = ({
  crop,
  farm,
  getCustomerPlanningParameter,
  testID,
  updatePlanningParameters,
}: FieldTableProps) => {
  const [translate] = useTranslation(['common', 'growers']);
  const [hasDetails, setHasDetails] = useState(false);

  const fieldRows = (
    cropInput: ApiCrop,
    farmInput: ApiGrowerFarm,
    fields: ApiGrowerField[],
  ) => fields.map((field, idx) => {
    const planningParametersForField = getCustomerPlanningParameter({
      crop: cropInput,
      farm: farmInput,
      field,
    });
    const yieldGoalForField = planningParametersForField?.yieldGoal;
    const yieldGoalUomForField = planningParametersForField?.yieldGoalUom;
    return {
      ...field,
      hasDetails: !!field.cropZones.length,
      initDetail: expandedState[field.fieldName],
      rowDetailId: `row-drawer:${idx}|${field.id}`,
      rowId: `row:${idx}|${field.id}`,
      yieldGoal: yieldGoalForField,
      yieldGoalUom: yieldGoalUomForField,
    };
  });

  useEffect(() => {
    setHasDetails(farm.growerFields.some((field) => field.cropZones.length));
  }, [farm.growerFields]);

  const columns: FieldTableType[] = [
    !hasDetails && {
      columnId: 'spacer',
      header: {
        render: <HSpacer size="11" />,
      },
      render: () => null,
      flex: 0.11,
      align: 'left',
    },
    {
      columnId: 'fieldName',
      header: {
        render: translate('FIELD_NAME'),
      },
      render: (field) => field.fieldName,
      flex: 1,
    },
    {
      columnId: 'yieldGoal',
      header: {
        render: translate('YIELD_GOAL'),
      },
      render: (field) => (field.cropZones.length ? (
        `${StringUtility.formatDecimal(field.yieldGoal, 2)} ${translate(field.yieldGoalUom)}`
      ) : (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <NumericInput
            onChangeValue={(value) => updatePlanningParameters({
              crop,
              farm,
              field,
              property: 'yieldGoal',
              value,
            })}
            precision={2}
            style={{ minWidth: 88 }}
            testID={`${crop.cropType}-${crop.subType}-${farm.id}-${field.id}-yield-goal-input`}
            value={field.yieldGoal}
          />
          <HSpacer size="3" />
          <Text style={{ flex: 1 }}>{translate<string>(crop.yieldGoalUom)}</Text>
        </View>
      )),
      flex: 1.1,
    },
    {
      columnId: 'acreage',
      header: {
        render: translate('ACREAGE'),
        align: 'right',
      },
      render: (field) => (
        <Text category="p2" style={{ marginRight: 16 }}>
          {field.areaValue}
        </Text>
      ),
      flex: 1,
      align: 'right',
    },
  ];

  return (
    <SortableTable
      columns={columns}
      data={fieldRows(crop, farm, farm.growerFields)}
      onRowExpand={(data, expanded) => {
        expandedState[data.fieldName] = expanded;
      }}
      rowDetail={hasDetails && ((data) => {
        if (data.cropZones.length) {
          const { cropZones } = data;
          return (
            <CropZoneTable
              crop={crop}
              cropZones={cropZones}
              farm={farm}
              fields={farm.growerFields}
              getCustomerPlanningParameters={getCustomerPlanningParameter}
              testID="cropzone-table"
              updatePlanningParameters={updatePlanningParameters}
            />
          );
        }
        return null;
      })}
      testID={testID}
    />
  );
};

export default React.memo(FieldTable);
