import { Icon } from '@design';
import { AreaUnitType } from '@shared/enums';
import {
  FarmPlanDeliverableV1,
} from '@shared/interfaces/GrowerDeliverable/FarmPlanDeliverable/v1/FarmPlanDeliverableV1';
import * as _ from 'lodash';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Sizing, ThemeColors } from '../../../../constants';
import { ModalUtility } from '../../../../elements/content';
import { SortableTable } from '../../../../elements/table';
import { Button, Card, CenteredSpinner, Text } from '../../../../ui-components';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { testId } from '../../../../utilities/testId';
import {
  FarmPlanAcresPlannedSummary,
  FarmPlanAcresPlannedSummaryResult,
} from '../../../FarmPlan/FarmPlanAcresPlannedSummary';
import { ShowNotes } from '../../ShowNotes';
import { FarmPlanDeliverablePerFieldSummary } from './FarmPlanDeliverablePerFieldSummary';
import FarmPlanDeliverableProductDiscountSummaryQuickView
  from './FarmPlanDeliverableProductDiscountSummaryQuickView';
import {
  FarmPlanDeliverableProductSummaryQuickView,
} from './FarmPlanDeliverableProductSummaryQuickView';

interface Props {
  name?: string;
  note?: string;
  selections: FarmPlanDeliverableV1.Selections;
  snapshot: FarmPlanDeliverableV1.Snapshot;
  deliverableId: string;
}

const styles = StyleSheet.create({
  base: {
    flex: 1,
    overflow: 'hidden',
  },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  overviewHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  notesContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 2 * Sizing.DOUBLE_SPACING,
  },
  notesRowsContainer: {
    flex: 1,
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  overviewHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
    marginRight: 1.2 * Sizing.BASE_SPACING,
  },
  header: {
    flex: 1,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  subHeader: {
    fontSize: 1.5 * Sizing.BASE_SPACING,
    marginRight: Sizing.BASE_SPACING,
  },
  showMore: {
    color: ThemeColors.PRIMARY,
    marginTop: Sizing.HALF_SPACING,
    textDecorationLine: 'none',
    fontSize: Sizing.BASE_SPACING,
    marginRight: Sizing.BASE_SPACING,
  },
  icon: {
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  summaryCard: {
    flex: 1,
    borderRadius: Sizing.HALF_SPACING,
    backgroundColor: ThemeColors.DARK_GRAY,
    marginRight: Sizing.BASE_SPACING,
  },
  summaryCardHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  summaryCardRow: {
    flexDirection: 'row',
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: 1,
    padding: Sizing.BASE_SPACING,
  },
  quickViewSubsection: {
    margin: Sizing.BASE_SPACING,
    minWidth: '30%',
  },
  quickViewWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  quickViewInfoHeader: { fontWeight: 'bold' },
  summaryTableHeader: {
    fontSize: 1.1 * Sizing.EM,
    fontWeight: 'bold',
    marginBottom: 2 * Sizing.BASE_SPACING,
    paddingLeft: 4 * Sizing.BASE_SPACING,
  },
  summaryTableText: {
    fontSize: 1.1 * Sizing.EM,
    marginBottom: Sizing.BASE_SPACING,
    paddingLeft: 6 * Sizing.BASE_SPACING,
  },
  simulatedTableRow: {
    flexDirection: 'row',
    paddingHorizontal: Sizing.DOUBLE_SPACING,
  },
  summaryTable: {
    marginTop: Sizing.DOUBLE_SPACING,
    paddingTop: Sizing.DOUBLE_SPACING,
    borderBottomLeftRadius: Sizing.BASE_SPACING,
    borderBottomRightRadius: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.LIGHT_BLACK,
  },
  totalHeader: {
    marginTop: 0.1 * Sizing.DOUBLE_SPACING,
    paddingTop: Sizing.DOUBLE_SPACING,
    borderBottomLeftRadius: Sizing.BASE_SPACING,
    borderBottomRightRadius: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.LIGHT_BLACK,
  },
  subTotalHeader: {
    backgroundColor: ThemeColors.LIGHT_BLACK,
    paddingTop: Sizing.DOUBLE_SPACING,
  },
});

const InfoModal: FunctionComponent = () => {
  const [translate] = useTranslation(['farmPlans']);

  return (
    <View nativeID="info-modal">
      <Card testID="info-modal-card">
        <Text style={[styles.largeText, { textAlign: 'center' }]}>
          {translate('DISCOUNT_INFO_MESSAGE_1') as string}
        </Text>
        <Text style={[styles.largeText, { textAlign: 'center' }]}>
          {translate('DISCOUNT_INFO_MESSAGE_2') as string}
        </Text>
      </Card>
    </View>
  );
};

const INITIAL_NUMBER_OF_ROWS = 3;

// 0.5 is a magic number for the drawer expander column
const EXTRA_WIDTH = 0.5;

const centsOrFlat = (value: number) => {
  if (Math.abs(value) < 1) {
    return StringUtility.formatCurrencyAccounting(value);
  }
  return StringUtility.formatFlatCurrencyAccounting(value);
};

interface IProductColumn {
  label: string,
  width: number,
  render: (data: FarmPlanDeliverableV1.Snapshot['products'][number]) => ReactElement,
}
interface IProductTable {
  columns: IProductColumn[],
  preCostWidth: number,
  postCostWidth: number,
}

const columnWidths = {
  productSkuName: 1.5,
  packagesContracted: 1.5,
  unitPrice: 0.75,
  priceLocked: 0.5,
  costPerAcre: 0.75,
  totalCost: 0.75,
  planStatus: 1,
  infoModal: 0.5,
};

export const FarmPlanDeliverableDesktopView: FunctionComponent<Props> = ({
  name, snapshot, selections, note, deliverableId,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  const [productTable, setProductTable] = useState<IProductTable>(null);

  const [numberOfRows, setNumbersOfRows] = useState(INITIAL_NUMBER_OF_ROWS);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!selections || !snapshot) {
      return;
    }

    const columns: IProductColumn[] = [];

    columns.push({
      label: translate('PRODUCT_SKU_NAME'),
      width: columnWidths.productSkuName,
      render: (data) => (
        <Text
          {...testId(`${data.productSkuName}`)}
          numberOfLines={1}
          style={styles.largeText}
        >
          {data.productSkuName}
        </Text>
      ),
    });

    columns.push({
      label: translate('PACKAGES_CONTRACTED'),
      width: columnWidths.packagesContracted,
      render: (data) => (
        <Text
          {...testId(`${data.productSkuName}-sku-packages-contracted`)}
          numberOfLines={1}
          style={styles.largeText}
        >
          {StringUtility.formatDecimal(data.packagesContracted, 2)}
        </Text>
      ),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selections.orderDetails.unitPrice && columns.push({
      label: translate('UNIT_PRICE'),
      width: columnWidths.unitPrice,
      render: (data) => (
        <Text
          {...testId(`${data.productSkuName}-unit-price`)}
          numberOfLines={1}
          style={[styles.largeText, data.discounts.length > 0 && { textDecorationLine: 'line-through' }]}
        >
          {StringUtility.formatCurrency(data.retailPrice?.perUnit)}
        </Text>
      ),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selections.orderDetails.priceLocked && columns.push({
      label: '',
      width: columnWidths.priceLocked,
      render: (data) => (
        <>
          {data.priceLocked && <Icon fill={ThemeColors.TEXT} name="Lock" size="large" testID={`${data.productSkuName}-lock-icon`} />}
          {!data.priceLocked && <Icon fill={ThemeColors.SECONDARY} name="GrowersUnlock" size="large" testID={`${data.productSkuName}-unlock-icon`} />}
        </>
      ),
    });

    const preCostWidth = columns.reduce((sum, column) => sum + column.width, 0);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selections.orderDetails.costPerAcre && columns.push({
      label: translate('COST_PER_AC'),
      width: columnWidths.costPerAcre,
      render: (data) => (
        <Text
          {...testId(`${data.productSkuName}-cost-per-acre`)}
          numberOfLines={1}
          style={[styles.largeText, data.discounts.length > 0 && { textDecorationLine: 'line-through' }]}
        >
          {StringUtility.formatCurrencyAccounting(data.retailPrice?.perAcre)}
        </Text>
      ),
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    selections.orderDetails.totalCost && columns.push({
      label: translate('TOTAL_COST'),
      width: columnWidths.totalCost,
      render: (data) => (
        <Text
          {...testId(`${data.productSkuName}-cost`)}
          numberOfLines={1}
          style={[styles.largeText, data.discounts.length > 0 && { textDecorationLine: 'line-through' }]}
        >
          {StringUtility.formatCurrencyAccounting(data.retailPrice?.total)}
        </Text>
      ),
    });

    const withCostWidth = columns.reduce((sum, column) => sum + column.width, 0);

    columns.push({
      label: translate('PLAN_STATUS'),
      width: columnWidths.planStatus,
      render: (data) => (
        <Text
          {...testId(`${data.productSkuName}-plan-status`)}
          numberOfLines={1}
          style={styles.largeText}
        >
          {translate(data.planStatus)}
        </Text>
      ),
    });

    columns.push({
      label: '',
      width: columnWidths.infoModal,
      render: (data) => (
        <>
          <TouchableOpacity onPress={() => ModalUtility.show(
            <FarmPlanDeliverableProductSummaryQuickView
              acresApplied={data.acresApplied}
              avgRateApplied={data.averageRateApplied}
              avgRateAppliedUom={data.averageRateAppliedUom}
              productSkuName={data.productSkuName}
              productSkuPackagesRequired={data.packagesRequired}
              productSkuPrice={data.discountedPrice.perPackage}
              productUnitsRequired={data.unitsRequired}
              selections={selections}
            />,
          )}
          >
            {data.productSkuName && (
              <Icon
                fill={ThemeColors.SECONDARY}
                name="GrowersViewDetails"
                size="large"
                testID={`${data.productSkuName}-view-details-icon`}
              />
            )}
          </TouchableOpacity>
        </>
      ),
    });

    const postCostWidth = columns.reduce((sum, column) => sum + column.width, 0) - withCostWidth;

    setProductTable({
      columns,
      preCostWidth: preCostWidth + EXTRA_WIDTH,
      postCostWidth,
    });

    setShowMore(snapshot.products.length <= INITIAL_NUMBER_OF_ROWS);
  }, [selections, snapshot, translate]);

  const PlannedAreas = () => {
    const acresPlannedSummary: FarmPlanAcresPlannedSummaryResult = ({
      planned: snapshot.overview.acresPlanned,
      total: snapshot.header.totalGrowerAcres,
      areaUnitType: AreaUnitType.Acre,
      passes: snapshot.overview.numberOfPasses,
      crops: _.map(snapshot.overview.acresPlannedPerCrop, (areaValue, typePlusSubtype) => ({
        // this is a little jenky.
        // typePlusSubtype is a key in an object that is the end readable type | subtype value
        // so this works, but only because this will attempt to be translated but the key won't
        // exist and just display the key.
        // fixing this would require changing the snapshot structure to store more data about each
        // crop as an array of objects rather than an object of typePlusSubtype: areaValue
        type: typePlusSubtype,
        areaValue,
        percent: Math.floor((areaValue / snapshot.overview.acresPlanned) * 100),
      })),
    });

    return (
      <FarmPlanAcresPlannedSummary
        planned={acresPlannedSummary}
        showField={{
          acresPlannedPerCrop: true,
          passes: true,
        }}
      />
    );
  };

  const showMoreHandler = () => {
    if (!showMore) {
      setNumbersOfRows(snapshot.products.length);
    } else {
      setNumbersOfRows(INITIAL_NUMBER_OF_ROWS);
    }
    setShowMore(!showMore);
  };

  if (!productTable) {
    return <CenteredSpinner size="giant" />;
  }

  const showCosts = (
    selections.orderDetails.totalCost
    || selections.orderDetails.costPerAcre
  );

  const hasNonSKUDiscount = (
    snapshot.overview.discounts.length > 0
  );

  const hasDiscountNonZero = !!(
    snapshot.overview.discountTotal || snapshot.overview.discountPerAcre
  );

  const hasSKUDiscounts = snapshot.products.some((product) => product.discounts?.length);
  const discountWidth = productTable.preCostWidth - (hasSKUDiscounts ? 0 : EXTRA_WIDTH);

  const showSubtotalDiscountSummary = showCosts && hasNonSKUDiscount;

  const showSubtotal = showCosts && hasDiscountNonZero;

  const canShowMore = snapshot.products.length > INITIAL_NUMBER_OF_ROWS;

  const TotalFooter = () => (
    <View style={styles.simulatedTableRow}>
      <View style={{ flex: discountWidth }}>
        <Text style={styles.summaryTableHeader}>{translate('TOTAL')}</Text>
      </View>
      {selections.orderDetails.costPerAcre && (
        <View style={{ flex: columnWidths.costPerAcre }}>
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatCurrencyAccounting(snapshot.overview.orderTotalPerAcre)}
          </Text>
        </View>
      )}
      {selections.orderDetails.totalCost && (
        <View style={{ flex: columnWidths.totalCost }}>
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatCurrencyAccounting(snapshot.overview.orderTotal)}
          </Text>
        </View>
      )}
      <View style={{ flex: productTable.postCostWidth }} />
    </View>
  );

  const subtotal = snapshot.overview.cost - snapshot.overview.productDiscountTotal;

  const SubTotalHeader = () => showSubtotalDiscountSummary && (
    <View style={styles.simulatedTableRow}>
      <View style={{ flex: discountWidth }}>
        <Text style={styles.summaryTableHeader}>{translate('SUBTOTAL')}</Text>
      </View>
      {selections.orderDetails.costPerAcre && (
        <View style={{ flex: columnWidths.costPerAcre }}>
          <Text
            numberOfLines={1}
            style={[styles.largeText, { textDecorationLine: 'line-through' }]}
          >
            {StringUtility.formatCurrencyAccounting(subtotal / snapshot.overview.acresPlanned)}
          </Text>
        </View>
      )}
      {selections.orderDetails.totalCost && (
        <View style={{ flex: columnWidths.totalCost }}>
          <Text
            numberOfLines={1}
            style={[styles.largeText, { textDecorationLine: 'line-through' }]}
          >
            {StringUtility.formatCurrencyAccounting(subtotal)}
          </Text>
        </View>
      )}
      <View style={{ flex: productTable.postCostWidth }} />
    </View>
  );

  const Discounts = () => hasDiscountNonZero && (
    <>
      <View style={styles.simulatedTableRow}>
        <Text style={[styles.summaryTableHeader, { marginRight: Sizing.HALF_SPACING }]}>
          {translate('ORDER_DISCOUNTS')}
        </Text>
        {/* Functionality for info icon in ST-2833 */}
        <TouchableOpacity onPress={() => ModalUtility.show(<InfoModal />)}>
          <Icon
            fill={ThemeColors.SECONDARY}
            name="Info"
            style={styles.icon}
            testID="info-icon"
          />
        </TouchableOpacity>
      </View>
      {snapshot.overview.discounts.map((discount) => (
        <View key={discount.name} style={styles.simulatedTableRow}>
          <Text
            numberOfLines={1}
            style={{
              flex: columnWidths.productSkuName,
              fontSize: 16,
              marginBottom: Sizing.BASE_SPACING,
              paddingLeft: 4 * Sizing.BASE_SPACING,
            }}
          >
            {discount.name}
          </Text>
          <View
            style={{
              flex: columnWidths.packagesContracted - (hasSKUDiscounts ? 0 : EXTRA_WIDTH),
              marginLeft: 20,
            }}
          >
            <Text style={[styles.largeText, { color: ThemeColors.DISCOUNT }]}>
              {translate('PERCENT_OFF', { percent: discount.percent })}
            </Text>
          </View>
          {selections.orderDetails.unitPrice && (
            <View style={{ flex: columnWidths.unitPrice }} />
          )}
          {selections.orderDetails.priceLocked && (
            <View style={{ flex: columnWidths.priceLocked }} />
          )}
          {selections.orderDetails.costPerAcre && (
            <View style={[{ flex: columnWidths.costPerAcre }]}>
              <Text
                numberOfLines={1}
                style={[styles.largeText, { color: ThemeColors.DISCOUNT }]}
              >
                {StringUtility.formatDiscount(discount.dollarsPerAcre)}
              </Text>
            </View>
          )}
          {selections.orderDetails.totalCost && (
            <View style={{ flex: columnWidths.totalCost }}>
              <Text
                numberOfLines={1}
                style={[styles.largeText, { color: ThemeColors.DISCOUNT }]}
              >
                {StringUtility.formatDiscount(discount.dollarsTotal)}
              </Text>
            </View>
          )}
          <View style={{ flex: productTable.postCostWidth }} />
        </View>
      ))}
    </>
  );

  return (
    <View
      style={{
        flex: 1,
        marginTop: Sizing.HALF_SPACING,
      }}
    >
      <View style={[styles.headerContainer, { marginBottom: Sizing.BASE_SPACING }]}>
        <View style={[styles.flexLeft, { flex: 1 }]}>
          <Text
            numberOfLines={1}
            style={styles.header}
          >
            {name || `${translate('FARM_PLAN_DELIVERABLE')}: `}
          </Text>
        </View>
        <View style={styles.flexRight}>
          <Button
            {...testId('download-pdf')}
            iconLeft="download-outline"
            onPress={() => LinkingUtility.openUrl(
              `${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`,
              { newTab: true },
            )}
            status="secondary"
          >
            {translate('DOWNLOAD_PDF')}
          </Button>
        </View>
      </View>
      <ScrollView nativeID="farm-plan-creation-view" style={styles.base}>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.summaryCard}>
            <View style={{ padding: Sizing.BASE_SPACING }}>
              <Text
                {...testId('farm-plan-name')}
                numberOfLines={1}
                style={styles.summaryCardHeader}
              >
                {snapshot.header.farmPlanName}
              </Text>
            </View>
            <View style={styles.summaryCardRow}>
              <View style={[styles.flexLeft, { flex: 1 }]}>
                <Icon
                  fill={ThemeColors.TEXT}
                  name="GrowersPlanning"
                  style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                  testID="grower-icon"
                />
                <Text
                  {...testId('grower-name')}
                  numberOfLines={1}
                  style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                >
                  {snapshot.header.growerName}
                </Text>
              </View>
            </View>
            <View style={styles.summaryCardRow}>
              <View style={[styles.flexLeft, { flex: 1 }]}>
                <Icon
                  fill={ThemeColors.TEXT}
                  name="GrowersBusiness"
                  style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                  testID="business-icon"
                />
                <Text
                  {...testId('business-name')}
                  numberOfLines={1}
                  style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                >
                  {snapshot.header.businessName}
                </Text>
              </View>
            </View>
            <View style={styles.summaryCardRow}>
              <View style={[styles.flexLeft, { flex: 1 }]}>
                <Icon
                  fill={ThemeColors.TEXT}
                  name="Person"
                  style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                  testID="user-icon"
                />
                <Text
                  {...testId('business-user-name')}
                  numberOfLines={1}
                  style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                >
                  {snapshot.header.businessUserName}
                </Text>
              </View>
            </View>
            {!!snapshot.header.locationName && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Icon
                    fill={ThemeColors.TEXT}
                    name="Navigation2"
                    style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                    testID="location-icon"
                  />
                  <Text
                    {...testId('business-location-name')}
                    numberOfLines={1}
                    style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                  >
                    {snapshot.header.locationName}
                  </Text>
                </View>
              </View>
            )}
          </View>
          {/* COST SUMMARY */}
          <View style={styles.summaryCard}>
            <View
              style={{
                padding: Sizing.BASE_SPACING,
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text style={styles.summaryCardHeader}>
                {translate('COST_SUMMARY')}
              </Text>
            </View>
            {(showSubtotal) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>
                    {translate('RETAIL_PRICE')}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.orderDetails.costPerAcre && (
                    <Text
                      nativeID="expected-cost-per-acre"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        { textDecorationLine: 'line-through' },
                      ]}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatCurrencyAccounting(
                          snapshot.overview.costPerAcre,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.orderDetails.totalCost && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="expected-cost-total"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        { textDecorationLine: 'line-through' },
                      ]}
                    >
                      {centsOrFlat(snapshot.overview.cost)}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {(hasDiscountNonZero) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>
                    {translate('DISCOUNTS')}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.orderDetails.costPerAcre && (
                    <Text
                      nativeID="discount-per-acre"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        {
                          color: snapshot.overview.discountPerAcre > 0
                            ? ThemeColors.DISCOUNT : ThemeColors.PRIMARY,
                        },
                      ]}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatDiscount(
                          snapshot.overview.discountPerAcre,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.orderDetails.totalCost && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="discount-total"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        {
                          color: snapshot.overview.discountTotal > 0
                            ? ThemeColors.DISCOUNT : ThemeColors.PRIMARY,
                        },
                      ]}
                    >
                      {StringUtility.formatFlatCurrencyAccounting(
                        -snapshot.overview.discountTotal,
                        '-$',
                      )}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {(showCosts) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>
                    {translate('TOTAL')}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.orderDetails.costPerAcre && (
                    <Text
                      nativeID="order-total-per-acre"
                      numberOfLines={1}
                      style={styles.largeText}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatCurrencyAccounting(
                          snapshot.overview.orderTotalPerAcre,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.orderDetails.totalCost && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="order-total"
                      numberOfLines={1}
                      style={styles.largeText}
                    >
                      {centsOrFlat(snapshot.overview.orderTotal)}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>

          <View style={[styles.summaryCard, { marginRight: 0 }]}>
            <PlannedAreas />
          </View>
        </View>
        <Card style={{ marginTop: Sizing.BASE_SPACING }} testID="farm-plan-creation-card">
          <View>
            {note ? (
              <View style={styles.notesContainer}>
                <Text
                  numberOfLines={1}
                  style={[styles.overviewHeader, { marginBottom: Sizing.BASE_SPACING }]}
                >
                  { translate('NOTES') as string }
                </Text>
                <View style={styles.notesRowsContainer}>
                  <ShowNotes note={note} />
                </View>
              </View>
            ) : null}
          </View>
          <View>
            <View style={styles.overviewHeaderContainer}>
              <Text
                numberOfLines={1}
                style={[styles.overviewHeader, { marginBottom: Sizing.BASE_SPACING }]}
              >
                { translate('ORDER_DETAILS') as string }
              </Text>
              <Text
                numberOfLines={1}
                style={styles.subHeader}
              >
                {translate(
                  snapshot.products.length > 1 ? 'PRODUCTS_COUNT' : 'PRODUCT_COUNT_1',
                  { productCount: snapshot.products.length },
                )}
              </Text>
              {canShowMore && (
                <Text
                  {...testId('show-details-link')}
                  numberOfLines={1}
                  onPress={showMoreHandler}
                  style={styles.showMore}
                >
                  {showMore ? translate('COLLAPSE_ALL') : translate('SHOW_DETAILS')}
                </Text>
              )}
            </View>
          </View>
          <SortableTable
            headers={productTable.columns.map((column, index) => ({
              label: column.label,
              field: `column${index}`,
            }))}
            includeDrawerToggleSpacing
            numberOfRows={numberOfRows}
            rows={snapshot.products.map((product) => ({
              key: product.productSkuName,
              columns: productTable.columns.map((column) => column.render(product)),
              drawer: (
                (product.discounts.length > 0)
                && (selections.orderDetails.totalCost
                || selections.orderDetails.costPerAcre
                || selections.orderDetails.unitPrice)) && (
                  <FarmPlanDeliverableProductDiscountSummaryQuickView
                    columnWidths={columnWidths}
                    discountedPrice={product.discountedPrice}
                    discounts={product.discounts}
                    selections={selections}
                  />
              ),
            }))}
            widths={productTable.columns.map((column) => column.width)}
          />
          {canShowMore && !showMore && (
            <View
              style={{ alignItems: 'center', marginVertical: Sizing.BASE_SPACING }}
            >
              <Text
                onPress={showMoreHandler}
                style={[styles.subHeader, styles.showMore]}
              >
                {translate('MORE', { count: snapshot.products.length - numberOfRows })}
              </Text>
            </View>
          )}
          {showCosts && (
            <>
              {showSubtotalDiscountSummary && showMore && (
                <View style={styles.subTotalHeader}>
                  <SubTotalHeader />
                  <Discounts />
                </View>
              )}
              <View style={styles.totalHeader}>
                <TotalFooter />
              </View>
            </>
          )}
          {canShowMore && showMore && (
            <View style={{ alignItems: 'center', marginTop: Sizing.BASE_SPACING }}>
              <Text
                onPress={showMoreHandler}
                style={[styles.subHeader, styles.showMore]}
              >
                {translate('COLLAPSE_ALL')}
              </Text>
            </View>
          )}
        </Card>

        <FarmPlanDeliverablePerFieldSummary
          selections={selections}
          zoneSummaries={snapshot.areas}
        />
      </ScrollView>
    </View>
  );
};
