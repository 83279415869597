import React, { FC, useState } from 'react';
import {
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';
import { withTranslation } from 'react-i18next';
import { RenderProp } from '@ui-kitten/components/devsupport';
import { InputStatus } from '@theme/variant-interfaces/Status';
import { TextProps, Text } from '../Text/Text';
import { Input, InputProps } from './Input';

export interface ValidatedInputProps extends InputProps {
  value?: string;
  errorMessage?: RenderProp<TextProps> | React.ReactText;
}

const ValidatedInputRaw: FC<ValidatedInputProps> = ({
  t,
  caption,
  errorMessage,
  isRequired,
  label,
  status,
  placeholder,
  onBlur,
  ...inputProps
}) => {
  const [requiredError, setRequiredError] = useState<boolean>(false);
  const [validationStatus, setValidationStatus] = useState<InputStatus>(status);

  const getErrorMessage = () => {
    if (isRequired && requiredError && errorMessage) {
      return errorMessage;
    }

    if (isRequired && requiredError && !errorMessage) {
      return (textProps) => (
        <Text {...textProps}>
          {`${label} ${t('REQUIRED')}`}
        </Text>
      );
    }

    return caption;
  };

  const onBlurValidation = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onBlur?.(event);

    if (isRequired && !event.nativeEvent?.text) {
      setRequiredError(true);
      setValidationStatus('danger');
    }

    if (isRequired && requiredError && event.nativeEvent?.text) {
      setRequiredError(false);
      setValidationStatus(status || null);
    }
  };

  return (
    <Input
      caption={getErrorMessage()}
      isRequired={isRequired}
      label={label}
      onBlur={onBlurValidation}
      placeholder={placeholder}
      status={validationStatus}
      {...inputProps}
    />
  );
};

export const ValidatedInput = withTranslation('common', { withRef: true })(ValidatedInputRaw);
