import { Icon, IconName, Text, VSpacer } from '@design';
import React from 'react';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: 400,
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
});

export interface EmptyStateProps {
  icon?: IconName,
  subTitle?: string,
  testID: string,
  title: string,
}

export function EmptyState (
  { icon, subTitle, title, testID }: EmptyStateProps,
) {
  return (
    <View style={styles.container}>
      <VSpacer size="11" />
      {icon && (
        <>
          <Icon name={icon} size="large" testID={`${testID}-icon`} />
          <VSpacer size="5" />
        </>
      )}
      <Text category="h6" status="basic" style={styles.text}>{title}</Text>
      {!!subTitle && (
        <>
          <VSpacer size="3" />
          <Text appearance="hint" category="p2" style={styles.text}>{subTitle}</Text>
        </>
      )}
      <VSpacer size="12" />
    </View>
  );
}
