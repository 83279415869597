import { ApiDeliverablePublic } from '@shared/interfaces/api';
import React, { FunctionComponent } from 'react';
import { DeliverableResponsive } from '../DeliverableResponsive';
import { FarmPlanDeliverableRenderFactory } from './FarmPlanDeliverableRenderFactory';

interface Props {
  deliverable: ApiDeliverablePublic,
}

export const FarmPlanDeliverablePublicPage: FunctionComponent<Props> = ({ deliverable }) => {
  const renderer = FarmPlanDeliverableRenderFactory.getPublicRenderer(deliverable);
  return (
    <DeliverableResponsive
      desktop={renderer.getDesktopView()}
      mobile={renderer.getMobileView()}
    />
  );
};
