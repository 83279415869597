import { StyleSheet, View } from 'react-native';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Text } from '../../../../ui-components';
import { StringUtility } from '../../../../utilities';
import { Sizing } from '../../../../constants';

interface FarmPlanProductSummaryProps {
  selections?: any;
  avgRateApplied: number;
  avgRateAppliedUom: string;
  acresApplied: number;
  productUnitsRequired: number;
  productSkuPackagesRequired: number;
  productSkuPrice: number;
  retailUnitPrice: number;
}

const styles = StyleSheet.create({
  quickViewSubsection: {
    margin: Sizing.BASE_SPACING,
    minWidth: '30%',
  },
  quickViewWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  quickViewInfoHeader: { fontWeight: 'bold' },
});

export const FarmPlanDeliverableProductSummaryQuickView: FC<FarmPlanProductSummaryProps> = ({
  selections,
  productUnitsRequired,
  productSkuPrice,
  productSkuPackagesRequired,
  acresApplied,
  avgRateApplied,
  avgRateAppliedUom,
  retailUnitPrice,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);

  const showField = useCallback((name: string) => (_.isNil(selections)
    ? true : _.get(selections, `${name}.enabled`) === true), [selections]);

  return (
    <View style={styles.quickViewWrapper}>
      {showField('PRODUCT_SUMMARY.AVERAGE_RATE_APPLIED') && (
        <View style={styles.quickViewSubsection}>
          <Text style={styles.quickViewInfoHeader}>{translate('AVG_RATE_APPLIED')}</Text>
          <Text>
            {`${StringUtility
              .formatDecimal(avgRateApplied)} ${translate(avgRateAppliedUom)}${translate('PER_AC')}`}
          </Text>
        </View>
      )}
      {showField('PRODUCT_SUMMARY.ACRES_APPLIED') && (
        <View style={styles.quickViewSubsection}>
          <Text style={styles.quickViewInfoHeader}>{translate('ACRES_APPLIED')}</Text>
          <Text>{StringUtility.formatDecimal(acresApplied, 2)}</Text>
        </View>
      )}
      {showField('PRODUCT_SUMMARY.PRODUCT_UNITS_REQUIRED') && (
        <View style={styles.quickViewSubsection}>
          <Text style={styles.quickViewInfoHeader}>{translate('UNITS_REQUIRED')}</Text>
          <Text>{StringUtility.formatDecimal(productUnitsRequired, 2)}</Text>
        </View>
      )}
      {showField('PRODUCT_SUMMARY.PRODUCT_SKU_PACKAGES_REQUIRED') && (
        <View style={styles.quickViewSubsection}>
          <Text style={styles.quickViewInfoHeader}>{translate('SKU_PACKAGES_REQUIRED')}</Text>
          <Text>{StringUtility.formatDecimal(productSkuPackagesRequired, 2)}</Text>
        </View>
      )}
      {showField('PRODUCT_SUMMARY.PRODUCT_SKU_PRICE') && (
        <View style={styles.quickViewSubsection}>
          <Text style={styles.quickViewInfoHeader}>{translate('PRODUCT_SKU_PRICE')}</Text>
          <Text>
            {StringUtility.formatCurrencyAccounting(productSkuPrice)}
          </Text>
        </View>
      )}
      {showField('PRODUCT_SUMMARY.RETAIL_UNIT_PRICE') && (
        <View style={styles.quickViewSubsection}>
          <Text style={styles.quickViewInfoHeader}>{translate('RETAIL_UNIT_PRICE')}</Text>
          <Text>
            {StringUtility.formatCurrencyAccounting(retailUnitPrice)}
          </Text>
        </View>
      )}
    </View>
  );
};
