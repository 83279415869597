import { ApiDeliverable } from '@shared/interfaces/api';
import { FunctionComponent } from 'react';
import { FarmPlanDeliverableRenderFactory } from './FarmPlanDeliverableRenderFactory';

interface Props {
  deliverable: ApiDeliverable;
}

export const FarmPlanDeliverableAuthPage:FunctionComponent<Props> = ({
  deliverable,
}) => {
  const renderer = FarmPlanDeliverableRenderFactory.getRenderer(deliverable);

  return renderer.getDesktopView();
};
