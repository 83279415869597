import { VSpacer, Header, Card, Text, TextLink, Icon } from '@design';
import { FarmPlanProfitCalculation } from '@shared/utils';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { StringUtility } from '../../../../../utilities';
import { Sizing, ThemeColors } from '../../../../../constants';

interface FarmPlanCostSummaryProps {
  profitCalculation: FarmPlanProfitCalculation;
  onViewDiscounts(): void;
}

export const FarmPlanCostSummary = ({
  profitCalculation,
  onViewDiscounts,
}: FarmPlanCostSummaryProps) => {
  const [translate] = useTranslation([
    'farmPlans',
    'growers',
    'prepare',
    'common',
  ]);

  const styles = useStyleSheet({
    card: {
      flex: 1,
    },
    divider: {
      backgroundColor: 'color-basic-transparent-100',
    },
    flexLeft: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flex: 1,
    },
    flexRight: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    largeText: { fontSize: Sizing.EM },
    perAcreHint: { fontSize: 12, lineHeight: 16.2 },
    summaryCard: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    summaryTotal: {
      flexDirection: 'row',
    },
    summaryDiscountText: {
      color: ThemeColors.DISCOUNT,
    },
  });

  const [hasDiscounts, setHasDiscounts] = useState(false);

  useEffect(() => {
    const hasSKUDiscount = profitCalculation.products.some(
      (summary) => summary.discounts.length > 0,
    );
    const anyDiscounts = profitCalculation.discounts?.length > 0 || hasSKUDiscount;
    setHasDiscounts(anyDiscounts);
  }, [profitCalculation]);

  const subtotalPerAcre = StringUtility.formatCurrencyAccounting(
    profitCalculation.productCosts / profitCalculation.acresPlanned,
  );
  const discountPerAcre = StringUtility.formatDiscount(
    profitCalculation.totalDiscount / profitCalculation.acresPlanned,
  );
  const totalPerAcre = StringUtility.formatCurrencyAccounting(
    profitCalculation.costAfterDiscounts / profitCalculation.acresPlanned,
  );
  const subtotal = StringUtility.formatCurrencyAccounting(
    profitCalculation.productCosts,
  );
  const totalDiscount = StringUtility.formatCurrencyAccounting(
    -profitCalculation.totalDiscount,
    '-$',
  );
  const total = StringUtility.formatCurrencyAccounting(
    profitCalculation.costAfterDiscounts,
  );
  const discounts = profitCalculation.products.reduce((acc, current) => {
    return acc + current.discounts.length;
  }, 0) + profitCalculation.discounts.length;

  return (
    <View style={{ flex: 1 }}>
      <Header
        level="3"
        testID="farm-plan-cost-summary"
        title={translate('COST_SUMMARY')}
      />
      <VSpacer size="4" />
      <Card style={styles.card} testID="cost-summary-card">
        <>
          <View style={styles.summaryTotal}>
            <View style={styles.flexLeft} testID="cost-summary-subtotal-label">
              <Text numberOfLines={1} style={styles.largeText}>
                {translate<string>('RETAIL_PRICE')}
              </Text>
            </View>

            <View style={[styles.flexRight, { flex: 1 }]}>
              <Text
                numberOfLines={1}
                style={[
                  styles.largeText,
                  hasDiscounts && { textDecorationLine: 'line-through' },
                ]}
                testID="cost-summary-subtotal"
              >
                {subtotal}
              </Text>
            </View>
          </View>
          <View style={styles.summaryTotal}>
            <View style={styles.flexLeft} testID="cost-summary-subtotal-per-acre-label">
              <Text appearance="hint" category="p2">
                {translate<string>('PER_ACRE')}
              </Text>
            </View>

            <View style={[styles.flexRight, { flex: 1 }]}>
              <Text
                appearance="hint"
                category="p2"
                style={
                  hasDiscounts
                    ? { textDecorationLine: 'line-through' }
                    : undefined
                }
                testID="cost-summary-subtotal-per-acre"
              >
                {subtotalPerAcre}
              </Text>
            </View>
          </View>
          {hasDiscounts && (
            <>

              <VSpacer size="6" />
              <View style={styles.summaryTotal}>
                <View style={styles.flexLeft}>
                  <Text
                    style={styles.largeText}
                    testID="cost-summary-discount-label"
                  >
                    {hasDiscounts ? `${translate('DISCOUNTS')} (${discounts})` : '\u00a0'}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  <Text
                    category="p1"
                    numberOfLines={1}
                    style={styles.summaryDiscountText}
                    testID="cost-summary-discount-total"
                  >
                    {hasDiscounts ? totalDiscount : '\u00a0'}
                  </Text>
                </View>
              </View>
              <View style={styles.summaryTotal}>
                <View style={styles.flexLeft} testID="cost-summary-discount-per-acre-label">
                  <Text appearance="hint" category="p2">
                    {hasDiscounts && translate<string>('PER_ACRE')}
                  </Text>
                </View>

                <View style={[styles.flexRight, { flex: 1 }]}>
                  <Text
                    category="p2"
                    numberOfLines={1}
                    style={styles.summaryDiscountText}
                    testID="cost-summary-discount-per-acre"
                  >
                    {hasDiscounts ? discountPerAcre : '\u00a0'}
                  </Text>
                </View>
              </View>
              {hasDiscounts && (
                <View style={styles.summaryTotal}>
                  <TextLink
                    accessoryRight={(props) => <Icon {...props} name="Edit" testID="view-discounts-icon" />}
                    appearance="secondary"
                    onPress={onViewDiscounts}
                    style={{ fontSize: 12 }}
                    testID="view-discounts"
                  >
                    {translate<string>('VIEW_DISCOUNTS')}
                  </TextLink>
                </View>
              )}
            </>
          )}
          <VSpacer size="5" />
          <Divider style={styles.divider} />
          <VSpacer size="5" />
          <View style={styles.summaryTotal}>
            <View style={[styles.flexLeft]} testID="expected-cost-total-label">
              <Text numberOfLines={1} style={styles.largeText}>
                {translate<string>('TOTAL')}
              </Text>
            </View>
            <View style={[styles.flexRight, { flex: 1 }]}>
              <Text
                numberOfLines={1}
                style={[styles.largeText, { fontWeight: 'bold' }]}
                testID="expected-cost-total"
              >
                {total}
              </Text>
            </View>
          </View>
          <View style={styles.summaryTotal}>
            <View style={styles.flexLeft} testID="expected-cost-per-acre-label">
              <Text appearance="hint" category="p2">
                {translate<string>('PER_ACRE')}
              </Text>
            </View>

            <View style={[styles.flexRight, { flex: 1 }]}>
              <Text
                appearance="hint"
                category="p2"
                testID="expected-cost-total-per-acre"
              >
                {totalPerAcre}
              </Text>
            </View>
          </View>
          <VSpacer size="5" />
          <Text
            appearance="hint"
            style={styles.perAcreHint}
          >
            {translate<string>('PER_ACRE_DISCOUNTS')}
          </Text>
        </>
      </Card>
    </View>
  );
};
