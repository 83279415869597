import React, { FC, useState } from 'react';
import _ from 'lodash';
import { Button, Text } from '@design';
import { SortableTable, RowMeta, IColumn } from '../../../components/SortableTable';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

interface DemoData extends RowMeta {
  column1: string,
  column2: number,
  column3: string,
  column4: string,
  column5: string;
}

const columns: IColumn<DemoData>[] = [
  {
    columnId: 'col1',
    flex: 1,
    header: {
      render: 'flex width',
    },
    render: (data) => data.column1,
  },
  {
    columnId: 'col2',
    width: 50,
    header: {
      render: '50',
    },
    render: (data) => data.column2,
  },
  {
    columnId: 'col3',
    width: 100,
    header: {
      render: '100',
    },
    render: (data) => ({ textProps }) => <Text {...textProps}>{data.column3}</Text>,
  },
  {
    columnId: 'col4',
    width: 150,
    header: {
      render: '150',
    },
    render: (data) => data.column4,
  },
  {
    columnId: 'col5',
    flex: 1,
    header: {
      align: 'right',
      render: 'x',
    },
    render: (data) => data.column5,
    align: 'right',
  },

];

const data: DemoData[] = [
  {
    rowId: 'row1',
    column1: 'Row 1 column 1',
    column2: 1.2,
    column3: 'Row 1 column 3',
    column4: 'Row 1 column 4',
    column5: 'r1c5',
    hasDetails: true,
    initDetail: true,
  },
  {
    rowId: 'row2',
    column1: 'Row 2 column 1',
    column2: 2.2,
    column3: 'Row 2 column 3',
    column4: 'Row 2 column 4',
    column5: 'r2c5',
    hasDetails: true,
  },
  {
    rowId: 'row3',
    column1: 'Row 3 column 1',
    column2: 3.2,
    column3: 'Row 3 column 3',
    column4: 'Row 3 column 4',
    column5: 'r3c5',
  },
];

export const SortableTableDemo: FC = () => {
  const [demo1Data] = useState(_.cloneDeep(data));
  const [demo2Data, setDemo2Data] = useState(_.cloneDeep(data));

  return (
    <>
      <DemoContainer label="SortableTable No Detail">
        <DemoBlock style={{ width: '100%' }}>
          <SortableTable
            columns={columns}
            data={demo1Data}
            style={{ width: '100%' }}
            testID="test-table"
          />
        </DemoBlock>
      </DemoContainer>
      <DemoContainer label="SortableTable Detail">
        <DemoBlock style={{ width: '100%' }}>
          <SortableTable
            columns={columns}
            data={demo2Data}
            rowDetail={(detailData) => <Text>{JSON.stringify(detailData)}</Text>}
            style={{ width: '100%' }}
            testID="test-table"
          />
        </DemoBlock>
      </DemoContainer>
      <Button
        onPress={() => {
          const cloned = _.cloneDeep(demo2Data);
          cloned[0].column1 += ' cloned';
          setDemo2Data(cloned);
        }}
        testID="test-button"
      >
        Clone Data
      </Button>
    </>
  );
};
