import { useFeatureFlags } from '@design';
import { FeatureFlags } from '@shared/enums';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { IntegrationApi } from '../utilities/api';

interface UseIntegrationProps {
  businessId?: string,
  onError?(error: Error): void,
}

export const useIntegration = ({ businessId, onError }: UseIntegrationProps) => {
  const queryClient = useQueryClient();
  const { isFlagOn } = useFeatureFlags();
  const invalidateIntegration = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.INTEGRATION, businessId])
  ), [businessId, queryClient]);

  const { data: integration, isFetching: isIntegrationLoading } = useQuery(
    [QueryKeys.INTEGRATION, businessId],
    () => IntegrationApi.getForBusiness(businessId),
    {
      enabled: !!businessId && isFlagOn(businessId, FeatureFlags.INTEGRATIONS),
      onError: (error: Error) => onError?.(error),
    },
  );

  return {
    integration,
    invalidateIntegration,
    isIntegrationLoading,
  };
};
