import { CropSubType, CropType } from '@shared/enums';
import { ApiCropLogic } from '@shared/interfaces/api';
import { CropLogicUtilities, Permissions, userHasPermission } from '@shared/utils';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { useAuthentication } from '../contexts/dataSync/AuthenticationContext';
import { CropLogicApi } from '../utilities/api';
import { useBusinessLocation } from './useBusinessLocation';
import { usePriceTypeList } from './usePriceTypeList';
import { useUser } from './useUser';

interface UseProgramProps {
  programId: string | null,
  onCreate?(): void,
  onError?(): void,
  onUpdate?(): void,
}

export function useProgram ({
  programId,
  onCreate,
  onError,
  onUpdate,
}: UseProgramProps) {
  const { currentBusinessId, user: currentUser } = useAuthentication();
  const { defaultPriceTypeId, priceTypes, isLoading: isPriceTypeLoading } = usePriceTypeList();

  const canEditAccount = userHasPermission(currentUser, Permissions.ACCESS_ALL_BUSINESSES);
  const defaultUserId = canEditAccount ? '' : currentUser.id ?? '';

  const defaultProgram = useMemo<ApiCropLogic>(() => ({
    businessId: currentBusinessId,
    createdAt: new Date(),
    cropSubType: CropSubType.ADZUKI_BEAN,
    cropType: CropType.ALFALFA,
    farmPlanId: null,
    id: null,
    isActive: true,
    logicName: '',
    passes: [],
    priceTypeId: defaultPriceTypeId,
    updatedAt: new Date(),
    userAccountId: defaultUserId,
  }), [currentBusinessId, defaultUserId, defaultPriceTypeId]);

  const initialData = useMemo(() => ({
    initialProgram: defaultProgram,
    isProgramSaved: true,
    isProgramValid: false,
    program: defaultProgram,
  }), [defaultProgram]);

  const queryKey = [QueryKeys.PROGRAM, programId];
  const queryClient = useQueryClient();
  const { data, isFetching: isProgramLoading } = useQuery(
    queryKey,
    async () => {
      if (!programId) {
        return initialData;
      }

      const program = await CropLogicApi.getCropLogic(programId);
      if (!program.priceTypeId) {
        program.priceTypeId = defaultPriceTypeId;
      }
      
      return {
        initialProgram: program,
        isProgramSaved: true,
        isProgramValid: true,
        program,
      };
    },
    {
      enabled: !isPriceTypeLoading,
      onError: () => onError?.(),
      placeholderData: initialData,
    },
  );

  const isOwner = data.program.userAccountId === currentUser.id;

  const priceType = priceTypes.find(({ id }) => (
    id === data.program?.priceTypeId ?? defaultPriceTypeId));

  const { isUserLoading, user } = useUser({
    userAccountId: data.program?.userAccountId,
    onError,
  });

  const { isBusinessLocationLoading, businessLocation } = useBusinessLocation({
    businessLocationId: data.program?.scenarioParameters?.businessLocationId,
    onError,
  });

  const mutationProgram = useMutation(async ({ newProgram, updateInitialProgram }: {
    newProgram: ApiCropLogic,
    updateInitialProgram: boolean,
  }) => {
    const { isFieldsValid, scenarioParamsValid } = CropLogicUtilities.validateCropLogic(
      newProgram,
      false,
      null,
    );

    const isValid = isFieldsValid && scenarioParamsValid && newProgram.passes.length > 0;

    if (isValid && updateInitialProgram) {
      if (!newProgram.id) {
        newProgram = await CropLogicApi.createCropLogic(newProgram);
        onCreate?.();
      } else {
        newProgram = await CropLogicApi.updateCropLogic(newProgram.id, newProgram);
        onUpdate?.();
      }
    }

    queryClient.setQueryData(queryKey, {
      initialProgram: updateInitialProgram ? newProgram : data.initialProgram,
      isProgramSaved: updateInitialProgram || _.isEqual(newProgram, data.initialProgram),
      isProgramValid: isValid,
      program: newProgram,
    });
  }, {
    onError: () => onError?.(),
  });

  const setProgram = useCallback((
    newProgram: ApiCropLogic,
    updateInitialProgram = false,
  ) => {
    mutationProgram.mutate({ newProgram, updateInitialProgram });
  }, [mutationProgram]);

  const isLoading = isBusinessLocationLoading
    || isPriceTypeLoading
    || isProgramLoading
    || isUserLoading;

  return {
    ...data,
    businessLocation,
    canEditAccount,
    isLoading,
    isOwner,
    priceType,
    setProgram,
    user,
  };
}
