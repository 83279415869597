import { Spinner, SpinnerProps } from '@ui-kitten/components';
import React, { FunctionComponent } from 'react';
import { View, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  centeredSpinnerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  absoluteCenteredSpinnerWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
});

export interface CenteredSpinnerProps extends SpinnerProps {
  isFixed?: boolean;
}

export const CenteredSpinner: FunctionComponent<CenteredSpinnerProps> = (props) => (
  <View
    style={props.isFixed ? styles.absoluteCenteredSpinnerWrapper : styles.centeredSpinnerWrapper}
  >
    <Spinner {...props} />
  </View>
);
