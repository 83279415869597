import {
  Button,
  HSpacer,
  Modal,
  Text,
} from '@design';
import { CostType, DiscountType } from '@shared/enums';
import { useBusinessDiscounts } from '../../../../hooks/useBusinessDiscounts';
import React, { FunctionComponent, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DiscountModalTitle } from '../../../../constants';
import {
  OrderDetailsDiscountModal,
} from '../../../components/shared/FarmPlan/OrderDetailsDiscountModal';
import { DemoBlock, DemoContainer } from '../components/DemoBlock';

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: '#000',
    opacity: 0.7,
  },
  demoContainer: {
    margin: 64,
    padding: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    width: 80,
    marginLeft: 4,
    marginRight: 4,
  },
});

export const ModalDemo: FunctionComponent = () => {
  const { businessDiscountsList } = useBusinessDiscounts();

  const [completeVisible, setCompleteVisible] = useState(false);
  const [sansTitleVisible, setSansTitleVisible] = useState(false);
  const [sansButtonsVisible, setSansButtonsVisible] = useState(false);
  const [onlyExitButtonVisible, setOnlyExitButtonVisible] = useState(false);

  const [addOrderDiscountModalVisible, setAddOrderDiscountModalVisible] = useState(false);
  const [addSkuDiscountModalVisible, setAddSkuDiscountModalVisible] = useState(false);

  const testDiscounts = [{
    costType: CostType.TOTAL_COST,
    createdAt: new Date(),
    discountType: DiscountType.DOLLARS,
    dollars: 500,
    id: 'discount-id',
    isActive: true,
    name: 'test',
    percent: null,
    updatedAt: new Date(),
  }];
  const testProductId = '123';

  return (
    <View>
      <DemoContainer>
        <DemoBlock>
          <Button
            onPress={() => setAddOrderDiscountModalVisible(!addOrderDiscountModalVisible)}
            size="small"
            testID="test-button"
          >
            Add Order Discount
          </Button>
          {addOrderDiscountModalVisible && (
            <OrderDetailsDiscountModal
              businessDiscountsList={businessDiscountsList}
              discountSkuEditActionTitle={DiscountModalTitle.ADD_ORDER_DISCOUNT}
              discounts={testDiscounts}
              onApply={() => {
                setAddOrderDiscountModalVisible(false);
              }}
              onClose={() => setAddOrderDiscountModalVisible(false)}
            />
          )}
        </DemoBlock>
        <DemoBlock>
          <Button
            onPress={() => setAddSkuDiscountModalVisible(!addSkuDiscountModalVisible)}
            size="small"
            testID="test-button"
          >
            Add SKU discount
          </Button>
          {addSkuDiscountModalVisible && (
            <OrderDetailsDiscountModal
              businessDiscountsList={businessDiscountsList}
              discountSkuEditActionTitle={DiscountModalTitle.ADD_SKU_DISCOUNT}
              discounts={testDiscounts}
              onApply={(newDiscount) => {
                // eslint-disable-next-line no-console
                console.log('New discount:', newDiscount);
                setAddSkuDiscountModalVisible(false);
              }}
              onClose={() => setAddSkuDiscountModalVisible(false)}
              productId={testProductId}
            />
          )}
        </DemoBlock>
        <DemoBlock>
          <Button onPress={() => setCompleteVisible(!completeVisible)} size="small" testID="test-button">
            All Features
          </Button>
          <Modal
            backdropStyle={styles.backdrop}
            footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
              <>
                <Button {...primaryButtonProp} testID="test-button">Cancel</Button>
                <HSpacer {...spacerProp} />
                <Button {...secondaryButtonProp} testID="test-button">Cancel</Button>
              </>
            )}
            onClose={() => setCompleteVisible(false)}
            subTitle="Subtitle"
            testID="modal-test"
            title="Title"
            visible={completeVisible}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Modal>
        </DemoBlock>
        <DemoBlock>
          <Button
            onPress={() => setSansTitleVisible(!sansTitleVisible)}
            size="small"
            testID="test-button"
          >
            All Features Except Title
          </Button>
          <Modal
            backdropStyle={styles.backdrop}
            footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
              <>
                <Button {...primaryButtonProp} testID="test-button">Cancel</Button>
                <HSpacer {...spacerProp} />
                <Button {...secondaryButtonProp} testID="test-button">Cancel</Button>
              </>
            )}
            onClose={() => setSansTitleVisible(false)}
            testID="modal-test"
            visible={sansTitleVisible}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Modal>
        </DemoBlock>
        <DemoBlock>
          <Button onPress={() => setSansButtonsVisible(!sansButtonsVisible)} size="small" testID="test-button">All Features Except Buttons</Button>
          <Modal
            backdropStyle={styles.backdrop}
            onClose={() => setSansButtonsVisible(false)}
            subTitle="Subtitle"
            testID="modal-test"
            title="Title"
            visible={sansButtonsVisible}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Modal>
        </DemoBlock>
        <DemoBlock>
          <Button onPress={() => setOnlyExitButtonVisible(!onlyExitButtonVisible)} size="small" testID="test-button">Only Exit Button</Button>
          <Modal
            backdropStyle={styles.backdrop}
            onClose={() => setOnlyExitButtonVisible(false)}
            testID="modal-test"
            visible={onlyExitButtonVisible}
          >
            <Text>
              Bacon ipsum dolor amet hamburger sirloin short ribs fatback
              alcatra. Short ribs landjaeger pig shankle meatball venison
              flank swine boudin hamburger spare ribs. Rump boudin leberkas
              chuck strip steak filet mignon.
            </Text>
          </Modal>
        </DemoBlock>
      </DemoContainer>
    </View>
  );
};
