import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

export enum ScreenSizes {
  Wide,
  Medium,
  Narrow,
}

export const useScreenSizing = () => {
  const [sizeSetting, setSizeSetting] = useState(0);
  const screen = useWindowDimensions();
  let actualWidth = screen.width;
  if (screen.height < screen.width) {
    actualWidth = screen.height;
  }
  useEffect(() => {
    if (actualWidth < 320) {
      setSizeSetting(ScreenSizes.Narrow);
    } else if (actualWidth < 600) {
      setSizeSetting(ScreenSizes.Medium);
    } else {
      setSizeSetting(ScreenSizes.Wide);
    }
  }, [actualWidth, screen.width]);

  return sizeSetting;
};
