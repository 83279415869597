import React, { useEffect, useState } from 'react';
import { Button, HSpacer, Modal } from '@design';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { ApiPriceType } from '@shared/interfaces/api';
import { usePriceTypeList } from '../../../../hooks/usePriceTypeList';
import { PriceTypeSelect } from '../../../components/shared/Input/PriceTypeSelect';

interface PriceTypeDialogProps {
  onClose: () => void,
  onUpdatePriceType: (priceTypeId: string) => void,
  priceTypeId: string,
}

export const PriceTypeDialog = (props: PriceTypeDialogProps) => {
  const { onClose, onUpdatePriceType, priceTypeId } = props;
  const [translate] = useTranslation(['common', 'productOrders']);
  const { priceTypes } = usePriceTypeList();
  const [selectedPriceType, setSelectedPriceType] = useState<ApiPriceType>(null);

  useEffect(() => {
    const defaultPriceType = priceTypes.find((priceType) => priceType.id === priceTypeId);
    setSelectedPriceType(defaultPriceType);
  }, [priceTypeId, priceTypes]);

  const renderFooterAccessory = ({
    primaryButtonProp,
    secondaryButtonProp,
    spacerProp,
  }) => (
    <View style={{ flexDirection: 'row' }}>
      <Button
        {...primaryButtonProp}
        onPress={onClose}
        testID="price-type-modal-cancel-button"
      >
        {translate<string>('CANCEL')}
      </Button>
      <HSpacer {...spacerProp} />
      <Button
        {...secondaryButtonProp}
        onPress={() => {
          onUpdatePriceType(selectedPriceType.id);
          onClose();
        }}
        testID="price-type-modal-apply-button"
      >
        {translate<string>('APPLY')}
      </Button>
    </View>
  );

  return (
    <Modal
      footerAccessory={renderFooterAccessory}
      hideCloseButton
      testID="price-type-modal"
      title={translate('EDIT_PRICE_TYPE_TITLE')}
      visible
    >
      <PriceTypeSelect
        isRequired
        onUpdatePriceType={setSelectedPriceType}
        selectedPriceType={selectedPriceType}
      />
    </Modal>
  );
};
