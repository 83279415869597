import React, { Component } from 'react';
import { Icon as UIKIcon, IconProps as UIKIconProps, styled } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';
import { Size } from '@theme/variant-interfaces/Size';
import { Status } from '@theme/variant-interfaces/Status';
import { IconMap } from './IconMap';

export type IconName = keyof typeof IconMap;

export interface IconProps extends Omit<UIKIconProps, 'name' | 'pack'> {
  name: IconName,
  size?: Size,
  status?: Status,
  testID: string,
}

@styled('Icon')
export class Icon extends Component<IconProps> {
  public render () {
    const {
      name,
      eva,
      style,
      testID,
      ...iconProps
    } = this.props;

    const mappedIcon = IconMap[name];

    return (
      <UIKIcon
        name={mappedIcon.name}
        pack={mappedIcon.pack}
        {...iconProps}
        // it seems that the UIKIcon does not do well with arrays of styles, so
        // we will flatten for them.
        style={StyleSheet.flatten([
          eva.style,
          style,
        ])}
        testID={testID}
      />
    );
  }
}

export const IconNames = Object.keys(IconMap) as any as IconName[];
IconNames.sort();
