import { Button, Dialog, HSpacer, ModalSpinner, Text, useToast, VSpacer } from '@design';
import { ApiFarmPlan, ApiProductSummary } from '@shared/interfaces/api';
import { FarmUtility, Permissions, RoleUtility } from '@shared/utils';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys, Routes } from '../../../../../constants';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useFarmPlan } from '../../../../../hooks/useFarmPlan';
import { useHistory, useLocation, useParams } from '../../../../../router';
import { UserApi } from '../../../../../utilities/api';
import { AcresPlanned } from './AcresPlanned';
import { EditModeFooter } from './EditModeFooter';
import { FarmPlanFormDetails } from './FarmPlanFormDetails';
import { FarmPlanModal } from './FarmPlanModal';
import { FarmPlanProducts } from './FarmPlanProducts';
import { FarmPlanProfit } from './FarmPlanProfit';
import { FarmPlanProgramAssignment } from './FarmPlanProgramAssignment';
import { ViewModeFooter } from './ViewModeFooter';

const styles = StyleSheet.create({
  scrollView: {
    paddingBottom: 150 - 32, // 32 = wrapper padding bottom
  },
  list: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  card: {
    flex: 1,
  },
});

export enum Mode { VIEW, EDIT, CREATE }

export const FarmPlanFormPage = () => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  const location = useLocation();
  const [showCreateModal, setShowCreateModal] = useState(/create/.test(location.pathname));
  const [showCancelModal, setShowCancelModal] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();

  const { grower: customerId, id: farmPlanId } = useParams<{ grower?: string, id: string }>();

  const [mode, setMode] = useState(!farmPlanId ? Mode.CREATE : Mode.VIEW);

  const { user: currentUser } = useAuthentication();

  const { createToast } = useToast();

  const {
    businessLocation,
    customer,
    farmPlan,
    farmsWithPrograms,
    hasAcceptedOrders,
    initialFarmPlan,
    isFarmPlanSaved,
    isFarmPlanValid,
    isLoading,
    isLocked,
    priceType,
    programs,
    setFarmPlan,
    summaries,
    user,
  } = useFarmPlan({
    farmPlanId,
    defaultCustomerId: customerId,
    onCreate: async (newFarmPlan) => {
      history.push(Routes.FARM_PLAN_OVERVIEW
        .replace(/:grower/, newFarmPlan.growerId)
        .replace(/:id/, newFarmPlan.id));

      createToast({
        children: translate<string>('FARM_PLAN_CREATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
    },
    onError: () => createToast({
      children: translate<string>('UNEXPECTED_ERROR'),
      status: 'danger',
      testID: 'toast-content-element',
    }),
    onUpdate: () => {
      setMode(Mode.VIEW);
      createToast({
        children: translate<string>('FARM_PLAN_UPDATED'),
        status: 'success',
        testID: 'toast-content-element',
      });
    },
    useUserInputsForSummaries: false,
  });

  const handleFarmPlanChange = (plan: ApiFarmPlan) => {
    const {
      growerFarmPlanProductSummaries,
    } = FarmUtility.createFarmPlanSummary(
      farmsWithPrograms,
      plan.id,
      plan.priceTypeId,
      plan.businessLocationId,
      plan.growerFarmPlanProductSummaries,
    );
    plan.growerFarmPlanProductSummaries = growerFarmPlanProductSummaries as ApiProductSummary[];
    setFarmPlan(plan);
  };

  const goToFarmPlan = useCallback(() => {
    history.push(!customerId
      ? Routes.PREPARE_REVIEW.replace(/:tab/, 'farm-plans')
      : Routes.GROWER_DETAILS
        .replace(/:grower/, customerId)
        .replace(/:tab/, 'farm-plans'));
  }, [history, customerId]);

  const goBack = useCallback(async () => {
    await queryClient.invalidateQueries(QueryKeys.FARM_PLAN_LIST);

    goToFarmPlan();
  }, [goToFarmPlan, queryClient]);

  const { data: growerAssignments } = useQuery(
    [QueryKeys.GROWER_ASSIGNMENTS],
    () => UserApi.getGrowerAssignments(),
    {
      onError: () => createToast({
        status: 'warning',
        children: translate<string>('UNEXPECTED_ERROR'),
        testID: 'toast-content-element',
      }),
    },
  );

  const canEditFarmPlan = !hasAcceptedOrders && (
    RoleUtility.roleHasPermission(currentUser.userRole, Permissions.MODIFY_OTHER_OWNER)
    || growerAssignments?.includes(farmPlan.growerId)
  );
  const viewMode = (mode === Mode.VIEW || !canEditFarmPlan) && isFarmPlanSaved;

  const goToOrderDetails = useCallback(() => {
    history.push(Routes.FARM_PLAN_OVERVIEW
      .replace(/:grower/, farmPlan.growerId)
      .replace(/:id/, farmPlan.id), viewMode && { previous: location.pathname });
  }, [history, farmPlan, location.pathname, viewMode]);

  return isLocked ? (
    <ModalSpinner visible />
  ) : (
    <>
      {isLoading && <ModalSpinner visible />}
      {showCreateModal ? (
        <FarmPlanModal
          editMode={mode === Mode.EDIT}
          farmPlan={farmPlan}
          onCancel={(oldFarmPlan) => {
            setShowCreateModal(false);
            setFarmPlan(oldFarmPlan);

            if (mode === Mode.CREATE && _.isEqual(initialFarmPlan, oldFarmPlan)) {
              goToFarmPlan();
            }
          }}
          onFarmPlanChange={handleFarmPlanChange}
          onSave={() => {
            handleFarmPlanChange(farmPlan);
            setShowCreateModal(false);
          }}
          plannedFarms={farmsWithPrograms}
          priceType={priceType}
          readonlyCustomer={!!customerId}
        />
      ) : (
        <>
          <Dialog
            footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
              <>
                <Button {...primaryButtonProp} testID="confirmation-modal-cancel-button">{translate<string>('CANCEL')}</Button>
                <HSpacer {...spacerProp} />
                <Button
                  {...secondaryButtonProp}
                  onPress={mode === Mode.CREATE ? goBack : () => {
                    setFarmPlan(initialFarmPlan, false, null);
                    setMode(Mode.VIEW);
                    setShowCancelModal(false);
                  }}
                  testID="confirmation-modal-confirm-button"
                >
                  {translate<string>(mode === Mode.CREATE ? 'YES_DISCARD_FARM_PLAN' : 'YES_DISCARD_CHANGES')}
                </Button>
              </>
            )}
            onClose={() => setShowCancelModal(false)}
            status="warning"
            testID="confirmation-modal"
            title={mode === Mode.CREATE ? translate('DISCARD_FARM_PLAN') : translate('DISCARD_CHANGES')}
            visible={showCancelModal}
          >
            <Text>
              {mode === Mode.CREATE ? translate<string>('FARM_PLAN_NOT_CREATED') : ''}
            </Text>
          </Dialog>
          <ScrollView style={styles.scrollView} testID="farm-plan-form-view">
            <Text appearance="hint" category="overline">
              {translate<string>('FARM_PLAN')}
            </Text>
            <Text category="h3" testID="farm-plan-order-name">
              {farmPlan.planName}
            </Text>
            <VSpacer size="8" />
            <FarmPlanFormDetails
              customer={customer}
              farmPlan={farmPlan}
              farmPlanLocation={businessLocation}
              location={location}
              onPress={() => setShowCreateModal(true)}
              priceType={priceType}
              userData={user}
            />
            <VSpacer size="8" />
            <View style={styles.list}>
              <FarmPlanProfit
                customerId={customer?.id}
                locationId={businessLocation?.id}
                priceTypeId={farmPlan.priceTypeId}
                products={summaries.products}
                profit={summaries.profit}
              />
              <HSpacer size="7" />
              <FarmPlanProducts
                locationId={farmPlan.businessLocationId}
                priceTypeId={farmPlan.priceTypeId}
                products={summaries.products}
              />
              <HSpacer size="7" />
              <AcresPlanned acresPlanned={summaries.acresPlanned} />
            </View>
            <VSpacer size="10" />
            <FarmPlanProgramAssignment
              createMode={mode === Mode.CREATE}
              cropLogics={programs}
              farmPlan={farmPlan}
              plannedFarms={farmsWithPrograms}
              planningParameters={customer?.planningParameters ?? []}
              setFarmPlan={setFarmPlan}
            />
          </ScrollView>
          {viewMode ? (
            <ViewModeFooter
              disabled={!canEditFarmPlan || !isFarmPlanSaved}
              onBack={goBack}
              onViewOrderDetails={goToOrderDetails}
            />
          ) : (
            <EditModeFooter
              createMode={mode === Mode.CREATE}
              disabled={
                !isFarmPlanValid
                || isFarmPlanSaved
                || isLoading
              }
              onCancel={() => setShowCancelModal(true)}
              onSave={() => setFarmPlan(farmPlan, true)}
            />
          )}
        </>
      )}
    </>
  );
};
