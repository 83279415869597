import { Button, Dialog, HSpacer, Text } from '@design';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { testId } from '../../../../../utilities';
import { BusinessLocationModal } from './BusinessLocationModal';

const styles = StyleSheet.create({
  title: {
    marginRight: 32 - 8, // 32 - Dialog paddingRight
  },
});

export interface EditBusinessLocationDialogProps {
  visible: boolean,
  locationId: string | null,
  onUpdateLocation(locationId: string | null): void,
  onCancel(): void,
}
export function EditBusinessLocationDialog ({
  locationId,
  visible,
  onUpdateLocation,
  onCancel,
}: EditBusinessLocationDialogProps) {
  const [translate] = useTranslation(['common', 'productOrders', 'errors']);
  const [businessLocationVisible, setBusinessLocationVisible] = useState(false);

  return businessLocationVisible ? (
    <BusinessLocationModal
      {...testId('edit-business-location-dialog-modal')}
      initialLocationId={locationId}
      onCancel={() => {
        setBusinessLocationVisible(false);
        onCancel();
      }}
      onUpdateLocation={async (newLocationId) => {
        await onUpdateLocation(newLocationId);
        setBusinessLocationVisible(false);
      }}
    />
  ) : (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button
            {...testId('edit-business-location-dialog-cancel')}
            {...primaryButtonProp}
            appearance="outline"
            status="basic"
          >
            {translate<string>('CANCEL')}
          </Button>
          <HSpacer {...spacerProp} />
          <Button
            {...testId('edit-business-location-dialog-continue')}
            {...secondaryButtonProp}
            onPress={() => setBusinessLocationVisible(true)}
          >
            {translate<string>('CONTINUE_EDITING')}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="edit-business-location-dialog"
      visible={visible}
    >
      <Text category="p1" status="basic" style={styles.title}>
        {translate<string>('EDIT_BUSINESS_LOCATION_DIALOG_DESCRIPTION')}
      </Text>
    </Dialog>
  );
}
