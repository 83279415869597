import { Icon } from '@design';
import { AreaUnitType } from '@shared/enums';
import { FarmPlanDeliverableV0 } from '@shared/interfaces';
import * as _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Sizing, ThemeColors } from '../../../../constants';
import { ModalUtility } from '../../../../elements/content';
import { SortableTable } from '../../../../elements/table';
import { useHistory } from '../../../../router';
import { Button, Card, CenteredSpinner, Text } from '../../../../ui-components';
import { Environment, LinkingUtility, StringUtility } from '../../../../utilities';
import { testId } from '../../../../utilities/testId';
import {
  FarmPlanAcresPlannedSummary,
  FarmPlanAcresPlannedSummaryResult,
} from '../../../FarmPlan/FarmPlanAcresPlannedSummary';
import { ShowNotes } from '../../ShowNotes';
import { FarmPlanDeliverablePerFieldSummary } from './FarmPlanDeliverablePerFieldSummary';
import {
  FarmPlanDeliverableProductSummaryQuickView,
} from './FarmPlanDeliverableProductSummaryQuickView';

interface Props {
  name?: string;
  note?: string;
  selections: FarmPlanDeliverableV0.Selections;
  snapshot: FarmPlanDeliverableV0.Snapshot;
  deliverableId: string;
}

const styles = StyleSheet.create({
  base: {
    flex: 1,
    overflow: 'hidden',
  },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  flexRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  overviewHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  notesContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 2 * Sizing.DOUBLE_SPACING,
  },
  notesRowsContainer: {
    flex: 1,
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  overviewHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
    marginRight: 1.2 * Sizing.BASE_SPACING,
  },
  header: {
    flex: 1,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  subHeader: {
    fontSize: 1.5 * Sizing.BASE_SPACING,
    marginRight: Sizing.BASE_SPACING,
  },
  showMore: {
    color: ThemeColors.PRIMARY,
    marginTop: Sizing.HALF_SPACING,
    textDecorationLine: 'none',
    fontSize: Sizing.BASE_SPACING,
    marginRight: Sizing.BASE_SPACING,
  },
  icon: {
    width: 1.25 * Sizing.EM,
    height: 1.25 * Sizing.EM,
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  summaryCard: {
    flex: 1,
    borderRadius: Sizing.HALF_SPACING,
    backgroundColor: ThemeColors.DARK_GRAY,
    marginRight: Sizing.BASE_SPACING,
  },
  summaryCardHeader: {
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  summaryCardRow: {
    flexDirection: 'row',
    borderTopColor: ThemeColors.MID_GRAY,
    borderTopWidth: 1,
    padding: Sizing.BASE_SPACING,
  },
  quickViewSubsection: {
    margin: Sizing.BASE_SPACING,
    minWidth: '30%',
  },
  quickViewWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  quickViewInfoHeader: { fontWeight: 'bold' },
  summaryTableHeader: {
    fontSize: 1.1 * Sizing.EM,
    fontWeight: 'bold',
    marginBottom: 2 * Sizing.BASE_SPACING,
    paddingLeft: 4 * Sizing.BASE_SPACING,
  },
  summaryTableText: {
    fontSize: 1.1 * Sizing.EM,
    marginBottom: Sizing.BASE_SPACING,
    paddingLeft: 6 * Sizing.BASE_SPACING,
  },
  simulatedTableRow: {
    flexDirection: 'row',
    marginHorizontal: 32,
  },
  summaryTable: {
    marginTop: Sizing.DOUBLE_SPACING,
    paddingTop: Sizing.DOUBLE_SPACING,
    borderBottomLeftRadius: Sizing.BASE_SPACING,
    borderBottomRightRadius: Sizing.BASE_SPACING,
    backgroundColor: ThemeColors.LIGHT_BLACK,
  },
});

const InfoModal: FunctionComponent = () => {
  const [translate] = useTranslation(['farmPlans']);

  return (
    <View nativeID="info-modal">
      <Card disabled testID="info-modal-card">
        <Text style={[styles.largeText, { textAlign: 'center' }]}>
          {translate('DISCOUNT_INFO_MESSAGE_1') as string}
        </Text>
        <Text style={[styles.largeText, { textAlign: 'center' }]}>
          {translate('DISCOUNT_INFO_MESSAGE_2') as string}
        </Text>
      </Card>
    </View>
  );
};

const COST_PER_ACRE_WIDTH = 0.75;
const TOTAL_COST_WIDTH = 0.75;

type LocalOverview = {
  [key in keyof FarmPlanDeliverableV0.Snapshot['OVERVIEW']]: any
};

const INITIAL_NUMBER_OF_ROWS = 3;

const centsOrFlat = (value: number) => {
  if (Math.abs(value) < 1) {
    return StringUtility.formatCurrencyAccounting(value);
  }
  return StringUtility.formatFlatCurrencyAccounting(value);
};

export const FarmPlanDeliverableDesktopView: FunctionComponent<Props> = ({
  name, snapshot, selections, note, deliverableId,
}) => {
  const [translate] = useTranslation(['deliverable', 'farmPlans', 'growers', 'prepare', 'common']);
  const history = useHistory();
  const [productHeaders, setProductHeaders] = useState([]);
  const [productRows, setProductRows] = useState([]);
  const [numberOfRows, setNumbersOfRows] = useState(INITIAL_NUMBER_OF_ROWS);
  const [showMore, setShowMore] = useState(false);

  const [productWidths, setProductWidths] = useState<number[]>([]);
  const [preCostWidths, setPreCostWidths] = useState(0);
  const [postCostWidths, setPostCostWidths] = useState(0);

  const [planSummary, setPlanSummary] = useState([]);
  const [passSummary, setPassSummary] = useState([]);
  const [planHeader, setPlanHeader] = useState({} as any);
  const [planOverview, setPlanOverview] = useState<LocalOverview>({} as any);

  const showField = (fname: string) => _.get(selections, `${fname}.enabled`) === true;

  const showGroup = (gname: string) => _.some(_.keys(_.get(selections, gname, {})), (key) => showField(`${gname}.${key}`));

  useEffect(() => {
    const extractData = (item) => _.reduce(
      item,
      (memo, value, key) => _.assignIn(memo, { [key]: value.data }),
      {},
    );

    const extractArrayData = (data: any, sname: string) => _.reduce(
      _.get(data, sname, []),
      (memo, item) => {
        memo.push(extractData(item));
        return memo;
      },
      [],
    );

    setPlanSummary(extractArrayData(snapshot, 'PRODUCT_SUMMARY'));
    setPassSummary(extractArrayData(snapshot, 'PER_FIELD_OR_CROP_ZONE_SUMMARY'));
    setPlanHeader(extractData(_.get(snapshot, 'HEADER', {})));
    setPlanOverview(extractData(_.get(snapshot, 'OVERVIEW', {})) as LocalOverview);
  }, [snapshot]);

  const PlanQuickView = ({ data }) => (

    <FarmPlanDeliverableProductSummaryQuickView
      acresApplied={data.ACRES_APPLIED}
      avgRateApplied={data.AVERAGE_RATE_APPLIED}
      avgRateAppliedUom={data.AVERAGE_RATE_APPLIED_UOM}
      productSkuPackagesRequired={data.PRODUCT_SKU_PACKAGES_REQUIRED}
      productSkuPrice={data.PRODUCT_SKU_PRICE}
      productUnitsRequired={data.PRODUCT_UNITS_REQUIRED}
      retailUnitPrice={data.RETAIL_UNIT_PRICE}
      selections={selections}
    />
  );

  useEffect(() => {
    const showColumn = (cname: string) => _.get(selections, `${cname}.enabled`) === true;

    setProductHeaders(_.compact([
      showColumn('PRODUCT_SUMMARY.PRODUCT_SKU_NAME') ? {
        label: translate('PRODUCT_SKU_NAME'),
        field: 'product.skuName',
      } : null,
      showColumn('PRODUCT_SUMMARY.PRODUCT_SKU_PACKAGES_CONTRACTED') ? {
        label: translate('PACKAGES_CONTRACTED'),
        field: 'productSkuPackagesContracted',
      } : null,
      showColumn('PRODUCT_SUMMARY.UNIT_PRICE') ? {
        label: translate('UNIT_PRICE'),
        field: 'unitPrice',
      } : null,
      showColumn('PRODUCT_SUMMARY.PRICE_LOCK') ? {
        label: '',
        field: 'locked',
      } : null,
      showColumn('PRODUCT_SUMMARY.COST_PER_ACRE') ? {
        label: translate('COST_PER_AC'),
        field: 'costPerAcre',
      } : null,
      showColumn('PRODUCT_SUMMARY.TOTAL_COST') ? {
        label: translate('TOTAL_COST'),
        field: 'totalCost',
      } : null,
      showColumn('PRODUCT_SUMMARY.PLAN_STATUS') ? {
        label: translate('PLAN_STATUS'),
        field: 'planStatus',
      } : null,
    ]));

    const widths: number[] = [];
    if (showColumn('PRODUCT_SUMMARY.PRODUCT_SKU_NAME')) { widths.push(1.5); }
    if (showColumn('PRODUCT_SUMMARY.PRODUCT_SKU_PACKAGES_CONTRACTED')) { widths.push(1.5); }
    if (showColumn('PRODUCT_SUMMARY.UNIT_PRICE')) { widths.push(0.75); }
    if (showColumn('PRODUCT_SUMMARY.PRICE_LOCK')) { widths.push(0.5); }
    const preCostSum = widths.reduce((sum, w) => sum + w, 0);
    if (showColumn('PRODUCT_SUMMARY.COST_PER_ACRE')) { widths.push(COST_PER_ACRE_WIDTH); }
    if (showColumn('PRODUCT_SUMMARY.TOTAL_COST')) { widths.push(TOTAL_COST_WIDTH); }
    const withCostSum = widths.reduce((sum, w) => sum + w, 0);
    if (showColumn('PRODUCT_SUMMARY.PLAN_STATUS')) { widths.push(1.25); }
    const postCostSum = widths.reduce((sum, w) => sum + w, 0) - withCostSum;

    setProductWidths(widths);
    // 0.5 is a magic number for the drawer expander column
    setPreCostWidths(preCostSum + 0.5);
    setPostCostWidths(postCostSum);

    setShowMore(planSummary.length <= INITIAL_NUMBER_OF_ROWS);

    setProductRows(planSummary.map((data, i) => ({
      key: `${data.PRODUCT_SKU_NAME}-${i}`,
      columns: _.compact([
        showColumn('PRODUCT_SUMMARY.PRODUCT_SKU_NAME') ? (
          <Text
            {...testId(`${data.PRODUCT_SKU_NAME}`)}
            numberOfLines={1}
            style={styles.largeText}
          >
            {data.PRODUCT_SKU_NAME}
          </Text>
        ) : null,
        showColumn('PRODUCT_SUMMARY.PRODUCT_SKU_PACKAGES_CONTRACTED') ? (
          <Text
            {...testId(`${data.PRODUCT_SKU_NAME}-sku-packages-contracted`)}
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatDecimal(data.PRODUCT_SKU_PACKAGES_CONTRACTED, 2)}
          </Text>
        ) : null,
        showColumn('PRODUCT_SUMMARY.UNIT_PRICE') ? (
          <Text
            {...testId(`${data.PRODUCT_SKU_NAME}-unit-price`)}
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatCurrency(data.UNIT_PRICE)}
          </Text>
        ) : null,
        showColumn('PRODUCT_SUMMARY.PRICE_LOCK') ? (
          <View>
            {data.PRICE_LOCK && <Icon fill={ThemeColors.TEXT} name="Lock" size="large" testID={`${data.PRODUCT_SKU_NAME}-lock-icon`} />}
            {!data.PRICE_LOCK && <Icon fill={ThemeColors.SECONDARY} name="GrowersUnlock" size="large" testID={`${data.PRODUCT_SKU_NAME}-unlock-icon`} />}
          </View>
        ) : null,
        showColumn('PRODUCT_SUMMARY.COST_PER_ACRE') ? (
          <Text
            {...testId(`${data.PRODUCT_SKU_NAME}-cost-per-acre`)}
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatCurrencyAccounting(data.COST_PER_ACRE)}
          </Text>
        ) : null,
        showColumn('PRODUCT_SUMMARY.TOTAL_COST') ? (
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatFlatCurrency(data.TOTAL_COST)}
          </Text>
        ) : null,
        showColumn('PRODUCT_SUMMARY.PLAN_STATUS') ? (
          <Text
            {...testId(`${data.PRODUCT_SKU_NAME}-plan-status`)}
            numberOfLines={1}
            style={styles.largeText}
          >
            {translate(data.PLAN_STATUS)}
          </Text>
        ) : null,
      ]),
      drawer: <PlanQuickView data={data} />,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, planSummary, passSummary, translate, selections]);

  const PlannedAreas = () => {
    const acresPlannedSummary: FarmPlanAcresPlannedSummaryResult = ({
      planned: planOverview.ACRES_PLANNED,
      total: planHeader.TOTAL_GROWER_ACRES,
      areaUnitType: AreaUnitType.Acre,
      passes: planOverview.NUMBER_OF_PASSES,
      crops: _.map(planOverview.ACRES_PLANNED_PER_CROP, (areaValue, typePlusSubtype) => ({
        // this is a little jenky.
        // typePlusSubtype is a key in an object that is the end readable type | subtype value
        // so this works, but only because this will attempt to be translated but the key won't
        // exist and just display the key.
        // fixing this would require changing the snapshot structure to store more data about each
        // crop as an array of objects rather than an object of typePlusSubtype: areaValue
        type: typePlusSubtype,
        areaValue,
        percent: Math.floor((areaValue / planOverview.ACRES_PLANNED) * 100),
      })),
    });

    return (
      <FarmPlanAcresPlannedSummary
        planned={acresPlannedSummary}
        showField={{
          acresPlannedPerCrop: showField('OVERVIEW.ACRES_PLANNED_PER_CROP'),
          passes: true,
        }}
      />
    );
  };

  const showMoreHandler = () => {
    if (!showMore) {
      setNumbersOfRows(productRows.length);
    } else {
      setNumbersOfRows(INITIAL_NUMBER_OF_ROWS);
    }
    setShowMore(!showMore);
  };

  if (!selections) {
    return <CenteredSpinner size="giant" />;
  }

  const showRevenue = (
    selections.OVERVIEW.EXPECTED_REVENUE?.enabled || selections.OVERVIEW.REVENUE_PER_ACRE?.enabled
  );
  const showProfit = (
    selections.OVERVIEW.EXPECTED_PROFIT?.enabled || selections.OVERVIEW.PROFIT_PER_ACRE?.enabled
  );
  const showOrderTotal = (
    (
      selections.PRODUCT_SUMMARY.TOTAL_COST?.enabled
      || selections.PRODUCT_SUMMARY.COST_PER_ACRE?.enabled
    )
    && (
      selections.OVERVIEW.ORDER_TOTAL?.enabled
      || selections.OVERVIEW.ORDER_TOTAL_PER_ACRE?.enabled
    )
  );
  const hasDiscountNonZero = (
    (selections.OVERVIEW.DISCOUNT_TOTAL?.enabled || selections.OVERVIEW.DISCOUNT_PER_ACRE?.enabled)
    && (!!snapshot.OVERVIEW.DISCOUNT_TOTAL?.data || !!snapshot.OVERVIEW.DISCOUNT_PER_ACRE?.data)
  );
  const hasProductCost = (
    selections.OVERVIEW.PRODUCT_COST?.enabled
    || selections.OVERVIEW.PRODUCT_COST_PER_ACRE?.enabled
  );
  const showSubtotal = hasProductCost && (!showOrderTotal || hasDiscountNonZero);
  const subtotalIsCost = showSubtotal && !showOrderTotal && !hasDiscountNonZero;

  const canShowMore = productRows.length > INITIAL_NUMBER_OF_ROWS;

  const TotalFooter = () => (
    <View style={styles.simulatedTableRow}>
      <View style={{ flex: preCostWidths }}>
        <Text style={styles.summaryTableHeader}>{translate('TOTAL')}</Text>
      </View>
      {selections.PRODUCT_SUMMARY.COST_PER_ACRE?.enabled && (
        <View style={{ flex: COST_PER_ACRE_WIDTH }}>
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatCurrencyAccounting((
              planOverview.ORDER_TOTAL_PER_ACRE ?? planOverview.PRODUCT_COST_PER_ACRE
            ))}
          </Text>
        </View>
      )}
      {selections.PRODUCT_SUMMARY.TOTAL_COST?.enabled && (
        <View style={{ flex: TOTAL_COST_WIDTH }}>
          <Text
            numberOfLines={1}
            style={styles.largeText}
          >
            {StringUtility.formatFlatCurrencyAccounting((
              planOverview.ORDER_TOTAL ?? planOverview.PRODUCT_COST
            ))}
          </Text>
        </View>
      )}
      <View style={{ flex: postCostWidths }} />
    </View>
  );

  const SubTotalHeader = () => showSubtotal && !subtotalIsCost && (
    <View style={styles.simulatedTableRow}>
      <View style={{ flex: preCostWidths }}>
        <Text style={styles.summaryTableHeader}>{translate('SUBTOTAL')}</Text>
      </View>
      {selections.PRODUCT_SUMMARY.COST_PER_ACRE?.enabled && (
        <View style={{ flex: COST_PER_ACRE_WIDTH }}>
          <Text
            numberOfLines={1}
            style={[styles.largeText, { textDecorationLine: 'line-through' }]}
          >
            {StringUtility.formatCurrencyAccounting(planOverview.PRODUCT_COST_PER_ACRE)}
          </Text>
        </View>
      )}
      {selections.PRODUCT_SUMMARY.TOTAL_COST?.enabled && (
        <View style={{ flex: TOTAL_COST_WIDTH }}>
          <Text
            numberOfLines={1}
            style={[styles.largeText, { textDecorationLine: 'line-through' }]}
          >
            {StringUtility.formatFlatCurrencyAccounting(planOverview.PRODUCT_COST)}
          </Text>
        </View>
      )}
      <View style={{ flex: postCostWidths }} />
    </View>
  );

  const Discounts = () => hasDiscountNonZero && (
    <>
      <View style={styles.simulatedTableRow}>
        <Text style={[styles.summaryTableHeader, { marginRight: Sizing.HALF_SPACING }]}>
          {translate('DISCOUNTS')}
        </Text>
        {/* Functionality for info icon in ST-2833 */}
        <TouchableOpacity onPress={() => ModalUtility.show(<InfoModal />)}>
          <Icon
            fill={ThemeColors.SECONDARY}
            name="Info"
            style={styles.icon}
            testID="info-icon"
          />
        </TouchableOpacity>
      </View>
      {snapshot.OVERVIEW.DISCOUNTS.data.map((discount) => (
        <View key={discount.NAME} style={styles.simulatedTableRow}>
          <View style={{ flex: preCostWidths, flexDirection: 'row' }}>
            <View style={{ flex: 3 }}>
              <Text numberOfLines={1} style={styles.summaryTableText}>
                {discount.NAME}
              </Text>
            </View>
            <View style={{ flex: 1, marginLeft: 2 * Sizing.DOUBLE_SPACING }}>
              <Text style={[styles.largeText, { color: ThemeColors.DISCOUNT }]}>
                {translate('PERCENT_OFF', { percent: discount.PERCENT })}
              </Text>
            </View>
          </View>
          {selections.PRODUCT_SUMMARY.COST_PER_ACRE?.enabled && (
            <View style={[{ flex: COST_PER_ACRE_WIDTH }]}>
              <Text
                numberOfLines={1}
                style={styles.largeText}
              >
                {StringUtility.formatDiscount(discount.DOLLARS_PER_ACRE)}
              </Text>
            </View>
          )}
          {selections.PRODUCT_SUMMARY.TOTAL_COST?.enabled && (
            <View style={{ flex: TOTAL_COST_WIDTH }}>
              <Text
                numberOfLines={1}
                style={styles.largeText}
              >
                {StringUtility.formatFlatDiscount(discount.DOLLARS)}
              </Text>
            </View>
          )}
          <View style={{ flex: postCostWidths }} />
        </View>
      ))}
    </>
  );

  return (
    <View
      style={{
        flex: 1,
        marginTop: Sizing.HALF_SPACING,
      }}
    >
      {snapshot && (
        <View style={[styles.headerContainer, { marginBottom: Sizing.BASE_SPACING }]}>
          <View style={[styles.flexLeft, { flex: 1 }]}>
            <Text
              numberOfLines={1}
              style={styles.header}
            >
              {name || `${translate('FARM_PLAN_DELIVERABLE')}: `}
            </Text>
          </View>
          <View style={styles.flexRight}>
            <Button
              {...testId('download-pdf')}
              iconLeft="download-outline"
              onPress={() => LinkingUtility.openUrl(
                `${Environment.get('API_URL')}/growerDeliverables/public/${deliverableId}/pdf`,
                { newTab: true },
              )}
              status="secondary"
            >
              {translate('DOWNLOAD_PDF')}
            </Button>
          </View>
        </View>
      )}
      <ScrollView nativeID="farm-plan-creation-view" style={styles.base}>
        <View style={{ flexDirection: 'row' }}>
          {showGroup('HEADER') && (
            <View style={styles.summaryCard}>
              {showField('HEADER.FARM_PLAN_NAME') && (
                <View style={{ padding: Sizing.BASE_SPACING }}>
                  <Text
                    {...testId('farm-plan-name')}
                    numberOfLines={1}
                    style={styles.summaryCardHeader}
                  >
                    {planHeader.FARM_PLAN_NAME}
                  </Text>
                </View>
              )}
              {showField('HEADER.GROWER_NAME') && (
                <View style={styles.summaryCardRow}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.TEXT}
                      name="GrowersPlanning"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="grower-icon"
                    />
                    <Text
                      {...testId('grower-name')}
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {planHeader.GROWER_NAME}
                    </Text>
                  </View>
                </View>
              )}
              {showField('HEADER.BUSINESS_NAME') && (
                <View style={styles.summaryCardRow}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.TEXT}
                      name="GrowersBusiness"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="business-icon"
                    />
                    <Text
                      {...testId('business-name')}
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {planHeader.BUSINESS_NAME}
                    </Text>
                  </View>
                </View>
              )}
              {showField('HEADER.BUSINESS_USER_NAME') && (
                <View style={styles.summaryCardRow}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.TEXT}
                      name="Person"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="user-icon"
                    />
                    <Text
                      {...testId('business-user-name')}
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {planHeader.BUSINESS_USER_NAME}
                    </Text>
                  </View>
                </View>
              )}
              {showField('HEADER.LOCATION_NAME') && !!planHeader.LOCATION_NAME && (
                <View style={styles.summaryCardRow}>
                  <View style={[styles.flexLeft, { flex: 1 }]}>
                    <Icon
                      fill={ThemeColors.TEXT}
                      name="Navigation2"
                      style={[styles.icon, { marginRight: Sizing.HALF_SPACING }]}
                      testID="location-icon"
                    />
                    <Text
                      {...testId('business-location-name')}
                      numberOfLines={1}
                      style={[styles.largeText, { marginRight: Sizing.BASE_SPACING }]}
                    >
                      {planHeader.LOCATION_NAME}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          )}
          <View style={styles.summaryCard}>
            <View
              style={{
                padding: Sizing.BASE_SPACING,
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text style={styles.summaryCardHeader}>
                {!!selections.OVERVIEW.DISCOUNTS?.enabled && (
                  translate('COST_SUMMARY')
                )}
                {!selections.OVERVIEW.DISCOUNTS?.enabled && (
                  translate('PROFIT_SUMMARY')
                )}
              </Text>
            </View>
            {(showRevenue) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>{translate('REVENUE')}</Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.OVERVIEW.REVENUE_PER_ACRE?.enabled && (
                    <Text nativeID="expected-revenue-per-acre" style={styles.largeText}>
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatCurrency(
                          snapshot.OVERVIEW.REVENUE_PER_ACRE.data,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.OVERVIEW.EXPECTED_REVENUE?.enabled && (
                    <Text nativeID="expected-revenue-total" style={styles.largeText}>
                      {centsOrFlat(snapshot.OVERVIEW.EXPECTED_REVENUE.data)}
                    </Text>
                  )}
                </View>
              </View>
            )}
            {(showSubtotal) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>
                    {subtotalIsCost ? translate('COST') : translate('SUBTOTAL')}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.OVERVIEW.PRODUCT_COST_PER_ACRE?.enabled && (
                    <Text
                      nativeID="expected-cost-per-acre"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        !subtotalIsCost && { textDecorationLine: 'line-through' },
                      ]}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatCurrencyAccounting(
                          // we are using PRODUCT_COST here because that is our only cost
                          // and EXPECTED_COST was turned off because it is redundant
                          snapshot.OVERVIEW.PRODUCT_COST_PER_ACRE.data,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.OVERVIEW.PRODUCT_COST?.enabled && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="expected-cost-total"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        !subtotalIsCost && { textDecorationLine: 'line-through' },
                      ]}
                    >
                      {centsOrFlat(
                        // we are using PRODUCT_COST here because that is our only cost
                        // and EXPECTED_COST was turned off because it is redundant
                        snapshot.OVERVIEW.PRODUCT_COST.data,
                      )}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {(hasDiscountNonZero) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>
                    {translate('DISCOUNTS')}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.OVERVIEW.DISCOUNT_PER_ACRE?.enabled && (
                    <Text
                      nativeID="discount-per-acre"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        {
                          color: snapshot.OVERVIEW.DISCOUNT_PER_ACRE.data > 0
                            ? ThemeColors.DISCOUNT : ThemeColors.PRIMARY,
                        },
                      ]}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatDiscount(
                          snapshot.OVERVIEW.DISCOUNT_PER_ACRE.data,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.OVERVIEW.DISCOUNT_TOTAL?.enabled && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="discount-total"
                      numberOfLines={1}
                      style={[
                        styles.largeText,
                        {
                          color: snapshot.OVERVIEW.DISCOUNT_TOTAL.data > 0
                            ? ThemeColors.DISCOUNT : ThemeColors.PRIMARY,
                        },
                      ]}
                    >
                      {StringUtility.formatFlatCurrencyAccounting(
                        -snapshot.OVERVIEW.DISCOUNT_TOTAL.data,
                        '-$',
                      )}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {(showOrderTotal) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={styles.largeText}>
                    {translate('TOTAL')}
                  </Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.OVERVIEW.ORDER_TOTAL_PER_ACRE?.enabled && (
                    <Text
                      nativeID="order-total-per-acre"
                      numberOfLines={1}
                      style={styles.largeText}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatCurrencyAccounting(
                          // we are using PRODUCT_COST here because that is our only cost
                          // and EXPECTED_COST was turned off because it is redundant
                          snapshot.OVERVIEW.ORDER_TOTAL_PER_ACRE.data,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.OVERVIEW.ORDER_TOTAL?.enabled && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="order-total"
                      numberOfLines={1}
                      style={styles.largeText}
                    >
                      {centsOrFlat(
                        // we are using PRODUCT_COST here because that is our only cost
                        // and EXPECTED_COST was turned off because it is redundant
                        snapshot.OVERVIEW.ORDER_TOTAL.data,
                      )}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {(showProfit) && (
              <View style={styles.summaryCardRow}>
                <View style={[styles.flexLeft, { flex: 1 }]}>
                  <Text style={[styles.largeText, { fontWeight: 'bold' }]}>{translate('PROFIT')}</Text>
                </View>
                <View style={[styles.flexRight, { flex: 1 }]}>
                  {selections.OVERVIEW.PROFIT_PER_ACRE?.enabled && (
                    <Text
                      nativeID="expected-profit-per-acre"
                      style={[styles.largeText, {
                        fontWeight: 'bold',
                        color: snapshot.OVERVIEW.PROFIT_PER_ACRE.data > 0
                          ? ThemeColors.PRIMARY : ThemeColors.DANGER,
                      }]}
                    >
                      {translate('PER_ACRE_SHORT', {
                        amount: StringUtility.formatCurrencyAccounting(
                          snapshot.OVERVIEW.PROFIT_PER_ACRE.data,
                        ),
                      })}
                    </Text>
                  )}
                </View>
                {selections.OVERVIEW.EXPECTED_PROFIT?.enabled && (
                  <View style={[styles.flexRight, { flex: 1 }]}>
                    <Text
                      nativeID="expected-profit-total"
                      style={[styles.largeText, {
                        fontWeight: 'bold',
                        color: snapshot.OVERVIEW.EXPECTED_PROFIT.data > 0
                          ? ThemeColors.PRIMARY : ThemeColors.DANGER,
                      }]}
                    >
                      {centsOrFlat(snapshot.OVERVIEW.EXPECTED_PROFIT.data)}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </View>

          {showField('OVERVIEW.ACRES_PLANNED') && (
            <View style={[styles.summaryCard, { marginRight: 0 }]}>
              <PlannedAreas />
            </View>
          )}
        </View>
        {showGroup('PRODUCT_SUMMARY') && (
          <Card style={{ marginTop: Sizing.BASE_SPACING }} testID="product-summary-card">
            <View>
              {!!note && (
                <View style={styles.notesContainer}>
                  <Text
                    numberOfLines={1}
                    style={[styles.overviewHeader, { marginBottom: Sizing.BASE_SPACING }]}
                  >
                    { translate('NOTES') as string }
                  </Text>
                  <View style={styles.notesRowsContainer}>
                    <ShowNotes note={note} />
                  </View>
                </View>
              )}
            </View>
            <View>
              <View style={styles.overviewHeaderContainer}>
                <Text
                  numberOfLines={1}
                  style={[styles.overviewHeader, { marginBottom: Sizing.BASE_SPACING }]}
                >
                  { translate('ORDER_DETAILS') as string }
                </Text>
                <Text
                  numberOfLines={1}
                  style={styles.subHeader}
                >
                  {productRows.length > 1
                    ? translate('PRODUCTS_COUNT', { productCount: productRows.length })
                    : translate('PRODUCT_COUNT_1', { productCount: productRows.length })}
                </Text>
                {canShowMore && (
                  <Text
                    {...testId('show-details-link')}
                    numberOfLines={1}
                    onPress={showMoreHandler}
                    style={styles.showMore}
                  >
                    {showMore ? translate('COLLAPSE_ALL') : translate('SHOW_DETAILS')}
                  </Text>
                )}
              </View>
            </View>
            <SortableTable
              headers={productHeaders}
              numberOfRows={numberOfRows}
              rows={productRows}
              widths={productWidths}
            />
            {canShowMore && !showMore && (
              <View {...testId('show-details-link')} style={{ alignItems: 'center', marginTop: Sizing.BASE_SPACING }}>
                <Text
                  onPress={showMoreHandler}
                  style={[styles.subHeader, styles.showMore]}
                >
                  {translate('MORE', { count: productRows.length - numberOfRows })}
                </Text>
              </View>
            )}
            {showOrderTotal && (
              <View style={styles.summaryTable}>
                {hasDiscountNonZero && showMore && (
                  <>
                    <SubTotalHeader />
                    <Discounts />
                  </>
                )}
                <TotalFooter />
              </View>
            )}
            {canShowMore && showMore && (
              <View
                style={{ alignItems: 'center', marginTop: Sizing.BASE_SPACING }}
              >
                <Text
                  onPress={showMoreHandler}
                  style={[styles.subHeader, styles.showMore]}
                >
                  {translate('COLLAPSE_ALL')}
                </Text>
              </View>
            )}
          </Card>
        )}
        {showGroup('PER_FIELD_OR_CROP_ZONE_SUMMARY') && passSummary && (
          <FarmPlanDeliverablePerFieldSummary
            selections={selections}
            zoneSummaries={passSummary}
          />
        )}
      </ScrollView>
    </View>
  );
};
