import * as _ from 'lodash';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { ISortableTableHeaderProps, SortableTable } from '../../../../elements/table';
import { Card, Search, Text } from '../../../../ui-components';
import { Sizing, ThemeColors } from '../../../../constants';
import { StringUtility } from '../../../../utilities';
import { testId } from '../../../../utilities/testId';

export interface DeliverableFieldSummaryProps {
  // TODO: use latest models from db (not in this repo yet)
  zoneSummaries: any[],
  selections: any,
}

const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  header: {
    marginRight: 1.2 * Sizing.BASE_SPACING,
    fontSize: 1.5 * Sizing.EM,
    fontWeight: 'bold',
  },
  subHeader: {
    fontSize: 1.5 * Sizing.BASE_SPACING,
    marginRight: 1.2 * Sizing.BASE_SPACING,
  },
  showMore: {
    color: ThemeColors.PRIMARY,
    marginTop: Sizing.HALF_SPACING,
    textDecorationLine: 'none',
    fontSize: Sizing.BASE_SPACING,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: Sizing.BASE_SPACING,
  },
  actionBar: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: Sizing.BASE_SPACING,
  },
  search: {
    flex: 1,
    height: 2.5 * Sizing.EM,
  },
  largeText: { fontSize: 1.1 * Sizing.EM },
  flexLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

const INITIAL_NUMBER_OF_ROWS = 3;

export const FarmPlanDeliverablePerFieldSummary = (props: DeliverableFieldSummaryProps) => {
  const { zoneSummaries, selections } = props;
  const [translate] = useTranslation(['farmPlans', 'growers', 'prepare', 'common']);
  const [search, setSearch] = useState('');
  const [numberOfRows, setNumbersOfRows] = useState(INITIAL_NUMBER_OF_ROWS);
  const [showMore, setShowMore] = useState(false);
  const [rows, setRows] = useState([]);

  const showField = useCallback(
    (name: string) => _.get(selections, `${name}.enabled`) === true,
    [selections],
  );

  const passHeaders: ISortableTableHeaderProps[] = useMemo(() => _.compact([
    (showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.FARM_NAME')
    || showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.ZONE_PRODUCT_SUMMARY_NAME')) ? {
        label: translate('FARM_NAME'),
        field: '',
      } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.FIELD_NAME') ? {
      label: translate('FIELD_NAME'),
      field: '',
    } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.CROP_ZONE_NAME') ? {
      label: translate('CROP_ZONE_NAME'),
      field: '',
    } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.CROP_TYPE') ? {
      label: translate('CROP_TYPE'),
      field: '',
    } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.PASS_NAME') ? {
      label: translate('PASS_NAME'),
      field: '',
    } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.PRODUCT_SKU_NAME') ? {
      label: translate('PRODUCT_SKU_NAME'),
      field: '',
    } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.RATE_APPLIED') ? {
      label: `${translate('RATE')} ${translate('PER_ACRE_NOTE')}`,
      field: '',
    } : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.UNITS_REQUIRED') ? {
      label: translate('UNITS_REQUIRED'),
      field: '',
    } : null,
  ]), [translate, showField]);

  const tableWidths = useMemo(() => _.compact([
    (showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.FARM_NAME')
    || showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.ZONE_PRODUCT_SUMMARY_NAME')) ? 1 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.FIELD_NAME') ? 1 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.CROP_ZONE_NAME') ? 1 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.CROP_TYPE') ? 1.5 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.PASS_NAME') ? 1 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.PRODUCT_SKU_NAME') ? 1.5 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.RATE_APPLIED') ? 1 : null,
    showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.UNITS_REQUIRED') ? 1 : null,
  ]), [showField]);

  const getRows = (searchStr?: string) => {
    const getFarmName = (summary) => (
      summary?.FARM_NAME || summary?.ZONE_PRODUCT_SUMMARY_NAME?.split?.(' / ').shift()
    );
    const summaries = zoneSummaries.map((zoneSummary) => (
      _.assignIn(zoneSummary, {
        FARM_NAME: getFarmName(zoneSummary),
        cropColor: zoneSummary.CROP_COLOR || ThemeColors.TEXT,
        key: [
          getFarmName(zoneSummary),
          zoneSummary.FIELD_NAME,
          zoneSummary.CROP_ZONE_NAME,
          zoneSummary.PASS_NAME,
          zoneSummary.SKU_NAME,
        ].join(' '),
      })
    ));

    summaries.sort((a, b) => a.key.localeCompare(b.key));

    let lastFarmName = '';
    let lastFieldName = '';
    let lastZoneName = '';

    const newRows = summaries.map((zoneSummary) => {
      const applicationRate = StringUtility.formatDecimal(zoneSummary.RATE_APPLIED, 3);
      const applicationRateUom = translate(zoneSummary.RATE_APPLIED_UOM);
      const row = {
        key: [
          zoneSummary.FARM_NAME,
          zoneSummary.FIELD_NAME,
          zoneSummary.CROP_ZONE_NAME,
          zoneSummary.PASS_NAME,
          zoneSummary.PASS_NUMBER,
          zoneSummary.CROP_TYPE,
          zoneSummary.CROP_SUB_TYPE,
          zoneSummary.PRODUCT_SKU_NAME,
        ].join(' '),
        columns: _.compact([
          (showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.FARM_NAME')
          || showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.ZONE_PRODUCT_SUMMARY_NAME')) ? (
            <>
              {(searchStr || lastFarmName !== zoneSummary.FARM_NAME) && (
                <>
                  <Text
                    numberOfLines={1}
                    style={styles.largeText}
                  >
                    {zoneSummary.FARM_NAME}
                  </Text>
                  {zoneSummary.FARM_AREA && (
                    <Text
                      numberOfLines={1}
                      style={{ color: ThemeColors.LIGHTER_GRAY }}
                    >
                      {`(${StringUtility.formatDecimal(zoneSummary.FARM_AREA)} ac)`}
                    </Text>
                  )}
                </>
              )}
            </>
            ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.FIELD_NAME') ? (
            <>
              {(searchStr || lastFieldName !== zoneSummary.FIELD_NAME) && (
                <>
                  <Text
                    numberOfLines={1}
                    style={styles.largeText}
                  >
                    {zoneSummary.FIELD_NAME}
                  </Text>
                  {zoneSummary.FIELD_AREA && (
                    <Text style={{ color: ThemeColors.LIGHTER_GRAY }}>
                      {`(${StringUtility.formatDecimal(zoneSummary.FIELD_AREA, 0)} ac)`}
                    </Text>
                  )}
                </>
              )}
            </>
          ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.CROP_ZONE_NAME') ? (
            <>
              {(zoneSummary.CROP_ZONE_NAME
              && (searchStr || lastZoneName !== zoneSummary.CROP_ZONE_NAME)) && (
                <>
                  <Text
                    numberOfLines={1}
                    style={styles.largeText}
                  >
                    {zoneSummary.CROP_ZONE_NAME}
                  </Text>
                  {zoneSummary.CROP_ZONE_AREA && (
                    <Text style={{ color: ThemeColors.LIGHTER_GRAY }}>
                      {`(${StringUtility.formatDecimal(zoneSummary.CROP_ZONE_AREA, 0)} ac)`}
                    </Text>
                  )}
                </>
              )}
            </>
          ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.CROP_TYPE') ? (
            <>
              {zoneSummary.CROP_TYPE && (
                <Text
                  numberOfLines={1}
                  style={[styles.largeText, { color: zoneSummary.cropColor }]}
                >
                  {translate(zoneSummary.CROP_TYPE)}
                  {zoneSummary.CROP_SUB_TYPE ? ` | ${translate(zoneSummary.CROP_SUB_TYPE)}` : ''}
                </Text>
              )}
            </>
          ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.PASS_NAME') ? (
            <>
              <Text
                numberOfLines={1}
                style={[styles.largeText]}
              >
                {zoneSummary.PASS_NAME}
              </Text>
            </>
          ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.PRODUCT_SKU_NAME') ? (
            <Text
              numberOfLines={1}
              style={styles.largeText}
            >
              {zoneSummary.PRODUCT_SKU_NAME}
            </Text>
          ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.RATE_APPLIED') ? (
            <Text
              numberOfLines={1}
              style={styles.largeText}
            >
              {`${applicationRate} ${applicationRateUom}`}
            </Text>
          ) : null,
          showField('PER_FIELD_OR_CROP_ZONE_SUMMARY.UNITS_REQUIRED') ? (
            <Text
              numberOfLines={1}
              style={styles.largeText}
            >
              {/* eslint-disable-next-line prefer-template */}
              {StringUtility.formatDecimal(zoneSummary.UNITS_REQUIRED, 2) + ' '}
              {translate(zoneSummary.UNITS_REQUIRED_UOM)}
            </Text>
          ) : null,
        ]),
      };

      lastFarmName = zoneSummary.FARM_NAME;
      lastFieldName = zoneSummary.FIELD_NAME;
      lastZoneName = zoneSummary.CROP_ZONE_NAME || zoneSummary.FIELD_NAME;

      return row;
    });

    if (!searchStr) {
      return newRows;
    }

    return _.compact(newRows.map((row) => {
      if (new RegExp(`.*${searchStr}.*`, 'ig').test(row?.key)) {
        return row;
      }
      return null;
    }));
  };

  useEffect(() => {
    setRows(getRows());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneSummaries]);

  const resetSearch = () => {
    setSearch('');
    setRows(getRows());
  };

  const fields = zoneSummaries.map(({ FIELD_NAME }) => FIELD_NAME);
  const fieldCount = _.uniq(fields).length;

  const showMoreHandler = () => {
    if (!showMore) {
      setNumbersOfRows(zoneSummaries.length);
    } else {
      setNumbersOfRows(INITIAL_NUMBER_OF_ROWS);
    }
    setShowMore(!showMore);
  };

  return (
    <Card style={{ marginTop: Sizing.BASE_SPACING }} testID="per-field-summary-card">
      <View style={{ paddingBottom: Sizing.BASE_SPACING }}>
        <View style={styles.headerContainer}>
          <Text
            numberOfLines={1}
            style={styles.header}
          >
            { translate('FIELD_DETAILS') as string }
          </Text>
          <Text
            numberOfLines={1}
            style={styles.subHeader}
          >
            {fieldCount > 1
              ? translate('FIELDS_COUNT', { fields: fieldCount })
              : translate('FIELD_COUNT', { field: fieldCount })}
          </Text>
          {rows.length > INITIAL_NUMBER_OF_ROWS
            && (
            <Text
              numberOfLines={1}
              onPress={showMoreHandler}
              style={[styles.subHeader, styles.showMore]}
            >
              {showMore ? translate('COLLAPSE_ALL') : translate('SHOW_DETAILS')}
            </Text>
            )}
        </View>
      </View>
      <View style={styles.actionBar}>
        <View style={styles.search}>
          <Search
            nativeID="search-bar"
            onChangeText={setSearch}
            onClear={() => resetSearch()}
            onSubmitEditing={() => setRows(getRows(search))}
            placeholder={`${translate('SEARCH')}...`}
            style={{ flex: 1 }}
            value={search}
          />
        </View>
        <View style={{ flex: 1 }} />
      </View>
      <ScrollView>
        <SortableTable
          headers={passHeaders}
          numberOfRows={numberOfRows}
          rows={rows}
          widths={tableWidths}
        />
        {rows.length > INITIAL_NUMBER_OF_ROWS
        && (
        <View {...testId('show-more-fields-link')} style={{ alignItems: 'center', marginTop: Sizing.BASE_SPACING }}>
          <Text
            onPress={showMoreHandler}
            style={[styles.subHeader, styles.showMore]}
          >
            {showMore ? translate('COLLAPSE_ALL') : translate('MORE', { count: rows.length - numberOfRows })}
          </Text>
        </View>
        )}
      </ScrollView>
    </Card>
  );
};
