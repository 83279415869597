/* eslint-disable react/no-array-index-key */
import { StyledComponentProps } from '@ui-kitten/components/theme';
import React, { FC, Fragment, useState } from 'react';
import { View, ViewProps, StyleSheet, FlatList } from 'react-native';
import { Modal, Text } from '@design';
import { TFunction, useTranslation } from 'react-i18next';
import { TextLink } from '@design/TextLink/TextLink';
import { HSpacer, VSpacer } from '../Spacer';

const INITIAL_MAX_ITEMS_AMOUNT = 5;

export interface ListModalProps extends ViewProps, StyledComponentProps {
  buttonShowMoreText?: string,
  horizontal?: boolean,
  items?: React.ReactNode[],
  maxItems?: number,
  onClose?: () => void,
  onViewAll?: () => void,
  showItemAmount?: boolean,
  testID: string,
  title: TFunction | string,
  visible?: boolean,
}

const styles = StyleSheet.create({
  showMoreText: {
    alignSelf: 'flex-end',
  },
  viewItems: {
    maxHeight: 400,
  },
  listWrap: {
    alignItems: 'flex-start',
    marginRight: 'auto',
    width: '100%',
  },
  listItem: {
    width: '100%',
  },
  itemsAmountLabel: {
    marginBottom: 5,
  },
});

const ListModalRaw: FC<ListModalProps> = ({
  buttonShowMoreText,
  horizontal = false,
  items = [],
  maxItems = INITIAL_MAX_ITEMS_AMOUNT,
  onClose = () => {},
  onViewAll = () => {},
  showItemAmount = false,
  testID,
  title,
  style,
  visible = false,
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [translate] = useTranslation('common');
  const filteredItems = items.filter(Boolean);
  const getContent = (isModal) => {
    if (isModal) {
      return (
        <>
          {
            showItemAmount && (
              <Text
                appearance="hint"
                category="c1"
                style={styles.itemsAmountLabel}
              >
                {`${filteredItems.length} ${String(title).toLowerCase()}`}
              </Text>
            )
          }
          <FlatList
            data={filteredItems}
            keyExtractor={(item, index) => `${index}`}
            renderItem={({ item, index }) => {
              return (
                <Fragment
                  key={index}
                >
                  {!!index && <VSpacer size="2" />}
                  <View testID={`${testID}-${index}-wrapper`}>{item}</View>
                </Fragment>
              );
            }}
          />
        </>
      );
    }
    return filteredItems.slice(0, maxItems).map((item, index) => {
      return (
        <Fragment
          key={index}
        >
          {!!index && <VSpacer size="2" />}
          <View style={styles.listItem} testID={`${testID}-${index}-wrapper`}>
            {item}
          </View>
        </Fragment>
      );
    });
  };

  return (
    <>
      {isVisible && (
        <Modal
          onClose={() => {
            setIsVisible(false);
            onClose();
          }}
          testID={`${testID}-modal`}
          title={`${title}`}
          visible
        >
          <View style={styles.viewItems}>{getContent(true)}</View>
        </Modal>
      )}
      <View
        style={[
          horizontal
            ? {
              flexDirection: 'row',
              flexWrap: 'wrap',
            }
            : {},
          style,
        ]}
      >
        <View style={styles.listWrap}>
          <Text appearance="hint" category="c1">{`${title}`}</Text>
          {!!filteredItems.length && <>{getContent(false)}</>}
          {filteredItems.length > maxItems && (
            <View
              style={[styles.showMoreText, { flexDirection: 'row' }]}
            >
              <TextLink
                appearance="secondary"
                category="p2"
                onPress={() => {
                  onViewAll();
                  setIsVisible(!isVisible);
                }}
              >
                {buttonShowMoreText ?? translate<string>('VIEW_ALL_PRICING_INFO')}
              </TextLink>
              <HSpacer size="7" />
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export const ListModal: FC<ListModalProps> = ListModalRaw;
