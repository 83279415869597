import { Reports } from '@shared/enums/Reports';
import { Permissions, RoleUtility, timeStamp } from '@shared/utils';
import { Spinner, useStyleSheet } from '@ui-kitten/components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, StyleSheet } from 'react-native';
import { Card, Text, Table, TableRow, TableHeader, IconButton, TableCell, PageHeader, TextLink } from '@design';
import { useAuthentication } from '../../contexts/dataSync/AuthenticationContext';
import { UnexpectedError } from '../../ui-components/utils/UnexpectedError';
import { ReportingApi } from '../../utilities/api/ReportingApi';
import { CSVResponse, ExportToCSV } from '../../utilities/helpers/exportCSV';

const styles = StyleSheet.create({
  cancelPadding: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    paddingBottom: 0,
  },
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const ReportsPage = () => {
  const { user, currentBusinessId: businessId } = useAuthentication();
  const [translate] = useTranslation(['reports', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [showRowDetails, setShowRowDetails] = useState([]);
  const [showAllDetails, setShowAllDetails] = useState(false);

  const growersStyles = useStyleSheet({
    description: {
      color: 'text-hint-color',
    },
  });

  const reportFactory = (token, reportAction, bizId) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCallback((async () => {
      try {
        setIsLoading(true);
        const report: CSVResponse = await reportAction(bizId);
        ExportToCSV(report.csv, `${translate(token)} - ${timeStamp()}`);
      } catch (err) {
        UnexpectedError(err as Error, `Downloading ${translate(token)}`);
      } finally {
        setIsLoading(false);
      }
    }), [token, reportAction, bizId]);
  };

  const reports = [{
    key: Reports.AcceptedReport,
    titleKey: 'ACCEPTED_REPORT',
    descriptionKey: 'ACCEPTED_REPORT_DESCRIPTION',
    action: reportFactory('ACCEPTED_REPORT', ReportingApi.getAcceptedReport, businessId),
    testId: 'download-accepted-report',
  }, {
    key: Reports.SalesInsightReport,
    titleKey: 'SALES_INSIGHT_REPORT',
    descriptionKey: 'SALES_INSIGHT_REPORT_DESCRIPTION',
    action: reportFactory('SALES_INSIGHT_REPORT', ReportingApi.getSalesInsightReport, businessId),
    testId: 'download-sales-insight-report',
  }];

  reports.push({
    key: Reports.BusinessPricingReport,
    titleKey: 'BUSINESS_PRICING_REPORT',
    descriptionKey: 'BUSINESS_PRICING_REPORT_DESCRIPTION',
    action: reportFactory('BUSINESS_PRICING_REPORT', ReportingApi.getBusinessPricingReport, businessId),
    testId: 'download-business-pricing-report',
  });

  const hasPermission = RoleUtility.roleHasPermission(
    user.userRole,
    Permissions.MODIFY_BUSINESS_OBJECTS,
  );

  const rows = hasPermission && reports.map((report) => (
    <TableRow
      key={report.key}
      rowDetails={showRowDetails.includes(report.key) && (
      <View style={{ left: -15 }}>
        <Text category="c1" style={growersStyles.description}>
          {translate('DESCRIPTION') as string}
        </Text>
        <Text category="p2">{translate(report.descriptionKey) as string}</Text>
      </View>

      )}
      testID={`report-${report.key}`}
    >
      <TableCell style={{ width: 60 }} testID="expand" usePadding={false}>
        <IconButton
          appearance="ghost"
          onPress={() => {
            let rowDetails = [...showRowDetails];
            if (!rowDetails.includes(report.key)) {
              rowDetails.push(report.key);
              setShowRowDetails(rowDetails);
              return;
            }
            rowDetails = rowDetails.filter((v) => v !== report.key);
            setShowRowDetails(rowDetails);
          }}
          size="medium"
          status="basic"
          testID="expand-report-chevron"
        >
          {showRowDetails.includes(report.key) ? 'ChevronDown' : 'ChevronRight'}
        </IconButton>
      </TableCell>
      <TableCell style={{ width: 280 }} testID="report-name">
        {translate<string>(report.titleKey)}
      </TableCell>
      <TableCell style={{ flex: 1 }} testID="description">
        {translate<string>(report.descriptionKey)}
      </TableCell>
      <TableCell style={{ paddingRight: 32 }} testID="download">
        <TextLink
          appearance="secondary"
          category="p2"
          onPress={report.action}
          testID={report.testId}
        >
          {translate<string>('DOWNLOAD')}
        </TextLink>
      </TableCell>
    </TableRow>
  ));

  const showAll = () => {
    setShowRowDetails(reports.map((r) => r.key));
    setShowAllDetails(true);
  };

  const hideAll = () => {
    setShowRowDetails([]);
    setShowAllDetails(false);
  };

  return (
    <ScrollView testID="reportingView">
      <PageHeader testID="reports-page">Reports</PageHeader>
      <Card style={styles.cancelPadding} testID="report-card">
        {isLoading && (
          <View style={[styles.container, styles.spinnerContainer]}>
            <Spinner size="giant" />
          </View>
        )}
        <Table testID="reports-table">
          <TableRow appearance="header" testID="reports-table-row-show-all-details">
            <TableHeader
              style={{ width: 60 }}
              testID="reports-table-header-show-all-details"
            >
              <IconButton
                appearance="ghost"
                onPress={showAllDetails ? hideAll : showAll}
                size="medium"
                status="basic"
                testID="expand-and-hide-all-chevron"
              >
                {showAllDetails ? 'ChevronDown' : 'ChevronRight'}
              </IconButton>
            </TableHeader>
            <TableHeader
              style={{ width: 280 }}
              testID="reports-table-header-report-name"
            >
              <Text>Report Name</Text>
            </TableHeader>
            <TableHeader
              style={{ flex: 1 }}
              testID="reports-table-header-description"
            >
              <Text>Description</Text>
            </TableHeader>
            <TableHeader
              testID="reports-table-header-download"
            >
              <Text />
            </TableHeader>
          </TableRow>
          {rows}
        </Table>
      </Card>
    </ScrollView>
  );
};
