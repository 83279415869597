import { Select, SelectItem, useBanner } from '@design';
import { CropTypeAndSubtype } from '@shared/interfaces';
import { IndexPath } from '@ui-kitten/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../constants';
import { useAuthentication } from '../../../../contexts/dataSync/AuthenticationContext';
import { BusinessApi } from '../../../../utilities/api';

export interface BusinessCropSelectProps {
  crops?: CropTypeAndSubtype[],
  onUpdateCrops(crops: CropTypeAndSubtype[]): void,
  readonly?: boolean,
}

export const BusinessCropSelect = ({
  crops,
  onUpdateCrops,
  readonly,
}: BusinessCropSelectProps) => {
  const [translate] = useTranslation(['common', 'prepare']);
  const { currentBusinessId } = useAuthentication();

  const { showBanner } = useBanner();

  const { data: availableCrops, isLoading } = useQuery(
    [QueryKeys.TANK_MIX_BUSINESS_CROPS, currentBusinessId],
    () => BusinessApi.getBusinessCrops(currentBusinessId),
    {
      initialData: [],
      onError: () => {
        showBanner(translate('ERROR_BUSINESS_CROPS'));
      },
    },
  );

  return (
    <Select
      disabled={isLoading}
      isRequired
      label={translate<string>('CROPS')}
      multiSelect
      onSelect={(indexPath: IndexPath | IndexPath[]) => {
        const indices = (indexPath as IndexPath[]).map((index) => index.row).sort();
        onUpdateCrops(indices.map((index) => {
          return {
            cropType: availableCrops[index].cropType,
            cropSubType: availableCrops[index].subType,
          };
        }));
      }}
      readonly={readonly}
      selectedIndex={crops?.map((crop) => (
        new IndexPath(availableCrops?.findIndex((c) => (
          c.cropType === crop.cropType
          && c.subType === crop.cropSubType
        )))
      ))}
      testID="crop-type"
      value={crops?.map((crop) => (
        `${translate(crop.cropType)} | ${translate(crop.cropSubType)}`
      )).join(', ')}
    >
      {availableCrops.map((crop, index) => (
        <SelectItem
          key={`${crop.cropType}-${crop.subType}`}
          testID={`crop-select-${index}`}
          title={`${translate(crop.cropType)} | ${translate(crop.subType)}`}
        />
      ))}
    </Select>
  );
};
