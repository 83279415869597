import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '../constants';
import { GrowerApi } from '../utilities/api';

interface UseCustomerProps {
  customerId?: string,
  onError?(error: Error): void,
}

export const useCustomer = ({ customerId, onError }: UseCustomerProps) => {
  const queryClient = useQueryClient();
  const invalidateCustomer = useCallback(() => (
    queryClient.invalidateQueries([QueryKeys.CUSTOMER, customerId])
  ), [customerId, queryClient]);

  const { data: customer, isFetching: isCustomerLoading } = useQuery(
    [QueryKeys.CUSTOMER, customerId],
    () => GrowerApi.getGrower(customerId),
    {
      enabled: !!customerId,
      onError: (error: Error) => onError?.(error),
    },
  );

  return {
    customer,
    invalidateCustomer,
    isCustomerLoading,
  };
};
