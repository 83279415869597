import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, HSpacer, Text, useToast } from '@design';
import { useQueryClient } from 'react-query';
import { ApiTankMix } from '@shared/interfaces/api';
import { TankMixApi } from '../../../../utilities/api';
import { QueryKeys } from '../../../../constants';

interface ChangeTankMixStatusProps {
  tankMix: ApiTankMix,
  onCancel: () => void,
  onTankMixUpdated: (updatedTankMix: ApiTankMix) => void,
}

export const ChangeTankMixStatusDialog: FC<ChangeTankMixStatusProps> = ({
  tankMix, onCancel, onTankMixUpdated }) => {
  const [translate] = useTranslation(['common', 'prepare', 'tankMix']);
  const { createToast } = useToast();
  const queryClient = useQueryClient();

  const updateTankMix = useCallback(async () => {
    const { id, isActive } = tankMix;
    try {
      const updatedTankMix = await TankMixApi.updateStatus(id, !isActive);
      onTankMixUpdated(updatedTankMix);
      createToast({
        status: 'success',
        children: `${translate('PRODUCT_MIX_UPDATED')}`,
        testID: 'toast-content-element',
      });
      await queryClient.invalidateQueries(QueryKeys.TANK_MIX_LIST);
    } catch (err) {
      createToast({
        status: 'warning',
        children: `${translate('UNEXPECTED_ERROR', { error: (err as Error).message })}`,
        testID: 'toast-content-element',
      });
    }
  }, [createToast, onTankMixUpdated, queryClient, tankMix, translate]);

  return (
    <Dialog
      footerAccessory={({ primaryButtonProp, secondaryButtonProp, spacerProp }) => (
        <>
          <Button testID="cancel-button" {...primaryButtonProp}>{`${translate('CANCEL')}`}</Button>
          <HSpacer {...spacerProp} />
          <Button
            {...secondaryButtonProp}
            onPress={updateTankMix}
            testID="activate-deactivate-button"
          >
            {translate(!tankMix?.isActive ? 'ACTIVATE' : 'DEACTIVATE') as string}
          </Button>
        </>
      )}
      onClose={onCancel}
      testID="change-tank-mix-status-dialog"
      visible={!!tankMix}
    >
      <Text>
        {translate(tankMix?.isActive
          ? 'PRODUCT_MIX_DEACTIVATE_MODAL_WARNING'
          : 'PRODUCT_MIX_ACTIVATE_MODAL_WARNING') as string}
      </Text>
    </Dialog>
  );
};
