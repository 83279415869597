import { Icon } from '@design';
import { IntegrationType, PlanStatus } from '@shared/enums';
import { ApiFarmPlan, ApiGrowerFarm } from '@shared/interfaces/api';
import { userIsInternal } from '@shared/utils';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../../../constants';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useAuthentication } from '../../../../../contexts/dataSync/AuthenticationContext';
import { useIntegration } from '../../../../../hooks/useIntegration';
import { useHistory, useLocation } from '../../../../../router';
import { ButtonBar } from '../../../../components/shared/ButtonBar';
import {
  ConfirmationModal,
} from '../../../../components/shared/ConfirmationModal/ConfirmationModal';

export interface FarmPlanOverviewFooterProps {
  farmPlan: ApiFarmPlan,
  hasAcceptedOrders: boolean,
  initialFarmPlan: ApiFarmPlan,
  setFarmPlan(
    newFarmPlan: ApiFarmPlan,
    updateInitialFarmPlan: boolean,
    newFarms?: ApiGrowerFarm[],
  ): void,
}

export const FarmPlanOverviewFooter = ({
  farmPlan,
  hasAcceptedOrders,
  initialFarmPlan,
  setFarmPlan,
}: FarmPlanOverviewFooterProps) => {
  const [translate] = useTranslation(['common', 'farmPlans']);

  const { setModalProps } = useAppContext();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [acceptedModalVisible, setAcceptedModalVisible] = useState(false);

  const { currentBusinessId, user } = useAuthentication();
  const isInternal = userIsInternal(user);

  const history = useHistory();
  const location = useLocation<{ previous: string }>();
  const { integration } = useIntegration({ businessId: currentBusinessId });

  const { viewMode, newAcceptedOrders } = useMemo(
    () => ({
      viewMode: _.isEqual(initialFarmPlan, farmPlan),
      newAcceptedOrders: farmPlan.growerFarmPlanProductSummaries?.some((productSummary, idx) => (
        productSummary.planStatus === PlanStatus.ACCEPTED
        && initialFarmPlan.growerFarmPlanProductSummaries[idx].planStatus !== PlanStatus.ACCEPTED
      )),
    }),
    [farmPlan, initialFarmPlan],
  );
  const hasBusinessCentralIntegration = (
    !!integration && integration.type === IntegrationType.MSDynamics
  );

  return (
    <>
      <ConfirmationModal
        cancelText={translate<string>('KEEP_EDITING')}
        confirmText={translate<string>('CONFIRM')}
        messageText={translate<string>('CHANGES_YOU_MADE_SO_FAR_WILL_NOT_BE_SAVED')}
        onCancel={() => setCancelModalVisible(false)}
        onConfirm={() => {
          setFarmPlan(initialFarmPlan, true, null);
          setCancelModalVisible(false);
        }}
        status="warning"
        title={translate<string>('CONFIRM_CANCELLATION')}
        visible={cancelModalVisible}
      />
      <ConfirmationModal
        cancelText={translate<string>('CANCEL')}
        confirmText={translate<string>('YES_SAVE')}
        messageText={translate<string>(hasBusinessCentralIntegration
          ? 'ONE_OR_MORE_PRODUCTS_HAVE_BEEN_ACCEPTED_DYNAMICS'
          : 'ONE_OR_MORE_PRODUCTS_HAVE_BEEN_ACCEPTED')}
        onCancel={() => setAcceptedModalVisible(false)}
        onConfirm={async () => {
          setFarmPlan(farmPlan, true);
          setAcceptedModalVisible(false);
        }}
        status="warning"
        title={translate<string>('SAVE_FARM_PLAN_ORDER')}
        visible={acceptedModalVisible}
      />
      {viewMode ? (
        <ButtonBar
          accessoryLeft={(backProps) => (
            <Icon name="ArrowBack" testID="farm-plan-view-button-icon" {...backProps} />
          )}
          hideRightButton={isInternal}
          leftAction={() => {
            if (hasAcceptedOrders) {
              history.push(Routes.PREPARE_REVIEW.replace(/:tab/, 'farm-plans'));
            } else {
              history.push(
                location.state?.previous
                ?? Routes.FARM_PLAN_EDIT_FROM_GROWER
                  .replace(/:grower/, farmPlan.growerId)
                  .replace(/:id/, farmPlan.id),
              );
            }
          }}
          leftButtonText={translate('BACK')}
          rightAction={!farmPlan ? undefined : () => setModalProps({
            farmPlanId: farmPlan.id,
            showNoteCheckbox: !!farmPlan.note,
            showSpinner: false,
            type: 'createDeliverableFarmPlan',
          })}
          rightButtonText={translate('CREATE_AND_SHARE_DELIVERABLE')}
          testID="farm-plan-view-button"
        />
      ) : (
        <ButtonBar
          buttonBarType="grouped"
          leftAction={() => setCancelModalVisible(true)}
          leftButtonText={translate('CANCEL')}
          rightAction={!farmPlan ? undefined : () => {
            if (newAcceptedOrders) {
              setAcceptedModalVisible(true);
            } else {
              setFarmPlan(farmPlan, true);
            }
          }}
          rightButtonText={translate('SAVE_CHANGES')}
          testID="farm-plan-edit-button"
        />
      )}
    </>
  );
};
