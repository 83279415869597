import { SharedConfig } from '@shared/constants';
import { CropLogicEndpoint } from '@shared/interfaces/api';
import qs from 'qs';
import { Client } from './Client';

export class CropLogicApi {
  static createCropLogic (
    cropLogicData: CropLogicEndpoint.Create.Request,
  ): Promise<CropLogicEndpoint.Create.Response> {
    return Client('crop-logics', { body: cropLogicData });
  }

  static getCropLogic (cropLogicId: string): Promise<CropLogicEndpoint.Get.Response> {
    return Client(`crop-logics/${cropLogicId}`);
  }

  static updateCropLogic (
    cropLogicId: string,
    updates: CropLogicEndpoint.Update.Request,
  ): Promise<CropLogicEndpoint.Update.Response> {
    return Client(`crop-logics/${cropLogicId}`, {
      method: 'PATCH',
      body: updates,
    });
  }

  static listCropLogic (
    filters: CropLogicEndpoint.List.Query,
  ): Promise<CropLogicEndpoint.List.Response> {
    const query = qs.stringify(filters, { arrayFormat: 'comma', skipNulls: true });
    return Client(`crop-logics?${query}`);
  }

  static getAllCropLogicsForBusiness (
    businessId: string,
    isActive?: boolean[],
  ): Promise<CropLogicEndpoint.List.Response> {
    const filters: CropLogicEndpoint.List.Query = {
      businessId,
      isActive: isActive.map((value) => Boolean(value).toString()),
      limit: SharedConfig.MAX_PAGE_LIMIT,
    };
    return this.listCropLogic(filters);
  }
}
