import { FalsyIcon, IconName, IconProps, Text } from '@design';
import { DescriptionListTextCategory } from '@theme/variant-interfaces/Category';
import { useStyleSheet } from '@ui-kitten/components';
import { FalsyText, RenderProp } from '@ui-kitten/components/devsupport';
import React, { ReactNode } from 'react';
import { TextProps, View } from 'react-native';

export interface DescriptionListItemProps {
  accessoryLabel?: RenderProp<Partial<IconProps>> | IconName,
  accessoryLeft?: RenderProp<Partial<IconProps>> | IconName,
  accessoryRight?: RenderProp<Partial<IconProps>> | IconName,
  children?: ReactNode,
  label: string,
  testID?: string,
  text?: string | RenderProp<TextProps>,
  textAccessory?: ReactNode,
  textCategory?: DescriptionListTextCategory,
  wrap?: boolean,
}

const DescriptionListItem = ({
  accessoryLabel,
  accessoryLeft,
  accessoryRight,
  children,
  label,
  text,
  textAccessory,
  textCategory = 'p1',
  testID,
  wrap = false,
}: DescriptionListItemProps) => {
  const themedStyles = useStyleSheet({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      color: 'text-hint-color',
    },
    icon: {
      paddingLeft: 8,
    },
    labelIcon: {
      paddingRight: 10,
    },
    wrap: {
      flex: 1,
    },
  });

  return (
    <View style={[themedStyles.container, wrap && themedStyles.wrap]}>
      <FalsyIcon
        component={accessoryLabel}
        size={textCategory === 'p2' ? 'small' : 'medium'}
        style={themedStyles.labelIcon}
      />
      <Text category={textCategory} style={themedStyles.label} testID={testID} wrap>
        {`${label}: `}
        <FalsyIcon
          component={accessoryLeft}
          size={textCategory === 'p2' ? 'small' : 'medium'}
          style={themedStyles.icon}
        />
        { !!children && <>{children}</> }
        { !children && (
          <Text category={textCategory} testID={`${testID}-value`}>
            <FalsyText category={textCategory} component={text} />
          </Text>
        )}
      </Text>
      {textAccessory}
      <FalsyIcon
        component={accessoryRight}
        size={textCategory === 'p2' ? 'small' : 'medium'}
        style={themedStyles.icon}
      />
    </View>
  );
};

export default DescriptionListItem;
