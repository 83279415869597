import React from 'react';
import { Badge, HSpacer } from '@design';
import { View } from 'react-native';

export interface BillingShippingContactTagBadgesProps {
  tags: string[],
  testID: string,
}

export const ContactTagBadges = ({
  tags,
  testID,
}: BillingShippingContactTagBadgesProps) => {
  return (
    <>
      {tags.map((tag, index) => (
        <View key={tag} style={{ flexDirection: 'row' }}>
          <HSpacer size="5" />
          <Badge
            appearance="tag"
            testID={`${testID}-customer-contact-badge-${index}`}
          >
            {tag.toLocaleUpperCase()}
          </Badge>
        </View>
      ))}
    </>
  );
};
