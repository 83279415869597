import React from 'react';
import { Drawer, DrawerItem, IndexPath } from '@ui-kitten/components';

interface SubNavigationProps {
  labels: string[],
  onChange: (selectedLabel: string) => void,
  selectedLabel: string,
  testID: string,
}

export const SubNavigation = ({
  labels,
  onChange,
  selectedLabel,
  testID,
}: SubNavigationProps) => {
  const selectedIndex = new IndexPath(labels.findIndex((l) => l === selectedLabel));

  return (
    <Drawer
      onSelect={(index) => onChange(labels[index.row])}
      selectedIndex={selectedIndex}
      testID={testID}
    >
      {labels.map((label, index) => (
        <DrawerItem key={label} testID={`${testID}-item-${index}`} title={label} />
      ))}
    </Drawer>
  );
};
