import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Text, Input, VSpacer } from '@design';
import { SpacerSize } from '@theme/variant-interfaces/Size';
import { useStyleSheet } from '@ui-kitten/components';
import { Sizing } from '../../constants';
import DeviceSizes from './DeviceSizes';
import { useScreenSizing } from '../../hooks/useScreenSizing';

interface IPasswordFormProps {
  onChangePassword: (password: string) => void;
  onFormValidation: (isValid: boolean) => void;
  error?: string
}
export const PasswordForm: FunctionComponent<IPasswordFormProps> = ({
  onChangePassword,
  onFormValidation,
  error,
}) => {
  const [translate] = useTranslation(['login']);

  const [displayError, setDisplayError] = useState<string>(error);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [retype, setRetype] = useState<string>('');
  const sizeSetting = useScreenSizing();

  const styles = useStyleSheet({
    text: { marginBottom: Sizing.BASE_SPACING },
    subtext: { marginBottom: Sizing.HALF_SPACING },
    errorMessage: {
      padding: Sizing.HALF_SPACING,
      color: 'color-danger-500',
      textAlign: 'center',
      backgroundColor: 'color-danger-transparent-100',
    },
  });

  useEffect(() => {
    setDisplayError(error);
  }, [error]);

  useEffect(() => {
    // check form validation after every change to password and retype
    // the output of this will determine if the form is ready to submit
    // this should NOT cause errors to be shown while typing

    const hasPassword = !!password;
    const hasRetype = !!retype;
    const match = password === retype;

    // TODO perform other validations here
    // other validations should be the same code as the API uses from a shared package
    // other validations need some kind of a ui indicator that should be neutral until success
    // We don't want to give the user errors while they are still working
    // examples would be the length requirement or common passwords

    const valid = hasPassword
      && hasRetype
      && match;

    if (password === retype) {
      // only clear an existing error when passwords become matching
      // do not set the error condition here as the user is still typing
      setPasswordMismatch(false);
    }

    onFormValidation(valid);
  }, [password, retype, onFormValidation]);

  const checkMismatch = useCallback(() => {
    // used to set error condition on mismatch
    // should be used only with onBlur to set the error after user is finished with field
    // only set error if both fields have a value
    setPasswordMismatch(!!password && !!retype && password !== retype);
  }, [password, retype]);

  const typePassword = useCallback((newPassword) => {
    // store internally
    setPassword(newPassword);
    // set externally
    onChangePassword(newPassword);
  }, [onChangePassword]);

  return (
    <>
      {!!displayError && (
        <>
          <Text category="p2" nativeID="error-message" style={styles.errorMessage}>
            {displayError}
          </Text>
          <VSpacer size={DeviceSizes[sizeSetting].bottomVSpace as SpacerSize} />
        </>
      )}

      <Input
        label={translate<string>('NEW_PASSWORD')}
        onBlur={checkMismatch}
        onChangeText={typePassword}
        secureTextEntry
        status={passwordMismatch || displayError ? 'danger' : undefined}
        testID="password"
        value={password}
      />

      <VSpacer size="7" />

      <Input
        caption={passwordMismatch ? translate<string>('PASSWORD_MISMATCH') : null}
        label={translate<string>('REENTER_PASSWORD')}
        onBlur={checkMismatch}
        onChangeText={(nextValue) => setRetype(nextValue)}
        secureTextEntry
        status={passwordMismatch ? 'danger' : undefined}
        testID="verify-password"
        value={retype}
      />

      <VSpacer size="7" />

      <View>
        <Text appearance="hint" category="p2" style={styles.text}>
          {translate<string>('PASSWORD_MUST')}
        </Text>
        <Text appearance="hint" category="p2" style={styles.subtext}>
          <Text appearance="hint" category="p2" style={{ marginRight: 7 }}>
            {'\u2022'}
          </Text>
          {translate<string>('PASSWORD_RULE_LENGTH')}
        </Text>
        <Text appearance="hint" category="p2" style={styles.subtext}>
          <Text appearance="hint" category="p2" style={{ marginRight: 7 }}>
            {'\u2022'}
          </Text>
          {translate<string>('PASSWORD_RULE_EMAIL')}
        </Text>
        <Text appearance="hint" category="p2" style={styles.subtext}>
          <Text appearance="hint" category="p2" style={{ marginRight: 7 }}>
            {'\u2022'}
          </Text>
          {translate<string>('PASSWORD_RULE_NAME')}
        </Text>
      </View>
    </>
  );
};
