import {
  Button,
  Dialog,
  Table,
  Text,
  useBanner,
  VSpacer,
} from '@design';
import { IBusinessLocation } from '@shared/interfaces';
import { ApiPrice, ApiPriceType } from '@shared/interfaces/api';
import { Divider, useStyleSheet } from '@ui-kitten/components';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductPriceApi } from '../../../utilities/api/ProductPriceApi';
import { ConfirmationModal } from '../../components/shared/ConfirmationModal/ConfirmationModal';
import { MultiplePricingTableRow } from './MultiplePricingTableRow';

export interface MultiplePricingTableProps {
  businessLocations: IBusinessLocation[],
  onPricesChanged?: (prices: ApiPrice[]) => void,
  prices: ApiPrice[],
  priceTypes: ApiPriceType[],
}

export const MultiplePricingTable = memo(({
  businessLocations,
  prices,
  priceTypes,
  onPricesChanged,
}: MultiplePricingTableProps) => {
  const [translate] = useTranslation(['errors', 'common', 'products']);
  const [priceToDelete, setPriceToDelete] = useState<ApiPrice>();
  const [usages, setUsages] = useState<string>();
  const { showBanner } = useBanner();

  const styles = useStyleSheet({
    divider: {
      backgroundColor: 'color-basic-control-transparent-100',
    },
  });

  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false);
  const [
    showCorporatePricingMessageModal,
    setShowCorporatePricingMessageModal,
  ] = useState(false);

  const onDelete = async (price: ApiPrice) => {
    try {
      setShowDeleteConfirmationModal(true);
      setPriceToDelete(price);
      setUsages('...');
      setUsages((await ProductPriceApi.getUsages(price.id)).toString().padStart(3, '0'));
    } catch {
      showBanner(translate('ERROR_FETCH_PRODUCT_PRICE_USAGES'));
    }
  };

  const sortedLocations = (
    businessLocations.sort((a, b) => a.locationName.localeCompare(b.locationName))
  );

  return (
    <>
      <Text category="s1">{translate<string>('PRICING')}</Text>
      <VSpacer size="9" />
      <Divider style={styles.divider} />
      <Table style={{ backgroundColor: 'transparent' }} testID="multiple-pricing-table">
        <MultiplePricingTableRow
          index={0}
          onCorporateInfoMessage={() => setShowCorporatePricingMessageModal(true)}
          onPricesChanged={onPricesChanged}
          priceTypes={priceTypes}
          prices={prices}
        />
      </Table>
      <VSpacer size="9" />
      <Text category="c2" style={{ textTransform: 'uppercase' }}>
        {translate<string>('LOCATION_PRICING')}
      </Text>
      <VSpacer size="7" />
      <Divider style={styles.divider} />
      <Table style={{ backgroundColor: 'transparent' }} testID="multiple-pricing-location-table">
        {sortedLocations.map((location, index) => (
          <MultiplePricingTableRow
            index={index + 1}
            key={location.id}
            location={location}
            onDelete={onDelete}
            onPricesChanged={onPricesChanged}
            priceTypes={priceTypes}
            prices={prices}
          />
        ))}
      </Table>
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          cancelText={translate('CANCEL')}
          confirmText={translate('YES_DELETE')}
          messageText={
            translate(
              'DELETE_LOCATION_PRICE_DIALOG_MESSAGE',
              {
                usages,
                priceType: priceTypes.find(
                  (pt) => pt.id === priceToDelete.priceTypeId,
                )?.name,
                location: businessLocations.find(
                  (bl) => bl.id === priceToDelete.locationId,
                )?.locationName,
              },
            )
          }
          onCancel={() => {
            setShowDeleteConfirmationModal(false);
          }}
          onConfirm={() => {
            prices.splice(prices.findIndex((p) => p === priceToDelete), 1);
            onPricesChanged([...prices]);
            setShowDeleteConfirmationModal(false);
          }}
          status="warning"
          testID="delete-price-confirmation-modal"
          title={translate('DELETE_LOCATION_PRICE_DIALOG_TITLE')}
          visible={showDeleteConfirmationModal}
        />
      )}
      {showCorporatePricingMessageModal && (
        <Dialog
          footerAccessory={({ primaryButtonProp }) => (
            <>
              <Button
                testID="close-button"
                {...primaryButtonProp}
                appearance="outline"
                onPress={() => setShowCorporatePricingMessageModal(false)}
              >
                {translate<string>('CLOSE')}
              </Button>
            </>
          )}
          onClose={() => setShowCorporatePricingMessageModal(false)}
          status="info"
          testID="corporate-pricing-message-modal"
          title={translate<string>('CORPORATE_PRICING_INFO_DIALOG_TITLE')}
          visible={showCorporatePricingMessageModal}
        >
          <Text>
            {translate<string>('CORPORATE_PRICING_INFO_DIALOG_MESSAGE')}
          </Text>
        </Dialog>
      )}
    </>
  );
});
